import React, { useState, useEffect } from 'react';
import { Breadcrumb, Col, Row, Avatar, DatePicker, Spin, Table } from 'antd';
import { useHistory } from 'react-router-dom';
import locale from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';

import { BreadcrumbAntStyle, ButtonSystemStyle, TableAntStyle } from '@styles/Style';
import { VietnameseString } from '@constants/VietnameseString';
import { requestGetDetailSetupKPI, requestGetDetailSetupKPIFromDate } from './services/api';
import { ROUTER, STATUS } from '@constants/Constant';
import Wrapper from '@components/ui/wrapper/Wrapper';
import { getAllProduct, getAllTag, handleSetupRawDataDetailKPI } from './Utils/func';
import { formatPrice, setSearchParamsInUrlVer2, randomColor } from '@constants/funcHelper';
import { greenColor, greenColorHover, primaryColor } from '@styles/Color';
import TagCustom from '../components/TagCustom';
import { notifyFail } from '@utils/notify';

function DetailKPI() {
  const history = useHistory();

  const [idSetupKPI, setIdSetupKPI] = useState(undefined);
  const [telemarKetingID, setTelemarKetingID] = useState(undefined);

  const [systemKPI, setSystemKPI] = useState([
    { key: 'sales', name: VietnameseString.sale, type: 'price', active: STATUS.active },
    { key: 'revenue', name: VietnameseString.revenue, type: 'price', active: STATUS.active },
    { key: 'opportunity', name: VietnameseString.opportunity, type: 'quantity', active: STATUS.active },
    { key: 'contractClosing', name: VietnameseString.contract_closing, type: 'quantity', active: STATUS.active },
    { key: 'call', name: VietnameseString.call, type: 'quantity', active: STATUS.active },
    { key: 'newCustomer', name: 'Số khách hàng tự thêm', type: 'quantity', active: STATUS.active },
  ]);

  const [tags, setTags] = useState([]);
  const [products, setProducts] = useState([]);

  const [loading, setLoading] = useState(false);

  const [tagsSelected, setTagsSelected] = useState([
    { id: undefined, name: undefined, color: undefined, kpi: undefined },
  ]);
  const [productsSelected, setProductsSelected] = useState([{ id: undefined, name: undefined, kpi: undefined }]);

  const [telemarketingDetail, setTelemarketingDetail] = useState({});
  const [detailSetupKPI, setDetailSetupKPI] = useState({
    telemarketingId: [],
    effectiveTime: null,
    autoExtend: 0,
    workDay: 24,
    call: null,
    contractClosing: null,
    newCustomer: null,
    opportunity: null,
    revenue: null,
    sales: null,
    kpiOther: {
      tags: [],
      products: [],
    },
  });

  const [detailCurrentKPI, setDetailCurrentKPI] = useState({});

  useEffect(() => {
    getAllTag().then((res) => setTags(res));
    getAllProduct().then((res) => setProducts(res));
  }, []);

  const getDetailSetupKPI = async (user_sale_kpi_id) => {
    try {
      setLoading(true);
      setIdSetupKPI(user_sale_kpi_id);
      const result = await requestGetDetailSetupKPI(user_sale_kpi_id);
      const data = handleSetupRawDataDetailKPI(systemKPI, tags, products, result);
      if (data !== undefined) {
        setDetailSetupKPI(data?.detailDataSetupKPI);
        setSystemKPI(data?.newSystemKPI);
        setProductsSelected(data?.productsSelected);
        setTagsSelected(data?.tagsSelected);
        setTelemarketingDetail(data?.telemarketingDetail);
        setDetailCurrentKPI(data?.detailDataCurrentSetupKPI);
        setTelemarKetingID(data?.telemarketingID);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const param = searchParams.entries();
    for (let key of param) {
      if (key[0] === 'id') {
        getDetailSetupKPI(key[1]);
      }
    }
  }, [products, tags]);

  const columnsSystemKPI = [
    {
      title: VietnameseString.KPI,
      dataIndex: 'name',
      key: 'name',
      render: (value, record, index) => {
        return <>{value}</>;
      },
    },
    {
      title: VietnameseString.targets,
      dataIndex: 'kpi',
      key: 'kpi',
      render: (value, record, index) => {
        if (record.type === 'price') {
          return <>{formatPrice(value)}đ</>;
        } else {
          return <>{value}</>;
        }
      },
    },
    {
      title: VietnameseString.reality,
      dataIndex: 'current',
      key: 'current',
      render: (value, record, index) => {
        let color = 'black';

        if (record.kpi > record.current) {
          color = 'red';
        }
        if (record.kpi < record.current) {
          color = 'green';
        }

        if (record.type === 'price') {
          return <div style={{ color, fontWeight: 'bold' }}>{formatPrice(value)}đ</div>;
        } else {
          return <div style={{ color, fontWeight: 'bold' }}>{value}</div>;
        }
      },
    },
  ];

  const handleRenderOtherKPIItem = (title, type, arrSelected) => {
    return (
      <Row>
        <Col span={4}>
          <span style={{ fontWeight: 'bold' }}>{title}</span>
        </Col>
        <Col span={20}>
          {arrSelected.map((select, index) => {
            if (select !== undefined) {
              return (
                <Row key={index} gutter={[16, 16]} style={{ marginBottom: '10px' }}>
                  <Col span={10}>
                    {type === 'tag' ? (
                      <TagCustom tagName={select.name} tagColor={select.color} />
                    ) : (
                      <td>{select.name}</td>
                    )}
                  </Col>
                  <Col span={10}>{type === 'tag' ? select.kpi : `${formatPrice(select.kpi)}đ`}</Col>
                  <Col span={4}>{type === 'tag' ? select.current : `${formatPrice(select.current)}đ`}</Col>
                </Row>
              );
            }
          })}
        </Col>
      </Row>
    );
  };

  const columnsTableOtherKPI = [
    {
      title: VietnameseString.KPI,
      dataIndex: 'name',
      key: 'name',
      onCell: (record, index) => {
        if (index === 0) {
          return {
            rowSpan: tagsSelected.length,
          };
        }
        if (index > 0 && index < tagsSelected.length) {
          return {
            rowSpan: 0,
          };
        }

        if (index === tagsSelected.length) {
          return {
            rowSpan: productsSelected.length,
          };
        }

        if (index > tagsSelected.length) {
          return {
            rowSpan: 0,
          };
        }
      },
      render: (value, record, index) => {
        if (record.type === 'tag') return <>{VietnameseString.classify}</>;
        if (record.type === 'product') return <>{VietnameseString.product}</>;
      },
    },
    {
      title: VietnameseString.targets_name,
      dataIndex: 'name',
      key: 'name',
      render: (value, record, index) => {
        if (record.type === 'tag')
          return (
            <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
              <TagCustom tagName={record.name} tagColor={record.color} />
            </div>
          );
        if (record.type === 'product') return <>{value}</>;
      },
    },
    {
      title: VietnameseString.targets,
      dataIndex: 'kpi',
      key: 'kpi',
      render: (value, record, index) => {
        if (record.type === 'tag') return <>{value}</>;
        if (record.type === 'product') return <>{formatPrice(value)}đ</>;
      },
    },
    {
      title: VietnameseString.reality,
      dataIndex: 'current',
      key: 'current',
      render: (value, record, index) => {
        let color = 'black';

        if (record.kpi > record.current) {
          color = 'red';
        }
        if (record.kpi < record.current) {
          color = 'green';
        }
        if (record.type === 'tag') return <div style={{ color, fontWeight: 'bold' }}>{value}</div>;
        if (record.type === 'product') return <div style={{ color, fontWeight: 'bold' }}>{formatPrice(value)}đ</div>;
      },
    },
  ];

  return (
    <div className="content-wrapper">
      <BreadcrumbAntStyle>
        <Breadcrumb.Item>
          <a onClick={() => history.goBack()}>{VietnameseString.KPI}</a>
        </Breadcrumb.Item>

        <Breadcrumb.Item>{VietnameseString.detail_setup_KPI}</Breadcrumb.Item>
      </BreadcrumbAntStyle>

      <Spin spinning={loading}>
        <Wrapper>
          <Row style={{ marginBottom: '10px' }} align="middle" justify="space-between">
            <Col>
              <h2 style={{ fontWeight: 'bold', color: primaryColor }}>{VietnameseString.detail_setup_KPI}</h2>
            </Col>

            <Col>
              <ButtonSystemStyle
                bgButtonColor={greenColor}
                bgButtonColorHover={greenColorHover}
                onClick={() => {
                  if (idSetupKPI !== undefined) {
                    history.push({
                      pathname: ROUTER.EDIT_KPI_SETUP,
                      search: `id=${idSetupKPI}`,
                    });
                  }
                }}
              >
                {VietnameseString.edit_setup_KPI}
              </ButtonSystemStyle>
            </Col>
          </Row>

          <Row style={{ marginBottom: '10px' }} align="middle">
            <Col xl={4} xs={12}>
              {VietnameseString.telemarketing}
            </Col>

            <Col xl={8} xs={12}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  {telemarketingDetail?.full_name && (
                    <Avatar
                      src={telemarketingDetail.profile_image && telemarketingDetail.profile_image}
                      style={{
                        backgroundColor: randomColor(
                          telemarketingDetail?.full_name
                            .trim()
                            .split(' ')
                            [telemarketingDetail?.full_name.trim().split(' ').length - 1].charAt(0)
                            ?.toUpperCase()
                        ),
                        marginRight: '5px',
                      }}
                      size="small"
                    >
                      {telemarketingDetail?.full_name
                        .trim()
                        .split(' ')
                        [telemarketingDetail?.full_name.trim().split(' ').length - 1].charAt(0)
                        ?.toUpperCase()}
                    </Avatar>
                  )}
                </div>

                <div>
                  <span>{telemarketingDetail?.full_name ? telemarketingDetail?.full_name : '--'}</span>
                </div>
              </div>
            </Col>
          </Row>

          <Row style={{ marginBottom: '10px' }} align="middle">
            <Col xl={4} xs={12}>
              {VietnameseString.month_of_application}
            </Col>

            <Col xl={8} xs={12}>
              <DatePicker
                allowClear={false}
                style={{ width: '100%' }}
                format={'MM/YYYY'}
                locale={locale}
                value={detailSetupKPI.effectiveTime !== null && moment(detailSetupKPI.effectiveTime)}
                onChange={async (date, dateString) => {
                  try {
                    const result = await requestGetDetailSetupKPIFromDate(
                      telemarKetingID,
                      moment(date).startOf('month').format('YYYY-MM-DD')
                    );
                    setSearchParamsInUrlVer2({ id: result.data.id });
                    getDetailSetupKPI(result.data.id);
                  } catch (error) {
                    notifyFail('Tháng được chọn chưa có thiết lập KPI');
                    setSearchParamsInUrlVer2({ id: idSetupKPI });
                  } finally {
                  }
                }}
                picker="month"
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: '10px' }} align="middle">
            <Col xl={4} xs={12}>
              {VietnameseString.working_days_of_the_month}
            </Col>

            <Col xl={8} xs={12}>
              {detailSetupKPI.workDay}
            </Col>
          </Row>

          <Row gutter={[32, 32]} style={{ marginBottom: '10px' }}>
            <Col xxl={10} xl={12} sm={24} xs={24}>
              <Row style={{ marginBottom: '10px' }} align="middle">
                <Col span={24}>
                  <h5 style={{ fontWeight: 'bold', color: primaryColor }}>{VietnameseString.system_KPI}</h5>
                </Col>
              </Row>

              <Row align="middle">
                <Col span={24}>
                  <Table
                    bordered={true}
                    columns={columnsSystemKPI}
                    scroll={{ x: 'max-content' }}
                    dataSource={systemKPI
                      .filter((s) => s.active === STATUS.active)
                      .map((sysKPI) => ({
                        name: sysKPI.name,
                        kpi: detailSetupKPI[sysKPI.key],
                        current: detailCurrentKPI[sysKPI.key],
                        type: sysKPI.type,
                      }))}
                    pagination={false}
                  />
                </Col>
              </Row>
            </Col>

            <Col xxl={14} xl={12} sm={24} xs={24}>
              <Row style={{ marginTop: '10px' }} align="middle">
                <Col>
                  <h5 style={{ fontWeight: 'bold', color: primaryColor }}>{VietnameseString.other_KPI}</h5>
                </Col>
              </Row>

              <Row>
                {/* <Col span={24}>
                  <Row style={{ fontWeight: 'bold' }}>
                    <Col span={4}>
                      <span style={{ display: 'inline-block', padding: '16px' }}>{VietnameseString.KPI}</span>
                    </Col>
                    <Col span={8}>
                      <span style={{ display: 'inline-block', padding: '16px' }}>{VietnameseString.targets_name}</span>
                    </Col>
                    <Col span={8}>
                      <span style={{ display: 'inline-block', padding: '16px' }}>{VietnameseString.targets}</span>
                    </Col>
                    <Col span={4}>
                      <span style={{ display: 'inline-block', padding: '16px' }}>{VietnameseString.reality}</span>
                    </Col>
                  </Row>
                </Col>

                <Col gutter={[16, 16]} span={24} style={{ marginBottom: '10px' }}>
                  {handleRenderOtherKPIItem(VietnameseString.classify, 'tag', tagsSelected)}
                  <div style={{ marginBottom: '20px' }}></div>
                  {handleRenderOtherKPIItem(VietnameseString.product, 'product', productsSelected)}
                </Col> */}

                <Col span={24}>
                  <TableAntStyle
                    bordered={true}
                    columns={columnsTableOtherKPI}
                    scroll={{ x: 'max-content' }}
                    dataSource={[
                      ...tagsSelected.map((tag) => ({ ...tag, type: 'tag' })),
                      ...productsSelected.map((product) => ({ ...product, type: 'product' })),
                    ]}
                    pagination={false}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Wrapper>
      </Spin>
    </div>
  );
}

export default DetailKPI;
