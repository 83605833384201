import React, { Component } from 'react';
import { requestJobDetail, changeActive, requestDeleteJob, requestJobDetailSimple } from '@constants/Api';
import { withRouter, Link } from 'react-router-dom';
import '@styles/RegisterRequest.css';
import { Row, Col, FormControl, Button, Nav, Table, ProgressBar, Modal } from 'react-bootstrap';
import { ROUTER, STRING, JOB_STATUS } from '@constants/Constant';
import reactotron from 'reactotron-react-js';
import { toDateString } from '@utils/ToDateString';
import { Empty } from 'antd';
import moment from 'moment';
import { Switch, Progress } from 'antd';
import { getItemFromId } from '@utils/getItemFromId';
import { notifyFail, notifySuccess } from '@utils/notify';
import Loading from '@src/components/Loading';

class ResultChild extends Component {
  constructor(props) {
    super();
    this.state = {
      item: {},
      modalDelete: false,
      modal: false,
      is_active: false,
      modalEditField: {
        job_id: '',
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    const { id } = this.props;
    this.getData(id);
  }

  async getData(id) {
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const res = await requestJobDetailSimple({ id: id });
      reactotron.log(res);
      this.setState({
        ...this.state,
        item: res.data,
        isLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async changeActive() {
    const { is_active } = this.state;
    const { id } = this.props;
    try {
      await changeActive({
        isActive: is_active ? 2 : 1,
        job_id: id,
      });
      this.setState({
        modal: false,
      });
      this.getData(id);
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  handleModal = (bool, is_active) => {
    this.setState({
      ...this.state,
      modal: bool,
      is_active: is_active == 1 ? true : false,
    });
  };

  async requestDeleteJob() {
    const { job_id } = this.state.modalEditField;
    const { item } = this.state;
    const payload = {
      job_id: job_id || '',
    };

    try {
      await requestDeleteJob(payload);
      this.setState({ modalDelete: false });
      this.props.history.push(ROUTER.JOB);
      notifySuccess('Xóa thành công');
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  setShowModal = (modal, bool, data) => {
    this.setState({
      [modal]: bool,
    });
    if (data) {
      this.setState({
        modalEditField: {
          job_id: data?.job_id || '',
        },
      });
    }
  };

  deleteModal = () => {
    const { modalDelete, modalEditField } = this.state;
    return (
      <Modal
        show={modalDelete}
        onHide={() => this.setShowModal('modalDelete', false)}
        dialogClassName="modal-90w"
        centered
        scrollable
      >
        <Modal.Header closeButton className="text-white bg-primary" style={{ color: 'white' }}>
          <h5 className="text-light pb-0">Bạn chắc chắn muốn xóa?</h5>
        </Modal.Header>
        <Modal.Footer className="justify-content-end">
          <Button variant="success" className="text-light" onClick={() => this.requestDeleteJob()}>
            Có
          </Button>
          <Button variant="danger" className="text-light" onClick={() => this.setShowModal('modalDelete', false)}>
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderModal = () => {
    const { modal, is_active } = this.state;
    const { id } = this.props;
    return (
      <Modal show={modal} onHide={() => this.handleModal(false, '')} dialogClassName="modal-90w" centered scrollable>
        <Modal.Header closeButton className="text-white bg-primary">
          <h5 className="text-light pb-0">{is_active == false ? 'Xác nhận hoạt động' : 'Ngưng hoạt động'}</h5>
        </Modal.Header>
        <Modal.Footer className="justify-content-end">
          <Button variant="success" className="text-light btn-oversize" onClick={() => this.changeActive()}>
            Có
          </Button>
          <Button variant="danger" className="text-light btn-oversize" onClick={() => this.handleModal(false, '')}>
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderTitle() {
    const { item } = this.state;
    return (
      <>
        <Row className="pt-4 job-title">
          <Col md={8} className="mb-4 p-0">
            {/* <h2 className="d-inline">Công việc</h2>
            <i className="fas fa-grip-lines-vertical px-4"></i> */}
            <h3 className="d-inline">{item.name || '--'}</h3>
          </Col>

          <Col md={4} className="text-center text-md-right p-0">
            {item?.isDelete == 1 ? (
              <Link>
                <Button
                  variant="danger"
                  className="text-light"
                  style={{ marginBottom: 10 }}
                  onClick={() => {
                    this.setShowModal('modalDelete', true, item);
                  }}
                >
                  Xóa
                </Button>
              </Link>
            ) : (
              ''
            )}
            <Link to={ROUTER.JOB_RESULT + `/${item.parent_id}`}>
              <Button variant="success" className="text-light" style={{ marginBottom: 10 }}>
                Công việc chung
              </Button>
            </Link>
          </Col>
        </Row>
      </>
    );
  }

  renderBody() {
    const { item } = this.state;
    return (
      <Row>
        <Col md={12} className="p-0">
          <div className="w-100">
            <Row className="">
              <Col md={12} className="content p-0">
                {/* <Row className=" text-center text-md-left pb-2">
                  <Col md={8}>
                    <span
                      style={{
                        padding: '0px',
                        fontWeight: 'bolder',
                        fontSize: '22px',
                      }}
                    >
                      Thông tin công việc
                    </span>
                  </Col>
                </Row> */}
                <Row className="lower">
                  <Col md={5}>
                    <Row>
                      <Col md={6} sm={6} className="px-md-0 ">
                        <Row>
                          <Col
                            sm={3}
                            className="d-flex align-items-center justify-content-end justify-content-md-center px-0"
                          >
                            <i className="far fa-users tele-icon"></i>
                          </Col>
                          <Col sm={8} className="px-0">
                            <Row>
                              <Col md={12} sm={12} className="px-0 text-center text-md-left">
                                Khách hàng
                              </Col>
                              <Col md={12} sm={12} className="text-center text-lg-left px-0">
                                <b>{item?.countCustomer || '0'}</b>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={6} sm={6} className="px-4 px-md-0 ">
                        <Row>
                          <Col
                            sm={4}
                            md={2}
                            className="d-flex align-items-center justify-content-end justify-content-md-center px-0"
                          >
                            <i className="far fa-check-circle tele-icon"></i>
                          </Col>
                          <Col sm={5} md={8} className="px-0">
                            <Row>
                              <Col md={12} sm={12} className="px-0">
                                Cuộc gọi thành công
                              </Col>
                              <Col md={12} sm={12} className="text-center text-lg-left px-0">
                                <b>{item?.countCalled || '0'}</b>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={6} className="p-0">
                        <Row>
                          <Col
                            sm={3}
                            className="d-flex align-items-center justify-content-end justify-content-md-center px-0"
                          >
                            <i className="fas fa-user-headset tele-icon"></i>
                          </Col>
                          <Col sm={8} className="px-0">
                            <Row>
                              <Col md={12} sm={12} className="px-0">
                                Số sale
                              </Col>
                              <Col md={12} sm={12} className="text-center text-lg-left px-0">
                                <b>{item?.countTelesale || '0'}</b>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={6} sm={6} className="px-4 px-md-0 ">
                        <Row>
                          <Col
                            sm={2}
                            className="d-flex align-items-center justify-content-end justify-content-md-center px-0"
                          >
                            <i class="far fa-check-square tele-icon"></i>
                          </Col>
                          <Col sm={8} className="px-0">
                            <Row>
                              <Col md={12} sm={12} className="px-0 text-center text-md-left">
                                Đánh giá
                              </Col>
                              <Col md={12} sm={12} className="text-center text-lg-left px-0">
                                <b>{item?.countReviewed || '0'}</b>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={2}>
                    <Row>
                      <Col md={12} sm={4} className="text-center offset-2 offset-md-0 px-0">
                        <span>Hoạt động</span>
                      </Col>
                      <Col md={12} sm={5} className="waiting text-center">
                        <Switch checked={item.is_active == 1} onClick={() => this.handleModal(true, item.is_active)} />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={2} sm={6}>
                    <Row className="center-row">
                      <span>Trạng thái</span>
                    </Row>
                    <Row className="center-row justify-content-center">
                      <span className="text-blue text-uppercase" style={{ fontWeight: '600' }}>
                        {(getItemFromId(JOB_STATUS, item.is_active) &&
                          getItemFromId(JOB_STATUS, item.is_active).name) ||
                          '---'}
                      </span>
                    </Row>
                  </Col>
                  <Col md={3} sm={6} className="center-row pr-0">
                    <Row className="center-row">
                      <span>Tiến độ công việc</span>
                    </Row>
                    <Row className="center-row py-1">
                      <Col md={12} className="text-center">
                        {item.countCustomer != 0 && item.countCustomer != null ? (
                          <Progress percent={parseFloat((item.countCalled / item.countCustomer) * 100).toFixed(1)} />
                        ) : (
                          <Progress />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  }

  render() {
    const { isLoading } = this.state;
    return (
      <>
        {isLoading ? <Loading /> : ''}
        <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px' }}>
          <Col md className="bg bg-2">
            {this.renderModal()}
            {this.renderTitle()}
            {this.deleteModal()}
            {this.renderBody()}
          </Col>
        </Row>
        {/* {this.renderModal()}
        {this.renderTitle()}
        {this.renderBody()} */}
      </>
    );
  }
}

export default withRouter(ResultChild);
