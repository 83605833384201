import { getPhoneNumber } from '@actions/';
import CallStatusBar from '@screens/Sale/Call/components/CallStatusBar';
import NumberPad from '@screens/Sale/Call/NumberPad';
import { notifyFail } from '@utils/notify';
import { Row } from 'antd';
import 'antd/dist/antd.css';
import React, { memo, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CALLING_CODE } from './callProviders/CallConstant';
import useCallStatus from './callProviders/useCallStatus';
import {
  checkAndShowError,
  errorDefine,
  isCallEnded,
  isEndWithError,
  isUnhandledException,
} from '@components/callProviders/CallHelper';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const ModalCall = props => {
  const dispatch = useDispatch();
  const { disabled, handleCancel } = props;
  const [customerDetail, setCustomerDetail] = useState({});
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const { call, masked } = props.callStatus;
  const draggleRef = useRef(null);
  const onStartDrag = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();

    if (!targetRect) {
      return;
    }

    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };
  useEffect(() => {
    if(checkAndShowError(call.status, call.data?.sipReason)){
      return 
    }
    if (isUnhandledException(call.status)) {
      notifyFail(`Vui lòng thử lại - (${call.status})`);
    }
    if (isEndWithError(call.status)) {
      // sau này sẽ bắn ra thông tin lỗi cụ thể dựa trên code
      notifyFail(`Vui lòng thử lại - (${call.status})`);
    }
  }, [call]);

  return (
    <>
      <div
        style={{
          position: 'fixed',
          // left: '20vw',
          bottom: '0vh',
          zIndex: 999,
          width: 0,
        }}
      >
        <Draggable disabled={disabled} bounds={bounds} onStart={onStartDrag}>
          <div
            style={{
              padding: 2,
              border: '2px solid black',
              borderRadius: 20,
              backgroundColor: '#bdbdbd',
              width: 255,
              height: 500,
            }}
          >
            <div
              ref={draggleRef}
              style={{
                border: '5px solid black',
                borderRadius: 20,
                backgroundColor: 'white',
                height: 492,
              }}
            >
              <Row
                justify="space-between"
                style={{
                  backgroundColor: 'white',
                  padding: '0 5px 0 5px',
                  borderRadius: '20px 20px 0 0',
                }}
              >
                <b
                  style={{
                    fontSize: 10,
                    width: '20%',
                    margin: '0 0 0 5px',
                    color: '#ef8737',
                  }}
                  onClick={() => {}}
                >
                  EZSale
                </b>
                <div
                  style={{
                    height: 8,
                    width: 100,
                    backgroundColor: 'black',
                    borderRadius: '0 0 20px 20px',
                  }}
                ></div>

                <b
                  style={{
                    cursor: 'pointer',
                    width: '20%',
                    textAlign: 'end',
                    fontSize: 10,
                    margin: '0 5px 0 0px',
                  }}
                  onClick={() => {
                    if (isCallEnded(call.status)) {
                      handleCancel();
                      dispatch(getPhoneNumber({ number: '' }));
                    }
                  }}
                >
                  X
                </b>
              </Row>
              <CallStatusBar callStatus={props.callStatus} />

              <NumberPad callingStatus={call} masked={masked} />
            </div>
          </div>
        </Draggable>
      </div>
    </>
  );
};

export default memo(ModalCall);
