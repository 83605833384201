import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import '@styles/Company.css';
import { Row, Col } from 'react-bootstrap';
import {
  getCallHistory,
  getJobFilter,
  getListCallStatus,
  getTelesaleList,
  getListKeyword,
} from '@src/redux/actions';
import {
  Empty,
  Button,
  Row as RowAnt,
  Col as ColAnt,
  Avatar,
  Spin,
  Skeleton,
  Badge,
} from 'antd';
import {
  ROUTER,
  STRING,
  CALL_TYPE,
  BASE_URL_IMAGE,
  CALL_STATUS_STRING,
  BOX_SHADOW,
} from '@constants/Constant';
import Pagination from 'react-js-pagination';
import moment from 'moment';
import {
  getAmountOfCall,
  requestFullListTag,
  requestListDepartment,
  requestListReviewer,
  requestListTelesale,
} from '@constants/Api';
import styled from 'styled-components';
import { AudioStyle } from '@styles/Style';
import ModalReviewCall from './components/ModalReviewCall';
import CallReviewDetail from './components/CallReviewDetail';
import {
  setSearchParamsInUrlVer2,
  formatTime,
  randomColor,
  getFirstLetter,
  getSearchParamsInUrl,
} from '@constants/funcHelper';
import Filter from './components/Filter';
import { useCallback } from 'react';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { notifySuccess } from '@utils/notify';
import ButtonModules from 'src/modules/Button';
import IconAntd from 'src/modules/IconAntd';
import EZLoadingFull from 'src/modules/loading/EZ.Loading.Full';

const EditOutlined = () => (
  <svg width="2em" height="2em" fill="#ef8737" viewBox="0 0 1024 1024">
    <path d="M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 000-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 009.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z" />
  </svg>
);
const ButtonReview = styled(Button)`
  &:hover {
    color: #ef8737;
  }
`;
const CardStyle = styled.div`
  padding: 10px 20px;
  border-radius: 8px;
  background-color: white;
  box-shadow: ${BOX_SHADOW};
`;
const locationSearch = window.location.search;
const dataParams = new URLSearchParams(locationSearch);
const pageParams = dataParams.get('page') || 1;
export default function CallScreen() {
  const callStatus = useSelector(state => state.callStatusReducer.data);
  const keywordList = useSelector(state => state.keywordReducer.data);
  const callHistory = useSelector(state => state.callHistoryReducer.data);
  const pagingCallHistory = useSelector(
    state => state.callHistoryReducer?.paging
  );
  const isLoadingCallHistory = useSelector(
    state => state.callHistoryReducer?.isLoading
  );
  const [params, setParams] = useState({
    is_check_kpi: 0,
    page: pageParams,
    limit: 12,
    search: '',
    tag_ids: [],
    department_id: [],
    telesale_id: [],
    job_parent_id: [],
    job_id: [],
    call_reviewed_created_by: [],
    keyword: '',
    call_reviewed: '',
    call_type: '',
    call_status: [],
    fromDate: '',
    toDate: '',
    total: 0,
    query: '',
    job_form_question_id: [],
    job_form_answers_id: [],
  });

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [listDepartment, setListDepartment] = useState();
  const [listTags, setListTags] = useState();
  const [listReviewer, setListReviewer] = useState();
  const [listTelesales, setListTelesales] = useState();
  const [defaultData, setDefaultData] = useState();
  const [totalItem, setTotalItem] = useState(0);
  const [limitPage, setLimitPage] = useState();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    getFullListTag();
    getListReviewer();
    dispatch(getJobFilter());
    dispatch(getListCallStatus());
    dispatch(getTelesaleList());
    dispatch(getListKeyword());
    getListDepartment();
    getListTelesale();
    if (location.state && location.state.saleId) {
      let state = {};
      history.replace({ ...history.location, state });
    }
    const searchParamsInUrl = getSearchParamsInUrl();
    setParams({ ...params, ...searchParamsInUrl });
  }, []);

  const getData = params => {
    dispatch(getCallHistory(params));
  };

  const debounceGetDataOppo = useCallback(_.debounce(getData, 500), [params]);

  useEffect(() => {
    if (location.state && location.state.saleId) {
      setParams(
        prevState => ({
          ...prevState,
          telesale_id: location.state.saleId,
          [STRING.fromDate]: location.state.date
            ? moment(location.state.date)._d
            : moment()._d,
          [STRING.toDate]: location.state.date
            ? moment(location.state.date)._d
            : moment()._d,
          is_check_kpi: 1,
        }),
        () => {
          getData({
            page: 1,
            telesale_id: location.state.saleId,
            fromDate: location.state.date || moment().format('YYYY-MM-DD'),
            toDate: location.state.date || moment().format('YYYY-MM-DD'),
            is_check_kpi: 1,
          });
        }
      );
    } else {
      debounceGetDataOppo(params);
    }
  }, [params]);

  useEffect(() => {
    if (pagingCallHistory) {
      setTotalItem(pagingCallHistory?.totalItemCount);
      setLimitPage(pagingCallHistory?.limit);
    }
  }, [pagingCallHistory, isLoadingCallHistory]);

  const handleCloseModal = () => {
    setIsVisibleModal(false);
  };

  const getListDepartment = async telesale_id => {
    const res = await requestListDepartment(telesale_id || '');
    setListDepartment(res.data);
  };

  const getListTelesale = async department_id => {
    const res = await requestListTelesale(department_id || '');
    setListTelesales(res.data);
  };

  const getFullListTag = async () => {
    const res = await requestFullListTag();
    setListTags(res.data);
  };

  const getListReviewer = async () => {
    const res = await requestListReviewer();
    setListReviewer(res.data);
  };

  const searchBarChange = paramSearch => {
    if (typeof paramSearch === 'object') {
      setParams(prevState => ({
        ...prevState,
        ...paramSearch,
        page: 1,
      }));
    }
    if (Object.keys(paramSearch).length < 2) {
      mixpanel.track(
        `Filter call history ${Object.keys(paramSearch)} : ${
          paramSearch[Object.keys(paramSearch)[0]]
        }`,
        {
          params,
        }
      );
    } else {
      mixpanel.track(
        `Filter call history fromDate: ${
          paramSearch[Object.keys(paramSearch)[0]]
        }, toDate: ${paramSearch[Object.keys(paramSearch)[0]]}`,
        {
          params,
        }
      );
    }
  };

  const getExportCallList = async () => {
    try {
      notifySuccess('Vui lòng đợi xử lí trong ít phút');

      const res = await getAmountOfCall(params);
    } catch (error) {
      console.log(error);
    }
  };

  const renderPageHeader = () => {
    return (
      <Row>
        <Col md={12} sm={12} style={{ padding: '0 10px' }}>
          <div
            style={{
              marginBottom: 5,
              backgroundColor: 'white',
              boxShadow: BOX_SHADOW,
              padding: '10px 15px',
              borderRadius: 8,
            }}
          >
            <RowAnt
              justify="space-between"
              style={{ marginBottom: '10px' }}
              align="middle"
            >
              <Col style={{ display: 'flex' }}>
                <h2
                  className="text-left"
                  style={{ fontWeight: 'bold', fontSize: '18px' }}
                >
                  Cuộc gọi
                </h2>
                <span style={{ margin: '3px 0 0 5px' }}>
                  ({totalItem} kết quả)
                </span>
              </Col>
              <ButtonModules
                color="primary"
                onClick={() => {
                  mixpanel.track(`Export excel call history`);
                  getExportCallList();
                }}
              >
                Xuất excel
              </ButtonModules>
            </RowAnt>

            <Filter
              searchBarChange={searchBarChange}
              params={params}
              listTags={listTags}
              listDepartment={listDepartment}
              getListDepartment={getListDepartment}
              listTelesales={listTelesales}
              getListTelesale={getListTelesale}
              callStatus={callStatus}
              keywordList={keywordList}
              listReviewer={listReviewer}
            />
          </div>
        </Col>
      </Row>
    );
  };

  const renderBlockHeader = (call, index) => {
    return (
      <RowAnt justify="space-between">
        <Link
          key={index}
          to={ROUTER.CALL_DETAIL + '/' + call.id}
          style={{ color: 'black' }}
          onClick={() => {
            mixpanel.track(
              `View call detail ${call.customer_phone}, ${
                call.customer_name || 'Chưa cập nhật'
              } (id: ${call.id})`
            );
          }}
        >
          <RowAnt align="middle">
            <Avatar
              src={`${BASE_URL_IMAGE}upload/image/ff85643f62770d8622dc4d40ea5e84ea.png`}
              size="small"
            />
            <Row style={{ padding: '3px 0 0 8px' }}>
              <div style={{ fontSize: 18, fontWeight: 700, color: '#ef8737' }}>
                {CALL_TYPE.getCallType(call.call_type)?.name || 'Chưa cập nhật'}
              </div>
              <div style={{ padding: '3px 0 0 3px' }}>
                ({formatTime(call?.start_call_time)})
              </div>
            </Row>
          </RowAnt>
        </Link>
        <ButtonModules
          icon={<IconAntd icon="EditOutlined" />}
          color="primary"
          border
          onClick={() => {
            setParams(prevState => ({
              ...prevState,
              call_id: call.id,
            }));
            setIsVisibleModal(true);
            setDefaultData(call);
          }}
        >
          <b>Đánh giá</b>
        </ButtonModules>
      </RowAnt>
    );
  };

  const renderSaleInfo = (call, index) => {
    const saleName = call.sale_name ? call.sale_name?.trim().split(' ') : 'N';
    return (
      <Col>
        <p className="text-gray m-0" style={{ fontSize: '14px' }}>
          Sale
        </p>
        <RowAnt>
          <Link
            key={index}
            to={ROUTER.TELESALE_DETAIL + '/' + call.sale_id}
            style={{ color: 'black' }}
          >
            {call.sale_profile_image ? (
              <>
                <Avatar size={24} src={call.sale_profile_image} />
                &nbsp;
              </>
            ) : (
              <Avatar
                style={{
                  backgroundColor: randomColor(getFirstLetter(saleName)),
                  marginRight: '5px',
                }}
                size={24}
              >
                <span id="avaCus">
                  {saleName[saleName.length - 1].charAt(0)?.toUpperCase()}
                </span>
              </Avatar>
            )}
            <b className="m-0">{call.sale_name || 'Chưa cập nhật'}</b>
          </Link>
        </RowAnt>
      </Col>
    );
  };

  const renderCustomerInfo = (call, index) => {
    const customerName = call.customer_name
      ? call.customer_name?.trim().split(' ')
      : 'N';
    return (
      <Col>
        <p className="text-gray m-0" style={{ fontSize: '14px' }}>
          Khách hàng
        </p>
        <RowAnt>
          <Link
            key={index}
            to={ROUTER.CUSTOMER_DETAIL + '/' + call.customer_id}
            style={{ color: 'black' }}
          >
            <Avatar
              style={{
                backgroundColor: randomColor(getFirstLetter(customerName)),
                marginRight: '5px',
              }}
              size={24}
            >
              <span id="avaCus">
                {customerName[customerName.length - 1].charAt(0)?.toUpperCase()}
              </span>
            </Avatar>
            <b className="m-0">{call.customer_name || 'Chưa cập nhật'}</b>
          </Link>
        </RowAnt>
      </Col>
    );
  };

  const renderRecordingInfo = call => {
    return (
      <Col>
        <p className="text-gray m-0" style={{ fontSize: '14px' }}>
          File ghi âm
        </p>
        <div>
          {isLoadingCallHistory && !isVisibleModal ? (
            <Skeleton.Input />
          ) : (
            <AudioStyle
              style={{ width: '100%' }}
              controls
              autoplay
              className="audio-element"
            >
              <source src={call?.content_audio_url}></source>
            </AudioStyle>
          )}
        </div>
      </Col>
    );
  };

  const renderPhoneInfo = call => {
    return (
      <Col>
        <p className="text-gray m-0" style={{ fontSize: '14px' }}>
          Số điện thoại
        </p>
        <b className="m-0">{call.customer_phone || 'Chưa cập nhật'}</b>
      </Col>
    );
  };

  const renderJobInfo = call => {
    return (
      <Col>
        <p className="text-gray m-0" style={{ fontSize: '14px' }}>
          Tên giao việc
        </p>
        <b className="m-0">{call.job_name || 'Chưa cập nhật'}</b>
      </Col>
    );
  };

  const renderStatusInfo = call => {
    return (
      <Col>
        <p className="text-gray m-0" style={{ fontSize: '14px' }}>
          Trạng thái
        </p>
        {call.status_name === CALL_STATUS_STRING.NOT_ANSWER ? (
          <b className={`m-0 ${call.status_name && 'text-danger'}`}>
            {call.status_name || 'Chưa cập nhật'}
          </b>
        ) : call.status_name === CALL_STATUS_STRING.AGREE ? (
          <b className={`m-0 ${call.status_name && 'text-success'}`}>
            {call.status_name || 'Chưa cập nhật'}
          </b>
        ) : (
          <b className={`m-0 ${call.status_name && 'text-warning'}`}>
            {call.status_name || 'Chưa cập nhật'}
          </b>
        )}
      </Col>
    );
  };
  console.log('callHistory', callHistory);
  const renderContentPage = () => {
    return (
      <div style={{ padding: '0 6px' }}>
        {isLoadingCallHistory && !isVisibleModal && <EZLoadingFull />}
        {/* <Spin spinning={isLoadingCallHistory}> */}
        <RowAnt>
          {callHistory?.length > 0
            ? callHistory.map((call, index) => {
                console.log('call', call);
                return (
                  <ColAnt
                    style={{ padding: '6px' }}
                    key={call.id}
                    xs={24}
                    sm={12}
                    lg={8}
                  >
                    <Badge.Ribbon
                      text={
                        params.page === 1
                          ? index + 1
                          : (params.page - 1) * limitPage + (index + 1)
                      }
                      color="volcano"
                    >
                      <CardStyle>
                        <Col>
                          {renderBlockHeader(call, call.id)}
                          <Row className="mt-3">
                            {renderSaleInfo(call, call.id)}
                            {renderCustomerInfo(call, call.id)}
                          </Row>
                          <Row className="mt-3">
                            {renderPhoneInfo(call, call.id)}
                            {renderJobInfo(call, call.id)}
                          </Row>
                          <Row className="mt-3">
                            {renderStatusInfo(call, call.id)}
                            <CallReviewDetail data={call} params={params} />
                          </Row>
                          <Row className="mt-3">
                            {renderRecordingInfo(call, call.id)}
                          </Row>
                        </Col>
                      </CardStyle>
                    </Badge.Ribbon>
                  </ColAnt>
                );
              })
            : !isLoadingCallHistory && (
                <Empty description={<span>Không có dữ liệu</span>} />
              )}
        </RowAnt>
        {/* </Spin> */}
        <ModalReviewCall
          isVisible={isVisibleModal}
          handleCloseModal={handleCloseModal}
          callId={params.call_id}
          data={defaultData}
          getData={getData}
          payload={params}
        />
      </div>
    );
  };

  return (
    <Container className="content-wrapper">
      <div className="content-header" style={{ paddingTop: '10px' }}>
        {renderPageHeader()}

        {renderContentPage()}

        <RowAnt justify="end" style={{ margin: '10px 10px 0 0' }}>
          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            hideDisabled
            activePage={parseInt(params.page)}
            itemsCountPerPage={parseInt(limitPage)}
            totalItemsCount={parseInt(totalItem)}
            pageRangeDisplayed={5}
            hideNavigation
            onChange={page => {
              setParams(prevState => ({
                ...prevState,
                page: page,
              }));
              setSearchParamsInUrlVer2({ page });
              window.scrollTo(0, 0);
            }}
          />
        </RowAnt>
      </div>
    </Container>
  );
}

const Container = styled.div`
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
`;
