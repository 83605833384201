import {
  requestFullCustomerAction,
  requestFullListTag,
  requestFullSaleList,
  requestGetCustomerGroups,
  requestGetDepartment,
  requestSalesByDepartment,
} from '@constants/Api';
import { TELESALE_ENTERPRISE_STATUS } from '@constants/Constant';
import { rmVN, setSearchParamsInUrlVer2 } from '@constants/funcHelper';
import { faSun } from '@fortawesome/fontawesome-free-regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col,
  DatePicker,
  Dropdown,
  Empty,
  Input,
  Menu,
  Row,
  Select,
} from 'antd';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import TagCustom from './TagCustom';

const { Option } = Select;
const { RangePicker } = DatePicker;

Filter.propTypes = {
  params: PropTypes.object,
  setParams: PropTypes.func,
  handleChangeParamSearchCustomers: PropTypes.func,
  listFilterFieldConfigGetAPI: PropTypes.array,
  setVisibleFilterConfiguration: PropTypes.func,
  setVisibleDisplayConfiguration: PropTypes.func,
};

const FontAwesomeIconStyle = styled(FontAwesomeIcon)`
  &:hover {
    /* color: #52c41a; */
    cursor: pointer;
  }
`;

export default function Filter({
  user,
  setVisibleDisplayConfiguration,
  setVisibleFilterConfiguration,
  handleChangeParamSearchCustomers,
  params,
  setParams,
  listFilterFieldConfigGetAPI,
}) {
  const dateFormat = 'YYYY/MM/DD';

  const province = useSelector(state => state.ProvinceReducer.data);

  const [sales, setSales] = useState([]);
  const [tags, setTags] = useState([]);
  const [customerAction, setCustomerAction] = useState([]);
  const [customerGroup, setCustomerGroup] = useState([]);
  const [departments, setDepartments] = useState([]);

  const getFullSales = async () => {
    try {
      const res = await requestFullSaleList({
        type: TELESALE_ENTERPRISE_STATUS.PASS,
      });
      const sales =
        res?.data?.map(sale => ({
          ...sale,
          value: sale.id,
          label: sale.full_name,
        })) || [];
      setSales(sales);
    } catch (error) {}
  };

  const getFullTags = async () => {
    try {
      const res = await requestFullListTag({});
      const options =
        res?.data?.map(tag => ({
          ...tag,
          label: tag.name,
          value: tag.id,
        })) || [];
      setTags(options);
    } catch (err) {}
  };

  const getListAction = async () => {
    try {
      const result = await requestFullCustomerAction();
      setCustomerAction(result.data);
    } catch (error) {}
  };

  const getFullCustomerGroup = async () => {
    try {
      const result = await requestGetCustomerGroups({
        search: '',
      });
      setCustomerGroup(result.data);
    } catch (error) {}
  };

  // const getFullDepartments = async () => {
  //   try {
  //     const result = await requestGetFullDepartment();
  //     setDepartments(result.data);
  //   } catch (error) {
  //     console.log('error: ', error);
  //   }
  // };
  const getDepartments = async (telesaleId = []) => {
    try {
      const result = await requestGetDepartment(telesaleId);
      setDepartments(result.data);
    } catch (error) {}
  };

  const [departmentSelected, setDepartmentSelected] = useState();

  const getSalesByDepartment = async () => {
    try {
      const result = await requestSalesByDepartment({
        department_id: departmentSelected,
      });
      setSales(result.data);
    } catch (error) {}
  };

  useEffect(() => {
    getFullSales();
    getFullTags();
    getListAction();
    getFullCustomerGroup();
    getDepartments();
  }, []);
  useEffect(() => {
    getSalesByDepartment();
    if (!departmentSelected) {
      getFullSales();
    }
  }, [departmentSelected]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={23}>
        <Row gutter={[16, 16]}>
          <Col xxl={8} xl={8} lg={8} sm={12} xs={24}>
            <Input.Search
              placeholder="Khách hàng, số điện thoại, email, công việc, nội dung giao việc"
              allowClear
              onSearch={searchKey =>
                handleChangeParamSearchCustomers('search', searchKey)
              }
              onChange={e =>
                handleChangeParamSearchCustomers('search', e.target.value)
              }
              style={{ width: '100%' }}
              value={params?.search}
            />
          </Col>

          {/* <Col xxl={8} xl={8} lg={8} sm={12} xs={24}>
            <Input.Search
              placeholder="Mã khách hàng"
              allowClear
              onSearch={searchKey =>
                handleChangeParamSearchCustomers('id', searchKey?.trim())
              }
              onChange={e =>
                handleChangeParamSearchCustomers('id', e?.target?.value?.trim())
              }
              style={{ width: '100%' }}
              value={params?.id}
            />
          </Col> */}

          <Col xxl={4} xl={8} lg={8} sm={12} xs={24}>
            <Input.Search
              placeholder="Ghi chú nội dung cuộc gọi"
              allowClear
              onSearch={searchKey =>
                handleChangeParamSearchCustomers('call_note', searchKey)
              }
              onChange={e =>
                handleChangeParamSearchCustomers('call_note', e.target.value)
              }
              style={{ width: '100%' }}
              value={params?.call_note}
            />
          </Col>

          <Col xxl={4} xl={8} lg={8} sm={12} xs={24}>
            <Select
              style={{ width: '100%' }}
              mode="multiple"
              optionFilterProp="children"
              filterOption={(input, option) => {
                return rmVN(option.children).indexOf(rmVN(input)) >= 0;
              }}
              placeholder="Nhóm khách hàng"
              allowClear
              maxTagCount={1}
              value={params?.group_id}
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>Không có dữ liệu!</span>}
                />
              }
              onChange={value => {
                handleChangeParamSearchCustomers('group_id', value);
              }}
            >
              {customerGroup.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col xxl={4} xl={8} lg={8} sm={12} xs={24}>
            <Select
              style={{ width: '100%' }}
              mode="multiple"
              optionFilterProp="children"
              filterOption={(input, option) => {
                return (
                  rmVN(option.children.props.tagName).indexOf(rmVN(input)) >= 0
                );
              }}
              placeholder="Phân loại"
              allowClear
              maxTagCount={1}
              value={params?.tag_ids}
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>Không có dữ liệu!</span>}
                />
              }
              onChange={value =>
                handleChangeParamSearchCustomers('tag_ids', value)
              }
            >
              {tags.map((item, index) => (
                <Option key={index} value={item.value}>
                  <TagCustom tagName={item.label} tagColor={item.color} />
                </Option>
              ))}
            </Select>
          </Col>

          <Col xxl={4} xl={8} lg={8} sm={12} xs={24}>
            <RangePicker
              style={{ width: '100%' }}
              placeholder={['Từ ngày', 'Đến ngày']}
              format={dateFormat}
              value={[
                params?.from_date ? moment(params?.from_date, dateFormat) : '',
                params?.to_date ? moment(params?.to_date, dateFormat) : '',
              ]}
              onChange={(date, dateString) => {
                if (date !== null) {
                  setSearchParamsInUrlVer2({
                    from_date: moment(date[0]).format(dateFormat),
                    to_date: moment(date[1]).format(dateFormat),
                    page: 1,
                  });
                  setParams({
                    ...params,
                    from_date: moment(date[0]).format(dateFormat),
                    to_date: moment(date[1]).format(dateFormat),
                    page: 1,
                  });
                  mixpanel.track(
                    `filter from_date: ${moment(date[0]).format(
                      dateFormat
                    )}/ to_date: ${moment(date[1]).format(dateFormat)}`
                  );
                } else {
                  mixpanel.track(`filter from_date: ''/ to_date: ''`);
                  setParams({
                    ...params,
                    from_date: '',
                    to_date: '',
                  });
                }
              }}
            />
          </Col>

          <Col xxl={4} xl={8} lg={8} sm={12} xs={24}>
            <Select
              style={{ width: '100%' }}
              mode="multiple"
              optionFilterProp="children"
              filterOption={(input, option) => {
                return rmVN(option.children).indexOf(rmVN(input)) >= 0;
              }}
              placeholder="Loại tương tác"
              allowClear
              maxTagCount={1}
              value={params?.df_customer_action_id}
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>Không có dữ liệu!</span>}
                />
              }
              onChange={value =>
                handleChangeParamSearchCustomers('df_customer_action_id', value)
              }
            >
              {customerAction.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col xxl={4} xl={8} lg={8} sm={12} xs={24}>
            <Select
              style={{ width: '100%' }}
              mode="multiple"
              optionFilterProp="children"
              filterOption={(input, option) => {
                return rmVN(option.children).indexOf(rmVN(input)) >= 0;
              }}
              placeholder="Trạng thái cơ hội"
              allowClear
              maxTagCount={1}
              value={params?.opportunity_action_id}
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>Không có dữ liệu!</span>}
                />
              }
              onChange={value =>
                handleChangeParamSearchCustomers('opportunity_action_id', value)
              }
            >
              {/* {customerAction.map((item, index) => {
                if (item.id <= 3)
                  return (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  );
              })} */}
              <Option value={1}>Khách hàng tiềm năng</Option>
              <Option value={3}>Khách hàng chốt hợp đồng</Option>
              <Option value={4}>Khách hàng từ chối</Option>
            </Select>
          </Col>

          <Col xxl={4} xl={8} lg={8} sm={12} xs={24}>
            <Select
              style={{ width: '100%' }}
              mode="multiple"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                return rmVN(option.children).indexOf(rmVN(input)) >= 0;
              }}
              placeholder="Thành phố"
              allowClear
              maxTagCount={1}
              value={params?.province_ids}
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>Không có dữ liệu!</span>}
                />
              }
              onChange={value =>
                handleChangeParamSearchCustomers('province_ids', value)
              }
            >
              {province.length &&
                province?.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Col>

          {/* Bộ lọc động */}

          {listFilterFieldConfigGetAPI.map((field, index) => {
            if (field.filterable) {
              if (field.data_type === 'options') {
                return (
                  <Col key={index} xxl={4} xl={8} lg={8} sm={12} xs={24}>
                    <Select
                      style={{ width: '100%' }}
                      mode="multiple"
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        try {
                          return (
                            rmVN(option.children).indexOf(rmVN(input)) >= 0
                          );
                        } catch (error) {}
                      }}
                      placeholder={field.label}
                      allowClear
                      maxTagCount={1}
                      value={params?.[field.code]}
                      notFoundContent={
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={<span>Không có dữ liệu!</span>}
                        />
                      }
                      onChange={value =>
                        handleChangeParamSearchCustomers(field.code, value)
                      }
                    >
                      {field.customer_data_field_sources.map((item, index) => (
                        <Option key={index} value={item.value}>
                          {item.value}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                );
              }
              if (field.data_type === 'text' || field.data_type === 'number') {
                return (
                  <Col key={index} xxl={4} xl={8} lg={8} sm={12} xs={24}>
                    <Input.Search
                      placeholder={field.label}
                      allowClear
                      onSearch={searchKey =>
                        handleChangeParamSearchCustomers(field.code, searchKey)
                      }
                      onChange={e =>
                        handleChangeParamSearchCustomers(
                          field.code,
                          e.target.value
                        )
                      }
                      style={{ width: '100%' }}
                      value={params?.[field.code]}
                    />
                  </Col>
                );
              }
              if (field.data_type === 'date_time') {
                return (
                  <Col key={index} xxl={4} xl={8} lg={8} sm={12} xs={24}>
                    <DatePicker
                      style={{ width: '100%' }}
                      placeholder={field.label}
                      format={dateFormat}
                      value={
                        params?.[field.code]
                          ? moment(params?.[field.code], dateFormat)
                          : ''
                      }
                      onChange={(date, dateString) =>
                        handleChangeParamSearchCustomers(
                          field.code,
                          date ? moment(date).format(dateFormat) : ''
                        )
                      }
                    />
                  </Col>
                );
              }
            }
          })}
        </Row>
      </Col>

      <Col span={1}>
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu
              onClick={({ item, key, keyPath, domEvent }) => {
                switch (key) {
                  case 'filterConfig':
                    setVisibleFilterConfiguration(true);
                    break;

                  case 'displayConfig':
                    setVisibleDisplayConfiguration(true);
                    break;

                  default:
                    break;
                }
              }}
            >
              <Menu.Item key={'filterConfig'}>Cấu hình bộ lọc</Menu.Item>
              <Menu.Item key={'displayConfig'}>Cấu hình hiển thị</Menu.Item>
            </Menu>
          }
          placement="bottomRight"
        >
          {/* <SettingOutlined /> */}
          <FontAwesomeIconStyle icon={faSun} size="2x" />
        </Dropdown>
      </Col>
    </Row>
  );
}
