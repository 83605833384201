import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, FormControl, Button, Nav, Table } from 'react-bootstrap';
import { ROUTER, TYPE_MEDIA, USER_ROLE, STRING, JOB_STATUS } from '@constants/Constant';
import { connect } from 'react-redux';
import reactotron from 'reactotron-react-js';
import DatePickerCustom from '../../../../components/DatePickerCustom';
import { Line } from 'react-chartjs-2';
import '@styles/RegisterRequest.css';
import { Progress, Pagination } from 'antd';
import { getJobSaleStatistic } from '@src/redux/actions';
import { notifyFail, notifySuccess } from '@utils/notify';
import { getCallDurationStatitic, getJobChart, requestSaleOpportunity, requestJobSaleStatistic } from '@constants/Api';
import moment from 'moment';
class General extends Component {
  constructor(props) {
    super(props);
    this.state = {
      [STRING.fromDate]: '',
      [STRING.toDate]: '',
      datachart: {
        labels: ['1', '2', '3', '4', '5'],
        datasets: [
          {
            label: 'Đồng ý thỏa thuận',
            borderColor: '#aeea00',
            fill: false,
            pointBackgroundColor: '#aeea00',
            pointBorderColor: 'white',
            pointRadius: 5,
            data: [41, 15, 6, 17, 28],
          },
        ],
      },
      callStatistic: [],
      saleOpportunity: {},
      startDate: '',
      endDate: '',
      currentPage: 1,
      jobSaleStatisticList: [],
      formattedPaging: {
        total: null,
        current: null,
        pageSize: null,
      },
    };
  }
  componentDidMount() {
    const { id } = this.props.match?.params;
    Promise.all([
      this.props.getJobSaleStatistic({ telesale_id: id }),
      this.callDurationStatistic(id),
      this.getJobChart(),
      this.requestSaleOpportunity(),
      this.getJobSaleStatistic(),
    ]);
  }

  async getJobSaleStatistic() {
    const { id } = this.props.match?.params;
    try {
      const res = await requestJobSaleStatistic({
        telesale_id: id,
        page: this.state.currentPage,
      });
      console.log(res, 'resssssssss');
      this.setState({
        ...this.state,
        jobSaleStatisticList: [...res.data],
        formattedPaging: {
          total: res.paging.totalItemCount,
          current: res.paging.page,
          pageSize: res.paging.limit,
        },
      });
    } catch (error) {
      console.log(error, 'errorrrrrr');
    }
  }

  startDate = () => {
    const { [STRING.fromDate]: fromDate, [STRING.toDate]: toDate } = this.state;
    if (fromDate == '' && toDate == '') {
      let now = Date.now() - 604800000;
      let d = new Date(now);
      let date = d.getDate() > 9 ? d.getDate() : '0' + d.getDate();
      let month = d.getMonth() + 1 > 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1);
      let year = d.getFullYear();
      let startDate = year + '-' + month + '-' + date + 'T00:00:00.000Z';
      this.setState({
        startDate: startDate,
      });
    }
    if (fromDate == '' && toDate != '') {
      let now = new Date(toDate) - 604800000;
      let d = new Date(now);
      let date = d.getDate() > 9 ? d.getDate() : '0' + d.getDate();
      let month = d.getMonth() + 1 > 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1);
      let year = d.getFullYear();
      let startDate = year + '-' + month + '-' + date + 'T00:00:00.000Z';
      this.setState({
        startDate: startDate,
      });
    }
  };

  endDate = () => {
    const { [STRING.fromDate]: fromDate, [STRING.toDate]: toDate } = this.state;
    if (toDate == '') {
      let now = Date.now();
      let d = new Date(now);
      let date = d.getDate() > 9 ? d.getDate() : '0' + d.getDate();
      let month = d.getMonth() + 1 > 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1);
      let year = d.getFullYear();
      let endDate = year + '-' + month + '-' + date + 'T00:00:00.000Z';
      this.setState({
        endDate: endDate,
      });
    }
  };

  async getJobChart() {
    const { [STRING.fromDate]: fromDate, [STRING.toDate]: toDate } = this.state;
    await this.startDate();
    await this.endDate();
    const { startDate, endDate } = this.state;
    const { id } = this.props.match.params;
    let arrDate = [];
    let arrValues = [];
    try {
      const res = await getJobChart({
        fromDate: fromDate || startDate,
        toDate: toDate || endDate,
        job_id: '',
        telesale_id: id,
      });
      let count = res?.data?.map((value) => {
        arrDate.push(value.quarter);
        arrValues.push(value.earnings);
      });
      this.setState({
        ...this.state,
        datachart: {
          ...this.state.datachart,
          labels: arrDate,
          datasets: [
            {
              label: 'Đồng ý thỏa thuận',
              borderColor: '#aeea00',
              fill: false,
              pointBackgroundColor: '#aeea00',
              pointBorderColor: 'white',
              pointRadius: 5,
              data: arrValues,
            },
          ],
        },
      });
    } catch (err) {
      notifyFail(err);
    }
  }

  async requestSaleOpportunity() {
    const { [STRING.fromDate]: fromDate, [STRING.toDate]: toDate } = this.state;
    await this.startDate();
    await this.endDate();
    const { startDate, endDate } = this.state;
    const { id } = this.props.match.params;
    try {
      const res = await requestSaleOpportunity({
        fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') + 'T00:00:00.000Z' : startDate,
        toDate: toDate ? moment(toDate).format('YYYY-MM-DD') + 'T00:00:00.000Z' : endDate,
        telesale_id: id,
      });
      this.setState({
        ...this.state,
        saleOpportunity: res.data[0],
      });
    } catch (err) {
      notifyFail(err);
    }
  }

  handleChange = (field, value) => {
    this.setState({
      ...this.state,
      [field]: value || '',
    });
  };

  searchBar = (field, value) => {
    const { id } = this.props.match?.params;
    this.setState(
      {
        ...this.state,
        [field]: value,
      },
      () => {
        if (field !== '') {
          this.getJobChart();
          this.requestSaleOpportunity();
          this.callDurationStatistic(id);
        }
      }
    );
  };

  async callDurationStatistic(id) {
    const { [STRING.fromDate]: fromDate, [STRING.toDate]: toDate } = this.state;

    try {
      const res = await getCallDurationStatitic({
        telesale_id: id,
        fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') + 'T00:00:00.000Z' : '',
        toDate: toDate ? moment(toDate).format('YYYY-MM-DD') + 'T00:00:00.000Z' : '',
      });
      this.setState({
        callStatistic: res.data,
      });
    } catch (err) {
      notifyFail(err + '');
    }
  }

  renderBody() {
    return (
      <>
        <Row className="mb-5">{this.renderField()}</Row>
        {/* {this.renderView()} */}
        <Row className="mt-3">
          <Col md={6}>{this.renderCall()}</Col>
          <Col md={6} className="pr-0">
            {this.renderOpportunity()}
          </Col>
        </Row>
        <Row className="mt-3 mb-5">{this.renderChart()}</Row>
        {this.renderTable()}
      </>
    );
  }

  renderField() {
    const { [STRING.fromDate]: fromDate, [STRING.toDate]: toDate } = this.state;
    return (
      <>
        <Col md={4} style={{ fontWeight: '700' }}>
          Tìm kiếm
        </Col>
        <Col md={4} sm={12}>
          <DatePickerCustom
            className={`date-picker form-control`}
            placeholderText={STRING.fromDate}
            handleChange={this.searchBar}
            selected={fromDate}
            maxDate={toDate ? new Date(toDate) : Date.now()}
            dateFormat="dd/MM/yyyy"
          />
        </Col>
        <Col md={4} sm={12}>
          <DatePickerCustom
            className={`date-picker form-control`}
            placeholderText={STRING.toDate}
            handleChange={this.searchBar}
            selected={toDate}
            minDate={new Date(fromDate)}
            dateFormat="dd/MM/yyyy"
          />
        </Col>
      </>
    );
  }

  renderOpportunity() {
    const { saleOpportunity } = this.state;
    return (
      <Row className="mb-3">
        <Col md="12" style={{ padding: 0 }}>
          <b>Thống kê cơ hội</b>
        </Col>
        <Row className="w-100" style={{ marginTop: '30px' }}>
          <div className="col-md-6">
            <div className="info-box mb-3 bg-warning text-light">
              <span className="info-box-icon">
                <i class="fas fa-shopping-basket icon-oppotunity"></i>
              </span>
              <div className="info-box-content">
                <span className="info-box-text">Cơ hội</span>
                <span className="info-box-number">{saleOpportunity?.count || '0'}</span>
              </div>
            </div>
            <div className="info-box mb-3 bg-success">
              <span className="info-box-icon">
                <i class="fas fa-money-bill-wave icon-oppotunity"></i>
              </span>
              <div className="info-box-content">
                <span className="info-box-text">Doanh thu</span>
                <span className="info-box-number">{saleOpportunity?.revenue || '0'}</span>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="info-box mb-3 bg-danger">
              <span className="info-box-icon ">
                <i class="fas fa-file-powerpoint icon-oppotunity"></i>
              </span>
              <div className="info-box-content">
                <span className="info-box-text">ePoint</span>
                <span className="info-box-number">{saleOpportunity?.epoint || '0'}</span>
              </div>
            </div>
            <div className="info-box mb-3 bg-info">
              <span className="info-box-icon">
                <i class="fas fa-spell-check icon-oppotunity"></i>
              </span>
              <div className="info-box-content">
                <span className="info-box-text">Thành công</span>
                <span className="info-box-number">{saleOpportunity?.count_complete || '0'}</span>
              </div>
            </div>
          </div>
        </Row>
      </Row>
    );
  }

  renderChart() {
    return (
      <div className="w-100 table-wrap">
        <span className="pb-2 d-indivne-block">
          <b>Thống kê số cuộc gọi</b>
        </span>
        <Line
          Option={{
            responsive: true,
            fill: false,
            lineTension: true,
          }}
          data={this.state.datachart}
        />
        {/* <Row>
          <b className="text-center" style={{ width: '100%' }}>
            THỐNG KÊ SỐ CUỘC GỌI (01 - 07/07/2020)
          </b>
        </Row> */}
        {/* <Row>
          <b className="text-center" style={{ width: '100%', marginBottom: '25px' }}>
            823 cuộc gọi (700 thành công)
          </b>
        </Row> */}
      </div>
    );
  }

  renderView() {
    const detail = this.props.detail;
    return (
      <>
        <Row>
          <Col md>
            <Row style={{ fontWeight: '600', fontSize: 35 }} className="text-danger">
              {detail.user_sale_info && detail.user_sale_info.reward_point}
            </Row>
            <Row>Kinh nghiệm</Row>
          </Col>
          <Col md style={{ textAlign: 'center' }}>
            <Row>
              <Col md={12} style={{ fontWeight: '600', fontSize: 35 }} className="text-primary">
                {detail.user_sale_info && detail.user_sale_info.kpi_average}
              </Col>
            </Row>
            <Row>
              <Col md={12}>KPI</Col>
            </Row>
          </Col>
          <Col md style={{ textAlign: 'end' }}>
            <Row>
              <Col md={12} style={{ fontWeight: '600', fontSize: 35 }} className="text-success">
                {detail.user_sale_info && detail.user_sale_info.success_call}
              </Col>
            </Row>
            <Row>
              <Col md={12}>Số cuộc gọi TB</Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  renderCall() {
    const { callStatistic } = this.state;
    console.log('callStatistic', callStatistic);

    const calls = callStatistic.map((item) => parseInt(item.count));

    return (
      <Row className="mb-3">
        <Col md="12" style={{ padding: 0 }}>
          <b>Thống kê thời gian cuộc gọi</b>
        </Col>
        <Col md={12} style={{ padding: 0, marginTop: '33px' }}>
          <Table responsive="md">
            <tbody>
              <tr>
                <td className="text-left">
                  <Row>
                    <Col md="11">
                      <div>
                        <p className="m-0">Tổng số cuộc gọi</p>
                      </div>
                    </Col>
                  </Row>
                </td>
                <th>{calls.reduce((accumulator, currentValue) => accumulator + currentValue, 0)}</th>
              </tr>
              {callStatistic?.length &&
                callStatistic?.map((value, index) => (
                  <tr>
                    <td className="text-left">
                      <Row>
                        <Col md="11">
                          <div>
                            <p className="m-0">
                              {index == callStatistic?.length - 1
                                ? `Trên ${value.time} phút`
                                : `Dưới ${value.time} phút`}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </td>
                    <th>{value?.count || '0'}</th>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  }

  getStatus = (status) => {
    let name = '--';
    JOB_STATUS.map((value) => {
      if (value.id == status) {
        name = value.name;
      }
    });
    return name;
  };

  renderTable() {
    const jobSaleStatistic = this.props.jobSaleStatistic?.data;
    console.log(this.state.jobSaleStatisticList, 'this.state.jobSaleStatisticList');
    reactotron.log(jobSaleStatistic, 'jobSaleStatistic');
    let listStatus = [];
    let count = this.state.jobSaleStatisticList?.callStatus?.map((value) => listStatus.push(value?.count));
    const clrChild = ['text-success', 'text-primary', 'text-black-50', 'text-warning', 'text-orange'];
    return (
      <>
        <b>Thống kê công việc Telesale</b>
        {this.state.jobSaleStatisticList?.length > 0 &&
          this.state.jobSaleStatisticList?.map((value, index) => (
            <div
              style={{
                padding: '30px 40px',
                backgroundColor: '#ffffff',
                borderRadius: '8px',
                boxShadow: '0 20px 80px rgba(0, 0, 0, 0.2)',
                marginTop: '10px',
              }}
            >
              <Row>
                <b style={{ fontSize: 17 }}>{value?.name}</b>
                <span style={{ margin: '3px 0 0 5px' }}>
                  <i style={{ color: '#2979ff' }}>{this.getStatus(value.status)}</i>
                </span>
              </Row>
              <Row>
                <Col md={3} className="p-0">
                  <span>Số khách hàng cần tư vấn: </span>
                  <span>
                    <b style={{ color: '#23A0CB' }}>{value?.countCustomer}</b>
                  </span>
                </Col>
                <Col md={4} className="p-0">
                  <span className="m-0">Thời gian: </span>
                  <b style={{ color: '#F76766' }}>
                    {Intl.DateTimeFormat('vi-VN').format(new Date(value?.start_at)) || '--'}-
                    {Intl.DateTimeFormat('vi-VN').format(new Date(value?.end_at)) || '--'}
                  </b>
                </Col>
                <Col md></Col>
                <Col md></Col>
                <Col md></Col>
              </Row>
              <Row className="mt-3">
                {value?.callStatus?.length > 0 &&
                  value?.callStatus?.map((item, index) => (
                    <Col md>
                      <Row>
                        <Col xs={9} className="px-0">
                          <Row>
                            <span style={{ fontWeight: '600', fontSize: 35 }} className={clrChild[index]}>
                              {item?.count}
                            </span>
                            <span style={{ color: '#707070', marginTop: '23px' }}>
                              <b>
                                (
                                {value?.callStatus?.reduce((a, v) => (a = a + v.count), 0) != 0
                                  ? parseFloat(
                                      (
                                        (item?.count * 100) /
                                        value?.callStatus?.reduce((a, v) => (a = a + v.count), 0)
                                      ).toFixed(2)
                                    )
                                  : '0'}
                                %)
                              </b>
                            </span>
                            <Col md={12} xs={12} className="px-0">
                              {item?.name}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  ))}
              </Row>
              <Row className="mt-3">
                <Col md>
                  <p className="m-0">Kinh nghiệm cộng</p>
                  <p className="m-0">
                    <span style={{ fontWeight: '600', fontSize: 35 }}>{value?.pointAdd || '0'}</span>
                    <i class="fal fa-chart-line fa-2x" style={{ marginLeft: '30px', color: '#4DBD74' }}></i>
                  </p>
                </Col>
                <Col md>
                  <p className="m-0">Kinh nghiệm trừ</p>
                  <p className="m-0">
                    <span style={{ fontWeight: '600', fontSize: 35 }}>{value?.pointSub || '0'}</span>
                    <i class="fal fa-chart-line-down fa-2x" style={{ marginLeft: '30px', color: '#F76766' }}></i>
                  </p>
                </Col>
                <Col md></Col>
                <Col md></Col>
                <Col md></Col>
              </Row>
            </div>
          ))}
        <div className="mt-2 text-right">
          <Pagination
            {...this.state.formattedPaging}
            onChange={(value) => {
              this.setState(
                {
                  ...this.state,
                  currentPage: value,
                },
                () => {
                  this.getJobSaleStatistic();
                }
              );
            }}
          />
        </div>
      </>
    );
  }

  render() {
    return this.renderBody();
  }
}

const mapStateToProps = (state) => ({
  jobSaleStatistic: state.jobSaleStatisticReducer,
});

const mapDispatchToProps = {
  getJobSaleStatistic,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(General));
