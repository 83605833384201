import { Form } from 'antd';
import styled from 'styled-components';

export const FormStyled = styled(Form)`
  height: 100%;
  width: 100%;

  &
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    ):before {
    content: '';
    margin-right: 0;
  }

  &
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    ):after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
`;
