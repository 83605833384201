import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, FormControl, Button, Nav, Table } from 'react-bootstrap';
import { ROUTER, TYPE_MEDIA, USER_ROLE, STRING } from '@constants/Constant';
import { connect } from 'react-redux';
import reactotron from 'reactotron-react-js';
import DatePickerCustom from '../../../../components/DatePickerCustom';
import { Line } from 'react-chartjs-2';
import '@styles/RegisterRequest.css';
import { Progress } from 'antd';
import { getListForSale } from '@src/redux/actions';
import { getItemFromId } from '@utils/getItemFromId';
import { Empty, Switch } from 'antd';
import { REPORT_STATUS } from '@constants/Constant';
import moment from 'moment';
class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        telesale_id: '',
        [STRING.fromDate]: '',
        [STRING.toDate]: '',
      },
      activePage: 1,
    };
  }

  handleChange = async (field, value) => {
    this.setState(
      {
        ...this.state,
        form: {
          ...this.state.form,
          [field]: value,
        },
      },
      () => {
        if (field !== 'search') {
          const { page, telesale_id, [STRING.fromDate]: fromDate, [STRING.toDate]: toDate, limit } = this.state.form;
          this.getData({
            page: page || '',
            job_id: telesale_id || '',
            fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : '',
            toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : '',
            limit: limit || '',
          });
        }
      }
    );
  };
  componentDidMount() {
    this.getData({ page: 1 });
    // this.props.getListForSale();
  }

  getData({ page, telesale_id, limit = 1000, fromDate, toDate }) {
    const { id } = this.props.match.params;
    this.props.getListForSale({
      page: page || '',
      telesale_id: id || '',
      limit: limit || '',
      fromDate: fromDate || '',
      toDate: toDate || '',
    });
  }

  renderBody() {
    return (
      <>
        <Row className="mb-3">{this.renderField()}</Row>
        {this.renderView()}
        {this.renderTable()}
      </>
    );
  }

  renderField() {
    const { [STRING.fromDate]: fromDate, [STRING.toDate]: toDate } = this.state.form;
    return (
      <>
        <Col md={2}>
          <b>Tổng quan</b>
        </Col>
        <Col md={3} sm={12}>
          <DatePickerCustom
            className={`date-picker form-control`}
            placeholderText={STRING.fromDate}
            handleChange={this.handleChange}
            selected={fromDate}
            maxDate={new Date(toDate)}
            dateFormat="dd/MM/yyyy"
          />
        </Col>
        <Col md={3} sm={12}>
          <DatePickerCustom
            className={`date-picker form-control`}
            placeholderText={STRING.toDate}
            handleChange={this.handleChange}
            selected={toDate}
            minDate={new Date(fromDate)}
            dateFormat="dd/MM/yyyy"
          />
        </Col>
      </>
    );
  }

  renderBox(text, value) {
    return (
      <Col
        md
        style={{
          marginTop: "5px",
          marginRight: '16px',
          padding: '30px 40px',
          backgroundColor: '#ffffff',
          borderRadius: '8px',
          boxShadow: '0 20px 80px rgba(0, 0, 0, 0.15)',
        }}
      >
        <h5>{text}</h5>
        <h2>{value}</h2>
      </Col>
    );
  }

  renderView() {
    const telesale = this.props.telesaleHistory?.data;
    // reactotron.log(telesale, "telesale");
    return (
      <>
        <Row className="pb-3">
          <Col md={12}>
            <b>Số yêu cầu chuyển tiền</b>
          </Col>
        </Row>
        {/* <Row className="pb-3">
          <Col sm={2}></Col>
          <Col sm={4}>
            <span>Số cơ hội bán hàng: </span>
            <span>
              <b className="text-blue">{telesale?.totalCount || '0'}</b>
            </span>
          </Col>
          <Col sm={4}>
            <span>Tỷ lệ chuyển đổi thành công: </span>
            <span>
              <b className="text-green">{telesale?.totalPercent || '0'}%</b>
            </span>
          </Col>
          <Col sm={2}></Col>
        </Row> */}
        <Row className="pb-3">
          <Col sm={4} style={{paddingLeft: "36px"}}>
            <Row className="pb-3">
              <span className="mr-1">Số cơ hội bán hàng: </span>
              <span>
                <b className="text-blue">{telesale?.totalCount || '0'}</b>
              </span>
            </Row>
            <Row>
              <span className="mr-1">Tỷ lệ chuyển đổi thành công: </span>
              <span>
                <b className="text-green">{telesale?.totalPercent || '0'}%</b>
              </span>
            </Row>
          </Col>
          <Col sm={8}>
            <Table striped bordered hover>
              <thead style={{ textAlign: 'center' }}>
                <th>Loại sản phẩm</th>
                <th>Số nhu cầu</th>
                <th>Nhu cầu thành công</th>
              </thead>
              <tbody>
                {telesale?.listData?.length > 0
                  ? telesale?.listData?.map((value, index) => (
                      <tr>
                        <td>{value?.product_name || '--'}</td>
                        <td>{value?.count || '0'}</td>
                        <td>{value?.totalSuccess || '0'}</td>
                      </tr>
                    ))
                  : ''}
              </tbody>
            </Table>
          </Col>
        </Row>

        {/* <Row className="pb-3">
          <Col md={2} xs={4}>
            <b>Doanh số:</b>
          </Col>
          <Col md={6} xs={4}>
            <b>Doanh thu:</b>
          </Col>
          <Col md={2} xs={4}>
            <b>Điểm Vpoint:</b>
          </Col>
        </Row> */}
        <Row className="pb-3">
          {this.renderBox('Doanh số', telesale?.totalSale)}
          {telesale?.listData?.length > 0
            ? telesale?.listData?.map(
                (value, index) => {
                  this.renderBox(value?.product_name, value?.totalRevenue);
                }

                // <Button md={4} className="btn btn-light btn-oversize" style={{ border: '2px solid #ada4a4' }}>
                //   <p className="m-0">{value?.product_name}</p>
                //   <b className="m-0 text-red">{value?.totalRevenue}</b>
                // </Button>
              )
            : this.renderBox('Doanh thu', 0)}
          {/* <Col md={2} xs={4}>
            <Button className="btn btn-light btn-oversize" style={{ border: '2px solid  #ada4a4' }}>
              <p className="m-0">Doanh số:</p>
              <b className="m-0 text-blue">{telesale?.totalSale}</b>
            </Button>
          </Col> */}
          {/* <Col md={6} xs={4}>
            <Row>
              {telesale?.listData?.length > 0 ? (
                telesale?.listData?.map((value, index) => (
                  <Button md={4} className="btn btn-light btn-oversize" style={{ border: '2px solid #ada4a4' }}>
                    <p className="m-0">{value?.product_name}</p>
                    <b className="m-0 text-red">{value?.totalRevenue}</b>
                  </Button>
                ))
              ) : (
                <Button md={4} className="btn btn-light btn-oversize" style={{ border: '2px solid  #ada4a4' }}>
                  <p className="m-0">Doanh thu</p>
                  <b className="m-0 text-red">0</b>
                </Button>
              )}
            </Row>
          </Col> */}
          {this.renderBox('Điểm Vpoint', telesale?.totalVpoint)}
          {/* <Col md={2} xs={4}>
            <Button className="btn btn-light btn-oversize" style={{ border: '2px solid  #ada4a4' }}>
              <p className="m-0">Điểm Vpoint:</p>
              <b className="m-0 text-warning">{telesale?.totalVpoint}</b>
            </Button>
          </Col> */}
        </Row>
        <Row className="pb-3 mt-2">
          <Col md={2} xs={4} className="p-0">
            <b>Báo cáo chi tiết:</b>
          </Col>
        </Row>
        <Row className="pb-3">
          <Col md={4} xs={4}>
            <Row>
              <span className="mr-1">Doanh số: </span>
              <span>
                <b className="text-blue">{telesale?.totalSale}</b>
              </span>
            </Row>
          </Col>
          <Col md={4} xs={4}>
            <Row>
              <span className="mr-1">Số lượng:</span>
              <span>
                <b className="text-red">{telesale?.totalCount}</b>
              </span>
            </Row>
          </Col>
          <Col md={4} xs={4}>
            <Row>
              <span className="mr-1">Điểm Vpoint: </span>
              <span>
                <b className="text-warning">{telesale?.totalVpoint}</b>
              </span>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  renderTable() {
    const telesaleHistory = this.props.telesaleHistory?.data;
    const itemsCount = this.props.telesaleHistory?.paging;

    return (
      <>
        <div className="w-100 table-wrap" style={{ padding: '0px 15px' }}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>STT</th>
                <th>Công việc</th>
                <th>Loại cơ hội</th>
                <th>Sale</th>
                <th>Doanh số</th>
                <th>Doanh thu</th>
                <th>Trạng thái</th>
                <th>Ngày chuyển nhu cầu</th>
              </tr>
            </thead>
            <tbody>
              {telesaleHistory?.listOpportunitis && telesaleHistory?.listOpportunitis?.length ? (
                telesaleHistory?.listOpportunitis?.map((telesale, index) =>
                  // this.renderTableRow(telesale, index + itemsCount?.limit * (this.state.activePage - 1) + 1)
                  this.renderTableRow(telesale, index + 1)
                )
              ) : (
                <td colspan="8">
                  <Empty description={<span>Không có dữ liệu</span>} />
                </td>
              )}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
  renderTableRow(telesale, index) {
    const status = getItemFromId(REPORT_STATUS, telesale.status);
    return (
      <tr>
        <td>{index}</td>
        <td>{telesale?.job_name || '--'}</td>
        <td>{telesale?.opportunity_category_name || '--'}</td>
        <td>{telesale?.telesale_name || '--'}</td>
        <td>{telesale?.sales || '--'}</td>
        <td>{telesale?.revenue || '--'}</td>
        <td>{(status && status?.name) || '--'}</td>
        <td>{Intl.DateTimeFormat('vi-VN').format(new Date(telesale?.created_at)) || '---'}</td>
      </tr>
    );
  }

  render() {
    const telesale = this.props.telesaleHistory;
    reactotron.log(telesale, 'telesale');
    return this.renderBody();
  }
}

const mapStateToProps = (state) => ({
  telesaleHistory: state.reportTelesaleReducer,
});

const mapDispatchToProps = {
  getListForSale,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Report));
