import { RetweetOutlined } from '@ant-design/icons';
import { CALLING_CODE } from '@components/callProviders/CallConstant';
import { isCallEnded } from '@components/callProviders/CallHelper';
import CallUtil from '@components/callProviders/CallUtil';
import useCallStatus from '@components/callProviders/useCallStatus';
import SpinInButton from '@components/SpinInButton';
import {
  requestCallHistoryDetail,
  requestCallHistoryDetailCalling,
  requestCustomerDetail,
  requestCustomerDetailInteractionHistory,
} from '@constants/Api';
import {
  BOX_SHADOW,
  EVENT_CHANNEL,
  FIELD_IMPORT,
  ROUTER,
} from '@constants/Constant';
import {
  getSearchParamsInUrl,
  randomColor,
  setSearchParamsInUrl,
  setSearchParamsInUrlVer2,
} from '@constants/funcHelper';
import Loading from '@src/components/Loading';
import { ButtonSystemStyle } from '@styles/Style';
import EventEmitter from '@utils/EventEmitter';
import { notifyFail, notifySuccess } from '@utils/notify';
import { Avatar, Breadcrumb, Button, PageHeader, Skeleton, Tabs } from 'antd';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { BreadcrumbItem, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {
  requestForwardToEnterprise,
  requestUpdateCustomer,
} from 'src/network/web_sale/CustomerApi';
import styled from 'styled-components';
import {
  requestStartCall,
  requestUpdateCall,
} from '../../../network/web_sale/CallApi';
import {
  requestCreateOpportunity,
  requestGetCustomerDetailInboundCall,
  requestGetOpportunities,
  requestGetProducts,
  requestSubmitForm,
  requestSubmitIncomingCall,
} from '../../../network/web_sale/GeneralApi';
import {
  requestGetJobDetail,
  requestNextCustomerByJob,
} from '../../../network/web_sale/JobApi';
import '../Call/style.css';
import CallStatus from './CallStatus';
import CustomerDetail from './components/CustomerDetail';
import HistoryCustomer from './components/HistoryCustomer';
import ModalAddOpportunity from './components/ModalAddOpportunity';
import ModalRecallTime from './components/ModalRecallTime';
import NextCustomerDetails from './components/NextCustomerDetails';
import Result from './components/Result';
import Scenario from './components/Scenario';
import TagView from './components/TagView';
import zalo_icon from '@assets/iconzalo.svg';
import ModalMessageZalo from './components/ModalMessageZalo';
import EZLoading from 'src/modules/loading/EZ.Loading';
import {
  ContainerCallingStyled,
  PageHeaderCallingStyled,
} from './Calling/style';
import PageheaderLeft from './Calling/Pageheader.Left';
import PageheaderExtra from './Calling/Pageheader.Extra';
import { LoadingSkeletonUserInfo } from './Calling/Loading';
const { TabPane } = Tabs;

const NextIcon = () => (
  <svg
    width="18px"
    height="18px"
    fill="white"
    viewBox="0 0 1024 1024"
    style={{ margin: '0 1px 3px 0' }}
  >
    <path d="M533.2 492.3L277.9 166.1c-3-3.9-7.7-6.1-12.6-6.1H188c-6.7 0-10.4 7.7-6.3 12.9L447.1 512 181.7 851.1A7.98 7.98 0 00188 864h77.3c4.9 0 9.6-2.3 12.6-6.1l255.3-326.1c9.1-11.7 9.1-27.9 0-39.5zm304 0L581.9 166.1c-3-3.9-7.7-6.1-12.6-6.1H492c-6.7 0-10.4 7.7-6.3 12.9L751.1 512 485.7 851.1A7.98 7.98 0 00492 864h77.3c4.9 0 9.6-2.3 12.6-6.1l255.3-326.1c9.1-11.7 9.1-27.9 0-39.5z" />
  </svg>
);

const TabsStyle = styled(Tabs)`
  .ant-tabs-ink-bar {
    background: #ef8737;
  }
`;

const searchParams = new URLSearchParams(window.location.search);
const { call_id, job_id } = getSearchParamsInUrl();

function SaleCallingScreen() {
  const { call, callId } = useCallStatus();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);
  const [callNote, setCallNote] = useState('');
  const [callDetail, setCallDetail] = useState({});
  // const [customerDetail, setCustomerDetail] = useState({});
  const [customerDetail, setCustomerDetail] = useState({ name: '' });
  const [callStatusId, setCallStatusId] = useState(null);
  const [timeRecall, setTimeRecall] = useState('');
  const [noteRecall, setNoteRecall] = useState('');
  const [specificTimeRecall, setSpecificTimeRecall] = useState(moment());
  const [jobDetail, setJobDetail] = useState({});
  const [idJob, setIdJob] = useState('');
  const [modalAddOpportunityVisible, setModalAddOpportunityVisible] =
    useState(false);
  const [opportunityList, setOpportunityList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [opportunityId, setOpportunityId] = useState('');
  const [productId, setProductId] = useState('');
  const [opportunityNote, setOpportunityNote] = useState('');
  const [newCustomerName, setNewCustomerName] = useState('');
  const [newJobId, setNewJobId] = useState('');
  const [jobList, setJobList] = useState([]);
  const [shouldRecall, setShouldRecall] = useState(0);
  const [convertedAnswerList, setConvertedAnswerList] = useState([]);
  const [modalRecallTimeVisible, setModalRecallTimeVisible] = useState(false);
  const [recallChoice, setRecallChoice] = useState(0);
  const [isLoadingCustomerDetail, setIsLoadingCustomerDetail] = useState(false);
  const [subPhones, setSubPhones] = useState([]);
  const [answerDate, setAnswerDate] = useState('');

  const [isLoadingNextCustomer, setIsLoadingNextCustomer] = useState(false);
  const [nextCustomer, setNextCustomer] = useState(null);

  const [isSaving, setIsSaving] = useState(false);
  const [isStartCall, setIsStartCall] = useState(false);
  const [isCalled, setIsCalled] = useState('');
  const [isShowCallbackSchedual, setShowCallBackSchedual] = useState('');
  const [isCanUpdateParams, setIsCanUpdateParams] = useState(() =>
    searchParams.get('isCanUpdate')
  );

  const [isCallBackGetDetails, setIsCallBackGetDetails] = useState(false);
  useEffect(() => {
    return () => localStorage.removeItem('previousPage');
  }, []);

  useEffect(() => {
    if (!callId) {
      getCallDetail(call_id);
    } else {
      getCallDetail(callId);
    }
  }, [callId, isCallBackGetDetails]);

  const getNextCustomer = async () => {
    // let paramSortPassed = localStorage.getItem('previousPage') ?
    // JSON.parse((localStorage.getItem('previousPage'))) : {}
    if (CallUtil.currentJobId) {
      try {
        // console.log("paramSortPassed", paramSortPassed)
        // alert(3333)

        setIsLoadingNextCustomer(true);
        const res = await requestNextCustomerByJob({
          job_id: CallUtil.currentJobId,
          uncontacted: CallUtil.isUncontacted ? 1 : 0,
          customer_id: CallUtil.currentCustomerId,
          search: CallUtil.currentSearch,
          // from_date: paramSortPassed.fromDate ,
          // to_date: paramSortPassed.toDate,
          // time_recall_status: paramSortPassed.time_recall_status
        });

        // console.log('resCustomer', res);
        setNextCustomer(res.data);
        if (res.data !== null) {
          const { customer_id } = res.data;
          CallUtil.currentCustomerId = customer_id;
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoadingNextCustomer(false);
      }
    }
  };
  useEffect(() => {
    if (parseInt(shouldRecall) === 1) {
      setModalRecallTimeVisible(true);
    } else {
      setTimeRecall('');
      setNoteRecall('');
    }
  }, [shouldRecall]);

  const getCallDetail = async id => {
    setIsLoading(true);
    if (!id) {
      return;
    }
    try {
      const { data } = await requestCallHistoryDetail({ id });
      // console.log('data', data);
      if (data.customer_id) {
        getCustomerDetail(data.customer_id, id);
        getOpportunityList(id, data.customer_id);
        // getProductList(id, data.customer_id);
        // getSuitableJob(
        //   data.customer_phone,
        //   data.customer_id,
        //   data.enterprise_id
        // );
        if (!customerDetail?.name && customerDetail?.name !== '') {
          // alert(1);
          getSuitableJob(
            data.customer_phone,
            data.customer_id,
            data.enterprise_id
          );
        }
        if (CallUtil.currentJobId != null) {
          CallUtil.currentCustomerId = data.customer_id;
          getNextCustomer();
        } else {
          setNextCustomer(null);
        }
      }
      if (data.job_id) {
        setIdJob(data.job_id);
        getJobDetail(data.job_id);
      }
      setCallDetail(data);
      if (data.status) setCallStatusId(data.status);
      if (data.start_call_time) {
        //nếu tồn tại thì là trạng thái đã gọi
        setIsCalled(data.start_call_time);
      }
      //show lịch hẹn gọi lại hay không
      // console.log('data111', data);

      let searchParams = {
        call_id: id,
        customer_id: data.customer_id,
      };

      if (CallUtil.currentJobId) searchParams.job_id = CallUtil.currentJobId;

      setSearchParamsInUrlVer2(searchParams);
    } catch (error) {
      console.log('Error getCallDetail: ', { error });
    }
    setIsLoading(false);
  };

  const getCustomerDetail = async (id, callId) => {
    setIsLoadingCustomerDetail(true);

    try {
      const res = await requestCustomerDetail(id, callId);
      setCustomerDetail(res.data.main); //lấy chi tiết customer
      setSubPhones(res.data.main.customer_phones); //lấy sô phone phụ
      getDetailInteractionHistory(res.data?.main?.id); //lấy lịch sử tương tác của customer
      setShowCallBackSchedual(res?.data?.main?.sale_time_recall); //true | false có show <CallStatus /> gọi lại hay k
    } catch (error) {
      console.log('Error getCustomerDetail: ', { error });
    }
    setIsLoadingCustomerDetail(false);
  };

  const getJobDetail = async id => {
    try {
      const res = await requestGetJobDetail({
        id: id,
      });
      setJobDetail({ ...res.data });
    } catch (error) {
      console.log('Error getJobDetail: ', { error });
    }
  };

  const getOpportunityList = async (callId, customerId) => {
    try {
      const res = await requestGetOpportunities({
        call_id: callId,
        customer_id: customerId,
      });
      setOpportunityList(res.data);
    } catch (error) {
      console.log('Error getOpportunityList: ', { error });
    }
  };

  const getProductList = async (callId, customerId) => {
    try {
      const res = await requestGetProducts({
        call_id: callId,
        customer_id: customerId,
      });
      setProductList(res.data);
    } catch (error) {
      console.log('Error getProductList: ', { error });
    }
  };

  const getSuitableJob = async (customer_phone, customer_id, enterprise_id) => {
    try {
      const res = await requestGetCustomerDetailInboundCall({
        hotline: '',
        customer_phone: customer_phone,
        customer_id: customer_id,
        enterprise_id: enterprise_id,
      });
      setJobList(res.data?.ortherJob || []);
    } catch (error) {
      console.log('Error getSuitableJob: ', { error });
    }
  };

  // Scenario funcs

  // Result funcs
  const handleChangeStatusId = value => {
    setCallStatusId(value);
  };

  const handleChangeAnswerList = (quest, answer) => {
    if (convertedAnswerList.map(item => item.id).includes(quest.id)) {
      let cloneConvertedAnswerList = JSON.parse(
        JSON.stringify(convertedAnswerList)
      );
      // Xóa tất cả các câu hỏi con đã chọn trước đó nếu câu hỏi là câu hỏi chọn 1 đáp án
      if (quest.type_input === 2 || quest.type_input === 5) {
        const listChildID = quest.job_form_answers
          .filter(formAnswer => formAnswer.id !== answer)
          .map(formAnswers =>
            formAnswers.job_form_answer_child?.map(
              childAnswer => childAnswer.id
            )
          );

        cloneConvertedAnswerList = cloneConvertedAnswerList.filter(
          cloneAnswer => {
            return !listChildID.map(ids => ids?.includes(cloneAnswer.id))[0];
          }
        );
      }

      cloneConvertedAnswerList = cloneConvertedAnswerList.map(
        convertedAnswer => {
          if (convertedAnswer.id === quest.id) {
            switch (quest.type_input) {
              case 1:
              case 4:
                return {
                  ...convertedAnswer,
                  text_result: answer,
                };
              case 3:
                return {
                  ...convertedAnswer,
                  number_result: answer,
                };
              default:
                return {
                  ...convertedAnswer,
                  number_result: [answer],
                };
            }
          } else {
            return convertedAnswer;
          }
        }
      );
      setConvertedAnswerList(cloneConvertedAnswerList);
    } else {
      let newConvertedAnswer = {};
      switch (quest.type_input) {
        case 1:
        case 4:
          newConvertedAnswer = {
            id: quest.id,
            question: quest.name,
            text_result: answer,
          };
          break;
        case 3:
          newConvertedAnswer = {
            id: quest.id,
            question: quest.name,
            number_result: answer,
          };
          break;
        default:
          newConvertedAnswer = {
            id: quest.id,
            question: quest.name,
            number_result: [answer],
          };
          break;
      }
      setConvertedAnswerList(prev => [...prev, newConvertedAnswer]);
    }
  };

  const handleSubmitResult = async autoNext => {
    const finalAnswerList = [...convertedAnswerList];
    if (!callStatusId) {
      notifyFail('Vui lòng chọn trạng thái cuộc gọi!');
      return;
    }

    if (shouldRecall && recallChoice === 0 && !specificTimeRecall) {
      notifyFail('Vui lòng chọn thời gian gọi lại!');
      return;
    }

    let convertedRecallTime = '';

    if (shouldRecall) {
      if (recallChoice) {
        convertedRecallTime = moment().add(recallChoice, 'm').format();
      } else {
        convertedRecallTime = specificTimeRecall.format();
      }
    }

    const newArr = finalAnswerList.length
      ? finalAnswerList.map(item => {
          if (!item.number_result?.length) {
            return {
              ...item,
              number_result: [null],
            };
          } else {
            return item;
          }
        })
      : [];
    const payloadIncomingCall = {
      call_id: callId || call_id,
      call_status_id: callStatusId || null,
      time_recall: convertedRecallTime,
      note_recall: shouldRecall ? noteRecall || '' : '',
      job_id: callDetail.job_id,
      province_id: null,
      customer_name: newCustomerName.trim(),
      address: '',
      forward_job_id: newJobId ? parseInt(newJobId) : 0,
      is_validate:
        parseInt(callStatusId) === 3 || parseInt(callStatusId) === 4 ? 1 : 0,
      customer_data_input: [
        {
          customer_data_field_code: 'company',
          value: '',
        },
        {
          customer_data_field_code: 'customer_type',
          value: '',
        },
        {
          customer_data_field_code: 'tax_id',
          value: '',
        },
        {
          customer_data_field_code: 'position',
          value: '',
        },
      ],
      form: finalAnswerList,
    };
    const payloadSubmit = {
      call_id: callId || call_id,
      call_status_id: callStatusId || null,
      time_recall: shouldRecall ? convertedRecallTime || '' : '',
      note_recall: shouldRecall ? noteRecall || '' : '',
      form: finalAnswerList,
      is_validate:
        parseInt(callStatusId) === 3 || parseInt(callStatusId) === 4 ? 1 : 0,
      call_note: callNote,
    };
    const payloadUpdateSubmit = {
      call_id: callId || call_id,
      call_status_id: callStatusId || null,
      time_recall: timeRecall || convertedRecallTime || null,
      note_recall: noteRecall || '',
      form: newArr,
      call_note: callNote || '',
    };

    const callAway = isCanUpdateParams ? payloadUpdateSubmit : payloadSubmit;
    const callResult = customerDetail.name ? callAway : payloadIncomingCall;
    mixpanel.track(`Update call result`, { callResult });

    try {
      setIsSaving(true);
      if (customerDetail.name) {
        if (isCanUpdateParams) {
          const update = await requestUpdateCall(payloadUpdateSubmit);
          setIsCanUpdateParams(update?.data?.isCanUpdate);
          setSearchParamsInUrl('isCanUpdate', update?.data?.isCanUpdate);

          notifySuccess('Sửa phiếu kết quả thành công!');
        } else {
          const res = await requestSubmitForm(payloadSubmit);
          setIsCanUpdateParams(res?.data?.isCanUpdate);
          setSearchParamsInUrl('isCanUpdate', res?.data?.isCanUpdate);

          notifySuccess('Cập nhật phiếu kết quả thành công!');

          // if (jobDetail?.job_category?.enterprise_forward_job_enabled) {
          //   await forwardToEnterprise(callId);
          // }
        }
      } else {
        const res = await requestSubmitIncomingCall(payloadIncomingCall);
        setIsCanUpdateParams(res?.data?.isCanUpdate);
        setSearchParamsInUrl('isCanUpdate', res?.data?.isCanUpdate);

        notifySuccess('Cập nhật phiếu kết quả thành công!');
      }

      if (autoNext) {
        callNextCustomer();
      } else {
        getCallDetail(callId);
        // setIsCallBackGetDetails(!isCallBackGetDetails);
      }
    } catch (error) {
      notifyFail(error.msg);
    } finally {
      setIsSaving(false);
    }
  };

  const callNextCustomer = async () => {
    try {
      setIsStartCall(true);
      const hotline = CallUtil.getDefaultHotlines();
      // // create call ID
      const payload = {
        job_id: CallUtil.currentJobId,
        customer_phone_id: '',
        job_telesale_customer_id: nextCustomer.job_telesale_customer_id,
        hotline: hotline.outbound_phone.toString(),
      };

      const callRes = await requestStartCall(payload);
      CallUtil.makeCall(
        hotline.type,
        nextCustomer.unmasked_pnumber,
        callRes.data.id,
        nextCustomer.phone,
        CallUtil.currentJobId,
        CallUtil.isUncontacted,
        payload
      );
      setNextCustomer(null);
    } catch (error) {
      console.log('Error callNextCustomer: ', { error });
    } finally {
      setIsStartCall(false);
    }
  };

  // ModalAddOpportunity funcs
  const handleCloseModalAddOpportunity = () => {
    setModalAddOpportunityVisible(false);
  };

  const handleSaveOpportunity = async () => {
    try {
      const purchaseOpportunity = opportunityList.length
        ? opportunityList.find(item => item.name.toUpperCase() === 'MUA BÁN')
        : {};

      if (!opportunityId) {
        notifyFail('Vui lòng chọn cơ hội!');
        return;
      }

      if (parseInt(opportunityId) === purchaseOpportunity.id && !productId) {
        notifyFail('Vui lòng chọn sản phẩm!');
        return;
      }

      if (opportunityNote.trim() === '') {
        notifyFail('Vui lòng nhập ghi chú!');
        return;
      }

      const payload = {
        opportunity_category_id: opportunityId,
        call_id: callId,
        customer_id: customerDetail.id,
        product_id: productId,
        note: opportunityNote,
      };

      const res = await requestCreateOpportunity(payload);
      notifySuccess('Thêm cơ hội thành công!');
      setModalAddOpportunityVisible(false);
    } catch (error) {
      notifyFail('Thêm cơ hội thất bại!');
    }
  };
  // Lưu thông tin khách hàng
  const [displayUpdateCustomerModal, setDisplayUpdateCustomerModal] =
    useState(false);

  const name = customerDetail?.name
    ? customerDetail?.name.trim()?.split(' ')
    : '';

  useEffect(() => {
    EventEmitter.addListener(EVENT_CHANNEL.REQUEST_TO_LOAD_SCREEN, arg => {
      setIsLoadingScreen(arg);
    });

    return () => {
      EventEmitter.removeAllListeners([EVENT_CHANNEL.REQUEST_TO_LOAD_SCREEN]);
    };
  }, []);

  const forwardToEnterprise = async callId => {
    try {
      await requestForwardToEnterprise({ call_id: callId });
    } catch (error) {
      console.log('Error forwardToEnterprise: ', { error });
    }
  };

  //lịch sử tương tác của khách hàng đang gọi
  const [customerInteractionHistory, setCustomerInteractionHistory] = useState(
    []
  );
  const [
    loadingCustomerInteractionHistory,
    setLoadingCustomerInteractionHistory,
  ] = useState(true);

  const [cursorId, setCursorId] = useState('');
  const [viewMore, setViewMore] = useState(false);

  //cữ
  // const getDetailInteractionHistory = useCallback(async customerId => {
  //   try {
  //     const result = await requestCustomerDetailInteractionHistory({
  //       id: customerId,
  //       page: 1,
  //       limit: 1000,
  //     });
  //     setCustomerInteractionHistory(result.data);
  //   } catch (error) {
  //     console.log('Exception: ', error);
  //   } finally {
  //     setLoadingCustomerInteractionHistory(false);
  //   }
  // }, []);

  //update v2
  const getDetailInteractionHistory = useCallback(
    async customerId => {
      try {
        const result = await requestCustomerDetailInteractionHistory({
          id: customerId,
          page: 1,
          limit: 12,
          cursor_id: cursorId,
        });
        setCustomerInteractionHistory(result.data);
        setCursorId(result.paging.next);
      } catch (error) {
        console.log('Exception: ', error);
      } finally {
        setLoadingCustomerInteractionHistory(false);
      }
    },
    [viewMore]
  );

  useEffect(() => {
    if (customerDetail.id) getDetailInteractionHistory(customerDetail.id);
  }, [customerDetail.id, viewMore]);

  const resetNexCustomer = React.useCallback(() => {
    setNextCustomer(null);
  }, []);

  const handleOpenModalOpportunity = React.useCallback(() => {
    setModalAddOpportunityVisible(true);
  }, []);

  return (
    <ContainerCallingStyled>
      {/* {(isLoading || isLoadingScreen || isSaving || isStartCall) && (
        <EZLoading />
      )} */}

      <div className="content-wrapper">
        <div className="content-header" style={{ paddingTop: '2px' }}>
          {/* Thông tin chung và các action lưu kết quả, nút tiếp theo */}
          <div className="d-flex">
            <PageHeaderCallingStyled
              ghost={false}
              onBack={null}
              title={
                isLoadingCustomerDetail || !customerDetail?.name ? (
                  <LoadingSkeletonUserInfo />
                ) : (
                  <PageheaderLeft
                    job_id={job_id}
                    customerDetail={customerDetail}
                    isCalled={isCalled}
                    isShowCallbackSchedual={isShowCallbackSchedual}
                    callDetail={callDetail}
                    getDetailInteractionHistory={getDetailInteractionHistory}
                  />
                )
              }
              style={{ margin: 10, padding: '10px 24px', borderRadius: '6px' }}
              extra={[
                <PageheaderExtra
                  key="extra"
                  call={call}
                  nextCustomer={nextCustomer}
                  resetNexCustomer={resetNexCustomer}
                  handleOpenModalOpportunity={handleOpenModalOpportunity}
                  customerDetail={customerDetail}
                  setSubPhones={setSubPhones}
                  handleSubmitResult={handleSubmitResult}
                  isLoadingNextCustomer={isLoadingNextCustomer}
                />,
              ]}
            />
            {/* {isCallEnded(call.status) && nextCustomer && (
              <div
                style={{
                  backgroundColor: 'white',
                  boxShadow: BOX_SHADOW,
                  borderRadius: '6px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '10px 10px 10px 0',
                  padding: '0 10px',
                }}
              >
                <NextCustomerDetails data={nextCustomer} />
              </div>
            )} */}
          </div>
          {/* Phiếu kết quả */}
          <>
            <div style={{ display: 'flex', flexFlow: 'nowrap' }}>
              <div style={{ width: '30%', padding: '0 0 10px 10px' }}>
                <Result
                  data={jobDetail}
                  callDetail={callDetail}
                  isLoading={isLoading}
                  customerDetail={customerDetail}
                  callStatusId={callStatusId}
                  setCallStatusId={setCallStatusId}
                  callNote={callNote}
                  setCallNote={setCallNote}
                  onChangeStatusId={value => handleChangeStatusId(value)}
                  onChangeAnswerList={(quest, value) =>
                    handleChangeAnswerList(quest, value)
                  }
                  setConvertedAnswerList={setConvertedAnswerList}
                  handleSubmitResult={() => handleSubmitResult()}
                  onOpenModalOpportunity={() => handleOpenModalOpportunity()}
                  newCustomerName={newCustomerName}
                  onChangeNewCustomerName={value => setNewCustomerName(value)}
                  shouldRecall={shouldRecall}
                  onChangeShouldRecall={value => setShouldRecall(value)}
                  convertedAnswerList={convertedAnswerList}
                  onOpenModalTimeRecall={() =>
                    parseInt(shouldRecall) === 1 &&
                    setModalRecallTimeVisible(true)
                  }
                  setJobList={setJobList}
                  newJobId={newJobId}
                  jobList={jobList}
                  setNewJobId={setNewJobId}
                  answerDate={answerDate}
                  setAnswerDate={setAnswerDate}
                  currentCall={call}
                  callId={callId}
                />
              </div>

              <div
                style={{
                  width: '70%',
                  padding: '0 10px 10px 10px',
                }}
              >
                <CustomerDetail
                  isLoading={isLoadingCustomerDetail}
                  setIsLoading={setIsLoadingCustomerDetail}
                  customerDetail={customerDetail}
                  setCustomerDetail={setCustomerDetail}
                  subPhones={subPhones}
                  setSubPhones={setSubPhones}
                  setDisplayUpdateCustomerModal={setDisplayUpdateCustomerModal}
                  idCustomer={customerDetail.id}
                />
              </div>
            </div>

            {/* Lịch sử tương tác khách hàng và kịch bản công việc */}
            <div
              style={{
                backgroundColor: 'white',
                margin: '0 10px',
                borderRadius: '6px',
                padding: '10px 24px 10px 24px',
                boxShadow: BOX_SHADOW,
              }}
            >
              <TabsStyle defaultActiveKey="1" style={{ width: '100%' }}>
                <TabPane
                  tab={
                    <div
                      style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        color: '#ef8737',
                      }}
                    >
                      Lịch sử tương tác
                    </div>
                  }
                  key="1"
                >
                  {customerDetail.id ? (
                    <div style={{ padding: '0 140px 0 160px' }}>
                      <HistoryCustomer
                        customerDetail={customerDetail}
                        loadingCustomerInteractionHistory={
                          loadingCustomerInteractionHistory
                        }
                        setViewMore={setViewMore}
                        viewMore={viewMore}
                        customerInteractionHistory={customerInteractionHistory}
                        cursorId={cursorId}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </TabPane>

                <TabPane
                  tab={
                    <div
                      style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        color: '#ef8737',
                      }}
                    >
                      Kịch bản
                    </div>
                  }
                  key="2"
                >
                  <Scenario data={jobDetail} />
                </TabPane>
              </TabsStyle>
            </div>

            <ModalAddOpportunity
              callId={callId}
              jobId={idJob}
              idCustomer={customerDetail.id}
              visible={modalAddOpportunityVisible}
              setVisible={setModalAddOpportunityVisible}
              onClose={() => handleCloseModalAddOpportunity()}
              onSave={() => handleSaveOpportunity()}
              opportunityList={opportunityList}
              productList={productList}
              opportunityId={opportunityId}
              productId={productId}
              onChangeOpportunity={value => setOpportunityId(value)}
              onChangeProduct={value => setProductId(value)}
              note={opportunityNote}
              onChangeNote={value => setOpportunityNote(value)}
              customerName={customerDetail?.name}
            />

            <ModalRecallTime
              visible={modalRecallTimeVisible}
              specificTimeRecall={specificTimeRecall}
              onChangeSpecificTimeRecall={value => setSpecificTimeRecall(value)}
              note={noteRecall}
              onChangeNote={value => setNoteRecall(value)}
              onClose={() => setModalRecallTimeVisible(false)}
              onSave={() => setModalRecallTimeVisible(false)}
              choice={recallChoice}
              onChangeChoice={value => setRecallChoice(value)}
            />
          </>
        </div>
      </div>
    </ContainerCallingStyled>
  );
}

export default SaleCallingScreen;
