const convertToTime = (value) => {
  let hour = parseInt(value / 3600000);
  let minute = ((value % 3600000) / 3600000) * 60;
  if (hour < 10) {
    hour = '0' + hour;
  }
  if (minute < 10) {
    minute = '0' + minute;
  }
  return hour + ':' + minute;
};

export { convertToTime };
