import styled from 'styled-components';

export const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const UpdateStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 20px;
  transition: all 0.3s ease-in-out;

  & .update {
    border-radius: 999px;
    background-color: #eadcf8;
    color: #7828c8;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    padding: 4px 10px;
  }

  & .text_update {
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
  }

  & .icon_update * {
    color: #7828c8 !important;
    position: relative;
  }

  &:hover {
    cursor: pointer;

    & .icon_update {
      transform: translateX(4px);
      scale: 1.1;
    }

    & .text_update {
      opacity: 0.7;
    }
  }
`;
