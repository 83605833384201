import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Empty, Input, message, Pagination, Row, Select, Skeleton } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { getSearchParamsInUrl, setSearchParamsInUrl } from '@constants/funcHelper';
import { requestGetTemplateZNS, requestSyncTemplateOA } from './services/api';
import { VietnameseString } from '@constants/VietnameseString';
import TemplateItem from './components/TemplateItem';
import Wrapper from '@components/ui/wrapper/Wrapper';
import { ROUTER } from '@constants/Constant';

import { BreadcrumbAntStyle, TagHyperlink } from '@styles/Style';
import { primaryColor } from '@styles/Color';

const STATUS = {
  APPROVED: 1,
  NOT_APPROVED: 0,
};

const InputSearch = styled(Input.Search)`
  margin: 10px 0 0 0;
`;

const SelectStatus = styled(Select)`
  margin: 10px 0 0 0;
  width: 100%;
`;

const ButtonAddTemplate = styled(Button)`
  margin: 10px 0 0 10px;
`;

const Heading = styled.h2`
  color: ${primaryColor};
`;

const { Option } = Select;

function ZaloOADetail(props) {
  const history = useHistory();

  const userInfo = useSelector((store) => store.userReducer.data);

  const [isLoading, setIsLoading] = useState(false);
  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 12,
  });
  const [params, setParams] = useState({
    oa_provider_id: '',
    status: '',
    search: '',
    page: paging.current,
    limit: paging.pageSize,
  });
  const [templates, setTemplates] = useState([]);

  const TAB_ID = 7;

  const syncTemplateOA = useCallback(async (params) => {
    try {
      setIsLoading(true);
      const payload = {
        enterprise_id: userInfo.enterprise_id,
        oa_provider_id: params.oa_provider_id,
      };
      const { msg } = await requestSyncTemplateOA(payload);
      getTemplateZNS(params);
      message.success(msg);
    } catch (error) {
      console.log('Error syncTemplateOA: ', { error });
    }
  }, []);

  const getTemplateZNS = useCallback(async (params) => {
    try {
      const { data, paging } = await requestGetTemplateZNS({
        enterprise_id: userInfo.enterprise_id,
        ...params,
      });
      setTemplates(data);
      setPaging({
        total: paging.count,
        current: paging.page,
        pageSize: paging.limit,
      });
    } catch (error) {
      console.log('Error getTemplateZNS: ', { error });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const debounceGetTemplateZNS = useCallback(_.debounce(getTemplateZNS, 500), []);

  useEffect(() => {
    const searchParamsInUrl = getSearchParamsInUrl();
    setParams({ ...params, ...searchParamsInUrl });
  }, []);

  useEffect(() => {
    if (params.oa_provider_id) {
      debounceGetTemplateZNS(params);
    }
  }, [params.search, params.status, params.page, params.oa_provider_id]);

  return (
    <>
      <div className="content-wrapper">
        <BreadcrumbAntStyle>
          <BreadcrumbAntStyle.Item
            onClick={() => {
              history.push({
                pathname: `${ROUTER.GENERAL_CONFIG}`,
                search: `tabId=${TAB_ID}`,
              });
            }}
          >
            <TagHyperlink style={{ cursor: 'pointer' }}>{VietnameseString.general_configuration}</TagHyperlink>
          </BreadcrumbAntStyle.Item>

          <BreadcrumbAntStyle.Item>{VietnameseString.list_of_templates}</BreadcrumbAntStyle.Item>
        </BreadcrumbAntStyle>
        <Wrapper>
          <Heading>{VietnameseString.list_of_templates}</Heading>
          <Row justify="space-between">
            <Filter
              params={params}
              onChangeFilter={(field, value) => {
                setParams({
                  ...params,
                  [field]: value,
                });
              }}
            />

            <ButtonAddTemplate
              type="primary"
              disabled={isLoading}
              onClick={() => {
                syncTemplateOA(params);
              }}
            >
              {VietnameseString.template_sync}
            </ButtonAddTemplate>
          </Row>
          <br />

          <p>
            {VietnameseString.result}: {paging.total}
          </p>

          <Row gutter={[16, 16]}>
            {isLoading ? (
              <Skeleton active />
            ) : templates?.length ? (
              templates.map((template) => {
                return (
                  <Col xxl={6} lg={8} md={12} sm={24} xs={24} key={template.id}>
                    <TemplateItem
                      data={template}
                      onClick={(event) => {
                        history.push({
                          pathname: ROUTER.DETAIL_TEMPLATE_ZNS,
                          search: new URLSearchParams({ id: template.id }).toString(),
                        });
                      }}
                    />
                  </Col>
                );
              })
            ) : (
              <Col span={24}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>{VietnameseString.no_data}</span>} />
              </Col>
            )}
          </Row>

          <Row justify="end" style={{ marginTop: '12px' }}>
            <Col>
              <Pagination defaultCurrent={paging.current} pageSize={paging.pageSize} total={paging.total} />
            </Col>
          </Row>
        </Wrapper>
      </div>
    </>
  );
}

const Filter = (props) => {
  const { params, onChangeFilter } = props;
  return (
    <Row gutter={[16, 16]} style={{ width: '80%' }}>
      <Col xxl={8} xl={8} lg={8} sm={12} xs={24}>
        <InputSearch
          placeholder="Tên, ID"
          allowClear
          value={params.search}
          onChange={(event) => {
            setSearchParamsInUrl('search', event.target.value);
            onChangeFilter('search', event.target.value);
          }}
        />
      </Col>
      <Col xxl={8} xl={8} lg={8} sm={12} xs={24}>
        <SelectStatus
          style={{ width: '100%', marginTop: 10 }}
          allowClear
          placeholder={VietnameseString.status}
          value={params.status !== '' ? params.status : undefined}
          onChange={(value) => {
            setSearchParamsInUrl('status', value !== undefined ? value : '');
            onChangeFilter('status', value !== undefined ? value : '');
          }}
        >
          <Option key={STATUS.APPROVED} value={STATUS.APPROVED}>
            {VietnameseString.activated}
          </Option>
          <Option key={STATUS.NOT_APPROVED} value={STATUS.NOT_APPROVED}>
            {VietnameseString.not_activated}
          </Option>
        </SelectStatus>
      </Col>
    </Row>
  );
};

Filter.propTypes = {
  params: PropTypes.object.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
};

export default ZaloOADetail;
