import { getAxios, handleResult } from '@constants/Api';

export const jobService = {
  getEvoucher: params => {
    return handleResult(
      getAxios.get(`evoucher/list`, {
        params,
      })
    );
  },

};
