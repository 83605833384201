import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '@styles/Settings.css';
import { Row, Col, FormControl, Button, Modal, FormGroup, Table } from 'react-bootstrap';
import { getListKeyword } from '@src/redux/actions';
import { connect } from 'react-redux';
import {
  //   getListKeyword,
  deleteKeyWord,
  createKeyWord,
  updateKeyWord,
} from '@constants/Api';
import { notifyFail, notifySuccess } from '@utils/notify';
import Loading from '@src/components/Loading';
import Pagination from 'react-js-pagination';
import reactotron from 'reactotron-react-js';
import { Empty } from 'antd';
import { throttle, debounce } from 'lodash';
let timeout;

class Keyword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalAddKeyWord: false,
      modaldeleteKeyWord: false,
      modalKeyWordField: {
        id: '',
        name: '',
      },
      keyWord: '',
      modalEditKeyWord: false,
      activePage: 1,
      isLoading: false,
      query: '',
      page: '',
      listKeyWord: [],
      disable: false,
    };
  }

  componentDidMount() {
    this.getData('', 1);
  }

  getData = (search, page) => {
    this.props.getListKeyword({
      search: search,
      page: page,
    });
  };

  addKeyWordFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalKeyWordField: {
        ...this.state.modalKeyWordField,
        [field]: value,
      },
    });
  };

  handlePressKeyword = (e) => {
    let { keyWord, activePage } = this.state;
    if (e.charCode === 13) {
      this.getData(keyWord, activePage);
    }
  };

  addModalKeyWord = () => {
    const { modalAddKeyWord, modalKeyWordField, modalEditKeyWord } = this.state;
    return (
      <Modal
        show={modalAddKeyWord}
        onHide={() => {
          this.setShowModal('modalAddKeyWord', false);
          this.setState({
            modalEditKeyWord: false,
            modalKeyWordField: {
              id: '',
              name: '',
            },
          });
        }}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton className={modalEditKeyWord ? 'bg-orange' : 'bg-blue'}>
          <h5 className="text-light pb-0">{modalEditKeyWord ? 'Sửa từ khóa' : 'Thêm từ khóa'}</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          {/* {this.checkValidationType()} */}
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Tên từ khóa</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="text"
                placeholder="Nhập nội dung"
                value={modalKeyWordField.name}
                onChange={(e) => this.addKeyWordFieldChange('name', e.target.value)}
              ></FormControl>
            </Row>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            disabled={this.state.disable}
            variant="success"
            className="text-light"
            onClick={() => {
              modalEditKeyWord ? this.updateKeyWord() : this.createKeyWord();
            }}
          >
            Thêm mới
          </Button>
          <Button
            variant="danger"
            className="text-light"
            onClick={() => {
              this.setShowModal('modalAddKeyWord', false);
              this.setState({
                modalEditKeyWord: false,
                modalKeyWordField: {
                  id: '',
                  name: '',
                },
              });
            }}
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  async deleteKeyWord(id) {
    this.setState({
      isLoading: true,
    });
    console.log(id);
    try {
      await deleteKeyWord({
        id: id,
      });
      this.setState({ modaldeleteKeyWord: false, isLoading: false }, () => {
        this.getData('', 1);
        notifySuccess('Xóa thành công!');
      });
      // this.props.deleteStaffAction(this.state.userId);
    } catch (err) {
      this.setState(
        {
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async createKeyWord() {
    this.setState({
      isLoading: true,
      disable: true,
    });
    const { name } = this.state.modalKeyWordField;
    try {
      await createKeyWord({ name: name });
      this.setState({ modalAddKeyWord: false, disable: false, isLoading: false }, () => {
        this.getData('', 1);
        notifySuccess('Thêm mới thành công!');
      });
    } catch (err) {
      this.setState(
        {
          disable: false,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async updateKeyWord() {
    this.setState({
      isLoading: true,
    });
    const { name, id } = this.state.modalKeyWordField;
    try {
      await updateKeyWord({
        id: id,
        name: name,
      });
      this.setState({ modalAddKeyWord: false, isLoading: false }, () => {
        this.getData('', 1);
        notifySuccess('Sửa thành công!');
      });
      // this.props.deleteStaffAction(this.state.userId);
    } catch (err) {
      this.setState(
        {
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  addModalDeleteKeyWord() {
    const { modaldeleteKeyWord } = this.state;
    const { id } = this.state.modalKeyWordField;
    return (
      <Modal
        show={modaldeleteKeyWord}
        onHide={() =>
          this.setState({
            modaldeleteKeyWord: false,
          })
        }
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton className="bg-red">
          <h5 className="text-light pb-0">Bạn chắc chắn muốn xóa từ khóa này?</h5>
        </Modal.Header>
        <Modal.Body className="custom-body">
          <Row>
            <Col className="button-wrapper" style={{ textAlign: 'center' }}>
              <Button
                variant="success"
                className="text-light"
                onClick={() => {
                  if (timeout) clearTimeout(timeout);
                  this.deleteKeyWord(id);
                }}
              >
                Có
              </Button>
              <Button
                variant="danger"
                className="text-light"
                onClick={() => {
                  this.setState({
                    modaldeleteKeyWord: false,
                  });
                }}
              >
                Thoát
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  handleChange(fieldName, value) {
    this.setState({
      ...this.state,
      [fieldName]: value || '',
    });
  }

  setShowModal = (fieldName, value) => {
    this.setState({
      ...this.state,
      [fieldName]: value || '',
    });
  };

  renderBody() {
    return <div>{this.renderKeyword()}</div>;
  }

  renderKeyword() {
    const { keyWord } = this.state;
    return (
      <>
        <Col sm={12} md={12}>
          <Row>
            <Col sm={12} className="d-flex justify-content-between">
              <h5>Từ khóa cuộc gọi</h5>
              <Button
                variant="success"
                className="text-light"
                onClick={() => this.setShowModal('modalAddKeyWord', true)}
              >
                Thêm từ khóa
              </Button>
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={12}>
          <Row>
            <Col md={6} sm={12}>
              <FormControl
                onKeyPress={this.handlePressKeyword}
                type="text"
                id=""
                placeholder="Nhập từ khóa"
                value={keyWord}
                onChange={(e) => this.handleChange('keyWord', e.target.value)}
              />
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={12}>
          <div className="row">
            <Col
              sm={10}
              md={12}
              style={{
                padding: '0px',
                marginTop: '20px',
              }}
            >
              <Row className="table-wrap mx-3">
                <Table striped bordered hover style={{ textAlign: 'center' }}>
                  <thead>
                    <tr>
                      <th>STT</th>
                      <th>Tên từ khóa</th>
                      <th>Ngày tạo</th>
                      <th></th>
                    </tr>
                  </thead>
                  {this.renderTableKeyWord()}
                </Table>
              </Row>
            </Col>
          </div>
        </Col>
      </>
    );
  }

  renderTableKeyWord() {
    const { listKeyWord } = this.state;
    return (
      <tbody>
        {this.props.keywordState.data?.length > 0 ? (
          this.props.keywordState.data?.map((value, index) => (
            <tr>
              <td>{index + 1 || '--'}</td>
              <td>{value.name || '--'}</td>
              <td>{Intl.DateTimeFormat('vi-VN').format(new Date(value.created_at)) || '--'}</td>
              <td>
                <i
                  className="btnEdit fa fa-fw fa-edit"
                  onClick={() => {
                    this.setState(
                      {
                        modalEditKeyWord: true,
                        modalKeyWordField: {
                          id: value.id,
                          name: value.name,
                        },
                      },
                      () => this.setShowModal('modalAddKeyWord', true)
                    );
                  }}
                />
                <i
                  className="btnDelete far fa-trash-alt"
                  onClick={() => {
                    this.setState({
                      modaldeleteKeyWord: true,
                      modalKeyWordField: {
                        id: value.id,
                      },
                    });
                  }}
                />
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colspan="4">
              <Empty description={<span>Không có dữ liệu</span>} />
            </td>
          </tr>
        )}
      </tbody>
    );
  }

  renderPagination() {
    const itemsCount = this.props.categoryState?.paging?.totalItemCount;
    const limit = this.props.categoryState?.paging?.limit;
    const { query } = this.state;
    return (
      <Pagination
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled
        activePage={this.state.activePage}
        itemsCountPerPage={limit}
        totalItemsCount={itemsCount}
        pageRangeDisplayed={5}
        hideNavigation
        onChange={(page) => {
          this.setState({
            ...this.state,
            activePage: page,
          });
          this.getData({ page, query });
        }}
      />
    );
  }

  render() {
    const { isLoading } = this.props.keywordState;
    let loading = <Loading />;
    if (!isLoading) {
      loading = null;
    }
    return (
      <>
        {loading}
        {this.addModalKeyWord()}
        {this.addModalDeleteKeyWord()}
        {this.renderBody()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  keywordState: state.keywordReducer,
});

const mapDispatchToProps = {
  getListKeyword,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Keyword));
