import { GET_HOTLINE, GET_HOTLINE_SUCCESS, GET_HOTLINE_FAIL } from '@actions/type';

const initialState = {
  data: {},
  isLoading: true,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_HOTLINE:
      return {
        ...state,
        isLoading: true,
      };
      break;
    case GET_HOTLINE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.response,
        error: null,
      };
      break;
    case GET_HOTLINE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      return state;
  }
}
