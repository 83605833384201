import React from 'react';
import { Col, DatePicker, Input, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ButtonSystemStyle } from '@styles/Style';

const { Search } = Input;
const { RangePicker } = DatePicker;

const WrapperFilterContent = styled.div`
  display: flex;

  .input-search {
    width: 500px;

    + .range-picker {
      margin-left: 12px;
    }
  }

  .range-picker {
    width: 300px;
  }
`;

function Filter(props) {
  const {
    placeholderTextSearch,
    onChangeInputSearch,
    onChangeRangePicker,
    onChangeRangePickerStartEnd,
    titleButton,
    onClick,
    filterStartEndDate,
  } = props;
  return (
    <Row gutter={[16, 16]} justify="space-between" style={{ marginBottom: '12px' }}>
      <Col span={18}>
        <WrapperFilterContent>
          <Search
            className="input-search"
            placeholder={placeholderTextSearch || 'Nhập nội dung tìm kiếm'}
            onChange={(event) => onChangeInputSearch(event?.target?.value?.trim())}
            onSearch={onChangeInputSearch}
          />

          <RangePicker placeholder={['Từ ngày', 'Đến ngày']} className="range-picker" onChange={onChangeRangePicker} />

          {filterStartEndDate && (
            <RangePicker
              style={{ marginLeft: '14px' }}
              placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
              className="range-picker"
              onChange={onChangeRangePickerStartEnd}
            />
          )}
        </WrapperFilterContent>
      </Col>

      <Col style={{ display: 'flex', justifyContent: 'end' }} span={6}>
        <ButtonSystemStyle onClick={onClick}>
          <PlusOutlined style={{ marginRight: '10px' }} />
          {titleButton}
        </ButtonSystemStyle>
      </Col>
    </Row>
  );
}

Filter.propTypes = {
  placeholderTextSearch: PropTypes.string,
  onChangeInputSearch: PropTypes.func.isRequired,
  onChangeRangePicker: PropTypes.func.isRequired,
  titleButton: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Filter;
