import { Modal } from 'antd';
import styled from 'styled-components';

export const ModalModulesStyled = styled(Modal)`
  transition: all 0.3s ease-in-out;

  & * {
    font-family: 'Quicksand', sans-serif;
  }

  .ant-modal-content {
    border-radius: 12px !important;
    padding-top: 8px;
  }

  .ant-modal-content:hover {
    /* scale: 1.1; */
  }

  & strong {
    font-size: 26px;
  }

  & h3 {
    text-align: center;
    margin-bottom: 6px;
  }

  & p {
    font-weight: 600;
    text-align: center;
    color: #999;
  }

  & .row_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  & .btn_action {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
  }

  & .btn_accept {
    background-color: green;
  }
  & .btn_accept * {
    font-size: 20px;
  }

  & .btn_cancel {
    background-color: red;
  }
  & .btn_cancel * {
    font-size: 20px;
  }

  & .btn_action:hover {
    opacity: 0.8;
    scale: 1.1;
  }

  & .btn_close {
    position: absolute;
    top: 12px;
    right: 17px;
    background-color: #f1f1f1;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
  }
  & .btn_close:hover {
    opacity: 0.8;
  }
`;
