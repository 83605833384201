import { getAxios, handleResult } from '@constants/Api';

export const requestGetMessageHistory = payload => {
  return handleResult(
    getAxios.get(`sms/messageHistory`, {
      params: new URLSearchParams(payload),
    })
  );
};

export const requestExportMessageHistory = payload => {
  return handleResult(
    getAxios.get(`sms/exportmessageHistory`, {
      params: new URLSearchParams(payload),
    })
  );
};

export const requestStaticMessageHistory = payload => {
  return handleResult(
    getAxios.get(`sms/reportMessageHistory`, {
      params: new URLSearchParams(payload),
    })
  );
};

export const requestResendMessage = resendMessageIds => {
  return handleResult(
    getAxios.post(`sms/send-again`, {
      message_history_ids: resendMessageIds,
    })
  );
};

/**
 * requestStaticZNSHistory
 * @param {enterprise_id, search, template_id, oa_provider_id, status, job_id, from_date, to_date} params
 * @returns Promise
 */
export const requestStaticZNSHistory = params => {
  return handleResult(
    getAxios.get('zns/reportMessageHistory', {
      params: new URLSearchParams(params),
    })
  );
};

/**
 * requestGetZNSHistory
 * @param {enterprise_id, search, template_id, oa_provider_id, status, job_id, from_date, to_date, page, limit} params
 * @returns Promise
 */
export const requestGetZNSHistory = params => {
  return handleResult(
    getAxios.get('zns/messageHistory', {
      params: new URLSearchParams(params),
    })
  );
};
/**
 * requestExportZNSHistory
 * @param {enterprise_id, search, template_id, oa_provider_id, status, job_id, from_date, to_date} params
 * @returns Promise
 */
export const requestExportZNSHistory = params => {
  return handleResult(
    getAxios.get('zns/exportMessageHistory', {
      params: new URLSearchParams(params),
    })
  );
};

/**
 *
 * @param {message_history_ids} payload
 * @returns
 */
export const requestResendZNS = payload => {
  return handleResult(getAxios.post('zns/sendAgain', payload));
};

export const requestGetZaloSendingHistory = params => {
  const paramsFilter = {
    ...params,
    search: params?.search ? params.search.trim() : '',
  };
  return handleResult(
    getAxios.get('sms/messageZaloHistory', {
      params: new URLSearchParams(paramsFilter),
    })
  );
};

export const getReportMsgZaloHistory = params => {
  const paramsFilter = {
    ...params,
    search: params?.search ? params.search.trim() : '',
  };
  return handleResult(
    getAxios.get('sms/reportMessageZaloHistory', {
      params: new URLSearchParams(paramsFilter),
    })
  );
};

export const requestExportZaloSendingHistory = params =>
  handleResult(
    getAxios.get('sms/exportMessageZaloHistory', {
      params: new URLSearchParams(params),
    })
  );
