import React from 'react';
import { Col, DatePicker, Row, Select } from 'antd';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { requestGetReportCallInJob } from '@screens/Enterprise/Job/services/api';
import { VietnameseString } from '@constants/VietnameseString';
import { CALL_STATUS } from '@constants/Constant';

import { TableBorderedAntStyle } from '@styles/Style';

const { RangePicker } = DatePicker;
const { Column, ColumnGroup } = TableBorderedAntStyle;

const formatDateTime = 'YYYY/MM/DD';
const widthColumnInTable = 120;
const noneRation = [VietnameseString.average];

function renderValueColumn(value, record, showRatio = true) {
  let ratio = 0;

  if (value) {
    ratio = Number((value / record.total) * 100).toFixed(2);
  }

  if (showRatio && !noneRation.includes(record.title)) {
    return (
      <span>
        <span style={{ color: 'blue' }}>{value}</span> | {ratio}%
      </span>
    );
  } else {
    return <span style={{ color: 'blue' }}>{value}</span>;
  }
}

function renderValueColumQuestion(value, record, answer) {
  let ratio = 0;
  const temp = value?.find(i => i.id === answer.id);
  if (temp) {
    if (record.total === 0) {
      ratio = 0;
    } else {
      ratio = Number((temp?.count / record.total) * 100).toFixed(2);
    }
  }

  if (!noneRation.includes(record.title)) {
    return (
      <span>
        <span style={{ color: 'blue' }}>{temp ? temp.count : 0}</span> | {ratio}
        %
      </span>
    );
  } else {
    return <span style={{ color: 'blue' }}>{temp ? temp.count : 0}</span>;
  }
}

function General(props) {
  const {
    jobInfo,
    generalParams,
    handleChangeGeneralParams,
    handleNavigatingToCustomersTab,
    handleNavigatingToCallList,
  } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [dataTable, setDataTable] = React.useState([]);
  const [dataQuestion, setDataQuestion] = React.useState({
    question: '',
    answer: [],
  });

  const columns = [
    {
      title: '',
      width: widthColumnInTable,
      fixed: 'left',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Tổng cuộc gọi',
      width: widthColumnInTable,
      dataIndex: 'total',
      key: 'total',
      render: (value, record) => renderValueColumn(value, record),
      onCell: record => {
        return {
          onClick: () => {
            handleNavigatingToCustomersTab({
              generalParams,
              record,
            });
          },
        };
      },
    },
    {
      title: VietnameseString.busy_call_back_later,
      width: widthColumnInTable,
      dataIndex: 'callBusyCallBackLater',
      key: 'callBusyCallBackLater',
      render: (value, record) => renderValueColumn(value, record),
      onCell: record => {
        return {
          onClick: () => {
            handleNavigatingToCustomersTab({
              generalParams,
              record,
              callStatus: CALL_STATUS.BUSY_CALL_BACK_LASTER,
            });
          },
        };
      },
    },
    {
      title: VietnameseString.do_not_answer_the_phone,
      width: widthColumnInTable,
      dataIndex: 'callNotAnswer',
      key: 'callNotAnswer',
      render: (value, record) => renderValueColumn(value, record),
      onCell: record => {
        return {
          onClick: () => {
            handleNavigatingToCustomersTab({
              generalParams,
              record,
              callStatus: CALL_STATUS.DO_NOT_ANSWER_THE_PHONE,
            });
          },
        };
      },
    },
    {
      title: VietnameseString.wrong_information,
      width: widthColumnInTable,
      dataIndex: 'callWrong',
      key: 'callWrong',
      render: (value, record) => renderValueColumn(value, record),
      onCell: record => {
        return {
          onClick: () => {
            handleNavigatingToCustomersTab({
              generalParams,
              record,
              callStatus: CALL_STATUS.WRONG_INFORMATION,
            });
          },
        };
      },
    },
    {
      title: VietnameseString.agree_to_exchange,
      width: widthColumnInTable,
      dataIndex: 'callAgree',
      key: 'callAgree',
      render: (value, record) => renderValueColumn(value, record),
      onCell: record => {
        return {
          onClick: () => {
            handleNavigatingToCustomersTab({
              generalParams,
              record,
              callStatus: CALL_STATUS.AGREE_TO_EXCHANGE,
            });
          },
        };
      },
    },
    {
      title: VietnameseString.unreported,
      width: widthColumnInTable,
      dataIndex: 'callNotUpdateResult',
      key: 'callNotUpdateResult',
      render: (value, record) => renderValueColumn(value, record),
    },
    {
      title: VietnameseString.call_back_appointment,
      width: widthColumnInTable,
      dataIndex: 'callBackAppointment',
      key: 'callBackAppointment',
      render: (value, record) => renderValueColumn(value, record, false),
      onCell: record => {
        return {
          onClick: () => {
            handleNavigatingToCustomersTab({
              generalParams,
              record,
              callStatus: CALL_STATUS.CALL_BACK_APPOINTMENT,
            });
          },
        };
      },
    },
    {
      title: VietnameseString.picked_up_the_phone,
      width: widthColumnInTable,
      dataIndex: 'callDurationGreat0s',
      key: 'callDurationGreat0s',
      render: (value, record) => renderValueColumn(value, record),
    },
    {
      title:
        VietnameseString.customer_called +
        '/' +
        VietnameseString.total_customer,
      width: widthColumnInTable,
      dataIndex: 'callCalled',
      key: 'callCalled',
      render: (value, record) => {
        // const total = dataTable.find((el) => (el.title = VietnameseString.total_number_of_calls));

        if (noneRation.includes(record.title)) {
          return (
            <span>
              <span style={{ color: 'blue' }}>{value}</span>
            </span>
          );
        } else {
          return (
            <span>
              <span style={{ color: 'blue' }}>{value}</span> |{' '}
              {record.callCountCustomer}
            </span>
          );
        }
      },
    },
    {
      title: VietnameseString.total_call_time + '(s)',
      width: widthColumnInTable,
      dataIndex: 'totaTimeCall',
      key: 'totaTimeCall',
      render: (value, record) => renderValueColumn(value, record, false),
    },
    {
      title: 'Số cuộc gọi trên 10s',
      width: widthColumnInTable,
      dataIndex: 'callDurationGreat10s',
      key: 'callDurationGreat10s',
      render: (value, record) => renderValueColumn(value, record),
    },
  ];

  const getReportCallInJob = React.useCallback(async function (
    params = generalParams
  ) {
    try {
      setIsLoading(true);
      const { data } = await requestGetReportCallInJob(params);

      const data_table = [
        { title: VietnameseString.total_number_of_calls, ...data.dataTotal },
        { title: VietnameseString.average, ...data.dataAvg },
      ];

      for (const [key, value] of Object.entries(data.result)) {
        data_table.push({ ...value, title: moment(key).format('DD/MM/YYYY') });
      }

      setDataTable(data_table);

      setDataQuestion({
        question: data.question,
        answer: data.jobAnswer,
      });
    } catch (error) {
      console.log('Error getReportCallInJob: ', { error });
    } finally {
      setIsLoading(false);
    }
  },
  []);

  //kiểm tra nếu ngày đầu tuần nhỏ hơn ngày tạo giao việc -> set lại from bằng ngày tạo giao việc
  React.useEffect(() => {
    const timestamp1 = moment(new Date()).startOf('week').unix();
    const timestamp2 = moment(jobInfo.created_at).unix();

    if (jobInfo.created_at && timestamp2 > timestamp1) {
      handleChangeGeneralParams({
        from: moment(jobInfo.created_at).format(formatDateTime),
      });
    }
  }, [handleChangeGeneralParams, jobInfo.created_at]);

  React.useEffect(() => {
    if (jobInfo.job_id) {
      getReportCallInJob(generalParams);
    }
  }, [generalParams, getReportCallInJob, jobInfo.job_id]);

  return (
    <div>
      <Filter
        jobInfo={jobInfo}
        params={generalParams}
        handleChangeParam={handleChangeGeneralParams}
      />

      <TableBorderedAntStyle
        style={{ marginTop: '16px' }}
        loading={isLoading}
        scroll={{
          x: 'max-content',
          y: 450,
        }}
        dataSource={dataTable}
        pagination={false}
      >
        {columns.map((col, index) => (
          <Column key={index} {...col} />
        ))}
        {dataQuestion.question?.name && (
          <ColumnGroup title={dataQuestion.question?.name}>
            {dataQuestion.answer.map(answer => (
              <Column
                title={answer.name}
                dataIndex="callQuestion"
                key="callQuestion"
                width={widthColumnInTable}
                render={(value, record) =>
                  renderValueColumQuestion(value, record, answer)
                }
                onCell={record => {
                  return {
                    onClick: () => {
                      if (generalParams.telesale_id) {
                        handleNavigatingToCallList({
                          generalParams,
                          record,
                          dataQuestion,
                          answer,
                        });
                      }
                    },
                  };
                }}
              />
            ))}
          </ColumnGroup>
        )}
      </TableBorderedAntStyle>
    </div>
  );
}

function Filter(props) {
  const { jobInfo, params, handleChangeParam } = props;

  const userInfo = useSelector(store => store.userReducer.data);

  return (
    <Row gutter={[16, 16]}>
      <Col xxl={4} xl={4} lg={6} md={12} sm={12} xs={12}>
        <Select
          style={{ width: '100%' }}
          value={params.telesale_id}
          onChange={value => {
            handleChangeParam({ telesale_id: value });
          }}
        >
          <Select.Option value={userInfo.user_id}>
            {userInfo.full_name}
          </Select.Option>

          <Select.Option value="">Tất cả</Select.Option>
        </Select>
      </Col>

      <Col xxl={8} xl={12} lg={12} md={12} sm={12} xs={12}>
        <RangePicker
          style={{ width: '100%' }}
          allowClear
          value={
            params.from && params.to && [moment(params.from), moment(params.to)]
          }
          disabledDate={current => {
            return (
              current &&
              (current < moment(jobInfo?.created_at).startOf('day') ||
                current > moment())
            );
          }}
          onChange={dates => {
            dates
              ? handleChangeParam({
                  from: moment(dates[0]).format(formatDateTime),
                  to: moment(dates[1]).format(formatDateTime),
                })
              : handleChangeParam({
                  from: '',
                  to: '',
                });
          }}
        />
      </Col>
    </Row>
  );
}

General.propTypes = {
  jobInfo: PropTypes.object.isRequired,
  generalParams: PropTypes.shape({
    telesale_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    from: PropTypes.string,
    to: PropTypes.string,
    job_id: PropTypes.string,
    sub_job_id: PropTypes.arrayOf(PropTypes.number),
    department_id: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  handleChangeGeneralParams: PropTypes.func.isRequired,
  handleNavigatingToCustomersTab: PropTypes.func.isRequired,
  handleNavigatingToCallList: PropTypes.func.isRequired,
};

Filter.propTypes = {
  jobInfo: PropTypes.object.isRequired,
  params: PropTypes.shape({
    telesale_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    from: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
  handleChangeParam: PropTypes.func.isRequired,
};

export default General;
