import React, { Component } from 'react';
import '@styles/Customer.css';
import Table from 'react-bootstrap/Table';
import { Row, Col } from 'react-bootstrap';
import { requestGetReviewerDetail, requestVehiclesList, requestGetReviewerTasks } from '@constants/Api';
import { getReviewerTask } from '@src/redux/actions';
import reactotron from 'reactotron-react-js';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import { checkAllAttrFilled } from '@utils/checkAllAttrFilled';
import { notifyFail, notifySuccess } from '@utils/notify';
import { ROUTER, PHONE_REGEX, EMAIL_REGEX, ACTIVE_STATUS } from '@constants/Constant';
import Loading from '@src/components/Loading';
import { getItemFromId } from '@utils/getItemFromId';
import { Empty } from 'antd';

class EvaluationDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      form: {
        telesale_name: '',
        job_name: '',
        status: '',
        reviewed: '',
        status_name: '',
        remain: '',
        start_date: '',
        end_date: '',
      },
      detail: {},
      listJob: {},
      isLoading: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.getData();
    this.getReviewerTask(id);
    // this.getTask();
  }

  async getData() {
    const { id } = this.props.match?.params;
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const detail = await requestGetReviewerDetail({ id: id });
      this.setState({
        ...this.state,
        detail: detail.data,
        isLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async getReviewerTask(id) {
    try {
      const res = await requestGetReviewerTasks({ id });
      this.setState({ listJob: res.data });
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  // getTask() {
  //   const { id } = this.props.match?.params;
  //   this.props.getReviewerTask({ id: id });
  // }

  renderBody() {
    return (
      <div className="content-wrapper">
        <div className="content-header">
          <Row>
            <Col md={12}>{this.renderInfo()}</Col>
          </Row>
          <br />
          <Row>
            <Col md={12}>{this.renderTable()}</Col>
          </Row>
        </div>
      </div>
    );
  }

  renderInfo() {
    const { detail } = this.state;
    const status = getItemFromId(ACTIVE_STATUS, detail.status);
    return (
      <>
        <div>
          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px' }}>
            <Col md className="bg">
              <Row>
                <Col md={6} sm={12} className="px-0">
                  {this.renderButton()}
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={6} sm={12}>
                  <Row className="info-row">
                    <Col>Họ tên:</Col>
                    <Col>{detail?.full_name || '--'}</Col>
                  </Row>
                  <Row className="info-row">
                    <Col>Số điện thoại:</Col>
                    <Col>{detail?.user_name || '--'}</Col>
                  </Row>
                  <Row className="info-row">
                    <Col>Email:</Col>
                    <Col>{detail?.email || '--'}</Col>
                  </Row>
                  <Row className="info-row">
                    <Col>Ngày sinh:</Col>
                    <Col>{(detail?.dob && Intl.DateTimeFormat('vi-VN').format(new Date(detail?.dob))) || '--'}</Col>
                  </Row>
                </Col>
                <Col md={6} sm={12}>
                  <Row className="info-row">
                    <Col>Giới tính:</Col>
                    <Col>{detail.gender ? (detail.gender === 1 ? 'Nam' : 'Nữ') : '--'}</Col>
                  </Row>
                  <Row className="info-row">
                    <Col>Thành phố:</Col>
                    <Col>{(detail && detail.province_name) || '--'}</Col>
                  </Row>
                  <Row className="info-row">
                    <Col>Địa chỉ cụ thể:</Col>
                    <Col>{(detail && detail.address) || '--'}</Col>
                  </Row>
                  <Row className="info-row">
                    <Col>Trạng thái:</Col>
                    <Col>{(status && status.name) || '--'}</Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  renderButton() {
    return (
      <div>
        {/* <a
          className="driver-name-block"
          onClick={() => this.props.history.goBack()}
        >
          <i
            className="header icon-back fas fa-angle-left"
            style={{ fontSize: "30px", fontWeight: "600" }}
          />
          <span
            className="lead header driver-name"
            style={{ fontSize: "30px", fontWeight: "500" }}
          >
            Chi tiết nhân viên đánh giá
          </span>
        </a> */}

        <Row>
          <h3>Chi tiết nhân viên đánh giá</h3>
        </Row>
      </div>
    );
  }

  renderTable() {
    return (
      <div className="w-100 table-wrap">
        <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 20 }}>
          <Col md className="bg">
            <h3>Công việc</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Tên nhân viên</th>
                  <th>Công việc</th>
                  <th>Sale</th>
                  <th>Số lượng</th>
                  <th>Đã hoàn thành</th>
                  <th>Trạng thái</th>
                  <th>Thời gian</th>
                </tr>
              </thead>
              <tbody>{this.renderTableData()}</tbody>
            </Table>
          </Col>
        </Row>
      </div>
    );
  }
  renderTableData() {
    const { listJob } = this.state;
    reactotron.log(listJob);
    return (
      <>
        {listJob?.length > 0 ? (
          listJob?.map((value, index) => (
            <tr>
              <td>{index + 1 || '--'}</td>
              <td>{value?.full_name || '--'}</td>
              <td>{value?.job_name || '--'}</td>
              <td>{value?.telesale_name || '--'}</td>
              <td>{value?.amount || '--'}</td>
              <td>{value?.reviewed || '--'}</td>
              <td>
                {value?.status == 1
                  ? 'Đang thực hiện'
                  : value?.status == 0
                  ? 'Đã giao'
                  : value?.status == 2
                  ? 'đã hoàn thành'
                  : 'quá hạn' || '--'}
              </td>
              <td>
                {Intl.DateTimeFormat('vi-VN').format(new Date(value?.start_date)) +
                  ' - ' +
                  Intl.DateTimeFormat('vi-VN').format(new Date(value?.end_date))}
              </td>
            </tr>
          ))
        ) : (
          <td colSpan={9}>
            <Empty description="Không có dữ liệu"></Empty>
          </td>
        )}
      </>
    );
  }
  render() {
    return (
      <>
        {this.state.isLoading ? <Loading /> : null}
        {this.renderBody()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  provinceState: state.ProvinceReducer,
  taskState: state.reviewerTaskReducer,
});
const mapDispatchToProps = {
  getReviewerTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EvaluationDetail));
