import { notifyFail } from '@utils/notify';
import { sendCallBroadCast } from './BroadCastUtil';
import { CALLING_CODE, CLIENT_STATUS, PROVIDER_NAME } from './CallConstant';
import { isCallEnded } from './CallHelper';
import JsSIPCallClient from './JsSIP/JsSIPCallClient';
import JsSIPUtil from './JsSIP/JsSIPUtil';
import StringeeUtil from './Stringee/StringeeUtil';

class Call {
  currentCall = {
    name: 'unknown',
    status: CALLING_CODE.Init,
    data: {},
  };
  currentCallId = 0;
  currentJobId = null;
  currentSearch = null;
  currentCustomerId = null;
  currentClient = {
    [PROVIDER_NAME.ITY]: CLIENT_STATUS.notInit,
    [PROVIDER_NAME.STRINGEE]: CLIENT_STATUS.notInit,
  };
  hotlines = [];
  isUncontacted = false;

  // save lastCall for recall
  lastCallInfo = {
    provider: '',
    destination: '',
    callId: 0,
    masked: '',
    jobId: 0,
    isUncontacted: false,
    lastStartCallPayload: null,
  };

  //lấy hotline được chọn mặc định từ API
  getDefaultHotlines(isNeedCheckUndefined = false) {
    const hotline = this.hotlines.find(hl => hl.is_default_hotline);
    if (hotline) {
      return hotline;
    } else if (!isNeedCheckUndefined) {
      return this.hotlines[0];
    } else {
      return undefined;
    }
  }

  cancelAutoNext() {
    this.currentSearch = null;
  }

  sendDtmf(dtmf) {
    switch (this.currentCall.name) {
      case 'stringee':
        StringeeUtil.stringeeCallClient.sendDtmf(dtmf);
        break;

      default:
        break;
    }
  }

  makeCall(
    provider,
    destination,
    callId,
    masked = null,
    jobId = null,
    isUncontacted = false,
    lastStartCallPayload = null
  ) {
    this.lastCallInfo = {
      provider,
      destination,
      callId,
      masked,
      jobId,
      isUncontacted,
      lastStartCallPayload,
    };
    try {
      if (jobId != this.currentJobId) {
        this.cancelAutoNext();
      }
      this.isUncontacted = isUncontacted;
      this.currentCallId = callId;
      this.currentJobId = jobId;

      if (!isCallEnded(this.currentCall.status)) {
        try {
          this.hangupCall();
        } catch (error) {
          console.log(error);
        }
      }

      console.log('====makeCall====', this.currentCall, {
        provider,
        destination,
        callId,
        masked,
        jobId,
        isUncontacted,
        lastStartCallPayload,
      });
      switch (provider) {
        case 'windsoft':
        case 'ity':
          if (
            this.currentClient[PROVIDER_NAME.ITY] !== CLIENT_STATUS.registered
          ) {
            notifyFail(
              'Dịch vụ tạm thời bị gián đoạn hoặc không khả dụng. (Auth Error)'
            );
            throw new Error('Authentication error');
          }

          JsSIPUtil.jsSIPCallClient.makeCall(destination, callId, masked);
          sendCallBroadCast({
            name: 'ITY',
            status: CALLING_CODE.Preparing,
            data: { destination, callId, masked, jobId },
          });
          break;

        default:
          if (
            this.currentClient[PROVIDER_NAME.STRINGEE] !==
            CLIENT_STATUS.registered
          ) {
            notifyFail(
              'Dịch vụ tạm thời bị gián đoạn hoặc không khả dụng. (Auth Error)'
            );
            throw new Error('Authentication error');
          }
          StringeeUtil.stringeeCallClient.makeCall(destination, callId, masked);
          sendCallBroadCast({
            name: 'stringee',
            status: CALLING_CODE.Preparing,
            data: { destination, callId, masked },
          });
          break;
      }
      return true;
    } catch (error) {
      return false;
    }
  }
  hangupCall() {
    switch (this.currentCall.name) {
      case 'stringee':
        StringeeUtil.stringeeCallClient.hangupCall();
        break;

      default:
        JsSIPUtil.jsSIPCallClient.hangupCall();
        break;
    }
  }
}

const CallUtil = new Call();
export default CallUtil;
