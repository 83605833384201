import React, { useState, useEffect } from 'react';
import '@styles/Settings.css';
import { Modal } from 'react-bootstrap';
import {
  Button as ButtonAntd,
  Input,
  InputNumber,
  Select,
  Form,
  Popconfirm,
} from 'antd';
import {
  MinusCircleOutlined,
  PlusOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import {
  requestCreateCustomerAttribute,
  requestUpdateCustomerAttribute,
  requestRemoveCustomerAttribute,
} from '@constants/Api';
import './style.css';
import { notifyFail } from '@utils/notify';
import { removeVietnameseDiacritics } from './fnhelper';

const { Option } = Select;

const attributeTypes = [
  { value: 'options', label: 'Select' },
  { value: 'number', label: 'Number' },
  { value: 'date_time', label: 'Datetime' },
  { value: 'text', label: 'Text' },
];

const NOT_DELETE_ALL = 0;

const AddUpdateAttribute = ({
  shouldAddAttribute,
  data,
  formattedPaging,
  onSuccess,
  onError,
  onClose,
  modalVisible,
}) => {
  const [selectedType, setSelectedType] = useState('');
  const [idArray, setIdArray] = useState(
    !shouldAddAttribute
      ? data.choices.length
        ? data.choices.map(item => item.id)
        : []
      : []
  );
  const [listDeleteAttributeOption, setListDeleteAttributeOption] = useState(
    []
  );
  const [deleteAttributeId, setDeleteAttributeId] = useState(NOT_DELETE_ALL);
  const [isFilter, setIsFilter] = useState(
    !shouldAddAttribute && data.filterable
  );
  const [isNoneChoice, setIsNoneChoice] = useState();
  const [form] = Form.useForm();

  useEffect(() => {
    setSelectedType(!shouldAddAttribute ? data.attributeType : null);
  }, []);

  useEffect(() => {
    setIsNoneChoice(idArray.length ? false : true);
  }, []);

  function handleChangeNameAttr(e) {
    let valueNameAtr = e.target.value;
    let valueAtrCode = removeVietnameseDiacritics(valueNameAtr);
    console.log(
      `valueNameAtr`,
      valueNameAtr,
      valueAtrCode,
      /^[\w_-]*$/u.test(valueAtrCode)
    );
    form.setFieldsValue({
      data_customer_code: valueAtrCode,
    });
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const formItemLayoutWithOutLabel = {
    labelCol: {
      xs: { span: 10 },
      sm: { span: 7 },
    },
    wrapperCol: {
      xs: { span: 14 },
      sm: { span: 16 },
    },
  };

  const formItemLayoutWithOutLabelAddChoice = {
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16, offset: 8 },
    },
  };

  const formItemLayoutWithOutLabelButton = {
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24, offset: 7 },
    },
  };

  let initialValues = {
    attributeName: '',
    attributeType: null,
    choices: [],
    numericalOrder: formattedPaging?.total + 1,
    filterVisible: false,
  };

  if (!shouldAddAttribute) {
    initialValues = {
      ...data,
      choices: data.choices.length ? data.choices.map(item => item.value) : [],
      filterVisible: false,
    };
  }

  console.log(`initialValue`, initialValues);

  const handleChangeSelectType = value => {
    setSelectedType(value || '');
    form.setFieldsValue({ choices: [] });
  };

  const deleteAttributeCustomer = async () => {
    const payload = {
      customer_data_field_source_id: listDeleteAttributeOption,
      customer_data_field_id: deleteAttributeId,
    };
    try {
      const res = await requestRemoveCustomerAttribute(payload);
    } catch (error) {
      console.log('errorerror', error);
    }
  };

  const handleSave = values => {
    if (selectedType === 'options' && isNoneChoice) {
      notifyFail('Vui lòng chọn ít nhất 1 loại thuộc tính');
    } else {
      deleteAttributeCustomer();
      onFinish(values);
    }
  };

  const onFinish = async values => {
    // debugger
    console.log(`values`, values);

    try {
      const payload = {
        name: values.attributeName,
        data_type: values.attributeType || 'ref',
        position: values.numericalOrder,
        filterable: isFilter ? 1 : 0,
        data_customer_code: values.data_customer_code,
      };
      let res = null;

      if (shouldAddAttribute) {
        payload.options = values.choices || [];
        res = await requestCreateCustomerAttribute(payload);
      } else {
        payload.id = data.id;
        payload.is_active = values.status;
        let newArr = [];
        if (values.choices && values.choices.length) {
          newArr = values.choices.map((item, index) => {
            return {
              id: idArray[index] || 0,
              value: item,
            };
          });
        }
        payload.options = newArr;
        res = await requestUpdateCustomerAttribute(payload);
      }
      form.resetFields();
      onSuccess(res);
      setSelectedType(null);
    } catch (error) {
      onError(error);
    }
  };

  return (
    <Modal
      show={modalVisible}
      onHide={() => {
        onClose();
        form.resetFields();
        setSelectedType(null);
      }}
      dialogClassName="modal-100w"
      centered
    >
      <Modal.Header closeButton className="bg-primary">
        <h5 className="text-light pb-0">
          {shouldAddAttribute ? 'Thêm thuộc tính' : 'Sửa thuộc tính'}
        </h5>
      </Modal.Header>

      <Modal.Body className="custom-body pb-0">
        <Form
          form={form}
          {...formItemLayoutWithOutLabel}
          initialValues={initialValues}
          onFinish={handleSave}
          autoComplete="off"
          style={{ width: '100%' }}
        >
          <Form.Item
            labelAlign="left"
            label="Tên thuộc tính"
            name="attributeName"
            rules={[
              {
                required: data.data_type !== 'ref',
                message: 'Vui lòng nhập tên thuộc tính!',
              },
            ]}
          >
            <Input
              placeholder="Nhập tên thuộc tính"
              style={{ width: '280px', marginLeft: 10 }}
              disabled={data.data_type === 'ref' ? true : false}
              onChange={handleChangeNameAttr}
            />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label="Mã thuộc tính"
            name="data_customer_code"
            rules={[
              { required: true, message: 'Vui lòng nhập mã thuộc tính!' },
              { pattern: /^[\w_-]*$/u, message: 'Mã thuộc tính không hợp lệ' },
            ]}
          >
            <Input
              placeholder="Nhập mã thuộc tính"
              style={{ width: '280px', marginLeft: 10 }}
              disabled={data.data_type === 'ref' ? true : false}
            />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label="Loại thuộc tính"
            name="attributeType"
            rules={[
              {
                required: data.data_type !== 'ref',
                message: 'Vui lòng chọn loại thuộc tính!',
              },
            ]}
          >
            <Select
              style={{ width: '280px', marginLeft: 10 }}
              placeholder="Chọn loại thuộc tính"
              allowClear
              disabled={
                (!shouldAddAttribute && data?.customer_data?.length !== 0) ||
                data.data_type === 'ref'
              }
              // value={attributeModal.type}
              onChange={value => {
                handleChangeSelectType(value);
              }}
            >
              {attributeTypes.map(item => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {selectedType === 'options' && (
            <Form.List name="choices">
              {(fields, { add, remove }, { errors }) => {
                return (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        labelAlign="left"
                        {...(index === 0
                          ? formItemLayout
                          : formItemLayoutWithOutLabelAddChoice)}
                        label={index === 0 ? 'Lựa chọn' : ''}
                        required={true}
                        key={field.key}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: data.data_type !== 'ref',
                              whitespace: true,
                              message: 'Vui lòng nhập lựa chọn!',
                            },
                          ]}
                          noStyle
                        >
                          <Input
                            placeholder="Nhập lựa chọn"
                            style={{ width: '280px' }}
                            onChange={() => {
                              setIsNoneChoice(false);
                            }}
                          />
                        </Form.Item>
                        <div className="align-middle">
                          <Popconfirm
                            title="Bạn có chắc chắn muốn xoá?"
                            onConfirm={() => {
                              remove(field.name);
                              const arr = [...listDeleteAttributeOption];
                              const newArr = arr.push(idArray[field.key]);
                              setListDeleteAttributeOption(arr);
                              setDeleteAttributeId(NOT_DELETE_ALL);
                            }}
                            onCancel={() => {}}
                            okText="Xoá"
                            cancelText="Huỷ"
                          >
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              style={{
                                marginLeft: '16px',
                                fontSize: 'large',
                                color: 'red',
                              }}
                              onClick={() => {}}
                            />
                          </Popconfirm>
                        </div>
                      </Form.Item>
                    ))}
                    <Form.Item {...formItemLayoutWithOutLabelButton}>
                      <ButtonAntd
                        type="dashed"
                        onClick={() => {
                          add();
                        }}
                        style={{ width: '280px', marginLeft: '10px' }}
                        icon={<PlusOutlined />}
                      >
                        Thêm lựa chọn
                      </ButtonAntd>
                    </Form.Item>
                    <Form.Item {...formItemLayoutWithOutLabelButton}>
                      <Popconfirm
                        title="Bạn có chắc chắn muốn xoá tất cả?"
                        onConfirm={() => {
                          remove(fields.map((_, index) => index));
                          setDeleteAttributeId(data.id);
                          setIdArray([]);
                          setIsNoneChoice(true);
                        }}
                        onCancel={() => {}}
                        okText="Xoá"
                        cancelText="Huỷ"
                      >
                        <ButtonAntd
                          type="dashed"
                          style={{
                            width: '280px',
                            marginLeft: '10px',
                            borderColor: 'red',
                            color: 'red',
                          }}
                          icon={<CloseOutlined />}
                        >
                          Xoá tất cả
                        </ButtonAntd>
                      </Popconfirm>
                    </Form.Item>
                  </>
                );
              }}
            </Form.List>
          )}

          <Form.Item
            labelAlign="left"
            label="STT hiển thị"
            name="numericalOrder"
            rules={[
              { required: true, message: 'Vui lòng nhập số thứ tự!' },
              {
                type: 'number',
                min: 1,
                message: 'Vui lòng nhập số lớn hơn hoặc bằng 1!',
              },
              {
                type: 'number',
                max: formattedPaging.total + 1,
                message: `Số thứ tự không được lớn hơn ${
                  formattedPaging.total + 1
                }`,
              },
              {
                type: 'integer',
                message: 'Vui lòng nhập số nguyên dương!',
              },
            ]}
          >
            <InputNumber
              placeholder="Nhập số thứ tự"
              style={{ width: '280px', marginLeft: 10 }}
            />
          </Form.Item>

          {!shouldAddAttribute && (
            <Form.Item
              labelAlign="left"
              label="Trạng thái"
              name="status"
              rules={[
                {
                  required: data.data_type !== 'ref',
                  message: 'Vui lòng chọn trạng thái!',
                },
              ]}
            >
              <Select
                style={{ width: '280px', marginLeft: 10 }}
                placeholder="Chọn trạng thái"
                allowClear
                onChange={value => {}}
                disabled={data.data_type !== 'ref'}
              >
                <Option value={1}>Đang hoạt động</Option>
                <Option value={2}>Ngừng hoạt động</Option>
              </Select>
            </Form.Item>
          )}

          <Form.Item
            wrapperCol={{ offset: 9, span: 16 }}
            style={{ marginTop: 6 }}
          >
            <ButtonAntd
              type="primary"
              htmlType="submit"
              style={{ marginRight: 6 }}
            >
              Lưu
            </ButtonAntd>
            <ButtonAntd
              type="primary"
              danger
              onClick={() => {
                onClose();
                setSelectedType(null);
                form.resetFields();
              }}
            >
              Đóng
            </ButtonAntd>
          </Form.Item>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddUpdateAttribute;
