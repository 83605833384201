import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Radio,
  Row,
  Select,
  Space,
  TimePicker,
} from 'antd';
import { EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  JOB_CRON_TIME_CODE,
  JOB_CRON_TIME_REPEAT,
  SCRIPT_TYPE,
  VALUES_JOB_CRON_TIME_WEEK,
} from '../../Constants';
import {
  requestGetAllNumberPrefix,
  requestGetAllTemplatesMessage,
} from '@screens/Admin/Company/services/api';
import {
  requestGetOA,
  requestGetTemplateZNS,
} from '@screens/Enterprise/Settings/zaloOA/services/api';
import AddTemplate from '@screens/Admin/Company/components/AddTemplate';
import { VietnameseString } from '@constants/VietnameseString';
import { isImage, rmVN } from '@constants/funcHelper';
import { IS_APPROVED } from '@constants/Constant';

import { IframeStyled, ScrollStyle } from '@styles/Style';

const { Option } = Select;

const FormStyled = styled(Form)`
  &&& {
    text-align: start;
    width: 100%;

    .ant-form-item {
      margin-bottom: 0;
    }
  }
`;

const RadioStyled = styled(Radio)`
  &&& {
    .radio__item {
      display: flex;
      align-items: center;
    }

    .radio__item-value {
      span {
        display: flex;
        align-items: center;

        font-weight: normal;
        margin-left: 12px;

        div {
          display: flex;
          align-items: center;

          + div {
            margin-left: 12px;
          }
        }
      }
    }
  }
`;

const TemplateAreaStyled = styled.div`
  &&& {
    display: flex;
    align-items: center;
    width: 100%;

    .plus-outlined__icon {
      cursor: pointer;

      svg {
        margin-left: 16px;
        font-size: 32px;
        color: red;
      }
    }
  }
`;

export const MessageContentStyled = styled(ScrollStyle)`
  border: 1px solid #f0f0f0;
  padding: 12px;
  border-radius: 8px;
  overflow-y: scroll;
`;

export const MultimediaAreaStyled = styled(ScrollStyle)`
  display: flex;
  align-items: center;
  margin: 4px;

  overflow-x: auto;
`;

export const ItemMediaStyled = styled.div`
  width: 300px;

  + .item-media {
    margin-left: 8px;
  }
`;

const DateInputStyled = styled.div`
  &&& {
    input[type='date']::-webkit-inner-spin-button,
    input[type='date']::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
  }
`;

const ContainerDatePickerStyled = styled.div`
  &&& {
    padding: 12px;

    .wrapper-icon {
      position: absolute;
      top: 0;
      right: 8px;
    }

    .icon svg {
      font-size: 18px;

      :hover {
        color: red;
        font-size: 19px;
      }
    }
  }
`;

const timeFormat = 'DD/MM/YYYY';

function RadioItem(props) {
  const {
    dataJobCron,
    handleChangeFormAdd,
    title,
    radioItemValue,
    radioItemSelected,
    subTitle,
    radioItemExpand,
  } = props;

  return (
    <div className="radio__item">
      <span className="radio__item-title">{title}</span>
      <span className="radio__item-value">
        {radioItemSelected === radioItemValue ? (
          <span>
            {subTitle ? (
              <div>
                {subTitle}:&nbsp;&nbsp;&nbsp;
                <Select
                  value={dataJobCron.value}
                  onClick={event => {
                    event.stopPropagation();
                    event.preventDefault();
                  }}
                  style={{ width: '100px' }}
                  placeholder={subTitle}
                  onChange={value => {
                    handleChangeFormAdd({ value });
                  }}
                >
                  {radioItemExpand.map((el, idx) => (
                    <Option key={idx} value={el.value}>
                      {el.name}
                    </Option>
                  ))}
                </Select>
              </div>
            ) : null}

            <div>
              {VietnameseString.hour}:&nbsp;&nbsp;&nbsp;
              <TimePicker
                format={'HH:mm'}
                value={
                  dataJobCron.time_repeat
                    ? moment(Number(dataJobCron.time_repeat))
                    : undefined
                }
                placeholder={VietnameseString.hour}
                onChange={value => {
                  handleChangeFormAdd({
                    time_repeat: new Date(value).getTime(),
                  });
                }}
              />
            </div>
          </span>
        ) : null}
      </span>
    </div>
  );
}

function ScriptOfSmsOrZns(props) {
  const {
    type,
    dataJobCron,
    handleChangeFormAdd,
    isCheckEvoucher,
    idEvoucher,
  } = props;

  const [form] = Form.useForm();

  const valuePrefixNumber = Form.useWatch('sms_provider_id', form);
  const valueOA = Form.useWatch('oa_provider_id', form);
  const valueTemplate = Form.useWatch('message_template_id', form);

  const { enterprise_id } = useSelector(redux => redux.userReducer.data);

  const [visibleDatePicker, setVisibleDatePicker] = useState(false);

  const [numberPrefixData, setNumberPrefixData] = useState([]);
  const [oa, setOa] = useState([]);
  const [templatesMessage, setTemplatesMessage] = useState([]);
  const [templatesZNS, setTemplatesZNS] = useState([]);
  const [templatesMessageSelected, setTemplatesMessageSelected] = useState({});
  const [templatesZNSSelected, setTemplatesZNSSelected] = useState({});

  const [visible, setVisible] = useState(false);

  const getNumberPrefix = useCallback(async () => {
    try {
      const { data } = await requestGetAllNumberPrefix({
        enterpriseId: enterprise_id,
        page: 1,
      });
      setNumberPrefixData(data);
    } catch (error) {
      console.log('Error getNumberPrefix:', { error });
    }
  }, []);

  const getOA = useCallback(async () => {
    try {
      const { data } = await requestGetOA({ enterprise_id });
      setOa(data);
    } catch (error) {
      console.log('Error getOA: ', { error });
    }
  }, []);

  const getTemplatesMessage = useCallback(async valuePrefixNumber => {
    try {
      const { data } = await requestGetAllTemplatesMessage({
        numberPrefixId: valuePrefixNumber,
        search: '',
        isApproved: IS_APPROVED.YES,
        fromDate: '',
        toDate: '',
        page: 1,
      });
      setTemplatesMessage(data);
    } catch (error) {
      console.log('Error getTemplatesMessage: ', { error });
    }
  }, []);

  const getTemplateZNS = useCallback(async valueOA => {
    try {
      const { data } = await requestGetTemplateZNS({
        enterprise_id,
        oa_provider_id: valueOA,
        search: '',
        status: IS_APPROVED.YES,
        page: 1,
        limit: 999,
      });
      setTemplatesZNS(data);
    } catch (error) {
      console.log('Error getTemplateZNS: ', { error });
    }
  }, []);

  const isCheckScriptTypeOfSMS = type => {
    let check = false;
    if (type === SCRIPT_TYPE.FOR_SMS) {
      check = true;
    }
    return check;
  };

  const isCheckScriptTypeOfZNS = type => {
    let check = false;
    if (type === SCRIPT_TYPE.FOR_ZNS) {
      check = true;
    }
    return check;
  };

  useEffect(() => {
    if (
      dataJobCron.oa_provider_id === '' ||
      dataJobCron.sms_provider_id === ''
    ) {
      form.setFieldsValue({
        message_template_id: undefined,
        sms_provider_id: undefined,
        oa_provider_id: undefined,
      });
    }

    if (isCheckScriptTypeOfSMS(type)) {
      getNumberPrefix();
    } else {
      getOA();
    }
  }, [type]);


  console.log(`valueTemplate`,valueTemplate )
  useEffect(() => {
    if (valuePrefixNumber) getTemplatesMessage(valuePrefixNumber);
    if (valueOA) getTemplateZNS(valueOA);
  }, [valuePrefixNumber, valueOA]);

  useEffect(() => {
    if (valueTemplate) {
      if (isCheckScriptTypeOfSMS(type))
        setTemplatesMessageSelected(
          templatesMessage.find(el => el.id === valueTemplate)
        );

      if (isCheckScriptTypeOfZNS(type))
        setTemplatesZNSSelected(
          templatesZNS.find(el => el.template_id === valueTemplate)
        );
    }
  }, [valueTemplate, templatesMessage, templatesZNS]);

  useEffect(() => {
    form.setFieldsValue({
      oa_provider_id: dataJobCron?.oa_provider_id,
      sms_provider_id: dataJobCron?.sms_provider_id,
      message_template_id: dataJobCron?.message_template_id
    });
  }, [dataJobCron?.oa_provider_id, dataJobCron?.sms_provider_id]);

  return (
    <Fragment>
      <FormStyled
        name={isCheckScriptTypeOfSMS(type) ? 'scriptOfSMS' : 'scriptOfZNS'}
        form={form}
        initialValues={dataJobCron}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <div>
              <Form.Item name="is_display" label="">
                <Checkbox
                  checked={Boolean(dataJobCron.is_display)}
                  onChange={event =>
                    handleChangeFormAdd({
                      is_display: Number(event.target.checked),
                    })
                  }
                >
                  {
                    VietnameseString.allow_displaying_templates_in_sales_accounts
                  }
                </Checkbox>
              </Form.Item>
            </div>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col lg={12} sm={24} xs={24}>
            <div>
              <Form.Item
                name={
                  isCheckScriptTypeOfSMS(type)
                    ? 'sms_provider_id'
                    : 'oa_provider_id'
                }
                label={
                  isCheckScriptTypeOfSMS(type)
                    ? VietnameseString.select_number_prefix
                    : VietnameseString.select_account_zns
                }
                rules={[{ required: true, message: '' }]}
              >
                <Select
                  placeholder={
                    isCheckScriptTypeOfSMS(type)
                      ? VietnameseString.select_number_prefix
                      : VietnameseString.select_account_zns
                  }
                  onChange={value => {
                    console.log("value dau so", value)
                    form.setFieldsValue({ message_template_id: undefined });
                    let dataForm = {
                      message_template_id: '',
                    };

                    if (isCheckScriptTypeOfSMS(type)) {
                      dataForm.sms_provider_id = value;
                    }
                    if (isCheckScriptTypeOfZNS(type)) {
                      dataForm.oa_provider_id = value;
                    }

                    handleChangeFormAdd(dataForm);
                  }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (
                      rmVN(option.children[0]).indexOf(rmVN(input)) >= 0 ||
                      rmVN(option.children[2]).indexOf(rmVN(input)) >= 0
                    );
                  }}
                >
                  {isCheckScriptTypeOfSMS(type)
                    ? numberPrefixData.map(el => (
                        <Option key={el.id} value={el.id}>
                          {el.phone} - {el.sms_type}
                        </Option>
                      ))
                    : oa.map(el => (
                        <Option key={el.id} value={el.id}>
                          {el.name}
                        </Option>
                      ))}
                </Select>
              </Form.Item>
            </div>

            <div>
              <Form.Item
                name="time_option"
                label={VietnameseString.sending_time}
                rules={[{ required: true, message: '' }]}
              >
                <Radio.Group
                  value={dataJobCron.time_option}
                  onChange={event => {
                    event.target.value === JOB_CRON_TIME_REPEAT.PERIODICALLY
                      ? handleChangeFormAdd({
                          time_option: event.target.value,
                          time_repeat: '',
                          code: dataJobCron.code || JOB_CRON_TIME_CODE.ALlWAYS,
                        })
                      : handleChangeFormAdd({
                          time_option: event.target.value,
                          time_repeat: '',
                        });
                  }}
                >
                  <Radio value={JOB_CRON_TIME_REPEAT.ONCE}>
                    {VietnameseString.once}
                  </Radio>
                  <Radio value={JOB_CRON_TIME_REPEAT.PERIODICALLY}>
                    {VietnameseString.periodically}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </div>

            {dataJobCron.time_option === JOB_CRON_TIME_REPEAT.ONCE ? (
              <div>
                <DatePicker
                  format={timeFormat + ' HH:mm'}
                  showToday={false}
                  allowClear={false}
                  open={visibleDatePicker}
                  disabledDate={currentDate =>
                    currentDate &&
                    currentDate < moment(new Date()).startOf('day')
                  }
                  value={
                    dataJobCron.time_repeat
                      ? moment(Number(dataJobCron.time_repeat))
                      : undefined
                  }
                  onChange={date =>
                    handleChangeFormAdd({
                      value: '',
                      time_repeat: new Date(date).getTime(),
                    })
                  }
                  onClick={() => {
                    setVisibleDatePicker(true);
                  }}
                  panelRender={panelNode => {
                    // panelNode === element calendar
                    return (
                      <ContainerDatePickerStyled>
                        {/* <div className="wrapper-icon">
                          <CloseCircleOutlined
                            className="icon"
                            onClick={() => {
                              setVisibleDatePicker(false);
                            }}
                          />
                        </div> */}
                        {panelNode}
                        <Row gutter={[16, 16]}>
                          <Col span={12}>
                            <div>{VietnameseString.date}</div>

                            <DateInputStyled
                              onMouseDown={e => e.stopPropagation()}
                            >
                              <Input
                                type="date"
                                value={
                                  dataJobCron.time_repeat
                                    ? moment(
                                        Number(dataJobCron.time_repeat)
                                      ).format('YYYY-MM-DD')
                                    : undefined
                                }
                                onChange={event => {
                                  handleChangeFormAdd({
                                    value: '',
                                    time_repeat: new Date(
                                      event.target.value
                                    ).getTime(),
                                  });
                                }}
                              />
                            </DateInputStyled>
                          </Col>

                          <Col span={12}>
                            <div>{VietnameseString.hour}</div>

                            <div>
                              <TimePicker
                                format={'HH:mm'}
                                value={
                                  dataJobCron.time_repeat
                                    ? moment(Number(dataJobCron.time_repeat))
                                    : undefined
                                }
                                onChange={(time, timeString) => {
                                  handleChangeFormAdd({
                                    value: '',
                                    time_repeat: new Date(time).getTime(),
                                  });
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </ContainerDatePickerStyled>
                    );
                  }}
                />
                {visibleDatePicker && (
                  <div
                    style={{
                      position: 'fixed',
                      inset: 0,
                      backgroundColor: 'transparent',
                    }}
                    onClick={() => setVisibleDatePicker(false)}
                  />
                )}
              </div>
            ) : (
              <div>
                <Form.Item name="code">
                  <Radio.Group
                    value={dataJobCron.code}
                    onChange={event => {
                      handleChangeFormAdd({
                        code: event.target.value,
                        value: '',
                        time_repeat: '',
                      });
                    }}
                  >
                    <Space direction="vertical">
                      <RadioStyled value={JOB_CRON_TIME_CODE.ALlWAYS}>
                        {VietnameseString.continuous}
                      </RadioStyled>

                      <RadioStyled value={JOB_CRON_TIME_CODE.DATE}>
                        <RadioItem
                          dataJobCron={dataJobCron}
                          handleChangeFormAdd={handleChangeFormAdd}
                          title={VietnameseString.date}
                          radioItemValue={JOB_CRON_TIME_CODE.DATE}
                          radioItemSelected={dataJobCron.code}
                        />
                      </RadioStyled>

                      <RadioStyled value={JOB_CRON_TIME_CODE.WEEK}>
                        <RadioItem
                          dataJobCron={dataJobCron}
                          handleChangeFormAdd={handleChangeFormAdd}
                          title={VietnameseString.week}
                          radioItemValue={JOB_CRON_TIME_CODE.WEEK}
                          radioItemSelected={dataJobCron.code}
                          subTitle="Thứ"
                          radioItemExpand={VALUES_JOB_CRON_TIME_WEEK}
                        />
                      </RadioStyled>

                      <RadioStyled value={JOB_CRON_TIME_CODE.MONTH}>
                        <RadioItem
                          dataJobCron={dataJobCron}
                          handleChangeFormAdd={handleChangeFormAdd}
                          title={VietnameseString.month}
                          radioItemValue={JOB_CRON_TIME_CODE.MONTH}
                          radioItemSelected={dataJobCron.code}
                          subTitle={VietnameseString.date}
                          radioItemExpand={Array.from({ length: 31 }).map(
                            (_, i) => {
                              const v = ++i;
                              return { value: v, name: v };
                            }
                          )}
                        />
                      </RadioStyled>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </div>
            )}
          </Col>

          <Col lg={12} sm={24} xs={24}>
            <Form.Item
              name="message_template_id"
              label={VietnameseString.choose_a_template}
              rules={[{ required: true, message: '' }]}
            >
              <TemplateAreaStyled>
                <Select
                  placeholder={VietnameseString.choose_a_template}
                  value={valueTemplate}
                  onChange={value => {
                    form.setFieldsValue({ message_template_id: value });
                    handleChangeFormAdd({ message_template_id: value });
                  }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return rmVN(option.children).indexOf(rmVN(input)) >= 0;
                  }}
                >
                  {isCheckScriptTypeOfSMS(type)
                    ? templatesMessage.map(el => (
                        <Option key={el.id} value={el.id}>
                          {el.message}
                        </Option>
                      ))
                    : templatesZNS.map(el => (
                        <Option key={el.template_id} value={el.template_id}>
                          {el.template_name}
                        </Option>
                      ))}
                </Select>

                {valuePrefixNumber ? (
                  <PlusOutlined
                    className="plus-outlined__icon"
                    onClick={() => setVisible(true)}
                  />
                ) : null}
              </TemplateAreaStyled>
            </Form.Item>

            {/* Xem trước mẫu tin nhắn SMS */}
            {valueTemplate &&
            templatesMessageSelected &&
            isCheckScriptTypeOfSMS(type) ? (
              <div>
                <div>
                  Xem trước tin nhắn: {templatesMessageSelected.count_character}{' '}
                  {VietnameseString.character}/{' '}
                  {templatesMessageSelected.count_message} SMS
                </div>

                <MessageContentStyled>
                  <p>{templatesMessageSelected.message}</p>

                  {templatesMessageSelected ? (
                    <MultimediaAreaStyled>
                      {templatesMessageSelected.message_template_media &&
                      templatesMessageSelected.message_template_media.length ? (
                        templatesMessageSelected.message_template_media.map(
                          (media, index) => {
                            return (
                              <ItemMediaStyled
                                key={index}
                                className="item-media"
                              >
                                {isImage(media.media_url) ? (
                                  <Image
                                    width={200}
                                    src={media.media_url}
                                    preview={{
                                      mask: (
                                        <>
                                          <EyeOutlined />
                                          {VietnameseString.view_image}
                                        </>
                                      ),
                                    }}
                                  />
                                ) : (
                                  <video
                                    width={300}
                                    controls
                                    src={media.media_url}
                                  />
                                )}
                              </ItemMediaStyled>
                            );
                          }
                        )
                      ) : (
                        <Fragment></Fragment>
                      )}
                    </MultimediaAreaStyled>
                  ) : (
                    <Fragment></Fragment>
                  )}
                </MessageContentStyled>
              </div>
            ) : (
              <Fragment></Fragment>
            )}

            {/* Xem trước mấu tin nhắn Zalo - ZNS */}
            {valueTemplate &&
            templatesZNSSelected &&
            isCheckScriptTypeOfZNS(type) ? (
              <div>
                <div>Xem trước tin nhắn:</div>

                <IframeStyled
                  height={380}
                  title={templatesZNSSelected.template_name}
                  src={templatesZNSSelected.preview_url}
                />
              </div>
            ) : (
              <Fragment />
            )}
          </Col>
        </Row>
      </FormStyled>

      {visible && valuePrefixNumber && (
        <AddTemplate
          visible={visible}
          setVisible={show => {
            setVisible(show);
          }}
          numberPrefixId={valuePrefixNumber}
          enterpriseId={enterprise_id}
          codeTypeOfSMS={
            numberPrefixData.find(el => el.id === valuePrefixNumber).df_sms_type
              .code
          }
          numberPrefix={
            numberPrefixData.find(el => el.id === valuePrefixNumber).phone
          }
          isCheckEvoucher={isCheckEvoucher}
          idEvoucher={idEvoucher}
        />
      )}
    </Fragment>
  );
}

ScriptOfSmsOrZns.propTypes = {
  type: PropTypes.oneOf([SCRIPT_TYPE.FOR_ZNS, SCRIPT_TYPE.FOR_SMS]),
  dataJobCron: PropTypes.object.isRequired,
  handleChangeFormAdd: PropTypes.func.isRequired,
};

export default ScriptOfSmsOrZns;
