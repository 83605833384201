import { isCallEnded } from '@components/callProviders/CallHelper';
import { Button } from 'antd';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

const NomalNumpad = ({ onClickNumberPad, onClickDelete, btnCall }) => {
  return (
    <div>
      <Row
        style={{ marginTop: 40 }}
        className="mb-3 d-flex justify-content-center"
      >
        <Col xs={4} sm={4} md={4} lg={4} className="d-flex justify-content-end">
          <div className="number-btn" onClick={() => onClickNumberPad('1')}>
            1
          </div>
        </Col>
        <Col
          xs={4}
          sm={3}
          md={3}
          lg={3}
          className="d-flex justify-content-center"
        >
          <div className="number-btn" onClick={() => onClickNumberPad('2')}>
            2
          </div>
        </Col>
        <Col
          xs={4}
          sm={4}
          md={4}
          lg={4}
          className="d-flex justify-content-start"
        >
          <div className="number-btn" onClick={() => onClickNumberPad('3')}>
            3
          </div>
        </Col>
      </Row>
      <Row className="mb-3 d-flex justify-content-center">
        <Col xs={4} sm={4} md={4} lg={4} className="d-flex justify-content-end">
          <div className="number-btn" onClick={() => onClickNumberPad('4')}>
            4
          </div>
        </Col>
        <Col
          xs={4}
          sm={3}
          md={3}
          lg={3}
          className="d-flex justify-content-center"
        >
          <div className="number-btn" onClick={() => onClickNumberPad('5')}>
            5
          </div>
        </Col>
        <Col
          xs={4}
          sm={4}
          md={4}
          lg={4}
          className="d-flex justify-content-start"
        >
          <div className="number-btn" onClick={() => onClickNumberPad('6')}>
            6
          </div>
        </Col>
      </Row>
      <Row className="mb-3 d-flex justify-content-center">
        <Col xs={4} sm={4} md={4} lg={4} className="d-flex justify-content-end">
          <div className="number-btn" onClick={() => onClickNumberPad('7')}>
            7
          </div>
        </Col>
        <Col
          xs={4}
          sm={3}
          md={3}
          lg={3}
          className="d-flex justify-content-center"
        >
          <div className="number-btn" onClick={() => onClickNumberPad('8')}>
            8
          </div>
        </Col>
        <Col
          xs={4}
          sm={4}
          md={4}
          lg={4}
          className="d-flex justify-content-start"
        >
          <div className="number-btn" onClick={() => onClickNumberPad('9')}>
            9
          </div>
        </Col>
      </Row>
      <Row className="mb-3 d-flex justify-content-center">
        <Col xs={4} sm={4} md={4} lg={4} className="d-flex justify-content-end">
          <div className="number-btn" onClick={() => onClickNumberPad('*')}>
            *
          </div>
        </Col>
        <Col
          xs={4}
          sm={3}
          md={3}
          lg={3}
          className="d-flex justify-content-center"
        >
          <div className="number-btn" onClick={() => onClickNumberPad('0')}>
            0
          </div>
        </Col>
        <Col
          xs={4}
          sm={4}
          md={4}
          lg={4}
          className="d-flex justify-content-start"
        >
          <div className="number-btn" onClick={() => onClickNumberPad('#')}>
            #
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col xs={4} sm={4} md={4} lg={2} className="col-pad"></Col>
        <Col
          xs={4}
          sm={3}
          md={3}
          lg={2}
          className="d-flex justify-content-center col-pad"
        >
          {btnCall}
        </Col>
        <Col
          xs={4}
          sm={4}
          md={4}
          lg={2}
          className="d-flex justify-content-start col-pad"
        >
          <div className="delete-box">
            <div className="delete-btn" onClick={() => onClickDelete()}>
              X
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

function CallButton(props) {
  const { size, children, ...rest } = props;

  const style = {
    height: size === 'large' ? '48px' : '32px',
    fontSize: size === 'large' ? '18px' : '14px',
    padding: size === 'large' ? '0 32px' : '0 16px',
  };

  return (
    <Button style={style} size={size} {...rest}>
      {children}
    </Button>
  );
}

export default React.memo(NomalNumpad);
