import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { Row, Col, FormControl } from 'react-bootstrap';
import '@styles/JobGeneral.css';
import { ROUTER, STRING, NUMBER } from '@constants/Constant';
import DatePickerCustom from '../../../../components/DatePickerCustom';
import { Line, Doughnut } from 'react-chartjs-2';
import Loading from '@src/components/Loading';
import { notifyFail, notifySuccess } from '@utils/notify';
import moment from 'moment';
import { ReactSelect } from '@components/ReactSelect';
import Pagi from '@components/PaginationTotalItem';
import reactotron from 'reactotron-react-js';
import { Empty } from 'antd';

import {
  requestGetCallChart,
  requestJobCategoriesFilter,
  requestGetTelesaleStatistic,
  requestTelesalesFilter,
  requestGetCallStatusChart,
  requestGetFullDepartment,
  requestGetSaleReportResult,
  requestTelesalesFilterByDepartment,
} from '@constants/Api';
import { VietnameseString } from '@constants/VietnameseString';

class Screen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      [STRING.fromDate]: '',
      [STRING.toDate]: '',
      job_id: '',
      telesale_id: '',
      startDate: '',
      endDate: '',
      dataChart: {
        labels: [],
        datasets: [
          {
            label: 'Thống kê cuộc gọi',
            data: [],
            borderColor: '',
            fill: false,
            pointBackgroundColor: '#1b5e20',
            pointBorderColor: 'white',
            pointRadius: 5,
          },
        ],
      },
      department_id: '',
      listJobs: [],
      listTelesales: [],
      listReports: [],
      paging: {},
      activePage: 1,
      isLoading: false,
      departmentList: [],
      callStatusData: [],
      callResult: {
        callDuration: '',
        totalCall: '',
        totalCustomer: '',
        totalOpportunity: '',
      },
    };
  }

  componentDidMount() {
    this.requestGetCallChart();
    this.requestJobCategoriesFilter();
    this.requestGetTelesaleStatistic();
    this.requestTelesalesFilter();
    this.getFullDepartment();
    this.getCallStatusChart();
    this.getSaleReportResult();
  }

  startDate = () => {
    const { [STRING.fromDate]: fromDate, [STRING.toDate]: toDate } = this.state;
    if (fromDate == '' && toDate == '') {
      let now = Date.now() - 604800000;
      let d = new Date(now);
      let date = d.getDate() > 9 ? d.getDate() : '0' + d.getDate();
      let month = d.getMonth() + 1 > 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1);
      let year = d.getFullYear();
      let startDate = year + '-' + month + '-' + date + 'T00:00:00.000Z';
      this.setState({
        ...this.state,
        startDate: startDate,
        // [STRING.fromDate]: moment(startDate).format('YYYY-MM-DD'),
      });
      console.log(typeof moment(startDate).format('YYYY-MM-DD'), 'if 1');
    }
    if (fromDate == '' && toDate != '') {
      let now = new Date(toDate) - 604800000;
      let d = new Date(now);
      let date = d.getDate() > 9 ? d.getDate() : '0' + d.getDate();
      let month = d.getMonth() + 1 > 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1);
      let year = d.getFullYear();
      let startDate = year + '-' + month + '-' + date + 'T00:00:00.000Z';
      console.log(moment(startDate).format('YYYY-MM-DD'), 'if 2');
      this.setState({
        ...this.state,
        startDate: startDate,
        // [STRING.fromDate]: moment(startDate).format('YYYY-MM-DD'),
      });
    }
  };

  endDate = () => {
    const { [STRING.fromDate]: fromDate, [STRING.toDate]: toDate } = this.state;
    if (toDate == '') {
      let now = Date.now();
      let d = new Date(now);
      let date = d.getDate() > 9 ? d.getDate() : '0' + d.getDate();
      let month = d.getMonth() + 1 > 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1);
      let year = d.getFullYear();
      let endDate = year + '-' + month + '-' + date + 'T00:00:00.000Z';
      this.setState({
        ...this.state,
        endDate: endDate,
        // [STRING.toDate]: endDate,
      });
    }
  };

  async getSaleReportResult() {
    const { [STRING.fromDate]: fromDate, [STRING.toDate]: toDate, job_id, telesale_id, department_id } = this.state;
    await this.startDate();
    await this.endDate();
    const { startDate, endDate } = this.state;

    try {
      const res = await requestGetSaleReportResult({
        fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : startDate,
        toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : endDate,
        department_id: department_id || '',
        sale_id: telesale_id || '',
      });
      console.log(res, 'resultttttt');
      this.setState({
        ...this.state,
        callResult: {
          ...this.state.callResult,
          callDuration: res.data.call_duration,
          totalCall: res.data.total_call,
          totalCustomer: res.data.total_customer,
          totalOpportunity: res.data.total_oppor,
        },
      });
    } catch (error) {}
  }

  async getCallStatusChart() {
    try {
      const { [STRING.fromDate]: fromDate, [STRING.toDate]: toDate, job_id, telesale_id, department_id } = this.state;
      await this.startDate();
      await this.endDate();
      const { startDate, endDate } = this.state;

      const res = await requestGetCallStatusChart({
        fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : startDate,
        toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : endDate,
        department_id: department_id || '',
      });
      console.log(res, 'yessssssssssssssss');
      this.setState({
        ...this.state,
        callStatusData: res.data,
      });
    } catch (error) {}
  }

  async requestGetCallChart() {
    const { [STRING.fromDate]: fromDate, [STRING.toDate]: toDate, job_id, telesale_id, department_id } = this.state;
    await this.startDate();
    await this.endDate();
    const { startDate, endDate } = this.state;
    let arrDate = [];
    let arrValues = [];
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const res = await requestGetCallChart({
        fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : startDate,
        toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : endDate,
        department_id: department_id || '',
        telesale_id: telesale_id || '',
        limit: 12,
        page: 1,
      });
      let count = res?.data?.chartCall?.map((value) => {
        arrDate.push(value.quarter);
        arrValues.push(value.earnings);
      });
      this.setState({
        ...this.state,
        dataChart: {
          ...this.state.dataChart,
          labels: arrDate,
          datasets: [
            {
              label: 'Số cuộc gọi trong ngày',
              borderColor: '#aeea00',
              fill: false,
              pointBackgroundColor: '#aeea00',
              pointBorderColor: 'white',
              pointRadius: 5,
              data: arrValues,
            },
          ],
        },
        isLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async requestJobCategoriesFilter() {
    try {
      const res = await requestJobCategoriesFilter({
        enterprise_id: '',
        telesale_id: '',
        have_call: '',
      });
      this.setState({
        ...this.state,
        listJobs: res.data,
      });
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  async getFullDepartment() {
    try {
      const res = await requestGetFullDepartment();
      this.setState({
        ...this.state,
        departmentList: res.data,
      });
    } catch (error) {
      notifyFail(error.msg);
    }
  }

  async requestTelesalesFilter() {
    try {
      const res = await requestTelesalesFilterByDepartment({
        // enterprise_id: '',
        // job_id: '',
        // sub_job_id: '',
        // limit: 100,
        department_id: this.state.department_id || '',
      });
      this.setState({
        ...this.state,
        listTelesales: res.data,
      });
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  async requestGetTelesaleStatistic() {
    const {
      [STRING.fromDate]: fromDate,
      [STRING.toDate]: toDate,
      job_id,
      telesale_id,
      activePage,
      department_id,
    } = this.state;
    await this.startDate();
    await this.endDate();
    const { startDate, endDate } = this.state;
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const res = await requestGetTelesaleStatistic({
        fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : startDate,
        toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : endDate,
        department_id: department_id || '',
        telesale_id: telesale_id || '',
        limit: 12,
        page: activePage,
      });
      this.setState({
        ...this.state,
        listReports: res.data,
        paging: res.paging,
        isLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  handleChange = (field, value) => {
    this.setState(
      {
        ...this.state,
        [field]: value || '',
      },
      () => {
        this.requestGetCallChart();
        this.requestGetTelesaleStatistic();
        this.getSaleReportResult();
        this.getCallStatusChart();
      }
    );
  };

  handleChangeSelect = (selectedOption, field) => {
    this.setState(
      {
        ...this.state,
        [field]: selectedOption.value,
      },
      () => {
        this.requestGetCallChart();
        this.requestGetTelesaleStatistic();
        this.getSaleReportResult();
        this.getCallStatusChart();
        this.requestTelesalesFilter();
      }
    );
  };

  renderTitle() {
    return (
      <>
        <Row>
          <Col md={5} sm={12}>
            <h2 className="text-left" style={{ marginBottom: '10px' }}>
              {VietnameseString.call_reports}
            </h2>
            <div class="line"></div>
          </Col>
          <br />
        </Row>
      </>
    );
  }

  renderResult() {
    const { callResult } = this.state;

    return (
      <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
        <Col
          md
          style={{
            padding: '30px 35px',
            backgroundColor: '#ffffff',
            borderRadius: '8px',
            boxShadow: '0 20px 80px rgba(0, 0, 0, 0.07)',
          }}
        >
          <div className="w-100">
            <Row>
              <Col md={2} className="pl-0">
                <span>
                  <b>Kết quả</b>
                </span>
              </Col>
              <Col md={8}>
                <Row>
                  <Col md={6}>
                    Số cuộc gọi: <b>{callResult.totalCall || 0}</b>
                  </Col>
                  <Col md={6}>
                    Số cơ hội: <b>{callResult.totalOpportunity || 0}</b>
                  </Col>
                  <Col md={6}>
                    Số khách hàng: <b>{callResult.totalCustomer || 0}</b>
                  </Col>
                  <Col md={6}>
                    Thời gian gọi trung bình:{' '}
                    <b>
                      {callResult.callDuration && callResult.totalCall
                        ? this.millisToMinutesAndSeconds(
                            parseInt((parseInt(callResult.callDuration) * 1000) / callResult.totalCall)
                          )
                        : 0}
                    </b>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  }

  renderBody() {
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <Row
            className="mt-1"
            style={{ textAlign: 'center', padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}
          >
            <Col md className="bg">
              {this.renderTitle()}
              {this.renderField()}
            </Col>
          </Row>

          {this.renderResult()}

          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
            <Col
              md
              style={{
                padding: '30px 35px',
                backgroundColor: '#ffffff',
                borderRadius: '8px',
                boxShadow: '0 20px 80px rgba(0, 0, 0, 0.07)',
              }}
            >
              {this.renderchart()}
            </Col>
          </Row>
          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
            <Col md className="bg">
              {this.renderTable()}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderField() {
    const {
      job_id,
      [STRING.fromDate]: fromDate,
      [STRING.toDate]: toDate,
      listJobs,
      listTelesales,
      departmentList,
    } = this.state;
    const jobs = listJobs?.map(
      (province) =>
        new Object({
          value: province.id,
          label: province.name,
        })
    );
    jobs.unshift({ value: '', label: 'Chọn công việc' });

    const departments = departmentList?.map(
      (department) =>
        new Object({
          value: department.id,
          label: department.name,
        })
    );
    departments.unshift({ value: '', label: 'Chọn phòng ban' });

    const telesales = listTelesales?.map(
      (province) =>
        new Object({
          value: province.id,
          label: province.full_name,
        })
    );
    telesales.unshift({ value: '', label: 'Chọn sale' });

    return (
      <div className="w-100">
        <Row>
          <Col md={3} sm={12}>
            <ReactSelect
              handleChangeSelect={(e) => this.handleChangeSelect(e, 'department_id')}
              list={departments}
              placeholder="Chọn phòng ban"
            />
          </Col>
          <Col md={3} sm={12}>
            <ReactSelect
              handleChangeSelect={(e) => {
                this.handleChangeSelect(e, 'telesale_id');
              }}
              list={telesales}
              placeholder="Chọn sale"
            />
          </Col>
          <Col md={3} sm={12}>
            <DatePickerCustom
              className={`date-picker form-control`}
              placeholderText={STRING.fromDate}
              handleChange={this.handleChange}
              selected={fromDate}
              maxDate={new Date(toDate)}
              dateFormat="dd/MM/yyyy"
            />
          </Col>
          <Col md={3} sm={12}>
            <DatePickerCustom
              className={`date-picker form-control`}
              placeholderText={STRING.toDate}
              handleChange={this.handleChange}
              selected={toDate}
              minDate={new Date(fromDate)}
              dateFormat="dd/MM/yyyy"
            />
          </Col>
        </Row>
      </div>
    );
  }

  renderchart() {
    const { callStatusData } = this.state;
    const index = callStatusData.findIndex((item) => item.count > 0);
    const data = {
      labels:
        index >= 0 ? callStatusData.filter((item) => item.id !== 5).map((item) => item.name) : ['Chưa có cuôc gọi'],
      datasets: [
        {
          label: 'My First Dataset',
          data: index >= 0 ? callStatusData.filter((item) => item.id !== 5).map((item) => item.count) : [1],
          backgroundColor:
            index >= 0
              ? ['rgb(54, 162, 235)', 'rgb(255, 99, 132)', 'rgb(255, 205, 86)', 'rgb(90, 164, 93)']
              : ['rgb(212, 216, 218)'],
          hoverOffset: 4,
        },
      ],
    };

    // 'rgb(42, 108, 133)'

    return (
      <div className="w-100 table-wrap">
        <span className="pb-2 d-indivne-block">
          <b>Thống kê cuộc gọi</b>
        </span>
        <Row>
          <Col md={6}>
            <Line data={this.state.dataChart} />
          </Col>
          <Col md={6}>
            <Doughnut type="doughnut" data={data} />
          </Col>
        </Row>
        <Row>
          <b className="text-center mt-4" style={{ width: '100%' }}>
            THỐNG KÊ BÁO CÁO SỐ CUỘC GỌI
          </b>
        </Row>
      </div>
    );
  }

  handlePageChange = (page) => {
    const { search, activePage, [STRING.fromDate]: fromDate, [STRING.toDate]: toDate } = this.state;
    this.setState({ ...this.state, activePage: page }, () => this.requestGetTelesaleStatistic());
  };

  renderPagination() {
    const { paging } = this.state;
    let pagingTable = { page: paging.page, totalItemCount: paging.count, limit: paging.limit };
    return <Pagi paging={pagingTable} handlePageChange={this.handlePageChange} />;
  }

  millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
  }

  renderTable() {
    const { listReports, activePage } = this.state;
    return (
      <div className="w-100 table-wrap">
        <span className="pb-2 d-indivne-block">
          <b>Báo cáo chi tiết</b>
        </span>
        <Table bordered responsive className="mt-4">
          <thead>
            <tr>
              <th>STT</th>
              <th>Sale</th>
              {/* <th>Công việc tham gia</th> */}
              <th>Khách hàng</th>
              {/* <th>Tiến độ</th> */}
              <th>Trạng thái cuộc gọi</th>
              <th>Thời lượng cuộc gọi</th>
              <th>Thời gian gọi trung bình</th>
              <th>Số cơ hội</th>
              <th>Doanh số (VNĐ)</th>
              <th>Điểm vpoint (Điểm)</th>
            </tr>
          </thead>
          <tbody>
            {listReports?.length > 0 ? (
              listReports?.map((value, index) => (
                <tr>
                  <td>{(activePage - 1) * 12 + index + 1 || '--'}</td>
                  <td>{value?.full_name || '--'}</td>
                  {/* <td>
                    {value?.job_telesales?.length > 0 ? (
                      value?.job_telesales?.map((item) => <div>{item?.job_name || '--'}</div>)
                    ) : (
                      <p>Không có dữ liệu</p>
                    )}
                  </td> */}
                  <td>
                    <b>{value?.total_customer || '0'}</b>
                    {/* <div>{`Chưa giao: ${value['Chưa giao việc'] || '0'}`}</div> */}
                    <div>{`Đã giao việc: ${value['Đã giao việc'] || '0'}`}</div>
                    <div>{`Đã nhận việc: ${value['Đã nhận việc'] || '0'}`}</div>
                    <div>{`Đã gọi: ${value['Đã gọi'] || '0'}`}</div>
                    <div>{`Từ chối: ${value['Từ chối'] || '0'}`}</div>
                  </td>
                  {/* <td>
                    {value?.total_customer != 0
                      ? parseFloat((value['Đã gọi'] / value?.total_customer) * 100).toFixed(2) + '%'
                      : '0.00%'}
                  </td> */}
                  <td>
                    <div>{`Không nghe máy: ${value['Không nghe máy'] || '0'}`}</div>
                    <div>{`Bận gọi lại sau: ${value['Bận gọi lại sau'] || '0'}`}</div>
                    <div>{`Sai thông tin: ${value['Sai thông tin'] || '0'}`}</div>
                    <div>{`Đồng ý trao đổi: ${value['Đồng ý trao đổi'] || '0'}`}</div>
                  </td>
                  <td>
                    <b>{value.total_call_1 + value.total_call_2 + value.total_call_3 + value.total_call_4}</b>
                    <div>Dưới 1 phút: {value.total_call_1 || 0}</div>
                    <div>Dưới 3 phút: {value.total_call_2 || 0}</div>
                    <div>Dưới 5 phút: {value.total_call_3 || 0}</div>
                    <div>Trên 5 phút: {value.total_call_4 || 0}</div>
                  </td>
                  <td>
                    {value.call_duration && value.total_call
                      ? this.millisToMinutesAndSeconds(
                          parseInt((parseInt(value.call_duration) * 1000) / value.total_call)
                        )
                      : '00:00'}
                  </td>
                  <td>
                    <b>{value?.total_opportunity || '0'}</b>
                    <div>{`Chưa tiếp nhận: ${value['Chưa tiếp nhận'] || '0'}`}</div>
                    <div>{`Đã tiếp nhận: ${value['Đã tiếp nhận'] || '0'}`}</div>
                    <div>{`Đang xử lý: ${value['Chưa xử lý'] || '0'}`}</div>
                    <div>{`Thành công: ${value['Thành công'] || '0'}`}</div>
                  </td>
                  <td>
                    {value?.total_sales
                      ? value?.total_sales.toLocaleString('vi', {
                          currency: 'VND',
                        })
                      : '0'}
                  </td>
                  <td>{value?.total_vpoint || '0'}</td>
                </tr>
              ))
            ) : (
              <td colSpan={9}>
                <Empty description="Không có dữ liệu" />
              </td>
            )}
          </tbody>
        </Table>
        <Row>
          <Col md={12} className="p-0" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {this.renderPagination()}
          </Col>
        </Row>
      </div>
    );
  }

  addFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalAddField: {
        ...this.state.modalAddField,
        [field]: value,
      },
    });
  };

  render() {
    const { isLoading } = this.state;
    return (
      <>
        {isLoading && <Loading />}
        {this.renderBody()}
      </>
    );
  }
}

export default withRouter(Screen);
