import React, { useState, useEffect } from 'react';
import '@styles/Settings.css';
import { Row, Col, FormControl, Button, Modal, Table, FormGroup } from 'react-bootstrap';
import { notifyFail, notifySuccess } from '@utils/notify';
import Loading from '@src/components/Loading';
import { Empty, Button as ButtonAntd, Input, InputNumber, Select, Upload } from 'antd';
import { convertToTime } from '@utils/convertToTime';
import { STRING } from '@constants/Constant';
import { MinusCircleOutlined, PlusOutlined, CloseSquareOutlined } from '@ant-design/icons';
import { requestUpdateAudioScenario, requestGetAudioScenario } from '@constants/Api';

const { Option } = Select;

const SignUpAudio = (props) => {
  return (
    <>
      <Col sm={12} md={12}>
        <Row>
          <Col sm={12} className="d-flex justify-content-between">
            <h5>Đăng ký thử giọng:</h5>
            <Button variant="success" className="text-light m-0 mt-4" onClick={() => props.onClickUpdateAudition()}>
              Lưu kịch bản
            </Button>
          </Col>
        </Row>
      </Col>
      <Col sm={12} md={12}>
        <Row>
          <Col md={6} sm={12}>
            <FormControl as="select" className="mb-3" value={props.audioType} onChange={props.onChangeAudioType}>
              <option value={1}>Link youtube</option>
              <option value={2}>Đăng video</option>
            </FormControl>
          </Col>
          <Col md={12} sm={12}>
            {parseInt(props.audioType) === 1 ? (
              <FormControl
                type="text"
                placeholder="Link youtube"
                value={props.linkYoutube}
                onChange={props.onChangeLinkYoutube}
              />
            ) : parseInt(props.audioType) === 2 ? (
              props.uploadVideo && props.uploadVideo?.fileList?.length === 0 ? (
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture-card"
                  fileList={props.uploadVideo.fileList}
                  // onPreview={(file) => props.onPreviewVideo(file)}
                  onChange={props.onChangeVideo}
                >
                  {props.uploadVideo?.fileList?.length >= 1 ? null : (
                    <div>
                      <PlusOutlined />
                      <div>Đăng video</div>
                    </div>
                  )}
                </Upload>
              ) : (
                <div style={{ border: '1px solid dotted', position: 'relative' }}>
                  <CloseSquareOutlined
                    style={{
                      position: 'absolute',
                      fontSize: 30,
                      left: '36%',
                      cursor: 'pointer',
                      color: 'red',
                    }}
                    onClick={() => {
                      props.onRemoveVideo();
                      // handleChangeVideo(uploadVideo)
                      // setUploadVideo({
                      //   fileList: [],
                      // })
                      // setVideoUrl('')
                    }}
                  />
                  <video
                    controls
                    // src={props.videoUrl}
                    src={
                      props.videoUrl
                        ? props.videoUrl
                        : URL.createObjectURL(props.uploadVideo?.fileList[0].originFileObj)
                    }
                    className="uploaded-pic img-thumbnail "
                    style={{ width: '500px', height: '300px' }}
                  ></video>
                </div>
              )
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Col>
      <Col md={12} sm={12}>
        <Row>
          <Col md={12} sm={12}>
            <FormControl
              as="textarea"
              className="mt-3"
              placeholder="Mô tả"
              value={props.description}
              onChange={props.onChangeDescription}
            />
          </Col>
        </Row>
      </Col>
    </>
  );
};

const ModalAddUpdate = (props) => {
  return (
    <Modal show={props.visible} onHide={() => props.onCloseModal()} dialogClassName="modal-90w" centered>
      <Modal.Header closeButton className="bg-primary">
        <h5 className="text-light pb-0">
          {props.shouldAddScenario ? 'Tạo kịch bản thử giọng' : 'Sửa kịch bản thử giọng'}
        </h5>
      </Modal.Header>

      <Modal.Body className="custom-body">
        {/* {this.checkValidationReview()} */}
        <FormGroup className="text-left">
          <Row className="modal-row">
            <label>Bước</label>
          </Row>
          <Row className="modal-row">
            <FormControl
              type="number"
              placeholder="Bước"
              value={props.data.order}
              onChange={(e) => props.onChangeData('order', e.target.value)}
            ></FormControl>
          </Row>
        </FormGroup>
        <FormGroup className="text-left">
          <Row className="modal-row">
            <label>Tên bước</label>
          </Row>
          <Row className="modal-row">
            <FormControl
              type="text"
              placeholder="Tên bước"
              value={props.data.name}
              onChange={(e) => props.onChangeData('name', e.target.value)}
            ></FormControl>
          </Row>
        </FormGroup>
        <FormGroup className="text-left">
          <Row className="modal-row">
            <label>Nội dung</label>
          </Row>
          <Row className="modal-row">
            <FormControl
              as="textarea"
              placeholder="Nội dung"
              value={props.data.content}
              onChange={(e) => props.onChangeData('content', e.target.value)}
            ></FormControl>
          </Row>
        </FormGroup>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          variant="success"
          onClick={() => {
            props.onSubmitScenario();
          }}
          className="text-light"
          style={{ background: 'green' }}
        >
          Lưu
        </Button>
        <Button
          style={{ background: 'red' }}
          variant="danger"
          onClick={() => props.onCloseModal()}
          className="text-light"
        >
          Hủy
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ModalConfirmDelete = (props) => {
  return (
    <Modal
      show={props.visible}
      onHide={() => {
        props.onCloseModal();
      }}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      centered
    >
      <Modal.Header closeButton className="bg-red">
        <h5 className="text-light pb-0">Bạn chắc chắn muốn xóa bước này?</h5>
      </Modal.Header>
      <Modal.Body className="custom-body">
        <Row>
          <Col className="button-wrapper" style={{ textAlign: 'center' }}>
            <ButtonAntd
              className="text-light"
              style={{ background: 'green' }}
              onClick={() => {
                props.onConfirm();
              }}
            >
              Có
            </ButtonAntd>
            <ButtonAntd
              style={{ background: 'red' }}
              className="text-light"
              onClick={() => {
                props.onCloseModal();
              }}
            >
              Thoát
            </ButtonAntd>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

const AudioScenario = (props) => {
  return (
    <>
      <Col sm={12} md={12}>
        <Row>
          <Col sm={12} className="d-flex justify-content-between">
            <h5 style={{ marginTop: '20px' }}>Kịch bản thử giọng:</h5>
            <i
              className="fas fa-plus text-green add-btn"
              style={{ marginTop: '20px' }}
              onClick={() => props.onOpenModalAdd()}
            ></i>
          </Col>
        </Row>
      </Col>
      {props.audioScenarioList.length ? (
        props.audioScenarioList.map((item, index) => {
          return (
            <Col sm={12} md={12}>
              <Row>
                <Col sm={12}>
                  <div
                    style={{
                      padding: '30px 20px',
                      backgroundColor: '#ffffff',
                      borderRadius: '12px',
                      boxShadow: '0 20px 80px rgba(0, 0, 0, 0.2)',
                      marginTop: '10px',
                    }}
                  >
                    <Row>
                      <Col sm={12}>
                        <Row>
                          <Col sm={9} style={{ color: 'red', paddingRight: 0 }}>
                            Bước {item.order}: <label style={{ color: 'black' }}>{item.name}</label>
                          </Col>
                          <Col sm={3} style={{ color: 'red' }}>
                            <div className="float-right">
                              <i
                                className="btnEdit fa fa-fw fa-edit mr-2"
                                onClick={() => {
                                  props.onOpenModalUpdate(item, index);
                                }}
                              ></i>
                              <i
                                className="far fa-trash-alt step-icon-del"
                                onClick={() => {
                                  props.onOpenModalDelete(index);
                                }}
                              ></i>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={12}>
                        <Row>
                          <Col sm={12} style={{ whiteSpace: 'pre-wrap' }}>
                            {item.content}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          );
        })
      ) : (
        <Empty description="Chưa có kịch bản thử giọng" />
      )}
    </>
  );
};

function AuditionRegistration() {
  const [isLoading, setIsLoading] = useState(true);
  const [audioType, setAudioType] = useState(null);
  const [linkYoutube, setLinkYoutube] = useState('');
  const [video, setVideo] = useState([]);
  const [audioScenarioList, setAudioScenarioList] = useState([]);
  const [scenarioModalVisible, setScenarioModalVisible] = useState(false);
  const [shouldAddScenario, setShouldAddScenario] = useState(true);
  const [scenarioModal, setScenarioModal] = useState({
    order: null,
    name: '',
    content: '',
  });
  const [modalConfirmVisible, setModalConfirmVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [description, setDescription] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [uploadVideo, setUploadVideo] = useState({
    fileList: [],
  });

  useEffect(() => {
    getData();
    setAudioType(1);
  }, []);

  useEffect(() => {
    if (parseInt(audioType) === 1) {
      setVideoUrl('');
      setUploadVideo({
        fileList: [],
      });
    }
    if (parseInt(audioType) === 2) {
      setLinkYoutube('');
    }
  }, [audioType]);

  const getData = async () => {
    try {
      setIsLoading(true);
      const res = await requestGetAudioScenario();
      console.log(res);
      setDescription(res.data.description);
      if (res.data.media_url.includes('youtube')) {
        setAudioType(1);
        setLinkYoutube(res.data.media_url);
      } else {
        setAudioType(2);
        setVideoUrl(res.data.media_url);
        setUploadVideo({
          fileList: [
            {
              uid: res.data.id,
              status: 'done',
              url: res.data.media_url,
              name: res.data.media_url,
            },
          ],
        });
      }
      const newArr = res.data.registration_scenario_steps.length
        ? res.data.registration_scenario_steps.map((item) => {
            return {
              order: item.order,
              name: item.title,
              content: item.content,
            };
          })
        : [];
      setAudioScenarioList(newArr);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  // ModalAddUpdate funcs
  const handleCloseModal = () => {
    setScenarioModalVisible(false);
    setScenarioModal({
      order: null,
      name: '',
      content: '',
    });
  };

  const handleChangeData = (fieldName, value) => {
    setScenarioModal({
      ...scenarioModal,
      [fieldName]: value,
    });
  };

  const handleSubmitScenario = () => {
    const { order, name, content } = scenarioModal;
    if (!order) {
      notifyFail('Vui lòng điền bước!');
      return;
    }
    if (!name) {
      notifyFail('Vui lòng điền tên bước!');
      return;
    }
    if (!content) {
      notifyFail('Vui lòng điền nội dung!');
      return;
    }

    if (shouldAddScenario) {
      let newArr = [...audioScenarioList];
      newArr.push(scenarioModal);
      setAudioScenarioList(newArr);
      notifySuccess('Thêm thành công!');
    } else {
      let newArr = [...audioScenarioList];
      const arrAfterUpdate = newArr.map((item, index) => {
        if (index === currentIndex) {
          return {
            ...scenarioModal,
          };
        } else {
          return item;
        }
      });
      setAudioScenarioList(arrAfterUpdate);
      notifySuccess('Cập nhật thành công!');
    }

    setScenarioModalVisible(false);
    setScenarioModal({
      order: null,
      name: '',
      content: '',
    });
  };

  // ModalConfirmDelete
  const handleCloseModalDelete = () => {
    setModalConfirmVisible(false);
  };

  const handleConfirmDelete = () => {
    let newArr = [...audioScenarioList];
    newArr.splice(currentIndex, 1);
    setAudioScenarioList(newArr);
    notifySuccess('Xóa thành công!');
    setModalConfirmVisible(false);
  };

  // SignUpAudio funcs
  const handleChangeAudioType = (value) => {
    setAudioType(value);
  };

  const handleChangeLinkYoutube = (value) => {
    setLinkYoutube(value);
  };

  const handleChangeDescription = (value) => {
    setDescription(value);
  };

  const handleChangeVideo = ({ fileList }) => {
    setUploadVideo({
      fileList,
    });
  };

  // const handlePreviewVideo = async (file) => {
  //   if (!file.url && !file.preview) {
  //     file.preview = await getBase64(file.originFileObj);
  //   }
  //   setVideoUrl(file.url || file.preview);
  // };

  const handleRemoveVideo = () => {
    setUploadVideo({
      fileList: [],
    });
    setVideoUrl('');
  };

  // AudioScenario
  const handleOpenModal = () => {
    setShouldAddScenario(true);
    setScenarioModalVisible(true);
  };

  const handleOpenModalUpdate = (item, index) => {
    setScenarioModal({
      ...item,
    });
    setCurrentIndex(index);
    setShouldAddScenario(false);
    setScenarioModalVisible(true);
  };

  const handleOpenModalDelete = (index) => {
    setCurrentIndex(index);
    setModalConfirmVisible(true);
  };

  const handleUpdateAudition = async () => {
    try {
      if (parseInt(audioType) === 1) {
        if (!linkYoutube) {
          notifyFail('Vui lòng điền link youtube!');
          return;
        }
      }
      if (parseInt(audioType) === 2) {
        if (!uploadVideo.fileList.length) {
          notifyFail('Vui lòng chọn đăng video!');
          return;
        }
      }
      if (!description) {
        notifyFail('Vui lòng điền mô tả!');
        return;
      }
      if (!audioScenarioList.length) {
        notifyFail('Vui lòng thêm kịch bản thử giọng!');
        return;
      }
      setIsLoading(true);
      console.log({ linkYoutube, uploadVideo, description, audioScenarioList });
      const jsonInput = {
        scenario: {
          description: description,
          scenario_steps: [...audioScenarioList],
        },
      };
      let formData = new FormData();
      formData.append('jsonInput', JSON.stringify(jsonInput));
      if (parseInt(audioType) === 1 || (parseInt(audioType) === 2 && videoUrl)) {
        formData.append('media_url', linkYoutube || videoUrl || '');
        formData.append('video', null);
        console.log('ko thay video');
      }
      if (parseInt(audioType) === 2 && !videoUrl) {
        formData.append('video', uploadVideo.fileList.length ? uploadVideo.fileList[0].originFileObj : '');
        console.log('có thay video');
      }
      const res = await requestUpdateAudioScenario(formData);
      notifySuccess('Lưu kịch bản thành công!');
      getData();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <ModalAddUpdate
        visible={scenarioModalVisible}
        data={scenarioModal}
        onCloseModal={() => handleCloseModal()}
        onChangeData={handleChangeData}
        onSubmitScenario={handleSubmitScenario}
      />
      <ModalConfirmDelete
        visible={modalConfirmVisible}
        onCloseModal={() => handleCloseModalDelete()}
        onConfirm={() => handleConfirmDelete()}
      />
      <SignUpAudio
        audioType={audioType}
        onChangeAudioType={(e) => handleChangeAudioType(e.target.value)}
        linkYoutube={linkYoutube}
        onChangeLinkYoutube={(e) => handleChangeLinkYoutube(e.target.value)}
        description={description}
        onChangeDescription={(e) => handleChangeDescription(e.target.value)}
        videoUrl={videoUrl}
        uploadVideo={uploadVideo}
        onChangeVideo={handleChangeVideo}
        // onPreviewVideo={(file) => {
        //   handlePreviewVideo(file);
        // }}
        onRemoveVideo={handleRemoveVideo}
        onClickUpdateAudition={() => handleUpdateAudition()}
      />
      <AudioScenario
        audioScenarioList={audioScenarioList}
        onOpenModalAdd={() => handleOpenModal()}
        onOpenModalUpdate={(item, index) => handleOpenModalUpdate(item, index)}
        onOpenModalDelete={(index) => handleOpenModalDelete(index)}
      />
      {/* <Col sm={12} md={12}>
        <Row>
          <Col sm={12} className="d-flex justify-content-center">
            <Button className="text-light m-0 mt-4" onClick={() => handleUpdateAudition()}>
              Lưu kịch bản
            </Button>
          </Col>
        </Row>
      </Col> */}
    </>
  );
}

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export default AuditionRegistration;
