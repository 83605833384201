import { isCallEnded } from '@components/callProviders/CallHelper';
import React from 'react';
import NextCustomerDetails from '../components/NextCustomerDetails';
import CallUtil from '@components/callProviders/CallUtil';
import { CALLING_CODE } from '@components/callProviders/CallConstant';
import { ButtonSystemStyle } from '@styles/Style';
import EZLoading from 'src/modules/loading/EZ.Loading';
import { RetweetOutlined } from '@ant-design/icons';
import { requestStartCall } from 'src/network/web_sale/CallApi';
import moment from 'moment';
import { FIELD_IMPORT } from '@constants/Constant';
import mixpanel from 'mixpanel-browser';
import { requestUpdateCustomer } from 'src/network/web_sale/CustomerApi';
import { notifyFail } from '@utils/notify';
import SpinInButton from '@components/SpinInButton';
import { Button } from 'antd';
import ButtonModules from 'src/modules/Button';

const AddOppoIcon = () => (
  <svg
    width="18px"
    height="18px"
    fill="white"
    viewBox="0 0 1024 1024"
    style={{ margin: '0 1px 3px 0' }}
  >
    <path d="M476 399.1c0-3.9-3.1-7.1-7-7.1h-42c-3.8 0-7 3.2-7 7.1V484h-84.5c-4.1 0-7.5 3.1-7.5 7v42c0 3.8 3.4 7 7.5 7H420v84.9c0 3.9 3.2 7.1 7 7.1h42c3.9 0 7-3.2 7-7.1V540h84.5c4.1 0 7.5-3.2 7.5-7v-42c0-3.9-3.4-7-7.5-7H476v-84.9zM560.5 704h-225c-4.1 0-7.5 3.2-7.5 7v42c0 3.8 3.4 7 7.5 7h225c4.1 0 7.5-3.2 7.5-7v-42c0-3.8-3.4-7-7.5-7zm-7.1-502.6c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v704c0 17.7 14.3 32 32 32h512c17.7 0 32-14.3 32-32V397.3c0-8.5-3.4-16.6-9.4-22.6L553.4 201.4zM664 888H232V264h282.2L664 413.8V888zm190.2-581.4L611.3 72.9c-6-5.7-13.9-8.9-22.2-8.9H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h277l219 210.6V824c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V329.6c0-8.7-3.5-17-9.8-23z" />
  </svg>
);

const DownloadIcon = () => (
  <svg
    width="18px"
    height="18px"
    fill="white"
    viewBox="0 0 1024 1024"
    style={{ margin: '0 1px 3px 0' }}
  >
    <path d="M505.7 661a8 8 0 0012.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V168c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z" />
  </svg>
);

const RecallButton = React.memo(({ call, reCallCurrentCustomer }) => {
  if (
    CallUtil.lastCallInfo.lastStartCallPayload &&
    isCallEnded(call.status) &&
    call.status !== CALLING_CODE.Preparing
  ) {
    return (
      <ButtonModules
        onClick={() => {
          reCallCurrentCustomer();
          console.log(CallUtil.lastCallInfo);
        }}
        color="primary"
      >
        <RetweetOutlined />
        Gọi lại
      </ButtonModules>
    );
  }
});

const PageheaderExtra = ({
  call,
  nextCustomer,
  resetNexCustomer,
  handleOpenModalOpportunity,
  customerDetail,
  setSubPhones,
  handleSubmitResult,
  isLoadingNextCustomer,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [isLoadingSubmitForm, setIsLoadingSubmitForm] = React.useState(false);

  const reCallCurrentCustomer = async () => {
    try {
      setLoading(true);
      const { lastCallInfo } = CallUtil;
      // const hotline = CallUtil.getDefaultHotlines();
      // console.log('hotline1111', hotline);
      const callRes = await requestStartCall(lastCallInfo.lastStartCallPayload);
      CallUtil.makeCall(
        lastCallInfo.provider,
        lastCallInfo.destination,
        callRes.data.id,
        lastCallInfo.masked,
        lastCallInfo.jobId,
        CallUtil.isUncontacted,
        lastCallInfo.lastStartCallPayload
      );
      resetNexCustomer();
    } catch (error) {
      console.log('Error callNextCustomer: ', { error });
    } finally {
      setLoading(false);
    }
  };

  const handleSaveInfor = async (autoNext = false) => {
    //Button Lưu phiếu Kq
    try {
      setIsLoadingSubmitForm(true);
      console.log('customerDetail:::in', customerDetail);
      if (customerDetail.dob) {
        //check xem dob property có tồn tại trong customerDetail? ngày sinh
        customerDetail.dob = moment(customerDetail.dob).toISOString(); //nếu có thì format moment
      }
      for (const property in customerDetail) {
        //lặp qua key của object CustomerDetail
        if (property.includes(FIELD_IMPORT.DATE_TIME)) {
          //nếu có trường nào bao gồm chữ "date_time"
          customerDetail[property.replace(FIELD_IMPORT.DATE_TIME, '')] = //xóa chữ date_time trong tên trường và thêm thuộc tính mới
            customerDetail[property] !== null
              ? moment(customerDetail[property]).format('DD/MM/YYYY')
              : null;
          delete customerDetail[property];
        } else {
          if (customerDetail[property] === undefined) {
            // nếu có trường giá trị undefined thay thế = ""
            customerDetail[property] = '';
          }
        }
      }

      mixpanel.track('Save called customer details', { customerDetail });
      const updateCustomerResponse = await requestUpdateCustomer({
        id: customerDetail.id,
        ...customerDetail,
      }); // lấy dữ liệu mới từ reponse để fill vào phiếu kêt quả
      setSubPhones(updateCustomerResponse.data.main.customer_phones);
      // setDisplayUpdateCustomerModal(false);
      handleSubmitResult(autoNext);
    } catch (error) {
      notifyFail(error.msg);
    } finally {
      setIsLoadingSubmitForm(false);
    }
  };

  return (
    <div>
      {(loading || isLoadingSubmitForm) && <EZLoading />}
      {/* <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          background: 'transparent',
        }}
      >
        {isCallEnded(call.status) && nextCustomer && (
          <NextCustomerDetails data={nextCustomer} />
        )}
      </div> */}

      <div className="buttonWrapper" style={{ gap: 8 }}>
        <RecallButton
          call={call}
          reCallCurrentCustomer={reCallCurrentCustomer}
        />

        <ButtonModules
          onClick={() => handleOpenModalOpportunity()}
          icon={<AddOppoIcon />}
          color="primary"
        >
          Tạo cơ hội
        </ButtonModules>

        {/* Button Lưu và Button Lưu và đồng bộ */}
        {isCallEnded(call.status) && (
          <ButtonModules
            loading={isLoadingSubmitForm}
            onClick={() => {
              if (customerDetail.name) {
                handleSaveInfor();
              } else {
                notifyFail('Vui lòng cập nhật tên khách hàng');
              }
            }}
            icon={isLoadingSubmitForm ? <SpinInButton /> : <DownloadIcon />}
            color="primary"
          >
            Lưu phiếu kết quả
          </ButtonModules>
        )}

        {/* Button Hoàn thành và chi tiết khách hàng tiếp theo */}
        {isLoadingNextCustomer && (
          // <p>Đang lấy thông tin khách hàng tiếp theo</p>
          <Button type="link" loading={true}>
            Đang tải KH tiếp theo
          </Button>
        )}
        {isCallEnded(call.status) && nextCustomer && (
          <ButtonModules
            loading={isLoadingNextCustomer || isLoadingSubmitForm}
            onClick={() => {
              handleSaveInfor(true);
            }}
            icon={
              isLoadingNextCustomer || isLoadingSubmitForm ? (
                <SpinInButton />
              ) : (
                <></>
              )
            }
            color="primary"
          >
            Lưu và gọi KH tiếp theo
          </ButtonModules>
        )}
      </div>
      {isCallEnded(call.status) && nextCustomer && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '6px',
          }}
        >
          <NextCustomerDetails data={nextCustomer} />
        </div>
      )}
    </div>
  );
};

export default React.memo(PageheaderExtra);
