const MOBI = /((^(\+84|84|0|0084){1})(3)(2|3|4|5|6|7|8|9))+([0-9]{7})$/;
const VINA = /((^(\+84|84|0|0084){1})(8)(1|2|3|4|5|6|8|9))+([0-9]{7})$/;
const VIETTEL = /((^(\+84|84|0|0084){1})(9)(2|3|4|1|6|7|8|9|0))+([0-9]{7})$/;
const SEVEN = /((^(\+84|84|0|0084){1})(7)(0|6|7|8|9))+([0-9]{7})$/;
const FIVE = /((^(\+84|84|0|0084){1})(5)(2|9))+([0-9]{7})$/;

export const rulesZaloPerson = {
  phone: [
    {
      required: true,
      message: 'Vui lòng nhập số điện thoại',
    },
    () => ({
      validator(_, value) {
        if (!value?.toString()?.trim()) return Promise.resolve();
        if (value.length > 10)
          return Promise.reject(
            new Error('Vui lòng nhập đúng định dạng số điện thoại!')
          );
        if (
          (!value?.toString()?.match(SEVEN) &&
            !value?.toString()?.match(MOBI) &&
            !value?.toString()?.match(VIETTEL) &&
            !value?.toString()?.match(VINA) &&
            !value?.toString()?.match(FIVE)) ||
          isNaN(Number(value))
        ) {
          return Promise.reject(
            new Error('Vui lòng nhập đúng định dạng số điện thoại!')
          );
        }
        return Promise.resolve();
      },
    }),
  ],
};
