import { toQuerystringFromObject } from './apiUtil';
const CustomerApi = (apiClient) => ({
  requestCustomerList: ({ search = '', page = '1', province_ids = [], group_id = '', ...filters }) => {
    console.log(filters);
    const otherOptions = toQuerystringFromObject(filters);
    return apiClient.get(
      `customer/list?query=${encodeURIComponent(search)}` +
        `&province_ids=${province_ids.join(',')}` +
        `&group_id=${group_id}` +
        `&page=${page}` +
        `&${otherOptions}`
    );
  },

  requestCustomerDetail: (id) => {
    return apiClient.get(`customer/detail?id=${id}`);
  },
  // CUSTOMER CREATE
  requestCustomerCreate: (payload) => {
    return apiClient.post(`customer/create`, payload);
  },
  // CUSTOMER UPDATE
  requestCustomerUpdate: (payload) => {
    return apiClient.post(`customer/update`, payload);
  },

  // CUSTOMER IMPORT
  requestCustomerImport: (payload) => {
    return apiClient.post(`customer/import`, payload);
  },

  // CUSTOMER TRANSPORT
  getListTransportCustomer: () => {
    return apiClient.get(`customer/dataamendments`);
  },

  //CUSTOMER DEAL
  getListDealCustomer: (payload) => {
    return apiClient.get(`customer/transactions?customer_id=${payload.customer_id}`);
  },

  requestFilterList: (payload) => {
    return apiClient.get(`customer/customerFilters?enterprise_id=${payload.enterprise_id}`);
  },

  requestFormInsertSchemaCustomer: (payload = { enterprise_id: '' }) => {
    return apiClient.get(`customer/formInsertSchema?enterprise_id=${payload.enterprise_id}`, { ...payload });
  },

  getCustomerDetailSchema: (payload = { enterprise_id: '' }) => {
    return apiClient.get(`customer/customerDetailSchema?enterprise_id=${payload.enterprise_id}`);
  },
  getCustomerVehicleSchema: (payload = { enterprise_id: '' }) => {
    return apiClient.get(`customer/customerVehiclesSchema?enterprise_id=${payload.enterprise_id}`);
  },
  getCustomerVehicles: (payload = { enterprise_id: '', customer_id: null }) => {
    return apiClient.get(
      `customer/customerVehicles?enterprise_id=${payload.enterprise_id}&customer_id=${payload.customer_id}`
    );
  },

  /**
   *
   * @param {object} payload
   * @param {number} payload.enterprise_id optional
   * @param {number} payload.customer_id
   * @param {number} payload.vehicle_id
   * @param {object} payload.payload - data to be update - {key: value}
   */
  updateCustomerVehicle: (payload) => {
    return apiClient.post(`customer/customerVehicle`, payload);
  },
});
// CUSTOMER DETAIL
export default CustomerApi;
