import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import '@styles/Company.css';
import Table from 'react-bootstrap/Table';
import { Row, Col, FormControl } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import {
  getResultReviewer,
  getEnterprisesFilter,
  getJobCategoriesFilter,
  getJobFilter,
  getTelesalesFilter,
  getListCallStatus,
  getReviewerList,
  getListCall,
} from '@src/redux/actions';
import { Empty } from 'antd';
import reactotron from 'reactotron-react-js';
import Loading from '@src/components/Loading';
import { getItemFromId } from '@utils/getItemFromId';
import { RESULT_STATUS, ROUTER } from '@constants/Constant';

class EvaluationResultScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBar: {},
      activePage: 1,
      form: {
        job_category_id: '',
        job_id: '',
        telesale_id: '',
        call_status: '',
        limit: '',
        status: '',
      },
    };
  }

  componentDidMount() {
    const { form } = this.state.form;
    this.getData({ page: 1 });
    this.props.getJobCategoriesFilter();
    this.props.getJobFilter({
      enterprise_id: '',
      telesale_id: '',
      job_category_id: form?.job_category_id || '',
      limit: '',
    });
    this.props.getTelesalesFilter();
    this.props.getListCallStatus();
    this.props.getReviewerList();
    this.props.getEnterprisesFilter();
  }

  getData({ job_category_id, job_id, telesale_id, call_status, page, limit, status }) {
    this.props.getResultReviewer({
      page: page || '',
      job_category_id: job_category_id || '',
      job_id: job_id || '',
      telesale_id: telesale_id || '',
      call_status: call_status || '',
      status: status || '',
      limit: limit || '',
    });
  }

  renderBody() {
    return (
      <>
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <div className="content-header">
            <Row className="mt-3" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
              <Col md className="bg">
                <Row>
                  <h2> Kết quả đánh giá </h2>
                </Row>
                <div className="line" />
                <Row>
                  <Col md={12} sm={12} className="p-0">
                    {this.renderField()}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-3" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
              <Col md className="bg">
                <Row>
                  <Col md={12} sm={12}>
                    {this.renderTable()}
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="d-flex justify-content-center justify-content-md-end">
                    {this.renderPagination()}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }

  renderPagination() {
    const itemsCount = this.props.resultState?.paging?.count;
    const limit = this.props.resultState?.paging?.limit;
    const { job_category_id, job_id, telesale_id, call_status, page, status } = this.state;
    return (
      <Pagination
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled
        activePage={this.state.activePage}
        itemsCountPerPage={limit}
        totalItemsCount={itemsCount}
        pageRangeDisplayed={5}
        hideNavigation
        onChange={
          (page) => {
            this.setState({
              ...this.state,
              activePage: page,
            });
            this.getData({
              job_category_id,
              job_id,
              telesale_id,
              call_status,
              page,
              status,
              limit,
            });
          }
          //   this.getData(page, search, is_active))
        }
      />
    );
  }

  renderField() {
    const { job_id, call_status, telesale_id, job_category_id } = this.state.form;
    const categoryList = this.props.jobState?.data;
    const job = this.props.subJobState?.data;
    const saleList = this.props.telesaleState?.data;
    const callStatus = this.props.statusState?.data;
    const company = this.props.companyState?.data;
    return (
      <div className="w-100">
        <Row className="mb-2">
          <Col md={4} className="pb-1 pb-md-0">
            <FormControl
              as="select"
              id=""
              value={telesale_id}
              onChange={(e) => this.searchBarChange('telesale_id', e.target.value)}
            >
              <option value="">Sale</option>
              {saleList?.length > 0 && saleList?.map((value, index) => <option value={value.id}>{value.name} </option>)}
            </FormControl>
          </Col>
          <Col md={2} className="pb-1 pb-md-0">
            <FormControl
              as="select"
              value={job_category_id}
              onChange={(e) => this.searchBarChange('job_category_id', e.target.value)}
            >
              <option value="">Công việc chung</option>
              {categoryList && categoryList.length
                ? categoryList?.map((value) => (
                    <option value={value.id} key={value.id}>
                      {value.name}
                    </option>
                  ))
                : ''}
            </FormControl>
          </Col>
          <Col md={2} className="pb-1 pb-md-0">
            <FormControl
              as="select"
              id=""
              value={job_id}
              onChange={(e) => this.searchBarChange('job_id', e.target.value)}
            >
              <option value="">Công việc</option>
              {job_category_id != ''
                ? job?.length > 0 && job?.map((value, index) => <option value={value.id}>{value.name}</option>)
                : ''}
            </FormControl>
          </Col>
          <Col md={2} className="pb-1 pb-md-0">
            <FormControl
              as="select"
              id=""
              value={call_status}
              onChange={(e) => this.searchBarChange('call_status', e.target.value)}
            >
              <option value="">Trạng thái cuộc gọi</option>
              {callStatus.length > 0 &&
                callStatus?.map((value, index) => <option value={value.id}>{value.name}</option>)}
            </FormControl>
          </Col>
          <Col md={2} className="pb-1 pb-md-0">
            <FormControl as="select" value="" onChange={(e) => this.searchBarChange('', e.target.value)}>
              <option value="">Trạng thái</option>
              <option value="0">Đã giao</option>
              <option value="1">Đang thực hiện</option>
              <option value="2">Đã hoàn thành</option>
              <option value="3">Quá hạn</option>
            </FormControl>
          </Col>
        </Row>
      </div>
    );
  }

  // renderTable() {
  //     return (
  //         <div className="w-100 table-wrap " >
  //             <span className="pb-2 d-inline-block">Kết quả lọc: <b>230</b></span>
  //             <Link to={ROUTER.CALL_DETAIL} style={{color:"black"}}>
  //             <Row className="group-info"   >

  //                    <Col md={9} sm={9} xs={9} >

  //                        <b style={{fontSize:17}}>Nguyễn Thị Thu Thảo</b> <span>: 0349202944</span>
  //                    </Col>
  //                    <Col md={3} sm={3} xs={3}>
  //                    <i className="fa fa-user" aria-hidden="true"></i><span className="ml-2" >Nguyễn Xuân Hùng</span>
  //                    </Col>
  //                    <Col md={9} sm={9} xs={9}>
  //                    <i className="fa fa-suitcase" aria-hidden="true"></i>  <span> Chiến dịch giới thiệu dòng xe mới tới khách hàng tại hà nội</span>
  //                    </Col>
  //                    <Col md={3} sm={3} xs={3}>
  //                   <span className="ml-2" >Trạng thái</span>
  //                    </Col>
  //                    <Col md={9} sm={9} xs={9}>
  //                    <i className="fa fa-clock-o" aria-hidden="true"></i> <span>9:35 15/7/2020 </span><span>|<b className="ml-2">4:24s</b> </span>
  //                    </Col>
  //                    <Col md={3} sm={3} xs={3}>
  //                  <span className="ml-2 text-success" >Đồng ý trao đổi</span>
  //                    </Col>
  //             </Row>
  //             </Link>
  //         </div>
  //     )
  // }
  renderTable() {
    const result = this.props.resultState?.data;
    reactotron.log(result?.call_reviewers, 'call_reviewers');
    const itemsCount = this.props.resultState?.paging;
    return (
      <div className="w-100 table-wrap">
        <Row>
          <Col md={3} className="p-0">
            <span className="pb-2 d-inline-block">
              Kết quả lọc: <b className="text-danger">{itemsCount?.count || 0}</b>
            </span>
          </Col>
          <Col md={9} className="p-0">
            {result?.review_config?.length > 0 &&
              result?.review_config.map((result, index) => (
                <div style={{ textAlign: 'end' }}>
                  <b>{result?.name}: </b>
                  <b>{result.count}</b>
                </div>
              ))}
          </Col>
        </Row>
        {/* <Row className="mb-3">
          {result?.review_config?.length > 0 &&
            result?.review_config.map((result, index) => (
              <Col md={2}>
                <b>{result?.name}: </b>
                <b>{result.count}</b>
              </Col>
            ))}
        </Row> */}
        <Table striped bordered responsive hover>
          <thead>
            <tr>
              <th>STT</th>
              <th>Nhân viên đánh giá</th>
              <th>Công việc</th>
              <th>Sale</th>
              <th>Trạng thái cuộc gọi</th>
              <th>Số lượng</th>
              <th>Đã hoàn thành</th>
              <th>Trạng thái</th>
              <th>Thời hạn</th>
              <th>Ngày hoàn thành</th>
              <th>Nhận xét</th>
            </tr>
          </thead>
          <tbody>
            {' '}
            {result?.list_task?.length ? (
              result?.list_task.map((result, index) =>
                this.renderTableRow(result, index + itemsCount.limit * (this.state.activePage - 1) + 1)
              )
            ) : (
              <td colSpan="11">
                <Empty description={<span>Không có dữ liệu</span>} />
              </td>
            )}
          </tbody>
        </Table>
      </div>
    );
  }

  renderTableRow(result, index) {
    const status = getItemFromId(RESULT_STATUS, result.status);
    return (
      <tr
        style={{ cursor: 'default' }}
        onClick={() => {
          console.log('onclick row', result.id);
          this.props.history.push(ROUTER.EVALUATE_JOB_DETAIL + `/${result.id}`);
        }}
      >
        <td>{index}</td>
        <td>{result.full_name}</td>

        <td>{result?.jobs?.length && result?.jobs?.map((item, index) => <p>{item?.name}</p> || '--')}</td>
        <td>
          {result?.telesales?.length && result?.telesales?.map((item, index) => <p>{`${item?.name}`}</p> || '--')}
        </td>

        <td>
          {result?.call_status?.length && result?.call_status?.map((item, index) => <p>{`${item?.name}`}</p> || '--')}
        </td>
        <td>{result.amount}</td>
        <td>{result.called}</td>
        <td>{(status && status.name) || '--'}</td>
        <td>
          {Intl.DateTimeFormat('vi-VN').format(new Date(result?.start_date)) || '--'}
          <br></br>-{Intl.DateTimeFormat('vi-VN').format(new Date(result?.end_date)) || '--'}
        </td>
        <td>
          {result?.completed_at != null ? Intl.DateTimeFormat('vi-VN').format(new Date(result?.completed_at)) : '--'}
        </td>
        <td>
          {result?.review_configs?.length
            ? result?.review_configs?.map((item, index) => (
                <p>
                  <span>{item?.name}: </span>
                  <span>{item?.count || '0'}</span>
                </p>
              ))
            : '--'}
        </td>
      </tr>
    );
  }

  addFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalAddField: {
        ...this.state.modalAddField,
        [field]: value,
      },
    });
  };

  editFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalEditField: {
        ...this.state.modalEditField,
        [field]: value,
      },
    });
  };

  searchBarChange = async (field, value) => {
    this.setState(
      {
        ...this.state,
        form: {
          ...this.state.form,
          [field]: value,
        },
        // [field]: value,
      },
      () => {
        if (field !== 'search') {
          const { page, job_id, call_status, telesale_id, job_category_id } = this.state.form;
          this.getData({
            page: page || '',
            job_category_id: job_category_id || '',
            job_id: job_id || '',
            call_status: call_status || '',
            telesale_id: telesale_id || '',
          });
          this.props.getJobFilter({
            enterprise_id: '',
            telesale_id: '',
            job_category_id: job_category_id || '',
            limit: '',
          });
        }
      }
    );
  };

  render() {
    const { isLoading } = this.props.resultState;
    let loading = <Loading />;
    if (!isLoading) {
      loading = null;
    }
    return (
      <>
        {loading}
        {this.renderBody()}
        {/* {this.renderPagination()} */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  resultState: state.resultReviewerReducer,
  companyState: state.enterpriseFilterReducer,
  jobState: state.jobCategoryFilterReducer,
  subJobState: state.jobFilterReducer,
  telesaleState: state.telesaleFilterReducer,
  statusState: state.callStatusReducer,
  reviewerState: state.reviewerListReducer,
});

const mapDispatchToProps = {
  getResultReviewer,
  getEnterprisesFilter,
  getJobCategoriesFilter,
  getJobFilter,
  getTelesalesFilter,
  getListCallStatus,
  getReviewerList,
  getListCall,
  // createAccount,
  // getProvinceList
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EvaluationResultScreen));
