import { clearJobDetail } from '@actions/';
import {
  CalendarOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  PauseCircleOutlined,
  PlusSquareOutlined,
  RedoOutlined,
  SyncOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';
import SubNameJob from '@components/SubNameJob';
import Wrapper from '@components/ui/wrapper/Wrapper';
import {
  changeActive,
  requestCheckCreateSubJob,
  requestDeleteJob,
  requestJobList,
} from '@constants/Api';
import {
  JOB_FILTER,
  JOB_STATUS,
  ROUTER,
  BASE_URL_IMAGE,
} from '@constants/Constant';
import { randomColor } from '@constants/funcHelper';
import {
  BreadcrumbAntStyle,
  ButtonSystemStyle,
  JobNameInTableStyle,
  TableAntStyle,
} from '@styles/Style';
import { notifyFail } from '@utils/notify';
import {
  Avatar,
  Badge,
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Dropdown,
  Empty,
  Menu,
  Modal,
  Progress,
  Row,
  Select,
  Tag,
  Tooltip,
} from 'antd';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TypingAutoSearch from 'src/common/Inputs/TypingAutoSearch';
import SubJob from './Components/SubJob';

const { Option } = Select;
const { RangePicker } = DatePicker;

export default function Job() {
  const user = useSelector(states => states.userReducer.data);
  const history = useHistory();
  const dispatch = useDispatch();
  // const jobs = useSelector((state) => state.jobReducer);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [params, setParams] = useState({
    page: 1,
    search: '',
    job_category_id: '',
    status: '',
    enterprise_id: '',
    job_status: '',
    fromDate: '',
    toDate: '',
  });

  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 0,
  });

  const [date, setDate] = useState({
    fromDate: '',
    toDate: '',
  });
  const [visibleModalTime, setVisibleModalTime] = useState(false);
  const [rowCurrentSelected, setRowCurrentSelected] = useState([]);

  const getData = async () => {
    try {
      setIsLoadingTable(true);
      const res = await requestJobList(params);
      const data = res.data.map(job => ({
        ...job,
        key: job.id,
      }));
      setJobs(data);
      if (params.search !== '') {
        const arrayColumSelected = [];
        res.data.map(job => {
          if (job.countSubJob) {
            arrayColumSelected.push(job.id);
          }
        });
        setRowCurrentSelected([...arrayColumSelected]);
      }
      setPaging({
        total: res.paging.totalItemCount,
        current: res.paging.page,
        pageSize: res.paging.limit,
      });
    } catch (error) {
      console.error('Exception ' + error);
    } finally {
      setIsLoadingTable(false);
    }
  };

  useEffect(() => {
    getData();
  }, [params]);

  const handleChangeActive = async (id, isActive) => {
    try {
      await changeActive({
        isActive: isActive === 1 ? 2 : 1,
        job_id: id,
      });
      // getJobs();
      getData();
    } catch (err) {
      notifyFail(err.msg);
    }
  };

  const handleDeleteJob = async id => {
    mixpanel.track(`Delete job, id: ${id}`);
    try {
      await requestDeleteJob({
        job_id: id,
      });
      getData();
    } catch (error) {
      notifyFail(error.msg);
    }
  };

  const handleCheckCreateSubJob = async id => {
    try {
      await requestCheckCreateSubJob({ job_id: id });

      history.push({
        pathname: ROUTER.JOB_GENERAL_ADD + `/${id}`,
        state: { jobId: id },
      });
    } catch (error) {
      // console.error('Exception ' + error);
      notifyFail(error.msg || 'Không thể giao việc');
    }
  };

  const modalConfirmChangeActiveJob = (id, isActive) => {
    mixpanel.track(`Change status job`);
    Modal.confirm({
      title: 'Xác nhận',
      icon: <ExclamationCircleOutlined style={{ fontSize: '30px' }} />,
      content:
        isActive === 1
          ? 'Bạn chắc chắn muốn tạm dừng hoạt động'
          : 'Bạn chắc chắn muốn khôi phục hoạt động!',
      okText: isActive === 1 ? 'Tạm dừng' : 'Khôi phục',
      cancelText: 'Quay lại',
      onOk: () => handleChangeActive(id, isActive),
    });
  };

  const columns = [
    {
      width: 130,
      title: '',
      dataIndex: '',
      key: '',
      render: (text, record, index) => (
        <>
          <Badge
            size="small"
            count={record.countSubJob}
            style={{ backgroundColor: '#52c41a' }}
          >
            <ButtonSystemStyle
              onClick={event => {
                event.stopPropagation();
                dispatch(clearJobDetail());
                handleCheckCreateSubJob(record.id);
              }}
            >
              Giao việc
            </ButtonSystemStyle>
          </Badge>
        </>
      ),
    },
    {
      // width: '20%',
      title: 'Công việc',
      dataIndex: '',
      key: '',
      render: (text, record, index) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <JobNameInTableStyle
              onClick={event => {
                event.stopPropagation();
                history.push(ROUTER.JOB_RESULT + `/${record.id}`);
              }}
            >
              {record.name}
            </JobNameInTableStyle>
          </div>
          {SubNameJob(record.created_at, record.job_dialer_type)}
        </div>
      ),
    },
    {
      width: 170,
      title: 'Tiến độ',
      dataIndex: '',
      key: '',
      render: (text, record, index) => (
        <>
          <Progress
            percent={
              record.countCustomer !== 0
                ? ((record.countCalled / record.countCustomer) * 100).toFixed(1)
                : 0
            }
            strokeWidth={12}
            strokeColor={{
              '0%': '#108ee9',
              '100%': '#87d068',
            }}
          />
          <p
            style={{ fontSize: '12px' }}
          >{`${record.countCalled}/${record.countCustomer} khách hàng`}</p>
        </>
      ),
    },
    {
      width: 120,
      title: 'Sale',
      dataIndex: 'profiles',
      key: 'profiles',
      render: profiles => {
        if (profiles) {
          try {
            // let sales = JSON.parse(profiles);
            return (
              <Avatar.Group
                maxCount={2}
                maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
              >
                {profiles.map((sale, index) => {
                  if (sale.full_name !== null && sale.profile_image !== null) {
                    const name = sale.full_name.trim().split(' ');
                    return (
                      <Tooltip
                        key={index}
                        title={sale.full_name}
                        placement="bottom"
                      >
                        <Avatar
                          src={sale.profile_image && `${sale.profile_image}`}
                          style={
                            index >= 7
                              ? { display: 'none' }
                              : {
                                  backgroundColor: randomColor(
                                    name[name.length - 1]
                                      .charAt(0)
                                      ?.toUpperCase()
                                  ),
                                }
                          }
                        >
                          {sale.profile_image
                            ? ''
                            : name[name.length - 1].charAt(0)?.toUpperCase()}
                        </Avatar>
                      </Tooltip>
                    );
                  } else {
                    return (
                      <Avatar
                        key={index}
                        style={{ backgroundColor: '#87d068' }}
                        icon={<UserOutlined />}
                      />
                    );
                  }
                })}
              </Avatar.Group>
            );
          } catch (error) {
            console.log('error: ', error);
          }
        } else {
          return <>--</>;
        }
      },
    },
    {
      width: 160,
      title: 'Tình trạng',
      dataIndex: 'status',
      key: 'status',
      render: value => {
        // let name = '--';
        let tag = {
          color: 'cyan',
          name: '--',
        };
        JOB_STATUS.map(item => {
          if (item.id === value) {
            // name = item.name;
            switch (value) {
              case 1:
                tag = {
                  color: 'warning',
                  name: item.name,
                  icon: <ClockCircleOutlined />,
                };
                break;

              case 2:
                tag = {
                  color: 'processing',
                  name: item.name,
                  icon: <SyncOutlined />,
                };
                break;

              case 3:
                tag = {
                  color: 'success',
                  name: item.name,
                  icon: <CheckCircleOutlined />,
                };
                break;

              default:
                break;
            }
          }
        });
        return (
          <Tag
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
            color={tag.color}
            icon={tag.icon}
          >
            {tag.name}
          </Tag>
        );
      },
    },
    {
      width: 50,
      title: '',
      dataIndex: '',
      key: '',
      render: (text, record, index) => (
        <Dropdown overlay={menu(record)} trigger={['click']}>
          <a className="ant-dropdown-link" onClick={e => e.stopPropagation()}>
            <UnorderedListOutlined />
          </a>
        </Dropdown>
      ),
    },
  ];

  const menu = job => {
    return (
      <Menu
        onClick={({ item, key, keyPath, domEvent }) => {
          domEvent.stopPropagation();
          switch (key) {
            case 'edit':
              history.push({
                pathname: ROUTER.JOB_EDIT + `/${job.id}`,
                state: { jobId: job.id },
              });
              break;

            case 'pause':
              modalConfirmChangeActiveJob(job.id, job.is_active);
              break;

            case 'start':
              modalConfirmChangeActiveJob(job.id, job.is_active);
              break;

            case 'detail':
              history.push(ROUTER.JOB_RESULT + `/${job.id}`);
              mixpanel.track(`Go to detail result job: ${job.name}`);
              break;

            case 'delete':
              handleDeleteJob(job.id);
              break;

            default:
              break;
          }
        }}
      >
        {[2, 3].includes(job.job_call_type) ? (
          <>
            <Menu.Item key="detail" icon={<InfoCircleOutlined />}>
              Chi tiết kết quả
            </Menu.Item>
          </>
        ) : (
          <>
            <Menu.Item key="detail" icon={<InfoCircleOutlined />}>
              Chi tiết kết quả
            </Menu.Item>
            {job.is_active === 1 && (
              <Menu.Item key="pause" icon={<PauseCircleOutlined />}>
                Tạm dừng hoạt động
              </Menu.Item>
            )}
            {job.is_active === 2 && (
              <Menu.Item key="start" icon={<RedoOutlined />}>
                Khôi phục hoạt động
              </Menu.Item>
            )}
            <Menu.Item key="edit" icon={<EditOutlined />}>
              Chỉnh sửa
            </Menu.Item>
            {job.subJobs.length === 0 && (
              <Menu.Item key="delete" icon={<DeleteOutlined />}>
                Xóa công việc
              </Menu.Item>
            )}
          </>
        )}
      </Menu>
    );
  };

  return (
    <div className="content-wrapper job-general">
      <BreadcrumbAntStyle>
        <Breadcrumb.Item>Danh sách công việc</Breadcrumb.Item>
      </BreadcrumbAntStyle>

      <Wrapper>
        <Row
          style={{ padding: '5px 10px' }}
          gutter={[16, 16]}
          align="middle"
          justify="space-between"
        >
          <Col
            xxl={{ span: 3, offset: 0 }}
            xl={{ span: 3, offset: 0 }}
            lg={{ span: 3, offset: 0 }}
            sm={{ span: 6, offset: 0 }}
            xs={{ span: 7 }}
          >
            {/* <h5 style={{ color: 'orange' }}>{jobs.paging?.totalItemCount} kết quả</h5> */}
            <h5 style={{ color: '#FE7A15' }}>{paging.total} kết quả</h5>
          </Col>
          <Col
            xxl={{ span: 8, offset: 0 }}
            xl={{ span: 9, offset: 0 }}
            lg={{ span: 10, offset: 0 }}
            sm={{ span: 18, offset: 0 }}
            xs={{ span: 17 }}
          >
            <TypingAutoSearch
              style={{ width: '100%' }}
              onSearchSubmit={searchKey => {
                setParams({ ...params, search: searchKey.trim(), page: 1 });
                mixpanel.track(`filter job search: ${searchKey.trim()} `);
              }}
              isSearchLoading={false}
              placeholder="Tìm kiếm công việc, giao việc, sale chăm sóc"
            />
          </Col>
          <Col
            xxl={{ span: 4, offset: 0 }}
            xl={{ span: 5, offset: 0 }}
            lg={{ span: 5, offset: 0 }}
            sm={{ span: 12, offset: 0 }}
            xs={{ span: 11 }}
          >
            <Select
              placeholder="Trạng thái"
              style={{ width: '100%' }}
              allowClear
              onChange={value => {
                mixpanel.track(`filter job status : ${value}`);
                value
                  ? setParams({ ...params, status: value, page: 1 })
                  : setParams({ ...params, status: '', page: 1 });
              }}
            >
              {JOB_FILTER.map((item, index) => {
                return (
                  <Option key={index} value={item.value}>
                    {item.title}
                  </Option>
                );
              })}
              {/* <>
                <OptGroup label="Trạng thái hoạt động">
                  {JOB_FILTER.map((item, index) => {
                    if (index < 2) {
                      return (
                        <Option key={index} value={item.value}>
                          {item.title}
                        </Option>
                      );
                    }
                  })}
                </OptGroup>
                <OptGroup label="Trạng thái công việc">
                  {JOB_FILTER.map((item, index) => {
                    if (index >= 2) {
                      return (
                        <Option key={index} value={item.value}>
                          {item.title}
                        </Option>
                      );
                    }
                  })}
                </OptGroup>
              </> */}
            </Select>
          </Col>
          <Col
            xxl={{ span: 5, offset: 0 }}
            xl={{ span: 7, offset: 0 }}
            lg={{ span: 6, offset: 0 }}
            sm={{ span: 12, offset: 0 }}
            xs={{ span: 13 }}
          >
            <Button
              style={{ width: '100%' }}
              // type={isActiveButtonDateTime ? 'primary' : 'default'}
              onClick={() => setVisibleModalTime(true)}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CalendarOutlined style={{ marginRight: '10px' }} />
                {date.fromDate && date.toDate
                  ? `${
                      date.fromDate &&
                      moment(date.fromDate).format('DD/MM/YYYY')
                    } -- ${
                      date.toDate && moment(date.toDate).format('DD/MM/YYYY')
                    }`
                  : 'Ngày tạo'}
              </div>
            </Button>
          </Col>
          <Col
            xxl={{ span: 4, offset: 0 }}
            xl={{ span: 5, offset: 19 }}
            lg={{ span: 6, offset: 18 }}
            sm={{ span: 12, offset: 12 }}
            xs={{ span: 24 }}
          >
            <ButtonSystemStyle
              style={{ padding: '5px 10px', width: '100%' }}
              onClick={() => {
                dispatch(clearJobDetail());
                history.push(ROUTER.JOB_ADD);
              }}
            >
              <PlusSquareOutlined style={{ marginRight: '10px' }} />
              Thêm công việc
            </ButtonSystemStyle>
          </Col>
        </Row>
      </Wrapper>

      <Wrapper>
        <TableAntStyle
          loading={isLoadingTable}
          columns={columns}
          dataSource={jobs}
          scroll={{
            x: '992px',
            scrollToFirstRowOnChange: true,
            // y: '573px',
            // y: 'calc(100vh - 300px)',
          }}
          expandedRowKeys={[...rowCurrentSelected]}
          expandable={{
            defaultExpandAllRows: true,
            expandedRowRender: record => (
              <SubJob data={record} getData={getData} />
            ),
            onExpand: (expanded, record) => {
              if (record.countSubJob >= 1) {
                if (rowCurrentSelected.includes(record.id)) {
                  setRowCurrentSelected(
                    rowCurrentSelected.filter(item => record.id !== item)
                  );
                } else {
                  setRowCurrentSelected([...rowCurrentSelected, record.id]);
                }
              }
            },
          }}
          onRow={record => ({
            onClick: event => {
              if (record.countSubJob >= 1) {
                if (rowCurrentSelected.includes(record.id)) {
                  setRowCurrentSelected(
                    rowCurrentSelected.filter(item => record.id !== item)
                  );
                } else {
                  setRowCurrentSelected([...rowCurrentSelected, record.id]);
                }
              }
            },
          })}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu.</span>}
              />
            ),
          }}
          pagination={{
            // size: 'small',
            showSizeChanger: false,
            ...paging,
            onChange: async (page, pageSize) => {
              setParams({ ...params, page });
            },
          }}
        />
      </Wrapper>

      {/* Modal filter date time */}
      <Modal
        title="Khoảng thời gian"
        visible={visibleModalTime}
        onOk={() => {
          setVisibleModalTime(false);
          mixpanel.track(
            `filter job fromDate: ${
              date.fromDate ? moment(date.fromDate).format('YYYY-MM-DD') : ''
            }/ toDate job: ${
              date.toDate ? moment(date.toDate).format('YYYY-MM-DD') : ''
            }`
          );
          setParams({
            ...params,
            fromDate: date.fromDate
              ? moment(date.fromDate).format('YYYY-MM-DD')
              : '',
            toDate: date.toDate ? moment(date.toDate).format('YYYY-MM-DD') : '',
            page: 1,
          });
        }}
        onCancel={() => {
          setDate({
            fromDate: '',
            toDate: '',
          });
          setVisibleModalTime(false);
          setParams({
            ...params,
            fromDate: '',
            toDate: '',
          });
        }}
        okText="Lọc"
        cancelText="Hủy"
      >
        <Row justify="space-around">
          <Col>
            <Button
              size="small"
              onClick={() =>
                setDate({
                  fromDate: moment(new Date()),
                  toDate: moment(new Date()),
                })
              }
            >
              Hôm nay
            </Button>
          </Col>

          <Col>
            <Button
              size="small"
              onClick={() => {
                let d = new Date();
                d.setDate(d.getDate() - 7);
                setDate({
                  fromDate: moment(d),
                  toDate: moment(new Date()),
                });
              }}
            >
              7 ngày
            </Button>
          </Col>

          <Col>
            <Button
              size="small"
              onClick={() => {
                let d = new Date();
                d.setDate(d.getDate() - 30);
                setDate({
                  fromDate: moment(d),
                  toDate: moment(new Date()),
                });
              }}
            >
              30 ngày
            </Button>
          </Col>

          <Col>
            <Button
              size="small"
              onClick={() => {
                let d = new Date();
                d.setDate(d.getDate() - 90);
                setDate({
                  fromDate: moment(d),
                  toDate: moment(new Date()),
                });
              }}
            >
              90 ngày
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Col span={24}>
            <RangePicker
              allowEmpty={[true, true]}
              placeholder={['Từ ngày', 'Đến ngày']}
              style={{ width: '100%' }}
              format={'DD/MM/YYYY'}
              value={[date.fromDate, date.toDate]}
              onChange={(date, dateString) => {
                setDate({
                  fromDate: date ? date[0] : '',
                  toDate: date ? date[1] : '',
                });
              }}
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
