import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Table from "react-bootstrap/Table";
import "@styles/JobGeneral.css";
import { ROUTER, STRING } from "@constants/Constant";
import { Row, Col, FormControl } from "react-bootstrap";

class ReportDetailScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderBody() {
    return (
      <div className="content-wrapper">
        <div className="content-header">
          <Row className="ml-3 mb-5">
            <a
              className="driver-name-block"
              onClick={() => this.props.history.goBack()}
            >
              <i className="header icon-back fas fa-angle-left fa-2x " />
              <span
                className="lead header driver-name ml-2"
                style={{ fontSize: "28px", fontWeight: "600" }}
              >
                Chi tiết báo cáo
              </span>
            </a>
          </Row>
          <Row>{this.renderInfo()}</Row>
          <Row>
            <Col md={12}>{this.renderTable()}</Col>
          </Row>
        </div>
      </div>
    );
  }

  renderInfo() {
    return (
      <>
        <Row className="w-100 mb-4">
          <Col md="3">
            <b>Nhân viên đánh giá</b>
          </Col>
          <Col md="3">
            <span>Dương Văn Chiến</span>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col md="12">
            <b>Giao việc</b>
          </Col>
        </Row>

        <Row className="w-100 mb-2">
          <Col md="3">
            <span>Công việc:</span>
            <span> 50</span>
          </Col>
          <Col md="3">
            <span>Số lượng:</span>
            <span> 50</span>
          </Col>
        </Row>

        <Row className="w-100 mb-4">
          <Col md="2">
            <span>Công việc</span>
          </Col>
          <Col md="2">
            <span>
              <div>Chiến dịch mua xe mới</div>
              <div>Chiến dịch mua phụ tùng</div>
            </span>
          </Col>
          <Col md="2">
            <span>Trạng thái cuộc gọi</span>
          </Col>
          <Col md="2">
            <span>
              <div>Đồng ý trao đổi</div>
              <div>Bận, gọi lại sau</div>
            </span>
          </Col>
          <Col md="2">
            <span>Sale</span>
          </Col>
          <Col md="2">
            <span>
              <div>Dương Văn Chiến</div>
              <div>Ngô Thị Hồng</div>
            </span>
          </Col>
        </Row>

        <Row className="w-100 mb-4">
          <Col md="3">
            <b>Đánh giá</b>
          </Col>
        </Row>
      </>
    );
  }

  renderTable() {
    return (
      <div className="w-100 table-wrap">
        <Table triped bordered>
          <thead>
            <tr>
              <th>STT</th>
              <th>Nhân viên đánh giá</th>
              <th>Công việc</th>
              <th>Sale</th>
              <th>Trạng thái cuộc gọi</th>
              <th>Nhận xét</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Dương Văn Chiến</td>
              <td>Chiến dịch mua xe mới</td>
              <td>Ngô Thị Hồng</td>
              <td>Đồng ý trao đổi</td>
              <td>
                <div>Nói tốt, trôi chảy: 80</div>
                <div>Khai thác nhiều thông tin: 80</div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }

  render() {
    return <>{this.renderBody()}</>;
  }
}

export default withRouter(ReportDetailScreen);
