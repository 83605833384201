import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { ROUTER } from '@constants/Constant';
// import reactotron from 'reactotron-react-js';

// static component
import NavBar from '@components/NavBar';
import PrivateRoute from './PrivateRoute';

// Auth screen

import PageNotFound from '@screens/Auth/PageNotFound';

import SocketComponent from '@components/SocketComponent';
import JsSIPCallClient from '@components/callProviders/JsSIP/JsSIPCallClient';
import JsSIPUtil from '@components/callProviders/JsSIP/JsSIPUtil';
import StringeeCallClient from '@components/callProviders/Stringee/StringeeCallClient';
import StringeeUtil from '@components/callProviders/Stringee/StringeeUtil';
import useCallStatus from '@components/callProviders/useCallStatus';
import { USER_ROLE } from '@constants/Constant';
import ForgetPasswordScreen from '@screens/Auth/ForgetPasswordScreen';
import PageExport from '@screens/Enterprise/ElectronicVoucher/PageExport';
import { BarLoader } from 'react-spinners';
import reactotron from 'src/ReactotronConfig';
import { renderAdminRoute } from './routes/Admin.route';
import { renderEnterpriseRoute } from './routes/Enterprise.route';
import { renderSaleRoute } from './routes/Sale.route';
import { renderReviewerRoute } from './routes/Reviewer.route';
import LazyLoading from 'src/modules/loading/Lazy.loading';
import Header from 'src/layouts/Header';
import IncomingCallPage from '@screens/Call/pages';

const ReleasePage = React.lazy(() => import('@screens/Release'));
const LoginScreen = React.lazy(() => import('@screens/Auth/LoginScreen'));

export const MainNavigator = props => {
  const renderRoute = () => {
    const user = props.user?.data;

    if ([USER_ROLE.ADMIN].includes(user && user.role_id)) {
      return <>{renderAdminRoute()}</>;
    }

    if (
      [
        USER_ROLE.ENTERPRISE,
        USER_ROLE.PROFESSION_MANAGER,
        USER_ROLE.HR_MANAGER,
        USER_ROLE.LEADER,
      ].includes(user && user.role_id)
    ) {
      return <>{renderEnterpriseRoute()}</>;
    }

    if ([USER_ROLE.REVIEWER].includes(user && user.role_id)) {
      return <>{renderReviewerRoute()}</>;
    }

    if ([USER_ROLE.SALE].includes(user && user.role_id)) {
      return <>{renderSaleRoute()}</>;
    }
  };

  // render() {
  // reactotron.log('AppNavigator Render');
  const { call, clients, callId } = useCallStatus();

  return (
    <>
      {/* <Header user={props.user} callClients={clients} /> */}
      <Header user={props.user} callClients={clients} />
      <NavBar user={props.user} />
      {/* <p
        style={{
          height: 100,
          width: '80%',
          marginLeft: 100,
          overflow: 'scroll',
        }}
      >
        <p>{JSON.stringify({ callId })}</p>
        <p>{JSON.stringify({ call })}</p>
        <p>{JSON.stringify({ clients })}</p>
      </p> */}
      <Switch>
        <PrivateRoute
          path="/release"
          exact
          Component={() => (
            <LazyLoading>
              <ReleasePage />
            </LazyLoading>
          )}
        />
        {renderRoute()}
      </Switch>
      <SocketComponent />
    </>
  );
  // }
};

export const AppNavigator = props => {
  reactotron.log('AppNavigator render');
  return (
    <>
      <Router>
        {props.userInfoState.data.user_id && (
          <>
            <StringeeCallClient
              user={props.userInfoState.data}
              ref={ref => {
                StringeeUtil.stringeeCallClient = ref;
              }}
            />
            <JsSIPCallClient
              user={props.userInfoState.data}
              ref={ref => {
                JsSIPUtil.jsSIPCallClient = ref;
              }}
            />
          </>
        )}
        <Switch>
          <Route
            path="/Login"
            exact
            component={() => (
              <LazyLoading>
                <LoginScreen />
              </LazyLoading>
            )}
          />

          <Route
            path={ROUTER.FORGET_PASSWORD}
            exact
            component={ForgetPasswordScreen}
          />

          <Route path="/PageNotFound" exact component={PageNotFound} />
          <Route path="/evoucher" exact component={PageExport} />
          <Route
            path="/incomingCall/:callId"
            exact
            component={IncomingCallPage}
          />
          <PrivateRoute
            path="/"
            Component={MainNavigator}
            user={props.userInfoState}
          />
        </Switch>
      </Router>
    </>
  );
};

const mapStateToProps = state => ({
  userInfoState: state.userReducer,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AppNavigator);
