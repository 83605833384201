/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect } from 'react';
import { Spin, DatePicker, List, Tooltip, Button as ButtonAtnd } from 'antd';
import {
  Row,
  Col,
  FormControl,
  Button,
  ProgressBar,
  Modal,
  Table,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { requestCustomerDetail } from '@constants/Api';
import { notifyFail, notifySuccess } from '@utils/notify';
import {
  requestUpdateCustomer,
  requestGetDynamicCustomerField,
  requestCreateCustomerPhone,
  requestUpdateCustomerPhone,
  requestDestroyCustomerPhone,
} from 'src/network/web_sale/CustomerApi';
import { FIELD_IMPORT } from '@constants/Constant';
import moment from 'moment';
import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import ModalSubPhone from './components/ModalSubPhone';

const Header = ({ onCancel, onSave }) => {
  return (
    <Row
      className="mt-1"
      style={{
        textAlign: 'center',
        padding: '0px 20px',
        borderRadius: '8px',
        marginBottom: 20,
      }}
    >
      <Col md className="bg">
        <Row>
          <Col md={6} sm={12}>
            <h2 className="text-left" style={{ marginBottom: '10px' }}>
              Sửa khách hàng
            </h2>
            <div class="line"></div>
          </Col>
          <Col md={6} sm={12}>
            <div className="d-flex justify-content-end">
              <Button
                variant="danger"
                className="text-light"
                onClick={() => onCancel()}
              >
                Hủy
              </Button>
              <Button
                variant="success"
                className="text-light"
                onClick={() => onSave()}
              >
                Lưu
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const Info = props => {
  const {
    provinces,
    data,
    subInfo,
    subPhoneList,
    onChangeData,
    onChangeSubInfo,
    onEdit,
    onDelete,
    onAddSubPhone,
  } = props;

  return (
    <Row
      className="mt-1"
      style={{
        textAlign: 'center',
        padding: '0px 20px',
        borderRadius: '8px',
        marginBottom: 20,
      }}
    >
      <Col
        md={subInfo.length ? 6 : 12}
        sm={12}
        className="pl-0 pr-0 pb-4 pr-md-2"
      >
        <div className="bg">
          <h4 className="text-left" style={{ marginBottom: '15px' }}>
            Thông tin cơ bản
          </h4>
          <Row className="mb-4">
            <Col sm={12} md={5} className="pl-0 pr-0 text-left">
              <label>Tên khách hàng</label>
            </Col>
            <Col sm={12} md={7}>
              <FormControl
                type="text"
                placeholder="Tên khách hàng"
                value={data.name}
                onChange={e => onChangeData('name', e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={12} md={5} className="pl-0 pr-0 text-left">
              <label>Số điện thoại</label>
            </Col>
            <Col sm={12} md={7} className="text-left">
              <label className="text-info">{data.phone}</label>
              <List
                itemLayout="horizontal"
                size="small"
                dataSource={subPhoneList}
                renderItem={item => (
                  <List.Item
                    actions={[
                      <Tooltip key="edit" title="Sửa">
                        <EditOutlined
                          style={{ cursor: 'pointer' }}
                          onClick={() => onEdit(item)}
                        />
                      </Tooltip>,
                      <Tooltip key="delete" title="Xóa">
                        <DeleteOutlined
                          style={{ cursor: 'pointer' }}
                          onClick={() => onDelete(item.id)}
                        />
                      </Tooltip>,
                    ]}
                    style={{ paddingLeft: 0 }}
                  >
                    <List.Item.Meta
                      description={item.note}
                      title={item.phone}
                    />
                  </List.Item>
                )}
              />
              {subPhoneList.length < 5 ? (
                <ButtonAtnd
                  type="dashed"
                  style={{ width: '100%' }}
                  icon={<PlusCircleOutlined />}
                  onClick={() => onAddSubPhone()}
                >
                  Thêm số phụ
                </ButtonAtnd>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={12} md={5} className="pl-0 pr-0 text-left">
              <label>Email</label>
            </Col>
            <Col sm={12} md={7}>
              <FormControl
                type="text"
                placeholder="Email"
                value={data.email}
                onChange={e => onChangeData('email', e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={12} md={5} className="pl-0 pr-0 text-left">
              <label>Tỉnh, thành phố</label>
            </Col>
            <Col sm={12} md={7}>
              <FormControl
                as="select"
                value={data.province_id}
                onChange={e => onChangeData('province_id', e.target.value)}
              >
                <option value="">Chọn tỉnh, thành phố</option>
                {provinces.length ? (
                  provinces.map(item => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })
                ) : (
                  <></>
                )}
              </FormControl>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={12} md={5} className="pl-0 pr-0 text-left">
              <label>Ngày sinh</label>
            </Col>
            <Col sm={12} md={7}>
              <DatePicker
                style={{ width: '100%' }}
                placeholder="Chọn ngày sinh"
                format="DD/MM/YYYY"
                size="middle"
                value={data.dob ? moment.utc(data.dob) : null}
                disabledDate={current => {
                  return current && current >= moment().endOf('day');
                }}
                onChange={e => {
                  onChangeData('dob', moment(e).toISOString());
                }}
              />
            </Col>
          </Row>
        </div>
      </Col>
      {subInfo.length ? (
        <Col md={6} sm={12} className="pl-0 pr-0 pb-4 pl-md-2">
          <div className="bg">
            <h4 className="text-left" style={{ marginBottom: '15px' }}>
              Thông tin thêm
            </h4>
            {subInfo.map(item => {
              return (
                <Row className="mb-4" key={item.id}>
                  <Col sm={12} md={5} className="pl-0 pr-0 text-left">
                    <label>{item.name || 'Dữ liệu mới'}</label>
                  </Col>
                  <Col sm={12} md={7}>
                    {item.data_type === FIELD_IMPORT.TEXT ||
                    item.data_type === FIELD_IMPORT.NUMBER ? (
                      <FormControl
                        type="text"
                        placeholder={`Nhập ${item.name.toLowerCase()}`}
                        value={item.value}
                        onChange={e => onChangeSubInfo(item.id, e.target.value)}
                      />
                    ) : item.data_type === FIELD_IMPORT.OPTIONS ? (
                      <FormControl
                        as="select"
                        value={item.value}
                        onChange={e => onChangeSubInfo(item.id, e.target.value)}
                      >
                        <option value="">Chọn {item.name.toLowerCase()}</option>
                        {item.customer_data_field_sources.length ? (
                          item.customer_data_field_sources.map(choice => {
                            return (
                              <option key={choice.id} value={choice.value}>
                                {choice.value}
                              </option>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </FormControl>
                    ) : (
                      <DatePicker
                        style={{
                          width: '100%',
                          borderRadius: '0.25rem',
                          height: 33.5,
                        }}
                        format="DD/MM/YYYY"
                        placeholder="Chọn ngày"
                        value={item.value ? item.value : null}
                        onChange={(date, dateSring) =>
                          onChangeSubInfo(item.id, date)
                        }
                      />
                    )}
                  </Col>
                </Row>
              );
            })}
          </div>
        </Col>
      ) : (
        <></>
      )}
    </Row>
  );
};

function UpdateCustomer() {
  const { id } = useParams();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    name: '',
    email: '',
    phone: '',
    province_id: '',
    dob: null,
  });
  const [subPhoneList, setSubPhoneList] = useState([]);
  const [subInfo, setSubInfo] = useState([]);

  const [modalSubPhoneVisible, setModalSubPhoneVisible] = useState(false);
  const [subPhone, setSubPhone] = useState({
    phone: '',
    note: '',
  });

  const provinceList = useSelector(state => state.ProvinceReducer.data);

  useEffect(() => {
    getCustomerDetail();
  }, []);

  const getCustomerDetail = async () => {
    try {
      setIsLoading(true);
      const { data } = await requestCustomerDetail(id);
      const { main } = data;
      const fields = await requestGetDynamicCustomerField();

      // fiter by data_type === 'ref'
      const newArr =
        fields.data
          .filter(item => item.data_type !== 'ref')
          .map(item => {
            const mappingValue =
              main.customer_data.find(
                field => field.customer_data_field_code === item.code
              )?.value || '';

            return {
              ...item,
              value:
                item.data_type === FIELD_IMPORT.DATE_TIME
                  ? mappingValue
                    ? moment(mappingValue, 'DD/MM/YYYY')
                    : ''
                  : mappingValue,
            };
          }) || [];

      setSubInfo(newArr || []);

      console.log(newArr);

      setData({
        name: main.name,
        email: main.email || '',
        phone: main.phone,
        province_id: main.province_id || '',
        dob: main.dob || '',
      });
      setSubPhoneList(main.customer_phones || []);
      setIsLoading(false);
    } catch (error) {}
  };

  // Header funcs
  const handleUpdateCustomer = async () => {
    try {
      let newData = { ...data };

      // if (!newData.name) {
      //   notifyFail('Vui lòng nhập tên khách hàng!');
      //   return;
      // }

      let subInfoObj = {};

      if (subInfo.length) {
        subInfoObj = subInfo.reduce((obj, item) => {
          return Object.assign(obj, {
            [item.code]:
              item.data_type === FIELD_IMPORT.DATE_TIME
                ? item.value
                  ? item.value.format('DD/MM/YYYY')
                  : ''
                : item.value
                ? item.value.toString()
                : '',
          });
        }, {});
      }

      newData.id = parseInt(id);
      newData.province_id = parseInt(newData.province_id) || '';
      if (newData.dob === '') {
        newData.dob = null;
      } else {
        newData.dob = new Date(newData.dob).getTime();
      }

      delete newData.phone;

      const res = await requestUpdateCustomer(
        Object.assign(newData, subInfoObj)
      );
      notifySuccess('Cập nhật thông tin thành công!');
      getCustomerDetail();
    } catch (error) {
      console.log(error);
      notifyFail(error.msg);
    }
  };

  const handleCancelUpdate = () => {
    history.goBack();
  };

  // Info funcs
  const handleChangeData = (fieldName, value) => {
    setData({
      ...data,
      [fieldName]: fieldName === 'province_id' ? parseInt(value) || '' : value,
    });
  };

  const handleChangeSubInfo = (id, value) => {
    const newArr = [...subInfo];
    const result = newArr.map(item => {
      if (item.id === id) {
        return {
          ...item,
          value: value,
        };
      } else {
        return item;
      }
    });
    setSubInfo(result);
  };

  const handleDeletePhone = async phoneId => {
    try {
      const res = await requestDestroyCustomerPhone({
        id: phoneId,
        customer_id: id,
      });
      notifySuccess('Xóa thành công!');
      getCustomerDetail();
    } catch (error) {}
  };

  const handleEditPhone = item => {
    setSubPhone({ ...item });
    setModalSubPhoneVisible(true);
  };

  const handleAddSubPhone = () => {
    setSubPhone({
      phone: '',
      note: '',
    });
    setModalSubPhoneVisible(true);
  };

  // ModalSubPhone funcs
  const handleSaveSubPhone = async () => {
    try {
      if (!subPhone.phone.trim()) {
        notifyFail('Vui lòng nhập số điện thoại');
        return;
      }

      if (!subPhone.id) {
        const res = await requestCreateCustomerPhone({
          phone: subPhone.phone.trim(),
          note: subPhone.note.trim(),
          customer_id: id,
        });
        notifySuccess('Thêm thành công!');
      } else {
        const res = await requestUpdateCustomerPhone({
          phone: subPhone.phone.trim(),
          note: subPhone.note.trim(),
          customer_id: id,
          id: subPhone.id,
        });
        notifySuccess('Cập nhật thành công!');
      }
      setModalSubPhoneVisible(false);
      getCustomerDetail();
    } catch (error) {
      notifyFail(error.msg);
    }
  };

  const handleChangeSubPhone = (fieldName, value) => {
    setSubPhone({
      ...subPhone,
      [fieldName]: value,
    });
  };

  return (
    <Spin spinning={isLoading}>
      <div className="content-wrapper">
        <div className="content-header">
          <ModalSubPhone
            visible={modalSubPhoneVisible}
            onClose={() => setModalSubPhoneVisible(false)}
            onSave={handleSaveSubPhone}
            data={subPhone}
            onChangeData={handleChangeSubPhone}
          />
          <Header onCancel={handleCancelUpdate} onSave={handleUpdateCustomer} />
          <Info
            provinces={provinceList}
            data={data}
            onChangeData={handleChangeData}
            subInfo={subInfo}
            onChangeSubInfo={handleChangeSubInfo}
            subPhoneList={subPhoneList}
            onDelete={id => handleDeletePhone(id)}
            onEdit={item => handleEditPhone(item)}
            onAddSubPhone={() => handleAddSubPhone()}
          />
        </div>
      </div>
    </Spin>
  );
}

export default UpdateCustomer;
