import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Pagination from '@components/Pagination';
import {
  requestAssignSale,
  requestAssignTelemarketingWithRatio,
} from '@constants/Api';
import { ROUTER } from '@constants/Constant';
import {
  clearJobDetail,
  getJobDetail,
  getTelesaleList,
} from '@src/redux/actions';
import '@styles/RegisterRequest.css';
import { notifyFail, notifySuccess } from '@utils/notify';
import {
  Checkbox,
  Col as ColAntd,
  Empty,
  Radio,
  Row as RowAntd,
  Table,
} from 'antd';
import React, { Component } from 'react';
import { Button, Col, FormControl, Modal, Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import reactotron from 'reactotron-react-js';
import { ButtonSystemStyle, TableAntStyle } from '@styles/Style';
import { VietnameseString } from '@constants/VietnameseString';
import {
  ASSIGN_CUSTOMER_PRIORITY_ENABLED,
  DIVIDE_TYPE,
  TIME_LOOP,
} from '../../Constants';
import { handleAutomaticCustomerDivision } from '../../funcHelper';
import InputSearch from '../../Edit/Tabs/InputSearch';

class Assign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      modal: false,
      data: null,
      telesaleInfo: {},
      tableLoading: false,
      tableColumns: [
        // {
        //   title: 'Họ tên',
        //   dataIndex: 'name',
        // },
        // {
        //   title: 'KPI trung bình',
        //   dataIndex: 'kpi',
        // },
        // {
        //   title: 'Cuộc gọi còn lại',
        //   dataIndex: 'remain',
        // },
        // {
        //   title: 'Thành công',
        //   dataIndex: 'success',
        // },
        // {
        //   title: 'Số cuộc gọi trung bình trên ngày',
        //   dataIndex: 'average',
        // },
        // {
        //   title: 'Điểm kinh nghiệm',
        //   dataIndex: 'reward_point',
        // },

        {
          title: 'Họ tên',
          dataIndex: 'name',
          render: (text, record, index) => {
            return (
              <div onClick={() => this.handleModalOpen(record)}>{text}</div>
            );
          },
        },
        {
          title: 'Số cuộc gọi trung bình trên ngày',
          dataIndex: 'average',
        },
        {
          title: 'Điểm kinh nghiệm',
          dataIndex: 'reward_point',
        },
        {
          title: 'KPI trung bình',
          dataIndex: 'kpi',
        },
        {
          title: 'Thành công',
          dataIndex: 'success',
        },
        {
          title: 'Cuộc gọi còn lại',
          dataIndex: 'remain',
        },
      ],
      tableData: [],
      job_telesales: [],
      isRequireAccept: true,
      divide_type: DIVIDE_TYPE.QUANTITY,
      ratio_code: TIME_LOOP.DATE,
      searchSale: '',
      assign_customer_prioritize_enable: ASSIGN_CUSTOMER_PRIORITY_ENABLED.NONE,
    };
  }

  componentDidMount() {
    this.props.getTelesaleList({
      page: 1,
      job_id: this.props.match.params?.id,
    });
    // this.props.getJobDetail({ id: this.props.match.params?.id });
  }

  componentWillReceiveProps(nextProp) {
    if (nextProp.telesaleState?.data?.length) {
      this.setTable(nextProp.telesaleState.data);
    }
    if (nextProp.telesaleState?.isLoading) {
      this.setState({ tableLoading: true });
    } else {
      this.setState({ tableLoading: false });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.searchSale !== prevState.searchSale) {
      setTimeout(() => {
        this.props.getTelesaleList({
          page: 1,
          job_id: this.props.match.params?.id,
          search: this.state.searchSale,
        });
      }, 500);
    }
  }

  setTable(telesaleList) {
    if (telesaleList.length > 0) {
      var rows = [];
      telesaleList.forEach(sale => {
        rows.push({
          key: sale.id,
          code: sale?.code,
          name: sale.full_name,
          kpi: Math.ceil(sale.kpi_average),
          remain: sale.remain_call,
          success: Math.ceil(sale.opportunity_percent),
          average: Math.ceil(sale.success_call),
          reward_point: sale.reward_point,
          data: sale,
        });
      });
      this.setState({ tableData: rows });
    }
  }

  renderHeader() {
    return (
      <Row className="py-3">
        <Col sm={12} className="px-0">
          <h4>Giao việc</h4>
        </Col>
      </Row>
    );
  }

  renderLabel() {
    return (
      <>
        <Row>
          <Col sm={12} className="px-0">
            <div className="alert alert-secondary">
              <Row className="">
                <Col className="text-center text-md-left">
                  <span>Số khách hàng cần chăm sóc: </span>
                  <b className="text-red">
                    {this.props.jobState?.data?.totalCustomer}
                  </b>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <div
          style={{
            margin: '10px 0px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Checkbox
              disabled={this.state.divide_type === DIVIDE_TYPE.RATIO ||this.state.divide_type === DIVIDE_TYPE.AUTO_DIVIDE || this.state.divide_type === DIVIDE_TYPE.QUANTITY}
              checked={
               (this.state.divide_type === DIVIDE_TYPE.RATIO  ||this.state.divide_type === DIVIDE_TYPE.AUTO_DIVIDE || this.state.divide_type === DIVIDE_TYPE.QUANTITY ) 
                  ? true
                  : this.state.isRequireAccept
              }
              onChange={event => {
                this.setState({
                  ...this.state,
                  isRequireAccept: event.target.checked,
                });
              }}
            >
              {VietnameseString.assign_work_without_sales_confirmation}
            </Checkbox>

            {this.state.divide_type === DIVIDE_TYPE.RATIO ? (
              <Checkbox
                style={{ margin: 0 }}
                checked={this.state.assign_customer_prioritize_enable}
                onChange={event => {
                  this.setState({
                    assign_customer_prioritize_enable: Number(
                      event.target.checked
                    ),
                  });
                }}
              >
                Ưu tiên sale đã từng chăm sóc
              </Checkbox>
            ) : (
              <></>
            )}
          </div>

          <Radio.Group
          
            name='radio-group-job'
            onChange={event => {
              this.handleTableCheck([]);
              this.setState({
                divide_type: event.target.value,
              });
            }}
            value={this.state.divide_type}
          >
            <Radio value={DIVIDE_TYPE.QUANTITY}>
              {VietnameseString.divide_by_number_of_customers}
            </Radio>
            <Radio value={DIVIDE_TYPE.RATIO}>
              {VietnameseString.proportional_division}
            </Radio>

            <Radio value={DIVIDE_TYPE.AUTO_DIVIDE}>
              Giao việc tự động
            </Radio>
          </Radio.Group>


        </div>
      </>
    );
  }

  handlePageChange = page => {
    const { id } = this.props.match.params;
    this.props.getTelesaleList({
      page: page,
      job_id: id,
    });
  };

  renderTable() {
    reactotron.log(this.props.jobState?.data?.totalCustomer);
    return (
      <>
        <Row className="my-3 mb-2">
          <Col className="d-flex justify-content-between">
            <span>Chọn sale</span>
            <i
              className="far fa-sync-alt add-btn text-green"
              onClick={() =>
                this.props.getTelesaleList({
                  page: 1,
                  job_id: this.props.match.params?.id,
                })
              }
            ></i>
          </Col>
        </Row>
        <Row className="table-wrap">
          <TableAntStyle
            loading={this.state.tableLoading}
            rowSelection={{
              type: 'checkbox',
              onChange: selectedRowKeys =>
                this.handleTableCheck(selectedRowKeys),
              selectedRowKeys: this.state.selectedSale,
              preserveSelectedRowKeys: true,
              // getCheckboxProps: (record) => ({
              //   disabled:
              //     this.props.jobState?.data?.totalCustomer == undefined ||
              //     this.props.jobState?.data?.totalCustomer === 0,
              // }),
            }}
            columns={this.state.tableColumns}
            dataSource={this.state.tableData}
            pagination={false}
            // onRow={(record, rowIndex) => {
            //   return { onClick: () => this.handleModalOpen(record) };
            // }}
            className="w-100"
            // footer={() => (
            //   <Row className=" justify-content-md-end justify-content-center">
            //     <Pagination screen="telesaleState" handlePageChange={this.handlePageChange} />
            //   </Row>
            // )}
          />
        </Row>
      </>
    );
  }

  handleTableCheck(selectedRowKeys) {
    const saleList = this.props.telesaleState?.data;
    const { divide_type } = this.state;
    var arr = [],
      convertedArr = [...this.state.job_telesales];
    convertedArr = convertedArr.filter(sale =>
      selectedRowKeys.includes(sale.telesale_id)
    );
    // convertedArr = convertedArr.map(value => value[]);
    // convert
    var cusNum = this.props.jobState?.data?.totalCustomer || 0;

    if (saleList?.length) {
      arr = saleList.filter(sale => selectedRowKeys.includes(sale.id));
      arr.forEach(sale => {
        var index = convertedArr.findIndex(ele => ele.telesale_id === sale.id);

        if (index < 0) {
          if (divide_type === DIVIDE_TYPE.QUANTITY) {
            convertedArr.push({
              telesale_id: sale.id,
              full_name: sale.full_name,
              amount: 0,
              is_default: 0,
            });
          } else {
            convertedArr.push({
              telesale_id: sale.id,
              full_name: sale.full_name,
              config_customer: 0,
              remaining_customer: 0,
              total_customer: 0,
              is_default: 0,
            });
          }
        }
      });
    }
    // lọc thằng bị xóa khỏi list
    convertedArr = convertedArr.filter(sale =>
      selectedRowKeys?.includes(sale.telesale_id)
    );

    // tự động chia việc
    if (divide_type === DIVIDE_TYPE.QUANTITY) {
      handleAutomaticCustomerDivision(cusNum, convertedArr, 'amount');
    } else {
      handleAutomaticCustomerDivision(cusNum, convertedArr, 'config_customer');
    }

    this.setState({
      selectedSale: [...selectedRowKeys],
      job_telesales: [...convertedArr],
    });
  }

  async submit() {
    const jobId = this.props.jobState.data?.job_id;

    const {
      divide_type,
      job_telesales,
      isRequireAccept,
      ratio_code,
      assign_customer_prioritize_enable,
    } = this.state;

    try {
      this.setState({ isLoading: true });
      if (!this.validateInfo()) {
        return;
      }

      if (divide_type === DIVIDE_TYPE.QUANTITY) {
        await requestAssignSale({
          job_telesales: [...job_telesales],
          job_id: jobId,
          is_require_accept: Number(isRequireAccept),
        });    

      } 
      else if(divide_type === DIVIDE_TYPE.AUTO_DIVIDE) {
        await requestAssignSale({
          job_telesales: [...job_telesales],
          job_id: jobId,
          is_require_accept: Number(isRequireAccept),
          assign_customer_type: 1
        });    
      }
      
      else {
        const job_telesale_ratio = job_telesales.map(i => ({
          telesale_id: i.telesale_id,
          config_customer: i.config_customer,
          remaining_customer: i.config_customer,
          total_customer: 0,
        }));
        await requestAssignTelemarketingWithRatio({
          job_telesale_ratio,
          job_id: jobId,
          is_require_accept: Number(isRequireAccept),
          code: ratio_code,
          time_repeat: new Date().getTime(),
          assign_customer_prioritize_enable,
        });
      }

      this.props.history.push(ROUTER.JOB);
      notifySuccess('Giao việc thành công');
      this.props.clearJobDetail();
    } catch (err) {
      // this.props.setLoading(false);
      notifyFail(err.msg);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  validateInfo() {
    const { job_telesales, divide_type } = this.state;

    var cusNum = this.props.jobState?.data?.totalCustomer || 0;
    var jobNum = job_telesales.reduce(
      (total = 0, job) => total + parseInt(job.amount),
      0
    );
    var fixedSale = job_telesales.filter(sale => sale.is_default);
    var saleHasNoJob = job_telesales.filter(
      sale => parseInt(sale.amount) < 1 || !sale.amount
    );

    if (!this.props?.jobState.data?.job_id) {
      notifyFail('Vui lòng nhập thông tin chung');
      return false;
    }

    // buộc phải có telesale thực hiện công việc
    if (job_telesales.length < 1) {
      notifyFail('Cần tối thiểu 1 sale thực hiện công việc này!');
      return false;
    }

    if (divide_type === DIVIDE_TYPE.QUANTITY) {
      // kiểm tra xem việc đã được giao hết cho telesale chưa,
      if (jobNum < cusNum) {
        notifyFail('Giao chưa hết số lượng công việc!');
        return false;
      }
      if (jobNum > cusNum) {
        notifyFail(
          'Số lượng công việc đã giao vượt quá số lượng khách cần phục vụ!'
        );
        return false;
      }
    }

    // telesale được chọn nào cũng phải được giao job
    // if (saleHasNoJob.length > 0) {
    //   notifyFail('Sale được chọn cần có số lượng công việc lớn hơn 0!');
    //   return false;
    // }

    // buộc phải có 1 telesale cố định
    // if (fixedSale.length < 1) {
    //   notifyFail('Cần có tối thiểu 1 telesale cố định cho công việc này!');
    //   return false;
    // }

    return true;
  }

  removeSelected(id) {
    var newArr = [...this.state.selectedSale];
    var index = newArr.indexOf(id);
    var newjobTelesale = this.state.job_telesales.filter(
      sale => sale.telesale_id !== id
    );

    newArr.splice(index, 1);
    this.setState({
      selectedSale: newArr,
      job_telesales: [...newjobTelesale],
    });
  }

  handleSelectedRowChange(field, value, saleId) {
    var newArr = [...this.state.job_telesales];
    var saleIndex = newArr.findIndex(element => element.telesale_id === saleId);
    // xóa is_defautl của tất cả các telesale đc chọn
    newArr.forEach((element, index) => {
      newArr.splice(index, 1, { ...element, is_default: 0 });
    });
    // thêm is_default cho telesale vừa đc đánh dấu
    newArr.splice(saleIndex, 1, {
      ...newArr[saleIndex],
      [field]: parseInt(value),
    });
    this.setState({ job_telesales: [...newArr] });
  }

  renderSelectedRow() {
    const saleList = this.props.telesaleState.data;
    const { selectedSale, job_telesales } = this.state;
    if (selectedSale?.length > 0) {
      return (
        // <>
        //   {/* {saleList?.length && saleList.filter(sale => selectedSale?.includes(sale.id)).map((sale => { */}
        //   {this.state.job_telesales.map((sale) => {
        //     return (
        //       <>
        //         <Row className="align-items-center mt-2 align-items-center">
        //           <Col md={3} className="px-0">
        //             <span>{sale.full_name}</span>
        //           </Col>
        //           <Col md={2} className="px-0">
        //             <FormControl
        //               type="number"
        //               className="d-inline-block mx-xs-0 mx-md-3 px-0 px-md-2"
        //               placeholder="Nhập lượng công việc sẽ giao"
        //               value={sale.amount}
        //               onChange={(event) => this.handleSelectedRowChange('amount', event.target.value, sale.telesale_id)}
        //             ></FormControl>
        //           </Col>
        //           {/* <Col md={2} xs={6} className="d-flex justify-content-center align-items-baseline offset-md-1 px-0">
        //             <Checkbox
        //               checked={sale?.is_default}
        //               onChange={(event) =>
        //                 this.handleSelectedRowChange('is_default', event.target.checked ? 1 : 0, sale.telesale_id)
        //               }
        //             />
        //             <b className="text-blue pl-2 d-inline-block">Cố định</b>
        //           </Col> */}
        //           <Col md={2} xs={6} className="">
        //             <i
        //               className="fas fa-times add-btn text-red"
        //               onClick={() => this.removeSelected(sale.telesale_id)}
        //             ></i>
        //           </Col>
        //         </Row>
        //       </>
        //     );
        //   })}
        // </>

        <RowAntd gutter={[16, 16]}>
          {job_telesales.map(sale => {
            return (
              <ColAntd xl={8} sm={12}>
                <Row className="align-items-center mt-2 align-items-center">
                  <Col xl={4} sm={4} className="px-0">
                    <span>{sale.full_name}</span>
                  </Col>
                 {this.state.divide_type !== DIVIDE_TYPE.AUTO_DIVIDE &&   <Col xl={4} sm={6} className="px-0">
                    <FormControl
                      type="number"
                      className="d-inline-block mx-xs-0 mx-md-3 px-0 px-md-2"
                      placeholder={
                        this.state.divide_type === DIVIDE_TYPE.QUANTITY
                          ? 'Nhập lượng công việc sẽ giao'
                          : 'Nhập tỉ lệ'
                      }
                      value={
                        this.state.divide_type === DIVIDE_TYPE.QUANTITY
                          ? sale.amount
                          : sale.config_customer
                      }
                      onChange={event => {
                        if (this.state.divide_type === DIVIDE_TYPE.QUANTITY) {
                          this.handleSelectedRowChange(
                            'amount',
                            event.target.value,
                            sale.telesale_id
                          );
                        } else if(this.state.divide_type === DIVIDE_TYPE.RATIO) {
                          this.handleSelectedRowChange(
                            'config_customer',
                            event.target.value,
                            sale.telesale_id
                          );
                        }
                      }}
                    ></FormControl>
                  </Col>}
                  <Col xl={3} sm={1} className="ml-1">
                    <i
                      className="fas fa-times add-btn text-red"
                      onClick={() => this.removeSelected(sale.telesale_id)}
                    ></i>
                  </Col>
                </Row>
              </ColAntd>
            );
          })}
        </RowAntd>
      );
    } else {
      return <Empty description={<span>Không có sale nào được chọn</span>} />;
    }
  }

  renderSelected() {
    return (
      <>
        <Row className="mt-3">
          <Col>
            <b>Danh sách sale đã chọn</b>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="px-0">
            {this.renderSelectedRow()}
          </Col>
        </Row>
      </>
    );
  }

  renderModal() {
    const { modal } = this.state;
    const sale = this.state.telesaleInfo;
    return (
      <>
        <Modal show={modal} onHide={() => this.handleModalClose()}>
          <Modal.Header closeButton className="text-white btn-info">
            <Modal.Title>Thông tin sale</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-4 mx-5">
              <Col md={6}>
                <span>Họ và tên</span>
              </Col>
              <Col md={6}>
                <span>{sale && sale.full_name}</span>
              </Col>
            </Row>
            <Row className="mb-4 mx-5">
              <Col md={6}>
                <span>Số điện thoại</span>
              </Col>
              <Col md={6}>
                <span>{sale && sale.phone}</span>
              </Col>
            </Row>
            <Row className="mb-4 mx-5">
              <Col md={6}>
                <span>Email</span>
              </Col>
              <Col md={6}>
                <span>{sale && sale.email}</span>
              </Col>
            </Row>
            <Row className="mb-4 mx-5">
              <Col md={6}>
                <span>KPI</span>
              </Col>
              <Col md={6}>
                <b className="text-blue">
                  {sale && Math.ceil(sale?.kpi_average)} cuộc/ngày
                </b>
              </Col>
            </Row>
            <Row className="mb-4 mx-5">
              <Col md={6}>
                <span>Số cuộc gọi trung bình</span>
              </Col>
              <Col md={6}>
                <b className="text-blue">
                  {sale && Math.ceil(sale?.success_call)} cuộc/ngày
                </b>
              </Col>
            </Row>
            <Row className="mb-4 mx-5">
              <Col md={6}>
                <span>Số cuộc gọi còn lại</span>
              </Col>
              <Col md={6}>
                <b className="text-blue">{sale && sale?.remain_call} cuộc</b>
              </Col>
            </Row>
            <Row className="mb-4 mx-5">
              <Col md={6}>
                <span>Tỷ lệ thành công</span>
              </Col>
              <Col md={6}>
                <b className="text-blue">
                  {sale && Math.ceil(sale?.opportunity_percent)} %
                </b>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button
              variant="danger "
              className="text-light"
              onClick={() => this.handleModalClose()}
            >
              Đóng
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  handleModalClose() {
    this.setState({
      modal: false,
    });
  }

  handleModalOpen(record) {
    this.setState({
      ...this.state,
      modal: true,
      telesaleInfo: record.data,
    });
  }

  renderTimeLoop() {
    return (
      <>
        <Row className="mt-3 mt-2">
          <Col>
            <b>Chia khách hàng theo thời gian:</b>
          </Col>
        </Row>
        <Row>
          <Col>
            <Radio.Group
              onChange={event => {
                this.setState({ ratio_code: event.target.value });
              }}
              value={this.state.ratio_code}
            >
              <Radio value={TIME_LOOP.DATE}>{VietnameseString.date}</Radio>
              <Radio value={TIME_LOOP.WEEK}>{VietnameseString.week}</Radio>
              <Radio value={TIME_LOOP.MONTH}>{VietnameseString.month}</Radio>
            </Radio.Group>
          </Col>
        </Row>
      </>
    );
  }

  renderButton() {
    return (
      <Row className="mt-3 mb-5">
        <Col xs={12} className="text-center text-md-right px-0">
          <Button
            variant="primary"
            className="text-light"
            onClick={() => this.submit()}
          >
            Kết thúc
          </Button>
        </Col>
      </Row>
    );
  }

  renderFooter() {
    return (
      <>
        <RowAntd
          style={{ width: '100%', marginBottom: '15px' }}
          align="middle"
          justify="space-between"
        >
          <ColAntd>
            <ButtonSystemStyle
              onClick={() => {
                this.props.getJobDetail({
                  id: this.props.jobState?.data?.job_id,
                });
                this.props.handleChangeStep(1);
              }}
            >
              Quay lại
            </ButtonSystemStyle>
          </ColAntd>
          <ColAntd>
            <ButtonSystemStyle onClick={() => this.submit()}>
              Hoàn thành
            </ButtonSystemStyle>
          </ColAntd>
        </RowAntd>
      </>
    );
  }

  onSearchSale = event => {
    this.setState({
      searchSale: event.target.value?.trim(),
    });
  };
  render() {
    // reactotron.log(list);
    return (
      <>
        {this.renderHeader()}
        {this.renderLabel()}
        <InputSearch
          onSearch={this.onSearchSale}
          lengthResult={this.state.tableData.length}
        />
        {this.renderTable()}
        {this.renderSelected()}
        {this.state.divide_type === DIVIDE_TYPE.RATIO && this.renderTimeLoop()}
        {this.renderModal()}
        {/* {this.renderButton()} */}
        {this.renderFooter()}
      </>
    );
  }
}

const mapStateToProps = state => ({
  telesaleState: state.TelesaleReducer,
  jobState: state.jobDetailReducer,
});

const mapDispatchToProps = {
  getTelesaleList,
  getJobDetail,
  clearJobDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Assign));
