import React from 'react';
import { Descriptions, Modal, Spin } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { VietnameseString } from '@constants/VietnameseString';
import { formatPrice } from '@constants/funcHelper';

import { TableAntStyle } from '@styles/Style';
import { voucherService } from '../../service';

const DescriptionsStyled = styled(Descriptions)`
  .description-table div span {
    display: inline-block;
    width: 100%;
  }
`;

const dataSource = [
  {
    key: '1',
    name: 'Mike',
    age: 32,
    address: '10 Downing Street',
  },
  {
    key: '2',
    name: 'John',
    age: 42,
    address: '10 Downing Street',
  },
];

const columns = [
  {
    title: VietnameseString.product,
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: VietnameseString.quantity,
    dataIndex: 'age',
    key: 'age',
  },
];

function VoucherDetail(props) {
  const { visible, id, onCancel } = props;
  const [voucher, setVoucher] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  // React.useEffect(() => {
  //   if (!id) return;
  //   setLoading(true);
  //   voucherService
  //     .detailVoucher(id)
  //     .then((res) => {
  //       console.log('🚀 ~ file: VoucherDetail.jsx:55 ~ voucherService.detailVoucher ~ res', res);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, [id]);

  return (
    <Modal title={VietnameseString.voucher_code_details} footer={null} visible={visible} onCancel={onCancel}>
      <Spin spinning={loading}>
        <DescriptionsStyled column={1} title="">
          <Descriptions.Item label={VietnameseString.voucher_code}>{formatPrice(2000000)} VNĐ</Descriptions.Item>
          <Descriptions.Item label={VietnameseString.status}>{formatPrice(200000)} VNĐ</Descriptions.Item>
          <Descriptions.Item label={VietnameseString.activate_date}>{formatPrice(100000)} điểm</Descriptions.Item>
          <Descriptions.Item label={VietnameseString.customer_name}>{formatPrice(100000)} điểm</Descriptions.Item>

          {/* Danh sách sản phẩm */}
          <Descriptions.Item className="description-table" label="">
            <TableAntStyle dataSource={dataSource} columns={columns} pagination={false} />
          </Descriptions.Item>

          <Descriptions.Item label="Thành tiền trước giảm giá">{formatPrice(100000)} điểm</Descriptions.Item>
          <Descriptions.Item label="Thành tiền sau giảm giá">{formatPrice(100000)} điểm</Descriptions.Item>
        </DescriptionsStyled>
      </Spin>
    </Modal>
  );
}

VoucherDetail.propTypes = {
  visible: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default VoucherDetail;
