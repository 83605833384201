import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Row, Col, DatePicker, Space } from 'antd';
import {
  requestGetHomeListCallStatusChart,
  requestGetHomeNewCustomerChart,
  requestGetHomeRevenueChart,
  requestGetHomeOpportunityChart,
} from '@constants/Api';
import moment from 'moment';
import './style.css';
import { notifyFail } from '@utils/notify';
import Loading from '@components/Loading';
import mixpanel from 'mixpanel-browser';

const HomeChart = ({ tilte, children }) => {
  return (
    <div className="mt-3 p-4" style={{ backgroundColor: '#fff', borderRadius: 'var(--section-radius)' }}>
      <p style={{ fontWeight: 'bold', fontSize: '18px', display: 'inline-block', borderBottom: '4px solid #006400' }}>
        {tilte}
      </p>
      {children}
    </div>
  );
};

// I have no time. Sorry !
const OpportunityAmount = ({ tilte, amount, ...props }) => {
  return (
    <Col xs={24} xxl={12} className="pb-3">
      <Row justify="space-between" className="mr-0 mx-xl-2 p-2 p-sm-3 p-md-2" style={props.style}>
        <Col className="pl-3">
          <span style={{ fontSize: '15px', fontWeight: '600' }}>
            <i className="fas fa-home" style={{ color: 'gray' }} /> {tilte}
          </span>
        </Col>
        <Col className="pr-3">
          <span style={{ fontSize: '15px', fontWeight: '600' }}>{amount}</span>
        </Col>
      </Row>
    </Col>
  );
};

const OpportunityFail = ({ tilte, amount, ...props }) => {
  return (
    <Col xs={24} xxl={12} className="pb-3">
      <Row justify="space-between" className="ml-0 mx-xl-2 p-2 p-sm-3 p-md-2" style={props.style}>
        <Col className="pl-3">
          <span style={{ fontSize: '15px', fontWeight: '600' }}>
            <i className="far fa-times-circle" style={{ color: 'rgb(255, 0, 0)' }} /> {tilte}
          </span>
        </Col>
        <Col className="pr-3">
          <span style={{ fontSize: '15px', fontWeight: '600' }}>{amount}</span>
        </Col>
      </Row>
    </Col>
  );
};

const OpportunityProcessing = ({ tilte, amount, ...props }) => {
  return (
    <Col xs={24} xxl={12} className="pb-3">
      <Row justify="space-between" className="mr-0 mx-xl-2 p-2 p-sm-3 p-md-2" style={props.style}>
        <Col className="pl-3">
          <span style={{ fontSize: '15px', fontWeight: '600' }}>
            <i class="fas fa-hourglass-half" style={{ color: 'rgb(255, 191, 0)' }} /> {tilte}
          </span>
        </Col>
        <Col className="pr-3">
          <span style={{ fontSize: '15px', fontWeight: '600' }}>{amount}</span>
        </Col>
      </Row>
    </Col>
  );
};

const OpportunityCompleted = ({ tilte, amount, ...props }) => {
  return (
    <Col xs={24} xxl={12}>
      <Row justify="space-between" className="ml-0 mx-xl-2 p-2 p-sm-3 p-md-2" style={props.style}>
        <Col className="pl-3">
          <span style={{ fontSize: '15px', fontWeight: '600' }}>
            <i className="far fa-check-circle" style={{ color: 'rgb(0, 128, 0)' }} /> {tilte}
          </span>
        </Col>
        <Col className="pr-3">
          <span style={{ fontSize: '15px', fontWeight: '600' }}>{amount}</span>
        </Col>
      </Row>
    </Col>
  );
};

const formatCurrency = new Intl.NumberFormat('vi-VN', {
  style: 'currency',
  currency: 'VND',
});

const SaleDashboardScreen = () => {
  const [date, setDate] = useState({
    fromDate: moment().subtract(6, 'days').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  });
  const [isLoading, setIsLoading] = useState(false);

  const [opportunity, setOpportunity] = useState({});
  const [revenue, setRevenue] = useState({
    totalRevenue: 0,
    totalSale: 0,
  });

  const [callChart, setCallChart] = useState({});
  const [newCustomerChart, setNewCustomerChart] = useState({});
  const [revenueChart, setRevenueChart] = useState({});

  const handleFromdateChange = (pickedDate) => {
    mixpanel.track(`Filter Dashboard from: ${moment(pickedDate).format('YYYY-MM-DD')}`);
    setDate({
      fromDate: moment(pickedDate).format('YYYY-MM-DD'),
      toDate: moment(pickedDate).add(6, 'days').format('YYYY-MM-DD'),
    });
  };

  const handleTodateChange = (pickedDate) => {
    mixpanel.track(`Filter Dashboard to: ${moment(pickedDate).format('YYYY-MM-DD')}`);
    setDate((prevDate) => ({ ...prevDate, toDate: moment(pickedDate).format('YYYY-MM-DD') }));
  };

  const setDisplayChartLength = (arr) => {
    return arr.length > 1 ? arr : ['0', ...arr];
  };

  const initHomePage = async () => {
    setIsLoading(true);
    try {
      const data = await Promise.all([
        requestGetHomeListCallStatusChart(date),
        requestGetHomeNewCustomerChart(date),
        requestGetHomeRevenueChart(date),
        requestGetHomeOpportunityChart(date),
      ]);

      const listCallQuater = data[0].data.map((call) => call.quarter);
      const listCallEarning = data[0].data.map((call) => call.earnings);
      const listNewCustomerQuater = data[1].data.map((customer) => customer.quarter);
      const listNewCustomerEarning = data[1].data.map((customer) => customer.customers);
      const listRevenueLabel = data[2].data.chartRevenue.map((revenue) => revenue.date);
      const listRevenueValue = data[2].data.chartRevenue.map((revenue) => revenue.value);
      const listSalesValue = data[2].data.chartSales.map((revenue) => revenue.value);

      setOpportunity(data[3].data.find((oppo) => oppo.category_name === 'Mua Bán'));

      setRevenue({
        totalRevenue: data[2].data.totalRevenue,
        totalSale: data[2].data.totalSale,
      });

      setCallChart({
        labels: setDisplayChartLength(listCallQuater),
        datasets: [
          {
            data: setDisplayChartLength(listCallEarning),
            backgroundColor: 'rgb(254, 122, 21, 0.2)',
            borderColor: 'rgb(254, 122, 21)',
            tension: 0.3,
          },
        ],
      });

      setNewCustomerChart({
        labels: setDisplayChartLength(listNewCustomerQuater),
        datasets: [
          {
            data: setDisplayChartLength(listNewCustomerEarning),
            backgroundColor: 'rgb(0, 128, 0, 0.2)',
            borderColor: 'rgb(0, 128, 0)',
            tension: 0.3,
          },
        ],
      });

      setRevenueChart({
        labels: setDisplayChartLength(listRevenueLabel),
        datasets: [
          {
            label: 'Doanh thu',
            data: setDisplayChartLength(listRevenueValue),
            borderColor: '#1890ff',
            tension: 0.3,
            fill: false,
          },
          {
            label: 'Doanh số',
            data: setDisplayChartLength(listSalesValue),
            borderColor: 'rgb(255, 0, 0)',
            tension: 0.3,
            fill: false,
          },
        ],
      });
    } catch (error) {
      notifyFail(error.msg || 'Đã có lỗi xảy ra!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initHomePage();
  }, [date]);

  return (
    <>
      {isLoading && <Loading />}
      <div className="content-wrapper">
        <Row
          style={{ backgroundColor: '#fff', borderRadius: 'var(--section-radius)' }}
          className="p-4"
          justify="space-between"
        >
          <span style={{ fontWeight: 'bold', fontSize: '20px' }}>Tổng quan</span>
          <Space>
            <DatePicker
              placeholder="Từ ngày"
              format="DD/MM/YYYY"
              allowClear={false}
              value={moment(date.fromDate)}
              onChange={(e) => handleFromdateChange(e)}
              disabledDate={(current) => current && current > moment().endOf('day')}
            />
            <DatePicker
              placeholder="Đến ngày"
              format="DD/MM/YYYY"
              value={moment(date.toDate)}
              allowClear={false}
              disabledDate={(current) => {
                return (
                  // (current &&
                  //   (current > moment(date.fromDate).add(6, 'days').endOf('day') || current < moment(date.fromDate))) ||
                  // current > moment().endOf('day')
                  (current && current > moment().endOf('day')) || current < moment(date.fromDate)
                );
              }}
              onChange={(e) => handleTodateChange(e)}
            />
          </Space>
        </Row>

        <Row gutter={16} style={{ margin: 0 }}>
          <Col xs={24} sm={24} md={12}>
            <HomeChart tilte="Cuộc gọi">
              <Line data={callChart} options={{ legend: { display: false } }} />
            </HomeChart>
            <HomeChart tilte="Khách hàng mới" style={{ marginTop: '16px' }}>
              <Line data={newCustomerChart} options={{ legend: { display: false } }} />
            </HomeChart>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <HomeChart tilte="Cơ hội" style={{ backgroundColor: '#fff' }}>
              <div className="opportunity center-container py-4 p-md-2 py-lg-3 py-xl-4">
                <div style={{ display: 'flex' }}>
                  <div className="icon">
                    <i className="far fa-gem" />
                  </div>
                  <div className="pl-3 fee">
                    <p className="description pt-md-2 pt-xl-3 mb-0">Hoa hồng</p>
                    <span className="total">
                      {formatCurrency.format(opportunity?.Epoint ? opportunity?.Epoint : 0)}
                    </span>
                  </div>
                </div>
              </div>
              <Row>
                <OpportunityAmount
                  tilte="Tổng"
                  amount={opportunity?.total}
                  style={{
                    borderRadius: '30px',
                    border: '1px solid #fff',
                    backgroundColor: 'rgb(128, 0, 255, 0.2)',
                  }}
                />
                <OpportunityFail
                  tilte="Thất Bại"
                  amount={opportunity?.fail}
                  style={{
                    borderRadius: '30px',
                    border: '1px solid #fff',
                    backgroundColor: 'rgb(255, 0, 0, 0.2)',
                  }}
                />
                <OpportunityProcessing
                  tilte="Đã tiếp nhận"
                  amount={opportunity?.accepted}
                  style={{
                    borderRadius: '30px',
                    border: '1px solid #fff',
                    backgroundColor: 'rgb(255, 191, 0, 0.2)',
                  }}
                />
                <OpportunityCompleted
                  tilte="Hoàn thành"
                  amount={opportunity?.success}
                  style={{
                    borderRadius: '30px',
                    border: '1px solid #fff',
                    backgroundColor: 'rgb(0, 128, 0, 0.2)',
                  }}
                />
              </Row>
            </HomeChart>
            <HomeChart tilte="Doanh số">
              <Row>
                <Col span={12}>
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        width: '15px',
                        height: '15px',
                        borderRadius: '50%',
                        backgroundColor: 'blue',
                        marginTop: '7px',
                      }}
                    />
                    <div className="pl-2">
                      <p className="mb-0" style={{ fontSize: '18px' }}>
                        {formatCurrency.format(revenue.totalRevenue)}
                      </p>
                      <p style={{ fontSize: '16px' }}>Doanh thu</p>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        width: '15px',
                        height: '15px',
                        borderRadius: '50%',
                        backgroundColor: 'red',
                        marginTop: '7px',
                      }}
                    />
                    <div className="pl-2">
                      <p className="mb-0" style={{ fontSize: '18px' }}>
                        {formatCurrency.format(revenue.totalSale)}
                      </p>
                      <p style={{ fontSize: '16px' }}>Doanh số</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Line
                  data={revenueChart}
                  options={{
                    legend: { display: false },
                  }}
                />
              </Row>
            </HomeChart>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SaleDashboardScreen;
