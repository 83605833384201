import { rmVN } from '@constants/funcHelper';
import { VietnameseString } from '@constants/VietnameseString';
import { ColStyled } from '@screens/Enterprise/ElectronicVoucher/ElectronicVoucherStyled';
import { hooks } from '@screens/Enterprise/ElectronicVoucher/store';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  TimePicker,
} from 'antd';
import React from 'react';
import styled from 'styled-components';
import { PlusOutlined, LineOutlined } from '@ant-design/icons';
import { Colors } from '@constants/Theme';
import { primaryColor } from '@styles/Color';
import {
  SET_CONDITION_PRODUCT,
  SET_CONDITION_PURCHASE_TIME_DATE,
  SET_CONDITION_PURCHASE_TIME_HOUR,
  SET_TIME_BUY,
} from '@screens/Enterprise/ElectronicVoucher/store/constants';
import moment from 'moment';
import { uniqueId } from 'lodash';
import { create_UUID } from '@screens/Enterprise/ElectronicVoucher/service';
const initialDateRanger = {
  startDate: null,
  endDate: null,
};

const initialHourRanger = {
  startHour: null,
  endHour: null,
};

const initialChooseProduct = {
  productName: null,
  quantity: null,
  chooseQuantity: false,
  price: null,
  choosePrice: false,
  product_id: null,
};

const TotalPriceOrder = ({ conditionApply, form }) => {
  const [state, dispatch] = hooks.usePromotionsStore();
  console.log('🚀 ~ state:', state);

  const applyTime = Form.useWatch('applyTime', form);

  // thời gian mua hàng
  // const [timeBuy, setTimeBuy] = React.useState('date');

  // danh sách ngày
  // const [days, setDays] = React.useState([initialDateRanger]);

  // danh sách giờ
  // const [hours, setHours] = React.useState([initialHourRanger]);
  // danh sách chọn sản phẩm
  // const [chooseProducts, setChooseProducts] = React.useState([initialChooseProduct]);

  const onChange = e => {
    dispatch({
      type: SET_TIME_BUY,
      payload: e.target.value,
    });
  };

  return (
    <>
      <Form.Item
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn điều kiện áp dụng',
          },
        ]}
        wrapperCol={{ span: 24 }}
        name="condition_apply"
      >
        <Checkbox.Group style={{ width: '100%' }}>
          <Row gutter={[6, 6]} style={{ width: '100%' }}>
            <ColStyled span={24}>
              <Checkbox value="totalPriceOrder">
                Tổng số tiền của đơn hàng
              </Checkbox>
            </ColStyled>
            {/* Tổng số tiền của đơn hàng */}
            {conditionApply?.includes('totalPriceOrder') && (
              <Form.Item
                label="Số tiền"
                colon={false}
                name="condition_total_money"
                style={{ margin: '6px 20px' }}
                rules={[
                  { required: true, message: 'Vui lòng nhập số tiền' },
                  {
                    validator: (_, value) => {
                      if (+value < 1) {
                        return Promise.reject(
                          new Error('Số tiền phải lớn hơn 0!')
                        );
                      }
                      if (+value > 100000000000) {
                        return Promise.reject(
                          new Error('Số tiền phải nhỏ hơn 100 tỷ!')
                        );
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
                normalize={value =>
                  value?.toString()?.replace(/[^\wÀ-úÀ-ÿ]/g, '')
                }
              >
                <InputNumber
                  addonAfter="VNĐ"
                  style={{ width: '100%' }}
                  formatter={value =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={value =>
                    value ? value.replace(/\$\s?|(,*)|\./g, '') : ''
                  }
                  placeholder="Số tiền"
                  controls={false}
                />
              </Form.Item>
            )}
            <ColStyled span={24}>
              <Checkbox value="product">Sản phẩm</Checkbox>
            </ColStyled>
            {/* Sản phẩm */}
            {conditionApply?.includes('product') && (
              <>
                {state?.condition_product?.map((prod, index) => (
                  <div
                    key={index}
                    style={{ width: '100%', padding: '4px 20px' }}
                  >
                    {index > 0 && <Divider style={{ margin: '0 0 10px 0' }} />}
                    <div style={{ display: 'flex' }}>
                      <div
                        style={{
                          width: '100%',
                          alignItems: 'center',
                          marginRight: '10px',
                        }}
                      >
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng chọn sản phẩm',
                            },
                          ]}
                          name={`condition_product_${prod?.key_id}`}
                          wrapperCol={{ span: 24 }}
                        >
                          <Select
                            style={{ width: '100%', marginBottom: '15px' }}
                            placeholder={VietnameseString.product}
                            allowClear
                            maxTagCount={1}
                            // mode="multiple"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                              return (
                                rmVN(option.children).indexOf(rmVN(input)) >= 0
                              );
                            }}
                            value={prod?.productName}
                            onChange={value => {
                              const chosenProduct = state?.products.filter(
                                prod => prod.id === value
                              );
                              const newChooseProducts =
                                state?.condition_product?.map(
                                  (product, idex) => {
                                    if (idex === index) {
                                      return {
                                        ...product,
                                        productName: chosenProduct[0]?.name,
                                        product_id:
                                          chosenProduct[0]?.product_id,
                                      };
                                    } else {
                                      return product;
                                    }
                                  }
                                );
                              dispatch({
                                type: SET_CONDITION_PRODUCT,
                                payload: newChooseProducts,
                              });
                            }}
                          >
                            {state?.products?.map(pro => {
                              return (
                                <Select.Option
                                  disabled={state?.condition_product?.find(
                                    prod => prod?.product_id === pro?.id
                                  )}
                                  key={pro.name}
                                  value={pro.id}
                                >
                                  {pro.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>

                        <Checkbox.Group
                          style={{ width: '100%' }}
                          value={[
                            prod?.chooseQuantity && 'chooseQuantity',
                            prod?.choosePrice && 'choosePrice',
                          ]}
                          onChange={value => {
                            dispatch({
                              type: SET_CONDITION_PRODUCT,
                              payload: state?.condition_product?.map(
                                (prod, idex) => {
                                  if (index === idex) {
                                    return {
                                      ...prod,
                                      chooseQuantity:
                                        value.includes('chooseQuantity'),
                                      choosePrice:
                                        value.includes('choosePrice'),
                                    };
                                  }
                                  return prod;
                                }
                              ),
                            });
                          }}
                        >
                          <Row>
                            <Col span={24} style={{ margin: '4px 0' }}>
                              <Row>
                                <ColStyled
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                  span={8}
                                >
                                  <Checkbox value="chooseQuantity">
                                    Số lượng
                                  </Checkbox>
                                </ColStyled>
                                <ColStyled span={16}>
                                  {prod.chooseQuantity && (
                                    <Form.Item
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Vui lòng nhập số lượng',
                                        },
                                        {
                                          validator: (_, value) => {
                                            if (+value < 1) {
                                              return Promise.reject(
                                                new Error(
                                                  'Số lượng phải lớn hơn 0!'
                                                )
                                              );
                                            }

                                            if (+value > 100000000000) {
                                              return Promise.reject(
                                                new Error(
                                                  'Số lượng phải nhỏ hơn 100 tỷ!'
                                                )
                                              );
                                            }

                                            return Promise.resolve();
                                          },
                                        },
                                      ]}
                                      name={`condition_product_quantity_${prod?.key_id}`}
                                      wrapperCol={{ span: 24 }}
                                    >
                                      <InputNumber
                                        style={{
                                          width: '100%',
                                          marginTop: '4px',
                                        }}
                                        formatter={value =>
                                          `${value}`.replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ','
                                          )
                                        }
                                        parser={value =>
                                          value
                                            ? value.replace(
                                                /\$\s?|(,*)|\./g,
                                                ''
                                              )
                                            : ''
                                        }
                                        placeholder="Nhập số lượng"
                                        onChange={value => {
                                          const newChooseProducts = [
                                            ...state?.condition_product,
                                          ];

                                          const findProduct =
                                            newChooseProducts.find(
                                              product =>
                                                product.key_id === prod.key_id
                                            );

                                          if (findProduct) {
                                            findProduct.valueQuantity = value;
                                          }
                                          dispatch({
                                            type: SET_CONDITION_PRODUCT,
                                            payload: newChooseProducts,
                                          });
                                        }}
                                      />
                                    </Form.Item>
                                  )}
                                </ColStyled>
                              </Row>
                            </Col>
                            <Col span={24}>
                              <Row>
                                <ColStyled
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                  span={8}
                                >
                                  <Checkbox value="choosePrice">
                                    Thành tiền
                                  </Checkbox>
                                </ColStyled>
                                <ColStyled span={16}>
                                  {prod.choosePrice && (
                                    <Form.Item
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Vui lòng nhập số tiền',
                                        },
                                        {
                                          validator: (_, value) => {
                                            if (+value < 1) {
                                              return Promise.reject(
                                                new Error(
                                                  'Số tiền phải lớn hơn 0!'
                                                )
                                              );
                                            }

                                            if (+value > 100000000000) {
                                              return Promise.reject(
                                                new Error(
                                                  'Số tiền phải nhỏ hơn 100 tỷ!'
                                                )
                                              );
                                            }

                                            return Promise.resolve();
                                          },
                                        },
                                      ]}
                                      name={`condition_product_price_${prod?.key_id}`}
                                      wrapperCol={{ span: 24 }}
                                    >
                                      <InputNumber
                                        addonAfter="VNĐ"
                                        style={{
                                          width: '100%',
                                          marginTop: '4px',
                                        }}
                                        formatter={value =>
                                          `${value}`.replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ','
                                          )
                                        }
                                        parser={value =>
                                          value
                                            ? value.replace(
                                                /\$\s?|(,*)|\./g,
                                                ''
                                              )
                                            : ''
                                        }
                                        placeholder="Số tiền"
                                        controls={false}
                                        onChange={value => {
                                          const newChooseProducts = [
                                            ...state?.condition_product,
                                          ];

                                          const findProduct =
                                            newChooseProducts.find(
                                              product =>
                                                product.key_id === prod.key_id
                                            );

                                          if (findProduct) {
                                            findProduct.valuePrice = value;
                                          }

                                          dispatch({
                                            type: SET_CONDITION_PRODUCT,
                                            payload: newChooseProducts,
                                          });
                                        }}
                                      />
                                    </Form.Item>
                                  )}
                                </ColStyled>
                              </Row>
                            </Col>
                          </Row>
                        </Checkbox.Group>
                      </div>
                      {index > 0 && ( // btn delete
                        <Button
                          onClick={() => {
                            // remove 1 item index
                            const products = state?.condition_product?.filter(
                              (_, i) => i !== index
                            );

                            form.setFieldsValue({
                              [`condition_product_quantity_${index}`]:
                                undefined,
                              [`condition_product_price_${index}`]: undefined,
                              [`condition_product_${index}`]: undefined,
                            });

                            dispatch({
                              type: SET_CONDITION_PRODUCT,
                              payload: products,
                            });
                          }}
                          icon={<LineOutlined />}
                          shape="circle"
                        />
                      )}
                    </div>
                  </div>
                ))}
                <AddProductStyled
                  onClick={() =>
                    dispatch({
                      type: SET_CONDITION_PRODUCT,
                      payload: [
                        ...state?.condition_product,
                        {
                          ...initialChooseProduct,
                          key_id: create_UUID(),
                        },
                      ],
                    })
                  }
                >
                  <PlusOutlined />
                  <span>Thêm mới sản phẩm</span>
                </AddProductStyled>
              </>
            )}

            <ColStyled span={24}>
              <Checkbox value="timeBuy">Thời gian mua hàng</Checkbox>
            </ColStyled>
            {/* thời gian mua hàng */}
            {conditionApply?.includes('timeBuy') && (
              <Radio.Group
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
                onChange={onChange}
                value={state?.timeBuy}
              >
                <SpaceStyled direction="vertical">
                  <Radio value="date">Ngày</Radio>
                  {/* danh sách ngày */}
                  {state?.timeBuy === 'date' && (
                    <Space direction="vertical">
                      {state?.condition_purchase_time_date?.map(
                        (day, index) => (
                          <Space
                            key={index}
                            size="middle"
                            style={{ padding: '0 10px' }}
                          >
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: 'Vui lòng chọn ngày',
                                },
                              ]}
                              name={`condition_product_date_${day?.key_id}`}
                              wrapperCol={{ span: 24 }}
                            >
                              <DatePicker.RangePicker
                                value={[day.startDate, day.endDate]}
                                showTime
                                format="DD/MM/YYYY HH:mm"
                                style={{ width: '100%' }}
                                disabledDate={current => {
                                  // disabled date before today and after applyTime

                                  return (
                                    (current &&
                                      current <
                                        moment(applyTime?.[0])
                                          .subtract(1, 'days')
                                          .endOf('day')) ||
                                    (applyTime?.[1] &&
                                      current >
                                        moment(applyTime?.[1]).endOf('day'))
                                  );
                                }}
                                onChange={(dates, dateString) => {
                                  const newDays = [
                                    ...state?.condition_purchase_time_date,
                                  ];
                                  newDays[index] = {
                                    ...newDays[index],
                                    startDate: dates?.[0],
                                    endDate: dates?.[1],
                                  };
                                  dispatch({
                                    type: SET_CONDITION_PURCHASE_TIME_DATE,
                                    payload: newDays,
                                  });
                                  // setDays(newDays);
                                }}
                              />
                            </Form.Item>
                            {index > 0 ? (
                              // btn delete
                              <Button
                                onClick={() => {
                                  // remove 1 item index
                                  const newDays =
                                    state?.condition_purchase_time_date?.filter(
                                      (_, i) => i !== index
                                    );
                                  // form.setFieldsValue({
                                  //   [`condition_product_date_${index}`]:
                                  //     undefined,
                                  // });
                                  dispatch({
                                    type: SET_CONDITION_PURCHASE_TIME_DATE,
                                    payload: newDays,
                                  });
                                }}
                                icon={<LineOutlined />}
                                shape="circle"
                              />
                            ) : (
                              // btn add
                              <Button
                                onClick={() => {
                                  dispatch({
                                    type: SET_CONDITION_PURCHASE_TIME_DATE,
                                    payload: [
                                      ...state?.condition_purchase_time_date,
                                      {
                                        ...initialDateRanger,
                                        key_id: create_UUID(),
                                      },
                                    ],
                                  });
                                }}
                                icon={<PlusOutlined />}
                                shape="circle"
                              />
                            )}
                          </Space>
                        )
                      )}
                    </Space>
                  )}
                  <Radio value="hour">Giờ</Radio>
                  {state?.timeBuy === 'hour' && (
                    <Space direction="vertical">
                      {state?.condition_purchase_time_hour?.map(
                        (day, index) => (
                          <Space
                            key={index}
                            size="middle"
                            style={{ padding: '0 10px' }}
                          >
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: 'Vui lòng chọn giờ',
                                },
                              ]}
                              name={`condition_product_hour_${day?.key_id}`}
                              wrapperCol={{ span: 24 }}
                            >
                              <TimePicker.RangePicker
                                placeholder={['Giờ bắt đầu', 'Giờ kết thúc']}
                                showTime
                                format="HH:mm"
                                style={{ width: '100%' }}
                                onChange={(dates, dateString) => {
                                  const newHours = [
                                    ...state?.condition_purchase_time_hour,
                                  ];
                                  newHours[index] = {
                                    ...newHours[index],
                                    startDate: dates?.[0],
                                    endDate: dates?.[1],
                                  };
                                  dispatch({
                                    type: SET_CONDITION_PURCHASE_TIME_HOUR,
                                    payload: newHours,
                                  });
                                  // setHours(newHours);
                                }}
                              />
                            </Form.Item>
                            {index > 0 ? (
                              // btn delete

                              <Button
                                onClick={() => {
                                  // remove 1 item index
                                  const newHours =
                                    state?.condition_purchase_time_hour?.filter(
                                      (_, i) => i !== index
                                    );
                                  // form.setFieldsValue({
                                  //   [`condition_product_hour_${index}`]:
                                  //     undefined,
                                  // });
                                  dispatch({
                                    type: SET_CONDITION_PURCHASE_TIME_HOUR,
                                    payload: newHours,
                                  });
                                }}
                                icon={<LineOutlined />}
                                shape="circle"
                              />
                            ) : (
                              // btn add
                              <Button
                                onClick={() => {
                                  dispatch({
                                    type: SET_CONDITION_PURCHASE_TIME_HOUR,
                                    payload: [
                                      ...state?.condition_purchase_time_hour,
                                      {
                                        ...initialHourRanger,
                                        key_id: create_UUID(),
                                      },
                                    ],
                                  });
                                }}
                                icon={<PlusOutlined />}
                                shape="circle"
                              />
                            )}
                          </Space>
                        )
                      )}
                    </Space>
                  )}
                </SpaceStyled>
              </Radio.Group>
            )}

            <ColStyled span={24}>
              <Checkbox value="orther">Điều kiện khác</Checkbox>
            </ColStyled>
            {/* điều kiện khác */}
            {conditionApply?.includes('orther') && (
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập ghi chú',
                  },
                ]}
                name="condition_other"
                style={{ width: '100%', padding: '10px' }}
                label="Ghi chú"
                normalize={value => value?.toString()?.trimStart()}
              >
                <Input.TextArea placeholder="Nhập ghi chú" rows={4} />
              </Form.Item>
            )}
          </Row>
        </Checkbox.Group>
      </Form.Item>
    </>
  );
};

const SpaceStyled = styled(Space)`
  width: 100%;
  padding: 0 20px;
  & .ant-space-item {
    display: flex;
    justify-content: flex-start;
  }
`;

const AddProductStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 600;

  &:hover {
    color: ${primaryColor};
  }

  width: 100%;

  & span {
    margin-left: 10px;
  }
`;

export default TotalPriceOrder;
