import { Input, Select } from 'antd';
import styled from 'styled-components';

export const ButtonCallStyled = styled.div`
  &&& {
    :hover {
      cursor: ${props => (props.loading ? 'progress' : 'pointer')};
    }
  }
`;

export const InputPhoneStyled = styled(Input)`
  & * {
    font-size: 16px;
  }

  & span.ant-input-clear-icon {
    display: flex;
  }
`;

export const hangupCallStyle = {
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  border: '10px solid pink',
  backgroundColor: 'red',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  color: 'white',
};

export const FunctionKeyStyle = {
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  backgroundColor: '#d1d1d1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
};

export const ContainerSelectHotlineStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  margin: 6px 36px 0 36px;
  cursor: pointer;
  position: relative;
  &::before {
    content: 'Hotline';
    position: absolute;
    top: -8px;
    font-size: 8px;
    left: 50%;
    transform: translateX(-50%);
  }

  & .ant-select-selector {
    padding-left: 10px !important;
  }

  & .icon_phone {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 6px;
    position: relative;
    padding-right: 6px;
    img {
      height: 14px;
      width: 14px;
    }
    /* & * {
      color: #248037;
    } */

    & .icon_down {
      display: flex;
      align-items: center;
      padding-left: 4px;
    }

    & .icon_down * {
      font-size: 10px;
      color: #999;
      font-weight: 600;
    }

    & span {
      font-size: 10px;
    }
  }

  & .icon_phone::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background: #e5e5e5;
    right: 0;
  }
`;

export const SelectHotlineStyled = styled(Select)`
  & * {
    font-size: 12px;
    color: #777;
  }
`;
