import { Button, Form, Modal, Radio, Row, Space } from 'antd';
import React, { useEffect } from 'react';

export default function ModalPickPhone({
  isDisplayPickPhoneNumberModal,
  dataCustomerPhone,
  setPhoneStartCall,
  phoneStartCall,
  setIsDisplayPickPhoneNumberModal,
  onCall,
  dataCustomerPhoneFirst,
}) {
  useEffect(() => {
    setPhoneStartCall(dataCustomerPhoneFirst);
  }, []);

  const hanldePickPhoneNumberModalCancel = () => {
    setIsDisplayPickPhoneNumberModal(false);
    setPhoneStartCall([]);
  };

  const handlePickedPhone = (formValues) => {
    setIsDisplayPickPhoneNumberModal(false);
    onCall();
  };
  return (
    <Modal
      title="Chọn số điện thoại"
      destroyOnClose={true}
      footer={null}
      visible={isDisplayPickPhoneNumberModal}
      onCancel={hanldePickPhoneNumberModalCancel}
    >
      <Form name="basic" layout="vertical" autoComplete="off" onFinish={handlePickedPhone}>
        <Form.Item name="phone" initialValue={dataCustomerPhoneFirst}>
          <Radio.Group
            onChange={(value) => {
              setPhoneStartCall(value.target.value);
            }}
            value={phoneStartCall}
            defaultValue={dataCustomerPhone?.[0]?.phone}
          >
            <div>
              <Radio style={{float: 'left'}} value={dataCustomerPhoneFirst}>{dataCustomerPhoneFirst}</Radio>
            </div>
            {dataCustomerPhone?.map((item) => (
              <div>
                <Radio style={{float: 'left'}} value={item.phone}>{item.phone}</Radio>
              </div>
            ))}
          </Radio.Group>
        </Form.Item>

        <Row justify="end">
          <Form.Item className="m-0">
            <Button type="primary" htmlType="submit">
              OK
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
}
