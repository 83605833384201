import '@styles/Customer.css';
import { formatDateTimeString } from '@utils/dateUtils';
import {
  Spin,
  Table as TableAntd,
  Tabs,
  Tag,
  Typography,
  Descriptions,
  Empty,
  Divider,
  Pagination,
  Row,
  Col,
  Select,
  DatePicker,
} from 'antd';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { toDateString } from '@utils/ToDateString';
import { useHistory } from 'react-router-dom';
import { ROUTER } from '@constants/Constant';

const { TabPane } = Tabs;
const { Text, Title, Link: LinkAntd } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const NotAvailableText = () => <Text type="secondary">Chưa cập nhật</Text>;
const timeout = 500;
let locale = {
  emptyText: 'Không có dữ liệu',
};

const CallHistory = ({
  data,
  jobs,
  handleChange,
  handleChangeDate,
  handleChangeFromDate,
  handleChangeToDate,
  formattedPage,
  onChangePage,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  let history = useHistory();

  useEffect(() => {
    let loadingTimeout = setTimeout(() => setIsLoading(false), timeout);
    return () => clearTimeout(loadingTimeout);
  }, []);

  const columns = [
    { title: 'STT', dataIndex: 'order', key: 'order' },
    {
      title: 'Công việc',
      dataIndex: 'job_name',
      key: 'job_name',
      render: (value) => <Text>{value || <NotAvailableText />}</Text>,
    },
    {
      title: 'Trạng thái cuộc gọi',
      dataIndex: 'status_name',
      key: 'status_name',
      render: (value) => (
        <Tag color={value ? (value.toUpperCase() === 'KHÔNG NGHE MÁY' ? 'red' : 'green') : 'blue'}>
          {value || <NotAvailableText />}
        </Tag>
      ),
    },
    {
      title: 'Thời gian',
      dataIndex: 'start_call_time',
      key: 'start_call_time',
      render: (value) => <Text>{formatDateTimeString(value) || <NotAvailableText />}</Text>,
    },
    {
      title: 'Nhân viên',
      dataIndex: 'sale_name',
      key: 'sale_name',
      render: (value) => <Text>{value || <NotAvailableText />}</Text>,
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Row gutter={16} style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <Select
            style={{ width: '100%' }}
            placeholder="Chọn công việc"
            onChange={(value) => handleChange('type', value)}
            allowClear
          >
            {jobs.length ? (
              jobs.map((item, index) => {
                return (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                );
              })
            ) : (
              <></>
            )}
          </Select>
        </Col>
        <Col span={8}>
          <RangePicker
            placeholder={['Từ ngày', 'Đến ngày']}
            format="YYYY-MM-DD"
            onChange={(date, dateString) => {
              console.log(dateString[0], dateString[1]);
              // handleChangeDate('fromDate', 'toDate', date);
              handleChangeFromDate('fromDate', dateString && dateString[0]);
              handleChangeToDate('toDate', dateString && dateString[1]);
            }}
          />
        </Col>
      </Row>
      {data.length ? (
        data.map((item, index) => {
          return (
            <div
              key={item.id}
              // onClick={() => {
              //   // console.log({ item, index });
              //   history.push(`${ROUTER.CALL_DETAIL}/${item.id}`);
              // }}
            >
              <Divider orientation="left" style={{ marginBottom: 0 }}>
                {item.job_name}
              </Divider>
              <Descriptions
                style={{
                  marginTop: 0,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 12,
                  border: '0.5px solid #CDCDCD',
                  borderTop: 'none',
                  borderRadius: '10px',
                }}
              >
                <Descriptions.Item label={'Trạng thái cuộc gọi'} labelStyle={{ color: 'grey' }}>
                  <Tag
                    color={
                      item.status_name
                        ? item.status_name.toUpperCase() === 'KHÔNG NGHE MÁY'
                          ? 'red'
                          : 'green'
                        : 'blue'
                    }
                  >
                    {item.status_name || <NotAvailableText />}
                  </Tag>
                </Descriptions.Item>
                <Descriptions.Item label={'Thời gian'} labelStyle={{ color: 'grey' }}>
                  <Text strong={true}>{formatDateTimeString(item.start_call_time) || <NotAvailableText />}</Text>
                </Descriptions.Item>
                <Descriptions.Item label={'Thời lượng'} labelStyle={{ color: 'grey' }}>
                  <Text strong={true}>{item.time_recall || <NotAvailableText />}</Text>
                </Descriptions.Item>
                <Descriptions.Item label={'Nhân viên'} labelStyle={{ color: 'grey' }}>
                  <Text strong={true}>{item.sale_name || <NotAvailableText />}</Text>
                </Descriptions.Item>
              </Descriptions>
            </div>
          );
        })
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Không có dữ liệu" />
      )}
      <Pagination
        defaultCurrent={1}
        total={10}
        style={{ float: 'right', marginTop: '20px' }}
        {...formattedPage}
        onChange={(page) => onChangePage(page)}
        showSizeChanger={false}
      />
      {/* <TableAntd locale={locale} columns={columns} dataSource={data} /> */}
    </Spin>
  );
};

export default CallHistory;
