import { getAxios, handleResult } from '@constants/Api';

export const getCustomerDetailInboundCall = ({
  hotline,
  customer_phone,
  enterprise_id,
  customer_id,
}) => {
  return handleResult(
    getAxios('call/getCustomerDetailInboundCall', {
      params: {
        hotline,
        customer_phone,
        enterprise_id,
        customer_id,
      },
    })
  );
};
