import { rmVN } from '@constants/funcHelper';
import { VietnameseString } from '@constants/VietnameseString';
import {
  ColStyled,
  LabelStyled,
} from '@screens/Enterprise/ElectronicVoucher/ElectronicVoucherStyled';
import { voucherService } from '@screens/Enterprise/ElectronicVoucher/service';
import { hooks } from '@screens/Enterprise/ElectronicVoucher/store';
import { Button, Form, Row, Select, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { useParams } from 'react-router-dom';
import useDebounce from 'src/hooks/useDebounce';
import AddEditGift from '../../gift/AddEditGift';

const GiveAGift = ({ form }) => {
  const [state, dispatch] = hooks.usePromotionsStore();
  const [visibleQuickAdd, setVisibleQuickAdd] = React.useState(false);

  const [loadingGift, setLoadingGift] = React.useState(false);

  const { id } = useParams();

  const [gifts, setGifts] = React.useState([]);
  const [searchGift, setSearchGift] = React.useState('');
  const debounceSearchGift = useDebounce(searchGift, 300);

  const converted_gift = Form.useWatch('converted_gift', form);
  const customer_gift = Form.useWatch('customer_gift', form);

  React.useEffect(() => {
    setLoadingGift(true);
    voucherService
      .getGiftVoucherV2({
        search: debounceSearchGift,
      })
      .then(res => {
        setGifts(res.data);
      })
      .finally(() => {
        setLoadingGift(false);
      });
  }, [debounceSearchGift]);

  const returnProducts = products => {
    let productsWhenUpdate = [];

    if (id) {
      productsWhenUpdate =
        state?.detail_evoucher?.e_voucher_converted_gifts?.map(pro => {
          return {
            id: pro?.product_id,
            name: pro?.product_name,
          };
        });
    }

    // check if productWhenUpdate is exits from the products
    const set = new Set(productsWhenUpdate.map(({ id }) => id));
    return [
      ...products,
      ...productsWhenUpdate.filter(({ id }) => !set.has(id)),
    ];
  };

  const returnGift = giftsProps => {
    let giftsWhenUpdate = [];

    if (id) {
      giftsWhenUpdate = state?.detail_evoucher?.e_voucher_customer_gifts?.map(
        gift => {
          return {
            id: gift?.e_voucher_gift_id,
            name: gift?.e_voucher_gift_name,
          };
        }
      );
    }

    // check if productWhenUpdate is exits from the products
    const set = new Set(giftsProps.map(({ id }) => id));
    return [...giftsWhenUpdate.filter(({ id }) => !set.has(id)), ...giftsProps];
  };

  return (
    <>
      <div>
        <Row>
          <LabelStyled>{VietnameseString.redeem_gifts}</LabelStyled>
        </Row>

        <Row style={{ padding: '0 10px' }}>
          <ColStyled span={24}>
            <Form.Item
              label={VietnameseString.product}
              colon={false}
              name="converted_gift"
              style={{ marginBottom: 0, width: '100%' }}
              rules={
                (!converted_gift && !customer_gift) ||
                (converted_gift?.length === 0 && customer_gift?.length === 0) ||
                (converted_gift?.length === 0 && !customer_gift) ||
                (!converted_gift && customer_gift?.length === 0)
                  ? [{ required: true, message: 'Vui lòng chọn sản phẩm' }]
                  : []
              }
            >
              <Select
                style={{ width: '100%' }}
                placeholder={VietnameseString.product}
                allowClear
                maxTagCount={1}
                mode="multiple"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return rmVN(option.children).indexOf(rmVN(input)) >= 0;
                }}
              >
                {returnProducts(state?.products)?.map(pro => (
                  <Select.Option key={pro.id} value={pro.id}>
                    {pro.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </ColStyled>
        </Row>
      </div>
      <div>
        <Row>
          <LabelStyled>{VietnameseString.gifts}</LabelStyled>
        </Row>

        <Row style={{ padding: '0 10px' }}>
          <ColStyled span={24}>
            <Form.Item
              label={VietnameseString.gifts}
              colon={false}
              name="customer_gift"
              style={{ marginBottom: 0, width: '100%' }}
              rules={
                (!converted_gift && !customer_gift) ||
                (converted_gift?.length === 0 && customer_gift?.length === 0) ||
                (converted_gift?.length === 0 && !customer_gift) ||
                (!converted_gift && customer_gift?.length === 0)
                  ? [{ required: true, message: 'Vui lòng chọn quà tặng' }]
                  : []
              }
            >
              <Select
                onSearch={setSearchGift}
                allowClear
                mode="multiple"
                showSearch
                placeholder="Chọn quà tặng"
                style={{ width: '97%' }}
                loading={loadingGift}
                filterOption={false}
                onClear={() => setSearchGift('')}
              >
                {returnGift(gifts)?.map(gift => (
                  <Select.Option key={gift.id} value={gift.id}>
                    {gift.name}
                  </Select.Option>
                )) ?? []}
              </Select>
            </Form.Item>
            <Button
              onClick={() => {
                setVisibleQuickAdd(true);
              }}
              shape="circle"
              icon={<PlusOutlined />}
            />
          </ColStyled>
        </Row>
        <AddEditGift
          visible={visibleQuickAdd}
          onCancel={() => setVisibleQuickAdd(false)}
          onSuccess={values => {
            setGifts([{ id: values?.id, name: values?.name }, ...gifts]);
            form.setFieldsValue({
              customer_gift: customer_gift
                ? [values.id, ...customer_gift]
                : [values.id],
            });
            setVisibleQuickAdd(false);
          }}
        />
      </div>
    </>
  );
};

export default GiveAGift;
