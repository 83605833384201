import React, { Component } from 'react';
import '@styles/review.css';
import './Job.css';
import { requestResultReviewer } from '@constants/Api';
import { ROUTER } from '@constants/Constant';
import { notifyFail, notifySuccess } from '@utils/notify';
import Loading from '@src/components/Loading';

import { withRouter } from 'react-router-dom';
import { Row, Col, Table } from 'react-bootstrap';
import { Empty } from 'antd';
import moment from 'moment';

class JobScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      status: '',
      listTasks: '',
      isLoading: false,
    };
  }

  componentDidMount() {
    this.requestResultReviewer();
  }

  async requestResultReviewer() {
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const res = await requestResultReviewer();
      this.setState({
        ...this.state,
        listTasks: res.data?.list_task,
        isLoading: false,
      });
    } catch (err) {
      this.setState({ ...this.state, isLoading: false }, () => notifyFail(err.msg));
    }
  }

  renderTitle() {
    return <h2 className="title-tab">Danh sách công việc đang thực hiện</h2>;
  }

  renderListJob() {
    return (
      <Row className="mt-1" style={{ padding: '0 18px', borderRadius: '8px', marginBottom: 20 }}>
        <Col md className="bg">
          <Table striped bordered hover className="text-center">
            <thead>
              <th>STT</th>
              <th>Công việc</th>
              <th>Reviewer</th>
              <th>Sales</th>
              <th>Thời gian hoàn thành</th>
            </thead>
            <tbody>{this.renderTableData()}</tbody>
          </Table>
        </Col>
      </Row>
    );
  }

  renderTableData() {
    const { listTasks } = this.state;
    return (
      <>
        {listTasks?.length > 0 ? (
          listTasks?.map((value, index) => (
            <tr onClick={() => this.props.history.push(ROUTER.JOB_REVIEWER_DETAIL + '/' + value?.id)}>
              <td>{index + 1}</td>
              <td>{value?.jobs?.length > 0 && value?.jobs?.map((item) => <p className>{item?.name || '--'}</p>)}</td>
              <td>{value?.full_name || '--'}</td>
              <td>
                {value?.telesales?.length > 0 && value?.telesales?.map((item) => <p className>{item?.name || '--'}</p>)}
              </td>
              <td>
                {moment(value?.start_date).format('DD-MM-YYYY') +
                  ' đến ' +
                  moment(value?.end_date).format('DD-MM-YYYY')}
              </td>
            </tr>
          ))
        ) : (
          <td colSpan="5">
            <Empty description="Không có dữ liệu" />
          </td>
        )}
      </>
    );
  }

  renderBody() {
    const { isLoading } = this.state;
    return (
      <div className="content-wrapper">
        {isLoading ? <Loading /> : null}
        {this.renderTitle()}
        {this.renderListJob()}
      </div>
    );
  }

  render() {
    return <>{this.renderBody()}</>;
  }
}

export default withRouter(JobScreen);
