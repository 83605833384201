import { DownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Wrapper from '@components/ui/wrapper/Wrapper';
import { ROUTER, STRING } from '@constants/Constant';
import Context from '@screens/Enterprise/Job/Add/Tabs/Context';
import Education from '@screens/Enterprise/Job/Add/Tabs/Education';
import Exam from '@screens/Enterprise/Job/Add/Tabs/Exam';
import FormQuestion from '@screens/Enterprise/Job/Add/Tabs/FormQuestion';
import General from '@screens/Enterprise/Job/Add/Tabs/General';
import Loading from '@src/components/Loading';
import { clearJobDetail } from '@src/redux/actions';
import '@styles/Job.css';
import { BreadcrumbAntStyle, ButtonSystemStyle } from '@styles/Style';
import { Breadcrumb, message, Modal as ModalAntd, Steps } from 'antd';
import mixpanel from 'mixpanel-browser';
import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { errorMinigame } from './Tabs/fnHelper';

const { Step } = Steps;

class Screen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: '1',
      activeKey: 'general',
      isLoading: false,
      isCheckEvoucher: false,
      idEvoucher: '',
      modalError: {
        shown: false,
        message: '',
      },
      checks: {
        general: {
          isCheck: true,
        },
        context: {
          isCheck: true,
        },
        formresult: {
          isCheck: true,
        },
        requirement: {
          isCheck: true,
        },
        education: {
          isCheck: true,
        },
        test: {
          isCheck: true,
        },
      },
      currentStep: 0,
      stepStatus: ['process', 'wait', 'wait', 'wait', 'wait'],
    };
  }

  componentWillUnmount() {
    this.props.clearJobDetail();
  }

  setLoading(value) {
    this.setState({ isLoading: value });
  }

  renderBody() {
    return (
      <div className="content-wrapper job-general">
        <BreadcrumbAntStyle>
          <Breadcrumb.Item>
            <a onClick={() => this.props.history.push(ROUTER.JOB)}>
              Danh sách công việc
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Công việc</Breadcrumb.Item>
        </BreadcrumbAntStyle>
        {this.renderContent()}
        {/* <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
          <Col md className="bg">
            <div className="content-header h-100">
              <Col md={12}>{this.renderContent()}</Col>
            </div>
          </Col>
        </Row> */}
      </div>
    );
  }

  // _setActiveTab = (key, res) => {
  //   const nextTabItem = JOB_TAB_MAPPING[key];
  //   if (nextTabItem && nextTabItem.dependOns) {
  //     const jobData = this.props.jobState?.data;
  //     const currentData = res?.data;
  //     for (const dependKey of nextTabItem.dependOns) {
  //       const dependItem = JOB_TAB_MAPPING[dependKey];
  //       if (dependItem && dependItem.rules) {
  //         for (const rule of dependItem.rules) {
  //           let isValid = rule.validate(jobData);
  //           if (!isValid && currentData) {
  //             isValid = rule.validate(currentData);
  //           }
  //           if (!isValid) {
  //             // this.handleShowErrorModal(rule.message || `Vui lòng hoàn thành ${dependItem.name} của công việc trước!`);
  //             // return;
  //           }
  //         }
  //       }
  //     }
  //   }
  //   this.setState({ activeKey: key });
  // };

  renderContent() {
    const steps = [
      {
        title: STRING.general_information,
        content: (
          <General
            setLoading={this.setLoading.bind(this)}
            // onNext={(res) => this._setActiveTab('context', res)}
            // shouldValidate={this.state.checks['general']?.isCheck} // shouldValidate = true => not validate
            setStep={(step, stepStatus) =>
              this.setState({
                ...this.state,
                currentStep: step,
                stepStatus: stepStatus,
              })
            }
            currentStep={this.state.currentStep}
            stepStatus={this.state.stepStatus}
            // check step 1 có áp dụng evoucher không   //áp dụng chương trình ưu đãi
            onChangeCheckEvoucher={value => {
              this.setState({
                ...this.state,
                isCheckEvoucher: value,
              });
            }}
            onChangeEvoucherWhenEdit={value => {
              this.setState({
                ...this.state,
                isCheckEvoucher: value?.isCheckEvoucher,
                idEvoucher: value?.idEvoucher,
              });
            }}
            onChangeIdEvoucher={value => {  //mã evoucher trong select được chọn
              this.setState({
                ...this.state,
                idEvoucher: value,
              });
            }}
            isCheckedMiniGame = {value => {
              this.setState({
                ...this.state,
                isShowTempplateMinigame: value,
              });
            
            }}
            selectedIdMiniGame = {value => {
              this.setState({
                ...this.state,
                mini_game_id: value,
              });
            }}
            //callback lưu bước 1
            onSaveStep={funcSave => (this.saveGeneral = funcSave)}
            onUpdateStep={funcSave => (this.updateGeneral = funcSave)}
          />
        ),
      },
      {
        title: STRING.the_script,
        content: (
          <Context
            setLoading={this.setLoading.bind(this)}
            // onNext={() => this._setActiveTab('formresult')}
            // shouldValidate={this.state.checks['context']?.isCheck} // shouldValidate = true => not validate
            setStep={(step, stepStatus) =>
              this.setState({
                ...this.state,
                currentStep: step,
                stepStatus: stepStatus,
              })
            }
            currentStep={this.state.currentStep}
            stepStatus={this.state.stepStatus}
            jobDetail={this.props.jobState}
            isCheckEvoucher={this.state.isCheckEvoucher}
            idEvoucher={this.state.idEvoucher}
            //callback lưu bước 2
            onSaveStep={funcSave => (this.saveContext = funcSave)}
          />
        ),
      },
      {
        title: STRING.result_card,
        content: (
          <FormQuestion
            setLoading={this.setLoading.bind(this)}
            // onNext={() => this._setActiveTab('education')}
            // shouldValidate={this.state.checks['formresult']?.isCheck} // shouldValidate = true => not validate
            setStep={(step, stepStatus) =>
              this.setState({
                ...this.state,
                currentStep: step,
                stepStatus: stepStatus,
              })
            }
            currentStep={this.state.currentStep}
            stepStatus={this.state.stepStatus}
            //callback lưu bước 3
            onSaveStep={funcSave => (this.saveFormQuestion = funcSave)}
          />
        ),
      },
      {
        title: STRING.educate,
        content: (
          <Education
            setLoading={this.setLoading.bind(this)}
            // onNext={() => this._setActiveTab('test')}
            // shouldValidate={this.state.checks['education']?.isCheck} // shouldValidate = true => not validate
            setStep={(step, stepStatus) =>
              this.setState({
                ...this.state,
                currentStep: step,
                stepStatus: stepStatus,
              })
            }
            currentStep={this.state.currentStep}
            stepStatus={this.state.stepStatus}
            //callback lưu bước 4
            onSaveStep={funcSave => (this.saveEducation = funcSave)}
          />
        ),
      },
      {
        title: STRING.exam,
        content: (
          <Exam
            setLoading={this.setLoading.bind(this)}
            // onNext={() => this.props.history.push(ROUTER.JOB)}
            // checks={this.state.checks}
            // shouldValidate={this.state.checks['test']?.isCheck} // shouldValidate = true => not validate
            setStep={(step, stepStatus) =>
              this.setState({
                ...this.state,
                currentStep: step,
                stepStatus: stepStatus,
              })
            }
            currentStep={this.state.currentStep}
            stepStatus={this.state.stepStatus}
            //callback lưu bước 5
            onSaveStep={funcSave => (this.saveExam = funcSave)}
          />
        ),
      },
    ];
   
    return (
      <>
        <Wrapper>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3>Nội dung</h3>
            <ButtonSystemStyle
              onClick={() => {
                const { jobState } = this.props;
                ModalAntd.confirm({
                  title: 'Xác nhận',
                  icon: <ExclamationCircleOutlined />,
                  content: 'Bạn có muốn lưu toàn bộ thông tin đã nhập?',
                  okText: 'Lưu',
                  cancelText: 'Hủy',
                  onOk: () => {
                    if (this.state.isCheckEvoucher && !this.state.idEvoucher) {
                      message.warning('Vui lòng chọn mã chương trình ưu đãi');
                      return;
                    }
                    if(!errorMinigame(this.state.isShowTempplateMinigame,this.state.mini_game_id )) {
                      return 
                    }
                    switch (parseInt(this.state.currentStep)) {
                      case 0:
                        if (jobState?.data?.job_id) {
                          this.updateGeneral('save');
                        } else {
                          this.saveGeneral('save');
                        }
                        break;

                      case 1:
                        this.saveContext('save');
                        break;

                      case 2:
                        this.saveFormQuestion('save');
                        break;

                      case 3:
                        this.saveEducation('save');
                        break;

                      case 4:
                        this.saveExam('save');
                        break;

                      default:
                        break;
                    }
                  },
                });
              }}
            >
              <DownloadOutlined style={{ marginRight: '10px' }} />
              Lưu tất cả
            </ButtonSystemStyle>
          </div>
          <Steps
            type="navigation"
            current={this.state.currentStep}
            onChange={current => {
              const { jobState } = this.props;
              if (this.state.isCheckEvoucher && !this.state.idEvoucher) {
                message.warning('Bạn chưa chọn mã chương trình ưu đãi');
                return;
              }
              if(!errorMinigame(this.state.isShowTempplateMinigame,this.state.mini_game_id )) {
                return 
              }
              switch (parseInt(this.state.currentStep)) {
                case 0:
                  if (jobState?.data?.job_id) {
                    this.updateGeneral(`changeStep:${current}`);
                  } else {
                    this.saveGeneral(`changeStep:${current}`);
                  }
                  break;

                case 1:
                  this.saveContext(`changeStep:${current}`);
                  break;

                case 2:
                  this.saveFormQuestion(`changeStep:${current}`);
                  break;

                case 3:
                  this.saveEducation(`changeStep:${current}`);
                  break;

                case 4:
                  this.saveExam(`changeStep:${current}`);
                  break;

                default:
                  break;
              }

              // const arr = this.state.stepStatus;
              // arr[this.state.currentStep] = 'finish';
              // arr[current] = 'process';
              // this.setState({
              //   ...this.state,
              //   currentStep: current,
              // });
            }}
          >
            {steps.map((step, index) => {
              return (
                <Step
                  key={index}
                  status={
                    this.state.stepStatus
                      ? this.state.stepStatus[index]
                      : 'wait'
                  }
                  title={step.title}
                />
              );
            })}
          </Steps>
        </Wrapper>

        <Wrapper>
          <div className="steps-content">
            {steps[this.state.currentStep]?.content}
          </div>
        </Wrapper>
      </>
    );
  }

  handleCloseErrorModal = () => {
    this.setState({ modalError: { shown: false } });
  };
  handleShowErrorModal = message => {
    this.setState({ modalError: { shown: true, message } });
  };
  renderErrorModal = () => {
    const { modalError } = this.state;
    return (
      <Modal show={modalError.shown} onHide={this.handleCloseErrorModal}>
        <Modal.Header closeButton>
          <Modal.Title>Thông báo</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{modalError.message}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleCloseErrorModal}>
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  render() {
    return (
      <>
        {this.state.isLoading ? <Loading /> : null}
        {this.renderBody()}
        {this.renderErrorModal()}
      </>
    );
  }
}

const selectCompleteState = (jobState = {}) => {
  const { data = {} } = jobState;
  return {
    general: data?.job_id && !!data.name,
    context: data?.job_id && data?.scenario?.scenario_steps?.length > 0,
    formresult: data?.job_id && data?.job_form_questions?.length > 0,
    requirement: data?.job_id && data?.job_config?.id,
    education: data?.job_id && data?.edu_infor,
    test: data?.job_id && data?.job_exam_questions?.length > 0,
  };
};
const mapStateToProps = state => ({
  userInfoState: state.userReducer,
  jobState: state.jobDetailReducer,
  completeState: selectCompleteState(state.jobDetailReducer),
});

const mapDispatchToProps = {
  clearJobDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
