import { VietnameseString } from '@constants/VietnameseString';

export const SENDING_ZNS_STATUS = {
  SUCCESS: 1,
  SEND: 0,
  CAN_NOT_SEND: -1,
};

export const SENDING_ZALO_STATUS = {
  INIT_MSG: 0,
  SENT: 1,
  CAN_NOT_SEND: 2,
};

const SENDING_ZNS_STATUS_DATA = {
  [SENDING_ZNS_STATUS.SUCCESS]: {
    label: VietnameseString.success,
    color: 'green',
  },
  [SENDING_ZNS_STATUS.SEND]: {
    label: VietnameseString.send,
    color: 'blue',
  },
  [SENDING_ZNS_STATUS.CAN_NOT_SEND]: {
    label: VietnameseString.can_not_send,
    color: 'red',
  },
};

const SENDING_ZALO_STATUS_DATA = {
  [SENDING_ZALO_STATUS.INIT_MSG]: {
    label: 'Chờ gửi',
    color: 'orange',
  },
  [SENDING_ZALO_STATUS.SENT]: {
    label: VietnameseString.send,
    color: 'green',
  },
  [SENDING_ZALO_STATUS.CAN_NOT_SEND]: {
    label: VietnameseString.can_not_send,
    color: 'red',
  },
};

export const getStatusZNSStyle = status => {
  let style;
  if (SENDING_ZNS_STATUS_DATA[status]) {
    style = SENDING_ZNS_STATUS_DATA[status];
  } else {
    style = {
      label: VietnameseString.unknown,
      color: 'black',
    };
  }

  return style;
};

export const getStatusZaloStyle = status => {
  let style;
  if (SENDING_ZALO_STATUS_DATA[status]) {
    style = SENDING_ZALO_STATUS_DATA[status];
  } else {
    style = {
      label: VietnameseString.unknown,
      color: 'black',
    };
  }

  return style;
};

export const SENDING_SMS_STATUS = {
  SEND: 1,
  CAN_NOT_SEND: 3,
};

const SENDING_STATUS_SMS_DATA = {
  [SENDING_SMS_STATUS.SEND]: {
    label: VietnameseString.send,
    color: 'blue',
  },
  [SENDING_SMS_STATUS.CAN_NOT_SEND]: {
    label: VietnameseString.can_not_send,
    color: 'red',
  },
};

export const getStatusSMSStyle = status => {
  let style;
  if (SENDING_STATUS_SMS_DATA[status]) {
    style = SENDING_STATUS_SMS_DATA[status];
  } else {
    style = {
      label: VietnameseString.unknown,
      color: 'black',
    };
  }

  return style;
};
