import { downloadFile } from '@screens/Enterprise/ElectronicVoucher/utils';
import { Button, Modal, Row, Space, Spin } from 'antd';
import React from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

const PreviewFile = ({
  fileCurrent,
  fileViewCurrent,
  fileNameCurrent,
  setFileCurrent,
  setFileViewCurrent,
  setFileNameCurrent,
}) => {
  return (
    <>
      <div
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Modal
          centered
          bodyStyle={{ height: '99vh', padding: '0 10px 0' }}
          width="100vw"
          visible={fileViewCurrent}
          onCancel={() => {
            setFileCurrent('');
            setFileViewCurrent(false);
          }}
          destroyOnClose
          footer={null}
          closable={false}
        >
          <>
            {fileCurrent && (
              <DocViewer
                config={{
                  header: {
                    disableHeader: true,
                    disableFileName: true,
                    retainURLParams: true,
                  },
                }}
                style={{ height: '93%' }}
                pluginRenderers={DocViewerRenderers}
                documents={[
                  {
                    uri: fileCurrent,
                  },
                ]}
              />
            )}

            <Row justify="center" style={{ height: '56px' }}>
              <Space>
                <Button
                  onClick={() => {
                    setFileViewCurrent(false);
                    setFileCurrent('');
                  }}
                >
                  Đóng
                </Button>
                {fileNameCurrent && (
                  <Button
                    type="primary"
                    onClick={() => {
                      downloadFile(fileCurrent, fileNameCurrent);
                      setFileCurrent('');
                      setFileViewCurrent(false);
                    }}
                  >
                    Tải xuống
                  </Button>
                )}
              </Space>
            </Row>
          </>
        </Modal>
      </div>
    </>
  );
};

export default React.memo(PreviewFile);
