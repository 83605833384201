import { requestGetAllJob } from '@constants/Api';
import {
  ColStyled,
  InputStyled,
} from '@screens/Enterprise/ElectronicVoucher/ElectronicVoucherStyled';
import { voucherService } from '@screens/Enterprise/ElectronicVoucher/service';
import { hooks } from '@screens/Enterprise/ElectronicVoucher/store';
import { jobService } from '@screens/Enterprise/Job/Add/Tabs/function.components/service';
import { requestSubJobFilter } from '@screens/Enterprise/Job/services/api';
import { Checkbox, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import React from 'react';
import useDebounce from 'src/hooks/useDebounce';

const GiftForReferrer = ({ preferentialType, form }) => {
  const [vouchers, setVouchers] = React.useState([]);
  const [parentJob, setParentJob] = React.useState([]);
  const [parentJobId, setParentJobId] = React.useState('');
  const [subJob, setSubJob] = React.useState([]);

  const [search, setSearch] = React.useState('');
  const debouncedSearchTerm = useDebounce(search, 300);
  const [loadingSearchVoucher, setLoadingSearchVoucher] = React.useState(false);

  const gift_for_referrer = Form.useWatch('gift_for_referrer', form);
  const gift_for_referrer_point = Form.useWatch(
    'gift_for_referrer_point',
    form
  );
  const gift_for_referrer_voucher = Form.useWatch(
    'gift_for_referrer_voucher',
    form
  );
  const recommend_parent_job_id = Form.useWatch(
    'recommend_parent_job_id',
    form
  );
  const recommend_job_id = Form.useWatch('recommend_job_id', form);

  const [state] = hooks.usePromotionsStore();

  React.useEffect(() => {
    const getAllJob = async () => {
      try {
        const { data } = await requestGetAllJob();
        setParentJob(data);
      } catch (error) {
        console.log('Error getAllJob: ', { error });
      }
    };
    getAllJob();
  }, []);

  React.useEffect(() => {
    const getAllSubJob = async function () {
      try {
        const { data } = await requestSubJobFilter({
          job_category_id: parentJobId,
          enterprise_id: '',
          telesale_id: '',
          limit: '',
        });
        setSubJob(data);
      } catch (error) {
        console.log('Error getAllSubJob: ', { error });
      }
    };
    getAllSubJob(parentJobId);
  }, [parentJobId]);

  React.useEffect(() => {
    setLoadingSearchVoucher(true);
    jobService
      .getEvoucher({
        limit: 12,
        search: debouncedSearchTerm,
      })
      .then(res => {
        if (
          res?.data?.some(item => {
            return (
              item?.id === state?.detail_evoucher?.recommend_gift?.voucher_id
            );
          }) ||
          !state?.detail_evoucher
        ) {
          setVouchers(res.data);
        } else {
          if (state?.detail_evoucher?.recommend_gift?.voucher_id) {
            voucherService
              .detailEVoucher(
                state?.detail_evoucher?.recommend_gift?.voucher_id
              )
              .then(({ data: stateLocation }) => {
                if (stateLocation) {
                  setVouchers([stateLocation, ...res.data]);
                }
              })
              .catch(() => {
                setVouchers([...res.data]);
              });
          } else {
            setVouchers([...res.data]);
          }
        }
      })
      .finally(() => {
        setLoadingSearchVoucher(false);
      });
  }, [debouncedSearchTerm, state?.detail_evoucher?.recommend_gift?.voucher_id]);

  return (
    <div className="gift-container">
      <Form.Item
        valuePropName="checked"
        style={{ marginBottom: 0 }}
        wrapperCol={{ span: 24 }}
        name="gift_for_referrer"
      >
        <Checkbox>Quà tặng cho người giới thiệu</Checkbox>
      </Form.Item>

      {gift_for_referrer ? (
        <div style={{ width: '100%' }} className="gift-options">
          <Row>
            <ColStyled span={24}>
              <Form.Item
                style={{ marginBottom: '0' }}
                valuePropName="checked"
                wrapperCol={{ span: 24 }}
                name="gift_for_referrer_point"
              >
                <Checkbox>Tặng điểm</Checkbox>
              </Form.Item>
            </ColStyled>

            <Col span={24}>
              {gift_for_referrer_point ? (
                <div className="gift-value">
                  <Row gutter={[32, 32]} align="middle">
                    <ColStyled span={6}>Số điểm</ColStyled>

                    <ColStyled span={18}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập số điểm',
                          },
                          {
                            validator: (_, value) => {
                              if (+value < 1) {
                                return Promise.reject(
                                  new Error('Số điểm phải lớn hơn 0!')
                                );
                              }

                              if (+value > 100000000000) {
                                return Promise.reject(
                                  new Error('Số điểm phải nhỏ hơn 100 tỷ!')
                                );
                              }

                              return Promise.resolve();
                            },
                          },
                        ]}
                        style={{ width: '100%' }}
                        wrapperCol={{ span: 24 }}
                        name="recommend_gift_point"
                        normalize={value =>
                          value?.toString()?.replace(/[^\wÀ-úÀ-ÿ]/g, '')
                        }
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          formatter={value =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }
                          parser={value =>
                            value ? value.replace(/\$\s?|(,*)|\./g, '') : ''
                          }
                          placeholder="Nhập số điểm"
                        />
                      </Form.Item>
                    </ColStyled>
                  </Row>
                </div>
              ) : null}
            </Col>
          </Row>

          <Row>
            <ColStyled span={24}>
              <Form.Item
                valuePropName="checked"
                wrapperCol={{ span: 24 }}
                name="gift_for_referrer_voucher"
              >
                <Checkbox>Chọn công việc</Checkbox>
              </Form.Item>
            </ColStyled>
            <Col span={24}>
              {gift_for_referrer_voucher ? (
                <div className="gift-value">
                  <Row gutter={[32, 32]} align="middle">
                    <ColStyled span={12}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng chọn công việc',
                          },
                        ]}
                        style={{ width: '100%' }}
                        wrapperCol={{ span: 24 }}
                        name="recommend_parent_job_id"
                      >
                        <Select
                          showSearch
                          allowClear
                          filterOption={(input, option) => {
                            return option.props.children
                              .trim()
                              .toLowerCase()
                              .includes(input.trim().toLowerCase());
                          }}
                          placeholder="Chọn công việc"
                          style={{ width: '100%' }}
                          loading={loadingSearchVoucher}
                          onSearch={value => setSearch(value)}
                          onClear={() => {
                            setSearch('');
                          }}
                          onChange={value => {
                            setParentJobId(value ? value : '');
                            form.resetFields(['recommend_job_id']);
                          }}
                        >
                          {parentJob.map(job => (
                            <Select.Option key={job.id} value={job?.id}>
                              {job?.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </ColStyled>
                    {recommend_parent_job_id && (
                      <ColStyled span={12}>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng chọn giao việc',
                            },
                          ]}
                          style={{ width: '100%' }}
                          wrapperCol={{ span: 24 }}
                          name="recommend_job_id"
                        >
                          <Select
                            showSearch
                            allowClear
                            filterOption={(input, option) => {
                              return option.props.children
                                .trim()
                                .toLowerCase()
                                .includes(input.trim().toLowerCase());
                            }}
                            placeholder="Chọn giao việc"
                            style={{ width: '100%' }}
                            loading={loadingSearchVoucher}
                            onSearch={value => setSearch(value)}
                            onClear={() => setSearch('')}
                          >
                            {subJob.map(job => (
                              <Select.Option key={job.id} value={job?.id}>
                                {job?.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </ColStyled>
                    )}
                  </Row>
                </div>
              ) : null}
            </Col>
          </Row>
        </div>
      ) : null}
      {/* qui đổi điểm */}
      {(preferentialType?.includes('accumulatePoints') ||
        gift_for_referrer_point) && (
        <div style={{ marginTop: '16px' }}>
          <Row align="middle" style={{ width: '100%' }}>
            <ColStyled span={6}>
              <span className="title-redeem-points">Quy đổi điểm</span>
            </ColStyled>
            <Col span={18}>
              <Row style={{ flexWrap: 'nowrap', width: '100%' }} align="middle">
                <Form.Item
                  rules={[
                    { required: true, message: 'Vui lòng nhập số điểm' },
                    {
                      validator: (_, value) => {
                        if (+value < 1) {
                          return Promise.reject(
                            new Error('Số điểm phải lớn hơn 0!')
                          );
                        }
                        if (+value > 100000000000) {
                          return Promise.reject(
                            new Error('Số điểm phải nhỏ hơn 100 tỷ!')
                          );
                        }

                        return Promise.resolve();
                      },
                    },
                  ]}
                  style={{ width: '100%' }}
                  name="start_point"
                  wrapperCol={{ span: 24 }}
                  normalize={value =>
                    value?.toString()?.replace(/[^\wÀ-úÀ-ÿ]/g, '')
                  }
                >
                  <InputNumber
                    style={{ width: '100%', textAlign: 'center' }}
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={value =>
                      value ? value.replace(/\$\s?|(,*)|\./g, '') : ''
                    }
                    placeholder="Nhập số điểm"
                    controls={false}
                  />
                </Form.Item>
                {/* <Input.Group className="input-group" compact> */}

                <span style={{ padding: '4px 11px' }}> = </span>
                <Form.Item
                  rules={[
                    { required: true, message: 'Vui lòng nhập số tiền' },
                    {
                      validator: (_, value) => {
                        if (+value < 1) {
                          return Promise.reject(
                            new Error('Số tiền phải lớn hơn 0!')
                          );
                        }

                        if (+value > 100000000000) {
                          return Promise.reject(
                            new Error('Số tiền phải nhỏ hơn 100 tỷ!')
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  style={{ width: '100%' }}
                  name="end_point"
                  wrapperCol={{ span: 24 }}
                  normalize={value =>
                    value?.toString()?.replace(/[^\wÀ-úÀ-ÿ]/g, '')
                  }
                >
                  <InputNumber
                    addonAfter="VNĐ"
                    style={{ width: '100%', textAlign: 'center' }}
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={value =>
                      value ? value.replace(/\$\s?|(,*)|\./g, '') : ''
                    }
                    placeholder="Nhập số tiền"
                    controls={false}
                  />
                </Form.Item>
              </Row>
              {/* </Input.Group> */}
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default GiftForReferrer;
