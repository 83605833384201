import Toast from '@components/Toast';
import { ConfigProvider } from 'antd';
import 'antd/dist/antd.css';
import viVN from 'antd/es/locale/vi_VN';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { createGlobalStyle } from 'styled-components';
import './App.css';
import AppNavigator from './navigation/AppNavigator';
import store from './redux/store';
import LoadingComponent from './modules/loading';
// eslint-disable-next-line no-global-assign
// console = reactotron;

const GlobalStyle = createGlobalStyle`
  *,html,body {
    font-family: 'Quicksand', sans-serif !important;
  }

`;

function App() {
  const pathname = window?.location?.pathname;
  const zaloPlatformSiteVerification =
    process.env.REACT_APP_ZALO_PLATFORM_SITE_VERIFICATION;

  const handleLoading = () => {
    const loader = document.querySelector('.loader--app');
    if (!loader) return;
    loader.classList.add('loader--hide');
  };

  React.useEffect(() => {
    window.addEventListener('load', handleLoading);
    return () => window.removeEventListener('load', handleLoading);
  }, []);

  return (
    <ConfigProvider locale={viVN}>
      <Provider store={store}>
        <Helmet>
          <meta
            name="zalo-platform-site-verification"
            content={zaloPlatformSiteVerification}
          />
        </Helmet>

        <AppNavigator />
        <Toast />
        {pathname === '/evoucher' && <GlobalStyle />}
      </Provider>
    </ConfigProvider>
  );
}

export default App;
