/**
 * @callback MakeCall
 * @param {string} destination
 * @param {string} callId
 */

/**
 * @typedef {object} StringeeCallClient
 * @property {MakeCall} makeCall
 * @property {HangupCall} hangupCall
 * @property {SendDTMF} sendDtmf
 */

/**
 * @class StringeeCallUtil
 * @property stringeeCallClient {StringeeCallClient}
 */
class StringeeCallUtil {
  /** @type {StringeeCallClient} */
  stringeeCallClient = null;
  // stringeeCall = null;
  // stringeeClient = null;
}

const StringeeUtil = new StringeeCallUtil();
// StringeeUtil.stringeeCallClient.hangupCall()
export default StringeeUtil;
