import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MapGL, { Popup } from '@goongmaps/goong-map-react';
import '@goongmaps/goong-js/dist/goong-js.css';
import styled from 'styled-components';

import { getAxios, handleResult } from '@constants/Api';
import MarkerGoongMapWeb from './MarkerGoongMapWeb';
import EventEmitter from '@utils/EventEmitter';

const requestGetConfig = () => {
  return handleResult(getAxios.get('config/configKey'));
};

const StyledUl = styled.ul`
  list-style-type: none;
  margin-bottom: 0px;

  li {
    font-size: 12px;
  }
`;

const StyledPopup = styled(Popup)`
  .mapboxgl-popup-content {
    padding: 10px;
  }
`;

function GoongMapWeb(props) {
  const { data } = props;

  const [viewport, setViewport] = useState({
    latitude: 21.021237,
    longitude: 105.791025,
    zoom: 15,
    bearing: 0,
    pitch: 0,
  });

  const [goongApiAccessToken, setGoongApiAccessToken] = useState('');

  const getConfig = async () => {
    try {
      const { data } = await requestGetConfig();

      setGoongApiAccessToken(data.GOONG_MAP_KEY);
    } catch (error) {
      console.log('Error getConfig:', { error });
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      setViewport({
        ...viewport,
        latitude: data[0].latitude,
        longitude: data[0].longitude,
      });
    }
  }, [data]);

  useEffect(() => {
    EventEmitter.addListener('show-detail-marker-in-goong-web', (data) => {
      const { longitude, latitude, location } = data;
      setViewport({
        ...viewport,
        latitude: Number(latitude),
        longitude: Number(longitude),
      });
      setPopupInfo({ longitude: Number(longitude), latitude: Number(latitude), location });
    });

    EventEmitter.addListener('hide-detail-marker-in-goong-web', (data) => {
      setPopupInfo(null);
    });

    getConfig();

    return () => {
      EventEmitter.removeAllListeners(['show-detail-marker-in-goong-web', 'hide-detail-marker-in-goong-web']);
    };
  }, []);

  const [popupInfo, setPopupInfo] = useState(null);

  return (
    <MapGL
      key={goongApiAccessToken}
      {...viewport}
      width="100%"
      height="100%"
      mapStyle="https://tiles.goong.io/assets/goong_map_web.json"
      onViewportChange={setViewport}
      goongApiAccessToken={goongApiAccessToken}
    >
      {data.map((pin, index) => (
        <MarkerGoongMapWeb
          key={`marker-${index}`}
          longitude={pin.longitude}
          latitude={pin.latitude}
          location={pin.location}
          onClick={setPopupInfo}
        />
      ))}

      {popupInfo && (
        <StyledPopup
          tipSize={5}
          anchor="top"
          longitude={popupInfo.longitude}
          latitude={popupInfo.latitude}
          closeOnClick={false}
          onClose={setPopupInfo}
        >
          <>
            {typeof popupInfo.location === 'string' ? (
              <div style={{ fontSize: '12px' }}>{popupInfo.location}</div>
            ) : (
              <StyledUl>
                <li style={{ fontWeight: 'bold' }}>{popupInfo.location.action}</li>
                {popupInfo.location.customerName && <li>Tên - SĐT khách hàng: {popupInfo.location.customerName}</li>}
                <li>Địa chỉ định vị: {popupInfo.location.addressOfThePlaceName}</li>
                <li>Khoảng cách: {popupInfo.location.distance}</li>
              </StyledUl>
            )}
          </>
        </StyledPopup>
      )}
    </MapGL>
  );
}

export default GoongMapWeb;

GoongMapWeb.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      longitude: PropTypes.number,
      latitude: PropTypes.number,
      location: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          action: PropTypes.string,
          customerName: PropTypes.string,
          distance: PropTypes.string,
        }),
      ]),
    })
  ),
};
