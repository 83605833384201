import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '@styles/Settings.css';
import FormResult from './Context/_Form';

import { Row, Col, FormControl, Modal, FormGroup, Nav, Table } from 'react-bootstrap';
import { getHotlineList, getReviewList, getTypeJob, getTelesaleList } from '@src/redux/actions';
import { connect } from 'react-redux';
import {
  requestListTag,
  requestCreateTag,
  requestUpdateTag,
  requestDeleteTag,
  requestCreateReview,
  requestUpdateReview,
  requestDeleteReview,
  requestCreateTypeJob,
  requestDeleteTypeJob,
  requestUpdateTypeJob,
  requestPostConfigKpi,
  requestGetConfigKpi,
  requestTimeUpdate,
  requestGetTimeUpdate,
  requestGetCustomerPhoneVisible,
  requestChangeCustomerPhoneVisible,
  requestGetKpiNotifications,
  requestCreateKpiNotification,
  requestUpdateKpiNotification,
  requestDeleteKpiNotification,
} from '@constants/Api';
import { notifyFail, notifySuccess } from '@utils/notify';
import { checkAllAttrFilled } from '@utils/checkAllAttrFilled';
import { STRING } from '@constants/Constant';
import Pagination from 'react-js-pagination';
import GeneralQuestion from './GeneralQuestion';
import Hotline from './Hotline';
import { JOB_LINK } from '@constants/Constant';

import Keyword from './Keyword';
import Vpoint from './Vpoint';
import Checkin from './Checkin';
import reactotron from 'reactotron-react-js';
import Loading from '@src/components/Loading';

import { Empty, Input, Space, Upload, Button, TimePicker, Select, Typography } from 'antd';

import { UploadOutlined } from '@ant-design/icons';

const { Option } = Select;
const { TextArea } = Input;
const { Text, Link } = Typography;

let timeout;

function FormError(props) {
  if (props.isHidden) {
    return null;
  }

  return <div style={{ color: 'red', width: '100%', textAlign: 'center' }}>{props.errorMessage}</div>;
}

const workingDays = [
  { value: 1, label: 'T2' },
  { value: 2, label: 'T3' },
  { value: 3, label: 'T4' },
  { value: 4, label: 'T5' },
  { value: 5, label: 'T6' },
  { value: 6, label: 'T7' },
];

const userRoles = [
  { value: 1, label: 'Admin' }, // Admin
  // { value: 2, label: 'Công ty/Doanh nghiệp' }, // ENTERPRISE
  // { value: 3, label: 'Quản lý' }, // PROFESSIONAL_MANAGER
  // { value: 4, label: 'Quản lý nhân sự' }, // HR_MANAGER
  { value: 5, label: 'Sale' }, // Sale
  // { value: 6, label: 'Nhân viên đánh giá' }, // REVIEWER
  // { value: 7, label: 'Quản lý sale' }, // TELESALE_MANAGER
  { value: 8, label: 'Trưởng phòng ban' }, // LEADER
];

class JobSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalAddReview: false,
      modalAddVoice: false,
      modalEditReview: false,
      modalAddVoiceField: {
        step: '',
        name: '',
        content: '',
      },
      modalAddReviewField: {
        name: '',
        point: '',
      },
      modalEditReviewField: {
        id: '',
        name: '',
        point: '',
      },
      modalAddType: false,
      modalEditType: false,

      modalAddTag: false,
      modalEditTag: false,
      modalAddTagField: {
        name: '',
        color: '',
      },
      modalEditTagField: {
        id: '',
        name: '',
        color: '',
      },
      modalAddTypeField: {
        name: '',
      },
      modalEditTypeField: {
        id: '',
        name: '',
      },
      currentIndex: 0,
      id: '',
      confirmModal: false,
      confirmModalReview: false,
      confirmModalTag: false,
      confirmModalType: false,
      tableInfo: true,
      activePage: 1,
      isLoading: false,
      query: '',
      tagSearch: '',
      tag: '',
      page: '',
      value: '',
      timeUpdate: '',
      disable: false,
      voiceList: [],
      tagList: [],
      shouldUploadVideo: 1,
      customerPhoneVisible: true,
      // --------------
      modalKpiNotificationVisible: false,
      kpiNotificationList: [],
      shouldAddKpiNotification: true,
      kpiNotificationModal: {
        workingDays: [],
        userRole: null,
        time: '',
        content: '',
        status: null,
      },
      contentButtons: [
        {
          id: 1,
          isActive: false,
          label: 'Tên sale',
          text: '[Tên sale]',
        },
        {
          id: 2,
          isActive: false,
          label: 'Số cuộc gọi',
          text: '[Số cuộc gọi]',
        },
        {
          id: 3,
          isActive: false,
          label: 'Số cơ hội',
          text: '[Số cơ hội]',
        },
        {
          id: 4,
          isActive: false,
          label: 'Số khách hàng mới',
          text: '[Số khách hàng mới]',
        },
        {
          id: 5,
          isActive: false,
          label: 'Doanh thu',
          text: '[Doanh thu]',
        },
        {
          id: 6,
          isActive: false,
          label: 'Thời gian',
          text: '[Thời gian]',
        },
      ],
    };
  }

  componentDidMount() {
    this.props.getReviewList();
    this.props.getTelesaleList();
    this.getData({ page: 1 });
    this.getDetail();
    this.getTimeUpdate();
    this.getTagList();
    this.getCustomerPhoneVisible();
  }

  async getCustomerPhoneVisible() {
    try {
      this.setState({ isLoading: true });
      const res = await requestGetCustomerPhoneVisible();
      this.setState({
        ...this.state,
        isLoading: false,
        customerPhoneVisible: parseInt(res.data.value) === 0 ? false : true,
      });
    } catch (error) {}
  }

  async changeCustomerPhoneVisible(bool) {
    try {
      await requestChangeCustomerPhoneVisible({ value: bool ? 1 : 0 });
      this.getCustomerPhoneVisible();
      notifySuccess('Thay đổi thành công!');
    } catch (error) {}
  }

  async getTagList() {
    const { tagSearch } = this.state;
    try {
      const res = await requestListTag({
        search: this.state.tagSearch.trim(),
        page: 1,
      });
      this.setState({
        ...this.state,
        tagList: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  }
  async addTag() {
    try {
      await requestCreateTag({
        name: this.state.modalAddTagField.name,
        color: this.state.modalAddTagField.color || '#000000',
      });
      this.getTagList();
    } catch (error) {}
  }

  getData({ page, query }) {
    this.props.getTypeJob({
      page: page || '',
      query: query || '',
    });
  }

  async submitKpi() {
    const { value, timeUpdate } = this.state;
    // const check = await this.validateFormAdd();
    const { id } = this.props.match.params;
    // if (check) {
    const payload = {
      value: value,
    };

    try {
      const res = await requestPostConfigKpi(payload);
      const time = await requestTimeUpdate({ value: timeUpdate });
      notifySuccess('Cấu hình thành công');
    } catch (err) {
      this.setState({ isLoading: false });
      notifyFail(err.msg);
    }
    // }
    return;
  }

  async getTimeUpdate() {
    try {
      const res = await requestGetTimeUpdate();
      this.setState({ ...this.state, timeUpdate: res.data?.value });
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  async getDetail() {
    this.setState({ isLoading: true });
    const res = await requestGetConfigKpi();
    const data = res.data || {};
    await this.setState({
      value: data?.value,
      isLoading: false,
      file: data.user_enterprise_info?.profile_image || '',
    });
  }

  handleChange(fieldName, value) {
    this.setState({
      ...this.state,
      [fieldName]: value || '',
    });
  }

  setShowModal = (fieldName, value) => {
    this.setState({
      ...this.state,
      [fieldName]: value || '',
    });
  };

  validateFormAddReview() {
    const { modalAddReviewField } = this.state;
    if (!checkAllAttrFilled(this.state.modalAddReviewField)) {
      return false;
    }
    return true;
  }

  getResetAddReviewField() {
    return {
      name: '',
      point: '',
    };
  }

  async createReview() {
    const { modalAddReviewField } = this.state;
    const check = await this.validateFormAddReview();
    if (check) {
      const payload = {
        name: modalAddReviewField.name.trim(),
        point: modalAddReviewField.point,
      };
      if (modalAddReviewField.point >= 0) {
        this.setState(
          {
            ...this.state,
            isLoading: false,
          },
          () => notifyFail('Điểm phải âm')
        );
        return;
      }

      try {
        const res = await requestCreateReview(payload);

        this.props.getReviewList();

        this.setState((prevState) => ({
          modalAddReviewField: {
            ...this.getResetAddReviewField(),
          },
        }));

        this.setState({ isLoading: false });
        this.showModal('modalAddReview', false);
        notifySuccess('Đánh giá thành công');
      } catch (err) {
        this.setState({ isLoading: false });
        notifyFail(err.msg);
      }
    }
    return;
  }

  async createReviewNoCloseModal() {
    const { modalAddReviewField } = this.state;
    const check = await this.validateFormAddReview();
    if (check) {
      const payload = {
        name: modalAddReviewField.name.trim(),
        point: modalAddReviewField.point,
      };
      if (modalAddReviewField.point >= 0) {
        this.setState(
          {
            ...this.state,
            isLoading: false,
          },
          () => notifyFail('Điểm phải âm')
        );
        return;
      }

      try {
        const res = await requestCreateReview(payload);

        this.props.getReviewList();

        this.setState((prevState) => ({
          modalAddReviewField: {
            ...this.getResetAddReviewField(),
          },
        }));

        this.setState({ isLoading: false });
        // this.showModal('modalAddReview', false);
        notifySuccess('Đánh giá thành công');
      } catch (err) {
        this.setState({ isLoading: false });
        notifyFail(err.msg);
      }
    }
    // return;
  }

  validateFormAddType() {
    const { modalAddTypeField } = this.state;
    if (!checkAllAttrFilled(this.state.modalAddTypeField)) {
      return false;
    }
    return true;
  }

  getResetAddTypeField() {
    return {
      name: '',
    };
  }

  async createType() {
    const { modalAddTypeField } = this.state;
    const check = await this.validateFormAddType();
    if (check) {
      this.setState({
        ...this.state,
        disable: true,
      });
      const payload = {
        name: modalAddTypeField.name.trim(),
      };

      try {
        const res = await requestCreateTypeJob(payload);

        this.props.getTypeJob();

        this.setState((prevState) => ({
          modalAddTypeField: {
            ...this.getResetAddTypeField(),
          },
          disable: true,
        }));

        this.showModalType('modalAddType', false);
        this.setState({ isLoading: false });
        notifySuccess('Thêm mới thành công');
      } catch (err) {
        this.setState({ isLoading: false, disable: false });
        notifyFail(err.msg);
      }
    }
    return;
  }

  validateFormEditReview() {
    const { modalEditReviewField } = this.state;
    if (!checkAllAttrFilled(this.state.modalEditReviewField)) {
      return false;
    }
    return true;
  }

  async updateReview() {
    const { modalEditReviewField, currentIndex } = this.state;
    const check = await this.validateFormEditReview();
    if (check) {
      this.setState({
        isLoading: true,
      });
      if (modalEditReviewField.point >= 0) {
        this.setState(
          {
            ...this.state,
            isLoading: false,
          },
          () => notifyFail('Điểm phải âm')
        );
        return;
      }
      try {
        const payload = {
          name: modalEditReviewField.name,
          point: modalEditReviewField.point,
          id: currentIndex,
        };
        await requestUpdateReview(payload);

        this.setState({ modalEditReview: false, isLoading: false }, () => {
          this.props.getReviewList();
          notifySuccess('Cập nhật thông tin thành công!');
        });
      } catch (err) {
        this.setState(
          {
            modalEditReview: false,
            isLoading: false,
          },
          () => notifyFail(err.msg)
        );
      }
    }
  }

  validateFormEditType() {
    const { modalEditTypeField } = this.state;
    if (!checkAllAttrFilled(this.state.modalEditTypeField)) {
      return false;
    }
    return true;
  }

  async updateTag() {
    const { modalEditTagField, currentIndex } = this.state;
    // const check = await this.validateFormEditReview();
    // if (check) {
    //   this.setState({
    //     isLoading: true,
    //   });
    // if (modalEditTagField.point >= 0) {
    //   this.setState(
    //     {
    //       ...this.state,
    //       isLoading: false,
    //     },
    //     () => notifyFail('Điểm phải âm')
    //   );
    //   return;
    // }
    try {
      const payload = {
        name: modalEditTagField.name,
        color: modalEditTagField.color || '#000000',
        id: currentIndex,
      };
      await requestUpdateTag(payload);

      this.setState({ modalEditTag: false, isLoading: false }, () => {
        this.getTagList();
        notifySuccess('Cập nhật thông tin thành công!');
      });
    } catch (err) {
      this.setState(
        {
          modalEditTag: false,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async updateType() {
    const { modalEditTypeField, currentIndex } = this.state;
    const check = await this.validateFormEditType();
    if (check) {
      this.setState({
        isLoading: true,
      });

      try {
        const payload = {
          name: modalEditTypeField.name.trim(),
          point: modalEditTypeField.point.trim(),
          id: currentIndex,
        };
        await requestUpdateTypeJob(payload);

        this.setState({ modalEditType: false, isLoading: false }, () => {
          this.props.getTypeJob();
          notifySuccess('Cập nhật thông tin thành công!');
        });
      } catch (err) {
        this.setState(
          {
            modalEditType: false,
            isLoading: false,
          },
          () => notifyFail(err.msg)
        );
      }
    }
  }

  async deleteReview() {
    this.setState({
      isLoading: true,
    });
    try {
      await requestDeleteReview({
        id: this.state.id,
      });
      this.setState({ confirmModalReview: false, isLoading: false }, () => {
        this.props.getReviewList();
        notifySuccess('Xóa thành công!');
      });
      // this.props.deleteStaffAction(this.state.userId);
    } catch (err) {
      this.setState(
        {
          confirmModalReview: false,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async deleteTag() {
    this.setState({
      isLoading: true,
    });
    try {
      await requestDeleteTag({
        id: this.state.id,
      });
      this.setState({ confirmModalTag: false, isLoading: false }, () => {
        this.getTagList();
        notifySuccess('Xóa thành công!');
      });
      // this.props.deleteStaffAction(this.state.userId);
    } catch (err) {
      this.setState(
        {
          confirmModalTag: false,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async deleteType() {
    this.setState({
      isLoading: true,
    });
    try {
      await requestDeleteTypeJob({
        id: this.state.id,
      });
      this.setState({ confirmModalType: false, isLoading: false }, () => {
        this.props.getTypeJob();
        notifySuccess('Xóa thành công!');
      });
      // this.props.deleteStaffAction(this.state.userId);
    } catch (err) {
      this.setState(
        {
          confirmModalType: false,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  renderWorkInfo() {
    const { tableInfo } = this.state;
    return (
      <>
        <Row className="content-header table-tabs pl-0 pb-0">
          <Col md={12}>
            <Nav variant="tabs" defaultActiveKey="question">
              {/* <Nav.Item>
                <Nav.Link eventKey="review" onSelect={() => this.showTableInfo(1)} className="tab-item">
                  Thông số hệ thống
                </Nav.Link>
              </Nav.Item> */}

              {/* <Nav.Item>
                <Nav.Link eventKey="voice" onSelect={() => this.showTableInfo(2)} className="tab-item">
                  Đăng ký thử giọng
                </Nav.Link>
              </Nav.Item> */}

              <Nav.Item>
                <Nav.Link eventKey="question" onSelect={() => this.showTableInfo(1)} className="tab-item">
                  Câu hỏi chung
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="type-job" onSelect={() => this.showTableInfo(2)} className="tab-item">
                  Loại công việc
                </Nav.Link>
              </Nav.Item>

              {/* <Nav.Item>
                <Nav.Link eventKey="hotline" onSelect={() => this.showTableInfo(5)} className="tab-item">
                  Hotline
                </Nav.Link>
              </Nav.Item> */}

              <Nav.Item>
                <Nav.Link eventKey="keyWord" onSelect={() => this.showTableInfo(3)} className="tab-item">
                  {STRING.key_word}
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="vPoint" onSelect={() => this.showTableInfo(4)} className="tab-item">
                  {STRING.to_Vpoint}
                </Nav.Link>
              </Nav.Item>

              {/* <Nav.Item>
                <Nav.Link eventKey="checkin" onSelect={() => this.showTableInfo(8)} className="tab-item">
                  {STRING.checkin}
                </Nav.Link>
              </Nav.Item> */}

              {/* <Nav.Item>
                <Nav.Link eventKey="tag" onSelect={() => this.showTableInfo(9)} className="tab-item">
                  Thẻ tag
                </Nav.Link>
              </Nav.Item> */}
            </Nav>
          </Col>
        </Row>
        <Row className="bg-white">
          <Col md={12} className="bg-white pt-4" style={{ minHeight: '700px' }}>
            {tableInfo == 1 ? (
              this.renderQuestion()
            ) : tableInfo == 2 ? (
              this.renderTypeJob()
            ) : tableInfo == 3 ? (
              this.renderKeyWord()
            ) : tableInfo == 4 ? (
              this.renderVPoint()
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </>
    );
  }

  showTableInfo(status) {
    this.setState({
      ...this.state,
      tableInfo: status,
    });
  }

  renderBody() {
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
            <Col md className="bg">
              <Row>
                <h2>Cấu hình công việc</h2>
              </Row>
              <div class="line"></div>
              {this.renderWorkInfo()}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderTag() {
    const { tagSearch } = this.state;
    return (
      <>
        <Col sm={12} md={12}>
          <Row>
            <Col sm={12} className="d-flex justify-content-between">
              <h5>Thẻ tag</h5>
              <Button
                variant="success"
                style={{ background: 'green' }}
                className="text-light"
                onClick={() => this.showModal('modalAddTag', true)}
              >
                Thêm mới
              </Button>
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={12}>
          <Row>
            <Col md={6} sm={12}>
              <FormControl
                type="text"
                id=""
                placeholder="Tên thẻ"
                value={tagSearch}
                onChange={(e) => this.setState({ ...this.state, tagSearch: e.target.value }, () => this.getTagList())}
                // onKeyPress={(e) => this.handleKeyPress(e)}
              />
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={12}>
          <div className="row">
            <Col
              sm={10}
              md={12}
              style={{
                padding: '0px',
                marginTop: '20px',
              }}
            >
              <Row className="table-wrap mx-4">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>STT</th>
                      <th>Tên</th>
                      <th>Màu</th>
                      <th>Ngày tạo</th>
                      <th></th>
                    </tr>
                  </thead>
                  {this.renderTableTag()}
                </Table>
              </Row>
              <Row>
                <Col md={12} className="d-flex justify-content-center justify-content-md-end">
                  {this.renderPagination()}
                </Col>
              </Row>
            </Col>
          </div>
        </Col>
      </>
    );
  }

  renderTableTag() {
    const { tagList, confirmModalType } = this.state;
    return (
      <tbody>
        {tagList.length > 0 ? (
          tagList?.map((value, index) => (
            <tr>
              <td>{index + 1 || <p style={{ color: 'grey' }}>Chưa cập nhật</p>}</td>
              <td>{value.name || <p style={{ color: 'grey' }}>Chưa cập nhật</p>}</td>
              <td>
                {value.color ? (
                  <div style={{ backgroundColor: value.color, width: '100%', height: '20px' }}></div>
                ) : (
                  <p style={{ color: 'grey' }}>Chưa cập nhật</p>
                )}
              </td>
              <td>
                {Intl.DateTimeFormat('vi-VN').format(new Date(value.created_at)) || (
                  <p style={{ color: 'grey' }}>Chưa cập nhật</p>
                )}
              </td>
              <td>
                <div className="d-flex justify-content-center align-items-center">
                  <i
                    className="far fa-edit step-icon-edit mr-2"
                    onClick={() => {
                      this.showModalType('modalEditTag', true);
                      this.setState({
                        modalEditTagField: {
                          name: value.name,
                          color: value.color,
                        },
                        currentIndex: value.id,
                      });
                    }}
                  ></i>
                  <i
                    className="far fa-trash-alt step-icon-del"
                    onClick={() => {
                      this.setState({
                        id: value.id,
                        confirmModalTag: true,
                      });
                    }}
                  ></i>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <td colSpan="4">
            <Empty description="Không có dữ liệu"></Empty>
          </td>
        )}
      </tbody>
    );
  }
  modalAddTag() {
    const { modalAddTag, modalAddTagField, tag } = this.state;
    return (
      <Modal
        show={modalAddTag}
        onHide={() => this.showModal('modalAddTag', false)}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton className="bg-primary">
          <h5 className="text-light pb-0">Thêm thẻ tag</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          {/* {this.checkValidationReview()} */}
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Tên thẻ</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="text"
                max={0}
                placeholder="Tên thẻ"
                value={modalAddTagField.name}
                onChange={(e) => this.addTagFieldChange('name', e.target.value)}
              ></FormControl>
            </Row>
          </FormGroup>
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label for="colorpicker">Màu</label>
              <input
                type="color"
                id="colorpicker"
                value={modalAddTagField.color}
                onChange={(e) => {
                  // this.addTagFieldChangmodalAddTagFielde('color', e.target.value);
                  this.setState({
                    ...this.state,
                    modalAddTagField: {
                      ...this.state.modalAddTagField,
                      color: e.target.value,
                    },
                  });
                }}
              ></input>
            </Row>
            {/* <Row className="modal-row">
              <FormControl
                type="text"
                max={0}
                placeholder="Màu"
                value={modalAddTagField.color}
                onChange={(e) => this.addTagFieldChange('color', e.target.value)}
              ></FormControl>
            </Row> */}
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              if (!modalAddTagField.name) {
                notifyFail('Vui lòng nhập tên thẻ!');
                return;
              }
              this.addTag();
              let newArr = [...tag];
              newArr.push(modalAddTagField);
              this.setState({
                ...this.state,
                modalAddTagField: {
                  name: '',
                  color: '',
                },
                tag: [...newArr],
              });
              this.showModal('modalAddTag', false);
            }}
            className="text-light"
            style={{ background: 'green' }}
          >
            Lưu
          </Button>
          <Button
            style={{ background: 'red' }}
            variant="danger"
            onClick={() => this.showModal('modalAddTag', false)}
            className="text-light"
          >
            Thoát
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  editModalTag = () => {
    const { modalEditTag, modalEditTagField, currentIndex } = this.state;
    return (
      <Modal
        show={modalEditTag}
        onHide={() => this.showModal('modalEditTag', false)}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton className="bg-primary">
          <h5 className="text-light pb-0">Cập nhật tag</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          {/* {this.checkValidationEditReview()} */}
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Tên thẻ</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="text"
                placeholder="Nhập nội dung"
                value={modalEditTagField.name}
                onChange={(e) => this.editTagFieldChange('name', e.target.value)}
              ></FormControl>
            </Row>
          </FormGroup>
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Màu</label>
              <input
                type="color"
                id="colorpicker"
                value={modalEditTagField.color}
                onChange={(e) => {
                  // this.addTagFieldChangmodalAddTagFielde('color', e.target.value);
                  this.setState({
                    ...this.state,
                    modalEditTagField: {
                      ...this.state.modalEditTagField,
                      color: e.target.value,
                    },
                  });
                }}
              ></input>
            </Row>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="success"
            style={{ background: 'green' }}
            // className="header-btn btn-oversize"
            // name="empty"
            onClick={() => {
              if (!modalEditTagField.name) {
                notifyFail('Vui lòng nhập tên thẻ!');
                return;
              }
              this.updateTag();
            }}
            className="text-light"
          >
            Lưu
          </Button>
          <Button
            style={{ background: 'red' }}
            variant="danger"
            // className="header-btn btn-oversize"
            onClick={() => this.showModal('modalEditTag', false)}
            className="text-light"
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  editModalReview = () => {
    const { modalEditReview, modalEditReviewField, currentIndex } = this.state;
    return (
      <Modal
        show={modalEditReview}
        onHide={() => this.showModal('modalEditReview', false)}
        dialogClassName="modal-90w"
        centered
        scrollable
      >
        <Modal.Header closeButton className="bg-primary">
          <h5 className="text-light pb-0">Cập nhật đánh giá</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          {this.checkValidationEditReview()}
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Nội dung</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="text"
                placeholder="Nhập nội dung"
                value={modalEditReviewField.name}
                onChange={(e) => this.editReviewFieldChange('name', e.target.value)}
              ></FormControl>
            </Row>
          </FormGroup>
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Điểm</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="number"
                max={0}
                placeholder="Nhập điểm"
                value={modalEditReviewField.point}
                onChange={(e) => this.editReviewFieldChange('point', e.target.value)}
              ></FormControl>
            </Row>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="success"
            style={{ background: 'green' }}
            // className="header-btn btn-oversize"
            // name="empty"
            onClick={() => this.updateReview()}
            className="text-light"
          >
            Lưu
          </Button>
          <Button
            style={{ background: 'red' }}
            variant="danger"
            // className="header-btn btn-oversize"
            onClick={() => this.showModal('modalEditReview', false)}
            className="text-light"
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderVoice() {
    const { voiceList } = this.state;
    const item = {
      id: 26,
      is_require: 1,
      name: 'Khách hàng có nhu cầu khiếu nại hay bảo hành không?',
      reward_point: 5,
      type_input: 3,
    };

    return (
      <>
        <Col sm={12} md={12}>
          <Row>
            <Col sm={12} className="d-flex justify-content-between">
              <h5>Đăng ký thử giọng:</h5>
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={12}>
          <Row>
            <Col md={6}>
              <FormControl
                as="select"
                id=""
                style={{ marginBottom: '20px' }}
                // value={status}
                onChange={(e) => {
                  this.setState({
                    ...this.state,
                    shouldUploadVideo: e.target.value,
                  });
                }}
              >
                {JOB_LINK.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </FormControl>
            </Col>
            {parseInt(this.state.shouldUploadVideo) === 1 ? (
              <Col md={12} sm={12}>
                <FormControl type="text" id="" placeholder="Link youtube" style={{ marginBottom: '30px' }} />
              </Col>
            ) : parseInt(this.state.shouldUploadVideo) === 2 ? (
              <Col md={12} sm={12}>
                <Upload>
                  <Button icon={<UploadOutlined />}>Đăng video</Button>
                </Upload>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Col>
        <Col sm={12} md={12}>
          <Row>
            <Col sm={12} className="d-flex justify-content-between">
              <h5 style={{ marginTop: '20px' }}>Kịch bản thử giọng:</h5>
              <i
                className="fas fa-plus text-green add-btn"
                style={{ marginTop: '20px' }}
                onClick={() => this.showModal('modalAddVoice', true)}
              ></i>
            </Col>
          </Row>
        </Col>
        {voiceList.length ? (
          voiceList.map((item, index) => {
            return (
              <div
                style={{
                  padding: '30px 20px',
                  backgroundColor: '#ffffff',
                  borderRadius: '12px',
                  boxShadow: '0 20px 80px rgba(0, 0, 0, 0.2)',
                  marginTop: '10px',
                }}
              >
                <Row>
                  <Col sm={12}>
                    <Row>
                      <Col sm={2} style={{ color: 'red', paddingRight: 0 }}>
                        Bước {item.step}:
                      </Col>
                      <Col sm={7} style={{ paddingLeft: 0 }}>
                        {item.name}
                      </Col>
                      <Col sm={3} style={{ color: 'red' }}>
                        <div className="float-right">
                          <i
                            className="far fa-edit step-icon-edit mr-2"
                            onClick={() => {
                              console.log('update');
                            }}
                          ></i>
                          <i
                            className="far fa-trash-alt step-icon-del"
                            onClick={() => {
                              console.log('remove');
                            }}
                          ></i>
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <Col sm={12}>
                    <Row>
                      <Col sm={12}>{item.content}</Col>
                    </Row>
                  </Col>
                </Row>
                {/* <FormResult
                  form={{ ...item }}
                  // deleteForm={this.deleteForm.bind(this)}
                  // showSubModal={this.showSubModal.bind(this)}
                  // showEditModal={this.showEditModal.bind(this)}
                  key={index}
                  index={index}
                /> */}
              </div>
            );
          })
        ) : (
          <></>
        )}
      </>
    );
  }

  renderReview() {
    const { value, timeUpdate } = this.state;
    return (
      <div className="row" style={{ justifyContent: 'space-around' }}>
        <Col
          sm={10}
          md={5}
          style={{
            padding: '30px 20px',
            backgroundColor: '#ffffff',
            borderRadius: '12px',
            boxShadow: '0 20px 80px rgba(0, 0, 0, 0.2)',
            marginTop: '10px',
          }}
        >
          <Row className="mt-3 mx-2">
            <Col sm={12} className="d-flex justify-content-between">
              <h5>Đánh giá chất lượng cuộc gọi</h5>
              <i className="fas fa-plus text-green add-btn" onClick={() => this.showModal('modalAddReview', true)}></i>
            </Col>
          </Row>
          <Row className="table-wrap mx-4">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Nội dung</th>
                  <th>Điểm</th>
                  <th></th>
                </tr>
              </thead>
              {this.renderTableReview()}
            </Table>
          </Row>
        </Col>
        <Col
          sm={10}
          md={5}
          style={{
            padding: '30px 20px',
            backgroundColor: '#ffffff',
            borderRadius: '12px',
            boxShadow: '0 20px 80px rgba(0, 0, 0, 0.2)',
            marginTop: '10px',
          }}
        >
          <Row className="mt-3 mx-2">
            <Col md={12} sm={12} className="d-flex justify-content-between">
              <h5>Thông số hệ thống</h5>
            </Col>
          </Row>
          <Row className="table-wrap mx-4">
            <Col md={12} sm={12}>
              <Row className="mb-3">
                <Col md={5}>Thời gian cập nhật kpi</Col>
                <Col md={4}>
                  <FormControl
                    type="number"
                    min={0}
                    max={31}
                    id=""
                    value={value}
                    onChange={(e) => this.handleInputChange('value', e.target.value)}
                  />
                </Col>
                <Col md={2}>ngày</Col>
              </Row>

              <Row className="mb-3">
                <Col md={5}>Thời gian cập nhật cuộc gọi</Col>
                <Col md={4}>
                  <FormControl
                    type="number"
                    min={0}
                    max={31}
                    id=""
                    value={timeUpdate}
                    onChange={(e) => this.handleInputChange('timeUpdate', e.target.value)}
                  />
                </Col>
                <Col md={2}>phút</Col>
              </Row>

              <Row className="mb-3">
                <Col md={5}>Ẩn số điện thoại khách hàng</Col>
                <Col md={4}>
                  <FormControl
                    type="checkbox"
                    style={{ width: '20px', height: '20px' }}
                    checked={this.state.customerPhoneVisible}
                    onChange={() => this.changeCustomerPhoneVisible(!this.state.customerPhoneVisible)}
                  />
                </Col>
              </Row>

              {/* <Row className="mb-3">
                <Col md={5}>Thời gian cấu hình KPI</Col>
                <Col md={4}>
                  <FormControl
                    type="number"
                    id=""
                    value={value}
                    onChange={(e) => this.handleInputChange('value', e.target.value)}
                  />
                </Col>
                <Col md={2}>ngày</Col>
              </Row> */}
              <div className="text-center text-md-center">
                <Button
                  variant="primary"
                  style={{ background: 'green' }}
                  className="text-light"
                  onClick={() => this.submitKpi()}
                >
                  Lưu
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
        <Col
          md={11}
          style={{
            padding: '30px 20px',
            backgroundColor: '#ffffff',
            borderRadius: '12px',
            boxShadow: '0 20px 80px rgba(0, 0, 0, 0.2)',
            marginTop: '10px',
          }}
        >
          <Row className="mt-3 mx-2">
            <Col sm={12} className="d-flex justify-content-between">
              <h5>Cấu hình thông báo Kpi</h5>
              <i
                className="fas fa-plus text-green add-btn"
                onClick={() => {
                  this.setState({
                    modalKpiNotificationVisible: true,
                    shouldAddKpiNotification: true,
                  });
                }}
              ></i>
            </Col>
          </Row>
          <Row className="table-wrap mx-4">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Ngày thông báo</th>
                  <th>Loại tài khoản gửi</th>
                  <th>Thời gian gửi</th>
                  <th>Trạng thái</th>
                  <th></th>
                </tr>
              </thead>
              {this.renderTableReviewKPI()}
            </Table>
          </Row>
        </Col>
      </div>
    );
  }

  modalKpiNotification() {
    const { modalKpiNotificationVisible, shouldAddKpiNotification, contentButtons, kpiNotificationModal } = this.state;

    return (
      <Modal
        show={modalKpiNotificationVisible}
        onHide={() => {
          this.setState({
            ...this.state,
            modalKpiNotificationVisible: false,
          });
        }}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton className="bg-primary">
          <h5 className="text-light pb-0">Thêm thời gian thông báo</h5>
        </Modal.Header>
        <Modal.Body className="custom-body">
          <div className="mb-4">
            <label>Ngày làm việc</label>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Chọn ngày làm việc"
              // value={[]}
              onChange={(values) => console.log(values, 'values')}
              optionFilterProp="label"
              allowClear
              notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Dữ liệu không tồn tại" />}
            >
              {workingDays.map((item, index) => {
                return (
                  <Option key={index} value={item.value} label={item.label}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div className="mb-4">
            <label>Loại tài khoản nhận</label>
            <Select
              style={{ width: '100%' }}
              placeholder="Chọn loại tài khoản"
              // value={[]}
              onChange={(value) => console.log(value, 'values')}
              allowClear
            >
              {userRoles.map((item, index) => {
                return (
                  <Option key={index} value={item.value} label={item.label}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div className="mb-4">
            <label>Thời gian</label>
            <TimePicker
              placeholder="Chọn thời gian"
              style={{ width: '100%' }}
              onChange={(value) => console.log(value, 'time picker')}
            />
          </div>
          <div className="mb-4">
            <label>Nội dung thông báo</label>
            <br />
            {contentButtons.map((item, index) => {
              return (
                <Button
                  key={index}
                  style={{
                    marginRight: '5px',
                    marginBottom: '5px',
                  }}
                  type={item.isActive ? 'primary' : ''}
                  onClick={() => this.handleContentButtons(item.id)}
                  // danger
                >
                  {item.label}
                </Button>
              );
            })}
            <TextArea placeholder="Nội dung thông báo" autoSize value={kpiNotificationModal.content} />
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="success"
            onClick={() => {
              console.log('add kpi notification');
            }}
            className="text-light"
            style={{ background: 'green' }}
          >
            Lưu
          </Button>
          <Button
            style={{ background: 'red' }}
            variant="danger"
            onClick={() => {
              this.setState({
                ...this.state,
                modalKpiNotificationVisible: false,
              });
            }}
            className="text-light"
          >
            Hủy
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  handleContentButtons(id) {
    const { contentButtons, kpiNotificationModal } = this.state;
    const newButtons = [...contentButtons];
    const newArr = newButtons.map((subItem, subIndex) => {
      if (subItem.id === id) {
        return {
          ...subItem,
          isActive: !subItem.isActive,
        };
      } else {
        return subItem;
      }
    });
    this.setState({
      ...this.state,
      kpiNotificationModal: {
        ...kpiNotificationModal,
        content: newArr
          .filter((item) => item.isActive)
          .map((item) => item.text)
          .join(''),
      },
      contentButtons: [...newArr],
    });
  }

  renderQuestion() {
    return (
      <>
        <GeneralQuestion />
      </>
    );
  }

  renderTypeJob() {
    const { query } = this.state;
    return (
      <>
        <Col sm={12} md={12}>
          <Row>
            <Col sm={12} className="d-flex justify-content-between">
              <h5>Loại công việc</h5>
              <Button
                variant="success"
                style={{ background: 'green' }}
                className="text-light"
                onClick={() => this.showModalType('modalAddType', true)}
              >
                Thêm loại công việc
              </Button>
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={12}>
          <Row>
            <Col md={6} sm={12}>
              <FormControl
                type="text"
                id=""
                placeholder="Tên loại công việc"
                value={query}
                onChange={(e) => this.handleChange('query', e.target.value)}
                // onKeyPress={(e) => this.handleKeyPress(e)}
              />
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={12}>
          <div className="row">
            <Col
              sm={10}
              md={12}
              style={{
                padding: '0px',
                marginTop: '20px',
              }}
            >
              <Row className="table-wrap mx-4">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>STT</th>
                      <th>Tên</th>
                      <th>Ngày tạo</th>
                      <th></th>
                    </tr>
                  </thead>
                  {this.renderTableTypeJob()}
                </Table>
              </Row>
              <Row>
                <Col md={12} className="d-flex justify-content-center justify-content-md-end">
                  {this.renderPagination()}
                </Col>
              </Row>
            </Col>
          </div>
        </Col>
      </>
    );
  }

  renderTableTypeJob() {
    const typeJob = this.props.typeJobState?.data;
    return (
      <tbody>
        {typeJob.length > 0 ? (
          typeJob?.map((value, index) => (
            <tr>
              <td>{index + 1 || '--'}</td>
              <td>{value.name || '--'}</td>
              <td>{Intl.DateTimeFormat('vi-VN').format(new Date(value.created_at)) || '###'}</td>
              <td>
                <div className="d-flex justify-content-center align-items-center">
                  <i
                    className="far fa-edit step-icon-edit mr-2"
                    onClick={() => {
                      this.showModalType('modalEditType', true);
                      this.setState({
                        modalEditTypeField: {
                          name: value.name,
                        },
                        currentIndex: value.id,
                      });
                    }}
                  ></i>
                  <i
                    className="far fa-trash-alt step-icon-del"
                    onClick={() => {
                      this.setState({
                        id: value.id,
                        confirmModalType: true,
                      });
                    }}
                  ></i>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <td colSpan="4">
            <Empty description="Không có dữ liệu"></Empty>
          </td>
        )}
      </tbody>
    );
  }

  renderHotLine() {
    return (
      <>
        <Hotline />
      </>
    );
  }

  renderKeyWord() {
    const { keyWord } = this.state;
    return (
      <>
        <Keyword />
      </>
    );
  }

  renderVPoint() {
    return (
      <>
        <Vpoint />
      </>
    );
  }

  renderCheckin() {
    return (
      <>
        <Checkin />
      </>
    );
  }

  renderPagination() {
    const itemsCount = this.props.categoryState?.paging?.totalItemCount;
    const limit = this.props.categoryState?.paging?.limit;
    const { query } = this.state;
    return (
      <Pagination
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled
        activePage={this.state.activePage}
        itemsCountPerPage={limit}
        totalItemsCount={itemsCount}
        pageRangeDisplayed={5}
        hideNavigation
        onChange={(page) => {
          this.setState({
            ...this.state,
            activePage: page,
          });
          this.getData({ page, query });
        }}
      />
    );
  }

  renderTableReview() {
    const review = this.props.reviewState.data;
    return (
      <tbody>
        {review.length > 0 ? (
          review?.map((value, index) => (
            <tr>
              <td>{index + 1 || '--'}</td>
              <td>{value.name || '--'}</td>
              <td>{value.point || '--'}</td>
              <td>
                <div className="d-flex justify-content-center align-items-center">
                  <i
                    className="far fa-edit step-icon-edit mr-2"
                    onClick={() => {
                      this.showModal('modalEditReview', true);
                      this.setState({
                        modalEditReviewField: {
                          name: value.name,
                          point: value.point,
                        },
                        currentIndex: value.id,
                      });
                    }}
                  ></i>
                  <i
                    className="far fa-trash-alt step-icon-del"
                    onClick={() => {
                      this.setState({
                        id: value.id,
                        confirmModalReview: true,
                      });
                    }}
                  ></i>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <td colSpan="4">
            <Empty description="Không có dữ liệu" />
          </td>
        )}
      </tbody>
    );
  }

  renderTableReviewKPI() {
    return (
      <tbody>
        <td colSpan="6">
          <Empty description="Không có dữ liệu" />
        </td>
      </tbody>
    );
  }

  handleChange = (field, value) => {
    this.setState(
      {
        ...this.state,
        [field]: value,
      },
      () =>
        this.getData({
          query: this.state.query,
          page: '',
        })
    );
  };

  showModal = (modal, bool, data) => {
    this.setState({
      [modal]: bool,
    });
    if (data) {
      this.setState({
        modalEditReviewField: {
          name: data.name,
          point: data.point,
          id: data.id,
        },
      });
    }
  };

  showModalType = (modal, bool, data) => {
    this.setState({
      [modal]: bool,
      disable: false,
    });
    if (data) {
      this.setState({
        modalEditTypeField: {
          name: data.name,
          id: data.id,
        },
      });
    }
  };

  handleTableCheck(selectedRowKeys) {
    const saleList = this.props.telesaleState?.data;
    var arr = [],
      convertedArr = [];
    if (saleList?.length) {
      arr = saleList.filter((sale) => selectedRowKeys?.includes(sale.id));
      arr.forEach((sale) =>
        convertedArr.push({
          telesale_id: sale.id,
          is_default: 0,
        })
      );
    }
    this.setState({
      selectedSale: [...selectedRowKeys],
      telesales: [...convertedArr],
    });
  }

  checkValidationReview = () => {
    const { modalAddReviewField } = this.state;
    if (!checkAllAttrFilled(this.state.modalAddReviewField)) {
      return <FormError errorMessage={'Vui lòng điền đầy đủ thông tin!'} />;
    }
    return true;
  };

  addModalVoice = () => {
    const { modalAddVoice, modalAddVoiceField, voiceList } = this.state;
    return (
      <Modal
        show={modalAddVoice}
        onHide={() => this.showModal('modalAddVoice', false)}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton className="bg-primary">
          <h5 className="text-light pb-0">Tạo kịch bản thử giọng</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          {/* {this.checkValidationReview()} */}
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Bước</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="text"
                placeholder="Bước"
                value={modalAddVoiceField.step}
                onChange={(e) => this.addVoiceFieldChange('step', e.target.value)}
              ></FormControl>
            </Row>
          </FormGroup>
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Tên bước</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="text"
                max={0}
                placeholder="Tên bước"
                value={modalAddVoiceField.name}
                onChange={(e) => this.addVoiceFieldChange('name', e.target.value)}
              ></FormControl>
            </Row>
          </FormGroup>
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Nội dung</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="text"
                max={0}
                placeholder="Nội dung"
                value={modalAddVoiceField.content}
                onChange={(e) => this.addVoiceFieldChange('content', e.target.value)}
              ></FormControl>
            </Row>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="success"
            onClick={() => {
              let newArr = [...voiceList];
              newArr.push(modalAddVoiceField);
              this.setState({
                ...this.state,
                modalAddVoiceField: {
                  step: '',
                  name: '',
                  content: '',
                },
                voiceList: [...newArr],
              });
              this.showModal('modalAddVoice', false);
            }}
            className="text-light"
            style={{ background: 'green' }}
          >
            Lưu
          </Button>
          <Button
            style={{ background: 'red' }}
            variant="danger"
            onClick={() => this.showModal('modalAddVoice', false)}
            className="text-light"
          >
            Hủy
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  addModalReview = () => {
    const { modalAddReview, modalAddReviewField } = this.state;
    return (
      <Modal
        show={modalAddReview}
        onHide={() => this.showModal('modalAddReview', false)}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton className="bg-primary">
          <h5 className="text-light pb-0">Thêm đánh giá</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          {this.checkValidationReview()}
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Nội dung</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="text"
                placeholder="Nhập nội dung"
                value={modalAddReviewField.name}
                onChange={(e) => this.addReviewFieldChange('name', e.target.value)}
              ></FormControl>
            </Row>
          </FormGroup>
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Điểm</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="number"
                max={0}
                placeholder="Nhập điểm"
                value={modalAddReviewField.point}
                onChange={(e) => this.addReviewFieldChange('point', e.target.value)}
              ></FormControl>
            </Row>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="primary"
            style={{ background: 'blue' }}
            onClick={() => this.createReviewNoCloseModal()}
            className="text-light"
          >
            Thêm mới và tiếp tục
          </Button>
          <Button
            variant="success"
            style={{ background: 'green' }}
            onClick={() => this.createReview()}
            className="text-light"
          >
            Thêm mới
          </Button>
          <Button
            variant="danger"
            style={{ background: 'red' }}
            onClick={() => this.showModal('modalAddReview', false)}
            className="text-light"
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  checkValidationType = () => {
    const { modalAddTypeField } = this.state;
    if (!checkAllAttrFilled(this.state.modalAddTypeField)) {
      return <FormError errorMessage={'Vui lòng điền đầy đủ thông tin!'} />;
    }
    return true;
  };

  addModalType = () => {
    const { modalAddType, modalAddTypeField } = this.state;
    return (
      <Modal
        show={modalAddType}
        onHide={() => this.showModalType('modalAddType', false)}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton className="bg-primary">
          <h5 className="text-light pb-0">Thêm loại công việc</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          {this.checkValidationType()}
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Nội dung</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="text"
                placeholder="Nhập nội dung"
                value={modalAddTypeField.name}
                onChange={(e) => this.addTypeFieldChange('name', e.target.value)}
              ></FormControl>
            </Row>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="success"
            className="text-light"
            style={{ background: 'green' }}
            onClick={() => this.createType()}
            disabled={this.state.disable}
          >
            Thêm mới
          </Button>
          <Button
            variant="danger"
            style={{ background: 'red' }}
            className="text-light"
            onClick={() => this.showModalType('modalAddType', false)}
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  checkValidationEditReview = (data) => {
    const { modalEditReviewField } = this.state;
    if (!checkAllAttrFilled(this.state.modalEditReviewField)) {
      return <FormError errorMessage={'Vui lòng điền đầy đủ thông tin!'} />;
    }
    return true;
  };

  addReviewFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalAddReviewField: {
        ...this.state.modalAddReviewField,
        [field]: value,
      },
    });
  };

  addVoiceFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalAddVoiceField: {
        ...this.state.modalAddVoiceField,
        [field]: value,
      },
    });
  };

  addTagFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalAddTagField: {
        ...this.state.modalAddTagField,
        [field]: value,
      },
    });
  };

  addTypeFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalAddTypeField: {
        ...this.state.modalAddTypeField,
        [field]: value,
      },
    });
  };

  editReviewFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalEditReviewField: {
        ...this.state.modalEditReviewField,
        [field]: value,
      },
    });
  };

  editTagFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalEditTagField: {
        ...this.state.modalEditTagField,
        [field]: value,
      },
    });
  };

  editTypeFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalEditTypeField: {
        ...this.state.modalEditTypeField,
        [field]: value,
      },
    });
  };

  checkValidationEditType = (data) => {
    const { modalEditTypeField } = this.state;
    if (!checkAllAttrFilled(this.state.modalEditTypeField)) {
      return <FormError errorMessage={'Vui lòng điền đầy đủ thông tin!'} />;
    }
    return true;
  };

  editModalType = () => {
    const { modalEditType, modalEditTypeField, currentIndex } = this.state;
    return (
      <Modal
        show={modalEditType}
        onHide={() => this.showModal('modalEditType', false)}
        dialogClassName="modal-90w"
        centered
        scrollable
      >
        <Modal.Header closeButton className="bg-primary">
          <h5 className="text-light pb-0">Cập nhật loại công việc</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          {this.checkValidationEditType()}
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Nội dung</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="text"
                placeholder="Nhập nội dung"
                value={modalEditTypeField.name}
                onChange={(e) => this.editTypeFieldChange('name', e.target.value)}
              ></FormControl>
            </Row>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="success"
            style={{ background: 'green' }}
            className="text-light"
            name="empty"
            onClick={() => this.updateType()}
          >
            Lưu
          </Button>
          <Button
            variant="danger"
            style={{ background: 'red' }}
            className="text-light"
            onClick={() => this.showModal('modalEditType', false)}
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderConfirmReviewModal() {
    const { confirmModalReview } = this.state;
    return (
      <Modal
        show={confirmModalReview}
        onHide={() =>
          this.setState({
            confirmModalReview: false,
          })
        }
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header
          closeButton
          className="bg-primary"
          // style={{ textAlign: "center", backgroundColor: "#fd7e14" }}
        >
          <h5 className="text-light pb-0">Bạn chắc chắn muốn xóa ?</h5>
        </Modal.Header>
        <Modal.Body className="custom-body">
          <Row>
            <Col className="button-wrapper " style={{ textAlign: 'center' }}>
              <Button
                variant="success"
                style={{ background: 'red' }}
                // className="header-btn btn-oversize"
                onClick={() => {
                  if (timeout) clearTimeout(timeout);
                  this.deleteReview(this.state.id);
                }}
                className="text-light"
                // style={{ marginRight: "10px" }}
              >
                Có
              </Button>
              <Button
                variant="danger"
                style={{ background: 'green' }}
                // className="header-btn btn-oversize"
                onClick={() => {
                  this.setState({
                    confirmModalReview: false,
                  });
                }}
                className="text-light"
              >
                Thoát
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  renderConfirmTagModal() {
    const { confirmModalTag } = this.state;
    return (
      <Modal
        show={confirmModalTag}
        onHide={() =>
          this.setState({
            confirmModalTag: false,
          })
        }
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header
          closeButton
          className="bg-primary"
          // style={{ textAlign: "center", backgroundColor: "#fd7e14" }}
        >
          <h5 className="text-light pb-0">Bạn chắc chắn muốn xóa ?</h5>
        </Modal.Header>
        <Modal.Body className="custom-body">
          <Row>
            <Col className="button-wrapper " style={{ textAlign: 'center' }}>
              <Button
                variant="success"
                style={{ background: 'red' }}
                // className="header-btn btn-oversize"
                onClick={() => {
                  if (timeout) clearTimeout(timeout);
                  this.deleteTag(this.state.id);
                }}
                className="text-light"
                // style={{ marginRight: "10px" }}
              >
                Có
              </Button>
              <Button
                variant="danger"
                style={{ background: 'green' }}
                // className="header-btn btn-oversize"
                onClick={() => {
                  this.setState({
                    confirmModalTag: false,
                  });
                }}
                className="text-light"
              >
                Thoát
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  renderConfirmTypeModal() {
    const { confirmModalType } = this.state;
    return (
      <Modal
        show={confirmModalType}
        onHide={() =>
          this.setState({
            confirmModalType: false,
          })
        }
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header
          closeButton
          // style={{ textAlign: "center", backgroundColor: "#fd7e14" }}
          className="bg-primary"
        >
          <h5 className="text-light pb-0">Bạn chắc chắn muốn xóa ?</h5>
        </Modal.Header>
        <Modal.Body className="custom-body">
          <Row>
            <Col className="button-wrapper" style={{ textAlign: 'center' }}>
              <Button
                variant="success"
                style={{ background: 'red' }}
                className="text-light"
                onClick={() => {
                  if (timeout) clearTimeout(timeout);
                  this.deleteType(this.state.id);
                }}
              >
                Có
              </Button>
              <Button
                variant="danger"
                style={{ background: 'green' }}
                className="text-light"
                onClick={() => {
                  this.setState({
                    confirmModalType: false,
                  });
                }}
              >
                Thoát
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  handleInputChange(field, value) {
    this.setState({
      ...this.state,
      [field]: value,
    });
  }

  render() {
    const { isLoading } = this.props.typeJobState;
    let loading = <Loading />;
    if (!isLoading) {
      loading = null;
    }
    return (
      <>
        {/* {isLoading ? <Loading /> : null} */}
        {loading}
        {this.addModalReview()}
        {this.modalAddTag()}
        {this.editModalTag()}
        {this.addModalVoice()}
        {this.editModalReview()}
        {this.editModalType()}
        {this.renderConfirmReviewModal()}
        {this.renderConfirmTagModal()}
        {this.renderConfirmTypeModal()}
        {this.addModalType()}
        {this.modalKpiNotification()}
        {this.renderBody()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  reviewState: state.reviewReducer,
  typeJobState: state.typeJobReducer,
});

const mapDispatchToProps = {
  getReviewList,
  getTypeJob,
  getTelesaleList,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(JobSetting));
