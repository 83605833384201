import { createBrowserHistory } from 'history';
import moment from 'moment';
import Axios from 'axios';

import { notifyFail, notifySuccess } from '@utils/notify';

const history = createBrowserHistory();

export const randomColor = name => {
  const arrayColor = [
    '#96D9C9',
    '#AABCD6',
    '#B0D4FF',
    '#BAF3FF',
    '#C0E8FF',
    '#C7DDFF',
    '#CDD5FF',
    '#F6D7BF',
    '#FFBFBF',
    '#FFC9F6',
    '#FFCDB8',
    '#FFDDA6',
  ];

  const hashCode = s =>
    s.split('').reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);

  const index = hashCode(name) % arrayColor.length;

  return arrayColor[index];
};

export const rmVN = (str, condition) => {
  let intermediateCondition = {
    methodRemovesWhitespace: true,
    methodRemoveSpecialCharacters: true,
    toUpperCase: true,
  };

  if (condition) {
    intermediateCondition.methodRemovesWhitespace =
      condition.methodRemovesWhitespace;
    intermediateCondition.methodRemoveSpecialCharacters =
      condition.methodRemoveSpecialCharacters;
    intermediateCondition.toUpperCase = condition.toUpperCase;
  }

  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  str = str.replace(/Đ/g, 'D');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, ' ');
  if (intermediateCondition.methodRemovesWhitespace) str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  if (intermediateCondition.methodRemoveSpecialCharacters)
    str = str.replace(
      /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
      ' '
    );
  return intermediateCondition.toUpperCase ? str.toUpperCase() : str;
};

export function formatPrice(num) {
  if (num === null || num === undefined || Number.isNaN(parseFloat(num)))
    return '';
  var result = num.toString().replace(/,/g, '');
  return result.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function setSearchParamsInUrl(key, value) {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set(key, JSON.stringify(value));
  history.replace({
    pathname: window.location.pathname,
    search: searchParams.toString(),
  });

  return searchParams.toString();
}

/**
 *
 * @param {[{key,value}]} data
 */
export function setSearchParamsInUrlVer2(data) {
  const searchParams = new URLSearchParams(window.location.search);

  for (const [key, value] of Object.entries(data)) {
    searchParams.set(key, JSON.stringify(value));
  }

  history.replace({
    pathname: window.location.pathname,
    search: searchParams.toString(),
  });

  return searchParams.toString();
}

export function getSearchParamsInUrl() {
  const locationSearch = window.location.search;
  const searchParams = new URLSearchParams(locationSearch);

  let params = {};

  if (locationSearch) {
    try {
      const fieldsSearch = searchParams.entries();
      for (let field of fieldsSearch) {
        params = { ...params, [field[0]]: JSON.parse(field[1]) };
      }
    } catch (error) {
      console.log(error);
    }
  }

  return params;
}

export function enterNumbersOnly(value) {
  if (value === null || value === undefined || value == 0) return '';
  var result = value?.replace(/[^\w\s]/gi, '');
  return result;
}

export function formatTime(value, cusFormat) {
  return moment(value).format(cusFormat ? cusFormat : 'HH:mm DD/MM/YYYY');
}

export function getFirstLetter(value) {
  return value[value?.length - 1].charAt(0)?.toUpperCase();
}

export function getExtension(filename) {
  var parts = filename && filename.split('.');
  return parts && parts[parts.length - 1];
}

export function isImage(filename) {
  var ext = getExtension(filename);
  switch (ext?.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'bmp':
    case 'png':
      //etc
      return true;
    default:
      return false;
  }
}

export function isVideo(filename) {
  var ext = getExtension(filename);
  switch (ext?.toLowerCase()) {
    case 'm4v':
    case 'avi':
    case 'mpg':
    case 'mp4':
      // etc
      return true;
    default:
      return false;
  }
}

export async function downloadFileWithSocket(path, fileName) {
  try {
    notifySuccess('Đã xuất thành công file excel!');
    const { data } = await Axios.get(path, { responseType: 'blob' });
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.log('download error: ', { error });
    notifyFail('Có lỗi xảy ra. Vui lòng thao tác lại!');
  }
}

/**
 * dateFormatConversion
 * @param {string} date DD/MM/YYYY || DD-MM-YYYY
 * @return 'YYYY/MM/DD'
 */
export function dateFormatConversion(date) {
  let result = '';
  let temp1, temp2;

  if (date.includes('/')) temp1 = date.split('/');
  if (date.includes('-')) temp1 = date.split('-');

  temp2 = temp1.reverse();

  result = temp2.join('/');
  return result;
}
export function getStatusCall(value) {
  switch (value) {
    case 3:
      return 'Chưa gọi';
    case 4:
      return 'Đã gọi';
    default:
      return 'Không xác định';
  }
}

export function getDay(value) {
  // console.log('value', value);
  if (!value) {
    return '';
  } else {
    let iscurrentDate = moment(value).isSame(moment(), 'day');
    // console.log('value', value, iscurrentDate);

    if (iscurrentDate) {
      return 'Hôm nay ' + moment(value).format('HH:mm');
    } else {
      return moment(value).format('DD/MM/YYYY HH:mm');
    }
  }
}



export function getListOptionsAnswerData(optionsQuestion, getListJobAnswerByOption) {
  let optionAnswerData =  (optionsQuestion.map(item => {
    return {label: item.name, options: item.job_form_answers.map(itemChild => {
      getListJobAnswerByOption &&  getListJobAnswerByOption.push(itemChild)
      return {label: itemChild.name, value: itemChild.id, key: itemChild.id}
    })}
  }))
  return {
    optionAnswerData: optionAnswerData,
    getListJobAnswerByOption: getListJobAnswerByOption
  }
}
export function convertNumberLocalString(x) {
  return  Number(x?.toString()?.replace(/,/g,"")) || 0
}

export function formatNumber(x) {
  return Number(x).toLocaleString()
}