import {
  addPhoneNumberPicked,
  getIsNewCustomer,
  getShouldCall,
  getStringeeCall,
  getUpdateCall,
} from '@actions/';
import { DownOutlined } from '@ant-design/icons';
import MessageZaloPerson from '@components/interaction-history/Message.ZaloPerson';
import Minigame from '@components/interaction-history/Minigame';
import Wrapper from '@components/ui/wrapper/Wrapper';
import {
  requestCustomerDetail,
  requestCustomerDetailInteractionHistory,
  requestFullListTag,
  requestGetHotline,
  requestUpdateTagsCustomer,
} from '@constants/Api';
import {
  CALLING_STATUS,
  DF_CUSTOMER_ACTION_ID,
  GENDER,
  ROUTER,
} from '@constants/Constant';
import { randomColor, rmVN } from '@constants/funcHelper';
import { faEdit } from '@fortawesome/fontawesome-free-regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showPriceFormat } from '@screens/Enterprise/Customer/MockData';
import {
  AppointmentSchedule,
  Call,
  Create,
  CustomerClosesTheContract,
  CustomerRefuses,
  DeleteOpportunities,
  DeliverToSale,
  EditCustomer,
  LocationReport,
  Message,
  MissedCall,
  PotentialCustomers,
  UpdateTag,
} from '@src/components/interaction-history';
import { primaryColor } from '@styles/Color';
import { BreadcrumbAntStyle } from '@styles/Style';
import { selectHotlineAuto } from '@utils/callErrorCodeUtil';
import { notifyFail, notifySuccess } from '@utils/notify';
import {
  Avatar,
  Breadcrumb,
  Col,
  Descriptions,
  Divider,
  Dropdown,
  Empty,
  Menu,
  Row,
  Select,
  Spin,
  Timeline,
} from 'antd';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  requestStartCall,
  requestStartCallWithNumber,
} from '../../../network/web_sale/CallApi';
import ModalAddOpportunity from '../Call/components/ModalAddOpportunity';
import ModalPickPhone from './componentsInstead/ModalPickPhone';
import TagCustom from './componentsInstead/TagCustom';

const { Option } = Select;

const InfoCustomerFlexCenter = styled.div`
  width: 100%;
  display: flex;
  /* justify-content: center; */
  font-size: 20px;
  font-weight: 700;
`;

const FontAwesomeIconStyle = styled(FontAwesomeIcon)`
  &:hover {
    /* color: #52c41a; */
    cursor: pointer;
  }
`;

const DescriptionStyle = styled(Descriptions)`
  .ant-descriptions-row > th,
  .ant-descriptions-row > td {
    padding-bottom: 0px;
  }

  .ant-descriptions-item-label {
    color: gray;
    font-size: 13px;
  }

  .ant-descriptions-item-content {
    font-size: 16px;
    /* color: #2626ff; */
    padding-bottom: 10px;
    text-align: start;
  }
`;

const TimeLineStyle = styled(Timeline)`
  .ant-timeline-item {
    padding: 0;
  }

  .ant-timeline-item-tail {
    position: absolute;
    top: 10px;
    left: 4px;
    height: calc(100% - 10px);
    border-left: 2px dotted #b3b3b3;
  }
`;

const DividerTextStyle = styled.div`
  color: white;
  background-color: ${primaryColor};
  padding: 0 10px;
  border-radius: 4px;
`;

const AvatarStyle = styled(Avatar)`
  @media (min-width: 1600px) {
    #avaCus {
      font-size: 32px;
    }
  }
  @media (min-width: 1200px and max-width: 1600px) {
    #avaCus {
      font-size: 24px;
    }
  }

  @media (min-width: 992px and max-width: 1200px) {
    #avaCus {
      font-size: 20px;
    }
  }
`;

export default function CustomerDetail({
  data,
  jobId,
  job_telesale_customer_id,
  recallImmediately,
}) {
  const history = useHistory();
  const params = useParams();
  const user = useSelector(states => states.userReducer.data);
  const returnCustomerId = useCallback(() => {
    return params.id;
  }, []);

  const [extraPhoneNumber, setExtraPhoneNumber] = useState([]);
  const [mainPhoneNumber, setMainPhoneNumber] = useState('');
  const [customerDetailInfo, setCustomerDetailInfo] = useState({});
  const [customerInteractionHistory, setCustomerInteractionHistory] = useState(
    []
  );
  const [
    loadingCustomerInteractionHistory,
    setLoadingCustomerInteractionHistory,
  ] = useState(false);
  const [tags, setTags] = useState([]);
  const [phoneStartCall, setPhoneStartCall] = useState([]);
  const [customerTags, setCustomerTags] = useState([]);

  const getFullTags = async () => {
    try {
      const res = await requestFullListTag({});
      const options =
        res?.data?.map(tag => ({
          tag_id: tag.id,
          tag_name: tag.name,
          tag_color: tag.color,
        })) || [];
      setTags(options);
    } catch (err) {}
  };
  const [modalAddOpportunityVisible, setModalAddOpportunityVisible] =
    useState(false);
  const dispatch = useDispatch();
  const [isDisplayPickPhoneNumberModal, setIsDisplayPickPhoneNumberModal] =
    useState(false);
  const onCallFromDetailCustomer = async () => {
    try {
      const listOfHotlines = await requestGetHotline();
      let hotlineDefault = selectHotlineAuto(
        listOfHotlines.data,
        phoneStartCall?.length
          ? phoneStartCall
          : customerDetailInfo?.main?.phone
      );
      if (customerDetailInfo?.main?.job_id) {
        const res = await requestStartCall({
          job_id: parseInt(customerDetailInfo?.main?.job_id),
          job_telesale_customer_id: parseInt(
            res?.data?.job_telesale_customer_id
          ),
          hotline: hotlineDefault.toString(),
        });
        // setDataStartCall(res);
        dispatch(getIsNewCustomer({ status: false }));
        dispatch(getShouldCall({ status: true }));
        dispatch(getUpdateCall({}));
        dispatch(
          getStringeeCall({
            customerPhone: phoneStartCall?.length
              ? phoneStartCall
              : customerDetailInfo?.main?.phone,
            customerId: customerDetailInfo?.main?.id,
            jobId: customerDetailInfo?.main?.job_id || '',
            callId: res.data.id,
            job_telesale_customer_id: res?.data?.job_telesale_customer_id,
          })
        );
        dispatch(
          addPhoneNumberPicked(
            phoneStartCall?.length
              ? phoneStartCall
              : customerDetailInfo?.main?.phone
          )
        );
      } else {
        const res = await requestStartCallWithNumber({
          phone_number: phoneStartCall?.length
            ? phoneStartCall
            : customerDetailInfo?.main?.phone,
          hotline: hotlineDefault.toString(),
        });
        // setDataStartCall(res);
        dispatch(getIsNewCustomer({ status: false }));
        dispatch(getShouldCall({ status: true }));
        dispatch(getUpdateCall({}));
        dispatch(
          getStringeeCall({
            customerPhone: phoneStartCall?.length
              ? phoneStartCall
              : customerDetailInfo?.main?.phone,
            customerId: customerDetailInfo?.main?.id,
            jobId: customerDetailInfo?.main?.job_id || '',
            callId: res.data.id,
            job_telesale_customer_id: res?.data?.job_telesale_customer_id,
          })
        );
        dispatch(
          addPhoneNumberPicked(
            phoneStartCall?.length
              ? phoneStartCall
              : customerDetailInfo?.main?.phone
          )
        );
      }

      history.push({
        pathname: ROUTER.CALLING_SALE,
        search: `?call_id=${customerDetailInfo?.main?.id}&call_status=${CALLING_STATUS.calling}`,
      });
    } catch (error) {
      console.log('call from customer detail', error);
    }
  };

  const getDetailCustomer = async () => {
    try {
      if (!returnCustomerId()) return;
      const result = await requestCustomerDetail(returnCustomerId());
      setCustomerTags(result.data.main.customer_tags.map(item => item.tag_id));
      setCustomerDetailInfo(result.data);
      setMainPhoneNumber(result.data.main.phone);
      if (result.data.main.customer_phones.length) {
        const arr = result.data.main.customer_phones.map(item => item.phone);
        setExtraPhoneNumber(arr);
      }
    } catch (error) {
      console.log('Exception: ', error);
    }
  };

  const getDetailInteractionHistory = async () => {
    try {
      setLoadingCustomerInteractionHistory(true);
      const result = await requestCustomerDetailInteractionHistory({
        id: returnCustomerId(),
        page: 1,
        limit: 1000,
      });
      setCustomerInteractionHistory(result.data);
    } catch (error) {
      console.log('Exception: ', error);
    } finally {
      setLoadingCustomerInteractionHistory(false);
    }
  };

  useEffect(() => {
    getDetailInteractionHistory();
  }, []);

  useEffect(() => {
    getDetailCustomer();
    getFullTags();
  }, []);

  const handleUpdateTagsCustomer = async tags => {
    const tagsCustomerCurrent = customerDetailInfo.main.customer_tags.map(
      (tag, index) => tag.tag_id
    );
    const check = _.isEqual(
      tags.sort((a, b) => a - b),
      tagsCustomerCurrent.sort((a, b) => a - b)
    );
    if (!check) {
      try {
        const result = await requestUpdateTagsCustomer({
          customerId: returnCustomerId(),
          tags: tags,
        });
        notifySuccess(result.data.msg);
        getDetailCustomer();
        getDetailInteractionHistory();
      } catch (error) {
        setCustomerTags(
          customerDetailInfo.main.customer_tags.map((tag, index) => tag.tag_id)
        );
        notifyFail('Cập nhật không thành công');
        console.log('error: ', error);
      }
    }
  };

  const infoCustomer = customerDetailInfo => {
    const name = customerDetailInfo?.main?.name?.split(' ');

    return (
      <>
        <Row gutter={[16, 16]} align="middle" justify="center">
          <Col span={7}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <AvatarStyle
                style={{
                  backgroundColor: randomColor(
                    name[name.length - 1].charAt(0)?.toUpperCase()
                  ),
                  marginRight: '5px',
                }}
                size={{ xs: 24, sm: 32, md: 40, lg: 50, xl: 45, xxl: 75 }}
              >
                <span id="avaCus">
                  {name[name.length - 1].charAt(0)?.toUpperCase()}
                </span>
              </AvatarStyle>
            </div>
          </Col>

          <Col span={17}>
            <Row>
              <InfoCustomerFlexCenter>
                {customerDetailInfo.main.name}
              </InfoCustomerFlexCenter>
            </Row>

            <Row>
              <InfoCustomerFlexCenter>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div style={{ margin: '3px 10px 0px 0px' }}>
                    {mainPhoneNumber}
                  </div>
                  <div>
                    {extraPhoneNumber.length ? (
                      <Dropdown
                        placement="bottomRight"
                        overlay={
                          <Menu
                            onClick={(item, key, keyPath, domEvent) => {
                              const extra = [...extraPhoneNumber];
                              const idx = extra.findIndex(
                                phone => phone === item.key
                              );
                              extra.splice(idx, 1, mainPhoneNumber);
                              setExtraPhoneNumber(extra);
                              setMainPhoneNumber(item.key);
                            }}
                          >
                            {extraPhoneNumber.map((phone, index) => {
                              return <Menu.Item key={phone}>{phone}</Menu.Item>;
                            })}
                          </Menu>
                        }
                        trigger={['click']}
                      >
                        <DownOutlined />
                      </Dropdown>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </InfoCustomerFlexCenter>
            </Row>
          </Col>
        </Row>

        <Row>
          <Select
            allowClear
            style={{ width: '100%', marginTop: '10px' }}
            bordered={false}
            mode={user?.config_tag == 1 ? 'multiple' : null}
            optionFilterProp="children"
            filterOption={(input, option) => {
              return (
                rmVN(option.children.props.tagName).indexOf(rmVN(input)) >= 0
              );
            }}
            placeholder="Phân loại"
            maxTagCount={user?.config_tag == 1 ? 1 : null}
            defaultValue={
              user?.config_tag == 0
                ? customerDetailInfo?.main.customer_tags?.length > 1
                  ? `${
                      customerDetailInfo?.main.customer_tags?.[0]?.tag_name
                    } + ${customerDetailInfo?.main.customer_tags?.length - 1}`
                  : customerDetailInfo?.main.customer_tags.length === 0
                  ? undefined
                  : `${customerDetailInfo?.main.customer_tags?.[0]?.tag_name}`
                : customerTags
            }
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu!</span>}
              />
            }
            onChange={value => {
              mixpanel.track(
                ` Update tagId: ${value}, customer ${customerDetailInfo.main.name}`
              );
              setCustomerTags(value);
              if (user?.config_tag == 0) {
                if (!value) {
                  handleUpdateTagsCustomer([]);
                } else {
                  handleUpdateTagsCustomer([value]);
                }
              }
            }}
            onBlur={() => {
              if (user?.config_tag == 1) {
                handleUpdateTagsCustomer(customerTags);
              }
            }}
          >
            {/* {_.uniqBy([...tags, ...customerDetailInfo.main.customer_tags], 'tag_id').map((item, index) => ( */}
            {tags.map((item, index) => (
              <Option key={index} value={item.tag_id}>
                <TagCustom tagName={item.tag_name} tagColor={item.tag_color} />
              </Option>
            ))}
          </Select>
        </Row>

        <Row style={{ marginTop: '10px' }}>
          Cập nhật lần cuối{' '}
          {moment(customerDetailInfo.modified_date).format('HH:mm DD/MM/YYYY')}{' '}
          bởi{' '}
          {customerInteractionHistory.length
            ? customerInteractionHistory[0].updated_by
            : ''}
        </Row>
      </>
    );
  };

  const titleInfoMoreCustomer = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ fontSize: '20px', marginRight: '20px' }}>
          Thông tin cá nhân
        </div>
        <FontAwesomeIconStyle
          icon={faEdit}
          size="lg"
          onClick={() => {
            try {
              history.push({
                pathname: ROUTER.EDIT_CUSTOMER + `/${returnCustomerId()}`,
                state: { key: 'detail' },
              });
            } catch (error) {
              console.log('Exception: ', error);
            }
          }}
        />
      </div>
    );
  };

  const infoMoreCustomer = customerDetailInfo => {
    return (
      <DescriptionStyle
        title={titleInfoMoreCustomer()}
        column={1}
        layout="vertical"
      >
        {customerDetailInfo.list_field_configs &&
          customerDetailInfo.list_field_configs.map((field, index, array) => {
            try {
              switch (field.code) {
                case 'province':
                  if (customerDetailInfo?.main?.province) {
                    return (
                      <Descriptions.Item key={index} label={field.label}>
                        {customerDetailInfo?.main?.province.name}
                      </Descriptions.Item>
                    );
                  } else break;

                case 'gender':
                  if (
                    customerDetailInfo?.main?.gender === GENDER.MAN ||
                    customerDetailInfo?.main?.gender === GENDER.WOMEN
                  ) {
                    return customerDetailInfo?.main?.gender === GENDER.MAN ? (
                      <Descriptions.Item key={index} label={field.label}>
                        Nam
                      </Descriptions.Item>
                    ) : (
                      <Descriptions.Item key={index} label={field.label}>
                        Nữ
                      </Descriptions.Item>
                    );
                  } else break;

                case 'modified_date':
                  // if (customerDetailInfo?.main?.modified_date) {
                  //   return (
                  //     <Descriptions.Item key={index} label={field.label}>
                  //       {moment(customerDetailInfo?.main?.modified_date).format('DD/MM/YYYY')}
                  //     </Descriptions.Item>
                  //   );
                  // } else break;
                  break;

                case 'dob':
                  if (customerDetailInfo?.main?.dob) {
                    return (
                      <Descriptions.Item key={index} label={field.label}>
                        {moment(customerDetailInfo?.main?.dob).format(
                          'DD/MM/YYYY'
                        )}
                      </Descriptions.Item>
                    );
                  } else break;

                case 'customer_tags':
                  break;

                default:
                  try {
                    if (customerDetailInfo?.main?.[field.code.toLowerCase()]) {
                      return (
                        <Descriptions.Item key={index} label={field.label}>
                          {/* {customerDetailInfo?.main?.[field.code]} */}
                          {showPriceFormat(
                            field.code.toLowerCase(),
                            customerDetailInfo?.main?.[field.code]
                          )}
                        </Descriptions.Item>
                      );
                    } else {
                      const data = customerDetailInfo.main.customer_data.find(
                        item =>
                          item.customer_data_field_code.toLowerCase() ===
                          field.code.toLowerCase()
                      );
                      if (data?.value) {
                        return (
                          <Descriptions.Item key={index} label={data.name}>
                            {showPriceFormat(
                              field.code.toLowerCase(),
                              data.value
                            )}
                          </Descriptions.Item>
                        );
                      }
                    }
                  } catch (error) {
                    console.log('Exception: ', error);
                    return <></>;
                  }
              }
            } catch (error) {
              console.log('Exception: ', error);
            }
          })}
      </DescriptionStyle>
    );
  };

  const timeDivider = date => {
    if (
      new Date().getDate() === new Date(date).getDate() &&
      new Date().getMonth() === new Date(date).getMonth() &&
      new Date().getFullYear() === new Date(date).getFullYear()
    ) {
      return (
        <Divider>
          <DividerTextStyle>Hôm nay</DividerTextStyle>
        </Divider>
      );
    } else if (
      new Date().getDate() - new Date(date).getDate() === 1 &&
      new Date().getMonth() === new Date(date).getMonth() &&
      new Date().getFullYear() === new Date(date).getFullYear()
    ) {
      return (
        <Divider>
          <DividerTextStyle>Hôm qua</DividerTextStyle>
        </Divider>
      );
    } else {
      return (
        <Divider>
          <DividerTextStyle>
            {moment(date).format('DD/MM/YYYY')}
          </DividerTextStyle>
        </Divider>
      );
    }
  };

  const interactionHistory = customerInteractionHistory => {
    return (
      <div>
        <Row
          justify="space-between"
          style={{
            fontSize: '28px',
            fontWeight: 700,
            marginBottom: '00px',
            color: primaryColor,
          }}
        >
          Lịch sử tương tác
          {/* <Tooltip title="Gọi">
            <div
              style={callStyle}
              onClick={() => {
                if (customerDetailInfo?.main?.customer_phones.length) {
                  setIsDisplayPickPhoneNumberModal(true);
                } else {
                  onCallFromDetailCustomer();
                }
              }}
            >
              <i className="fas fa-phone-alt fa-lg"></i>
            </div>
          </Tooltip> */}
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => setModalAddOpportunityVisible(true)}
                  >
                    Tạo cơ hội
                  </a>
                </Menu.Item>
              </Menu>
            }
            placement="topLeft"
            arrow
            trigger={['click']}
          >
            <div
              style={{
                cursor: 'pointer',
                width: '15px',
                textAlign: 'end',
                marginTop: 15,
              }}
            >
              <i className="fas fa-ellipsis-v fa-lg"></i>
            </div>
          </Dropdown>
        </Row>
        <TimeLineStyle>
          {customerInteractionHistory.length > 0 ? (
            customerInteractionHistory.map((item, index, array) => {
              if (index === 0) {
                return (
                  <>
                    {timeDivider(item.created_at)}
                    <Timeline.Item
                      key={index}
                      dot={
                        <img
                          src={item.df_customer_action_icon}
                          style={{ width: '25px', height: '25px' }}
                          alt=""
                        />
                      }
                    >
                      <div
                        style={{
                          backgroundColor: 'rgb(246 246 246)',
                          margin: '10px 15px',
                          padding: '8px 16px',
                          borderRadius: '8px',
                        }}
                      >
                        {renderInteractionHistory(item)}
                      </div>
                    </Timeline.Item>
                  </>
                );
              } else {
                const currentDateItem = new Date(
                  array[index].created_at
                ).getDate();
                const prevDateItem = new Date(
                  array[index - 1].created_at
                ).getDate();
                if (currentDateItem === prevDateItem) {
                  return (
                    <Timeline.Item
                      key={index}
                      dot={
                        <img
                          src={item.df_customer_action_icon}
                          style={{ width: '25px', height: '25px' }}
                          alt=""
                        />
                      }
                    >
                      <div
                        style={{
                          backgroundColor: '#f3f3f3',
                          margin: '10px 15px',
                          padding: '8px 16px',
                          borderRadius: '8px',
                        }}
                      >
                        {renderInteractionHistory(item)}
                      </div>
                    </Timeline.Item>
                  );
                } else {
                  return (
                    <>
                      {timeDivider(item.created_at)}
                      <Timeline.Item
                        key={index}
                        dot={
                          <img
                            src={item.df_customer_action_icon}
                            style={{ width: '25px', height: '25px' }}
                            alt=""
                          />
                        }
                      >
                        <div
                          style={{
                            backgroundColor: '#f3f3f3',
                            margin: '10px 15px',
                            padding: '8px 16px',
                            borderRadius: '8px',
                          }}
                        >
                          {renderInteractionHistory(item)}
                        </div>
                      </Timeline.Item>
                    </>
                  );
                }
              }
            })
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>Không có lịch sử.</span>}
            />
          )}
        </TimeLineStyle>
      </div>
    );
  };

  const renderInteractionHistory = interactionHistory => {
    switch (interactionHistory.df_customer_action_id) {
      case DF_CUSTOMER_ACTION_ID.KHACH_HANG_TIEM_NANG:
        return <PotentialCustomers interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.KHACH_HANG_CHOT_HOP_DONG:
        return (
          <CustomerClosesTheContract interactionHistory={interactionHistory} />
        );

      case DF_CUSTOMER_ACTION_ID.KHACH_HANG_TU_CHOI:
        return <CustomerRefuses interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.GOI_DEN:
        return <Call interactionHistory={interactionHistory} user={user} />;

      case DF_CUSTOMER_ACTION_ID.GOI_DI:
        return <Call interactionHistory={interactionHistory} user={user} />;

      case DF_CUSTOMER_ACTION_ID.GOI_NHO:
        return <MissedCall interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.CHINH_SUA_KHACH_HANG:
        return <EditCustomer interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.GIAO_CHO_SALE_CHAM_SOC:
        return <DeliverToSale interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.LICH_HEN:
        return <AppointmentSchedule interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.THEM_MOI:
        return <Create interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.CAP_NHAT_THE_TAG:
        return <UpdateTag interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.XOA_CO_HOI:
        return <DeleteOpportunities interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.BAO_CAO_VI_TRI:
        return <LocationReport interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.GUI_TIN_NHAN_SMS:
        return <Message interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.GUI_TIN_NHAN_ZNS:
        return <Message interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.VONG_QUAY_MAY_MAN:
        return <Minigame interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.GUI_TIN_NHAN_ZALO_CA_NHAN:
        return <MessageZaloPerson interactionHistory={interactionHistory} />;

      default:
        break;
    }
  };

  return (
    <div className="content-wrapper">
      <BreadcrumbAntStyle>
        <Breadcrumb.Item>
          {/* <a onClick={() => history.push(ROUTER.CUSTOMER)}>Danh sách khách hàng</a> */}
          <a onClick={() => history.goBack()}>Danh sách khách hàng</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Chi tiết khách hàng</Breadcrumb.Item>
      </BreadcrumbAntStyle>

      <Wrapper>
        <Row gutter={[16, 16]}>
          <Col xxl={6} xl={6} lg={7} sm={24} xs={24}>
            <Row>
              <Col span={24}>
                {customerDetailInfo?.main?.id &&
                  infoCustomer(customerDetailInfo)}
              </Col>
            </Row>

            <Row>
              <Divider />
            </Row>

            <Row>
              <Col span={24}>
                {customerDetailInfo?.main?.id &&
                  infoMoreCustomer(customerDetailInfo)}
              </Col>
            </Row>
          </Col>

          {/* <Divider type="vertical" /> */}
          {/* <div style={{ borderLeft: '1px solid black', height: 'auto' }}></div> */}

          <Col
            xxl={{ span: 17, offset: 1 }}
            xl={{ span: 17, offset: 1 }}
            lg={{ span: 16, offset: 1 }}
            sm={{ span: 24 }}
            xs={24}
          >
            <Spin spinning={loadingCustomerInteractionHistory}>
              {interactionHistory(customerInteractionHistory)}
            </Spin>
          </Col>
        </Row>
      </Wrapper>
      <ModalPickPhone
        isDisplayPickPhoneNumberModal={isDisplayPickPhoneNumberModal}
        dataCustomerPhone={customerDetailInfo?.main?.customer_phones}
        setPhoneStartCall={setPhoneStartCall}
        phoneStartCall={phoneStartCall}
        setIsDisplayPickPhoneNumberModal={setIsDisplayPickPhoneNumberModal}
        onCall={onCallFromDetailCustomer}
        dataCustomerPhoneFirst={customerDetailInfo?.main?.phone}
      />
      {/* <Modal
        title="Chọn số điện thoại"
        destroyOnClose={true}
        footer={null}
        visible={isDisplayPickPhoneNumberModal}
        onCancel={hanldePickPhoneNumberModalCancel}
      >
        <Form name="basic" layout="vertical" autoComplete="off" onFinish={handlePickedPhone}>
          <Form.Item name="phone" initialValue={customerDetailInfo?.main?.customer_phones[0]}>
            <Radio.Group
              onChange={(value) => {
                setPhoneStartCall(value.target.value);
              }}
            >
              {customerDetailInfo?.main?.customer_phones.map((phone) => (
                <div>
                  <Radio value={phone}>{phone}</Radio>
                </div>
              ))}
            </Radio.Group>
          </Form.Item>

          <Row justify="end">
            <Form.Item className="m-0">
              <Button type="primary" htmlType="submit">
                OK
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Modal> */}
      <ModalAddOpportunity
        visible={modalAddOpportunityVisible}
        setVisible={setModalAddOpportunityVisible}
        customerName={customerDetailInfo?.main?.name}
      />
    </div>
  );
}
