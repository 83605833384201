import { handleResult, getAxios } from '@constants/Api';
import Axios from 'axios';

const ZNS_ENDPOINT = {
  PROVIDER: 'zns/oaprovider',
  OFFICIAL_ACCOUNT: 'zns/officialAccount',
  TEMPLATE: 'zns/template',
  All_TEMPLATE: 'zns/all_template',
};

/**
 * requestCreateZNSProvider
 * @param {enterprise_id, access_token, refresh_token, app_id, secret_key} payload
 * @returns Promise
 */
export const requestCreateZNSProvider = payload =>
  handleResult(getAxios.post(ZNS_ENDPOINT.PROVIDER, payload));

/**
 * requestCreateOA
 * @param {enterprise_id, zns_provider_id} payload
 * @returns Promise
 */
export const requestCreateOA = payload =>
  handleResult(getAxios.post(ZNS_ENDPOINT.OFFICIAL_ACCOUNT, payload));

/**
 * requestGetOA
 * @param {enterprise_id} params
 * @returns Promise
 */
export const requestGetOA = params =>
  handleResult(
    getAxios.get(ZNS_ENDPOINT.OFFICIAL_ACCOUNT, {
      params: new URLSearchParams(params),
    })
  );

/**
 * requestSyncTemplateOA
 * @param {enterprise_id, oa_provider_id} payload
 * @returns Promise
 */
export const requestSyncTemplateOA = payload =>
  handleResult(getAxios.post(ZNS_ENDPOINT.TEMPLATE, payload));

/**
 * requestGetTemplateZNS
 * @param {enterprise_id, oa_provider_id, search, status} params
 * @returns Promise
 */
export const requestGetTemplateZNS = params =>
  handleResult(
    getAxios.get(ZNS_ENDPOINT.TEMPLATE, { params: new URLSearchParams(params) })
  );

/**
 * requestGetTemplateZNSDetail
 * @param {*} template_id
 * @returns Promise
 */
export const requestGetTemplateZNSDetail = template_id =>
  handleResult(getAxios.get(`${ZNS_ENDPOINT.TEMPLATE}/${template_id}`));

/**
 * requestGetAllTemplateZNS
 * @param {enterprise_id} params
 * @returns Promise
 */
export const requestGetAllTemplateZNS = params =>
  handleResult(
    getAxios.get(ZNS_ENDPOINT.All_TEMPLATE, {
      params: new URLSearchParams(params),
    })
  );

export const getListAccountZalo = params =>
  handleResult(
    getAxios.get('/user/listAccountZalo', {
      params,
    })
  );

export const getDetailAccountZalo = params =>
  handleResult(getAxios.get('/user/detailAccountZalo'), {
    params,
  });

export const createAccountZalo = payload =>
  handleResult(getAxios.post('/user/createAccountZalo', payload));

export const updateAccountZalo = (id, payload) =>
  handleResult(
    getAxios.put('/user/updateAccountZalo', {
      ...payload,
      id,
    })
  );

export const deleteAccountZalo = id =>
  handleResult(getAxios.delete(`/user/deleteAccountZalo/${id}`));
