import CallUtil from '@components/callProviders/CallUtil';
import { ROUTER } from '@constants/Constant';
import { Breadcrumb } from 'antd';
import React from 'react';
import { BreadcrumbItem } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const BreadcrumbCalling = ({ job_id }) => {
  const history = useHistory();

  return (
    <Breadcrumb style={{ marginBottom: '10px' }}>
      <BreadcrumbItem
        onClick={() => {
          if (localStorage.getItem('previousPage')) {
            history.goBack();
          } else {
            history.replace({
              pathname: `${ROUTER.JOB_DETAIL_SALE}/${
                CallUtil.currentJobId || job_id
              }`,
            });
          }
        }}
      >
        Danh sách khách hàng trong chi tiết công việc
      </BreadcrumbItem>
      <BreadcrumbItem style={{ fontWeight: 'bold' }}>
        Phiếu kết quả cuộc gọi
      </BreadcrumbItem>
    </Breadcrumb>
  );
};

export default React.memo(BreadcrumbCalling);
