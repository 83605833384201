import { getNextCustomer } from '@actions/';
import { BarsOutlined } from '@ant-design/icons';
import Wrapper from '@components/ui/wrapper/Wrapper';
import { ROUTER } from '@constants/Constant';
import { BreadcrumbAntStyle } from '@styles/Style';
import { notifyFail, notifySuccess } from '@utils/notify';
import {
  Avatar,
  Breadcrumb,
  Button,
  Empty,
  Input,
  Modal,
  Progress,
  Row,
  Table,
  Tooltip,
} from 'antd';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useCancellableApi } from 'src/hooks/useCancellableApi';
import styled from 'styled-components';
import {
  requestConfirmJob,
  requestGetJobList,
} from '../../../network/web_sale/JobApi';
import Filter from './component/Filter';
import { requestGetCallStatus } from '@constants/Api';
import CallUtil from '@components/callProviders/CallUtil';

const CloseCircleOutlined = () => (
  <svg
    width="25px"
    height="25px"
    fill="red"
    viewBox="0 0 1024 1024"
    style={{ marginBottom: 6 }}
  >
    <path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 00-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z" />
    <path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
  </svg>
);

const CheckCircleOutlined = () => (
  <svg
    width="25px"
    height="25px"
    fill="green"
    viewBox="0 0 1024 1024"
    style={{ marginBottom: 6 }}
  >
    <path d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
    <path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
  </svg>
);

const JobNameStyled = styled.div`
  text-align: start;
  font-weight: 700;

  &:hover {
    color: green;
  }
`;

function JobSaleScreen() {
  const user = useSelector(states => states.userReducer.data);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmJob, setConfirmJob] = useState();
  const [jobList, setJobList] = useState([]);
  const [jobId, setJobId] = useState();
  const [jobTelesaleId, setJobTelesaleId] = useState();
  const [reason, setReason] = useState();
  const [params, setParams] = useState({
    limit: 20,
    page: 1,
    search: '',
    job_status: '',
    from_date: '',
    to_date: '',
  });
  const [formattedPaging, setFormattedPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 0,
  });

  const windowLocation = window.location.search;
  const searchParams = new URLSearchParams(windowLocation);

  const handleChangeURLSearchParams = (field, value) => {
    searchParams.set(field, JSON.stringify(value));
    history.push({
      pathname: window.location.pathname,
      search: searchParams.toString(),
    });
  };
  const handleChangeParamSearchCustomers = (field, value) => {
    mixpanel.track(`filter job ${field}: ${value}`);
    setParams({
      ...params,
      [field]: value || '',
      page: 1,
    });
  };

  useEffect(() => {
    if (windowLocation) {
      // setIsLoadingTable(true);
      const fieldsSearch = searchParams.entries();
      let payload = {};
      for (let field of fieldsSearch) {
        payload = { ...payload, [field[0]]: JSON.parse(field[1]) };
      }
      if (!JSON.stringify(payload).includes('province_ids')) {
        payload = { ...payload, province_ids: [] };
      }
      if (!JSON.stringify(payload).includes('telesale_id')) {
        payload = { ...payload, telesale_id: [] };
      }
      setParams({ ...payload });
    }
  }, []);

  const apiGetListJob = useCancellableApi(requestGetJobList);
  const getData = async (payload = params) => {
    try {
      //ghi giá trị param lên url search
      for (let field of Object.entries(payload)) {
        handleChangeURLSearchParams(field[0], field[1]);
      }
      setIsLoading(true);
      const res = await apiGetListJob(payload);
      setJobList(res.data);
      setFormattedPaging({
        total: res.paging.totalItemCount,
        current: res.paging.page,
        pageSize: res.paging.limit,
      });
    } catch (error) {
      notifyFail(error?.mgs);
    }
    setIsLoading(false);
  };

  const postConfirmJob = async () => {
    const payload = {
      job_id: jobId,
      status: confirmJob === 1 ? 3 : 5,
      job_telesale_id: jobTelesaleId,
      reason: confirmJob === 1 ? '' : reason,
    };
    try {
      // setIsLoading(true);
      const res = await requestConfirmJob(payload);
      if (confirmJob === 1) {
        notifySuccess('Chấp nhận công việc thành công');
      } else {
        notifySuccess('Từ chối công việc thành công');
      }
      setTimeout(debounceGetDataJob(), 3000);
    } catch (error) {
      notifyFail(error?.msg);
    }
    // setIsLoading(false);
    setIsModalVisible(false);
  };

  const debounceGetDataJob = useCallback(_.debounce(getData, 100), []);

  useEffect(() => {
    debounceGetDataJob(params);
  }, [params]);

  const column = [
    {
      title: <b></b>,
      dataIndex: '',
      key: '',
      width: 95,
      render: (value, record) => (
        <>
          {record?.job_telesales_status === 2 ? (
            <Row justify="space-between" style={{ margin: '0 10px 0 10px' }}>
              <Tooltip title="Từ chối">
                <div
                  onClick={() => {
                    setIsModalVisible(true);
                    setConfirmJob(0);
                    setJobId(record.job_id);
                    setJobTelesaleId(record.job_telesales_id);
                  }}
                >
                  <CloseCircleOutlined />
                </div>
              </Tooltip>
              <Tooltip title="Chấp nhận">
                <div
                  onClick={() => {
                    setIsModalVisible(true);
                    setConfirmJob(1);
                    setJobId(record.job_id);
                    setJobTelesaleId(record.job_telesales_id);
                  }}
                >
                  <CheckCircleOutlined />
                </div>
              </Tooltip>
            </Row>
          ) : (
            <Button
              style={{
                backgroundColor: '#ef8737',
                border: 'none',
                color: 'white',
                borderRadius: 5,
                padding: 5,
              }}
              onClick={() => {
                mixpanel.track(`Goto detail ${record.names}`);
                dispatch(
                  getNextCustomer({
                    jobId: record.job_id,
                    currentPageNumber: formattedPaging.current,
                  })
                );
                history.push({
                  pathname: `${ROUTER.JOB_DETAIL_SALE + '/' + record.job_id}`,
                  state: {},
                });
              }}
            >
              Bắt đầu ❯❯
            </Button>
          )}
        </>
      ),
    },
    {
      title: <b>Nội dung giao việc</b>,
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => (
        <>
          <JobNameStyled
            onClick={() => {
              history.push({
                pathname: ROUTER.JOB_RESULT_SALE + `/${record.job_id}`,
              });
            }}
          >
            {value ? value : '---'}
          </JobNameStyled>
          <Row>
            <Avatar src={record.avatar} /> &nbsp;&nbsp;&nbsp;
            {value ? record.full_name : '---'}
          </Row>
        </>
      ),
    },
    {
      title: <b>Tiến độ</b>,
      dataIndex: '',
      key: '',
      width: 230,
      render: (value, record) => (
        <div style={{ padding: '0 5px 0 5px', width: 200 }}>
          <Progress
            // type="line"
            percent={
              record.totalCustomer === 0
                ? 0
                : parseFloat(
                    (parseInt(record.called) / parseInt(record.totalCustomer)) *
                      100
                  ).toFixed(1)
            }
            format={percent => `${percent}%`}
          />
          {`${parseInt(record.called)}` +
            '/' +
            `${parseInt(record.totalCustomer)}`}{' '}
          Khách hàng
        </div>
      ),
    },
    {
      title: <b>Đồng ý trao đổi</b>,
      dataIndex: 'call_successfully',
      key: 'call_successfully',
      render: (value, record) => <>{value ? value : '---'}</>,
    },
    {
      title: <b>Khách hàng tiềm năng</b>,
      dataIndex: 'total_opportunity_accepted',
      key: 'total_opportunity_accepted',
      render: (value, record) => <>{value ? value : '---'}</>,
    },
    {
      title: <b>Chốt hợp đồng</b>,
      dataIndex: 'total_opportunity_success',
      key: 'total_opportunity_success',
      render: (value, record) => <>{value ? value : '---'}</>,
    },
    {
      title: <b>Trạng thái</b>,
      dataIndex: '',
      key: '',
      render: (value, record) => {
        // switch (value) {
        //   case 1:
        //     return 'Chưa nhận việc';
        //   case 3:
        //     return 'Đang thực hiện';
        //   case 4:
        //     return 'Hoàn thành';
        //   default:
        //     return '---';
        // }
        if (record?.job_telesales_status === 2) {
          return <span>Chưa nhận việc</span>;
        }
        if (
          parseInt(record.called) === 0 &&
          record?.job_telesales_status !== 2
        ) {
          return <span>Đã nhận việc</span>;
        }
        if (
          parseInt(record.called) &&
          parseInt(record.called) < parseInt(record.totalCustomer)
        ) {
          return <span>Đang thực hiện</span>;
        }
        if (
          parseInt(record.called) &&
          parseInt(record.called) >= parseInt(record.totalCustomer)
        ) {
          return <span>Hoàn thành</span>;
        }
      },
    },
    {
      title: <b>Ngày cập nhật</b>,
      dataIndex: 'modified_date',
      key: 'modified_date',
      render: value => <>{moment(value).format('DD/MM/YYYY')}</>,
    },
    {
      title: null,
      dataIndex: '',
      key: '',
      render: (_, record) => {
        return (
          <div
            onClick={() => {
              history.push({
                pathname: ROUTER.JOB_RESULT_SALE + `/${record.job_id}`,
              });
            }}
          >
            <BarsOutlined />
          </div>
        );
      },
    },
  ];
  return (
    <div className="content-wrapper">
      <BreadcrumbAntStyle>
        <Breadcrumb.Item>Danh sách công việc</Breadcrumb.Item>
      </BreadcrumbAntStyle>
      <Wrapper>
        <Filter
          params={params}
          setParams={setParams}
          handleChangeParamSearchCustomers={handleChangeParamSearchCustomers}
          user={user?.user_enterprise_info?.office_name}
        />
      </Wrapper>
      <Wrapper>
        <b>{formattedPaging.total} </b>kết quả
        <Table
          size="middle"
          loading={isLoading}
          bordered
          columns={column}
          dataSource={jobList}
          scroll={{
            x: 800,
            scrollToFirstRowOnChange: true,
            // y: 'calc(100vh - 430px)',
          }}
          onRow={(record, rowIndex) => ({
            onClick: () => {
              //   history.push({
              //     pathname: `${ROUTER.OPPORTUNITY_DETAIL_SALE + '/' + record.id}`,
              //     state: {},
              //   });
            },
          })}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu.</span>}
              />
            ),
            triggerDesc: '',
            triggerAsc: '',
            cancelSort: '',
          }}
          pagination={{
            ...formattedPaging,
            showSizeChanger: false,
            onChange: async (page, pageSize) => {
              setParams({ ...params, page });
            },
          }}
        />
      </Wrapper>
      <Modal
        visible={isModalVisible}
        destroyOnClose
        okText="Xác nhận"
        onOk={() => {
          if (confirmJob !== 1 && !reason) {
            notifyFail('Hãy cho biết lý do từ chối CV này');
          } else {
            postConfirmJob();
          }
        }}
        cancelText="Huỷ"
        onCancel={() => {
          setIsModalVisible(false);
          setReason();
        }}
      >
        {confirmJob === 1 ? (
          <>Bạn có chắc chắn muốn công việc này?</>
        ) : (
          <div>
            <span>Từ chối</span>
            <Input.TextArea
              maxLength={256}
              showCount
              placeholder="Lý do từ chối"
              value={reason}
              onChange={e => {
                setReason(e.target.value.trimStart());
              }}
            />
          </div>
        )}
      </Modal>
    </div>
  );
}

export default JobSaleScreen;
