import React from 'react';
import InteractionHistoryHeader from './InteractionHistoryHeader';

export default function Create({ interactionHistory }) {
  return (
    <>
      <InteractionHistoryHeader interactionHistory={interactionHistory} />
    </>
  );
}
