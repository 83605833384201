export function removeAccents(str) {
    if (!str) {
        return str;
    }
    /*
  const dungSan = str.normalize('NFC');
  // NFC — Normalization Form Canonical Composition. — Dựng Sẵn (mặc định)
  const toHop = str.normalize('NFD');
  // NFD — Normalization Form Canonical Decomposition. — Tổ Hợp
*/

    // Chuyển hết sang chữ thường
    str = str.toLowerCase();

    // xóa dấu
    str = str
        .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
        .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp

    // Thay ký tự đĐ
    str = str.replace(/[đĐ]/g, "d");

    // Xóa ký tự đặc biệt
    str = str.replace(/([^0-9a-z-\s])/g, "");

    // Xóa khoảng trắng liên tiếp
    str = str.replace(/\s+/g, " ");

    // return
    return str;
}

export function removeAccentsZalo(str) {
    if (!str) {
        return str;
    }
    /*
  const dungSan = str.normalize('NFC');
  // NFC — Normalization Form Canonical Composition. — Dựng Sẵn (mặc định)
  const toHop = str.normalize('NFD');
  // NFD — Normalization Form Canonical Decomposition. — Tổ Hợp
*/

    // Chuyển hết sang chữ thường
    // str = str.toLowerCase();

    // xóa dấu
    str = str
        .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
        .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp

    // Thay ký tự đĐ
    str = str.replace(/[đĐ]/g, "d");

    // Xóa ký tự đặc biệt
    // str = str.replace(/([^0-9a-z-\s])/g, "");

    // Xóa khoảng trắng liên tiếp
    str = str.replace(/\s+/g, "");

    // return
    return str;
}

export function toSlug(str) {
    // Chuyển hết sang chữ thường
    str = removeAccents(str);

    // Xóa ký tự đặc biệt
    str = str.replace(/([^0-9a-z-\s])/g, "");

    // Xóa khoảng trắng thay bằng ký tự -
    str = str.replace(/(\s+)/g, "-");

    // Xóa ký tự - liên tiếp
    str = str.replace(/-+/g, "-");

    // xóa phần dư - ở đầu & cuối
    str = str.replace(/^-+|-+$/g, "");

    // return
    return str;
}

export function checkNormalizePhone(phone) {
    if (!phone) {
        return "";
    }
    let nomalizedPhone = phone;
    nomalizedPhone = phone.replace(/[^0-9]/g, "");

    nomalizedPhone = nomalizedPhone.replace(/^([^0-9])/gi, "");
    if (phone.length >= 10) {
        nomalizedPhone = nomalizedPhone.replace(/^(84)/gi, "");
    }

    nomalizedPhone = nomalizedPhone.replace(/^([0]+)/gi, "0");
    if (nomalizedPhone.length == 8) {
        nomalizedPhone = nomalizedPhone.replace(/^([0])/gi, "");
    } else {
        nomalizedPhone = nomalizedPhone.replace(/^([1-9])/gi, "0$1");
    }
    return nomalizedPhone.length < 8 || nomalizedPhone.length > 12;
}
