import { formatPrice } from '@constants/funcHelper';
import {
  ExpandInfoStyled,
  IconStyled,
} from '@screens/Enterprise/Opportunity/components/styled';
import { DescriptionCustomInteractionHistory } from '@styles/Style';
import { Descriptions } from 'antd';
import moment from 'moment';
import React, { Fragment, useState } from 'react';
import { arrow_up } from 'react-icons-kit/ikons/arrow_up';
import styled from 'styled-components';
import InteractionHistoryHeader from './InteractionHistoryHeader';

const HeadingStyled = styled('h6')`
  margin: 12px 0;
  font-weight: 600;
  font-size: 14px;
`;

const EVoucherDetail = styled('div')`
  margin: 4px 0 0 20px;
  display: flex;
  justify-content: space-between;

  p {
    min-width: 120px;
    margin: 0;
  }
`;

const EVoucherDetailItem = styled('div')`
  width: 50%;
`;

const renderPromotionType = eVoucherData => {
  const types = [];
  if (eVoucherData.type_discount_money === 1) {
    types.push('Giảm giá theo tổng tiền');
  }
  if (eVoucherData.type_discount_product === 1) {
    types.push('Giảm giá theo sản phẩm');
  }
  if (eVoucherData.type_save_point === 1) {
    types.push('Tích điểm');
  }
  if (eVoucherData.e_voucher_customer_gifts?.length > 0) {
    types.push('Tặng quà');
  }

  return types.join(', ');
};

const renderProductCondition = eVoucherData => {
  return eVoucherData.condition_product.map(prod => {
    return (
      <div key={prod.product_id}>
        <EVoucherDetail>
          <p>Sản phẩm: </p>
          <span>{prod.name}</span>
        </EVoucherDetail>
        <EVoucherDetail>
          <p>Số lượng: </p>
          <span>{prod.amount}</span>
        </EVoucherDetail>
        <EVoucherDetail>
          <p>Thành tiền: </p>
          <span>{formatPrice(prod.money)} VNĐ</span>
        </EVoucherDetail>
      </div>
    );
  });
};

const renderTimeCondition = eVoucherData =>
  eVoucherData?.condition_time.map(item => {
    return (
      <span key={item.key_id}>
        Từ <b>{item.start_at}</b> đến <b>{item.finish_at}</b>
      </span>
    );
  });

const renderTotalDiscountValue = eVoucherData => {
  return eVoucherData?.type_discount_money === 1
    ? formatPrice(eVoucherData?.discount_money)
    : `${eVoucherData?.discount_money}%`;
};

const renderProductDiscountValue = eVoucherData => {
  return eVoucherData?.type_discount_product === 1
    ? formatPrice(eVoucherData?.discount_product)
    : `${eVoucherData?.discount_product}%`;
};

const renderSavePoint = eVoucherData => {
  return eVoucherData?.type_save_point === 1
    ? `Tích điểm: ${formatPrice(eVoucherData?.save_point)} điểm`
    : `Tích điểm: ${formatPrice(
        eVoucherData?.save_point
      )}% theo tổng tiền đơn hàng (${
        eVoucherData?.convert_point.point
      } điểm = ${formatPrice(eVoucherData?.convert_point.money)} VNĐ)`;
};

function EVoucherActivator({ interactionHistory }) {
  const [isExpand, setIsExpand] = useState(false);
  const eVoucherData = interactionHistory.data.evoucher_config;

  return (
    <Fragment>
      <InteractionHistoryHeader interactionHistory={interactionHistory} />
      <DescriptionCustomInteractionHistory column={1}>
        <Descriptions.Item label="Loại ưu đãi">
          {renderPromotionType(eVoucherData)}
        </Descriptions.Item>
        {eVoucherData.save_point && (
          <Descriptions.Item label="Số điểm">
            {interactionHistory.data.point.point}
          </Descriptions.Item>
        )}
      </DescriptionCustomInteractionHistory>
      <div>
        <div
          className="d-flex align-items-center"
          onClick={() => setIsExpand(!isExpand)}
          style={{ cursor: 'pointer' }}
        >
          <HeadingStyled>Chi tiết mã giảm giá</HeadingStyled>
          <IconStyled
            icon={arrow_up}
            className={isExpand && 'isExpand'}
            style={{ paddingBottom: '3px' }}
          />
        </div>
        {isExpand && (
          <ExpandInfoStyled
            className={`discount-code-container ${isExpand ? 'expanded' : ''}`}
            style={{ display: 'flex', flexDirection: 'row', gap: '50px' }}
          >
            {/* Left */}
            <EVoucherDetailItem>
              <EVoucherDetail>
                <p>Tên chương trình: </p>
                <span>{eVoucherData.name}</span>
              </EVoucherDetail>
              <EVoucherDetail>
                <p>Mã chương trình: </p>
                <span>{eVoucherData.code}</span>
              </EVoucherDetail>
              <EVoucherDetail>
                <p>Thời gian áp dụng: </p>
                <span>
                  Từ {moment(eVoucherData.start_at).format('DD-MM-YYYY')}{' '}
                  {eVoucherData.finish_at
                    ? `- ${moment(eVoucherData.finish_at).format('DD-MM-YYYY')}`
                    : ''}
                </span>
              </EVoucherDetail>
              {(eVoucherData.condition_total_money ||
                eVoucherData?.condition_product?.length > 0) && (
                <HeadingStyled>Điều kiện áp dụng</HeadingStyled>
              )}
              {eVoucherData.condition_total_money && (
                <EVoucherDetail>
                  <p>Tổng tiền đơn hàng: </p>
                  <span>
                    {formatPrice(eVoucherData?.condition_total_money)} VNĐ
                  </span>
                </EVoucherDetail>
              )}
              {eVoucherData?.condition_product?.length > 0 &&
                renderProductCondition(eVoucherData)}
            </EVoucherDetailItem>

            {/* Right */}
            <EVoucherDetailItem>
              {(!!eVoucherData.condition_time ||
                eVoucherData.condition_other) && (
                <HeadingStyled>Thời gian mua hàng: </HeadingStyled>
              )}
              {!!eVoucherData.condition_time && (
                <EVoucherDetail>
                  <p style={{ flex: '1' }}>Ngày: </p>
                  <div
                    style={{
                      width: '70%',
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {renderTimeCondition(eVoucherData)}
                  </div>
                </EVoucherDetail>
              )}
              {eVoucherData.condition_other && (
                <EVoucherDetail>
                  <p>Điều kiện khác: </p>
                  <span>{eVoucherData.condition_other}</span>
                </EVoucherDetail>
              )}
              <HeadingStyled>Mã giảm giá: </HeadingStyled>
              {eVoucherData?.discount_money && (
                <EVoucherDetail>
                  <p>
                    Giảm giá {renderTotalDiscountValue(eVoucherData)} theo tổng
                    tiền
                  </p>
                </EVoucherDetail>
              )}

              {eVoucherData?.discount_product && (
                <EVoucherDetail>
                  <p>
                    Giảm giá <b>{renderProductDiscountValue(eVoucherData)}</b>{' '}
                    theo sản phẩm
                  </p>
                </EVoucherDetail>
              )}
              {eVoucherData?.save_point && (
                <EVoucherDetail>{renderSavePoint(eVoucherData)}</EVoucherDetail>
              )}
              {eVoucherData?.recommend_gift.point && (
                <EVoucherDetail>
                  Tặng điểm cho người giới thiệu:{' '}
                  {formatPrice(eVoucherData?.recommend_gift.point)} điểm
                </EVoucherDetail>
              )}
              {eVoucherData.convert_point.point && (
                <EVoucherDetail>
                  <p>
                    Quy đổi điểm:{' '}
                    {`${formatPrice(
                      eVoucherData?.convert_point.point
                    )} điểm = ${formatPrice(
                      eVoucherData?.convert_point.money
                    )} VNĐ`}
                  </p>
                </EVoucherDetail>
              )}
            </EVoucherDetailItem>
          </ExpandInfoStyled>
        )}
      </div>
    </Fragment>
  );
}

export default EVoucherActivator;
