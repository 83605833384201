import { requestCustomerGroupAddCustomers } from '@constants/Api';
import CustomerList from '@screens/Enterprise/Customer/components/CustomerList';
import Loading from '@src/components/Loading';
import '@styles/Customer.css';
import { notifyFail, notifySuccess } from '@utils/notify';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';

class AddCustomersToGroupModal extends Component {
  static propTypes = {
    schemas: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any,
      })
    ).isRequired,
    payload: PropTypes.shape({
      enterprise_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      exclude_group_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      group_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    group_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    shown: PropTypes.bool,
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      requesting: false,
      customer_ids: [],
      checkFullCustomers: false,
      //callback lấy filter khi chọn tất cả khách hàng
      filterChanged: {},
    };
  }

  handleCheckFullCustomer = (check) => {
    this.setState({
      checkFullCustomers: check,
    });
  };

  onSelectedCustomersChanged = (customerIds = []) => {
    let arr = [...customerIds];
    this.setState({ customer_ids: [...arr] });
  };

  onAddCustomersPressed = () => {
    this.setState({ requesting: true });
    const { payload, group_id } = this.props;
    const { customer_ids } = this.state;
    let requestPayload = {
      id: group_id,
      enterprise_id: payload.enterprise_id,
      customer_ids,
    };
    if (this.state.checkFullCustomers) {
      requestPayload = {
        id: group_id,
        enterprise_id: payload.enterprise_id,
        full_customer_ids: 1,
        ...this.state.filterChanged,
        query: this.state.filterChanged.search,
      };
    }
    requestCustomerGroupAddCustomers(requestPayload)
      .then((res) => {
        this.setState({ requesting: false }, () => {
          this.props.onClose();
          if (this.props.onSuccess) {
            this.props.onSuccess();
          } else {
            window.location.reload();
          }
        });
        notifySuccess('Thêm khách hàng thành công');
      })
      .catch((err) => {
        console.log(err);
        this.setState({ requesting: false });
        notifyFail(err.msg);
      });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.shown && this.state.checkFullCustomers) {
      this.setState({ checkFullCustomers: false });
    }
  }

  render() {
    return (
      <Modal show={this.props.shown} onHide={this.props.onClose} centered size="xl">
        <Modal.Header closeButton className="pb-0">
          <Modal.Title>Thêm khách hàng</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.props.isLoading ? <Loading /> : null}
          <CustomerList
            payload={this.props.payload}
            resultCountText="Kết quả lọc: "
            showCheckbox
            onSelectionChanged={this.onSelectedCustomersChanged}
            //Chọn tất cả hay không
            checkFullCustomers={this.state.checkFullCustomers}
            handleCheckFullCustomer={this.handleCheckFullCustomer}
            //callback lấy filter khi chọn tất cả khách hàng
            onFilterChanged={(filterData) => {
              this.setState({ filterChanged: filterData });
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="text-light" onClick={this.props.onClose}>
            Đóng
          </Button>
          <Button
            variant="primary"
            disabled={!this.state.customer_ids?.length}
            className="text-light"
            onClick={this.onAddCustomersPressed}
          >
            Thêm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default AddCustomersToGroupModal;

// const mapStateToProps = (state) => ({
//   provinceState: state.ProvinceReducer,
// });

// const mapDispatchToProps = {
//   getProvinceList,
// };
// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddCustomersToGroupModal));
