import { formatPrice, formatTime, setSearchParamsInUrlVer2 } from '@constants/funcHelper';
import { Empty, Row, Table } from 'antd';
import React, { useState } from 'react';

function RechargeHistory(props) {
  const { data, currentBalance } = props;
  const [params, setParams] = useState();
  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 0,
  });
  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      //   render: (stt) => (currentPageNumber - 1) * limitPerPage + stt,
    },
    {
      title: 'Thời gian nạp',
      dataIndex: 'phone',
      render: (value) => <>{formatTime(value)}</>,
    },
    {
      title: 'Số tiền nạp',
      dataIndex: 'phone',
      render: (value) => <>{value !== 0 ? '+' + formatPrice(value) : '0'}</>,
    },
    {
      title: 'Số tiền trước khi nạp',
      dataIndex: 'phone',
      render: (value) => <>{value !== 0 ? formatPrice(value) : '0'}</>,
    },
    {
      title: 'Số tiền sau khi nạp',
      dataIndex: 'phone',
      render: (value) => <>{value !== 0 ? formatPrice(value) : '0'}</>,
    },
  ];
  return (
    <>
      <Row justify="end">
        <div>Số dư hiện tại:&nbsp; </div>
        <div style={{ color: 'tomato' }}>{formatPrice(currentBalance)}&nbsp;VNĐ</div>
      </Row>
      <br />
      <Table
        columns={columns}
        dataSource={data}
        scroll={{
          x: 'max-content',
        }}
        pagination={{
          showSizeChanger: false,
          ...paging,
          onChange: async (page, pageSize) => {
            setParams({ ...params, page });
            setSearchParamsInUrlVer2({ page });
          },
        }}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>Không có dữ liệu.</span>} />,
          triggerDesc: '',
          triggerAsc: '',
          cancelSort: '',
        }}
      />
    </>
  );
}

export default RechargeHistory;
