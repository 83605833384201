import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tabs } from 'antd';

import { getSearchParamsInUrl } from '@constants/funcHelper';
import { VietnameseString } from '@constants/VietnameseString';
import ZNSSendingHistory from './components/ZNSSendingHistory';
import RechargeHistory from './components/RechargeHistory';
import Wrapper from '@components/ui/wrapper/Wrapper';
import MessHistory from './components/MessHistory';

import { primaryColor } from '@styles/Color';
import { TabsStyled } from '@styles/Style';
import ZaloSendingHistory from './components/ZaloSendingHistory';

const tabs = [
  {
    tab: VietnameseString.sms_sending_history,
    key: 'sms_sending_history',
    component: MessHistory,
  },
  {
    tab: VietnameseString.zns_sending_history,
    key: 'zns_sending_history',
    component: ZNSSendingHistory,
  },
  {
    tab: 'Lịch sử gửi Zalo',
    key: 'zalo_sending_history',
    component: ZaloSendingHistory,
  },
  {
    tab: VietnameseString.sms_recharge_history,
    key: 'sms_recharge_history',
    component: RechargeHistory,
  },
  {
    tab: VietnameseString.zns_recharge_history,
    key: 'zns_recharge_history',
    component: RechargeHistory,
  },
];

function MessageReport() {
  const history = useHistory();

  const [tab, setTab] = useState(tabs[0].key);

  useEffect(() => {
    const searchParamsInUrl = getSearchParamsInUrl();
    setTab(searchParamsInUrl.tab);
  }, []);

  return (
    <div className="content-wrapper">
      <Wrapper>
        <h1 style={{ color: primaryColor }}>
          {VietnameseString.message_report}
        </h1>

        <TabsStyled
          type="card"
          destroyInactiveTabPane={true}
          activeKey={tab}
          onChange={activeKey => {
            const searchParamsInUrl = new URLSearchParams({
              tab: JSON.stringify(activeKey),
            });
            setTab(activeKey);
            history.replace({
              pathname: window.location.pathname,
              search: searchParamsInUrl.toString(),
            });
          }}
        >
          {tabs.map(({ component: Component, ...i }) => (
            <Tabs.TabPane tab={i.tab} key={i.key}>
              <Component />
            </Tabs.TabPane>
          ))}
        </TabsStyled>
      </Wrapper>
    </div>
  );
}

export default MessageReport;
