import { default as CallUtil } from '@components/callProviders/CallUtil';
import { requestGetCallStatus } from '@constants/Api';
import { notifyFail } from '@utils/notify';
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { requestStartCall } from 'src/network/web_sale/CallApi';
import { requestNextCustomerByJob } from 'src/network/web_sale/JobApi';
import React from 'react';
import { VietnameseString } from '@constants/VietnameseString';
import {
  LIST_OPPORTUNITY_STATUS,
  ROUTER,
  TagCallStatus,
} from '@constants/Constant';
import TagCustom from '@screens/Sale/Customer/componentsInstead/TagCustom';
import moment from 'moment';
import clockCell from '@assets/clockcell2.png';
import { AudioStyle, TableBorderedAntStyle } from '@styles/Style';
import { getDay, getStatusCall } from '@constants/funcHelper';
import { MenuOutlined, SearchOutlined, PhoneFilled } from '@ant-design/icons';
import {
  Button,
  Col as ColAntd,
  DatePicker,
  Divider,
  Dropdown,
  Input,
  List,
  Pagination,
  Popover,
  Row as RowAntd,
  Space,
  Spin,
  Tag,
  Tooltip,
} from 'antd';

import { Col, FormControl, Row } from 'react-bootstrap';
import MultiSelectControl from '@components/ui/forms/MultiSelectControl';
import styled from 'styled-components';
import callblue from '@assets/commingcall.png';
import callgreen from '@assets/outcall.png';
import { ButtonStyled, colSpan, PhoneFilledStyled } from './../../StyleJob';

const CustomerList = props => {
  const {
    data,
    params,
    handleChangeParamSearchCustomers,
    tagList,
    setParams,
    formattedPaging,
    onChangePage,
    onNavigate,
    setVisibleOppoModal,
    setRecordCustomer,
    getNextCustomerWithJob,
    setVisibleRecallModal,
    loading,
  } = props;
  const { id } = useParams();
  const history = useHistory();

  const [callStatus, setCallStatus] = useState();
  const [loadingHotlines, setLoadingHotline] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [isSelectSubPhoneNumber, setIsSelectSubPhoneNumber] = useState(false);
  const [selectedSubPhoneNumber, setSelectedSubPhoneNumber] = useState('');

  const [isLoadingNextCustomer, setIsLoadingNextCustomer] = useState(false);
  const [isLoadingNextCustomerBtn2, setIsLoadingNextCustomerBtn2] =
    useState(false);

  const [subPhone, setSubPhone] = useState([]);

  const localtion = useLocation();
  const getCallStatus = async () => {
    try {
      // const res = await requestGetCallStatus();
      // let dataCallStatus = res.data;
      console.log('CallStatus', CallUtil.listCallStatus);
      let dataCallStatus = [...CallUtil.listCallStatus];
      let itemCallBackLater = { id: 0, name: 'Hẹn gọi lại sau' };
      dataCallStatus.push(itemCallBackLater);
      setCallStatus(dataCallStatus);
    } catch (error) {
      console.log('Error getCallStatus: ', { error });
    }
  };

  const DropdownOverlay = props => {
    const {
      history,
      setVisibleOppoModal,
      dataRecord,
      setVisibleRecallModal,
      setRecordCustomer,
    } = props;
    return (
      <>
        <div
          style={{
            padding: '5px 15px 5px 15px',
            backgroundColor: 'white',
            boxShadow:
              '0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d',
          }}
        >
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setVisibleOppoModal(true);
              setRecordCustomer(dataRecord);
            }}
          >
            Tạo cơ hội
          </div>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              history.push({
                pathname: `${
                  ROUTER.EDIT_CUSTOMER + '/' + dataRecord.customer_id
                }`,
                state: {},
              });
            }}
          >
            Chỉnh sửa thông tin
          </div>
        </div>
      </>
    );
  };

  const handleMakeCall = async (customer, callWithSubPhoneNumber = '') => {
    let customerPhoneNumber = customer.unmasked_pnumber;
    let isCallWithSubPhoneNumber = false;

    if (callWithSubPhoneNumber) {
      customerPhoneNumber = callWithSubPhoneNumber;
      isCallWithSubPhoneNumber = true;
    }
    // console.log('customer', customer);
    try {
      // get hotline
      setLoadingHotline(true);
      const hotlines = CallUtil.hotlines;
      if (hotlines.length === 0) {
        notifyFail('Không có hotline phù hợp');
      } else if (hotlines.length === 1) {
        // gọi luôn
        makeCallWithHotline(
          hotlines[0],
          customerPhoneNumber,
          customer.customer_id,
          customer.job_telesale_customer_id,
          isCallWithSubPhoneNumber,
          customer.phone
        );
      } else {
        // hiển thị popup chọn hotline
        setSelectedCustomer(customer.customer_id);
      }
      return;
    } catch (error) {
      console.log('Error handleMakeCall: ', { error });
    } finally {
      setLoadingHotline(false);
    }
  };

  const getNextCustomerByJob = async (uncontacted = true) => {
    if (CallUtil.hotlines.length === 0) {
      notifyFail('Không có hotline phù hợp');
    }

    try {
      uncontacted
        ? setIsLoadingNextCustomer(true)
        : setIsLoadingNextCustomerBtn2(true);

      const res = await requestNextCustomerByJob({
        job_id: id,
        uncontacted: uncontacted ? 1 : 0,
        customer_id: '',
        search: params,
      });

      if (res.data === null) {
        notifyFail('Không có khách hàng phù hợp');
        return;
      }

      const { job_telesale_customer_id, unmasked_pnumber, phone, customer_id } =
        res.data;
      CallUtil.isUncontacted = uncontacted;
      CallUtil.currentCustomerId = customer_id;
      CallUtil.currentJobId = id;
      CallUtil.currentSearch = params;

      let hotline = CallUtil.getDefaultHotlines();

      const payload = {
        job_id: parseInt(id),
        customer_phone_id: '',
        job_telesale_customer_id,
        hotline: hotline.outbound_phone.toString(),
      };
      const callRes = await requestStartCall(payload);
      !uncontacted &&
        localStorage.setItem('previousPage', JSON.stringify(params));

      CallUtil.makeCall(
        hotline.type,
        unmasked_pnumber,
        callRes.data.id,
        phone,
        id,
        uncontacted,
        payload
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingNextCustomer(false);
      setIsLoadingNextCustomerBtn2(false);
    }
  };

  /**
   * function makeCallWithHotline
   * @param {*} hotline
   * @param {*} customerPhoneNumber
   * @param {number} customerId //phục vụ mục đích tìm khách hàng cho nút tiếp theo
   * @param {*} jobTelemarketingCustomerId //bảng danh sách khách hàng của giao việc của sale
   * @param {boolean} isCallWithSubPhoneNumber
   * @param {string} maskedPhone // Số đã che
   */
  const makeCallWithHotline = async (
    hotline, //thông tin hotline gọi
    customerPhoneNumber, //số điện thoại khách hàng gọi
    customerId, // customerId khách hàng gọi
    jobTelemarketingCustomerId, //id telesale customer
    isCallWithSubPhoneNumber = false, //gọi với số phụ hay không
    masked // che hay không che vài số đầu sdt kh
  ) => {
    // console.log(
    //   'customerPhoneNumber',
    //   hotline,
    //   customerPhoneNumber,
    //   customerId,
    //   jobTelemarketingCustomerId,
    //   isCallWithSubPhoneNumber,
    //   masked
    // );
    try {
      // create call ID
      const payload = {
        job_id: parseInt(id),
        customer_phone_id: '',
        job_telesale_customer_id: parseInt(jobTelemarketingCustomerId),
        hotline: hotline.outbound_phone.toString(),
      };

      if (isCallWithSubPhoneNumber && subPhone) {
        const sub = subPhone.find(sub => sub.phone === customerPhoneNumber);
        if (sub) payload.customer_phone_id = sub.id;
      }
      const res = await requestStartCall(payload);

      CallUtil.currentJobId = parseInt(id);
      CallUtil.currentSearch = params;

      localStorage.setItem('previousPage', JSON.stringify(params));

      CallUtil.makeCall(
        hotline.type,
        customerPhoneNumber,
        res.data.id,
        masked,
        parseInt(id),
        CallUtil.isUncontacted,
        payload
      );
    } catch (error) {
      console.log('Error makeCallWithHotline: ', { error });
    } finally {
      setSelectedCustomer(null);
      setSubPhone([]);
      setSelectedSubPhoneNumber('');
    }
  };
  console.log('params', params);
  const handleSelectPhoneNumber = customer => {
    //hiển thị popup chọn số điện thoại chính/phụ

    setIsSelectSubPhoneNumber(true);

    setSubPhone(customer.customer_phone);

    setSelectedCustomer(customer.customer_id);
  };

  useEffect(() => {
    getCallStatus();
  }, []);

  function getTitle(listTooltip) {
    return listTooltip.map(item => {
      return <div key={item.id}>{item.tag_name}</div>;
    });
  }
  const columns = [
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Popover
            content={() => {
              if (isSelectSubPhoneNumber) {
                const subPhoneNumber = subPhone.map(i => i.phone);
                console.log('subphoneNumber', subPhoneNumber);
                return (
                  <>
                    <Divider orientation="center">
                      Chọn số điện thoại phụ
                    </Divider>
                    <List
                      size="small"
                      dataSource={[record.phone, ...subPhoneNumber]}
                      renderItem={subPhone => (
                        <List.Item
                          onClick={() => {
                            if (!CallUtil.getDefaultHotlines(true)) {
                              //nếu không chọn hotline default -> chọn thêm hotline default ở dưới
                              setIsSelectSubPhoneNumber(false);
                              setSelectedSubPhoneNumber(subPhone);
                              handleMakeCall(record, subPhone);
                            } else {
                              //nếu chọn hotline default rồi => gọi luôn
                              makeCallWithHotline(
                                CallUtil.getDefaultHotlines(),
                                subPhone,
                                record.customer_id,
                                record.job_telesale_customer_id,
                                true,
                                subPhone
                              );
                            }
                          }}
                        >
                          <Button type="link">{subPhone.toString()}</Button>
                        </List.Item>
                      )}
                    />
                  </>
                );
              } else {
                if (!CallUtil.getDefaultHotlines(true)) {
                  //nếu không chọn hotline thì show
                  return (
                    <>
                      <Divider orientation="center">Chọn hotline</Divider>
                      <List
                        size="small"
                        dataSource={CallUtil.hotlines}
                        renderItem={hotline => (
                          <List.Item
                            onClick={() => {
                              // if(Cookie.get('valueDropDown')) {
                              // }
                              if (selectedSubPhoneNumber) {
                                makeCallWithHotline(
                                  hotline,
                                  selectedSubPhoneNumber,
                                  record.customer_id,
                                  record.job_telesale_customer_id,
                                  true,
                                  selectedSubPhoneNumber
                                );
                              } else {
                                makeCallWithHotline(
                                  hotline,
                                  record.unmasked_pnumber,
                                  record.customer_id,
                                  record.job_telesale_customer_id,
                                  false,
                                  record.phone
                                );
                              }
                            }}
                          >
                            <Button type="link">
                              {hotline.outbound_phone.toString()}
                            </Button>
                          </List.Item>
                        )}
                      />
                    </>
                  );
                } else {
                  return null;
                }
              }
            }}
            trigger="click"
            visible={
              selectedCustomer === record.customer_id &&
              selectedCustomer &&
              CallUtil.hotlines.length > 0
            }
            onVisibleChange={isShow => {
              if (!isShow) {
                setSelectedCustomer(null);

                setIsSelectSubPhoneNumber(false);
              }
            }}
          >
            <PhoneFilledStyled
              loading={
                selectedCustomer === record.customer_id && loadingHotlines
              }
              onClick={() => {
                // nếu không chọn 1 hotline
                if (!CallUtil.getDefaultHotlines(true)) {
                  if (
                    Array.isArray(record.customer_phone) &&
                    record.customer_phone.length
                  ) {
                    handleSelectPhoneNumber(record);
                  } else {
                    handleMakeCall(record);
                  }
                } else {
                  //nếu có chọn 1 hotline default
                  if (record?.customer_phone.length > 0) {
                    //nếu khách hàng có số phụ + chọn 1 hotline default
                    handleSelectPhoneNumber(record); //cho khách hàng chọn số phụ
                  } else {
                    //khách hàng không có số phụ  + chọn 1 hotline default => gọi luôn theo hotline
                    makeCallWithHotline(
                      CallUtil.getDefaultHotlines(),
                      record.unmasked_pnumber,
                      record.customer_id,
                      record.job_telesale_customer_id,
                      false,
                      record.phone
                    );
                  }
                }
              }}
            />
          </Popover>
        </Space>
      ),
    },
    {
      title: 'Họ và tên',
      dataIndex: 'name',
      key: 'name',
      render: value => <span>{value || VietnameseString.not_update}</span>,
      onCell: record => {
        return {
          onClick: () =>
            onNavigate(record.customer_id, record.job_telesale_customer_id),
        };
      },
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone',
      render: (value, record) => {
        if (
          Array.isArray(record.customer_phone) &&
          record.customer_phone?.length
        ) {
          return (
            <Tooltip
              title={record.customer_phone.map((subItem, index) => {
                return (
                  <div key={index}>
                    <div>{subItem.phone}</div>
                  </div>
                );
              })}
            >
              {value}
            </Tooltip>
          );
        } else {
          return <span>{value || VietnameseString.not_update}</span>;
        }
      },
    },
    {
      title: 'Trạng thái cơ hội',
      dataIndex: 'opportunity_status',
      key: 'opportunity_status',
      render: value => {
        const opportunity = LIST_OPPORTUNITY_STATUS.find(op => op.id === value);

        return (
          <span>
            {opportunity ? opportunity.name : VietnameseString.not_update}
          </span>
        );
      },
    },
    {
      title: 'Phân loại',
      dataIndex: 'customer_tags',
      key: 'customer_tags',
      render: tags => {
        console.log('tags', tags);
        return (
          <span style={{ display: 'inline-flex' }}>
            {tags.length ? (
              <>
                {tags.map((i, idx) => {
                  if (idx < 2) {
                    return (
                      <TagCustom
                        key={i.tag_id}
                        tagColor={i.tag_color}
                        tagName={i.tag_name}
                      />
                    );
                  } else return <></>;
                })}

                {tags.length > 2 ? (
                  <Tooltip title={getTitle(tags.slice(2))}>
                    <div>
                      <TagCustom
                        tagColor={''}
                        tagName={`+ ${tags.length - 2}`}
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <></>
                )}
              </>
            ) : (
              VietnameseString.not_update
            )}
          </span>
        );
      },
    },
    {
      title: 'Ngày giao việc',
      dataIndex: 'job_created_at',
      width: '190px',
      key: 'job_created_at',
      render: value => moment(value).format('HH:mm - DD/MM/YYYY'),
    },
    {
      title: 'Cuộc gọi gần nhất',
      dataIndex: 'modified_date',
      key: 'call_time',
      width: '230px',
      render: (value, record) => {
        let statusCall =
          record?.df_call_status_name || record?.call_status_name;
        if (value) {
          return (
            <div>
              <div>
                {/* {moment
                    .utc(new Date(value))
                    .local()
                    .format('HH:mm - DD/MM/YYYY')} */}
                {moment(value).format('HH:mm - DD/MM/YYYY')}
              </div>
              {record?.content_audio_url ? (
                <div
                  style={{
                    width: '100%',
                    // display: 'flex',
                    // justifyContent: 'space-between',
                    // alignItems: 'center',
                    backgroundColor: '#f1f3f4',
                    borderRadius: '20px',
                    padding: '0 5px ',
                    marginBottom: '5px',
                  }}
                >
                  <AudioStyle
                    style={{ width: '100%' }}
                    controls
                    autoplay
                    className="audio-element"
                  >
                    <source src={record?.content_audio_url}></source>
                    {/* <source src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"></source> */}
                  </AudioStyle>
                </div>
              ) : null}
              {statusCall && (
                <Tag color={TagCallStatus[statusCall]}>{statusCall}</Tag>
              )}
            </div>
          );
        } else {
          return VietnameseString.not_update;
        }
      },
    },
    {
      title: 'Lịch hẹn gọi lại',
      dataIndex: 'time_recall',
      key: 'time_recall',
      render: value => {
        // console.log('value1', value);
        return value ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={clockCell}
              alt=""
              width="20px"
              style={{
                marginRight: '5px',
              }}
            />
            <span>{getDay(value)}</span>
          </div>
        ) : (
          ''
        );
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: value => <div>{getStatusCall(value)}</div>,
    },

    {
      title: 'Ghi chú cuộc gọi',
      dataIndex: 'call_note',
      key: 'call_note',
      render: value => <span>{value || VietnameseString.not_update}</span>,
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (_, record) => {
        return (
          <Dropdown
            overlay={DropdownOverlay({
              history: history,
              dataRecord: record,
              onNavigate: onNavigate,
              setVisibleOppoModal: setVisibleOppoModal,
              setVisibleRecallModal: setVisibleRecallModal,
              setRecordCustomer: setRecordCustomer,
            })}
            trigger={['click']}
          >
            <div onClick={() => {}}>
              <MenuOutlined />
            </div>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <Spin spinning={loading}>
      <Row>
        <Col>
          {/* Filter */}
          <div style={{ margin: '0 1rem 0 1rem' }}>
            <RowAntd gutter={(16, 16)}>
              <ColAntd {...colSpan}>
                <Input
                  allowClear
                  style={{ width: '100%', marginTop: 10 }}
                  prefix={<SearchOutlined />}
                  placeholder="Tên khách hàng, số điện thoại"
                  value={params?.search}
                  onSearch={searchKey =>
                    handleChangeParamSearchCustomers('search', searchKey)
                  }
                  onChange={e =>
                    handleChangeParamSearchCustomers('search', e.target.value)
                  }
                />
              </ColAntd>
              <ColAntd {...colSpan}>
                <FormControl
                  as="select"
                  value={params.status}
                  onChange={e => {
                    handleChangeParamSearchCustomers('status', e.target.value);
                  }}
                  style={{ marginTop: 10 }}
                >
                  <option value="">Trạng thái</option>
                  <option value="3">Chưa gọi</option>
                  <option value="4">Đã gọi</option>
                </FormControl>
              </ColAntd>
              <ColAntd {...colSpan}>
                <MultiSelectControl
                  style={{ marginTop: 10 }}
                  options={tagList}
                  value={params.tag_id.length > 0 ? params.tag_id : undefined}
                  onChange={value => {
                    handleChangeParamSearchCustomers('tag_id', value);
                  }}
                  placeholder={'Chọn phân loại'}
                  className="w-100"
                />
              </ColAntd>
              <ColAntd {...colSpan}>
                <FormControl
                  as="select"
                  value={params.opportunity_status}
                  onChange={e => {
                    handleChangeParamSearchCustomers(
                      'opportunity_status',
                      e.target.value
                    );
                  }}
                  style={{ marginTop: 10 }}
                >
                  <option value="">Trạng thái cơ hội</option>
                  <option value="1">Khách hàng tiềm năng</option>
                  <option value="3">Chốt hợp đồng</option>
                  <option value="4">Khách hàng từ chối</option>
                </FormControl>
              </ColAntd>
              <ColAntd {...colSpan}>
                <DatePicker.RangePicker
                  allowClear
                  style={{ width: '100%', marginTop: 10 }}
                  placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                  format={'YYYY/MM/DD'}
                  value={[
                    params?.fromDate
                      ? moment(params?.fromDate, 'YYYY/MM/DD')
                      : '',
                    params?.toDate ? moment(params?.toDate, 'YYYY/MM/DD') : '',
                  ]}
                  onChange={(date, dateString) => {
                    console.log('date', date, dateString);
                    if (date !== null) {
                      setParams({
                        ...params,
                        fromDate: dateString[0],
                        toDate: dateString[1],
                        page: 1,
                      });
                    } else {
                      setParams({
                        ...params,
                        fromDate: '',
                        toDate: '',
                      });
                    }
                  }}
                />
              </ColAntd>
              <ColAntd {...colSpan}>
                <DatePicker.RangePicker
                  allowClear
                  style={{ width: '100%', marginTop: 10 }}
                  placeholder={[
                    'Ngày bắt đầu giao việc',
                    'Ngày kết thúc giao việc',
                  ]}
                  format={'YYYY/MM/DD'}
                  value={[
                    params?.fromDateJob
                      ? moment(params?.fromDateJob, 'YYYY/MM/DD')
                      : '',
                    params?.toDateJob
                      ? moment(params?.toDateJob, 'YYYY/MM/DD')
                      : '',
                  ]}
                  onChange={(date, dateString) => {
                    if (date !== null) {
                      setParams({
                        ...params,
                        fromDateJob: dateString[0],
                        toDateJob: dateString[1],
                        page: 1,
                      });
                    } else {
                      setParams({
                        ...params,
                        fromDateJob: '',
                        toDateJob: '',
                      });
                    }
                  }}
                />
              </ColAntd>
              <ColAntd {...colSpan}>
                <Input
                  style={{ marginTop: 10 }}
                  allowClear
                  // type="text"
                  placeholder="Ghi chú cuộc gọi"
                  value={params.call_note}
                  onChange={e => {
                    handleChangeParamSearchCustomers(
                      'call_note',
                      e.target.value
                    );
                  }}
                />
              </ColAntd>
              <ColAntd {...colSpan}>
                <FormControl
                  as="select"
                  value={
                    params.time_recall_status == 1 ? 0 : params.call_status_id
                  }
                  onChange={e => {
                    handleChangeParamSearchCustomers(
                      'call_status_id',
                      e.target.value
                    );
                  }}
                  style={{ marginTop: 10 }}
                >
                  <option value="">Trạng thái cuộc gọi</option>
                  {callStatus
                    ? callStatus?.map((item, index) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))
                    : ''}
                </FormControl>
              </ColAntd>
            </RowAntd>
            <div
              style={{
                justifyContent: 'flex-end',
                display: 'flex',
                gap: '10px',
                marginTop: '20px',
              }}
            >
              <ButtonStyled
                loading={isLoadingNextCustomer}
                type="primary"
                style={{ color: 'black' }}
                onClick={() => getNextCustomerByJob()}
                ghost
                icon={
                  <img
                    src={callblue}
                    alt=""
                    width="20px"
                    style={{ marginRight: '5px', marginBottom: '2px' }}
                  />
                }
              >
                Gọi cho khách hàng chưa gọi
                {/* <DoubleRightOutlined /> */}
              </ButtonStyled>
              <ButtonStyled
                loading={isLoadingNextCustomerBtn2}
                ghost
                style={{ borderColor: 'green', color: 'black' }}
                type="primary"
                onClick={() => getNextCustomerByJob(false)}
                icon={
                  <img
                    src={callgreen}
                    alt=""
                    width="20px"
                    style={{ marginRight: '5px', marginBottom: '2px' }}
                  />
                }
              >
                Gọi theo DSKH
                {/* <DoubleRightOutlined /> */}
              </ButtonStyled>
            </div>
          </div>

          {/* Bảng dữ liệu */}

          <Row className="table-wrap mx-3">
            <TableBorderedAntStyle
              style={{ width: '100%' }}
              dataSource={data}
              columns={columns}
              pagination={false}
              scroll={{ x: 992 }}
            />
          </Row>

          {/* Pagination */}
          <div className="mx-3 text-right">
            <Pagination
              showSizeChanger={false}
              {...formattedPaging}
              onChange={onChangePage}
            />
          </div>
        </Col>
      </Row>
    </Spin>
  );
};

export default CustomerList;
