import React from 'react';
import logo from '@assets/logo-ezsale.png';

const EZLoading = () => {
  return (
    <div
      class="loader--app"
      style={{ zIndex: 999, backgroundColor: 'transparent' }}
    >
      <img
        src={logo}
        class="blink_me"
        alt="logo"
        width="200px"
        height="200px"
      />
    </div>
  );
};

export default EZLoading;
