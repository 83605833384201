import React, { Component } from 'react';
import { Row, Col, Button, FormControl, FormGroup, Form, Table, Modal } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';

import { ROUTER, STATUS, NUMBER } from '@constants/Constant';
import { notifyFail, notifySuccess } from '@utils/notify';
import reactotron from 'reactotron-react-js';
import { Empty, Checkbox, Avatar, Pagination as PaginationAntd } from 'antd';
import moment from 'moment';
import Pagination from '@components/PaginationTotal';
import Loading from '@src/components/Loading';

import {
  requestGetDepartmentDetail,
  requestJobDepartment,
  requestSaleList,
  requestTelesaleDepartment,
  requestAddTelesaleToDepartment,
  requestAddLeaderToDepartment,
  requestLeaderDepartment,
  requestLeaderList,
  requestDeleteLeaderDepartment,
  requesDeleteTelesaleDepartment,
} from '@constants/Api';

class Screen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      departmentInfo: {},
      listJob: [],
      pagingJob: {},
      listTelesale: [],
      listLeaderIdExist: [],
      listTelesaleDepartment: [],
      listLeader: [],
      listLeaderDepartment: [],
      pagingTelesale: {},
      pagingLeader: {
        total: 0,
        current: 1,
        pageSize: 12,
      },
      modal: false,
      modalLeader: false,
      modalDelete: false,
      activePage: 1,
      isLoading: false,
      activePageJob: 1,
      listTelesaleIdExist: [],
      listTelesaleDelete: [],
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.requestGetDepartmentDetail(id);
    this.requestJobDepartment(id, this.state.activePageJob);
    this.requestSaleList(this.state.activePage);
    this.requestTelesaleDepartment(id);
    this.requestLeaderDepartment(id);
    this.requestLeaderList(id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { id } = this.props.match.params;
    if (prevState.pagingLeader.current !== this.state.pagingLeader.current) {
      this.requestLeaderList(id);
    }
  }

  async requestGetDepartmentDetail(id) {
    this.setState({ ...this.state, isLoading: true });
    try {
      const res = await requestGetDepartmentDetail({
        department_id: id,
      });
      this.setState({
        ...this.state,
        departmentInfo: res.data,
        isLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async requestJobDepartment(id, page) {
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const res = await requestJobDepartment({
        department_id: id,
        page: page,
      });
      this.setState({
        ...this.state,
        listJob: res.data,
        pagingJob: res.paging,
        isLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: true,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async requestSaleList(page) {
    this.setState({ ...this.state, isLoading: true });
    try {
      const res = await requestSaleList({ page: page, department_id: this.props.match.params.id });
      this.setState({
        ...this.state,
        listTelesale: res.data,
        pagingTelesale: res.paging,
        isLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async requestLeaderList(id, page, limit) {
    if (!page) page = this.state.pagingLeader.current;
    if (!limit) limit = this.state.pagingLeader.pageSize;
    this.setState({ ...this.state, isLoading: true });
    try {
      const res = await requestLeaderList({ department_id: id, page, limit });

      this.setState({
        ...this.state,
        listLeader: res.data,
        isLoading: false,
        pagingLeader: {
          total: res.paging.count,
          current: res.paging.page,
          pageSize: res.paging.limit,
        },
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async requestTelesaleDepartment(id) {
    this.setState({ ...this.state, isLoading: true });
    try {
      const res = await requestTelesaleDepartment({ department_id: id });
      const list = [];
      const temp = res.data?.forEach((value) => {
        list.push(value?.id);
      });
      this.setState({
        ...this.state,
        listTelesaleDepartment: res.data,
        listTelesaleIdExist: list,
        isLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async requestLeaderDepartment(id) {
    this.setState({ ...this.state, isLoading: true });
    try {
      const res = await requestLeaderDepartment({ department_id: id, page: 1 });
      const list = [];
      const temp = res.data?.forEach((value) => {
        list.push(value?.id);
      });
      this.setState({
        ...this.state,
        listLeaderDepartment: res.data,
        isLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async requestAddTelesaleToDepartment(type = 1) {
    const { id } = this.props.match.params;
    const { listTelesaleIdExist, listLeaderIdExist } = this.state;
    this.setState({ ...this.state, isLoading: true });
    try {
      if (type == 1) {
        const res = await requestAddTelesaleToDepartment({
          department_id: id,
          listSale: JSON.stringify(listTelesaleIdExist),
        });
      } else {
        const res = await requestAddLeaderToDepartment({
          department_id: id,
          listLeader: JSON.stringify(listLeaderIdExist),
        });
      }
      this.setState(
        {
          ...this.state,
          isLoading: false,
          modal: false,
          listLeaderDepartment: [],
        },
        () => {
          notifySuccess('Cập nhật nhân viên thành công');
          this.requestTelesaleDepartment(id);
          this.requestJobDepartment(id, this.state.activePageJob);
          this.requestLeaderDepartment(id);
          this.requestGetDepartmentDetail(id);
          this.requestLeaderList(this.state.departmentInfo.id);
          this.requestSaleList(1);
          if (type != 1) {
            this.handleChange('modalLeader', false);
          }
        }
      );
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async requesDeleteTelesale(listID) {
    try {
      const payload = {
        user_departments: JSON.stringify(listID),
        department_id: this.state.departmentInfo.id,
      };
      const res = await requesDeleteTelesaleDepartment(payload);
      this.setState(
        {
          ...this.state,
          isLoading: false,
          modal: false,
          listTelesaleDelete: [],
        },
        () => {
          notifySuccess('Cập nhật nhân viên thành công');
          this.setShowModalDelete('modalDelete', false);
          this.requestJobDepartment(this.state.departmentInfo.id, this.state.activePageJob);
          this.requestTelesaleDepartment(this.state.departmentInfo.id);
          this.requestLeaderDepartment(this.state.departmentInfo.id);
          this.requestGetDepartmentDetail(this.state.departmentInfo.id);
          this.requestSaleList(1);
        }
      );
    } catch (err) {
      this.setState(
        {
          ...this.state,
          modalDeleteId: null,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async requesDeleteLeader(id) {
    try {
      const payload = {
        user_departments: `[${id}]`,
        department_id: this.state.departmentInfo.id,
      };
      const res = await requestDeleteLeaderDepartment(payload);
      this.setState(
        {
          ...this.state,
          isLoading: false,
          modal: false,
        },
        () => notifySuccess('Cập nhật nhân viên thành công')
      );
      this.setShowModalDelete('modalDelete', false);
      this.requestTelesaleDepartment(this.state.departmentInfo.id);
      this.requestLeaderDepartment(this.state.departmentInfo.id);
      this.requestGetDepartmentDetail(this.state.departmentInfo.id);
      this.requestLeaderList(this.state.departmentInfo.id);
    } catch (err) {
      this.setState(
        {
          ...this.state,
          modalDeleteId: null,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }
  handleChange = (field, value) => {
    const { listTelesaleDepartment } = this.state;
    const list = [];
    const temp = listTelesaleDepartment?.forEach((value) => {
      list.push(value?.id);
    });
    reactotron.log(list);
    this.setState({ ...this.state, [field]: value, listTelesaleIdExist: list });
  };

  handlePageChange = (page) => {
    this.requestSaleList(page);
    this.setState({ ...this.state, activePage: page });
  };

  handlePageChangeListJob = (page) => {
    const { id } = this.props.match.params;
    this.requestJobDepartment(id, page);
    this.setState({ ...this.state, activePageJob: page });
  };

  handleChangeTelesale = (e, id, listID) => {
    if (e.target.checked && !listID.includes(id)) {
      const arr = listID.push(id);
      this.setState({
        ...this.state,
        listTelesaleIdExist: listID,
      });
    }
    if (!e.target.checked && listID.includes(id)) {
      const indexTelesale = listID.findIndex((value) => value == id);
      listID.splice(indexTelesale, 1);
      this.setState({
        ...this.state,
        listTelesaleIdExist: listID,
      });
    }
  };

  handleChangeTelesaleDelete = (e, id, listID) => {
    if (e.target.checked && !listID.includes(id)) {
      const arr = listID.push(id);
      this.setState({
        ...this.state,
        listTelesaleDelete: listID,
      });
    }
    if (!e.target.checked && listID.includes(id)) {
      const indexTelesale = listID.findIndex((value) => value == id);
      listID.splice(indexTelesale, 1);
      this.setState({
        ...this.state,
        listTelesaleDelete: listID,
      });
    }
  };

  renderHeader() {
    const { departmentInfo } = this.state;
    return (
      <Row className="mt-1" style={{ padding: '0px 10px', borderRadius: '8px', marginBottom: 8 }}>
        <Col md className="bg-infor">
          <h2 className="m-0">{departmentInfo?.name || '--'}</h2>
        </Col>
      </Row>
    );
  }

  renderBody() {
    return (
      <div className="content-wrapper">
        <div className="content-header">
          {this.renderHeader()}

          <Row style={{ padding: '0px 10px', borderRadius: '8px', marginBottom: 10 }}>
            <Col md={4} sm={12} lg={3} className="p-0 mt-1">
              <div className="bg-infor">{this.renderGeneralInfo()}</div>
              <div className="bg-infor mt-2">{this.renderLeader()}</div>
            </Col>

            <Col md={8} sm={12} lg={9} className="pl-lg-3 pl-md-3 pl-xs-0 p-0 mt-1">
              <div className="bg-infor">{this.renderJob()}</div>
              <div className="bg-infor mt-2">{this.renderTelesale()}</div>
            </Col>
          </Row>

          {/* <Row style={{ padding: '0px 10px', borderRadius: '8px', marginBottom: 10 }}>
            <Col md={12} className="p-0 mt-1">
              <div className="bg-infor">{this.renderTelesale()}</div>
            </Col>
          </Row> */}
        </div>
      </div>
    );
  }

  renderGeneralInfo() {
    const { departmentInfo } = this.state;
    const { id } = this.props.match.params;
    return (
      <>
        <Row>
          <Col md={10} className="p-0">
            <label>Tên phòng ban</label>
          </Col>
          <Col md={2} className="p-0">
            <i
              className="far fa-edit"
              style={{ fontSize: '20px', cursor: 'pointer' }}
              onClick={() => this.props.history.push(ROUTER.UPDATE_DEPARTMENT + `/${id}`)}
            ></i>
          </Col>
        </Row>
        <Row>
          <span>{departmentInfo?.name || '--'}</span>
        </Row>
        <Row className="mt-1">
          <Col md={10} className="p-0">
            <label className="m-0">Mô tả</label>
          </Col>
        </Row>
        <Row>
          <span>{departmentInfo?.note || '--'}</span>
        </Row>
        <Row className="mt-1">
          <Col md={10} className="p-0">
            <label className="m-0">Số lượng nhân viên</label>
          </Col>
          <Col md={2} className="p-0">
            <b>{departmentInfo?.countUser || '0'}</b>
          </Col>
        </Row>
      </>
    );
  }

  renderJob() {
    const { listJob, pagingJob } = this.state;
    const paging = {
      page: pagingJob?.page,
      totalItemCount: pagingJob?.count,
      limit: pagingJob?.limit,
    };
    return (
      <>
        <Row>
          <h4>Công việc tham gia</h4>
        </Row>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>STT</th>
              <th>Công việc</th>
              <th>Thời gian</th>
              <th>Trạng thái</th>
            </tr>
          </thead>
          <tbody>
            {listJob?.length > 0 ? (
              listJob?.map((item, index) =>
                this.renderTableJob(item, index + 1 + (pagingJob?.page - 1) * NUMBER.page_limit)
              )
            ) : (
              <td colSpan="4">
                <Empty description="Không có dữ liệu" />
              </td>
            )}
          </tbody>
        </table>
        <Row>
          <Col md={12} className="d-flex justify-content-center justify-content-md-end p-0">
            <Pagination screen="customerGroupState" handlePageChange={this.handlePageChangeListJob} paging={paging} />
          </Col>
        </Row>
      </>
    );
  }

  renderTableJob(item, index) {
    return (
      <>
        <tr>
          <td>{index}</td>
          <td>{item?.name || '--'}</td>
          <td>
            {moment(item.start_at).format('DD-MM-YYYY') + ' đến ' + moment(item.end_at).format('DD-MM-YYYY') || '--'}
          </td>
          <td>{item?.status == 2 ? 'Đang thực hiện' : 'Hoàn thành' || '--'}</td>
        </tr>
      </>
    );
  }
  setShowModalDelete = (modal, bool, id) => {
    this.setState({
      [modal]: bool,
      modalDeleteId: id,
    });
  };
  renderTableLeader(item, index) {
    return (
      <>
        <tr>
          <td>{index}</td>
          <td>{item?.full_name + `(${item.phone})` || '--'}</td>
          <td>
            <i
              className="far fa-trash text-danger"
              style={{ fontSize: '20px', cursor: 'pointer' }}
              onClick={() => this.setShowModalDelete('modalDelete', true, item.id)}
            ></i>
          </td>
        </tr>
      </>
    );
  }

  deleteModal = () => {
    const { modalDelete, modalDeleteId: id, listTelesaleDelete } = this.state;
    return (
      <Modal
        show={modalDelete}
        onHide={() => this.setShowModalDelete('modalDelete', false, id)}
        dialogClassName="modal-90w"
        centered
        scrollable
      >
        <Modal.Header closeButton className="text-white bg-primary pb-0" style={{ color: 'white' }}>
          <h5 className="text-light pb-0">Bạn chắc chắn muốn xóa?</h5>
        </Modal.Header>
        <Modal.Footer className="justify-content-end">
          <Button
            variant="success"
            className="text-light"
            onClick={() => (id > 0 ? this.requesDeleteLeader(id) : this.requesDeleteTelesale(listTelesaleDelete))}
          >
            Có
          </Button>
          <Button
            variant="danger"
            className="text-light"
            onClick={() => this.setShowModalDelete('modalDelete', false, 0)}
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderTelesale() {
    const { listTelesaleDepartment } = this.state;
    return (
      <>
        <Row className="mb-1">
          <Col md={5} className="p-0">
            <h4>Danh sách Sale</h4>
          </Col>
          <Col md={7} className="p-0 mb-2">
            <Button
              variant="danger"
              className="text-light"
              style={{ float: 'right' }}
              onClick={() => this.setShowModalDelete('modalDelete', true, 0)}
            >
              Xóa
            </Button>
            <Button
              variant="primary"
              className="text-light"
              style={{ float: 'right' }}
              onClick={() => this.handleChange('modal', true)}
            >
              Thêm
            </Button>
          </Col>
        </Row>
        <Row>
          {listTelesaleDepartment.length > 0 ? (
            <>{listTelesaleDepartment?.map((value) => this.renderTelesaleItem(value))}</>
          ) : (
            <Empty description="Không có dữ liệu" style={{ margin: 'auto' }} />
          )}
        </Row>
      </>
    );
  }

  renderLeader() {
    const { listLeaderDepartment } = this.state;
    return (
      <>
        <Row>
          <Col md={10} lg={10} xs={10} className="p-0">
            <label>Trưởng phòng</label>
          </Col>
          <Col md={2} lg={2} xs={2} className="p-0">
            <i
              className="far fa-user-plus"
              style={{ fontSize: '15px', cursor: 'pointer' }}
              onClick={() => this.handleChange('modalLeader', true)}
            ></i>
          </Col>
        </Row>
        <Row>
          <table class="table table-striped">
            <thead>
              <tr>
                <th>STT</th>
                <th>Trưởng phòng</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {listLeaderDepartment.length > 0 ? (
                <>{listLeaderDepartment?.map((value, index) => this.renderTableLeader(value, index + 1))}</>
              ) : (
                <td colSpan="4">
                  <Empty description="Không có dữ liệu" style={{ margin: 'auto' }} />
                </td>
              )}
            </tbody>
          </table>
        </Row>
      </>
    );
  }

  renderLeaderItem(value) {
    return (
      <>
        <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
          <div className="card bg-light">
            <div className="card-header text-muted border-bottom-0">Trưởng nhóm</div>
            <div className="card-body pt-0">
              <div className="row">
                <div className="col-7">
                  <h2>
                    <b>{value?.full_name || '--'}</b>
                  </h2>
                  <ul className="ml-4 mb-0 fa-ul text-muted">
                    <li className="small">
                      <span className="fa-li">
                        <i className="fas fa-phone"></i>
                      </span>{' '}
                      Phone: {value?.phone || '--'}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="text-right">
                <a href={ROUTER.TELESALE_DETAIL + `/${value.id}`} className="btn btn-sm btn-primary">
                  <i className="fas fa-user" /> Xoá
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderTelesaleItem(value) {
    const { listTelesaleDelete } = this.state;
    return (
      <>
        <div className="col-lg-4 col-sm-6 col-md-6 d-flex align-items-stretch">
          <div className="p-0 card bg-light col-lg-12">
            <td className="pl-3 pt-3 text-left">
              <Checkbox
                checked={listTelesaleDelete?.includes(value?.id)}
                onChange={(e) => this.handleChangeTelesaleDelete(e, value?.id, listTelesaleDelete)}
              />
            </td>
            <div className="card-body pt-0 pb-3 pl-0 pr-2">
              <div className="row">
                <div className="col-lg-10 col-xs-12 col-sm-12">
                  <h2>
                    <b>{value?.full_name || '--'}</b>
                  </h2>
                  <ul className="ml-4 mb-0 fa-ul text-muted">
                    <li className="small">
                      <span className="fa-li">
                        <i className="fas fa-map-marker-alt"></i>
                      </span>{' '}
                      {value?.address || '--'}
                    </li>
                    <li className="small">
                      <span className="fa-li">
                        <i className="fas fa-phone"></i>
                      </span>{' '}
                      {value?.phone || '--'}
                    </li>
                    <li className="small">
                      <span className="fa-li">
                        <i className="fas fa-envelope"></i>
                      </span>{' '}
                      {value?.email || '--'}
                    </li>
                  </ul>
                </div>
                <div className="d-flex d-flex-row justify-content-center" style={{ flex: 1 }}>
                  <div
                    // className="col-lg-5 col-md-5 col-xs-12 col-sm-12 text-center"
                    style={{ display: 'flex', alignItems: 'center' }}
                    className="float-right"
                  >
                    <Avatar
                      src={value?.profile_image}
                      size={{ xs: 24, sm: 32, md: 32, lg: 44, xl: 44, xxl: 44 }}
                      // className="bg-blue text-white"
                      style={{ marginTop: '5px' }}
                    >
                      {value?.user_sale_info?.profile_image !== '' ? null : value.full_name[0].toUpperCase()}
                    </Avatar>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="text-right">
                <a href={ROUTER.TELESALE_DETAIL + `/${value.id}`} className="btn btn-sm btn-primary">
                  <i className="fas fa-user" /> Xem chi tiết
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderModal() {
    const { modal, listTelesale, pagingTelesale } = this.state;
    return (
      <Modal
        show={modal}
        onHide={() => this.handleChange('modal', false)}
        dialogClassName="modal-90w"
        centered
        size="lg"
      >
        <Modal.Header closeButton className="bg-blue pb-0">
          <h5 className="text-light pb-0">Chỉnh sửa danh sách nhân viên</h5>
        </Modal.Header>

        <Modal.Body className="custom-body pb-0">
          <Row>
            <Col md={5} sm={12} className="p-0">
              <h5>Danh sách nhân viên</h5>
            </Col>
          </Row>
          <Row>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Tên sale</th>
                  <th>Số điện thoại</th>
                  <th>Email</th>
                  <th>Địa chỉ</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {listTelesale?.length > 0 ? (
                  listTelesale.map((item, index) =>
                    this.renderTableRow(item, index + 1 + (pagingTelesale?.page - 1) * NUMBER.page_limit)
                  )
                ) : (
                  <td colSpan="6">
                    <Empty description="Không có dữ liệu" />
                  </td>
                )}
              </tbody>
            </Table>
          </Row>
          <Row>
            <Col md={12} className="d-flex justify-content-center justify-content-md-end p-0">
              <Pagination
                screen="customerGroupState"
                handlePageChange={this.handlePageChange}
                paging={pagingTelesale}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="success" className="text-light" onClick={() => this.requestAddTelesaleToDepartment()}>
            Cập nhật
          </Button>
          <Button variant="danger" className="text-light" onClick={() => this.handleChange('modal', false)}>
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderLeaderModal() {
    const { modalLeader, listLeader } = this.state;
    return (
      <Modal
        show={modalLeader}
        onHide={() => this.handleChange('modalLeader', false)}
        dialogClassName="modal-90w"
        centered
        size="lg"
      >
        <Modal.Header closeButton className="bg-blue pb-0">
          <h5 className="text-light pb-0">Thêm tài khoản quản lý phòng ban</h5>
        </Modal.Header>

        <Modal.Body className="custom-body pb-0">
          <Row>
            <Col md={5} sm={12} className="p-0">
              <h5>Danh sách tài khoản</h5>
            </Col>
          </Row>
          <Row>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Tên TK</th>
                  <th>Số điện thoại</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {listLeader?.length > 0 ? (
                  listLeader.map((item, index) => this.renderLeaderTableRow(item, index, this.state.pagingLeader))
                ) : (
                  <td colSpan="6">
                    <Empty description="Không có dữ liệu" />
                  </td>
                )}
              </tbody>
              <tfoot>
                <div
                  style={{
                    margin: '10px 0px 10px 20px',
                  }}
                >
                  <PaginationAntd
                    defaultCurrent={this.state.pagingLeader.current}
                    current={this.state.pagingLeader.current}
                    pageSize={this.state.pagingLeader.pageSize}
                    total={this.state.pagingLeader.total}
                    onChange={(page, pageSize) => {
                      this.setState({
                        pagingLeader: {
                          ...this.state.pagingLeader,
                          current: page,
                        },
                      });
                    }}
                  />
                  {/* <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    hideDisabled
                    activePage={Number(this.state?.pagingLeader?.current)}
                    itemsCountPerPage={this.state?.pagingLeader?.pageSize}
                    totalItemsCount={this.state?.pagingLeader?.total || 0}
                    pageRangeDisplayed={5}
                    hideNavigation
                    onChange={(page) => {
                      try {
                        // this.setState({
                        //   paging: {
                        //     ...this.state?.paging,
                        //     page,
                        //   },
                        // });
                      } catch (error) {}
                    }}
                  /> */}
                </div>
              </tfoot>
            </Table>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="success" className="text-light" onClick={() => this.requestAddTelesaleToDepartment(2)}>
            Cập nhật
          </Button>
          <Button variant="danger" className="text-light" onClick={() => this.handleChange('modalLeader', false)}>
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderTableRow(item, index) {
    const { listTelesaleIdExist } = this.state;
    return (
      <>
        <tr>
          <td>{index}</td>
          <td>{item?.full_name || '--'}</td>
          <td>{item?.phone || '--'}</td>
          <td>{item?.email || '--'}</td>
          <td>{item?.user_sale_info?.province?.name || '--'}</td>
          <td>
            <Checkbox
              checked={listTelesaleIdExist?.includes(item?.id)}
              onChange={(e) => this.handleChangeTelesale(e, item?.id, listTelesaleIdExist)}
            />
          </td>
        </tr>
      </>
    );
  }

  renderLeaderTableRow(item, index, pagingLeader) {
    const { listLeaderIdExist } = this.state;
    return (
      <>
        <tr>
          <td>{(pagingLeader.current - 1) * pagingLeader.pageSize + index + 1}</td>
          <td>{item?.full_name || '--'}</td>
          <td>{item?.phone || '--'}</td>
          <td>
            <Checkbox
              checked={listLeaderIdExist?.includes(item?.id)}
              onChange={(e) => this.handleChangeTelesale(e, item?.id, listLeaderIdExist)}
            />
          </td>
        </tr>
      </>
    );
  }

  render() {
    const { isLoading } = this.state;
    return (
      <>
        {isLoading && <Loading />}
        {this.renderModal()}
        {this.renderLeaderModal()}
        {this.renderBody()}
        {this.deleteModal()}
      </>
    );
  }
}

export default withRouter(Screen);
