import React, { Component } from 'react';
import { Row, Col, Button, FormControl, Table, Modal } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';

import { ROUTER, NUMBER } from '@constants/Constant';
import { notifyFail, notifySuccess } from '@utils/notify';
import moment from 'moment';
import reactotron from 'reactotron-react-js';
import Loading from '@src/components/Loading';
import Pagination from '@components/PaginationComponent';
import { Empty, Checkbox } from 'antd';

import { requestGetListDepartment, requestDeleteDepartment } from '@constants/Api';

class Screen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      listDepartment: [],
      paging: {},
      isLoading: false,
      modalDelete: false,
      activePage: 1,
      listDepartmentDelete: [],
      isCheckAll: false,
    };
  }

  componentDidMount() {
    const { activePage, search } = this.state;
    this.requestGetListDepartment(activePage, search);
  }

  async requestGetListDepartment(page, search = '') {
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const res = await requestGetListDepartment({
        search: search,
        page: page,
      });
      console.log(res.data);
      this.setState({
        ...this.state,
        listDepartment: res.data,
        paging: res.paging,
        isLoading: false,
      });
      this.handleCheckAll();
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  handleKeyPress = (e) => {
    let { search, activePage } = this.state;
    if (e.charCode === 13) {
      this.requestGetListDepartment(activePage, search);
    }
  };

  handleChange = (field, value) => {
    this.setState({ ...this.state, [field]: value });
    console.log(this.state);
  };

  handlePageChange = (page) => {
    this.requestGetListDepartment(page, this.state.search);
    this.setState({ activePage: page });
  };

  renderTitle() {
    return <h2> Phòng ban </h2>;
  }
  handleChangeDepartments = (e, id, listID) => {
    if (e.target.checked && !listID.includes(id)) {
      const arr = listID.push(id);
      this.setState({
        ...this.state,
        listDepartmentDelete: listID,
      });
    }
    if (!e.target.checked && listID.includes(id)) {
      const indexTelesale = listID.findIndex((value) => value == id);
      listID.splice(indexTelesale, 1);
      this.setState({
        ...this.state,
        listDepartmentDelete: listID,
      });
    }
  };

  handleChangeAllDepartments = (e) => {
    const { listDepartment } = this.state;
    if (e.target.checked) {
      const arr = listDepartment.map((u) => u.id);
      this.setState({
        ...this.state,
        listDepartmentDelete: arr,
        isCheckAll: true,
      });
    }
    if (!e.target.checked) {
      this.setState({
        ...this.state,
        listDepartmentDelete: [],
        isCheckAll: false,
      });
    }
  };
  handleCheckAll() {
    const { isCheckAll, listDepartmentDelete, listDepartment } = this.state;
    const arr = listDepartment.map((u) => u.id);
    if (isCheckAll) {
      this.setState({
        listDepartmentDelete: [...listDepartmentDelete, ...arr],
      });
    }
  }
  async requestDeleteDepartment(listDepartmentDelete) {
    if (listDepartmentDelete.length == 0) {
      notifyFail('Vui lòng chọn phòng ban cần xóa');
      return;
    }
    try {
      const res = await requestDeleteDepartment({
        listDepartments: listDepartmentDelete,
      });
      this.setState({
        ...this.state,
        modalDelete: false,
        activePage: 1,
      });
      this.requestGetListDepartment(1);
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  setShowModalDelete = (modal, bool) => {
    this.setState({
      [modal]: bool,
    });
  };

  deleteModal = () => {
    const { modalDelete, listDepartmentDelete } = this.state;
    return (
      <Modal
        show={modalDelete}
        onHide={() => this.setShowModalDelete('modalDelete', false)}
        dialogClassName="modal-90w"
        centered
        scrollable
      >
        <Modal.Header closeButton className="text-white bg-primary pb-0" style={{ color: 'white' }}>
          <h5 className="text-light pb-0">Bạn chắc chắn muốn xóa?</h5>
        </Modal.Header>
        <Modal.Footer className="justify-content-end">
          <Button
            variant="success"
            className="text-light"
            onClick={() => this.requestDeleteDepartment(listDepartmentDelete)}
          >
            Có
          </Button>
          <Button variant="danger" className="text-light" onClick={() => this.setShowModalDelete('modalDelete', false)}>
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  renderButton() {
    return (
      <div className="text-center text-md-right">
        <Col className="button-wrapper">
          <Button variant="danger" className="text-light" onClick={() => this.setShowModalDelete('modalDelete', true)}>
            Xóa
          </Button>
          <Link to={ROUTER.ADD_DEPARTMENT}>
            <Button variant="success" className="text-light">
              Thêm mới
            </Button>
          </Link>
          {/* <Link to={ROUTER.UPDATE_DEPARTMENT}>
            <Button variant="primary" className="text-light">
              Chỉnh sửa
            </Button>
          </Link>
          <Button variant="danger" className="text-light">
            Xóa
          </Button> */}
        </Col>
      </div>
    );
  }

  renderField() {
    const { search } = this.state;
    return (
      <FormControl
        type="text"
        placeholder="Tên phòng ban"
        value={search || ''}
        onChange={(e) => this.handleChange('search', e.target.value)}
        onKeyPress={(e) => this.handleKeyPress(e)}
      ></FormControl>
    );
  }

  renderBody() {
    return (
      <div className="content-wrapper">
        <div className="content-header">
          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 20 }}>
            <Col md className="bg">
              <Row>
                <Col md={5} sm={12} className="p-0">
                  {this.renderTitle()}
                </Col>
                <Col md={7} sm={12}>
                  {this.renderButton()}
                </Col>
              </Row>
              <div className="line"></div>
              <Row>
                <Col md={6} className="p-0">
                  {this.renderField()}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 20 }}>
            <Col md className="bg">
              <Row>
                <Col md={12}>{this.renderTable()}</Col>
              </Row>
              <Row>
                <Col md={12} className="d-flex justify-content-center justify-content-md-end">
                  <Pagination
                    screen="customerGroupState"
                    handlePageChange={this.handlePageChange}
                    paging={this.state.paging}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderTable() {
    const { listDepartment, paging } = this.state;
    return (
      <>
        <span className="pb-2 d-inline-block">
          Kết quả lọc: <b className="text-danger">{paging?.count || 0}</b>
        </span>
        <div className="w-100 table-wrap">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>STT</th>
                <th>Tên phòng ban</th>
                <th>Số lượng nhân viên</th>
                <th>Ngày tạo</th>
                <th>
                  <Checkbox onChange={(e) => this.handleChangeAllDepartments(e)} />
                </th>
              </tr>
            </thead>
            <tbody>
              {listDepartment?.length > 0 ? (
                listDepartment.map((item, index) =>
                  this.renderTableRow(item, index + 1 + (paging?.page - 1) * NUMBER.page_limit)
                )
              ) : (
                <td colSpan="5">
                  <Empty description="Không có dữ liệu" />
                </td>
              )}
            </tbody>
          </Table>
        </div>
      </>
    );
  }

  renderTableRow(item, index) {
    const { listDepartmentDelete } = this.state;
    return (
      <>
        <tr>
          <td onClick={() => this.props.history.push(ROUTER.DEPARTMENT_DETAIL + `/${item.id}`)}>{index}</td>
          <td onClick={() => this.props.history.push(ROUTER.DEPARTMENT_DETAIL + `/${item.id}`)}>
            {item?.name || '--'}
          </td>
          <td onClick={() => this.props.history.push(ROUTER.DEPARTMENT_DETAIL + `/${item.id}`)}>
            {item?.countUser || '0'}
          </td>
          <td onClick={() => this.props.history.push(ROUTER.DEPARTMENT_DETAIL + `/${item.id}`)}>
            {moment(item?.created_at).format('DD-MM-YYYY') || '--'}
          </td>
          <td>
            <Checkbox
              checked={listDepartmentDelete?.includes(item?.id)}
              onChange={(e) => this.handleChangeDepartments(e, item?.id, listDepartmentDelete)}
            />
          </td>
        </tr>
      </>
    );
  }

  render() {
    const { isLoading } = this.state;
    return (
      <>
        {isLoading && <Loading />}
        {this.renderBody()}
        {this.deleteModal()}
      </>
    );
  }
}

export default withRouter(Screen);
