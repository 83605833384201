import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import '@styles/Settings.css';
import { Row, Col, FormControl, Nav } from 'react-bootstrap';

class System extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  renderBody() {
    const { value } = this.state;
    return (
      <div>
        <Row>
          <Col md={6} className="ml-3">
            <Row className="mb-3">
              <b>Thông số hệ thống</b>
            </Row>
            {/* <Row>
              <Col md={4} className="mt-2">
                Thời gian cấu hình KPI
              </Col>
              <Col md={4}>
                <FormControl
                  type="number"
                  id=""
                  value={value}
                  onChange={(e) => this.handleInputChange('value', e.target.value)}
                />
              </Col>
              <Col md={4} className="mt-2">
                Ngày
              </Col>
            </Row> */}
          </Col>
        </Row>
      </div>
    );
  }

  handleInputChange(field, value) {
    this.setState({
      ...this.state,
      [field]: value,
    });
  }

  render() {
    return <>{this.renderBody()}</>;
  }
}

export default withRouter(System);
