import { voucherService } from '@screens/Enterprise/ElectronicVoucher/service';
import { Select } from 'antd';
import React from 'react';
import useDebounce from 'src/hooks/useDebounce';
import { jobService } from './service';

const SelectEvoucherComponent = ({ defaultValue, onChangeEvoucher }) => {
  const [vouchers, setVouchers] = React.useState([]);
  const [loadingSearchVoucher, setLoadingSearchVoucher] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const debouncedSearchTerm = useDebounce(search, 300);

  React.useEffect(() => {
    setLoadingSearchVoucher(true);
    jobService
      .getEvoucher({ search: debouncedSearchTerm })
      .then(res => {
        setVouchers([...res.data]);
      })
      .finally(() => {
        setLoadingSearchVoucher(false);
      });
  }, [debouncedSearchTerm]);

  return (
    <Select
      showSearch
      allowClear
      // filterOption={false}
      placeholder="Chọn mã chương trình"
      style={{ width: '100%' }}
      loading={loadingSearchVoucher}
      onSearch={value => setSearch(value)}
      defaultValue={defaultValue || null}
      filterOption={false}
      // add filter
      onChange={onChangeEvoucher}
    >
      {vouchers.map(voucher => (
        <Select.Option key={voucher.code} value={voucher?.id}>
          {voucher.code}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectEvoucherComponent;
