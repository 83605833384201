import { DescriptionCustomInteractionHistory } from '@styles/Style';
import { Descriptions } from 'antd';
import moment from 'moment';
import React from 'react';
import InteractionHistoryHeader from './InteractionHistoryHeader';

export default function AppointmentSchedule({ interactionHistory }) {
  return (
    <>
      <InteractionHistoryHeader interactionHistory={interactionHistory} />

      <DescriptionCustomInteractionHistory column={1}>
        <Descriptions.Item label="Thời gian gọi lại">
          {interactionHistory.time_recall
            ? moment.utc(interactionHistory.time_recall).local().format('hh:mm A DD/MM/YYYY')
            : 'Chưa cập nhật'}
        </Descriptions.Item>

        <Descriptions.Item label="Ghi chú">
          {interactionHistory.note_recall ? interactionHistory.note_recall : 'Chưa cập nhật'}
        </Descriptions.Item>
      </DescriptionCustomInteractionHistory>
    </>
  );
}
