import { notifyFail, notifySuccess } from "@utils/notify";
import { removeAccentsZalo } from "@utils/stringUtils";
import { Form, Input, Popconfirm, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Icon from "react-icons-kit";
import { edit } from "react-icons-kit/fa/edit";
import { trashO } from "react-icons-kit/fa/trashO";
import FormModules from "src/modules/Form";
import FooterForm from "src/modules/Form/Footer.Form";
import ItemForm from "src/modules/Form/Item.Form";
import ModalModules from "src/modules/Modal";
import TableModules from "src/modules/Table";
import { rulesZaloPerson } from "../rules";
import {
    createAccountZalo,
    deleteAccountZalo,
    getListAccountZalo,
    updateAccountZalo,
} from "../services/api";

const columns = (page) => [
    {
        title: "STT",
        dataIndex: "id",
        width: "60px",
        render: (value, row, index) => (page === 1 ? index + 1 : (page - 1) * 12 + (index + 1)),
    },
    {
        title: "Tài khoản",
        dataIndex: "phone",
    },
    {
        title: "Mật khẩu",
        dataIndex: "password",
    },
    {
        title: "Ngày tạo",
        dataIndex: "created_at",
        render: (value) => moment(value).format("DD/MM/YYYY"),
    },
];

const ZaloPerson = ({ isFormZaloPerson, setIsFormZaloPerson }) => {
    const [zaloAccountList, setZaloAccountList] = useState([]);
    const [editMode, setEditMode] = useState("");
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);

    const [loadingForm, setLoadingForm] = useState(false);
    const [callback, setCallback] = useState(false);
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();

    useEffect(() => {
        setLoading(true);
        getListAccountZalo({ search: "", page })
            .then((res) => {
                setZaloAccountList(res.data);
                setTotal(res.paging.totalItemCount);
            })
            .finally(() => setLoading(false));
    }, [callback, page]);

    const onSubmitForm = (values) => {
        setLoadingForm(true);

        if (editMode) {
            updateAccountZalo(editMode, values)
                .then((res) => {
                    if (res?.status) {
                        notifySuccess("Cập nhật tài khoản thành công");
                        setCallback(!callback);
                        cancelModal();
                    }
                })
                .catch((err) => {
                    form.setFields([
                        {
                            name: "phone",
                            errors: [err?.msg],
                        },
                    ]);
                })
                .finally(() => setLoadingForm(false));
        } else {
            createAccountZalo(values)
                .then((res) => {
                    if (res?.status) {
                        notifySuccess("Tạo tài khoản thành công");
                        setCallback(!callback);
                        cancelModal();
                    }
                })
                .catch((err) => {
                    form.setFields([
                        {
                            name: "phone",
                            errors: [err?.msg],
                        },
                    ]);
                })
                .finally(() => setLoadingForm(false));
        }
    };

    const handleClickEdit = (row) => {
        form.setFieldsValue({
            phone: row.phone,
            password: row.password,
        });

        setIsFormZaloPerson(true);
        setEditMode(row.id);
    };

    const cancelModal = () => {
        setIsFormZaloPerson(false);
        setEditMode("");
        form.resetFields();
    };

    const handleDeleteAccount = (row) => {
        const id = row.id;
        deleteAccountZalo(id)
            .then((res) => {
                notifySuccess("Xoá tài khoản thành công");
                setCallback(!callback);
                setPage(1);
            })
            .catch((err) => {
                notifyFail(err?.msg);
            });
    };

    return (
        <div>
            <TableModules
                columns={[
                    ...columns(page),
                    {
                        title: "--",
                        width: "100px",
                        dataIndex: "action",
                        render: (value, row) => (
                            <Space
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Icon
                                    size='18px'
                                    icon={edit}
                                    onClick={() => handleClickEdit(row)}
                                />
                                <Popconfirm
                                    placement='left'
                                    title='Bạn có chắc chắn muốn xoá tài khoản này không?'
                                    onConfirm={() => handleDeleteAccount(row)}
                                    okText='Xác nhận'
                                    cancelText='Huỷ'
                                >
                                    <span>
                                        <Icon size='18px' icon={trashO} />
                                    </span>
                                </Popconfirm>
                            </Space>
                        ),
                    },
                ]}
                dataSource={loading ? [] : zaloAccountList}
                size='small'
                // defaultPagination={{
                //   onChange: _page => setPage(_page),
                // }}
                // showTotalResult
                loading={loading}
                isPagination
                page={page}
                rowSelect={false}
                onChangePage={(_page) => setPage(_page)}
                // expandedRowRender={rowRender}
                // onRowSelection={onRowSelection}
                total={total}
            />

            <ModalModules
                loading={loadingForm}
                title={editMode ? "Sửa tài khoản zalo cá nhân" : "Thêm tài khoản zalo cá nhân"}
                modalVisible={isFormZaloPerson}
                onCancel={() => {
                    cancelModal();
                }}
            >
                <FormModules
                    form={form}
                    onSubmit={onSubmitForm}
                    layoutType='vertical'
                    style={{ width: "100%" }}
                >
                    <ItemForm
                        name='phone'
                        rules={rulesZaloPerson.phone}
                        label='Tài khoản'
                        normalize={(value) => value?.trim()}
                        inputField={<Input placeholder='Nhập số điện thoại' />}
                    />
                    <Form.Item
                        name='password'
                        rules={[
                            {
                                required: true,
                                message: "Vui lòng nhập mật khẩu",
                            },
                        ]}
                        label='Mật khẩu'
                        normalize={(value) => removeAccentsZalo(value)}
                    >
                        <Input placeholder='Nhập mật khẩu' />
                    </Form.Item>
                    <FooterForm
                        onCancel={() => {
                            cancelModal();
                        }}
                    />
                </FormModules>
            </ModalModules>
        </div>
    );
};

export default React.memo(ZaloPerson);
