import { formatPrice } from '@constants/funcHelper';
import { VietnameseString } from '@constants/VietnameseString';
import { TableAntStyle } from '@styles/Style';
import { Col, Empty, Row, Skeleton, Table } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState, useCallback } from 'react';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const arrayColor = [
  '#ffbe00',
  '#fce190',
  '#c0b9d1',
  '#7b59ca',
  '#edbbd1',
  '#ea257c',
  '#bdecad',
  '#4ab425',
  '#ffb68d',
  '#d74d00',
  '#82c7d5',
  '#0094b4',
  '#dee213',
  '#f1f2a3',
  '#b45f06',
  '#fcc082',
  '#fc82f2',
  '#02096b',
];

const numberOfFractionalDigits = 2;

export default function BusinessReportChart({
  isLoadingChart,
  businessReportChart,
  typeTimeCode,
  from,
  to,
  type_time_code,
  isLoadingChartWithProduct,
  businessReportChartWithProduct,
}) {
  const [data, setData] = useState([]);

  const returnTimeline = (from, to, type, format) => {
    const diff = moment(to).diff(moment(from), type);
    let timeline = [from.format(format)];

    for (let index = 0; index < diff; index++) {
      timeline.push(from.add(1, type).format(format));
    }

    return timeline;
  };

  const returnProcessData = useCallback((businessReportChart, timeline) => {
    const productOfTime = [];

    const processedDataOfTime = timeline.map(time => {
      let totalSales = 0;
      let totalRevenue = 0;
      let product = {};
      if (businessReportChart[time]) {
        businessReportChart[time].forEach(element => {
          if (element?.total_revenue || element?.total_sales) {
            totalSales = Number(element?.total_sales);
            totalRevenue = Number(element?.total_revenue);
          } else {
            productOfTime.push(element);
            product = { ...product, [element.name]: element.total_product };
          }
        });
      }
      return {
        name: time,
        [VietnameseString.sale]: totalSales,
        [VietnameseString.revenue]: totalRevenue,
        ...product,
      };
    });
    return processedDataOfTime;
  }, []);

  const handleChartRawData = useCallback(
    (businessReportChart, typeTimeCode, from, to, type_time_code) => {
      const fromDate = moment(from).startOf('day');
      const toDate = moment(to).endOf('day');

      switch (typeTimeCode) {
        case type_time_code.DATE:
          return returnProcessData(
            businessReportChart,
            returnTimeline(fromDate, toDate, 'hour', 'H')
          );

        case type_time_code.WEEK:
          return returnProcessData(
            businessReportChart,
            returnTimeline(fromDate, toDate, 'day', 'YYYY-MM-DD')
          );

        case type_time_code.MONTH:
          return returnProcessData(
            businessReportChart,
            returnTimeline(fromDate, toDate, 'day', 'YYYY-MM-DD')
          );

        case type_time_code.QUARTER:
          return returnProcessData(
            businessReportChart,
            returnTimeline(fromDate, toDate, 'months', 'M')
          );

        case type_time_code.YEAR:
          return returnProcessData(
            businessReportChart,
            returnTimeline(fromDate, toDate, 'months', 'M')
          );

        case type_time_code.MULTIPLE_MONTH:
          return returnProcessData(
            businessReportChart,
            returnTimeline(fromDate, toDate, 'months', 'M')
          );

        case type_time_code.MULTIPLE_QUARTER:
          return returnProcessData(
            businessReportChart,
            returnTimeline(fromDate, toDate, 'quarter', 'Q')
          );

        case type_time_code.MULTIPLE_YEAR:
          return returnProcessData(
            businessReportChart,
            returnTimeline(fromDate, toDate, 'years', 'YYYY')
          );

        default:
          return [];
      }
    },
    []
  );

  useEffect(() => {
    setData(
      handleChartRawData(
        businessReportChart,
        typeTimeCode,
        from,
        to,
        type_time_code
      )
    );
  }, [businessReportChart, typeTimeCode, from, to, type_time_code]);

  return (
    <Row gutter={[16, 16]} justify="space-between">
      <Col xxl={18} xl={14} lg={16} md={24} sm={24} xs={24}>
        <span style={{ fontSize: '16px' }}>
          {VietnameseString.sales_of_closed_contracts_over_time}
        </span>
        {isLoadingChart ? (
          <Skeleton active />
        ) : (
          <ResponsiveContainer width="100%" height={350}>
            <ComposedChart data={data}>
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="name" scale="band" />
              <YAxis
                allowDataOverflow
                domain={['dataMin', dataMax => dataMax * 2 + 2]}
                type="number"
              />
              <YAxis
                orientation="right"
                allowDataOverflow
                type="number"
                yAxisId="yRight"
                tickFormatter={v => `${formatPrice(v)} đ`}
              />
              <Tooltip
                formatter={(value, name, props) => {
                  if (
                    name === VietnameseString.sale ||
                    name === VietnameseString.revenue
                  ) {
                    return `${formatPrice(value)} đ`;
                  } else {
                    return value;
                  }
                }}
              />
              <Legend />

              {businessReportChartWithProduct.map((item, index) => (
                <Bar
                  key={index}
                  dataKey={item.product.name}
                  barSize={20}
                  stackId="product"
                  fill={arrayColor[index % 18]}
                />
              ))}
              <Line
                yAxisId="yRight"
                type="monotone"
                dataKey={VietnameseString.sale}
                stroke="#ff7300"
              />
              <Line
                yAxisId="yRight"
                type="monotone"
                dataKey={VietnameseString.revenue}
                stroke="#4077ee"
              />
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </Col>

      <Col xxl={6} xl={10} lg={8} md={24} sm={24} xs={24}>
        <span style={{ fontSize: '16px' }}>
          {VietnameseString.earn_money_by_product}
        </span>
        <TableAntStyle
          bordered={true}
          dataSource={businessReportChartWithProduct.sort(
            (a, b) => b.total_revenue - a.total_revenue
          )}
          loading={isLoadingChartWithProduct}
          columns={[
            {
              title: VietnameseString.product,
              dataIndex: '',
              key: '',
              render: (_text, record, index) => {
                return (
                  <b style={{ color: arrayColor[index % 24] }}>
                    {record.product.name}
                  </b>
                );
              },
            },
            {
              title: VietnameseString.into_money,
              align: 'right',
              dataIndex: '',
              key: '',
              render: (_text, record, _index) => (
                <b>
                  {record.total_revenue &&
                    `${formatPrice(record.total_revenue)} đ`}
                </b>
              ),
            },
            {
              title: '%',
              align: 'right',
              dataIndex: 'percent',
              key: 'percent',
              render: (percent, _record, _index) => {
                return (
                  <b>{Number(percent).toFixed(numberOfFractionalDigits)} %</b>
                );
              },
            },
          ]}
          pagination={false}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>{VietnameseString.no_product}</span>}
              />
            ),
          }}
          summary={pageData => {
            let totalPriceProduct = pageData.reduce(
              (previousValue, currentValue, currentIndex, array) => {
                return previousValue + parseFloat(currentValue.total_revenue);
              },
              0
            );

            let totalPercent = pageData.reduce(
              (previousValue, currentValue, currentIndex, array) => {
                return (
                  previousValue +
                  parseFloat(
                    Number(currentValue.percent).toFixed(
                      numberOfFractionalDigits
                    )
                  )
                );
              },
              0
            );

            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell>
                    <b>Tổng</b>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell>
                    <b style={{ display: 'flex', justifyContent: 'end' }}>
                      {formatPrice(totalPriceProduct)} đ
                    </b>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell>
                    <b style={{ display: 'flex', justifyContent: 'end' }}>
                      {Number(totalPercent).toFixed(numberOfFractionalDigits)} %
                    </b>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </Col>
    </Row>
  );
}
