import * as AntdIcons from '@ant-design/icons';
import React from 'react';
import propTypes from 'prop-types';

const IconAntd = ({
  spin = false,
  icon,
  props,
  size = '20px',
  style,
  className,
  color = '#222',
}) => {
  //@ts-ignore
  const AntdIcon = AntdIcons[icon];

  return (
    <AntdIcon
      spin={spin}
      style={{
        fontSize: `${size} !important`,
        height: size,
        display: 'flex',
        alignItems: 'center',
        color,
        ...style,
      }}
      className={className}
      {...props}
    />
  );
};

IconAntd.propTypes = {
  spin: propTypes.bool,
  icon: propTypes.string,
  props: propTypes.object,
  size: propTypes.string,
  style: propTypes.object,
};

export default IconAntd;
