import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

const SectionGrid = ({ children, ...props }) => {
  return <Col {...props}>{children}</Col>;
};

SectionGrid.defaultProps = {
  ...Col.defaultProps,
};
SectionGrid.propTypes = {
  ...Col.propTypes,
};

const sectionStyles = { padding: '0px 0px', borderRadius: '8px', marginBottom: '20px' };
const Section = ({ children, ...props }) => {
  const styles = sectionStyles;
  return (
    <Row className="mt-1" style={styles} {...props}>
      {children}
    </Row>
  );
};

Section.Grid = SectionGrid;

Section.propTypes = {};

export default Section;
