import { Select } from 'antd';
import React from 'react';
import { Icon } from 'react-icons-kit';
import { microphone } from 'react-icons-kit/fa/microphone';
import { microphoneSlash } from 'react-icons-kit/fa/microphoneSlash';
import styled from 'styled-components';

const MicrophoneList = ({ onChange }) => {
  const [microphones, setMicrophones] = React.useState([]);
  const [isMicro, setIsMicro] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      try {
        // Yêu cầu truy cập vào microphone
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        if (stream.active) {
          // // Lấy danh sách các thiết bị âm thanh (bao gồm cả microphone)
          navigator.mediaDevices
            .enumerateDevices()
            .then(devices => {
              const microphoneDevices = devices.filter(
                device => device.kind === 'audioinput'
              );
              setMicrophones(microphoneDevices);
            })
            .catch(error => {
              console.error('Error enumerating devices:', error);
            });
        }
        console.log('Microphone access granted:', stream);
        // Bạn có thể làm gì đó với luồng âm thanh ở đây
      } catch (error) {
        if (error?.name === 'PermissionDeniedError') {
          alert(
            'Vui lòng bật quyền truy cập vào microphone để sử dụng tính năng call.'
          );
        }
        setIsMicro(false);
      }
    })();
  }, []);

  const [audioDevice, setAudioDevice] = React.useState(null);

  React.useEffect(() => {
    const getAudioDeviceInfo = async () => {
      try {
        // Yêu cầu truy cập vào microphone
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        const audioTrack = stream.getAudioTracks()[0];
        const audioDevice = audioTrack.getSettings().deviceId;
        setAudioDevice(audioDevice);
      } catch (error) {
        console.log(
          '🚀 ~ file: index.jsx:53 ~ getAudioDeviceInfo ~ error:',
          error
        );
        alert(
          'Vui lòng bật quyền truy cập vào microphone để sử dụng tính năng call.'
        );
        setIsMicro(false);
      }
    };

    getAudioDeviceInfo();
  }, []);

  const handleDeviceChange = async deviceId => {
    setAudioDevice(deviceId);
    onChange && onChange(deviceId);
    try {
      // Yêu cầu truy cập vào thiết bị âm thanh được chọn
      await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: { exact: deviceId } },
      });

      console.log('Selected audio device:', deviceId);
      // Bạn có thể làm gì đó với luồng âm thanh ở đây
    } catch (error) {
      console.error('Error accessing audio device:', error);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div>
        {isMicro ? (
          <Icon icon={microphone} />
        ) : (
          <Icon
            style={{ color: 'red', marginRight: '4px' }}
            icon={microphoneSlash}
          />
        )}
      </div>
      {isMicro ? (
        <SelectMicroStyled
          value={audioDevice}
          placeholder="Chọn Micrô"
          onChange={value => {
            handleDeviceChange(value);
          }}
          // size="small"
          bordered={false}
        >
          {microphones.map(microphone => (
            <Select.Option
              key={microphone.deviceId}
              value={microphone.deviceId}
            >
              {microphone.label}
            </Select.Option>
          ))}
        </SelectMicroStyled>
      ) : (
        <span style={{ color: 'red', fontSize: '11px' }}>
          Microphone đang bị tắt hoặc không khả dụng!
        </span>
      )}
    </div>
  );
};

const SelectMicroStyled = styled(Select)`
  /* width: 300px; */
  border-bottom: 1px solid #e5e5e5;

  & * {
    font-size: 12px !important;
  }
`;

export default MicrophoneList;
