import { VietnameseString } from '@constants/VietnameseString';
import {
  ColStyled,
  LabelStyled,
  RadioGroupStyled,
} from '@screens/Enterprise/ElectronicVoucher/ElectronicVoucherStyled';
import { Col, Form, InputNumber, Radio, Row } from 'antd';
import React from 'react';

const AccumulatePoints = ({ typeVoucher }) => {
  return (
    <div>
      <Row>
        <LabelStyled>{VietnameseString.accumulate_points}</LabelStyled>
      </Row>

      <Row style={{ padding: '0 10px', width: '100%' }}>
        <ColStyled span={24}>
          <Form.Item
            style={{ width: '100%' }}
            name="type_save_point"
            wrapperCol={{ span: 24 }}
          >
            <RadioGroupStyled>
              <Row style={{ marginBottom: '6px' }}>
                <Col span={8}>
                  <Radio className="radio-option" value="amountOfMoney">
                    Số điểm
                  </Radio>
                </Col>
                <Col span={16}>
                  {typeVoucher?.type_save_point === 'amountOfMoney' && (
                    <Form.Item
                      colon={false}
                      name="save_point"
                      style={{ marginBottom: 0 }}
                      rules={
                        typeVoucher?.type_save_point === 'amountOfMoney' && [
                          { required: true, message: 'Vui lòng nhập số điểm' },
                          {
                            validator: (_, value) => {
                              if (+value < 1) {
                                return Promise.reject(
                                  new Error('Số điểm phải lớn hơn 0!')
                                );
                              }

                              if (+value > 100000000000) {
                                return Promise.reject(
                                  new Error('Số điểm phải nhỏ hơn 100 tỷ!')
                                );
                              }

                              return Promise.resolve();
                            },
                          },
                        ]
                      }
                      normalize={value =>
                        value?.toString()?.replace(/[^\wÀ-úÀ-ÿ]/g, '')
                      }
                    >
                      <InputNumber
                        addonAfter="Điểm"
                        style={{ width: '100%' }}
                        formatter={value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={value =>
                          value ? value.replace(/\$\s?|(,*)|\./g, '') : ''
                        }
                        placeholder="Số điểm"
                        controls={false}
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Row>
                <Col
                  style={{ display: 'flex', alignItems: 'center' }}
                  span={10}
                >
                  <Radio className="radio-option" value="percentage">
                    Theo % số tiền trên đơn hàng
                  </Radio>
                </Col>
                <Col span={14}>
                  {typeVoucher?.type_save_point === 'percentage' && (
                    <Form.Item
                      labelCol={{ span: 12 }}
                      name="save_point_percent"
                      colon={false}
                      style={{ marginBottom: 0 }}
                      rules={
                        typeVoucher?.type_save_point === 'percentage' && [
                          {
                            required: true,
                            message: 'Vui lòng nhập số phần trăm',
                          },
                          {
                            validator: (_, value) => {
                              if (+value < 1) {
                                return Promise.reject(
                                  new Error('Số % phải lớn hơn 0!')
                                );
                              }

                              if (+value > 100) {
                                return Promise.reject(
                                  new Error('Số % phải nhỏ hơn 100!')
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]
                      }
                      normalize={value =>
                        value?.toString()?.replace(/[^\wÀ-úÀ-ÿ]/g, '')
                      }
                    >
                      <InputNumber
                        addonAfter="%"
                        style={{ width: '100%' }}
                        formatter={value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={value =>
                          value ? value.replace(/\$\s?|(,*)|\./g, '') : ''
                        }
                        placeholder="Số phần trăm"
                        controls={false}
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </RadioGroupStyled>
          </Form.Item>
        </ColStyled>
      </Row>

      {/* <Row>
    <ColStyled span={24}>
      <RadioGroupStyled
        // value={valueOfTheVoucher.accumulatePoints.selected}
        // onChange={(event) => {
        //   dispatch(
        //     actions.setPromotionsValueOfTheVoucher({
        //       accumulatePoints: {
        //         selected: event.target.value,
        //         amountOfPoint: undefined,
        //         percentage: undefined,
        //       },
        //     })
        //   );
        // }}
      >
          <Radio className="radio-option" value="amountOfPoint">
            <span>Số điểm:</span>

            <span>
              <InputStyled
                placeholder="Số điểm"
                disabled={valueOfTheVoucher.accumulatePoints.selected !== 'amountOfPoint'}
                value={formatPrice(valueOfTheVoucher.accumulatePoints.amountOfPoint)}
                onChange={(event) => {
                  dispatch(
                    actions.setPromotionsValueOfTheVoucher({
                      accumulatePoints: {
                        amountOfPoint: event.target.value.replaceAll(REGEXP_WITH_NUMBER, ''),
                      },
                    })
                  );
                }}
              />
            </span>
          </Radio>

          <Radio className="radio-option" value="percentage">
            <span>Theo % số tiền trên đơn hàng:</span>

            <span>
              <InputStyled
                suffix="%"
                placeholder="Số phần trăm"
                disabled={valueOfTheVoucher.accumulatePoints.selected !== 'percentage'}
                value={formatPrice(valueOfTheVoucher.accumulatePoints.percentage)}
                onChange={(event) => {
                  dispatch(
                    actions.setPromotionsValueOfTheVoucher({
                      accumulatePoints: {
                        percentage: event.target.value.replaceAll(REGEXP_WITH_NUMBER, ''),
                      },
                    })
                  );
                }}
              />
            </span>
          </Radio>
      </RadioGroupStyled>
    </ColStyled>
  </Row> */}
    </div>
  );
};

export default AccumulatePoints;
