import React, { useEffect, useState } from 'react';
import { Col, DatePicker, Empty, Row, Select } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/vi_VN';

import { CONDITION, TIME_KEEPING_TYPE } from '../Constants.js';
import { setSearchParamsInUrlVer2, rmVN } from '@constants/funcHelper';
import { VietnameseString } from '@constants/VietnameseString';
import { requestGetAllDepartment, requestGetTelemarketingInDepartment } from '@constants/Api';
import { TIME_CODE_TYPE } from '@constants/Constant.js';
import { requestGetAllCategoryLocationReport } from '../services/api.js';

const { RangePicker } = DatePicker;

function Filter(props) {
  const { locationReportSearch, onLocationReportSearchChange, onLocationReportSearchTimeChange, setTimeFilterType } =
    props;

  const [telemarketing, setTelemarketing] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [categories, setCategories] = useState([]);

  const getAllTelemarketing = async (departmentId) => {
    try {
      const result = await requestGetTelemarketingInDepartment(departmentId);
      const telemarketing =
        result?.data?.map((sale) => ({
          ...sale,
          value: sale.id,
          label: sale.full_name,
        })) || [];
      setTelemarketing(telemarketing);
    } catch (error) {}
  };

  const getAllDepartment = async () => {
    try {
      const result = await requestGetAllDepartment();
      setDepartments(result.data);
    } catch (error) {}
  };

  const getAllCategoryLocationReport = async () => {
    try {
      const { data } = await requestGetAllCategoryLocationReport();
      setCategories(data);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getAllDepartment();
    getAllCategoryLocationReport();
  }, []);

  useEffect(() => {
    getAllTelemarketing(locationReportSearch.departmentId);
  }, [locationReportSearch.departmentId]);

  const renderTimeFilter = (timeType) => {
    let picker = 'day';
    let format = 'DD/MM/YYYY';

    switch (timeType) {
      case TIME_CODE_TYPE.DATE:
        picker = 'day';
        format = 'DD/MM/YYYY';
        break;

      case TIME_CODE_TYPE.WEEK:
        picker = 'week';
        format = 'W/YYYY';
        break;

      case TIME_CODE_TYPE.MONTH:
        picker = 'month';
        format = 'MM/YYYY';
        break;

      case TIME_CODE_TYPE.YEAR:
        picker = 'year';
        format = 'YYYY';
        break;

      default:
        break;
    }

    return (
      <RangePicker
        allowClear={false}
        locale={locale}
        picker={picker !== 'day' ? picker : 'date'}
        format={format}
        value={[moment(locationReportSearch.from), moment(locationReportSearch.to)]}
        disabledDate={(currentDate) => currentDate && currentDate > moment().endOf(picker)}
        onChange={(date) => {
          let to = moment(date[1]).endOf(picker).format('YYYY/MM/DD');

          if (moment(date[1]).endOf(picker) > moment(new Date())) {
            to = moment(new Date()).format('YYYY/MM/DD');
          }

          onLocationReportSearchTimeChange(moment(date[0]).format('YYYY/MM/DD'), to);
        }}
      />
    );
  };

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: '10px' }}>
      <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
        <Select
          style={{ width: '100%' }}
          mode="multiple"
          optionFilterProp="children"
          filterOption={(input, option) => {
            return rmVN(option.children).indexOf(rmVN(input)) >= 0;
          }}
          placeholder={VietnameseString.telemarketing}
          allowClear
          maxTagCount={1}
          value={locationReportSearch.telemarketingId}
          notFoundContent={
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>{VietnameseString.no_data}</span>} />
          }
          onChange={(value) => {
            onLocationReportSearchChange('telemarketingId', value);
          }}
        >
          {telemarketing.map((item, index) => (
            <Select.Option key={index} value={item.id}>
              {item.full_name}
            </Select.Option>
          ))}
        </Select>
      </Col>

      <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
        <Select
          style={{ width: '100%' }}
          mode="multiple"
          optionFilterProp="children"
          filterOption={(input, option) => {
            return rmVN(option.children).indexOf(rmVN(input)) >= 0;
          }}
          placeholder={VietnameseString.department}
          onClear={() => {
            onLocationReportSearchChange('telemarketingId', []);
          }}
          allowClear
          maxTagCount={1}
          value={locationReportSearch.departmentId}
          notFoundContent={
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>{VietnameseString.no_data}</span>} />
          }
          onChange={(value) => {
            onLocationReportSearchChange('departmentId', value);
          }}
        >
          {departments.map((item, index) => (
            <Select.Option key={index} value={item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Col>

      <Col xxl={3} xl={6} lg={6} md={6} sm={12} xs={24}>
        <Select
          style={{ width: '100%' }}
          mode="multiple"
          optionFilterProp="children"
          filterOption={(input, option) => {
            return rmVN(option.children).indexOf(rmVN(input)) >= 0;
          }}
          placeholder={VietnameseString.name_of_attendance_type}
          onClear={() => {
            onLocationReportSearchChange('categoryId', []);
          }}
          allowClear
          maxTagCount={1}
          value={locationReportSearch.categoryId}
          onChange={(value) => {
            onLocationReportSearchChange('categoryId', value);
          }}
        >
          {categories.map((item, index) => (
            <Select.Option key={index} value={item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Col>

      <Col xxl={3} xl={6} lg={6} md={6} sm={12} xs={24}>
        <Select
          style={{ width: '100%' }}
          mode="multiple"
          optionFilterProp="children"
          filterOption={(input, option) => {
            return rmVN(option.children).indexOf(rmVN(input)) >= 0;
          }}
          placeholder={VietnameseString.status}
          onClear={() => {
            onLocationReportSearchChange('isLate', []);
          }}
          allowClear
          maxTagCount={1}
          value={locationReportSearch.isLate}
          onChange={(value) => {
            onLocationReportSearchChange('isLate', value);
          }}
        >
          {TIME_KEEPING_TYPE.map((item, index) => (
            <Select.Option key={index} value={item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Col>

      <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
        <Select
          style={{ width: '100%' }}
          mode="multiple"
          optionFilterProp="children"
          filterOption={(input, option) => {
            return rmVN(option.children).indexOf(rmVN(input)) >= 0;
          }}
          placeholder={VietnameseString.condition}
          onClear={() => {
            onLocationReportSearchChange('verifyAddress', []);
          }}
          allowClear
          maxTagCount={1}
          value={locationReportSearch.verifyAddress}
          onChange={(value) => {
            onLocationReportSearchChange('verifyAddress', value);
          }}
        >
          {CONDITION.map((item, index) => (
            <Select.Option key={index} value={item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Col>

      <Col xxl={2} xl={6} lg={6} md={6} sm={12} xs={24}>
        <Select
          style={{ width: '100%' }}
          value={locationReportSearch.timeType}
          onChange={(value) => {
            setTimeFilterType(value);

            setSearchParamsInUrlVer2([{ timeType: value, page: 1 }]);
          }}
        >
          <Select.Option value={TIME_CODE_TYPE.DATE}>{VietnameseString.date}</Select.Option>
          <Select.Option value={TIME_CODE_TYPE.WEEK}>{VietnameseString.week}</Select.Option>
          <Select.Option value={TIME_CODE_TYPE.MONTH}>{VietnameseString.month}</Select.Option>
          <Select.Option value={TIME_CODE_TYPE.YEAR}>{VietnameseString.year}</Select.Option>
        </Select>
      </Col>

      <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
        {renderTimeFilter(locationReportSearch.timeType)}
      </Col>
    </Row>
  );
}

export default Filter;

Filter.propTypes = {
  locationReportSearch: PropTypes.object,
  onLocationReportSearchChange: PropTypes.func,
  onLocationReportSearchTimeChange: PropTypes.func,
  setTimeFilterType: PropTypes.func,
};
