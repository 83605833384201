import axios from 'axios';
import Cookie from 'js-cookie';
import Reactotron from '../ReactotronConfig';

//********************************|  API  |*************************************//

import { default as CustomerService } from './endpoint/customerApi';
import CustomerGroupService from './endpoint/customerGroupApi';

const reactotron =
  process.env.NODE_ENV !== 'production' &&
  require('reactotron-react-js').default;

export function getBaseURL() {
  if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL;
  }
  if (process.env.NODE_ENV === 'production') {
    return null;
  }
  return null;
}

export function getSocketURL() {
  if (process.env.REACT_APP_SOCKET_URL) {
    return process.env.REACT_APP_SOCKET_URL;
  }
  if (process.env.NODE_ENV === 'production') {
    return null;
  }
  return null;
}

//********************************|  CONFIG  |*************************************//
function createAxios() {
  var axiosInstant = axios.create();
  // if (process.env.REACT_APP_ENV === 'test') {
  //   axiosInstant.defaults.baseURL = 'http://3.1.13.10:8018/';
  // } else {
  //   // axiosInstant.defaults.baseURL = "http://18.141.210.230:8008/";
  //   // axiosInstant.defaults.baseURL = 'http://localhost:3030/';
  axiosInstant.defaults.baseURL = process.env.REACT_APP_API_URL;
  // axiosInstant.defaults.baseURL = process.env.REACT_APP_API_URL || 'https://ezsale.winds.vn/api/';
  // axiosInstant.defaults.baseURL = 'http://localhost:8008/';
  // }
  // axiosInstant.defaults.baseURL = 'https://staging.api.ezsale.vn/';
  axiosInstant.defaults.timeout = 120000;
  axiosInstant.defaults.headers = { 'Content-Type': 'application/json' };

  axiosInstant.interceptors.request.use(
    async config => {
      config.headers.token = Cookie.get('SESSION_ID');
      return config;
    },
    error => Promise.reject(error)
  );

  axiosInstant.interceptors.response.use(response => {
    Reactotron.apisauce(response);

    // if (response.data && response.data.code == 403) {
    //   showMessages(
    //     R.strings().notif_tab_cus,
    //     R.strings().require_login_againt,
    //     () =>
    //       AsyncStorage.removeItem(ASYNC_STORAGE.TOKEN, () => {
    //         const store = require("@app/redux/store").default;
    //         store.dispatch({ type: "reset" });
    //         NavigationUtil.navigate(SCREEN_ROUTER_AUTH.AUTH_LOADING);
    //       })
    //   );
    // } else if (response.data && response.data.status != 1)
    //   showMessages(R.strings().notif_tab_cus, response.data.message);
    return response;
  });

  return axiosInstant;
}

// return axiosInstant;
// }

export const getAxios = createAxios();

/* Support function */
export function handleResult(api) {
  return api
    .then(res => {
      if (res.data.status != 1) {
        if (res.data.code === 403) {
          if (Cookie.get('SESSION_ID')) {
            alert('Phiên đăng nhập hết hạn');
          }
          Cookie.remove('SESSION_ID');
          // alert('Phiên đăng nhập hết hạn');
          window.location.href = '/Login';
        }
        return Promise.reject(res.data);
      }
      return Promise.resolve(res.data);
    })
    .catch(err => {
      if (err.msg) return Promise.reject({ ...err });
      return Promise.reject({ ...err, msg: err.message });
    });
}
export const handleObjectEmpty = obj => {
  const cloneObj = { ...obj };

  for (const key in cloneObj) {
    if (Object.prototype.hasOwnProperty.call(cloneObj, key)) {
      const element = cloneObj[key];
      if (element === '' || element === null) delete cloneObj[key];
    }
  }
  return cloneObj;
};

export const ApiClient = {
  get: (endpoint, payload) => {
    return handleResult(getAxios.get(endpoint, payload));
  },
  post: (endpoint, payload) => {
    return handleResult(getAxios.post(endpoint, payload));
  },
  getFalsy: (endpoint, payload) => {
    return handleResult(getAxios.get(endpoint, handleObjectEmpty(payload)));
  },
  postFormData: (endpoint, payload) => {
    const headers = { 'Content-Type': 'multipart/form-data' };
    return handleResult(getAxios.post(endpoint, payload, { headers: headers }));
  },
};

// all endpoint relate to customer
export const CustomerApi = CustomerService(ApiClient);
export const CustomerGroupApi = CustomerGroupService(ApiClient);

// WEB ADMIN ------------------------------------------------------------------------------------

// USER INFO
export const requestGetUserInfo = () => {
  return handleResult(getAxios.get(`user/detail`));
};

// LOGIN
export const requestLogin = payload => {
  return handleResult(
    getAxios.post(`user/login`, {
      user_name: payload.USERNAME,
      password: payload.PASS,
      // platform: 'ios',
      // device_id: 'demo',
    })
  );
};

// LOGOUT
export const requestLogout = () => {
  return handleResult(getAxios.post(`user/logout`));
};

export const forgetPassword = payload => {
  return handleResult(getAxios.post(`user/forgotPassword`, payload));
};

// CHANGE PASSWORD
export const requestChangePass = payload => {
  return handleResult(getAxios.post(`user/changePassWord`, payload));
};

// GET LIST ROLE
export const getListRole = () => {
  return handleResult(getAxios.get(`util/role`));
};

// TELESALE LIST
export const requestSaleList = (
  payload = {
    search: '',
    province_id: '',
    status: '',
    work_type: '',
    active: '',
    type: '',
    page: 1,
    enterprise_id: '',
    job_id: '',
    department_id: '',
  }
) => {
  if (payload.province_id === 0) payload.province_id = null;
  if (!payload.province_id) payload.province_id = '';
  if (!payload.search) payload.search = '';
  if (!payload.status) payload.status = '';
  if (!payload.work_type) payload.work_type = '';
  if (!payload.active) payload.active = '';
  if (!payload.type) payload.type = '';
  if (!payload.enterprise_id) payload.enterprise_id = '';
  if (!payload.job_id) payload.job_id = '';
  if (!payload.department_id) payload.department_id = '';

  return handleResult(
    getAxios.get(
      `sale/list?search=${payload.search}` +
        `&province_id=${payload.province_id}` +
        `&is_active=${payload.status}` +
        `&type=1` +
        `&work_type=${payload.work_type}` +
        `&enterprise_id=${payload.enterprise_id}` +
        `&page=${payload.page}` +
        `&job_id=${payload.job_id}` +
        `&limit=${1000}` +
        `&department_id=${payload.department_id}`
    )
  );
};

export const requestGetFullSaleList = () => {
  return handleResult(
    getAxios.get(
      `sale/list?search=&province_id=&is_active=&type=1&work_type=&enterprise_id=&page=1&job_id=&department_id=&limit=1000`
    )
  );
};

// TELESALE ASSiGN EXPRESS
export const requestSaleAssignExpress = (
  { search = '', page = '1', group_id = '', call_note = '', ...filters },
  option
) => {
  const otherOptions = toQuerystringFromObject(filters);
  return handleResult(
    getAxios.get(
      `enterprise/jobTelesale?query=${encodeURIComponent(search.trim())}` +
        // `&province_ids=${province_ids.join(',')}` +
        `&group_id=${group_id}` +
        `&page=${page}` +
        `&search` +
        `&call_note=${call_note}` +
        `&${otherOptions}`,
      option
    )
  );
};

// TELESALE DETAIL
export const requestSaleDetail = payload => {
  return handleResult(getAxios.get(`sale/detail?id=${payload.id}`));
};

export const requestDeleteSale = payload => {
  return handleResult(getAxios.post(`sale/destroy`, payload));
};

// TELESALE CREATE
export const requestCreateTelesale = payload => {
  return handleResult(
    getAxios.post('sale/create', payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  );
};

// TELESALE UPDATE
export const requestUpdateTelesale = payload => {
  return handleResult(
    getAxios.post('sale/update', payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  );
};

// TELESALE INACTIVE LIST
export const requestSaleInactiveList = (
  payload = {
    search: '',
    province_id: '',
    status: '',
    work_type: '',
    active: '',
    type: '',
    page: 1,
  }
) => {
  if (payload.province_id === 0) payload.province_id = null;
  if (!payload.province_id) payload.province_id = '';
  if (!payload.search) payload.search = '';
  if (!payload.status) payload.status = '';
  if (!payload.work_type) payload.work_type = '';
  if (!payload.active) payload.active = '';
  if (!payload.type) payload.type = '';

  return handleResult(
    getAxios.get(
      `sale/list?search=${payload.search}` +
        `&province_id=${payload.province_id}` +
        `&is_active=${payload.status}` +
        `&type=4` +
        `&work_type=${payload.work_type}` +
        `&page=${payload.page}`
    )
  );
};

// TELESALE UPDATE STATUS
export const requestUpdateSaleStatus = payload => {
  return handleResult(getAxios.post('sale/changeStatus', payload));
};

export const requestGetListHotline = payload => {
  return handleResult(
    getAxios.get(
      `config/hotlines?enterprise_id=${payload.enterprise_id}&search=${payload.search}&type=${payload.type}&page=${payload.page}`,
      payload
    )
  );
};

// REGISTER LIST
export const requestRegisterList = (
  payload = {
    search: '',
    province_id: '',
    status: '',
    // enterprise_id: '',
  }
) => {
  return handleResult(
    getAxios.get(
      `sale/list?page=${payload.page}` +
        `&search=${payload.search}` +
        `&province_id=${payload.province_id}` +
        `&status=${payload.status}` +
        `&type=2`
      // `&enterprise_id=${payload.enterprise_id}`
    )
  );
};

// REJECTED REGISTER LIST
export const requestRejectedRegisterList = (
  payload = {
    search: '',
    province_id: '',
    status: '',
    work_type: '',
    active: '',
    type: '',
    page: 1,
  }
) => {
  return handleResult(
    getAxios.get(
      `sale/list?search=${payload.search}` +
        `&type=3` +
        `&province_id=${payload.province_id}` +
        `&status=${payload.status}` +
        `&page=${payload.page || 1}`
    )
  );
};

// PROVINCE LIST
export const requestProvinceList = () => {
  return handleResult(getAxios.get('util/province'));
};

// ACCOUNT LIST
export const requestAccountList = (
  payload = {
    search: '',
    role_id: '',
    page: 1,
  }
) => {
  return handleResult(
    getAxios.get(
      `user/list?page=${payload.page}` +
        `&search=${payload.search}` +
        `&role_id=${payload.role_id}`
    )
  );
};

// ACCOUNT CREATE
export const requestCreateAccount = payload => {
  return handleResult(getAxios.post('user/createUser', { ...payload }));
};

// ACCOUNT UPDATE
export const requestUpdateAccount = payload => {
  return handleResult(getAxios.post('user/update', payload));
};

export const requestUpdateAccountEnterprise = payload => {
  return handleResult(getAxios.post('user/updateReviewer', payload));
};

export const requestDeleteAccount = payload => {
  return handleResult(getAxios.post('user/delete', { ...payload }));
};

// ACCOUNT RESET PASSWORD
export const requestResetPassword = payload => {
  return handleResult(getAxios.post('user/resetPassword', payload));
};

export const changeActive = payload => {
  return handleResult(getAxios.post('job/changeActive', payload));
};

// LẤY DANH SÁCH CÁC CÔNG TY
export const requestCompanyList = (
  payload = {
    search: '',
    is_active: '',
  }
) => {
  return handleResult(
    getAxios.get(
      `enterprise/list?page=${payload.page}` +
        `&search=${payload.search}` +
        `&is_active=${payload.is_active}`
    )
  );
};

// TẠO CÔNG TY
export const requesCreateCompany = payload => {
  return handleResult(
    getAxios.post('admin/createEnterprise', payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  );
};

// COMPANY UPDATE
export const requestUpdateCompany = payload => {
  return handleResult(
    getAxios.post('enterprise/update', payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  );
};

// COMPANY DETAIL
export const requestCompanyDetail = payload => {
  return handleResult(getAxios.get(`enterprise/detail?id=${payload.id}`));
};

// WEB ENTERPRISE ------------------------------------------------------------------------------------

// GENERAL CATEGORY LIST
export const requestSubJobList = (
  payload = {
    search: '',
    is_active: '',
    page: '1',
  }
) => {
  return handleResult(
    getAxios.get(
      `job/list?page=${payload.page}&search=${payload.search}&is_active=${payload.is_active}`
    )
  );
};

export const getCallDurationStatitic = payload => {
  return handleResult(
    getAxios.get(
      `user/callDurationStatistic?telesale_id=${payload.telesale_id}&fromDate=${payload.fromDate}&toDate=${payload.toDate}`
    )
  );
};

export const changeActiveTelesale = payload => {
  return handleResult(getAxios.post('sale/active', payload));
};

// CATEGORY LIST IN CREATE JOB
export const requestCategoryFilterList = (
  payload = {
    enterprise_id: '',
  }
) => {
  return handleResult(
    getAxios.get(
      `category/listFilter?enterprise_id=${payload.enterprise_id}`,
      payload
    )
  );
};

// GENERAL JOB CREATE
export const requestCreateCategory = payload => {
  return handleResult(getAxios.post('category/create', { ...payload }));
};
// GENERAL JOB UPDATE
export const requestUpdateCategory = payload => {
  return handleResult(getAxios.post('category/update', payload));
};

// GENERAL JOB DETAIL
export const requestCategoryDetail = payload => {
  return handleResult(getAxios.get(`category/detail`, payload));
};

export const requestStatisticByJob = payload => {
  return handleResult(
    getAxios.get(
      `opportunity/opportunityStatisticByJob?fromDate=${payload.fromDate}&toDate=${payload.toDate}&job_id=${payload.job_id}`,
      payload
    )
  );
};

// LẤY DANH SÁCH HOTLINE
export const requestHotlineList = (
  payload = {
    search: '',
  }
) => {
  return handleResult(
    getAxios.get(`config/hotlines?search=${payload.search}`, payload)
  );
};
// HOTLINE CREATE
export const requestCreateHotline = payload => {
  return handleResult(getAxios.post('config/createHotlineEZ', { ...payload }));
};

// HOTLINE UPDATE
export const requestUpdateHotline = payload => {
  return handleResult(getAxios.post('config/updateHotline', payload));
};

// HOTLINE DELETE
export const requestDeleteHotline = payload => {
  return handleResult(getAxios.post('config/deleteHotline', payload));
};

export const requestGetHotlineInfo = payload => {
  return handleResult(
    getAxios.get(
      `config/getHotlineInfo?enterprise_id=${payload.enterprise_id}&id=${payload.id}`
    ),
    payload
  );
};

export const requestVehiclesList = payload => {
  return handleResult(
    getAxios.get(
      `customer/customerVehicles?customer_id=${payload.customer_id}`
    ),
    payload
  );
};

export const requestCallToken = payload => {
  return handleResult(getAxios.get(`user/getToken`), payload);
};

export const requestEZAcc = () => {
  return handleResult(getAxios.get(`/user/windsoftCenterUser`));
};

export const requestGetHotline = payload => {
  return handleResult(getAxios.get(`sale/getHotline`), payload);
};

export const requestGetHotlineV2 = payload => {
  return handleResult(getAxios.get(`sale/v2/getHotline?isFindAll=1`), payload);
};

// CUSTOMER LIST
// export const requestCustomerList = ({ search = '', page = '1', province_ids = [], group_id = '', ...filters }) => {
export const requestCustomerList = (
  { search = '', page = '1', group_id = '', call_note = '', ...filters },
  option
) => {
  const otherOptions = toQuerystringFromObject(filters);
  return handleResult(
    getAxios.get(
      `customer/list?query=${encodeURIComponent(search.trim())}` +
        // `&province_ids=${province_ids.join(',')}` +
        `&group_id=${group_id}` +
        `&call_note=${call_note}` +
        `&page=${page}` +
        `&${otherOptions}`,
      option
    )
  );
};
export const requestCustomerFind = phone => {
  return handleResult(getAxios.get(`customer/find?phone=${phone}`));
};
export const requestCustomerVehiclesCollections = payload => {
  return handleResult(
    getAxios.get(
      `customer/customerVehiclesCollections?customer_id=${payload.customer_id}`
    ),
    payload
  );
};
// CUSTOMER DETAIL
export const requestCustomerDetail = id => {
  return handleResult(getAxios.get(`customer/detail?id=${id}`));
};

//cũ
// export const requestCustomerDetailInteractionHistory = ({
//   id,
//   page,
//   limit,

// }) => {
//   return handleResult(
//     getAxios.get(
//       `customer/detailCustomerHistory?id=${id}&limit=${limit}&page=${page}`
//     )
//   );
// };

//updateV2

export const requestCustomerDetailInteractionHistory = ({
  id,
  page,
  limit,
  cursor_id,
}) => {
  return handleResult(
    getAxios.get(
      `customer/detailCustomerHistoryV2?id=${id}&limit=${limit}&page=${page}&cursor_direction=next&cursor_id=${cursor_id}`
    )
  );
};

// CUSTOMER CREATE
export const requestCustomerCreate = payload => {
  return handleResult(getAxios.post(`customer/create`, payload));
};
// CUSTOMER UPDATE
export const requestCustomerUpdate = payload => {
  return handleResult(getAxios.post(`customer/update-v2`, payload));
};

//create customer sub number phone
export const requestCreateCustomerSubPhoneNumber = payload => {
  return handleResult(getAxios.post('customer/createCustomerPhone', payload));
};
export const requestUpdateCustomerSubPhoneNumber = payload => {
  return handleResult(getAxios.post('customer/updateCustomerPhone', payload));
};
export const requestDestroyCustomerSubPhoneNumber = payload => {
  return handleResult(getAxios.post('customer/destroyCustomerPhone', payload));
};

// CUSTOMER IMPORT
export const requestCustomerImport = payload => {
  return handleResult(getAxios.post(`customer/import`, payload));
};

// CUSTOMER TRANSPORT
export const getListTransportCustomer = () => {
  return handleResult(getAxios.get(`customer/dataamendments`));
};

//CUSTOMER DEAL
export const getListDealCustomer = payload => {
  return handleResult(
    getAxios.get(`customer/transactions?customer_id=${payload.customer_id}`),
    payload
  );
};

// CUSTOMER GROUP LIST
export const requestCustomerGroupList = payload => {
  return handleResult(
    getAxios.get(
      `groupCustomer/list?query=${payload.search}` + `&page=${payload.page}`,
      payload
    )
  );
};

// CUSTOMER GROUP DETAIL
export const requestCustomerGroupDetail = payload => {
  return handleResult(getAxios.get(`groupCustomer/detail?id=${payload.id}`));
};
// CUSTOMER GROUP CREATE
export const requestCustomerGroupCreate = payload => {
  return handleResult(getAxios.post(`groupCustomer/create`, payload));
};
// CUSTOMER GROUP UPDATE
export const requestCustomerGroupUpdate = payload => {
  return handleResult(getAxios.post(`groupCustomer/update`, payload));
};
// CUSTOMER GROUP ADD CUSTOMER
export const requestCustomerGroupAddCustomers = payload => {
  return handleResult(getAxios.post(`groupCustomer/addCustomers`, payload));
};
// CUSTOMER GROUP REMOVE CUSTOMER
export const requestCustomerGroupRemoveCustomers = payload => {
  return handleResult(getAxios.post(`groupCustomer/deleteCustomers`, payload));
};

export const requestDeleteGroupCustomer = payload => {
  return handleResult(getAxios.post(`groupCustomer/delete`, payload));
};

// FILTER LIST
export const requestFilterList = payload => {
  return handleResult(
    getAxios.get(
      `customer/customerFilterNews?enterprise_id=${payload.enterprise_id}`
    )
  );
};

// LẤY DANH SÁCH REVIEW
export const requestReviewList = payload => {
  return handleResult(getAxios.get(`config/reviews`, payload));
};
// REVIEW CREATE
export const requestCreateReview = payload => {
  return handleResult(getAxios.post('config/createReview', { ...payload }));
};

// REVIEW UPDATE
export const requestUpdateReview = payload => {
  return handleResult(getAxios.post('config/updateReview', payload));
};

//  REVIEW DELETE
export const requestDeleteReview = payload => {
  return handleResult(getAxios.post('config/deleteReview', payload));
};

//danh sách tương tác gần nhất
export const requestFullCustomerAction = () => {
  return handleResult(getAxios.get(`customer/listCustomerAction`));
};

// Create Tag
export const requestCreateTag = payload => {
  return handleResult(getAxios.post('sale/createTag', payload));
};

// List Tag
export const requestListTag = payload => {
  return handleResult(
    getAxios.get(
      `sale/getTag?search=${payload?.search || ''}&page=${payload?.page || 1}`,
      payload
    )
  );
};
export const requestFullListTag = payload => {
  return handleResult(
    getAxios.get(
      `sale/getTag?search=${payload?.search || ''}&page=${
        payload?.page || 1
      }&limit=1000`,
      payload
    )
  );
};

//list sale
export const requestListSale = payload => {
  return handleResult(
    getAxios.get(
      `/sale/list?type=${payload.type}&page=${payload.page}`,
      payload
    )
  );
};

export const requestFullSaleList = payload => {
  return handleResult(
    getAxios.get(`/sale/list?limit=1000&type=${payload?.type || ''}`, payload)
  );
};

export const requestFullSaleListVer2 = payload => {
  return handleResult(
    getAxios.get(`/sale/listV2?limit=1000&type=${payload?.type || ''}`, payload)
  );
};

//list sale theo phòng ban
export const requestSalesByDepartment = ({ department_id }) => {
  return handleResult(
    getAxios.get(
      `/enterprise/telesaleDepartment?department_id=${department_id}`
    )
  );
};

// update Tag
export const requestUpdateTag = payload => {
  return handleResult(getAxios.post('sale/updateTag', payload));
};

// delete Tag
export const requestDeleteTag = payload => {
  return handleResult(getAxios.post('sale/deleteTag', payload));
};

//create KPI
export const requestCreateGroupKPI = payload => {
  return handleResult(getAxios.post('admin/createGroupKPI', payload));
};

//list KPI
export const requestListGroupKPI = payload => {
  return handleResult(
    getAxios.get(
      `admin/listKPI?result=${payload.result}&search=${
        payload.search
      }&fromDate=${payload.fromDate}&toDate=${payload.toDate}&page=${
        payload.page || 1
      }`,
      payload
    )
  );
};

// JOB Category LIST
export const requestJobCategoryList = (
  payload = {
    page: '1',
    search: '',
    job_category_id: '',
    status: '',
  }
) => {
  return handleResult(
    getAxios.get(
      `job/list?page=${payload.page}` +
        `&search=${payload.search}` +
        `&job_category_id=${payload.job_category_id}` +
        `&status=${payload.status}`,
      payload
    )
  );
};

// JOB LIST
export const requestJobList = (
  payload = {
    page: '1',
    search: '',
    job_category_id: '',
    status: '',
    job_status: '',
    fromDate: '',
    toDate: '',
    limit: '',
  }
) => {
  return handleResult(
    getAxios.get(
      `job/listCategories?page=${payload.page}` +
        `&search=${payload.search}` +
        `&job_category_id=${payload.job_category_id}` +
        `&status=${payload.status}` +
        `&enterprise_id=${payload.enterprise_id}` +
        `&job_status=${payload.job_status}` +
        `&from_date=${payload.fromDate}` +
        `&to_date=${payload.toDate}`,
      payload
    )
  );
};

export const requestGetAllJob = () => {
  return handleResult(getAxios.get('job/listJobs'));
};

// JOB CREATE
export const requestCreateJob = payload => {
  return handleResult(getAxios.post('job/create', { ...payload }));
};

// JOB CREATE
export const requestCreateScenario = payload => {
  return handleResult(getAxios.post('job/scenario', { ...payload }));
};

// JOB FORM CREATE
export const requestCreateForm = payload => {
  return handleResult(getAxios.post('job/form', payload));
};

// JOB FORM DELETE
export const requestDeleteForm = payload => {
  return handleResult(getAxios.post('job/deleteform', payload));
};

export const requestDeleteFormSetting = payload => {
  return handleResult(getAxios.post('config/deleteJobForm', payload));
};

// JOB FORM UPDATE
export const requestUpdateForm = payload => {
  return handleResult(getAxios.post('job/form', payload));
};

// JOB EXAM CREATE
export const requestCreateExam = payload => {
  return handleResult(getAxios.post('job/exam', payload));
};

// JOB EXAM MINIMUM POINT
export const requestSetMinPoint = payload => {
  return handleResult(getAxios.post('job/updateMinPointExam', payload));
};

// JOB EXAM REMOVE
export const requestDeleteExam = payload => {
  return handleResult(getAxios.post('job/deleteExam', payload));
};

// JOB TELESALE
export const requestAssignSale = payload => {
  return handleResult(getAxios.post('job/telesale', { ...payload }));
};

export const requestAssignTelemarketingWithRatio = payload => {
  return handleResult(getAxios.post('job/telesaleRatio', { ...payload }));
};

export const requestAddTelemarketingWhenAssignTelemarketingWithRatio =
  payload => {
    return handleResult(
      getAxios.post('job/updateTelesaleRatio', { ...payload })
    );
  };

// JOB TELESALE DETAIL
export const requestTelesaleDetail = (
  payload = {
    job_id: '',
    status: '',
  }
) => {
  // return handleResult(
  //   getAxios.get(
  //     `job/listSale?job_id=${payload.job_id}` + `&status=${payload.status}`
  //   )
  // );

  return handleResult(
    getAxios.get(`job/listSale`, { params: new URLSearchParams(payload) })
  );
};

// JOB UPDATE
export const requestUpdateJob = payload => {
  return handleResult(getAxios.post('job/update', payload));
};

// JOB DETAIL
export const requestJobDetail = payload => {
  return handleResult(getAxios.get(`job/detail?id=${payload.id}`, payload));
};

//toi uu JobDetail = job/detailsSimple ở màn không dùng job_exam_questions
export const requestJobDetailSimple = payload => {
  return handleResult(
    getAxios.get(`job/detailSimple?id=${payload.id}`, payload)
  );
};
// JOB REQUIREMENT CREATE
export const requestCreateRequirement = payload => {
  return handleResult(getAxios.post('job/config', { ...payload }));
};

// JOB TELESALE GET
export const requestGetTelesale = payload => {
  return handleResult(getAxios.get('job/telesale', { ...payload }));
};

// JOB TELESALE ROLLBACK
export const requestRollBackTelesale = payload => {
  return handleResult(getAxios.post('job/roleBack', { ...payload }));
};

export const requestRollBackWithRatio = payload => {
  return handleResult(getAxios.post('job/roleBackRatio', { ...payload }));
};

// ASIGNMENT JOB
export const requestCreateJobExpress = ({ params, payload }) => {
  // return handleResult(getAxios.post('job/createJobFast', payload));
  return handleResult(
    getAxios.post('job/createJobFast', payload, {
      params: new URLSearchParams({
        ...params,
        query: params?.search,
      }),
    })
  );
};

//LIST SUB JOB
export const requestListSubJob = payload => {
  return handleResult(
    getAxios.get(
      `job/listSubJob?search=${payload.search}` +
        `&list_customer_id=${payload.list_customer_id}`,
      payload
    )
  );
};

//ROLE BACK JOB
export const requestRoleBackJob = ({ params, payload }) => {
  // return handleResult(getAxios.post('job/roleBackJobs', payload));

  return handleResult(
    getAxios.post('job/roleBackJobs', payload, {
      params: new URLSearchParams({
        ...params,
        query: params?.search,
      }),
    })
  );
};

// CREATE EDUCATION
export const requestCreateEducation = payload => {
  return handleResult(
    getAxios.post('job/education', payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  );
};

// CREATE HOTLINE IN JOB
export const requestCreateHotlineJob = payload => {
  return handleResult(getAxios.post('job/hotline', { ...payload }));
};

// GET CUSTOMER JOB
export const requestCustomerJobList = (
  payload = {
    job_id: '',
    group_id: '',
    page: '1',
    status: '',
    fromDate: '',
    toDate: '',
  }
) => {
  return handleResult(
    getAxios.get(`job/customer`, {
      params: new URLSearchParams(payload),
      payload,
    })
  );
};

// GET CUSTOMERGROUP JOB
export const requestGroupCustomerFilterList = payload => {
  return handleResult(getAxios.get(`groupCustomer/listFilter`, payload));
};

export const requestGetCustomerGroups = payload => {
  return handleResult(
    getAxios.get(`groupCustomer/listFilterLimit?search=${payload.search}`)
  );
};

export const requestFullCustomerGroupList = payload => {
  return handleResult(
    getAxios.get(`groupCustomer/listFilter?limit=1000`, payload)
  );
};

// CREATE CUSTOMER IN JOB
export const requestCreateCustomerJob = payload => {
  return handleResult(getAxios.post('job/groupCustomer', { ...payload }));
};

// GET CUSTOMERGROUP JOB
export const requestJobCustomerStatus = payload => {
  return handleResult(getAxios.get(`util/jobCustomerStatus`, payload));
};

// CALL HISTORY
export const requestCallList = (
  payload = {
    page: 1,
    call_type: '',
    job_parent_id: '',
    job_id: '',
    call_status: '',
    telesale_id: '',
    start_call_time: '',
    limit: '',
    is_late: '',
    fromDate: '',
    toDate: '',
    job_category_id: '',
    job_form_question_id: '',
    job_form_answers_id: '',
    call_review_config_id: '',
    keyword: '',
    search: '',
    task_id: '',
    tag_ids: [],
    department_id: [],
    call_reviewed: '',
    call_reviewed_created_by: [],
  }
) => {
  if (!payload.call_type) payload.call_type = '';
  if (!payload.job_parent_id) payload.job_parent_id = '';
  if (!payload.job_id) payload.job_id = '';
  if (!payload.call_status) payload.call_status = '';
  if (!payload.telesale_id) payload.telesale_id = '';
  if (!payload.start_call_time) payload.start_call_time = '';
  if (!payload.limit) payload.limit = '';
  if (!payload.is_late) payload.is_late = '';
  if (!payload.fromDate) payload.fromDate = '';
  if (!payload.toDate) payload.toDate = '';
  if (!payload.job_category_id) payload.job_category_id = '';
  if (!payload.job_form_question_id) payload.job_form_question_id = '';
  if (!payload.job_form_answers_id) payload.job_form_answers_id = '';
  if (!payload.call_review_config_id) payload.call_review_config_id = '';
  if (!payload.keyword) payload.keyword = '';
  if (!payload.search) payload.search = '';
  if (!payload.task_id) payload.task_id = '';
  if (!payload.tag_ids) payload.tag_ids = [];
  if (!payload.department_id) payload.department_id = [];
  if (!payload.getListReviewer) payload.getListReviewer = [];
  if (!payload.call_reviewed) payload.call_reviewed = '';
  if (!payload.call_reviewed_created_by) payload.call_reviewed_created_by = [];

  return handleResult(
    getAxios.get(
      `call/list?call_type=${payload.call_type}` +
        `&job_parent_id=${payload.job_parent_id}` +
        `&job_id=${payload.job_id}` +
        `&page=${payload.page}` +
        `&call_status=${payload.call_status}` +
        `&telesale_id=${payload.telesale_id}` +
        `&start_call_time=${payload.start_call_time}` +
        `&is_late=${payload.is_late}` +
        `&fromDate=${payload.fromDate}` +
        `&toDate=${payload.toDate}` +
        `&job_category_id=${payload.job_category_id}` +
        `&job_form_question_id=${payload.job_form_question_id}` +
        `&job_form_answers_id=${payload.job_form_answers_id}` +
        `&call_review_config_id=${payload.call_review_config_id}` +
        `&keyword=${payload.keyword}` +
        `&search=${payload.search}` +
        `&limit=${payload.limit}` +
        `&is_check_kpi=${payload.is_check_kpi || 0}` +
        `&task_id=${payload.task_id}` +
        `&tag_ids=${payload.tag_ids}` +
        `&department_id=${payload.department_id}` +
        `&call_reviewed=${payload.call_reviewed}` +
        `&call_reviewed_created_by=${payload.call_reviewed_created_by}`,
      payload
    )
  );
};

export const requestCallListVer2 = (
  payload = {
    page: 1,
    call_type: '',
    job_id: '',
    call_status: '',
    telesale_id: '',
    start_call_time: '',
    limit: '',
    is_late: '',
    fromDate: '',
    toDate: '',
    job_category_id: '',
    job_form_question_id: '',
    job_form_answers_id: '',
    call_review_config_id: '',
    keyword: '',
    search: '',
    task_id: '',
    tag_ids: [],
    department_id: [],
    call_reviewed: '',
    call_reviewed_created_by: [],
  }
) => {
  if (!payload.call_type) payload.call_type = '';
  if (!payload.job_id) payload.job_id = '';
  if (!payload.call_status) payload.call_status = '';
  if (!payload.telesale_id) payload.telesale_id = '';
  if (!payload.start_call_time) payload.start_call_time = '';
  if (!payload.limit) payload.limit = '';
  if (!payload.is_late) payload.is_late = '';
  if (!payload.fromDate) payload.fromDate = '';
  if (!payload.toDate) payload.toDate = '';
  if (!payload.job_category_id) payload.job_category_id = '';
  if (!payload.job_form_question_id) payload.job_form_question_id = '';
  if (!payload.job_form_answers_id) payload.job_form_answers_id = '';
  if (!payload.call_review_config_id) payload.call_review_config_id = '';
  if (!payload.keyword) payload.keyword = '';
  if (!payload.search) payload.search = '';
  if (!payload.task_id) payload.task_id = '';
  if (!payload.tag_ids) payload.tag_ids = [];
  if (!payload.department_id) payload.department_id = [];
  if (!payload.getListReviewer) payload.getListReviewer = [];
  if (!payload.call_reviewed) payload.call_reviewed = '';
  if (!payload.call_reviewed_created_by) payload.call_reviewed_created_by = [];

  return handleResult(
    getAxios.get(
      `call/listCallJobs?call_type=${payload.call_type}` +
        `&job_id=${payload.job_id}` +
        `&page=${payload.page}` +
        `&call_status=${payload.call_status}` +
        `&telesale_id=${payload.telesale_id}` +
        `&start_call_time=${payload.start_call_time}` +
        `&is_late=${payload.is_late}` +
        `&fromDate=${payload.fromDate}` +
        `&toDate=${payload.toDate}` +
        `&job_category_id=${payload.job_category_id}` +
        `&job_form_question_id=${payload.job_form_question_id}` +
        `&job_form_answers_id=${payload.job_form_answers_id}` +
        `&call_review_config_id=${payload.call_review_config_id}` +
        `&keyword=${payload.keyword}` +
        `&search=${payload.search}` +
        `&limit=${payload.limit}` +
        `&is_check_kpi=${payload.is_check_kpi || 0}` +
        `&task_id=${payload.task_id}` +
        `&tag_ids=${payload.tag_ids}` +
        `&department_id=${payload.department_id}` +
        `&call_reviewed=${payload.call_reviewed}` +
        `&call_reviewed_created_by=${payload.call_reviewed_created_by}`,
      payload
    )
  );
};

export const getAmountOfCall = payload => {
  return handleResult(
    // getAxios.get(
    //   `call/export?call_type=${payload.call_type}` +
    //     `&job_id=${payload.job_id}` +
    //     `&page=${payload.page}` +
    //     `&call_status=${payload.call_status}` +
    //     `&telesale_id=${payload.telesale_id}` +
    //     `&start_call_time=${payload.start_call_time}` +
    //     `&is_late=${payload.is_late}` +
    //     `&fromDate=${payload.fromDate}` +
    //     `&toDate=${payload.toDate}` +
    //     `&job_category_id=${payload.job_category_id}` +
    //     `&job_form_question_id=${payload.job_form_question_id}` +
    //     `&job_form_answers_id=${payload.job_form_answers_id}` +
    //     `&call_review_config_id=${payload.call_review_config_id}` +
    //     `&keyword=${payload.keyword}` +
    //     `&search=${payload.search}` +
    //     `&limit=${payload.limit}` +
    //     `&is_check_kpi=${payload.is_check_kpi || 0}` +
    //     `&task_id=${payload.task_id}` +
    //     `&tag_ids=${payload.tag_ids}` +
    //     `&department_id=${payload.department_id}` +
    //     `&call_reviewed=${payload.call_reviewed}` +
    //     `&call_reviewed_created_by=${payload.call_reviewed_created_by}`,
    //   payload
    // )

    getAxios.get(`call/export`, {
      params: new URLSearchParams({
        ...payload,
        is_check_kpi: payload.is_check_kpi || 0,
      }),
    })
  );
};

export const requestCallHistoryList = (
  payload = {
    page: 1,
    job_id: '',
    call_status: '',
    telesale_id: '',
  }
) => {
  if (!payload.job_id) payload.job_id = '';
  if (!payload.call_status) payload.call_status = '';
  if (!payload.telesale_id) payload.telesale_id = '';

  return handleResult(
    getAxios.get(
      `reviewer/history?job_id=${payload.job_id}` +
        `&call_status=${payload.call_status}` +
        `&telesale_id=${payload.telesale_id}`,
      payload
    )
  );
};

// CALL HISTORY DETAIL
export const requestCallHistoryDetail = payload => {
  return handleResult(
    getAxios.get(`call/detail?call_id=${payload.id}`, payload)
  );
};

export const requestCallHistoryDetailCalling = payload => {
  return handleResult(
    getAxios.get(
      `call/detail?call_id=${payload.id}&response_type=simple`,
      payload
    )
  );
};

export const getListCallHistory = payload => {
  return handleResult(getAxios.get(`call/history`, { params: payload }));
};

export const updateValidateJob = payload => {
  return handleResult(getAxios.post('job/updateValidate', { ...payload }));
};

export const requestAddNote = payload => {
  return handleResult(getAxios.post('opportunity/createNote', { ...payload }));
};

export const getListCall = payload => {
  return handleResult(getAxios.get(`call/list`, { params: payload }));
};

export const getListOpportunityList = payload => {
  return handleResult(
    getAxios.get(
      `opportunity/list?customer_id=${payload.id}&telesale_id=&job_id=&status=&opportynity_code=${payload.type}&fromDate=${payload.fromDate}&page=${payload.page}&toDate=${payload.toDate}`
    )
  );
};

//Call review
export const getListQuestionForReview = id => {
  return handleResult(
    getAxios.get(`call/getCallReviewConfig?call_id=${id}`, {})
  );
};

export const updateCallReview = payload => {
  return handleResult(getAxios.post(`call/reviewCall`, { ...payload }));
};

export const requestListReviewer = () => {
  return handleResult(getAxios.get(`filter/reviewer`));
};

// show/hide customer phone
export const requestGetCustomerPhoneVisible = payload => {
  return handleResult(getAxios.get(`config/isShow`), { params: payload });
};

export const requestChangeCustomerPhoneVisible = payload => {
  return handleResult(getAxios.post(`config/isShow`, payload));
};

// show/hide customer tag
export const requestGetCustomerTag = payload => {
  return handleResult(getAxios.get(`config/isShowTag`), { params: payload });
};

export const requestChangeCustomerTag = payload => {
  return handleResult(getAxios.post(`config/isShowTag`, payload));
};

// GET TYPE JOB
export const requestTypeJobList = (
  payload = {
    query: '',
    page: '1',
  }
) => {
  return handleResult(
    getAxios.get(
      `jobtype/list?query=${payload.query}` + `&page=${payload.page}`,
      payload
    )
  );
};

// TYPE JOB CREATE
export const requestCreateTypeJob = payload => {
  return handleResult(getAxios.post('jobtype/create', { ...payload }));
};

// TYPE JOB UPDATE
export const requestUpdateTypeJob = payload => {
  return handleResult(getAxios.post('jobtype/update', payload));
};

//  TYPE JOB DELETE
export const requestDeleteTypeJob = payload => {
  return handleResult(getAxios.post('jobtype/delete', payload));
};

//GET LIST TUTORIAL
export const getListTutorial = payload => {
  return handleResult(
    getAxios.get(
      `tutorial/list?SEARCH=${payload.SEARCH}&FROM_DATE=${payload.FROM_DATE}&TO_DATE=${payload.TO_DATE}&PAGE=${payload.PAGE}&status=${payload.status}`
    ),
    payload
  );
};

// Customer attribute
export const requestCreateCustomerAttribute = payload => {
  return handleResult(getAxios.post(`customer/createAttr`, payload));
};

export const requestGetAttributeList = payload => {
  return handleResult(
    getAxios.get(
      `customer/customerFieldImport?is_config=${payload.is_config}&page=${payload.page}&limit=${payload.limit}&search=${payload.search}&is_active=${payload.is_active}`,
      payload
    )
  );
};

export const requestUpdateCustomerAttribute = payload => {
  return handleResult(
    getAxios.post(`customer/updatePropertyCustomer`, payload)
  );
};

export const requestDeleteCustomerAttribute = payload => {
  return handleResult(
    getAxios.post(`customer/deletePropertyCustomer`, payload)
  );
};

export const requestGetAttributeDetail = payload => {
  return handleResult(
    getAxios.get(`customer/getPropertyCustomerDetail?id=${payload.id}`, payload)
  );
};

export const requestRemoveCustomerAttribute = payload => {
  return handleResult(getAxios.post(`customer/deleteDataCustomer`, payload));
};

// KPI notification
export const requestGetKpiNotifications = payload => {
  return handleResult(
    getAxios.get(`kpi/getConfig?page=${payload.page}`, payload)
  );
};

export const requestCreateKpiNotification = payload => {
  return handleResult(getAxios.post(`kpi/createConfig`, payload));
};

export const requestUpdateKpiNotification = payload => {
  return handleResult(getAxios.post(`kpi/updateConfig`, payload));
};

export const requestDeleteKpiNotification = payload => {
  return handleResult(getAxios.post(`kpi/deleteConfig`, payload));
};

export const requestGetMessageOption = payload => {
  return handleResult(getAxios.get(`filter/messageOption`, payload));
};

export const deleteTutorial = id => {
  return handleResult(getAxios.post(`tutorial/delete`, id));
};

export const createTutorial = payload => {
  return handleResult(getAxios.post(`tutorial/create`, payload));
};

export const updateTutorial = payload => {
  return handleResult(getAxios.post(`tutorial/update`, payload));
};

export const getTutorialDetail = id => {
  return handleResult(getAxios.get(`tutorial/detail?ID=${id}`));
};

// CALL REVIEW CREATE
export const requestCreateCallReview = payload => {
  return handleResult(getAxios.post(`call/reviewCall`, { ...payload }));
};

// GET CALL STATUS
export const requestGetCallStatus = payload => {
  return handleResult(getAxios.get('util/callStatus', { ...payload }));
};

// GET LIST REVIEWER

export const requestGetReviewerList = (
  payload = {
    search: '',
    page: '1',
    status: '',
    province_id: '',
  }
) => {
  return handleResult(
    getAxios.get(
      `reviewer/list?search=${payload.search}` +
        `&page=${payload.page}` +
        `&province_id=${payload.province_id}` +
        `&status=${payload.status}`,
      payload
    )
  );
};

// GET REVIEWER STATUS
export const requestGetReviewerStatus = payload => {
  return handleResult(getAxios.get('reviewer/status', { ...payload }));
};

export const requestGetReviewerStatistic = payload => {
  return handleResult(getAxios.get('reviewer/statistic', { ...payload }));
};

export const requestGetStatistic = payload => {
  return handleResult(getAxios.get('reviewer/reviewStatistic', { ...payload }));
};

export const requestGetReviewerChart = payload => {
  return handleResult(
    getAxios.get(
      `reviewer/chart?fromDate=${payload.fromDate}&toDate=${payload.toDate}`,
      { ...payload }
    )
  );
};

// GET REVIEWER DETAIL
export const requestGetReviewerDetail = payload => {
  return handleResult(
    getAxios.get(`reviewer/detail?id=${payload.id}`, { ...payload })
  );
};

// GET REVIEWER TASK
export const requestGetReviewerTasks = payload => {
  return handleResult(
    getAxios.get(`reviewer/tasks?reviewer_id=${payload.id}`, { ...payload })
  );
};

// KEYWORD
export const getListKeyword = (
  payload = {
    search: '',
    page: '1',
    enterprise_id: '',
  }
) => {
  return handleResult(
    getAxios.get(
      `blockkeyword/list?search=${payload.search}&page=${payload.page}&enterprise_id=${payload.enterprise_id}`,
      payload
    )
  );
};

export const updateKeyWord = payload => {
  return handleResult(getAxios.post(`blockkeyword/update`, payload));
};

export const changePackage = payload => {
  return handleResult(getAxios.post(`enterprise/changePackage`, payload));
};

export const createKeyWord = payload => {
  return handleResult(getAxios.post(`blockkeyword/create`, payload));
};

export const deleteKeyWord = payload => {
  return handleResult(getAxios.post(`blockkeyword/delete`, payload));
};

//CHECKIN

export const getListCheckin = (
  payload = {
    search: '',
    page: 1,
  }
) => {
  return handleResult(
    getAxios.get(
      `timeKeeping/listCategory?search=${payload.search}&page=${payload.page}`,
      payload
    )
  );
};

export const updateCheckin = payload => {
  return handleResult(getAxios.post(`timeKeeping/updateCategory`, payload));
};

export const createCheckin = payload => {
  return handleResult(getAxios.post(`timeKeeping/createCategory`, payload));
};

export const deleteCheckin = payload => {
  return handleResult(getAxios.post(`timeKeeping/deleteCategory`, payload));
};

// VPOINT
export const getListVpoint = payload => {
  return handleResult(
    getAxios.get(
      `opportunity/configs?search=${payload.search}&page=${payload.page}`,
      payload
    )
  );
};

export const createVpoint = payload => {
  return handleResult(getAxios.post(`opportunity/createConfig`, payload));
};

export const updateVpoint = payload => {
  return handleResult(getAxios.post(`opportunity/updateConfig`, payload));
};

export const deleteVpoint = payload => {
  return handleResult(getAxios.post(`opportunity/deleteConfig`, payload));
};

export const requestGeneralQuestion = payload => {
  return handleResult(getAxios.get(`config/sharedform`, payload));
};

// JOB GENERAl CREATE
export const requestCreateGeneralQuestion = payload => {
  return handleResult(getAxios.post('config/sharedform', payload));
};

export const requestCallReviewConfig = payload => {
  return handleResult(
    getAxios.get(`call/getCallReviewConfig?call_id=${payload.call_id}`, payload)
  );
};

export const requestGetListCall = (
  payload = {
    job_category_id: '',
    job_id: '',
    telesale_id: '',
    fromdate: '',
    todate: '',
    call_status: '',
    page: 1,
    job_form_question_id: '',
    job_form_answers_id: '',
    keyword: '',
    enterprise_id: '',
  }
) => {
  return handleResult(
    getAxios.get(
      `reviewer/listCall?job_category_id=${payload.job_category_id}` +
        `&job_id=${payload.job_id}` +
        `&telesale_id=${payload.telesale_id}` +
        `&fromdate=${payload.fromdate}` +
        `&todate=${payload.todate}` +
        `&call_status=${payload.call_status}` +
        `&job_form_question_id=${payload.job_form_question_id}` +
        `&job_form_answers_id=${payload.job_form_answers_id}` +
        `&keyword=${payload.keyword}` +
        `&enterprise_id=${payload.enterprise_id}` +
        `&page=${payload.page}`,
      { ...payload }
    )
  );
};

export const getJobChart = payload => {
  return handleResult(
    getAxios.get(
      `user/callChart?fromDate=${payload.fromDate}&toDate=${payload.toDate}&job_id=${payload.job_id}&telesale_id=${payload.telesale_id}`,
      payload
    )
  );
};

// OCCASION (Cơ hội)
export const getListOccasion = payload => {
  return handleResult(
    getAxios.get(
      `opportunity/list?telesale_id=${payload.telesale_id}&job_id=${payload.job_id}&status=${payload.status}&opportunity_category_id=${payload.opportunity_category_id}&fromDate=${payload.fromDate}&page=${payload.page}`,
      payload
    )
  );
};

export const getOccasionDetail = (id, page) => {
  return handleResult(getAxios.get(`opportunity/detail?id=${id}&page=${page}`));
};

export const updateOccasion = payload => {
  return handleResult(getAxios.post(`opportunity/update`, payload));
};

export const getListCategory = () => {
  return handleResult(getAxios.get(`opportunity/categories?auto_convert`));
};

// SUBJOB CREATE
export const requestCreateSubJob = payload => {
  return handleResult(getAxios.post('job/createSubJob', { ...payload }));
};

//new
export const createSubJob = payload => {
  return handleResult(getAxios.post('job/createSubJobs', { ...payload }));
};

// SUBJOB CREATE
export const requestUpdateSubJob = payload => {
  return handleResult(getAxios.post('job/updateSubJob', { ...payload }));
};

//new
export const updateSubJob = payload => {
  return handleResult(getAxios.post('job/updateSubJobs', { ...payload }));
};

// ASSIGN REVIWER
export const requestAssignReviewer = payload => {
  return handleResult(getAxios.post('reviewer/assign', { ...payload }));
};

// RESULT REVIWER
export const requestResultReviewer = (
  payload = {
    limit: '',
    telesale_id: '',
    job_category_id: '',
    job_id: '',
    call_status: '',
    status: '',
    page: 1,
  }
) => {
  return handleResult(
    getAxios.get(
      `reviewer/results?job_category_id=${payload.job_category_id}` +
        `&job_id=${payload.job_id}` +
        `&telesale_id=${payload.telesale_id}` +
        `&status=${payload.status}` +
        `&call_status=${payload.call_status}` +
        `&page=${payload.page}` +
        `&limit=${payload.limit}`,
      { ...payload }
    )
  );
};

//FILTER

export const requestJobCategoriesFilter = (
  payload = {
    enterprise_id: '',
    telesale_id: '',
    limit: -1,
  }
) => {
  return handleResult(
    getAxios.get(
      `filter/jobCategories?enterprise_id=${payload.enterprise_id}` +
        `&telesale_id=${payload.telesale_id}`,
      { ...payload }
    )
  );
};

export const requestJobFilter = (
  payload = {
    enterprise_id: '',
    telesale_id: '',
    job_category_id: '',
    limit: '',
  }
) => {
  return handleResult(
    getAxios.get(
      `filter/jobs?enterprise_id=${payload.enterprise_id}` +
        `&telesale_id=${payload.telesale_id}` +
        `&limit=${payload.limit}` +
        `&job_category_id=${payload.job_category_id}`,
      { ...payload }
    )
  );
};

export const requestFullJobFilter = payload => {
  return handleResult(getAxios.get(`filter/jobs?limit=1000`));
};

export const requestJobParentFilter = (
  payload = {
    // enterprise_id: "",
    // telesale_id: "",
    // job_category_id: "",
    // limit: "",
    jobCategories: '',
    enterprise_id: '',
    telesale_id: '',
    search: '',
  }
) => {
  return handleResult(
    getAxios.get(
      `filter/jobCategories?enterprise_id=${payload.enterprise_id}` +
        `&telesale_id=${payload.telesale_id}` +
        `&search=${payload.search}`,
      { ...payload }
    )
  );
};

// export const requestEnterprisesFilter = (
//   payload = {
//     enterprise_id: "",
//     telesale_id: "",
//     job_category_id: "",
//   }
// ) => {
//   return handleResult(
//     getAxios.get(
//       `filter/enterprises?enterprise_id=${payload.enterprise_id}` +
//         `&telesale_id=${payload.telesale_id}``&job_category_id=${payload.job_category_id}`,
//       { ...payload }
//     )
//   );
// };

export const requestEnterprisesFilter = payload => {
  return handleResult(
    getAxios.get(
      `filter/enterprises?limit=${payload.limit}&search=${payload.search}`,
      { ...payload }
    )
  );
};
// export const requestJobFilter = (payload) => {
//   return handleResult(getAxios.get("filter/jobs", { ...payload }));
// };
export const requestTagKpi = (
  payload = {
    enterprise_id: '',
  }
) => {
  return handleResult(
    getAxios.get(`sale/getTag?page=1&limit=1000`, {
      ...payload,
    })
  );
};
export const requestProductKpi = (
  payload = {
    enterprise_id: '',
  }
) => {
  return handleResult(
    getAxios.get(`opportunity/configs-v2?product_id=''&limit=1000`, {
      ...payload,
    })
  );
};
export const requestTelesalesFilter = (
  payload = {
    enterprise_id: '',
  }
) => {
  return handleResult(
    getAxios.get(`filter/telesalesKPI`, {
      ...payload,
    })
  );
};
export const requestFullTelesalesFilter = (
  payload = {
    enterprise_id: '',
    limit: 1000,
  }
) => {
  return handleResult(
    getAxios.get(
      `filter/telesales?enterprise_id=${payload.enterprise_id}&limit=${payload.limit}`,
      {
        ...payload,
      }
    )
  );
};

export const requestTelesalesFilterByDepartment = payload => {
  return handleResult(
    getAxios.get(
      `enterprise/telesaleDepartment?department_id=${payload.department_id}`,
      {
        ...payload,
      }
    )
  );
};

export const requestQuessionsAndAnswerFilter = (
  payload = {
    enterprise_id: '',
    job_category_id: '',
  }
) => {
  return handleResult(
    getAxios.get(
      `filter/quessionsAndAnswer?enterprise_id=${payload.enterprise_id}` +
        `&job_category_id=${payload.job_category_id}`,
      {
        ...payload,
      }
    )
  );
};

export const requestQuestionsAndAnswerReport = (
  payload = {
    enterprise_id: '',
    job_category_id: '',
  }
) => {
  return handleResult(
    getAxios.get(
      `filter/quessionsAndAnswerReport?enterprise_id=${payload.enterprise_id}` +
        `&job_category_id=${payload.job_category_id}&page=1&limit=100`,
      {
        ...payload,
      }
    )
  );
};

//Anh Nam code nghiep vu lay ra tat ca Leader
export const requestLeaderList = (
  payload = {
    department_id: '',
    page: 1,
    limit: 12,
  }
) => {
  return handleResult(
    getAxios.get(
      `filter/getListLeader?department_id=${payload.department_id}&page=${payload.page}&limit=${payload.limit}`,
      {
        ...payload,
      }
    )
  );
};

export const requestGetListForSale = payload => {
  return handleResult(
    getAxios.get(
      `opportunity/listForSale?telesale_id=${payload.telesale_id}` +
        `&fromDate=${payload.fromDate}` +
        `&toDate=${payload.toDate}` +
        `&limit=${payload.limit}` +
        `&page=${payload.page}`,
      { ...payload }
    )
  );
};

export const requestGetListPackage = (
  payload = {
    search: '',
    page: 1,
    level: '',
    type: '',
  }
) => {
  return handleResult(
    getAxios.get(
      `admin/getListPackage?search=${payload.search}` +
        `&level=${payload.level}` +
        `&page=${payload.page}` +
        `&type=${payload.type}`,
      { ...payload }
    )
  );
};

export const requestUpdateAudioScenario = payload => {
  return handleResult(
    getAxios.post('enterprise/createOrUpdateScenario', payload)
  );
};

export const requestGetAudioScenario = payload => {
  return handleResult(getAxios.get('enterprise/getScenario', payload));
};

export const requestFormInsertSchemaCustomer = (
  payload = {
    enterprise_id: '',
  }
) => {
  return handleResult(
    getAxios.get(
      `customer/formInsertSchema?enterprise_id=${payload.enterprise_id}`,
      { ...payload }
    )
  );
};

export function toQuerystringFromObject(obj) {
  return Object.keys(obj)
    .filter(v => v)
    .map(v => {
      const value = obj[v];
      if (Array.isArray(value)) {
        return `${v}=${value.join(',')}`;
      } else {
        return `${v}=${value}`;
      }
    })
    .join('&');
}

export const requestGetTelesaleKpi = (
  payload = {
    telesale_id: '',
  }
) => {
  return handleResult(
    getAxios.get(`admin/listKPI?&telesale_id=${payload.telesale_id}`, {
      ...payload,
    })
  );
};

export const requestCreateKpi = payload => {
  return handleResult(getAxios.post('admin/createkpi', { ...payload }));
};
export const requestGetLog = payload => {
  return handleResult(
    getAxios.get(
      `admin/getLog?search=${payload.search || ''}&officeName=${
        payload.officeName || ''
      }&page=${payload.page || 1}&fromDate=${payload.fromDate || ''}&toDate=${
        payload.toDate || ''
      }&codeError=${payload.codeError || ''}`,
      { ...payload }
    )
  );
};

export const requestUpdateKpi = payload => {
  return handleResult(getAxios.post('admin/updatekpi', { ...payload }));
};

export const requestGetUserStatistic = payload => {
  return handleResult(getAxios.get(`user/statistic`, { ...payload }));
};

//TONG QUAN ADMIN
export const requestGetGeneralStatistic = payload => {
  return handleResult(
    getAxios.get(
      `user/userStatistic?fromDate=${payload.fromDate}&toDate=${payload.toDate}`,
      { ...payload }
    )
  );
};

export const requestGetsaleChart = payload => {
  return handleResult(
    getAxios.get(
      `user/getsaleChart?fromDate=${payload.fromDate}&toDate=${payload.toDate}`,
      { ...payload }
    )
  );
};

export const requestGetEnterpriseOppotunity = payload => {
  return handleResult(
    getAxios.get(
      `enterprise/oppotunityChart?fromDate=${payload.fromDate}&toDate=${payload.toDate}`,
      { ...payload }
    )
  );
};

export const requestGetEnterpriseChart = payload => {
  return handleResult(
    getAxios.get(
      `user/getEnterpriseChart?fromDate=${payload.fromDate}&toDate=${payload.toDate}`,
      { ...payload }
    )
  );
};

export const requestGetPackageStatistics = payload => {
  return handleResult(
    getAxios.get(
      `enterprise/getPackageStatistics?fromDate=${payload.fromDate}&toDate=${payload.toDate}`,
      {
        ...payload,
      }
    )
  );
};

//TONG QUAN ENTERPRISES
export const requestGetCallStatistic = payload => {
  return handleResult(
    getAxios.get(
      `user/callStatusChart?fromDate=${payload.fromDate}&toDate=${payload.toDate}`,
      { ...payload }
    )
  );
};

export const requestGetCustomerStatistic = payload => {
  return handleResult(
    getAxios.get(
      `user/customerStatistic?fromDate=${payload.fromDate}&toDate=${payload.toDate}`,
      { ...payload }
    )
  );
};

export const requestGetOpportunityStatusChart = payload => {
  return handleResult(
    getAxios.get(
      `user/opportunityStatusChart?fromDate=${payload.fromDate}&toDate=${payload.toDate}`,
      { ...payload }
    )
  );
};

export const requestCallStatistic = (
  payload = {
    fromDate: '',
    toDate: '',
  }
) => {
  return handleResult(
    getAxios.get(
      `user/callStatistic?&fromDate=${payload.fromDate}` +
        `&toDate=${payload.toDate}`,
      { ...payload }
    )
  );
};

export const requestJobStatistic = (
  payload = {
    fromDate: '',
    toDate: '',
    job_id: '',
  }
) => {
  return handleResult(
    getAxios.get(
      `job/statistic?&fromDate=${payload.fromDate}` +
        `&toDate=${payload.toDate}` +
        `&job_id=${payload.job_id}`,
      { ...payload }
    )
  );
};

export const requestJobSaleStatistic = (
  payload = {
    telesale_id: '',
  }
) => {
  return handleResult(
    getAxios.get(
      `job/jobSaleSatistic?&telesale_id=${payload.telesale_id}&page=${
        payload.page || 1
      }`,
      { ...payload }
    )
  );
};

export const requestPostConfigKpi = payload => {
  return handleResult(getAxios.post('config/kpi', { ...payload }));
};

export const requestChangeTelesale = payload => {
  return handleResult(getAxios.post('job/changeTelesale', { ...payload }));
};

export const requestChangeTelesaleWithRatio = payload => {
  return handleResult(getAxios.post('job/changeTelesaleRatio', { ...payload }));
};

export const requestGetConfigKpi = payload => {
  return handleResult(getAxios.get('config/kpi', { ...payload }));
};

// Xoa job
export const requestDeleteJob = payload => {
  return handleResult(getAxios.post('job/delete', { ...payload }));
};

// Xoa khach hang
export const requestDeleteCustomer = payload => {
  return handleResult(getAxios.post('customer/delete', { ...payload }));
};

export const requestDeleteMultiCustomer = payload => {
  return handleResult(getAxios.post('customer/deleteMultiple', { ...payload }));
};

// JOB TELESALE DETAIL
export const requestCustomerCollection = (
  payload = {
    customer_id: '',
  }
) => {
  return handleResult(
    getAxios.get(
      `customer/customerCollection?customer_id=${payload.customer_id}`
    )
  );
};

export const requestSaleOpportunity = payload => {
  return handleResult(
    getAxios.get(
      `sale/saleOpportunity?telesale_id=${payload.telesale_id}&fromDate=${payload.fromDate}&toDate=${payload.toDate}`,
      payload
    )
  );
};

// Package CREATE
export const requestCreatePackage = payload => {
  return handleResult(getAxios.post('admin/packageService', { ...payload }));
};

//Package Delete
export const requestDeletePackage = payload => {
  return handleResult(getAxios.post('admin/deletePackage', { ...payload }));
};
// Package History
export const requestPackageHistory = (
  payload = {
    enterprise_id: '',
  }
) => {
  if (!payload.enterprise_id) payload.enterprise_id = '';

  return handleResult(
    getAxios.get(
      `enterprise/addPackageHistory?enterprise_id=${payload.enterprise_id}`
    )
  );
};
//Package ADD
export const requestAddCallDurations = payload => {
  return handleResult(
    getAxios.post('enterprise/addCallDurations', { ...payload })
  );
};

//DEPARTMENT
export const requestGetListDepartment = payload => {
  return handleResult(
    getAxios.get(
      `enterprise/departments?search=${payload.search}&page=${payload.page}`,
      payload
    )
  );
};

//DEPARTMENT
export const requestDeleteDepartment = payload => {
  return handleResult(getAxios.post(`enterprise/deleteDepartments`, payload));
};

export const requestGetDepartmentDetail = payload => {
  return handleResult(
    getAxios.get(
      `enterprise/departmentDetail?department_id=${payload.department_id}`,
      payload
    )
  );
};

export const requestCreateDepartment = payload => {
  return handleResult(getAxios.post('enterprise/createDepartment', payload));
};

export const requestUpdateDepartment = payload => {
  return handleResult(getAxios.post('enterprise/updateDepartment', payload));
};

export const requestJobDepartment = payload => {
  return handleResult(
    getAxios.get(
      `enterprise/jobDepartment?department_id=${payload.department_id}&page=${payload.page}`,
      payload
    )
  );
};

export const requestTelesaleDepartment = payload => {
  return handleResult(
    getAxios.get(
      `enterprise/telesaleDepartment?department_id=${payload.department_id}`,
      payload
    )
  );
};

export const requestGetAppPartner = payload => {
  return handleResult(getAxios.get(`admin/getAppPartner`, payload));
};

export const requestUpdateAppPartner = payload => {
  return handleResult(getAxios.post(`admin/updateAppPartner`, payload));
};

export const requestLeaderDepartment = payload => {
  return handleResult(
    getAxios.get(
      `enterprise/getLeaderDepartment?department_id=${payload.department_id}&page=${payload.page}`,
      payload
    )
  );
};

export const requestDeleteLeaderDepartment = payload => {
  return handleResult(
    getAxios.post('enterprise/deleteLeaderDepartment', payload)
  );
};

export const requesDeleteTelesaleDepartment = payload => {
  return handleResult(
    getAxios.post('enterprise/deleteTelesaleDepartment', payload)
  );
};

export const requesSaveFilePath = payload => {
  return handleResult(getAxios.post('customer/savefilePath', payload));
};

export const requestImportCustomerDesign = payload => {
  return handleResult(getAxios.post('customer/importCustomerDesign', payload));
};

export const requesGetCustomerFieldImport = payload => {
  return handleResult(
    getAxios.get('customer/customerFieldImport?limit=1000', payload)
  );
};
export const requestAddTelesaleToDepartment = payload => {
  return handleResult(
    getAxios.post('enterprise/addTelesaleToDepartment', payload)
  );
};
export const requestAddLeaderToDepartment = payload => {
  return handleResult(
    getAxios.post('enterprise/addLeaderToDepartment', payload)
  );
};

export const requestGetTimeUpdate = payload => {
  return handleResult(getAxios.get('config/timeUpdate', payload));
};

export const requestTimeUpdate = payload => {
  return handleResult(getAxios.post('config/timeUpdate', payload));
};

export const requestUpdateEditable = payload => {
  return handleResult(getAxios.post('admin/updateEditable', payload));
};

export const requestDeleteKpi = payload => {
  return handleResult(getAxios.post('admin/deleteKPI', payload));
};

export const requestGetJobStatistic = payload => {
  return handleResult(
    getAxios.get(
      `statistic/jobStatistic?job_id=${payload.job_id}&fromDate=${payload.fromDate}&toDate=${payload.toDate}&limit=${payload.limit}&page=${payload.page}`,
      { ...payload }
    )
  );
};

//Báo cáo Enterprise
export const requestGetCallChart = payload => {
  return handleResult(
    getAxios.get(
      `statistic/getCallChart?department_id=${payload.department_id}&telesale_id=${payload.telesale_id}&fromDate=${payload.fromDate}&toDate=${payload.toDate}&limit=${payload.limit}&page=${payload.page}`,
      { ...payload }
    )
  );
};

export const requestGetCallStatusChart = payload => {
  return handleResult(
    getAxios.get(
      `statistic/getCallStatus?department_id=${payload.department_id}&fromDate=${payload.fromDate}&toDate=${payload.toDate}`,
      { ...payload }
    )
  );
};

export const requestGetSaleReportResult = payload => {
  return handleResult(
    getAxios.get(
      `statistic/getSaleReport?department_id=${payload.department_id}&fromDate=${payload.fromDate}&toDate=${payload.toDate}&sale_id=${payload.sale_id}`,
      { ...payload }
    )
  );
};

export const requestGetKpiRealtime = payload => {
  return handleResult(
    getAxios.get(
      `admin/kpiRealTime?date_time=${payload.date_time}&department_id=${
        payload.department_id || ''
      }&sale_id=${payload.sale_id || ''}`
    )
  );
};

export const requestGetKpiStatic = payload => {
  return handleResult(
    getAxios.get(
      `statistic/getKPIstatistic?date_time=${payload.date_time}&department_id=${
        payload.department_id || ''
      }&sale_id=${payload.sale_id || ''}`
    )
  );
};

export const requestGetFullDepartment = payload => {
  return handleResult(getAxios.get(`filter/departments`, { ...payload }));
};

export const requestGetDepartment = telesale_id => {
  return handleResult(
    getAxios.get(`filter/departments-v2?telesale_id=${telesale_id}`)
  );
};

export const requestGetOpportunityChart = payload => {
  return handleResult(
    getAxios.get(
      `statistic/opportunityChart?department_id=${payload.department_id}&opportunity_category_id=${payload.opportunity_category_id}&telesale_id=${payload.telesale_id}&status=${payload.status}&fromDate=${payload.fromDate}&toDate=${payload.toDate}&limit=${payload.limit}&page=${payload.page}`,
      { ...payload }
    )
  );
};

export const requestGetOpportunityList = payload => {
  return handleResult(
    getAxios.get(`filter/opportunitys?call_id=${payload.call_id}`, {
      ...payload,
    })
  );
};

export const requestGetOpportunityStatistic = payload => {
  return handleResult(
    getAxios.get(
      `statistic/opportunityStatistic?department_id=${payload.department_id}&opportunity_category_id=${payload.opportunity_category_id}&telesale_id=${payload.telesale_id}&status=${payload.status}&fromDate=${payload.fromDate}&toDate=${payload.toDate}&limit=${payload.limit}&page=${payload.page}`,
      { ...payload }
    )
  );
};

export const requestGetOpportunityResult = payload => {
  return handleResult(
    getAxios.get(
      `statistic/getOpporReport?department_id=${
        payload.department_id
      }&fromDate=${payload.fromDate}&toDate=${payload.toDate}&sale_id=${
        payload.sale_id
      }&opportunity_category_id=${payload.opportunity_category_id}&status=${
        payload.status === '' ? '' : payload.status
      }`,
      { ...payload }
    )
  );
};

export const requestGetTelesaleStatistic = payload => {
  return handleResult(
    getAxios.get(
      `statistic/telesaleStatistic?department_id=${payload.department_id}&telesale_id=${payload.telesale_id}&fromDate=${payload.fromDate}&toDate=${payload.toDate}&limit=${payload.limit}&page=${payload.page}`,
      { ...payload }
    )
  );
};

export const requestCheckCreateSubJob = payload => {
  return handleResult(
    getAxios.get(`job/checkCreateSubjob?job_id=${payload.job_id}`, {
      ...payload,
    })
  );
};

export const requesDownloadSampleFile = () => {
  // return`${getAxios.defaults.baseURL}customer/samplefile`;
  return getAxios.get('customer/samplefile', {
    responseType: 'blob',
    headers: { 'Content-Type': null },
  });
};
// export const requestGetExportCustomer = ({ payload, ...filters }) => {
//   const otherOptions = toQuerystringFromObject(filters);
//   return getAxios.get(
//     `customer/exportCustomer?group_id=${(payload && payload.group_id) || ''}&job_id=${
//       (payload && payload.job_id) || ''
//     }&${otherOptions}`,
//     { responseType: 'blob', headers: { 'Content-Type': null } }
//   );
// };
export const requestGetExportCustomer = ({
  search = '',
  group_id = '',
  ...filters
}) => {
  const otherOptions = toQuerystringFromObject(filters);
  return getAxios.get(
    `customer/exportCustomer-v2?query=${encodeURIComponent(search)}` +
      `&group_id=${group_id}` +
      `&${otherOptions}`,
    { responseType: 'blob', headers: { 'Content-Type': null } }
  );
};

export const requestExportCustomer = ({
  search = '',
  group_id = '',
  ...filters
}) => {
  const otherOptions = toQuerystringFromObject(filters);
  return getAxios.get(
    `customer/exportCustomerBulls?query=${encodeURIComponent(search)}` +
      `&group_id=${group_id}` +
      `&${otherOptions}`,
    { responseType: 'blob', headers: { 'Content-Type': null } }
  );
};

export const requestExportCustomerInJob = ({
  search = '',
  group_id = '',
  ...filters
}) => {
  const otherOptions = toQuerystringFromObject(filters);
  return getAxios.get(
    `customer/exportCustomerJobBull?query=${encodeURIComponent(search)}` +
      `&group_id=${group_id}` +
      `&${otherOptions}`,
    { responseType: 'blob', headers: { 'Content-Type': null } }
  );
};

// SALE  ======================================================================================================================================

export const getSaleOpportunities = payload => {
  return handleResult(
    getAxios.get(
      `opportunity/list?telesale_id=${payload.telesale_id}&job_id=${payload.job_id}&status=${payload.status}&opportynity_code=${payload.opportunity_code}&fromDate=${payload.fromDate}&toDate=${payload.toDate}&page=${payload.page}`,
      payload
    )
  );
};

export const getSaleCustomers = payload => {
  return handleResult(
    getAxios.get(
      `sale/myCustomer?search=${encodeURIComponent(
        payload.search
      )}&province_id=${payload.province_ids.join(
        ','
      )}&tag_id=${payload.tag_id.join(',')}&page=${payload.page}`
    )
  );
};

export const updateSaleOppoturnityStatus = payload =>
  handleResult(getAxios.post(`opportunity/updateStatusFailOpp`, payload));

export const addTags = payload =>
  handleResult(getAxios.post('sale/addTag', payload));

export const requestGetHomeListCallStatusChart = payload =>
  handleResult(
    getAxios.get(
      `/user/callChart?fromDate=${payload.fromDate}&toDate=${payload.toDate}`
    )
  );

export const requestGetHomeNewCustomerChart = payload =>
  handleResult(
    getAxios.get(
      `/user/customerChart?fromDate=${payload.fromDate}&toDate=${payload.toDate}`
    )
  );

export const requestGetHomeRevenueChart = payload =>
  handleResult(
    getAxios.get(
      `/enterprise/oppotunityChart?fromDate=${payload.fromDate}&toDate=${payload.toDate}`
    )
  );

export const requestGetHomeOpportunityChart = payload =>
  handleResult(
    getAxios.get(
      `/opportunity/oppportuniStatistic?fromDate=${payload.fromDate}&toDate=${payload.toDate}`
    )
  );

export const requestGetRecallList = payload =>
  handleResult(
    getAxios.get(
      `/call/recall?page=${payload.page}&job_id=${payload.job_id}&start_call_time=${payload.start_call_time}`
    )
  );

export const filterRecall = payload =>
  handleResult(
    getAxios.get(
      `/filter/jobs?enterprise_id=&telesale_id=&search=&job_category_id=&limit=1000`
    )
  );

//dowload file
export const requestDownloadLogsFile = payload => {
  return getAxios.get('customer/dowloadFile', {
    responseType: 'blob',
    headers: { 'Content-Type': null },
    params: { pathFile: payload },
  });
};

//custom field
export const requestGetDataFieldDisplayCustom = () => {
  return handleResult(getAxios.get(`customer/dataCustom`));
};

export const requestGetDataFieldFilterCustom = code => {
  let url = `customer/dataFilter`;
  if (code) {
    url = url + `?code=${code}`;
  }

  return handleResult(getAxios.get(url));
};

export const requestUpdateDataFieldDisplayCustom = payload => {
  return getAxios.post('customer/updateDataCustom', { data_customs: payload });
};

export const requestUpdateDataFieldFilterCustom = payload => {
  return getAxios.post('customer/updateDataFilter', {
    data_filterable: payload,
  });
};

export const requestUpdateTagsCustomer = ({ customerId, tags }) => {
  return getAxios.post('sale/addTag', {
    customer_id: customerId,
    tag_ids: tags,
  });
};

//telemarketing report
export const requestGetTelemarketingReportData = (payload, option) => {
  const formattedPayload = { ...payload, search: payload.telemarketing_id };
  return handleResult(
    getAxios.get(`sale/report`, {
      params: new URLSearchParams(formattedPayload),
      ...option,
    })
  );
};

export const requestGetTelemarketingReportDataTotal = (payload, option) => {
  const formattedPayload = { ...payload, search: payload.telemarketing_id };
  return handleResult(
    getAxios.get(`sale/reportDataTotal`, {
      params: new URLSearchParams(formattedPayload),
      ...option,
    })
  );
};

//get all department
export const requestGetAllDepartment = () => {
  return handleResult(getAxios.get('sale/findDepartment'));
};

//get all telemarketing in department
export const requestGetTelemarketingInDepartment = department_id => {
  return handleResult(
    getAxios.get(
      `sale/findSaleByRoleOrDepartment?department_id=${department_id}`
    )
  );
};

//get config display telemarketing report
export const requestGetConfigDisplayTelemarketing = () => {
  return handleResult(getAxios.get(`config/configSale`));
};

//update config display telemarketing report
export const requestUpdateConfigDisplayTelemarketing = data_sale_report => {
  return handleResult(
    getAxios.post(`customer/updateDataReport`, { data_sale_report })
  );
};

// Opportunity=============================================================================================================================================================================================================================================================

//update opportunity
export const requestUpdateOpportunity = payload => {
  return handleResult(getAxios.post(`opportunity/update-v2`, payload));
};

//creat opportunity
export const requestCreateOpportunity = payload => {
  return handleResult(getAxios.post(`opportunity/create-v2`, payload));
};

//list opportunity
export const requestListOpportunity = payload => {
  return handleResult(
    getAxios.get(
      // `/opportunity/list-v2?telesale_id=${payload.telesale_id}&department_id=${payload.department_id}&status=${payload.status}&fromDate=${payload.fromDate}&product_id=${payload.product_id}&toDate=${payload.toDate}&fromDateContractExp=${payload.fromDateContractExp}&toDateContractExp=${payload.toDateContractExp}&fromDateCreate=${payload.fromDateCreate}&toDateCreate=${payload.toDateCreate}&tag_id=${payload.tag_id}&job_id=${payload.job_id}&opportunity_category_id=${payload.type_id}&search=${payload.search}&page=${payload.page}`,
      `/opportunity/list-v2`,
      {
        params: new URLSearchParams({
          telesale_id: payload.telesale_id,
          department_id: payload.department_id,
          status: payload.status,
          fromDate: payload.fromDate,
          product_id: payload.product_id,
          toDate: payload.toDate,
          e_voucher_config_id: payload.e_voucher_config_id || '',
          fromDateContractExp: payload.fromDateContractExp,
          toDateContractExp: payload.toDateContractExp,
          fromDateCreate: payload.fromDateCreate,
          toDateCreate: payload.toDateCreate,
          tag_id: payload.tag_id,
          job_id: payload.job_id,
          opportunity_category_id: payload.type_id,
          customer_source: payload.customer_source,
          search: payload.search.trim(),
          page: payload.page,
        }),
        payload,
      }
    )
  );
};
export const requestDetailOpportunity = id => {
  return handleResult(getAxios.get(`opportunity/detail?id=${id}`));
};
//filter opportunity
export const requestListTelesale = department_id => {
  return handleResult(
    getAxios.get(
      `enterprise/telesaleDepartment?department_id=${department_id}
      `
    )
  );
};
export const requestListDepartment = telesale_id => {
  return handleResult(
    getAxios.get(
      `filter/departments-v2?telesale_id=${telesale_id}
      `
    )
  );
};
export const requestListProduct = product_id => {
  return handleResult(
    getAxios.get(`/opportunity/configs-v2?product_id=${product_id}&limit=1000`)
  );
};

//delete oppo
export const requestDeleteOpportunity = payload => {
  return handleResult(getAxios.post('/opportunity/delete', payload));
};

//Noti==========================================================================================================================================================================
export const requestListNoti = page_noti => {
  return handleResult(
    getAxios.get(`/user/notices?page=${page_noti}&limit=10`, page_noti)
  );
};

export const requestReadNoti = payload => {
  return handleResult(getAxios.post(`/user/readNoti`, payload));
};

// ======================================Business Report=====================================
export const requestGetBusinessReportData = (payload, option) => {
  const formattedPayload = { ...payload, search: payload.telemarketing_id };
  return handleResult(
    getAxios.get(`sale/report_sales`, {
      params: new URLSearchParams(formattedPayload),
      ...option,
    })
  );
};

export const requestGetBusinessReportDataTotal = (payload, option) => {
  const formattedPayload = { ...payload, search: payload.telemarketing_id };
  return handleResult(
    getAxios.get(`sale/total_report_sales`, {
      params: new URLSearchParams(formattedPayload),
      ...option,
    })
  );
};

export const requestExportBusinessReport = payload => {
  const formattedPayload = { ...payload, search: payload.telemarketing_id };
  return getAxios.get(`sale/export_report_sales`, {
    responseType: 'blob',
    headers: { 'Content-Type': null },
    params: new URLSearchParams(formattedPayload),
  });
};

export const requestGetBusinessReportChart = (payload, option) => {
  const formattedPayload = {
    ...payload,
    search: payload.telemarketing_id,
    type: payload.typeTimeCode,
  };
  return handleResult(
    getAxios.get(`sale/report_money_product_time`, {
      params: new URLSearchParams(formattedPayload),
      ...option,
    })
  );
};

export const requestGetBusinessReportChartWithProduct = (payload, option) => {
  const formattedPayload = {
    ...payload,
    search: payload.telemarketing_id,
    page: 1,
    limit: 9999,
  };
  return handleResult(
    getAxios.get(`/sale/report_sales_product`, {
      params: new URLSearchParams(formattedPayload),
      ...option,
    })
  );
};

//list API Key
export const requestListAPIKey = () => {
  return handleResult(getAxios.get(`/userkey`));
};

export const requestCreatAPIKey = () => {
  return handleResult(getAxios.post(`/userkey`));
};

export const requestDeleteAPIKey = id => {
  return handleResult(getAxios.delete(`/userkey/${id}`));
};
