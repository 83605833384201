import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Row, Col, FormControl, Button, Table } from 'react-bootstrap';
import reactotron from 'reactotron-react-js';
import Loading from '@src/components/Loading';
import { notifyFail } from '@utils/notify';
import TableNullData from '@components/TableNullData';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import { getProvinceList, getReviewerList } from '@src/redux/actions';
import { ROUTER, STRING, ACTIVE_STATUS } from '@constants/Constant';
import { requestGetReviewerStatus } from '@constants/Api';
import '@styles/JobGeneral.css';
import { getItemFromId } from '@utils/getItemFromId';
import { Empty } from 'antd';
import { ReactSelect } from '@components/ReactSelect';
class EvaluationStaffScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalAdd: false,
      modalEdit: false,
      modalAddField: {
        name: '',
      },
      modalEditField: {
        id: '',
        name: '',
        is_active: 1,
      },
      activePage: 1,
      isLoading: false,
      search: '',
      status: '',
      is_active: '',
      page: '',
      currentIndex: 0,
      form: {
        [STRING.dob]: '',
      },
      show: false,
      showConfirm: false,
      statusReview: {},
      reviewer_id: '',
    };
  }

  componentDidMount() {
    this.getData({ page: 1 });
    this.props.getProvinceList();
    this.getStatus();
  }

  getData({ page, search, status, province_id }) {
    this.props.getReviewerList({
      page: page || '',
      search: search || '',
      province_id: province_id || '',
      status: status || '',
    });
  }

  async getStatus() {
    try {
      const statusReview = await requestGetReviewerStatus();
      this.setState({
        statusReview: statusReview.data,
        modal: {
          name: statusReview.data.name,
          id: statusReview.data.id,
        },
      });
    } catch (err) {
      notifyFail(err.msg);
    }
  }
  renderBody() {
    return (
      <div className="content-wrapper">
        <div className="content-header">
          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
            <Col md className="bg">
              <Row>
                <Col md={5} sm={12}>
                  <h2> Nhân viên đánh giá </h2>
                </Col>
                <Col md={7} sm={12}>
                  {this.renderButton()}
                </Col>
              </Row>
              <Col md={12}>
                <div class="line"></div>
              </Col>

              <Row>{this.renderField()}</Row>
            </Col>
          </Row>
          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
            <Col md className="bg">
              <Row>{this.renderTable()}</Row>
              <Row>
                <Col md={12} className="d-flex justify-content-center justify-content-md-end">
                  {this.renderPagination()}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderButton() {
    return (
      <div className="text-center text-md-right">
        {this.state?.reviewer_id != '' ? (
          <Link to={ROUTER.ASSIGN + '/' + this.state?.reviewer_id}>
            <Button variant="primary" className="header-btn btn-oversize rounded">
              Giao việc
            </Button>
          </Link>
        ) : (
          <Link to={ROUTER.ASSIGN}>
            <Button variant="primary" className="header-btn btn-oversize rounded">
              Giao việc
            </Button>
          </Link>
        )}
      </div>
    );
  }

  renderField() {
    const { province_id, status, search, statusReview } = this.state;
    // const province = this.props.provinceState?.data;
    const listProvince = this.props.provinceState?.data?.map(
      (province) =>
        new Object({
          value: province.id,
          label: province.name,
        })
    );
    listProvince.unshift({ value: '', label: 'Chọn tỉnh thành phố' });
    return (
      <div className="w-100">
        <Row>
          <Col md={4} sm={12}>
            <FormControl
              type="text"
              id=""
              placeholder="Tên hoặc số điện thoại"
              value={search}
              onChange={(e) => this.searchBarChange('search', e.target.value)}
              onKeyPress={(e) => this.handleKeyPress(e)}
              className="h-100"
            />
          </Col>
          <Col md={4} sm={12}>
            <ReactSelect
              handleChangeSelect={(e) => this.handleChangeSelect(e, 'province_id')}
              list={listProvince}
              placeholder="Chọn tỉnh thành phố"
            />
          </Col>
          <Col md={4} sm={12}>
            <FormControl
              as="select"
              id=""
              value={status}
              onChange={(e) => this.searchBarChange('status', e.target.value)}
              className="h-100"
            >
              <option value="">Trạng thái</option>
              <option value="1">Đang hoạt động</option>
              <option value="2">Tạm Ngưng hoạt động</option>
            </FormControl>
          </Col>
        </Row>
      </div>
    );
  }

  renderTable() {
    const err = this.props.reviewerState?.data?.error;
    const paging = this.props.reviewerState?.paging;
    const reviewerList = this.props.reviewerState?.data;
    if (err) {
      notifyFail(err?.msg);
    }
    return (
      <>
        <div className="w-100 table-wrap" style={{ padding: '0px 15px' }}>
          <Row className="pb-3">
            <Col md={6} sm={6} className="p-0">
              Kết quả lọc: <b className="text-danger">{paging?.count}</b>
            </Col>
            {/* <Col md={6} sm={6} className="text-center text-md-right">
              <Button variant="primary" className="header-btn btn-oversize">
                Giao việc
              </Button>
            </Col> */}
          </Row>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>STT</th>
                <th>Họ tên</th>
                <th>Số điện thoại</th>
                <th>Tỉnh/ thành phố</th>
                <th>Email</th>
                <th>Giao việc</th>
                <th>Trạng thái</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {reviewerList && reviewerList?.length ? (
                reviewerList?.map((reviewer, index) =>
                  this.renderTableRow(reviewer, index + paging.limit * (this.state.activePage - 1) + 1)
                )
              ) : (
                <td colSpan="8">
                  <Empty description="Không có dữ liệu" />
                </td>
              )}
            </tbody>
          </Table>
        </div>
      </>
    );
  }

  renderTableRow(reviewer, index) {
    const status = getItemFromId(ACTIVE_STATUS, reviewer.status);
    return (
      <>
        <tr>
          <td>{index}</td>
          <td onClick={() => this.props.history.push(ROUTER.EVALUATION_STAFF_DETAIL + '/' + reviewer.id)}>
            {reviewer.full_name || '--'}
          </td>
          <td onClick={() => this.props.history.push(ROUTER.EVALUATION_STAFF_DETAIL + '/' + reviewer.id)}>
            {reviewer?.user_name || '--'}
          </td>
          <td onClick={() => this.props.history.push(ROUTER.EVALUATION_STAFF_DETAIL + '/' + reviewer.id)}>
            {reviewer?.province_name || '--'}
          </td>
          <td onClick={() => this.props.history.push(ROUTER.EVALUATION_STAFF_DETAIL + '/' + reviewer.id)}>
            {reviewer?.email || '--'}
          </td>
          <td onClick={() => this.props.history.push(ROUTER.EVALUATION_STAFF_DETAIL + '/' + reviewer.id)}>
            {reviewer?.totalTask || '--'}
          </td>
          <td onClick={() => this.props.history.push(ROUTER.EVALUATION_STAFF_DETAIL + '/' + reviewer.id)}>
            {(status && status.name) || '--'}
          </td>
          <td>
            <input
              type="radio"
              name="phone"
              value={reviewer?.id}
              // onChange={(e) =>
              //   this.handleChangeFormAdd("hotline_id", e.target.value)
              // }

              onClick={() => {
                this.setState({
                  ...this.state,
                  reviewer_id: reviewer?.id,
                });
                reactotron.log(this.state, ' this.setState');
              }}
            />
          </td>
        </tr>
      </>
    );
  }

  setShowModal = (modal, bool, data) => {
    this.setState({
      [modal]: bool,
    });
    if (data) {
      this.setState({
        modalEditField: {
          name: data.name,
          is_active: data.is_active,
          id: data.id,
        },
      });
    }
  };

  clearModal = () => {
    this.setState({
      modalAddField: {
        name: '',
      },
    });
  };

  handleInputChange = (field, value) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [field]: value,
      },
    });
    console.log(this.state.form);
  };

  showPassword = () => {
    this.setState({
      show: !this.state.show,
    });
  };

  showConfirmPassword = () => {
    this.setState({
      showConfirm: !this.state.showConfirm,
    });
  };

  handleChangeSelect = (selectedOption, field) => {
    this.setState(
      {
        ...this.state,
        [field]: selectedOption.value,
      },
      () => {
        const { status, province_id, search, page } = this.state;
        this.getData({
          page: page || '',
          search: search || '',
          province_id: province_id || '',
          status: status || '',
        });
      }
    );
  };

  searchBarChange = async (field, value) => {
    this.setState(
      {
        ...this.state,
        [field]: value,
      },
      () => {
        if (field !== 'search') {
          const { status, province_id, search, page } = this.state;
          this.getData({
            page: page || '',
            search: search || '',
            province_id: province_id || '',
            status: status || '',
          });
        }
      }
    );
  };

  handleKeyPress = (e) => {
    let { page, status, province_id, search } = this.state;
    if (e.charCode === 13) {
      page = page || '';
      search = search || '';
      province_id = province_id || '';
      status = status || '';
      this.getData({
        page,
        status,
        province_id,
        search,
      });
    }
  };

  renderPagination() {
    const itemsCount = this.props.reviewerState?.paging?.count;
    const limit = this.props.reviewerState?.paging?.limit;
    const { status, province_id, search } = this.state;
    return (
      <Pagination
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled
        activePage={this.state.activePage}
        itemsCountPerPage={limit}
        totalItemsCount={itemsCount}
        pageRangeDisplayed={5}
        hideNavigation
        onChange={(page) => {
          this.setState({
            ...this.state,
            activePage: page,
          });
          this.getData({ page, status, province_id, search });
        }}
      />
    );
  }

  render() {
    const { isLoading, error } = this.props.reviewerState;
    let loading = <Loading />;
    if (!isLoading) {
      loading = null;
    }
    if (error) {
      notifyFail(error.msg);
    }
    return (
      <>
        {loading}
        {this.renderBody()}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  reviewerState: state.reviewerListReducer,
  provinceState: state.ProvinceReducer,
});

const mapDispatchToProps = {
  getReviewerList,
  getProvinceList,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EvaluationStaffScreen));
