import React, { Component } from 'react';
import {
  requestCompanyDetail,
  requestAddCallDurations,
  changePackage,
  requestGetListPackage,
  requestGetAppPartner,
  getAmountOfCall,
} from '@constants/Api';
import { createFormData } from '@utils/createFormData';
import { withRouter, Link } from 'react-router-dom';
import '@styles/Company.css';
import { Row, Col, FormControl, Button, FormGroup, Table, Modal, Form } from 'react-bootstrap';
import { DatePicker } from 'antd';
import { ROUTER } from '@constants/Constant';
import Loading from '@src/components/Loading';
import { ToastContainer } from 'react-toastify';
import { notifyFail, notifySuccess } from '@utils/notify';
import reactotron from 'reactotron-react-js';
import { getTelesaleList, getPackageHistory, getListPackage } from '@src/redux/actions';
import { Empty } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import * as ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import { toDateHourString } from '@utils/ToDateString';
import SelectComponentV2 from '@components/select/SelectComponent';
import SMSConfiguration from './components/SMSConfiguration';
// import { PaginationComponent } from '@components/PaginationComponent';

function FormError(props) {
  if (props.isHidden) {
    return null;
  }
  return (
    <div
      style={{
        color: 'red',
        width: '100%',
        textAlign: 'center',
      }}
    >
      {props.errorMessage}
    </div>
  );
}

class CompanyDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        id: '',
        user_name: '',
        full_name: '',
        email: '',
        office_name: '',
        address: '',
        is_active: '',
      },
      isLoading: false,
      file: '',
      detail: {},
      modalAddCallDuration: false,
      modalEditPackage: false,
      modalExportExcel: false,
      modalAddCallDurationField: {
        package_service_id: '',
        note: '',
      },
      modalPackage: {
        package_id: '',
        note: '',
      },
      mainPackage: [],
      extraPackage: [],
      syncInfo: {
        create_sale_link: '',
        create_enterprise_link: '',
        name: '',
        deeplink_ios: '',
        deeplink_android: '',
        app_store_id: '',
        play_store_id: '',
      },
      excelInfor: {
        fromDate: '',
        toDate: '',
      },
    };
  }

  componentDidMount() {
    const { id } = this.props.match?.params;
    this.getData();
    this.props.getTelesaleList({ enterprise_id: id, page: 1 });
    this.props.getPackageHistory({ enterprise_id: parseInt(id), page: 1 });
    this.getMainPackage();
    this.getExtraPackage();
    this.getAppPartner();
  }

  async getMainPackage() {
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const res = await requestGetListPackage({ search: '', level: '', type: 1, page: '' });
      this.setState({
        ...this.state,
        mainPackage: res.data,
        isLoading: false,
      });
    } catch (err) {
      this.setState({
        ...this.state,
        isLoading: false,
      });
    }
  }

  async getExtraPackage() {
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const res = await requestGetListPackage({ search: '', level: '', type: 2, page: '' });
      this.setState({
        ...this.state,
        extraPackage: res.data,
        isLoading: false,
      });
    } catch (err) {
      this.setState({
        ...this.state,
        isLoading: false,
      });
    }
  }

  getData(payload) {
    this.getCompanyDetail();
  }

  async getAppPartner() {
    try {
      const res = await requestGetAppPartner();
    } catch (error) {}
  }

  async changePackage() {
    const { id } = this.props.match.params;
    const { modalPackage } = this.state;
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      await changePackage({
        id: id,
        package_service_id: modalPackage.package_id,
        note: modalPackage.note,
      });
      this.setState(
        {
          ...this.state,
          isLoading: false,
          modalEditPackage: false,
        },
        () => {
          notifySuccess('Cập nhật thành công');
          this.getData();
          this.props.getPackageHistory({ enterprise_id: id });
        }
      );
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async getCompanyDetail() {
    this.setState({ isLoading: true });
    const { id } = this.props.match.params;
    const res = await requestCompanyDetail({ id: id });
    let file = '';
    await this.setState({
      ...this.state,
      detail: res.data,
      isLoading: false,
      file: res.data.user_enterprise_info?.profile_image || '',
      syncInfo: {
        ...res.data.config_app_enter,
        create_sale_link: res.data.config_app_enter?.create_sale_link || '',
        create_enterprise_link: res.data.config_app_enter?.create_enterprise_link || '',
        name: res.data.config_app_enter?.name || '',
        deeplink_ios: res.data.config_app_enter?.deeplink_ios || '',
        deeplink_android: res.data.config_app_enter?.deeplink_android || '',
        app_store_id: res.data.config_app_enter?.app_store_id || '',
        play_store_id: res.data.config_app_enter?.play_store_id || '',
      },
    });
  }

  renderInfo() {
    const company = this.state.detail;
    return (
      <Col className="bg mb-3">
        <Row className="p-2">
          <h4>Thông tin công ty</h4>
        </Row>
        <Row className="p-2">
          <Col md={2}>
            <i className="fas fa-phone-alt"></i>
          </Col>
          <Col md={10}>{company?.phone}</Col>
        </Row>
        <Row className="p-2">
          <Col md={2}>
            <i className="fas fa-envelope"></i>
          </Col>
          <Col md={10}>{company?.email}</Col>
        </Row>
        <Row className="p-2">
          <Col md={2}>
            <i className="fas fa-map-marker-alt"></i>
          </Col>
          <Col md={10}>{company?.user_enterprise_info?.address}</Col>
        </Row>
        <Row className="p-2">
          <Col md={2}>
            <i className="fas fa-user"></i>
          </Col>
          <Col md={10}>{company?.full_name} </Col>
        </Row>
        <Row className="p-2">
          <Col md={2}>
            <i className="far fa-gem" style={{ paddingTop: '9px' }}></i>
          </Col>
          <Col md={10}>
            {company?.user_enterprise_info?.package_service?.name}
            <Button
              variant="primary"
              className="text-light ml-2"
              onClick={() => this.setShowModal('modalEditPackage', true)}
            >
              Đổi gói
            </Button>
          </Col>
        </Row>
      </Col>
    );
  }

  renderSyncInfo() {
    const {
      create_sale_link,
      create_enterprise_link,
      name,
      deeplink_ios,
      deeplink_android,
      app_store_id,
      play_store_id,
    } = this.state.syncInfo;

    return (
      <Col className="bg mb-3">
        <Row className="p-2">
          <h4>Thông tin đồng bộ</h4>
        </Row>
        <Row className="p-2">
          <Col md={2}>
            <i class="fas fa-mobile-alt fa-lg"></i>
          </Col>
          <Col md={10}>
            <label>App:</label>
            <span>{` ${name || 'Chưa cập nhật'}`}</span>
          </Col>
        </Row>
        <Row className="p-2">
          <Col md={2}>{/* <i class="fas fa-mobile-alt fa-lg"></i> */}</Col>
          <Col md={10}>
            <label>Link tạo công ty:</label>
            <br />
            <div style={{ wordWrap: 'break-word' }}>
              <a style={{ color: '#007bff' }}>{create_enterprise_link || 'Chưa cập nhật'}</a>
            </div>
          </Col>
        </Row>
        <Row className="p-2">
          <Col md={2}>{/* <i class="fas fa-mobile-alt fa-lg"></i> */}</Col>
          <Col md={10}>
            <label>Link tạo sale:</label>
            <br />
            <div style={{ wordWrap: 'break-word' }}>
              <a style={{ color: '#007bff' }}>{create_sale_link || 'Chưa cập nhật'}</a>
            </div>
          </Col>
        </Row>
        <Row className="p-2">
          <Col md={2}>
            <i class="fab fa-apple fa-lg"></i>
          </Col>
          <Col md={10}>
            <label>Mã app store:</label>
            <br />
            <div style={{ wordWrap: 'break-word' }}>
              <a style={{ color: '#007bff' }}>{app_store_id || 'Chưa cập nhật'}</a>
            </div>
          </Col>
        </Row>
        <Row className="p-2">
          <Col md={2}>
            <i class="fab fa-android fa-lg"></i>
          </Col>
          <Col md={10}>
            <label>Mã play store:</label>
            <br />
            <div style={{ wordWrap: 'break-word' }}>
              <a style={{ color: '#007bff' }}>{play_store_id || 'Chưa cập nhật'}</a>
            </div>
          </Col>
        </Row>
        <Row className="p-2">
          <Col md={2}>
            <i class="fab fa-apple fa-lg"></i>
          </Col>
          <Col md={10}>
            <label>Deeplink IOS:</label>
            <br />
            <div style={{ wordWrap: 'break-word' }}>
              <a style={{ color: '#007bff' }}>{deeplink_ios || 'Chưa cập nhật'}</a>
            </div>
          </Col>
        </Row>
        <Row className="p-2">
          <Col md={2}>
            <i class="fab fa-android fa-lg"></i>
          </Col>
          <Col md={10}>
            <label>Deeplink Android:</label>
            <br />
            <div style={{ wordWrap: 'break-word' }}>
              <a style={{ color: '#007bff' }}>{deeplink_android || 'Chưa cập nhật'}</a>
            </div>
          </Col>
        </Row>
      </Col>
    );
  }

  renderJobInfo() {
    const company = this.state.detail;
    return (
      <Col className="bg mb-3">
        <Row className="p-2">
          <h4>Thông tin công việc</h4>
        </Row>
        <Row>
          <Col md={6}>
            <Row className="p-2">
              <Col md={7}>Số công việc: </Col>
              <Col md={5}>
                <b>{company?.user_enterprise_info?.countJob || '0'}</b>
              </Col>
            </Row>
            <Row className="p-2">
              <Col md={7}>Số sale: </Col>
              <Col md={5}>
                <b>{company?.user_enterprise_info?.countTelesale || '0'}</b>
              </Col>
            </Row>
            <Row className="p-2">
              <Col md={7}>Đầu số:</Col>
              <Col md={5}>
                <b>{company?.user_enterprise_info?.countHotline || '0'}</b>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row className="p-2">
              <Col md={7}> Số sale của hệ thống: </Col>
              <Col md={5}>
                <b>{'0'}</b>
              </Col>
            </Row>
            <Row className="p-2">
              <Col md={7}> Số phút gọi còn lại: </Col>
              <Col md={5}>
                <b>{parseInt(company?.user_enterprise_info?.call_duration_remain / 60) || '0'}</b>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    );
  }
  renderTelesaleInfo() {
    const company = this.state.detail;
    const telesale = this.props.telesaleState?.data;

    return (
      <Col className="bg mb-3">
        <Row>
          <h4>Danh sách sale hệ thống tham gia</h4>
        </Row>
        <Row>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>STT</th>
                <th>Tên sale</th>
                <th>Thời gian</th>
                <th>Trạng thái</th>
              </tr>
            </thead>
            <tbody>
              {telesale && telesale.length ? (
                telesale?.map((tele, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{tele?.full_name}</td>
                    <td>{Intl.DateTimeFormat('vi-VN').format(new Date(tele?.created_at)) || null}</td>
                    <td>
                      {tele?.is_active == 1
                        ? 'Đang hoạt động'
                        : tele?.is_active == 2
                        ? 'Tạm ngưng hoạt động'
                        : 'Ngừng hoạt động'}
                    </td>
                  </tr>
                ))
              ) : (
                <td colspan="4">
                  <Empty description={<span>Không có dữ liệu</span>} />
                </td>
              )}
            </tbody>
          </Table>
        </Row>
      </Col>
    );
  }

  renderSMSConfiguration() {
    return (
      <Col className="bg mb-3">
        <SMSConfiguration />
      </Col>
    );
  }

  renderPackageHistory() {
    const packageHistory = this.props.packageHistoryState?.data;
    const paging = this.props.packageHistoryState?.paging;

    return (
      <Col className="bg mb-3">
        <Row className="mb-3">
          <Col md={4} className="p-0">
            <h4>Lịch sử giao dịch</h4>
          </Col>
          <Col md={8} className="p-0">
            <div className="text-center text-md-right">
              <Button
                variant="success"
                className="text-light"
                onClick={() => this.setShowModal('modalAddCallDuration', true)}
              >
                Thêm phút gọi
              </Button>
            </div>
          </Col>
        </Row>

        <Row>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>STT</th>
                <th>Tên nhóm</th>
                <th>Số sale</th>
                <th>Số công việc</th>
                <th>Số phút gọi</th>
                <th>Thời gian</th>
                <th>Trạng thái</th>
              </tr>
            </thead>
            <tbody>
              {packageHistory && packageHistory.length ? (
                packageHistory?.map((pack, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{pack?.package_name || ''}</td>
                    <td>{pack?.max_telesale || '0'}</td>
                    <td>{pack?.max_job || '0'}</td>
                    <td>{pack?.call_duration || '0'}</td>
                    <td>{Intl.DateTimeFormat('vi-VN').format(new Date(pack?.created_at)) || null}</td>
                    <td>
                      {pack?.is_active == 1
                        ? 'Đang hoạt động'
                        : pack?.is_active == 2
                        ? 'Tạm ngưng hoạt động'
                        : 'Ngừng hoạt động'}
                    </td>
                  </tr>
                ))
              ) : (
                <td colspan="7">
                  <Empty description={<span>Không có dữ liệu</span>} />
                </td>
              )}
            </tbody>
          </Table>
        </Row>
        {/* <Row>
          <PaginationComponent paging={paging} handlePageChange={this.props.getPackageHistory({ enterprise_id: id })} />
        </Row> */}
      </Col>
    );
  }

  addModalPackage = () => {
    const { modalEditPackage, modalPackage, mainPackage } = this.state;
    const company = this.state.detail;
    const pack = this.props.packageState.data?.data;
    return (
      <Modal
        show={modalEditPackage}
        onHide={() => this.setShowModal('modalEditPackage', false)}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton className="bg-blue pb-0">
          <h5 className="text-light">Đổi gói</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          <FormGroup className="text-left">
            <Row>
              <Col md={4}>
                <span>Tên công ty</span>
              </Col>
              <Col md={8}>
                <label>{company?.user_enterprise_info?.office_name}</label>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="text-left">
            <Row>
              <Col md={4}>
                <span>Gói dịch vụ(*)</span>
              </Col>
              <Col md={8}>
                <SelectComponentV2
                  value={modalPackage.package_id}
                  limit={1000}
                  onChange={(item) => {
                    this.handleInputChange('package_id', item.value);
                  }}
                  apiUrl="admin/getListPackage"
                  placeholder="Gói dịch vụ"
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="text-left">
            <Row>
              <Col md={4}>
                <span>Ghi chú(*)</span>
              </Col>
              <Col md={8}>
                <Form.Control
                  as="textarea"
                  rows={5}
                  className="w-100"
                  placeholder="Nhập ghi chú"
                  value={modalPackage.note}
                  onChange={(e) => this.handleChangePackage('note', e.target.value)}
                />
              </Col>
            </Row>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="success"
            className="text-light"
            onClick={() => {
              this.changePackage();
            }}
          >
            Cập nhật
          </Button>
          <Button variant="danger" className="text-light" onClick={() => this.setShowModal('modalEditPackage', false)}>
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  addModalCallDuration = () => {
    const { modalAddCallDuration, modalAddCallDurationField, extraPackage } = this.state;
    const company = this.state.detail;
    const pack = this.props.packageState.data?.data;
    return (
      <Modal
        show={modalAddCallDuration}
        onHide={() => this.setShowModal('modalAddCallDuration', false)}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton className="bg-blue pb-0">
          <h5 className="text-light">Thêm phút gọi</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          <FormGroup className="text-left">
            <Row>
              <Col md={4}>
                <span>Tên công ty</span>
              </Col>
              <Col md={8}>
                <label>{company?.user_enterprise_info?.office_name}</label>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="text-left">
            <Row>
              <Col md={4}>
                <span>Gói mua thêm(*)</span>
              </Col>
              <Col md={8}>
                <FormControl
                  as="select"
                  id="provice"
                  value={modalAddCallDurationField.package_service_id}
                  onChange={(e) => this.addCallDurationFieldChange('package_service_id', e.target.value)}
                >
                  <option value="" selected disabled>
                    Gói dịch vụ
                  </option>
                  {extraPackage && extraPackage.length
                    ? extraPackage?.map((pack, index) => <option value={pack?.id}>{pack?.name}</option>)
                    : ''}
                </FormControl>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="text-left">
            <Row>
              <Col md={4}>
                <span>Ghi chú(*)</span>
              </Col>
              <Col md={8}>
                <Form.Control
                  as="textarea"
                  rows={5}
                  className="w-100"
                  placeholder="Nhập ghi chú"
                  value={modalAddCallDurationField.note}
                  onChange={(e) => this.addCallDurationFieldChange('note', e.target.value)}
                />
              </Col>
            </Row>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="success"
            className="text-light"
            onClick={() => {
              this.createPackage();
            }}
          >
            Cập nhật
          </Button>
          <Button
            variant="danger"
            className="text-light"
            onClick={() => {
              // this.setShowModal('modalEditPackage', false);
              this.setShowModal('modalAddCallDuration', false);
            }}
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  async handleChangeDateExportExcel(date) {
    if (date) {
      this.setState((prevState) => ({
        ...prevState,
        excelInfor: { fromDate: moment(date[0]).format('YYYY-MM-DD'), toDate: moment(date[1]).format('YYYY-MM-DD') },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        excelInfor: { fromDate: '', toDate: '' },
      }));
    }
  }

  async exportExcel() {
    const { id } = this.props.match?.params;
    const { excelInfor } = this.state;
    try {
    } catch (error) {
      notifyFail(error.msg);
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('1');
    worksheet.getRow(2).values = [
      'STT',
      'Mã giao dịch',
      'Số tổng đài',
      'Thời lượng cuộc gọi (giây)',
      'Giá tiền ($)',
      'Tên KH',
      'SĐT KH',
      'Tên Sale',
      'SĐT Sale',
      'Trạng thái',
      'Thời gian bắt đầu',
      'Thời gian kết thúc',
    ];

    worksheet.columns = [
      { key: 'stt', width: 8 },
      { key: 'intergrated_call_id', width: 40 },
      { key: 'from_number', width: 20 },
      { key: 'answer_duration', width: 25 },
      { key: 'amount', width: 15 },
      { key: 'customer_name', width: 20 },
      { key: 'customer_phone', width: 15 },
      { key: 'sale_name', width: 30 },
      { key: 'sale_phone', width: 15 },
      { key: 'status_name', width: 23 },
      { key: 'start_time_datetime', width: 30 },
      { key: 'stop_time_datetime', width: 30 },
    ];

    try {
      const response = await getAmountOfCall({
        fromDate: excelInfor.fromDate,
        toDate: excelInfor.toDate,
        enterprise_id: id,
      });
      response.data.dataOutput.map((callInfor, index) => {
        worksheet.addRow({
          stt: index + 1,
          intergrated_call_id: callInfor.intergrated_call_id,
          from_number: callInfor.from_number,
          answer_duration: callInfor.answer_duration,
          amount: callInfor.amount,
          customer_name: callInfor.call_data.customer_name,
          customer_phone: callInfor.call_data.customer_phone,
          sale_name: callInfor.call_data.sale_name,
          sale_phone: callInfor.call_data.sale_phone,
          status_name: callInfor.call_data.status_name,
          start_time_datetime: callInfor.start_time_datetime,
          stop_time_datetime: callInfor.stop_time_datetime,
        });
      });
      worksheet.mergeCells('A1', 'L1');
      worksheet.getCell('A1').value = `Tổng thời lượng cuộc gọi: ${
        response.data.totalDuration
      } (s) \r\n Tổng số tiền: ${response.data.totalAmount.toFixed(4)} ($) `;
      worksheet.getRow(1).height = 40;
      worksheet.getRow(1).alignment = { vertical: 'middle', wrapText: true };
      workbook.xlsx.writeBuffer('export.xlsx').then(function (data) {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(blob, `DS_Cuocgoi_${toDateHourString(new Date())}.xlsx`);
      });
    } catch (error) {
      notifyFail(error.msg || 'Không thể kết xuất excel!');
    } finally {
      this.setState((prevState) => ({ ...prevState, modalExportExcel: false }));
    }
  }

  addModalExportExcel() {
    const { modalExportExcel } = this.state;
    return (
      <Modal
        show={modalExportExcel}
        dialogClassName="modal-90w"
        centered
        onHide={() => this.setShowModal('modalExportExcel', false)}
      >
        <Modal.Header closeButton className="bg-blue pb-0">
          <h5 className="text-light">Export Excel</h5>
        </Modal.Header>
        <Modal.Body>
          <DatePicker.RangePicker
            size="large"
            style={{ width: '100%' }}
            format="DD/MM/YYYY"
            placeholder={['Từ ngày', 'Đến ngày']}
            onChange={(e) => this.handleChangeDateExportExcel(e)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => this.exportExcel()}>Xuất excel</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  addCallDurationFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalAddCallDurationField: {
        ...this.state.modalAddCallDurationField,
        [field]: value,
      },
    });
  };

  handleChangePackage = (field, value) => {
    this.setState({
      ...this.state,
      modalPackage: {
        ...this.state.modalPackage,
        [field]: value,
      },
    });
  };

  setShowModal = (modal, bool, data) => {
    this.setState({
      [modal]: bool,
    });
    this.setState((prevState) => ({
      modalAddCallDurationField: {
        ...this.getResetAddCallDurationField(),
      },
    }));
  };

  getResetAddCallDurationField() {
    return {
      enterprise_id: '',
      package_service_id: '',
      note: '',
    };
  }
  validateFormAdd() {
    const { modalAddCallDurationField } = this.state;
    if (modalAddCallDurationField.package_service_id == '') {
      notifyFail('Vui lòng chọn gói dịch vụ');
      return false;
    }
    if (modalAddCallDurationField.note == '') {
      notifyFail('Vui lòng nhập ghi chú');
      return false;
    }
    return true;
  }

  async createPackage() {
    const { modalAddCallDurationField } = this.state;
    const { id } = this.props.match?.params;
    const check = await this.validateFormAdd();
    if (check) {
      const payload = {
        enterprise_id: id,
        package_service_id: modalAddCallDurationField?.package_service_id,
        note: modalAddCallDurationField?.note,
      };

      try {
        const res = await requestAddCallDurations(payload);
        this.setState((prevState) => ({
          modalAddCallDurationField: {
            ...this.getResetAddCallDurationField(),
          },
        }));
        this.props.getPackageHistory({ enterprise_id: id });
        this.setState({ isLoading: false });
        this.getCompanyDetail();
        this.setShowModal('modalAddCallDuration', false);
        notifySuccess('Thêm phút gọi thành công');
      } catch (err) {
        this.setState({ isLoading: false });
        notifyFail(err.msg);
      }
    }
    return;
  }

  renderBody() {
    const company = this.state.detail;
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <Row className="bg mr-3 ml-3" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 15 }}>
            <Col md={8}>
              <h2 style={{ color: 'red' }}>{company?.user_enterprise_info?.office_name}</h2>
            </Col>
            <Col md={4}>
              <div className="text-center text-md-right">
                <Button
                  variant="primary"
                  className="text-light"
                  onClick={() => this.setShowModal('modalExportExcel', true)}
                >
                  Xuất excel
                </Button>
                <Button variant="danger" className="text-light">
                  Xóa
                </Button>
                <Link to={ROUTER.COMPANY_EDIT + `/${company.id}`}>
                  <Button variant="warning" className="text-light">
                    Chỉnh sửa
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="mr-3 ml-3">
            <Col md={3} className="mr-3 mb-3 p-0">
              {this.renderInfo()}
              {this.renderSyncInfo()}
            </Col>
            <Col className="p-0">
              {this.renderJobInfo()}
              {this.renderTelesaleInfo()}
              {this.renderSMSConfiguration()}
              {this.renderPackageHistory()}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        {this.state.isLoading ? <Loading /> : null}
        {this.renderBody()}
        {this.addModalPackage()}
        {this.addModalCallDuration()}
        {this.addModalExportExcel()}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={true}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ToastContainer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  telesaleState: state.TelesaleReducer,
  packageHistoryState: state.packageHistoryReducer,
  packageState: state.listPackageReducer,
});

const mapDispatchToProps = {
  getTelesaleList,
  getPackageHistory,
  getListPackage,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompanyDetail));
