import {
  requestCallHistoryDetail,
  requestCallReviewConfig,
} from '@constants/Api';
import Loading from '@src/components/Loading';
import { getListCallStatus } from '@src/redux/actions';
import '@styles/RegisterRequest.css';
import { notifyFail, notifySuccess } from '@utils/notify';
import { Checkbox, DatePicker, Empty, Input, Radio, Space } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Nav, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { requestUpdateCall } from '../../../network/web_sale/CallApi';

const { TextArea } = Input;

const styles = {
  questionContainer: {
    marginTop: 10,
    marginBottom: 20,
  },
  answerRadioMargin: {
    marginRight: 10,
  },
  childQuestionContainer: {
    marginTop: 5,
    marginBottom: 5,
  },
};

const CallInfo = props => {
  const { data } = props;

  return (
    <Row
      className="mt-1"
      style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 10 }}
    >
      <Col md className="bg">
        <Row>
          <h2>Chi tiết cuộc gọi</h2>
        </Row>
        <Row className="title">
          <Col md={6} sm={6}>
            <Row>
              <Col md={12}>
                <b>Thông tin khách hàng</b>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4} sm={4} xs={4}>
                Họ tên
              </Col>
              <Col md={8} sm={8} xs={8}>
                {data.customer_name || 'Chưa cập nhật'}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4} sm={4} xs={4}>
                Số điện thoại
              </Col>
              <Col md={8} sm={8} xs={8}>
                {data.customer_phone || 'Chưa cập nhật'}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4} sm={4} xs={4}>
                Tỉnh/thành phố
              </Col>
              <Col md={8} sm={8} xs={8}>
                {data.province_name || 'Chưa cập nhật'}
              </Col>
            </Row>
          </Col>
          <Col md={6} sm={6}>
            <Row>
              <Col md={12}>
                <b>Thông tin cuộc gọi</b>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4} sm={4} xs={4}>
                Sale
              </Col>
              <Col md={8} sm={8} xs={8}>
                {data.sale_name || 'Chưa cập nhật'}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4} sm={4} xs={4}>
                Công việc
              </Col>
              <Col md={8} sm={8} xs={8}>
                {data.job_name || 'Chưa cập nhật'}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4} sm={4} xs={4}>
                Thời gian
              </Col>
              <Col md={8} sm={8} xs={8}>
                <span>
                  {' '}
                  {Intl.DateTimeFormat('vi-VN').format(
                    new Date(data.start_call_time || null)
                  )}
                </span>{' '}
                | <b>{data?.call_duration || '0'}s</b>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4} sm={4} xs={4}>
                Trạng thái
              </Col>
              <Col
                md={8}
                sm={8}
                xs={8}
                className={
                  data?.status_name === 'Không nghe máy'
                    ? 'text-danger'
                    : data?.status_name === 'Đồng ý trao đổi'
                    ? 'text-success'
                    : 'text-warning'
                }
              >
                {data.status_name || 'Chưa cập nhật'}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const WorkTabs = props => {
  const {
    tabInfo,
    onSelectTab,
    data,
    callStatus,
    callReviewData,
    callStatusId,
    onChangeStatusId,
    noteRecall,
    noteCall,
    onChangeNoteRecall,
    onChangeNoteCall,
    answerList,
    onChangeAnswerList,
    onSubmit,
  } = props;

  return (
    <Row
      className="mt-1"
      style={{ padding: '0px 20px', borderRadius: '8px', paddingBottom: 30 }}
    >
      <Col md className="bg">
        <Row>
          <h2>Kết quả cuộc gọi</h2>
        </Row>
        <Row className="content-header table-tabs pl-0 pb-0">
          <Col md={12}>
            <Nav variant="tabs" defaultActiveKey="scenario">
              <Nav.Item>
                <Nav.Link
                  eventKey="scenario"
                  onSelect={() => onSelectTab(1)}
                  className="tab-item"
                >
                  Kịch bản
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="general-info"
                  onSelect={() => onSelectTab(2)}
                  className="tab-item"
                >
                  Dữ liệu thu thập
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="call-history"
                  onSelect={() => onSelectTab(3)}
                  className="tab-item"
                >
                  Ghi âm cuộc gọi
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="rate"
                  onSelect={() => onSelectTab(4)}
                  className="tab-item"
                >
                  Đánh giá
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row className="bg-white">
          <Col md={12} className="bg-white pt-4" style={{ minHeight: '700px' }}>
            {tabInfo === 1 ? (
              <Scenario data={data} />
            ) : tabInfo === 2 ? (
              <DataCollection
                data={data}
                callStatus={callStatus}
                callStatusId={callStatusId}
                onChangeStatusId={value => onChangeStatusId(value)}
                noteRecall={noteRecall}
                noteCall={noteCall}
                onChangeNoteRecall={value => onChangeNoteRecall(value)}
                onChangeNoteCall={value => onChangeNoteCall(value)}
                answerList={answerList}
                onChangeAnswerList={(quest, value) =>
                  onChangeAnswerList(quest, value)
                }
                onSubmit={() => onSubmit()}
              />
            ) : tabInfo === 3 ? (
              <CallRecord data={data} />
            ) : (
              <Review data={data} callReviewData={callReviewData} />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const Scenario = props => {
  const { data } = props;

  return (
    <>
      <Row className="mt-2">
        <Col md={12}>
          <h4>Kịch bản</h4>
        </Col>
      </Row>
      {data.job?.scenario?.scenario_steps?.length > 0 ? (
        data.job?.scenario?.scenario_steps?.map((value, index) => (
          <>
            <Row className="mt-3" key={index}>
              <Col md={12} xs={12} sm={12}>
                <strong>
                  Bước {index + 1}: {value.name}
                </strong>
              </Col>
            </Row>
            <Row className="mt-3" key={index}>
              <Col md={12} xs={12} sm={12}>
                <p>
                  {/* <i className="fal fa-angle-right"></i>{' '} */}
                  <span style={{ whiteSpace: 'pre-wrap' }}>
                    {value?.content}
                  </span>
                </p>
              </Col>
            </Row>
          </>
        ))
      ) : (
        <Row className="mt-2">
          <Col md={12} xs={12} sm={12}>
            <span className="text-muted">Không có kịch bản</span>
          </Col>
        </Row>
      )}
      <Row className="mt-3">
        <Col md={12}>
          <h4>Mục tiêu</h4>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={12} xs={12} sm={12}>
          {data.job?.target ? (
            <div dangerouslySetInnerHTML={{ __html: data.job?.target }}></div>
          ) : (
            <span className="text-muted">Không có mục tiêu</span>
          )}
        </Col>
      </Row>
    </>
  );
};

const DataCollection = props => {
  const {
    data,
    callStatus,
    callStatusId,
    onChangeStatusId,
    noteRecall,
    noteCall,
    onChangeNoteRecall,
    onChangeNoteCall,
    answerList,
    onChangeAnswerList,
    onSubmit,
  } = props;

  const callStatusFilter = callStatus.filter(item => item.id !== 5) || [];

  console.log(answerList);

  const renderQuestion = v => {
    switch (v.type_input) {
      case 1:
        return (
          <Row>
            <Col md={8} className="mt-3 pl-0">
              <Input
                placeholder="Nhập..."
                disabled={!data.isCanUpdate}
                value={answerList?.find(item => item.id === v.id)?.text_result}
                onChange={e => onChangeAnswerList(v, e.target.value)}
              />
            </Col>
          </Row>
        );

      case 3:
        return (
          <Checkbox.Group
            value={
              answerList.find(answer => answer.id === v.id)?.number_result || ''
            }
            onChange={!data.isCanUpdate ? null : e => onChangeAnswerList(v, e)}
          >
            {v.job_form_answers?.map((item, index) => (
              <>
                <Row className="mt-3" key={index}>
                  <Col className="p-0">
                    <Checkbox key={item.id} value={item.id}>
                      {item.name}
                    </Checkbox>
                    <Row className="pl-4">
                      {answerList.find(answer => answer.id === v.id)
                        ?.number_result[0] === item.id &&
                        item?.job_form_answer_child?.length > 0 &&
                        item?.job_form_answer_child?.map((v1, i) => (
                          <Col
                            key={i}
                            xs={12}
                            style={styles.childQuestionContainer}
                          >
                            <Row>
                              <b>
                                {v1.name}
                                {v1.is_require === 1 && ' (*)'}
                              </b>
                            </Row>
                            {renderQuestion(v1)}
                          </Col>
                        ))}
                    </Row>
                  </Col>
                </Row>
              </>
            ))}
          </Checkbox.Group>
        );
      case 4:
        return (
          <Row>
            <Col md={8} className="mt-3 pl-0">
              <DatePicker
                style={{ width: '100%' }}
                defaultValue={
                  v.text_result && moment(v.text_result, 'DD/MM/YYYY')
                }
                placeholder="Chọn ngày"
                format="DD/MM/YYYY"
                onChange={
                  !data.isCanUpdate
                    ? null
                    : (date, dateString) => onChangeAnswerList(v, dateString)
                }
              />
            </Col>
          </Row>
        );
      default:
        return (
          <Radio.Group
            value={
              answerList.find(answer => answer.id === v.id)?.number_result[0] ||
              ''
            }
            onChange={
              !data.isCanUpdate
                ? null
                : e => onChangeAnswerList(v, e.target.value)
            }
          >
            {v.job_form_answers?.map((item, index) => (
              <>
                <Row className="mt-3" key={index}>
                  <Col className="p-0">
                    <Radio key={item.id} value={item.id}>
                      {item.name}
                    </Radio>
                    <Row className="pl-4">
                      {answerList.find(answer => answer.id === v.id)
                        ?.number_result[0] === item.id &&
                        item?.job_form_answer_child?.length > 0 &&
                        item?.job_form_answer_child?.map((v1, i) => (
                          <Col
                            key={i}
                            xs={12}
                            style={styles.childQuestionContainer}
                          >
                            <Row>
                              <b>
                                {v1.name}
                                {v1.is_require === 1 && ' (*)'}
                              </b>
                            </Row>
                            {renderQuestion(v1)}
                          </Col>
                        ))}
                    </Row>
                  </Col>
                </Row>
              </>
            ))}
          </Radio.Group>
        );
    }
  };

  return (
    <>
      <Row>
        <Col md={1}>
          <h6>
            <b>Gọi lại?</b>
          </h6>
        </Col>
        <Col md={6}>
          <p>
            {data?.time_recall
              ? moment(data?.time_recall).format('DD/MM/YYYY hh:mm:ss')
              : 'Không'}
          </p>
        </Col>
        {data.isCanUpdate === 1 && (
          <Col md={5} className="text-right">
            <Button onClick={() => onSubmit()}>Cập nhật</Button>
          </Col>
        )}
      </Row>
      <Row className="mt-3">
        <Col md={6} sm={12} xs={12} style={styles.questionContainer}>
          <Row>
            <b>Trạng thái cuộc gọi (*)</b>
          </Row>
          {/* {callStatus?.length > 0 &&
            callStatus?.map((value, index) => (
              <Row className="mt-3" key={index}>
                <Col md={12} sm={12} xs={12} className="p-0">
                  <input
                    style={styles.answerRadioMargin}
                    type="radio"
                    name=""
                    value={value.id}
                    checked={value.id === data?.status}
                    onChange={(e) => console.log(e.target.value)}
                  />
                  <span>{value.name}</span>
                </Col>
              </Row>
            ))} */}
          <Row className="mt-3">
            <Radio.Group
              value={callStatusId}
              onChange={e =>
                data.isCanUpdate === 1 && onChangeStatusId(e.target.value)
              }
            >
              <Space direction="vertical">
                {callStatusFilter?.length > 0 &&
                  callStatusFilter?.map((value, index) => (
                    <Radio key={value.id} value={value.id}>
                      {value.name}
                    </Radio>
                  ))}
              </Space>
            </Radio.Group>
          </Row>
          {data.default_forms?.length > 0 &&
            data.default_forms?.map((value, index) => (
              <Col key={index} style={styles.questionContainer}>
                <Row>
                  <b>{value.name}</b>
                </Row>
                {value.type_input === 1 ? (
                  <Row>
                    {value?.text_result ? (
                      <Col
                        md={8}
                        className="mt-3"
                        style={{
                          border: '1px solid #979090d9',
                          backgroundColor: '#dbdbdc',
                          borderRadius: '5px',
                        }}
                      >
                        {value?.text_result}
                      </Col>
                    ) : (
                      <span className="text-muted">Không có dữ liệu</span>
                    )}
                  </Row>
                ) : value.type_input === 4 ? (
                  <Row>
                    <Col
                      md={8}
                      className="mt-3"
                      style={{
                        border: '1px solid #979090d9',
                        backgroundColor: '#dbdbdc',
                        borderRadius: '5px',
                      }}
                    >
                      {Intl.DateTimeFormat('vi-VN').format(
                        new Date(value?.text_result || null)
                      )}
                    </Col>
                  </Row>
                ) : (
                  value.job_form_answers.length > 0 && (
                    <Radio.Group
                      // disabled={
                      //   value.isUpdated === 1 && data.isCanUpdate === 1
                      //     ? false
                      //     : value.isUpdated !== 1 || data.isCanUpdate !== 1
                      //     ? true
                      //     : false
                      // }
                      value={
                        answerList.find(answer => answer.id === value.id)
                          ?.number_result[0] || ''
                      }
                      onChange={e =>
                        value.isUpdated === 1 &&
                        data.isCanUpdate === 1 &&
                        onChangeAnswerList(value, e.target.value)
                      }
                    >
                      {value.job_form_answers?.map((item, index) => (
                        <>
                          <Row className="mt-3" key={index}>
                            <Col md={12} sm={12} xs={12} className="p-0">
                              {/* <input
                            type="radio"
                            style={styles.answerRadioMargin}
                            name=""
                            value={item.id}
                            checked={value.number_result?.includes(item.id)}
                          />
                          <span>{item.name}</span> */}
                              {/* <Space direction="vertical">
                                {callStatus?.length > 0 &&
                                  callStatus?.map((value, index) => ( */}
                              <Radio key={item.id} value={item.id}>
                                {item.name}
                              </Radio>
                              {/* ))}
                              </Space> */}
                            </Col>
                          </Row>
                          <Row className="pl-4">
                            {answerList.find(answer => answer.id === value.id)
                              ?.number_result[0] === item.id &&
                              item?.job_form_answer_child?.length > 0 &&
                              item?.job_form_answer_child?.map((v, i) => (
                                <Col
                                  key={i}
                                  style={styles.childQuestionContainer}
                                >
                                  <Row>
                                    <b>{v.name}</b>
                                  </Row>
                                  {v.type_input === 1 ? (
                                    <Row>
                                      {v?.text_result ? (
                                        <Col
                                          md={7}
                                          className="mt-3"
                                          style={{
                                            border: '1px solid #979090d9',
                                            backgroundColor: '#dbdbdc',
                                            borderRadius: '5px',
                                          }}
                                        >
                                          {v?.text_result}
                                        </Col>
                                      ) : (
                                        <span className="text-muted">
                                          Không có dữ liệu
                                        </span>
                                      )}
                                    </Row>
                                  ) : v.type_input === 4 ? (
                                    <Row>
                                      <Col
                                        md={8}
                                        className="mt-3"
                                        style={{
                                          border: '1px solid #979090d9',
                                          backgroundColor: '#dbdbdc',
                                          borderRadius: '5px',
                                        }}
                                      >
                                        {Intl.DateTimeFormat('vi-VN').format(
                                          new Date(v?.text_result || null)
                                        )}
                                      </Col>
                                    </Row>
                                  ) : (
                                    v.job_form_answers?.length > 0 && (
                                      <Radio.Group
                                        // disabled={
                                        //   v.isUpdated === 1 && data.isCanUpdate === 1
                                        //     ? false
                                        //     : v.isUpdated !== 1 || data.isCanUpdate !== 1
                                        //     ? true
                                        //     : false
                                        // }
                                        value={
                                          answerList.find(
                                            answer => answer.id === v.id
                                          )?.number_result[0] || ''
                                        }
                                        onChange={e =>
                                          value.isUpdated === 1 &&
                                          data.isCanUpdate === 1 &&
                                          onChangeAnswerList(v, e.target.value)
                                        }
                                      >
                                        {v.job_form_answers?.map(
                                          (item, index) => (
                                            <>
                                              <Row className="mt-3" key={index}>
                                                <Col
                                                  // md={1} sm={1} xs={1}
                                                  className="p-0"
                                                >
                                                  {/* <input
                                                  style={styles.answerRadioMargin}
                                                  type="radio"
                                                  name=""
                                                  value={item.id}
                                                  checked={value.number_result?.includes(item?.id)}
                                                /> */}
                                                  <Radio
                                                    key={item.id}
                                                    value={item.id}
                                                  >
                                                    {item.name}
                                                  </Radio>
                                                </Col>
                                                {/* <Col md={11} sm={11} xs={11} className="p-0">
                                                {item.name}
                                              </Col> */}
                                              </Row>
                                            </>
                                          )
                                        )}
                                      </Radio.Group>
                                    )
                                  )}
                                </Col>
                              ))}
                          </Row>
                        </>
                      ))}
                    </Radio.Group>
                  )
                )}
              </Col>
            ))}
        </Col>
        <Col md={6} sm={12} xs={12}>
          {data.job_form_questions?.length > 0 &&
            data.job_form_questions?.map((value, index) => (
              <Col key={index} style={styles.questionContainer}>
                <Row>
                  <b>
                    {`Câu ${index + 1}: `}
                    {value.name} {value.is_require === 1 && ' (*)'}
                  </b>
                </Row>
                {renderQuestion(value)}
              </Col>

              // <Col style={styles.questionContainer}>
              //   <Row>
              //     <b>{value.name} (*)</b>
              //   </Row>
              //   {value.type_input === 1 ? (
              //     <Row>
              //       {value?.text_result ? (
              //         <Col
              //           md={8}
              //           className="mt-3"
              //           style={{
              //             border: '1px solid #979090d9',
              //             backgroundColor: '#dbdbdc',
              //             borderRadius: '5px',
              //           }}
              //         >
              //           {value?.text_result}
              //         </Col>
              //       ) : (
              //         <span className="text-muted">Không có dữ liệu</span>
              //       )}
              //     </Row>
              //   ) : value.type_input === 4 ? (
              //     <Row>
              //       <Col
              //         md={8}
              //         className="mt-3"
              //         style={{
              //           border: '1px solid #979090d9',
              //           backgroundColor: '#dbdbdc',
              //           borderRadius: '5px',
              //         }}
              //       >
              //         {Intl.DateTimeFormat('vi-VN').format(new Date(value?.text_result || null))}
              //       </Col>
              //     </Row>
              //   ) : (
              //     value.job_form_answers.length > 0 && (
              //       <Radio.Group
              //         // disabled={
              //         //   value.isUpdated === 1 && data.isCanUpdate === 1
              //         //     ? false
              //         //     : value.isUpdated !== 1 || data.isCanUpdate !== 1
              //         //     ? true
              //         //     : false
              //         // }
              //         value={answerList.find((answer) => answer.id === value.id)?.number_result[0] || ''}
              //         onChange={(e) =>
              //           value.isUpdated === 1 && data.isCanUpdate === 1 && onChangeAnswerList(value, e.target.value)
              //         }
              //       >
              //         {value.job_form_answers?.map((item, index) => (
              //           <>
              //             <Row className="mt-3" key={index}>
              //               <Col md={12} sm={12} xs={12} className="p-0">
              //                 {/* <input
              //                   style={styles.answerRadioMargin}
              //                   type="radio"
              //                   name=""
              //                   value={item.id}
              //                   checked={value.number_result?.includes(item?.id)}
              //                 />
              //                 <span>{item.name}</span> */}
              //                 <Radio key={item.id} value={item.id}>
              //                   {item.name}
              //                 </Radio>
              //               </Col>
              //             </Row>
              //             <Row className="pl-4">
              //               {answerList.find((answer) => answer.id === value.id)?.number_result[0] === item.id &&
              //                 item?.job_form_answer_child?.length > 0 &&
              //                 item?.job_form_answer_child?.map((v, i) => (
              //                   <Col style={styles.childQuestionContainer}>
              //                     <Row>
              //                       <b>{v.name}</b>
              //                     </Row>
              //                     {v.type_input == 1 ? (
              //                       <Row>
              //                         {v?.text_result ? (
              //                           <Col
              //                             md={7}
              //                             className="mt-3"
              //                             style={{
              //                               border: '1px solid #979090d9',
              //                               backgroundColor: '#dbdbdc',
              //                               borderRadius: '5px',
              //                             }}
              //                           >
              //                             {v?.text_result}
              //                           </Col>
              //                         ) : (
              //                           <span className="text-muted">Không có dữ liệu</span>
              //                         )}
              //                       </Row>
              //                     ) : v.type_input == 4 ? (
              //                       <Row>
              //                         <Col
              //                           md={8}
              //                           className="mt-3"
              //                           style={{
              //                             border: '1px solid #979090d9',
              //                             backgroundColor: '#dbdbdc',
              //                             borderRadius: '5px',
              //                           }}
              //                         >
              //                           {Intl.DateTimeFormat('vi-VN').format(new Date(v?.text_result || null))}
              //                         </Col>
              //                       </Row>
              //                     ) : (
              //                       v.job_form_answers?.length > 0 && (
              //                         <Radio.Group
              //                           // disabled={
              //                           //   v.isUpdated === 1 && data.isCanUpdate === 1
              //                           //     ? false
              //                           //     : v.isUpdated !== 1 || data.isCanUpdate !== 1
              //                           //     ? true
              //                           //     : false
              //                           // }
              //                           value={answerList.find((answer) => answer.id === v.id)?.number_result[0] || ''}
              //                           onChange={(e) =>
              //                             value.isUpdated === 1 &&
              //                             data.isCanUpdate === 1 &&
              //                             onChangeAnswerList(v, e.target.value)
              //                           }
              //                         >
              //                           {v.job_form_answers?.map((item, index) => (
              //                             <>
              //                               <Row className="mt-3" key={index}>
              //                                 <Col
              //                                   // md={1} sm={1} xs={1}
              //                                   className="p-0"
              //                                 >
              //                                   {/* <input
              //                                     style={styles.answerRadioMargin}
              //                                     type="radio"
              //                                     name=""
              //                                     value={item.id}
              //                                     checked={value.number_result?.includes(item?.id)}
              //                                   /> */}
              //                                   <Radio key={item.id} value={item.id}>
              //                                     {item.name}
              //                                   </Radio>
              //                                 </Col>
              //                                 {/* <Col md={11} sm={11} xs={11} className="p-0">
              //                                   {item.name}
              //                                 </Col> */}
              //                               </Row>
              //                             </>
              //                           ))}
              //                         </Radio.Group>
              //                       )
              //                     )}
              //                   </Col>
              //                 ))}
              //             </Row>
              //           </>
              //         ))}
              //       </Radio.Group>
              //     )
              //   )}
              // </Col>
            ))}
          <Col className="mt-3" style={styles.questionContainer}>
            <Row>
              <b>Ghi chú</b>
            </Row>
            <Row>
              <Col md={8} className="mt-3 p-0">
                <TextArea
                  disabled={data.isCanUpdate === 1 ? false : true}
                  placeholder="Ghi chú"
                  value={noteRecall}
                  onChange={e => onChangeNoteRecall(e.target.value)}
                />
              </Col>
            </Row>
          </Col>
          <Col className="mt-3" style={styles.questionContainer}>
            <Row>
              <b>Ghi chú cuộc gọi</b>
            </Row>
            <Row>
              <Col md={8} className="mt-3 p-0">
                <TextArea
                  disabled={data.isCanUpdate === 1 ? false : true}
                  placeholder="Ghi chú cuộc gọi"
                  value={noteCall}
                  onChange={e => onChangeNoteCall(e.target.value)}
                  showCount
                  maxLength={2000}
                />
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
    </>
  );
};

const CallRecord = props => {
  const { data } = props;

  return (
    <>
      <Row className="mt-2">
        <Col md={12}>
          <b>File ghi âm</b>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={6} xs={6} sm={6}>
          <audio controls autoplay className="audio-element">
            <source src={data?.content_audio_url}></source>
          </audio>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={12}>
          <b>Chuyển đổi văn bản</b>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={12} xs={12} sm={12}>
          <p>{data?.content_text || 'Không có dữ liệu'}</p>
        </Col>
      </Row>
    </>
  );
};

const Review = props => {
  const { data, callReviewData } = props;
  const callReview = data?.call_reviews || [];
  const callReviewConfigs = callReviewData;
  const reviewMappings = {};
  callReview.forEach(v => (reviewMappings[v.id] = v));

  return (
    <>
      {callReview?.length > 0 ? (
        (callReviewConfigs || callReview)?.map((value, index) => (
          <Row key={value.id}>
            <Col md={12} xs={12} sm={12}>
              <Row md={12} className="mt-2">
                <Col md={12} sm={12} xs={12}>
                  <input
                    type="checkbox"
                    name=""
                    value={value.id}
                    checked={reviewMappings[value.id]}
                    readonly
                    onChange={e =>
                      this.handleChangeFormAdd('options', e.target.value)
                    }
                  />
                  <span className="ml-2">{value.name}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        ))
      ) : (
        <Row className="mt-2">
          <Col md={12} sm={12} xs={12}>
            <p className="text-muted">Chưa có đánh giá</p>
          </Col>
        </Row>
      )}
    </>
  );
};

function CallDetail() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [tabInfo, setTabInfo] = useState(1);
  const [callReviewData, setCallReviewData] = useState([]);

  const [callStatusId, setCallStatusId] = useState('');
  const [timeRecall, setTimeRecall] = useState('');
  const [noteRecall, setNoteRecall] = useState('');
  const [noteCall, setNoteCall] = useState('');
  const [answerList, setAnswerList] = useState([]);

  const callStatus = useSelector(state => state.callStatusReducer.data);
  const [isError, setIsError] = React.useState(false);

  useEffect(() => {
    getData();
    getCallReview();
    dispatch(getListCallStatus());
  }, [id]);

  const getData = async () => {
    try {
      setIsError(false);
      setIsLoading(true);
      const res = await requestCallHistoryDetail({ id: id });
      setData(res.data);
      setCallStatusId(res.data.status || '');
      setTimeRecall(res.data.time_recall || '');
      setNoteRecall(res.data.note_recall || '');
      setNoteCall(res.data.call_note || '');

      const default_forms = res.data.default_forms?.length
        ? res.data.default_forms.map(item => {
            return {
              id: item.id,
              question: item.name,
              number_result: item.number_result.filter(result => result).length
                ? item.number_result.filter(result => result)
                : [],
            };
          })
        : [];

      const default_forms_child = [];

      const newArr1 = res.data.default_forms?.length
        ? res.data.default_forms.map(item => {
            item.job_form_answers.map(answer => {
              if (answer.job_form_answer_child.length) {
                answer.job_form_answer_child.map(childItem => {
                  default_forms_child.push({
                    id: childItem.id,
                    question: childItem.name,
                    number_result: childItem.number_result.filter(
                      result => result
                    ).length
                      ? childItem.number_result.filter(result => result)
                      : [],
                  });
                });
              }
            });
          })
        : [];

      const job_form_questions = res.data.job_form_questions?.length
        ? res.data.job_form_questions.map(item => {
            return {
              id: item.id,
              question: item.name,
              number_result: item.number_result.filter(result => result).length
                ? item.number_result.filter(result => result)
                : [],
              text_result: item.text_result,
            };
          })
        : [];

      const job_form_questions_child = [];

      const newArr2 = res.data.job_form_questions?.length
        ? res.data.job_form_questions.map(item => {
            item.job_form_answers.map(answer => {
              if (answer.job_form_answer_child.length) {
                answer.job_form_answer_child.map(childItem => {
                  job_form_questions_child.push({
                    id: childItem.id,
                    question: childItem.name,
                    number_result: childItem.number_result.filter(
                      result => result
                    ).length
                      ? childItem.number_result.filter(result => result)
                      : [],
                    text_result: childItem.text_result,
                  });
                });
              }
            });
          })
        : [];

      setAnswerList(
        default_forms.concat(
          default_forms_child,
          job_form_questions,
          job_form_questions_child
        )
      );

      setIsLoading(false);
    } catch (error) {
      console.log('Error getData CallDetail: ', { error });
      setIsError(true);
      setIsLoading(false);
    }
  };

  const getCallReview = async () => {
    try {
      const res = await requestCallReviewConfig({ call_id: id });
      setCallReviewData(res.data);
    } catch (err) {
      notifyFail(err.msg);
    }
  };

  // CallInfo funcs

  // WorkTabs funcs
  const handleSelectTab = value => {
    setTabInfo(value);
  };

  const handleChangeStatusId = value => {
    setCallStatusId(value);
  };

  const handleChangeNoteRecall = value => {
    setNoteRecall(value);
  };

  const handleChangeNoteCall = value => {
    setNoteCall(value);
  };

  const handleChangeAnswerList = (quest, answer) => {
    // const newAnswers = [...answerList];
    // const newArr = newAnswers.map((item) => {
    //   if (item.id === quest.id) {
    //     return {
    //       ...item,
    //       number_result: [answerId],
    //     };
    //   } else {
    //     return item;
    //   }
    // });
    // setAnswerList(newArr);

    if (answerList.map(item => item.id).includes(quest.id)) {
      //
      let cloneConvertedAnswerList = JSON.parse(JSON.stringify(answerList));
      // Xóa tất cả các câu hỏi con đã chọn trước đó nếu câu hỏi là câu hỏi chọn 1 đáp án
      if (quest.type_input === 2 || quest.type_input === 5) {
        const listChildID = quest.job_form_answers
          .filter(formAnswer => formAnswer.id !== answer)
          .map(formAnswers =>
            formAnswers.job_form_answer_child?.map(
              childAnswer => childAnswer.id
            )
          );

        cloneConvertedAnswerList = cloneConvertedAnswerList.filter(
          cloneAnswer => {
            return !listChildID.map(ids => ids?.includes(cloneAnswer.id))[0];
          }
        );
      }

      cloneConvertedAnswerList = cloneConvertedAnswerList.map(
        convertedAnswer => {
          if (convertedAnswer.id === quest.id) {
            switch (quest.type_input) {
              case 1:
              case 4:
                return {
                  ...convertedAnswer,
                  text_result: answer,
                };
              case 3:
                return {
                  ...convertedAnswer,
                  number_result: answer,
                };
              default:
                return {
                  ...convertedAnswer,
                  number_result: [answer],
                };
            }
          } else {
            return convertedAnswer;
          }
        }
      );
      setAnswerList(cloneConvertedAnswerList);
    } else {
      let newConvertedAnswer = {};
      switch (quest.type_input) {
        case 1:
        case 4:
          newConvertedAnswer = {
            id: quest.id,
            question: quest.name,
            text_result: answer,
          };
          break;
        case 3:
          newConvertedAnswer = {
            id: quest.id,
            question: quest.name,
            number_result: answer,
          };
          break;
        default:
          newConvertedAnswer = {
            id: quest.id,
            question: quest.name,
            number_result: [answer],
          };
          break;
      }
      setAnswerList(prev => [...prev, newConvertedAnswer]);
    }
  };

  const handleSubmit = async () => {
    if (!callStatusId) {
      notifyFail('Vui lòng chọn trạng thái cuộc gọi!');
      return;
    }

    const newArr = answerList.length
      ? answerList.map(item => {
          if (!item.number_result.length) {
            return {
              ...item,
            };
          } else {
            return item;
          }
        })
      : [];

    try {
      const payload = {
        call_id: id,
        call_status_id: callStatusId || null,
        time_recall: timeRecall || null,
        note_recall: noteRecall || '',
        form: newArr,
        call_note: noteCall || '',
      };
      const res = await requestUpdateCall(payload);
      notifySuccess('Cập nhật thành công!');
      getData();
    } catch (error) {
      notifyFail(error.msg);
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <div className="content-wrapper">
        <div className="content-header">
          {isError ? (
            <Empty description={<>Không có dữ liệu cuộc gọi!</>} />
          ) : (
            <>
              <CallInfo data={data} />
              <WorkTabs
                data={data}
                callStatus={callStatus}
                callReviewData={callReviewData}
                tabInfo={tabInfo}
                onSelectTab={value => handleSelectTab(value)}
                callStatusId={callStatusId}
                onChangeStatusId={value => handleChangeStatusId(value)}
                noteRecall={noteRecall}
                noteCall={noteCall}
                onChangeNoteRecall={value => handleChangeNoteRecall(value)}
                onChangeNoteCall={value => handleChangeNoteCall(value)}
                answerList={answerList}
                onChangeAnswerList={(quest, value) =>
                  handleChangeAnswerList(quest, value)
                }
                onSubmit={() => handleSubmit()}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default CallDetail;
