import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

export default function SpinInButton() {
  return (
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: 16, color: 'white', lineHeight: '100%' }} spin />}
      style={{ marginRight: '10px' }}
    />
  );
}
