import { Button, Form, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  ContentWrapper,
  FlexContainerItem,
  ModalStyleTemplate,
  TitleModalTemplate,
  clearSelected,
} from './styleModalZalo';

import { ApiClient } from '@constants/Api';
import { notifySuccess } from '@utils/notify';

const ContentItem = ({ text, selected, onClick }) => {
  return (
    <ContentWrapper selected={selected} onClick={onClick}>
      {text}
    </ContentWrapper>
  );
};

const ListTags = ({ listItem, handleClickItem, valueTextArea }) => {
  return (
    <div className="list">
      {listItem.map((item, index) => {
        return (
          <ContentItem
            text={item.value}
            key={item.id}
            selected={item.selected && valueTextArea?.includes(item.value)}
            onClick={() => handleClickItem(item, index)}
          />
        );
      })}
    </div>
  );
};

const ModalTemplateMsg = ({
  isVisible,
  setIsShowModalTemplate,
  isCreateMsgSuccess,
  setIsCreateMsgSuccess,
}) => {
  const [form] = Form.useForm();
  const [listItem, setListItem] = useState([]);
  const contentMsgValue = Form.useWatch('content', form);
  useEffect(() => {
    async function getListOptions() {
      //list template óptions
      let res = await ApiClient.get('sale/messOptions');
      if (res.status) {
        setListItem(res.data);
      }
    }
    getListOptions();
  }, []);

  function handleClose() {
    setIsShowModalTemplate(false);
    form.resetFields();
    console.log('listItem', listItem);
    setListItem(clearSelected([...listItem]));
  }
  async function handleSubmit(value) {
    console.log('valueSubmit', value);
    let res = await ApiClient.post('sale/createMessage', value);
    if (res?.status) {
      form.resetFields();
      setListItem(clearSelected([...listItem]));
      setIsCreateMsgSuccess(!isCreateMsgSuccess);
      notifySuccess('Tạo mẫu tin nhắn thành công');
      setIsShowModalTemplate(false);
    }
  }

  const handleClickItem = (item, index) => {
    console.log('item', item, index);
    let listItemCopy = [...listItem];
    let indexItem = listItemCopy.findIndex(
      itemInList => item.id === itemInList.id
    );
    if (indexItem || indexItem === 0) {
      listItemCopy[indexItem].selected = !listItem[indexItem].selected; //toggle background
      if (listItemCopy[indexItem].selected) {
        //thêm vào chuỗi
        form.setFieldsValue({
          content: `${contentMsgValue || ''}${item.value}`,
        });
      } else {
        //xóa khỏi chuỗi
        if (contentMsgValue) {
          form.setFieldsValue({
            content: `${contentMsgValue.replaceAll(item.value, '')}`,
          });
        }
      }
    }
    setListItem(listItemCopy);

    // setListItem(listItemNew);
  };
  return (
    <ModalStyleTemplate
      visible={isVisible}
      title={<TitleModalTemplate onCloseModal={handleClose} />}
      footer={null}
      closable={false}
      destroyOnClose
    >
      <Form
        layout={'vertical'}
        form={form}
        style={{ width: '100%' }}
        onFinish={handleSubmit}
        // initialValues={{ layout: formLayout }}
        // onValuesChange={onFormLayoutChange}
      >
        <Form.Item
          label="Tên tin nhắn"
          name="name"
          rules={[
            { required: true, message: 'Vui lòng nhập tên tin nhắn' },
            { max: 256, message: 'Tên tin nhắn chỉ cho phép tối đa 256 ký tự' },
            {
              validator: (_, value) => {
                if (!value || !value.trim()) {
                  return Promise.reject(
                    'Không cho phép nhập toàn khoảng trắng.'
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder="Nhập tên tin nhắn" />
        </Form.Item>
        <Form.Item
          label="Nội dung tin nhắn"
          name="content"
          rules={[
            { required: true, message: 'Vui lòng nhập nội dung tin nhắn' },
            {
              max: 1000,
              message: 'Tên tin nhắn chỉ cho phép tối đa 1000 ký tự',
            },
            {
              validator: (_, value) => {
                if (!value || !value.trim()) {
                  return Promise.reject(
                    'Không cho phép nhập toàn khoảng trắng.'
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <FlexContainerItem>
            <ListTags listItem={listItem} handleClickItem={handleClickItem} valueTextArea = {form.getFieldValue('content')}/>

            <Input.TextArea
              showCount
              placeholder="Nhập nội dung tin nhắn"
              rows={5}
              value={form.getFieldValue('content')}
            />
          </FlexContainerItem>
        </Form.Item>
        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <Button
            htmlType="submit"
            style={{
              background: '#ef8737',
              color: 'white',
              minWidth: '100px',
              border: '#ef8737',
              marginTop: '10px',
            }}
          >
            Xác nhận
          </Button>
        </div>
      </Form>
    </ModalStyleTemplate>
  );
};

export default ModalTemplateMsg;
