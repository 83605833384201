import { requestGetLog } from '@constants/Api';
import { ROUTER, STRING, STRINGEE_ERROR } from '@constants/Constant';
import { Empty } from 'antd';
import moment from 'moment';
import React from 'react';
import { Col, FormControl, Row, Table } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import DatePickerCustom from '../../../components/DatePickerCustom';

const EnterpriseNameStyle = styled.span`
  font-weight: 600;
  :hover {
    color: blue;
  }
`;

class CallLogScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tagLog: [],
      modalLog: {
        name: '',
        point: '',
      },
      search: '',
      officeName: '',
      activePage: 1,
      [STRING.fromDate]: '',
      [STRING.toDate]: '',
      limit: 0,
      count: 0,
      codeError: '',
    };
  }

  componentDidMount() {
    this.rederlog();
  }
  renderTableTag() {
    const { tagLog, limit, activePage } = this.state;
    // console.log(tag, 'tag');
    return (
      <tbody>
        {tagLog.length > 0 ? (
          tagLog?.map((value, index) => (
            <tr>
              <td>{index + 1 + limit * (activePage - 1) || '--'}</td>
              <td>{value?.customer_name + `(${value?.customer_phone})`}</td>
              <td>{value?.telesale_name || '--'}</td>
              <td
                onClick={() => {
                  this.props.history.push(ROUTER.COMPANY_DETAIL + `/${value.enterprise_id}`);
                }}
              >
                <EnterpriseNameStyle>{value?.enterprise_name}</EnterpriseNameStyle>
              </td>
              <td>{value?.start_call_time ? moment(value?.start_call_time).format('HH:mm DD-MM-YYYY') : ''}</td>
              <td>{value?.dataJson?.endCallCause}</td>
            </tr>
          ))
        ) : (
          <td colSpan="4">
            <Empty description="Không có dữ liệu"></Empty>
          </td>
        )}
      </tbody>
    );
  }

  renderFilter() {
    const {
      search,
      officeName,
      [STRING.fromDate]: fromDate,
      [STRING.toDate]: toDate,
      activePage,
      codeError,
    } = this.state;
    return (
      <div className="w-100">
        <Row>
          <Col md={2} sm={6}>
            <FormControl
              onKeyPress={this.handleKeyPress}
              type="text"
              id=""
              placeholder={'Tên, SĐT khách hàng hoặc nội dung mã lỗi'}
              value={search}
              onChange={(e) => this.handleChange('search', e.target.value)}
            />
          </Col>

          <Col md={2} sm={6}>
            <FormControl
              onKeyPress={this.handleKeyPress}
              type="text"
              id=""
              placeholder={'Tên công ty'}
              value={officeName}
              onChange={(e) => this.handleChange('officeName', e.target.value)}
            />
          </Col>

          <Col md={2} sm={6}>
            <DatePickerCustom
              className={`date-picker form-control`}
              placeholderText={STRING.fromDate}
              handleChange={this.handleChangeDateTime}
              selected={fromDate}
              maxDate={new Date(toDate)}
              dateFormat="dd/MM/yyyy"
            />
          </Col>
          <Col md={2} sm={6}>
            <DatePickerCustom
              className={`date-picker form-control`}
              placeholderText={STRING.toDate}
              handleChange={this.handleChangeDateTime}
              selected={toDate}
              minDate={new Date(fromDate)}
              dateFormat="dd/MM/yyyy"
            />
          </Col>
          <Col md={2} sm={4}>
            <FormControl
              as="select"
              id=""
              value={codeError}
              onChange={(e) => this.handleChangeDateTime('codeError', e.target.value)}
            >
              <option value="">Mã lỗi</option>
              {STRINGEE_ERROR?.length > 0 &&
                STRINGEE_ERROR?.map((value, index) => <option value={value}>{value}</option>)}
            </FormControl>
          </Col>
        </Row>
      </div>
    );
  }
  renderTitle() {
    return (
      <>
        <Row>
          <Col sm={12}>
            <h2 className="text-left" style={{ marginBottom: '10px' }}>
              {' '}
              Tra cứ log
            </h2>
            <div class="line"></div>
          </Col>
        </Row>
      </>
    );
  }
  render() {
    return (
      <div className="content-wrapper">
        <div className="content-header">
          <>
            <Col md className="bg">
              {this.renderTitle()}
              {this.renderFilter()}
            </Col>
            <Col sm={12} md={12}>
              <div className="row">
                <Col
                  sm={10}
                  md={12}
                  style={{
                    padding: '0px',
                    marginTop: '20px',
                  }}
                >
                  <Row className="table-wrap mx-4">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>STT</th>
                          <th>Tên khách hàng (SĐT)</th>
                          <th>Sale thực hiện</th>
                          <th>Công ty</th>
                          <th>Thời gian gọi</th>
                          <th>Nội dung mã lỗi</th>
                        </tr>
                      </thead>
                      {this.renderTableTag()}
                    </Table>
                  </Row>
                  <Row>
                    <Col md={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      {this.renderPagination()}
                    </Col>
                  </Row>
                </Col>
              </div>
            </Col>
          </>
        </div>
      </div>
    );
  }

  renderPagination = () => {
    const { activePage, count, limit } = this.state;
    return (
      <Pagination
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled
        activePage={activePage}
        itemsCountPerPage={limit}
        totalItemsCount={count}
        pageRangeDisplayed={5}
        hideNavigation
        onChange={(page) => {
          this.setState({ activePage: page }, function () {
            this.searchlog();
          });
        }}
      />
    );
  };
  handleChange = (field, value) => {
    this.setState({
      [field]: value || '',
    });
  };
  handleKeyPress = (e) => {
    if (e.charCode === 13) {
      this.setState(
        {
          activePage: 1,
        },
        function () {
          this.searchlog();
        }
      );
    }
  };
  handleChangeDateTime = (field, value) => {
    this.setState(
      {
        [field]: value || '',
        activePage: 1,
      },
      function () {
        this.searchlog();
      }
    );
  };

  async searchlog() {
    console.log(this.state);
    const {
      search,
      officeName,
      [STRING.fromDate]: fromDate,
      [STRING.toDate]: toDate,
      activePage,
      codeError,
    } = this.state;
    const res = await requestGetLog({
      page: activePage,
      search: search.trim(),
      officeName: officeName.trim(),
      fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : '',
      toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : '',
      codeError,
    });
    console.log(res);
    this.setState({
      tagLog: res?.data,
      search,
      [STRING.fromDate]: fromDate,
      [STRING.toDate]: toDate,
      limit: res?.paging?.limit,
      count: res?.paging?.count,
    });
  }
  async rederlog() {
    const res = await requestGetLog({ page: 1 });
    this.setState({
      tagLog: res?.data,
      limit: res?.paging?.limit,
      count: res?.paging?.count,
    });
  }
}

export default withRouter(CallLogScreen);
