import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, FormControl, Button, Nav, Table } from 'react-bootstrap';
import { ROUTER, TYPE_MEDIA, USER_ROLE, STRING } from '@constants/Constant';
import { connect } from 'react-redux';
import reactotron from 'reactotron-react-js';
import DatePickerCustom from '../../../../components/DatePickerCustom';
import TableNullData from '@components/TableNullData';
import { getCallHistory, getJobFilter } from '@src/redux/actions';
import moment from 'moment';
import { Empty, Switch } from 'antd';

class CallLate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        job_id: '',
        [STRING.fromDate]: '',
        [STRING.toDate]: '',
        job_category_id: '',
      },
      activePage: 1,
    };
  }

  componentDidMount() {
    this.getData({ page: 1 });
    this.props.getJobFilter();
  }

  getData({
    page,
    call_type,
    job_id,
    call_status,
    telesale_id,
    start_call_time,
    limit = 1000,
    fromDate,
    toDate,
    is_late = 1,
    job_category_id,
    job_form_question_id,
    job_form_answers_id,
    call_review_config_id,
    keyword,
    search,
  }) {
    const { id } = this.props.match?.params;
    this.props.getCallHistory({
      page: page || '',
      call_type: call_type || '',
      job_id: job_id || '',
      call_status: call_status || '',
      telesale_id: id || '',
      start_call_time: start_call_time || '',
      limit: limit || '',
      fromDate: fromDate || '',
      toDate: toDate || '',
      is_late: is_late,
      job_category_id: job_category_id || '',
      job_form_question_id: job_form_question_id || '',
      job_form_answers_id: job_form_answers_id || '',
      call_review_config_id: call_review_config_id || '',
      keyword: keyword || '',
      search: search || '',
    });
  }

  handleChange = async (field, value) => {
    this.setState(
      {
        ...this.state,
        form: {
          ...this.state.form,
          [field]: value,
        },
      },
      () => {
        if (field !== 'search') {
          const {
            page,
            job_id,
            [STRING.fromDate]: fromDate,
            [STRING.toDate]: toDate,
            is_late = 1,
            limit,
            job_category_id,
          } = this.state.form;
          const { id } = this.props.match?.params;
          this.getData({
            page: page || '',
            job_id: job_id || '',
            fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : '',
            toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : '',
            is_late: is_late,
            limit: limit || '',
            job_category_id: job_category_id || '',
            telesale_id: id || '',
          });
        }
      }
    );
  };

  renderField() {
    const { job_id, [STRING.fromDate]: fromDate, [STRING.toDate]: toDate } = this.state.form;
    const job = this.props.subJobState?.data;
    return (
      <div className="w-100">
        <Row>
          <Col md={6} sm={12} className="pl-0 pr-0 pr-md-2">
            <FormControl as="select" id="" value={job_id} onChange={(e) => this.handleChange('job_id', e.target.value)}>
              <option value="">Công việc</option>
              {job?.length > 0 && job?.map((value, index) => <option value={value.id}>{value.name}</option>)}
            </FormControl>
          </Col>
          <Col md={3} sm={12}>
            <DatePickerCustom
              className={`date-picker form-control`}
              placeholderText={STRING.fromDate}
              handleChange={this.handleChange}
              selected={fromDate}
              maxDate={new Date(toDate)}
              dateFormat="dd/MM/yyyy"
            />
          </Col>
          <Col md={3} sm={12}>
            <DatePickerCustom
              className={`date-picker form-control`}
              placeholderText={STRING.toDate}
              handleChange={this.handleChange}
              selected={toDate}
              minDate={new Date(fromDate)}
              dateFormat="dd/MM/yyyy"
            />
          </Col>
        </Row>
      </div>
    );
  }

  renderTable() {
    const callHistory = this.props.callHistoryState?.data;
    const itemsCount = this.props.callHistoryState?.paging;

    return (
      <>
        <div className="w-100 table-wrap" style={{ padding: '0px 15px' }}>
          <span className="pb-2 d-inline-block">
            Kết quả lọc: <b>{itemsCount?.totalItemCount}</b>
          </span>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>STT</th>
                <th>Tên khách hàng</th>
                <th>Số điện thoại</th>
                <th>Công việc</th>
                <th>Lịch hẹn</th>
                <th>Thời gian tư vấn</th>
              </tr>
            </thead>
            <tbody>
              {callHistory && callHistory.length ? (
                callHistory?.map((call, index) =>
                  this.renderTableRow(call, index + itemsCount.limit * (this.state.activePage - 1) + 1)
                )
              ) : (
                <td colspan="6">
                  <Empty description={<span>Không có dữ liệu</span>} />
                </td>
              )}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
  renderTableRow(call, index) {
    return (
      <tr>
        <td>{index}</td>
        <td>{call.customer_name || '###'}</td>
        <td>{call.customer_phone || '###'}</td>
        <td>{call.job_name || '###'}</td>
        <td>{call?.time_recall ? moment(call?.time_recall).format('HH:mm DD-MM-YYYY') : '###'}</td>
        <td>{call?.start_call_time ? moment(call?.start_call_time).format('HH:mm DD-MM-YYYY') : '###'}</td>
      </tr>
    );
  }

  render() {
    return (
      <>
        <Row>
          <Col>{this.renderField()}</Col>
        </Row>
        <hr></hr>
        <Row>
          <Col>{this.renderTable()}</Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  callHistoryState: state.callHistoryReducer,
  subJobState: state.jobFilterReducer,
});

const mapDispatchToProps = {
  getCallHistory,
  getJobFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CallLate));
