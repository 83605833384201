import { put, takeEvery, call, takeLatest } from 'redux-saga/effects';
import {
  GET_ACCOUNT,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_FAIL,
  GET_PROVINCE,
  GET_PROVINCE_SUCCESS,
  GET_PROVINCE_FAIL,
  GET_REGISTER,
  GET_REGISTER_SUCCESS,
  GET_REGISTER_FAIL,
  GET_REJECTED_REGISTER,
  GET_REJECTED_REGISTER_SUCCESS,
  GET_REJECTED_REGISTER_FAIL,
  GET_TELESALE,
  GET_TELESALE_SUCCESS,
  GET_TELESALE_FAIL,
  GET_TELESALE_INACTIVE,
  GET_TELESALE_INACTIVE_SUCCESS,
  GET_TELESALE_INACTIVE_FAIL,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  GET_COMPANY,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAIL,
  GET_CATEGORY,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAIL,
  GET_HOTLINE,
  GET_HOTLINE_SUCCESS,
  GET_HOTLINE_FAIL,
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAIL,
  GET_CUSTOMER_GROUP,
  GET_CUSTOMER_GROUP_SUCCESS,
  GET_CUSTOMER_GROUP_FAIL,
  GET_REVIEW,
  GET_REVIEW_SUCCESS,
  GET_REVIEW_FAIL,
  GET_JOB,
  GET_JOB_SUCCESS,
  GET_JOB_FAIL,
  GET_JOB_DETAIL,
  GET_JOB_DETAIL_SUCCESS,
  GET_JOB_DETAIL_FAIL,
  GET_CATEGORY_FILTER_SUCCESS,
  GET_CATEGORY_FILTER_FAIL,
  GET_CATEGORY_FILTER,
  GET_CUSTOMER_JOB,
  GET_CUSTOMER_JOB_SUCCESS,
  GET_CUSTOMER_JOB_FAIL,
  GET_CUSTOMER_GROUP_JOB,
  GET_CUSTOMER_GROUP_JOB_SUCCESS,
  GET_CUSTOMER_GROUP_JOB_FAIL,
  GET_CUSTOMER_GROUP_STATUS,
  GET_CUSTOMER_GROUP_STATUS_SUCCESS,
  GET_CUSTOMER_GROUP_STATUS_FAIL,
  GET_CALL_HISTORY,
  GET_CALL_HISTORY_SUCCESS,
  GET_CALL_HISTORY_FAIL,
  GET_TYPE_JOB,
  GET_TYPE_JOB_SUCCESS,
  GET_TYPE_JOB_FAIL,
  GET_LIST_TUTORIAL,
  GET_LIST_TUTORIAL_SUCCESS,
  GET_LIST_TUTORIAL_FAIL,
  GET_CALL_STATUS,
  GET_CALL_STATUS_SUCCESS,
  GET_CALL_STATUS_FAIL,
  GET_LIST_REVIEWER,
  GET_LIST_REVIEWER_SUCCESS,
  GET_LIST_REVIEWER_FAIL,
  GET_TASK_REVIEWER,
  GET_TASK_REVIEWER_SUCCESS,
  GET_TASK_REVIEWER_FAIL,
  GET_GENERAL_QUESTION,
  GET_GENERAL_QUESTION_SUCCESS,
  GET_GENERAL_QUESTION_FAIL,
  GET_KEYWORD,
  GET_KEYWORD_SUCCESS,
  GET_KEYWORD_FAIL,
  GET_VPOINT,
  GET_VPOINT_SUCCESS,
  GET_VPOINT_FAIL,
  GET_LIST_CALL,
  GET_LIST_CALL_SUCCESS,
  GET_LIST_CALL_FAIL,
  GET_OCCASION,
  GET_OCCASION_SUCCESS,
  GET_OCCASION_FAIL,
  GET_RESULT_REVIEWER,
  GET_RESULT_REVIEWER_SUCCESS,
  GET_RESULT_REVIEWER_FAIL,
  GET_JOB_CATEGORY_FILTER,
  GET_JOB_CATEGORY_FILTER_SUCCESS,
  GET_JOB_CATEGORY_FILTER_FAIL,
  GET_JOB_FILTER,
  GET_JOB_FILTER_SUCCESS,
  GET_JOB_FILTER_FAIL,
  GET_ENTERPRISES_FILTER,
  GET_ENTERPRISES_FILTER_SUCCESS,
  GET_ENTERPRISES_FILTER_FAIL,
  GET_TELESALE_FILTER,
  GET_TELESALE_FILTER_FAIL,
  GET_TELESALE_FILTER_SUCCESS,
  GET_LIST_FOR_SALE,
  GET_LIST_FOR_SALE_SUCCESS,
  GET_LIST_FOR_SALE_FAIL,
  GET_QUESTION_ANSWER_FILTER,
  GET_QUESTION_ANSWER_FILTER_FAIL,
  GET_QUESTION_ANSWER_FILTER_SUCCESS,
  GET_TELESALE_KPI,
  GET_TELESALE_KPI_FAIL,
  GET_TELESALE_KPI_SUCCESS,
  GET_USER_STATISTIC,
  GET_USER_STATISTIC_FAIL,
  GET_USER_STATISTIC_SUCCESS,
  GET_CALL_STATISTIC,
  GET_CALL_STATISTIC_FAIL,
  GET_CALL_STATISTIC_SUCCESS,
  GET_JOB_STATISTIC,
  GET_JOB_STATISTIC_FAIL,
  GET_JOB_STATISTIC_SUCCESS,
  GET_JOB_SALE_STATISTIC,
  GET_JOB_SALE_STATISTIC_FAIL,
  GET_JOB_SALE_STATISTIC_SUCCESS,
  GET_LIST_PACKAGE,
  GET_LIST_PACKAGE_SUCCESS,
  GET_LIST_PACKAGE_FAIL,
  GET_LIST_HOTLINE,
  GET_LIST_HOTLINE_SUCCESS,
  GET_LIST_HOTLINE_FAIL,
  GET_PACKAGE_HISTORY,
  GET_PACKAGE_HISTORY_FAIL,
  GET_PACKAGE_HISTORY_SUCCESS,
  GET_LISTHOTLINE_NEW,
  GET_LISTHOTLINE_NEW_SUCCESS,
} from '../actions/type';
import * as API from '../../constants/Api';

//********************************|  FUNCTION  |*************************************//

// WEB ADMIN--------------------------------------------------------------------------------------------
//**************************** ACCOUNT ******************************/
// GET LIST
export function* getAccountList(action) {
  try {
    const response = yield call(API.requestAccountList, action.payload);
    yield put({ type: GET_ACCOUNT_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_ACCOUNT_FAIL, payload: err });
  }
}

//**************************** TELESALE ******************************/
// GET LIST
export function* getTelesaleList(action) {
  try {
    const response = yield call(API.requestSaleList, action.payload);
    yield put({ type: GET_TELESALE_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_TELESALE_FAIL, payload: err });
  }
}

//*********************** TELESALE INACTIVE ****************************/
// GET LIST
export function* getTelesaleInactiveList(action) {
  try {
    const response = yield call(API.requestSaleInactiveList, action.payload);
    yield put({ type: GET_TELESALE_INACTIVE_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_TELESALE_INACTIVE_FAIL, payload: err });
  }
}

//**************************** PROVINCE ******************************/
// GET LIST
export function* getProvinceList(action) {
  try {
    const response = yield call(API.requestProvinceList, action.payload);
    yield put({ type: GET_PROVINCE_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_PROVINCE_FAIL, payload: err });
  }
}

//**************************** REGISTER ******************************/
// GET LIST
export function* getRegisterList(action) {
  try {
    const response = yield call(API.requestRegisterList, action.payload);
    yield put({ type: GET_REGISTER_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_REGISTER_FAIL, payload: err });
  }
}

//************************ REJECTED REGISTER **************************/
// GET LIST
export function* getRejectedRegisterList(action) {
  try {
    const response = yield call(
      API.requestRejectedRegisterList,
      action.payload
    );
    yield put({ type: GET_REJECTED_REGISTER_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_REJECTED_REGISTER_FAIL, payload: err });
  }
}

//***************************** USER ******************************/
// GET INFO
export function* getUserInfo() {
  try {
    const response = yield call(API.requestGetUserInfo);
    yield put({ type: GET_USER_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_USER_FAIL, payload: err });
  }
}

// GET LIST COMPANY
export function* getCompanyList(action) {
  try {
    const response = yield call(API.requestCompanyList, action.payload);
    yield put({ type: GET_COMPANY_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_COMPANY_FAIL, payload: err });
  }
}

export function* getListHotline(action) {
  try {
    const response = yield call(API.requestGetListHotline, action.payload);
    yield put({ type: GET_LIST_HOTLINE_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_LIST_HOTLINE_FAIL, payload: err });
  }
}

// WEB ENTERPRISE--------------------------------------------------------------------------------------------

// GET LIST CATEGORY
export function* getSubJobList(action) {
  try {
    const response = yield call(API.requestSubJobList, action.payload);
    yield put({ type: GET_CATEGORY_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_CATEGORY_FAIL, payload: err });
  }
}

// GET LIST CATEGORY FILTER
export function* getCategoryFilter(action) {
  try {
    const response = yield call(API.requestCategoryFilterList, action.payload);
    yield put({ type: GET_CATEGORY_FILTER_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_CATEGORY_FILTER_FAIL, payload: err });
  }
}

// GET LIST HOTLINE
export function* getHotlineList(action) {
  try {
    const response = yield call(API.requestHotlineList, action.payload);
    yield put({ type: GET_HOTLINE_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_HOTLINE_FAIL, payload: err });
  }
}

// GET LIST CUSTOMER
export function* getCustomerList(action) {
  try {
    const response = yield call(API.requestCustomerList, action.payload);
    yield put({ type: GET_CUSTOMER_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_CUSTOMER_FAIL, payload: err });
  }
}

// GET LIST CUSTOMER GROUP
export function* getCustomerGroupList(action) {
  try {
    const response = yield call(API.requestCustomerGroupList, action.payload);
    yield put({ type: GET_CUSTOMER_GROUP_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_CUSTOMER_GROUP_FAIL, payload: err });
  }
}

// GET LIST REVIEW
export function* getReviewList(action) {
  try {
    const response = yield call(API.requestReviewList, action.payload);
    yield put({ type: GET_REVIEW_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_REVIEW_FAIL, payload: err });
  }
}

// GET LIST JOB
export function* getJobList(action) {
  try {
    const response = yield call(API.requestJobList, action.payload);
    yield put({ type: GET_JOB_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_JOB_FAIL, payload: err });
  }
}

// GET JOB DETAIL
export function* getJobDetail(action) {
  try {
    const response = yield call(API.requestJobDetail, action.payload);
    yield put({ type: GET_JOB_DETAIL_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_JOB_DETAIL_FAIL, payload: err });
  }
}

// GET LIST CUSTOMER
export function* getCustomerJobList(action) {
  try {
    const response = yield call(API.requestCustomerJobList, action.payload);
    yield put({ type: GET_CUSTOMER_JOB_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_CUSTOMER_JOB_FAIL, payload: err });
  }
}

// GET LIST CUSTOMER GROUP
export function* getCustomerGroupJobList(action) {
  try {
    const response = yield call(
      API.requestGroupCustomerFilterList,
      action.payload
    );
    yield put({ type: GET_CUSTOMER_GROUP_JOB_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_CUSTOMER_GROUP_JOB_FAIL, payload: err });
  }
}

// GET CUSTOMER GROUP STATUS
export function* getCustomerGroupStatus(action) {
  try {
    const response = yield call(API.requestJobCustomerStatus, action.payload);
    yield put({
      type: GET_CUSTOMER_GROUP_STATUS_SUCCESS,
      payload: { response },
    });
  } catch (err) {
    yield put({ type: GET_CUSTOMER_GROUP_STATUS_FAIL, payload: err });
  }
}

// GET CALL HISTORY
export function* getCallHistory(action) {
  try {
    const response = yield call(API.requestCallList, action.payload);
    yield put({ type: GET_CALL_HISTORY_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_CALL_HISTORY_FAIL, payload: err });
  }
}

// GET TYPE JOB
export function* getTypeJob(action) {
  try {
    const response = yield call(API.requestTypeJobList, action.payload);
    yield put({ type: GET_TYPE_JOB_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_TYPE_JOB_FAIL, payload: err });
  }
}

//GET LIST TUTORIAL
export function* getListTutorial(action) {
  try {
    const response = yield call(API.getListTutorial, action.payload);
    yield put({ type: GET_LIST_TUTORIAL_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_LIST_TUTORIAL_FAIL, payload: err });
  }
}

//GET LIST CALL STATUS
export function* getListCallStatus(action) {
  try {
    const response = yield call(API.requestGetCallStatus, action.payload);
    yield put({ type: GET_CALL_STATUS_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_CALL_STATUS_FAIL, payload: err });
  }
}
//GET LIST REVIEWER
export function* getReviewerList(action) {
  try {
    const response = yield call(API.requestGetReviewerList, action.payload);
    yield put({ type: GET_LIST_REVIEWER_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_LIST_REVIEWER_FAIL, payload: err });
  }
}

//GET REVIEWER TASK
export function* getReviewerTask(action) {
  try {
    const response = yield call(API.requestGetReviewerTasks, action.payload);
    yield put({ type: GET_TASK_REVIEWER_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_TASK_REVIEWER_FAIL, payload: err });
  }
}

// GET GENERAL QUESTION
export function* getGeneralQuestion(action) {
  try {
    const response = yield call(API.requestGeneralQuestion, action.payload);
    yield put({ type: GET_GENERAL_QUESTION_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_GENERAL_QUESTION_FAIL, payload: err });
  }
}

//GET KEYWORD
export function* getKeyword(action) {
  try {
    const response = yield call(API.getListKeyword, action.payload);
    yield put({ type: GET_KEYWORD_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_KEYWORD_FAIL, payload: err });
  }
}

//GET VPOINT
export function* getVpoint(action) {
  try {
    const response = yield call(API.getListVpoint, action.payload);
    yield put({ type: GET_VPOINT_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_VPOINT_FAIL, payload: err });
  }
}

// GET LIST CALL
export function* getListCall(action) {
  try {
    const response = yield call(API.requestGetListCall, action.payload);
    yield put({ type: GET_LIST_CALL_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_LIST_CALL_FAIL, payload: err });
  }
}

//***************************** OCCASION ******************************/
export function* getListOccasion(action) {
  try {
    const response = yield call(API.getListOccasion, action.payload);
    yield put({ type: GET_OCCASION_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_OCCASION_FAIL, payload: err });
  }
}

export function* getResultReviewer(action) {
  try {
    const response = yield call(API.requestResultReviewer, action.payload);
    yield put({ type: GET_RESULT_REVIEWER_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_RESULT_REVIEWER_FAIL, payload: err });
  }
}

// FILTER
export function* getJobCategoriesFilter(action) {
  try {
    const response = yield call(API.requestJobCategoriesFilter, action.payload);
    yield put({ type: GET_JOB_CATEGORY_FILTER_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_JOB_CATEGORY_FILTER_FAIL, payload: err });
  }
}
export function* getJobFilter(action) {
  try {
    const response = yield call(API.requestJobFilter, action.payload);
    yield put({ type: GET_JOB_FILTER_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_JOB_FILTER_FAIL, payload: err });
  }
}
export function* getEnterprisesFilter(action) {
  try {
    const response = yield call(API.requestEnterprisesFilter, action.payload);
    yield put({ type: GET_ENTERPRISES_FILTER_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_ENTERPRISES_FILTER_FAIL, payload: err });
  }
}
export function* getTelesalesFilter(action) {
  try {
    const response = yield call(API.requestTelesalesFilter, action.payload);
    yield put({ type: GET_TELESALE_FILTER_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_TELESALE_FILTER_FAIL, payload: err });
  }
}
export function* getQuessionsAndAnswerFilter(action) {
  try {
    const response = yield call(
      API.requestQuessionsAndAnswerFilter,
      action.payload
    );
    yield put({
      type: GET_QUESTION_ANSWER_FILTER_SUCCESS,
      payload: { response },
    });
  } catch (err) {
    yield put({ type: GET_QUESTION_ANSWER_FILTER_FAIL, payload: err });
  }
}

// GET LIST CALL
export function* getListForSale(action) {
  try {
    const response = yield call(API.requestGetListForSale, action.payload);
    yield put({ type: GET_LIST_FOR_SALE_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_LIST_FOR_SALE_FAIL, payload: err });
  }
}

// GET TELESALE KPI
export function* getTelesaleKpi(action) {
  try {
    const response = yield call(API.requestGetTelesaleKpi, action.payload);
    yield put({ type: GET_TELESALE_KPI_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_TELESALE_KPI_FAIL, payload: err });
  }
}
// GET USER STATISTIC
export function* getUserStatistic(action) {
  try {
    const response = yield call(API.requestGetUserStatistic, action.payload);
    yield put({ type: GET_USER_STATISTIC_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_USER_STATISTIC_FAIL, payload: err });
  }
}

// GET USER CALL STATISTIC
export function* getCallStatistic(action) {
  try {
    const response = yield call(API.requestCallStatistic, action.payload);
    yield put({ type: GET_CALL_STATISTIC_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_CALL_STATISTIC_FAIL, payload: err });
  }
}

// GET JOB SALE STATISTIC
export function* getJobSaleStatistic(action) {
  try {
    const response = yield call(API.requestJobSaleStatistic, action.payload);
    yield put({ type: GET_JOB_SALE_STATISTIC_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_JOB_SALE_STATISTIC_FAIL, payload: err });
  }
}
// GET JOB STATISTIC
export function* getJobStatistic(action) {
  try {
    const response = yield call(API.requestJobStatistic, action.payload);
    yield put({ type: GET_JOB_STATISTIC_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_JOB_STATISTIC_FAIL, payload: err });
  }
}

export function* getListPackage(action) {
  try {
    const response = yield call(API.requestGetListPackage, action.payload);
    yield put({ type: GET_LIST_PACKAGE_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_LIST_PACKAGE_FAIL, payload: err });
  }
}

export function* getPackageHistory(action) {
  try {
    const response = yield call(API.requestPackageHistory, action.payload);
    yield put({ type: GET_PACKAGE_HISTORY_SUCCESS, payload: { response } });
  } catch (err) {
    yield put({ type: GET_PACKAGE_HISTORY_FAIL, payload: err });
  }
}

export function* getListHotLineNew(action) {
  try {
    const reponse = yield call(API.requestGetHotlineV2);
    yield put({ type: GET_LISTHOTLINE_NEW_SUCCESS, payload: reponse });
  } catch (error) {
    console.log('err', error);
  }
}

//********************************|  CATCH EVENT  |*************************************//
export const watchGetUser = takeEvery(GET_USER, getUserInfo); // LẤY THÔNG TIN NGƯỜI DÙNG ĐĂNG NHẬP

export const watchGetAccountList = takeEvery(GET_ACCOUNT, getAccountList); // LẤY THÔNG TIN NGƯỜI DÙNG ĐĂNG NHẬP

export const watchGetTelesale = takeEvery(GET_TELESALE, getTelesaleList); // LẤY DANH SÁCH TELESALE ĐANG HOẠT ĐỘNG

export const watchGetTelesaleInactive = takeEvery(
  GET_TELESALE_INACTIVE,
  getTelesaleInactiveList
); // LẤY DANH SÁCH TELESALE ĐANG HOẠT ĐỘNG

export const watchGetProvinceList = takeEvery(GET_PROVINCE, getProvinceList); // LẤY DANH SÁCH TELESALE ĐANG HOẠT ĐỘNG

export const watchGetRegisterList = takeEvery(GET_REGISTER, getRegisterList); // LẤY DANH SÁCH TELESALE ĐANG HOẠT ĐỘNG

export const watchGetRejectedRegisterList = takeEvery(
  GET_REJECTED_REGISTER,
  getRejectedRegisterList
); // LẤY DANH SÁCH TELESALE ĐANG HOẠT ĐỘNG

export const watchGetCompanyList = takeEvery(GET_COMPANY, getCompanyList);

export const watchGetCategoryList = takeEvery(GET_CATEGORY, getSubJobList);

export const watchGetHotlineList = takeEvery(GET_HOTLINE, getHotlineList);

export const watchCustomerList = takeEvery(GET_CUSTOMER, getCustomerList);

export const watchGetCustomerGroupList = takeEvery(
  GET_CUSTOMER_GROUP,
  getCustomerGroupList
);

export const watchGetReviewList = takeEvery(GET_REVIEW, getReviewList);

export const watchGetJobList = takeEvery(GET_JOB, getJobList);

export const watchGetJobDetail = takeEvery(GET_JOB_DETAIL, getJobDetail);

export const watchGetCategoryFilter = takeEvery(
  GET_CATEGORY_FILTER,
  getCategoryFilter
);

export const watchCustomerJobList = takeEvery(
  GET_CUSTOMER_JOB,
  getCustomerJobList
);

export const watchCustomerGroupJobList = takeEvery(
  GET_CUSTOMER_GROUP_JOB,
  getCustomerGroupJobList
);

export const watchCustomerGroupStatus = takeEvery(
  GET_CUSTOMER_GROUP_STATUS,
  getCustomerGroupStatus
);

export const watchCallHistory = takeLatest(GET_CALL_HISTORY, getCallHistory);

export const watchTypeJob = takeEvery(GET_TYPE_JOB, getTypeJob);

export const WatchGetListTutorial = takeEvery(
  GET_LIST_TUTORIAL,
  getListTutorial
);

export const WatchGetListCallStatus = takeEvery(
  GET_CALL_STATUS,
  getListCallStatus
);

export const WatchGetReviewerList = takeEvery(
  GET_LIST_REVIEWER,
  getReviewerList
);

export const WatchGetReviewerTask = takeEvery(
  GET_TASK_REVIEWER,
  getReviewerTask
);

export const WatchGetGeneralQuestion = takeEvery(
  GET_GENERAL_QUESTION,
  getGeneralQuestion
);

export const WatchGetKeyword = takeEvery(GET_KEYWORD, getKeyword);

export const WatchGetVpoint = takeEvery(GET_VPOINT, getVpoint);

export const WatchGetListCall = takeEvery(GET_LIST_CALL, getListCall);

export const WatchGetListOccasion = takeEvery(GET_OCCASION, getListOccasion);

export const WatchGetResultReviewer = takeEvery(
  GET_RESULT_REVIEWER,
  getResultReviewer
);

export const WatchGetJobCategoriesFilter = takeEvery(
  GET_JOB_CATEGORY_FILTER,
  getJobCategoriesFilter
);

export const WatchGetJobFilter = takeEvery(GET_JOB_FILTER, getJobFilter);

export const WatchGetEnterprisesFilter = takeEvery(
  GET_ENTERPRISES_FILTER,
  getEnterprisesFilter
);

export const WatchGetTelesalesFilter = takeEvery(
  GET_TELESALE_FILTER,
  getTelesalesFilter
);

export const WatchGetListForSale = takeEvery(GET_LIST_FOR_SALE, getListForSale);

export const WatchGetQuessionsAndAnswerFilter = takeEvery(
  GET_QUESTION_ANSWER_FILTER,
  getQuessionsAndAnswerFilter
);

export const WatchGetTelesaleKpi = takeEvery(GET_TELESALE_KPI, getTelesaleKpi);

export const WatchGetUserStatistic = takeEvery(
  GET_USER_STATISTIC,
  getUserStatistic
);

export const WatchGetCallStatistic = takeEvery(
  GET_CALL_STATISTIC,
  getCallStatistic
);

export const WatchGetJobStatistic = takeEvery(
  GET_JOB_STATISTIC,
  getJobStatistic
);

export const WatchGetJobSaleStatistic = takeEvery(
  GET_JOB_SALE_STATISTIC,
  getJobSaleStatistic
);

export const WatchGetListPackage = takeEvery(GET_LIST_PACKAGE, getListPackage);

export const WatchGetListHotLine = takeEvery(GET_LIST_HOTLINE, getListHotline);

export const WatchGetPackageHistory = takeEvery(
  GET_PACKAGE_HISTORY,
  getPackageHistory
);

export const WatchGetListHotLineNew = takeEvery(
  GET_LISTHOTLINE_NEW,
  getListHotLineNew
);
