import Axios from 'axios';
import Cookies from 'js-cookie';
import React, { useRef } from 'react';

const createImage = options => {
  options = options || {};
  const img = document.createElement('img');
  if (options.src) {
    img.src = options.src;
  }
  return img;
};

const copyToClipboard = async pngBlob => {
  try {
    await navigator.clipboard.write([
      // eslint-disable-next-line no-undef
      new ClipboardItem({
        [pngBlob.type]: pngBlob,
      }),
      // eslint-disable-next-line no-undef
    ]);
    // await navigator.clipboard.writeText("abcdeg")
    // await navigator.clipboard.write([
    //     // eslint-disable-next-line no-undef
    //     new ClipboardItem({
    //       [pngBlob.type]: pngBlob
    //     }),
    //          // eslint-disable-next-line no-undef
    //   ]);

    console.log('Image copied');
  } catch (error) {
    console.error(error);
  }
};

const convertToPng = imgBlob => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const imageEl = createImage({ src: window.URL.createObjectURL(imgBlob) });
  imageEl.onload = e => {
    canvas.width = e.target.width;
    canvas.height = e.target.height;
    ctx.drawImage(e.target, 0, 0, e.target.width, e.target.height);
    canvas.toBlob(copyToClipboard, 'image/png', 1);
    alert(12);
  };
};

export const copyImg = async src => {
  if (!src) {
    return;
  }
  const img = await fetch(src);
  const imgBlob = await img.blob();
  const extension = src.split('.').pop();
  const supportedToBeConverted = ['jpeg', 'jpg', 'gif'];
  if (supportedToBeConverted.indexOf(extension.toLowerCase())) {
    return convertToPng(imgBlob);
  } else if (extension.toLowerCase() === 'png') {
    return copyToClipboard(imgBlob);
  }
  console.error('Format unsupported');
  return;
};

export const copyContentclipBoard = async (content, customerPhone) => {
  console.log('content', content);
  if (
    (content.is_sample_voucher === 1 
      // || content.is_sample_voucher === 0
      ) &&
    content.e_voucher_code
  ) {
    // let encodeBase64 = content.media_url.split(',')[1];
    // let binaryData = window.atob(encodeBase64);
    // // convert binary data to Blob object
    // const blob = new Blob([binaryData], { type: 'image/jpg' });
    // let formData = new FormData();
    // formData.append('file', blob);

    await fetch(content.media_url)
      .then(res => res.blob())
      .then(async blob => {
        // console.log(11111, blob);
        var formData = new FormData();
        formData.append('file', blob);
        let config = {
          headers: {
            token: Cookies.get('SESSION_ID'),
            'Content-Type': 'multipart/form-data',
          },
        };
        let res = await Axios.post(
          'https://api.ezsale.vn/media/uploadAll',
          formData,
          config
        );
        console.log('resdata', res);
        if (res.data.status) {
          content.media_url = res.data.data;
          // alert(999)
        }
      });
  }

  let contentNeedCopyToClipBoard = content?.media_url
    ? {
        message: content.message,
        link_img: content.media_url,
      }
    : {
        name: content.name,
        message: content.content || content.message,
      };


  console.log('contentNeedCopyToClipBoard', contentNeedCopyToClipBoard);

  try {
    let str = '';
    if (contentNeedCopyToClipBoard.message) {
      str = str + contentNeedCopyToClipBoard.message;
    }
    if (contentNeedCopyToClipBoard.link_img) {
      str = `${str} ${contentNeedCopyToClipBoard.link_img}`;
    }

    await navigator.clipboard.writeText(`${str}`);
    window.open(`https://zalo.me/${customerPhone}`, '_blank');
    console.log('coppy success');
  } catch (error) {
    console.error(error);
  }
};
