import React, { useState, useEffect, useCallback } from 'react';
import {
  PhoneFilled,
  StarFilled,
  DollarCircleFilled,
  FileDoneOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { Empty, Avatar, Row, Col, Modal, Tag, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import {
  DEFAULT_IMAGE,
  CUSTOMER_INTERACTION,
  ROUTER,
  OPPORTUNITY_STATUS,
} from '@constants/Constant';
import {
  formatPrice,
  getSearchParamsInUrl,
  setSearchParamsInUrlVer2,
} from '@constants/funcHelper';
import { useCancellableApi } from 'src/hooks/useCancellableApi';
import { getKpiProgress } from '../../../redux/actions/index';
import { REVENUE_CHANGE, SALE_CHANGE } from './Constant';
import Wrapper from '@components/ui/wrapper/Wrapper';
import { BreadcrumbAntStyle } from '@styles/Style';
import { notifyFail } from '@utils/notify';
import Filter from './component/Filter';
import {
  requestTelesalesFilter,
  requestGetAllDepartment,
  requestGetKpiRealtime,
  requestGetKpiStatic,
  requestTagKpi,
  requestProductKpi,
  requestFullCustomerAction,
} from '@constants/Api';

import { StyledBlinking, StyledTableBlinking } from './Style';
import '../../../styles/Job.css';
import styled from 'styled-components';
import mixpanel from 'mixpanel-browser';
import {
  convertColor,
  convertDataWithPrefix,
  getAllProduct,
  getAllTag,
} from './Utils/func';
import { createColumnTableDynamic } from './Utils/Func.jsx';
const BLINKING_TIME = 5000;
const timeFormat = 'DD/MM/YYYY';

function KPIProgressScreen() {
  const apiGetKpiRealtime = useCancellableApi(requestGetKpiRealtime);
  const apiGetKpiStatic = useCancellableApi(requestGetKpiStatic);

  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [params, setParams] = useState({
    sale_id: '',
    department_id: '',
    date: moment(new Date()).format('YYYY/MM/DD'),
  });
  const [departmentList, setDepartmentList] = useState([]);
  const [saleList, setSaleList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [data, setData] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [dataRecord, setDataRecord] = useState();
  const [listTagsDynamic, setListTagsDynamic] = useState([]);
  const [listProductDynamic, setListProductDynamic] = useState([]);
  const [kpiStatic, setKpiStatic] = useState({
    total_call: 0,
    total_customer: null,
    total_oppor: null,
    total_revenue: null,
  });

  /**
   * @initialState [{
   *  modified_at: Date,
   *  [KPI_type]: Number,
   *  telesale_id: Number
   * }]
   *
   * @KPI_type is the flexible string in several series: 'call_result', 'new_customer_result', 'opportunity_result', 'opportunity_status', 'sale_change', 'revenue_change'
   */
  const [realTimeKPIProgressTest, setRealTimeKPIProgressTest] = useState([]);

  useEffect(() => {
    getAllTag().then(res => setListTagsDynamic(res));
    getAllProduct().then(res => setListProductDynamic(res));
  }, []);

  let dataDynamicTable = useCallback(() => {
    let allColumn =  [
      ...createColumnTableDynamic(listProductDynamic, 'product'),
      ...createColumnTableDynamic(listTagsDynamic, 'tag'),
    ];
    //các column nào có tổng bằng 0 thì không được show
    let columnFilter = allColumn.filter(columnsItem => {
        let totalValueColumn = data.reduce((prev, next) => {
            return prev + (next[`${columnsItem.dataIndex}total`] || 0)
        }, 0)
        return totalValueColumn > 0
    })
    return columnFilter
  }, [listProductDynamic, listTagsDynamic, data]);


  const handleRoutingWithRealTimeKPIProgressDetail = (router, searchParams) => {
    const searchParamsInUrl = new URLSearchParams('');
    const detail = router.slice(1);
    if (searchParams) {
      for (const key in searchParams) {
        if (Object.hasOwnProperty.call(searchParams, key)) {
          searchParamsInUrl.set(key, JSON.stringify(searchParams[key]));
        }
      }
      searchParamsInUrl.set('page', 1);
    }
    mixpanel.track(`KPI progress: view detail ${detail}`, {
      router: router,
      searchParams: searchParams,
    });
    history.push({
      pathname: router,
      search: searchParamsInUrl.toString(),
    });
  };

  //số cuộc gọi, khách hàng tự thêm, cơ hội
  const checkBlinking = useCallback(
    (realTimeData, paramsSearch, columnData, columnName) => {
      return realTimeData.some(
        element =>
          element?.telesale_id === columnData.telesale_id &&
          Object.keys(element).includes(columnName) &&
          moment(element.modified_at).format(timeFormat) ===
            moment(paramsSearch.date).format(timeFormat)
      );
    },
    []
  );

  //chốt hợp đồng, doanh thu, doanh số
  const checkBlinkingVer2 = useCallback(
    (realTimeData, paramsSearch, columnData, columnName) => {
      let isBlinking = false;

      //cột chốt hợp đồng blinking nếu opportunity_status là chốt hợp đồng hoặc từ chối
      if (
        columnName === 'opportunity_status' &&
        realTimeData.some(
          element =>
            element?.telesale_id === columnData.telesale_id &&
            Object.keys(element).includes(columnName) &&
            (element[columnName] === OPPORTUNITY_STATUS.CLOSE_CONTRACT ||
              element[columnName] === OPPORTUNITY_STATUS.CUSTOMER_REFUSES) &&
            moment(element.modified_at).format(timeFormat) ===
              moment(paramsSearch.date).format(timeFormat)
        )
      ) {
        isBlinking = true;
      } else {
        //cột doanh thu, doanh số
        if (
          (columnName === 'sale_change' || columnName === 'revenue_change') &&
          realTimeData.some(
            element =>
              element?.telesale_id === columnData.telesale_id &&
              Object.keys(element).includes(columnName) &&
              (element[columnName] === REVENUE_CHANGE.YES ||
                element[columnName] === SALE_CHANGE.YES) &&
              moment(element.modified_at).format(timeFormat) ===
                moment(paramsSearch.date).format(timeFormat)
          )
        ) {
          isBlinking = true;
        }
      }
      return isBlinking;
    },
    []
  );
  console.log('dataRecord', dataRecord);
  const column = [
    {
      title: <b>Sale</b>,
      dataIndex: 'full_name',
      key: 'full_name',
      fixed: 'left',
      width: 180,
      ellipsize: false,
      ellipsis: true,
      render: (value, record) => (
        <Row
          align="middle"
          onClick={() => {
            setDataRecord(record);
            setVisibleModal(true);
            mixpanel.track(
              `View detail KPI progress ${value}(id: ${record.telesale_id})`
            );
          }}
        >
          <div>
            <Avatar
              size="large"
              style={{ marginRight: 5 }}
              src={record.profile_image || DEFAULT_IMAGE}
            />
          </div>
          <b>{value}</b>
        </Row>
      ),
    },
    {
      title: <b>Số cuộc gọi</b>,
      dataIndex: 'call',
      key: 'call',
      width: 100,
      fixed: 'left',
      ellipsize: false,
      ellipsis: true,
      render: (value, record) => (
        <StyledBlinking
          blinking={checkBlinking(
            realTimeKPIProgressTest,
            params,
            record,
            'call_result'
          )}
          style={{
            color: convertColor(record.call_result, value),
            width: '100%',
            height: '100%',
          }}
          onClick={() => {
            handleRoutingWithRealTimeKPIProgressDetail(ROUTER.CALL, {
              telesale_id: [record.telesale_id],
              fromDate: moment(params.date).format('YYYY-MM-DD'),
              toDate: moment(params.date).format('YYYY-MM-DD'),
            });
          }}
        >
          {record.call_result
            ? formatPrice(parseInt(record.call_result).toFixed())
            : '0'}
          /{value ? formatPrice(parseInt(value).toFixed()) : '0'}
        </StyledBlinking>
      ),
    },
    {
      title: <b>Khách hàng tự thêm</b>,
      dataIndex: 'new_customer',
      key: 'new_customer',
      fixed: 'left',
      width: 100,
      // ellipsize: false,
      // ellipsis: true,
      render: (value, record) => (
        <StyledBlinking
          blinking={
            checkBlinking(
              realTimeKPIProgressTest,
              params,
              record,
              'new_customer_result'
            ) ||
            checkBlinking(
              realTimeKPIProgressTest,
              params,
              record,
              'delete_customer'
            )
          }
          style={{ color: convertColor(record.new_customer_result, value) }}
          onClick={async () => {
            const searchParams = {
              telesale_id: [record.telesale_id],
              from_date: params.date,
              to_date: params.date,
            };

            try {
              const { data } = await requestFullCustomerAction();
              const action = data.find(
                action => action.name === CUSTOMER_INTERACTION.CREATE
              );
              if (action) {
                searchParams.df_customer_action_id = action.id;
              }
            } catch (error) {//
            } finally {
              handleRoutingWithRealTimeKPIProgressDetail(
                ROUTER.CUSTOMER,
                searchParams
              );
            }
          }}
        >
          {record.new_customer_result
            ? formatPrice(parseInt(record.new_customer_result).toFixed())
            : '0'}
          /{value ? formatPrice(parseInt(value).toFixed()) : '0'}
        </StyledBlinking>
      ),
    },
    {
      title: <b>Cơ hội</b>,
      dataIndex: 'opportunity',
      key: 'opportunity',
      fixed: 'left',
      width: 100,
      ellipsize: false,
      ellipsis: true,
      render: (value, record) => (
        <StyledBlinking
          blinking={checkBlinking(
            realTimeKPIProgressTest,
            params,
            record,
            'opportunity_result'
          )}
          style={{ color: convertColor(record.opportunity_result, value) }}
          onClick={() => {
            handleRoutingWithRealTimeKPIProgressDetail(ROUTER.OPPORTUNITY, {
              telesale_id: [record.telesale_id],
            });
          }}
        >
          {record.opportunity_result
            ? formatPrice(parseInt(record.opportunity_result).toFixed())
            : '0'}
          /{value ? formatPrice(parseInt(value).toFixed()) : '0'}
        </StyledBlinking>
      ),
    },
    {
      title: <b>Chốt hợp đồng</b>,
      dataIndex: 'contracts',
      key: 'contracts',
      fixed: 'left',
      width: 100,
      // ellipsize: false,
      // ellipsis: true,
      render: (value, record) => (
        <StyledBlinking
          blinking={checkBlinkingVer2(
            realTimeKPIProgressTest,
            params,
            record,
            'opportunity_status'
          )}
          style={{ color: convertColor(record.contract_result, value) }}
          onClick={() => {
            handleRoutingWithRealTimeKPIProgressDetail(ROUTER.OPPORTUNITY, {
              telesale_id: [record.telesale_id],
              fromDate: params.date,
              toDate: params.date,
              status: OPPORTUNITY_STATUS.CLOSE_CONTRACT,
            });
          }}
        >
          {record.contract_result
            ? formatPrice(parseInt(record.contract_result).toFixed())
            : '0'}
          /{value ? formatPrice(parseInt(value).toFixed()) : '0'}
        </StyledBlinking>
      ),
    },
    {
      title: <b>Doanh số(VNĐ)</b>,
      dataIndex: 'sales',
      key: 'sales',
      width: 100,
      align: "center",
      ellipsize: true,
      render: (value, record) => {
        let valueCell = `${record.sales_result
          ? formatPrice(parseInt(record.sales_result).toFixed())
          : '0'}
        /${value ? formatPrice(parseInt(value).toFixed()) : '0'}`
        return  <Tooltip title = {<div>{valueCell}</div>}>
        <StyledBlinking
          blinking={checkBlinkingVer2(
            realTimeKPIProgressTest,
            params,
            record,
            'sale_change'
          )}
          style={{ color: convertColor(record.sales_result, value) }}
          onClick={() => {
            handleRoutingWithRealTimeKPIProgressDetail(ROUTER.OPPORTUNITY, {
              telesale_id: [record.telesale_id],
              fromDate: params.date,
              toDate: params.date,
              status: OPPORTUNITY_STATUS.CLOSE_CONTRACT,
            });
          }}
        >
          {valueCell}
        </StyledBlinking>
        </Tooltip>
    
   
    }},
    {
      title: <b>Doanh thu(VNĐ)</b>,
      dataIndex: 'revenue',
      key: 'revenue',
      width: 100,
      ellipsize: true,
      // ellipsis: true,
      render: (value, record) => {
        let valueCell = `${record.revenue_result
          ? formatPrice(parseInt(record.revenue_result).toFixed())
          : '0'}
        /${value ? formatPrice(parseInt(value).toFixed()) : '0'}`
        return <Tooltip title= {valueCell}>
     <StyledBlinking
        blinking={checkBlinkingVer2(
          realTimeKPIProgressTest,
          params,
          record,
          'revenue_change'
        )}
        style={{ color: convertColor(record.revenue_result, value) }}
        onClick={() => {
          handleRoutingWithRealTimeKPIProgressDetail(ROUTER.OPPORTUNITY, {
            telesale_id: [record.telesale_id],
            fromDate: params.date,
            toDate: params.date,
            status: OPPORTUNITY_STATUS.CLOSE_CONTRACT,
          });
        }}
      >
        {valueCell}
      </StyledBlinking>
        </Tooltip>
    
      }
       
      
    },
  ];

  const kpiProgressInfoSocket = useSelector(state => {
    return state.kpiProgress.data;
  });

  const handleChangeParam = (field, value) => {
    mixpanel.track(`Filter KPI progress ${[field]}: ${value || ''}}`);
    setSearchParamsInUrlVer2({ [field]: value, page: 1 });

    setParams({
      ...params,
      [field]: value || '',
      page: 1,
    });
  };

  useEffect(() => {
    const searchParamsInUrl = getSearchParamsInUrl();
    setParams({ ...params, ...searchParamsInUrl });

    getDepartments();
    getSales();
    getTag();
    getProduct();

    return () => {
      dispatch(getKpiProgress({}));
    };
  }, []);

  useEffect(() => {
    debounceGetDataRealtime(params);
    debounceGetDataStatic(params);
  }, [params]);

  useEffect(() => {
    if (
      kpiProgressInfoSocket.telesale_id &&
      moment(kpiProgressInfoSocket.modified_at).format(timeFormat) ===
        moment(params.date).format(timeFormat)
    ) {
      setRealTimeKPIProgressTest(prev => [...prev, kpiProgressInfoSocket]);
      debounceGetDataRealtime(params);

      const timeOut = setTimeout(() => {
        setRealTimeKPIProgressTest([]);
      }, BLINKING_TIME);

      return () => clearTimeout(timeOut);
    }
  }, [kpiProgressInfoSocket]);

  const listTagSale = (dataRecord?.tags || []).map(el => {
    let value = tagList.find(item => item.id === el.id);
    return Object.assign(value, { kpi: el.kpi, result: el.result });
  });
  const listProductSale = (dataRecord?.products || []).map(el => {
    let value = productList.find(item => item.id === el.id);
    return Object.assign(value, { kpi: el.kpi, result: el.result });
  });

  const getDataRealtime = async payload => {
    try {
      setIsLoading2(true);
      const res = await apiGetKpiRealtime({
        date_time: payload.date
          ? moment(payload.date).format('YYYY-MM-DD')
          : '',
        department_id: payload.department_id,
        sale_id: payload.sale_id,
      });
      console.log('convertData1111', res.data);
      let convertData = res.data.map(item => {
        return {
          ...item,
          ...convertDataWithPrefix(item.tags, 'tag'),
          ...convertDataWithPrefix(item.products, 'product'),
        };
      });
      console.log('convertData', convertData);
      setData(convertData);

      setIsLoading2(false);
    } catch (error) {
      console.log('errr', error);
      setIsLoading2(false);
    }
  };

  const getDataStatic = async payload => {
    try {
      setIsLoading(true);
      const res = await apiGetKpiStatic({
        date_time: payload.date
          ? moment(payload.date).format('YYYY-MM-DD')
          : '',
        department_id: payload.department_id,
        sale_id: payload.sale_id,
      });
      setKpiStatic({
        ...res.data,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getDepartments = async () => {
    try {
      const res = await requestGetAllDepartment();
      setDepartmentList(res.data);
    } catch (error) {
      notifyFail(error.msg);
    }
  };

  const getSales = async () => {
    try {
      const res = await requestTelesalesFilter();
      setSaleList(res.data);
    } catch (error) {}
  };

  const getTag = async () => {
    try {
      const res = await requestTagKpi();
      setTagList(res.data);
    } catch (error) {}
  };

  const getProduct = async () => {
    try {
      const res = await requestProductKpi();
      setProductList(res.data);
    } catch (error) {}
  };

  const debounceGetDataRealtime = useCallback(
    _.debounce(getDataRealtime, 500),
    []
  );
  const debounceGetDataStatic = useCallback(_.debounce(getDataStatic, 500), []);

  const renderSystemRow = (title, numerator, denominator) => {
    return (
      <Row>
        <p style={{ width: '50%' }}>{title}:&nbsp;</p>
        <b>{renderFraction(numerator, denominator)}</b>
      </Row>
    );
  };

  const renderFraction = (numerator, denominator) => {
    const fraction = `${
      numerator ? formatPrice(parseInt(numerator).toFixed()) : '0'
    } / ${denominator ? formatPrice(parseInt(denominator).toFixed()) : '0'}`;
    if (fraction.length > 19) {
      return <Tooltip title={fraction}>{fraction.slice(0, 19)}...</Tooltip>;
    } else {
      return fraction;
    }
  };

  const renderTag = tag => {
    if (tag.length > 19) {
      return <Tooltip title={tag}>{tag.slice(0, 19)}...</Tooltip>;
    } else {
      return tag;
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <BreadcrumbAntStyle>
          <BreadcrumbAntStyle.Item>KPI</BreadcrumbAntStyle.Item>
          <BreadcrumbAntStyle.Item>
            Tiến độ thực hiện KPI
          </BreadcrumbAntStyle.Item>
        </BreadcrumbAntStyle>
        <Wrapper>
          <h4>Tiến độ KPI theo ngày</h4>
          <Filter
            params={params}
            setParams={setParams}
            departmentList={departmentList}
            saleList={saleList}
            handleChangeParam={handleChangeParam}
          />
          <b>Tổng số:</b>
          <Row justify="space-between" style={{ padding: '0 20px 0 100px' }}>
            <Col style={{ textAlign: 'start', width: '33.333%' }}>
              <PhoneFilled style={{ color: 'steelblue', fontSize: 32 }} /> Số
              cuộc gọi:{' '}
              {kpiStatic.total_call
                ? formatPrice(kpiStatic.total_call.toFixed())
                : 0}
            </Col>
            <Col style={{ textAlign: 'start', width: '33.333%' }}>
              <StarFilled style={{ color: 'green', fontSize: 32 }} /> Cơ hội:{' '}
              {kpiStatic.total_oppor
                ? formatPrice(kpiStatic.total_oppor.toFixed())
                : 0}
            </Col>
            <Col style={{ textAlign: 'start', width: '33.333%' }}>
              <DollarCircleFilled style={{ color: 'purple', fontSize: 32 }} />{' '}
              Doanh số:{' '}
              {kpiStatic.total_sales
                ? formatPrice(kpiStatic.total_sales.toFixed())
                : 0}
            </Col>
          </Row>
          <Row justify="space-between" style={{ padding: '0 20px 0 100px' }}>
            <Col style={{ textAlign: 'start', width: '33.333%' }}>
              <UsergroupAddOutlined style={{ color: 'brown', fontSize: 32 }} />{' '}
              Khách hàng tự thêm:{' '}
              {kpiStatic.total_customer
                ? formatPrice(kpiStatic.total_customer.toFixed())
                : 0}
            </Col>
            <Col style={{ textAlign: 'start', width: '33.333%' }}>
              <FileDoneOutlined style={{ color: 'orange', fontSize: 32 }} />{' '}
              Chốt hợp đồng:{' '}
              {kpiStatic.total_contract
                ? formatPrice(kpiStatic.total_contract.toFixed())
                : 0}
            </Col>
            <Col style={{ textAlign: 'start', width: '33.333%' }}>
              <DollarCircleFilled style={{ color: 'tomato', fontSize: 32 }} />{' '}
              Doanh thu:{' '}
              {kpiStatic.total_revenue
                ? formatPrice(kpiStatic.total_revenue.toFixed())
                : 0}
            </Col>
          </Row>
        </Wrapper>
        <Wrapper>
          <StyledTableBlinking
            className="table-kpi-progress"
            size="middle"
            loading={isLoading || isLoading2}
            bordered
            columns={[...column, ...dataDynamicTable()]}
            dataSource={data}
            scroll={{
              x: 800,
              scrollToFirstRowOnChange: true,
              y: 600,
            }}
            locale={{
              // emptyText: (
              //   <Empty
              //     image={Empty.PRESENTED_IMAGE_SIMPLE}
              //     description={<span>Không có dữ liệu.</span>}
              //   />
              // ),
              triggerDesc: '',
              triggerAsc: '',
              cancelSort: '',
            }}
          />
        </Wrapper>
      </div>

      {/* Chi tiết tiên độ KPI */}
      <Modal
        title="Tiến độ KPI"
        visible={visibleModal}
        onCancel={() => {
          setVisibleModal(false);
        }}
        width={450}
        footer={null}
      >
        <div>
          <Row>
            <div>
              <Avatar
                size="large"
                style={{ marginRight: 5 }}
                src={dataRecord?.profile_image || DEFAULT_IMAGE}
              />
            </div>
            <div>{dataRecord?.full_name}</div>
          </Row>
          <div>
            <b>
              Ngày:&nbsp;&nbsp;&nbsp;{moment(params.date).format(timeFormat)}
            </b>
          </div>
          <b style={{ color: 'orange' }}>KPI hệ thống</b>
          <div style={{ marginLeft: 45 }}>
            {renderSystemRow(
              'Doanh số(VNĐ)',
              dataRecord?.sales_result,
              dataRecord?.sales
            )}
            {renderSystemRow(
              'Doanh thu(VNĐ)',
              dataRecord?.revenue_result,
              dataRecord?.revenue
            )}
            {renderSystemRow(
              'Cơ hội',
              dataRecord?.opportunity_result,
              dataRecord?.opportunity
            )}
            {renderSystemRow(
              'Chốt hợp đồng',
              dataRecord?.contract_result,
              dataRecord?.contracts
            )}
            {renderSystemRow(
              'Gọi điện',
              dataRecord?.call_result,
              dataRecord?.call
            )}
            {renderSystemRow(
              'Khách hàng tự thêm',
              dataRecord?.new_customer_result,
              dataRecord?.new_customer
            )}
          </div>
          {listTagSale?.length > 0 ? (
            <>
              <b style={{ color: 'orange' }}>KPI phân loại</b>
              <div style={{ marginLeft: 45 }}>
                {listTagSale.map(item => (
                  <Row key={item.id}>
                    <div style={{ width: '50%' }}>
                      <Tag
                        key={item.id}
                        style={{ marginRight: 5, marginBottom: 5 }}
                      >
                        <div className="d-flex justify-content-around align-items-center">
                          <div
                            style={{
                              backgroundColor: item.color
                                ? item.color
                                : 'steelblue',
                              borderRadius: '50%',
                              width: '10px',
                              height: '10px',
                              marginRight: '3px',
                            }}
                          ></div>
                          {renderTag(item.name)}
                        </div>
                      </Tag>
                    </div>
                    <b>{renderFraction(item.result, item.kpi)}</b>
                  </Row>
                ))}
              </div>
            </>
          ) : null}
          {listProductSale?.length > 0 ? (
            <>
              <b style={{ color: 'orange' }}>KPI sản phẩm</b>
              <div style={{ marginLeft: 45 }}>
                {listProductSale.map(item => (
                  <Row key={item.id}>
                    <div style={{ width: '50%' }}>{item.name}:</div>
                    <b>{renderFraction(item.result, item.kpi)}</b>
                  </Row>
                ))}
              </div>
            </>
          ) : null}
        </div>
      </Modal>
    </>
  );
}

export default KPIProgressScreen;
