import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import '@styles/Corporation.css';
import Table from 'react-bootstrap/Table';
import { Row, Col, FormControl, Button, Modal, FormGroup, Form } from 'react-bootstrap';
import { ROUTER } from '@constants/Constant';
import Loading from '@src/components/Loading';
import { getCustomerGroupList } from '@src/redux/actions';
import { connect } from 'react-redux';
import { notifyFail } from '@utils/notify';
import moment from 'moment';
import Pagination from '@components/Pagination';
import { TableView } from '@components/ui/table';
import { getDateRangeString, formatDateString } from '@utils/dateUtils';
import Theme from '@constants/Theme';
import reactotron from 'reactotron-react-js';
import debounce from 'lodash/debounce';

class Screen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modelAdd: false,
      modelEdit: false,
      modalAddField: {
        name: '',
        phone: '',
        email: '',
        password: '',
        repassword: '',
      },
      modalEditField: {
        name: '',
        phone: '',
        email: '',
      },
      searchBar: {
        searchKey: '',
      },
      activePage: 1,
    };
  }

  componentDidMount() {
    const { searchKey } = this.state.searchBar;
    const { activePage } = this.state;
    this.getData(searchKey, activePage);
  }

  getData(search, page) {
    this.props.getCustomerGroupList({ search: search, page: page });
  }

  renderTitle() {
    return (
      <>
        <Row>
          <Col md={5} sm={12}>
            <h2 className="text-left" style={{ marginBottom: '10px' }}>
              {' '}
              Danh sách nhóm khách hàng
            </h2>
            <div class="line"></div>
          </Col>
          <br />
          <Col md={7} sm={12}>
            {this.renderButton()}
          </Col>
        </Row>
      </>
    );
  }

  renderBody() {
    return (
      <>
        <div className="content-wrapper">
          <div className="content-header">
            <Row
              className="mt-1"
              style={{ textAlign: 'center', padding: '0px 20px', borderRadius: '8px', marginBottom: 20 }}
            >
              <Col md className="bg">
                {this.renderTitle()}
                <Row>{this.renderField()}</Row>
              </Col>
            </Row>
            <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 20 }}>
              <Col md className="bg">
                <Row>
                  <Col md={12}>{this.renderTable()}</Col>
                </Row>
                <Row>
                  <Col md={12} className="d-flex justify-content-center justify-content-md-end">
                    <Pagination screen="customerGroupState" handlePageChange={this.handlePageChange} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }

  handlePageChange = (page) => {
    const { searchBar } = this.state;
    this.setState(
      {
        ...this.state,
        activePage: page,
      },
      () => this.getData(searchBar.searchKey, page)
    );
  };

  renderButton() {
    return (
      <div className="text-center text-md-right">
        <Link to={ROUTER.CUSTOMER_GROUP_ADD}>
          <Button variant="success" className="text-light">
            Thêm mới
          </Button>
        </Link>
      </div>
    );
  }

  renderField() {
    const { searchBar } = this.state;
    return (
      <div className="w-100">
        <Row>
          <Col md={6} sm={12}>
            <FormControl
              type="text"
              id=""
              placeholder="Tên nhóm"
              value={searchBar.searchKey}
              onChange={(e) => this.searchBarChange('searchKey', e.target.value)}
            />
          </Col>
        </Row>
      </div>
    );
  }

  renderTable() {
    const { customerGroupState } = this.props;
    const customerList = customerGroupState?.data || [];
    const count = customerGroupState?.paging?.totalItemCount;
    const pageNumber = customerGroupState?.paging?.page;
    const itemPerNumber = customerGroupState?.paging?.limit;

    const dataSource =
      (customerList?.length > 0 &&
        customerList.map((item, index) => {
          return {
            data: item,
            key: item.id,
            stt: index + 1 + (pageNumber - 1) * itemPerNumber,
            name: item.name,
            customer: item.customer,
            job: (item.jobs?.length > 0 && item.jobs?.map((v) => <div>{v.name}</div>)) || (
              <span className="text-muted">--</span>
            ),
            createdDate: formatDateString(item.created_at),
          };
        })) ||
      [];

    const tableData = {
      columns: [
        { dataIndex: 'stt', title: 'STT', width: Theme.Sizing.tableColumnNoWidth },
        { dataIndex: 'name', title: 'Tên nhóm', align: 'left' },
        { dataIndex: 'customer', title: 'Số lượng khách hàng' },
        { dataIndex: 'job', title: 'Công việc đang thực hiện', align: 'left' },
        { dataIndex: 'createdDate', title: 'Ngày tạo' },
      ],
      dataSource,
      locale: { emptyText: 'Chưa có nhóm nào' },
      pagination: false,
      onRow: (record, rowIndex) => ({
        onClick: (event) => {
          this.props.history.push(ROUTER.CUSTOMER_GROUP_DETAIL + `/${record.data.id}`);
        },
      }),
    };
    return (
      <>
        <span className="pb-2 d-inline-block">
          Kết quả lọc: <b className="text-danger">{count || 0}</b>
        </span>
        <div className="w-100 table-wrap" style={{ marginBottom: 8 }}>
          <TableView {...tableData} />
        </div>
      </>
    );
  }

  renderTable_2 = () => {
    const customerList = this.props.customerGroupState?.data;
    const count = this.props.customerGroupState?.paging?.totalItemCount;
    const pageNumber = this.props.customerGroupState?.paging?.page;
    const itemPerNumber = this.props.customerGroupState?.paging?.limit;
    // reactotron.log(this.props.customerGroupState?.paging, 'Chien');
    return (
      <>
        <span className="pb-2 d-inline-block">
          Kết quả lọc: <b className="text-danger">{count || 0}</b>
        </span>
        <div className="w-100 table-wrap">
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>STT</th>
                <th>Tên nhóm</th>
                <th>Khách hàng</th>
                <th>Công việc đang thực hiện</th>
                <th>Ngày tạo</th>
              </tr>
            </thead>
            <tbody>
              {customerList?.length &&
                customerList.map((item, index) =>
                  this.renderTableRow(item, index + 1 + (pageNumber - 1) * itemPerNumber)
                )}
            </tbody>
          </Table>
        </div>
      </>
    );
  };

  renderTableRow(item, index) {
    return (
      <>
        <tr onClick={() => this.props.history.push(ROUTER.CUSTOMER_GROUP_DETAIL + `/${item.id}`)}>
          <td>{index}</td>
          <td>{item.name || '--'}</td>
          <td>{item.customer || '--'}</td>
          <td>{item.job || '--'}</td>
          <td>{moment(item.created_at).format('DD-MM-YYYY') || '--'}</td>
        </tr>
      </>
    );
  }
  setShowModal = (modal, bool) => {
    this.setState({
      [modal]: bool,
    });
  };

  addFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalAddField: {
        ...this.state.modalAddField,
        [field]: value,
      },
    });
  };

  editFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalEditField: {
        ...this.state.modalEditField,
        [field]: value,
      },
    });
  };

  searchDataDebounce = debounce(() => {
    this.getData(this.state.searchBar?.searchKey, this.state.activePage);
  }, 500);

  searchBarChange = (field, value) => {
    this.setState(
      (state) => ({
        searchBar: {
          ...state.searchBar,
          [field]: value,
        },
      }),
      () => this.searchDataDebounce()
    );
  };

  render() {
    const { isLoading, error } = this.props.customerGroupState;
    let loading = <Loading />;
    if (error) {
      notifyFail(error.msg);
    }
    if (!isLoading) {
      loading = null;
    }
    return (
      <>
        {loading}
        {this.renderBody()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  customerGroupState: state.customerGroupReducer,
});

const mapDispatchToProps = {
  getCustomerGroupList,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Screen));
