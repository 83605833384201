export const SET_PROMOTIONS_GENERAL = 'set_promotions_general';
export const SET_PROMOTIONS_VALUE_OF_THE_VOUCHER = 'set_promotions_value_of_the_voucher';
export const SET_PROMOTIONS_VALUE_OF_THE_VOUCHER_IN_GIFTS = 'set_promotions_value_of_the_voucher_in_gifts';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_GIFTS = 'SET_GIFTS';

export const SET_CONDITION_PRODUCT = 'SET_CONDITION_PRODUCT';

export const SET_CONDITION_PURCHASE_TIME_DATE = 'SET_CONDITION_PURCHASE_TIME_DATE';
export const SET_CONDITION_PURCHASE_TIME_HOUR = 'SET_CONDITION_PURCHASE_TIME_HOUR';
export const SET_TIME_BUY = 'SET_TIME_BUY';
export const SET_CONFIG_TYPE = 'SET_CONFIG_TYPE';

// set detail evoucher
export const SET_DETAIL_EVOUCHER = 'SET_DETAIL_EVOUCHER';
