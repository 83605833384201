import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { Row, Col, FormControl } from 'react-bootstrap';
import '@styles/JobGeneral.css';
import { ROUTER, STRING, NUMBER } from '@constants/Constant';
import DatePickerCustom from '../../../../components/DatePickerCustom';
import { Line } from 'react-chartjs-2';
import Loading from '@src/components/Loading';
import { notifyFail, notifySuccess } from '@utils/notify';
import moment from 'moment';
import { ReactSelect } from '@components/ReactSelect';
import Pagi from '@components/PaginationTotalItem';
import reactotron from 'reactotron-react-js';
import { Empty } from 'antd';

import { requestGetCallChart, requestGetJobStatistic, requestJobCategoriesFilter } from '@constants/Api';

class Screen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      [STRING.fromDate]: '',
      [STRING.toDate]: '',
      job_id: '',
      startDate: '',
      endDate: '',
      dataChart: {
        labels: [],
        datasets: [
          {
            label: 'Thống kê cuộc gọi',
            data: [],
            borderColor: '',
            fill: false,
            pointBackgroundColor: '#1b5e20',
            pointBorderColor: 'white',
            pointRadius: 5,
          },
        ],
      },
      listJobs: [],
      listReports: [],
      paging: {},
      activePage: 1,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.requestGetCallChart();
    this.requestJobCategoriesFilter();
    this.requestGetJobStatistic();
  }

  startDate = () => {
    const { [STRING.fromDate]: fromDate, [STRING.toDate]: toDate } = this.state;
    if (fromDate == '' && toDate == '') {
      let now = Date.now() - 604800000;
      let d = new Date(now);
      let date = d.getDate() > 9 ? d.getDate() : '0' + d.getDate();
      let month = d.getMonth() + 1 > 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1);
      let year = d.getFullYear();
      let startDate = year + '-' + month + '-' + date + 'T00:00:00.000Z';
      this.setState({
        ...this.state,
        startDate: startDate,
      });
    }
    if (fromDate == '' && toDate != '') {
      let now = new Date(toDate) - 604800000;
      let d = new Date(now);
      let date = d.getDate() > 9 ? d.getDate() : '0' + d.getDate();
      let month = d.getMonth() + 1 > 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1);
      let year = d.getFullYear();
      let startDate = year + '-' + month + '-' + date + 'T00:00:00.000Z';
      this.setState({
        ...this.state,
        startDate: startDate,
      });
    }
  };

  endDate = () => {
    const { [STRING.fromDate]: fromDate, [STRING.toDate]: toDate } = this.state;
    if (toDate == '') {
      let now = Date.now();
      let d = new Date(now);
      let date = d.getDate() > 9 ? d.getDate() : '0' + d.getDate();
      let month = d.getMonth() + 1 > 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1);
      let year = d.getFullYear();
      let endDate = year + '-' + month + '-' + date + 'T00:00:00.000Z';
      this.setState({
        ...this.state,
        endDate: endDate,
      });
    }
  };

  async requestGetCallChart() {
    const { [STRING.fromDate]: fromDate, [STRING.toDate]: toDate, job_id } = this.state;
    await this.startDate();
    await this.endDate();
    const { startDate, endDate } = this.state;
    let arrDate = [];
    let arrValues = [];
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const res = await requestGetCallChart({
        fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : startDate,
        toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : endDate,
        job_id: job_id || '',
        telesale_id: '',
        limit: '',
        page: 1,
      });
      let count = res?.data?.chartCall?.map((value) => {
        arrDate.push(value.quarter);
        arrValues.push(value.earnings);
      });
      this.setState({
        ...this.state,
        dataChart: {
          ...this.state.dataChart,
          labels: arrDate,
          datasets: [
            {
              label: 'Số cuộc gọi trong ngày',
              borderColor: '#aeea00',
              fill: false,
              pointBackgroundColor: '#aeea00',
              pointBorderColor: 'white',
              pointRadius: 5,
              data: arrValues,
            },
          ],
        },
        isLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async requestJobCategoriesFilter() {
    try {
      const res = await requestJobCategoriesFilter({
        enterprise_id: '',
        telesale_id: '',
        have_call: '',
      });
      this.setState({
        ...this.state,
        listJobs: res.data,
      });
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  async requestGetJobStatistic() {
    const { [STRING.fromDate]: fromDate, [STRING.toDate]: toDate, job_id, activePage } = this.state;
    await this.startDate();
    await this.endDate();
    const { startDate, endDate } = this.state;
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const res = await requestGetJobStatistic({
        fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : startDate,
        toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : endDate,
        job_id: job_id || '',
        limit: 12,
        page: activePage,
      });
      this.setState({
        ...this.state,
        listReports: res.data,
        paging: res.paging,
        isLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  handleChange = (field, value) => {
    this.setState(
      {
        ...this.state,
        [field]: value || '',
      },
      () => {
        this.requestGetCallChart();
        this.requestGetJobStatistic();
      }
    );
  };

  handleChangeSelect = (selectedOption, field) => {
    this.setState(
      {
        ...this.state,
        [field]: selectedOption.value,
      },
      () => {
        this.requestGetCallChart();
        this.requestGetJobStatistic();
      }
    );
  };

  renderTitle() {
    return (
      <>
        <Row>
          <Col md={5} sm={12}>
            <h2 className="text-left" style={{ marginBottom: '10px' }}>
              {' '}
              Báo cáo tổng hợp công việc
            </h2>
            <div class="line"></div>
          </Col>
          <br />
        </Row>
      </>
    );
  }

  renderBody() {
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <Row
            className="mt-1"
            style={{ textAlign: 'center', padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}
          >
            <Col md className="bg">
              {this.renderTitle()}
              {this.renderField()}
            </Col>
          </Row>

          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
            <Col
              md
              style={{
                padding: '30px 35px',
                backgroundColor: '#ffffff',
                borderRadius: '8px',
                boxShadow: '0 20px 80px rgba(0, 0, 0, 0.07)',
              }}
            >
              {this.renderchart()}
            </Col>
          </Row>
          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
            <Col md className="bg">
              {this.renderTable()}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderField() {
    const { job_id, [STRING.fromDate]: fromDate, [STRING.toDate]: toDate, listJobs } = this.state;
    const jobs = listJobs?.map(
      (province) =>
        new Object({
          value: province.id,
          label: province.name,
        })
    );
    jobs.unshift({ value: '', label: 'Chọn công việc' });
    return (
      <div className="w-100">
        <Row>
          <Col md={4} sm={12}>
            <ReactSelect
              onChange={(e) => this.handleChangeSelect(e, 'job_id')}
              list={jobs}
              placeholder="Chọn công việc"
            />
          </Col>
          <Col md={4} sm={12}>
            <DatePickerCustom
              className={`date-picker form-control`}
              placeholderText={STRING.fromDate}
              handleChange={this.handleChange}
              selected={fromDate}
              maxDate={new Date(toDate)}
              dateFormat="dd/MM/yyyy"
            />
          </Col>
          <Col md={4} sm={12}>
            <DatePickerCustom
              className={`date-picker form-control`}
              placeholderText={STRING.toDate}
              handleChange={this.handleChange}
              selected={toDate}
              minDate={new Date(fromDate)}
              dateFormat="dd/MM/yyyy"
            />
          </Col>
        </Row>
      </div>
    );
  }

  renderchart() {
    return (
      <div className="w-100 table-wrap">
        <span className="pb-2 d-indivne-block">
          <b>Thống kê số cuộc gọi</b>
        </span>
        <Line data={this.state.dataChart} />
        <Row>
          <b className="text-center" style={{ width: '100%' }}>
            THỐNG KÊ SỐ CUỘC GỌI
          </b>
        </Row>
      </div>
    );
  }

  handlePageChange = (page) => {
    const { search, activePage, [STRING.fromDate]: fromDate, [STRING.toDate]: toDate } = this.state;
    this.setState({ ...this.state, activePage: page }, () => this.requestGetJobStatistic());
  };

  renderPagination() {
    const { paging } = this.state;
    let pagingTable = { page: paging.page, totalItemCount: paging.count, limit: paging.limit };
    return <Pagi paging={pagingTable} handlePageChange={this.handlePageChange} />;
  }

  renderTable() {
    const { listReports, activePage } = this.state;
    return (
      <div className="w-100 table-wrap">
        <span className="pb-2 d-indivne-block">
          <b>Báo cáo chi tiết</b>
        </span>
        <Table bordered responsive className="mt-4">
          <thead>
            <tr>
              <th>STT</th>
              <th>Công việc</th>
              <th>Khách hàng</th>
              <th>Số telesale</th>
              <th>Tiến độ</th>
              <th>Trạng thái cuộc gọi</th>
              <th>Số cơ hội</th>
              <th>Thời gian</th>
            </tr>
          </thead>
          <tbody>
            {listReports?.length > 0 ? (
              listReports?.map((value, index) => (
                <tr>
                  <td>{(activePage - 1) * 12 + index + 1 || '--'}</td>
                  <td>{value?.name || '--'}</td>
                  <td>
                    <b>{value?.total_customer || '0'}</b>
                    <div>{`Chưa giao: ${value['Chưa giao việc'] || '0'}`}</div>
                    <div>{`Đã giao việc: ${value['Đã giao việc'] || '0'}`}</div>
                    <div>{`Đã nhận việc: ${value['Đã nhận việc'] || '0'}`}</div>
                    <div>{`Đã gọi: ${value['Đã gọi'] || '0'}`}</div>
                    <div>{`Từ chối: ${value['Từ chối'] || '0'}`}</div>
                  </td>
                  <td>{value?.total_telesale || '0'}</td>
                  <td>
                    {value?.total_customer
                      ? parseFloat((value['Đã gọi'] / value?.total_customer) * 100).toFixed(2) + '%' || '0'
                      : '100'}
                  </td>
                  <td>
                    <div>{`Không nghe máy: ${value['Không nghe máy'] || '0'}`}</div>
                    <div>{`Bận gọi lại sau: ${value['Bận gọi lại sau'] || '0'}`}</div>
                    <div>{`Sai thông tin: ${value['Sai thông tin'] || '0'}`}</div>
                    <div>{`Đồng ý trao đổi: ${value['Đồng ý trao đổi'] || '0'}`}</div>
                  </td>
                  <td>
                    <b>{value?.total_opportunity || '0'}</b>
                    <div>{`Chưa xử lý: ${value['Chưa xử lý'] || '0'}`}</div>
                    <div>{`Chưa tiếp nhận: ${value['Chưa tiếp nhận'] || '0'}`}</div>
                    <div>{`Đã tiếp nhận: ${value['Đã tiếp nhận'] || '0'}`}</div>
                    <div>{`Đang xử lý: ${value['Đang xử lý'] || '0'}`}</div>
                    <div>{`Thành công: ${value['Thành công'] || '0'}`}</div>
                    <div>{`Thất bại: ${value['Thất bại'] || '0'}`}</div>
                  </td>
                  <td>
                    {moment(value?.start_at).format('DD/MM/YYYY') + ' - ' + moment(value?.end_at).format('DD/MM/YYYY')}
                  </td>
                </tr>
              ))
            ) : (
              <td colSpan={8}>
                <Empty description="Không có dữ liệu" />
              </td>
            )}
          </tbody>
        </Table>
        <Row>
          <Col md={12} className="p-0" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {this.renderPagination()}
          </Col>
        </Row>
      </div>
    );
  }

  addFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalAddField: {
        ...this.state.modalAddField,
        [field]: value,
      },
    });
  };

  render() {
    const { isLoading } = this.state;
    return (
      <>
        {isLoading && <Loading />}
        {this.renderBody()}
      </>
    );
  }
}

export default withRouter(Screen);
