import { Col, Form } from 'antd';
import React from 'react';
import propTypes from 'prop-types';

const ItemForm = ({
  grid,
  label,
  name,
  rules,
  valuePropName,
  inputField,
  style,
  extra,
  normalize,
  ...props
}) => {
  return (
    <Col span={grid ? 12 : 24}>
      <Form.Item
        // style={{ marginBottom: '14px' }}
        style={{ marginBottom: '5px', ...style }}
        extra={extra}
        colon={false}
        label={label}
        name={name}
        rules={rules}
        valuePropName={valuePropName}
        normalize={normalize}
        {...props}
      >
        {inputField}
      </Form.Item>
    </Col>
  );
};

ItemForm.propTypes = {
  grid: propTypes.bool,
  label: propTypes.string,
  name: propTypes.string,
  rules: propTypes.array,
  valuePropName: propTypes.string,
  inputField: propTypes.node,
  style: propTypes.object,
  extra: propTypes.node,
  normalize: propTypes.func,
};

export default React.memo(ItemForm);
