import {
  CloudDownloadOutlined,
  EyeOutlined,
  FileProtectOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { downloadFile } from '@screens/Enterprise/ElectronicVoucher/utils';
import {
  Avatar,
  Badge,
  Button,
  Divider,
  Dropdown,
  List,
  message,
  Row,
  Skeleton,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import moment from 'moment';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PreviewFile from './PreviewFile';
import { headerService } from './service';
const FileProtectOutlinedStyled = styled(FileProtectOutlined)`
  & * {
    font-size: 22px;
    color: #fd7e14;
  }
  padding-top: 6px;
`;

const ListItemStyled = styled(List.Item)`
  cursor: pointer;
  padding-bottom: 0;
`;

const ListStyled = styled(List)`
  & .ant-list-header {
    padding-top: 0;
  }
`;

const EXPORT_TYPE = {
  SMS_HISTORY: 1,
  ZNS_HISTORY: 2,
  LIST_CALL: 3,
  LIST_CUSTOMER: 4,
  LIST_CUSTOMER_JOB: 5,
  TIME_KEEPING: 6,
  REPORT_SALES: 7,
  ZALO_MSG_HISTORY: 8,
};

const checkFile = record => {
  if (
    !record.file_path ||
    record.file_path.includes('localhost') ||
    !record.file_path.includes('.xls') ||
    !record.file_path.includes('.xlsx')
  ) {
    message.error('File không tồn tại');
    return false;
  }
  return true;
};

const ImportExport = () => {
  const state = useSelector(state => {
    return state?.userReducer;
  });

  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [countFile, setCountFile] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [callback, setCallback] = React.useState(false);

  const [loadingSpin, setLoadingSpin] = React.useState(false);

  // file and view file
  const [file, setFile] = React.useState('');
  const [fileView, setFileView] = React.useState(false);
  const [fileName, setFileName] = React.useState('');

  const uniqueIds = [...new Set(files.map(obj => obj.id))];
  const uniqueObjs = uniqueIds.map(id => files.find(obj => obj.id === id));

  React.useEffect(() => {
    setLoading(true);
    headerService
      .getFile({ page })
      .then(res => {
        setFiles(prev => (page === 1 ? res?.data : [...prev, ...res?.data]));
        setCountFile(res?.paging?.count || 0);
      })
      .finally(() => setLoading(false));
  }, [page, state?.callbackFileImportExport, callback]);

  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setPage(prev => prev + 1);
  };

  const switchTypeFile = type => {
    switch (type) {
      case EXPORT_TYPE.SMS_HISTORY:
        return 'Lịch sử gửi SMS';
      case EXPORT_TYPE.ZNS_HISTORY:
        return 'Lịch sử gửi ZNS';
      case EXPORT_TYPE.LIST_CALL:
        return 'Danh sách cuộc gọi';
      case EXPORT_TYPE.LIST_CUSTOMER:
        return 'Danh sách khách hàng';
      case EXPORT_TYPE.LIST_CUSTOMER_JOB:
        return 'Danh sách khách hàng trong giao việc';
      case EXPORT_TYPE.TIME_KEEPING:
        return 'Danh sách vị trí';
      case EXPORT_TYPE.REPORT_SALES:
        return 'Danh sách doanh số';
      case EXPORT_TYPE.ZALO_MSG_HISTORY:
        return 'Lịch sử gửi Zalo';
      default:
        return 'Không xác định';
    }
  };

  React.useEffect(() => {
    if (page === 1) return;

    setPage(1);
  }, [state?.callbackFileImportExport]);

  const handleSetFileView = file_path => {
    setFile(file_path);
    setFileView(true);
  };

  const listFile = (
    <div>
      <div
        id="scrollableDiv"
        style={{
          height: 'calc(100vh - 400px)',
          width: '400px',
          overflowY: 'auto',
          backgroundColor: '#fff',
          padding: '20px',
          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        }}
      >
        <Spin tip="Đang tải lại ..." spinning={loadingSpin}>
          <InfiniteScroll
            dataLength={uniqueObjs.length}
            next={loadMoreData}
            hasMore={uniqueObjs.length < countFile}
            loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
            endMessage={<Divider plain>Đã tải xong</Divider>}
            scrollableTarget="scrollableDiv"
          >
            <ListStyled
              header={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <span>Danh sách tập tin</span>
                  <Tooltip title="Tải lại dữ liệu">
                    <div
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        setLoadingSpin(true);
                        if (page === 1) {
                          setCallback(!callback);
                        } else {
                          setPage(1);
                        }

                        setTimeout(() => {
                          setLoadingSpin(false);
                        }, 1000);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <ReloadOutlined />
                    </div>
                  </Tooltip>
                </div>
              }
              dataSource={uniqueObjs}
              renderItem={item => (
                <ListItemStyled>
                  <List.Item.Meta
                    // style={{ paddingBottom: '0' }}
                    avatar={
                      <Avatar src="https://res.cloudinary.com/hunre/image/upload/v1640235211/vinacse/xls_tp06nb.png" />
                    }
                    title={
                      <Row
                        justify="space-between"
                        style={{ flexWrap: 'nowrap' }}
                        className="gx-m-0"
                      >
                        <div>
                          {item?.in_progress ? (
                            <Skeleton.Button size="small" active block />
                          ) : (
                            <div>{switchTypeFile(item?.type)}</div>
                          )}
                          <div
                            style={{
                              fontWeight: '500',
                              fontSize: '11px',
                              color: '#666',
                            }}
                          >
                            {moment(item?.created_at).format(
                              'HH:mm DD/MM/YYYY'
                            )}
                          </div>
                          <div>
                            {Boolean(item?.is_read) && (
                              <span
                                style={{
                                  color: '#ef8737',
                                  fontSize: '10px',
                                  marginRight: '5px',
                                  border: '1px solid',
                                  padding: '1px 8px',
                                  borderRadius: '999px',
                                }}
                              >
                                Đã xem
                              </span>
                            )}
                            {Boolean(item?.is_download) && (
                              <span
                                style={{
                                  color: 'green',
                                  fontSize: '10px',
                                  border: '1px solid',
                                  padding: '1px 8px',
                                  borderRadius: '999px',
                                }}
                              >
                                Đã tải
                              </span>
                            )}
                          </div>
                        </div>
                        {!item?.in_progress && (
                          <div
                            style={{
                              width: '80px',
                              display: 'flex',
                              justifyContent: 'end',
                              alignItems: 'center',
                            }}
                          >
                            <Space>
                              <Button
                                size="small"
                                onClick={() => {
                                  const check = checkFile(item);
                                  if (!check) return;
                                  headerService.downFile(item?.id).then(() => {
                                    if (
                                      !item.file_path ||
                                      item.file_path.includes('localhost')
                                    )
                                      return message.error(
                                        'File không tồn tại'
                                      );
                                    setFiles(prev =>
                                      prev.map(filePrev =>
                                        filePrev?.id === item?.id
                                          ? {
                                              ...filePrev,
                                              is_download: true,
                                            }
                                          : {
                                              ...filePrev,
                                            }
                                      )
                                    );

                                    switch (item?.type) {
                                      case EXPORT_TYPE.SMS_HISTORY:
                                        return downloadFile(
                                          item.file_path,
                                          'Lich_su_gui_sms'
                                        );
                                      case EXPORT_TYPE.ZNS_HISTORY:
                                        return downloadFile(
                                          item.file_path,
                                          'Lich_su_gui_zns'
                                        );
                                      case EXPORT_TYPE.LIST_CALL:
                                        return downloadFile(
                                          item.file_path,
                                          'Danh_sach_cuoc_goi'
                                        );
                                      case EXPORT_TYPE.LIST_CUSTOMER:
                                        return downloadFile(
                                          item.file_path,
                                          'Danh_sach_khach_hang'
                                        );
                                      case EXPORT_TYPE.LIST_CUSTOMER_JOB:
                                        return downloadFile(
                                          item.file_path,
                                          'Danh_sach_khach_hang_trong_giao_viec'
                                        );
                                      case EXPORT_TYPE.TIME_KEEPING:
                                        return downloadFile(
                                          item.file_path,
                                          'Danh_sach_vi_tri'
                                        );
                                      case EXPORT_TYPE.REPORT_SALES:
                                        return downloadFile(
                                          item.file_path,
                                          'Danh_sach_doanh_so'
                                        );
                                      case EXPORT_TYPE.ZALO_MSG_HISTORY:
                                        return downloadFile(
                                          item.file_path,
                                          'Danh_sach_gui_zalo'
                                        );
                                      default:
                                        message.error('File không tồn tại');
                                    }
                                  });
                                }}
                                shape="circle"
                                icon={<CloudDownloadOutlined />}
                              />
                              <Button
                                size="small"
                                onClick={() => {
                                  const check = checkFile(item);
                                  if (!check) return;
                                  headerService.readFile(item?.id).then(() => {
                                    if (
                                      !item.file_path ||
                                      item.file_path.includes('localhost')
                                    )
                                      return message.error(
                                        'File không tồn tại'
                                      );

                                    setFiles(prev =>
                                      prev.map(filePrev =>
                                        filePrev?.id === item?.id
                                          ? {
                                              ...filePrev,
                                              is_read: true,
                                            }
                                          : {
                                              ...filePrev,
                                            }
                                      )
                                    );

                                    switch (item?.type) {
                                      case EXPORT_TYPE.SMS_HISTORY:
                                        handleSetFileView(item.file_path);
                                        return setFileName('Lich_su_gui_sms');

                                      case EXPORT_TYPE.ZNS_HISTORY:
                                        handleSetFileView(item.file_path);
                                        return setFileName('Lich_su_gui_zns');

                                      case EXPORT_TYPE.LIST_CALL:
                                        handleSetFileView(item.file_path);
                                        return setFileName(
                                          'Danh_sach_cuoc_goi'
                                        );

                                      case EXPORT_TYPE.LIST_CUSTOMER:
                                        handleSetFileView(item.file_path);
                                        return setFileName(
                                          'Danh_sach_khach_hang'
                                        );

                                      case EXPORT_TYPE.LIST_CUSTOMER_JOB:
                                        handleSetFileView(item.file_path);
                                        return setFileName(
                                          'Danh_sach_khach_hang_trong_giao_viec'
                                        );

                                      case EXPORT_TYPE.TIME_KEEPING:
                                        handleSetFileView(item.file_path);
                                        return setFileName('Danh_sach_vi_tri');

                                      case EXPORT_TYPE.REPORT_SALES:
                                        handleSetFileView(item.file_path);
                                        return setFileName(
                                          'Danh_sach_doanh_so'
                                        );
                                      case EXPORT_TYPE.ZALO_MSG_HISTORY:
                                        handleSetFileView(item.file_path);
                                        return setFileName(
                                          'Danh_sach_gui_zalo'
                                        );
                                      default:
                                        message.error('File không tồn tại');
                                    }
                                  });
                                }}
                                shape="circle"
                                icon={<EyeOutlined />}
                              />
                            </Space>
                          </div>
                        )}
                      </Row>
                    }
                  />
                </ListItemStyled>
              )}
            />
          </InfiniteScroll>
        </Spin>
      </div>
      {file && (
        <PreviewFile
          fileCurrent={file}
          fileViewCurrent={fileView}
          fileNameCurrent={fileName}
          setFileCurrent={setFile}
          setFileViewCurrent={setFileView}
          setFileNameCurrent={setFileName}
        />
      )}
    </div>
  );

  return (
    <li className="nav-item">
      <Dropdown
        overlay={listFile}
        trigger={['click']}
        // onVisibleChange={this.handleVisibleChange}
        // visible={this.state.visibleNotiDropdown}
      >
        <a
          style={{ margin: '0 10px 0 0' }}
          onClick={e => {
            e.preventDefault();
          }}
        >
          <Badge
            count={countFile}
            size="small"
            overflowCount={99}
            offset={[0, 2]}
            color="#ef8737"
          >
            <FileProtectOutlinedStyled />
          </Badge>
        </a>
      </Dropdown>
    </li>
  );
};

export default ImportExport;
