import { Input } from 'antd';
import React from 'react';

const InputSearch = ({ lengthResult, onSearch }) => {
  return (
    <div>
      <Input.Search
        style={{ width: '50%' }}
        placeholder="Tìm kiếm theo tên sale..."
        allowClear
        // onChange={this.onChangeSearch}
        onChange={onSearch}
      />
      <div style={{ marginTop: '22px' }}>
        Danh sách kết quả:
        <span
          style={{
            color: 'red',
            fontWeight: 500,
            marginLeft: '5px',
          }}
        >
          {lengthResult}
        </span>
        &nbsp;kết quả
      </div>
    </div>
  );
};

export default InputSearch;
