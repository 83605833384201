import { getListhotlineNew } from '@actions/index';
import { DownOutlined } from '@ant-design/icons';
import HotlineImage from '@assets/hotline.png';
import { isCallEnded } from '@components/callProviders/CallHelper';
import { ApiClient } from '@constants/Api';
import { notifyFail } from '@utils/notify';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContainerSelectHotlineStyled, SelectHotlineStyled } from './style';

const SelectHotline = React.memo(({ call, hotlines }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [valueSelectedHotLine, setValueSelectedHotLine] = useState();
  const dispatch = useDispatch();
  const hotlineState = useSelector(state => state.hotline?.dataHotline);
  useEffect(() => {
    function defaultData(data) {
      if (data?.listHotlineOutput) {
        let hotlineDefault = data.listHotlineOutput.find(hotline => {
          return hotline.is_default_hotline;
        });
        hotlineDefault
          ? setValueSelectedHotLine(hotlineDefault.outbound_phone)
          : setValueSelectedHotLine(data?.listHotlineOutput[0]?.outbound_phone);
      } else {
        setValueSelectedHotLine(data?.listHotlineOutput[0]?.outbound_phone);
      }
    }

    hotlineState && defaultData(hotlineState);
  }, [hotlineState]);
  if (!isCallEnded(call.status) || !(hotlines.length > 0)) {
    return <></>;
  }
  console.log('value', valueSelectedHotLine);
  async function onChangeHotLine(value) {
    let res = await ApiClient.post('sale/hotlineDefault', {
      type_default: 1,
      hotline: value,
    });

    if (!res.status) {
      notifyFail('Có lỗi xảy ra. Vui lòng thử lại!');
      return;
    }
    // setValueSelectedHotLine(res.defind?.hotline)

    dispatch(getListhotlineNew());
  }
  return (
    <ContainerSelectHotlineStyled
      onClick={() => setOpenDropdown(!openDropdown)}
    >
      <div className="icon_phone">
        {/* <PhoneOutlined /> */}
        <img src={HotlineImage} alt="HotlineImage" />
        <div className="icon_down">
          <DownOutlined />
        </div>
      </div>

      <SelectHotlineStyled
        open={openDropdown}
        bordered={false}
        showArrow={false}
        size="small"
        value={valueSelectedHotLine}
        style={{ width: '100%' }}
        onChange={value => {
          console.log('ChangeHotline', value);
          onChangeHotLine(value);
        }}
        options={hotlines}
      />
    </ContainerSelectHotlineStyled>
  );
});

export default React.memo(SelectHotline);
