import { Checkbox, Empty, Space, Tag } from 'antd';
import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import '../style.css';

const  ModalAddTag = (props) => {
  const { tags, visible, onChange, onClose, onSave } = props;
  const user = useSelector((states) => states.userReducer.data);
  return (
    <Modal
      show={visible}
      onHide={() => onClose()}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      centered
    >
      <Modal.Header closeButton className="bg-primary">
        <h5 className="text-light pb-0">Chọn phân loại</h5>
      </Modal.Header>
      <Modal.Body className="custom-body">
        <Row className="mb-3">
          <Col>
            {tags.length ? (
              <div style={{ height: `${document.body.clientHeight * 0.7}px`, overflowY: 'scroll' }}>
                <Space direction="vertical">
                  {tags.map((item, index) => (
                    <Checkbox key={item.id} checked={item.isCheck} onChange={() => onChange(item.id, item.isCheck)}>
                      <Tag
                        className="tag"
                        style={{
                          margin: 0,
                          marginLeft: 20,
                          marginTop: 20,
                          paddingLeft: 2,
                          position: 'relative',
                          backgroundColor: '#EFEFEF',
                          border: 'none',
                          borderBottomLeftRadius: 0,
                          borderTopLeftRadius: 0,
                          borderBottomRightRadius: '4px',
                          borderTopRightRadius: '4px',
                          fontWeight: 'normal',
                        }}
                        key={item.id}
                        color="default"
                      >
                        <div className="d-flex justify-content-around align-items-center">
                          <div
                            style={{
                              backgroundColor: item.color,
                              borderRadius: '50%',
                              width: '10px',
                              height: '10px',
                              marginRight: '3px',
                            }}
                          ></div>
                          {item.name}
                        </div>
                      </Tag>
                    </Checkbox>
                  ))}
                </Space>
              </div>
            ) : (
              <></>
            )}
          </Col>
          <Col md={12}>{!tags.length ? <Empty description="Chưa có phân loại" /> : <></>}</Col>
        </Row>

        <Row>
          <Col className="button-wrapper" style={{ textAlign: 'center' }}>
            <Button
              variant="danger"
              style={{ background: 'red' }}
              onClick={() => {
                onClose();
              }}
              className="text-light"
            >
              Hủy
            </Button>
            <Button
              variant="success"
              style={{ background: 'green' }}
              onClick={() => {
                onSave();
              }}
              className="text-light"
            >
              Lưu
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAddTag;
