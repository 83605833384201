import { DatePicker, Radio, Space } from 'antd';
import React from 'react';
import { Button, Col, Modal, Row, FormControl } from 'react-bootstrap';
import '../style.css';
import moment from 'moment';

const ModalRecallTime = (props) => {
  const {
    visible,
    onClose,
    onSave,
    specificTimeRecall,
    onChangeSpecificTimeRecall,
    choice,
    onChangeChoice,
    note,
    onChangeNote,
  } = props;

  return (
    <Modal
      show={visible}
      onHide={() => onClose()}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      centered
    >
      <Modal.Header closeButton className="bg-primary">
        <h5 className="text-light pb-0">Thời gian gọi lại</h5>
      </Modal.Header>
      <Modal.Body className="custom-body">
        <Row className="mb-3">
          <Col md={12} className="mb-3 p-0">
            <Radio.Group value={choice} onChange={(e) => onChangeChoice(e.target.value)}>
              <Space direction="vertical">
                <Radio value={15} style={{ fontWeight: 'normal' }}>
                  15 phút sau
                </Radio>
                <Radio value={30} style={{ fontWeight: 'normal' }}>
                  30 phút sau
                </Radio>
                <Radio value={60} style={{ fontWeight: 'normal' }}>
                  1 tiếng sau
                </Radio>
                <Radio value={120} style={{ fontWeight: 'normal' }}>
                  2 tiếng sau
                </Radio>
                <Radio value={0} style={{ fontWeight: 'normal' }}>
                  Khác
                </Radio>
              </Space>
            </Radio.Group>
          </Col>

          <Col md={12} className="mb-3 p-0">
            <DatePicker
              showTime
              style={{ width: '100%' }}
              disabled={choice === 0 ? false : true}
              format="HH:mm DD/MM/YYYY"
              placeholder="Chọn thời gian"
              value={specificTimeRecall || null}
              disabledDate={(current) => {
                return current && current < moment().startOf('day');
              }}
              onChange={(date, string) => onChangeSpecificTimeRecall(date)}
            />
          </Col>

          <Col md={12} className="mb-3 p-0">
            <label>Ghi chú</label>
            <FormControl
              as="textarea"
              aria-describedby="basic-addon1"
              value={note}
              placeholder="Ghi chú"
              onChange={(e) => onChangeNote(e.target.value)}
            ></FormControl>
          </Col>
        </Row>

        <Row>
          <Col className="button-wrapper" style={{ textAlign: 'center' }}>
            <Button
              variant="danger"
              style={{ background: 'red' }}
              onClick={() => {
                onClose();
              }}
              className="text-light"
            >
              Hủy
            </Button>
            <Button
              variant="success"
              style={{ background: 'green' }}
              onClick={() => {
                onSave();
              }}
              className="text-light"
            >
              Xác nhận
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ModalRecallTime;
