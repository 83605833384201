import { requestListNoti, requestReadNoti } from '@constants/Api';
import {
  EVENT_CHANNEL,
  NOTI_TYPE,
  ROLE_WEB_SALE,
  ROUTER,
} from '@constants/Constant';
import EventEmitter from '@utils/EventEmitter';
import {
  Avatar,
  Badge,
  Divider,
  Dropdown,
  Empty,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { BellOutlined } from '@ant-design/icons';

{
  /* <svg
    width="24px"
    height="24px"
    fill="#ef8737"
    viewBox="0 0 1024 1024"
    style={{ cursor: 'pointer' }}
  >
    <path d="M816 768h-24V428c0-141.1-104.3-257.7-240-277.1V112c0-22.1-17.9-40-40-40s-40 17.9-40 40v38.9c-135.7 19.4-240 136-240 277.1v340h-24c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h216c0 61.8 50.2 112 112 112s112-50.2 112-112h216c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM512 888c-26.5 0-48-21.5-48-48h96c0 26.5-21.5 48-48 48zM304 768V428c0-55.6 21.6-107.8 60.9-147.1S456.4 220 512 220c55.6 0 107.8 21.6 147.1 60.9S720 372.4 720 428v340H304z" />
  </svg> */
}

const BellStyled = styled(BellOutlined)`
  & * {
    font-size: 20px;
    color: #fd7e14;
  }
  padding-top: 6px;
`;

const BellNotiIcon = () => <BellStyled />;

const ItemNoti = styled.div`
  cursor: pointer;
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  &:hover {
    background-color: #ffd4b3;
  }
`;
const { Paragraph } = Typography;
const StarNotiIcon = () => (
  <svg
    width="25px"
    height="25px"
    fill="white"
    viewBox="0 0 1024 1024"
    style={{ margin: '0 1px 4px 0' }}
  >
    <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 00.6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0046.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z" />
  </svg>
);

const ListNotifications = ({
  setPage,
  listNotis,
  count,
  setListNotis,
  setCountUnread,
}) => {
  const userInfo = useSelector(state => state.userReducer);
  const history = useHistory();

  return (
    <div
      style={{
        backgroundColor: 'white',
        width: '350px',
        margin: 10,
        border: '1px rgba(0,0,0,.29) solid',
        boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 18%)',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexFlow: 'nowrap',
          backgroundColor: 'white',

          justifyContent: 'space-between',
          padding: 5,
        }}
      >
        <div style={{ fontWeight: 500, fontSize: 16 }}>Thông báo</div>
        <div
          style={{ cursor: 'pointer' }}
          onClick={async () => {
            try {
              await requestReadNoti({
                id: 0,
                is_read_all: 1,
              });

              setListNotis(prev =>
                prev.map(noti => ({
                  ...noti,
                  status: 2,
                }))
              );
              setCountUnread(0);

              // setCallback(!callback);
            } catch (err) {
              console.log('err: ', err);
            }
            // this.getListNoti();
          }}
        >
          Đọc hết
        </div>
      </div>
      <div
        style={{ overflowY: 'auto', maxHeight: '400px' }}
        // ref={this.scrollRef}
        // onScroll={() => {
        //   console.log('this.scrollRef: ', this.scrollRef);
        //   if (
        //     this.scrollRef.current!.scrollTop +
        //       this.scrollRef.current!.clientHeight >=
        //     this.scrollRef.current!.scrollHeight
        //   ) {
        //     let old = this.scrollRef.current!.scrollTop
        //     if (loadMoreNotification) {
        //       console.log('loadMoreNotification: ', loadMoreNotification)
        //       this.scrollRef.current!.scrollTop = old - 50
        //       getMoreDataNotify()
        //     }
        //   }
        // }}
      >
        {listNotis.length ? (
          listNotis.map(item => (
            <div key={item.id}>
              {/* <Spin> */}
              <Tooltip
                style={{}}
                title={item.content}
                // overlayStyle={{ margin: '15px 0px 15px 10px' }}
                placement="leftTop"
              >
                <li
                  onClick={async () => {
                    const metaData = JSON.parse(item.meta_data);
                    try {
                      await requestReadNoti({
                        id: item?.id,
                        is_read_all: 0,
                      });
                      setListNotis(prev =>
                        prev.map(noti =>
                          item?.id === noti?.id
                            ? {
                                ...noti,
                                status: 2,
                              }
                            : noti
                        )
                      );
                      setCountUnread(prev => prev - 1);

                      // this.getListNoti();
                    } catch (err) {
                      console.log('err: ', err);
                    }

                    // navigate cho sale
                    if (userInfo?.data?.role_id === 5) {
                      if (
                        item?.type === NOTI_TYPE.NEW_JOB ||
                        item?.type === NOTI_TYPE.ROLL_BACK
                      ) {
                        history.push(ROUTER.JOB_SALE);
                      }

                      if (item?.type === NOTI_TYPE.TASK_RECALL) {
                        history.push(ROUTER.RECALL_SALE);
                      }
                    }

                    if (
                      item?.type === NOTI_TYPE?.OPPORTUNITY_STATUS ||
                      item?.type === NOTI_TYPE?.NEW_OPPORTUNITY ||
                      item?.type === NOTI_TYPE?.SUCCESS_OPPORTUNITY ||
                      item?.type === NOTI_TYPE?.FAIL_OPPORTUNITY ||
                      item?.type === NOTI_TYPE?.OPPORTUNITY_NOTE ||
                      item?.type === NOTI_TYPE?.OPPORTUNITY_CREATE
                    ) {
                      if (userInfo?.data?.role_id == 5) {
                        history.push(
                          ROUTER.OPPORTUNITY_DETAIL_SALE +
                            `/${metaData.opportunity_id}`
                        );
                      } else {
                        history.push(
                          ROUTER.OCCASION_DETAIL + `/${metaData.opportunity_id}`
                        );
                      }
                    }
                    if (item?.type === NOTI_TYPE?.LOCATION_REPORT) {
                      if (userInfo?.data?.role_id == ROLE_WEB_SALE) {
                        history.push(
                          ROUTER.LOCATION_REPORT_DETAIL +
                            `?telemarketingId=${metaData.user_id}`
                        );
                      } else {
                        history.push(
                          ROUTER.LOCATION_REPORT_DETAIL +
                            `?telemarketingId=${metaData.user_id}`
                        );
                      }
                    }
                    if (item?.type === NOTI_TYPE?.CALL_REVIEW) {
                      if (userInfo?.data?.role_id == ROLE_WEB_SALE) {
                        history.push(
                          ROUTER.CALL_DETAIL_SALE + `/${metaData.call_id}`
                        );
                      }
                    }
                    if (item?.type === NOTI_TYPE?.RECALL) {
                      history.push(ROUTER.RECALL_SALE);
                    }
                    if (item?.type === NOTI_TYPE.CHANGE_FROM_SALE) {
                      history.push(ROUTER.JOB_SALE);
                    }
                  }}
                >
                  <ItemNoti
                    style={item?.status !== 2 ? { fontWeight: 700 } : {}}
                  >
                    <div>
                      <Avatar
                        style={{
                          backgroundColor: '#ffce3d',
                          verticalAlign: 'middle',
                        }}
                        icon={<StarNotiIcon />}
                      />
                    </div>
                    <Paragraph
                      style={{ margin: '0 10px 0 10px' }}
                      ellipsis={{ rows: 3 }}
                    >
                      <div>{item?.content}</div>
                    </Paragraph>
                    {item?.status === 2 ? <></> : <Badge status="processing" />}
                    {}
                  </ItemNoti>
                  <Divider style={{ margin: 0 }} />
                </li>
              </Tooltip>
              {/* </Spin> */}
            </div>
          ))
        ) : (
          <Empty description="Không có thông báo" />
        )}
        <div
          style={{
            fontSize: '25px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {/* {loadMoreNotification ? <LoadingOutlined /> : <></>} */}
        </div>
        {count === listNotis.length ? (
          <></>
        ) : (
          <div
            style={{
              marginTop: '5px',
              height: '30px',
              display: 'flex',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              setPage(prev => prev + 1);
            }}
          >
            [ Xem thêm ]{/* {!loadMoreNotification ? '[ Xem thêm ]' : ''} */}
          </div>
        )}
      </div>
    </div>
  );
};

const NotiHeader = () => {
  const [page, setPage] = React.useState(1);

  const [listNotis, setListNotis] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [countUnread, setCountUnread] = React.useState(0);

  React.useEffect(() => {
    getListNoti();
    EventEmitter.addListener(EVENT_CHANNEL.NEW_NOTIFICATION, () => {
      getListNoti();
    });
  }, [page]);

  // const getListMoreNoti = async () => {
  //   try {
  //     setLoading(true);
  //     const res = await requestListNoti(page + 1);
  //     setListNotis(prev => [...prev, ...res?.data]);
  //     setPage(res.paging.page);
  //     setCount(res.paging.count);
  //     setCountUnread(res.paging.countUnread);
  //   } catch (err) {
  //     console.log('err: ', err);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const getListNoti = async () => {
    try {
      const res = await requestListNoti(page);
      setListNotis(prev => [...prev, ...res?.data]);
      setCount(res.paging.count);
      setCountUnread(res.paging.countUnread);
    } catch (err) {
      console.log('err: ', err);
    }
  };

  return (
    <li className="nav-item">
      <Dropdown
        overlay={
          <ListNotifications
            setPage={setPage}
            listNotis={listNotis}
            count={count}
            setListNotis={setListNotis}
            setCountUnread={setCountUnread}
          />
        }
        trigger={['click']}
        // onVisibleChange={handleVisibleChange}
        // visible={visibleNotiDropdown}
      >
        <a
          onClick={e => {
            e.preventDefault();
          }}
        >
          <Badge
            count={countUnread}
            size="small"
            overflowCount={99}
            offset={[0, 2]}
            color="#ef8737"
          >
            <BellNotiIcon />
          </Badge>
        </a>
      </Dropdown>
    </li>
  );
};

export default React.memo(NotiHeader);
