import React, { Component } from 'react';
import Loading from '@src/components/Loading';
import { withRouter } from 'react-router-dom';
import '@styles/RegisterRequest.css';
import { Row, Col, FormControl } from 'react-bootstrap';
import { ROUTER, SALE_STATUS_WAITING, DEFAULT_IMAGE } from '@constants/Constant';
import { connect } from 'react-redux';
import { getItemFromId } from '@utils/getItemFromId';
import { renderSelection } from '@utils/renderSelection';
import Pagination from 'react-js-pagination';
import { getRegisterList, getProvinceList } from '@src/redux/actions';
import { notifyFail } from '@utils/notify';
import { Empty, Avatar } from 'antd';
import { ReactSelect } from '@components/ReactSelect';

class Screen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      search: '',
      status: '',
      province_id: '',
      page: '',
    };
  }

  componentDidMount() {
    this.getData({ page: 1 });
    this.props.getProvinceList();
  }

  getData({ page, search, province_id, status }) {
    this.props.getRegisterList({
      page: page || 1,
      search: search || '',
      status: status || '',
      province_id: province_id || '',
    });
  }

  handleChangeSelect = (selectedOption, field) => {
    this.setState({ [field]: selectedOption.value }, () => {
      const { search, province_id, page, status } = this.state;
      this.getData({
        search: search || '',
        province_id: province_id || '',
        page: page || 1,
        status: status || '',
      });
    });
  };

  renderTitle() {
    return (
      <>
        <Row>
          <Col md={5} sm={12}>
            <h2 className="text-left" style={{ marginBottom: '10px' }}>
              {' '}
              Yêu cầu đăng ký sale
            </h2>
            <div class="line"></div>
          </Col>
        </Row>
      </>
    );
  }

  renderBody() {
    return (
      <div className="content-wrapper">
        <div className="content-header">
          <Row
            className="mt-1"
            style={{ textAlign: 'center', padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}
          >
            <Col md className="bg">
              {this.renderTitle()}
              {this.renderField()}
            </Col>
          </Row>
          <Row>
            <Col md={12} style={{ paddingLeft: '20px' }}>
              {this.renderTable()}
            </Col>
            <Col md={12} className="d-flex justify-content-center justify-content-md-end">
              {this.renderPagination()}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderField() {
    const { search, province_id, status } = this.state;
    const listProvince = this.props.provinceState.data?.map(
      (province) =>
        new Object({
          value: province.id,
          label: province.name,
        })
    );
    listProvince.unshift({ value: '', label: 'Chọn tỉnh thành phố' });

    return (
      <div className="w-100">
        <Row>
          <Col md={6} sm={12}>
            <FormControl
              type="text"
              id=""
              placeholder="Tên hoặc số điện thoại ứng viên"
              value={search}
              onChange={(e) => this.searchBarChange('search', e.target.value)}
              onKeyPress={(e) => this.handleKeyPress(e)}
              className="h-100"
            />
          </Col>
          <Col md={3} sm={12}>
            <ReactSelect
              handleChangeSelect={(e) => this.handleChangeSelect(e, 'province_id')}
              list={listProvince}
              placeholder="Chọn tỉnh thành phố"
            />
          </Col>
          <Col md={3} sm={12}>
            <FormControl
              as="select"
              id=""
              value={status}
              onChange={(e) => this.searchBarChange('status', e.target.value)}
              className="h-100"
            >
              <option value="">Trạng thái</option>
              {renderSelection(SALE_STATUS_WAITING)}
            </FormControl>
          </Col>
        </Row>
      </div>
    );
  }

  renderTable() {
    const registerList = this.props.registerState?.data?.data;
    const err = this.props.registerState?.data?.error;
    const itemsCount = this.props.registerState?.data?.paging?.totalItemCount;
    if (err) {
      notifyFail(err?.msg);
    }
    return (
      <div className="w-100 table-wrap">
        <span className="pb-2 d-inline-block">
          Kết quả lọc: <b className="text-danger">{itemsCount || 0}</b>
        </span>
        {registerList?.length ? (
          registerList.map((item) => this.renderItem(item))
        ) : (
          <Empty description={<span>Không có dữ liệu</span>} />
        )}
      </div>
    );
  }

  renderItem(item) {
    console.log('item', item);
    const saleStatus = getItemFromId(SALE_STATUS_WAITING, item.user_sale_info.user_sale_status_id);
    return (
      <Row style={{ borderRadius: '8px', marginBottom: '20px' }}>
        <Col
          md
          style={{
            padding: '20px 0px 25px 40px',
            backgroundColor: '#ffffff',
            borderRadius: '8px',
          }}
          className="req-hov"
        >
          <Row onClick={() => this.props.history.push(ROUTER.REGISTER_REQUEST_DETAIL + `/${item.id}`)}>
            <Col md={12} className="header">
              <Row>
                <Col md={22} className="lower d-flex px-0">
                  {/* <span>{item.user_sale_info.code}</span> */}
                  <Avatar
                    src={item.user_sale_info.profile_image}
                    size={50}
                    className="bg-blue text-white"
                    style={{ marginTop: '5px' }}
                  >
                    {item.user_sale_info.profile_image !== '' ? null : item.full_name[0]?.toUpperCase()}
                  </Avatar>
                </Col>
                <Col md={10}>
                  <span style={{ fontWeight: '700', fontSize: '18px', marginRight: 10 }}>{item.full_name}</span>
                  <span className={`lower ${saleStatus && saleStatus.color}`}>
                    <span style={{ fontSize: '14px' }}>{saleStatus && saleStatus?.name}</span>
                  </span>
                  <div>
                    <p
                      style={{
                        width: '90px',
                        backgroundColor: '#23A0CB',
                        textAlign: 'center',
                        padding: '3px',
                        borderRadius: '14px',
                        color: '#FFFFFF',
                      }}
                    >
                      {item.user_sale_info.work_type == 1 ? 'Fulltime' : 'Parttime'}
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={12} className="content p-0">
              <Row>
                <Col md={1} className="p-0"></Col>
                <Col md={11} className="p-0">
                  <Row className="lower" style={{ color: '#666666' }}>
                    <Col md={4} className="p-0">
                      <i className="fas fa-phone-alt icon-contact"></i>
                      <span>{item.phone || '--'}</span>
                    </Col>
                    <Col md={4} className="p-0">
                      <i className="fas fa-envelope icon-contact"></i>
                      <span>{item.email || '--'}</span>
                    </Col>
                    <Col md={4} className="p-0">
                      <i class="far fa-map-marker-alt icon-contact"></i>
                      <span>{item.user_sale_info.province?.name || '--'}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col md={3} className="footer">
              {/* <Row>
                <Col md={12} xs={6} className="upper">
                  <span>Trạng thái</span>
                </Col>
                <Col md={12} xs={5} className={`lower ${saleStatus && saleStatus.color}`}>
                  <span>{saleStatus && saleStatus?.name}</span>
                </Col>
              </Row> */}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  searchBarChange = async (field, value) => {
    this.setState(
      {
        ...this.state,
        [field]: value,
      },
      () => {
        if (field !== 'search') {
          const { search, province_id, page, status } = this.state;
          this.getData({
            search: search || '',
            province_id: province_id || '',
            page: page || 1,
            status: status || '',
          });
        }
      }
    );
  };

  handleKeyPress = (e) => {
    let { search, province_id, page, status } = this.state;
    if (e.charCode === 13) {
      search = search || '';
      page = 1;
      province_id = province_id || '';
      status = status || '';
      this.getData({
        search,
        page,
        status,
        province_id,
      });
    }
  };

  renderPagination() {
    const itemsCount = this.props.registerState?.data?.paging?.totalItemCount;
    const limit = this.props.registerState?.data?.paging?.limit;
    const { search, status, province_id } = this.state;
    return (
      <Pagination
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled
        activePage={this.state.activePage}
        itemsCountPerPage={limit}
        totalItemsCount={itemsCount}
        pageRangeDisplayed={5}
        hideNavigation
        onChange={
          (page) => {
            this.setState({
              ...this.state,
              activePage: page,
            });
            this.getData({ page, search, status, province_id });
          }
          //   this.getData(page, search, is_active))
        }
      />
    );
  }

  render() {
    const { isLoading, error } = this.props.registerState;
    let loading = <Loading />;
    if (error) {
      notifyFail(error.msg);
    }
    if (!isLoading) {
      loading = null;
    }
    return (
      <>
        {loading}
        {this.renderBody()}
        {/* {this.renderPagination()} */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  provinceState: state.ProvinceReducer,
  registerState: state.RegisterReducer,
});

const mapDispatchToProps = {
  getRegisterList,
  getProvinceList,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Screen));
