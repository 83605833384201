import { MinusCircleOutlined } from '@ant-design/icons';
import {
  requestCreateCustomerSubPhoneNumber,
  requestCustomerCreate,
  requestCustomerDetail,
  requestCustomerFind,
  requestCustomerUpdate,
  requestDestroyCustomerSubPhoneNumber,
  requestFormInsertSchemaCustomer,
  requestFullListTag,
  requestJobDetail,
  requestUpdateCustomerSubPhoneNumber,
} from '@constants/Api';
import { EMAIL_REGEX, ROUTER, STRING } from '@constants/Constant';
import {
  convertNumberLocalString,
  formatNumber,
  formatTime,
  rmVN,
} from '@constants/funcHelper';
import Loading from '@src/components/Loading';
import {
  getGeneralQuestion,
  getJobFilter,
  getListCallStatus,
} from '@src/redux/actions';
import { greenColor, greenColorHover, primaryColor } from '@styles/Color';
import '@styles/Customer.css';
import { BreadcrumbAntStyle, ButtonSystemStyle } from '@styles/Style';
import { renderSelection } from '@utils/renderSelection';
import { notifyFail, notifySuccess } from '@utils/notify';
import {
  Breadcrumb,
  Checkbox,
  Col as ColAnt,
  Collapse,
  DatePicker,
  Empty,
  Form as FormAnt,
  Input as InputAnt,
  Radio,
  Row as RowAnt,
  Select,
  Space,
} from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { Col, Form, FormControl, Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import reactotron from 'reactotron-react-js';
import { FormGroup, Input } from 'reactstrap';
import styled from 'styled-components';
import DatePickerCustom from '../../../components/DatePickerCustom';
import ModalRecallTime from '../Call/components/ModalRecallTime';
import TagCustom from './componentsInstead/TagCustom';
import mixpanel from 'mixpanel-browser';

const { Panel } = Collapse;
const { Option } = Select;
const WrapperStyle = styled.div`
  background-color: white;
  margin: 10px 0px;
  border-radius: 4px;
`;

const CollapseStyle = styled(Collapse)`
  .ant-collapse-header {
    font-size: 22px;
    font-weight: 600;
  }
  padding: 0;
`;

const MinusCircleOutlinedStyle = styled(MinusCircleOutlined)`
  svg {
    position: relative;
    top: 4px;
    margin: 0 8px;
    color: #999;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s;
  }

  svg:hover {
    color: #777;
  }

  svg:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const checkPhoneNumber = value => {
  let trimValue = '';
  if (value) {
    trimValue = value.trim();
  } else {
    return false;
  }
  const reg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4,5})$/;

  if (reg.test(trimValue)) {
    return true;
  }
  return false;
};
class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableInfo: true,
      detail: {},
      form: {
        call_id: '',
        options: [],
      },
      form_question: [],
      modal: {
        name: '',
        phone: '',
        unmasked_pnumber: '',
        email: '',
        [STRING.dob]: '',
        gender: null,
        city: '',
        address: '',
        job_id: '',
        type_customer: '',
        additional_data: {
          value: '',
        },
        re_call: '1',
        [STRING.re_time]: '',
        status: '',
        note: '',
        [STRING.time]: '',
        sub_phone: [],
        tag_ids: [],
      },
      form_insert: {},
      formInsert: {},
      defaultJobForm: [],
      jobFormQuestion: [],
      isLoading: false,
      customerList: [],
      tags: [],
      subPhone: [],
      checkConfigTag: '',
      isVisible: true,
      specificTimeRecall: '',
      recallChoice: '',
      noteRecall: '',
      convertedRecallTime: '',
    };
  }
  formRef = React.createRef();

  componentDidMount() {
    this.props.getListCallStatus();
    this.props.getGeneralQuestion();
    this.props.getJobFilter();
    this.getData();
    this.getFormInsert();
    this.getFullTags();
  }

  async getListQuestion(id) {
    if (id) {
      this.setState({
        ...this.state,
        isLoading: true,
      });
      try {
        const res = await requestJobDetail({ id: id });
        this.setState({
          ...this.state,
          defaultJobForm: res.data.defaultJobform,
          jobFormQuestion: res.data.job_form_questions,
          isLoading: false,
        });
      } catch (err) {
        this.setState(
          {
            ...this.state,
            isLoading: false,
          },
          () => notifyFail(err.msg)
        );
      }
    }
  }

  async requestCustomerFind(value) {
    try {
      const res = await requestCustomerFind(value);
      this.setState({
        ...this.state,
        customerList: value === '' ? [] : res.data,
      });
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  async createCustomer() {
    const {
      modal,
      form_question,
      form_insert,
      noteRecall,
      convertedRecallTime,
      user,
    } = this.state;
    const { [STRING.dob]: dob, re_time } = this.state.modal;
    const { id } = this.props.match?.params;
    const form_submit = form_question.filter(ques => {
      if (ques.number_result) {
        return (
          ques.number_result.length > 0 ||
          ques.number_result.length === undefined
        );
      } else {
        return ques.text_result.length > 0;
      }
    });
    if (this.validateForm()) {
      this.setState({ isLoading: true });
      const {
        name,
        phone,
        unmasked_pnumber,
        email,
        gender,
        address,
        city,
        tag_ids,
        job_id,
        note,
        status,
      } = modal;
      const form = {
        name: name,
        phone: unmasked_pnumber,
        unmasked_pnumber: unmasked_pnumber,
        email: email || '',
        department_id: 1,
        province_id: city,
        dob: dob || null,
        gender: gender,
        address: address || '',
        additional_data: form_insert,
        tag_ids: tag_ids || '',
        job: {
          job_id: job_id || '',
          time_recall: convertedRecallTime,
          note_recall: noteRecall,
          call_status_id: status || '',
          form: form_submit,
        },
      };
      try {
        if (id == null) {
          mixpanel.track(`Create customer ${name}`, { form });
          await requestCustomerCreate(form);
          this.props.history.goBack();
          notifySuccess('Thêm mới thành công!');
        } else {
          const { name, email, gender, address, phone, city } = modal;
          await requestCustomerUpdate(
            Object.assign(
              {
                id: id,
                name,
                phone: unmasked_pnumber,
                unmasked_pnumber: unmasked_pnumber,
                email: email || '',
                department_id: 1,
                province_id: city,
                dob: dob || null,
                gender,
                address: address || '',
                // tag_ids: modal.tag_ids || '',
              },
              {
                ...this.state.form_insert,
                customer_revenue: convertNumberLocalString(
                  this.state.form_insert.customer_revenue
                ),
                customer_sales: convertNumberLocalString(
                  this.state.form_insert.customer_sales
                ),
              }
            )
          );
          this.props.history.goBack();
          notifySuccess('Cập nhật thông tin thành công!');
        }
        mixpanel.track(`Update customer ${name}`, {
          userName: `${user}`,
        });
      } catch (err) {
        this.setState({ isLoading: false });
        console.log(err, 'errorrrrrrrrrr');
        notifyFail(err.msg);
        return;
      }
    }
  }

  async getData() {
    const { id } = this.props.match.params;
    if (id != null) {
      let current_formInsert = {};
      try {
        const detail = await requestCustomerDetail(id);
        const additionalDataMappings = {};
        detail.data.main.customer_data.forEach(value => {
          const key = value.customer_data_field_code;
          const v = value.value;
          current_formInsert[key] = v;
          additionalDataMappings[key] = value;
        });

        const options = detail.data.main.customer_tags.map(tag => tag.tag_id);
        const subPhoneNumber = detail.data.main.customer_phones.map(
          phone => phone.phone
        );

        this.setState({
          ...this.state,
          customer_detail: {
            detail: detail.data?.main,
            additionalDataMappings,
          },
          modal: {
            name: detail.data.main?.name,
            phone: detail.data.main?.phone,
            unmasked_pnumber: detail.data.main?.unmasked_pnumber,
            email: detail.data.main?.email,
            [STRING.dob]: Date.parse(detail.data.main?.dob),
            gender: detail.data.main?.gender,
            city: detail.data.main?.province_id,
            address: detail.data.main?.address,
            sub_phone: subPhoneNumber,
            tag_ids: options || [],
          },
          form_insert: current_formInsert,
          subPhone: detail.data.main.customer_phones,
        });

        this.formRef.current.setFieldsValue({
          subPhoneNumberList: subPhoneNumber,
        });
      } catch (err) {
        notifyFail(err.msg);
      }
    }
  }

  async getFullTags() {
    try {
      const res = await requestFullListTag({});
      const options =
        res?.data?.map(tag => ({
          ...tag,
          label: tag.name,
          value: tag.id,
        })) || [];
      this.setState({
        tags: options,
      });
    } catch (err) {
      console.log('err: ', err);
    }
  }

  async getFormInsert() {
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const formInsert = await requestFormInsertSchemaCustomer();
      this.setState({
        ...this.state,
        formInsert: formInsert.data.length
          ? formInsert.data.filter(item => item.is_active === 1)
          : [],
        isLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  toggleDisable = () => {
    this.setState({ disabled: !this.state.disabled });
  };

  validateForm() {
    const form = this.state.modal;
    if (form.name === '' || form.unmasked_pnumber === '') {
      notifyFail('Vui lòng điền đầy đủ các thông tin bắt buộc');
      return false;
    }
    if (form.email && EMAIL_REGEX.exec(form.email) === null) {
      notifyFail('Email không hợp lệ');
      return false;
    }
    return true;
  }

  handleChange = date => {
    this.setState({
      startDate: date,
    });
  };

  checkExistRadio = id => {
    let check = false;
    for (const value of this.state.form_question) {
      if (value.number_result?.length > 0 && value.number_result?.[0] === id) {
        check = true;
      }
    }
    return check;
  };

  checkExistCheckbox = id => {
    let check = false;
    for (const value of this.state.form_question) {
      if (value.number_result?.length > 0) {
        for (const item of value.number_result) {
          if (item === id) {
            check = true;
          }
        }
      }
    }
    return check;
  };

  onChangeAnswer = (existingQuestion, valueAnswer, textAnswer) => {
    if (valueAnswer) {
      existingQuestion.number_result = valueAnswer;
    } else {
      existingQuestion.text_result = textAnswer;
    }
  };

  onAnswer = (
    formQuestions,
    questionId,
    questionName,
    valueAnswer,
    textAnswer
  ) => {
    if (valueAnswer) {
      formQuestions.push({
        id: questionId,
        question: questionName,
        number_result: valueAnswer,
      });
    } else {
      formQuestions.push({
        id: questionId,
        question: questionName,
        text_result: textAnswer,
      });
    }
  };

  onHandleAnswer = (questionId, questionName, valueAnswer, textAnswer) => {
    this.setState(state => {
      const formQuestions = [...state.form_question];
      const existingQuestion = formQuestions.find(
        ques => ques.id === questionId
      );
      if (existingQuestion) {
        this.onChangeAnswer(existingQuestion, valueAnswer, textAnswer);
      } else {
        this.onAnswer(
          formQuestions,
          questionId,
          questionName,
          valueAnswer,
          textAnswer
        );
      }
      return { ...state, form_question: formQuestions };
    });
  };

  getValueModal = field => {
    if (
      ['customer_revenue', `customer_sales`, `customer_debt`].includes(field) &&
      this.state.form_insert[field]
    ) {
      console.log(1111, this.state.form_insert[field]);
      return formatNumber(
        convertNumberLocalString(this.state.form_insert[field])
      );
    }
    return this.state.form_insert[field];
  };
  renderBody() {
    const job = this.props.subJobState?.data;
    const callStatus = this.props.statusState.data;
    const { modal, defaultJobForm, jobFormQuestion } = this.state;
    const { id } = this.props.match?.params;
    return (
      <div className="content-wrapper request-body">
        <BreadcrumbAntStyle>
          {this.props.history.location?.state?.key === 'detail' ? (
            <>
              <Breadcrumb.Item>
                <a
                  onClick={() => this.props.history.push(ROUTER.CUSTOMER_SALE)}
                >
                  Danh sách khách hàng
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <a onClick={() => this.props.history.goBack()}>
                  Chi tiết khách hàng
                </a>
              </Breadcrumb.Item>
            </>
          ) : (
            <Breadcrumb.Item>
              <a onClick={() => this.props.history.goBack()}>
                Danh sách khách hàng
              </a>
            </Breadcrumb.Item>
          )}
          <Breadcrumb.Item>
            {id ? 'Cập nhật thông tin' : 'Thêm khách hàng'}
          </Breadcrumb.Item>
        </BreadcrumbAntStyle>

        <div style={{ padding: '5px 15px 0px' }}>
          <RowAnt justify="space-between">
            <ColAnt>
              <h2 style={{ color: primaryColor, fontWeight: 700 }}>
                {id ? 'Cập nhật thông tin' : 'Thêm khách hàng'}
              </h2>
            </ColAnt>

            <ColAnt>
              <div style={{ display: 'flex' }}>
                <ButtonSystemStyle
                  bgButtonColor="#ff5555"
                  bgButtonColorHover="#ff2222"
                  style={{ marginRight: '10px' }}
                  onClick={() => this.props.history.goBack()}
                >
                  Hủy
                </ButtonSystemStyle>

                <ButtonSystemStyle
                  htmlType="submit"
                  bgButtonColor={greenColor}
                  bgButtonColorHover={greenColorHover}
                  onClick={() => this.createCustomer()}
                >
                  Lưu
                </ButtonSystemStyle>
              </div>
            </ColAnt>
          </RowAnt>

          <WrapperStyle>
            <CollapseStyle defaultActiveKey={['1']} ghost>
              <Panel header="Thông tin khách hàng" key="1">
                {this.renderInfo()}
              </Panel>
            </CollapseStyle>
          </WrapperStyle>

          {!id && (
            <WrapperStyle>
              <CollapseStyle defaultActiveKey={['1']} ghost>
                <Panel header="Thông tin công việc" key="1">
                  <div className="content-header ">
                    {!id && (
                      <Row>
                        <Col md={6} sm={6}>
                          <Select
                            style={{ width: '100%' }}
                            showSearch
                            placeholder="Tên công việc"
                            onChange={value => {
                              this.jobChange('job_id', value);
                            }}
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                              return (
                                rmVN(option.children).indexOf(rmVN(input)) >= 0
                              );
                            }}
                            allowClear
                            value={modal.job_id || undefined}
                            notFoundContent={
                              <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={<span>Không có dữ liệu!</span>}
                              />
                            }
                          >
                            {job?.length > 0 &&
                              job?.map((value, index) => (
                                <Option value={value.id}>{value.name}</Option>
                              ))}
                          </Select>
                        </Col>
                      </Row>
                    )}
                  </div>

                  <Row className="mt-3">
                    <Col md={6} sm={12} xs={12}>
                      {this.renderGeneralInfo()}
                      {!id && (
                        <Row>
                          <b>Trạng thái cuộc gọi</b>
                        </Row>
                      )}
                      {!id && (
                        <Row>
                          <Radio.Group
                            className="px-5"
                            value={modal && modal.status}
                            onChange={e =>
                              this.modalFieldChange('status', e.target.value)
                            }
                          >
                            {callStatus.length > 0 &&
                              callStatus?.map((value, index) => (
                                <div>
                                  <Radio
                                    value={value.id}
                                    style={{ fontWeight: 'normal' }}
                                    className="mt-2"
                                  >
                                    {value.name}
                                  </Radio>
                                </div>
                              ))}
                          </Radio.Group>
                        </Row>
                      )}

                      {defaultJobForm?.length > 0 &&
                        defaultJobForm?.map((value, index) => (
                          <Col>
                            <Row className="mt-2">
                              <b>{value.name}</b>
                            </Row>
                            {value.type_input === 5 || value.type_input == 2 ? (
                              <Row>
                                <Radio.Group
                                  onChange={e => {
                                    this.onHandleAnswer(value.id, value.name, [
                                      e.target.value,
                                    ]);
                                  }}
                                >
                                  {value.job_form_answers.length > 0 &&
                                    value.job_form_answers.map(
                                      (item, index) => (
                                        <>
                                          {this.renderSelect(item)}
                                          {
                                            // this.checkExistRadio(item.id) === true &&
                                            item.job_form_answer_child.length >
                                              0 &&
                                              item.job_form_answer_child.map(
                                                v => (
                                                  <div
                                                    style={{ marginLeft: 25 }}
                                                  >
                                                    <Row>
                                                      <b>{v.name}</b>
                                                    </Row>
                                                    {v.type_input === 4 ? (
                                                      this.renderDate(
                                                        v.id,
                                                        v.name
                                                      )
                                                    ) : v.type_input === 3 ? (
                                                      <Checkbox.Group
                                                        onChange={e =>
                                                          this.onHandleAnswer(
                                                            v.id,
                                                            v.name,
                                                            e
                                                          )
                                                        }
                                                      >
                                                        {v.job_form_answers.map(
                                                          i => (
                                                            <>
                                                              {this.renderMultiple(
                                                                i
                                                              )}
                                                            </>
                                                          )
                                                        )}
                                                      </Checkbox.Group>
                                                    ) : v.type_input === 2 ||
                                                      v.type_input === 5 ? (
                                                      <Radio.Group
                                                        onChange={e => {
                                                          this.onHandleAnswer(
                                                            v.id,
                                                            v.name,
                                                            [e.target.value]
                                                          );
                                                        }}
                                                      >
                                                        {' '}
                                                        {v.job_form_answers.map(
                                                          i => (
                                                            <>
                                                              {this.renderSelect(
                                                                i
                                                              )}
                                                            </>
                                                          )
                                                        )}{' '}
                                                      </Radio.Group>
                                                    ) : (
                                                      this.renderText(
                                                        v.id,
                                                        v.name
                                                      )
                                                    )}
                                                  </div>
                                                )
                                              )
                                          }
                                        </>
                                      )
                                    )}
                                </Radio.Group>
                              </Row>
                            ) : value.type_input === 3 ? (
                              <Row>
                                <Checkbox.Group
                                  onChange={e => {
                                    this.onHandleAnswer(
                                      value.id,
                                      value.name,
                                      e
                                    );
                                  }}
                                >
                                  {value.job_form_answers.length > 0 &&
                                    value.job_form_answers.map(
                                      (item, index) => (
                                        <>
                                          {this.renderMultiple(item)}
                                          {
                                            // this.checkExistCheckbox(item.id) === true &&
                                            item.job_form_answer_child.length >
                                              0 &&
                                              item.job_form_answer_child.map(
                                                v => (
                                                  <div
                                                    style={{ marginLeft: 25 }}
                                                  >
                                                    <Row>
                                                      <b>{v.name}</b>
                                                    </Row>
                                                    {v.type_input === 4 ? (
                                                      this.renderDate(
                                                        v.id,
                                                        v.name
                                                      )
                                                    ) : v.type_input === 3 ? (
                                                      <Checkbox.Group
                                                        onChange={e =>
                                                          this.onHandleAnswer(
                                                            v.id,
                                                            v.name,
                                                            e
                                                          )
                                                        }
                                                      >
                                                        {v.job_form_answers.map(
                                                          i => (
                                                            <>
                                                              {this.renderMultiple(
                                                                i
                                                              )}
                                                            </>
                                                          )
                                                        )}
                                                      </Checkbox.Group>
                                                    ) : v.type_input === 2 ||
                                                      v.type_input === 5 ? (
                                                      <Radio.Group
                                                        onChange={e => {
                                                          this.onHandleAnswer(
                                                            v.id,
                                                            v.name,
                                                            [e.target.value]
                                                          );
                                                        }}
                                                      >
                                                        {' '}
                                                        {v.job_form_answers.map(
                                                          i => (
                                                            <>
                                                              {this.renderSelect(
                                                                i
                                                              )}
                                                            </>
                                                          )
                                                        )}{' '}
                                                      </Radio.Group>
                                                    ) : (
                                                      this.renderText(
                                                        v.id,
                                                        v.name
                                                      )
                                                    )}
                                                  </div>
                                                )
                                              )
                                          }
                                        </>
                                      )
                                    )}
                                </Checkbox.Group>
                              </Row>
                            ) : value.type_input === 1 ? (
                              this.renderText(value.id, value.name)
                            ) : (
                              this.renderDate(value.id, value.name)
                            )}
                          </Col>
                        ))}
                    </Col>

                    <Col md={6} sm={12} xs={12}>
                      {jobFormQuestion?.length > 0 &&
                        jobFormQuestion?.map((value, index) => (
                          <Col>
                            <Row className="mt-2">
                              <b>{value.name}</b>
                            </Row>
                            {value.type_input === 5 ||
                            value.type_input === 2 ? (
                              <Row>
                                <Radio.Group
                                  onChange={e => {
                                    this.onHandleAnswer(value.id, value.name, [
                                      e.target.value,
                                    ]);
                                  }}
                                >
                                  {value.job_form_answers.length > 0 &&
                                    value.job_form_answers.map(
                                      (item, index) => (
                                        <>
                                          {this.renderSelect(item)}
                                          {
                                            // this.checkExistRadio(item.id) === true &&
                                            item.job_form_answer_child.length >
                                              0 &&
                                              item.job_form_answer_child.map(
                                                v => (
                                                  <div
                                                    style={{ marginLeft: 25 }}
                                                  >
                                                    <Row>
                                                      <b>{v.name}</b>
                                                    </Row>
                                                    {v.type_input === 4 ? (
                                                      this.renderDate(
                                                        v.id,
                                                        v.name
                                                      )
                                                    ) : v.type_input === 3 ? (
                                                      <Checkbox.Group
                                                        onChange={e =>
                                                          this.onHandleAnswer(
                                                            v.id,
                                                            v.name,
                                                            e
                                                          )
                                                        }
                                                      >
                                                        {v.job_form_answers.map(
                                                          i => (
                                                            <>
                                                              {this.renderMultiple(
                                                                i
                                                              )}
                                                            </>
                                                          )
                                                        )}
                                                      </Checkbox.Group>
                                                    ) : v.type_input === 2 ||
                                                      v.type_input === 5 ? (
                                                      <Radio.Group
                                                        onChange={e => {
                                                          this.onHandleAnswer(
                                                            v.id,
                                                            v.name,
                                                            [e.target.value]
                                                          );
                                                        }}
                                                      >
                                                        {' '}
                                                        {v.job_form_answers.map(
                                                          i => (
                                                            <>
                                                              {this.renderSelect(
                                                                i
                                                              )}
                                                            </>
                                                          )
                                                        )}{' '}
                                                      </Radio.Group>
                                                    ) : (
                                                      v.job_form_answers.map(
                                                        i => (
                                                          <>
                                                            {this.renderText(
                                                              i.id,
                                                              i.name
                                                            )}
                                                          </>
                                                        )
                                                      )
                                                    )}
                                                  </div>
                                                )
                                              )
                                          }
                                        </>

                                        // );
                                      )
                                    )}
                                </Radio.Group>
                              </Row>
                            ) : value.type_input === 3 ? (
                              <Row>
                                <Checkbox.Group
                                  onChange={e => {
                                    this.onHandleAnswer(
                                      value.id,
                                      value.name,
                                      e
                                    );
                                  }}
                                >
                                  {value.job_form_answers.length > 0 &&
                                    value.job_form_answers.map(
                                      (item, index) => (
                                        <>
                                          {this.renderMultiple(item)}
                                          {
                                            // this.checkExistCheckbox(item.id) === true &&
                                            item.job_form_answer_child.length >
                                              0 &&
                                              item.job_form_answer_child.map(
                                                v => (
                                                  <div
                                                    style={{ marginLeft: 25 }}
                                                  >
                                                    <Row>
                                                      <b>{v.name}</b>
                                                    </Row>
                                                    {v.type_input === 4 ? (
                                                      this.renderDate(
                                                        v.id,
                                                        v.name
                                                      )
                                                    ) : v.type_input === 3 ? (
                                                      <Checkbox.Group
                                                        onChange={e =>
                                                          this.onHandleAnswer(
                                                            v.id,
                                                            v.name,
                                                            e
                                                          )
                                                        }
                                                      >
                                                        {v.job_form_answers.map(
                                                          i => (
                                                            <>
                                                              {this.renderMultiple(
                                                                i
                                                              )}
                                                            </>
                                                          )
                                                        )}
                                                      </Checkbox.Group>
                                                    ) : v.type_input === 2 ||
                                                      v.type_input === 5 ? (
                                                      <Radio.Group
                                                        onChange={e =>
                                                          this.onHandleAnswer(
                                                            v.id,
                                                            v.name,
                                                            [e.target.value]
                                                          )
                                                        }
                                                      >
                                                        {' '}
                                                        {v.job_form_answers.map(
                                                          i => (
                                                            <>
                                                              {this.renderSelect(
                                                                i
                                                              )}
                                                            </>
                                                          )
                                                        )}{' '}
                                                      </Radio.Group>
                                                    ) : (
                                                      v.job_form_answers.map(
                                                        i => (
                                                          <>
                                                            {this.renderText(
                                                              i.id,
                                                              i.name
                                                            )}
                                                          </>
                                                        )
                                                      )
                                                    )}
                                                  </div>
                                                )
                                              )
                                          }
                                        </>
                                      )
                                    )}
                                </Checkbox.Group>
                              </Row>
                            ) : value.type_input === 1 ? (
                              this.renderText(value.id, value.name)
                            ) : (
                              this.renderDate(value.id, value.name)
                            )}
                          </Col>
                        ))}

                      {!id && (
                        <Col>
                          <Row className="mt-2">
                            <b>Ghi chú công việc</b>
                          </Row>
                          <Row className="mt-2">
                            <FormControl
                              as="textarea"
                              rows={5}
                              id=""
                              placeholder="Ghi chú công việc"
                              value={modal.note}
                              onChange={e =>
                                this.modalFieldChange('note', e.target.value)
                              }
                            />
                          </Row>
                        </Col>
                      )}
                    </Col>
                  </Row>
                </Panel>
              </CollapseStyle>
            </WrapperStyle>
          )}
        </div>
      </div>
    );
  }

  renderSelect = value => {
    return (
      <Radio value={value.id} style={{ fontWeight: 'normal' }}>
        {value.name}
      </Radio>
    );
  };

  renderMultiple = value => {
    return (
      <Checkbox value={value.id} style={{ fontWeight: 'normal' }}>
        {value.name}
      </Checkbox>
    );
  };

  renderFilteroption = (value, index) => {
    const { form_insert } = this.state;
    if (['company', 'tax_id', 'position'].indexOf(value?.code) >= 0) {
      if (form_insert.customer_type !== 'Doanh nghiệp') {
        return;
      }
    }

    return value?.data_type === 'options' ? (
      <FormGroup className="text-left ">
        <Form.Label>{value.name}</Form.Label>
        <FormControl
          as="select"
          id=""
          value={this.getValueModal(value.code)}
          onChange={e => this.modalFormInsertChange(value.code, e.target.value)}
        >
          <option value="" selected>
            {value.name}
          </option>
          {value.customer_data_field_sources?.length > 0 &&
            value.customer_data_field_sources?.map((item, index) => (
              <option key={index} value={item.value}>
                {item.value}
              </option>
            ))}
        </FormControl>
      </FormGroup>
    ) : value?.data_type === 'date_time' ? (
      <FormGroup className="text-left">
        <Form.Label>{value.name}</Form.Label>
        <DatePicker
          style={{ width: '100%' }}
          placeholder={value.name}
          allowClear={false}
          value={
            this.getValueModal(value.code)
              ? moment(this.getValueModal(value.code), 'DD/MM/YYYY')
              : ''
          }
          format="DD/MM/YYYY"
          onChange={(date, dateString) => {
            this.modalFormInsertChange(
              value.code,
              moment(date).format('DD/MM/YYYY') || ''
            );
          }}
        />
      </FormGroup>
    ) : (
      <FormGroup className="text-left">
        <Form.Label>{value.name}</Form.Label>
        <FormControl
          // type={value.data_type === 'text' ? 'text' : 'number'}
          type={'text'}
          placeholder={value.name}
          value={this.getValueModal(value.code)}
          onChange={e => this.modalFormInsertChange(value.code, e.target.value)}
          disabled={value.code === 'customer_debt'}
        ></FormControl>
      </FormGroup>
    );
  };

  renderText = (id, name) => {
    return (
      <FormControl
        type="text"
        name="name"
        placeholder="Nhập câu trả lời"
        onChange={e => this.onHandleAnswer(id, name, '', e.target.value)}
      ></FormControl>
    );
  };

  renderDate = (id, name) => {
    return (
      <FormGroup>
        <Input
          type="date"
          name="date"
          id="exampleDate"
          placeholder="date placeholder"
          onChange={e => this.onHandleAnswer(id, name, '', e.target.value)}
        />
      </FormGroup>
    );
  };

  renderInfo() {
    const { modal, formInsert, customerList } = this.state;
    const { [STRING.dob]: dob } = this.state.modal;
    const { id } = this.props.match?.params;
    const provinces = [...this.props.provinceState.data];

    return (
      <div className="content-header ">
        <Row>
          <Col md={6} sm={6}>
            <FormGroup className="text-left">
              <Form.Label>Tên khách hàng(*)</Form.Label>
              <FormControl
                type="text"
                name="name"
                placeholder="Nhập tên khách hàng"
                value={modal && modal.name}
                onChange={e => this.modalFieldChange('name', e.target.value)}
              ></FormControl>

              <Form.Label className="mt-3">Số điện thoại(*)</Form.Label>
              <FormControl
                type="tel"
                name="phone"
                placeholder="Nhập số điện thoại"
                value={modal && modal.unmasked_pnumber}
                onChange={e =>
                  this.handelChangePhoneNumber(
                    'unmasked_pnumber',
                    e.target.value
                  )
                }
              ></FormControl>

              <div
                style={{
                  position: 'absolute',
                  backgroundColor: 'black',
                  width: '95%',
                  zIndex: '99999',
                }}
              >
                {customerList.map(value => (
                  <p
                    className="p-1 m-0 text-light cus-item"
                    onClick={() => {
                      this.setState({
                        ...this.state,
                        modal: {
                          ...modal,
                          name: value?.name,
                        },
                      });
                      this.props.history.push(
                        ROUTER.EDIT_CUSTOMER + '/' + value.id
                      );
                    }}
                  >
                    {value?.name + ' - ' + value?.phone + ' - ' + value?.email}
                  </p>
                ))}
              </div>

              {id ? (
                <FormAnt style={{ marginTop: '10px' }} ref={this.formRef}>
                  <FormAnt.List name="subPhoneNumberList">
                    {(fields, { add, remove }, { errors }) => {
                      let sub = '';
                      const { subPhone } = this.state;
                      return (
                        <>
                          {fields.map((field, index) => {
                            return (
                              <FormAnt.Item required={false} key={field.key}>
                                <FormAnt.Item
                                  {...field}
                                  validateTrigger={['onChange', 'onBlur']}
                                  noStyle
                                >
                                  <InputAnt
                                    placeholder="Số phụ"
                                    style={{
                                      width: fields.length > 1 ? '91%' : '100%',
                                    }}
                                    onChange={event => {
                                      sub = event.target.value;
                                    }}
                                    onBlur={async () => {
                                      if (
                                        field.key <
                                        this.state.modal.sub_phone.length
                                      ) {
                                        try {
                                          if (checkPhoneNumber(sub)) {
                                            await requestUpdateCustomerSubPhoneNumber(
                                              {
                                                phone: sub.trim(),
                                                note: '',
                                                customer_id: id,
                                                id: subPhone[field.key].id,
                                              }
                                            );
                                            notifySuccess(
                                              'Cập nhật số phụ thành công'
                                            );
                                            this.getData();
                                          }
                                        } catch (error) {
                                          console.log('error: ', error);
                                          notifyFail(error.msg);
                                        }
                                      } else {
                                        try {
                                          if (checkPhoneNumber(sub)) {
                                            await requestCreateCustomerSubPhoneNumber(
                                              {
                                                phone: sub.trim(),
                                                note: '',
                                                customer_id: id,
                                              }
                                            );
                                            notifySuccess(
                                              'Cập nhật số phụ thành công'
                                            );
                                            this.getData();
                                          }
                                        } catch (error) {
                                          console.log('error: ', error);
                                          notifyFail(error.msg);
                                          remove(field.name);
                                        }
                                      }
                                    }}
                                  />
                                </FormAnt.Item>
                                <MinusCircleOutlinedStyle
                                  className="dynamic-delete-button"
                                  onClick={async () => {
                                    try {
                                      await requestDestroyCustomerSubPhoneNumber(
                                        {
                                          id: subPhone[field.key].id,
                                          customer_id: id,
                                        }
                                      );
                                      remove(field.name);
                                    } catch (error) {
                                      console.log('error: ', error);
                                      notifyFail(error.msg);
                                    }
                                  }}
                                />
                              </FormAnt.Item>
                            );
                          })}
                          {fields.length < 5 && (
                            <FormAnt.Item>
                              <Space>
                                <span>Thêm số phụ</span>
                                <span
                                  onClick={() => {
                                    add();
                                  }}
                                  style={{
                                    fontSize: '20px',
                                    borderRadius: '50%',
                                    backgroundColor: '#7cb305',
                                    fontWeight: 'bold',
                                    padding: '3px 10px',
                                    color: '#fff',
                                    cursor: 'pointer',
                                  }}
                                >
                                  +
                                </span>
                              </Space>
                              <FormAnt.ErrorList errors={errors} />
                            </FormAnt.Item>
                          )}
                        </>
                      );
                    }}
                  </FormAnt.List>
                </FormAnt>
              ) : null}

              {!id ? (
                <>
                  <Form.Label className="mt-3">Phân loại</Form.Label>
                  <Select
                    style={{ width: '100%' }}
                    mode={this.state.checkConfigTag == 1 ? 'multiple' : null}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return (
                        rmVN(option.children.props.tagName).indexOf(
                          rmVN(input)
                        ) >= 0
                      );
                    }}
                    placeholder="Phân loại"
                    allowClear
                    maxTagCount={1}
                    value={modal && modal.tag_ids}
                    notFoundContent={
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={<span>Không có dữ liệu!</span>}
                      />
                    }
                    onChange={value => {
                      this.state.checkConfigTag == 1
                        ? this.modalFieldChange('tag_ids', value)
                        : this.modalFieldChange('tag_ids', [value]);
                    }}
                  >
                    {this.state.tags.map((item, index) => {
                      return (
                        <Select.Option key={index} value={item.value}>
                          <TagCustom
                            tagName={item.label}
                            tagColor={item.color}
                          />
                        </Select.Option>
                      );
                    })}
                  </Select>
                </>
              ) : null}

              <Form.Label className="mt-3">Email</Form.Label>
              <FormControl
                type="email"
                value={modal && modal.email}
                placeholder="Nhập email"
                onChange={e => this.modalFieldChange('email', e.target.value)}
              ></FormControl>

              <Form.Label className="mt-3">Ngày sinh</Form.Label>
              <DatePickerCustom
                className={`date-picker form-control`}
                placeholderText={STRING.dob}
                handleChange={this.modalFieldChange}
                selected={dob}
                dateFormat="dd/MM/yyyy"
                maxDate={Date.now()}
              />

              <div key="inline-radio" className="inline-radio">
                <Form.Label className="mt-3">Giới tính</Form.Label>
                <Radio.Group
                  className="px-5"
                  value={modal && modal.gender}
                  onChange={e =>
                    this.modalFieldChange('gender', e.target.value)
                  }
                >
                  <Radio value={0}>Nữ</Radio>
                  <Radio value={1}>Nam</Radio>
                </Radio.Group>
              </div>

              <Form.Label className="mt-3">Tỉnh/Thành phố</Form.Label>
              <FormControl
                as="select"
                id=""
                value={modal && modal.city}
                onChange={e => this.modalFieldChange('city', e.target.value)}
              >
                <option value="" disabled selected>
                  Tỉnh/Thành phố
                </option>
                {provinces && renderSelection(provinces)}
              </FormControl>

              <Form.Label className="mt-3">Địa chỉ thường trú</Form.Label>
              <FormControl
                type="text"
                placeholder="Nhập địa chỉ cụ thể"
                value={modal && modal.address}
                onChange={e => this.modalFieldChange('address', e.target.value)}
              ></FormControl>
            </FormGroup>
          </Col>

          <Col md={6} sm={6}>
            {formInsert?.length > 0 &&
              formInsert?.map((value, index) =>
                this.renderFilteroption(value, index)
              )}
          </Col>
        </Row>
      </div>
    );
  }
  renderGeneralInfo = () => {
    const {
      modal,
      noteRecall,
      specificTimeRecall,
      recallChoice,
      isVisible,
      convertedRecallTime,
    } = this.state;
    const { [STRING.re_time]: re_time } = this.state.modal;
    const handleCloseModal = () => {
      this.setState(prevState => ({
        ...prevState,
        isVisible: false,
        specificTimeRecall: null,
        convertedRecallTime: null,
        recallChoice: null,
      }));
    };
    const handleSaveModal = () => {
      this.setState(prevState => ({
        ...prevState,
        isVisible: false,
      }));
    };
    const handleSetSpecificTimeRecall = value => {
      this.setState(prevState => ({
        ...prevState,
        specificTimeRecall: value,
        convertedRecallTime: moment(value).format(),
      }));
    };
    const handleSetRecallChoice = value => {
      this.setState(prevState => ({
        ...prevState,
        recallChoice: value,
        convertedRecallTime: moment().add(value, 'm').format(),
      }));
    };
    const handleSetNoteCall = value => {
      this.setState(prevState => ({
        ...prevState,
        noteRecall: value,
      }));
    };
    return (
      <>
        {modal.job_id && (
          <>
            <Row>
              <div key="inline-radio" className="inline-radio">
                <Form.Label className="mt-3">Gọi lại (*)</Form.Label>
                <Radio.Group
                  className="px-5"
                  value={modal && modal.re_call}
                  onChange={e =>
                    this.modalFieldChange('re_call', e.target.value)
                  }
                >
                  <Row>
                    <Col
                      onClick={() => {
                        this.setState({
                          ...this.state,
                          isVisible: true,
                        });
                      }}
                    >
                      <Radio value="1" style={{ fontWeight: 'normal' }}>
                        Có
                      </Radio>
                    </Col>
                    <Radio value="0" style={{ fontWeight: 'normal' }}>
                      Không
                    </Radio>
                  </Row>
                </Radio.Group>
              </div>
            </Row>
            {convertedRecallTime && (
              <div>
                <b>Thời gian gọi lại: </b>
                {convertedRecallTime}
              </div>
            )}
            {noteRecall && (
              <div>
                <b>Ghi chú gọi lại: </b>
                {noteRecall}
              </div>
            )}
            <ModalRecallTime
              visible={isVisible}
              specificTimeRecall={specificTimeRecall}
              onChangeSpecificTimeRecall={handleSetSpecificTimeRecall}
              onClose={handleCloseModal}
              onSave={handleSaveModal}
              note={noteRecall}
              onChangeNote={handleSetNoteCall}
              choice={recallChoice}
              onChangeChoice={handleSetRecallChoice}
            />
          </>
        )}
        {modal.job_id && modal.re_call === 1 ? (
          <Row>
            <Col md={1}></Col>
            <Col md={3}>
              <DatePickerCustom
                className={`date-picker form-control`}
                placeholderText={STRING.re_time}
                handleChange={this.modalFieldChange}
                selected={re_time}
                dateFormat="dd/MM/yyyy"
                minDate={Date.now()}
              />
            </Col>
          </Row>
        ) : (
          ''
        )}
      </>
    );
  };

  modalFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        [field]: value,
      },
    });
  };

  handelChangePhoneNumber = (field, value) => {
    this.setState(
      {
        ...this.state,
        modal: {
          ...this.state.modal,
          [field]: value,
        },
      },
      () => this.requestCustomerFind(value)
    );
  };

  modalFormInsertChange = (field, value) => {
    console.log('field: ', field);
    console.log('value: ', value);
    if (field === `customer_sales`) {
      this.setState({
        ...this.state,
        form_insert: {
          ...this.state.form_insert,
          [field]: value,
          customer_debt:
            convertNumberLocalString(value) -
            convertNumberLocalString(this.state.form_insert.customer_revenue),
        },
      });
      return;
    }
    if (field === `customer_revenue`) {
      this.setState({
        ...this.state,
        form_insert: {
          ...this.state.form_insert,
          [field]: value,
          customer_debt:
            convertNumberLocalString(this.state.form_insert.customer_sales) -
            convertNumberLocalString(value),
        },
      });
      return;
    }
    this.setState({
      ...this.state,
      form_insert: {
        ...this.state.form_insert,
        [field]: value,
      },
    });
  };

  jobChange = (field, value) => {
    this.setState(
      {
        ...this.state,
        modal: {
          ...this.state.modal,
          [field]: value,
        },
      },
      () => this.getListQuestion(value)
    );
  };

  render() {
    const { isLoading, form_insert } = this.state;
    reactotron.log(form_insert);
    return (
      <>
        {isLoading ? <Loading /> : ''}
        {this.renderBody()}
      </>
    );
  }
}

const mapStateToProps = state => ({
  customerState: state.customerReducer,
  provinceState: state?.ProvinceReducer,
  userState: state.userReducer,
  statusState: state.callStatusReducer,
  jobState: state.generalQuestionReducer,
  subJobState: state.jobFilterReducer,
});

const mapDispatchToProps = {
  getListCallStatus,
  getGeneralQuestion,
  getJobFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Add));
