/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import { Avatar, Breadcrumb, Col, Descriptions, Empty, Image, Row, Timeline } from 'antd';
import { faEye } from '@fortawesome/fontawesome-free-regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import EventEmitter from '@utils/EventEmitter';
import { useCancellableApi } from 'src/hooks/useCancellableApi';
import { getSearchParamsInUrl, setSearchParamsInUrlVer2, randomColor } from '@constants/funcHelper';
import {
  requestGetConfigTimeKeeping,
  requestGetLocationReport,
  requestGetLocationReportDetailSummary,
} from './services/api';
import { VietnameseString } from '@constants/VietnameseString';
import GoongMapWeb from '@components/goongMapWeb/GoongMapWeb';
import LocationReportDetailFilter from './components/LocationReportDetailFilter';
import Wrapper from '@components/ui/wrapper/Wrapper';
import { BreadcrumbAntStyle, DescriptionCustomInteractionHistory, TimeLineStyle } from '@styles/Style';
import { ROUTER, BASE_URL_IMAGE } from '@constants/Constant';
import { primaryColor } from '@styles/Color';
import TimeDivider from '@components/TimeDivider';
import { CONDITION, CONDITION_STATUS, IS_LATE, NAME_OF_ATTENDANCE_TYPE, TIME_KEEPING_TYPE } from './Constants';

const StyledMapView = styled.div`
  margin: 10px 15px;
  height: 100%;
  padding-bottom: 15px;

  position: relative;
`;

const StyledLocationReportDetailFilter = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
  right: 20px;
`;

const StyledSpanColorPrimary = styled.span`
  color: ${primaryColor};
`;

const StyledSpanColorRed = styled.span`
  color: red;
`;

const TextHoverStyle = styled.span`
  font-weight: bold;
  text-decoration: underline;

  :hover {
    color: #3939e9;
  }
`;

const DivScrollStyle = styled.div`
  overflow-x: auto;
  width: 100%;
  white-space: nowrap;

  ::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    border: 6px solid #d5d5d5;
    border-radius: 100px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 100px;
    background-color: lightgray;
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: #a891a8;
  }
`;

const StyledScrollInteractionHistory = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 160px);

  padding-left: 10px;
`;

function LocationReportDetail() {
  const locationSearch = window.location.search;
  const searchParams = new URLSearchParams(locationSearch);
  const telemarketingId = searchParams.get('telemarketingId');
  const history = useHistory();
  const location = useLocation();

  const apiGetLocationReportDetailTimekeeping = useCancellableApi(requestGetLocationReport);
  const apiGetLocationReportDetailSummary = useCancellableApi(requestGetLocationReportDetailSummary);

  const [locationReportDetailSearch, setLocationReportDetailSearch] = useState({
    telemarketingId: undefined,
    categoryId: [],
    isLate: undefined,
    fromDate: moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD'),
    toDate: moment(new Date()).format('YYYY-MM-DD'),
  });

  const [locationReportDetailSummary, setLocationReportDetailSummary] = useState({
    total_check_other: 0,
    total_check_in_on_time: 0,
    total_check_in_late: 0,
    total_check_out_on_time: 0,
    total_check_out_late: 0,
    total_verify_address_not_true: 0,
    total_no_check: 0,
    id: undefined,
    profile_image: '',
    full_name: '',
  });

  const [markerData, setMarkerData] = useState([]);
  const [interactionHistoryData, setInteractionHistoryData] = useState([]);
  const [imageConfigTimeKeeping, setImageConfigTimeKeeping] = useState([]);

  const getConfigTimeKeeping = async () => {
    try {
      const { data } = await requestGetConfigTimeKeeping();
      setImageConfigTimeKeeping(data);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    const searchParamsInUrl = getSearchParamsInUrl();
    setLocationReportDetailSearch({ ...locationReportDetailSearch, ...searchParamsInUrl });
    getConfigTimeKeeping();
  }, [telemarketingId]);

  const getLocationReportDetailTimekeeping = async (payload) => {
    try {
      const { data } = await apiGetLocationReportDetailTimekeeping({
        telesale_id: payload.telemarketingId,
        department_id: [],
        category_id: payload.categoryId,
        is_late: [],
        verify_address: [],
        from_date: payload.fromDate,
        to_date: payload.toDate,
        page: 1,
        limit: 10000,
      });

      const markers = data
        .filter((el) => el.time_keepings !== null)
        .map((el) => {
          if (el.time_keepings) {
            const { time_keepings } = el;

            return {
              longitude: Number(time_keepings.longitude),
              latitude: Number(time_keepings.latitude),
              location: {
                action: el.category_name && el.category_name,
                customerName:
                  time_keepings.customer_name &&
                  time_keepings.customer_phone &&
                  `${time_keepings.customer_name} - ${time_keepings.customer_phone}`,
                addressOfThePlaceName: time_keepings.address_sale,
                distance: time_keepings.distance ? `${time_keepings.distance} m` : `0 m`,
              },
            };
          }
        });

      setMarkerData(markers);
      setInteractionHistoryData(data);
    } catch (error) {
    } finally {
    }
  };

  const getLocationReportDetailSummary = async (payload) => {
    try {
      const { data } = await apiGetLocationReportDetailSummary({
        telesale_id: payload.telemarketingId,
        category_id: payload.categoryId,
        fromDate: payload.fromDate,
        toDate: payload.toDate,
      });
      setLocationReportDetailSummary(data);
    } catch (error) {
    } finally {
    }
  };

  const debounceGetLocationReportDetailTimekeeping = useCallback(_.debounce(getLocationReportDetailTimekeeping), [500]);
  const debounceGetLocationReportDetailSummary = useCallback(_.debounce(getLocationReportDetailSummary), [500]);

  useEffect(() => {
    debounceGetLocationReportDetailTimekeeping(locationReportDetailSearch);
    debounceGetLocationReportDetailSummary(locationReportDetailSearch);

    EventEmitter.emit('hide-detail-marker-in-goong-web');
  }, [locationReportDetailSearch, telemarketingId]);

  const handleLocationReportDetailSearchChange = (key, value) => {
    setLocationReportDetailSearch({
      ...locationReportDetailSearch,
      [key]: value,
    });
    setSearchParamsInUrlVer2({ [key]: value });
  };

  return (
    <div className="content-wrapper" style={{ display: 'flex', flexDirection: 'column' }}>
      <BreadcrumbAntStyle>
        <Breadcrumb.Item>
          <a
            onClick={() => {
              if (location.state?.locationSearch) {
                history.push(ROUTER.LOCATION_REPORT + location.state.locationSearch);
              } else {
                history.goBack();
              }
            }}
          >
            {VietnameseString.location_report}
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{locationReportDetailSummary.full_name}</Breadcrumb.Item>
      </BreadcrumbAntStyle>

      <Row style={{ flex: 1 }}>
        <Col xxl={14} xl={12} sm={24} style={{ display: 'flex', flexDirection: 'column' }}>
          <Row style={{ flex: 1, minHeight: '350px' }}>
            <Col span={24}>
              <StyledMapView>
                <GoongMapWeb data={markerData} />

                <StyledLocationReportDetailFilter>
                  <LocationReportDetailFilter
                    locationReportDetailSearch={locationReportDetailSearch}
                    onLocationReportDetailSearchChange={handleLocationReportDetailSearchChange}
                    onLocationReportDetailSearchTimeChange={(from, to) => {
                      setLocationReportDetailSearch({
                        ...locationReportDetailSearch,
                        fromDate: from,
                        toDate: to,
                      });
                      setSearchParamsInUrlVer2({ fromDate: from, toDate: to });
                    }}
                  />
                </StyledLocationReportDetailFilter>
              </StyledMapView>
            </Col>
          </Row>

          <Row>
            <Wrapper>
              <DescriptionCustomInteractionHistory
                column={{ xxl: 3, xl: 2 }}
                title={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>
                      {renderAvatarSale(
                        BASE_URL_IMAGE + locationReportDetailSummary.profile_image,
                        locationReportDetailSummary.full_name
                      )}
                    </span>
                    <span style={{ fontSize: '24px' }}>{locationReportDetailSummary.full_name}</span>
                  </div>
                }
              >
                <Descriptions.Item label={VietnameseString.number_of_customer_visits}>
                  <span>{locationReportDetailSummary.total_check_other}</span>
                </Descriptions.Item>

                <Descriptions.Item label={VietnameseString.number_of_checkins_on_time}>
                  <StyledSpanColorPrimary>{locationReportDetailSummary.total_check_in_on_time}</StyledSpanColorPrimary>
                </Descriptions.Item>

                <Descriptions.Item label={VietnameseString.number_of_late_checkins}>
                  <StyledSpanColorPrimary>{locationReportDetailSummary.total_check_in_late}</StyledSpanColorPrimary>
                </Descriptions.Item>

                <Descriptions.Item label={VietnameseString.number_of_on_time_checkouts}>
                  <StyledSpanColorPrimary>{locationReportDetailSummary.total_check_out_on_time}</StyledSpanColorPrimary>
                </Descriptions.Item>

                <Descriptions.Item label={VietnameseString.number_of_early_checkouts}>
                  <StyledSpanColorPrimary>{locationReportDetailSummary.total_check_out_early}</StyledSpanColorPrimary>
                </Descriptions.Item>

                <Descriptions.Item label={VietnameseString.number_of_times_without_attendance}>
                  <StyledSpanColorRed>{locationReportDetailSummary.total_no_check}</StyledSpanColorRed>
                </Descriptions.Item>

                <Descriptions.Item label={VietnameseString.number_of_times_the_address_does_not_match}>
                  <StyledSpanColorRed>{locationReportDetailSummary.total_verify_address_not_true}</StyledSpanColorRed>
                </Descriptions.Item>
              </DescriptionCustomInteractionHistory>
            </Wrapper>
          </Row>
        </Col>

        <Col xxl={10} xl={12} sm={24} style={{ display: 'flex', flexDirection: 'column' }}>
          <Row>
            <Col span={24}>
              <Wrapper>{renderInteractionHistory(interactionHistoryData, history, imageConfigTimeKeeping)}</Wrapper>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

const renderAvatarSale = (image, saleName) => {
  try {
    if (saleName) {
      const name = saleName.trim().split(' ');

      return (
        <Avatar
          src={image}
          style={{
            backgroundColor: randomColor(name[name.length - 1].charAt(0)?.toUpperCase()),
            marginRight: '5px',
          }}
          size="large"
        >
          {name[name.length - 1].charAt(0)?.toUpperCase()}
        </Avatar>
      );
    }
  } catch (error) {
  } finally {
  }
};

const renderInteractionHistory = (interactionHistory, history, imageConfigTimeKeeping) => {
  return (
    <div style={{ width: '100%' }}>
      <div style={{ fontSize: '28px', fontWeight: 700, marginBottom: '00px', color: primaryColor }}>
        {VietnameseString.attendance_history}
      </div>
      <StyledScrollInteractionHistory>
        <TimeLineStyle>
          {interactionHistory.length > 0 ? (
            interactionHistory.map((interaction, index, array) => {
              const srcImage = imageConfigTimeKeeping.find(
                (el) => el.category_type === interaction.category_type
              )?.path;

              if (index === 0) {
                return (
                  <Fragment key={index}>
                    <TimeDivider
                      date={
                        interaction.time_keepings
                          ? interaction.time_keepings.created_at
                          : moment(interaction.time, 'DD/MM/YYYY').toString()
                      }
                    />
                    <Timeline.Item
                      key={index}
                      dot={
                        <div>
                          <img src={srcImage} style={{ width: '25px', height: '25px' }} alt="img" />
                          <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'black' }}>{index + 1}</div>
                        </div>
                      }
                    >
                      <div
                        style={{
                          backgroundColor: 'rgb(246 246 246)',
                          margin: '10px 15px',
                          padding: '8px 16px',
                          borderRadius: '8px',
                        }}
                      >
                        {renderInteractionHistoryItem(interaction, history, index + 1)}
                      </div>
                    </Timeline.Item>
                  </Fragment>
                );
              } else {
                const currentDateItem = new Date(
                  array[index].time_keepings
                    ? array[index].time_keepings.created_at
                    : moment(array[index].time, 'DD/MM/YYYY').toString()
                ).getDate();
                const prevDateItem = new Date(
                  array[index - 1].time_keepings
                    ? array[index - 1].time_keepings.created_at
                    : moment(array[index - 1].time, 'DD/MM/YYYY').toString()
                ).getDate();
                if (currentDateItem === prevDateItem) {
                  return (
                    <Timeline.Item
                      key={index}
                      dot={
                        <div>
                          <img src={srcImage} style={{ width: '25px', height: '25px' }} alt="img" />
                          <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'black' }}>{index + 1}</div>
                        </div>
                      }
                    >
                      <div
                        style={{
                          backgroundColor: '#f3f3f3',
                          margin: '10px 15px',
                          padding: '8px 16px',
                          borderRadius: '8px',
                        }}
                      >
                        {renderInteractionHistoryItem(interaction, history, index + 1)}
                      </div>
                    </Timeline.Item>
                  );
                } else {
                  return (
                    <Fragment key={index}>
                      <TimeDivider
                        date={
                          interaction.time_keepings
                            ? interaction.time_keepings.created_at
                            : moment(interaction.time, 'DD/MM/YYYY').toString()
                        }
                      />
                      <Timeline.Item
                        key={index}
                        dot={
                          <div>
                            <img src={srcImage} style={{ width: '25px', height: '25px' }} alt="img" />
                            <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'black' }}>{index + 1}</div>
                          </div>
                        }
                      >
                        <div
                          style={{
                            backgroundColor: '#f3f3f3',
                            margin: '10px 15px',
                            padding: '8px 16px',
                            borderRadius: '8px',
                          }}
                        >
                          {renderInteractionHistoryItem(interaction, history, index + 1)}
                        </div>
                      </Timeline.Item>
                    </Fragment>
                  );
                }
              }
            })
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>Không có lịch sử.</span>} />
          )}
        </TimeLineStyle>
      </StyledScrollInteractionHistory>
    </div>
  );
};

const renderInteractionHistoryItem = (interaction, history, indexInteractionHistory) => {
  if (!interaction.time_keepings) {
    return (
      <div>
        <div>{renderInteractionHistoryHeader(interaction.category_name)}</div>

        <Descriptions column={1}>
          <Descriptions.Item label="Trạng thái">
            <span style={{ color: 'red', fontWeight: 'bold' }}>{VietnameseString.no_attendance}</span>
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  } else {
    return (
      <div>
        {renderInteractionHistoryHeader(interaction.time_keepings.category_name, interaction.time_keepings.created_at)}
        {renderInteractionHistoryContent(interaction, history, indexInteractionHistory)}
      </div>
    );
  }
};

const renderInteractionHistoryHeader = (actionName, time) => {
  return (
    <div>
      <p style={{ fontWeight: 700, display: 'inline-block', fontSize: '18px', color: primaryColor }}>{actionName}</p>
      <>{time && ` (${moment(time).format('HH:mm')})`}</>
    </div>
  );
};

const renderInteractionHistoryContent = (interaction, history, indexInteractionHistory) => {
  const { time_keepings } = interaction;

  const isLate = TIME_KEEPING_TYPE.find((el) => el.id === time_keepings.is_late);

  const condition = CONDITION.find((el) => el.id === time_keepings.verify_address);

  const isCheckin_out = NAME_OF_ATTENDANCE_TYPE.find((el) => el.id === interaction.category_type);

  return (
    <Descriptions column={1}>
      {isLate.name && isCheckin_out && (
        <Descriptions.Item label={VietnameseString.status}>
          <span style={{ color: isLate.id === IS_LATE.DUNG_GIO ? 'black' : primaryColor, fontWeight: 'bold' }}>
            {isLate.name}
          </span>
        </Descriptions.Item>
      )}

      {(time_keepings?.customer_name || time_keepings?.customer_phone) && (
        <Descriptions.Item label="Tên - SĐT khách hàng">
          <TextHoverStyle
            onClick={() =>
              time_keepings.customer_id &&
              history.push({
                pathname: `${ROUTER.CUSTOMER_DETAIL}/${time_keepings.customer_id}`,
              })
            }
          >
            <span>{time_keepings?.customer_name ? time_keepings?.customer_name : ''}</span>
            <span> - </span>
            <span>{time_keepings?.customer_phone ? time_keepings?.customer_phone : ''}</span>
          </TextHoverStyle>
        </Descriptions.Item>
      )}

      {time_keepings.address && (
        <Descriptions.Item label={VietnameseString.address_of_the_place_name}>
          {time_keepings.address}
        </Descriptions.Item>
      )}

      <Descriptions.Item label="Địa chỉ định vị">
        {renderLocatorAddress(interaction, indexInteractionHistory)}
      </Descriptions.Item>

      {time_keepings.distance && (
        <Descriptions.Item label={VietnameseString.distance}>{time_keepings.distance} m</Descriptions.Item>
      )}

      {condition.name && (
        <Descriptions.Item label={VietnameseString.condition}>
          <span
            style={{
              fontWeight: 'bold',
              color: condition.id === CONDITION_STATUS.KHONG_TRUNG_KHOP ? 'red' : 'green',
            }}
          >
            {condition.name}
          </span>
        </Descriptions.Item>
      )}

      {time_keepings.note && <Descriptions.Item label={VietnameseString.note}>{time_keepings.note}</Descriptions.Item>}

      {time_keepings.time_keeping_images.length > 0 && (
        <Descriptions.Item label="File ảnh">
          <DivScrollStyle>
            {time_keepings.time_keeping_images.map((img, index) => (
              <Image
                key={index}
                preview={{
                  mask: (
                    <>
                      <FontAwesomeIcon icon={faEye} />
                      Phóng to
                    </>
                  ),
                }}
                style={{
                  height: '140px',
                  objectFit: 'cover',
                  padding: '10px 10px',
                }}
                src={`${BASE_URL_IMAGE}${img.path}`}
                alt=""
              />
            ))}
          </DivScrollStyle>
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};

const renderLocatorAddress = (interaction, indexInteractionHistory) => {
  const { time_keepings } = interaction;

  return (
    <TextHoverStyle
      onClick={() => {
        return EventEmitter.emit('show-detail-marker-in-goong-web', {
          longitude: time_keepings.longitude,
          latitude: time_keepings.latitude,
          location: {
            action: interaction.category_name && `${indexInteractionHistory}. ${interaction.category_name}`,
            customerName:
              time_keepings.customer_name &&
              time_keepings.customer_phone &&
              `${time_keepings.customer_name} - ${time_keepings.customer_phone}`,
            addressOfThePlaceName: time_keepings.address,
            distance: time_keepings.distance ? `${time_keepings.distance} m` : `0 m`,
          },
        });
      }}
    >
      {time_keepings.latitude}, {time_keepings.longitude}
    </TextHoverStyle>
  );
};

export default LocationReportDetail;
