import React, { Component } from 'react';
import { Row, Col, FormControl, Button, Modal, FormGroup, Form } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import StepAdd from '@screens/Enterprise/Job/Add/Component/Context/_StepAdd';
import { QUESTION_TYPE } from '@constants/Constant';

class ModalAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: true,
      type: 0,
    };
  }

  handleTypeChange(value) {
    setTimeout(() => this.props.handle('type_input', value), 100);
    if (value == QUESTION_TYPE.YES_NO) {
      this.addOption(true);
    } else {
      this.resetOption();
    }
  }

  resetOption() {
    this.props.handle('job_form_answers', []);
  }

  addOption(isYesNoQues) {
    if (isYesNoQues) {
      this.props.handle('job_form_answers', [
        {
          name: 'Có',
          value: this.props.value.job_form_answers.length,
          job_form_answer_child: [],
        },
        {
          name: 'Không',
          value: this.props.value.job_form_answers.length,
          job_form_answer_child: [],
        },
      ]);
    } else {
      this.props.handle('job_form_answers', [
        ...this.props.value.job_form_answers,
        {
          name: 'Nhập thông tin vào đây',
          value: this.props.value.job_form_answers.length,
          job_form_answer_child: [],
        },
      ]);
    }
  }

  renderOption = () => {
    return (
      <>
        <Row className="modal-row">
          <Col md={5} className="">
            <Modal.Header>Lựa chọn</Modal.Header>
          </Col>
          <Col md={7} className="px-0">
            <i
              className={`fas fa-plus text-green add-btn ${
                this.props.value.type_input == QUESTION_TYPE.YES_NO ? ' not-allowed' : null
              }`}
              onClick={this.props.value.type_input != QUESTION_TYPE.YES_NO ? () => this.addOption() : null}
            ></i>
          </Col>
          {this.props.value.job_form_answers.map((item, index) => (
            <Col sm={12}>
              <StepAdd
                content={item}
                handle={this.props.handle}
                index={index}
                option={this.props.value.job_form_answers}
                isYesNoQues={this.props.value.type_input == QUESTION_TYPE.YES_NO}
              />
            </Col>
          ))}
        </Row>
      </>
    );
  };

  render() {
    const { value } = this.props;
    const { parent, parentOption } = value;
    return (
      <>
        <Modal
          show={this.props.show}
          onHide={() => this.props.close('subModalAdd', false)}
          dialogClassName="modal-90w"
          centered
        >
          <Modal.Header closeButton className="text-white bg-blue">
            <h5 className="text-light">Câu hỏi phụ</h5>
          </Modal.Header>

          <Modal.Body className="custom-body">
            <FormGroup className="text-left">
              <Row className="modal-row">
                <Col md={5}>
                  <Modal.Header>Câu hỏi chính:</Modal.Header>
                </Col>
                <Col md={7}>
                  <span>{parent && parent.name}</span>
                </Col>
              </Row>

              <Row className="modal-row">
                <Col md={5}>
                  <Modal.Header>Câu trả lời của khách hàng:</Modal.Header>
                </Col>
                <Col md={7}>
                  <span>{parentOption && parentOption.name}</span>
                </Col>
              </Row>

              <Row className="modal-row">
                <Col md={5}>
                  <Modal.Header>Nội dung câu hỏi phụ</Modal.Header>
                </Col>
                <Col md={7}>
                  <FormControl
                    type="text"
                    placeholder="Nhập câu hỏi"
                    value={value.name}
                    onChange={(e) => this.props.handle('name', e.target.value)}
                  ></FormControl>
                </Col>
              </Row>

              <Row className="modal-row">
                <Col md={5}>
                  <Modal.Header>Loại câu hỏi</Modal.Header>
                </Col>
                <Col md={7}>
                  <FormControl
                    value=""
                    as="select"
                    id=""
                    value={value.type_input}
                    onChange={(e) => this.handleTypeChange(e.target.value)}
                  >
                    <option value={QUESTION_TYPE.TEXT}>Nhập chữ</option>
                    <option value={QUESTION_TYPE.SELECT}>Chọn 1 đáp án</option>
                    <option value={QUESTION_TYPE.MULTIPLE_SELECT}>Chọn nhiều đáp án</option>
                    <option value={QUESTION_TYPE.DATE}>Nhập ngày</option>
                    <option value={QUESTION_TYPE.YES_NO}>Chọn có hoặc không</option>
                  </FormControl>
                </Col>
              </Row>
              <Row className="modal-row">
                <Col md={5}>
                  <Modal.Header>Điểm kinh nghiệm</Modal.Header>
                </Col>
                <Col md={7}>
                  <FormControl
                    type="number"
                    placeholder="Nhập điểm kinh nghiệm"
                    value={value.reward_point}
                    onChange={(e) => this.props.handle('reward_point', e.target.value)}
                  ></FormControl>
                </Col>
              </Row>

              {[QUESTION_TYPE.SELECT, QUESTION_TYPE.MULTIPLE_SELECT, QUESTION_TYPE.YES_NO].includes(
                parseInt(value.type_input)
              )
                ? this.renderOption()
                : null}

              <Row className="modal-row">
                <Col md={5}>
                  <Modal.Header>Bắt buộc</Modal.Header>
                </Col>
                <Col md={7} className="px-0">
                  <Form.Check
                    type="checkbox"
                    className="px-0 require-check"
                    onChange={(e) => this.props.handle('is_require', e.target.checked)}
                    checked={value.is_require}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button
              variant="success"
              className="text-light"
              onClick={() => this.props.add(value.parent.id, value.parentOption.id)}
            >
              Tạo mới
            </Button>
            <Button variant="danger" className="text-light" onClick={() => this.props.close('modalAdd', false)}>
              Đóng
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ModalAdd;
