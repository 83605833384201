import { getPhoneNumber } from '@actions/';
import Loading from '@components/Loading';
import { CALLING_CODE } from '@components/callProviders/CallConstant';
import { isCallEnded } from '@components/callProviders/CallHelper';
import CallUtil from '@components/callProviders/CallUtil';
import { ROUTER } from '@constants/Constant';
import { notifyFail } from '@utils/notify';
import React, { memo, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { requestStartCallWithNumber } from 'src/network/web_sale/CallApi';
import DTMFNumpad from './NumberPad/DTMFNumpad';
import NomalNumpad from './NumberPad/NomalNumpad';
import SelectHotline from './NumberPad/SelectHotline';
import {
  ButtonCallStyled,
  FunctionKeyStyle,
  InputPhoneStyled,
  hangupCallStyle,
} from './NumberPad/style';
import { useHistory } from 'react-router-dom';
import './numberPadStyle.css';
import { message } from 'antd';
import EZLoadingBox from 'src/modules/loading/EZ.Loading.Box';
import EZloadingOverlay from 'src/modules/loading/EZ.loading.Overlay';

function NumberPad(props) {
  const call = props.callingStatus;
  const { masked } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const phone = useSelector(state => state.phoneNumber.data);
  const [showNumb, setShowNumb] = useState(false);
  const [loadingButtonCall, setLoadingButtonCall] = useState(false);
  const [hotlines, setListHotline] = useState([]);
  const selectedHotlineIndexRef = useRef(-1);
  const [isStartCall, setIsStartCall] = useState(false);
  // let hotlineState = useSelector(state => state.hotline?.dataHotline);

  const getHotlines = () => {
    try {
      const formattedHotlines = CallUtil?.hotlines.map((hotline, index) => {
        return {
          value: hotline.outbound_phone,
          label: hotline.outbound_phone,
          data: hotline,
        };
      });
      setListHotline(formattedHotlines);
    } catch (error) {
      console.log(error);
    } finally {
      // setIsLoadingHotline(true);
    }
  };

  useEffect(() => {
    getHotlines();
  }, [CallUtil?.hotlines]);

  const onChangeNumber = value => {
    if (!value) {
      dispatch(getPhoneNumber({ number: value }));
    } else {
      // check if regex passed, pattern contain only digits *,+,=
      const regex = new RegExp('^[0-9*#+]+$');
      if (regex.test(value)) {
        dispatch(getPhoneNumber({ number: value }));
      }
    }
  };

  const onClickNumberPad = value => {
    let number = phone.number || '';
    const newStr = number.concat(value);
    dispatch(getPhoneNumber({ number: newStr }));

    if (inputRef.current) {
      inputRef.current.focus({
        cursor: 'end',
      });
    }
  };

  const onClickNumberDtmf = dtmfNumber => {
    CallUtil.sendDtmf(dtmfNumber);
  };

  const onClickDelete = () => {
    let number = phone.number || '';
    if (number.trim()) {
      const newStr = number.substring(0, number.length - 1);
      dispatch(getPhoneNumber({ number: newStr }));

      if (inputRef.current) {
        inputRef.current.focus({
          cursor: 'end',
        });
      }
    }
  };

  const handleMakeCall = async phone => {
    console.log('Make call from number pad');
    setLoadingButtonCall(true);
    setIsStartCall(true);
    let hotline = CallUtil.getDefaultHotlines();
    try {
      const res = await requestStartCallWithNumber({
        phone_number: phone.toString(),
        hotline: hotline?.outbound_phone,
      });
      console.log('🚀 ~ file: NumberPad.js:175 ~ handleMakeCall ~ res:', res);
      CallUtil.currentJobId = null;
      const isMakeCallSuccess = CallUtil.makeCall(
        hotline?.type,
        phone,
        res.data.id,
        phone
      );
      console.log(
        '=======================RequestMakeCall=========================',
        isMakeCallSuccess
      );

      if (!isMakeCallSuccess) {
        message.error('Không thể thực hiện cuộc gọi');
      } else {
        // thành công navigate đến phiếu kết quả
        const router = {
          pathname: ROUTER.CALLING_SALE,
          search: `?call_id=${res.data?.id}&customer_id=${res?.data?.customer_id}`,
        };

        history.replace(router);
      }
      setLoadingButtonCall(false);
      setIsStartCall(false);
    } catch (error) {
      setLoadingButtonCall(false);
      setIsStartCall(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (isCallEnded(call.status)) {
      dispatch(getPhoneNumber({ number: '' }));
      setLoadingButtonCall(false);
    } else if (masked) {
      dispatch(getPhoneNumber({ number: masked }));
    }
  }, [masked, call]);

  return (
    <div>
      {isStartCall && <EZloadingOverlay />}
      <Row>
        <SelectHotline
          call={call}
          selectedHotlineIndexRef={selectedHotlineIndexRef}
          hotlines={hotlines}
        />
        <Col
          style={{
            border: 20,
            display: 'flex',
            justifyContent: 'center',
            padding: '0 15px 0 15px',
          }}
        >
          <div style={{ padding: '0 30px 0 30px' }}>
            <Row justify="center" style={{ padding: '20px 10px 0 10px' }}>
              <Col>
                <InputPhoneStyled
                  allowClear={isCallEnded(call.status)}
                  bordered={false}
                  style={{
                    width: '100%',
                    height: '40px',
                    textAlign: 'center',
                  }}
                  value={phone.number || ''}
                  onChange={e => {
                    onChangeNumber(e.target.value.trim());
                  }}
                  autoFocus={true}
                  ref={inputRef}
                />
              </Col>
            </Row>
            {/* <h2>{callingStatus} </h2> */}
            {!isCallEnded(call.status) ? (
              <>
                <div
                  style={
                    showNumb
                      ? {
                          display: 'flex',
                          flexFlow: 'nowrap',
                          justifyContent: 'space-between',
                          margin: '0 0 10px 0',
                        }
                      : {
                          display: 'flex',
                          flexFlow: 'nowrap',
                          justifyContent: 'space-between',
                          margin: '70px 0 0 0',
                        }
                  }
                >
                  <div style={FunctionKeyStyle}>
                    <i class="fas fa-microphone-slash fa-lg"></i>
                  </div>
                  <div
                    style={FunctionKeyStyle}
                    onClick={() => setShowNumb(!showNumb)}
                  >
                    <i class="fas fa-th fa-lg"></i>
                  </div>
                </div>

                {!showNumb ? (
                  <></>
                ) : (
                  <DTMFNumpad onClickNumberDtmf={onClickNumberDtmf} />
                )}
                <div
                  style={
                    showNumb
                      ? { display: 'flex', justifyContent: 'center' }
                      : {
                          display: 'flex',
                          justifyContent: 'center',
                          margin: '150px 0 0 0',
                        }
                  }
                >
                  {call.status !== CALLING_CODE.Preparing ? (
                    <div
                      style={hangupCallStyle}
                      onClick={() => CallUtil.hangupCall()}
                    >
                      <i class="fas fa-phone-alt fa-lg"></i>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <NomalNumpad
                btnCall={
                  isCallEnded(call.status) ? (
                    <ButtonCallStyled
                      loading={loadingButtonCall ? 1 : 0}
                      className="number-btn number-btn--green"
                      onClick={() => {
                        if (
                          phone.number === '+' ||
                          phone.number === '#' ||
                          phone.number === '*'
                        ) {
                          notifyFail('Vui lòng nhập số điện thoại!');
                        } else {
                          if (
                            !loadingButtonCall &&
                            phone.number.length > 0
                            // &&
                            // selectedHotlineIndexRef.current >= 0
                          ) {
                            setLoadingButtonCall(true);
                            handleMakeCall(phone.number);
                          }
                        }
                      }}
                    >
                      <i class="fas fa-phone-alt fa-sm"></i>
                    </ButtonCallStyled>
                  ) : (
                    // )
                    <div
                      className="number-btn number-btn--green"
                      style={{ pointerEvents: 'none', opacity: 0.4 }}
                    >
                      <i class="fas fa-phone-alt fa-sm"></i>
                    </div>
                  )
                }
                onClickDelete={onClickDelete}
                onClickNumberPad={onClickNumberPad}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default memo(NumberPad);
