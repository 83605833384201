import React from 'react';
import { Divider } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import { DividerTextStyle } from '@styles/Style';

function TimeDivider(props) {
  const { date } = props;

  if (
    new Date().getDate() === new Date(date).getDate() &&
    new Date().getMonth() === new Date(date).getMonth() &&
    new Date().getFullYear() === new Date(date).getFullYear()
  ) {
    return (
      <Divider>
        <DividerTextStyle>Hôm nay</DividerTextStyle>
      </Divider>
    );
  } else if (
    new Date().getDate() - new Date(date).getDate() === 1 &&
    new Date().getMonth() === new Date(date).getMonth() &&
    new Date().getFullYear() === new Date(date).getFullYear()
  ) {
    return (
      <Divider>
        <DividerTextStyle>Hôm qua</DividerTextStyle>
      </Divider>
    );
  } else {
    return (
      <Divider>
        <DividerTextStyle>{moment(date).format('DD/MM/YYYY')}</DividerTextStyle>
      </Divider>
    );
  }
}

export default TimeDivider;

TimeDivider.PropTypes = {
  date: PropTypes.string,
};
