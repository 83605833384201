import { SearchOutlined } from '@ant-design/icons';
import { requestFullListTag, requestListProduct } from '@constants/Api';
import { rmVN, setSearchParamsInUrlVer2 } from '@constants/funcHelper';
import { requestGetCustomerSource } from '@screens/Enterprise/Job/services/api';
import TagCustom from '@screens/Sale/Customer/componentsInstead/TagCustom';
import { Col, DatePicker, Empty, Input, Row, Select, Tooltip } from 'antd';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { requestGetOpportunities } from 'src/network/web_sale/GeneralApi';
import { requestGetJobList } from 'src/network/web_sale/JobApi';

Filter.propTypes = {};
const { Option } = Select;
const { RangePicker } = DatePicker;

const colSpan = {
  xxl: 4,
  xl: 6,
  lg: 8,
  sm: 12,
  xs: 24,
};

function Filter({ params, setParams, handleChangeParamSearchCustomers }) {
  const [opportunityList, setOpportunityList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [tags, setTags] = useState([]);
  const [listProduct, setListProduct] = useState([]);
  const [sourceCustomers, setSourceCustomers] = React.useState([]);

  useEffect(() => {
    getProduct();
    getOpportunityList();
    getJobList();
    getFullTags();
    getSourceCustomers();
  }, []);

  const getFullTags = async () => {
    try {
      const res = await requestFullListTag({});
      const options =
        res?.data?.map(tag => ({
          ...tag,
          label: tag.name,
          value: tag.id,
        })) || [];
      setTags(options);
    } catch (err) {
      console.log('Error getAllTag: ', { err });
    }
  };

  const getJobList = async () => {
    try {
      const res = await requestGetJobList({
        search: '',
        page: 1,
        limit: 1000,
      });
      setJobList(res.data);
    } catch (error) {
      console.log('Error getJobList: ', { error });
    }
  };
  const getOpportunityList = async (callId, customerId) => {
    try {
      const res = await requestGetOpportunities({
        call_id: callId,
        customer_id: customerId,
      });
      setOpportunityList(res.data);
    } catch (error) {
      console.log('Error getOpportunityList: ', { error });
    }
  };
  const getProduct = async () => {
    try {
      const res = await requestListProduct({});
      const options = res?.data?.map(item => ({
        ...item,
        label: item.name,
        value: item.id,
      }));
      if (options) {
        setListProduct(options);
      }
    } catch (err) {
      console.log('Error getProduct: ', { err });
    }
  };

  const getSourceCustomers = async () => {
    try {
      const { data } = await requestGetCustomerSource();

      setSourceCustomers(data || []);
    } catch (error) {
      console.log('Error getSourceCustomers: ', { error });
    }
  };

  return (
    <Row gutter={(16, 16)} style={{ marginBottom: 10 }}>
      <Col {...colSpan}>
        <Input
          allowClear
          style={{ width: '100%', marginTop: 10 }}
          prefix={<SearchOutlined />}
          placeholder="Khách hàng, số điện thoại..."
          value={params?.search}
          onSearch={searchKey =>
            handleChangeParamSearchCustomers('search', searchKey)
          }
          onChange={e =>
            handleChangeParamSearchCustomers('search', e.target.value)
          }
        />
      </Col>

      <Col {...colSpan}>
        <Select
          style={{ width: '100%', marginTop: 10 }}
          mode="multiple"
          optionFilterProp="children"
          filterOption={(input, option) => {
            return rmVN(option.children).indexOf(rmVN(input)) >= 0;
          }}
          placeholder="Nguồn khách hàng"
          allowClear
          maxTagCount={1}
          notFoundContent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>Không có dữ liệu!</span>}
            />
          }
          onChange={value => {
            handleChangeParamSearchCustomers('customer_source', value);
          }}
          value={params?.customer_source || []}
        >
          {sourceCustomers.map(source => (
            <Option key={source.value} value={source.value}>
              {source.value}
            </Option>
          ))}
        </Select>
      </Col>

      {/* <Col {...colSpan}>
        <Select
          style={{ width: '100%', marginTop: 10 }}
          showSearch
          allowClear
          mode="multiple"
          maxTagCount={1}
          placeholder="Nội dung giao việc"
          optionFilterProp="children"
          filterOption={(input, option) => {
            return rmVN(option.children).indexOf(rmVN(input)) >= 0;
          }}
          filterSort={(optionA, optionB) =>
            optionA?.children
              ?.toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
          notFoundContent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>Không có dữ liệu!</span>}
            />
          }
          onChange={value => {
            handleChangeParamSearchCustomers('job_id', value);
          }}
          value={params.job_id.length > 0 ? params.job_id : undefined}
        >
          {jobList.map((item, index) => (
            <Option key={index} value={item.job_id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Col> */}

      <Col {...colSpan}>
        <Select
          style={{ width: '100%', marginTop: 10 }}
          showSearch
          allowClear
          maxTagCount={1}
          placeholder="Loại cơ hội"
          optionFilterProp="children"
          filterOption={(input, option) => {
            return rmVN(option.children).indexOf(rmVN(input)) >= 0;
          }}
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
          notFoundContent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>Không có dữ liệu!</span>}
            />
          }
          onChange={value => {
            handleChangeParamSearchCustomers('type_id', value || '');
          }}
          value={params.type_id ? params.type_id : undefined}
        >
          {opportunityList
            ? opportunityList.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.name}
                </Option>
              ))
            : ''}
        </Select>
      </Col>

      <Col {...colSpan}>
        <Select
          style={{ width: '100%', marginTop: 10 }}
          showSearch
          allowClear
          placeholder="Trạng thái cơ hội"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
          notFoundContent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>Không có dữ liệu!</span>}
            />
          }
          onChange={value => {
            handleChangeParamSearchCustomers('status', value || '');
          }}
          value={params?.status ? params?.status : undefined}
        >
          <Option value={1}>Khách hàng tiềm năng</Option>
          <Option value={3}>Chốt hợp đồng</Option>
          <Option value={4}>Khách hàng từ chối</Option>
        </Select>
      </Col>

      <Col {...colSpan}>
        <Select
          style={{ width: '100%', marginTop: 10 }}
          showSearch
          allowClear
          mode="multiple"
          placeholder="Sản phẩm"
          optionFilterProp="children"
          filterOption={(input, option) => {
            return rmVN(option.children).indexOf(rmVN(input)) >= 0;
          }}
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
          notFoundContent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>Không có dữ liệu!</span>}
            />
          }
          onChange={value => {
            handleChangeParamSearchCustomers('product_id', value);
          }}
          value={params.product_id ? params.product_id : undefined}
        >
          {listProduct
            ? listProduct.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.name}
                </Option>
              ))
            : ''}
        </Select>
      </Col>

      <Col {...colSpan}>
        <Select
          style={{ width: '100%', marginTop: 10 }}
          mode="multiple"
          optionFilterProp="children"
          filterOption={(input, option) => {
            return (
              rmVN(option.children.props.tagName).indexOf(rmVN(input)) >= 0
            );
          }}
          placeholder="Phân loại"
          allowClear
          maxTagCount={1}
          notFoundContent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>Không có dữ liệu!</span>}
            />
          }
          onChange={value => {
            handleChangeParamSearchCustomers('tag_id', value);
          }}
          value={params.tag_id ? params.tag_id : undefined}
        >
          {tags.map((item, index) => (
            <Option key={index} value={item.value}>
              <TagCustom tagName={item.label} tagColor={item.color} />
            </Option>
          ))}
        </Select>
      </Col>

      <Col {...colSpan}>
        <Tooltip title={'Thời gian chuyển trạng thái'} trigger={'hover'}>
          <RangePicker
            allowClear
            style={{ width: '100%', marginTop: 10 }}
            placeholder={['Từ ngày', 'đến ngày']}
            format="YYYY/MM/DD"
            renderExtraFooter={() => (
              <b style={{ fontSize: 16 }}>Thời gian chuyển trạng thái</b>
            )}
            value={[
              params?.fromDate ? moment(params?.fromDate, 'YYYY/MM/DD') : '',
              params?.toDate ? moment(params?.toDate, 'YYYY/MM/DD') : '',
            ]}
            onChange={(date, dateString) => {
              mixpanel.track(
                `filter opportunity fromDate: ${
                  date ? moment(date[0]).format('YYYY/MM/DD') : ''
                } /toDate: ${date ? moment(date[1]).format('YYYY/MM/DD') : ''}`
              );
              if (date !== null) {
                setSearchParamsInUrlVer2({
                  fromDate: moment(date[0]).format('YYYY/MM/DD'),
                  toDate: moment(date[1]).format('YYYY/MM/DD'),
                  page: 1,
                });

                setParams({
                  ...params,
                  fromDate: moment(date[0]).format('YYYY/MM/DD'),
                  toDate: moment(date[1]).format('YYYY/MM/DD'),
                  page: 1,
                });
              } else {
                setSearchParamsInUrlVer2({
                  fromDate: '',
                  toDate: '',
                  page: 1,
                });
                setParams({
                  ...params,
                  fromDate: '',
                  toDate: '',
                  page: 1,
                });
              }
            }}
          />
        </Tooltip>
      </Col>

      <Col {...colSpan}>
        <Tooltip title={'Thời gian hợp đồng hết hạn'} trigger={'hover'}>
          <RangePicker
            allowClear
            style={{ width: '100%', marginTop: 10 }}
            placeholder={['Từ ngày', 'đến ngày']}
            format={'YYYY/MM/DD'}
            renderExtraFooter={() => (
              <b style={{ fontSize: 16 }}>Thời gian hợp đồng hết hạn</b>
            )}
            value={[
              params?.fromDateContractExp
                ? moment(params?.fromDateContractExp, 'YYYY/MM/DD')
                : '',
              params?.toDateContractExp
                ? moment(params?.toDateContractExp, 'YYYY/MM/DD')
                : '',
            ]}
            onChange={(date, dateString) => {
              mixpanel.track(
                `filter opportunity fromDate: ${
                  date ? moment(date[0]).format('YYYY/MM/DD') : ''
                } /toDate: ${date ? moment(date[1]).format('YYYY/MM/DD') : ''}`
              );
              if (date !== null) {
                setSearchParamsInUrlVer2({
                  fromDateContractExp: moment(date[0]).format('YYYY/MM/DD'),
                  toDateContractExp: moment(date[1]).format('YYYY/MM/DD'),
                  page: 1,
                });

                setParams({
                  ...params,
                  fromDateContractExp: moment(date[0]).format('YYYY/MM/DD'),
                  toDateContractExp: moment(date[1]).format('YYYY/MM/DD'),
                  page: 1,
                });
              } else {
                setSearchParamsInUrlVer2({
                  fromDateContractExp: '',
                  toDateContractExp: '',
                  page: 1,
                });

                setParams({
                  ...params,
                  fromDateContractExp: '',
                  toDateContractExp: '',
                  page: 1,
                });
              }
            }}
          />
        </Tooltip>
      </Col>

      <Col {...colSpan}>
        <Tooltip title={'Ngày tạo'} trigger={'hover'}>
          <RangePicker
            allowClear
            style={{ width: '100%', marginTop: 10 }}
            placeholder={['Từ ngày', 'đến ngày']}
            format={'YYYY/MM/DD'}
            renderExtraFooter={() => <b style={{ fontSize: 16 }}>Ngày tạo</b>}
            value={[
              params?.fromDateCreate
                ? moment(params?.fromDateCreate, 'YYYY/MM/DD')
                : '',
              params?.toDateCreate
                ? moment(params?.toDateCreate, 'YYYY/MM/DD')
                : '',
            ]}
            onChange={(date, dateString) => {
              mixpanel.track(
                `filter opportunity fromDate: ${
                  date ? moment(date[0]).format('YYYY/MM/DD') : ''
                } /toDate: ${date ? moment(date[1]).format('YYYY/MM/DD') : ''}`
              );
              if (date !== null) {
                setSearchParamsInUrlVer2({
                  fromDateCreate: moment(date[0]).format('YYYY/MM/DD'),
                  toDateCreate: moment(date[1]).format('YYYY/MM/DD'),
                  page: 1,
                });

                setParams({
                  ...params,
                  fromDateCreate: moment(date[0]).format('YYYY/MM/DD'),
                  toDateCreate: moment(date[1]).format('YYYY/MM/DD'),
                  page: 1,
                });
              } else {
                setSearchParamsInUrlVer2({
                  fromDateCreate: '',
                  toDateCreate: '',
                  page: 1,
                });

                setParams({
                  ...params,
                  fromDateCreate: '',
                  toDateCreate: '',
                  page: 1,
                });
              }
            }}
          />
        </Tooltip>
      </Col>
    </Row>
  );
}

export default Filter;
