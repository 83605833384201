import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { requestListProduct } from '@constants/Api';
import { ROUTER } from '@constants/Constant';
import {
  enterNumbersOnly,
  formatPrice,
  randomColor,
} from '@constants/funcHelper';
import { notifyFail } from '@utils/notify';
import {
  Avatar,
  Button,
  DatePicker,
  Descriptions,
  Empty,
  Input,
  Row,
  Select,
  Skeleton,
  Table,
  Tooltip,
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { arrow_up } from 'react-icons-kit/ikons/arrow_up';
import { useHistory } from 'react-router-dom';
import DiscountCodeDetail from './DiscountCodeDetail';
import { RenderFileViewer, UploadItemRender } from './Preview.Upload';
import {
  ExpandInfoStyled,
  IconStyled,
  InputStyled,
  NameCustomer,
  PromotionStyled,
  SelectStyled,
  SuccessIconStyled,
  UploadStyled,
  WarningIconStyled,
} from './styled';
import { checkIsValidVoucher } from '@screens/Enterprise/Job/services/api';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

OpportunityProduct.propTypes = {};

const CONTRACT_CLOSE = 3;

function OpportunityProduct({
  loading,
  dataSource,
  setParams,
  params,
  setChangeSales,
  setChangeProduct,
  setDataEpoint,
  dataEpoint,
  setFileList,
  setListDelete,
  listDelete,
  setAddProduct,
  addProduct,
}) {
  const [statusOccasion, setStatusOccasion] = useState(1);
  const [listProduct, setListProduct] = useState();
  const [idDelete, setIdDetele] = useState();
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [changeRevenue, setChangeRevenue] = useState(false);
  const [dataProduct, setDataProduct] = useState([]);
  const [listFiles, setListFile] = useState([]);
  const [idProducts, setIdProducts] = useState([]);
  const [isExpand, setIsExpand] = useState(false);
  const [isValidVoucher, setIsValidVoucher] = useState(true);
  const [notValidMsg, setNotValidMsg] = useState('');
  const dataSalesRef = useRef();
  const dataRevenueRef = useRef();
  const dataEpointRef = useRef();

  // data doanh thu doanh số
  const [dataSale, setDataSale] = useState(0);
  const [dataRevenue, setDataRevenue] = useState(0);

  // fileview
  const [file, setFile] = React.useState('');
  const [fileView, setFileView] = React.useState(false);
  const [fileType, setFileType] = React.useState('');
  const [isFileUpload, setIsFileUpload] = React.useState(false);

  const param = useParams();

  // check isvalid voucher
  useEffect(() => {
    if (dataSource && dataSource?.e_voucher_config?.condition_product?.length) {
      const products = dataSource?.e_voucher_config?.condition_product.map(
        prod => {
          return {
            name: prod.name,
            revenue_origin: prod.money,
            amount: prod.amount,
            key_id: prod.key_id,
            product_id: prod.product_id,
            is_active: prod.is_active,
          };
        }
      );
      const eVoucherInfo = {
        e_voucher_code: dataSource?.data_e_voucher?.e_voucher_code,
        products: products,
        opportunity_id: Number(param.id),
        data_e_voucher: dataSource?.data_e_voucher?.data_e_voucher_config,
      };
      checkIsValidVoucher(eVoucherInfo).then(res => {
        if (res.data.error) {
          notifyFail(res.data.error);
          setNotValidMsg(res.data.error);
          setIsValidVoucher(false);
        }
      });
    }
  }, [dataSource, param.id]);

  // useEffect(() => {
  //   setIdProduct();
  // }, []);
  useEffect(() => {
    if (dataSource) {
      setListFile(
        dataSource?.opportunity_media.map(item => {
          return {
            uid: String(item?.id),
            name: item?.files.slice(8, 70),
            status: 'done',
            response: 'Download file',
            url: item?.files,
          };
        })
      );
      setStatusOccasion(dataSource?.status);
      setDataEpoint(
        dataSource?.epoint === 0
          ? 0
          : dataSource?.epoint
          ? dataSource?.epoint
          : dataSource?.total_epoint
      );
    }
  }, [dataSource]);

  useEffect(() => {
    if (dataSource) {
      setDataSale(dataSource?.sales);
      setDataRevenue(dataSource?.revenue_origin);
    }
  }, [dataSource?.id]);

  useEffect(() => {
    setDataProduct(
      dataSource?.opportunity_products.map(item => ({
        ...item,
        epoint: (item.commission * item.revenue_origin) / 100,
      }))
    );
    setIdProducts(
      dataSource?.opportunity_products.map(item => item?.product?.id)
    );
  }, [dataSource]);
  const idProduct = dataSource?.opportunity_products.map(item => item?.id);
  const getProduct = async dataSource => {
    setLoadingProduct(true);
    try {
      const idProduct = dataSource?.opportunity_products.map(
        item => item?.product?.id
      );
      const res = await requestListProduct();
      const options = res?.data?.map(item => ({
        ...item,
        label: item.name,
        value: item.id,
        commission: item?.commission,
      }));
      if (options) {
        setListProduct(options);
      }
    } catch (err) {
      console.log('err: ', err);
    } finally {
      setLoadingProduct(false);
    }
  };
  const { Option } = Select;
  const PlusCircleIcon = () => (
    <svg width="20px" height="20px" fill="#ef8737" viewBox="0 0 1024 1024">
      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z" />
    </svg>
  );
  const DeleteIcon = () => (
    <svg width="20px" height="20px" fill="currentColor" viewBox="0 0 1024 1024">
      <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z" />
    </svg>
  );
  const { TextArea } = Input;
  const valueIdProduct = dataSource?.opportunity_products.map(
    item => item?.product?.id
  );
  const handleDelete = key => {
    setAddProduct(true);
    setChangeProduct(true);
    let valueDelete = dataProduct.filter((item, index) => {
      let valueIdProductDelete = valueIdProduct.filter(
        items => items !== item?.product?.id
      );
      setIdProducts(valueIdProductDelete);
      if (index !== key) {
        return item;
      }
      setIdDetele(key?.id);
    });
    const CommissionTotal = valueDelete.map(item => ({
      commissionTotal: (item.commission * item.revenue_origin) / 100,
    }));
    if (dataSalesRef.current && changeRevenue) {
      // doanh số
      setDataSale(_.sumBy(valueDelete, 'revenue_origin'));
      dataSalesRef.current.value = formatPrice(
        _.sumBy(valueDelete, 'revenue_origin')
      );

      // doanh thu
      setDataRevenue(_.sumBy(valueDelete, 'revenue_origin'));
      dataRevenueRef.current.value = formatPrice(
        _.sumBy(valueDelete, 'revenue_origin')
      );

      dataEpointRef.current.value = formatPrice(
        _.sumBy(CommissionTotal, 'commissionTotal')
      );
    }
    setDataProduct(valueDelete);
    setIdProducts(
      valueDelete.map(item => {
        return item.product_id;
      })
    );
    setParams({
      ...params,
      opportunity_data: valueDelete,
      list_product_delete: idProduct,
      sales: _.sumBy(valueDelete, 'revenue_origin'),
      revenue_origin: _.sumBy(valueDelete, 'revenue_origin'),
      epoint: _.sumBy(CommissionTotal, 'commissionTotal'),
    });
    // setParams({ ...params, list_product_delete: [...params.list_product_delete, key] });
  };

  const handleAdd = () => {
    const newData = {
      // product: {
      //   name: '',
      // },
      product_id: '',
      amount: 0,
      revenue_origin: 0,
      note: '',
    };
    setDataProduct([...dataProduct, newData]);

    setParams({ ...params, list_product_delete: idProduct });
  };
  // const handleSave = (row) => {
  //   const newData = [...this.state.dataSource];
  //   const index = newData.findIndex((item) => row.key === item.key);
  //   const item = newData[index];
  //   newData.splice(index, 1, { ...item, ...row });
  //   this.setState({
  //     dataSource: newData,
  //   });
  // };

  const history = useHistory();

  function onChangeTimeClose(date, dateString) {
    setParams({
      ...params,
      contract_close_date:
        moment(date).format('YYYY/MM/DD') === 'Invalid date'
          ? ''
          : moment(date).format('YYYY/MM/DD'),
      list_product_delete: [...idProduct],
    });
  }

  function onChangeTimeExp(date, dateString) {
    setParams({
      ...params,
      contract_exp_date:
        moment(date).format('YYYY/MM/DD') === 'Invalid date'
          ? ''
          : moment(date).format('YYYY/MM/DD'),
      list_product_delete: [...idProduct],
    });
  }

  function onChangeTimeCancel(date, dateString) {
    setParams({
      ...params,
      contract_cancle_date:
        moment(date).format('YYYY/MM/DD') === 'Invalid date'
          ? ''
          : moment(date).format('YYYY/MM/DD'),
      list_product_delete: [...idProduct],
    });
  }

  function handleChange(value) {
    setParams({
      ...params,
      status: value,
      list_product_delete: [...idProduct],
    });
    setStatusOccasion(value);
  }

  let defaultFileList = { ...dataSource?.opportunity_media };
  defaultFileList = dataSource?.opportunity_media.map(item => {
    return {
      uid: String(item?.id),
      name: item?.files.slice(8, 70),
      status: 'done',
      response: 'Download file',
      url: item?.files,
    };
  });

  const propUpload = {
    onChange({ file, fileList }) {
      setFileList(fileList);
    },
    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: 'Download',
      showRemoveIcon: true,
      removeIcon: <DeleteOutlined onClick={e => {}} />,
    },
  };

  const handleToggleDiscountDetail = () => {
    setIsExpand(!isExpand);
  };

  const name = dataSource?.telesale_name?.trim().split(' ');

  const columns = [
    {
      width: '25%',
      title: 'Sản phẩm',
      dataIndex: 'product_id',
      editable: true,
      render: (value, record, index) => {
        return (
          <>
            <Select
              showSearch
              loading={loadingProduct}
              style={{ width: '100%' }}
              placeholder="Sản phẩm"
              optionFilterProp="children"
              defaultValue={record?.product?.name}
              // value={record?.product?.name}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              onClick={() => {
                getProduct(dataSource);
              }}
              onChange={value => {
                setChangeProduct(true);
                setAddProduct(true);
                let values = value;
                const arr = [...dataProduct];
                const check = listProduct.find(p => p.id === value);
                const new_data_record = {
                  ...record,
                  product_id: values,
                  commission: check.commission,
                  name: check.name,
                };
                arr[index] = new_data_record;
                setIdProducts(
                  arr.map(item => {
                    return item.product_id;
                  })
                );
                setDataProduct(arr);
                setParams({
                  ...params,
                  opportunity_data: arr,
                  list_product_delete: [...idProduct],
                });
              }}
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>Không có sản phẩm nào!</span>}
                />
              }
            >
              {listProduct
                ? listProduct.map((item, index) => {
                    const checkDisable = idProducts.includes(item?.id);
                    return (
                      <>
                        {checkDisable ? (
                          ''
                        ) : (
                          <Option key={index} value={item?.id}>
                            {item?.name}
                          </Option>
                        )}
                      </>
                    );
                  })
                : ''}
            </Select>
          </>
        );
      },
    },
    {
      width: '15%',
      title: 'Số lượng',
      dataIndex: 'amount',
      editable: true,
      render: (value, record, index) => {
        return (
          <>
            <Input
              placeholder="Số lượng"
              allowClear
              value={value === 0 ? 0 : formatPrice(value)}
              onChange={e => {
                setChangeProduct(true);
                if (e.target.value.replaceAll(',', '') < 999999) {
                  let values = enterNumbersOnly(e.target.value);
                  const arr = [...dataProduct];
                  const new_data_record = {
                    ...record,
                    amount: parseInt(values === '' ? 0 : values),
                  };
                  arr[index] = new_data_record;
                  setDataProduct(arr);

                  setParams({
                    ...params,
                    opportunity_data: arr,
                    list_product_delete: [...idProduct],
                  });
                } else {
                  notifyFail('Vui lòng nhập số lượng tối đa 999999');
                }
              }}
            />
          </>
        );
      },
    },
    {
      width: '25%',
      title: 'Thành tiền',
      dataIndex: 'revenue_origin',
      editable: true,
      render: (value, record, index) => {
        return (
          <>
            <Input
              placeholder="Thành tiền"
              allowClear
              value={
                record.revenue_origin ? formatPrice(record.revenue_origin) : 0
              }
              onChange={e => {
                setChangeProduct(true);
                setChangeRevenue(true);
                let values = enterNumbersOnly(e.target.value);
                const arr = [...dataProduct];
                const new_data_record = {
                  ...record,
                  revenue_origin: parseInt(values === '' ? 0 : values),
                  epoint:
                    (parseInt(values === '' ? 0 : values) * record.commission) /
                    100,
                };
                arr[index] = new_data_record;
                setDataProduct(arr);
                if (dataSalesRef.current) {
                  dataSalesRef.current.value = formatPrice(
                    _.sumBy(arr, 'revenue_origin')
                  );

                  // set lại data để tính doanh số
                  setDataSale(_.sumBy(arr, 'revenue_origin'));

                  dataRevenueRef.current.value = formatPrice(
                    _.sumBy(arr, 'revenue_origin')
                  );

                  // set lại data để tính doanh thu
                  setDataRevenue(_.sumBy(arr, 'revenue_origin'));

                  dataEpointRef.current.value = formatPrice(
                    _.sumBy(arr, 'epoint')
                  );
                }
                setParams({
                  ...params,
                  sales: _.sumBy(arr, 'revenue_origin'),
                  revenue_origin: _.sumBy(arr, 'revenue_origin'),
                  epoint: _.sumBy(arr, 'epoint') ? _.sumBy(arr, 'epoint') : 0,
                  opportunity_data: arr,
                  list_product_delete: [...idProduct],
                });
              }}
            />
          </>
        );
      },
    },
    {
      width: '25%',
      title: 'Giá sau khi giảm',
      dataIndex: 'revenue',
      editable: true,
      render: (value, record, index) => {
        return (
          <>
            <Input
              disabled
              value={record.revenue ? formatPrice(record.revenue) : ''}
            />
          </>
        );
      },
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'product_exp_date',
      width: '200px',
      render: (value, record, index) => {
        return (
          <>
            <DatePicker
              inputReadOnly
              style={{ width: '100%' }}
              onChange={time => {
                setChangeProduct(true);
                let values = time && moment(time).format('YYYY-MM-DD');
                const arr = [...dataProduct];
                const new_data_record = {
                  ...record,
                  product_exp_date: values,
                };
                arr[index] = new_data_record;
                setDataProduct(arr);
                setParams({
                  ...params,
                  opportunity_data: arr,
                  list_product_delete: [...idProduct],
                });
              }}
              placeholder="Ngày hết hạn"
              format="DD/MM/YYYY"
              value={value && moment(value)}
            />
          </>
        );
      },
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      editable: true,
      width: '200px',
      render: (value, record, index) => {
        return (
          <>
            <TextArea
              style={{ marginTop: '22px' }}
              autoSize={{ maxRows: 3 }}
              placeholder="Ghi chú"
              // allowClear
              showCount
              maxLength={2000}
              value={value}
              onChange={e => {
                setChangeProduct(true);
                let values = e.target.value;
                const arr = [...dataProduct];
                const new_data_record = {
                  ...record,
                  note: values,
                };
                arr[index] = new_data_record;
                setDataProduct(arr);
                setParams({
                  ...params,
                  opportunity_data: arr,
                  list_product_delete: [...idProduct],
                });
              }}
            />
          </>
        );
      },
    },

    {
      width: 30,
      title: '',
      dataIndex: 'operation',
      render: (value, record, index) =>
        dataProduct?.length >= 1 ? (
          <Button
            type="text"
            style={{ color: 'red' }}
            icon={<DeleteIcon />}
            onClick={() => {
              // handleDelete(record.product.id);
              handleDelete(index);
            }}
          />
        ) : null,
    },
  ];

  return (
    <>
      <div>
        <b style={{ fontSize: 18, color: '#ef8737' }}>
          Khách hàng:{' '}
          <NameCustomer
            onClick={() => {
              history.push({
                pathname: `${ROUTER.CUSTOMER_DETAIL}/${dataSource?.customer_id}`,
                state: {},
              });
            }}
          >
            {dataSource?.customer_name}
          </NameCustomer>
        </b>
        <Skeleton loading={loading}>
          <Descriptions style={{ marginTop: 20 }}>
            <Descriptions.Item
              label={<div style={{ marginTop: 5 }}>Loại cơ hội</div>}
            >
              <div style={{ marginTop: 5 }}>
                {dataSource?.opportunity_category_name}
              </div>
              {/* <div style={{ marginTop: 5 }}>Mua bán</div> */}
            </Descriptions.Item>
            <Descriptions.Item
              label={<div style={{ marginTop: 5 }}>Sale chăm sóc</div>}
            >
              <Row style={{ alignItems: 'end', paddingTop: 4 }}>
                {name ? (
                  <Avatar
                    size="small"
                    style={{
                      backgroundColor: randomColor(
                        name[name?.length - 1].charAt(0)?.toUpperCase()
                      ),
                    }}
                    src={dataSource.profile_image}
                  >
                    {name[name?.length - 1].charAt(0)?.toUpperCase()}
                  </Avatar>
                ) : (
                  <></>
                )}
                &nbsp;&nbsp;
                <div>{dataSource?.telesale_name}</div>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item
              label={<div style={{ marginTop: 5 }}>Ngày tạo cơ hội</div>}
            >
              <div style={{ marginTop: 5 }}>
                {moment(dataSource?.created_at).format('DD/MM/YYYY')}
              </div>
            </Descriptions.Item>
          </Descriptions>
          <Row style={{ alignItems: 'center', marginBottom: 10 }}>
            <div>Trạng thái</div>
            <div>
              {dataSource?.status ? (
                <SelectStyled
                  value={statusOccasion}
                  bordered={false}
                  onChange={handleChange}
                  // disabled={dataSource?.status !== 1 ? true : false}
                >
                  {dataSource?.status === 1 ? (
                    <Option value={1}>
                      <b style={{ color: 'orange' }}>Khách hàng tiềm năng</b>
                    </Option>
                  ) : (
                    <></>
                  )}
                  <Option value={3}>
                    <b style={{ color: 'green' }}>Chốt hợp đồng</b>
                  </Option>
                  <Option value={4}>
                    <b style={{ color: 'red' }}>Khách hàng từ chối</b>
                  </Option>
                </SelectStyled>
              ) : (
                <></>
              )}
            </div>
          </Row>
          {dataSource?.opportunity_category?.auto_convert === 0 ? (
            <>
              <Row style={{ paddingBottom: 15 }}>
                <div style={{ width: 300 }}>
                  Doanh số (tiền trên hợp đồng):{' '}
                </div>
                <div>
                  <InputStyled
                    placeholder="Doanh số"
                    ref={dataSalesRef}
                    defaultValue={
                      dataSource?.sales === 0
                        ? '0'
                        : formatPrice(dataSource?.sales)
                    }
                    onChange={e => {
                      setDataSale(e.target.value.replaceAll(',', ''));
                      dataSalesRef.current.value = formatPrice(e.target.value);
                      setParams({
                        ...params,
                        list_product_delete: [...idProduct],
                        sales: e.target.value.replaceAll(',', ''),
                      });
                      setChangeSales(e.target.value);
                    }}
                  />
                  <div
                    style={{
                      height: 1,
                      backgroundColor: 'black',
                      width: '100%',
                    }}
                  />
                </div>
              </Row>
              <Row style={{ paddingBottom: 15 }}>
                <div style={{ width: 300 }}>
                  Doanh thu (tiền khách hàng thanh toán):{' '}
                </div>
                <div>
                  <InputStyled
                    placeholder="Doanh thu"
                    ref={dataRevenueRef}
                    defaultValue={
                      dataSource?.revenue_origin === 0
                        ? '0'
                        : formatPrice(dataSource?.revenue_origin)
                    }
                    onChange={e => {
                      setDataRevenue(e.target.value.replaceAll(',', ''));
                      dataRevenueRef.current.value = formatPrice(
                        e.target.value
                      );
                      setParams({
                        ...params,
                        list_product_delete: [...idProduct],
                        revenue_origin: e.target.value.replaceAll(',', ''),
                      });
                      setChangeRevenue(e.target.value);
                    }}
                  />
                  <div
                    style={{
                      height: 1,
                      backgroundColor: 'black',
                      width: '100%',
                    }}
                  />
                </div>
              </Row>
              {/* {statusOccasion == CONTRACT_CLOSE ? ( */}
              <Row style={{ paddingBottom: 15 }}>
                <div style={{ width: 300 }}>
                  Công nợ (Tiền khách phải trả):{' '}
                </div>
                <div>
                  <div style={{ padding: '0 10px' }}>
                    {formatPrice((dataSale || 0) - (dataRevenue || 0))}
                  </div>
                  <div
                    style={{
                      height: 1,
                      backgroundColor: 'black',
                      width: '100%',
                    }}
                  />
                </div>
              </Row>
              {/* ) : (
                <></>
              )} */}
              <Row>
                <div style={{ width: 170, marginTop: 15 }}>File đính kèm: </div>
                <div>
                  <UploadStyled
                    // customRequest={handleUpload}
                    beforeUpload={() => {
                      // Ngăn chặn upload file
                      return false;
                    }}
                    onChange={(file, fileList) => {
                      setFileList(file?.fileList);
                      setListDelete([...listDelete, Number(file.file.uid)]);
                      setParams({
                        ...params,
                        // list_file_delete: [...params.list_file_delete, file.file.uid],
                        list_product_delete: [...idProduct],
                      });
                    }}
                    showUploadList={(
                      showDownloadIcon,
                      downloadIcon,
                      showRemoveIcon,
                      removeIcon
                    ) => {
                      showDownloadIcon(true);
                      downloadIcon(true);
                      showRemoveIcon(true);
                      removeIcon(<DeleteOutlined onClick={e => {}} />);
                    }}
                    // itemRender={}
                    defaultFileList={[...listFiles]}
                    itemRender={(
                      originNode,
                      fileUpload,
                      currFileList,
                      actions
                    ) => {
                      return (
                        <UploadItemRender
                          originNode={originNode}
                          fileUpload={fileUpload}
                          actions={actions}
                          setFile={setFile}
                          setFileView={setFileView}
                          setFileType={setFileType}
                          setIsFileUpload={setIsFileUpload}
                        />
                      );
                    }}
                  >
                    <Button
                      type="text"
                      style={{
                        marginTop: 15,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      icon={<UploadOutlined />}
                    >
                      Upload
                    </Button>
                  </UploadStyled>
                </div>
                <RenderFileViewer
                  file={file}
                  fileView={fileView}
                  setFile={setFile}
                  setFileView={setFileView}
                  fileType={fileType}
                  isFileUpload={isFileUpload}
                />
              </Row>
              <Row style={{ padding: '15px 0 15px 0' }}>
                <div style={{ width: 170 }}>Hoa hồng cho sale (VND): </div>
                <div>
                  <InputStyled
                    placeholder="Chưa cập nhật"
                    ref={dataEpointRef}
                    defaultValue={
                      dataEpoint === 0
                        ? 0
                        : dataEpoint === -1
                        ? ''
                        : formatPrice(dataEpoint)
                    }
                    // value={dataEpoint === 0 ? 0 : dataEpoint === -1 ? '' : formatPrice(dataEpoint)}
                    onChange={e => {
                      dataEpointRef.current.value = formatPrice(e.target.value);
                      setDataEpoint(e.target.value);
                      setParams({
                        ...params,
                        list_product_delete: [...idProduct],
                        epoint: e.target.value
                          ? e.target.value.replaceAll(',', '')
                          : -1,
                      });
                    }}
                  />
                  {/* <Input
              placeholder="Chưa cập nhật"
              bordered={false}
              defaultValue={dataSource?.sales === 0 ? 0 : formatPrice(dataSource?.sales)}
              onChange={(e) => {
                setParams({ ...params, epoint: e.target.value });
              }}
            /> */}
                  <div
                    style={{
                      height: 1,
                      backgroundColor: 'black',
                      width: '100%',
                    }}
                  />
                </div>
              </Row>
            </>
          ) : (
            <></>
          )}
          {dataSource?.opportunity_category?.auto_convert === 0 ? (
            <>
              {statusOccasion === 3 ? (
                <>
                  <Row>
                    <div style={{ width: 170, marginTop: 15 }}>
                      Ngày chốt hợp đồng:{' '}
                    </div>
                    <div>
                      <DatePicker
                        inputReadOnly
                        style={{ marginTop: 10 }}
                        onChange={onChangeTimeClose}
                        format="DD/MM/YYYY"
                        placeholder={['Ngày chốt']}
                        defaultValue={
                          dataSource?.contract_close_date
                            ? moment(dataSource?.contract_close_date)
                            : moment(new Date())
                        }
                      />
                    </div>
                  </Row>
                  <Row>
                    <div style={{ width: 170, marginTop: 15 }}>
                      Ngày hết hạn hợp đồng:{' '}
                    </div>
                    <div>
                      <DatePicker
                        inputReadOnly
                        style={{ marginTop: 10 }}
                        onChange={onChangeTimeExp}
                        placeholder={['Ngày hết hạn']}
                        format="DD/MM/YYYY"
                        defaultValue={
                          dataSource?.contract_exp_date
                            ? moment(dataSource?.contract_exp_date)
                            : null
                        }
                      />
                    </div>
                  </Row>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {statusOccasion === 4 ? (
            <Row>
              <div style={{ width: 170, marginTop: 15 }}>
                Ngày khách hàng từ chối:{' '}
              </div>
              <div>
                <DatePicker
                  inputReadOnly
                  style={{ marginTop: 10 }}
                  onChange={onChangeTimeCancel}
                  placeholder={['Ngày từ chối']}
                  format="DD/MM/YYYY"
                  defaultValue={
                    dataSource?.contract_cancle_date
                      ? moment(dataSource?.contract_cancle_date)
                      : moment(new Date())
                  }
                />
              </div>
            </Row>
          ) : (
            <></>
          )}
        </Skeleton>
      </div>
      <Row style={{ padding: '15px 0 15px 0' }}>
        <div className="d-flex align-items-center" style={{ width: 150 }}>
          Áp dụng mã giảm giá:{' '}
        </div>
        {loading ? (
          ''
        ) : dataSource?.e_voucher_config ? (
          <Tooltip title={notValidMsg} placement="right">
            <PromotionStyled className={!isValidVoucher && 'notValid'}>
              <b>{dataSource?.data_e_voucher?.e_voucher_code}</b>
              {!isValidVoucher && <WarningIconStyled />}
              {isValidVoucher && <SuccessIconStyled />}
            </PromotionStyled>
          </Tooltip>
        ) : (
          <b>Không có mã giảm giá nào được áp dụng.</b>
        )}
      </Row>
      <div>
        <div onClick={handleToggleDiscountDetail}>
          <button
            style={{
              fontSize: 18,
              color: '#ef8737',
              border: 'none',
              fontWeight: '700',
              background: 'transparent',
              margin: '12px 0',
            }}
          >
            Chi tiết mã giảm giá
          </button>
          <IconStyled
            icon={arrow_up}
            className={isExpand && 'isExpand'}
            style={{ color: '#ef8737' }}
          />
        </div>
        <ExpandInfoStyled
          className={`discount-code-container ${isExpand ? 'expanded' : ''}`}
        >
          {isExpand &&
            (dataSource?.e_voucher_config ? (
              <DiscountCodeDetail
                data={dataSource}
                isValidVoucher={isValidVoucher}
              />
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <span>Không có mã giảm giá nào được áp dụng.</span>
                }
              />
            ))}
        </ExpandInfoStyled>
      </div>
      {dataSource?.opportunity_category?.auto_convert === 0 ? (
        <>
          <div style={{ margin: '10px 0' }}>
            <div className="d-flex align-items-center mb-1">
              <b style={{ fontSize: 18, color: '#ef8737' }}>Sản phẩm</b>
              <Button
                onClick={() => {
                  if (addProduct) {
                    handleAdd();
                    setAddProduct(false);
                  } else {
                    notifyFail('Vui lòng cập nhật sản phẩm!');
                  }
                }}
                type="text"
                icon={<PlusCircleIcon style={{ fontSize: '24px' }} />}
              />
            </div>
            <Table
              loading={loading}
              rowClassName={() => 'editable-row'}
              bordered
              dataSource={dataProduct}
              columns={columns}
              pagination={false}
              scroll={{
                x: 800,
                scrollToFirstRowOnChange: true,
                // y: 'calc(100vh - 430px)',
              }}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<span>Không có dữ liệu.</span>}
                  />
                ),
                triggerDesc: '',
                triggerAsc: '',
                cancelSort: '',
              }}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default OpportunityProduct;
