import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Spin, Tag, Tooltip } from 'antd';
import { Button } from 'react-bootstrap';
import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';

import {
  requestAddCustomerTags,
  requestGetJobDetailAllTags,
} from 'src/network/web_sale/GeneralApi';
import { notifyFail, notifySuccess } from '@utils/notify';
import { requestCustomerDetail } from '@constants/Api';
import ModalAddTag from './ModalAddTag';
import styled from 'styled-components';

const TagIcon = () => (
  <svg
    width="18px"
    height="18px"
    fill="#ef8737"
    viewBox="0 0 1024 1024"
    style={{ margin: '0 1px 3px 0' }}
  >
    <path d="M483.2 790.3L861.4 412c1.7-1.7 2.5-4 2.3-6.3l-25.5-301.4c-.7-7.8-6.8-13.9-14.6-14.6L522.2 64.3c-2.3-.2-4.7.6-6.3 2.3L137.7 444.8a8.03 8.03 0 000 11.3l334.2 334.2c3.1 3.2 8.2 3.2 11.3 0zm62.6-651.7l224.6 19 19 224.6L477.5 694 233.9 450.5l311.9-311.9zm60.16 186.23a48 48 0 1067.88-67.89 48 48 0 10-67.88 67.89zM889.7 539.8l-39.6-39.5a8.03 8.03 0 00-11.3 0l-362 361.3-237.6-237a8.03 8.03 0 00-11.3 0l-39.6 39.5a8.03 8.03 0 000 11.3l243.2 242.8 39.6 39.5c3.1 3.1 8.2 3.1 11.3 0l407.3-406.6c3.1-3.1 3.1-8.2 0-11.3z" />
  </svg>
);

const ButtonClassifyStyled = styled(Button)`
  background-color: white;
  color: #ef8737;
  border-color: #ef8737;
  border-radius: 3;
  box-shadow: none;
  padding: 2px 8px;
  margin: 0;
  font-weight: 600;
`;
function TagView(props) {
  const { onGetDetailInteractionHistory } = props;

  const searchParams = new URLSearchParams(window.location.search);
  const customerIdParams = searchParams.get('customer_id');

  const user = useSelector(store => store.userReducer.data);

  const [isLoadingCustomerDetail, setIsLoadingCustomerDetail] = useState(true);
  const [customerDetail, setCustomerDetail] = useState({});
  const [allTag, setAllTag] = useState([]);
  const [visible, setVisible] = useState(false);

  const ListTag = customerDetail?.customer_tags
    ? customerDetail?.customer_tags.map((item, index) => {
        return <div key={index}>{item.tag_name}</div>;
      })
    : null;

  const getCustomerDetail = async id => {
    if (!id) return null;
    try {
      const { data } = await requestCustomerDetail(id);
      setCustomerDetail(data.main);
    } catch (error) {
      console.log('Error getCustomerDetail: ', { error });
    } finally {
      setIsLoadingCustomerDetail(false);
    }
  };

  const getAllTag = async () => {
    try {
      const res = await requestGetJobDetailAllTags();
      const newArr = res.data.length
        ? res.data.map(item => {
            return {
              ...item,
              isCheck: false,
            };
          })
        : [];
      setAllTag(newArr);
    } catch (error) {
      console.log('Error getAllTag: ', { error });
    }
  };

  const handleOpenModalAddTag = () => {
    const tagsArr = [...allTag];
    const selectedTags =
      customerDetail.customer_tags?.map(item => item.tag_id) || [];
    const newArr = tagsArr.map(item => {
      if (selectedTags.includes(item.id)) {
        return {
          ...item,
          isCheck: true,
        };
      } else {
        return item;
      }
    });
    setAllTag(newArr);
    setVisible(true);
  };

  const handleChangeSelectTags = (id, checked) => {
    const tagsArr = [...allTag];
    const newArr = tagsArr.map(item => {
      if (item.id === id) {
        return {
          ...item,
          isCheck: !checked,
        };
      } else {
        return item;
      }
    });
    setAllTag(newArr);
  };

  const handleCloseModalAddTag = () => {
    const tagsArr = [...allTag];
    const newArr = tagsArr.map(item => {
      return {
        ...item,
        isCheck: false,
      };
    });
    setAllTag(newArr);
    setVisible(false);
  };

  const handleSaveTag = async () => {
    try {
      const payload = {
        customer_id: customerIdParams || customerDetail.id,
        tag_ids: allTag.length
          ? allTag.filter(item => item.isCheck).map(item => item.id)
          : [],
      };
      mixpanel.track('Save tag', { payload });
      if (user?.config_tag == 0 && payload?.tag_ids.length > 1) {
        notifyFail('Chỉ được chọn 1 phân loại!');
      } else {
        await requestAddCustomerTags(payload);
        getCustomerDetail(customerIdParams);
        onGetDetailInteractionHistory();
        notifySuccess('Sửa phân loại thành công!');
        setVisible(false);
      }
    } catch (error) {
      console.log('Error handleSaveTag: ', { error });
    }
  };

  useEffect(() => {
    getCustomerDetail(customerIdParams);
    getAllTag();
  }, [customerIdParams]);

  return (
    <Fragment>
      <ButtonClassifyStyled onClick={handleOpenModalAddTag}>
        <TagIcon />
        Phân loại
      </ButtonClassifyStyled>

      {/* <Spin spinning={isLoadingCustomerDetail}> */}
      {customerDetail.customer_tags?.length ? (
        customerDetail.customer_tags.map((item, index) =>
          index < 2 ? (
            <Tag
              className="tag"
              style={{
                margin: '0 0 0 20px',
                paddingLeft: 2,
                position: 'relative',
                backgroundColor: '#EFEFEF',
                border: 'none',
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomRightRadius: '4px',
                borderTopRightRadius: '4px',
                height: 20,
              }}
              key={item.tag_id}
              color="default"
            >
              <div className="d-flex justify-content-around align-items-center">
                <div
                  style={{
                    backgroundColor: item.tag_color,
                    borderRadius: '50%',
                    width: '10px',
                    height: '10px',
                    marginRight: '3px',
                  }}
                ></div>
                {item.tag_name}
              </div>
            </Tag>
          ) : null
        )
      ) : (
        <></>
      )}
      {customerDetail.customer_tags?.length > 2 ? (
        <Tooltip title={ListTag.slice(2, customerDetail.customer_tags.length)}>
          <Tag
            className="tag"
            style={{
              margin: '0 0 0 20px',
              paddingLeft: 2,
              position: 'relative',
              backgroundColor: '#EFEFEF',
              border: 'none',
              borderBottomLeftRadius: 0,
              borderTopLeftRadius: 0,
              borderBottomRightRadius: '4px',
              borderTopRightRadius: '4px',
              height: 20,
            }}
            color="default"
          >
            + {customerDetail.customer_tags?.length - 2}
          </Tag>
        </Tooltip>
      ) : null}
      {/* </Spin> */}

      <ModalAddTag
        visible={visible}
        tags={allTag}
        onChange={(id, checked) => handleChangeSelectTags(id, checked)}
        onClose={() => handleCloseModalAddTag()}
        onSave={() => {
          handleSaveTag();
        }}
      />
    </Fragment>
  );
}

TagView.propTypes = {
  onGetDetailInteractionHistory: PropTypes.func,
};

export default TagView;
