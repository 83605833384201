/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { STRING, ROUTER } from '@constants/Constant.js';
import '@styles/Company.css';
import {
  Row,
  Col,
  FormControl,
  Button,
  FormGroup,
  Form,
} from 'react-bootstrap';
import DatePickerCustom from '../../../components/DatePickerCustom';
import 'react-datepicker/dist/react-datepicker.css';
import { ACCEPT_TYPE, PHONE_REGEX, EMAIL_REGEX } from '@constants/Constant';
import { requestCreateTelesale } from '@constants/Api';
import { createFormData } from '@utils/createFormData';
import { checkAllAttrFilled } from '@utils/checkAllAttrFilled';
import { checkNormalizePhone } from '@utils/stringUtils';
import { renderSelection } from '@utils/renderSelection';
import { connect } from 'react-redux';
import { getProvinceList, getUserInfo } from '@src/redux/actions';
import reactotron from 'reactotron-react-js';
import moment from 'moment';
import Loading from '@src/components/Loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Input, Space } from 'antd';

class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        full_name: '',
        email: '',
        user_name: '',
        gender: '2',
        id_number: '',
        province_id: '',
        [STRING.dob]: '',
        address: '',
        work_experience: '',
        password: '',
        repassword: '',
        profile: '',
        front_id: '',
        back_id: '',
        work_type: '',
        doc: '',
      },
      profile: '',
      front_id: '',
      back_id: '',
      titleUploader: '',
      isLoading: false,
      show: false,
      showConfirm: false,
    };
  }

  componentDidMount() {
    this.getData();
    this.props.getUserInfo();
  }

  getData(payload) {
    this.props.getProvinceList();
  }

  async createTelesale() {
    const { form } = this.state;

    const { [STRING.dob]: dob } = form;

    let formatedDob = dob ? moment(dob).format('YYYY-MM-DD') : '';
    const check = this.validateForm();
    if (check) {
      const formData = createFormData({
        ...form,
        dob: formatedDob,
        full_name: form.full_name.trim(),
      });
      this.setState({ isLoading: true });
      requestCreateTelesale(formData)
        .then(res => {
          this.setState({ isLoading: false });
          Object.keys(this.state.form).forEach(k =>
            this.setState({
              form: {
                ...this.state.form,
                [k]: '',
              },
            })
          );
          this.props.userInfoState.data?.role_id === 1
            ? this.props.history.push(ROUTER.REGISTER_REQUEST)
            : this.props.history.push(ROUTER.TELESALE);

          this.notifySuccess();
        })
        .catch(err => {
          this.setState({ isLoading: false });
          this.notifyFail(err.msg);
        });
    }
    return;
  }

  showPassword = () => {
    this.setState({
      show: !this.state.show,
    });
  };

  showConfirmPassword = () => {
    this.setState({
      showConfirm: !this.state.showConfirm,
    });
  };

  validateForm() {
    const { form } = this.state;

    if (!form.full_name || !form.email || !form.user_name) {
      this.notifyFail('Vui lòng nhập đầy đủ thông tin bắt buộc');
      return false;
    }

    if (checkNormalizePhone(form.user_name)) {
      this.notifyFail('Số điện thoại không hợp lệ');
      return false;
    }

    if (
      form.id_number.length &&
      form.id_number.length != 9 &&
      form.id_number.length != 12
    ) {
      this.notifyFail('Số CMND không hợp lệ (bao gồm 9 hoặc 12 số)');
      return false;
    }

    if (EMAIL_REGEX.exec(form.email) === null) {
      this.notifyFail('Email không hợp lệ');
      return false;
    }

    if (form.password !== form.repassword) {
      this.notifyFail('Mật khẩu không khớp');
      return false;
    }

    // check password
    if (form.password.length < 6) {
      this.notifyFail('Mật khẩu phải có tối thiểu 6 ký tự.');
      return false;
    }
    // var uppercase = 0,
    //   lowercase = 0,
    //   specialChar = 0,
    //   number = 0;
    // var specialCharList = '<>@!#$%^&*()_+[]{}?:;|\'"\\,./~`-=';
    // for (var i = 0; i < form.password.length; i++) {
    //   reactotron.log('charcode ' + form.password[i], form.password[i].charCodeAt());
    //   if (form.password[i].charCodeAt() >= 65 && form.password[i].charCodeAt() <= 90) {
    //     uppercase = 1;
    //     reactotron.log('upper');
    //   }
    //   if (form.password[i].charCodeAt() >= 97 && form.password[i].charCodeAt() <= 122) {
    //     lowercase = 1;
    //     reactotron.log('lower');
    //   }
    //   if (form.password[i].charCodeAt() >= 48 && form.password[i].charCodeAt() <= 57) {
    //     number = 1;
    //     reactotron.log('number');
    //   }
    //   if (specialCharList.indexOf(form.password[i]) !== -1) {
    //     specialChar = 1;
    //     reactotron.log('special');
    //   }
    // }
    // if (!(uppercase && lowercase && specialChar && number)) {
    //   this.notifyFail('Mật khẩu chưa đủ mạnh');
    //   return false;
    // }

    return true;
  }

  notifySuccess = mes =>
    toast.success('Thêm mới thành công!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  notifyFail = mes =>
    toast.error(mes, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  handleChangeImage = (type, event) => {
    if (!ACCEPT_TYPE.IMAGE.includes(event.target.files[0]?.type)) {
      // alert('Định dạng ảnh không được hỗ trợ. Vui lòng chọn ảnh khác.');
      return;
    }
    this.setState({
      form: {
        ...this.state.form,
        [type]: event.target?.files[0],
      },
      [type]: (window.URL || window.webkitURL).createObjectURL(
        event.target.files[0]
      ),
    });
  };

  handleInputChange = (field, value) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [field]: value,
      },
    });
    reactotron.log(this.state.form);
  };

  renderTitle() {
    return (
      <>
        <Row>
          <Col sm={12}>
            <h2 className="text-left" style={{ marginBottom: '10px' }}>
              {' '}
              Thêm sale
            </h2>
            <div class="line"></div>
          </Col>
        </Row>
      </>
    );
  }

  renderBody() {
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <Row
            className="mt-1"
            style={{
              textAlign: 'center',
              padding: '0px 20px',
              borderRadius: '8px',
              marginBottom: 30,
            }}
          >
            <Col md className="bg">
              {this.renderTitle()}

              <Row>{this.renderField()}</Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderButton() {
    return (
      <div className="">
        <Button
          variant="success"
          className="rounded text-light"
          onClick={() => this.createTelesale()}
          style={{ width: '100px' }}
        >
          Lưu
        </Button>
      </div>
    );
  }

  renderField() {
    const { form, profile, front_id, back_id } = this.state;
    const { [STRING.dob]: dob } = this.state.form;
    return (
      <div className="w-100">
        <Row>
          <Col md={12} className="">
            <Row className="justify-content-center">
              {this.renderPicUploader()}
            </Row>
          </Col>
          <Col md={12}>
            {/* <Row>
              <label className="big-title">Thông tin cá nhân</label>
            </Row> */}
            <FormGroup className="add-company-form">
              <Row>
                <Col md={6} className="tele-input-col">
                  <label htmlFor="name">Họ và tên(*)</label>
                  <FormControl
                    type="text"
                    name="name"
                    placeholder="Nhập họ và tên"
                    value={form.full_name}
                    onChange={e =>
                      this.handleInputChange('full_name', e.target.value)
                    }
                  ></FormControl>

                  <label htmlFor="phone">Số điện thoại(*)</label>
                  <FormControl
                    type="number"
                    name="phone"
                    placeholder="Nhập số điện thoại"
                    value={form.user_name}
                    onChange={e =>
                      this.handleInputChange('user_name', e.target.value)
                    }
                  ></FormControl>

                  <br />
                  <label htmlFor="gender">Giới tính</label>
                  <Form.Check
                    inline
                    type="radio"
                    label="Nam"
                    name="gender"
                    className="radio-button offset-2 tele-radio"
                    value="2"
                    checked={form.gender === '2' ? 'checked' : ''}
                    onChange={e =>
                      this.handleInputChange('gender', e.target.value)
                    }
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="Nữ"
                    name="gender"
                    className="radio-button tele-radio"
                    value="1"
                    checked={form.gender === '1' ? 'checked' : ''}
                    onChange={e =>
                      this.handleInputChange('gender', e.target.value)
                    }
                  />
                  <br />
                  <br />

                  {/* <label htmlFor="cmnd">Số CMND</label>
                  <FormControl
                    type="number"
                    name="phone"
                    placeholder="Nhập số CMND (9 hoặc 12 số)"
                    value={form.id_number}
                    onChange={(e) => this.handleInputChange('id_number', e.target.value)}
                  ></FormControl> */}
                </Col>

                <Col md={6} className="tele-input-col">
                  <label htmlFor="email">Email(*)</label>
                  <FormControl
                    type="email"
                    name="email"
                    placeholder="Nhập email"
                    value={form.email}
                    onChange={e =>
                      this.handleInputChange('email', e.target.value)
                    }
                  ></FormControl>

                  <label htmlFor="email">Ngày sinh</label>
                  <DatePickerCustom
                    className={`date-picker form-control`}
                    placeholderText={STRING.dob}
                    handleChange={this.handleInputChange}
                    selected={dob}
                    dateFormat="dd/MM/yyyy"
                    maxDate={Date.now()}
                  />

                  <label htmlFor="city">Tỉnh - Thành phố</label>
                  <FormControl
                    as="select"
                    id="provice"
                    value={form.province_id}
                    onChange={e =>
                      this.handleInputChange('province_id', e.target.value)
                    }
                  >
                    <option value="" selected disabled>
                      Tỉnh/Thành phố
                    </option>
                    {renderSelection(this.props.provinceState.data)}
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="tele-input-col">
                  <label htmlFor="cmnd">Số CMND (*)</label>
                  <FormControl
                    type="number"
                    name="phone"
                    placeholder="Nhập số CMND (9 hoặc 12 số)"
                    value={form.id_number}
                    onChange={e =>
                      this.handleInputChange('id_number', e.target.value)
                    }
                  ></FormControl>
                </Col>
                <Col md={6} className="tele-input-col">
                  <label htmlFor="address ">Địa chỉ cụ thể</label>
                  <FormControl
                    type="text"
                    name="address"
                    placeholder="Nhập địa chỉ cụ thể"
                    value={form.address}
                    onChange={e =>
                      this.handleInputChange('address', e.target.value)
                    }
                  ></FormControl>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Row>
                    <Col md={6} className="pl-0">
                      <Row>
                        <label>Ảnh mặt trước CMND</label>
                      </Row>
                      <div className="upload-form-wrapper mb-2">
                        <label
                          htmlFor="upload2"
                          className="label-upload"
                          style={{ cursor: 'pointer' }}
                        >
                          {front_id.length ? (
                            <img
                              src={front_id}
                              className="uploaded-pic"
                              width="200"
                              height="150"
                              style={{
                                objectFit: 'contain',
                                borderRadius: '8px',
                              }}
                            />
                          ) : (
                            <>
                              <i className="fas fa-image upload-icon"></i>
                              <p>Bấm để chọn ảnh</p>
                              {/* <span>(Định dạng ảnh được hỗ trợ: jpg, jpeg, png)</span> */}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          id="upload2"
                          accept=".jpg,.jpeg,.png"
                          style={{ display: 'none' }}
                          onChange={e => this.handleChangeImage('front_id', e)}
                        />
                        <br />
                      </div>
                    </Col>
                    <Col md={6} className="pr-0">
                      <Row>
                        <label>Ảnh mặt sau CMND</label>
                      </Row>
                      <div className="upload-form-wrapper mb-2">
                        <label
                          htmlFor="upload3"
                          className="label-upload"
                          style={{ cursor: 'pointer' }}
                        >
                          {back_id.length ? (
                            <img
                              src={back_id}
                              className="uploaded-pic"
                              width="200"
                              height="150"
                              style={{
                                objectFit: 'contain ',
                                borderRadius: '8px',
                              }}
                            />
                          ) : (
                            <>
                              <i className="fas fa-image upload-icon"></i>
                              <p>Bấm để chọn ảnh</p>
                              {/* <span>(Định dạng ảnh được hỗ trợ: jpg, jpeg, png)</span> */}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          id="upload3"
                          accept=".jpg,.jpeg,.png"
                          style={{ display: 'none' }}
                          onChange={e => this.handleChangeImage('back_id', e)}
                        />
                        <br />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row>
                    {/* <Col md={12} className="tele-input-col"> */}
                    <label htmlFor="address ">Mật khẩu (*)</label>
                    <Space
                      direction="vertical"
                      name="password"
                      autoComplete="username email"
                      onChange={e =>
                        this.handleInputChange('password', e.target.value)
                      }
                      style={{ width: '100%' }}
                      id="password"
                    >
                      <Input.Password
                        placeholder="Nhập mật khẩu"
                        value={form.password}
                      />
                    </Space>
                    {/* </Col> */}
                  </Row>
                  <Row className="mt-3">
                    {/* <Col md={12} className="tele-input-col"> */}
                    <label htmlFor="address ">Xác nhận mật khẩu (*)</label>
                    <Space
                      direction="vertical"
                      name="repassword"
                      autoComplete="username email"
                      onChange={e =>
                        this.handleInputChange('repassword', e.target.value)
                      }
                      style={{ width: '100%' }}
                    >
                      <Input.Password
                        placeholder="Nhập lại mật khẩu"
                        value={form.repassword}
                      />
                    </Space>
                    {/* </Col> */}
                  </Row>
                  <Row>
                    {/* <Col sm={8}></Col> */}
                    {/* <Col style={{ color: 'gray', marginRight: '1rem' }}>
                      <div>
                        <i>* Quy tắc đặt mật khẩu</i>
                      </div>
                      <div>
                        <i> - Mật khẩu dài 6 ký tự</i> 
                      </div>
                      <i>- Mật khẩu bao gồm chữ hoa, chữ thường, ký tự số, ký tự đặc biệt</i>
                    </Col> */}
                  </Row>
                </Col>
              </Row>

              {/* <Row>
                <Col md={12} className="tele-input-col">
                  <Row>
                    <label htmlFor="address ">Mật khẩu</label>
                  </Row>
                  <Row>
                    <Space
                      direction="vertical"
                      name="password"
                      autoComplete="username email"
                      onChange={(e) => this.handleInputChange('password', e.target.value)}
                      style={{ width: '100%' }}
                      id="password"
                    >
                      <Input.Password placeholder="Nhập mật khẩu" value={form.password} />
                    </Space>
                  </Row>
                </Col>
              </Row>
               */}
              {/* <Row className="mt-3">
                <Col md={12} className="tele-input-col">
                  <Row>
                    <label htmlFor="address ">Xác nhận mật khẩu</label>
                  </Row>
                  <Row>
                    <Space
                      direction="vertical"
                      name="repassword"
                      autoComplete="username email"
                      onChange={(e) => this.handleInputChange('repassword', e.target.value)}
                      style={{ width: '100%' }}
                    >
                      <Input.Password placeholder="Nhập lại mật khẩu" value={form.repassword} />
                    </Space>
                  </Row>
                </Col>
              </Row>
               */}
              <Row>
                <label className="big-title">Thông tin công việc</label>
              </Row>
              <Row>
                <Col md={6}>
                  <Row>
                    <label>Kinh nghiệm làm việc</label>
                  </Row>
                  <Row>
                    <textarea
                      placeholder="Mô tả kinh ngiệm làm việc"
                      className="form-control"
                      rows="5"
                      onChange={e =>
                        this.handleInputChange(
                          'work_experience',
                          e.target.value
                        )
                      }
                      value={form.work_experience}
                    ></textarea>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row>
                    <label>Hình thức làm việc</label>
                  </Row>
                  <Row>
                    <FormControl
                      as="select"
                      id=""
                      value={form.work_type}
                      onChange={e =>
                        this.handleInputChange('work_type', e.target.value)
                      }
                    >
                      <option value="" selected disabled>
                        Hình thức làm việc
                      </option>
                      <option value="1">Fulltime</option>
                      <option value="2">Parttime</option>
                    </FormControl>
                  </Row>
                  <Row>
                    <label>Hồ sơ</label>
                  </Row>
                  <Row>
                    <Col sm={12} className="px-0 text-left">
                      <label
                        htmlFor="uploader"
                        className="text-blue uploader-label"
                      >
                        <i
                          className="fas fa-paperclip mr-2"
                          style={{ fontSize: 16 }}
                        ></i>
                        {this.state.titleUploader
                          ? this.state.titleUploader
                          : 'Đính kèm hồ sơ (.doc, .pdf)'}
                      </label>
                      <input
                        type="file"
                        id="uploader"
                        className="d-none"
                        onChange={e => this.handleFileUploader(e)}
                      ></input>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center mb-4">{this.renderButton()}</Row>
      </div>
    );
  }

  handleFileUploader(event) {
    console.log(event.target.files[0]);
    if (
      event.target.files[0] &&
      !ACCEPT_TYPE.DOCUMENT.includes(event.target.files[0].type)
    ) {
      alert('Định dạng không được hỗ trợ. Vui lòng chọn file khác.');
      return;
    }
    this.setState({
      titleUploader: event.target.files[0].name,
      form: {
        ...this.state.form,
        doc: event.target.files[0],
      },
    });
  }

  renderPicUploader() {
    const { form, profile, front_id, back_id } = this.state;
    return (
      <>
        <form ctype="multipart/form-data" className="">
          {/* <Row>
            <label>Ảnh đại diện(*)</label>
          </Row> */}
          <div className=" mb-2">
            <label
              htmlFor="upload1"
              className="label-upload"
              style={{ cursor: 'pointer' }}
            >
              {profile.length ? (
                <img
                  src={profile}
                  width="200"
                  height="200"
                  style={{
                    objectFit: 'cover',
                    width: 100,
                    height: 100,
                    borderRadius: '50%',
                  }}
                />
              ) : (
                <div
                  style={{
                    width: 100,
                    height: 100,
                    backgroundColor: '#bdbdbd',
                    borderRadius: '50%',
                    position: 'relative',
                  }}
                >
                  <img
                    src={require('../../../assets/no-image.jpg')}
                    width="200"
                    height="200"
                    style={{
                      objectFit: 'cover',
                      width: 100,
                      height: 100,
                      borderRadius: '50%',
                    }}
                  />
                </div>
              )}
            </label>
            <input
              type="file"
              id="upload1"
              accept=".jpg,.jpeg,.png"
              style={{ display: 'none' }}
              onChange={e => this.handleChangeImage('profile', e)}
            />
            <br />
          </div>
        </form>
      </>
    );
  }

  render() {
    const { front_id } = this.state.form;
    console.log(front_id);
    return (
      <>
        {this.state.isLoading ? <Loading /> : null}
        {this.renderBody()}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ToastContainer />
      </>
    );
  }
}

const mapStateToProps = state => ({
  provinceState: state.ProvinceReducer,
  userInfoState: state.userReducer,
});

const mapDispatchToProps = {
  getProvinceList,
  getUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Add));
