import React from 'react';

import { ROUTER } from '@constants/Constant';
// import reactotron from 'reactotron-react-js';

// static component
import PrivateRoute from '../PrivateRoute';

// Auth screen
// import LoginScreen from '@screens/Auth/LoginScreen';

// Screen of Admin web

import RegisterRequestDetail from '@screens/Admin/RegisterRequest/Detail';
import RegisterRequest from '@screens/Admin/RegisterRequest/Screen';

import RegisterRequestRejectedDetail from '@screens/Admin/RegisterRequestRejected/Detail';
import RegisterRequestRejected from '@screens/Admin/RegisterRequestRejected/Screen';

import TelesaleAdd from '@screens/Admin/Telesale/Add';
import TelesaleEdit from '@screens/Admin/Telesale/Edit';

import TelesaleInactiveDetail from '@screens/Admin/TelesaleInactive/Detail';
import TelesaleInactiveScreen from '@screens/Admin/TelesaleInactive/Screen';

import ReportDetail from '@screens/Admin/Report/Detail';

// Screen of Enterprise web
import en_CustomerScreen from '@screens/Enterprise/Customer/Customer';
// import en_CustomerDetail from '@screens/Enterprise/Customer/Detail';
import AddCustomerScreen from '@screens/Enterprise/Customer/Add';
import en_CustomerDetail from '@screens/Enterprise/Customer/CustomerDetail';

import en_CustomerGroupAdd from '@screens/Enterprise/CustomerGroup/Add';
import en_CustomerGroupDetail from '@screens/Enterprise/CustomerGroup/Detail';
import en_CustomerGroupEdit from '@screens/Enterprise/CustomerGroup/Edit';
import en_CustomerGroupScreen from '@screens/Enterprise/CustomerGroup/Screen';

// import KPIProgress from '@screens/Enterprise/KPI/KPIProgress';
import CreateEditKPISetup from '@screens/Enterprise/KPI/CreateEditKPISetup';
import DetailKPI from '@screens/Enterprise/KPI/DetailKPI';
import KPIProgressScreen from '@screens/Enterprise/KPI/KPIProgressScreen';
import SetupKPI from '@screens/Enterprise/KPI/SetupKPI';

import AddDepartmentScreen from '@screens/Enterprise/Department/Add';
import DepartmentDetailScreen from '@screens/Enterprise/Department/Detail';
import DepartmentScreen from '@screens/Enterprise/Department/Screen';

import en_JobAdd from '@screens/Enterprise/Job/Add/Screen';
import en_Job from '@screens/Enterprise/Job/Job';
import en_JobResult from '@screens/Enterprise/Job/Result';

import en_JobGeneral from '@screens/Enterprise/Job/AddEditSubJob/AddEditSubJob';

import {
  AddEditPromotions,
  ElectronicVoucher,
} from '@screens/Enterprise/ElectronicVoucher';

import DetailJobScreen from '@screens/Enterprise/Evaluate/DetailJobScreen';
import BusinessReport from '@screens/Enterprise/Report/BusinessReport/BusinessReport';
import LocationReport from '@screens/Enterprise/Report/LocationReport/LocationReport';
import LocationReportDetail from '@screens/Enterprise/Report/LocationReport/LocationReportDetail';
import ReportEvaluate from '@screens/Enterprise/Report/ReportEvaluate/Screen';
import ReportJob from '@screens/Enterprise/Report/ReportJob/Screen';
import ReportOpportunity from '@screens/Enterprise/Report/ReportOpportunity/Screen';
import ReportTelesale from '@screens/Enterprise/Report/ReportTelesale/Screen';
import TelemarketingReport from '@screens/Enterprise/Report/ReportTelesale/TelemarketingReport';
import en_GeneralConfig from '@screens/Enterprise/Settings/GeneralConfig';
import en_JobConfig from '@screens/Enterprise/Settings/JobConfig';

// Copy y hết của admin =))
import en_TelesaleDetail from '@screens/Admin/Telesale/Detail';
import en_TelesaleScreen from '@screens/Admin/Telesale/Screen';

// import CallScreen from '@screens/Enterprise/Call/CallScreen';
import CallScreen from '@screens/Enterprise/Call/CallScreenV2';

import Assign from '@screens/Enterprise/Evaluate/AssignScreen';
import EvaluationDetailScreen from '@screens/Enterprise/Evaluate/EvaluationDetailScreen';
import EvaluationResult from '@screens/Enterprise/Evaluate/EvaluationResultScreen';
import EvaluationStaff from '@screens/Enterprise/Evaluate/EvaluationStaffScreen';

import AccountEnterpriseScreen from '@screens/Enterprise/Account/Screen';
import OccasionScreen from '@screens/Enterprise/Opportunity/Screen';

import OpportunityDetail from '@screens/Enterprise/Opportunity/OpportunityDetail';
import MessageReport from '@screens/Enterprise/Report/MessageReport/MessageReport';
import ZaloOADetail from '@screens/Enterprise/Settings/zaloOA/DetailZaloOA';
import TemplateOADetail from '@screens/Enterprise/Settings/zaloOA/TemplateOADetail';
import ZaloOALoginCallback from '@screens/Enterprise/Settings/zaloOA/components/ZaloOALoginCallback';
import SaleCallDetailScreen from '@screens/Sale/Call/CallDetail';
import LazyLoading from 'src/modules/loading/Lazy.loading';

const En_Dashboard = React.lazy(() =>
  import('@screens/Enterprise/Dashboard/Screen')
);

export const renderEnterpriseRoute = () => {
  return (
    <>
      {/* Customer route */}
      <PrivateRoute path={ROUTER.CUSTOMER} Component={en_CustomerScreen} />
      <PrivateRoute
        path={ROUTER.CUSTOMER_DETAIL + '/:id'}
        Component={en_CustomerDetail}
      />

      {/* CustomerGroup route */}
      <PrivateRoute
        path={ROUTER.CUSTOMER_GROUP}
        Component={en_CustomerGroupScreen}
      />
      <PrivateRoute
        path={ROUTER.CUSTOMER_GROUP_ADD}
        Component={en_CustomerGroupAdd}
      />
      <PrivateRoute
        path={ROUTER.CUSTOMER_GROUP_EDIT + '/:id'}
        Component={en_CustomerGroupEdit}
      />
      <PrivateRoute
        path={ROUTER.CUSTOMER_GROUP_DETAIL + '/:id'}
        Component={en_CustomerGroupDetail}
      />

      {/* Department route */}
      <PrivateRoute path={ROUTER.DEPARTMENT} Component={DepartmentScreen} />
      <PrivateRoute
        path={ROUTER.ADD_DEPARTMENT}
        Component={AddDepartmentScreen}
      />
      <PrivateRoute
        path={ROUTER.UPDATE_DEPARTMENT + '/:id'}
        Component={AddDepartmentScreen}
      />
      <PrivateRoute
        path={ROUTER.DEPARTMENT_DETAIL + '/:id'}
        Component={DepartmentDetailScreen}
      />

      {/* temporarily closed -- starting */}
      {/* <PrivateRoute path={ROUTER.KPI} Component={KPIScreen} /> */}
      <PrivateRoute path={ROUTER.KPI} Component={SetupKPI} />
      <PrivateRoute
        path={ROUTER.CREATE_KPI_SETUP}
        Component={CreateEditKPISetup}
      />
      <PrivateRoute
        path={ROUTER.EDIT_KPI_SETUP}
        Component={CreateEditKPISetup}
      />
      {/* <PrivateRoute path={ROUTER.KPI_PROGRESS} Component={KPIProgress} /> */}
      <PrivateRoute path={ROUTER.KPI_PROGRESS} Component={KPIProgressScreen} />

      <PrivateRoute path={ROUTER.KPI_DETAIL} Component={DetailKPI} />
      {/* temporarily closed -- ending */}

      {/* Job general route */}
      <PrivateRoute
        path={ROUTER.JOB_GENERAL_ADD + '/:id'}
        Component={en_JobGeneral}
      />
      {/* <PrivateRoute path={ROUTER.JOB_GENERAL_EDIT + '/:id'} Component={en_JobGeneralEdit} /> */}
      <PrivateRoute
        path={ROUTER.JOB_GENERAL_EDIT + '/:id'}
        Component={en_JobGeneral}
      />

      {/* Job route */}
      <PrivateRoute path={ROUTER.JOB} Component={en_Job} />
      <PrivateRoute path={ROUTER.JOB_ADD} Component={en_JobAdd} />
      {/* <PrivateRoute path={ROUTER.JOB_EDIT + '/:id'} Component={en_JobEdit} /> */}
      <PrivateRoute path={ROUTER.JOB_EDIT + '/:id'} Component={en_JobAdd} />
      <PrivateRoute
        path={ROUTER.JOB_RESULT + '/:id'}
        Component={en_JobResult}
      />
      <PrivateRoute
        path={ROUTER.JOB_RESULT_CHILD + '/:id'}
        Component={en_JobResult}
      />

      {/* Dashboard route */}
      <PrivateRoute
        path={ROUTER.HOME}
        Component={() => (
          <LazyLoading>
            <En_Dashboard />
          </LazyLoading>
        )}
      />
      <PrivateRoute
        path="/"
        exact
        Component={() => (
          <LazyLoading>
            <En_Dashboard />
          </LazyLoading>
        )}
      />

      {/* RegisterRequest route */}
      <PrivateRoute
        path={ROUTER.REGISTER_REQUEST}
        Component={RegisterRequest}
      />
      <PrivateRoute
        path={ROUTER.REGISTER_REQUEST_DETAIL + '/:id'}
        Component={RegisterRequestDetail}
      />

      {/* RegisterRequest rejected request */}
      <PrivateRoute
        path={ROUTER.REGISTER_REQUEST_REJECTED}
        Component={RegisterRequestRejected}
      />
      <PrivateRoute
        path={ROUTER.REGISTER_REQUEST_REJECTED_DETAIL + '/:id'}
        Component={RegisterRequestRejectedDetail}
      />
      {/* Telesale route */}
      <PrivateRoute
        path={ROUTER.TELESALE}
        exact
        Component={en_TelesaleScreen}
      />
      <PrivateRoute
        path={ROUTER.TELESALE_DETAIL + '/:id'}
        Component={en_TelesaleDetail}
      />
      {/* <PrivateRoute path={ROUTER.TELESALE} exact Component={TelesaleScreen} /> */}
      <PrivateRoute path={ROUTER.TELESALE_ADD} exact Component={TelesaleAdd} />
      <PrivateRoute
        path={ROUTER.TELESALE_EDIT + '/:id'}
        exact
        Component={TelesaleEdit}
      />
      {/* <PrivateRoute path={ROUTER.TELESALE_DETAIL + "/:id"} Component={TelesaleDetail} /> */}

      {/* Telesale Inactive route */}
      <PrivateRoute
        path={ROUTER.TELESALE_INACTIVE}
        exact
        Component={TelesaleInactiveScreen}
      />
      <PrivateRoute
        path={ROUTER.TELESALE_INACTIVE_DETAIL + '/:id'}
        Component={TelesaleInactiveDetail}
      />

      {/* e-voucher */}
      <PrivateRoute path={ROUTER.E_VOUCHER} Component={ElectronicVoucher} />
      <PrivateRoute
        path={ROUTER.ADD_PROMOTIONS}
        Component={AddEditPromotions}
      />
      <PrivateRoute
        path={ROUTER.EDIT_PROMOTIONS + '/:id'}
        Component={AddEditPromotions}
      />

      {/* Settings route */}
      <PrivateRoute path={ROUTER.GENERAL_CONFIG} Component={en_GeneralConfig} />
      <PrivateRoute path={ROUTER.ZALO_OA_DETAIL} Component={ZaloOADetail} />
      <PrivateRoute path={ROUTER.ZALO_LOGIN} Component={ZaloOALoginCallback} />
      <PrivateRoute
        path={ROUTER.DETAIL_TEMPLATE_ZNS}
        Component={TemplateOADetail}
      />
      <PrivateRoute path={ROUTER.JOB_CONFIG} Component={en_JobConfig} />

      {/* Call route */}
      <PrivateRoute path={ROUTER.CALL} Component={CallScreen} />
      <PrivateRoute
        path={ROUTER.CALL_DETAIL + '/:id'}
        // Component={CallDetailScreen}
        Component={SaleCallDetailScreen}
      />

      {/* Evaluation route */}
      <PrivateRoute
        path={ROUTER.EVALUATION_STAFF}
        exact
        Component={EvaluationStaff}
      />

      {/* occasaion */}
      <PrivateRoute
        path={ROUTER.OPPORTUNITY}
        exact
        Component={OccasionScreen}
      />
      {/* <PrivateRoute path={ROUTER.OCCASION_DETAIL + '/:id'} exact Component={OccasionDetailScreen} /> */}
      <PrivateRoute
        path={ROUTER.OCCASION_DETAIL + '/:id'}
        exact
        Component={OpportunityDetail}
      />

      <PrivateRoute path={ROUTER.ASSIGN} exact Component={Assign} />
      <PrivateRoute path={ROUTER.ASSIGN + '/:id'} exact Component={Assign} />
      <PrivateRoute
        path={ROUTER.EVALUATION_RESULT}
        exact
        Component={EvaluationResult}
      />
      <PrivateRoute
        path={ROUTER.ACCOUNT}
        exact
        Component={AccountEnterpriseScreen}
      />
      <PrivateRoute
        path={ROUTER.EVALUATION_STAFF_DETAIL + '/:id'}
        exact
        Component={EvaluationDetailScreen}
      />
      <PrivateRoute
        path={ROUTER.ADD_CUSTOMER}
        exact
        Component={AddCustomerScreen}
      />
      <PrivateRoute
        path={ROUTER.EDIT_CUSTOMER + '/:id'}
        exact
        Component={AddCustomerScreen}
      />

      {/* report */}

      {/* temporarily closed -- starting */}
      <PrivateRoute path={ROUTER.REPORT_JOB} exact Component={ReportJob} />
      <PrivateRoute
        path={ROUTER.REPORT_TELESALE}
        exact
        Component={TelemarketingReport}
      />
      <PrivateRoute
        path={ROUTER.REPORT_CALL}
        exact
        Component={ReportTelesale}
      />
      <PrivateRoute
        path={ROUTER.REPORT_EVALUATE}
        exact
        Component={ReportEvaluate}
      />
      <PrivateRoute
        path={ROUTER.REPORT_DETAIL + '/:id'}
        Component={ReportDetail}
      />
      <PrivateRoute
        path={ROUTER.REPORT_OPPORTUNITY}
        exact
        Component={ReportOpportunity}
      />
      <PrivateRoute
        path={ROUTER.BUSINESS_REPORT}
        exact
        Component={BusinessReport}
      />
      <PrivateRoute
        path={ROUTER.LOCATION_REPORT}
        exact
        Component={LocationReport}
      />
      <PrivateRoute
        path={ROUTER.LOCATION_REPORT_DETAIL}
        exact
        Component={LocationReportDetail}
      />
      <PrivateRoute
        path={ROUTER.MESSAGE_REPORT}
        exact
        Component={MessageReport}
      />
      {/* temporarily closed -- ending */}

      <PrivateRoute
        path={ROUTER.EVALUATE_JOB_DETAIL + '/:id'}
        exact
        Component={DetailJobScreen}
      />
    </>
  );
};
