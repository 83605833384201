import { VietnameseString } from '@constants/VietnameseString';

export const JOB_TAB_ITEMS = [
  {
    name: 'Thông tin chung',
    eventKey: 'general',
    nextEventKey: '',
    rules: [
      {
        message: 'Vui lòng hoàn thành thông tin chung của công việc!',
        validate: data => data?.parent_id && data?.job_id && !!data?.name,
      },
    ],
  },
  {
    name: 'Kịch bản',
    eventKey: 'context',
    nextEventKey: '',
    dependOns: ['general'],
  },
  {
    name: 'Phiếu kết quả',
    eventKey: 'formresult',
    nextEventKey: '',
    dependOns: ['general'],
  },
  // { name: 'Điều kiện công việc', eventKey: 'requirement', nextEventKey: '', dependOns: ['general'] },
  {
    name: 'Đào tạo',
    eventKey: 'education',
    nextEventKey: '',
    dependOns: ['general'],
  },
  {
    name: 'Đề thi',
    eventKey: 'test',
    nextEventKey: '',
    dependOns: ['general'],
  },
];

export const JOB_TAB_MAPPING = JOB_TAB_ITEMS.reduce((result, item) => {
  result[item.eventKey] = item;
  return result;
}, {});

export const SUB_JOB_TAB_ITEMS = [
  {
    name: 'Thông tin chung',
    eventKey: 'subjob',
    nextEventKey: '',
    rules: [
      {
        message: 'Vui lòng hoàn thành thông tin chung của công việc',
        validate: data => data?.parent_id && data?.job_id && !!data?.name,
      },
    ],
  },
  {
    name: 'Dữ liệu khách hàng',
    eventKey: 'userdata',
    nextEventKey: '',
    dependOns: ['subjob'],
    rules: [
      {
        message: 'Vui lòng chọn nhóm khách hàng trước khi giao việc',
        validate: data => data?.parent_id && !!data?.group_id,
      },
    ],
  },
  {
    name: 'Giao việc',
    eventKey: 'assign',
    nextEventKey: '',
    dependOns: ['subjob', 'userdata'],
    rules: [
      {
        message: '',
        validate: data => data?.parent_id && !!data?.countTelesale,
      },
    ],
  },
];

export const SUB_JOB_TAB_MAPPING = SUB_JOB_TAB_ITEMS.reduce((result, item) => {
  result[item.eventKey] = item;
  return result;
}, {});

export const SCRIPT_TYPE = {
  FOR_TELEMARKETING: 1,
  FOR_ZNS: 2,
  FOR_SMS: 3,
  SEND_SMS: 4,
  // zalo cá nhân
  SEND_ZALO_PERSON: 5,
};

export const JOB_CRON_TIME_REPEAT = {
  ONCE: 1, // 1 lần
  PERIODICALLY: 2, // Định kì
};

export const JOB_CRON_TIME_CODE = {
  DATE: 'd', // ngày
  WEEK: 'w', // tuần
  MONTH: 'm', // tháng
  YEAR: 'y', // năm
  ALlWAYS: 'lt', // liên tục
};

export const VALUES_JOB_CRON_TIME_WEEK = [
  { value: 2, name: VietnameseString.monday },
  { value: 3, name: VietnameseString.tuesday },
  { value: 4, name: VietnameseString.wednesday },
  { value: 5, name: VietnameseString.thursday },
  { value: 6, name: VietnameseString.friday },
  { value: 7, name: VietnameseString.saturday },
  { value: 8, name: VietnameseString.sunday },
];

export const CUSTOMER_DATA_TYPE_PROPERTY = {
  OPTIONS: 'options',
  TEXT: 'text',
  DATE_TIME: 'date_time',
  NUMBER: 'number',
  ALL: '',
  // REF: 'ref',
};

export const AUTO_DELETE_CUSTOMER = {
  NOT_REQUIRED: 0, // K BẮT BUỘC
  REQUIRED: 1, // BẮT BUỘC
};
export const APPLY_NEW_CUSTOMER = {
  NOT_REQUIRED: 0, // K BẮT BUỘC
  REQUIRED: 1, // BẮT BUỘC
};

export const DIVIDE_TYPE = {
  QUANTITY: 1,
  RATIO: 2,
  AUTO_DIVIDE: 3,
};

export const TIME_LOOP = {
  DATE: 'd',
  WEEK: 'w',
  MONTH: 'm',
};

export const OPTION_SCHEDULE_CALL_BACK = 0;

export const TIME_RECALL_STATUS = {
  APPROVE: 1,
  NONE: 0,
};

export const FORWARD_ANSWER = {
  APPROVE: 1,
  NONE: 0,
};

export const DELETE_CUSTOMER_IN_GROUP = {
  APPROVE: 1,
  NONE: 0,
};

export const ASSIGN_CUSTOMER_PRIORITY_ENABLED = {
  APPROVE: 1,
  NONE: 0,
};
