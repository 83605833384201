import React, { Component } from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  Modal,
  Row,
} from 'react-bootstrap';

import StepAdd from '@screens/Enterprise/Job/Add/Component/Context/_StepAdd';
import { QUESTION_TYPE } from '@constants/Constant';

import 'react-datepicker/dist/react-datepicker.css';
import { FORWARD_ANSWER } from '@screens/Enterprise/Job/Constants';

class ModalAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 0,
    };
  }

  handleTypeChange(value) {
    setTimeout(() => this.props.handle('type_input', value), 100);
    if (value == QUESTION_TYPE.YES_NO) {
      this.addOption(true);
    } else {
      this.resetOption();
    }
  }

  addOption(isYesNoQues) {
    if (isYesNoQues) {
      this.props.handle('job_form_answers', [
        {
          name: 'Có',
          value: this.props.value.job_form_answers.length,
          job_form_answer_child: [],
          enterprise_forward_answer_enabled: FORWARD_ANSWER.NONE,
        },
        {
          name: 'Không',
          value: this.props.value.job_form_answers.length,
          job_form_answer_child: [],
          enterprise_forward_answer_enabled: FORWARD_ANSWER.NONE,
        },
      ]);
    } else {
      this.props.handle('job_form_answers', [
        ...this.props.value.job_form_answers,
        {
          name: 'Nhập thông tin vào đây',
          value: this.props.value.job_form_answers.length,
          job_form_answer_child: [],
          enterprise_forward_answer_enabled: FORWARD_ANSWER.NONE,
        },
      ]);
    }
  }

  resetOption() {
    this.props.handle('job_form_answers', []);
  }

  renderOption() {
    return (
      <>
        <Row className="modal-row scroll-option">
          <Col md={5} className="">
            <Modal.Header>Lựa chọn</Modal.Header>
          </Col>
          <Col md={7} className="px-0">
            <i
              className={`fas fa-plus text-green add-btn ${
                this.props.value.type_input == QUESTION_TYPE.YES_NO
                  ? ' not-allowed'
                  : null
              }`}
              onClick={
                this.props.value.type_input != QUESTION_TYPE.YES_NO
                  ? () => this.addOption()
                  : null
              }
            ></i>
          </Col>
          {this.props.value.job_form_answers.map((item, index) => (
            <Col sm={12} key={index}>
              <StepAdd
                content={item}
                handle={this.props.handle}
                index={index}
                option={this.props.value.job_form_answers}
                value={this.props.value}
                isYesNoQues={
                  this.props.value.type_input == QUESTION_TYPE.YES_NO
                }
                forwardJobEnable={this.props.forwardJobEnable}
              />
            </Col>
          ))}
        </Row>
      </>
    );
  }

  render() {
    const { value } = this.props;
    return (
      <>
        <Modal
          show={this.props.show}
          onHide={() => this.props.close('modalAdd', false)}
          dialogClassName="modal-90w"
          centered
        >
          <Modal.Header closeButton className="bg-blue">
            <h5 className="text-light pb-0">Tạo câu hỏi</h5>
          </Modal.Header>

          <Modal.Body className="custom-body">
            <FormGroup className="text-left">
              <Row className="modal-row">
                <Col md={5}>
                  <Modal.Header>Nội dung câu hỏi</Modal.Header>
                </Col>

                <Col md={7}>
                  <FormControl
                    type="text"
                    placeholder="Nhập câu hỏi"
                    value={value.name}
                    onChange={e => this.props.handle('name', e.target.value)}
                  ></FormControl>
                </Col>
              </Row>

              <Row className="modal-row">
                <Col md={5}>
                  <Modal.Header>Loại giá trị</Modal.Header>
                </Col>

                <Col md={7}>
                  <FormControl
                    // value=""
                    as="select"
                    id=""
                    value={value.type_input}
                    onChange={e => this.handleTypeChange(e.target.value)}
                  >
                    <option value={QUESTION_TYPE.TEXT}>Chữ</option>
                    <option value={QUESTION_TYPE.SELECT}>1 đáp án</option>
                    <option value={QUESTION_TYPE.MULTIPLE_SELECT}>
                      Nhiều đáp án
                    </option>
                    <option value={QUESTION_TYPE.DATE}>Ngày/Tháng/Năm</option>
                    <option value={QUESTION_TYPE.YES_NO}>Có/Không</option>
                  </FormControl>
                </Col>
              </Row>

              <Row className="modal-row">
                <Col md={5}>
                  <Modal.Header>Điểm kinh nghiệm</Modal.Header>
                </Col>

                <Col md={7}>
                  <FormControl
                    type="number"
                    placeholder="Nhập điểm kinh nghiệm"
                    value={value.reward_point}
                    onChange={e =>
                      this.props.handle('reward_point', e.target.value)
                    }
                  ></FormControl>
                </Col>
              </Row>

              {/* Danh sách các lựa chọn cho câu hỏi */}
              {[
                QUESTION_TYPE.SELECT,
                QUESTION_TYPE.MULTIPLE_SELECT,
                QUESTION_TYPE.YES_NO,
              ].includes(parseInt(value.type_input))
                ? this.renderOption()
                : null}

              <Row className="modal-row">
                <Col md={3}>
                  <Modal.Header>Bắt buộc</Modal.Header>
                </Col>

                <Col md={3} className="px-0">
                  <Form.Check
                    type="checkbox"
                    value={1}
                    className="px-0 require-check"
                    onChange={e =>
                      // this.props.handle(
                      //   'is_require',
                      //   e.target.checked || value.is_conclusions ? 1 : 0
                      // )
                      this.props.handle('is_require', e.target.checked)
                    }
                    // checked={value.is_require == 1 || value.is_conclusions == 1}
                    checked={value.is_require == 1}
                  />
                </Col>

                <Col md={3}>
                  <Modal.Header>Kết luận</Modal.Header>
                </Col>

                <Col md={3} className="px-0">
                  <Form.Check
                    type="checkbox"
                    value={1}
                    className="px-0 require-check"
                    onChange={e =>
                      this.props.handle(
                        'is_conclusions',
                        e.target.checked ? 1 : 0
                      )
                    }
                    checked={value.is_conclusions == 1}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Modal.Body>

          <Modal.Footer className="justify-content-center">
            <Button
              disabled={this.props.disabled}
              variant="success"
              className="text-light"
              onClick={() => this.props.add()}
            >
              Tạo mới
            </Button>

            <Button
              variant="danger"
              className="text-light"
              onClick={() => this.props.close('modalAdd', false)}
            >
              Đóng
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ModalAdd;
