import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getTelesaleInactiveList, getProvinceList } from '@src/redux/actions';
import '@styles/Telesale.css';
import '@styles/ToggleSwitch.css';
import { Row, Col, FormControl, Button, Modal } from 'react-bootstrap';
import { renderSelection } from '@utils/renderSelection';
import { ROUTER, USER_ROLE, DEFAULT_IMAGE } from '@constants/Constant';
import { notifyFail, notifySuccess } from '@utils/notify';
import Loading from '@src/components/Loading';
import Pagination from '@components/Pagination';
import { Empty, Switch } from 'antd';
import { changeActiveTelesale, requestDeleteSale } from '@constants/Api';
import { ReactSelect } from '@components/ReactSelect';

class TelesaleScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBar: {
        searchKey: '',
        status: '',
        city: '',
        work: '',
        page: 1,
      },
      modalDeleteSaleVisible: false,
      selectedSale: {},
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = (payload) => {
    this.props.getTelesaleInactiveList(payload);
    this.props.getProvinceList();
  };

  handleModal = (bool, id, is_active) => {
    this.setState({
      ...this.state,
      modal: bool,
      telesale_id: id,
      is_active: is_active == 1 ? true : false,
    });
  };

  async changeActiveTelesale() {
    const { telesale_id, is_active } = this.state;
    try {
      await changeActiveTelesale({
        id: telesale_id,
        is_active: is_active ? 2 : 1,
      });
      this.setState({
        modal: false,
      });
      this.props.getTelesaleInactiveList();
      notifySuccess('Thao tác thành công');
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  renderModal = () => {
    const { modal, is_active } = this.state;
    return (
      <Modal show={modal} onHide={() => this.handleModal(false, '')} dialogClassName="modal-90w" centered scrollable>
        <Modal.Header closeButton className="text-white bg-primary pb-0">
          <h5 className="text-light pb-0">{is_active == false ? 'Xác nhận hoạt động' : 'Ngưng hoạt động'}</h5>
        </Modal.Header>
        {/* {is_active == false ? (
          <></>
        ) : (
          <Modal.body>Công việc của sale sẽ bị thu hồi sau khi ngừng hoạt động sale, bạn có muốn thu hồi?</Modal.body>
        )} */}
        <Modal.Footer className="justify-content-end">
          <Button variant="success" className="text-light btn-oversize" onClick={() => this.changeActiveTelesale()}>
            Có
          </Button>
          <Button variant="danger" className="text-light btn-oversize" onClick={() => this.handleModal(false, '')}>
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderTitle() {
    return (
      <>
        <Row>
          <Col md={6} sm={12}>
            <h2 className="text-left" style={{ marginBottom: '10px' }}>
              {' '}
              Danh sách sale ngừng hoạt động
            </h2>
            <div class="line"></div>
          </Col>
          {/* <Col md={6} sm={12}>
            {this.renderHeaderButton()}
          </Col> */}
        </Row>
      </>
    );
  }

  renderBody() {
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <Row
            className="mt-1"
            style={{ textAlign: 'center', padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}
          >
            <Col md className="bg">
              {this.renderTitle()}
              {this.renderField()}
            </Col>
          </Row>
          <Row>
            <Col md={12} style={{ padding: '0 20px' }}>
              {this.renderTable()}
            </Col>
          </Row>
          <Row>
            <Col md={12} className="d-flex justify-content-md-end justify-content-center" style={{ padding: '0 20px' }}>
              <Pagination screen="telesaleState" handlePageChange={this.handlePageChange} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderHeaderButton() {
    if (this.props.userState?.data?.role_id === USER_ROLE.ADMIN) {
      return (
        <div className="text-center text-md-right">
          <Link to={ROUTER.TELESALE_ADD}>
            <Button variant="success" className="text-light">
              Thêm mới
            </Button>
          </Link>
        </div>
      );
    } else return null;
  }

  renderField() {
    const { searchBar } = this.state;
    const listProvince = this.props.provinceState.data?.map(
      (province) =>
        new Object({
          value: province.id,
          label: province.name,
        })
    );
    listProvince.unshift({ value: '', label: 'Chọn tỉnh thành phố' });
    return (
      <div className="w-100">
        <Row>
          <Col md={6} sm={12}>
            <FormControl
              type="text"
              id=""
              placeholder="Tên hoặc số điện thoại sale"
              value={searchBar.searchKey}
              onChange={(e) => this.searchBarChange('searchKey', e.target.value)}
              onKeyPress={(e) => this.handleKeyPress(e)}
              className="h-100"
            />
          </Col>
          <Col md={3} sm={12}>
            <ReactSelect
              handleChangeSelect={(e) => this.handleChangeSelect(e, 'city')}
              list={listProvince}
              placeholder="Chọn tỉnh thành phố"
            />
          </Col>
          <Col md={3} sm={12}>
            <FormControl
              as="select"
              id=""
              value={searchBar.work}
              onChange={(e) => this.searchBarChange('work', e.target.value)}
              className="h-100"
            >
              <option value="">Hình thức làm việc</option>
              <option value="1">Fulltime</option>
              <option value="2">Parttime</option>
            </FormControl>
          </Col>
        </Row>
      </div>
    );
  }

  renderTable() {
    const saleList = this.props.telesaleState?.data;
    const { paging } = this.props.telesaleState;
    const err = this.props.telesaleState?.error;
    if (err) {
      notifyFail(err?.msg);
    }
    return (
      <div className="w-100 table-wrap">
        <span className="pb-2 d-inline-block">
          Kết quả lọc: <b className="text-danger">{paging?.totalItemCount || 0}</b>
        </span>
        {saleList.length ? (
          saleList.map((sale) => this.renderItem(sale))
        ) : (
          <Empty description={<span>Không có dữ liệu</span>} />
        )}
      </div>
    );
  }

  modalDeleteSale() {
    const { modalDeleteSaleVisible, selectedSale } = this.state;
    return (
      <Modal
        show={modalDeleteSaleVisible}
        onHide={() =>
          this.setState({
            ...this.state,
            modalDeleteSaleVisible: false,
          })
        }
        centered
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton className="text-white bg-primary pb-0">
          <h5 className="text-light pb-0">Bạn có chắc chắn muốn xóa sale ngừng hoạt động này?</h5>
        </Modal.Header>
        <Modal.Body>
          <h4>
            {selectedSale.full_name} (
            <label className="text-info" style={{ fontSize: '20px' }}>
              {selectedSale.phone}
            </label>
            )
          </h4>
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          <Button variant="success" className="text-light btn-oversize" onClick={() => this.handleDeleteSale()}>
            Có
          </Button>
          <Button
            variant="danger"
            className="text-light btn-oversize"
            onClick={() =>
              this.setState({
                ...this.state,
                modalDeleteSaleVisible: false,
              })
            }
          >
            Hủy
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  async handleDeleteSale() {
    const { selectedSale } = this.state;
    try {
      console.log(selectedSale, 'selectedSale');
      const res = await requestDeleteSale({ id: selectedSale.id });
      notifySuccess('Xóa thành công!');
      this.setState(
        {
          ...this.state,
          modalDeleteSaleVisible: false,
          selectedSale: {},
        },
        () => this.getData()
      );
    } catch (error) {}
  }

  renderItem(sale) {
    return (
      <Row className="mt-1" style={{ padding: '0px 0px', borderRadius: '8px', marginBottom: 30 }}>
        <Col
          md
          className="tele-item"
          style={{
            padding: '20px 0px 25px 30px',
            backgroundColor: '#ffffff',
            borderRadius: '8px',
          }}
        >
          <Row>
            <Col
              md={2}
              className="header"
              onClick={() => this.props.history.push(ROUTER.TELESALE_DETAIL + `/${sale?.id}`)}
            >
              <Row>
                <Col md={12} xs={12} className="mr-3">
                  <img
                    src={sale?.user_sale_info?.profile_image ? sale?.user_sale_info?.profile_image : DEFAULT_IMAGE}
                    className="w-100 avt-pic text-blue"
                    alt=" "
                    style={{ objectFit: 'contain', borderRadius: 10 }}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              md={10}
              className="content"
              // onClick={() => this.props.history.push(ROUTER.TELESALE_DETAIL + `/${sale.id}`)}
            >
              <Row
                className="upper mb-2"
                onClick={() => this.props.history.push(ROUTER.TELESALE_DETAIL + `/${sale?.id}`)}
              >
                <b>{sale?.full_name || '--'}</b>
              </Row>
              <Row className="lower">
                <Col md={3} onClick={() => this.props.history.push(ROUTER.TELESALE_DETAIL + `/${sale?.id}`)}>
                  <Row className="justify-content-center justify-content-md-start pb-1">
                    <i className="fas fa-phone-alt icon-contact pt-1"></i>
                    <span>{sale?.phone || '--'}</span>
                  </Row>
                  <Row className="justify-content-center justify-content-md-start pb-1">
                    <i className="fas fa-envelope icon-contact pt-1"></i>
                    <span>{sale?.email || '--'}</span>
                  </Row>
                  <Row className="justify-content-center justify-content-md-start pb-1">
                    <i className="fas fa-map-marker icon-contact pt-1"></i>
                    <span>{sale?.user_sale_info?.province?.name || '--'}</span>
                  </Row>
                </Col>
                <Col md={3} xs={6} onClick={() => this.props.history.push(ROUTER.TELESALE_DETAIL + `/${sale?.id}`)}>
                  <Row className="center-row">
                    <span>Kinh nghiệm</span>
                  </Row>
                  <Row className="center-row">
                    <span className="waiting">{sale?.user_sale_info?.reward_point || '0'}</span>
                  </Row>
                </Col>
                <Col
                  md={3}
                  xs={6}
                  className="center-row"
                  onClick={() => this.props.history.push(ROUTER.TELESALE_DETAIL + `/${sale?.id}`)}
                >
                  <Row className="center-row">
                    <span>Số cuộc gọi TB</span>
                  </Row>
                  <Row className="center-row">
                    <span className="accept">{sale?.user_sale_info?.success_call || '0'} cuộc/ngày</span>
                  </Row>
                </Col>
                <Col md={3}>
                  <Row>
                    <Col md={12} xs={4} className="text-center offset-2 offset-md-0 px-0">
                      <span>Trạng thái</span>
                    </Col>
                    <Col md={12} xs={5} className="waiting text-center">
                      {/* <Switch checked={sale?.is_active === 1} /> */}
                      <Switch
                        checked={sale.is_active == 1}
                        onClick={() => this.handleModal(true, sale.id, sale.is_active)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                className="pt-2"
                //  onClick={() => this.props.history.push(ROUTER.TELESALE_DETAIL + `/${sale?.id}`)}
              >
                <Col md={3} className="px-0 pb-1">
                  <Row>
                    <Col xs={3} md={2} className="d-flex align-items-center px-md-0">
                      <i className="fas fa-users tele-icon pt-md-2 pt-0"></i>
                    </Col>
                    <Col xs={8} className="px-0">
                      <Row>
                        <Col md={12} xs={10} className="px-0">
                          Công việc
                        </Col>
                        <Col md={12} xs={2} className="text-center text-lg-left px-0">
                          <b>{sale?.user_sale_info?.count_job || '0'}</b>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col md={3} className="px-0 pb-1">
                  <Row>
                    <Col xs={3} md={2} className="d-flex align-items-center px-md-0">
                      <i className="fas fa-phone-alt tele-icon"></i>
                    </Col>
                    <Col xs={8} className="px-0">
                      <Row>
                        <Col md={12} xs={10} className="px-0">
                          <span>Cuộc gọi còn lại</span>
                        </Col>
                        <Col md={12} xs={2} className="text-center text-lg-left px-0">
                          <b>{sale?.user_sale_info?.remain_call || '0'}</b>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col md={3} className="px-0 pb-1">
                  <Row>
                    <Col xs={3} md={2} className="d-flex align-items-center px-md-0">
                      <i className="far fa-check-circle tele-icon"></i>
                    </Col>
                    <Col xs={8} className="px-0">
                      <Row>
                        <Col md={12} xs={10} className="px-0">
                          <span>Cuộc gọi thành công</span>
                        </Col>
                        <Col md={12} xs={2} className="text-center text-lg-left px-0">
                          <b>{sale?.user_sale_info?.success_call || '0'}</b>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col md={3} className="px-0 pb-1">
                  <Row>
                    <Col md={12} xs={4} className="text-center offset-2 offset-md-0 px-0">
                      <span style={{ fontSize: '16px' }}>Xóa</span>
                    </Col>
                    <Col md={12} xs={5} className="waiting text-center">
                      <i
                        class="far fa-trash-alt"
                        style={{ color: 'red', fontSize: '24px' }}
                        onClick={() => {
                          if (sale?.user_sale_info?.count_job > 0) {
                            notifyFail('Sale ngừng hoạt động này vẫn còn công việc!');
                            return;
                          }
                          this.setState({
                            ...this.state,
                            modalDeleteSaleVisible: true,
                            selectedSale: sale,
                          });
                        }}
                      ></i>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            {/* <Col md={2} className="footer text-center">

                    <Button
                        variant="outline-danger mt-4 mt-md-0"
                        size=""
                        onClick={() => this.props.history.push(ROUTER.TELESALE_EDIT + `/${sale.id}`)}
                    >
                        Cập nhật
                    </Button>
                </Col> */}
          </Row>
          {/* <Row>
            <Col md={12} xs={4} className="text-center offset-2 offset-md-0 px-0">
              <span>Trạng thái</span>
            </Col>
            <Col md={12} xs={5} className="waiting text-center">
              <Switch checked={sale?.is_active === 1} />
            </Col>
          </Row> */}
        </Col>
      </Row>
    );
  }

  handleChangeSelect = (selectedOption, field) => {
    this.setState(
      {
        ...this.state,
        searchBar: {
          ...this.state.searchBar,
          [field]: selectedOption.value,
        },
      },
      () => {
        const { searchBar } = this.state;
        this.getData({
          search: searchBar.searchKey,
          province_id: searchBar.city,
          status: searchBar.status,
          work_type: searchBar.work,
          active: '',
          type: '',
          page: this.state.searchBar.page,
        });
      }
    );
  };

  async searchBarChange(field, value) {
    await this.setState({
      ...this.state,
      searchBar: {
        ...this.state.searchBar,
        [field]: value,
      },
    });

    if (field !== 'searchKey') {
      const { searchBar } = this.state;
      this.getData({
        search: searchBar.searchKey,
        province_id: searchBar.city,
        status: searchBar.status,
        work_type: searchBar.work,
        active: '',
        type: '',
        page: this.state.searchBar.page,
      });
    }
  }

  handleKeyPress = (e) => {
    const { searchBar } = this.state;
    if (e.charCode === 13) {
      this.getData({
        page: this.state.searchBar.page,
        search: searchBar.searchKey.trim(),
        province_id: searchBar.city,
        status: searchBar.status,
        work_type: searchBar.work,
        active: '',
        type: '',
      });
    }
  };

  handlePageChange = (page) => {
    const { searchBar } = this.state;
    this.getData({
      search: searchBar.searchKey,
      province_id: searchBar.city,
      status: searchBar.status,
      work_type: searchBar.work,
      active: '',
      type: '',
      page: page,
    });
  };

  render() {
    const { isLoading, error } = this.props.telesaleState;
    let loading = <Loading />;
    if (error) {
      notifyFail(error.msg);
    }
    if (!isLoading) {
      loading = null;
    }
    return (
      <>
        {loading}
        {this.renderBody()}
        {this.renderModal()}
        {this.modalDeleteSale()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  telesaleState: state.telesaleInactiveReducer,
  provinceState: state.ProvinceReducer,
  userState: state.userReducer,
});

const mapDispatchToProps = {
  getTelesaleInactiveList,
  getProvinceList,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TelesaleScreen));
