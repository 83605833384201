import React, { Component } from 'react';
import '@styles/RegisterRequest.css';
import { Row, Col } from 'react-bootstrap';
import { Popconfirm } from 'antd';

class Step extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: '1',
    };
  }

  renderLine(value) {
    return (
      <>
        <p className="m-0">{value}</p>
      </>
    );
  }

  render() {
    console.log(this.props.form.content.split('\n'));
    const content = this.props.form.content.split('\n');
    return (
      <>
        <div className="assessment step-frame d-flex align-items-center mb-2">
          <Row className="w-100">
            <Col md={10} className="text-center text-md-left">
              <div style={{ color: 'red', fontWeight: '700' }}>
                <span>{`Bước ${this.props.form?.order}: `}</span>
                <span>{this.props.form?.name}</span>
              </div>

              {/* <span>{this.props.form?.sort((a, b) => a.order > b.order )}</span> */}
            </Col>
            <Col md={2} className="">
              <Row className="justify-content-center">
                <i
                  className="far fa-edit step-icon-edit mr-2"
                  onClick={() => this.props.update('modalEdit', true, this.props.form, this.props.index)}
                ></i>
                <Popconfirm
                  title="Bạn muốn xóa bước này?"
                  onConfirm={() => this.props.destroyer(this.props.index)}
                  okText="Có"
                  cancelText="Không"
                >
                  <i className="far fa-trash-alt step-icon-del"></i>
                </Popconfirm>
              </Row>
            </Col>
            <Col md={12}>
              {/* <p>{this.props.form.content.replace('\n', <br />)}</p> */}
              <div style={{ display: 'flex', textAlign: 'left' }}>
                {content && content?.map((value) => this.renderLine(value))}
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Step;
