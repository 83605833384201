import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '@styles/Settings.css';
import './style.css';

import {
  Row,
  Col,
  FormControl,
  Modal,
  FormGroup,
  Nav,
  Table,
  Button as ButtonReactBootstrap,
} from 'react-bootstrap';
import {
  getReviewList,
  getTypeJob,
  getTelesaleList,
  getUserInfo,
} from '@src/redux/actions';
import { connect } from 'react-redux';
import {
  requestListTag,
  requestCreateTag,
  requestUpdateTag,
  requestDeleteTag,
  requestCreateReview,
  requestUpdateReview,
  requestDeleteReview,
  requestCreateTypeJob,
  requestDeleteTypeJob,
  requestUpdateTypeJob,
  requestPostConfigKpi,
  requestGetConfigKpi,
  requestTimeUpdate,
  requestGetTimeUpdate,
  requestGetCustomerPhoneVisible,
  requestChangeCustomerPhoneVisible,
  requestGetKpiNotifications,
  requestCreateKpiNotification,
  requestUpdateKpiNotification,
  requestDeleteKpiNotification,
  requestGetMessageOption,
  requestGetAppPartner,
  requestUpdateAppPartner,
  requestGetCustomerTag,
  requestChangeCustomerTag,
} from '@constants/Api';
import { notifyFail, notifySuccess } from '@utils/notify';
import { checkAllAttrFilled } from '@utils/checkAllAttrFilled';
import { STRING, USER_ROLE } from '@constants/Constant';
import Hotline from './Hotline';
import { JOB_LINK } from '@constants/Constant';

import Keyword from './Keyword';
import Vpoint from './Vpoint';
import Checkin from './Checkin';
import CustomerData from './CustomerData';
import AuditionRegistration from './AuditionRegistration';
import Loading from '@src/components/Loading';
import { getMsSinceMidnight } from '../../../Utils/dateUtils';
import {
  Empty,
  Input,
  Upload,
  Button,
  TimePicker,
  Select,
  Typography,
  Pagination as Paging,
} from 'antd';
import moment from 'moment';

import { UploadOutlined } from '@ant-design/icons';
import { convertToTime } from '@utils/convertToTime';
import APIkey from './components/APIkey';
import ZaloOA from './zaloOA/ZaloOA';
import { setSearchParamsInUrl } from '@constants/funcHelper';
import GeneralQuestion from './GeneralQuestion';

const { Option } = Select;
const { TextArea } = Input;
const { Text, Link } = Typography;
const TYPE = {
  SYSTEM_NUMBER: 1,
  AUDITION: 2,
  HOTLINE: 5,
  CHECK_IN: 8,
  CUSTOMER: 9,
  TAG: 10,
  API: 4,
  ZALO_OA: 7,
};
let timeout;

function FormError(props) {
  if (props.isHidden) {
    return null;
  }

  return (
    <div style={{ color: 'red', width: '100%', textAlign: 'center' }}>
      {props.errorMessage}
    </div>
  );
}

function formatWorkingDays(string) {
  const newArr = string?.split(',').map(item => {
    return convertTime(parseInt(item));
  });
  return newArr?.length ? newArr.join(', ') : 'Chưa cập nhật';
}

function convertTime(value) {
  switch (value) {
    case 1:
      return 'CN';
    case 2:
      return 'Thứ 2';
    case 3:
      return 'Thứ 3';
    case 4:
      return 'Thứ 4';
    case 5:
      return 'Thứ 5';
    case 6:
      return 'Thứ 6';
    case 7:
      return 'Thứ 7';
    default:
      return '';
  }
}

const workingDays = [
  { value: 2, label: 'Thứ 2' },
  { value: 3, label: 'Thứ 3' },
  { value: 4, label: 'Thứ 4' },
  { value: 5, label: 'Thứ 5' },
  { value: 6, label: 'Thứ 6' },
  { value: 7, label: 'Thứ 7' },
  { value: 1, label: 'CN' },
];

const userRoles = [
  // { value: 1, label: 'Admin' }, // Admin
  { value: 2, label: 'Admin' }, // ENTERPRISE
  // { value: 3, label: 'Quản lý' }, // PROFESSIONAL_MANAGER
  // { value: 4, label: 'Quản lý nhân sự' }, // HR_MANAGER
  { value: 5, label: 'Sale' }, // Sale
  // { value: 6, label: 'Nhân viên đánh giá' }, // REVIEWER
  // { value: 7, label: 'Quản lý sale' }, // TELESALE_MANAGER
  { value: 8, label: 'Trưởng phòng ban' }, // LEADER
];
class GeneralSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalAddReview: false,
      modalAddVoice: false,
      modalEditReview: false,
      modalAddVoiceField: {
        step: '',
        name: '',
        content: '',
      },
      modalAddReviewField: {
        name: '',
        point: '',
      },
      modalEditReviewField: {
        id: '',
        name: '',
        point: '',
      },
      modalAddType: false,
      modalEditType: false,

      modalAddTag: false,
      modalEditTag: false,
      modalAddTagField: {
        name: '',
        color: '',
      },
      modalEditTagField: {
        id: '',
        name: '',
        color: '',
      },
      modalAddTypeField: {
        name: '',
      },
      modalEditTypeField: {
        id: '',
        name: '',
      },
      currentIndex: 0,
      id: '',
      confirmModal: false,
      confirmModalReview: false,
      confirmModalTag: false,
      confirmModalType: false,
      tableInfo: '',
      activePage: 1,
      isLoading: false,
      query: '',
      tagSearch: '',
      tag: '',
      page: '',
      value: '',
      timeUpdate: '',
      disable: false,
      voiceList: [],
      tagList: [],
      shouldUploadVideo: 1,
      customerPhoneVisible: true,
      multiTags: true,
      // --------------
      modalKpiNotificationVisible: false,
      kpiNotificationList: [],
      shouldAddKpiNotification: true,
      kpiNotificationModal: {
        workingDays: [],
        userRole: null,
        time: '',
        content: '',
        status: null,
      },
      contentButtons: [],
      currentKpiId: null,
      modalDeleteKpiVisible: false,
      workDays: [
        {
          id: 2,
          label: 'Thứ 2',
          isActive: false,
        },
        {
          id: 3,
          label: 'Thứ 3',
          isActive: false,
        },
        {
          id: 4,
          label: 'Thứ 4',
          isActive: false,
        },
        {
          id: 5,
          label: 'Thứ 5',
          isActive: false,
        },
        {
          id: 6,
          label: 'Thứ 6',
          isActive: false,
        },
        {
          id: 7,
          label: 'Thứ 7',
          isActive: false,
        },
        {
          id: 1,
          label: 'CN',
          isActive: false,
        },
      ],
      formattedPagingKpi: {
        total: null,
        current: null,
        pageSize: null,
      },
      currentPageKpi: 1,
      btnDisableKpi: false,
      formattedPagingTag: {
        total: null,
        current: null,
        pageSize: null,
      },
      currentPageTag: 1,
      syncInfo: {
        id: '',
        create_sale_link: '',
        create_enterprise_link: '',
        name: '',
        deeplink_ios: '',
        deeplink_android: '',
        app_store_id: '',
        play_store_id: '',
        status: '',
      },
    };
  }

  componentDidMount() {
    this.props.getReviewList();
    this.props.getTelesaleList();
    this.getData({ page: 1 });
    this.getDetail();
    this.getTimeUpdate();
    this.getTagList();
    this.getCustomerPhoneVisible();
    this.getCustomerTag();
    this.getMessageOption();
    this.getKpiNotifications();
  }

  componentDidUpdate(prevProps, prevState) {
    const searchParams = new URLSearchParams(window.location.search);
    const tabIdParams = searchParams.get('tabId');
    if (prevState.tableInfo !== Number(tabIdParams)) {
      this.setState({
        ...this.state,
        tableInfo: Number(tabIdParams),
      });
    }
  }

  async getAppPartner() {
    try {
      const res = await requestGetAppPartner();
      console.log(res, 'res partnerrrrrrrr');
      if (res.data?.name) {
        this.setState({
          ...this.state,
          syncInfo: {
            ...res.data,
          },
        });
      }
    } catch (error) {
      console.log(error, 'res errorrrrrrrrr');
    }
  }

  async getKpiNotifications() {
    const { currentPageKpi } = this.state;

    try {
      const res = await requestGetKpiNotifications({ page: currentPageKpi });
      this.setState({
        ...this.state,
        kpiNotificationList: [...res.data],
        formattedPagingKpi: {
          total: res.paging.count,
          current: res.paging.page,
          pageSize: res.paging.limit,
        },
      });
    } catch (error) {}
  }

  async getMessageOption() {
    try {
      const res = await requestGetMessageOption();
      const newArr = res.data.map(item => {
        return {
          ...item,
          isActive: false,
        };
      });
      this.setState({
        ...this.state,
        contentButtons: [...newArr],
      });
    } catch (error) {}
  }

  async getCustomerPhoneVisible() {
    try {
      this.setState({ isLoading: true });
      const res = await requestGetCustomerPhoneVisible();
      this.setState({
        ...this.state,
        isLoading: false,
        customerPhoneVisible: parseInt(res.data.value) === 0 ? false : true,
      });
    } catch (error) {}
  }

  async changeCustomerPhoneVisible(bool) {
    try {
      await requestChangeCustomerPhoneVisible({ value: bool ? 1 : 0 });
      this.getCustomerPhoneVisible();
      notifySuccess('Thay đổi thành công!');
    } catch (error) {}
  }

  async getCustomerTag() {
    try {
      this.setState({ isLoading: true });
      const res = await requestGetCustomerTag();
      this.setState({
        ...this.state,
        isLoading: false,
        multiTags: parseInt(res.data.value) === 0 ? false : true,
      });
    } catch (error) {}
  }

  async changeCustomerTag(bool) {
    try {
      await requestChangeCustomerTag({ value: bool ? 1 : 0 });
      this.props.getUserInfo();
      this.getCustomerTag();
      notifySuccess('Thay đổi thành công!');
    } catch (error) {}
  }

  async getTagList() {
    const { tagSearch, currentPageTag } = this.state;
    try {
      const res = await requestListTag({
        search: this.state.tagSearch.trim(),
        page: currentPageTag,
      });
      this.setState({
        ...this.state,
        tagList: res.data,
        formattedPagingTag: {
          total: res.paging.count,
          current: res.paging.page,
          pageSize: res.paging.limit,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
  async addTag() {
    try {
      await requestCreateTag({
        name: this.state.modalAddTagField.name,
        color: this.state.modalAddTagField.color || '#000000',
      });
      this.getTagList();
    } catch (error) {}
  }

  getData({ page, query }) {
    this.props.getTypeJob({
      page: page || '',
      query: query || '',
    });
  }

  async submitKpi() {
    const { value, timeUpdate } = this.state;
    // const check = await this.validateFormAdd();
    const { id } = this.props.match.params;
    // if (check) {
    const payload = {
      value: value,
    };

    try {
      const res = await requestPostConfigKpi(payload);
      const time = await requestTimeUpdate({ value: timeUpdate });
      notifySuccess('Cấu hình thành công');
    } catch (err) {
      this.setState({ isLoading: false });
      notifyFail(err.msg);
    }
    // }
    return;
  }

  async getTimeUpdate() {
    try {
      const res = await requestGetTimeUpdate();
      this.setState({ ...this.state, timeUpdate: res.data?.value });
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  async getDetail() {
    this.setState({ isLoading: true });
    const res = await requestGetConfigKpi();
    const data = res.data || {};
    await this.setState({
      value: data?.value,
      isLoading: false,
      file: data.user_enterprise_info?.profile_image || '',
    });
  }

  handleChange(fieldName, value) {
    this.setState({
      ...this.state,
      [fieldName]: value || '',
    });
  }

  setShowModal = (fieldName, value) => {
    this.setState({
      ...this.state,
      [fieldName]: value || '',
    });
  };

  validateFormAddReview() {
    const { modalAddReviewField } = this.state;
    if (!checkAllAttrFilled(this.state.modalAddReviewField)) {
      return false;
    }
    return true;
  }

  getResetAddReviewField() {
    return {
      name: '',
      point: '',
    };
  }

  async createReview() {
    const { modalAddReviewField } = this.state;
    const check = await this.validateFormAddReview();
    if (check) {
      const payload = {
        name: modalAddReviewField.name.trim(),
        point: modalAddReviewField.point,
      };
      // if (modalAddReviewField.point >= 0) {
      //   this.setState(
      //     {
      //       ...this.state,
      //       isLoading: false,
      //     },
      //     () => notifyFail('Điểm phải âm')
      //   );
      //   return;
      // }

      try {
        const res = await requestCreateReview(payload);

        this.props.getReviewList();

        this.setState(prevState => ({
          modalAddReviewField: {
            ...this.getResetAddReviewField(),
          },
        }));

        this.setState({ isLoading: false });
        this.showModal('modalAddReview', false);
        notifySuccess('Đánh giá thành công');
      } catch (err) {
        this.setState({ isLoading: false });
        notifyFail(err.msg);
      }
    }
    return;
  }

  async createReviewNoCloseModal() {
    const { modalAddReviewField } = this.state;
    const check = await this.validateFormAddReview();
    if (check) {
      const payload = {
        name: modalAddReviewField.name.trim(),
        point: modalAddReviewField.point,
      };
      // if (modalAddReviewField.point >= 0) {
      //   this.setState(
      //     {
      //       ...this.state,
      //       isLoading: false,
      //     },
      //     () => notifyFail('Điểm phải âm')
      //   );
      //   return;
      // }

      try {
        const res = await requestCreateReview(payload);

        this.props.getReviewList();

        this.setState(prevState => ({
          modalAddReviewField: {
            ...this.getResetAddReviewField(),
          },
        }));

        this.setState({ isLoading: false });
        // this.showModal('modalAddReview', false);
        notifySuccess('Đánh giá thành công');
      } catch (err) {
        this.setState({ isLoading: false });
        notifyFail(err.msg);
      }
    }
    // return;
  }

  validateFormAddType() {
    const { modalAddTypeField } = this.state;
    if (!checkAllAttrFilled(this.state.modalAddTypeField)) {
      return false;
    }
    return true;
  }

  getResetAddTypeField() {
    return {
      name: '',
    };
  }

  async createType() {
    const { modalAddTypeField } = this.state;
    const check = await this.validateFormAddType();
    if (check) {
      this.setState({
        ...this.state,
        disable: true,
      });
      const payload = {
        name: modalAddTypeField.name.trim(),
      };

      try {
        const res = await requestCreateTypeJob(payload);

        this.props.getTypeJob();

        this.setState(prevState => ({
          modalAddTypeField: {
            ...this.getResetAddTypeField(),
          },
          disable: true,
        }));

        this.showModalType('modalAddType', false);
        this.setState({ isLoading: false });
        notifySuccess('Thêm mới thành công');
      } catch (err) {
        this.setState({ isLoading: false, disable: false });
        notifyFail(err.msg);
      }
    }
    return;
  }

  validateFormEditReview() {
    const { modalEditReviewField } = this.state;
    const { point, ...rest } = modalEditReviewField;

    if (point === 0) {
      if (!checkAllAttrFilled(rest)) {
        return <FormError errorMessage={'Vui lòng điền đầy đủ thông tin!'} />;
      }
    } else {
      if (!checkAllAttrFilled(this.state.modalEditReviewField)) {
        return false;
      }
    }
    return true;
  }

  async updateReview() {
    const { modalEditReviewField, currentIndex } = this.state;
    const check = await this.validateFormEditReview();
    if (check) {
      this.setState({
        isLoading: true,
      });
      // if (modalEditReviewField.point >= 0) {
      //   this.setState(
      //     {
      //       ...this.state,
      //       isLoading: false,
      //     },
      //     () => notifyFail('Điểm phải âm')
      //   );
      //   return;
      // }
      try {
        const payload = {
          name: modalEditReviewField.name,
          point: String(modalEditReviewField.point),
          id: currentIndex,
        };
        await requestUpdateReview(payload);

        this.setState({ modalEditReview: false, isLoading: false }, () => {
          this.props.getReviewList();
          notifySuccess('Cập nhật thông tin thành công!');
        });
      } catch (err) {
        this.setState(
          {
            modalEditReview: false,
            isLoading: false,
          },
          () => notifyFail(err.msg)
        );
      }
    }
  }

  validateFormEditType() {
    const { modalEditTypeField } = this.state;
    if (!checkAllAttrFilled(this.state.modalEditTypeField)) {
      return false;
    }
    return true;
  }

  async updateTag() {
    const { modalEditTagField, currentIndex } = this.state;
    // const check = await this.validateFormEditReview();
    // if (check) {
    //   this.setState({
    //     isLoading: true,
    //   });
    // if (modalEditTagField.point >= 0) {
    //   this.setState(
    //     {
    //       ...this.state,
    //       isLoading: false,
    //     },
    //     () => notifyFail('Điểm phải âm')
    //   );
    //   return;
    // }
    try {
      const payload = {
        name: modalEditTagField.name,
        color: modalEditTagField.color || '#000000',
        id: currentIndex,
      };
      await requestUpdateTag(payload);

      this.setState({ modalEditTag: false, isLoading: false }, () => {
        this.getTagList();
        notifySuccess('Cập nhật thông tin thành công!');
      });
    } catch (err) {
      this.setState(
        {
          modalEditTag: false,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async updateType() {
    const { modalEditTypeField, currentIndex } = this.state;
    const check = await this.validateFormEditType();
    if (check) {
      this.setState({
        isLoading: true,
      });

      try {
        const payload = {
          name: modalEditTypeField.name.trim(),
          point: modalEditTypeField.point.trim(),
          id: currentIndex,
        };
        await requestUpdateTypeJob(payload);

        this.setState({ modalEditType: false, isLoading: false }, () => {
          this.props.getTypeJob();
          notifySuccess('Cập nhật thông tin thành công!');
        });
      } catch (err) {
        this.setState(
          {
            modalEditType: false,
            isLoading: false,
          },
          () => notifyFail(err.msg)
        );
      }
    }
  }

  async deleteReview() {
    this.setState({
      isLoading: true,
    });
    try {
      await requestDeleteReview({
        id: this.state.id,
      });
      this.setState({ confirmModalReview: false, isLoading: false }, () => {
        this.props.getReviewList();
        notifySuccess('Xóa thành công!');
      });
      // this.props.deleteStaffAction(this.state.userId);
    } catch (err) {
      this.setState(
        {
          confirmModalReview: false,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async deleteTag() {
    this.setState({
      isLoading: true,
    });
    try {
      await requestDeleteTag({
        id: this.state.id,
      });
      this.setState({ confirmModalTag: false, isLoading: false }, () => {
        this.getTagList();
        notifySuccess('Xóa thành công!');
      });
      // this.props.deleteStaffAction(this.state.userId);
    } catch (err) {
      this.setState(
        {
          confirmModalTag: false,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async deleteType() {
    this.setState({
      isLoading: true,
    });
    try {
      await requestDeleteTypeJob({
        id: this.state.id,
      });
      this.setState({ confirmModalType: false, isLoading: false }, () => {
        this.props.getTypeJob();
        notifySuccess('Xóa thành công!');
      });
      // this.props.deleteStaffAction(this.state.userId);
    } catch (err) {
      this.setState(
        {
          confirmModalType: false,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  renderWorkInfo() {
    const { tableInfo } = this.state;
    return (
      <>
        <Row className="content-header table-tabs pl-0 pb-0">
          <Col md={12}>
            <Nav variant="tabs" defaultActiveKey={tableInfo}>
              <Nav.Item>
                <Nav.Link
                  eventKey={TYPE.SYSTEM_NUMBER}
                  onSelect={() => this.showTableInfo(TYPE.SYSTEM_NUMBER)}
                  className="tab-item"
                >
                  Thông số hệ thống
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  eventKey={TYPE.AUDITION}
                  onSelect={() => this.showTableInfo(TYPE.AUDITION)}
                  className="tab-item"
                >
                  Đăng ký thử giọng
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  eventKey={TYPE.HOTLINE}
                  onSelect={() => this.showTableInfo(TYPE.HOTLINE)}
                  className="tab-item"
                >
                  Hotline
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  eventKey={TYPE.CHECK_IN}
                  onSelect={() => this.showTableInfo(TYPE.CHECK_IN)}
                  className="tab-item"
                >
                  {STRING.checkin}
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  eventKey={TYPE.CUSTOMER}
                  onSelect={() => this.showTableInfo(TYPE.CUSTOMER)}
                  className="tab-item"
                >
                  {STRING.customer_data}
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  eventKey={TYPE.TAG}
                  onSelect={() => this.showTableInfo(TYPE.TAG)}
                  className="tab-item"
                >
                  Phân loại
                </Nav.Link>
              </Nav.Item>
              {this.props.userState?.data?.role_id === USER_ROLE.ENTERPRISE && (
                <>
                  <Nav.Item>
                    <Nav.Link
                      eventKey={TYPE.API}
                      onSelect={() => this.showTableInfo(TYPE.API)}
                      className="tab-item"
                    >
                      Thiết lập API Key
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey={TYPE.ZALO_OA}
                      onSelect={() => this.showTableInfo(TYPE.ZALO_OA)}
                      className="tab-item"
                    >
                      Tài khoản
                    </Nav.Link>
                  </Nav.Item>
                </>
              )}
            </Nav>
          </Col>
        </Row>
        <Row className="bg-white">
          <Col md={12} className="bg-white pt-4" style={{ minHeight: '700px' }}>
            {tableInfo == TYPE.SYSTEM_NUMBER ? (
              this.renderReview()
            ) : tableInfo == TYPE.AUDITION ? (
              this.renderVoice()
            ) : // : tableInfo == 3 ? (
            //   renderSync()
            // )
            tableInfo == TYPE.API ? (
              <APIkey />
            ) : tableInfo == TYPE.HOTLINE ? (
              this.renderHotLine()
            ) : tableInfo == TYPE.CHECK_IN ? (
              this.renderCheckin()
            ) : tableInfo == TYPE.CUSTOMER ? (
              this.renderCustomerData()
            ) : tableInfo == TYPE.ZALO_OA ? (
              <ZaloOA />
            ) : (
              this.renderTag()
            )}
          </Col>
        </Row>
      </>
    );
  }

  showTableInfo(status) {
    this.setState(
      {
        ...this.state,
        tableInfo: status || TYPE.SYSTEM_NUMBER,
      },
      () => {
        setSearchParamsInUrl('tabId', status || TYPE.SYSTEM_NUMBER);
      }
    );
  }

  renderCustomerData() {
    return <CustomerData />;
  }

  async handleUpdateAppPartner() {
    const {
      create_sale_link,
      create_enterprise_link,
      name,
      deeplink_ios,
      deeplink_android,
      app_store_id,
      play_store_id,
      status,
      id,
    } = this.state.syncInfo;

    if (!name) {
      notifyFail('Vui lòng nhập tên app!');
      return;
    }
    if (!create_sale_link) {
      notifyFail('Vui lòng nhập link tạo sale!');
      return;
    }

    try {
      const payload = {
        id: id,
        create_sale_link: create_sale_link,
        create_enterprise_link: create_enterprise_link,
        name: name,
        deeplink_ios: deeplink_ios || '',
        deeplink_android: deeplink_android || '',
        app_store_id: app_store_id || '',
        play_store_id: play_store_id || '',
        status: parseInt(status),
      };
      const res = requestUpdateAppPartner(payload);
      notifySuccess('Lưu đồng bộ thành công!');
      const getAppPartnerTimeout = setTimeout(() => {
        this.getAppPartner();
      }, 1000);

      return () => clearTimeout(getAppPartnerTimeout);
    } catch (error) {
      notifyFail('Lưu thất bại, vui lòng kiểm tra lại các trường đã nhập!');
    }
  }

  handleChangeSyncInfo(fieldName, value) {
    this.setState({
      ...this.state,
      syncInfo: {
        ...this.state.syncInfo,
        [fieldName]: value,
      },
    });
  }

  renderSync() {
    const {
      create_sale_link,
      create_enterprise_link,
      name,
      deeplink_ios,
      deeplink_android,
      app_store_id,
      play_store_id,
    } = this.state.syncInfo;

    return (
      <>
        <Col sm={12} md={12}>
          <Row>
            <Col sm={12} className="d-flex justify-content-between">
              <h5>Thông số đồng bộ</h5>
              <ButtonReactBootstrap
                variant="success"
                className="text-light m-0 mt-4"
                onClick={() => this.handleUpdateAppPartner()}
              >
                Lưu đồng bộ
              </ButtonReactBootstrap>
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={12}>
          <Row>
            <Col md={6} sm={6} className="mt-3">
              <label>Tên app (*)</label>
              <FormControl
                type="text"
                placeholder="Tên app"
                value={name}
                onChange={e =>
                  this.handleChangeSyncInfo('name', e.target.value)
                }
              ></FormControl>
            </Col>
            <Col md={6} sm={6} className="mt-3">
              <label>Link tạo sale (*)</label>
              <FormControl
                type="text"
                placeholder="Link tạo sale"
                value={create_sale_link}
                onChange={e =>
                  this.handleChangeSyncInfo('create_sale_link', e.target.value)
                }
              ></FormControl>
            </Col>
            <Col md={6} sm={6} className="mt-3">
              <i class="fab fa-apple fa-lg mr-2"></i>
              <label>Deeplink IOS</label>
              <FormControl
                type="text"
                placeholder="Deeplink IOS"
                value={deeplink_ios}
                onChange={e =>
                  this.handleChangeSyncInfo('deeplink_ios', e.target.value)
                }
              ></FormControl>
            </Col>
            <Col md={6} sm={6} className="mt-3">
              <i class="fab fa-apple fa-lg mr-2"></i>
              <label>Mã app store</label>
              <FormControl
                type="text"
                placeholder="Mã app store"
                value={app_store_id}
                onChange={e =>
                  this.handleChangeSyncInfo('app_store_id', e.target.value)
                }
              ></FormControl>
            </Col>
            <Col md={6} sm={6} className="mt-3">
              <i class="fab fa-android fa-lg mr-2"></i>
              <label>Deeplink Android</label>
              <FormControl
                type="text"
                placeholder="Deeplink Android"
                value={deeplink_android}
                onChange={e =>
                  this.handleChangeSyncInfo('deeplink_android', e.target.value)
                }
              ></FormControl>
            </Col>
            <Col md={6} sm={6} className="mt-3">
              <i class="fab fa-android fa-lg mr-2"></i>
              <label>Mã play store</label>
              <FormControl
                type="text"
                placeholder="Mã play store"
                value={play_store_id}
                onChange={e =>
                  this.handleChangeSyncInfo('play_store_id', e.target.value)
                }
              ></FormControl>
            </Col>
          </Row>
        </Col>
      </>
    );
  }

  renderBody() {
    const { tableInfo } = this.state;
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <Row
            className="mt-1"
            style={{
              padding: '0px 20px',
              borderRadius: '8px',
              marginBottom: 30,
            }}
          >
            <Col md className="bg">
              <Row>
                <h2>Cấu hình chung</h2>
              </Row>
              <div class="line"></div>
              {tableInfo && this.renderWorkInfo()}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderTag() {
    const { tagSearch } = this.state;
    return (
      <>
        <Col sm={12} md={12}>
          <Row>
            <Col sm={12} className="d-flex justify-content-between">
              <h5>Phân loại</h5>
              <Button
                variant="success"
                style={{ background: 'green' }}
                className="text-light"
                onClick={() => this.showModal('modalAddTag', true)}
              >
                Thêm mới
              </Button>
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={12}>
          <Row>
            <Col md={6} sm={12}>
              <FormControl
                type="text"
                id=""
                placeholder="Phân loại"
                value={tagSearch}
                onChange={e =>
                  this.setState(
                    { ...this.state, tagSearch: e.target.value },
                    () => this.getTagList()
                  )
                }
                // onKeyPress={(e) => this.handleKeyPress(e)}
              />
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={12}>
          <div className="row">
            <Col
              sm={10}
              md={12}
              style={{
                padding: '0px',
                marginTop: '20px',
              }}
            >
              <Row className="table-wrap mx-4">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>STT</th>
                      <th>Tên</th>
                      <th>Màu</th>
                      <th>Ngày tạo</th>
                      <th></th>
                    </tr>
                  </thead>
                  {this.renderTableTag()}
                </Table>
              </Row>
              <Row>
                <Col
                  md={12}
                  className="d-flex justify-content-center justify-content-md-end pr-4"
                >
                  {this.renderPagination()}
                </Col>
              </Row>
            </Col>
          </div>
        </Col>
      </>
    );
  }

  renderTableTag() {
    const { tagList, confirmModalType, formattedPagingTag } = this.state;
    return (
      <tbody>
        {tagList.length > 0 ? (
          tagList?.map((value, index) => (
            <tr>
              <td>
                {index +
                  formattedPagingTag.pageSize *
                    (formattedPagingTag.current - 1) +
                  1}
              </td>
              <td>
                {value.name || <p style={{ color: 'grey' }}>Chưa cập nhật</p>}
              </td>
              <td>
                {value.color ? (
                  <div
                    style={{
                      backgroundColor: value.color,
                      width: '100%',
                      height: '20px',
                    }}
                  ></div>
                ) : (
                  <p style={{ color: 'grey' }}>Chưa cập nhật</p>
                )}
              </td>
              <td>
                {Intl.DateTimeFormat('vi-VN').format(
                  new Date(value.created_at)
                ) || <p style={{ color: 'grey' }}>Chưa cập nhật</p>}
              </td>
              <td>
                <div className="d-flex justify-content-center align-items-center">
                  <i
                    className="btnEdit fa fa-fw fa-edit mr-2"
                    onClick={() => {
                      this.showModalType('modalEditTag', true);
                      this.setState({
                        modalEditTagField: {
                          name: value.name,
                          color: value.color,
                        },
                        currentIndex: value.id,
                      });
                    }}
                  ></i>
                  <i
                    className="far fa-trash-alt step-icon-del"
                    onClick={() => {
                      this.setState({
                        id: value.id,
                        confirmModalTag: true,
                      });
                    }}
                  ></i>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <td colSpan="4">
            <Empty description="Không có dữ liệu"></Empty>
          </td>
        )}
      </tbody>
    );
  }
  modalAddTag() {
    const { modalAddTag, modalAddTagField, tag } = this.state;
    return (
      <Modal
        show={modalAddTag}
        onHide={() => this.showModal('modalAddTag', false)}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton className="bg-primary">
          <h5 className="text-light pb-0">Thêm thẻ tag</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          {/* {this.checkValidationReview()} */}
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Tên thẻ</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="text"
                max={0}
                placeholder="Tên thẻ"
                value={modalAddTagField.name}
                onChange={e => this.addTagFieldChange('name', e.target.value)}
              ></FormControl>
            </Row>
          </FormGroup>
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label for="colorpicker">Màu</label>
              <input
                type="color"
                id="colorpicker"
                value={modalAddTagField.color}
                onChange={e => {
                  // this.addTagFieldChangmodalAddTagFielde('color', e.target.value);
                  this.setState({
                    ...this.state,
                    modalAddTagField: {
                      ...this.state.modalAddTagField,
                      color: e.target.value,
                    },
                  });
                }}
              ></input>
            </Row>
            {/* <Row className="modal-row">
              <FormControl
                type="text"
                max={0}
                placeholder="Màu"
                value={modalAddTagField.color}
                onChange={(e) => this.addTagFieldChange('color', e.target.value)}
              ></FormControl>
            </Row> */}
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              if (!modalAddTagField.name) {
                notifyFail('Vui lòng nhập tên thẻ!');
                return;
              }
              this.addTag();
              let newArr = [...tag];
              newArr.push(modalAddTagField);
              this.setState({
                ...this.state,
                modalAddTagField: {
                  name: '',
                  color: '',
                },
                tag: [...newArr],
              });
              this.showModal('modalAddTag', false);
            }}
            className="text-light"
            style={{ background: 'green' }}
          >
            Lưu
          </Button>
          <Button
            style={{ background: 'red' }}
            variant="danger"
            onClick={() => this.showModal('modalAddTag', false)}
            className="text-light"
          >
            Thoát
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  editModalTag = () => {
    const { modalEditTag, modalEditTagField, currentIndex } = this.state;
    return (
      <Modal
        show={modalEditTag}
        onHide={() => this.showModal('modalEditTag', false)}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton className="bg-primary">
          <h5 className="text-light pb-0">Cập nhật tag</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          {/* {this.checkValidationEditReview()} */}
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Tên thẻ</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="text"
                placeholder="Nhập nội dung"
                value={modalEditTagField.name}
                onChange={e => this.editTagFieldChange('name', e.target.value)}
              ></FormControl>
            </Row>
          </FormGroup>
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Màu</label>
              <input
                type="color"
                id="colorpicker"
                value={modalEditTagField.color}
                onChange={e => {
                  // this.addTagFieldChangmodalAddTagFielde('color', e.target.value);
                  this.setState({
                    ...this.state,
                    modalEditTagField: {
                      ...this.state.modalEditTagField,
                      color: e.target.value,
                    },
                  });
                }}
              ></input>
            </Row>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="success"
            style={{ background: 'green' }}
            // className="header-btn btn-oversize"
            // name="empty"
            onClick={() => {
              if (!modalEditTagField.name) {
                notifyFail('Vui lòng nhập tên thẻ!');
                return;
              }
              this.updateTag();
            }}
            className="text-light"
          >
            Lưu
          </Button>
          <Button
            style={{ background: 'red' }}
            variant="danger"
            // className="header-btn btn-oversize"
            onClick={() => this.showModal('modalEditTag', false)}
            className="text-light"
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  editModalReview = () => {
    const { modalEditReview, modalEditReviewField, currentIndex } = this.state;
    return (
      <Modal
        show={modalEditReview}
        onHide={() => this.showModal('modalEditReview', false)}
        dialogClassName="modal-90w"
        centered
        scrollable
      >
        <Modal.Header closeButton className="bg-primary">
          <h5 className="text-light pb-0">Cập nhật đánh giá</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          {this.checkValidationEditReview()}
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Nội dung</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="text"
                placeholder="Nhập nội dung"
                value={modalEditReviewField.name}
                onChange={e =>
                  this.editReviewFieldChange('name', e.target.value)
                }
              ></FormControl>
            </Row>
          </FormGroup>
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Điểm</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="number"
                max={0}
                placeholder="Nhập điểm"
                value={modalEditReviewField.point}
                onChange={e =>
                  this.editReviewFieldChange('point', e.target.value)
                }
              ></FormControl>
            </Row>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="success"
            style={{ background: 'green' }}
            // className="header-btn btn-oversize"
            // name="empty"
            onClick={() => this.updateReview()}
            className="text-light"
          >
            Lưu
          </Button>
          <Button
            style={{ background: 'red' }}
            variant="danger"
            // className="header-btn btn-oversize"
            onClick={() => this.showModal('modalEditReview', false)}
            className="text-light"
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderVoice() {
    const { voiceList } = this.state;
    const hidden = true;
    if (hidden) {
      return <AuditionRegistration />;
    } else {
      return (
        <>
          <Col sm={12} md={12}>
            <Row>
              <Col sm={12} className="d-flex justify-content-between">
                <h5>Đăng ký thử giọng:</h5>
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={12}>
            <Row>
              <Col md={6}>
                <FormControl
                  as="select"
                  id=""
                  style={{ marginBottom: '20px' }}
                  // value={status}
                  onChange={e => {
                    this.setState({
                      ...this.state,
                      shouldUploadVideo: e.target.value,
                    });
                  }}
                >
                  {JOB_LINK.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </FormControl>
              </Col>
              {parseInt(this.state.shouldUploadVideo) === 1 ? (
                <Col md={12} sm={12}>
                  <FormControl
                    type="text"
                    id=""
                    placeholder="Link youtube"
                    style={{ marginBottom: '30px' }}
                  />
                </Col>
              ) : parseInt(this.state.shouldUploadVideo) === 2 ? (
                <Col md={12} sm={12}>
                  <Upload>
                    <Button icon={<UploadOutlined />}>Đăng video</Button>
                  </Upload>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </Col>
          <Col sm={12} md={12}>
            <Row>
              <Col sm={12} className="d-flex justify-content-between">
                <h5 style={{ marginTop: '20px' }}>Kịch bản thử giọng:</h5>
                <i
                  className="fas fa-plus text-green add-btn"
                  style={{ marginTop: '20px' }}
                  onClick={() => this.showModal('modalAddVoice', true)}
                ></i>
              </Col>
            </Row>
          </Col>
          {voiceList.length ? (
            voiceList.map((item, index) => {
              return (
                <div
                  style={{
                    padding: '30px 20px',
                    backgroundColor: '#ffffff',
                    borderRadius: '12px',
                    boxShadow: '0 20px 80px rgba(0, 0, 0, 0.2)',
                    marginTop: '10px',
                  }}
                >
                  <Row>
                    <Col sm={12}>
                      <Row>
                        <Col sm={2} style={{ color: 'red', paddingRight: 0 }}>
                          Bước {item.step}:
                        </Col>
                        <Col sm={7} style={{ paddingLeft: 0 }}>
                          {item.name}
                        </Col>
                        <Col sm={3} style={{ color: 'red' }}>
                          <div className="float-right">
                            <i
                              className="btnEdit fa fa-fw fa-edit mr-2"
                              onClick={() => {}}
                            ></i>
                            <i
                              className="far fa-trash-alt step-icon-del"
                              onClick={() => {}}
                            ></i>
                          </div>
                        </Col>
                      </Row>
                    </Col>

                    <Col sm={12}>
                      <Row>
                        <Col sm={12}>{item.content}</Col>
                      </Row>
                    </Col>
                  </Row>
                  {/* <FormResult
                  form={{ ...item }}
                  // deleteForm={this.deleteForm.bind(this)}
                  // showSubModal={this.showSubModal.bind(this)}
                  // showEditModal={this.showEditModal.bind(this)}
                  key={index}
                  index={index}
                /> */}
                </div>
              );
            })
          ) : (
            <></>
          )}
        </>
      );
    }
  }

  renderReview() {
    const { value, timeUpdate } = this.state;
    return (
      <div className="row" style={{ justifyContent: 'space-around' }}>
        <Col
          sm={10}
          md={5}
          style={{
            padding: '30px 20px',
            backgroundColor: '#ffffff',
            borderRadius: '12px',
            boxShadow: '0 20px 80px rgba(0, 0, 0, 0.2)',
            marginTop: '10px',
          }}
        >
          <Row className="mt-3 mx-2">
            <Col sm={12} className="d-flex justify-content-between">
              <h5>Đánh giá chất lượng cuộc gọi</h5>
              <i
                className="fas fa-plus text-green add-btn"
                onClick={() => this.showModal('modalAddReview', true)}
              ></i>
            </Col>
          </Row>
          <Row className="table-wrap mx-4">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Nội dung</th>
                  <th>Điểm</th>
                  <th></th>
                </tr>
              </thead>
              {this.renderTableReview()}
            </Table>
          </Row>
        </Col>
        <Col
          sm={10}
          md={5}
          style={{
            padding: '30px 20px',
            backgroundColor: '#ffffff',
            borderRadius: '12px',
            boxShadow: '0 20px 80px rgba(0, 0, 0, 0.2)',
            marginTop: '10px',
          }}
        >
          <Row className="mt-3 mx-2">
            <Col md={12} sm={12} className="d-flex justify-content-between">
              <h5>Thông số hệ thống</h5>
            </Col>
          </Row>
          <Row className="table-wrap mx-4">
            <Col md={12} sm={12}>
              <Row className="mb-3">
                <Col md={5}>Thời gian cập nhật kpi</Col>
                <Col md={4}>
                  <FormControl
                    type="number"
                    min={0}
                    max={31}
                    id=""
                    value={value}
                    onChange={e =>
                      this.handleInputChange('value', e.target.value)
                    }
                  />
                </Col>
                <Col md={2}>ngày</Col>
              </Row>

              <Row className="mb-3">
                <Col md={5}>Thời gian cập nhật cuộc gọi</Col>
                <Col md={4}>
                  <FormControl
                    type="number"
                    min={0}
                    max={31}
                    id=""
                    value={timeUpdate}
                    onChange={e =>
                      this.handleInputChange('timeUpdate', e.target.value)
                    }
                  />
                </Col>
                <Col md={2}>phút</Col>
              </Row>

              <Row className="mb-3">
                <Col md={5}>Ẩn số điện thoại khách hàng</Col>
                <Col md={4}>
                  <FormControl
                    type="checkbox"
                    style={{ width: '20px', height: '20px' }}
                    checked={this.state.customerPhoneVisible}
                    onChange={() =>
                      this.changeCustomerPhoneVisible(
                        !this.state.customerPhoneVisible
                      )
                    }
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={5}>Khách hàng có nhiều phân loại</Col>
                <Col md={4}>
                  <FormControl
                    type="checkbox"
                    style={{ width: '20px', height: '20px' }}
                    checked={this.state.multiTags}
                    onChange={() =>
                      this.changeCustomerTag(!this.state.multiTags)
                    }
                  />
                </Col>
              </Row>

              {/* <Row className="mb-3">
                <Col md={5}>Thời gian cấu hình KPI</Col>
                <Col md={4}>
                  <FormControl
                    type="number"
                    id=""
                    value={value}
                    onChange={(e) => this.handleInputChange('value', e.target.value)}
                  />
                </Col>
                <Col md={2}>ngày</Col>
              </Row> */}
              <div className="text-center text-md-center">
                <Button
                  variant="primary"
                  style={{ background: 'green' }}
                  className="text-light"
                  onClick={() => this.submitKpi()}
                >
                  Lưu
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
        <Col
          md={11}
          style={{
            padding: '30px 20px',
            backgroundColor: '#ffffff',
            borderRadius: '12px',
            boxShadow: '0 20px 80px rgba(0, 0, 0, 0.2)',
            marginTop: '10px',
          }}
        >
          <Row className="mt-3 mx-2">
            <Col sm={12} className="d-flex justify-content-between">
              <h5>Cấu hình thông báo Kpi</h5>
              <i
                className="fas fa-plus text-green add-btn"
                onClick={() => {
                  this.setState({
                    modalKpiNotificationVisible: true,
                    shouldAddKpiNotification: true,
                  });
                }}
              ></i>
            </Col>
          </Row>
          <Row className="table-wrap mx-4">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Ngày thông báo</th>
                  <th>Loại tài khoản gửi</th>
                  <th>Thời gian gửi</th>
                  <th>Trạng thái</th>
                  <th></th>
                </tr>
              </thead>
              {this.renderTableReviewKPI()}
            </Table>
          </Row>
          <Row className="mx-4 d-flex justify-content-end">
            <Paging
              {...this.state.formattedPagingKpi}
              onChange={value => {
                this.setState(
                  {
                    ...this.state,
                    currentPageKpi: value,
                  },
                  () => {
                    this.getKpiNotifications();
                  }
                );
              }}
            />
          </Row>
        </Col>
      </div>
    );
  }

  handleCloseKpiModal() {
    const { contentButtons, kpiNotificationModal, workDays } = this.state;
    const buttons = [...contentButtons];
    const days = [...workDays];

    this.setState({
      ...this.state,
      modalKpiNotificationVisible: false,
      kpiNotificationModal: {
        workingDays: [],
        userRole: null,
        time: '',
        content: '',
        status: null,
      },
      contentButtons: buttons.map(item => {
        return {
          ...item,
          isActive: false,
        };
      }),
      workDays: days.map(item => {
        return {
          ...item,
          isActive: false,
        };
      }),
    });
  }

  modalKpiNotification() {
    const {
      modalKpiNotificationVisible,
      shouldAddKpiNotification,
      contentButtons,
      kpiNotificationModal,
      workDays,
    } = this.state;
    const buttons = [...contentButtons];

    return (
      <Modal
        show={modalKpiNotificationVisible}
        onHide={() => {
          this.handleCloseKpiModal();
        }}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton className="bg-primary">
          <h5 className="text-light pb-0">
            {shouldAddKpiNotification
              ? 'Thêm thời gian thông báo'
              : 'Sửa thời gian thông báo'}
          </h5>
        </Modal.Header>
        <Modal.Body className="custom-body">
          <div className="mb-4">
            <label>Ngày làm việc</label>
            {/* <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Chọn ngày làm việc"
              value={kpiNotificationModal.workingDays || []}
              onChange={(values) => {
                this.setState({
                  ...this.state,
                  kpiNotificationModal: {
                    ...kpiNotificationModal,
                    workingDays: values,
                  },
                });
              }}
              optionFilterProp="label"
              allowClear
              notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Dữ liệu không tồn tại" />}
            >
              {workingDays.map((item, index) => {
                return (
                  <Option key={index} value={item.value} label={item.label}>
                    {item.label}
                  </Option>
                );
              })}
            </Select> */}
            <div className="d-flex">
              {workDays.map(item => {
                return (
                  <div
                    key={item.id}
                    className="d-flex justify-content-center align-items-center ml-2"
                    style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      backgroundColor: item.isActive ? '#1890ff' : 'white',
                      border: 'solid 1px #1890ff',
                    }}
                    onClick={() => this.handleWorkDays(item.id)}
                  >
                    <span
                      style={{
                        fontSize: '12px',
                        color: item.isActive ? 'white' : '#1890ff',
                      }}
                    >
                      {item.label}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mb-4">
            <label>Loại tài khoản nhận</label>
            <Select
              style={{ width: '100%' }}
              placeholder="Chọn loại tài khoản"
              value={kpiNotificationModal.userRole}
              onChange={value => {
                const formattedValue = value || null;
                this.setState({
                  ...this.state,
                  kpiNotificationModal: {
                    ...kpiNotificationModal,
                    userRole: formattedValue,
                  },
                });
              }}
              allowClear
            >
              {userRoles.map((item, index) => {
                return (
                  <Option key={index} value={item.value} label={item.label}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div className="mb-4 time-picker">
            <label>Thời gian</label>
            <TimePicker
              placeholder="Chọn thời gian"
              style={{ width: '100%' }}
              value={
                kpiNotificationModal.time
                  ? moment(kpiNotificationModal.time)
                  : ''
              }
              format="HH:mm"
              showNow={false}
              onSelect={value => {
                this.setState({
                  ...this.state,
                  kpiNotificationModal: {
                    ...kpiNotificationModal,
                    time: value || '',
                  },
                });
              }}
              onChange={value => {
                this.setState({
                  ...this.state,
                  kpiNotificationModal: {
                    ...kpiNotificationModal,
                    time: value || '',
                  },
                });
              }}
              // allowClear
              // renderExtraFooter={() => <></>}
              // popupStyle={{ width: '100px', fontSize: '20px' }}
            />
            {/* <FormControl
              type="time"
              aria-describedby="basic-addon1"
              placeholder={`Chọn thời gian`}
              onChange={(e) =>
                this.setState({
                  ...this.state,
                  kpiNotificationModal: {
                    ...kpiNotificationModal,
                    time: e.target.value,
                  },
                })
              }
              value={kpiNotificationModal.time}
            /> */}
          </div>
          {!shouldAddKpiNotification && (
            <div className="mb-4">
              <label>Trạng thái</label>
              <Select
                style={{ width: '100%' }}
                placeholder="Chọn trạng thái"
                value={kpiNotificationModal.status}
                onChange={value => {
                  // const formattedValue = value || null;
                  this.setState({
                    ...this.state,
                    kpiNotificationModal: {
                      ...kpiNotificationModal,
                      status: value,
                    },
                  });
                }}
                allowClear
              >
                <Option value={1}>Đang hoạt động</Option>
                <Option value={0}>Ngừng hoạt động</Option>
              </Select>
            </div>
          )}
          <div className="mb-4">
            <label>Nội dung thông báo</label>
            <br />
            {contentButtons
              .sort((itemA, itemB) => {
                if (itemA.id < itemB.id) {
                  return -1;
                }
                if (itemA.id > itemB.id) {
                  return 1;
                }
                return 0;
              })
              .map(item => {
                if (
                  (kpiNotificationModal.userRole === 2 ||
                    kpiNotificationModal.userRole === 8) &&
                  item.name === 'Tên sale'
                ) {
                  return <></>;
                } else {
                  return (
                    <Button
                      key={item.id}
                      style={{
                        marginRight: '5px',
                        marginBottom: '5px',
                      }}
                      type={item.isActive ? 'primary' : ''}
                      onClick={() => this.handleContentButtons(item)}
                      // danger
                    >
                      {item.name}
                    </Button>
                  );
                }
              })}
            <TextArea
              placeholder="Nội dung thông báo"
              // autoSize
              value={kpiNotificationModal.content}
              onChange={e => {
                this.handleChangeContent(e.target.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="success"
            disabled={this.state.btnDisableKpi}
            onClick={() => {
              this.setState({
                ...this.state,
                btnDisableKpi: true,
              });
              this.addUpdateKpiNotification();
            }}
            className="text-light"
            style={{ background: 'green' }}
          >
            Lưu
          </Button>
          <Button
            style={{ background: 'red' }}
            variant="danger"
            onClick={() => {
              this.handleCloseKpiModal();
            }}
            className="text-light"
          >
            Hủy
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  handleContentButtons(item) {
    const { contentButtons, kpiNotificationModal } = this.state;
    const { content } = kpiNotificationModal;
    const newButtons = [...contentButtons];
    const newArr = newButtons.map((subItem, subIndex) => {
      if (subItem.id === item.id) {
        return {
          ...subItem,
          isActive: !subItem.isActive,
        };
      } else {
        return subItem;
      }
    });
    let newContent = '';

    if (item.isActive) {
      newContent = content.replace(item.value, '');
    } else {
      newContent = content.concat(item.value);
    }

    this.setState({
      ...this.state,
      kpiNotificationModal: {
        ...kpiNotificationModal,
        // content: newArr
        //   .filter((item) => item.isActive)
        //   .sort((itemA, itemB) => {
        //     if (itemA.id < itemB.id) {
        //       return -1;
        //     }
        //     if (itemA.id > itemB.id) {
        //       return 1;
        //     }
        //     return 0;
        //   })
        //   .map((item) => item.value)
        //   .join(''),
        content: newContent.trim(),
      },
      contentButtons: [...newArr],
    });
  }

  handleChangeContent(value) {
    const { contentButtons, kpiNotificationModal } = this.state;
    const contentArr = value.match(/\[(.*?)\]/g) || [];
    const buttons = contentButtons.map(item => {
      if (contentArr.includes(item.value)) {
        return {
          ...item,
          isActive: true,
        };
      } else {
        return {
          ...item,
          isActive: false,
        };
      }
    });

    this.setState({
      ...this.state,
      contentButtons: [...buttons],
      kpiNotificationModal: {
        ...kpiNotificationModal,
        content: value,
      },
    });
  }

  handleWorkDays(id) {
    const { workDays } = this.state;
    const newArr = [...workDays];
    this.setState({
      ...this.state,
      workDays: newArr.map(item => {
        if (item.id === id) {
          return {
            ...item,
            isActive: !item.isActive,
          };
        } else {
          return item;
        }
      }),
    });
  }

  async addUpdateKpiNotification() {
    const {
      shouldAddKpiNotification,
      kpiNotificationModal,
      currentKpiId,
      workDays,
      modalKpiNotificationVisible,
    } = this.state;
    // return;
    const activeDays = workDays.filter(item => item.isActive);
    console.log({ activeDays, workDays });

    if (modalKpiNotificationVisible) {
      if (!activeDays.length) {
        notifyFail('Vui lòng chọn ngày làm việc!');
        this.setState({
          ...this.state,
          btnDisableKpi: false,
        });
        return;
      }
      if (!kpiNotificationModal.userRole) {
        notifyFail('Vui lòng chọn loại tài khoản!');
        this.setState({
          ...this.state,
          btnDisableKpi: false,
        });
        return;
      }
      if (!kpiNotificationModal.time) {
        notifyFail('Vui lòng chọn thời gian!');
        this.setState({
          ...this.state,
          btnDisableKpi: false,
        });
        return;
      }
      if (!shouldAddKpiNotification) {
        if (typeof kpiNotificationModal.status !== 'number') {
          notifyFail('Vui lòng chọn trạng thái!');
          this.setState({
            ...this.state,
            btnDisableKpi: false,
          });
          return;
        }
      }
      if (!kpiNotificationModal.content) {
        notifyFail('Vui lòng chọn nội dung thông báo!');
        this.setState({
          ...this.state,
          btnDisableKpi: false,
        });
        return;
      }
    }

    try {
      if (shouldAddKpiNotification) {
        await requestCreateKpiNotification({
          // days_value: kpiNotificationModal.workingDays.join(','),
          time: getMsSinceMidnight(kpiNotificationModal.time._d),
          days_value: activeDays.map(item => item.id).join(','),
          // time: parseInt(moment(kpiNotificationModal.time._d).format('x') / 1000),
          role_id: kpiNotificationModal.userRole,
          content: kpiNotificationModal.content,
        });
        notifySuccess('Thêm thành công!');
      } else {
        await requestUpdateKpiNotification({
          id: currentKpiId,
          // days_value: kpiNotificationModal.workingDays.join(','),
          days_value: activeDays.map(item => item.id).join(','),
          time: getMsSinceMidnight(kpiNotificationModal.time._d),
          // time: parseInt(moment(kpiNotificationModal.time._d).format('x') / 1000),
          role_id: kpiNotificationModal.userRole,
          content: kpiNotificationModal.content,
          is_active: kpiNotificationModal.status,
        });
        notifySuccess('Sửa thành công!');
      }

      this.setState(
        {
          isLoading: false,
          modalKpiNotificationVisible: false,
          kpiNotificationModal: {
            workingDays: [],
            userRole: null,
            time: '',
            content: '',
            status: null,
          },
          workDays: workDays.map(item => {
            return {
              ...item,
              isActive: false,
            };
          }),
        },
        () => {
          this.getKpiNotifications();
          this.getMessageOption();
          this.setState({
            ...this.state,
            btnDisableKpi: false,
          });
        }
      );
    } catch (error) {
      this.setState({
        isLoading: false,
        btnDisableKpi: false,
      });
    }
  }

  renderQuestion() {
    return (
      <>
        <GeneralQuestion />
      </>
    );
  }

  renderTypeJob() {
    const { query } = this.state;
    return (
      <>
        <Col sm={12} md={12}>
          <Row>
            <Col sm={12} className="d-flex justify-content-between">
              <h5>Loại công việc</h5>
              <Button
                variant="success"
                style={{ background: 'green' }}
                className="text-light"
                onClick={() => this.showModalType('modalAddType', true)}
              >
                Thêm loại công việc
              </Button>
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={12}>
          <Row>
            <Col md={6} sm={12}>
              <FormControl
                type="text"
                id=""
                placeholder="Tên loại công việc"
                value={query}
                onChange={e => this.handleChange('query', e.target.value)}
                // onKeyPress={(e) => this.handleKeyPress(e)}
              />
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={12}>
          <div className="row">
            <Col
              sm={10}
              md={12}
              style={{
                padding: '0px',
                marginTop: '20px',
              }}
            >
              <Row className="table-wrap mx-4">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>STT</th>
                      <th>Tên</th>
                      <th>Ngày tạo</th>
                      <th></th>
                    </tr>
                  </thead>
                  {this.renderTableTypeJob()}
                </Table>
              </Row>
              <Row>
                <Col
                  md={12}
                  className="d-flex justify-content-center justify-content-md-end"
                >
                  {this.renderPagination()}
                </Col>
              </Row>
            </Col>
          </div>
        </Col>
      </>
    );
  }

  renderTableTypeJob() {
    const typeJob = this.props.typeJobState?.data;
    return (
      <tbody>
        {typeJob.length > 0 ? (
          typeJob?.map((value, index) => (
            <tr>
              <td>{index + 1 || '--'}</td>
              <td>{value.name || '--'}</td>
              <td>
                {Intl.DateTimeFormat('vi-VN').format(
                  new Date(value.created_at)
                ) || '###'}
              </td>
              <td>
                <div className="d-flex justify-content-center align-items-center">
                  <i
                    className="btnEdit fa fa-fw fa-edit mr-2"
                    onClick={() => {
                      this.showModalType('modalEditType', true);
                      this.setState({
                        modalEditTypeField: {
                          name: value.name,
                        },
                        currentIndex: value.id,
                      });
                    }}
                  ></i>
                  <i
                    className="far fa-trash-alt step-icon-del"
                    onClick={() => {
                      this.setState({
                        id: value.id,
                        confirmModalType: true,
                      });
                    }}
                  ></i>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <td colSpan="4">
            <Empty description="Không có dữ liệu"></Empty>
          </td>
        )}
      </tbody>
    );
  }

  renderHotLine() {
    return (
      <>
        <Hotline />
      </>
    );
  }

  renderKeyWord() {
    const { keyWord } = this.state;
    return (
      <>
        <Keyword />
      </>
    );
  }

  renderVPoint() {
    return (
      <>
        <Vpoint />
      </>
    );
  }

  renderCheckin() {
    return (
      <>
        <Checkin />
      </>
    );
  }

  renderPagination() {
    const itemsCount = this.props.categoryState?.paging?.totalItemCount;
    const limit = this.props.categoryState?.paging?.limit;
    const { query } = this.state;
    return (
      <Paging
        {...this.state.formattedPagingTag}
        onChange={value => {
          this.setState(
            {
              ...this.state,
              currentPageTag: value,
            },
            () => {
              this.getTagList();
            }
          );
        }}
      />
    );
  }

  renderTableReview() {
    const review = this.props.reviewState.data;
    return (
      <tbody>
        {review.length > 0 ? (
          review?.map((value, index) => (
            <tr key={index}>
              <td>{index + 1 || '--'}</td>
              <td>{value.name || '--'}</td>
              <td>{value?.point}</td>
              <td>
                <div className="d-flex justify-content-center align-items-center">
                  <i
                    className="btnEdit fa fa-fw fa-edit mr-2"
                    onClick={() => {
                      this.showModal('modalEditReview', true);
                      this.setState({
                        modalEditReviewField: {
                          name: value.name,
                          point: value.point,
                        },
                        currentIndex: value.id,
                      });
                    }}
                  ></i>
                  <i
                    className="far fa-trash-alt step-icon-del"
                    onClick={() => {
                      this.setState({
                        id: value.id,
                        confirmModalReview: true,
                      });
                    }}
                  ></i>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <td colSpan="4">
            <Empty description="Không có dữ liệu" />
          </td>
        )}
      </tbody>
    );
  }

  renderTableReviewKPI() {
    const {
      kpiNotificationList,
      activePage,
      formattedPagingKpi,
      currentPageKpi,
    } = this.state;

    return (
      <tbody>
        {kpiNotificationList.length ? (
          kpiNotificationList.map((item, index) => {
            return (
              <tr key={index}>
                <td>
                  {index +
                    formattedPagingKpi.pageSize *
                      (formattedPagingKpi.current - 1) +
                    1}
                </td>
                <td>{formatWorkingDays(item?.days_value)}</td>
                <td>
                  {item?.role_id === 2
                    ? 'Admin'
                    : item?.role_id === 5
                    ? 'Sale'
                    : item?.role_id === 8
                    ? 'Trưởng phòng ban'
                    : 'Chưa cập nhật'}
                </td>
                {/* <td>{moment(new Date(item.time)).format('HH:mm:ss')}</td> */}
                {/* <td>{moment.unix(item.time).format('HH:mm')}</td> */}
                <td>{convertToTime(item.time).toString().split('.')[0]}</td>
                <td>
                  {parseInt(item?.is_active) === 1
                    ? 'Đang hoạt động'
                    : 'Ngừng hoạt động'}
                </td>
                <td>
                  <div className="d-flex justify-content-center align-items-center">
                    <i
                      className="btnEdit fa fa-fw fa-edit mr-2"
                      onClick={() => {
                        this.handleUpdateKpi(item);
                      }}
                    ></i>
                    <i
                      className="far fa-trash-alt step-icon-del"
                      onClick={() => {
                        this.setState({
                          ...this.state,
                          currentKpiId: item?.id,
                          modalDeleteKpiVisible: true,
                        });
                      }}
                    ></i>
                  </div>
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan="6">
              <Empty description="Không có dữ liệu" />
            </td>
          </tr>
        )}
      </tbody>
    );
  }

  handleUpdateKpi(item) {
    const { contentButtons, kpiNotificationModal, workDays } = this.state;
    const contentArr = item.content.match(/\[(.*?)\]/g);
    const days = [...workDays];
    const activeDays = [
      ...item.days_value.split(',').map(item => parseInt(item)),
    ];

    this.setState({
      ...this.state,
      modalKpiNotificationVisible: true,
      shouldAddKpiNotification: false,
      kpiNotificationModal: {
        ...kpiNotificationModal,
        workingDays: item.days_value.split(',').map(value => parseInt(value)),
        userRole: parseInt(item.role_id),
        // time: moment.unix(item.time),
        time: moment.unix(item.time / 1000).add(-7, 'hours'),
        content: item.content,
        status: parseInt(item.is_active),
      },
      currentKpiId: item.id,
      contentButtons: contentButtons.map(item => {
        if (contentArr?.includes(item.value)) {
          return {
            ...item,
            isActive: true,
          };
        } else {
          return {
            ...item,
            isActive: false,
          };
        }
      }),
      workDays: days.map(item => {
        if (activeDays?.includes(item.id)) {
          return {
            ...item,
            isActive: true,
          };
        } else {
          return {
            ...item,
            isActive: false,
          };
        }
      }),
    });
  }

  handleChange = (field, value) => {
    this.setState(
      {
        ...this.state,
        [field]: value,
      },
      () =>
        this.getData({
          query: this.state.query,
          page: '',
        })
    );
  };

  showModal = (modal, bool, data) => {
    this.setState({
      [modal]: bool,
    });
    if (data) {
      this.setState({
        modalEditReviewField: {
          name: data.name,
          point: data.point,
          id: data.id,
        },
      });
    }
  };

  showModalType = (modal, bool, data) => {
    this.setState({
      [modal]: bool,
      disable: false,
    });
    if (data) {
      this.setState({
        modalEditTypeField: {
          name: data.name,
          id: data.id,
        },
      });
    }
  };

  handleTableCheck(selectedRowKeys) {
    const saleList = this.props.telesaleState?.data;
    var arr = [],
      convertedArr = [];
    if (saleList?.length) {
      arr = saleList.filter(sale => selectedRowKeys?.includes(sale.id));
      arr.forEach(sale =>
        convertedArr.push({
          telesale_id: sale.id,
          is_default: 0,
        })
      );
    }
    this.setState({
      selectedSale: [...selectedRowKeys],
      telesales: [...convertedArr],
    });
  }

  checkValidationReview = () => {
    const { modalAddReviewField } = this.state;
    if (!checkAllAttrFilled(this.state.modalAddReviewField)) {
      return <FormError errorMessage={'Vui lòng điền đầy đủ thông tin!'} />;
    }
    return true;
  };

  addModalVoice = () => {
    const { modalAddVoice, modalAddVoiceField, voiceList } = this.state;
    return (
      <Modal
        show={modalAddVoice}
        onHide={() => this.showModal('modalAddVoice', false)}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton className="bg-primary">
          <h5 className="text-light pb-0">Tạo kịch bản thử giọng</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          {/* {this.checkValidationReview()} */}
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Bước</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="text"
                placeholder="Bước"
                value={modalAddVoiceField.step}
                onChange={e => this.addVoiceFieldChange('step', e.target.value)}
              ></FormControl>
            </Row>
          </FormGroup>
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Tên bước</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="text"
                max={0}
                placeholder="Tên bước"
                value={modalAddVoiceField.name}
                onChange={e => this.addVoiceFieldChange('name', e.target.value)}
              ></FormControl>
            </Row>
          </FormGroup>
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Nội dung</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="text"
                max={0}
                placeholder="Nội dung"
                value={modalAddVoiceField.content}
                onChange={e =>
                  this.addVoiceFieldChange('content', e.target.value)
                }
              ></FormControl>
            </Row>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="success"
            onClick={() => {
              let newArr = [...voiceList];
              newArr.push(modalAddVoiceField);
              this.setState({
                ...this.state,
                modalAddVoiceField: {
                  step: '',
                  name: '',
                  content: '',
                },
                voiceList: [...newArr],
              });
              this.showModal('modalAddVoice', false);
            }}
            className="text-light"
            style={{ background: 'green' }}
          >
            Lưu
          </Button>
          <Button
            style={{ background: 'red' }}
            variant="danger"
            onClick={() => this.showModal('modalAddVoice', false)}
            className="text-light"
          >
            Hủy
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  addModalReview = () => {
    const { modalAddReview, modalAddReviewField } = this.state;
    return (
      <Modal
        show={modalAddReview}
        onHide={() => this.showModal('modalAddReview', false)}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton className="bg-primary">
          <h5 className="text-light pb-0">Thêm đánh giá</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          {this.checkValidationReview()}
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Nội dung</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="text"
                placeholder="Nhập nội dung"
                value={modalAddReviewField.name}
                onChange={e =>
                  this.addReviewFieldChange('name', e.target.value)
                }
              ></FormControl>
            </Row>
          </FormGroup>
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Điểm</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="number"
                max={0}
                placeholder="Nhập điểm"
                value={modalAddReviewField.point}
                onChange={e =>
                  this.addReviewFieldChange('point', e.target.value)
                }
              ></FormControl>
            </Row>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="primary"
            style={{ background: 'blue' }}
            onClick={() => this.createReviewNoCloseModal()}
            className="text-light"
          >
            Thêm mới và tiếp tục
          </Button>
          <Button
            variant="success"
            style={{ background: 'green' }}
            onClick={() => this.createReview()}
            className="text-light"
          >
            Thêm mới
          </Button>
          <Button
            variant="danger"
            style={{ background: 'red' }}
            onClick={() => this.showModal('modalAddReview', false)}
            className="text-light"
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  checkValidationType = () => {
    const { modalAddTypeField } = this.state;
    if (!checkAllAttrFilled(this.state.modalAddTypeField)) {
      return <FormError errorMessage={'Vui lòng điền đầy đủ thông tin!'} />;
    }
    return true;
  };

  addModalType = () => {
    const { modalAddType, modalAddTypeField } = this.state;
    return (
      <Modal
        show={modalAddType}
        onHide={() => this.showModalType('modalAddType', false)}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton className="bg-primary">
          <h5 className="text-light pb-0">Thêm loại công việc</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          {this.checkValidationType()}
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Nội dung</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="text"
                placeholder="Nhập nội dung"
                value={modalAddTypeField.name}
                onChange={e => this.addTypeFieldChange('name', e.target.value)}
              ></FormControl>
            </Row>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="success"
            className="text-light"
            style={{ background: 'green' }}
            onClick={() => this.createType()}
            disabled={this.state.disable}
          >
            Thêm mới
          </Button>
          <Button
            variant="danger"
            style={{ background: 'red' }}
            className="text-light"
            onClick={() => this.showModalType('modalAddType', false)}
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  checkValidationEditReview = data => {
    const { modalEditReviewField } = this.state;
    const { point, ...rest } = modalEditReviewField;

    if (point === 0) {
      if (!checkAllAttrFilled(rest)) {
        return <FormError errorMessage={'Vui lòng điền đầy đủ thông tin!'} />;
      }
    } else {
      if (!checkAllAttrFilled(this.state.modalEditReviewField)) {
        return <FormError errorMessage={'Vui lòng điền đầy đủ thông tin!'} />;
      }
    }
    return true;
  };

  addReviewFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalAddReviewField: {
        ...this.state.modalAddReviewField,
        [field]: value,
      },
    });
  };

  addVoiceFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalAddVoiceField: {
        ...this.state.modalAddVoiceField,
        [field]: value,
      },
    });
  };

  addTagFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalAddTagField: {
        ...this.state.modalAddTagField,
        [field]: value,
      },
    });
  };

  addTypeFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalAddTypeField: {
        ...this.state.modalAddTypeField,
        [field]: value,
      },
    });
  };

  editReviewFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalEditReviewField: {
        ...this.state.modalEditReviewField,
        [field]: value,
      },
    });
  };

  editTagFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalEditTagField: {
        ...this.state.modalEditTagField,
        [field]: value,
      },
    });
  };

  editTypeFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalEditTypeField: {
        ...this.state.modalEditTypeField,
        [field]: value,
      },
    });
  };

  checkValidationEditType = data => {
    const { modalEditTypeField } = this.state;
    if (!checkAllAttrFilled(this.state.modalEditTypeField)) {
      return <FormError errorMessage={'Vui lòng điền đầy đủ thông tin!'} />;
    }
    return true;
  };

  editModalType = () => {
    const { modalEditType, modalEditTypeField, currentIndex } = this.state;
    return (
      <Modal
        show={modalEditType}
        onHide={() => this.showModal('modalEditType', false)}
        dialogClassName="modal-90w"
        centered
        scrollable
      >
        <Modal.Header closeButton className="bg-primary">
          <h5 className="text-light pb-0">Cập nhật loại công việc</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          {this.checkValidationEditType()}
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Nội dung</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="text"
                placeholder="Nhập nội dung"
                value={modalEditTypeField.name}
                onChange={e => this.editTypeFieldChange('name', e.target.value)}
              ></FormControl>
            </Row>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="success"
            style={{ background: 'green' }}
            className="text-light"
            name="empty"
            onClick={() => this.updateType()}
          >
            Lưu
          </Button>
          <Button
            variant="danger"
            style={{ background: 'red' }}
            className="text-light"
            onClick={() => this.showModal('modalEditType', false)}
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderConfirmReviewModal() {
    const { confirmModalReview } = this.state;
    return (
      <Modal
        show={confirmModalReview}
        onHide={() =>
          this.setState({
            confirmModalReview: false,
          })
        }
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header
          closeButton
          className="bg-primary"
          // style={{ textAlign: "center", backgroundColor: "#fd7e14" }}
        >
          <h5 className="text-light pb-0">Bạn chắc chắn muốn xóa?</h5>
        </Modal.Header>
        <Modal.Body className="custom-body">
          <Row>
            <Col className="button-wrapper " style={{ textAlign: 'center' }}>
              <Button
                variant="success"
                style={{ background: 'green' }}
                // className="header-btn btn-oversize"
                onClick={() => {
                  if (timeout) clearTimeout(timeout);
                  this.deleteReview(this.state.id);
                }}
                className="text-light"
                // style={{ marginRight: "10px" }}
              >
                Có
              </Button>
              <Button
                variant="danger"
                style={{ background: 'red' }}
                // className="header-btn btn-oversize"
                onClick={() => {
                  this.setState({
                    confirmModalReview: false,
                  });
                }}
                className="text-light"
              >
                Thoát
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  modalDeleteKpiNotification() {
    const { currentKpiId, modalDeleteKpiVisible } = this.state;

    return (
      <Modal
        show={modalDeleteKpiVisible}
        onHide={() =>
          this.setState({
            ...this.state,
            modalDeleteKpiVisible: false,
          })
        }
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton className="bg-primary">
          <h5 className="text-light pb-0">Bạn chắc chắn muốn xóa?</h5>
        </Modal.Header>
        <Modal.Body className="custom-body">
          <Row>
            <Col className="button-wrapper " style={{ textAlign: 'center' }}>
              <Button
                variant="success"
                style={{ background: 'green' }}
                onClick={() => {
                  this.handleDeleteKpi(currentKpiId);
                }}
                className="text-light"
              >
                Có
              </Button>
              <Button
                variant="danger"
                style={{ background: 'red' }}
                onClick={() => {
                  this.setState({
                    ...this.state,
                    modalDeleteKpiVisible: false,
                  });
                }}
                className="text-light"
              >
                Thoát
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  async handleDeleteKpi(id) {
    try {
      this.setState({
        ...this.state,
        isLoading: true,
      });
      const res = await requestDeleteKpiNotification({ id });
      notifySuccess('Xóa thành công');
      this.setState(
        {
          ...this.state,
          isLoading: false,
          modalDeleteKpiVisible: false,
        },
        () => this.getKpiNotifications()
      );
    } catch (error) {
      this.setState({
        ...this.state,
        isLoading: false,
        modalDeleteKpiVisible: false,
      });
    }
  }

  renderConfirmTagModal() {
    const { confirmModalTag } = this.state;
    return (
      <Modal
        show={confirmModalTag}
        onHide={() =>
          this.setState({
            confirmModalTag: false,
          })
        }
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header
          closeButton
          className="bg-primary"
          // style={{ textAlign: "center", backgroundColor: "#fd7e14" }}
        >
          <h5 className="text-light pb-0">Bạn chắc chắn muốn xóa?</h5>
        </Modal.Header>
        <Modal.Body className="custom-body">
          <Row>
            <Col className="button-wrapper " style={{ textAlign: 'center' }}>
              <Button
                variant="success"
                style={{ background: 'green' }}
                // className="header-btn btn-oversize"
                onClick={() => {
                  if (timeout) clearTimeout(timeout);
                  this.deleteTag(this.state.id);
                }}
                className="text-light"
                // style={{ marginRight: "10px" }}
              >
                Có
              </Button>
              <Button
                variant="danger"
                style={{ background: 'red' }}
                // className="header-btn btn-oversize"
                onClick={() => {
                  this.setState({
                    confirmModalTag: false,
                  });
                }}
                className="text-light"
              >
                Thoát
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  renderConfirmTypeModal() {
    const { confirmModalType } = this.state;
    return (
      <Modal
        show={confirmModalType}
        onHide={() =>
          this.setState({
            confirmModalType: false,
          })
        }
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header
          closeButton
          // style={{ textAlign: "center", backgroundColor: "#fd7e14" }}
          className="bg-primary"
        >
          <h5 className="text-light pb-0">Bạn chắc chắn muốn xóa?</h5>
        </Modal.Header>
        <Modal.Body className="custom-body">
          <Row>
            <Col className="button-wrapper" style={{ textAlign: 'center' }}>
              <Button
                variant="success"
                style={{ background: 'green' }}
                className="text-light"
                onClick={() => {
                  if (timeout) clearTimeout(timeout);
                  this.deleteType(this.state.id);
                }}
              >
                Có
              </Button>
              <Button
                variant="danger"
                style={{ background: 'red' }}
                className="text-light"
                onClick={() => {
                  this.setState({
                    confirmModalType: false,
                  });
                }}
              >
                Thoát
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  handleInputChange(field, value) {
    this.setState({
      ...this.state,
      [field]: value,
    });
  }

  render() {
    const { isLoading } = this.props.typeJobState;
    let loading = <Loading />;
    if (!isLoading) {
      loading = null;
    }
    return (
      <>
        {/* {isLoading ? <Loading /> : null} */}
        {loading}
        {this.addModalReview()}
        {this.modalAddTag()}
        {this.editModalTag()}
        {this.addModalVoice()}
        {this.editModalReview()}
        {this.editModalType()}
        {this.renderConfirmReviewModal()}
        {this.renderConfirmTagModal()}
        {this.renderConfirmTypeModal()}
        {this.modalDeleteKpiNotification()}
        {this.addModalType()}
        {this.modalKpiNotification()}
        {this.renderBody()}
      </>
    );
  }
}

const mapStateToProps = state => ({
  reviewState: state.reviewReducer,
  typeJobState: state.typeJobReducer,
  userState: state.userReducer,
});

const mapDispatchToProps = {
  getReviewList,
  getTypeJob,
  getTelesaleList,
  getUserInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GeneralSetting));
