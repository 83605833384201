import { BOX_SHADOW } from '@constants/Constant';
import {
  Checkbox,
  DatePicker,
  Divider,
  Input,
  Radio,
  Row as RowAnt,
  Skeleton,
  Space,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { CALLING_CODE } from '@components/callProviders/CallConstant';
import { isCallEnded } from '@components/callProviders/CallHelper';
import { formatTime, setSearchParamsInUrl } from '@constants/funcHelper';
import { RE_CALL } from '../Constant';
import SuitableJob from './SuitableJob';

import CallUtil from '@components/callProviders/CallUtil';
import '../style.css';

const { TextArea } = Input;

const styles = {
  questionContainer: {
    marginTop: 10,
    marginBottom: 20,
  },
  answerRadioMargin: {
    marginRight: 10,
  },
  childQuestionContainer: {
    marginTop: 5,
    marginBottom: 5,
  },
};

/**
 * function canNotUpdateResultCard
 * @param {number} status
 * @returns Boolean
 */
const canNotUpdateResultCard = status => {
  let isUpdate = true;

  if (isCallEnded(status) || status === CALLING_CODE.Answered) isUpdate = false;

  return isUpdate;
};

const CALL_STATUS_IN_CARD_RESULT = {
  AGREE_TO_EXCHANGE: 4,
  DO_NOT_ANSWER_THE_PHONE: 2,
};

const Result = props => {
  const {
    callDetail,
    customerDetail,
    callStatusId,
    onChangeStatusId,
    setNewJobId,
    onChangeAnswerList,
    newJobId,
    shouldRecall,
    onChangeShouldRecall,
    convertedAnswerList,
    onOpenModalTimeRecall,
    callNote,
    setCallNote,
    jobList,
    setConvertedAnswerList,
    answerDate,
    setAnswerDate,
  } = props;

  const { currentCall, callId } = props;

  const prevCall = useRef();

  const [timeRecall, setTimeRecall] = useState();

  // const [callStatusRequestFromApi, setCallStatusRequestFromApi] = useState([]);
  const [callStatusWithCurrentCall, setCallStatusWithCurrentCall] =
    useState(null);

  useEffect(() => {
    if (prevCall.current?.status !== currentCall?.status) {
      if (currentCall.status === CALLING_CODE.Answered) {
        onChangeStatusId(CALL_STATUS_IN_CARD_RESULT.AGREE_TO_EXCHANGE);
        setCallStatusWithCurrentCall(
          CALL_STATUS_IN_CARD_RESULT.AGREE_TO_EXCHANGE
        );
      }

      if (
        currentCall.status === CALLING_CODE.Busy ||
        currentCall.status === CALLING_CODE.TemporarilyUnavailable
      ) {
        onChangeStatusId(CALL_STATUS_IN_CARD_RESULT.DO_NOT_ANSWER_THE_PHONE);
        setCallStatusWithCurrentCall(
          CALL_STATUS_IN_CARD_RESULT.DO_NOT_ANSWER_THE_PHONE
        );
      }

      if (currentCall.status === CALLING_CODE.Ended) {
        if (prevCall.current?.status === CALLING_CODE.Answered) {
          onChangeStatusId(CALL_STATUS_IN_CARD_RESULT.AGREE_TO_EXCHANGE);
          setCallStatusWithCurrentCall(
            CALL_STATUS_IN_CARD_RESULT.AGREE_TO_EXCHANGE
          );
        } else {
          onChangeStatusId(CALL_STATUS_IN_CARD_RESULT.DO_NOT_ANSWER_THE_PHONE);
          setCallStatusWithCurrentCall(
            CALL_STATUS_IN_CARD_RESULT.DO_NOT_ANSWER_THE_PHONE
          );
        }
      }

      prevCall.current = currentCall;
    }
  }, [currentCall]);

  useEffect(() => {
    if (callId) resetField();
  }, [callId]);

  // useEffect(() => {
  //   getCallStatus();
  // }, []);

  useEffect(() => {
    if (callDetail.time_recall)
      setTimeRecall(formatTime(callDetail.time_recall));
  }, [callDetail.time_recall]);

  // const getCallStatus = async () => {
  //   try {
  //     const { data } = await requestGetCallStatus();

  //     //logic này chưa hiểu mục đích loại trừ callStatusRequestFromApi có id === 5 để làm gì
  //     // setCallStatusRequestFromApi(data.filter(item => item.id !== 5));

  //     setCallStatusRequestFromApi(data);
  //   } catch (error) {
  //     console.log('Error getCallStatus: ', { error });
  //   }
  // };

  const renderQuestion = v => {
    switch (v.type_input) {
      case 1:
        return (
          <Row>
            <Col style={{ margin: '8px 0 0 7px' }}>
              <Input
                placeholder="Nhập..."
                disabled={
                  !v.isUpdated || canNotUpdateResultCard(currentCall.status)
                }
                value={
                  convertedAnswerList?.find(item => item.id === v.id)
                    ?.text_result
                }
                onChange={e => {
                  onChangeAnswerList(v, e.target.value);
                  if (e.target.value) {
                    setSearchParamsInUrl('content_call', e.target.value);
                  }
                }}
              />
            </Col>
          </Row>
        );

      case 3:
        return (
          <>
            <Checkbox.Group
              disabled={
                !v.isUpdated || canNotUpdateResultCard(currentCall.status)
              }
              value={
                convertedAnswerList?.find(answer => answer.id === v.id)
                  ?.number_result
                  ? convertedAnswerList?.find(answer => answer.id === v.id)
                      ?.number_result
                  : v?.number_result
              }
              // defaultValuevalue={v?.number_result}
              onChange={e => onChangeAnswerList(v, e)}
            >
              {v.job_form_answers?.map((item, index) => (
                <>
                  <Row className="mt-2 pl-2" key={index}>
                    <Col className="p-0">
                      <Checkbox
                        style={{ fontWeight: '500' }}
                        key={item.id}
                        value={item.id}
                      >
                        {item.name}
                      </Checkbox>
                      <Row className="pl-4">
                        {convertedAnswerList?.find(answer => answer.id === v.id)
                          ?.number_result[0] === item.id &&
                          item?.job_form_answer_child?.length > 0 &&
                          item?.job_form_answer_child?.map((v1, index) => (
                            <Col
                              key={index}
                              xs={12}
                              style={styles.childQuestionContainer}
                            >
                              <Row>
                                <>
                                  {v1.name}
                                  {v1.is_require === 1 && ' (*)'}
                                </>
                              </Row>
                              {renderQuestion(v1)}
                            </Col>
                          ))}
                      </Row>
                    </Col>
                  </Row>
                </>
              ))}
            </Checkbox.Group>
          </>
        );

      case 4:
        return (
          <Row>
            <Col style={{ margin: '8px 0 0 7px' }}>
              <DatePicker
                style={{ width: '100%' }}
                placeholder="Chọn ngày"
                format="DD/MM/YYYY"
                value={answerDate}
                disabled={
                  !v.isUpdated || canNotUpdateResultCard(currentCall.status)
                }
                onChange={(date, dateString) => {
                  onChangeAnswerList(v, dateString);
                  setAnswerDate(date);
                }}
              />
            </Col>
          </Row>
        );

      default:
        return (
          <>
            <Radio.Group
              value={
                convertedAnswerList?.find(answer => answer.id === v.id)
                  ?.number_result[0]
                  ? convertedAnswerList?.find(answer => answer.id === v.id)
                      ?.number_result[0]
                  : v?.number_result[0]
              }
              defaultValue={v?.number_result?.[0]}
              disabled={
                !v.isUpdated || canNotUpdateResultCard(currentCall.status)
              }
              onChange={e => onChangeAnswerList(v, e.target.value)}
            >
              {v.job_form_answers?.map((item, index) => (
                <>
                  <Row className="mt-2 pl-2" key={index}>
                    <Col className="p-0">
                      <Radio
                        style={{ fontWeight: '500' }}
                        key={item.id}
                        value={item.id}
                      >
                        {item.name}
                      </Radio>
                      <Row className="pl-4">
                        {convertedAnswerList?.find(answer => answer.id === v.id)
                          ?.number_result[0] === item.id &&
                          item?.job_form_answer_child?.length > 0 &&
                          item?.job_form_answer_child?.map((v1, index) => (
                            <Col
                              key={index}
                              xs={12}
                              style={styles.childQuestionContainer}
                            >
                              <Row>
                                <>
                                  {v1.name}
                                  {v1.is_require === 1 && ' (*)'}
                                </>
                              </Row>
                              {renderQuestion(v1)}
                            </Col>
                          ))}
                      </Row>
                    </Col>
                  </Row>
                </>
              ))}
            </Radio.Group>
          </>
        );
    }
  };

  const resetField = () => {
    setConvertedAnswerList([]);
    onChangeStatusId(null);
    setCallStatusWithCurrentCall(null);
    setCallNote('');
    setTimeRecall();
    onChangeShouldRecall(0);
    setAnswerDate('');
  };

  return (
    <div
      style={{
        height: '100%',
        backgroundColor: 'white',
        borderRadius: '6px',
        padding: 10,
        boxShadow: BOX_SHADOW,
      }}
    >
      <div style={{ display: 'flex', flexFlow: 'nowrap' }}>
        <div style={{ width: '100%' }}>
          <div className="text-left pb-2">
            <strong style={{ fontSize: '17px', color: '#ef8737' }}>
              Phiếu kết quả
            </strong>
          </div>

          <Divider style={{ margin: 0 }} />
          {callDetail?.id ? (
            <div style={{ padding: '0 10px' }}>
              <RowAnt justify="space-between" style={{ margin: '10px 0 0 0' }}>
                <div className="text-left">
                  <b>Gọi lại?</b>
                </div>
                <Radio.Group
                  onChange={e => onChangeShouldRecall(e.target.value)}
                  value={shouldRecall}
                  defaultValue={
                    timeRecall && timeRecall !== 'null'
                      ? RE_CALL.YES
                      : RE_CALL.NO
                  }
                >
                  <Radio
                    style={{ fontWeight: '500' }}
                    value={RE_CALL.YES}
                    onClick={() => onOpenModalTimeRecall()}
                  >
                    Có
                  </Radio>
                  <Radio style={{ fontWeight: '500' }} value={RE_CALL.NO}>
                    Không
                  </Radio>
                </Radio.Group>
              </RowAnt>

              <RowAnt>{timeRecall || ''}</RowAnt>

              <div>
                <Col style={styles.questionContainer}>
                  <Row>
                    <b>Trạng thái cuộc gọi (*)</b>
                  </Row>

                  <Row className="mt-2 pl-2">
                    <Radio.Group
                      value={callStatusWithCurrentCall || callStatusId}
                      disabled={canNotUpdateResultCard(currentCall.status)}
                      onChange={e => {
                        setCallStatusWithCurrentCall(0);
                        onChangeStatusId(e.target.value);
                      }}
                    >
                      <Space direction="vertical">
                        {CallUtil?.listCallStatus?.length &&
                          CallUtil?.listCallStatus.map(s => (
                            <Radio
                              style={{ fontWeight: '500', color: 'black' }}
                              key={s.id}
                              value={s.id}
                            >
                              {s.name}
                            </Radio>
                          ))}
                      </Space>
                    </Radio.Group>
                  </Row>

                  {callDetail?.default_forms?.length ? (
                    callDetail?.default_forms?.map((value, index) => (
                      <Col key={index}>
                        <Row className="mt-2">
                          <b>
                            {`Câu ${index + 1}: `}
                            {value.name}
                            {value.is_require === 1 && ' (*)'}
                          </b>
                        </Row>
                        {renderQuestion(value)}
                      </Col>
                    ))
                  ) : (
                    <></>
                  )}
                </Col>

                <Col style={styles.questionContainer}>
                  {callDetail.job_form_questions?.length > 0 ? (
                    callDetail.job_form_questions?.map((value, index) => (
                      <div key={index}>
                        <Row>
                          <b>
                            {`Câu ${
                              callDetail?.default_forms?.length + index + 1
                            }: `}
                            {value.name}
                            {value.is_require === 1 && ' (*)'}
                          </b>
                        </Row>
                        {renderQuestion(value)}
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </Col>

                <div style={{ width: '100%' }}>
                  <b>Ghi chú cuộc gọi</b>
                  <TextArea
                    rows={4}
                    defaultValue={callDetail.call_note}
                    value={callNote}
                    placeholder="Ghi chú cuộc gọi"
                    showCount
                    maxLength={2000}
                    onChange={e => {
                      setCallNote(e.target.value);
                    }}
                  />
                </div>
                {!customerDetail.name ? (
                  <SuitableJob
                    customerDetail={customerDetail}
                    jobList={jobList}
                    jobId={newJobId}
                    onChangeJob={value => setNewJobId(value)}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

Result.propTypes = {
  currentCall: PropTypes.object.isRequired,
};

export default Result;
