import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ROUTER, STRING, NUMBER } from '@constants/Constant';
import { Link, withRouter } from 'react-router-dom';
import '@styles/Customer.css';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { Tag, TreeSelect, Upload, Table } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import Loading from '@src/components/Loading';
import { notifyFail, notifySuccess } from '@utils/notify';

class ConfirmDeleteModal extends Component {
  static propTypes = {
    title: PropTypes.string,
    shown: PropTypes.bool,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }
  handleConfirmPress = () => {
    this.props.onConfirm();
  };

  handleClosePress = (e) => {
    this.props.onClose && this.props.onClose();
  };

  render() {
    return (
      <Modal show={this.props.shown} onHide={this.handleClosePress} centered centered scrollable>
        <Modal.Header closeButton className="pb-0">
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>

        {/* <Modal.Body>{this.props.isLoading ? <Loading /> : null}</Modal.Body> */}
        <Modal.Footer>
          <Button variant="secondary" className="text-light" onClick={this.handleClosePress}>
            Bỏ qua
          </Button>
          <Button variant="danger" className="text-light btn-oversize" onClick={this.props.onConfirm}>
            Xoá
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ConfirmDeleteModal.defaultProps = {
  title: 'Bạn chắc chắn muốn xóa dữ liệu?',
  shown: false,
};
export default ConfirmDeleteModal;

// const mapStateToProps = (state) => ({
//   provinceState: state.ProvinceReducer,
// });

// const mapDispatchToProps = {
//   getProvinceList,
// };
// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddCustomersToGroupModal));
