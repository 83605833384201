import React, { Component } from 'react';
import Pagination from 'react-js-pagination';

class Pagi extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { paging } = this.props;
    return (
      <div>
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          hideDisabled
          activePage={Number(paging?.page)}
          itemsCountPerPage={paging?.limit}
          totalItemsCount={paging?.count || 0}
          pageRangeDisplayed={5}
          hideNavigation
          onChange={(page) => this.props.handlePageChange(page)}
        />
      </div>
    );
  }
}

export default Pagi;
