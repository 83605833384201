import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { message, Modal, Radio } from 'antd';
import PropTypes from 'prop-types';

import { CUSTOMER_DATA_TYPE_PROPERTY } from '../../Constants';
import { VietnameseString } from '@constants/VietnameseString';
import { requestGetCustomerDataType } from '../../services/api';

import { ButtonSystemStyle, ScrollStyle, TableBorderedAntStyle } from '@styles/Style';

const SelectCustomerDataTypeStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  margin-bottom: 12px;
`;

const FooterModalStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
`;

const ContentDataTypeStyled = styled(ScrollStyle)`
  max-height: 60vh;
  overflow: auto;
`;

function ChooseCustomerDataTypeModal(props) {
  const { visible, onCancelModal, onAddCustomerDataType } = props;

  const [customerDataTypePropertySelected, setCustomerDataTypePropertySelected] = useState(
    CUSTOMER_DATA_TYPE_PROPERTY.ALL
  );

  const [customerDataTypeSelected, setCustomerDataTypeSelected] = useState([]);
  const [customerDataType, setCustomerDataType] = useState([]);

  const getCustomerDataType = async ({ type }) => {
    try {
      const { data, dataTypeDfOptions } = await requestGetCustomerDataType({ type });
      const dataCustom = data.map((el) => ({ ...el, key: el.code }));
      const dataTypeDfOptionsCustom = dataTypeDfOptions.map((el) => ({ ...el, options: [] }));

      const dataTypeDf = dataTypeDfOptionsCustom.reduce((r, item) => ({ ...r, [item.data_type_df]: item }), {});

      dataCustom.forEach((v) => {
        if (v.data_type_df && dataTypeDf[v.data_type_df]) {
          dataTypeDf[v.data_type_df].options.push(v);
          return;
        }
        /*
          mặc định những item không có data_type_df hoặc data_type_df không trùng với
          dataTypeDfOptions(lấy từ api) thì đẩy vào options của data_type_df === 'ref_customer'
         */
        dataTypeDf.ref_customer.options.push(v);
      });

      setCustomerDataType(Object.values(dataTypeDf));
    } catch (error) {
      console.log('Error getCustomerDataType: ', { error });
    }
  };

  useEffect(() => {
    getCustomerDataType({ type: customerDataTypePropertySelected });
  }, [customerDataTypePropertySelected]);

  const handleRenderContent = (customerDataType) => {
    return (
      <ContentDataTypeStyled>
        {customerDataType.map((type, index) => {
          return (
            <TableBorderedAntStyle
              key={index}
              dataSource={type.options}
              columns={[
                {
                  title: type.name,
                  dataIndex: 'label',
                  key: 'label',
                },
              ]}
              rowSelection={{
                hideSelectAll: true,
                preserveSelectedRowKeys: true,
                selectedRowKeys: customerDataTypeSelected.map((i) => i.key),
                onSelect: (record, selected, selectedRows, nativeEvent) => {
                  if (selected) {
                    setCustomerDataTypeSelected((prev) => [...prev, record]);
                  } else {
                    setCustomerDataTypeSelected((prev) => prev.filter((el) => el.id !== record.id));
                  }
                },
              }}
              pagination={false}
            />
          );
        })}
      </ContentDataTypeStyled>
    );
  };

  return (
    <Modal
      width="550px"
      visible={visible}
      title="Lựa chọn điều kiện"
      maskClosable={false}
      footer={null}
      onOk={() => {}}
      onCancel={onCancelModal}
    >
      <SelectCustomerDataTypeStyled>
        <div>Loại trường:</div>

        <Radio.Group
          onChange={(value) => {
            setCustomerDataTypePropertySelected(value.target.value);
          }}
          value={customerDataTypePropertySelected}
        >
          <Radio value={CUSTOMER_DATA_TYPE_PROPERTY.ALL}>{VietnameseString.all}</Radio>
          <Radio value={CUSTOMER_DATA_TYPE_PROPERTY.DATE_TIME}>{VietnameseString.date}</Radio>
          <Radio value={CUSTOMER_DATA_TYPE_PROPERTY.NUMBER}>{VietnameseString.number}</Radio>
          <Radio value={CUSTOMER_DATA_TYPE_PROPERTY.TEXT}>{VietnameseString.text}</Radio>
          <Radio value={CUSTOMER_DATA_TYPE_PROPERTY.OPTIONS}>{VietnameseString.select}</Radio>
        </Radio.Group>
      </SelectCustomerDataTypeStyled>

      {handleRenderContent(customerDataType)}

      <FooterModalStyled>
        <ButtonSystemStyle
          onClick={() => {
            if (customerDataTypeSelected.length) {
              onAddCustomerDataType(customerDataTypeSelected);
              onCancelModal();
            } else {
              message.warn(VietnameseString.please_select_attribute);
            }
          }}
        >
          {VietnameseString.save}
        </ButtonSystemStyle>
      </FooterModalStyled>
    </Modal>
  );
}

ChooseCustomerDataTypeModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancelModal: PropTypes.func.isRequired,
  onAddCustomerDataType: PropTypes.func.isRequired,
};

export default ChooseCustomerDataTypeModal;
