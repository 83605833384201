import { Table } from 'antd';
import styled from 'styled-components';

export const WrapperTable = styled.div`
  overflow-y: hidden;

  &::-webkit-scrollbar {
    width: 0;
  }
  padding-bottom: 10px;
`;

export const TableStyled = styled(Table)`
  background-color: #fff;

  & th.ant-table-cell {
    font-weight: 700;
  }

  & th.ant-table-cell {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  & td.ant-table-cell {
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word !important;
  }

  & .ant-table-expanded-row td.ant-table-cell {
    padding: 10px;
  }

  & .ant-table-body {
    overflow: overlay;
  }

  & .ant-table-body::-webkit-scrollbar-track {
    border-radius: 12px;
    /* background-color: rgba(0, 0, 0, 0.005); */
  }

  & .ant-table-body::-webkit-scrollbar {
    height: 10px;
    width: 4px;
  }

  & .ant-table-body::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: #999;
  }
`;

export const ResultStyled = styled.div`
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
`;

export const ReloadStyled = styled.div`
  margin-left: 10px;
  cursor: pointer;

  &:hover {
    scale: 1.3;
  }
`;
