import { message } from 'antd';

export const downloadFile = (fileLink, fileName) => {
  if (fileLink.includes('localhost')) return message.error('Có lỗi xảy ra');
  try {
    const link = document.createElement('a');
    link.setAttribute('download', fileName);

    link.href = fileLink;

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();
    // Clean up and remove the link
    link.parentNode.removeChild(link);
  } catch {
    message.error('Có lỗi xảy ra');
  }
};
