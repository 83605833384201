import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { VietnameseString } from '@constants/VietnameseString';
import { ROUTER } from '@constants/Constant';
import PromotionDetail from './PromotionDetail';
import { message, Pagination, Popconfirm, Row, Tag } from 'antd';
import Filter from '../Filter';
import moment from 'moment';

import { TableBorderedAntStyle } from '@styles/Style';
import { WrapperContentActionStyled } from '../../ElectronicVoucherStyled';
import { voucherService } from '../../service';
import useDebounce from 'src/hooks/useDebounce';
import { notifyFail, notifySuccess } from '@utils/notify';
import { useSelector } from 'react-redux';

// số lượng item trên 1 page
const RECORD_SIZE = 12;

function VoucherManagement({ callbackReload }) {
  const history = useHistory();

  const state = useSelector(state => {
    return state?.userReducer;
  });

  const [visiblePromotionDetail, setVisiblePromotionDetail] = useState(false);
  const [idPromotionDetail, setIdPromotionDetail] = useState(undefined);
  const [dataEVoucher, setDataEVoucher] = useState([]);
  const [callback, setCallback] = useState(false);

  const [loading, setLoading] = useState(false);

  // page
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');
  const debouncedSearchTerm = useDebounce(search, 300);
  const [filterDate, setFilterDate] = useState({
    from_date: null,
    to_date: null,
  });
  const [filterDateStartEnd, setFilterDateStartEnd] = useState({
    from_date: null,
    to_date: null,
  });

  React.useEffect(() => {
    setLoading(true);
    voucherService
      .getVoucher({
        page,
        limit: RECORD_SIZE,
        search: debouncedSearchTerm,
        from_date: filterDate.from_date,
        to_date: filterDate.to_date,
        start_at: filterDateStartEnd.from_date,
        finish_at: filterDateStartEnd.to_date,
      })
      .then(res => {
        setDataEVoucher(res.data);
        setTotal(res?.paging?.count);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    page,
    debouncedSearchTerm,
    filterDate,
    callback,
    filterDateStartEnd,
    callbackReload,
    state?.callbackEvoucher,
  ]);

  const onChangePage = _page => {
    setPage(_page);
  };

  const columns = page => [
    {
      title: VietnameseString.ordinal_number,
      dataIndex: 'id',
      key: 'id',
      render: (row, record, index) =>
        page === 1 ? ++index : (page - 1) * RECORD_SIZE + ++index,
    },
    {
      title: VietnameseString.promotions_code,
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: VietnameseString.promotions_name,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: VietnameseString.used + '/' + VietnameseString.number_of_codes,
      dataIndex: 'address',
      key: 'address',
      render: (value, row) => {
        return (
          <Tag
            color={
              row?.e_voucher_is_used == row?.e_voucher_total
                ? 'success'
                : 'processing'
            }
          >
            {row?.e_voucher_is_used} / {row?.e_voucher_total}
          </Tag>
        );
      },
    },
    {
      title: VietnameseString.start_day,
      dataIndex: 'start_at',
      key: 'start_at',
      render: value => moment(value).format('DD/MM/YYYY'),
    },
    {
      title: VietnameseString.end_day,
      dataIndex: 'finish_at',
      key: 'finish_at',
      render: value => (value ? moment(value).format('DD/MM/YYYY') : '-'),
    },
    {
      title: VietnameseString.creater,
      dataIndex: 'created_name',
      key: 'created_name',
    },
    {
      title: VietnameseString.dateCreated,
      dataIndex: 'created_at',
      key: 'created_at',
      render: value => moment(value).format('DD/MM/YYYY'),
    },
    {
      title: VietnameseString.note,
      dataIndex: 'condition_other',
      key: 'condition_other',
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      key: 'action',
      with: '60px',
      render: (data, record, index) => {
        return (
          <WrapperContentActionStyled>
            <i
              className="icon-edit far fa-edit"
              onClick={event => {
                event.stopPropagation();
                history.push(ROUTER.EDIT_PROMOTIONS + '/' + record.id, {
                  ...record,
                });
              }}
            />

            <Popconfirm
              placement="bottomRight"
              title={VietnameseString.are_you_sure_you_want_to_delete}
              okText={VietnameseString.yes}
              cancelText={VietnameseString.no}
              onConfirm={event => {
                event.stopPropagation();

                voucherService
                  .deleteVoucher({
                    id: record?.id,
                  })
                  .then(() => {
                    if (dataEVoucher?.length < 2 && page > 1) {
                      setPage(prev => prev - 1);
                    } else {
                      setCallback(!callback);
                    }
                    notifySuccess('Xoá thành công chương trình ' + record.code);
                  })
                  .catch(err => {
                    notifyFail(err.msg);
                  });
              }}
              onCancel={event => {
                event.stopPropagation();
              }}
            >
              <i
                className="icon-trash far fa-trash-alt"
                onClick={event => {
                  event.stopPropagation();
                }}
              />
            </Popconfirm>
          </WrapperContentActionStyled>
        );
      },
    },
  ];

  return (
    <div>
      <Filter
        placeholderTextSearch={[
          VietnameseString.promotions_code,
          VietnameseString.promotions_name,
          VietnameseString.creater,
          VietnameseString.note,
        ].join(', ')}
        titleButton={VietnameseString.add_promotions}
        onChangeInputSearch={value => {
          setSearch(value);
          setPage(1);
        }}
        onChangeRangePicker={(_dates, _dateStrings) => {
          setPage(1);
          setFilterDate({
            from_date: _dateStrings[0],
            to_date: _dateStrings[1],
          });
        }}
        onChangeRangePickerStartEnd={(_dates, _dateStrings) => {
          setPage(1);
          setFilterDateStartEnd({
            from_date: _dateStrings[0],
            to_date: _dateStrings[1],
          });
        }}
        filterStartEndDate
        onClick={() => {
          history.push(ROUTER.ADD_PROMOTIONS);
        }}
      />

      <TableBorderedAntStyle
        loading={loading}
        dataSource={dataEVoucher}
        columns={columns(page)}
        onRow={(record, _rowIndex) => {
          return {
            onClick: _event => {
              setVisiblePromotionDetail(true);
              setIdPromotionDetail(record.id);
            },
          };
        }}
        pagination={false}
      />

      {total > RECORD_SIZE && (
        <Row justify="end" style={{ marginTop: '10px' }}>
          <PaginationComponent
            page={page || 1}
            total={total || 0}
            onChange={onChangePage}
          />
        </Row>
      )}

      {visiblePromotionDetail && idPromotionDetail && (
        <PromotionDetail
          visible={visiblePromotionDetail}
          id={idPromotionDetail}
          onCancel={() => {
            setVisiblePromotionDetail(false);
            setIdPromotionDetail(undefined);
          }}
        />
      )}
    </div>
  );
}

const PaginationComponent = React.memo(
  ({ page, total, pageSize = RECORD_SIZE, onChange }) => {
    return (
      <Pagination
        onChange={value => onChange(value)}
        current={Number(page)}
        total={total}
        showSizeChanger={false}
        pageSize={pageSize}
        showQuickJumper={total > 120}
      />
    );
  }
);

export default VoucherManagement;
