import React, { useState } from 'react';
import { Descriptions, Form, Image, Modal, Popconfirm, Switch } from 'antd';

import { VietnameseString } from '@constants/VietnameseString';
import Filter from '../Filter';

import { TableBorderedAntStyle } from '@styles/Style';
import { WrapperContentActionStyled } from '../../ElectronicVoucherStyled';
import AddEditGift from './AddEditGift';
import { voucherService } from '../../service';
import { formatPrice } from '@constants/funcHelper';
import moment from 'moment';
import { notifyFail, notifySuccess } from '@utils/notify';

const RECORD_SIZE = 12;

const initialGift = {
  id: undefined,
  name: undefined,
  price: undefined,
  remain: undefined,
  material: undefined,
  shaped: undefined,
  partner: undefined,
  description: undefined,
};

function GiftManagement() {
  const [visibleGiftDetail, setVisibleGiftDetail] = useState(false);
  const [giftDetail, setGiftDetail] = useState(null);

  const [callback, setCallback] = useState(false);

  const [total, setTotal] = useState(0);
  const [params, setParams] = useState({
    page: 1,
  });

  const [gifts, setGifts] = useState([]);

  const [visibleAddEditGift, setVisibleAddEditGift] = useState(false);

  const columns = [
    {
      title: VietnameseString.ordinal_number,
      dataIndex: 'id',
      key: 'id',
      render: (row, record, index) =>
        params?.page === 1 ? ++index : (params?.page - 1) * 12 + ++index,
    },
    {
      title: 'Tên quà tặng',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Giá trị',
      dataIndex: 'price',
      key: 'price',
      render: value => formatPrice(value),
    },
    {
      title: 'Số lượng hiện có',
      dataIndex: 'remain',
      key: 'remain',
    },
    {
      title: 'Ngày thêm',
      dataIndex: 'created_at',
      key: 'created_at',
      render: value => moment(value).format('DD/MM/YYYY'),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (value, row) => (
        <div
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Switch
            checked={!!value}
            onChange={e => {
              voucherService
                .updateGiftVoucher(row?.id, {
                  ...row,
                  status: value ? 0 : 1,
                })
                .then(res => {
                  if (res?.status) {
                    notifySuccess('Cập nhật thành công trạng thái');
                    setCallback(!callback);
                  }
                })
                .catch(err => {
                  if (err?.msg) {
                    notifyFail(err?.msg);
                  }
                });
            }}
          />
        </div>
      ),
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      key: 'action',
      render: (data, record, index) => {
        return (
          <WrapperContentActionStyled>
            <i
              className="icon-edit far fa-edit"
              onClick={event => {
                event.stopPropagation();

                setGiftDetail(record);

                setVisibleAddEditGift(true);
              }}
            />

            <Popconfirm
              placement="bottomRight"
              title={VietnameseString.are_you_sure_you_want_to_delete}
              okText={VietnameseString.yes}
              cancelText={VietnameseString.no}
              onConfirm={event => {
                event.stopPropagation();
                voucherService
                  .deleteGiftVoucher({ id: record?.id })
                  .then(res => {
                    if (res.status) {
                      notifySuccess('Xoá thành công quà tặng ' + record?.name);
                      setCallback(!callback);
                    }
                  })
                  .catch(err => {
                    if (err?.msg) {
                      notifyFail(err?.msg);
                    }
                  });
              }}
              onCancel={event => {
                event.stopPropagation();
              }}
            >
              <i
                className="icon-trash far fa-trash-alt"
                onClick={event => {
                  event.stopPropagation();
                }}
              />
            </Popconfirm>
          </WrapperContentActionStyled>
        );
      },
    },
  ];

  React.useEffect(() => {
    voucherService.getGiftVoucher(params).then(res => {
      if (res.status) {
        setGifts(res.data);
        setTotal(res?.paging?.count);
      }
    });
  }, [params, callback]);

  return (
    <div>
      <Filter
        placeholderTextSearch={'Tên quà tặng'}
        titleButton="Thêm quà tặng"
        onChangeInputSearch={value => {
          setParams({ ...params, search: value });
        }}
        onChangeRangePicker={(dates, dateStrings) => {
          setParams({
            ...params,
            from_date: dates?.[0]
              ? moment(dates[0]).format('YYYY-MM-DD')
              : null,
            to_date: dates?.[1] ? moment(dates[1]).format('YYYY-MM-DD') : null,
          });
        }}
        onClick={() => {
          setVisibleAddEditGift(true);
        }}
      />

      <TableBorderedAntStyle
        dataSource={gifts}
        columns={columns}
        pagination={
          total > RECORD_SIZE
            ? {
                total,
                pageSize: RECORD_SIZE,
                onChange: _page => {
                  setParams({
                    ...params,
                    page: _page,
                  });
                },
              }
            : false
        }
        onRow={(record, _rowIndex) => {
          return {
            onClick: _event => {
              setGiftDetail(record);

              setVisibleGiftDetail(true);
            },
          };
        }}
      />

      {visibleGiftDetail && (
        <Modal
          title="Thông tin chi tiết"
          footer={null}
          visible={visibleGiftDetail}
          onCancel={() => {
            setVisibleGiftDetail(false);
            setGiftDetail(null);
          }}
        >
          <Descriptions bordered size="small" column={1}>
            <Descriptions.Item label="Tên quà tặng">
              <strong>{giftDetail.name}</strong>
            </Descriptions.Item>
            <Descriptions.Item label="Số lượng hiện có">
              <strong>{giftDetail.remain}</strong>
            </Descriptions.Item>
            <Descriptions.Item label="Hình ảnh">
              <div>
                <Image
                  src={giftDetail?.e_voucher_image}
                  alt="img"
                  width={50}
                  height={50}
                />
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="Giá trị">
              <strong>{formatPrice(giftDetail.price)}</strong>
            </Descriptions.Item>
            <Descriptions.Item label="Chất liệu">
              <strong>{giftDetail.material}</strong>
            </Descriptions.Item>
            <Descriptions.Item label="Hình dáng">
              <strong>{giftDetail.shaped}</strong>
            </Descriptions.Item>
            <Descriptions.Item label="Đối tác sản xuất">
              <strong>{giftDetail.partner}</strong>
            </Descriptions.Item>
            <Descriptions.Item label="Mô tả">
              <strong>{giftDetail.description}</strong>
            </Descriptions.Item>
          </Descriptions>
        </Modal>
      )}

      {visibleAddEditGift && (
        <AddEditGift
          callback={callback}
          setCallback={setCallback}
          visible={visibleAddEditGift}
          onCancel={() => {
            setVisibleAddEditGift(false);
            setGiftDetail(null);
          }}
          giftDetail={giftDetail}
        />
      )}
    </div>
  );
}

export default GiftManagement;
