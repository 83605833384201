import Axios from 'axios';
import { useRef } from 'react';

export const CODE_EZ_EXCEPTION_API_CANCEL = 'EZExceptionApiCancel';

export function useCancellableApi(api) {
  const sourceRef = useRef();

  return async (payload, options = {}) => {
    if (sourceRef.current) {
      sourceRef.current.cancel(CODE_EZ_EXCEPTION_API_CANCEL);
    }
    sourceRef.current = Axios.CancelToken.source();
    return api(payload, {
      ...options,
      cancelToken: sourceRef.current.token,
    });
  };
}
