import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '@styles/RegisterRequest.css';
import { Row, Col, FormControl, Button, Modal } from 'react-bootstrap';
import { TYPE_MEDIA, SALE_STATUS, USER_ROLE, SALE_STATUS_ALIAS, STRING, ROUTER } from '@constants/Constant';
import { toDateString } from '../../../Utils/ToDateString';
import Loading from '@src/components/Loading';
import reactotron from 'reactotron-react-js';
import { requestSaleDetail, requestUpdateSaleStatus } from '@constants/Api';
import { getItemFromId } from '@utils/getItemFromId';
import ReactPlayer from 'react-player';
import { notifyFail, notifySuccess } from '@utils/notify';
import { connect } from 'react-redux';
import { Avatar, Image } from 'antd';
import Table from 'react-bootstrap/Table';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: '3',
      isLoading: false,
      searchBar: {
        searchKey: '',
        status: '0',
        work: '0',
      },
      detail: {},
      assessment: {
        review_voice: '',
        review_fluent: '',
        review_comfident: '',
        review_other: '',
        reason: '',
        status: '',
      },
      hasRejectModal: false,
    };
  }

  componentDidMount() {
    this.getData();
    this.getUserRole();
  }

  getUserRole() {
    this.setState({
      role: this.props.userState.data.role_id,
    });
  }

  async getData() {
    this.setState({ isLoading: true });
    const { id } = this.props.match.params;
    let profile = '',
      front_id = '',
      back_id = '',
      video = '';
    try {
      const res = await requestSaleDetail({ id: id });
      if (res.data.user_sale_media) {
        res.data.user_sale_media.forEach((media) => {
          if (media.type === TYPE_MEDIA.PROFILE_IMAGE) profile = media.url;
          if (media.type === TYPE_MEDIA.BACK_IMAGE) back_id = media.url;
          if (media.type === TYPE_MEDIA.FRONT_IMAGE) front_id = media.url;
          if (media.type === TYPE_MEDIA.VIDEO) video = media.url;
        });
      }
      this.setState({
        detail: res.data,
        isLoading: false,
        front_id: front_id,
        back_id: back_id,
        profile: profile,
        video: video,
      });
      reactotron.log('state', this.state);
    } catch (err) {
      notifyFail(err.msg);
      this.setState({ isLoading: false });
    }
  }

  renderBody() {
    const { role } = this.state;
    return (
      <div className="content-wrapper">
        <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
          <Col md className="bg" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
            {this.renderInfo()}
            {this.renderTable()}
            {this.renderLayout(role)}
          </Col>
        </Row>
        {/* Content Header (Page header) */}
        {/* {this.renderInfo()}

        {this.renderLayout(role)} */}
      </div>
    );
  }

  renderTable() {
    const sale = this.state.detail;
    const saleStatus = getItemFromId(SALE_STATUS, sale.user_sale_info?.user_sale_status_id);
    return (
      <>
        <Table bordered id="infor" style={{ display: 'none' }}>
          <tbody>
            <tr>
              <td style={{ fontWeight: 'bold', color: '#2196f3' }}>THÔNG TIN TELESALE</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Họ và tên</td>
              <td>{sale?.full_name}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Số điện thoại</td>
              <td>{sale?.phone}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{sale?.email}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Ngày sinh</td>
              <td>{sale?.user_sale_info?.dob && toDateString(sale.user_sale_info.dob)}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Tỉnh/Thành phố</td>
              <td>{sale?.user_sale_info && sale.user_sale_info.province?.name}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Thẻ CCCD</td>
              <td>{sale?.user_sale_info && sale.user_sale_info?.id_number}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Thu nhập mong muốn</td>
              <td>{sale.user_sale_info?.expected_salary ? sale.user_sale_info?.expected_salary : '--'}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Kinh nghiệm</td>
              <td>{sale?.user_sale_info && sale.user_sale_info?.work_experience}</td>
              <td></td>
              <td></td>
            </tr>
            <tr></tr>
            <tr>
              <td style={{ fontWeight: 'bold', color: '#2196f3' }}>KẾT QUẢ DUYỆT</td>
              <td
                className={
                  sale?.user_sale_info && sale.user_sale_info?.user_sale_status_id == 1 ? 'waiting' : 'text-blue'
                }
              >
                {saleStatus && saleStatus?.name}
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr></tr>
            <tr>
              <td style={{ fontWeight: 'bold', color: '#2196f3' }}>KẾT QUẢ CHI TIẾT</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td style={{ backgroundColor: '#1976d2' }}>Chức vụ</td>
              <td style={{ backgroundColor: '#1976d2' }}>Người duyệt</td>
              <td style={{ backgroundColor: '#1976d2' }}>Trạng thái</td>
              <td style={{ backgroundColor: '#1976d2' }}>Nội dung</td>
            </tr>
            {sale?.user_sale_status_histories?.length &&
              sale?.user_sale_status_histories?.map((value) => (
                <tr>
                  <td>
                    {value?.user?.role_id == 1
                      ? 'Admin'
                      : value?.user?.role_id == 3
                      ? 'Trưởng phòng chuyên môn'
                      : 'Trưởng phòng nhân sự'}
                  </td>
                  <td>{value?.user?.full_name}</td>
                  <td>Đã phê duyệt</td>
                  {value?.user?.role_id != 1 && (
                    <td>
                      <div>Chất giọng: {value?.review_voice}</div>
                      <div>Trôi chảy: {value?.review_fluent}</div>
                      <div>Sự tự tin: {value?.review_comfident}</div>
                      <div>Khác: {value?.review_other}</div>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </Table>
      </>
    );
  }

  renderLayout(role) {
    const saleStatus = this.state.detail?.user_sale_info?.user_sale_status_id;
    switch (role) {
      case USER_ROLE.ADMIN: // screen cho admin duyet
        return this.renderAdminLayout(saleStatus);
      case USER_ROLE.PROFESSION_MANAGER: // screen cho TP chuyen mon duyet
        return this.renderProManagerLayout(saleStatus);
      case USER_ROLE.HR_MANAGER: // screen cho TP nhan su duyet
        return this.renderHRLayout(saleStatus);
      case USER_ROLE.ENTERPRISE: // screen cho enterprise duyet
        return this.renderAdminLayout(saleStatus);
    }
  }

  renderAdminLayout(saleStatus) {
    const histories = this.state.detail?.user_sale_status_histories;
    if (
      [
        SALE_STATUS_ALIAS.ADMIN_ACCEPT,
        SALE_STATUS_ALIAS.PROFESSION_MANAGER_ACCEPT,
        SALE_STATUS_ALIAS.HR_MANAGER_ACCEPT,
      ].includes(saleStatus)
    ) {
      return (
        <>
          {histories &&
            histories?.map((history) => {
              if (history.df_sale_status_id == SALE_STATUS_ALIAS.PROFESSION_MANAGER_ACCEPT)
                return this.renderResult('Trưởng phòng chuyên môn', history);
            })}
        </>
      );
    } else if ([SALE_STATUS_ALIAS.WAITING].includes(saleStatus)) {
      return (
        <>
          {/* {this.renderReason()} */}
          {this.renderModalRejectTelesale()}
          {this.renderSubmitButton()}
        </>
      );
    }
  }

  renderProManagerLayout(saleStatus) {
    const histories = this.state.detail?.user_sale_status_histories;
    if ([SALE_STATUS_ALIAS.PROFESSION_MANAGER_ACCEPT].includes(saleStatus)) {
      return (
        <>
          {histories &&
            histories?.map((history) => {
              if (history.df_sale_status_id == SALE_STATUS_ALIAS.PROFESSION_MANAGER_ACCEPT)
                return this.renderResult('Trưởng phòng chuyên môn', history);
            })}
        </>
      );
    } else if ([SALE_STATUS_ALIAS.ADMIN_ACCEPT].includes(saleStatus)) {
      return (
        <>
          {this.renderAssessment('TP chuyên môn')}
          {this.renderModalRejectTelesale()}
          {/* {this.renderReason()} */}
          {this.renderSubmitButton(0)}
        </>
      );
    }
  }

  renderHRLayout(saleStatus) {
    const histories = this.state.detail?.user_sale_status_histories;
    if ([SALE_STATUS_ALIAS.PROFESSION_MANAGER_ACCEPT].includes(saleStatus)) {
      return (
        <>
          {histories &&
            histories?.map((history) => {
              if (history.df_sale_status_id == SALE_STATUS_ALIAS.PROFESSION_MANAGER_ACCEPT)
                return this.renderResult('Trưởng phòng chuyên môn', history);
            })}
          {this.renderAssessment('TP nhân sự')}
          {this.renderModalRejectTelesale()}
          {/* {this.renderReason()} */}
          {this.renderSubmitButton(0)}
        </>
      );
    } else {
      return (
        <>
          {histories &&
            histories?.map((history) => {
              if (history.df_sale_status_id == SALE_STATUS_ALIAS.PROFESSION_MANAGER_ACCEPT)
                return this.renderResult('Trưởng phòng chuyên môn', history);
            })}
        </>
      );
    }
  }

  renderHeaderButton() {
    const { id } = this.props.match.params;
    const sale = this.state.detail;
    return (
      <Row className="justify-content-center justify-content-md-end">
        <Col className="button-wrapper">
          <Button
            variant="info"
            className="text-light"
            onClick={() => this.props.history.push(ROUTER.TELESALE_EDIT + '/' + id)}
          >
            <div className="text-light">Sửa</div>
          </Button>
          {/* <Button variant="warning" className="text-light"> */}
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button btn-warning"
            table="infor"
            filename={`bang-tinh-${sale?.full_name}`}
            sheet="tablexls"
            buttonText="Xuất kết quả"
          ></ReactHTMLTableToExcel>
        </Col>
      </Row>
    );
  }

  renderSubmitButton(shouldDisplayButton = 1) {
    return (
      <Row className="justify-content-center mt-3">
        {shouldDisplayButton ? (
          <Button variant="success" className="text-light" onClick={() => this.submitAssessment(1, 1)}>
            Duyệt nhanh
          </Button>
        ) : (
          <></>
        )}
        <Button variant="success" className="text-light" onClick={() => this.submitAssessment(1)}>
          Duyệt
        </Button>
        {/* <Button variant="danger" className="text-light" onClick={() => this.submitAssessment(0)}> */}
        <Button variant="danger" className="text-light" onClick={() => this.setShowModal('hasRejectModal', true)}>
          Từ chối
        </Button>
      </Row>
    );
  }

  renderModalRejectTelesale() {
    const { hasRejectModal } = this.state;
    return (
      <Modal
        show={hasRejectModal}
        onHide={() => {
          this.setShowModal('hasRejectModal', false);
        }}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton className="bg-danger pb-0">
          <h5 className="text-light pb-0">Nhập lý do từ chối</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          <Row className="title">
            <Col md={12} className="">
              <textarea
                className="form-control"
                rows="6"
                placeholder="Ghi chú"
                onChange={(e) => this.handleInputChange('reason', e.target.value)}
              >
                {this.state.assessment.reason}
              </textarea>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="success" className="text-light" onClick={() => this.submitAssessment(0)}>
            Xác nhận
          </Button>
          <Button
            variant="danger"
            className="text-light"
            onClick={() => {
              this.setShowModal('hasRejectModal', false);
            }}
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  setShowModal = (field, value) => {
    this.setState({
      ...this.state,
      [field]: value,
    });
  };

  async submitAssessment(isAccept, isThrow = 0) {
    const payload = {
      ...this.state.assessment,
      id: this.state.detail.user_id,
      status: isAccept,
      is_throw: isThrow,
    };
    const check = this.validateAssessment(payload);
    if (check) {
      try {
        this.setState({ isLoading: true });
        const res = await requestUpdateSaleStatus(payload);
        this.setState({ isLoading: false });
        notifySuccess((isAccept ? 'Phê duyệt' : 'Từ chối') + ' thành công');
        window.location.reload(false);
      } catch (err) {
        this.setState({ isLoading: false });
        notifyFail(err.msg);
      }
    } else {
      return;
    }
  }

  validateAssessment(payload) {
    if (!payload.id) {
      console.log('missing user_id');
    }
    if (payload.status) {
      if (payload.reason.length > 0) {
        notifyFail('Trường hợp phê duyệt, lý do từ chối buộc phải bỏ trống');
        return false;
      }
      if ([USER_ROLE.PROFESSION_MANAGER, USER_ROLE.HR_MANAGER].includes(this.state.role)) {
        if (!this.validateField(payload)) {
          return false;
        }
      }
      return true;
    }
    if (!payload.status) {
      if (payload.reason.trim().length === 0) {
        notifyFail('Nhập ghi chú');
        return false;
      }
      if ([USER_ROLE.PROFESSION_MANAGER, USER_ROLE.HR_MANAGER].includes(this.state.role)) {
        if (!this.validateField(payload)) {
          return false;
        }
      }
      return true;
    }
    console.log('missing status');
    return false;
  }

  validateField(payload) {
    if (!payload.review_voice) {
      notifyFail('Đánh giá giọng nói không được bỏ trống');
      return false;
    }
    if (!payload.review_comfident) {
      notifyFail('Đánh giá tự tin không được bỏ trống');
      return false;
    }
    if (!payload.review_fluent) {
      notifyFail('Đánh giá độ trôi chảy không được bỏ trống');
      return false;
    }
    return true;
  }

  renderInfo() {
    const sale = this.state.detail;
    const { profile, front_id, back_id, video } = this.state;
    const saleStatus = getItemFromId(SALE_STATUS, sale.user_sale_info?.user_sale_status_id);
    return (
      <div className="content-header personal-info">
        <Row className="title ml-md-3">
          <Avatar src={sale?.user_sale_info?.profile_image} size={50} className="bg-blue text-white">
            {sale?.user_sale_info?.profile_image !== '' ? null : sale?.full_name[0].toUpperCase()}
          </Avatar>
          <Col md={7} className="name">
            <h3> {sale && sale.full_name} </h3>
          </Col>
          <Col md={4}>{this.renderHeaderButton()}</Col>
        </Row>
        <br />
        <Row className="info">
          <Col md={4} className="mx-md-4 mx-0 mb-3 mb-md-0">
            {video ? (
              <ReactPlayer url={video} className="w-100" controls={true} width={640} height={200} />
            ) : (
              <img src={require('@assets/novideo.png')} style={{ width: '335px', height: 'auto' }}></img>
            )}
          </Col>
          <Col md={7}>
            <Row>
              <Col>
                <label>{`${STRING.phoneNumber}:`}</label>
              </Col>
              <Col>
                <span>{sale && sale.phone}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>{`${STRING.email}:`}</label>
              </Col>
              <Col>
                <span>{sale && sale.email}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>{`${STRING.dob}:`}</label>
              </Col>
              <Col>
                <span>{sale?.user_sale_info?.dob && toDateString(sale.user_sale_info.dob)}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>{`${STRING.gender}:`}</label>
              </Col>
              <Col>
                <span>{sale?.user_sale_info && sale.user_sale_info.gender == 2 ? 'Nam' : 'Nữ'}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>{`${STRING.city}:`}</label>
              </Col>
              <Col>
                <span>{sale?.user_sale_info && sale.user_sale_info.province?.name}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                {/* <label>{`${STRING.identicalId}:`}</label> */}
                <label>Thẻ CCCD:</label>
              </Col>
              <Col>
                <span>{sale?.user_sale_info && sale.user_sale_info?.id_number}</span>
              </Col>
            </Row>
            <Row>
              <Col></Col>
              <Col md={6} className="p-0 mb-3 ">
                <Image
                  width={150}
                  height={85}
                  src={(front_id && front_id) || require('../../../assets/no-image.png')}
                  style={{
                    cursor: 'pointer',

                    objectFit: 'contain',
                  }}
                />
                <Image
                  width={150}
                  height={85}
                  src={(back_id && back_id) || require('../../../assets/no-image.png')}
                  style={{ cursor: 'pointer', objectFit: 'contain', marginLeft: 10 }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>{`${STRING.income}:`}</label>
              </Col>
              <Col>
                <span>
                  {/* {sale?.user_sale_info?.expected_salary
                    ? Number(sale.user_sale_info?.expected_salary)?.toLocaleString('vi', {
                        currency: 'VND',
                      }) + ' VNĐ'
                    : "--"} */}
                  {sale?.user_sale_info?.expected_salary || '--'} VNĐ
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>{`${STRING.experience}:`}</label>
              </Col>
              <Col>
                <span>{sale?.user_sale_info && sale.user_sale_info?.work_experience}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>{`${STRING.profile}:`}</label>
              </Col>
              <Col>
                {sale?.user_sale_info && sale.user_sale_info?.cv_url ? (
                  <a
                    href={sale?.user_sale_info && sale.user_sale_info?.cv_url}
                    className="link-infor"
                    style={{ color: 'red' }}
                    target="_blank"
                  >
                    Click để xem
                  </a>
                ) : (
                  '--'
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <label>{`${STRING.status}:`}</label>
              </Col>
              <Col>
                <span
                  className={
                    sale?.user_sale_info && sale.user_sale_info?.user_sale_status_id == 1 ? 'waiting' : 'text-blue'
                  }
                >
                  {saleStatus && saleStatus?.name}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row></Row>
      </div>
    );
  }

  renderAssessment(role) {
    const { assessment } = this.state;
    return (
      <div className="content-header assessment">
        <Row className="title">
          <Col md={9} className="name">
            <h5> Đánh giá ứng viên - {role} </h5>
          </Col>
        </Row>
        <Row className="content">
          <Col md={6}>
            <Row>
              <Col md={3}>
                <label>Chất giọng</label>
              </Col>
              <Col md={8}>
                <FormControl
                  type="text"
                  placeholder="Nhập đánh giá chất giọng"
                  value={assessment.review_voice}
                  onChange={(e) => this.handleInputChange('review_voice', e.target.value)}
                ></FormControl>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={3}>
                <label>Sự tự tin</label>
              </Col>
              <Col md={8}>
                <FormControl
                  type="text"
                  placeholder="Nhập đánh giá sự tự tin"
                  value={assessment.review_comfident}
                  onChange={(e) => this.handleInputChange('review_comfident', e.target.value)}
                ></FormControl>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col md={3}>
                <label>Trôi chảy</label>
              </Col>
              <Col md={8}>
                <FormControl
                  type="text"
                  placeholder="Nhập đánh giá mức độ trôi chảy"
                  value={assessment.review_fluent}
                  onChange={(e) => this.handleInputChange('review_fluent', e.target.value)}
                ></FormControl>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={3}>
                <label>Khác</label>
              </Col>
              <Col md={8}>
                <FormControl
                  type="text"
                  placeholder="Nhập đánh giá khác"
                  value={assessment.review_other}
                  onChange={(e) => this.handleInputChange('review_other', e.target.value)}
                ></FormControl>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }

  renderReason() {
    return (
      <Row className="title pb-3">
        <Col md={12} className="">
          <textarea
            className="form-control"
            rows="6"
            placeholder="Ghi chú"
            onChange={(e) => this.handleInputChange('reason', e.target.value)}
          >
            {this.state.assessment.reason}
          </textarea>
        </Col>
      </Row>
    );
  }

  handleInputChange(field, value) {
    this.setState({
      assessment: {
        ...this.state.assessment,
        [field]: value,
      },
    });
  }

  renderResult(role, assessment) {
    return (
      <div className={'content-header assessment'}>
        <Row className="title">
          <Col md={9} className="name">
            <h5> Đánh giá ứng viên - {role} </h5>
          </Col>
        </Row>
        <Row className="content">
          <Col md={6}>
            <Row>
              <Col md={3}>
                <label>Chất giọng</label>
              </Col>
              <Col md={8}>
                <div className="alert alert-secondary h-50">{(assessment && assessment.review_voice) || '###'}</div>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <label>Sự tự tin</label>
              </Col>
              <Col md={8}>
                <div className="alert alert-secondary h-50">{(assessment && assessment.review_comfident) || '###'}</div>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col md={3}>
                <label>Trôi chảy</label>
              </Col>
              <Col md={8}>
                <div className="alert alert-secondary h-50">{(assessment && assessment.review_fluent) || '###'}</div>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <label>Khác</label>
              </Col>
              <Col md={8}>
                <div className="alert alert-secondary h-50">{(assessment && assessment.review_other) || '###'}</div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    return (
      <>
        {this.state.isLoading ? <Loading /> : null}
        {this.renderBody()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userState: state.userReducer,
});

export default connect(mapStateToProps, null)(withRouter(Detail));
