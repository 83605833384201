import TagCustom from '@screens/Enterprise/components/TagCustom';
import { Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { convertColor } from './func';
import { formatPrice } from '@constants/funcHelper';
const ColorDot = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${props => {
    return props.color;
  }};
  display: inline-block;
  position: relative;
  top: -3px;
  margin-right: 5px;
`;
const TitleCol = styled.strong``;
export function createColumnTableDynamic(data, pretext) {
  if (!Array.isArray(data) || data.length < 1) {
    return [];
  } else {
    return data.map((item, index) => {
      return {
        title: (
          <Tooltip
            title={`${item.name}${pretext === 'product' ? ' (VNĐ)' : ''}`}
          >
            <ColorDot color={item.color} />
            <TitleCol>{item.name}</TitleCol>
          </Tooltip>
        ),
        dataIndex: `${pretext}${item.id}`,
        key: item.id,
        render: (value, record) => {
          let dataCell = value ? value : ``;
          return (
            <b
              style={{
                color: convertColor(...(dataCell.split('/') || [0, 0])),
              }}
            >
              {formatPrice(dataCell)}
            </b>
          );
        },
        width: 110,
        ellipsize: true,

        // ellipsize: false,
        // ellipsis: true,
      };
    });
  }
}
