/**
 * @callback MakeCall
 * @param {string} destination
 * @param {string} callId
 */

/**
 * @typedef {object} JsSIPCallClient
 * @property {MakeCall} makeCall
 * @property {HangupCall} hangupCall
 * @property {SendDTMF} sendDtmf
 */

/**
 * @class SIPUtil
 * @property jsSIPCallClient {JsSIPCallClient}
 */

class SIPUtil {
  /** @type {JsSIPCallClient} */
  jsSIPCallClient = null;
  // jsSIPCall = null;
  // jsSIPClient = null;
}

const JsSIPUtil = new SIPUtil();
export default JsSIPUtil;
