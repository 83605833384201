import { requestCreateEducation } from '@constants/Api';
import { ACCEPT_TYPE, NULL_ITEM, ROUTER, STRING } from '@constants/Constant';
import { getJobDetail } from '@src/redux/actions';
import '@styles/RegisterRequest.css';
import { ButtonSystemStyle, TitleStyle } from '@styles/Style';
import { createFormData } from '@utils/createFormData';
import { notifyFail, notifySuccess } from '@utils/notify';
import { Col as ColAntd, Row as RowAntd } from 'antd';
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { requestGetJobDetail } from 'src/network/web_sale/JobApi';

function FormError(props) {
  if (props.isHidden) {
    return null;
  }
  return <div style={{ color: 'red', width: '100%', textAlign: 'center' }}>{props.errorMessage}</div>;
}

class Education extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        job_id: '',
        edu_infor: '',
        edu_video_url: '',
      },
      isLoading: false,
      edu_url: '',
    };
    //callback lưu bước 4
    this.saveStep = this.createEducation.bind(this);
  }

  componentDidMount() {
    // this.getData();
    if (this.props.jobDetailState.data.job_id) {
      (async () => {
        const res = await requestGetJobDetail({ id: this.props.jobDetailState.data.job_id });
        this.setState({
          ...this.state,
          form: {
            ...this.state.form,
            job_id: res.data.job_id,
            edu_infor: res.data.edu_infor ? res.data.edu_infor : '',
            edu_video_url: res.data.edu_video_url,
          },
          edu_url: res.data.edu_url,
        });
      })();
    }
    //callback lưu bước 4
    this.props.onSaveStep(this.saveStep);
  }

  componentWillUnmount() {}

  modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      [{ align: [] }],
      ['link', 'image', 'video'],
      ['clean'],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
    ],
  };

  getData(payload) {
    this.props.getJobDetail();
  }

  renderHeader() {
    return (
      // <Row className="py-3">
      //   <Col sm={12} className="px-0">
      //     <h4>Đào tạo</h4>
      //   </Col>
      //   {this.props.shouldValidate && this.checkValidation()}
      // </Row>
      <RowAntd justify="space-between">
        <ColAntd>
          <TitleStyle>{`${STRING.educate}`}</TitleStyle>
        </ColAntd>
        <ColAntd>
          {/* <ButtonPrimaryStyle type="primary" htmlType="button" onClick={() => this.onFinish('save')}>
            <DownloadOutlined style={{ marginRight: '10px' }} />
            Lưu
          </ButtonPrimaryStyle> */}
        </ColAntd>
      </RowAntd>
    );
  }

  validateFormAdd() {
    const { edu_url } = this.state;
    // if (form.edu_infor) {
    //   return true;
    // }
    // if (!checkAllAttrFilled(form)) {
    //   return false;
    // }
    // if (this.props.shouldValidate) {
    if (NULL_ITEM.exec(edu_url) === null) {
      return false;
    }
    return true;
  }

  onFinish = (type) => {
    // ModalAntd.confirm({
    //   title: 'Xác nhận',
    //   icon: <ExclamationCircleOutlined />,
    //   content: 'Bạn có muốn lưu thông tin trước khi chuyển sang nội dung khác?',
    //   okText: 'Lưu',
    //   cancelText: 'Hủy',
    // onOk: () =>
    this.createEducation(type);
    // });
  };

  setLoading(value) {
    this.props.setLoading(value);
  }

  async createEducation(type) {
    const job = this.props.jobDetailState.data;
    if (job.job_id !== undefined) {
      this.setLoading(true);
      const formData = createFormData({ ...this.state.form, job_id: job.job_id });
      this.setState({ isLoading: true });
      requestCreateEducation(formData)
        .then((res) => {
          this.setState({ isLoading: false });
          this.props.getJobDetail({ id: res.data.job_id });
          this.setLoading(false);
          const stepStatus = this.props.stepStatus;
          if (type === 'save') {
            notifySuccess('Thêm công việc thành công');
            this.props.history.push(ROUTER.JOB);
          }
          if (type === 'prev') {
            stepStatus[3] = 'finish'; //hoàn thành bước 4
            stepStatus[2] = 'process'; //chuyển trạng thái bước 3
            this.props.setStep(parseInt(this.props.currentStep) - 1, stepStatus);
          }
          if (type === 'next') {
            stepStatus[3] = 'finish'; //hoàn thành bước 4
            stepStatus[4] = 'process'; //chuyển trạng thái bước 5
            this.props.setStep(this.props.currentStep + 1, stepStatus);
          }
          if (type.includes('changeStep')) {
            let step = type.split(':');
            stepStatus[3] = 'finish'; //hoàn thành bước 4
            stepStatus[parseInt(step[1])] = 'process'; // chuyển trạng thái bước được chọn trên menu step
            this.props.setStep(parseInt(step[1]), stepStatus);
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          notifyFail(err.msg);
          this.setLoading(false);
        });
      return;
    } else {
      const stepStatus = this.props.stepStatus;
      if (type === 'save') {
        notifySuccess('Thêm công việc thành công');
        this.props.history.push(ROUTER.JOB);
      }
      if (type === 'prev') {
        stepStatus[3] = 'finish'; //hoàn thành bước 4
        stepStatus[2] = 'process'; //chuyển trạng thái bước 3
        this.props.setStep(parseInt(this.props.currentStep) - 1, stepStatus);
      }
      if (type === 'next') {
        stepStatus[3] = 'finish'; //hoàn thành bước 4
        stepStatus[4] = 'process'; //chuyển trạng thái bước 5
        this.props.setStep(this.props.currentStep + 1, stepStatus);
      }
      if (type.includes('changeStep')) {
        let step = type.split(':');
        stepStatus[3] = 'finish'; //hoàn thành bước 4
        stepStatus[parseInt(step[1])] = 'process'; // chuyển trạng thái bước được chọn trên menu step
        this.props.setStep(parseInt(step[1]), stepStatus);
      }
    }
  }

  checkValidation = () => {
    const { edu_url } = this.state;
    const job = this.props.jobDetailState.data;

    if (job.job_id === undefined) {
      return <FormError errorMessage={' Vui lòng nhập thông tin chung!'} />;
    }
    if (NULL_ITEM.exec(edu_url) === null) {
      return <FormError errorMessage={'Vui lòng thêm file đính kèm!'} />;
    }
    // if (form?.edu_infor === '') {
    //   return <FormError errorMessage={'Vui lòng điền thông tin đào tạo!'} />;
    // }
    // if (form?.edu_video_url === "") {
    //   return <FormError errorMessage={"Vui lòng thêm link video hướng dẫn!"} />;
    // }
    else {
      return true;
    }
  };

  handleChangeFrom = (value) => {
    const job = this.props.jobDetailState.data;
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        edu_infor: value === '<p><br></p>' ? '' : value,
        job_id: job.job_id,
      },
    });
  };

  renderTraining() {
    const { edu_url } = this.state;
    return (
      <>
        <Row>
          <Col sm={12} className="p-0">
            <label htmlFor="uploader" className="text-red uploader-label" style={{ fontSize: '18px' }}>
              <i className="fas fa-paperclip mr-2"></i>
              {this.state.edu_url ? this.state.edu_url : 'File đính kèm: docx, xlsx, pdf, mp3, mp4 (tối đa 500MB)'}
            </label>
            <input
              type="file"
              id="uploader"
              className="d-none"
              // accept=".doc, .docx,.pptx,.pdf, .xlsx, .mp3, .mp4"
              ref={edu_url}
              onChange={(e) => this.handleUploader('edu_url', e)}
            ></input>
          </Col>
          {/* <Col sm={12} className="p-0">
            <span>Video hướng dẫn</span>
            <Form.Control
              as="textarea"
              value={form.edu_video_url}
              onChange={(e) =>
                this.handleInputChange("edu_video_url", e.target.value)
              }
            />
          </Col> */}
          <Col sm={12} className="p-0 mt-3 mb-3">
            <span>Thông tin đào tạo</span>
            <ReactQuill value={this.state.form.edu_infor} modules={this.modules} onChange={this.handleChangeFrom} />
          </Col>
        </Row>
      </>
    );
  }

  handleUploader(type, event) {
    const job = this.props.jobDetailState.data;
    if (event.target.files[0] && !ACCEPT_TYPE.DOCUMENT.includes(event.target.files[0].type)) {
      alert('Định dạng  không được hỗ trợ. Vui lòng chọn file khác.');
      return;
    }

    let sizeFile = parseInt(event.target.files[0].size) / 1024 / 1024;
    if (sizeFile > 500) {
      notifyFail('Dung lượng file lớn hơn giới hạn 500MB');
      return;
    }

    this.setState({
      form: {
        ...this.state.form,
        [type]: event?.target?.files[0],
        job_id: job.job_id,
      },
      [type]: (window.URL || window.webkitURL).createObjectURL(event?.target?.files[0]),
      edu_url: event?.target?.files[0].name,
    });
  }

  // handleInputChange = (field, value) => {
  //   const job = this.props.jobDetailState.data
  //   this.setState({
  //     ...this.state,
  //     form: {
  //       ...this.state.form,
  //       [field]: value,
  //       job_id: job.job_id,
  //     },
  //   });
  // };

  renderButton() {
    return (
      <RowAntd justify="space-between">
        <ColAntd>
          <ButtonSystemStyle htmlType="button" onClick={() => this.onFinish('prev')}>
            Quay lại
          </ButtonSystemStyle>
        </ColAntd>

        <ColAntd>
          <ButtonSystemStyle htmlType="button" onClick={() => this.onFinish('next')}>
            Tiếp tục
          </ButtonSystemStyle>
        </ColAntd>
      </RowAntd>
    );
  }

  render() {
    return (
      <>
        {this.renderHeader()}
        {this.renderTraining()}
        {this.renderButton()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  jobDetailState: state.jobDetailReducer,
});

const mapDispatchToProps = {
  getJobDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Education));
