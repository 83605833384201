import {
  CalendarOutlined,
  EnvironmentOutlined,
  HomeOutlined,
  MailOutlined,
  ManOutlined,
  PhoneOutlined,
  WomanOutlined,
} from '@ant-design/icons';
import { isCallEnded } from '@components/callProviders/CallHelper';
import CallUtil from '@components/callProviders/CallUtil';
import useCallStatus from '@components/callProviders/useCallStatus';
import { ROUTER } from '@constants/Constant';
import '@styles/Customer.css';
import { notifyFail } from '@utils/notify';
import { toDateString } from '@utils/ToDateString';
import {
  Button,
  Collapse,
  Descriptions,
  Divider,
  Dropdown,
  List,
  Menu,
  PageHeader,
  Popover,
  Tag,
  Typography,
} from 'antd';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { requestStartCall } from '../../../../network/web_sale/CallApi';
import '../style.css';

const { Panel } = Collapse;
const { Text } = Typography;

const NotAvailableText = () => <Text type="secondary">Chưa cập nhật</Text>;

const CallStyled = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #28a745;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  :hover {
    background: #1f8136;
    cursor: ${props => (props.loading ? 'progress' : 'pointer')};
  }
`;

const CustomerInfo = ({
  data,
  onOpenModalAddTag,
  jobId,
  job_telesale_customer_id,
  onOpenModalAddOpportunity,
  recallImmediately,
  fromCallSchedule,
}) => {
  const history = useHistory();

  const { call } = useCallStatus();

  const nextCustomerStore = useSelector(state => state.nextCustomer.data);

  const [isSelectSubPhoneNumber, setIsSelectSubPhoneNumber] = useState(false);
  const [isSelectHotline, setIsSelectHotline] = useState(false);
  const [subPhone, setSubPhone] = useState([]);
  const [selectedSubPhoneNumber, setSelectedSubPhoneNumber] = useState('');

  const handleNavigateNextPage = () => {
    history.replace({
      pathname: `${ROUTER.JOB_CUSTOMER_DETAIL_SALE}/${nextCustomerStore.nextId}`,
      state: {
        isBackFromCalling: false,
        idJob: nextCustomerStore?.jobId,
        job_telesale_customer_id: nextCustomerStore?.nextJobTelesaleCustomerId,
      },
    });
  };

  const handleSelectPhoneNumber = customer => {
    //hiển thị popup chọn số điện thoại chính/phụ
    setIsSelectSubPhoneNumber(true);
    setIsSelectHotline(true);

    setSubPhone(customer.customer_phones);
  };

  const handleMakeCall = async (customer, callWithSubPhoneNumber = '') => {
    let customerPhoneNumber = customer.unmasked_pnumber;
    let isCallWithSubPhoneNumber = false;

    if (callWithSubPhoneNumber) {
      customerPhoneNumber = callWithSubPhoneNumber;
      isCallWithSubPhoneNumber = true;
    }

    try {
      // setLoadingHotline(true);
      const hotlines = CallUtil.hotlines;

      if (hotlines.length === 0) {
        notifyFail('Không có hotline phù hợp');
      } else if (hotlines.length === 1) {
        setIsSelectHotline(false);
        // gọi luôn
        makeCallWithHotline(
          hotlines[0],
          customerPhoneNumber,
          parseInt(job_telesale_customer_id),
          isCallWithSubPhoneNumber,
          customer.phone
        );
      }
    } catch (error) {
      console.log('Error handleMakeCall: ', { error });
    }
  };

  /**
   * function makeCallWithHotline
   * @param {*} hotline
   * @param {*} customerPhoneNumber
   * @param {*} jobTelemarketingCustomerId //bảng danh sách khách hàng của giao việc của sale
   * @param {boolean} isCallWithSubPhoneNumber
   * @param {string} maskedPhone // Số đã che
   */
  const makeCallWithHotline = async (
    hotline,
    customerPhoneNumber,
    jobTelemarketingCustomerId,
    isCallWithSubPhoneNumber = false,
    masked
  ) => {
    try {
      const payload = {
        job_id: parseInt(jobId),
        customer_phone_id: '',
        job_telesale_customer_id: parseInt(jobTelemarketingCustomerId),
        hotline: hotline.outbound_phone.toString(),
      };
      if (isCallWithSubPhoneNumber && subPhone) {
        const sub = subPhone.find(sub => sub.phone === customerPhoneNumber);
        if (sub) payload.customer_phone_id = sub.id;
      }
      const res = await requestStartCall(payload);
      if (fromCallSchedule) {
        CallUtil.currentSearch = null;
        CallUtil.isUncontacted = true;
        CallUtil.currentJobId = jobId;
      }
      CallUtil.makeCall(
        hotline.type,
        customerPhoneNumber,
        res.data.id,
        masked,
        parseInt(jobId),
        fromCallSchedule || CallUtil.isUncontacted
      );
    } catch (error) {
      console.log('Error makeCallWithHotline: ', { error });
    }
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <div className="bg p-4">
            <PageHeader
              ghost={false}
              style={{ padding: 0 }}
              title={data.name}
              extra={[
                <div
                  className={`d-flex align-items-center ${
                    job_telesale_customer_id
                      ? 'justify-content-between'
                      : 'justify-content-end'
                  }`}
                  style={{ width: '60px' }}
                >
                  <Popover
                    visible={isSelectHotline}
                    placement="bottomRight"
                    onVisibleChange={isShow => {
                      if (!isShow) {
                        setIsSelectHotline(false);
                      }
                    }}
                    content={() => {
                      if (isSelectSubPhoneNumber) {
                        const subPhoneNumber = subPhone.map(i => i.phone);

                        return (
                          <>
                            <Divider orientation="center">
                              Chọn số điện thoại phụ
                            </Divider>
                            <List
                              size="small"
                              dataSource={[data.phone, ...subPhoneNumber]}
                              renderItem={subPhone => (
                                <List.Item
                                  onClick={() => {
                                    setIsSelectSubPhoneNumber(false);
                                    setSelectedSubPhoneNumber(subPhone);
                                    handleMakeCall(data, subPhone);
                                  }}
                                >
                                  <Button type="link">
                                    {subPhone.toString()}
                                  </Button>
                                </List.Item>
                              )}
                            />
                          </>
                        );
                      } else {
                        return (
                          <>
                            <Divider orientation="center">Chọn hotline</Divider>
                            <List
                              size="small"
                              dataSource={CallUtil.hotlines}
                              renderItem={hotline => (
                                <List.Item
                                  onClick={() => {
                                    if (selectedSubPhoneNumber) {
                                      makeCallWithHotline(
                                        hotline,
                                        selectedSubPhoneNumber,
                                        parseInt(job_telesale_customer_id),
                                        true,
                                        selectedSubPhoneNumber
                                      );
                                    } else {
                                      makeCallWithHotline(
                                        hotline,
                                        data.unmasked_pnumber,
                                        parseInt(job_telesale_customer_id),
                                        false,
                                        data.phone
                                      );
                                    }
                                  }}
                                >
                                  <Button type="link">
                                    {hotline.outbound_phone.toString()}
                                  </Button>
                                </List.Item>
                              )}
                            />
                          </>
                        );
                      }
                    }}
                    trigger="click"
                  >
                    <CallStyled
                      onClick={() => {
                        if (!isCallEnded(call.status)) {
                          notifyFail('Đang có cuộc gọi khác');
                        } else {
                          if (data.customer_phones.length) {
                            handleSelectPhoneNumber(data);
                          } else {
                            setIsSelectHotline(true);
                            handleMakeCall(data);
                          }
                        }
                      }}
                    >
                      <i className="fas fa-phone-alt fa-lg"></i>
                    </CallStyled>
                  </Popover>

                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => onOpenModalAddOpportunity()}
                          >
                            Tạo cơ hội
                          </a>
                        </Menu.Item>
                      </Menu>
                    }
                    placement="topLeft"
                    arrow
                    trigger={['click']}
                  >
                    <div
                      style={{
                        cursor: 'pointer',
                        width: '15px',
                        textAlign: 'end',
                      }}
                    >
                      <i className="fas fa-ellipsis-v fa-lg"></i>
                    </div>
                  </Dropdown>
                </div>,
              ]}
            >
              <Row>
                <Col md={8} className="pl-0">
                  {data.customer_tags?.length ? (
                    data.customer_tags.map((item, index) => {
                      return (
                        <Tag
                          className="tag"
                          style={{
                            margin: 0,
                            marginLeft: 16,
                            paddingLeft: 2,
                            position: 'relative',
                            backgroundColor: '#EFEFEF',
                            border: 'none',
                            borderBottomLeftRadius: 0,
                            borderTopLeftRadius: 0,
                            borderBottomRightRadius: '4px',
                            borderTopRightRadius: '4px',
                          }}
                          key={item.tag_id}
                          color="default"
                        >
                          <div className="d-flex justify-content-around align-items-center">
                            <div
                              style={{
                                backgroundColor: item.color,
                                borderRadius: '50%',
                                width: '10px',
                                height: '10px',
                                marginRight: '3px',
                              }}
                            ></div>
                            {item.tag_name}
                          </div>
                        </Tag>
                      );
                    })
                  ) : (
                    <Text style={{ marginLeft: 5 }} strong={true}>
                      <NotAvailableText />
                    </Text>
                  )}
                </Col>
                <Col md={4} className="text-right pr-0">
                  <div>
                    <Button
                      key="3"
                      type="primary"
                      style={{ backgroundColor: '#138496', border: 'none' }}
                      onClick={() => onOpenModalAddTag()}
                    >
                      Cập nhật phân loại
                    </Button>
                  </div>
                  {nextCustomerStore.nextId ? (
                    <div
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        handleNavigateNextPage();
                      }}
                    >
                      Tiếp theo >>
                    </div>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </PageHeader>
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={12}>
          <div className="bg p-4">
            <PageHeader
              ghost={false}
              style={{ padding: 0 }}
              title={'Thông tin khách hàng'}
              extra={[
                <Link to={ROUTER.CUSTOMER_DETAIL_SALE + '/' + data.id}>
                  <Button
                    key="3"
                    type="primary"
                    style={{ backgroundColor: '#138496', border: 'none' }}
                  >
                    Xem và cập nhật thông tin
                  </Button>
                </Link>,
              ]}
            >
              <Row>
                <Col md={data.customer_data?.length ? 6 : 12}>
                  {data.customer_data?.length ? (
                    <Divider orientation="left">
                      <h5>Thông tin cơ bản</h5>
                    </Divider>
                  ) : (
                    <></>
                  )}
                  <Descriptions
                    style={{ paddingLeft: 10, paddingRight: 10 }}
                    column={{
                      xxl: data.customer_data?.length ? 2 : 3,
                      xl: data.customer_data?.length ? 2 : 3,
                      lg: data.customer_data?.length ? 2 : 3,
                      md: 2,
                      sm: 2,
                      xs: 1,
                    }}
                  >
                    <Descriptions.Item
                      className="descriptions"
                      label={null}
                      labelStyle={{ color: 'grey' }}
                    >
                      <Text>
                        <PhoneOutlined />
                      </Text>
                      {data.phone ? (
                        <Collapse expandIconPosition={'right'} ghost>
                          <Panel
                            header={
                              <Text style={{ color: '#1890ff' }} strong>
                                {data.phone}
                              </Text>
                            }
                            key="1"
                          >
                            {data.customer_phones.length ? (
                              <Descriptions column={1}>
                                {data.customer_phones.length ? (
                                  data.customer_phones.map(item => {
                                    return (
                                      <Descriptions.Item
                                        key={item.id}
                                        label={item.phone}
                                        labelStyle={{ color: '#1890ff' }}
                                        style={{
                                          paddingBottom: 0,
                                          paddingLeft: 5,
                                          backgroundColor: '#EFEFEF',
                                        }}
                                      >
                                        {item.note}
                                      </Descriptions.Item>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </Descriptions>
                            ) : (
                              <Text
                                style={{
                                  color: '#1890ff',
                                  marginLeft: 5,
                                  padding: 0,
                                }}
                                strong
                              >
                                <NotAvailableText />
                              </Text>
                            )}
                          </Panel>
                        </Collapse>
                      ) : (
                        <Text
                          style={{
                            color: '#1890ff',
                            marginLeft: 5,
                            padding: 0,
                          }}
                          strong
                        >
                          <NotAvailableText />
                        </Text>
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={null}
                      labelStyle={{ color: 'grey' }}
                    >
                      <MailOutlined />
                      <Text
                        strong={true}
                        style={{ marginLeft: 5, textAlign: 'left' }}
                      >
                        {data.email || <NotAvailableText />}
                      </Text>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={null}
                      labelStyle={{ color: 'grey' }}
                    >
                      <CalendarOutlined />
                      <Text
                        strong={true}
                        style={{ marginLeft: 5, textAlign: 'left' }}
                      >
                        {data.dob != null ? (
                          toDateString(data.dob)
                        ) : (
                          <NotAvailableText />
                        )}
                      </Text>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={null}
                      labelStyle={{ color: 'grey' }}
                    >
                      <ManOutlined />/<WomanOutlined />
                      <Text
                        strong={true}
                        style={{ marginLeft: 5, textAlign: 'left' }}
                      >
                        {data.gender != null ? (
                          data.gender === 1 ? (
                            'Nam'
                          ) : (
                            'Nữ'
                          )
                        ) : (
                          <NotAvailableText />
                        )}
                      </Text>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={null}
                      labelStyle={{ color: 'grey' }}
                    >
                      <EnvironmentOutlined />
                      <Text
                        strong={true}
                        style={{ marginLeft: 5, textAlign: 'left' }}
                      >
                        {data.province?.name || <NotAvailableText />}
                      </Text>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={null}
                      labelStyle={{ color: 'grey' }}
                    >
                      <HomeOutlined />
                      <Text
                        strong={true}
                        style={{ marginLeft: 5, textAlign: 'left' }}
                      >
                        {data.address || <NotAvailableText />}
                      </Text>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                {data.customer_data?.length ? (
                  <Col md={6}>
                    <Divider orientation="left">
                      <h5>Dữ liệu khách</h5>
                    </Divider>
                    <Descriptions
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                      column={{
                        xxl: 2,
                        xl: 1,
                        lg: 1,
                        md: 1,
                        sm: 1,
                        xs: 1,
                      }}
                    >
                      {/* -------------------------Thông tin thêm----------------------- */}
                      {data.customer_data?.length ? (
                        data.customer_data.map((item, index) => {
                          return (
                            <Descriptions.Item
                              key={index}
                              label={item.name || 'Dữ liệu mới'}
                              labelStyle={{ color: 'grey' }}
                            >
                              <Text strong={true} style={{ textAlign: 'left' }}>
                                {item.value || <NotAvailableText />}
                              </Text>
                            </Descriptions.Item>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </Descriptions>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            </PageHeader>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CustomerInfo;
