export function handleAutomaticCustomerDivision(cusNum, convertedArr, field) {
  //cusNum: tổng số khách hàng chưa giao việc
  //convertedArr: danh sách sale được chọn

  var averageJob = Math.floor(cusNum / convertedArr.length), //trung bình mỗi sale nhận được
    remainedJob = cusNum - averageJob * convertedArr.length; //số dư còn lại chưa chia

  let hasDefault = false; //cờ ưu tiên giả định trong trường hợp không có sale nào được ưu tiên trong số danh sách sale được chọn

  for (let i = 0; i < convertedArr.length; i++) {
    //is_default: kiểm tra độ ưu tiên của sale
    // + nếu sale không được ưu tiên thì chỉ nhận số trung bình
    // + nếu sale được ưu tiên sẽ nhận số dư còn lại
    if (convertedArr[i].is_default === 0) {
      convertedArr[i][field] = averageJob;
    } else {
      convertedArr[i][field] = averageJob + remainedJob;
      hasDefault = true;
    }
  }

  if (!hasDefault && convertedArr.length > 0) {
    convertedArr[convertedArr.length - 1][field] = averageJob + remainedJob;
  }
}
