import React from 'react';

import { ROUTER } from '@constants/Constant';
// import reactotron from 'reactotron-react-js';

// static component
import PrivateRoute from '../PrivateRoute';

// Auth screen
// import LoginScreen from '@screens/Auth/LoginScreen';

// Screen of Admin web
import AccountScreen from '@screens/Admin/Account/Screen';

// import Dashboard from '@screens/Admin/Dashboard/Dashboard';

import RegisterRequestDetail from '@screens/Admin/RegisterRequest/Detail';
import RegisterRequest from '@screens/Admin/RegisterRequest/Screen';

import RegisterRequestRejectedDetail from '@screens/Admin/RegisterRequestRejected/Detail';
import RegisterRequestRejected from '@screens/Admin/RegisterRequestRejected/Screen';

import TelesaleAdd from '@screens/Admin/Telesale/Add';
import TelesaleDetail from '@screens/Admin/Telesale/Detail';
import TelesaleEdit from '@screens/Admin/Telesale/Edit';
import TelesaleScreen from '@screens/Admin/Telesale/Screen';

import TelesaleInactiveDetail from '@screens/Admin/TelesaleInactive/Detail';
import TelesaleInactiveScreen from '@screens/Admin/TelesaleInactive/Screen';

import CompanyAdd from '@screens/Admin/Company/Add';
import CompanyDetail from '@screens/Admin/Company/Detail';
import CompanyEdit from '@screens/Admin/Company/Edit';
import SMSConfigurationDetail from '@screens/Admin/Company/SMSConfigurationDetail';
import CompanyScreen from '@screens/Admin/Company/Screen';

import ReportDetail from '@screens/Admin/Report/Detail';
import Report from '@screens/Admin/Report/Screen';
import StatisticJob from '@screens/Admin/StatisticJob/Screen';
import StatisticKPI from '@screens/Admin/StatisticKPI/Screen';

import AddTutorialScreen from '@screens/Admin/Tutorial/TutorialDetail';
import TutorialScreen from '@screens/Admin/Tutorial/TutorialScreen';

import SettingScreen from '@screens/Admin/Settings/Settings';

//Screen web reviewer
import CallLogScreen from '@screens/Admin/CallLog/CallLogScreen';

import ZaloOALoginCallback from '@screens/Enterprise/Settings/zaloOA/components/ZaloOALoginCallback';
import LazyLoading from 'src/modules/loading/Lazy.loading';

const Dashboard = React.lazy(() =>
  import('@screens/Admin/Dashboard/Dashboard')
);

export const renderAdminRoute = () => {
  return (
    <>
      <PrivateRoute
        path="/"
        exact
        Component={props => (
          <LazyLoading>
            <Dashboard {...props} />
          </LazyLoading>
        )}
      />
      <PrivateRoute
        path={ROUTER.HOME}
        exact
        Component={props => (
          <LazyLoading>
            <Dashboard {...props} />
          </LazyLoading>
        )}
      />
      {/* RegisterRequest route */}
      <PrivateRoute
        path={ROUTER.REGISTER_REQUEST}
        Component={RegisterRequest}
      />
      <PrivateRoute
        path={ROUTER.REGISTER_REQUEST_DETAIL + '/:id'}
        Component={RegisterRequestDetail}
      />
      {/* RegisterRequest rejected request */}
      <PrivateRoute
        path={ROUTER.REGISTER_REQUEST_REJECTED}
        Component={RegisterRequestRejected}
      />
      <PrivateRoute
        path={ROUTER.REGISTER_REQUEST_REJECTED_DETAIL + '/:id'}
        Component={RegisterRequestRejectedDetail}
      />
      {/* Account route */}
      <PrivateRoute path={ROUTER.ACCOUNT} exact Component={AccountScreen} />
      {/* Telesale route */}
      <PrivateRoute path={ROUTER.TELESALE} exact Component={TelesaleScreen} />
      <PrivateRoute path={ROUTER.TELESALE_ADD} exact Component={TelesaleAdd} />
      <PrivateRoute
        path={ROUTER.TELESALE_EDIT + '/:id'}
        exact
        Component={TelesaleEdit}
      />
      <PrivateRoute
        path={ROUTER.TELESALE_DETAIL + '/:id'}
        Component={TelesaleDetail}
      />
      {/* Telesale Inactive route */}
      <PrivateRoute
        path={ROUTER.TELESALE_INACTIVE}
        exact
        Component={TelesaleInactiveScreen}
      />
      <PrivateRoute
        path={ROUTER.TELESALE_INACTIVE_DETAIL + '/:id'}
        Component={TelesaleInactiveDetail}
      />
      {/* Company route */}
      <PrivateRoute path={ROUTER.COMPANY} exact Component={CompanyScreen} />
      <PrivateRoute path={ROUTER.COMPANY_ADD} exact Component={CompanyAdd} />
      <PrivateRoute
        path={ROUTER.COMPANY_EDIT + '/:id'}
        exact
        Component={CompanyEdit}
      />
      <PrivateRoute
        path={ROUTER.COMPANY_DETAIL + '/:id'}
        exact
        Component={CompanyDetail}
      />
      <PrivateRoute
        path={ROUTER.SMS_CONFIGURATION_DETAIL}
        exact
        Component={SMSConfigurationDetail}
      />
      {/* Statistic route */}
      <PrivateRoute path={ROUTER.STATISTIC_JOB} Component={StatisticJob} />
      <PrivateRoute path={ROUTER.STATISTIC_KPI} Component={StatisticKPI} />
      <PrivateRoute path={ROUTER.REPORT} Component={Report} />
      <PrivateRoute
        path={ROUTER.REPORT_DETAIL + '/:id'}
        Component={ReportDetail}
      />
      {/* Setting route */}
      <PrivateRoute path={ROUTER.CALL_LOG} Component={CallLogScreen} />
      <PrivateRoute path={ROUTER.SETTINGS_ADMIN} Component={SettingScreen} />
      {/* Tutorial route */}
      <PrivateRoute path={ROUTER.TUTORIAL} exact Component={TutorialScreen} />
      <PrivateRoute
        path={ROUTER.ADD_TUTORIAL}
        exact
        Component={AddTutorialScreen}
      />
      <PrivateRoute
        path={ROUTER.TUTORIAL_DETAIL + '/:id'}
        exact
        Component={AddTutorialScreen}
      />
      <PrivateRoute path={ROUTER.ZALO_LOGIN} Component={ZaloOALoginCallback} />
    </>
  );
};
