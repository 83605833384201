import { getJobDetail } from '@actions/index';
import Loading from '@components/Loading';
import {
  requestCreateForm,
  requestDeleteForm,
  requestUpdateForm,
} from '@constants/Api';
import { QUESTION_TYPE, ROUTER, STRING } from '@constants/Constant';
import FormResult from '@screens/Enterprise/Job/Add/Component/Context/_Form';
import ModalAddForm from '@screens/Enterprise/Job/Add/Component/Context/_ModalAddForm';
import ModalAddSubForm from '@screens/Enterprise/Job/Add/Component/Context/_ModalAddSubForm';
import ModalEditForm from '@screens/Enterprise/Job/Add/Component/Context/_ModalEditForm';
import { ButtonSystemStyle, TitleStyle } from '@styles/Style';
import { notifyFail, notifySuccess } from '@utils/notify';
import { Col as ColAntd, Empty, Row as RowAntd } from 'antd';
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import reactotron from 'reactotron-react-js';
import { FORWARD_ANSWER } from '../../Constants';

class FormQuestion extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      tab: '1',
      modalAdd: false,
      subModalAdd: false,
      modalEdit: false,
      formAdd: {
        type_input: 1,
        name: '',
        reward_point: '',
        job_form_answers: [],
        is_require: 0,
        is_conclusions: 0,
        enterprise_forward_answer_enabled: FORWARD_ANSWER.NONE,
      },
      subFormAdd: {
        type_input: 1,
        name: '',
        reward_point: '',
        job_form_answers: [],
        is_require: false,
      },
      formEdit: {
        type_input: 1,
        name: '',
        reward_point: '',
        job_form_answers: [],
        is_require: false,
      },
      disabled: false,
      isLoading: false,
      forwardJobEnable: 0,
    };
    //callback lưu bước 3
    this.saveStep = this.onNextStep.bind(this);
  }

  componentDidMount() {
    //callback lưu bước 3
    this.props.onSaveStep(this.saveStep);

    const jobDetail = this.props?.jobState?.data;
    this.setState({
      forwardJobEnable: jobDetail.enterprise_forward_job_enabled,
    });
  }

  handleChangeFormAdd(field, value) {
    // if (field == 'is_conclusions' && value == 1) {
    //   this.setState({
    //     formAdd: {
    //       ...this.state.formAdd,
    //       [field]: value,
    //       is_require: 1,
    //     },
    //   });
    // } else {
    this.setState({
      formAdd: {
        ...this.state.formAdd,
        [field]: value,
      },
    });
    // }
  }

  handleSubFormAdd(field, value) {
    this.setState({
      subFormAdd: {
        ...this.state.subFormAdd,
        [field]: value,
      },
    });
  }

  handleChangeFormEdit(field, value) {
    this.setState({
      formEdit: {
        ...this.state.formEdit,
        [field]: value,
      },
    });
  }

  setLoading(value) {
    this.props.setLoading(value);
  }

  async addForm() {
    const jobId = this.props?.jobState.data?.job_id;
    this.setLoading(true);
    this.setState({
      ...this.state,
      disabled: true,
    });
    try {
      if (this.validateFormAdd(this.state.formAdd)) {
        await requestCreateForm({
          ...this.state.formAdd,
          job_id: jobId,
          id: 0,
        });
        this.props.getJobDetail({ id: jobId });
        this.setState({
          ...this.state,
          formAdd: {
            type_input: '1',
            name: '',
            reward_point: '',
            job_form_answers: [],
            is_require: false,
          },
          disabled: false,
        });
        this.setLoading(false);
        this.showModal('modalAdd', false);
        // notifySuccess('Thêm mới thành công!');
      } else {
        this.setState({
          ...this.state,
          disabled: false,
        });
        this.setLoading(false);
        return;
      }
    } catch (err) {
      this.setLoading(false);
      this.setState(
        {
          ...this.state,
          disabled: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  validateFormAdd(form) {
    const form_answers = form.job_form_answers;
    const allAnswersObj = {};
    for (let answer of form_answers) {
      if (allAnswersObj[answer.name.trim().toUpperCase()]) {
        // duplicate ata
        notifyFail('Trùng lặp đáp án ' + answer.name.toLowerCase());
        return false;
      }
      allAnswersObj[answer.name.trim().toUpperCase()] = answer;
    }
    if (!this.props?.jobState.data?.job_id) {
      notifyFail('Vui lòng nhập thông tin chung');
      return false;
    }
    if (!form.name.trim()) {
      notifyFail('Vui lòng nhập nội dung câu hỏi');
      return false;
    }
    if (form.reward_point) {
      if (form.reward_point < 1) {
        notifyFail('Điểm thưởng phải dương');
        return false;
      }
    } else {
      notifyFail('Vui lòng nhập điểm kinh nghiệm');
      return false;
    }

    if (
      [
        QUESTION_TYPE.SELECT,
        QUESTION_TYPE.MULTIPLE_SELECT,
        QUESTION_TYPE.YES_NO,
      ].includes(parseInt(form.type_input))
    ) {
      if (form.job_form_answers?.length < 2) {
        notifyFail('Vui lòng tạo ít nhất 2 lựa chọn');
        return false;
      }
    }
    // if ([QUESTION_TYPE.MULTIPLE_SELECT].includes(parseInt(form.type_input))) {
    //   if (form.job_form_answers?.length < 2) {
    //     notifyFail('Tạo ít nhất 2 câu trả lời');
    //     return false;
    //   }
    // }
    return true;
  }

  async addSubForm(questionId, optionId) {
    const { subFormAdd } = this.state;
    const jobId = this.props?.jobState.data?.job_id;
    if (this.validateFormAdd(subFormAdd)) {
      try {
        this.setLoading(true);
        const res = await requestCreateForm({
          job_id: jobId,
          id: 0,
          parent_id: optionId,
          type_input: subFormAdd.type_input,
          name: subFormAdd.name,
          reward_point: subFormAdd.reward_point,
          job_form_answers: subFormAdd.job_form_answers,
          is_require: subFormAdd.is_require,
        });
        this.props.getJobDetail({ id: jobId });
        this.setState({
          subFormAdd: {
            type_input: '1',
            name: '',
            reward_point: '',
            job_form_answers: [],
            is_require: false,
          },
          subModalAdd: false,
        });
        this.setLoading(false);
        // notifySuccess('Thêm mới thành công!');
      } catch (err) {
        this.setLoading(false);
        notifyFail(err.msg);
      }
    } else {
      return;
    }
  }

  async deleteForm(formId) {
    try {
      this.setLoading(true);
      await requestDeleteForm({
        job_id: this.props.jobState.data.job_id,
        form_id: formId,
      });
      this.props.getJobDetail({ id: this.props.jobState.data.job_id });

      this.setLoading(false);
      notifySuccess('Xóa thành công');
    } catch (err) {
      this.setLoading(false);
    }
  }

  async updateForm() {
    const jobId = this.props.jobState?.data?.job_id;
    if (this.validateFormAdd(this.state.formEdit)) {
      try {
        this.setLoading(true);
        const res = await requestUpdateForm({
          job_id: jobId,
          ...this.state.formEdit,
        });
        this.props.getJobDetail({ id: jobId });
        this.setLoading(false);
        this.setState({
          formEdit: {
            type_input: 1,
            name: '',
            reward_point: '',
            job_form_answers: [],
            is_require: false,
          },
          modalEdit: false,
        });
        notifySuccess('Cập nhật thông tin thành công!');
      } catch (err) {
        this.setLoading(false);
        notifyFail(err.msg);
      }
    } else {
      return;
    }
  }

  renderHeader() {
    return (
      <RowAntd justify="space-between">
        <ColAntd>
          <div style={{ display: 'flex' }}>
            <TitleStyle>{`${STRING.result_card}`}</TitleStyle>
            <i
              style={{ marginLeft: '20px' }}
              className="fas fa-plus text-green add-btn"
              onClick={() => this.showModal('modalAdd', true)}
            ></i>
          </div>
        </ColAntd>
        <ColAntd>
          {/* <ButtonPrimaryStyle type="primary" htmlType="button" onClick={() => this.onFinish('save')}>
            <DownloadOutlined style={{ marginRight: '10px' }} />
            Lưu
          </ButtonPrimaryStyle> */}
        </ColAntd>
      </RowAntd>
      // <Row className="py-3">
      //   <Col sm={12} className="px-0 d-flex justify-content-between">
      //     <h4>Phiếu kết quả</h4>
      //     <i className="fas fa-plus text-green add-btn" onClick={() => this.showModal('modalAdd', true)}></i>
      //   </Col>
      // </Row>
    );
  }

  renderForm() {
    const questionList = this.props.jobState.data?.job_form_questions;
    if (questionList?.length) {
      return (
        <>
          <Row>
            <Col sm={12} className="mt-2 p-0">
              {questionList?.length > 0 &&
                questionList.map((item, index) => (
                  <div
                    style={{
                      padding: '10px 5px',
                      backgroundColor: '#ffffff',
                      borderRadius: '12px',
                      boxShadow: '0 20px 80px rgba(0, 0, 0, 0.2)',
                      marginTop: '10px',
                    }}
                  >
                    <FormResult
                      form={{ ...item }}
                      deleteForm={this.deleteForm.bind(this)}
                      showSubModal={this.showSubModal.bind(this)}
                      showEditModal={this.showEditModal.bind(this)}
                      key={item.id}
                      index={index}
                    />
                  </div>
                ))}
            </Col>
          </Row>
        </>
      );
    } else {
      return (
        <Row>
          <Col sm={12} className=" mt-2">
            <Empty
              description={<span>Chưa có phiếu kết quả nào được tạo</span>}
            />
          </Col>
        </Row>
      );
    }
  }

  showModal(modal, value) {
    this.setState({
      ...this.state,
      modalAdd: value,
      formAdd: {
        type_input: 1,
        name: '',
        reward_point: '',
        job_form_answers: [],
        is_require: 0,
        is_conclusions: 0,
      },
    });
  }

  showSubModal(modal, value, parentName, childName) {
    this.setState({
      subModalAdd: value,
      subFormAdd: {
        ...this.state.subFormAdd,
        parent: parentName,
        parentOption: childName,
      },
    });
  }

  showEditModal(modal, value, form) {
    this.setState({
      modalEdit: value,
      formEdit: { ...form },
    });
  }

  renderButton() {
    return (
      <RowAntd
        style={{ marginTop: '10px' }}
        align="middle"
        justify="space-between"
      >
        <ColAntd>
          <ButtonSystemStyle
            htmlType="button"
            onClick={() => this.onFinish('prev')}
          >
            Quay lại
          </ButtonSystemStyle>
        </ColAntd>
        <ColAntd>
          <ButtonSystemStyle
            htmlType="button"
            onClick={() => this.onFinish('next')}
          >
            Tiếp tục
          </ButtonSystemStyle>
        </ColAntd>
      </RowAntd>
    );
  }

  onFinish = type => {
    // ModalAntd.confirm({
    //   title: 'Xác nhận',
    //   icon: <ExclamationCircleOutlined />,
    //   content: 'Bạn có muốn lưu thông tin trước khi chuyển sang nội dung khác?',
    //   okText: 'Lưu',
    //   cancelText: 'Quay lại',
    //   onOk: () =>
    this.onNextStep(type);
    // });
  };

  onNextStep(type) {
    const questionList = this.props.jobState.data?.job_form_questions;
    const jobId = this.props?.jobState.data?.job_id;
    this.props.getJobDetail({ id: jobId });
    this.setState({ isLoading: true });
    if (!questionList?.length) {
      const stepStatus = this.props.stepStatus;
      if (type === 'save') {
        notifySuccess('Thêm công việc thành công');
        this.props.history.push(ROUTER.JOB);
      }
      if (type === 'prev') {
        stepStatus[2] = 'finish'; //hoàn thành bước 3
        stepStatus[1] = 'process'; //chuyển trạng thái bước 2
        this.props.setStep(parseInt(this.props.currentStep) - 1, stepStatus);
      }
      if (type === 'next') {
        stepStatus[2] = 'finish'; //hoàn thành bước 3
        stepStatus[3] = 'process'; //chuyển trạng thái bước 4
        this.props.setStep(this.props.currentStep + 1, stepStatus);
      }
      if (type.includes('changeStep')) {
        let step = type.split(':');
        stepStatus[2] = 'finish'; //hoàn thành bước 3
        stepStatus[parseInt(step[1])] = 'process'; // chuyển trạng thái bước được chọn trên menu step
        this.props.setStep(parseInt(step[1]), stepStatus);
      }
      return;
    }
    const stepStatus = this.props.stepStatus;
    if (type === 'save') {
      notifySuccess('Thêm công việc thành công');
      this.props.history.push(ROUTER.JOB);
    }
    if (type === 'prev') {
      stepStatus[2] = 'finish'; //hoàn thành bước 3
      stepStatus[1] = 'process'; //chuyển trạng thái bước 2
      this.props.setStep(parseInt(this.props.currentStep) - 1, stepStatus);
    }
    if (type === 'next') {
      stepStatus[2] = 'finish'; //hoàn thành bước 3
      stepStatus[3] = 'process'; //chuyển trạng thái bước 4
      this.props.setStep(this.props.currentStep + 1, stepStatus);
    }
    if (type.includes('changeStep')) {
      let step = type.split(':');
      stepStatus[2] = 'finish'; //hoàn thành bước 3
      stepStatus[parseInt(step[1])] = 'process'; // chuyển trạng thái bước được chọn trên menu step
      this.props.setStep(parseInt(step[1]), stepStatus);
    }
    this.setState({ isLoading: false });
  }

  render() {
    return (
      <>
        {this.state.isLoading && <Loading />}

        {this.state.subModalAdd && (
          <ModalAddSubForm
            handle={this.handleSubFormAdd.bind(this)}
            value={this.state.subFormAdd}
            show={this.state.subModalAdd}
            close={this.showSubModal.bind(this)}
            add={this.addSubForm.bind(this)}
          />
        )}

        {this.state.modalAdd && (
          <ModalAddForm
            handle={this.handleChangeFormAdd.bind(this)}
            value={this.state.formAdd}
            show={this.state.modalAdd}
            close={this.showModal.bind(this)}
            add={this.addForm.bind(this)}
            disabled={this.state.disabled}
            forwardJobEnable={this.state.forwardJobEnable}
          />
        )}

        {this.state.modalEdit && (
          <ModalEditForm
            handle={this.handleChangeFormEdit.bind(this)}
            value={this.state.formEdit}
            show={this.state.modalEdit}
            close={this.showEditModal.bind(this)}
            update={this.updateForm.bind(this)}
            forwardJobEnable={this.state.forwardJobEnable}
          />
        )}

        {this.renderHeader()}

        {this.renderForm()}

        {this.renderButton()}
      </>
    );
  }
}

const mapStateToProps = state => ({
  jobState: state.jobDetailReducer,
});

const mapDispatchToProps = {
  getJobDetail,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FormQuestion));
