import {
  requestGetAllDepartment,
  requestGetAllJob,
  requestGetDataFieldFilterCustom,
  requestGetTelemarketingInDepartment,
  requestQuestionsAndAnswerReport,
} from '@constants/Api';
import { TIME_CODE_TYPE } from '@constants/Constant';
import { setSearchParamsInUrlVer2, rmVN, getListOptionsAnswerData } from '@constants/funcHelper';
import { VietnameseString } from '@constants/VietnameseString';
import { requestSubJobFilter } from '@screens/Enterprise/Job/services/api';
import { Col, DatePicker, Empty, Row, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function Filter({
  param,
  timeFilterType,
  setTimeFilterType,
  handleChangeParamTelemarketingReport,
  handleChangeParamTimeTelemarketingReport,
  handleChangeTypeOfInteraction,
  setParam
}) {
  const [telemarketing, setTelemarketing] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [customerSource, setCustomerSource] = useState([]);
  const [parentJob, setParentJob] = React.useState([]);
  const [subJob, setSubJob] = React.useState([]);
  const [questions, setQuestions] = React.useState([]);
  const [listAnswer, setListAnswer] = useState([])

  React.useEffect(() => {
    const getAllJob = async () => {
      try {
        const { data } = await requestGetAllJob();
        setParentJob(data);
      } catch (error) {
        console.log('Error getAllJob: ', { error });
      }
    };
    getAllJob();
  }, []);
  useEffect(() => {
    const getAllSubJob = async function (parentJobId) {
      try {
        const { data } = await requestSubJobFilter({
          job_category_id: parentJobId,
          enterprise_id: '',
          telesale_id: '',
          limit: '',
        });
        setSubJob(data);
      } catch (error) {
        console.log('Error getAllSubJob: ', { error });
      }
    };
    getAllSubJob(param.job_parent_id)
  }, [param.job_parent_id])
  const userInfo = useSelector(store => store.userReducer);
  let jobIdFilter = param?.job_id.length  ? param?.job_id : param?.job_parent_id;

  useEffect(() => {
    async function getQuestion(jobId) {
      try {
        const { data } = await requestQuestionsAndAnswerReport({
          enterprise_id: userInfo.data.enterprise_id,
          job_category_id: jobId
        });
        let dataConvert = data.map(item => {
          return {...item, label: item.name, value: item.id}
        })
        setQuestions(dataConvert);
      } catch (error) {
        console.log('Error getQuestionInJob: ', { error });
      }
    }
    getQuestion(jobIdFilter)
  }, [jobIdFilter])

  //giữ lại optionn filter answer
  useEffect(() => {
      if(param.job_form_question_id?.length > 0 ) {
       let listAnswerkeep =  questions.filter(item => {
          return param.job_form_question_id.includes(item.id)
        })
        setListAnswer(getListOptionsAnswerData(listAnswerkeep).optionAnswerData)
      } 
  }, [ questions])
  
  function handleChangeQuestion(value, optionsQuestion) {
    let getListAnswer = getListOptionsAnswerData(optionsQuestion,[] )
    let filterAnswer =[]
    getListAnswer.getListJobAnswerByOption.forEach(item => {
        [...param.job_form_answers_id].forEach(id => {
          if(item.id === id) {
            filterAnswer.push(id)
          }
      })
    })

    setListAnswer(getListAnswer.optionAnswerData)
    setParam({
      ...param, job_form_question_id: value,job_form_answers_id: filterAnswer
    })
    setSearchParamsInUrlVer2({ 
      job_form_question_id: value,
      job_form_answers_id: filterAnswer,
       page: 1 });
}
  const getAllTelemarketing = async () => {
    try {
      const result = await requestGetTelemarketingInDepartment(
        param.department_id
      );
      const telemarketing =
        result?.data?.map(sale => ({
          ...sale,
          value: sale.id,
          label: sale.full_name,
        })) || [];
      setTelemarketing(telemarketing);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const getAllDepartment = async () => {
    try {
      const result = await requestGetAllDepartment();
      setDepartments(result.data);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const getCustomerSource = async () => {
    try {
      // const { data } = await requestGetDataFieldFilterCustom('customer_source');
      const { data } = await requestGetDataFieldFilterCustom();
      if (data && data.length) {
        const dataFilter =
          data?.find(item => item.code === 'customer_source')
            ?.customer_data_field_sources || [];

        setCustomerSource(() =>
          dataFilter.map(s => ({ label: s.value, code: s.item_code }))
        );
      }
    } catch (error) {
      console.log('Error getCustomerSource: ', { error });
    }
  };

    function getValueChangeParentJob(listParentSelected, job_id) {
      let dataListElementSubjobSelected = subJob.filter(item => job_id.includes(item.id) )
      let dataJobNeedKeep = [] 
       dataListElementSubjobSelected.forEach(
        dataListElementSubjobSelectedItem => {
        if(listParentSelected.includes(dataListElementSubjobSelectedItem.parent_id)) {
          dataJobNeedKeep.push(dataListElementSubjobSelectedItem.id)
        } 
  
      })
      return dataJobNeedKeep

    }
  useEffect(() => {
    getAllDepartment();
    getCustomerSource();
  }, []);

  useEffect(() => {
    getAllTelemarketing();
  }, [param.department_id]);

  const handleRenderTimeFilter = () => {
    switch (timeFilterType) {
      case TIME_CODE_TYPE.DATE:
        return (
          <DatePicker
            allowClear={false}
            style={{ width: '100%' }}
            locale={locale}
            format={'DD/MM/YYYY'}
            value={moment(param.from)}
            disabledDate={currentDate =>
              currentDate && currentDate > moment().endOf('day')
            }
            onChange={(date, dateString) => {
              handleChangeParamTimeTelemarketingReport(
                moment(date).format('YYYY/MM/DD'),
                moment(date).format('YYYY/MM/DD')
              );
            }}
          />
        );

      case TIME_CODE_TYPE.WEEK:
        return (
          <DatePicker
            allowClear={false}
            style={{ width: '100%' }}
            locale={locale}
            value={moment(param.from)}
            disabledDate={currentDate =>
              currentDate && currentDate > moment().endOf('week')
            }
            onChange={(date, dateString) => {
              handleChangeParamTimeTelemarketingReport(
                moment(date).startOf('week').format('YYYY/MM/DD'),
                moment(date).endOf('week').format('YYYY/MM/DD')
              );
            }}
            picker="week"
          />
        );

      case TIME_CODE_TYPE.MONTH:
        return (
          <DatePicker
            allowClear={false}
            style={{ width: '100%' }}
            format={'MM/YYYY'}
            locale={locale}
            value={moment(param.from)}
            disabledDate={currentDate =>
              currentDate && currentDate > moment().endOf('month')
            }
            onChange={(date, dateString) => {
              handleChangeParamTimeTelemarketingReport(
                moment(date).startOf('month').format('YYYY/MM/DD'),
                moment(date).endOf('month').format('YYYY/MM/DD')
              );
            }}
            picker="month"
          />
        );

      case TIME_CODE_TYPE.QUARTER:
        return (
          <DatePicker
            allowClear={false}
            style={{ width: '100%' }}
            locale={locale}
            value={moment(param.from)}
            disabledDate={currentDate =>
              currentDate && currentDate > moment().endOf('quarter')
            }
            onChange={(date, dateString) => {
              handleChangeParamTimeTelemarketingReport(
                moment(date).startOf('quarter').format('YYYY/MM/DD'),
                moment(date).endOf('quarter').format('YYYY/MM/DD')
              );
            }}
            picker="quarter"
          />
        );

      case TIME_CODE_TYPE.YEAR:
        return (
          <DatePicker
            allowClear={false}
            style={{ width: '100%' }}
            locale={locale}
            value={moment(param.from)}
            disabledDate={currentDate =>
              currentDate && currentDate > moment().endOf('year')
            }
            onChange={(date, dateString) => {
              handleChangeParamTimeTelemarketingReport(
                moment(date).startOf('year').format('YYYY/MM/DD'),
                moment(date).endOf('year').format('YYYY/MM/DD')
              );
            }}
            picker="year"
          />
        );

      default:
        break;
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
        <Select
          style={{ width: '100%' }}
          mode="multiple"
          optionFilterProp="children"
          filterOption={(input, option) => {
            return rmVN(option.children).indexOf(rmVN(input)) >= 0;
          }}
          placeholder={VietnameseString.telemarketing}
          allowClear
          maxTagCount={1}
          value={param.telemarketing_id}
          notFoundContent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>{VietnameseString.no_data}</span>}
            />
          }
          onChange={value => {
            handleChangeParamTelemarketingReport('telemarketing_id', value);
          }}
        >
          {telemarketing.map((item, index) => (
            <Select.Option key={index} value={item.id}>
              {item.full_name}
            </Select.Option>
          ))}
        </Select>
      </Col>

      <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
        <Select
          style={{ width: '100%' }}
          mode="multiple"
          optionFilterProp="children"
          filterOption={(input, option) => {
            return rmVN(option.children).indexOf(rmVN(input)) >= 0;
          }}
          placeholder={VietnameseString.department}
          onClear={() => {
            //khi clear phòng ban thì clear sale chăm sóc và clear sale chăm sóc trên url
            handleChangeParamTelemarketingReport('telemarketing_id', []);
          }}
          allowClear
          maxTagCount={1}
          value={param.department_id}
          notFoundContent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>{VietnameseString.no_data}</span>}
            />
          }
          onChange={value => {
            handleChangeParamTelemarketingReport('department_id', value);
          }}
        >
          {departments.map((item, index) => (
            <Select.Option key={index} value={item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Col>

      <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
        <Select
          style={{ width: '100%' }}
          value={timeFilterType}
          onChange={value => {
            setTimeFilterType(value);
            setSearchParamsInUrlVer2({ type: value, page: 1 });
          }}
        >
          <Select.Option value={TIME_CODE_TYPE.DATE}>
            {VietnameseString.date}
          </Select.Option>
          <Select.Option value={TIME_CODE_TYPE.WEEK}>
            {VietnameseString.week}
          </Select.Option>
          <Select.Option value={TIME_CODE_TYPE.MONTH}>
            {VietnameseString.month}
          </Select.Option>
          <Select.Option value={TIME_CODE_TYPE.QUARTER}>
            {VietnameseString.quarter}
          </Select.Option>
          <Select.Option value={TIME_CODE_TYPE.YEAR}>
            {VietnameseString.year}
          </Select.Option>
        </Select>
      </Col>

      <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
        {handleRenderTimeFilter()}
      </Col>
      <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
        <Select
          style={{ width: '100%' }}
          mode="multiple"
          optionFilterProp="children"
          placeholder={VietnameseString.customer_source}
          value={param.customer_data_field_source_code}
          filterOption={(input, option) => {
            return rmVN(option.children).indexOf(rmVN(input)) >= 0;
          }}
          allowClear
          maxTagCount={1}
          onChange={value => {
            handleChangeParamTelemarketingReport(
              'customer_data_field_source_code',
              value
            );
          }}
        >
          {customerSource.map(s => (
            <Select.Option key={s.code} value={s.code}>
              {s.label}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
        <Select
          style={{ width: '100%' }}
          allowClear
          placeholder={'Loại tương tác'}
          value={param.customer_action_type_id}
          options={[
            { key: 0, value: 0, label: 'Tất cả' },
            { key: 10, value: 10, label: 'Thêm mới' },
          ]}
          onChange={handleChangeTypeOfInteraction}
        />
      </Col>
      <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
              <Select
                style={{ width: '100%' }}
                mode="multiple"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return rmVN(option.children).indexOf(rmVN(input)) >= 0;
                }}
                placeholder="Công việc"
                allowClear
                maxTagCount={1}
                value={param.job_parent_id}
                notFoundContent={
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>Không có dữ liệu!</span>} />
                }
                onChange={(value) => {
                  setParam({
                    ...param, job_parent_id: value,
                    job_form_question_id:[],
                    job_form_answers_id: [],
                    job_id: 
                    getValueChangeParentJob(value, param.job_id)
                  })
                  setListAnswer([])
                  setSearchParamsInUrlVer2({ job_parent_id: value, page: 1 });

                }}
              >
                         {parentJob.map(job => (
              <Select.Option key={job.id} value={job.id}>
                {job.name}
              </Select.Option>

              
            ))}
              </Select>
              </Col>

              <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
              <Select
                style={{ width: '100%' }}
                mode="multiple"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return rmVN(option.children).indexOf(rmVN(input)) >= 0;
                }}
                placeholder="Giao việc"
                value={param.job_id}
                allowClear
                maxTagCount={1}
                // value={call_status}
                notFoundContent={
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>Không có dữ liệu!</span>} />
                }
                onChange={(value) => {
                setParam({
                  ...param,  job_id: value,
                  job_form_question_id: [],
                  job_form_answers_id: [],
                })
                setSearchParamsInUrlVer2({ job_id: value, page: 1 });

                }}
              >
       
             {subJob.map(sub => (
              <Select.Option key={sub.id} value={sub.id}>
                {sub.name}
              </Select.Option>
            ))}
              </Select>
              </Col>
              <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
              <Select
                style={{ width: '100%' }}
                mode="multiple"
                // optionFilterProp="children"
                filterOption={(input, option) => {
                  return rmVN(option?.label).indexOf(rmVN(input)) >= 0;
                }}
                placeholder="Câu hỏi"
                allowClear
                maxTagCount={1}
                value={param.job_form_question_id}
                notFoundContent={
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>Không có dữ liệu!</span>} />
                }
                onChange={handleChangeQuestion}
                options = {questions}
              />
                    
          
              </Col>

              <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
              <Select
                style={{ width: '100%' }}
                mode="multiple"
                // optionFilterProp="children"
                filterOption={(input, option) => {
                  return rmVN(option?.label).indexOf(rmVN(input)) >= 0;
                }}
                placeholder="Đáp án"
                allowClear
                maxTagCount={1}
                value={ param.job_form_answers_id}
                notFoundContent={
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>Không có dữ liệu!</span>} />
                }
                onChange={(value) => {
           setParam({
            ...param, job_form_answers_id: value
           })
           setSearchParamsInUrlVer2({ job_form_answers_id: value, page: 1 })
                }}
                options={listAnswer}
              />
              </Col>
    </Row>
  );
}
