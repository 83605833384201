import React, { Component } from 'react';
import '@styles/review.css';
import './Job.css';
import { ReactSelect } from '@components/ReactSelect';
import { getCallHistory } from '@src/redux/actions';
import Pagination from '@components/PaginationTotal';
import { ROUTER } from '@constants/Constant';
import { requestResultReviewer } from '@constants/Api';
import { notifyFail, notifySuccess } from '@utils/notify';
import Loading from '@src/components/Loading';

import { withRouter, Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Empty } from 'antd';

class JobDetailScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job_category_id: '',
      job_id: '',
      telesale_id: '',
      status: '',
      listJobCategory: [],
      listJob: [],
      listTelesale: [],
      listStatus: [],
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.getData({ page: 1, task_id: id });
    this.requestResultReviewer();
  }

  getData({
    page,
    call_type,
    job_id,
    call_status,
    telesale_id,
    start_call_time,
    limit,
    fromDate,
    toDate,
    is_late = 0,
    job_category_id,
    job_form_question_id,
    job_form_answers_id,
    call_review_config_id,
    keyword,
    search,
    task_id,
  }) {
    this.props.getCallHistory({
      page: page || '',
      call_type: call_type || '',
      job_id: job_id || '',
      call_status: call_status || '',
      telesale_id: telesale_id || '',
      start_call_time: start_call_time || '',
      limit: 12,
      fromDate: fromDate || '',
      toDate: toDate || '',
      is_late: is_late,
      job_category_id: job_category_id || '',
      job_form_question_id: job_form_question_id || '',
      job_form_answers_id: job_form_answers_id || '',
      call_review_config_id: call_review_config_id || '',
      keyword: keyword || '',
      search: search || '',
      task_id: task_id || '',
    });
  }

  async requestResultReviewer() {
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const res = await requestResultReviewer();
      this.setState({
        ...this.state,
        listJobCategory: res.data?.list_task[0]?.job_categories,
        listJob: res.data?.list_task[0]?.jobs,
        listTelesale: res.data?.list_task[0]?.telesales,
        listStatus: res.data?.list_task[0]?.call_status,
      });
    } catch (err) {
      this.setState({ ...this.state, isLoading: false }, () => notifyFail(err.msg));
    }
  }

  handleChangeSelect = (selectedOption, field) => {
    this.setState({ [field]: selectedOption.value }, () => {
      const { job_category_id, job_id, telesale_id, status } = this.state;
      this.getData({
        job_category_id: job_category_id,
        job_id: job_id,
        telesale_id: telesale_id,
        call_status: status,
      });
    });
  };

  renderTitle() {
    return <h2 className="title-tab">Danh sách cuộc gọi</h2>;
  }

  renderField() {
    const { listJobCategory, listJob, listTelesale, listStatus } = this.state;
    const listJobCategoryArr = listJobCategory?.map(
      (province) =>
        new Object({
          value: province.id,
          label: province.name,
        })
    );
    listJobCategoryArr.unshift({ value: '', label: 'Công việc cha' });

    const listJobArr = listJob?.map(
      (province) =>
        new Object({
          value: province.id,
          label: province.name,
        })
    );
    listJobArr.unshift({ value: '', label: 'Giao việc' });

    const listTelesaleArr = listTelesale?.map(
      (province) =>
        new Object({
          value: province.id,
          label: province.name,
        })
    );
    listTelesaleArr.unshift({ value: '', label: 'Telesale' });

    const listStatusArr = listStatus?.map(
      (province) =>
        new Object({
          value: province.id,
          label: province.name,
        })
    );
    listStatusArr.unshift({ value: '', label: 'Trạng thái' });
    return (
      <Row>
        <Col md={3}>
          <ReactSelect
            handleChangeSelect={(e) => this.handleChangeSelect(e, 'job_category_id')}
            list={listJobCategoryArr}
            placeholder="Chọn công việc cha"
          />
        </Col>

        <Col md={3}>
          <ReactSelect
            handleChangeSelect={(e) => this.handleChangeSelect(e, 'job_id')}
            list={listJobArr}
            placeholder="Giao việc"
          />
        </Col>

        <Col md={3}>
          <ReactSelect
            handleChangeSelect={(e) => this.handleChangeSelect(e, 'telesale_id')}
            list={listTelesaleArr}
            placeholder="Sale"
          />
        </Col>

        <Col md={3}>
          <ReactSelect
            handleChangeSelect={(e) => this.handleChangeSelect(e, 'status')}
            list={listStatusArr}
            placeholder="Trạng thái"
          />
        </Col>
      </Row>
    );
  }

  renderTable() {
    const callHistory = this.props.callHistoryState?.data;
    const itemsCount = this.props.callHistoryState?.paging?.totalItemCount;
    return (
      <div className="w-100">
        <span className="pb-2 d-inline-block m-0" style={{ marginLeft: '21px' }}>
          Kết quả lọc: <b className="text-danger">{itemsCount || 0}</b>
        </span>
        {/* <Table striped bordered hover>
          <tbody> */}
        {callHistory?.length ? (
          callHistory.map((call) => this.renderCol(call))
        ) : (
          <Empty description={<span>Không có dữ liệu</span>} />
        )}
        {/* </tbody>
        </Table> */}
      </div>
    );
  }

  renderCol(call) {
    return (
      <Link to={ROUTER.CALL_DETAIL_REVIEWER + '/' + call.id} style={{ color: 'black' }}>
        <Row className="mt-1" style={{ padding: '0px 0px', borderRadius: '8px', marginBottom: 24 }}>
          <Col md className="bg item-hov">
            <Row>
              <Col md={12}>
                <b style={{ fontSize: 18 }}>{call.job_name || '--'}</b>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={5}>
                <p className="text-gray m-0" style={{ fontSize: '14px' }}>
                  Sale
                </p>
                <p className="m-0">{call.sale_name || '--'}</p>
              </Col>
              <Col md={4}>
                <p className="text-gray m-0" style={{ fontSize: '14px' }}>
                  Khách hàng
                </p>
                <p className="m-0">{call.customer_name || '--'}</p>
              </Col>
              <Col md={2} className="p-0">
                <p className="text-gray m-0" style={{ fontSize: '14px' }}>
                  Đánh giá
                </p>
                <p className="m-0 text-success">
                  {call.call_reviews.length ? (
                    call.call_reviews.map((item) => item.call_review_config.name).join(', ')
                  ) : (
                    <span style={{ color: 'red' }}>Chưa đánh giá</span>
                  )}
                </p>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <Row>
                  <Col md={5} className="p-0">
                    <p className="text-gray m-0" style={{ fontSize: '14px' }}>
                      Ngày
                    </p>
                    <p className="m-0">
                      {Intl.DateTimeFormat('vi-VN').format(new Date(call?.start_call_time || '--'))}
                    </p>
                  </Col>
                  <Col md={5} className="p-0">
                    <p className="text-gray m-0" style={{ fontSize: '14px' }}>
                      Thời gian gọi
                    </p>
                    <p className="m-0">{call?.call_duration || '--'} giây</p>
                  </Col>
                </Row>
              </Col>
              <br></br>
              <Col md={12}>
                <Row>
                  <Col md={5} className="p-0">
                    <p className="text-gray m-0" style={{ fontSize: '14px' }}>
                      Số điện thoại
                    </p>
                    <p className="m-0">{call.sale_phone || '--'}</p>
                  </Col>
                  <Col md={5} className="p-0">
                    <p className="text-gray m-0" style={{ fontSize: '14px' }}>
                      Tình trạng
                    </p>
                    <p className="m-0 text-success">{call.status_name || '---'}</p>
                  </Col>
                  <br></br>
                  <br></br>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Link>
    );
  }

  renderPagination() {
    const { paging } = this.props.callHistoryState;
    return (
      <Pagination
        paging={paging}
        handlePageChange={(page) => {
          const { id } = this.props.match.params;
          this.getData({ page: page, task_id: id });
        }}
      />
    );
  }

  renderBody() {
    return (
      <div className="content-wrapper">
        {this.renderTitle()}
        {this.renderField()}
        <Row className="mt-4">
          <Col md={12} sm={12}>
            {this.renderTable()}
          </Col>
        </Row>
        <Row>
          <Col md={12} className="d-flex justify-content-center justify-content-md-end">
            {this.renderPagination()}
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { isLoading } = this.props.callHistoryState;

    return (
      <>
        {isLoading && <Loading />}
        {this.renderBody()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  callHistoryState: state.callHistoryReducer,
});

const mapDispatchToProps = {
  getCallHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(JobDetailScreen));
