import { handleResult, getAxios } from '@constants/Api';

export const voucherService = {
  getVoucher: params => {
    return handleResult(
      getAxios.get(`evoucher/config`, {
        params,
      })
    );
  },
  createVoucher: payload => {
    return handleResult(getAxios.post(`evoucher/configV2`, payload));
  },
  updateVoucher: (payload, id) => {
    return handleResult(getAxios.patch(`evoucher/config/${id}`, payload));
  },
  deleteVoucher: payload => {
    return handleResult(getAxios.post(`evoucher/deleteEVoucher`, payload));
  },
  downloadExFile: () => {
    return getAxios.get('evoucher/samplefile', {
      responseType: 'blob',
      headers: { 'Content-Type': null },
    });
  },
  importExcelVoucher: payload => {
    return handleResult(
      getAxios.post(`evoucher/importExcelV2`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    );
  },
  detailEVoucher: id => {
    return handleResult(getAxios.get(`evoucher/config/${id}`));
  },
  // danh sách mã voucher chi tiết evoucher
  listVoucher: params => {
    return handleResult(getAxios.get(`evoucher`, { params }));
  },

  // todo: Chi tieest voucher
  detailVoucher: id => {
    return handleResult(getAxios.get(`evoucher/${id}`));
  },

  // danh sách mã quà tặng
  getGiftVoucher: params => {
    return handleResult(getAxios.get(`evoucher/gift`, { params }));
  },

  // danh sách mã quà tặng trong form evoucher
  getGiftVoucherV2: params => {
    return handleResult(getAxios.get(`evoucher/giftV2`, { params }));
  },
  // thêm quà tặng
  createGiftVoucher: payload => {
    return handleResult(getAxios.post(`evoucher/gift`, payload));
  },
  // sửa quà tặng
  updateGiftVoucher: (id, payload) => {
    return handleResult(getAxios.patch(`evoucher/gift/${id}`, payload));
  },
  // xoá quà tặng
  deleteGiftVoucher: payload => {
    return handleResult(getAxios.post(`evoucher/deleteEVoucherGift`, payload));
  },

  // check voucher code
  checkVoucherCode: payload => {
    return handleResult(getAxios.post(`evoucher/checkConfigCode`, payload));
  },

  // export excel voucher in detail evoucher
  exportExcelVoucher: params => {
    return handleResult(getAxios.get(`evoucher/export`, { params }));
  },

  // upload mẫu voucher
  uploadVoucher: payload => {
    return handleResult(getAxios.post(`evoucher/import`, payload));
  },
  uploadFile: payload =>
    handleResult(getAxios.post('media/uploadAll', payload)),
};

export function create_UUID() {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}
