import {
  Breadcrumb,
  Button,
  Descriptions,
  Modal,
  Table,
  Tabs,
  Timeline,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { STATUS_JOB } from '@constants/Constant';
import { primaryColor, primaryColorHover } from './Color';

export const TitleJobStatusStyle = styled.p`
  font-size: 20px;
  font-weight: 700;
  color: ${props => {
    switch (props.status) {
      case STATUS_JOB.WAITING:
        return 'red';
      case STATUS_JOB.PROCESSING:
        return primaryColor;
      case STATUS_JOB.SUCCESS:
        return 'blue';

      default:
        break;
    }
  }};
`;

export const TitleStyle = styled.h4`
  color: ${primaryColor};
`;

export const BreadcrumbAntStyle = styled(Breadcrumb)`
  padding: 5px 15px 0px;
`;

export const TableAntStyle = styled(Table)`
  padding-top: 2px;
  padding-bottom: 2px;

  td.ant-table-cell {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  th.ant-table-cell {
    padding: 8px;
    font-weight: 500;
    font-size: 16px;
  }

  colgroup col.ant-table-expand-icon-col {
    width: 32px;
  }

  + .ant-table-wrapper {
    margin-top: 12px;
  }
`;

export const TableBorderedAntStyle = styled(TableAntStyle)`
  //custom border
  th.ant-table-cell {
    border: 1px solid #efefef;
  }

  td.ant-table-cell {
    border: 1px solid #efefef;
  }
`;

export const JobNameInTableStyle = styled.p`
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  float: left;
  word-wrap: break-word;
  /* width: 100%; */
  /* margin-bottom: 2px; */
  &:hover {
    color: #52c41a;
  }

  /* giới hạn số dòng text */
  display: -webkit-box;
  max-height: 3.2rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  line-height: 1.6rem;
`;

export const TextLeftStyle = styled.p`
  text-align: left;
`;

export const ButtonSystemStyle = styled.button`
  background-color: ${props => {
    if (props.bgButtonColor) return props.bgButtonColor;
    return primaryColor;
  }};
  height: ${props => {
    if (props.height) return props.height;
    return '32px';
  }};
  color: ${props => {
    if (props.color) return props.color;
    return 'white';
  }};
  border: none;
  font-size: 16px;
  min-width: 100px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;

  &:hover {
    background-color: ${props => {
      if (props.bgButtonColorHover) return props.bgButtonColorHover;
      return primaryColorHover;
    }};
    cursor: ${props => {
      if (props.loading) return 'wait';
      return 'pointer';
    }};
  }

  + button {
    margin-left: 8px;
  }
`;

export const ButtonTransparentStyle = styled(Button)`
  border: 1px solid #d9d9d9;
  font-size: 16px;
  min-width: 80px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  padding: 5px 10px;
  background-color: transparent;

  &:hover {
    border: 1px solid #40a9ff;
    color: #40a9ff;
  }

  + button {
    margin-left: 12px;
  }
`;

export const AudioStyle = styled.audio`
  height: 35px;
  padding-top: 3px;
  &::-webkit-media-controls-play-button:hover {
    background-color: #f1f3f4;
  }
`;

export const DescriptionCustomInteractionHistory = styled(Descriptions)`
  .ant-descriptions-item {
    padding-bottom: 10px;
    font-weight: 600;
    text-align: start;
  }

  .ant-descriptions-item-label {
    align-items: center;
  }
`;

export const TimeLineStyle = styled(Timeline)`
  .ant-timeline-item {
    padding: 0;
  }

  .ant-timeline-item-tail {
    position: absolute;
    top: 10px;
    left: 4px;
    height: calc(100% - 10px);
    border-left: 2px dotted #b3b3b3;
  }
`;

export const DividerTextStyle = styled.div`
  color: white;
  background-color: ${primaryColor};
  padding: 0 10px;
  border-radius: 4px;
`;

export const TabsAntStyled = styled(Tabs)`
  width: 100%;
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${primaryColor};
    font-size: 16px;
    font-weight: 600;
  }

  .ant-tabs-tab {
    &:hover {
      color: ${primaryColor};
    }
  }
`;

export const FontAwesomeIconStyle = styled(FontAwesomeIcon)`
  &&& {
    + .svg-inline--fa {
      margin-left: 10px;
    }
  }
`;

export const OneLineTextStyled = styled.div`
  /* giới hạn số dòng text === 1 */
  display: -webkit-box;
  max-height: 3.2rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  line-height: 1.6rem;
`;

export const ScrollStyle = styled.div`
  ::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #a59a9a;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(199, 199, 199, 0.3);
    background-color: #f5f5f5;
  }
`;

export const ModalStyle = styled(Modal)`
  margin-top: ${props => {
    if (props.marginTop) return props.marginTop;
    return '';
  }};
  .ant-modal-close-x {
    display: ${props => {
      if (props.buttonCloseX) return props.buttonCloseX;
      return 'block';
    }};
  }
  .ant-modal-header {
    padding: 10px 24px 5px 24px;
  }
  .ant-typography {
    margin: 0;
  }
`;

export const TagHyperlink = styled.span`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: blue;
  }
`;

export const IframeStyled = styled.iframe`
  width: 100%;
  height: ${props => (props.height ? props.height : '100%')};
  border: none;

  overflow: auto;
`;

export const TabsStyled = styled(Tabs)`
  width: 100%;
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${primaryColor};
    font-size: 16px;
    font-weight: 600;
  }

  .ant-tabs-tab {
    &:hover {
      color: ${primaryColor};
    }
  }
`;

