import React from 'react';
import {
  Row,
  Col,
  FormControl,
  Button,
  Modal,
  FormGroup,
  Form,
} from 'react-bootstrap';

const CardHeader = ({
  title,
  actions,
  actionOptions = { className: 'text-center text-md-right px-0' },
  titleOptions = { className: 'text-left text-md-left px-0' },
  ...props
}) => {
  let titleColSpan = 12;
  const TitleElement = title && <h3>{title}</h3>;
  const ActionElements = actions && (
    <Col md={5} sm={12} {...actionOptions}>
      {actions}
    </Col>
  );
  if (ActionElements) {
    titleColSpan = 7;
  }
  const CustomerElement = (TitleElement || ActionElements) && (
    <Row>
      <Col md={titleColSpan} sm={12} {...titleOptions}>
        {TitleElement}
      </Col>
      {ActionElements}
    </Row>
  );

  return (
    <div className="mb-2">
      {CustomerElement}
      {props.children}
    </div>
  );
};

const CardContent = props => {
  return <div>{props.children}</div>;
};

const CardFooter = props => {
  return <div>{props.children}</div>;
};

const CardView = props => {
  return (
    <div className="bg">
      <div className="w-100 table-wrap">{props.children}</div>
    </div>
  );
};

CardView.Header = CardHeader;
CardView.Content = CardContent;
CardView.Footer = CardFooter;

export default CardView;
