import React from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';
import { randomColor } from '@constants/funcHelper';

TagCustom.propTypes = {
  tagName: PropTypes.string,
  tagColor: PropTypes.string,
};

export default function TagCustom(props) {
  return (
    <Tag
      className="tag"
      style={{
        margin: '5px 0 5px 10px',
        paddingLeft: 2,
        position: 'relative',
        backgroundColor: '#EFEFEF',
        border: 'none',
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomRightRadius: '4px',
        borderTopRightRadius: '4px',
        fontWeight: 'normal',
        display: 'block',
        //css width theo text
        width: 'min-content',
      }}
      key={1}
      color="default"
    >
      <div className="d-flex justify-content-around align-items-center">
        <div
          style={{
            // backgroundColor: randomColor(props.tagName[0].charAt(0)?.toUpperCase()),
            backgroundColor: props.tagColor,
            borderRadius: '50%',
            width: '10px',
            height: '10px',
            marginRight: '3px',
          }}
        ></div>
        {props.tagName}
      </div>
    </Tag>
  );
}
