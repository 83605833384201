import React, { Component } from 'react';
import Pagination from 'react-js-pagination';
import reactotron from 'reactotron-react-js';
import { connect } from 'react-redux';
class Pagi extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { paging } = this.props[this.props.screen];
    return (
      <div>
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          hideDisabled
          activePage={paging?.page}
          itemsCountPerPage={paging?.limit}
          totalItemsCount={paging?.totalItemCount || 0}
          pageRangeDisplayed={5}
          hideNavigation
          onChange={(page) => this.props.handlePageChange(page)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  telesaleState: state.TelesaleReducer,
  rejectedRegisterState: state.RejectedRegisterReducer,
  customerGroupState: state.customerGroupReducer,
  customerState: state.customerReducer,
});

export default connect(mapStateToProps, null)(Pagi);
