import React from 'react';
import PropTypes from 'prop-types';

const ContentHeader = ({ children, ...props }) => {
  return (
    <div className="content-header" {...props}>
      {children}
    </div>
  );
};

const ContentFooter = ({ children, ...props }) => {
  return (
    <div className="content-header" {...props}>
      {children}
    </div>
  );
};

const ContentBody = ({ children, ...props }) => {
  return <div>{children}</div>;
};

function Content({ children, ...props }) {
  return (
    <div className="content-wrapper" style={{ padding: '20px' }}>
      {children}
    </div>
  );
}

Content.Header = ContentHeader;
Content.Footer = ContentFooter;
Content.Body = ContentBody;

Content.propTypes = {};

export default Content;
