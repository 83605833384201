export const NAME_OF_ATTENDANCE_TYPE = [
  { id: 1, name: 'Check in buổi sáng' },
  { id: 2, name: 'Check out buổi chiều' },
];

export const TIME_KEEPING_TYPE = [
  { id: 1, name: 'Muộn' },
  { id: 0, name: 'Đúng giờ' },
  { id: 2, name: 'Sớm' },
  { id: 3, name: 'Không điểm danh' },
];

export const IS_LATE = {
  DUNG_GIO: 0,
  MUON: 1,
  SOM: 2,
  KHONG_DIEM_DANH: 3,
};

export const CONDITION = [
  { id: 0, name: 'Địa chỉ không trùng khớp' },
  { id: 1, name: 'Địa chỉ trùng khớp' },
];

export const CONDITION_STATUS = {
  KHONG_TRUNG_KHOP: 0,
  TRUNG_KHOP: 1,
};
