import moment from 'moment';

export const DATE_FORMAT = {
  SHORT_DATE: 'DD/MM/YYYY',
  SHORT_DATE_TIME: 'DD/MM/YYYY HH:mm',
  SHORT_TIME_DATE: 'HH:mm DD/MM/YYYY',
};
export function getDateRangeString(
  startDate,
  endDate,
  options = {
    fromFormat: null,
    toFormat: DATE_FORMAT.SHORT_DATE,
    invalidRange: 'Chưa cập nhật',
    invalidDate: 'Chưa cập nhật',
    separator: '-',
  }
) {
  const start = (startDate && moment(startDate)) || null;
  const end = (endDate && moment(endDate)) || null;
  if ((!start || !start.isValid()) && (!end || !end.isValid())) {
    return options.invalidRange;
  }
  return [
    (start.isValid() && start.format(options.toFormat)) || options.invalidDate,
    (end.isValid() && end.format(options.toFormat)) || options.invalidDate,
  ].join(options.separator);
}

export function formatDateString(dateString, format = DATE_FORMAT.SHORT_DATE, defaultString = '--') {
  const date = (dateString && moment(dateString)) || null;
  if (!date || !date.isValid()) {
    return defaultString;
  }
  return date.format(format);
}

export function formatDateTimeString(dateString, format = DATE_FORMAT.SHORT_DATE_TIME, defaultString = '--') {
  return formatDateString(dateString, format, defaultString);
}

export function formatTimeDateString(dateString, format = DATE_FORMAT.SHORT_TIME_DATE, defaultString = '--') {
  return formatDateString(dateString, format, defaultString);
}

export function getMsSinceMidnight(d) {
  const e = new Date(d);
  return d - e.setHours(0, 0, 0, 0);
}
