import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '@styles/RegisterRequest.css';
import { Row, Col, Button } from 'react-bootstrap';
import { TYPE_MEDIA, SALE_STATUS, SALE_STATUS_ALIAS, STRING } from '@constants/Constant';
import Loading from '@src/components/Loading';
import { requestSaleDetail } from '@constants/Api';
import { getItemFromId } from '@utils/getItemFromId';
import ReactPlayer from 'react-player';
import { notifyFail } from '@utils/notify';
import { Avatar, Image } from 'antd';
import { toDateString } from '../../../Utils/ToDateString';

class RegisterRequestRejectedDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBar: {
        searchKey: '',
        status: '0',
        city: '0',
      },
      detail: {},
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    this.setState({ isLoading: true });
    const { id } = this.props.match.params;
    let profile = '',
      front_id = '',
      back_id = '',
      video = '';
    try {
      const res = await requestSaleDetail({ id: id });
      if (res.data.user_sale_media) {
        res.data.user_sale_media.forEach((media) => {
          if (media.type === TYPE_MEDIA.PROFILE_IMAGE) profile = media.url;
          if (media.type === TYPE_MEDIA.BACK_IMAGE) back_id = media.url;
          if (media.type === TYPE_MEDIA.FRONT_IMAGE) front_id = media.url;
          if (media.type === TYPE_MEDIA.VIDEO) video = media.url;
        });
      }
      this.setState({
        detail: res.data,
        isLoading: false,
        front_id: front_id,
        back_id: back_id,
        profile: profile,
        video: video,
      });
    } catch (err) {
      notifyFail(err.msg);
      this.setState({ isLoading: false });
    }
  }

  renderBody() {
    return (
      <div className="content-wrapper">
        <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
          <Col md className="bg" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
            {this.renderInfo()}
            {this.renderLayout()}
          </Col>
        </Row>
      </div>
    );
  }

  renderLayout() {
    const histories = this.state.detail.user_sale_status_histories;
    return <>{histories?.length > 0 && histories?.map((history) => this.renderHistory(history))}</>;
  }

  renderHistory(history) {
    const { df_sale_status_id } = history;
    if (df_sale_status_id === SALE_STATUS_ALIAS.ADMIN_REJECT) {
      return <>{this.renderReasonResult(history)}</>;
    }

    if (df_sale_status_id === SALE_STATUS_ALIAS.PROFESSION_MANAGER_ACCEPT) {
      return <>{this.renderResult('TP chuyên môn', 'passed', history)}</>;
    }
    if (df_sale_status_id === SALE_STATUS_ALIAS.PROFESSION_MANAGER_REJECT) {
      return (
        <>
          {this.renderResult('TP chuyên môn', 'failed', history)}
          {this.renderReasonResult(history)}
        </>
      );
    }
    if (df_sale_status_id === SALE_STATUS_ALIAS.HR_REJECT) {
      return (
        <>
          {this.renderResult('TP tuyển dụng', 'failed', history)}
          {this.renderReasonResult(history)}
        </>
      );
    }
  }

  // renderHeaderButton() {
  //   return (
  //     <Row className="justify-content-center justify-content-md-end">
  //       <Button variant="warning" className="text-light">
  //         Xuất kết quả
  //       </Button>
  //     </Row>
  //   );
  // }

  renderInfo() {
    const sale = this.state.detail;
    const { profile, front_id, back_id, video } = this.state;
    const saleStatus = getItemFromId(SALE_STATUS, sale.user_sale_info?.user_sale_status_id);
    return (
      <div className="content-header personal-info">
        <Row className="title pl-md-3">
          <Avatar src={sale?.user_sale_info?.profile_image} size={50} className="bg-blue text-white">
            {sale?.user_sale_info?.profile_image !== '' ? null : sale?.full_name[0].toUpperCase()}
          </Avatar>
          <Col md={9} className="name">
            <h3> {sale && sale.full_name} </h3>
          </Col>
          {/* <Col md={2}>{this.renderHeaderButton()}</Col> */}
        </Row>
        <br />
        <Row className="info">
          <Col md={4} className="mx-md-4 mx-0 mb-3 mb-md-0">
            {video ? (
              <ReactPlayer url={video} className="w-100" controls={true} width={640} height={200} />
            ) : (
              <img src={require('@assets/novideo.png')} style={{ width: '335px', height: 'auto' }}></img>
            )}
          </Col>
          <Col md={7}>
            <Row>
              <Col>
                <label>{`${STRING.phoneNumber}:`}</label>
              </Col>
              <Col>
                <span>{sale && sale.phone}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>{`${STRING.email}:`}</label>
              </Col>
              <Col>
                <span>{sale && sale.email}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>{`${STRING.dob}:`}</label>
              </Col>
              <Col>
                <span>{sale?.user_sale_info?.dob && toDateString(sale.user_sale_info.dob)}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>{`${STRING.gender}:`}</label>
              </Col>
              <Col>
                <span>{sale?.user_sale_info && sale.user_sale_info.gender == 2 ? 'Nam' : 'Nữ'}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>{`${STRING.city}:`}</label>
              </Col>
              <Col>
                <span>{sale?.user_sale_info && sale.user_sale_info.province?.name}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>{`${STRING.identicalId}:`}</label>
              </Col>
              <Col>
                <span>{sale?.user_sale_info && sale.user_sale_info.id_number}</span>
              </Col>
            </Row>
            <Row>
              <Col></Col>
              <Col md={6} className="p-0 mb-3">
                <Image width={150} src={front_id && front_id} style={{ cursor: 'pointer' }} />
                <Image width={150} src={back_id && back_id} style={{ cursor: 'pointer', marginLeft: 10 }} />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>{`${STRING.income}:`}</label>
              </Col>
              <Col>
                <span>{(sale?.user_sale_info && sale.user_sale_info.expected_salary) || '--'} VNĐ</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>{`${STRING.experience}:`}</label>
              </Col>
              <Col>
                <span>{(sale?.user_sale_info && sale.user_sale_info.work_experience) || '--'}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>{`${STRING.profile}:`}</label>
              </Col>
              <Col>
                {sale?.user_sale_info && sale.user_sale_info?.cv_url ? (
                  <a
                    href={sale?.user_sale_info && sale.user_sale_info?.cv_url}
                    className="link-infor"
                    style={{ color: 'red' }}
                    target="_blank"
                  >
                    Click để xem
                  </a>
                ) : (
                  '--'
                )}
                {/* <a href={sale?.user_sale_info && sale.user_sale_info.cv_url}>Click here to read</a> */}
              </Col>
            </Row>
            <Row>
              <Col>
                <label>{`${STRING.status}:`}</label>
              </Col>
              <Col>
                <span className="text-danger">{saleStatus && saleStatus.name}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row></Row>
      </div>
    );
  }

  renderResult(role, color, assessment) {
    return (
      <div className={'content-header assessment ' + color}>
        <Row className="title">
          <Col md={9} className="name">
            <h5> Đánh giá ứng viên - {role} </h5>
          </Col>
        </Row>
        <Row className="content">
          <Col md={5}>
            <Row>
              <Col md={3} xs={6}>
                <label>Chất giọng</label>
              </Col>
              <Col md={8} xs={6}>
                <div className="alert alert-light h-50">{(assessment && assessment.review_voice) || '###'}</div>
              </Col>
            </Row>
            <Row>
              <Col md={3} xs={6}>
                <label>Sự tự tin</label>
              </Col>
              <Col md={8} xs={6}>
                <div className="alert alert-light h-50">{(assessment && assessment.review_comfident) || '###'}</div>
              </Col>
            </Row>
          </Col>
          <Col md={5}>
            <Row>
              <Col md={3} xs={6}>
                <label>Trôi chảy</label>
              </Col>
              <Col md={8} xs={6}>
                <div className="alert alert-light h-50">{(assessment && assessment.review_fluent) || '###'}</div>
              </Col>
            </Row>
            <Row>
              <Col md={3} xs={6}>
                <label>Khác</label>
              </Col>
              <Col md={8} xs={6}>
                <div className="alert alert-light h-50">{(assessment && assessment.review_other) || '###'}</div>
              </Col>
            </Row>
          </Col>
          <Col md={2} className="d-flex justify-content-center align-items-baseline">
            {color ? (
              color === 'passed' ? (
                <i className="fas fa-check-circle status-icon"></i>
              ) : (
                <i className="fas fa-times-circle status-icon"></i>
              )
            ) : null}
          </Col>
        </Row>
      </div>
    );
  }

  renderReasonResult(history) {
    return (
      <Row className="my-3 mb-5">
        <Col xs={4} md={2} className="text-center">
          <label className="text-red">Lý do từ chối</label>
        </Col>
        <Col xs={8} md={10}>
          <textarea
            className="form-control"
            rows="6"
            placeholder="Ghi chú"
            onChange={(e) => this.handleInputChange('reason', e.target.value)}
            value={history.reason}
            disabled={true}
          ></textarea>
          {/* <span className="text-red">{history.reason}</span> */}
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <>
        {this.state.isLoading ? <Loading /> : null}

        {this.renderBody()}
      </>
    );
  }
}

export default withRouter(RegisterRequestRejectedDetail);
