import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import { VietnameseString } from '@constants/VietnameseString';
import { dateFormatConversion } from '@constants/funcHelper';
import Wrapper from '@components/ui/wrapper/Wrapper';
import { requestJobDetail, requestJobDetailSimple } from '@constants/Api';
import Customers from './component/Customers';
import General from './component/General';

import { BreadcrumbAntStyle, TabsAntStyled } from '@styles/Style';
import { primaryColor } from '@styles/Color';
import { ROUTER } from '@constants/Constant';

const formatDateTime = 'YYYY/MM/DD';
const noneNavigation = [VietnameseString.average];
function convertTimeWhenNavigating(params, record) {
  let from = '';
  let to = '';

  if (record.title === VietnameseString.total_number_of_calls) {
    from = params?.from;
    to = params?.to;
  } else {
    from = dateFormatConversion(record.title);
    to = dateFormatConversion(record.title);
  }

  return { from, to };
}

function Result() {
  const { id: jobId } = useParams();
  const history = useHistory();

  const [jobInfo, setJobInfo] = React.useState({});
  const [tab, setTab] = React.useState('general');

  const userInfo = useSelector(store => store.userReducer.data);

  const [generalParams, setGeneralParams] = React.useState({
    telesale_id: userInfo.user_id,
    from: moment(new Date()).startOf('week').format(formatDateTime),
    to: moment(new Date()).format(formatDateTime),
    job_id: '',
    sub_job_id: [jobId],
    department_id: [],
  });

  const [customerPaging, setCustomerPaging] = React.useState({
    total: 0,
    current: 1,
    pageSize: 12,
  });

  const [customerParams, setCustomerParams] = React.useState({
    telesale_id: userInfo.user_id,
    job_id: '',
    group_id: '',
    page: customerPaging.current,
    status: '',
    call_status_id: [],
    fromDate: '',
    toDate: '',
    list_customer_sale: true, //key mặc định theo api yêu cầu :))
  });

  const handleChangeGeneralParams = React.useCallback(function (data) {
    setGeneralParams(prev => ({
      ...prev,
      ...data,
    }));
  }, []);

  const handleChangeCustomerParams = React.useCallback(function (data) {
    setCustomerParams(prev => ({
      ...prev,
      ...data,
      page: 1,
    }));
  }, []);

  const handleChangeCustomerPaging = React.useCallback(function (data) {
    setCustomerPaging(data);
  }, []);

  async function getJobInfo(jobId) {
    try {
      const { data } = await requestJobDetailSimple({ id: jobId });
      setJobInfo(data);
    } catch (error) {
      console.log('Error getJobInfo: ', { error });
    }
  }

  React.useEffect(() => {
    getJobInfo(jobId);
  }, [jobId]);

  return (
    <div className="content-wrapper">
      <BreadcrumbAntStyle>
        <BreadcrumbAntStyle.Item>Danh sách công việc</BreadcrumbAntStyle.Item>
      </BreadcrumbAntStyle>

      <Wrapper>
        <HeadingStyled>Kết quả công việc</HeadingStyled>

        <TabsAntStyled
          type="card"
          destroyInactiveTabPane={true}
          activeKey={tab}
          onChange={activeKey => {
            setTab(activeKey);
          }}
        >
          <TabsAntStyled.TabPane tab="Tổng quan" key="general">
            <General
              jobInfo={jobInfo}
              generalParams={generalParams}
              handleChangeGeneralParams={handleChangeGeneralParams}
              handleNavigatingToCustomersTab={({
                generalParams,
                record,
                callStatus,
              }) => {
                if (generalParams.telesale_id) {
                  if (noneNavigation.includes(record.title)) return;

                  const { from, to } = convertTimeWhenNavigating(
                    generalParams,
                    record
                  );

                  setCustomerParams(prev => ({
                    ...prev,
                    call_status_id:
                      callStatus !== undefined ? [callStatus] : [],
                    status: 4, //Đã gọi
                    fromDate: from,
                    toDate: to,
                    page: 1,
                  }));
                  setTab('customers');
                }
              }}
              handleNavigatingToCallList={({
                generalParams,
                record,
                dataQuestion,
                answer,
              }) => {
                if (noneNavigation.includes(record.title)) return;
                const { from, to } = convertTimeWhenNavigating(
                  generalParams,
                  record
                );
                const dataSearch = {
                  // job_id: [...params.sub_job_id],
                  // telesale_id: [...params.telesale_id],
                  job_form_question_id: [dataQuestion.question.id],
                  job_form_answers_id: [Number(answer?.id)],
                  fromDate: from,
                  toDate: to,
                };
                const searchParams = new URLSearchParams();
                for (const [key, value] of Object.entries(dataSearch)) {
                  searchParams.set(key, JSON.stringify(value));
                }

                history.push({
                  pathname: ROUTER.CALL_HISTORY_SALE,
                  search: searchParams.toString(),
                });
              }}
            />
          </TabsAntStyled.TabPane>

          <TabsAntStyled.TabPane tab="Danh sách khách hàng" key="customers">
            <Customers
              jobInfo={jobInfo}
              customerParams={customerParams}
              customerPaging={customerPaging}
              handleChangeCustomerParams={handleChangeCustomerParams}
              handleChangeCustomerPaging={handleChangeCustomerPaging}
              handleChangePage={page =>
                setCustomerParams(prev => ({
                  ...prev,
                  page,
                }))
              }
            />
          </TabsAntStyled.TabPane>
        </TabsAntStyled>
      </Wrapper>
    </div>
  );
}

const HeadingStyled = styled.h2`
  color: ${primaryColor};
  margin-bottom: 12px;
`;

export default Result;
