import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import Wrapper from '@components/ui/wrapper/Wrapper';
import { requestCreateOpportunity, requestListProduct } from '@constants/Api';
import { enterNumbersOnly, formatPrice } from '@constants/funcHelper';
import { UploadStyled } from '@screens/Sale/Opportunity/components/styled';
import { createFormDataNew } from '@utils/createFormData';
import { notifyFail, notifySuccess } from '@utils/notify';
import {
  Button,
  DatePicker,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Table,
} from 'antd';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { requestGetOpportunities } from 'src/network/web_sale/GeneralApi';
import moment from 'moment';
import '../style.css';

const ModalAddOpportunity = props => {
  const {
    visible,
    setVisible,
    idCustomer,
    callId,
    jobId,
    getData,
    customerName,
    setUpdateCustomerOppo,
  } = props;
  const PlusCircleIcon = () => (
    <svg
      width="20px"
      height="20px"
      fill="#ef8737"
      viewBox="0 0 1024 1024"
      style={{ marginBottom: 6 }}
    >
      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z" />
    </svg>
  );
  const DeleteIcon = () => (
    <svg width="20px" height="20px" fill="currentColor" viewBox="0 0 1024 1024">
      <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z" />
    </svg>
  );
  const { TextArea } = Input;
  const { Option } = Select;
  const param = useParams();
  const [dataProduct, setDataProduct] = useState([]);
  const [addProduct, setAddProduct] = useState(true);
  const [changeProduct, setChangeProduct] = useState(true);
  const [idProducts, setIdProducts] = useState([]);
  const [fileList, setFileList] = useState();
  const [listDelete, setListDelete] = useState([]);
  const [listFiles, setListFile] = useState([]);
  const [listProduct, setListProduct] = useState();
  const [opporList, setOpporList] = useState();
  const [autoConvert, setAutoConvert] = useState();
  const dataSalesRef = useRef();
  const dataRevenueRef = useRef();
  const dataSource = [{}];
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState({
    status: '',
    opportunity_category_id: '',
    files: [],
    call_id: callId || '',
    customer_id: '',
    revenue: '',
    sales: '',
    note: '',
    job_id: jobId ? jobId : '',
    opportunity_data: [
      {
        commission: '',
        product_id: '',
        name: '',
        amount: '',
        revenue: '',
        note: '',
        product_exp_date: '',
      },
    ],
  });

  useEffect(() => {
    getOpportunityList();
  }, []);

  useEffect(() => {
    getProduct();
    setChangeProduct(true);
  }, [visible]);
  const getOpportunityList = async (callId, customerId) => {
    // alert(33333);
    try {
      const res = await requestGetOpportunities({
        call_id: callId,
        customer_id: customerId,
      });
      // const data = res.data.map(item => item?.auto_convert);
      setOpporList(res.data);
    } catch (error) {}
  };

  const getProduct = async dataSource => {
    try {
      const res = await requestListProduct();
      const options = res?.data?.map(item => ({
        ...item,
        label: item.name,
        value: item.id,
        commission: item?.commission,
      }));
      if (options) {
        setListProduct(options);
      }
    } catch (err) {
      console.log('err: ', err);
    }
  };

  const createOppoturnity = async () => {
    setIsLoading(true);
    try {
      let dataPush = { ...params };
      dataPush.customer_id = idCustomer || param.id;
      dataPush.job_id = jobId || null;
      dataPush.call_id = callId || '';
      dataPush.status = params?.status;
      dataPush.revenue = params?.revenue || 0;
      dataPush.sales = params?.sales || 0;
      dataPush.opportunity_data = changeProduct
        ? params.opportunity_data
            ?.filter(
              item =>
                item?.product_id ||
                item?.name ||
                item?.amount ||
                item?.revenue ||
                item?.note
            )
            .map(item => ({
              product_id: item.product_id,
              name: '1',
              amount: item.amount,
              revenue: item.revenue || 0,
              note: item.note,
              product_exp_date: item.product_exp_date,
            }))
        : [];
      const form = createFormDataNew(dataPush);
      for (let i = 0; i < fileList?.length; i++) {
        if (i > 10) {
          notifyFail('Đính kèm tối đa 10 file');
        } else {
          form.append('files', fileList[i]?.originFileObj);
        }
      }
      if (dataPush?.sales > 9000000000000000000) {
        notifyFail('Đã quá giới hạn doanh số!');
        return;
      }
      if (dataPush?.revenue > 9000000000000000000) {
        notifyFail('Đã quá giới hạn doanh thu!');
        return;
      } else {
        if (
          (dataPush.revenue &&
            !Number(dataPush.revenue) &&
            !dataPush.revenue?.startsWith('0')) ||
          (dataPush.sales &&
            !Number(dataPush.sales) &&
            !dataPush.sales?.startsWith('0'))
        ) {
          if (!Number(dataPush.revenue)) {
            notifyFail('Doanh thu chỉ được nhập số!');
          } else {
            notifyFail('Doanh số chỉ được nhập số!');
          }
        } else {
          const dataOpportunity = { ...dataPush };
          dataOpportunity.fileList = fileList || [];
          mixpanel.track(`Create opportunity: ${customerName}`, {
            dataOpportunity,
          });
          const res = await requestCreateOpportunity(form);
          notifySuccess(res.msg);
          if (res.code === 1) {
            setChangeProduct(false);
            setAutoConvert(undefined);
            setIdProducts('');
            setParams({
              ...params,
              opportunity_category_id: '',
              revenue: '',
              sales: '',
              note: '',
              opportunity_data: [],
            });
          }
          setVisible(false);
          // getData();
          setUpdateCustomerOppo(true);
        }
      }
    } catch (error) {
      notifyFail(error.msg);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = key => {
    setAddProduct(true);
    const valueIdProduct = dataProduct?.map(item => item?.product_id);
    let valueDelete = dataProduct.filter((item, index) => {
      let valueIdProductDelete = valueIdProduct.filter(
        items => items !== item?.product_id
      );
      setIdProducts(valueIdProductDelete);
      if (index !== key) {
        return item;
      }
    });
    const CommissionTotal = valueDelete.map(item => ({
      commissionTotal: (item.commission * item.revenue) / 100,
    }));
    if (dataSalesRef.current) {
      dataSalesRef.current.value = formatPrice(_.sumBy(valueDelete, 'revenue'));
      dataRevenueRef.current.value = formatPrice(
        _.sumBy(valueDelete, 'revenue')
      );
    }
    setDataProduct(valueDelete);
    setIdProducts(
      valueDelete.map(item => {
        return item.product_id;
      })
    );
    setParams({
      ...params,
      opportunity_data: valueDelete,
      sales: _.sumBy(valueDelete, 'revenue'),
      revenue: _.sumBy(valueDelete, 'revenue'),
      epoint: _.sumBy(CommissionTotal, 'commissionTotal'),
    });
  };

  const handleAdd = () => {
    const newData = {
      product_id: '',
      amount: 0,
      revenue: 0,
      note: '',
    };
    setDataProduct([...dataProduct, newData]);
  };

  const columns = [
    {
      width: '15%',
      title: 'Sản phẩm',
      dataIndex: 'product_id',
      editable: true,
      render: (value, record, index) => {
        return (
          <>
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Sản phẩm"
              optionFilterProp="children"
              defaultValue={record?.name}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              onClick={() => {
                getProduct(dataSource);
              }}
              onChange={value => {
                setChangeProduct(true);
                setAddProduct(true);
                let values = value;
                const arr = [...dataProduct];
                const check = listProduct.find(p => p.id === value);
                const new_data_record = {
                  ...record,
                  product_id: values,
                  commission: check.commission,
                  name: check.name,
                };
                arr[index] = new_data_record;
                setIdProducts(
                  arr.map(item => {
                    return item.product_id;
                  })
                );
                setDataProduct(arr);
                setParams({
                  ...params,
                  opportunity_data: arr,
                });
              }}
            >
              {listProduct
                ? listProduct.map((item, index) => {
                    const checkDisable = idProducts.includes(item?.id);
                    return (
                      <>
                        {checkDisable ? (
                          ''
                        ) : (
                          <Option key={index} value={item?.id}>
                            {item?.name}
                          </Option>
                        )}
                      </>
                    );
                  })
                : ''}
            </Select>
          </>
        );
      },
    },
    {
      width: '20%',
      title: 'Số lượng',
      dataIndex: 'amount',
      editable: true,
      render: (value, record, index) => {
        return (
          <>
            <Input
              placeholder="Số lượng"
              allowClear
              value={value === 0 ? 0 : formatPrice(value)}
              onChange={e => {
                setChangeProduct(true);
                if (e.target.value.replaceAll(',', '') < 999999) {
                  let values = enterNumbersOnly(e.target.value);
                  const arr = [...dataProduct];
                  const new_data_record = {
                    ...record,
                    amount: parseInt(values === '' ? 0 : values),
                  };
                  arr[index] = new_data_record;
                  setDataProduct(arr);

                  setParams({ ...params, opportunity_data: arr });
                } else {
                  notifyFail('Vui lòng nhập số lượng tối đa 999999');
                }
              }}
            />
          </>
        );
      },
    },
    {
      width: '20%',
      title: 'Thành tiền',
      dataIndex: 'revenue',
      editable: true,
      render: (value, record, index) => {
        return (
          <>
            <Input
              placeholder="Thành tiền"
              allowClear
              value={value === 0 ? 0 : formatPrice(value)}
              onChange={e => {
                setChangeProduct(true);
                let values = enterNumbersOnly(e.target.value);
                const arr = [...dataProduct];
                const new_data_record = {
                  ...record,
                  revenue: parseInt(values === '' ? 0 : values),
                  epoint:
                    (parseInt(values === '' ? 0 : values) * record.commission) /
                    100,
                };
                arr[index] = new_data_record;
                setDataProduct(arr);
                if (dataSalesRef.current) {
                  dataSalesRef.current.value = formatPrice(
                    _.sumBy(arr, 'revenue')
                  );
                  dataRevenueRef.current.value = formatPrice(
                    _.sumBy(arr, 'revenue')
                  );
                  // dataEpointRef.current.value = formatPrice(_.sumBy(arr, 'epoint'));
                }
                setParams({
                  ...params,
                  sales: _.sumBy(arr, 'revenue'),
                  revenue: _.sumBy(arr, 'revenue'),
                  epoint: _.sumBy(arr, 'epoint') ? _.sumBy(arr, 'epoint') : 0,
                  opportunity_data: arr,
                  // list_product_delete: [...idProduct],
                });
              }}
            />
          </>
        );
      },
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'product_exp_date',
      width: '25%',
      render: (value, record, index) => {
        return (
          <>
            <DatePicker
              inputReadOnly
              style={{ width: '100%' }}
              onChange={time => {
                setChangeProduct(true);
                let values = time && moment(time).format('YYYY-MM-DD');
                const arr = [...dataProduct];
                const new_data_record = {
                  ...record,
                  product_exp_date: values,
                };
                arr[index] = new_data_record;
                setDataProduct(arr);
                setParams({
                  ...params,
                  opportunity_data: arr,
                });
              }}
              placeholder="Ngày hết hạn"
              format="DD/MM/YYYY"
              value={value && moment(value)}
            />
          </>
        );
      },
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      width: '25%',
      editable: true,
      render: (value, record, index) => {
        return (
          <>
            <TextArea
              style={{ marginTop: 22 }}
              showCount
              maxLength={2000}
              autoSize={{ maxRows: 3 }}
              placeholder="Ghi chú"
              value={value}
              onChange={e => {
                setChangeProduct(true);
                let values = e.target.value;
                const arr = [...dataProduct];
                const new_data_record = {
                  ...record,
                  note: values,
                };
                arr[index] = new_data_record;
                setDataProduct(arr);
                setParams({
                  ...params,
                  opportunity_data: arr,
                });
              }}
            />
          </>
        );
      },
    },
    {
      width: 30,
      title: '',
      dataIndex: 'operation',
      render: (value, record, index) => (
        <Button
          type="text"
          style={{ color: 'red' }}
          icon={<DeleteIcon />}
          onClick={() => {
            handleDelete(index);
          }}
        />
      ),
    },
  ];

  return (
    <Modal
      destroyOnClose
      visible={visible}
      onCancel={() => {
        setVisible(false);
        setAutoConvert('');
        setIdProducts('');
        setAutoConvert(undefined);
        setParams({
          ...params,
          opportunity_category_id: '',
          revenue: '',
          sales: '',
          note: '',
          opportunity_data: [],
        });
      }}
      okText="Lưu"
      cancelText="Huỷ"
      onOk={() => {
        if (autoConvert === undefined) {
          notifyFail('Vui lòng chọn loại cơ hội!');
        } else {
          if (autoConvert === 0 && !dataProduct.length) {
            notifyFail('Bạn phải chọn ít nhất 1 sản phẩm để tạo cơ hội!');
          } else {
            if (!addProduct) {
              notifyFail('Bạn phải chọn ít nhất 1 sản phẩm để tạo cơ hội!');
            } else {
              createOppoturnity();
            }
          }
        }
      }}
      okButtonProps={{ disabled: isLoading ? true : false }}
      title="Tạo cơ hội"
      width="70vw"
    >
      <Spin spinning={isLoading}>
        <div>
          <Wrapper style={{ padding: 0, margin: '0 20px' }}>
            <Row style={{ paddingBottom: 15 }}>
              <b style={{ fontSize: 18, width: 300, color: '#ef8737' }}>
                Loại cơ hội
              </b>
              <div>
                <Select
                  bordered
                  style={{ width: 400 }}
                  placeholder="Loại cơ hội"
                  onChange={value => {
                    const checkTypeOppo = opporList.find(p => p.id === value);
                    console.log(
                      'opporList',
                      checkTypeOppo,
                      opporList,
                      listProduct
                    );
                    const defaultProduct = {
                      product_id: listProduct?.[0]?.value,
                      name: listProduct?.[0]?.label,
                      amount: 0,
                      revenue: 0,
                      note: '',
                    };
                    const products = checkTypeOppo?.auto_convert
                      ? []
                      : [defaultProduct];
                    setAutoConvert(checkTypeOppo?.auto_convert);
                    setDataProduct(products);
                    setParams({
                      ...params,
                      opportunity_category_id: value,
                      revenue: '',
                      sales: '',
                      note: '',
                      opportunity_data: products,
                    });
                  }}
                >
                  {opporList
                    ? opporList.map((item, index) => {
                        return (
                          <Option key={index} value={item?.id}>
                            {item?.name}
                          </Option>
                        );
                      })
                    : ''}
                </Select>
              </div>
            </Row>
            {autoConvert === 0 ? (
              <>
                <b style={{ fontSize: 18, color: '#ef8737' }}>Sản phẩm</b>
                <Button
                  onClick={() => {
                    if (addProduct) {
                      setAddProduct(false);
                      handleAdd();
                    } else {
                      notifyFail('Vui lòng cập nhật sản phẩm!');
                    }
                  }}
                  type="text"
                  icon={<PlusCircleIcon />}
                />
                <Table
                  rowClassName={() => 'editable-row'}
                  bordered
                  dataSource={dataProduct}
                  columns={columns}
                  pagination={false}
                />
                <Row style={{ paddingBottom: 15 }}>
                  <div style={{ width: 300 }}>
                    Doanh số (tiền trên hợp đồng):{' '}
                  </div>
                  <div>
                    <input
                      style={{ width: 400 }}
                      placeholder="Doanh số"
                      ref={dataSalesRef}
                      defaultValue={
                        dataSource?.sales === 0
                          ? ''
                          : formatPrice(dataSource?.sales)
                      }
                      onChange={e => {
                        dataSalesRef.current.value = formatPrice(
                          e.target.value
                        );
                        setParams({
                          ...params,
                          sales: e.target.value.replaceAll(',', ''),
                        });
                      }}
                    />
                  </div>
                </Row>
                <Row style={{ paddingBottom: 15 }}>
                  <div style={{ width: 300 }}>
                    Doanh thu (tiền khách hàng thanh toán):{' '}
                  </div>
                  <div>
                    <input
                      style={{ width: 400 }}
                      placeholder="Doanh thu"
                      ref={dataRevenueRef}
                      defaultValue={
                        dataSource?.revenue === 0
                          ? ''
                          : formatPrice(dataSource?.revenue)
                      }
                      onChange={e => {
                        dataRevenueRef.current.value = formatPrice(
                          e.target.value
                        );
                        setParams({
                          ...params,
                          revenue: e.target.value.replaceAll(',', ''),
                        });
                      }}
                    />
                  </div>
                </Row>
                <Row>
                  <div style={{ width: 300 }}>File đính kèm: </div>
                  <div>
                    <UploadStyled
                      onChange={(file, fileList) => {
                        setFileList(file?.fileList);
                        setListDelete([...listDelete, Number(file.file.uid)]);
                        setParams({
                          ...params,
                        });
                      }}
                      showUploadList={(
                        showDownloadIcon,
                        downloadIcon,
                        showRemoveIcon,
                        removeIcon
                      ) => {
                        showDownloadIcon(true);
                        downloadIcon(true);
                        showRemoveIcon(true);
                        removeIcon(<DeleteOutlined onClick={e => {}} />);
                      }}
                      defaultFileList={[...listFiles]}
                    >
                      <Button icon={<UploadOutlined />}>Upload</Button>
                    </UploadStyled>
                  </div>
                </Row>
              </>
            ) : (
              <></>
            )}
            <Row>
              <b style={{ fontSize: 18, color: '#ef8737' }}>Ghi chú</b>
              <div style={{ width: '100%' }}>
                <TextArea
                  style={{ width: '100%' }}
                  showCount
                  maxLength={2000}
                  placeholder="Ghi chú"
                  onChange={e => {
                    setParams({ ...params, note: e.target.value.trim() });
                  }}
                />
              </div>
            </Row>
          </Wrapper>
        </div>
      </Spin>
    </Modal>
  );
};

export default ModalAddOpportunity;
