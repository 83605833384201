import { Col, Empty, Row, Tag, Tooltip } from "antd";
import React from "react";
import { ReloadStyled, ResultStyled, TableStyled, WrapperTable } from "./style";
import PaginationModules from "../Pagination";
import IconAntd from "../IconAntd";
import { wait } from "../utils";
import { VietnameseString } from "@constants/VietnameseString";

// interface IProps {
//     dataSource: any[];
//     columns: ColumnsType<any>;
//     page?: number;
//     onChangePage?: (page: number) => void;
//     total?: number;
//     isPagination?: boolean;
//     rowSelect?: boolean;
//     loading?: boolean;
//     isPageSettings?: boolean;
//     id?: string;
//     expandedRowRender?: (record: any, index: number, indent: number, expanded: any) => ReactNode;

//     onRowSelection?: (row: any[]) => void;
//     typeRowSelect?: RowSelect;
//     header?: any;
//     onRowClick?: any;
//     customRowKey?: any;
//     renderDefault?: any;
//     rowClassName?: any;
//     hiddenSelectAll?: boolean;
//     components?: any;
//     showTotalResult?: boolean;
//     reLoadData?: any;
//     pageSize?: any;
// }

const TableModules = ({
    id = "table_antd",
    dataSource,
    columns,
    page,
    onChangePage,
    isPagination = true,
    rowSelect = true,
    total,
    onRowSelection,
    expandedRowRender,
    loading = false,
    typeRowSelect = "checkbox",
    header,
    onRowClick,
    customRowKey,
    renderDefault,
    rowClassName,
    hiddenSelectAll,
    components,
    showTotalResult,
    reLoadData,
    pageSize = 12,
    defaultPagination = false,
    size = "middle",
}) => {
    const [loadingRefresh, setLoadingRefresh] = React.useState(false);

    const [keysExpanded, setKeysExpanded] = React.useState([]);

    const rowSelection = {
        onChange: (rowKey, selectedRows) => {
            onRowSelection && onRowSelection(selectedRows);
        },
        hideSelectAll: hiddenSelectAll,
    };

    return (
        <Row className='gx-m-0'>
            <Col span={24} className='gx-m-0 gx-px-0'>
                <WrapperTable>
                    {showTotalResult && (
                        <Row className='gx-m-0 gx-pb-3' align='middle'>
                            <ResultStyled>
                                <div>Kết quả lọc: </div>
                                <Tag className='gx-m-0 gx-ml-2' color='magenta'>
                                    {total}
                                </Tag>
                            </ResultStyled>
                            {reLoadData && (
                                <Tooltip title='Tải lại dữ liệu'>
                                    <ReloadStyled
                                        onClick={() => {
                                            if (reLoadData) {
                                                setLoadingRefresh(true);
                                                wait(1000).then(() => {
                                                    reLoadData();
                                                    setLoadingRefresh(false);
                                                });
                                            }
                                        }}
                                    >
                                        <IconAntd
                                            spin={loadingRefresh}
                                            style={{ color: loadingRefresh ? "red" : "black" }}
                                            size='16px'
                                            icon='ReloadOutlined'
                                        />
                                    </ReloadStyled>
                                </Tooltip>
                            )}
                        </Row>
                    )}
                    <TableStyled
                        showSorterTooltip={{ title: "Sắp xếp" }}
                        loading={loading || loadingRefresh}
                        title={header ? () => header : undefined}
                        id={id}
                        size={size}
                        // @ts-ignore
                        rowSelection={
                            rowSelect
                                ? customRowKey
                                    ? {
                                          selectedRowKeys: customRowKey,
                                          type: typeRowSelect,
                                          ...rowSelection,
                                      }
                                    : {
                                          type: typeRowSelect,
                                          ...rowSelection,
                                      }
                                : false
                        }
                        expandable={{
                            expandedRowRender,
                            expandedRowKeys: keysExpanded,
                            expandRowByClick: true,
                            onExpandedRowsChange: (keys) => {
                                if (keys?.length > 0) {
                                    // if (components) {
                                    //     setKeysExpanded([]);
                                    // } else {
                                    setKeysExpanded([keys.reverse()[0]]);
                                    // }
                                } else {
                                    setKeysExpanded([]);
                                }
                            },
                        }}
                        onRow={(record) => {
                            return {
                                onClick: () => onRowClick && onRowClick(record), // click row
                            };
                        }}
                        rowClassName={rowClassName || undefined}
                        bordered
                        pagination={defaultPagination}
                        rowKey={(record) => record.id}
                        // scroll={{ x: '100vh', y: `calc(100vh - ${pathname === '/settings' ? '330px' : '270px'})` }}
                        dataSource={dataSource}
                        columns={columns}
                        // onChange={(all) => console.log('change table', all)}
                        components={components}
                        locale={{
                            emptyText: (
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={<span>{VietnameseString.no_data}</span>}
                                />
                            ),
                            triggerDesc: "",
                            triggerAsc: "",
                            cancelSort: "",
                        }}
                    />
                </WrapperTable>
            </Col>
            {!defaultPagination &&
            !renderDefault &&
            onChangePage &&
            isPagination &&
            total &&
            total > pageSize ? (
                <Col span={24}>
                    <Row justify='end' style={{ flexDirection: "row" }}>
                        <PaginationModules
                            pageSize={pageSize}
                            page={page || 1}
                            total={total || 0}
                            onChange={onChangePage}
                        />
                    </Row>
                </Col>
            ) : null}
        </Row>
    );
};

export default TableModules;
