import React from 'react';
import { VietnameseString } from '@constants/VietnameseString';
import {
  ColStyled,
  LabelStyled,
  RadioGroupStyled,
} from '@screens/Enterprise/ElectronicVoucher/ElectronicVoucherStyled';
import { Col, Form, InputNumber, Radio, Row } from 'antd';

const DiscountByProduct = ({ typeVoucher }) => {
  return (
    <div>
      <Row>
        <LabelStyled>{VietnameseString.discount_by_product}</LabelStyled>
      </Row>

      <Row style={{ padding: '0 10px' }}>
        <ColStyled span={24}>
          <Form.Item style={{ width: '100%' }} name="type_discount_product">
            <RadioGroupStyled>
              <Row>
                <Col span={8}>
                  <Radio className="radio-option" value="amountOfMoney">
                    {VietnameseString.amount_of_money}
                  </Radio>
                </Col>
                <Col span={16}>
                  {typeVoucher?.type_discount_product === 'amountOfMoney' && (
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập số tiền',
                        },
                        {
                          validator: (_, value) => {
                            if (+value < 1) {
                              return Promise.reject(
                                new Error('Số tiền phải lớn hơn 0!')
                              );
                            }

                            if (+value > 100000000000) {
                              return Promise.reject(
                                new Error('Số tiền phải nhỏ hơn 100 tỷ!')
                              );
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                      colon={false}
                      name="discount_product"
                      style={{ marginBottom: 0 }}
                      normalize={value =>
                        value?.toString()?.replace(/[^\wÀ-úÀ-ÿ]/g, '')
                      }
                    >
                      <InputNumber
                        addonAfter="VNĐ"
                        style={{ width: '100%' }}
                        formatter={value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={value =>
                          value ? value.replace(/\$\s?|(,*)|\./g, '') : ''
                        }
                        placeholder="Số tiền"
                        controls={false}
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>

              <Row>
                <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
                  <Radio className="radio-option" value="percentage">
                    %
                  </Radio>
                </Col>

                <Col span={16}>
                  {typeVoucher?.type_discount_product === 'percentage' && (
                    <Form.Item
                      name="discount_product_percent"
                      colon={false}
                      style={{ marginBottom: 0, width: '100%' }}
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập số phần trăm',
                        },
                        {
                          validator: (_, value) => {
                            if (+value < 1) {
                              return Promise.reject(
                                new Error('Số % phải lớn hơn 0!')
                              );
                            }

                            if (+value > 100) {
                              return Promise.reject(
                                new Error('Số % phải nhỏ hơn 100!')
                              );
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                      normalize={value =>
                        value?.toString()?.replace(/[^\wÀ-úÀ-ÿ]/g, '')
                      }
                    >
                      <InputNumber
                        addonAfter="%"
                        style={{ width: '100%' }}
                        formatter={value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={value =>
                          value ? value.replace(/\$\s?|(,*)|\./g, '') : ''
                        }
                        placeholder="Số phần trăm"
                        controls={false}
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </RadioGroupStyled>
          </Form.Item>
        </ColStyled>
      </Row>
    </div>
  );
};

export default DiscountByProduct;
