import React from 'react';
import ChangePasswordModal from './ChangePasswordModal';
import Cookie from 'js-cookie';
import { requestLogout } from '@constants/Api';
import { useSelector } from 'react-redux';
import CompanyInfoModal from './CompanyInfoModal';

const DropdownHeader = () => {
  const [isModal, setIsModal] = React.useState(false);
  const [isModalInfo, setIsModalInfo] = React.useState(false);

  const userInfo = useSelector(state => state.userReducer);

  const onCloseModal = () => {
    setIsModal(false);
  };

  const onCloseInfoModal = () => {
    setIsModalInfo(false);
  };

  const logout = async () => {
    try {
      await requestLogout();
    } catch (err) {
      alert('Phiên đăng nhập hết hạn');
      Cookie.remove('SESSION_ID');
      window.location.href = '/login';
    }
    Cookie.remove('SESSION_ID');
    window.location.href = '/login';
  };

  return (
    <>
      <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        {userInfo.data?.role_id === 2 && (
          <a
            className="dropdown-item cursor"
            onClick={() => setIsModalInfo(true)}
          >
            <div className="dropdown-admin-item">
              <p className="me-txt-admin-drop">Thông tin công ty</p>
            </div>
          </a>
        )}
        <a className="dropdown-item cursor" onClick={() => setIsModal(true)}>
          <div className="dropdown-admin-item">
            <p className="me-txt-admin-drop">Đổi mật khẩu</p>
          </div>
        </a>
        <a className="dropdown-item cursor" onClick={logout}>
          <div className="dropdown-admin-item">
            <p className="me-txt-admin-drop">Đăng xuất</p>
          </div>
        </a>
      </div>

      <ChangePasswordModal showModal={isModal} closeModal={onCloseModal} />
      <CompanyInfoModal
        showInfoModal={isModalInfo}
        closeModal={onCloseInfoModal}
      />
    </>
  );
};

export default React.memo(DropdownHeader);
