import { BASE_URL_IMAGE, ROUTER } from '@constants/Constant';
import { randomColor } from '@constants/funcHelper';
import { primaryColor } from '@styles/Color';
import { Avatar, Col, Row } from 'antd';
import moment from 'moment';
import 'moment/locale/vi';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { notifyFail } from '@utils/notify';

moment.locale('vi');

export default function InteractionHistoryHeader({
  interactionHistory,
  isNavigate,
  isAdmin,
}) {
  const history = useHistory();
  // const selector = useSelector()
  const selector = useSelector(state => state);

  const isSale = Boolean(selector?.userReducer?.data?.role_id == 5);
  function navigateOpportunityDetails(url, id) {
    if (isNavigate && url && id) {
      history.push(`${url}/${id}`);
    }
  }
  try {
    const name = interactionHistory?.updated_by.split(' ');
    return (
      <Row align="middle" justify="space-between">
        <Col>
          <p
            style={
              isNavigate
                ? {
                    fontWeight: 700,
                    display: 'inline-block',
                    fontSize: '18px',
                    color: primaryColor,
                    cursor: 'pointer',
                  }
                : {
                    fontWeight: 700,
                    display: 'inline-block',
                    fontSize: '18px',
                    color: primaryColor,
                  }
            }
            onClick={() => {
              console.log('interactionHistory', interactionHistory);
              if (interactionHistory.is_active) {
                if (isSale) {
                  navigateOpportunityDetails(
                    ROUTER.OPPORTUNITY_DETAIL_SALE,
                    interactionHistory.opportunity_id
                  );
                } else {
                  navigateOpportunityDetails(
                    ROUTER.OCCASION_DETAIL,
                    interactionHistory.opportunity_id
                  );
                }
              } else if (interactionHistory.is_active === 0) {
                notifyFail('Cơ hội đã bị xóa');
              } else {
                console.log('ok.');
              }
            }}
          >
            {interactionHistory.df_customer_action_name}
          </p>{' '}
          ({moment(interactionHistory.created_at).format('hh:mm A')})
        </Col>

        <Col>
          <Row gutter={[8, 8]} align="middle">
            <Col>
              {interactionHistory.updated_by && interactionHistory.updated_by}
            </Col>
            <Col>
              <Avatar
                src={
                  interactionHistory.updated_by_icon &&
                  `${interactionHistory.updated_by_icon}`
                }
                style={{
                  backgroundColor: randomColor(
                    name[name.length - 1].charAt(0)?.toUpperCase()
                  ),
                  marginRight: '5px',
                }}
                size="large"
              >
                <div style={{ fontSize: '18px' }}>
                  {name[name.length - 1].charAt(0)?.toUpperCase()}
                </div>
              </Avatar>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  } catch (error) {
    return (
      <Row align="middle" justify="space-between">
        <Col>
          <p
            style={{
              fontWeight: 700,
              display: 'inline-block',
              fontSize: '18px',
              color: primaryColor,
            }}
          >
            {interactionHistory.df_customer_action_name}
          </p>{' '}
          ({moment(interactionHistory.created_at).format('hh:mm A')})
        </Col>
      </Row>
    );
  }
}
