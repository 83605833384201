import React, { Component } from 'react';
import { requestUpdateCompany, requestCompanyDetail, requestResetPassword } from '@constants/Api';
import { createFormData } from '@utils/createFormData';
import { withRouter } from 'react-router-dom';
import '@styles/Company.css';
import { Row, Col, FormControl, Button, FormGroup } from 'react-bootstrap';
import { ACCEPT_TYPE, PHONE_REGEX, EMAIL_REGEX } from '@constants/Constant';
import Loading from '@src/components/Loading';
import { ToastContainer } from 'react-toastify';
import { notifyFail, notifySuccess } from '@utils/notify';
import { getListPackage } from '@src/redux/actions';
import { connect } from 'react-redux';
import SelectComponentV2 from '@components/select/SelectComponent';

function FormError(props) {
  if (props.isHidden) {
    return null;
  }
  return (
    <div
      style={{
        color: 'red',
        width: '100%',
        textAlign: 'center',
      }}
    >
      {props.errorMessage}
    </div>
  );
}

class CompanyEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        id: '',
        user_name: '',
        full_name: '',
        email: '',
        office_name: '',
        address: '',
        is_active: '',
        code: '',
        package_service_id: '',
      },
      isLoading: false,
      file: '',
      syncInfo: {
        create_sale_link: '',
        create_enterprise_link: '',
        name: '',
        deeplink_ios: '',
        deeplink_android: '',
        app_store_id: '',
        play_store_id: '',
      },
      page: 1,
    };
  }

  componentDidMount() {
    this.getData();
    this.getListPackage();
  }

  getData(payload) {
    this.getCompanyDetail();
  }

  async getCompanyDetail() {
    this.setState({ isLoading: true });
    const { id } = this.props.match.params;
    const res = await requestCompanyDetail({ id: id });
    const data = res.data;
    let file = '';
    await this.setState({
      form: {
        id: data.id,
        full_name: data.full_name,
        email: data.email,
        user_name: data.phone,
        code: data.user_enterprise_info?.code,
        office_name: data.user_enterprise_info?.office_name,
        address: data.user_enterprise_info?.address,
        is_active: data.is_active,
        package_service_id: data.user_enterprise_info?.package_service?.id,
      },
      isLoading: false,
      file: data.user_enterprise_info?.profile_image || '',
      syncInfo: {
        ...res.data.config_app_enter,
        create_sale_link: res.data.config_app_enter?.create_sale_link || '',
        create_enterprise_link: res.data.config_app_enter?.create_enterprise_link || '',
        name: res.data.config_app_enter?.name || '',
        deeplink_ios: res.data.config_app_enter?.deeplink_ios || '',
        deeplink_android: res.data.config_app_enter?.deeplink_android || '',
        app_store_id: res.data.config_app_enter?.app_store_id || '',
        play_store_id: res.data.config_app_enter?.play_store_id || '',
      },
    });
  }

  async getListPackage(page) {
    const { id } = this.props.match.params;
    const res = await requestCompanyDetail({ id: id });
    const data = res.data;
    this.props.getListPackage({
      search: '',
      level: data.user_enterprise_info?.package_service?.level,
      type: 1,
      page: page || '',
    });
  }

  async resetPassword() {
    try {
      this.setState({ isLoading: true });
      const { id } = this.props.match.params;
      const res = await requestResetPassword({ id: id });
      this.setState({ isLoading: false });
      notifySuccess('Mật khẩu mới đã được vửi về email');
    } catch (err) {
      this.setState({ isLoading: false });
      notifyFail(err.msg);
    }
  }

  handleInputChange = (field, value) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [field]: value,
      },
    });
  };

  async update() {
    const check = await this.validateForm();
    const { full_name, user_name, email, office_name, address, id, is_active, package_service_id } = this.state.form;
    const object = {
      full_name: full_name?.trim(),
      user_name: user_name?.trim(),
      email: email?.trim(),
      office_name: office_name?.trim(),
      address: address?.trim(),
      id: id,
      is_active: is_active,
      package_service_id: package_service_id,
    };
    if (check) {
      const formdata = createFormData(this.state.form);
      const res = requestUpdateCompany(formdata)
        .then((res) => {
          this.setState({ isLoading: false });
          this.props.history.goBack();
          notifySuccess('Cập nhật thông tin thành công!');
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          notifyFail(err.msg);
        });
    }
    return;
  }

  validateForm() {
    const { form, file } = this.state;
    if (!form.user_name?.trim() || !form.full_name?.trim() || !form.email?.trim() || !form.office_name?.trim()) {
      return false;
    }
    if (file === 'http://18.141.210.230:8008/') {
      return false;
    }
    // if (PHONE_REGEX.exec(form.user_name) === null) {
    //   return false;
    // }

    if (EMAIL_REGEX.exec(form.email) === null) {
      return false;
    }

    return true;
  }

  checkValidation = () => {
    const { form, file } = this.state;
    if (!form.user_name?.trim() || !form.full_name?.trim() || !form.email?.trim() || !form.office_name?.trim()) {
      return <FormError errorMessage={' Vui lòng điền đầy đủ thông tin!'} />;
    }
    if (file === 'http://18.141.210.230:8008/') {
      return <FormError errorMessage={' Vui lòng thêm logo công ty'} />;
    }

    // if (IMAGE_URL.exec(this.state.file) === "http://18.141.210.230:8008/") {
    //   return <FormError errorMessage={" Vui lòng thêm logo công ty"} />;
    // }

    if (!EMAIL_REGEX.exec(form.email)) {
      return <FormError errorMessage={' Email không hợp lệ!'} />;
    }

    // if (PHONE_REGEX.exec(form.user_name) === null) {
    //   return <FormError errorMessage={'Số điện thoại không hợp lệ!'} />;
    // }
    else {
      return true;
    }
  };

  handleChangeImage = (type, event) => {
    if (!ACCEPT_TYPE.IMAGE.includes(event.target.files[0]?.type)) {
      // alert('Định dạng ảnh không được hỗ trợ. Vui lòng chọn ảnh khác.');
      return;
    }

    this.setState({
      form: {
        ...this.state.form,
        [type]: event.target.files[0],
      },
      [type]: (window.URL || window.webkitURL).createObjectURL(event.target.files[0]),
    });
  };

  handleInputChange = (field, value) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [field]: value,
      },
    });
  };

  renderBody() {
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
            <Col md className="bg">
              <Row>
                <h2> Cập nhật thông tin công ty </h2>
              </Row>
              {/* <div class="line"></div> */}
              <Row>
                {this.checkValidation()}
                {this.renderField()}
              </Row>
              <Row>
                <Col md={7} sm={12}>
                  {this.renderButton()}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      // <div className="content-wrapper">
      //   {/* Content Header (Page header) */}
      //   <div className="content-header">
      //     <Row>
      //       <Col md={5} sm={12}>
      //         <h2> Cập nhật thông tin công ty </h2>
      //       </Col>
      //     </Row>
      //     <hr />
      //     <Row>
      //       {this.checkValidation()}

      //       {this.renderField()}
      //     </Row>
      //     <Row>
      //       <Col md={7} sm={12}>
      //         {this.renderButton()}
      //       </Col>
      //     </Row>
      //   </div>
      // </div>
    );
  }

  renderButton() {
    const {
      match: { params },
    } = this.props;

    return (
      <div className="text-center text-md-right">
        {params.id && (
          <Button variant="success" className="text-light" onClick={() => this.update()}>
            Lưu thay đổi
          </Button>
        )}
      </div>
    );
  }
  renderButtonReset() {
    return (
      <div className="text-center text-md-right">
        <Button
          variant="dark"
          className="text-light"
          style={{ marginRight: '120px' }}
          onClick={() => this.resetPassword()}
        >
          Reset mật khẩu
        </Button>
      </div>
    );
  }

  renderField() {
    const { form } = this.state;
    const {
      create_sale_link,
      create_enterprise_link,
      name,
      deeplink_ios,
      deeplink_android,
      app_store_id,
      play_store_id,
    } = this.state.syncInfo;
    const pack = this.props.packageState.data?.data;

    return (
      <div className="w-100">
        {this.renderButtonReset()}
        <Row>
          <Col md={4} className="offset-0 offset-md-1">
            <Row>
              <label>Ảnh logo (*)</label>
            </Row>
            <Row>{this.renderUploader()}</Row>
            {create_sale_link ||
            create_enterprise_link ||
            name ||
            deeplink_ios ||
            deeplink_android ||
            app_store_id ||
            play_store_id ? (
              <Row className="mt-3">
                <Col md={3} className="pl-0 d-flex align-items-center">
                  <i class="fas fa-mobile-alt fa-lg mr-2"></i>
                  <label>Tên App (*)</label>
                </Col>
                <Col md={9}>
                  {/* <FormControl type="text" placeholder="Tên app" /> */}
                  <span>{name || 'Chưa cập nhật'}</span>
                </Col>
                <Col md={12} className="pl-0 mt-3">
                  <label>Link tạo công ty</label>
                </Col>
                <Col md={12} className="pl-0">
                  <div style={{ wordWrap: 'break-word' }}>
                    <a style={{ color: '#007bff' }}>{create_enterprise_link || 'Chưa cập nhật'}</a>
                  </div>
                </Col>
                <Col md={12} className="pl-0 mt-3">
                  <label>Link tạo sale (*)</label>
                </Col>
                <Col md={12} className="pl-0">
                  {/* <FormControl type="text" placeholder="Link tạo sale" /> */}
                  <div style={{ wordWrap: 'break-word' }}>
                    <a style={{ color: '#007bff' }}>{create_sale_link || 'Chưa cập nhật'}</a>
                  </div>
                </Col>
                {/* -------------------------- */}
                <Col md={12} className="pl-0 mt-3">
                  <i class="fab fa-apple fa-lg mr-2"></i>
                  <label>Mã app store</label>
                </Col>
                <Col md={12} className="pl-0">
                  {/* <FormControl type="text" placeholder="Mã app store" /> */}
                  <div style={{ wordWrap: 'break-word' }}>
                    <a style={{ color: '#007bff' }}>{app_store_id || 'Chưa cập nhật'}</a>
                  </div>
                </Col>
                <Col md={12} className="pl-0 mt-3">
                  <i class="fab fa-android fa-lg mr-2"></i>
                  <label>Mã play store</label>
                </Col>
                <Col md={12} className="pl-0">
                  {/* <FormControl type="text" placeholder="Mã play store" /> */}
                  <div style={{ wordWrap: 'break-word' }}>
                    <a style={{ color: '#007bff' }}>{play_store_id || 'Chưa cập nhật'}</a>
                  </div>
                </Col>
                <Col md={12} className="pl-0 mt-3">
                  <i class="fab fa-apple fa-lg mr-2"></i>
                  <label>Deeplink IOS</label>
                </Col>
                <Col md={12} className="pl-0">
                  {/* <FormControl type="text" placeholder="Deeplink IOS" /> */}
                  <div style={{ wordWrap: 'break-word' }}>
                    <a style={{ color: '#007bff' }}>{deeplink_ios || 'Chưa cập nhật'}</a>
                  </div>
                </Col>
                <Col md={12} className="pl-0 mt-3">
                  <i class="fab fa-android fa-lg mr-2"></i>
                  <label>Deeplink Android</label>
                </Col>
                <Col md={12} className="pl-0">
                  {/* <FormControl type="text" placeholder="Deeplink Android" /> */}
                  <div style={{ wordWrap: 'break-word' }}>
                    <a style={{ color: '#007bff' }}>{deeplink_android || 'Chưa cập nhật'}</a>
                  </div>
                </Col>
                {/* <Col md={12} className="pl-0 mt-3">
        <label>Trạng thái đồng bộ</label>
      </Col>
      <Col md={12} className="pl-0">
        <FormControl as="select">
          <option value="">Trạng thái đồng bộ</option>
          <option value="1">Đang đồng bộ</option>
          <option value="2">Ngừng đồng bộ</option>
        </FormControl>
      </Col> */}
              </Row>
            ) : (
              <></>
            )}
          </Col>
          <Col md={6}>
            <FormGroup className="add-company-form">
              <Row>
                <label>Tên công ty (*)</label>
                <FormControl
                  type="text"
                  placeholder="Nhập tên công ty"
                  value={form.office_name}
                  onChange={(e) => this.handleInputChange('office_name', e.target.value)}
                ></FormControl>
              </Row>
              <Row>
                <label>Mã số công ty</label>
                <FormControl
                  type="tel"
                  placeholder="Mã số công ty"
                  value={form.code}
                  disabled={true}
                  // onChange={(e) => this.handleInputChange('user_name', e.target.value)}
                ></FormControl>
              </Row>
              <Row>
                <label>Email (*)</label>
                <FormControl
                  type="email"
                  placeholder="Nhập email"
                  value={form.email}
                  onChange={(e) => this.handleInputChange('email', e.target.value)}
                ></FormControl>
              </Row>
              <Row>
                <label>Số điện thoại (*)</label>
                <FormControl
                  type="tel"
                  placeholder="Nhập số điện thoại"
                  value={form.user_name}
                  disabled={true}
                  onChange={(e) => this.handleInputChange('user_name', e.target.value)}
                ></FormControl>
              </Row>
              <Row>
                <label>Người liên hệ (*)</label>
                <FormControl
                  type="text"
                  placeholder="Nhập tên người liên hệ"
                  value={form.full_name}
                  onChange={(e) => this.handleInputChange('full_name', e.target.value)}
                ></FormControl>
              </Row>
              <Row>
                <label>Địa chỉ</label>
                <FormControl
                  type="text"
                  placeholder="Nhập địa chỉ công ty"
                  value={form.address}
                  onChange={(e) => this.handleInputChange('address', e.target.value)}
                ></FormControl>
              </Row>
              <Row>
                <label>Trạng thái</label>
                <FormControl
                  as="select"
                  id=""
                  value={form.is_active}
                  onChange={(e) => this.handleInputChange('is_active', e.target.value)}
                >
                  <option value="1">Đang hoạt động</option>
                  <option value="2">Tạm Ngưng hoạt động</option>
                </FormControl>
              </Row>
              <Row>
                <label>Gói dịch vụ (*)</label>
                <SelectComponentV2
                  value={form.package_service_id}
                  limit={1000}
                  onChange={(item) => {
                    this.handleInputChange('package_service_id', item.value)
                  }}
                  apiUrl="admin/getListPackage"
                  placeholder="Gói dịch vụ"
                />
              </Row>
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }

  renderUploader() {
    const { file } = this.state;
    return (
      <div
        style={{
          width: '100%',
          height: '300px',
          borderRadius: '7px',
          textAlign: 'center',
          backgroundColor: '#e0e0e0',
        }}
      >
        <label htmlFor="upload-button" className="label-upload">
          {file != 'http://18.141.210.230:8008/' ? (
            <img
              src={file}
              className="uploaded-pic"
              width="300"
              height="300"
              style={{ objectFit: 'contain', borderRadius: '8px' }}
            />
          ) : (
            <div style={{ marginTop: '5rem' }}>
              <i className="fas fa-images upload-icon m-0"></i>
              <br />
              <p className="m-0">Bấm để chọn ảnh logo của công ty</p>
            </div>
          )}
        </label>
        <input
          type="file"
          id="upload-button"
          accept=".jpg,.jpeg,.png"
          style={{ display: 'none' }}
          onChange={(e) => {
            this.handleChangeImage('file', e);
          }}
        />
        <br />
      </div>
    );
  }
  render() {
    return (
      <>
        {this.state.isLoading ? <Loading /> : null}
        {this.renderBody()}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={true}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ToastContainer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  packageState: state.listPackageReducer,
});

const mapDispatchToProps = {
  getListPackage,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompanyEdit));
