import React from 'react';
import PropTypes from 'prop-types';

import { Tag, TreeSelect, Upload, Table, Select } from 'antd';
import { Form, Row, Col, Button, Modal } from 'react-bootstrap';

function SelectControl({ multiple, ...props }) {
  const mode = multiple ? 'multiple' : undefined;
  return (
    <Select
      mode={mode}
      maxTagCount={1}
      maxTagTextLength={10}
      allowClear
      showArrow
      options
      value
      optionFilterProp="label"
      {...props}
    />
  );
}
SelectControl.propTypes = {
  ...Select.propTypes,
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    })
  ),
  onChange: PropTypes.func,
};

// const RefForwardSelectControl = React.forwardRef((props, ref) => {
//   return <SelectControl ref={ref} {...props} />;
// });
// RefForwardSelectControl.propTypes = SelectControl.propTypes;
export default SelectControl;
