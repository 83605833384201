import Pagination from '@components/Pagination';
import {
  requestAddTelemarketingWhenAssignTelemarketingWithRatio,
  requestAssignSale,
  requestChangeTelesale,
  requestChangeTelesaleWithRatio,
  requestRollBackTelesale,
  requestRollBackWithRatio,
  requestTelesaleDetail,
} from '@constants/Api';
import {
  ROUTER,
  TELESALE_JOB_STATUS,
  TELESALE_JOB_STATUS_ALIAS,
} from '@constants/Constant';
import { VietnameseString } from '@constants/VietnameseString';
import {
  clearJobDetail,
  getJobDetail,
  getTelesaleList,
} from '@src/redux/actions';
import '@styles/RegisterRequest.css';
import { ButtonSystemStyle, TableAntStyle } from '@styles/Style';
import { getItemFromId } from '@utils/getItemFromId';
import { notifyFail, notifySuccess } from '@utils/notify';
import {
  Checkbox,
  Col as ColAntd,
  Empty,
  Input,
  InputNumber,
  Radio,
  Row as RowAntd,
} from 'antd';
import React, { Component } from 'react';
import { Button, Col, Form, FormControl, Modal, Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  DELETE_CUSTOMER_IN_GROUP,
  DIVIDE_TYPE,
  TIME_LOOP,
} from '../../Constants';
import { handleAutomaticCustomerDivision } from '../../funcHelper';
import RollbackModal from '../Component/RollbackModal';
import InputSearch from './InputSearch';

class Assign extends Component {
  constructor(props) {
    super(props);
    this.timeoutId = null;
    this.onSearchInput = this.onSearchInput.bind(this);
    this.onSearchInputSaleTakeCare = this.onSearchInputSaleTakeCare.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);

    this.state = {
      tableLoading: false,
      addModal: false,
      changeModal: false,
      roleBack: false,
      Checked: null,
      selectedTelemarketing: [],
      isLoading: false,
      modal: false,
      data: null,
      telesaleInfo: {},
      tableColumns: [
        {
          title: 'Họ tên',
          dataIndex: 'name',
          render: (text, record) => {
            return (
              <div onClick={() => this.handleModalOpen(record)}>{text}</div>
            );
          },
        },
        {
          title: 'Số cuộc gọi trung bình trên ngày',
          dataIndex: 'average',
        },
        {
          title: 'Điểm kinh nghiệm',
          dataIndex: 'reward_point',
        },
        {
          title: 'KPI trung bình',
          dataIndex: 'kpi',
        },
        {
          title: 'Thành công',
          dataIndex: 'success',
        },
        {
          title: 'Cuộc gọi còn lại',
          dataIndex: 'remain',
        },
      ],
      selected: [],
      tableData: [],
      selectedSale: [],
      exSelectedSale: [],
      job_telesales: [],
      job_telesalesWithData: [],
      modalRoleBack: {
        telesale_id: '',
        job_id: '',
        count: '',
        is_rollback_all: '',
        delete_customer_in_group: DELETE_CUSTOMER_IN_GROUP.NONE,
      },
      ratio_code: null,
      isActiveButton: true,
      visibleRollBackModal: false,
      search: '',
      searchSaleTakeCare: '',
      assign_customer_prioritize_enable: null,
      isCheck_Auto_divide: false,
    };
  }

  componentDidMount() {
    const jobDetail = this.props.jobState.data;
    this.getData();
    this.props.getJobDetail({ id: this.props.match.params?.id });
    this.props.getTelesaleList({
      page: 1,
      job_id: 1,
    });
    if (jobDetail.assign_customer_type === 1) {
      this.setState({
        isCheck_Auto_divide: true,
      });
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const jobDetail = this.props.jobState.data;
    const { isActive: thisIsActive } = this.props;
    const { isActive: prevIsActive } = prevProps;
    if (thisIsActive === 'assign' && prevIsActive !== 'assign') {
      this.getData();
    }

    if (this.state.ratio_code === null && jobDetail.ratio_code) {
      this.setState({ ratio_code: jobDetail.ratio_code });
    }

    if (
      this.state.assign_customer_prioritize_enable === null &&
      jobDetail.assign_customer_prioritize_enable
    ) {
      this.setState({
        assign_customer_prioritize_enable:
          jobDetail.assign_customer_prioritize_enable,
      });
    }

    if (this.state.search.trim() !== prevState.search.trim()) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.props.getTelesaleList({
          page: 1,
          job_id: 1,
          search: this.state.search?.trim(),
        });
      }, 500);
    }
    if (this.state.searchSaleTakeCare !== prevState.searchSaleTakeCare) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getData();
      }, 500);
    }
  }

  async getData() {
    try {
      this.setState({
        tableLoading: true,
      });
      const res = await requestTelesaleDetail({
        job_id: this.props.match.params?.id,
        status: '',
        search: this.state.searchSaleTakeCare,
      });
      this.setState({
        tableLoading: false,
      });
      var arr = [];
      var idArr = [];
      if (res.data?.listSale?.length > 0) {
        arr = res.data.listSale?.map(ele => {
          return {
            ...ele,
            is_default: ele.is_required,
            amount: ele.totalSale,
          };
        });
        idArr = res.data.listSale?.map(ele => {
          return ele.telesale_id;
        });
      }
      this.setState({
        data: {
          ...res.data,
        },
        selected: [...idArr],
        job_telesalesWithData: [...arr],
      });
      // this.props.setLoading(false);
    } catch (err) {
      // this.props.setLoading(false);
      notifyFail(err.msg);
      this.setState({
        tableLoading: false,
      });
    }
  }

  componentWillReceiveProps(nextProp) {
    if (nextProp.telesaleState?.data?.length) {
      // this.setTable(nextProp.telesaleState.data);
    }
    if (nextProp.telesaleState?.isLoading) {
      this.setState({ tableLoading: true });
    } else {
      this.setState({ tableLoading: false });
    }
    if (nextProp.telesaleState !== this.props.telesaleState) {
      this.setTable(nextProp.telesaleState.data);
    }
  }

  setTable(telesaleList) {
    if (telesaleList.length >= 0) {
      var rows = [];
      telesaleList.forEach(sale => {
        rows.push({
          key: sale.id,
          code: sale?.code,
          name: sale.full_name,
          kpi: Math.ceil(sale.kpi_average),
          remain: sale.remain_call,
          success: Math.ceil(sale.opportunity_percent),
          average: Math.ceil(sale.success_call),
          reward_point: sale.reward_point,
          data: sale,
        });
      });
      this.setState({ tableData: rows });
    }
  }

  renderHeader() {
    return (
      <Row className="py-3">
        <Col sm={12} className="px-0">
          <h4>Giao việc</h4>
        </Col>
      </Row>
    );
  }

  renderLabel() {
    const detail = this.state?.data?.status;
    return (
      <Row>
        <Col sm={12} className="px-0">
          <div className="alert alert-secondary">
            <Row className="mb-md-2">
              <Col md={12} className="text-center text-md-left">
                <span className="mr-1">Số khách hàng cần tư vấn:</span>
                <b>
                  {/* {this.props.jobState?.data?.totalCustomer || 'Vui lòng chọn dữ liệu khách hàng trước khi giao việc'} */}
                  {this.props?.jobState?.data?.not_assign_customer_enable
                    ? this.state?.data?.customer?.customer_not_assign
                    : (detail &&
                        getItemFromId(
                          detail,
                          TELESALE_JOB_STATUS_ALIAS.NOT_ASSIGNED
                        )?.count) ||
                      '0'}
                </b>
              </Col>
            </Row>
            <Row className="">
              {/* <Col md className="text-center text-md-left text-info">
                <span className="mr-1">Đã giao việc:</span>
                <b>
                  {(detail &&
                    getItemFromId(detail, TELESALE_JOB_STATUS_ALIAS.ASSIGNED)
                      ?.count) ||
                    '0'}
                </b>
              </Col> */}

              <Col md className="text-center text-md-left text-primary">
                <span className="mr-1">Đã nhận việc:</span>
                <b>
                  {(detail &&
                    getItemFromId(
                      detail,
                      TELESALE_JOB_STATUS_ALIAS.SALE_ACCEPTED
                    )?.count) ||
                    '0'}
                </b>
              </Col>

              <Col md className="text-center text-md-left text-success">
                <span className="mr-1">Đã gọi:</span>
                <b>
                  {(detail &&
                    getItemFromId(detail, TELESALE_JOB_STATUS_ALIAS.CALLED)
                      ?.count) ||
                    '0'}
                </b>
              </Col>

              <Col md className="text-center text-md-left text-secondary">
                <span className="mr-1">Chưa giao việc:</span>
                <b>
                  {(detail &&
                    getItemFromId(
                      detail,
                      TELESALE_JOB_STATUS_ALIAS.NOT_ASSIGNED
                    )?.count) ||
                    '0'}
                </b>
              </Col>

              {/* <Col md className="text-center text-md-left text-red">
                <span className="mr-1">Đã từ chối:</span>
                <b>
                  {(detail &&
                    getItemFromId(detail, TELESALE_JOB_STATUS_ALIAS.REJECTED)
                      ?.count) ||
                    '0'}
                </b>
              </Col> */}
            </Row>
          </div>
        </Col>
      </Row>
    );
  }

  renderSubJobInfo() {
    const jobDetail = this.props.jobState.data;
    return (
      <div
        style={{
          margin: '10px 0px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Checkbox disabled checked={jobDetail.is_require_accept}>
            {VietnameseString.assign_work_without_sales_confirmation}
          </Checkbox>

          {jobDetail.job_ratio_custommer_telesales.length ? (
            <Checkbox
              style={{ margin: 0 }}
              checked={this.state.assign_customer_prioritize_enable}
              onChange={event => {
                this.setState({
                  assign_customer_prioritize_enable: Number(
                    event.target.checked
                  ),
                });
              }}
            >
              Ưu tiên sale đã từng chăm sóc
            </Checkbox>
          ) : (
            <></>
          )}
        </div>

        <Radio.Group
          disabled
          value={
            this.state.isCheck_Auto_divide
              ? DIVIDE_TYPE.AUTO_DIVIDE
              : jobDetail.job_ratio_custommer_telesales.length
              ? DIVIDE_TYPE.RATIO
              : DIVIDE_TYPE.QUANTITY
          }
        >
          <Radio value={DIVIDE_TYPE.QUANTITY}>
            {VietnameseString.divide_by_number_of_customers}
          </Radio>
          <Radio value={DIVIDE_TYPE.RATIO}>
            {VietnameseString.proportional_division}
          </Radio>
          <Radio value={DIVIDE_TYPE.AUTO_DIVIDE}>Giao việc tự động</Radio>
        </Radio.Group>
      </div>
    );
  }
  onChangeSearch(event) {
    this.setState({
      search: event.target.value,
    });
  }

  onSearchInput(value, event) {
    // console.log('value', value, this);

    this.setState({
      search: value,
    });
  }

  onSearchInputSaleTakeCare(event) {
    this.setState({
      searchSaleTakeCare: event.target.value?.trim(),
    });
  }

  renderTable() {
    const { selected } = this.state;
    return (
      <>
        <Row className="my-3 mb-2">
          <Col className="d-flex justify-content-between">
            <Input.Search
              style={{ width: '50%' }}
              placeholder="Tìm kiếm theo tên sale..."
              allowClear
              onChange={this.onChangeSearch}
              value={this.state.search}
              // onSearch={this.onSearchInput}
            />

            <i
              className="far fa-sync-alt add-btn text-green"
              onClick={() =>
                this.props.getTelesaleList({
                  page: 1,
                  job_id: this.props.match.params?.id,
                })
              }
            ></i>
          </Col>
        </Row>
        <div>
          Danh sách sale:
          <span style={{ color: 'red', fontWeight: 500, marginLeft: '5px' }}>
            {this.state.tableData?.length}
          </span>
          &nbsp;kết quả
        </div>
        <Row className="table-wrap">
          <TableAntStyle
            loading={this.state.tableLoading}
            rowSelection={{
              type: 'checkbox',
              onChange: selectedRowKeys =>
                this.handleTableCheck(selectedRowKeys),
              selectedRowKeys: this.state.selectedSale,
              preserveSelectedRowKeys: true,
              getCheckboxProps: record => ({
                // disabled:
                //   selected.includes(record.key) &&
                //   this.props.jobState?.data?.totalCustomer == 0,
              }),
            }}
            columns={this.state.tableColumns}
            dataSource={this.state.tableData}
            pagination={false}
            // onRow={(record, rowIndex) => {
            //   return { onClick: () => this.handleModalOpen(record) };
            // }}
            className="w-100"
            footer={() => (
              <Row className=" justify-content-md-end justify-content-center">
                <Pagination
                  screen="telesaleState"
                  handlePageChange={this.handlePageChange}
                />
              </Row>
            )}
          />
        </Row>
      </>
    );
  }

  handlePageChange(page) {
    this.props.getTelesaleList({
      page: page,
      job_id: 1,
    });
  }

  renderTableExchange() {
    const { selected } = this.state;
    return (
      <>
        <Row className="my-3 mb-2">
          <Col className="d-flex justify-content-between">
            {/* <span>Chọn các sale trong danh sách đã lọc theo điều kiện (*)</span> */}
            <Input.Search
              style={{ width: '50%' }}
              placeholder="Tìm kiếm theo tên sale..."
              allowClear
              onChange={this.onChangeSearch}
              onSearch={this.onSearchInput}
              value={this.state.search}
            />
            <i
              className="far fa-sync-alt add-btn text-green"
              onClick={() =>
                this.props.getTelesaleList({
                  page: 1,
                  job_id: this.props.match.params?.id,
                })
              }
            ></i>
          </Col>
        </Row>
        <div>
          Danh sách sale:
          <span style={{ color: 'red', fontWeight: 500, marginLeft: '5px' }}>
            {this.state.tableData.length}
          </span>
          &nbsp;kết quả
        </div>
        <Row className="table-wrap">
          <TableAntStyle
            loading={this.state.tableLoading}
            rowSelection={{
              type: 'checkbox',
              onChange: selectedRowKeys =>
                this.handleExTableCheck(selectedRowKeys),
              selectedRowKeys: this.state.exSelectedSale,
              preserveSelectedRowKeys: true,
              getCheckboxProps: record => ({
                disabled: this.state.selectedTelemarketing.includes(record.key),
              }),
            }}
            columns={this.state.tableColumns}
            dataSource={this.state.tableData}
            pagination={false}
            // onRow={(record, rowIndex) => {
            //   return { onClick: () => this.handleModalOpen(record) };
            // }}
            className="w-100"
            footer={() => (
              <Row className=" justify-content-md-end justify-content-center">
                <Pagination
                  screen="telesaleState"
                  handlePageChange={this.handlePageChange}
                />
              </Row>
            )}
          />
        </Row>
      </>
    );
  }

  handleTableCheck(selectedRowKeys) {
    const saleList = this.props.telesaleState?.data;
    const detail = this.state?.data?.status;
    const jobDetail = this.props.jobState.data;
    var arr = [],
      convertedArr = [...this.state.job_telesales];
    if (saleList?.length) {
      arr = saleList.filter(sale => selectedRowKeys.includes(sale.id)); //list rows được chọn
      arr.forEach(sale => {
        var index = convertedArr.findIndex(ele => ele.telesale_id === sale.id);

        if (index < 0) {
          if (jobDetail.job_ratio_custommer_telesales.length) {
            convertedArr.push({
              telesale_id: sale.id,
              full_name: sale.full_name,
              config_customer: 0,
              remaining_customer: 0,
              total_customer: 0,
              is_default: 0,
            });
          } else {
            convertedArr.push({
              telesale_id: sale.id,
              full_name: sale.full_name,
              amount: 0,
              is_default: 0,
            });
          }
        }
      });
    }
    // var cusNum = this.props.jobState?.data?.totalCustomer || 10;
    // var cusNum =
    //   (detail &&
    //     Number(
    //       getItemFromId(detail, TELESALE_JOB_STATUS_ALIAS.NOT_ASSIGNED)?.count
    //     )) ||
    //   0;

    // hotfix 04/04/2023
    let cusNum = this.props?.jobState?.data?.not_assign_customer_enable
      ? this.state?.data?.customer?.customer_not_assign
      : (detail &&
          getItemFromId(detail, TELESALE_JOB_STATUS_ALIAS.NOT_ASSIGNED)
            ?.count) ||
        '0';

    convertedArr = convertedArr.filter(sale =>
      selectedRowKeys?.includes(sale.telesale_id)
    );

    if (jobDetail.job_ratio_custommer_telesales.length) {
      //chia khách hàng cho sale
      handleAutomaticCustomerDivision(cusNum, convertedArr, 'config_customer');
    } else {
      handleAutomaticCustomerDivision(cusNum, convertedArr, 'amount');
    }
    // if (cusNum % convertedArr.length === 0) {
    //   averageJob = cusNum / convertedArr.length;
    //   for (var i = 0; i < convertedArr.length; i++) {
    //     convertedArr[i].amount = averageJob;
    //   }
    // } else {
    //   var hasDefault = false;
    //   averageJob = Math.floor(cusNum / convertedArr.length);
    //   remainedJob = cusNum - averageJob * convertedArr.length;
    //   for (var i = 0; i < convertedArr.length; i++) {
    //     if (convertedArr[i].is_default === 0) {
    //       convertedArr[i].amount = averageJob;
    //     } else {
    //       convertedArr[i].amount = averageJob + remainedJob;
    //       hasDefault = true;
    //     }
    //   }
    //   if (!hasDefault && convertedArr.length > 0) {
    //     convertedArr[convertedArr.length - 1].amount = averageJob + remainedJob;
    //   }
    // }

    this.setState({
      selectedSale: [...selectedRowKeys],
      job_telesales: [...convertedArr],
    });
  }

  handleExTableCheck(selectedRowKeys) {
    this.setState({
      exSelectedSale: [selectedRowKeys[selectedRowKeys.length - 1]],
    });
  }

  async addSubmit() {
    const { id } = this.props.match?.params;
    const jobDetail = this.props.jobState.data;
    const { job_telesales, ratio_code, assign_customer_prioritize_enable } =
      this.state;
    try {
      this.setState({ isActiveButton: false });
      // if (!this.validateInfo()) {
      //     return
      // }
      // this.props.setLoading(true);
      if (jobDetail.job_ratio_custommer_telesales.length) {
        const job_telesale_ratio = job_telesales.map(i => ({
          telesale_id: i.telesale_id,
          config_customer: i.config_customer,
          remaining_customer: i.config_customer,
          total_customer: 0,
        }));
        await requestAddTelemarketingWhenAssignTelemarketingWithRatio({
          job_telesale_ratio,
          job_id: id,
          is_require_accept: Number(jobDetail.is_require_accept),
          code: ratio_code,
          time_repeat: new Date().getTime(),
          assign_customer_prioritize_enable,
        });
      } else if (this.state.isCheck_Auto_divide) {
        //chia tu dong
        await requestAssignSale({
          job_telesales: job_telesales,
          job_id: id,
          assign_customer_type: 1,
        });
      } else {
        await requestAssignSale({
          job_telesales: job_telesales,
          job_id: id,
        });
      }
      this.getData();
      this.setState({ addModal: false }, () =>
        notifySuccess('Giao việc thành công')
      );
    } catch (err) {
      notifyFail(err.msg);
    } finally {
      this.setState({ isActiveButton: true, search: '', selectedSale: [] });
    }
  }

  validateInfo() {
    var jobNum = this.state.job_telesales.reduce(
      (total = 0, job) => total + parseInt(job.amount),
      0
    );
    var fixedSale = this.state.job_telesales.filter(sale => sale.is_default);
    var saleHasNoJob = this.state.job_telesales.filter(
      sale => parseInt(sale.amount) < 1 || !sale.amount
    );

    if (!this.props?.jobState.data?.job_id) {
      notifyFail('Vui lòng nhập thông tin chung');
      return false;
    }

    // buộc phải có telesale thực hiện công việc
    if (this.state.job_telesales.length < 1) {
      notifyFail('Cần tối thiểu 1 sale thực hiện công việc này!');
      return false;
    }

    // // kiểm tra xem việc đã được giao hết cho telesale chưa,
    // if (jobNum < 4) {
    //     notifyFail("Giao chưa hết số lượng công việc!")
    //     return false;
    // }
    // if (jobNum > 4) {
    //     notifyFail("Số lượng công việc đã giao vượt quá số lượng khách cần phục vụ!")
    //     return false;
    // }

    // telesale được chọn nào cũng phải được giao job
    if (saleHasNoJob.length > 0) {
      notifyFail('Sale được chọn cần có số lượng công việc lớn hơn 0!');
      return false;
    }

    // buộc phải có 1 telesale cố định
    // if (fixedSale.length < 1) {
    //   notifyFail('Cần có tối thiểu 1 telesale cố định cho công việc này!');
    //   return false;
    // }

    return true;
  }

  removeSelected(id) {
    var newArr = [...this.state.selectedSale];
    var index = newArr.indexOf(id);
    var newjobTelesale = this.state.job_telesales.filter(
      sale => sale.telesale_id !== id
    );

    newArr.splice(index, 1);
    this.setState({
      selectedSale: newArr,
      job_telesales: [...newjobTelesale],
    });
  }

  handleSelectedRowChange(field, value, saleId, isEdit) {
    if (!isEdit) {
      var newArr = [...this.state.job_telesales];
      var saleIndex = newArr.findIndex(
        element => element.telesale_id === saleId
      );
      // xóa is_defautl của tất cả các telesale đc chọn
      newArr.forEach((element, index) => {
        newArr.splice(index, 1, { ...element, is_default: 0 });
      });
      // thêm is_default cho telesale vừa đc đánh dấu
      newArr.splice(saleIndex, 1, {
        ...newArr[saleIndex],
        [field]: parseInt(value),
      });
      this.setState({ job_telesales: [...newArr] });
    } else {
      var newArr = [...this.state.job_telesalesWithData];
      var saleIndex = newArr.findIndex(
        element => element.telesale_id === saleId
      );
      // xóa is_defautl của tất cả các telesale đc chọn
      newArr.forEach((element, index) => {
        newArr.splice(index, 1, { ...element, is_default: 0 });
      });
      // thêm is_default cho telesale vừa đc đánh dấu
      newArr.splice(saleIndex, 1, {
        ...newArr[saleIndex],
        [field]: parseInt(value),
      });
      this.setState({ job_telesalesWithData: [...newArr] });
    }
  }

  renderSelectedRow() {
    const saleList = this.props.telesaleState.data;
    const { selectedSale } = this.state;
    const jobDetail = this.props.jobState.data;
    if (selectedSale?.length > 0) {
      return (
        <>
          {/* {saleList?.length && saleList.filter(sale => selectedSale?.includes(sale.id)).map((sale => { */}
          {this.state.job_telesales.map(sale => {
            return (
              <>
                <Row
                  className="align-items-center mt-2"
                  style={{ textAlign: 'end' }}
                >
                  <Col md={5} className="px-0">
                    <span>{sale.full_name}</span>
                  </Col>
                  {!this.state.isCheck_Auto_divide && (
                    <Col md={2} className="px-0">
                      <FormControl
                        type="number"
                        className="d-inline-block mx-xs-0 mx-md-3 px-0 px-md-2"
                        placeholder="Nhập lượng công việc sẽ giao"
                        // value={sale.amount}
                        // onChange={(event) => this.handleSelectedRowChange('amount', event.target.value, sale.telesale_id)}
                        value={
                          jobDetail.job_ratio_custommer_telesales.length
                            ? sale.config_customer
                            : sale.amount
                        }
                        onChange={event => {
                          if (jobDetail.job_ratio_custommer_telesales.length) {
                            this.handleSelectedRowChange(
                              'config_customer',
                              event.target.value,
                              sale.telesale_id
                            );
                          } else {
                            this.handleSelectedRowChange(
                              'amount',
                              event.target.value,
                              sale.telesale_id
                            );
                          }
                        }}
                      ></FormControl>
                    </Col>
                  )}

                  {/* <Col md={1} xs={6} className="d-flex justify-content-center align-items-baseline offset-md-1 px-0">
                  <Checkbox
                    checked={sale?.is_default}
                    onChange={(event) =>
                      this.handleSelectedRowChange('is_default', event.target.checked ? 1 : 0, sale.telesale_id)
                    }
                  />
                  <b className="text-blue pl-2 d-inline-block">Cố định</b>
                </Col> */}
                  <Col md={2} xs={6} className="ml-3">
                    <i
                      className="fas fa-times add-btn text-red"
                      onClick={() => this.removeSelected(sale.telesale_id)}
                    ></i>
                  </Col>
                </Row>
              </>
            );
          })}
        </>
      );
    } else {
      return <Empty description={<span>Không có sale nào được chọn</span>} />;
    }
  }

  renderSelected() {
    return (
      <>
        <Row className="mt-3">
          <Col>
            <b>Danh sách telesale đã chọn</b>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="px-0">
            {this.renderSelectedRow()}
          </Col>
        </Row>
      </>
    );
  }

  renderModal() {
    const { modal } = this.state;
    const sale = this.state.telesaleInfo;
    return (
      <>
        <Modal show={modal} onHide={() => this.handleModalClose()}>
          <Modal.Header closeButton className="text-white btn-info">
            <h5 className="text-light pb-0">Thông tin Sale</h5>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-4 mx-5">
              <Col md={6}>
                <span>Họ và tên</span>
              </Col>
              <Col md={6}>
                <span>{sale && sale.full_name}</span>
              </Col>
            </Row>
            <Row className="mb-4 mx-5">
              <Col md={6}>
                <span>Số điện thoại</span>
              </Col>
              <Col md={6}>
                <span>{sale && sale.phone}</span>
              </Col>
            </Row>
            <Row className="mb-4 mx-5">
              <Col md={6}>
                <span>Email</span>
              </Col>
              <Col md={6}>
                <span>{sale && sale.email}</span>
              </Col>
            </Row>
            <Row className="mb-4 mx-5">
              <Col md={6}>
                <span>KPI</span>
              </Col>
              <Col md={6}>
                <b className="text-blue">
                  {sale && Math.ceil(sale?.kpi_average)} cuộc/ngày
                </b>
              </Col>
            </Row>
            <Row className="mb-4 mx-5">
              <Col md={6}>
                <span>Số cuộc gọi trung bình</span>
              </Col>
              <Col md={6}>
                <b className="text-blue">
                  {sale && Math.ceil(sale?.success_call)} cuộc/ngày
                </b>
              </Col>
            </Row>
            <Row className="mb-4 mx-5">
              <Col md={6}>
                <span>Số cuộc gọi còn lại</span>
              </Col>
              <Col md={6}>
                <b className="text-blue">{sale && sale?.remain_call} cuộc</b>
              </Col>
            </Row>
            <Row className="mb-4 mx-5">
              <Col md={6}>
                <span>Tỷ lệ thành công</span>
              </Col>
              <Col md={6}>
                <b className="text-blue">
                  {sale && Math.ceil(sale?.opportunity_percent)} %
                </b>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button
              variant="danger "
              className="text-light"
              onClick={() => this.handleModalClose()}
            >
              Đóng
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  handleModalClose() {
    this.setState({
      modal: false,
    });
  }

  handleModalOpen(record) {
    this.setState({
      ...this.state,
      modal: true,
      telesaleInfo: record.data,
    });
  }

  handleClickButtonRollback() {
    const { selectedTelemarketing } = this.state;

    if (selectedTelemarketing.length === 0) {
      notifyFail('Vui lòng chọn một sale để thu hồi');
      return;
    } else if (selectedTelemarketing.length === 1) {
      this.setShowModal('roleBack', true);
    } else {
      this.setState({ visibleRollBackModal: true });
    }
  }

  renderHeaderButton() {
    // const saleId = this.state.Checked;
    const { job_dialer_type } = this.props.jobState.data;
    const { selectedTelemarketing } = this.state;
    return (
      <>
        <Row className="mt-2">
          <Col md={4} className="px-0">
            <b>Danh sách Sale đã chọn</b>
          </Col>
          <Col md={8} className="px-0 d-flex justify-content-end">
            <Button
              variant="success"
              onClick={() => this.setShowModal('addModal', true)}
              className="text-light"
            >
              Thêm sale
            </Button>
            <Button
              disabled={job_dialer_type}
              variant="warning"
              onClick={() => {
                if (
                  selectedTelemarketing.length > 1 ||
                  selectedTelemarketing.length === 0
                ) {
                  notifyFail(
                    'Vui lòng chỉ chọn 1 sale để thực hiện chức năng!'
                  );
                } else {
                  this.setShowModal('changeModal', true);
                }
              }}
              className="text-light"
            >
              Đổi sale
            </Button>

            <Button
              disabled={job_dialer_type}
              variant="danger"
              onClick={() => {
                this.handleClickButtonRollback();
              }}
              className="text-light"
            >
              Thu hồi
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  async submitRollBack() {
    // const saleId = this.state.Checked;
    const { selectedTelemarketing } = this.state;
    const jobDetail = this.props.jobState.data;

    if (this.handleCheckCountRollBack()) {
      try {
        if (selectedTelemarketing[0]) {
          const payload = {
            id: selectedTelemarketing[0],
            job_id: this.props.jobState?.data?.job_id,
            count:
              this.state.modalRoleBack?.is_rollback_all == 0
                ? parseInt(this.state?.modalRoleBack?.count || 0)
                : 0,
            is_rollback_all:
              parseInt(this.state?.modalRoleBack?.is_rollback_all) || 0,
            delete_customer_in_group:
              this.state.modalRoleBack.delete_customer_in_group,
          };

          if (jobDetail.job_ratio_custommer_telesales.length) {
            await requestRollBackWithRatio(payload);
          } else {
            await requestRollBackTelesale(payload);
          }

          this.getData();
          this.setState({ Checked: null });
          this.setShowModal('roleBack', false);
          notifySuccess('Thu hồi thành công');
        }
        return;
      } catch (err) {
        notifyFail(err.msg);
      }
    } else {
      notifyFail('Vui lòng nhập số khách hàng lớn hơn 0');
    }
  }

  handleCheckCountRollBack() {
    if (!parseInt(this.state?.modalRoleBack?.is_rollback_all)) {
      if (
        parseInt(this.state.modalRoleBack.count) >= 0 &&
        !isNaN(parseInt(this.state.modalRoleBack.count))
      ) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  }

  async submitExchange() {
    const jobDetail = this.props.jobState.data;
    const targetId = this.state.exSelectedSale[0];
    // const resourceId = this.state.Checked;
    const resourceId = this.state.selectedTelemarketing[0];
    if (!resourceId && resourceId !== 0) {
      notifyFail('Vui lòng chọn sale cần bàn giao lại việc cho.');
      return;
    }
    var newArr = [...this.state.job_telesalesWithData];
    var resourceIndex = newArr.findIndex(ele => ele.telesale_id === resourceId);
    var targetIndex = newArr.findIndex(ele => ele.telesale_id === targetId);
    // if (targetIndex !== -1) {
    //   newArr.splice(targetIndex, 1, {
    //     ...newArr[targetIndex],
    //     telesale_id: targetId,
    //     amount: newArr[resourceIndex].amount,
    //   });
    // } else {
    //   newArr.push({
    //     telesale_id: targetId,
    //     is_default: 0,
    //     amount: newArr[resourceIndex].amount,
    //   });
    // }
    newArr.splice(resourceIndex, 1, { ...newArr[resourceIndex], amount: 0 });
    try {
      // this.props.setLoading(true);

      const payload = {
        job_id: this.props.jobState?.data?.job_id,
        id: resourceId,
        to_telesale_id: targetId,
      };

      if (jobDetail.job_ratio_custommer_telesales.length) {
        await requestChangeTelesaleWithRatio(payload);
      } else {
        await requestChangeTelesale(payload);
      }

      this.setState({
        Checked: null,
        exSelectedSale: [],
        changeModal: false,
      });
      this.getData();
      // this.props.setLoading(false);
    } catch (err) {
      // this.props.setLoading(false);
      notifyFail(err.msg);
    } finally {
      this.setState({
        search: '',
      });
    }
  }

  renderLabelAddModal() {
    const detail = this.state?.data?.status;
    return (
      <Row>
        <Col sm={12} className="px-0">
          <div className="alert alert-secondary">
            <Row className="">
              <Col className="text-center text-md-left">
                <span>Số khách hàng cần tư vấn: </span>
                {/* <b className="text-red">{this.props.jobState?.data?.totalCustomer} khách</b> */}
                <b className="text-red">
                  {this.props?.jobState?.data?.not_assign_customer_enable
                    ? this.state?.data?.customer?.customer_not_assign
                    : (detail &&
                        getItemFromId(
                          detail,
                          TELESALE_JOB_STATUS_ALIAS.NOT_ASSIGNED
                        )?.count) ||
                      '0'}
                  &nbsp;khách
                </b>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  }

  renderButton() {
    return (
      <Row className="mt-3 mb-5">
        <Col xs={12} className="text-center text-md-right px-0">
          <Button
            variant="primary"
            onClick={() => this.props.history.push(ROUTER.JOB)}
            className="text-light"
          >
            Hoàn thành
          </Button>
        </Col>
      </Row>
    );
  }

  renderFooter() {
    return (
      <>
        <RowAntd
          style={{ width: '100%', marginBottom: '15px', marginTop: '10px' }}
          align="middle"
          justify="space-between"
        >
          <ColAntd>
            <ButtonSystemStyle
              onClick={() => {
                this.props.getJobDetail({
                  id: this.props.jobState?.data?.job_id,
                });
                this.props.handleChangeStep(1);
              }}
            >
              Quay lại
            </ButtonSystemStyle>
          </ColAntd>
          <ColAntd>
            <ButtonSystemStyle
              onClick={async () => {
                try {
                  const { id } = this.props.match?.params;
                  const jobDetail = this.props.jobState.data;
                  const {
                    job_telesalesWithData,
                    ratio_code,
                    assign_customer_prioritize_enable,
                  } = this.state;
                  const job_telesale_ratio = job_telesalesWithData.map(i => ({
                    telesale_id: i.telesale_id,
                    config_customer: i.config_customer,
                  }));
                  const { msg } =
                    await requestAddTelemarketingWhenAssignTelemarketingWithRatio(
                      {
                        job_telesale_ratio,
                        job_id: id,
                        is_require_accept: Number(jobDetail.is_require_accept),
                        code: ratio_code,
                        time_repeat: new Date().getTime(),
                        assign_customer_prioritize_enable,
                      }
                    );
                  notifySuccess(msg);
                  this.props.history.push(ROUTER.JOB);
                } catch (error) {
                  console.log('Error :', { error });
                }
              }}
            >
              Hoàn thành
            </ButtonSystemStyle>
          </ColAntd>
        </RowAntd>
      </>
    );
  }

  renderEditSelected() {
    let listSale = this.state?.job_telesalesWithData;
    const jobDetail = this.props.jobState.data;
    const { job_ratio_custommer_telesales } = jobDetail;
    const columns = [
      {
        title: VietnameseString.telemarketing,
        dataIndex: 'full_name',
        key: 'full_name',
      },
      {
        title: 'Đã gọi/Tổng số KH',
        dataIndex: '',
        key: '',
        render: (_, record) => {
          return (
            <div>
              {record.called}/
              {job_ratio_custommer_telesales.length
                ? record.total_customer
                : record.totalSale}
            </div>
          );
        },
      },
      {
        title: (
          <Checkbox
            checked={
              this.state.selectedTelemarketing.length > 0 &&
              this.state.selectedTelemarketing.length === listSale.length
            }
            onChange={event => {
              if (event.target.checked) {
                this.setState({
                  selectedTelemarketing: listSale.map(i => i.id),
                });
              } else {
                this.setState({ selectedTelemarketing: [] });
              }
            }}
          />
        ),
        dataIndex: '',
        key: '',
        render: (_, record) => {
          return (
            <Checkbox
              checked={this.state.selectedTelemarketing.includes(record.id)}
              onChange={event => {
                this.handleSelectedTelemarketing(
                  event.target.checked,
                  record.id
                );
              }}
            />
          );
        },
      },
    ];

    if (job_ratio_custommer_telesales.length) {
      columns.splice(2, 0, {
        title: 'Tỉ lệ',
        dataIndex: 'config_customer',
        key: 'config_customer',
        render: (value, _, index) => {
          return (
            <InputNumber
              style={{ width: '100%' }}
              value={value}
              onChange={value => {
                listSale[index].config_customer = value;
                this.setState({ job_telesalesWithData: listSale });
              }}
            />
          );
        },
      });
    }

    return (
      <div style={{ marginTop: '10px' }}>
        <TableAntStyle
          columns={columns}
          dataSource={listSale}
          loading={this.state.tableLoading}
          scroll={{
            y: `${document.body.clientHeight * 0.5}px`,
          }}
          pagination={false}
        />
      </div>
    );
  }

  renderTimeLoop() {
    return (
      <>
        <Row className="mt-3 mt-2">
          <Col>
            <b>Chia khách hàng theo thời gian:</b>
          </Col>
        </Row>
        <Row>
          <Col>
            <Radio.Group
              onChange={event => {
                this.setState({ ratio_code: event.target.value });
              }}
              value={this.state.ratio_code}
            >
              <Radio value={TIME_LOOP.DATE}>{VietnameseString.date}</Radio>
              <Radio value={TIME_LOOP.WEEK}>{VietnameseString.week}</Radio>
              <Radio value={TIME_LOOP.MONTH}>{VietnameseString.month}</Radio>
            </Radio.Group>
          </Col>
        </Row>
      </>
    );
  }

  renderEmpty() {
    return (
      <Empty
        className="pt-3"
        description={<span>Không có sale nào được chọn</span>}
      />
    );
  }

  handleSelectedTelemarketing(checked, telemarketingId) {
    const selected = this.state.selectedTelemarketing;
    if (checked) {
      selected.push(telemarketingId);
      this.setState({ selectedTelemarketing: selected });
    } else {
      const temp = selected.filter(i => i !== telemarketingId);
      this.setState({ selectedTelemarketing: temp });
    }
  }

  renderSelectedItem(sale) {
    const status = getItemFromId(TELESALE_JOB_STATUS, sale.status);

    return (
      <>
        <Row className="pt-3">
          <Col>
            <span>{sale?.full_name}</span>
            <br />
            <span className={status.color}>({sale?.name})</span>
          </Col>
          <Col style={{ textAlign: 'center' }}>
            <b>
              {sale?.called}/{sale?.totalSale}
            </b>
          </Col>
          {/* <Col className="d-flex justify-content-center align-items-baseline px-0">
            <Checkbox
              value={sale.telesale_id}
              checked={this.state.Checked === sale.id}
              onClick={e => this.setState({ Checked: sale.id })}
            />
          </Col> */}

          <Col className="d-flex justify-content-center align-items-baseline px-0">
            <Checkbox
              checked={this.state.selectedTelemarketing.includes(sale.id)}
              onChange={event => {
                this.handleSelectedTelemarketing(event.target.checked, sale.id);
              }}
            />
          </Col>
        </Row>
      </>
    );
  }

  setShowModal(field, value) {
    var arr = [],
      idArr = [];

    const res = this.state?.job_telesalesWithData;
    if (field === 'addModal') {
      if (res?.length > 0) {
        arr = res?.map(ele => {
          return {
            ...ele,
            is_default: ele.is_required,
            amount: ele.totalSale,
          };
        });
        idArr = res?.map(ele => {
          return ele.telesale_id;
        });
      }
    } else if (field === 'roleBack') {
      const { selectedTelemarketing } = this.state;
      this.setState({
        modalRoleBack: {
          ...this.modalRoleBack,
          id: selectedTelemarketing[0],
          job_id: this.props.jobState?.data?.job_id,
          count: this.state?.modalRoleBack?.count || '',
          is_rollback_all: this.state.modalRoleBack?.is_rollback_all,
        },
      });
    }

    this.setState({
      [field]: value,
    });
  }

  addModal = () => {
    const { addModal } = this.state;
    return (
      <Modal
        show={addModal}
        onHide={() => {
          this.setShowModal('addModal', false);
          this.setState({ search: '' });
        }}
        dialogClassName="modal-90w"
        centered
        scrollable
        size="lg"
        value={addModal}
      >
        <Modal.Header closeButton className="text-white bg-primary">
          <h5 className="text-light pb-0">Thêm sale đảm nhiệm</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          {this.renderLabelAddModal()}
          {this.renderTable()}
          {this.renderSelected()}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="primary"
            disabled={!this.state.isActiveButton}
            onClick={() => this.addSubmit()}
            className="text-light"
          >
            Lưu
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              this.setShowModal('addModal', false);
              this.setState({ search: '', selectedSale: [] }); //clear search sale and clear checkbox sale selected
            }}
            className="text-light"
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  changeModal = () => {
    const { changeModal, Checked } = this.state;

    return (
      <Modal
        show={changeModal}
        onHide={() => {
          this.setState({ search: '' });
          this.setShowModal('changeModal', false);
        }}
        dialogClassName="modal-90w"
        centered
        scrollable
        size="lg"
        value={changeModal}
      >
        <Modal.Header closeButton className="text-white bg-primary">
          <h5 className="text-light">Thay đổi sale đảm nhiệm</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          {this.renderTableExchange()}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="success"
            className="text-light"
            onClick={() => this.submitExchange()}
          >
            Lưu
          </Button>
          <Button
            variant="danger"
            className="text-light"
            onClick={() => {
              this.setShowModal('changeModal', false);
              this.setState({ search: '' });
            }}
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  handleChangeFormRole = (field, value) => {
    const saleId = this.state.Checked;
    this.setState({
      ...this.state,

      modalRoleBack: {
        ...this.state.modalRoleBack,
        id: saleId,
        job_id: this.props.jobState?.data?.job_id,
        [field]: value,
      },
    });
  };

  roleModal = sale => {
    const { roleBack, Checked } = this.state;
    // const saleId = this.state.Checked;
    const saledetail = this.props.jobState;
    const res = this.state?.job_telesalesWithData;
    const { selectedTelemarketing } = this.state;

    return (
      <Modal
        show={roleBack}
        onHide={() => this.setShowModal('roleBack', false)}
        dialogClassName="modal-90w"
        centered
        scrollable
        size="md"
        value={roleBack}
      >
        <Modal.Header closeButton className="text-white bg-primary">
          <h5 className="text-light">Thu hồi dữ liệu khách hàng</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          {res &&
            res.map(sale =>
              sale?.id == selectedTelemarketing[0] ? (
                <>
                  <Row>
                    <Col md={6}>
                      <label>{sale?.full_name}</label>
                    </Col>
                    <Col md={6}>
                      {sale?.not_called}/{sale?.totalSale}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <label>Tổng số khách hàng có thể thu hồi:</label>
                    </Col>
                    <Col md={6}>{sale?.not_called}</Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <label>Thu hồi</label>
                    </Col>
                    <Col md={6}>
                      <Form.Check
                        inline
                        type="radio"
                        label="Tất cả"
                        name="is_rollback_all"
                        className="radio-button tele-radio"
                        value="1"
                        checked={this.state.modalRoleBack?.is_rollback_all == 1}
                        onChange={e =>
                          this.handleChangeFormRole(
                            'is_rollback_all',
                            e.target.value
                          )
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Một phần"
                        name="is_rollback_all"
                        className="radio-button offset-2 tele-radio"
                        value="0"
                        checked={this.state.modalRoleBack?.is_rollback_all == 0}
                        onChange={e =>
                          this.handleChangeFormRole(
                            'is_rollback_all',
                            e.target.value
                          )
                        }
                      />
                    </Col>
                  </Row>
                  {this.state.modalRoleBack?.is_rollback_all == 0 ? (
                    <Row>
                      <Col md={6}></Col>
                      <Col md={6}>
                        <FormControl
                          type="text"
                          name="name"
                          placeholder="Nhập số khách hàng"
                          value={this.state?.modalRoleBack?.count}
                          onChange={e =>
                            this.handleChangeFormRole('count', e.target.value)
                          }
                        ></FormControl>
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )}

                  <Checkbox
                    checked={this.state.modalRoleBack.delete_customer_in_group}
                    onChange={e => {
                      this.handleChangeFormRole(
                        'delete_customer_in_group',
                        Number(e.target.checked)
                      );
                    }}
                  >
                    Xoá khách hàng ra khỏi nhóm và giao việc
                  </Checkbox>
                </>
              ) : (
                ''
              )
            )}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="success"
            className="text-light"
            onClick={() => this.submitRollBack()}
          >
            Xác nhận
          </Button>
          <Button
            variant="danger"
            className="text-light"
            onClick={() => this.setShowModal('roleBack', false)}
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  render() {
    const jobDetail = this.props.jobState.data;
    // console.log("isLoading", this.state.tableLoading)
    return (
      <>
        {this.renderHeader()}
        {this.renderLabel()}
        {this.renderSubJobInfo()}
        <div style={{ margin: '15px 0 10px' }}>
          <InputSearch
            lengthResult={this.state?.job_telesalesWithData.length}
            onSearch={this.onSearchInputSaleTakeCare}
          />
        </div>

        {this.renderHeaderButton()}
        {this.renderEditSelected()}
        {jobDetail.job_ratio_custommer_telesales.length ? (
          this.renderTimeLoop()
        ) : (
          <></>
        )}
        {/* {this.renderTable()} */}
        {/* {this.renderSelected()} */}
        {this.renderModal()}
        {/* {this.renderButton()} */}
        {this.renderFooter()}
        {this.addModal()}
        {this.changeModal()}
        {this.roleModal()}

        {this.state.visibleRollBackModal ? (
          <RollbackModal
            visible={this.state.visibleRollBackModal}
            handleCancel={() => {
              this.setState({ visibleRollBackModal: false });
              this.getData();
            }}
            selectedTelemarketing={this.state.selectedTelemarketing}
            listSale={this.state?.job_telesalesWithData}
          />
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  telesaleState: state.TelesaleReducer,
  jobState: state.jobDetailReducer,
});

const mapDispatchToProps = {
  getTelesaleList,
  getJobDetail,
  clearJobDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Assign));
