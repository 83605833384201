import { WrapperSelectHotlineStyled } from '@components/Header';
import React from 'react';
import HotlineImage from '@assets/hotline.png';
import { DownOutlined } from '@ant-design/icons';
import { SelectHotlineStyled } from '@screens/Sale/Call/NumberPad/style';

const SelectHotLine = ({
  openDropDown,
  openDropDownFn,
  onChangeListHotLine,
  listHotLine,
  valueDropDown,
}) => {
  return (
    <WrapperSelectHotlineStyled onClick={openDropDownFn}>
      <div className="icon_phone">
        <img src={HotlineImage} alt="HotlineImage" />
        <div className="icon_down">
          <DownOutlined />
        </div>
      </div>

      <SelectHotlineStyled
        open={openDropDown}
        bordered={false}
        showArrow={false}
        size="small"
        value={Number(valueDropDown)}
        style={{ minWidth: '120px' }}
        onChange={value => {
          onChangeListHotLine(value);
        }}
        options={listHotLine}
      />
    </WrapperSelectHotlineStyled>
  );
};

export default SelectHotLine;
