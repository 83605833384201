import React, { Component } from 'react';
import '@styles/RegisterRequest.css';
import { Row, Col, FormControl } from 'react-bootstrap';
import { notifyFail } from '@utils/notify';
import ReactDOM from 'react-dom';
import { Checkbox, Select } from 'antd';
import { requestGetUserInfo, requestJobFilter } from '@constants/Api';
import { getJobDetail } from '@actions/index';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FORWARD_ANSWER } from '@screens/Enterprise/Job/Constants';

const { Option } = Select;

const ContainerStyled = styled.div`
  display: flex;
  align-items: center;
`;

const FormInputStyled = styled.div`
  flex: 1;
`;

const CheckboxStyled = styled(Checkbox)`
  span {
    font-size: 12px;
  }
`;

class StepAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: false,
      transitionWork: [],
      forwardJobId: '',
    };
    this.getJobsEnterprise();
  }

  handleDoubleClick(e) {
    this.setState(
      {
        input: true,
      },
      () => this.refs.optionInput.focus()
    );
  }

  checkDuplicateOption(index) {
    // var job_form_answers = this.props.value?.job_form_answers;
    // var duplicate = job_form_answers?.filter(
    //   (ele) =>
    //     ele.name === job_form_answers[index].name &&
    //     ele.name !== "Nhập thông tin vào đây"
    // );
    // if (duplicate?.length > 1) {
    //   notifyFail("Các đáp án không được trùng nhau.");
    //   return false;
    // }
    return true;
  }

  handleOnBlur(e, index) {
    var isPassed = this.checkDuplicateOption(index);
    if (!isPassed) {
      e.target.focus();
      return;
    }
    this.setState({
      input: false,
    });
  }

  handleTextChange(e) {
    const newOption = this.props.option.map((item, index) =>
      index === this.props.index
        ? { ...item, name: e.target.value, is_active: 1 }
        : item
    );
    this.props.handle('job_form_answers', newOption);
  }

  remove(targetIndex) {
    const newOption = [...this.props.option];
    newOption.splice(targetIndex, 1);
    this.props.handle('job_form_answers', newOption);
  }

  async getJobsEnterprise() {
    const res = await requestGetUserInfo();
    const result = await requestJobFilter({
      enterprise_id: res.data.user_id,
      telesale_id: '',
      job_category_id: '',
      limit: '',
    });
    this.setState({
      ...this.state,
      transitionWork: result.data,
    });
  }

  handleCheckForwardJob(event) {
    const { option, index, handle } = this.props;

    const indexItemForwardJob = option.findIndex(
      item => item.enterprise_forward_answer_enabled
    );

    if (indexItemForwardJob !== -1) {
      option[indexItemForwardJob].enterprise_forward_answer_enabled =
        FORWARD_ANSWER.NONE;
    }

    const newOption = option.map((item, idx) =>
      idx === index
        ? {
            ...item,
            enterprise_forward_answer_enabled: Number(event.target.checked),
            is_active: 1,
          }
        : item
    );
    handle('job_form_answers', newOption);
  }

  render() {
    var style =
      document.activeElement === ReactDOM.findDOMNode(this.refs.optionInput)
        ? 'border border-primary'
        : null;
    const { jobState, content, job_dialer_type, index } = this.props;

    let disable = false;

    if (index === 0 && job_dialer_type === 1) {
      disable = true;
    }
    if (this.state.forwardJobId === '' && content.forward_job_id) {
      this.setState({ forwardJobId: content.forward_job_id });
    }

    return (
      <ContainerStyled>
        <FormInputStyled
          className={`assessment option-frame d-flex mb-2 ${style}`}
        >
          <Row className="w-100">
            <Col
              md={12}
              className="d-flex justify-content-between align-items-center"
            >
              {this.state.input ? (
                <FormControl
                  ref="optionInput"
                  disabled={disable}
                  style={{ marginTop: '5px', marginRight: '5px' }}
                  type="text"
                  className="border-0"
                  onBlur={e => this.handleOnBlur(e, this.props.index)}
                  value={this.props.content.name}
                  onChange={e => this.handleTextChange(e)}
                  onFocus={e => {
                    this.forceUpdate(() => {
                      this.refs.optionInput.select();
                      if (
                        this.refs.optionInput.value === 'Nhập thông tin vào đây'
                      ) {
                        this.refs.optionInput.value = '';
                      }
                    });
                  }}
                ></FormControl>
              ) : (
                <div className="w-100" onClick={e => this.handleDoubleClick(e)}>
                  <span className="d-block px-3">
                    {this.props.content.name}
                  </span>
                </div>
              )}
              <i
                className={`fas fa-times add-btn text-red ${
                  this.props.isYesNoQues ? 'not-allowed' : 'cursor'
                }`}
                onClick={
                  this.props.isYesNoQues
                    ? null
                    : () => this.remove(this.props.index)
                }
              ></i>
            </Col>

            {this.props?.value?.job_dialer_type === 1 && (
              <Col md={12} className="d-flex align-items-center">
                <Select
                  placeholder="Chọn công việc chuyển tiếp"
                  style={{ padding: '5px 5px', width: '100%' }}
                  allowClear
                  value={this.state.forwardJobId}
                  onChange={(value, option) => {
                    const newOption = this.props.option.map((item, index) =>
                      index === this.props.index
                        ? { ...item, forward_job_id: value }
                        : item
                    );
                    this.setState({ forwardJobId: value });
                    this.props.handle('job_form_answers', newOption);
                  }}
                >
                  {this.state.transitionWork?.map((job, idx) => (
                    <Option key={idx} value={job.id}>
                      {job.name}
                    </Option>
                  ))}
                </Select>
              </Col>
            )}
          </Row>
        </FormInputStyled>

        {this.props.forwardJobEnable ? (
          <CheckboxStyled
            checked={Boolean(
              this.props.content.enterprise_forward_answer_enabled
            )}
            onChange={this.handleCheckForwardJob.bind(this)}
          >
            Đồng bộ
          </CheckboxStyled>
        ) : (
          <></>
        )}
      </ContainerStyled>
    );
  }
}

const mapStateToProps = state => ({
  jobState: state.jobDetailReducer,
});

const mapDispatchToProps = {
  getJobDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(StepAdd);
// export default StepAdd;
