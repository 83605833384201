import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/vi_VN';
import {
  Avatar,
  Breadcrumb,
  Col,
  DatePicker,
  Empty,
  Popconfirm,
  Row,
  Select,
  Table,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy,
  faEdit,
  faTrashAlt,
} from '@fortawesome/fontawesome-free-regular';

import { useCancellableApi } from 'src/hooks/useCancellableApi';
import {
  requestGetSetupKPI,
  requestDeleteSetupKPI,
  requestCheckCopySetupKPI,
} from './services/api';
import {
  requestGetAllDepartment,
  requestGetTelemarketingInDepartment,
} from '@constants/Api';
import { ROUTER } from '@constants/Constant';
import { VietnameseString } from '@constants/VietnameseString';
import {
  getSearchParamsInUrl,
  randomColor,
  formatPrice,
  rmVN,
  setSearchParamsInUrlVer2,
} from '@constants/funcHelper';

import Wrapper from '@components/ui/wrapper/Wrapper';
import { greenColor, greenColorHover } from '@styles/Color';
import { BreadcrumbAntStyle, ButtonSystemStyle } from '@styles/Style';
import { notifySuccess } from '@utils/notify';
import mixpanel from 'mixpanel-browser';

const TelemarketingNameStyle = styled.div`
  font-weight: 700;
  /* &:hover {
    color: #52c41a;
  } */
`;

export default function SetupKPI() {
  const history = useHistory();

  const apiGetSetupKPI = useCancellableApi(requestGetSetupKPI);

  const [telemarketing, setTelemarketing] = useState([]);
  const [departments, setDepartments] = useState([]);

  const [KPI, setKPI] = useState([]);

  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 12,
  });

  const [param, setParam] = useState({
    telemarketing_id: [],
    department_id: [],
    from: moment(new Date()).startOf('month').format('YYYY/MM/DD'),
    to: moment(new Date()).endOf('month').format('YYYY/MM/DD'),
    page: paging.current,
    limit: paging.pageSize,
  });

  const [isLoadingTable, setIsLoadingTable] = useState(false);

  const columns = [
    {
      title: 'Sale',
      fixed: 'left',
      dataIndex: 'full_name',
      key: 'full_name',
      render: (fullName, record, index) => {
        try {
          const name = fullName.trim().split(' ');
          return (
            <div style={{ textAlign: 'left' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  {fullName && (
                    <Avatar
                      src={record.avatar && record.avatar}
                      style={{
                        backgroundColor: randomColor(
                          name[name.length - 1].charAt(0)?.toUpperCase()
                        ),
                        marginRight: '5px',
                      }}
                      size="small"
                    >
                      {name[name.length - 1].charAt(0)?.toUpperCase()}
                    </Avatar>
                  )}
                </div>
                <div>
                  <TelemarketingNameStyle>
                    {fullName ? fullName : '--'}
                  </TelemarketingNameStyle>
                </div>
              </div>
            </div>
          );
        } catch (error) {}
      },
    },
    {
      title: VietnameseString.call_count_KPI,
      dataIndex: 'kpi_call',
      key: 'kpi_call',
      render: (kpiCall, record, index) => (
        <>{`${record.total_calls ? record.total_calls : '--'}/${
          kpiCall ? kpiCall : '--'
        }`}</>
      ),
    },
    {
      title: VietnameseString.opportunity_KPI,
      dataIndex: 'kpi_opportunity',
      key: 'kpi_opportunity',
      render: (kpiOpportunity, record, index) => (
        <>{`${record.total_opportunity ? record.total_opportunity : '--'}/${
          kpiOpportunity ? kpiOpportunity : '--'
        }`}</>
      ),
    },
    {
      title: VietnameseString.new_customer_KPI,
      dataIndex: 'kpi_new_customer',
      key: 'kpi_new_customer',
      render: (kpiNewCustomer, record, index) => (
        <>{`${record.total_new_customers ? record.total_new_customers : '--'}/${
          kpiNewCustomer ? kpiNewCustomer : '--'
        }`}</>
      ),
    },
    {
      title: VietnameseString.sales_KPI,
      dataIndex: 'kpi_sales',
      key: 'kpi_sales',
      render: (kpiSales, record, index) => (
        <>
          {record.sales_contract
            ? formatPrice(record.sales_contract) + ' đ'
            : '--'}
          /{kpiSales ? formatPrice(kpiSales) + ' đ' : '--'}
        </>
      ),
    },
    {
      title: VietnameseString.revenue_KPI,
      dataIndex: 'kpi_revenue',
      key: 'kpi_revenue',
      render: (kpiRevenue, record, index) => (
        <>
          {record.revenue_contract
            ? formatPrice(record.revenue_contract) + ' đ'
            : '--'}
          /{kpiRevenue ? formatPrice(kpiRevenue) + ' đ' : '--'}
        </>
      ),
    },
    {
      title: '',
      width: 100,
      render: (text, record, index) => (
        <div
          style={{ display: 'flex', justifyContent: 'space-around' }}
          onClick={event => event.stopPropagation()}
        >
          <FontAwesomeIcon
            icon={faEdit}
            style={{ color: 'blue' }}
            onClick={e => {
              e.stopPropagation();
              history.push({
                pathname: ROUTER.EDIT_KPI_SETUP,
                search: `id=${record.user_sale_kpi_id}`,
              });
            }}
          />

          <FontAwesomeIcon
            icon={faCopy}
            style={{ color: 'green' }}
            onClick={async e => {
              e.stopPropagation();

              const result = await requestCheckCopySetupKPI(
                record.user_sale_kpi_id
              );

              if (result.type_obj === 'next') {
                mixpanel.track(`Copied KPI id: ${record.user_sale_kpi_id}`);
                notifySuccess(
                  'Tháng tiếp theo đã được thiết lập! Chuyển tới chi tiết của tháng tiếp theo.'
                );
                history.push({
                  pathname: ROUTER.KPI_DETAIL,
                  search: `id=${result.data.id}`,
                });
              } else {
                history.push({
                  pathname: ROUTER.CREATE_KPI_SETUP,
                  state: { id: record.user_sale_kpi_id, action: 'action-copy' },
                });
              }
            }}
          />

          <Popconfirm
            placement="bottomRight"
            title={VietnameseString.are_you_sure_you_want_to_delete}
            onConfirm={async e => {
              try {
                mixpanel.track(`Delete KPI id: ${record.user_sale_kpi_id}`);
                e.stopPropagation();
                await requestDeleteSetupKPI(record.user_sale_kpi_id);
                getSetupKPI(param);
              } catch (error) {}
            }}
            okText={VietnameseString.agree}
            cancelText={VietnameseString.cancel}
          >
            <FontAwesomeIcon
              icon={faTrashAlt}
              style={{ color: 'red', fontWeight: 700 }}
              onClick={e => {
                e.stopPropagation();
              }}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const searchParamsInUrl = getSearchParamsInUrl();
    setParam({ ...param, ...searchParamsInUrl });
  }, []);

  const getSetupKPI = async payload => {
    try {
      setIsLoadingTable(true);
      const result = await apiGetSetupKPI({
        telemarketing_id: payload.telemarketing_id,
        department_id: payload.department_id,
        from: payload.from,
        to: payload.to,
        page: payload.page,
        limit: payload.limit,
      });
      setKPI(result.data);

      setPaging({
        total: parseFloat(result.paging.totalItemCount),
        current: parseFloat(result.paging.page),
        pageSize: parseFloat(result.paging.limit),
      });
    } catch (error) {
    } finally {
      setIsLoadingTable(false);
    }
  };

  useEffect(() => {
    if (KPI.length === 0) {
      if (paging.current > 1) {
        setSearchParamsInUrlVer2({ page: paging.current - 1 });
        setParam({ ...param, page: paging.current - 1 });
      }
    }
  }, [KPI]);

  useEffect(() => {
    getSetupKPI(param);
  }, [param]);

  const handleChangeParam = (key, value) => {
    if (key !== 'department_id') {
      mixpanel.track(`Filter KPI: telesale_id: ${value}`);
      setParam({
        ...param,
        [key]: value,
        page: 1,
      });
      setSearchParamsInUrlVer2({ [key]: value, page: 1 });
    } else {
      mixpanel.track(`Filter KPI: department_id: ${value}`);
      setParam({
        ...param,
        [key]: value,
        telemarketing_id: [],
        page: 1,
      });
      setSearchParamsInUrlVer2({ [key]: value, telemarketing_id: [], page: 1 });
    }
  };

  const getAllTelemarketing = async () => {
    try {
      const result = await requestGetTelemarketingInDepartment(
        param.department_id
      );
      const telemarketing =
        result?.data?.map(sale => ({
          ...sale,
          value: sale.id,
          label: sale.full_name,
        })) || [];
      setTelemarketing(telemarketing);
    } catch (error) {}
  };

  const getAllDepartment = async () => {
    try {
      const result = await requestGetAllDepartment();
      setDepartments(result.data);
    } catch (error) {}
  };

  useEffect(() => {
    getAllDepartment();
  }, []);

  useEffect(() => {
    getAllTelemarketing();
  }, [param.department_id]);

  return (
    <div className="content-wrapper">
      <BreadcrumbAntStyle>
        <Breadcrumb.Item>{VietnameseString.KPI}</Breadcrumb.Item>
      </BreadcrumbAntStyle>

      <Wrapper>
        <Row gutter={[16, 16]}>
          <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
            <Select
              style={{ width: '100%' }}
              mode="multiple"
              optionFilterProp="children"
              filterOption={(input, option) => {
                return rmVN(option.children).indexOf(rmVN(input)) >= 0;
              }}
              placeholder={VietnameseString.telemarketing}
              allowClear
              maxTagCount={1}
              value={param.telemarketing_id}
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>{VietnameseString.no_data}</span>}
                />
              }
              onChange={value => {
                handleChangeParam('telemarketing_id', value);
              }}
            >
              {telemarketing.map((item, index) => (
                <Select.Option key={index} value={item.id}>
                  {item.full_name}
                </Select.Option>
              ))}
            </Select>
          </Col>

          <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
            <Select
              style={{ width: '100%' }}
              mode="multiple"
              optionFilterProp="children"
              filterOption={(input, option) => {
                return rmVN(option.children).indexOf(rmVN(input)) >= 0;
              }}
              placeholder={VietnameseString.department}
              onClear={() => {
                handleChangeParam('telemarketing_id', []);
              }}
              allowClear
              maxTagCount={1}
              value={param.department_id}
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>{VietnameseString.no_data}</span>}
                />
              }
              onChange={value => {
                handleChangeParam('telemarketing_id', []);
                handleChangeParam('department_id', value);
              }}
            >
              {departments.map((item, index) => (
                <Select.Option key={index} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Col>

          <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
            <DatePicker
              allowClear={false}
              style={{ width: '100%' }}
              locale={locale}
              format={'MM/YYYY'}
              value={moment(param.from)}
              onChange={date => {
                setSearchParamsInUrlVer2({
                  from: moment(date).startOf('month').format('YYYY/MM/DD'),
                  to: moment(date).endOf('month').format('YYYY/MM/DD'),
                  page: 1,
                });
                mixpanel.track(
                  `Filter KPI from: ${moment(date)
                    .startOf('month')
                    .format('YYYY/MM/DD')}/ to: ${moment(date)
                    .endOf('month')
                    .format('YYYY/MM/DD')}`
                );
                setParam({
                  ...param,
                  from: moment(date).startOf('month').format('YYYY/MM/DD'),
                  to: moment(date).endOf('month').format('YYYY/MM/DD'),
                  page: 1,
                });
              }}
              picker="month"
            />
          </Col>

          <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
            <ButtonSystemStyle
              bgButtonColor={greenColor}
              bgButtonColorHover={greenColorHover}
              onClick={() => {
                history.push(ROUTER.CREATE_KPI_SETUP);
              }}
            >
              {VietnameseString.setup_KPI}
            </ButtonSystemStyle>
          </Col>
        </Row>

        <div style={{ margin: '10px 0px' }}>
          <h6>{`Danh sách KPI tháng ${moment(param.from).format('MM')}`}</h6>
        </div>

        <Table
          loading={isLoadingTable}
          dataSource={KPI}
          columns={columns}
          pagination={{
            ...paging,
            onChange: (page, pageSize) => {
              setParam({ ...param, page });
              setSearchParamsInUrlVer2({ page });
            },
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                mixpanel.track(
                  `Go to detail KPI id: ${record.user_sale_kpi_id}`
                );
                history.push({
                  pathname: ROUTER.KPI_DETAIL,
                  search: `id=${record.user_sale_kpi_id}`,
                });
              },
            };
          }}
          scroll={{ x: 'max-content' }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu.</span>}
              />
            ),
            triggerDesc: '',
            triggerAsc: '',
            cancelSort: '',
          }}
        />
      </Wrapper>
    </div>
  );
}
