import React from 'react';
import PropTypes from 'prop-types';

import { VietnameseString } from '@constants/VietnameseString';

import { PopupStyled } from './PopupStyled';

function PopupWarning(props) {
  const { visible, content, confirmationContent, okText, cancelText, onOk, onCancel } = props;

  return (
    <PopupStyled visible={visible}>
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <h4>
              <i className="fas fa-exclamation-triangle"></i>
              {VietnameseString.warning}
            </h4>
            {/* <label for="control-modal" className="modal-close">
              X
            </label> */}
          </div>

          <div className="modal-body">
            <p>{content}</p>
            <p>{confirmationContent}</p>
          </div>

          <div className="modal-footer">
            <button onClick={onOk}>{okText}</button>
            <button onClick={onCancel}>{cancelText}</button>
          </div>
        </div>
      </div>
    </PopupStyled>
  );
}

PopupWarning.propTypes = {
  visible: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  confirmationContent: PropTypes.string,
  okText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
};

export default PopupWarning;
