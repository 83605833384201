// const ROUTER = {
//     HOME: "/home",
//     USER: "/user",
//   };

// export {
//   ROUTER
// }

const ROUTER = {
  PAGE_404: '/PageNotFound',
  FORGET_PASSWORD: '/quen-mat-khau',
  HOME: '/tong-quan',
  ACCOUNT: '/tai-khoan',
  CUSTOMER: '/khach-hang',
  CUSTOMER_DETAIL: '/chi-tiet-khach-hang',
  CUSTOMER_GROUP: '/nhom-khach-hang',
  CUSTOMER_GROUP_ADD: '/them-nhom-khach-hang',
  CUSTOMER_GROUP_EDIT: '/sua-nhom-khach-hang',
  CUSTOMER_GROUP_DETAIL: '/chi-tiet-nhom-khach-hang',
  DEPARTMENT: '/phong-ban',
  ADD_DEPARTMENT: '/them-phong-ban',
  UPDATE_DEPARTMENT: '/cap-nhat-phong-ban',
  DEPARTMENT_DETAIL: '/chi-tiet-phong-ban',
  CALL: '/cuoc-goi',
  CALL_DETAIL: '/chi-tiet-cuoc-goi',
  CALL_CENTER: '/tong-dai',
  COMPANY: '/cong-ty',
  COMPANY_ADD: '/them-cong-ty',
  COMPANY_EDIT: '/sua-cong-ty',
  COMPANY_DETAIL: '/chi-tiet-cong-ty',
  SMS_CONFIGURATION_DETAIL: '/chi-tiet-cau-hinh-SMS',
  CORPORATION: '/doanh-nghiep',
  JOB: '/cong-viec',
  JOB_RESULT: '/ket-qua-cong-viec',
  JOB_RESULT_CHILD: '/ket-qua-cong-viec-con',
  JOB_ADD: '/them-cong-viec',
  JOB_EDIT: '/sua-cong-viec',
  JOB_GENERAL_ADD: '/tao-cong-viec-con',
  JOB_GENERAL_EDIT: '/sua-cong-viec-con',
  OVERVIEW: '/tong-quan',
  REGISTER_REQUEST: '/yeu-cau-dang-ky',
  REGISTER_REQUEST_DETAIL: '/chi-tiet-yeu-cau',
  REGISTER_REQUEST_REJECTED: '/yeu-cau-bi-tu-choi',
  REGISTER_REQUEST_REJECTED_DETAIL: '/chi-tiet-yeu-cau-bi-tu-choi',
  STATISTIC_JOB: '/thong-ke-cong-viec',
  STATISTIC_KPI: '/ket-qua-KPI',
  KPI: '/KPI',
  CREATE_KPI_SETUP: '/thiet-lap-KPI',
  EDIT_KPI_SETUP: '/sua-thiet-lap-KPI',
  KPI_PROGRESS: '/tien-do-KPI',
  KPI_DETAIL: '/chi-tiet-KPI',
  REPORT: '/bao-cao-danh-gia',
  REPORT_DETAIL: '/chi-tiet-bao-cao',
  SETTINGS_ADMIN: '/cau-hinh-admin',
  CALL_LOG: '/call-log',
  SETTINGS: '/cau-hinh',
  GENERAL_CONFIG: '/cau-hinh-chung',
  JOB_CONFIG: '/cau-hinh-cong-viec',
  TELESALE: '/sale',
  TELESALE_ADD: '/them-sale',
  TELESALE_DETAIL: '/chi-tiet-sale',
  TELESALE_EDIT: '/sua-sale',
  TELESALE_INACTIVE: '/sale-ngung-hoat-dong',
  TELESALE_INACTIVE_DETAIL: '/chi-tiet-sale-ngung-hoat-dong',
  TUTORIAL: '/huong-dan',
  ADD_TUTORIAL: '/them-huong-dan',
  TUTORIAL_DETAIL: '/chi-tiet-huong-dan',
  EVALUATION_STAFF: '/nhan-vien-danh-gia',
  OPPORTUNITY: '/co-hoi',
  OCCASION_DETAIL: '/chi-tiet-co-hoi',
  ASSIGN: '/giao-viec',
  EVALUATION_RESULT: '/ket-qua-danh-gia',
  EVALUATION_STAFF_DETAIL: '/chi-tiet-nhan-vien-danh-gia',
  ADD_CUSTOMER: '/them-khach-hang',
  EDIT_CUSTOMER: '/sua-khach-hang',

  REPORT_JOB: '/bao-cao-cong-viec',
  REPORT_TELESALE: '/bao-cao-sale',
  REPORT_EVALUATE: '/bao-cao-danh-gia',
  REPORT_OPPORTUNITY: '/bao-cao-co-hoi',
  REPORT_CALL: '/bao-cao-cuoc-goi',
  BUSINESS_REPORT: '/bao-cao-doanh-so',
  LOCATION_REPORT: '/bao-cao-vi-tri',
  LOCATION_REPORT_DETAIL: '/chi-tiet-bao-cao-vi-tri',
  MESSAGE_REPORT: '/bao-cao-tin-nhan',

  HOMEPAGE: '/trang-chu',
  JOB_REVIEWER: '/cong-viec-reviewer',
  JOB_REVIEWER_DETAIL: '/chi-tiet-cong-viec-reviewer',
  CALL_DETAIL_REVIEWER: '/chi-tiet-cuoc-goi-reviewer',
  EVALUATE_HISTORY: '/lich-su-danh-gia',
  INFORMATION: '/thong-tin-ca-nhan',
  EVALUATE_JOB_DETAIL: '/chi-tiet-cong-viec-danh-gia',

  // web sale paths
  OVERVIEW_SALE: '/tong-quan-sale',
  JOB_SALE: '/cong-viec-cua-toi',
  JOB_DETAIL_SALE: '/chi-tiet-cong-viec-cua-toi',
  JOB_RESULT_SALE: '/ket-qua-cong-viec-cua-toi',
  JOB_TEST_SALE: '/bai-kiem-tra',
  PENDING_JOB_SALE: '/cong-viec-cho-nhan',
  CUSTOMER_SALE: '/khach-hang-sale',
  UPDATE_CUSTOMER_SALE: '/cap-nhat-khach-hang',
  CUSTOMER_DETAIL_SALE: '/chi-tiet-khach-hang-sale',
  CALL_HISTORY_SALE: '/lich-su-cuoc-goi-sale',
  CALL_DETAIL_SALE: '/chi-tiet-cuoc-goi-sale',
  CALLING_SALE: '/dang-goi',
  RECALL_SALE: '/goi-lai-sale',
  NUMBER_PAD: '/ban-phim',
  OPPORTUNITY_SALE: '/co-hoi-sale',
  OPPORTUNITY_DETAIL_SALE: '/chi-tiet-co-hoi-sale',
  JOB_CUSTOMER_DETAIL_SALE: '/chi-tiet-khach-hang-da-giao-viec',
  E_VOUCHER: '/e-voucher',
  ADD_PROMOTIONS: '/them-chuong-trinh-khuyen-mai',
  EDIT_PROMOTIONS: '/sua-chuong-trinh-khuyen-mai',
  ZALO_OA_DETAIL: '/danh-sach-template',
  ZALO_LOGIN: '/zalo-login',
  DETAIL_TEMPLATE_ZNS: '/chi-tiet-mau-tin-nhan-zalo',
};

const CALL_ERROR_CODE = {
  NOT_INIT: -1,
  SUCCESS: 0,
  ANSWER_URL_EMPTY: 1,
  FROM_NUMBER_NOT_FOUND: 4,
  FROM_NUMBER_NOT_BELONG_YOUR_ACCOUNT: 5,
  NOT_ENOUGH_MONEY: 8,
  FROM_NUMBER_OR_TO_NUMBER_INVALID_FORMAT: 10,
  FROM_NUMBER_NOT_BELONG_YOUR_PROJECT: 15,
  TO_NUMBER_INVALID: 14,
};

const FIELD_IMPORT = {
  DATE_TIME: 'date_time',
  OPTIONS: 'options',
  NUMBER: 'number',
  TEXT: 'text',
};

const STRING = {
  effective_time: 'Thởi gian',
  search: 'Tìm kiếm',
  add: 'Thêm mới',
  clearSearch: 'Xóa tìm kiếm',
  title: 'Tiêu đề bài viết',
  fromDate: 'Từ ngày',
  toDate: 'Đến ngày',
  index: 'STT',
  creater: 'Người tạo',
  dateCreated: 'Ngày tạo',
  displayIndex: 'Thứ tự hiển thị',
  status: 'Trạng thái',
  dob: 'Ngày sinh',
  phoneNumber: 'Số điện thoại',
  email: 'Email',
  gender: 'Giới tính',
  city: 'Thành phố',
  identicalId: 'Số CMND',
  income: 'Thu nhập mong muốn',
  experience: 'Kinh nghiệm',
  profile: 'Hồ sơ',
  realAddress: 'Địa chỉ cụ thể',
  description: 'Mô tả',
  avatar: 'Ảnh đại diện',
  content: 'Nội dung',
  saveDraft: 'Lưu nháp',
  save: 'Lưu',
  post: 'Đăng bài',
  cancel: 'Hủy',
  exit: 'Thoát',
  job: 'Công việc',
  customer_data: 'Dữ liệu khách hàng',
  customer_attribute: 'Thuộc tính khách hàng',
  occasion_type: 'Loại cơ hội',
  telesale: 'Sale',
  time: 'Thời gian',
  change_to_date: 'Ngày chuyển nhu cầu',
  full_name: 'Họ tên',
  province_city: 'Tỉnh/Thành phố',
  product_type: 'Loại sản phẩm',
  note: 'Ghi chú',
  revenue: 'Doanh thu',
  sale: 'Doanh số',
  key_word: 'Từ khóa',
  to_Vpoint: 'Quy đổi Vpoint',
  checkin: 'Loại điểm danh',
  start_call_time: 'Thời gian',
  startDate: 'Bắt đầu',
  endDate: 'Kết thúc',
  re_time: 'Thời gian gọi lại',

  general_information: 'Thông tin chung',
  the_script: 'Kịch bản',
  result_card: 'Phiếu kết quả',
  educate: 'Đào tạo',
  exam: 'Đề thi',
};

const CALL_STATUS_STRING = {
  NOT_ANSWER: 'Không nghe máy',
  AGREE: 'Đồng ý trao đổi',
};

const NUMBER = {
  page_limit: 12,
};

const SALE_STATUS = [
  {
    id: 1,
    name: 'Chờ duyệt',
    color: 'text-warning',
  },
  {
    id: 2,
    name: 'Bộ phận quản trị duyệt',
    color: 'text-primary',
  },
  {
    id: 3,
    name: 'Bộ phận quản trị từ chối',
    color: 'text-danger',
  },
  {
    id: 4,
    name: 'Bộ phận chuyên môn chấp thuận',
    color: 'text-primary',
  },
  {
    id: 5,
    name: 'Bộ phận chuyên môn từ chối',
    color: 'text-danger',
  },
  {
    id: 6,
    name: 'Bộ phận nhân sự chấp thuận',
    color: 'text-success',
  },
  {
    id: 7,
    name: 'Bộ phận nhân sự từ chối',
    color: 'text-danger',
  },
];

const CALLING_STATUS = {
  calling: 'calling',
  started: 'started',
  answered: 'answered',
  ringing: 'ringing',
  ended: 'ended',
  busy: 'busy',
};

const SALE_CALL_TYPE = {
  callAway: 1,
  incomingCall: 2,
};

const SALE_STATUS_WAITING = [
  {
    id: 1,
    name: 'Chờ duyệt',
    color: 'text-warning',
  },
  {
    id: 2,
    name: 'bộ phận quản trị duyệt',
    color: 'text-primary',
  },
  {
    id: 4,
    name: 'bộ phận chuyên môn chấp thuận',
    color: 'text-primary',
  },
  {
    id: 6,
    name: 'bộ phận nhân sự chấp thuận',
    color: 'text-success',
  },
];

const SALE_STATUS_REJECT = [
  {
    id: 3,
    name: 'bộ phận quản trị từ chối',
    color: 'text-danger',
  },
  {
    id: 5,
    name: 'bộ phận chuyên môn từ chối',
    color: 'text-danger',
  },
  {
    id: 7,
    name: 'bộ phận nhân sự từ chối',
    color: 'text-danger',
  },
];

const SALE_STATUS_ALIAS = {
  WAITING: 1,
  ADMIN_ACCEPT: 2,
  ADMIN_REJECT: 3,
  PROFESSION_MANAGER_ACCEPT: 4,
  PROFESSION_MANAGER_REJECT: 5,
  HR_ACCEPT: 6,
  HR_REJECT: 7,
};

const ACCEPT_TYPE = {
  IMAGE: ['image/jpeg', 'image/jpg', 'image/png'],
  DOCUMENT: [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'video/mp4',
    'audio/mpeg',
    'video/3gpp',
  ],
};

const USER_ROLE = {
  ADMIN: 1,
  ENTERPRISE: 2,
  PROFESSION_MANAGER: 3,
  HR_MANAGER: 4,
  SALE: 5,
  REVIEWER: 6,
  TELESALE_MANAGER: 7,
  LEADER: 8,
};

const ROLE = [
  {
    id: 1,
    name: 'ADMIN',
  },
  {
    id: 2,
    name: 'ENTERPRISE',
  },
  {
    id: 3,
    name: 'PROFESSION_MANAGER',
  },
  {
    id: 4,
    name: 'HR_MANAGER',
  },
  {
    id: 5,
    name: 'SALE',
  },
];

const ROLE_AVAILABLE = [
  {
    id: 1,
    name: 'ADMIN',
  },
  {
    id: 2,
    name: 'ENTERPRISE',
  },
  {
    id: 3,
    name: 'Trưởng phòng chuyên môn',
  },
  {
    id: 4,
    name: 'Trưởng phòng tuyển dụng',
  },
  {
    id: 5,
    name: 'SALE',
  },
  {
    id: 6,
    name: 'Nhân viên đánh giá',
  },
  {
    id: 7,
    name: 'TELESALE_MANAGER',
  },
  {
    id: 8,
    name: 'Trưởng phòng ban',
  },
  {
    id: 9,
    name: 'SALE_ADMIN',
  },
];

const TYPE_MEDIA = {
  FRONT_IMAGE: 1,
  BACK_IMAGE: 2,
  VIDEO: 3,
  PROFILE_IMAGE: 4,
};

const ACTIVE_STATUS = [
  {
    id: 1,
    name: 'Đang hoạt động',
  },
  {
    id: 2,
    name: 'Tạm ngừng hoạt động',
  },
];

const JOB_LINK = [
  // {
  //   id: '',
  //   name: 'Chọn hình thức',
  // },
  {
    id: 1,
    name: 'Link youtube',
  },
  {
    id: 2,
    name: 'Upload video',
  },
];

const JOB_STATUS = [
  {
    id: 1,
    name: 'Chờ giao việc',
  },
  {
    id: 2,
    name: 'Đang thực hiện',
  },
  {
    id: 3,
    name: 'Hoàn thành',
  },
];

export const WORK_STATUS = [
  {
    value: 1,
    title: 'Chờ giao việc',
  },
  {
    value: 2,
    title: 'Đang thực hiện',
  },
  {
    value: 3,
    title: 'Hoàn thành',
  },
];

export const JOB_FILTER = [
  // {
  //   value: 1,
  //   title: 'Đang hoạt động',
  // },
  {
    value: 2,
    title: 'Tạm dừng hoạt động',
  },
  {
    value: 3,
    title: 'Chờ giao việc',
  },
  {
    value: 4,
    title: 'Đang thực hiện',
  },
  {
    value: 5,
    title: 'Hoàn thành',
  },
];

export const STATUS_JOB = {
  WAITING: 1,
  PROCESSING: 2,
  SUCCESS: 3,
};

const RESULT_STATUS = [
  {
    id: 0,
    name: 'Đã giao',
  },
  {
    id: 1,
    name: 'Đang thực hiện',
  },
  {
    id: 2,
    name: 'Đã hoàn thành',
  },
  {
    id: 3,
    name: 'Quá hạn',
  },
];

const REPORT_STATUS = [
  {
    id: 0,
    name: 'Chưa tiếp nhận',
  },
  {
    id: 1,
    name: 'Đã tiếp nhận',
  },
  {
    id: 2,
    name: 'Đang xử lý',
  },
  {
    id: 3,
    name: 'Thành công',
  },
  {
    id: 4,
    name: 'Từ chối',
  },
];
const NULL_ITEM = /(.|\s)*\S(.|\s)*/i;
const NULL_ARRAY = /\[(.*)\]/i;
const QUESTION_TYPE = {
  TEXT: 1,
  SELECT: 2,
  MULTIPLE_SELECT: 3,
  DATE: 4,
  YES_NO: 5,
};

const LIST_STATUS = [
  { id: 1, name: 'Đã tiếp nhận' },
  { id: 2, name: 'Đang xử lý' },
  { id: 3, name: 'Thành công' },
  { id: 4, name: 'Từ chối' },
];

const OPPORTUNITY_STATUS = {
  CUSTOMER_POTENTIAL: 1,
  CLOSE_CONTRACT: 3,
  CUSTOMER_REFUSES: 4,
};

const LIST_OPPORTUNITY_STATUS = [
  { id: OPPORTUNITY_STATUS.CUSTOMER_POTENTIAL, name: 'Khách hàng tiềm năng' },
  { id: OPPORTUNITY_STATUS.CLOSE_CONTRACT, name: 'Chốt hợp đồng' },
  { id: OPPORTUNITY_STATUS.CUSTOMER_REFUSES, name: 'Khách hàng từ chối' },
];

const TELESALE_JOB_STATUS = [
  {
    id: 2,
    name: 'Đã giao việc',
    count: 21,
    color: 'text-info',
  },
  {
    id: 1,
    name: 'Chưa giao việc',
    count: 0,
    color: 'text-warning',
  },
  {
    id: 3,
    name: 'Đã nhận việc',
    count: 0,
    color: 'text-primary',
  },
  {
    id: 4,
    name: 'Đã gọi',
    count: 0,
    color: 'text-success',
  },
  {
    id: 5,
    name: 'Từ chối',
    count: 0,
    color: 'text-danger',
  },
];

const TELESALE_JOB_STATUS_ALIAS = {
  NOT_ASSIGNED: 1,
  ASSIGNED: 2,
  SALE_ACCEPTED: 3,
  CALLED: 4,
  REJECTED: 5,
};

const QUESTION_TYPE_ALIAS = [
  {
    id: 1,
    name: 'Nhập chữ',
  },
  {
    id: 2,
    name: 'Chọn 1 đáp án',
  },
  {
    id: 3,
    name: 'Chọn nhiều đáp án',
  },
  {
    id: 4,
    name: 'Nhập ngày',
  },
  {
    id: 5,
    name: 'Chọn có hoặc không',
  },
];

const VEHICLE = [
  { code: 'verhicle_name', value: 'Kiểu xe' },
  { code: 'verhicle_type', value: 'Dòng xe' },
  { code: 'verhicle_brand', value: 'Hãng xe' },
  { code: 'verhicle_license_plate', value: 'Biển số xe' },
];

const STATUS_OCCASION = [
  { id: 0, name: 'Chưa tiếp nhận' },
  { id: 1, name: 'Khách hàng tiềm năng' },
  { id: 2, name: 'Đang xử lý' },
  { id: 3, name: 'Khách hàng chốt hợp đồng' },
  { id: 4, name: 'Khách hàng từ chối' },
];

const STATUS = {
  active: 1,
  un_active: 0,
};

const LIST_TELECOM = [
  'VIETTEL',
  'MOBIPHONE',
  'VINAPHONE',
  'VIETNAMMOBILE',
  'GMOBILE',
  'ORTHER',
];

const TELECOM_CODE = [
  [
    '032',
    '033',
    '034',
    '035',
    '036',
    '037',
    '038',
    '039',
    '098',
    '097',
    '096',
    '086',
  ],
  ['081', '082', '083', '084', '085', '094', '091', '088'],
  ['070', '076', '077', '078', '079', '093', '090', '089'],
  ['052', '056', '058', '092'],
  ['059', '099'],
  [],
];

const CALL_TYPE = {
  ids: [1, 2],
  items: {
    1: { id: 1, name: 'Gọi đi' },
    2: { id: 2, name: 'Gọi vào' },
  },
  getCallType(id) {
    return this.items[id] || {};
  },
};

const JOB_TYPE = {
  INCOMMING: 2,
  DEFAULT: 1,
};

const BASE_URL = process.env.REACT_APP_API_URL;
const PUBLIC_URL = process.env.PUBLIC_URL;
const FILE_CUSTOMER_EXAMPLE = `${BASE_URL}/samples/customer_sample.xlsx`;
const DEFAULT_IMAGE = `${PUBLIC_URL}/logo.png`;

const NUMBER_REGEX = /^[+]?\d*\.?\d*$/i;
const PHONE_REGEX = /(^(09|03|07|08|05|01|19|18)+([0-9]{8})\b)/i;
// const EMAIL_REGEX = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const EMAIL_REGEX = /^[a-zA-Z0-9_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
const PASS_REGEX =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i;
const IMAGE_URL = /(https?:\/\/.*?\.(?:png|jpe?g|gif)(.*))(\w|$)/i;

const CONFIG = {
  THROTTLE_TIME: 200,
};

const CALL_RESULT_STATUS = {
  NOT_ANSWER: 2,
  SUCCESS: 4,
};

const SWITCHBOARD_HOTLINE = {
  EZCONNECT: 1,
  STRINGEE: 2,
  ITY: 3,
};

const keyStringee = {
  api_keyid: 'SKAQ4SytQYRZbCwrG8KpVzAQqWlKufOiS',
  api_keysec: 'd01ZOGRFRFBLdHJCS3VlYlA3R0o3SEZtTWxma1Q3Q1Y=',
  api_account_keyid: 'AC9649162bcff79350c867d6c717942bf7',
  api_account_keysec: 'fd4d91bbc809c5eb88b53f6824e97db1',
  domain: 'dev.connect.ezsale.vn',
  gateway_id: '97b93ee0-fd51-480d-bde1-fcbb2ddc1600',
};

export const BASE_URL_IMAGE = 'https://ezsale.s3-ap-southeast-1.amazonaws.com/';

const COLOR = [
  '#1565c0',
  '#00695c',
  '#3e95cd',
  '#8e5ea2',
  '#3cba9f',
  '#e8c3b9',
  '#c45850',
  '#8e24aa',
  '#d32f2f',
  '#d50000',
  '#9e9d24',
  '#2e7d32',
  '#fbc02d',
  '#ffb300',
  '#e65100',
  '#795548',
  '#d84315',
  '#424242',
  '#455a64',
  '#ffff00',
];

const STRINGEE_ERROR = [
  'USER_END_CALL',
  'MEDIA_TIMEOUT',
  'NOT_ENOUGH_MONEY',
  'TIMEOUT_WAIT_SDP_TO_MAKECALL',
  'Decline',
  'Gateway Timeout',
  'Service Unavailable',
  'Busy Here',
  'Request Timeout',
  'Temporarily Unavailable',
  'Request Terminated',
  'Not Acceptable Here',
];

const STRINGEE_ERROR_CODE = {
  INIT: 0,
  CALLING: 1,
  RINGING: 2,
  ANSWERED: 3,
  CONNECTED: 4,
  BUSY: 5,
  ENDED: 6,
};

const GENDER = {
  MAN: 0,
  WOMEN: 1,
};

const DF_CUSTOMER_ACTION_ID = {
  KHACH_HANG_TIEM_NANG: 1,
  KHACH_HANG_CHOT_HOP_DONG: 2,
  KHACH_HANG_TU_CHOI: 3,
  GOI_DEN: 4,
  GOI_DI: 5,
  GOI_NHO: 6,
  CHINH_SUA_KHACH_HANG: 7,
  GIAO_CHO_SALE_CHAM_SOC: 8,
  LICH_HEN: 9,
  THEM_MOI: 10,
  CAP_NHAT_THE_TAG: 11,
  XOA_CO_HOI: 12,
  BAO_CAO_VI_TRI: 13,
  GUI_TIN_NHAN_SMS: 14,
  GUI_TIN_NHAN_ZNS: 15,
  VONG_QUAY_MAY_MAN: 16,
  GUI_TIN_NHAN_ZALO_CA_NHAN: 17,
  TICH_DIEM: 18,
  KICH_HOAT_EVOUCHER: 19,
  TICH_DIEM_CO_HOI: 20,
};

const VERIFY_ADDRESS = {
  YES: 1,
  NO: 0,
};

const TELESALE_ENTERPRISE_STATUS = {
  FALSE: 0,
  PASS: 1,
  PROCESSING: 2,
  REJECT: 3,
};

// const DF_CUSTOMER_ACTION_ID = [
//   { id: 1, name: 'Khách hàng tiềm năng' },
//   {
//     id: 2,
//     name: 'Khách hàng chốt hợp đồng',
//   },
//   { id: 3, name: 'Khách hàng từ chối' },
//   { id: 4, name: 'Gọi đến' },
//   {
//     id: 5,
//     name: 'Gọi đi',
//   },
//   {
//     id: 6,
//     name: 'Gọi nhỡ',
//   },
//   {
//     id: 7,
//     name: 'Chỉnh sửa khách hàng',
//   },
//   {
//     id: 8,
//     name: 'Giao cho sale chăm sóc, nhận công việc chuyển tiếp',
//   },
//   {
//     id: 9,
//     name: 'Lịch hẹn',
//   },
//   {
//     id: 10,
//     name: 'Thêm mới',
//   },
//   {
//     id: 11,
//     name: 'Cập nhật thẻ tag',
//   },
// ];

const TIME_CODE_TYPE = {
  DATE: 1,
  WEEK: 2,
  MONTH: 3,
  QUARTER: 4,
  YEAR: 5,
};

const STATISTICS_TYPE = {
  RATIO: 1,
  MONEY: 2,
  SUM: 3,
};

const FIELD_CONFIG_TELEMARKETING_REPORT = {
  CALL: 'Call',
  CONTRACT: 'Contract',
  CUSTOMER: 'Customer',
  OPPORTUNITY: 'Opportunity',
  TAGS: 'Tags',
  RATIO: 'Ratio',
};

const TYPE_GROUP_DATA = {
  CUSTOMER: 1,
  CALL: 2,
  TAG: 3,
  OPPORTUNITY: 4,
  CONTRACT: 5,
};

const TYPE_GROUP_CONFIGS = [
  { name: 'Khách hàng', type: 'Customer', key: 1 },
  { name: 'Gọi điện', type: 'Call', key: 2 },
  { name: 'Phân loại', type: 'Tags', key: 3 },
  { name: 'Cơ hội', type: 'Opportunity', key: 4 },
  { name: 'Hợp đồng', type: 'Contract', key: 5 },
];

const NOTI_TYPE = {
  NEW_JOB: 1, // công việc mới
  NEW_CUSTOMER: 2, // có kh quan tâm đến cv + giao việc tự động
  OPPORTUNITY_STATUS: 4, // Kết quả cơ hộ cho sale
  ROLL_BACK: 5, // thu hồi công Việc
  CHANGE_TO_SALE: 6, // Đổi công việc
  CHANGE_FROM_SALE: 7, // Đỏi công việc
  MISS_CALL: 8, // cuộc gọi nhỡ
  TASK_RECALL: 10, // Lịch hẹn gọi lại (tự động)
  NEW_KPI: 11, // Tạo kpi
  RESULT_KPI: 12, // Cập nhật kết quả KPI (tự động)
  UPDATE_KPI: 13, // Cập nhật KPI
  TIME_KEEPING: 14, // Điểm danh (tự động)
  NEW_OPPORTUNITY: 15, // có cơ hội mới
  SUCCESS_OPPORTUNITY: 16, // có cơ hội tành công
  FAIL_OPPORTUNITY: 17, // có cơ hội thất bại
  RECALL: 18, // có cuộc lịch hẹn gọi lại
  NOTI_KPI_SALE: 19, // thông báo cho telesale
  NOTI_KPI_LEADER_ADMIN: 20, // thông báo cho leader và admin
  NOTI_START_OUTBOUNT: 21, // thông báo cho leader và admin
  NOTI_ADD_CUSTOMER_TO_JOB: 22, // thông báo chia việc cho sale
  CHANGE_SATE: 23, // thông báo chia việc cho sale
  OPPORTUNITY_NOTE: 24, // thông báo thêm ghi chú cơ hội
  OPPORTUNITY_CREATE: 25, // thông báo khi tạo cơ hội mới
  LOCATION_REPORT: 26, // báo cáo vị trí
  CALL_REVIEW: 27, // báo cáo vị trí
};

const CUSTOMER_INTERACTION = {
  CREATE: 'Thêm mới',
};

const NOT_UDATE_YET_STRING = '---';

const ROLE_WEB_SALE = 5;

const EVENT_CHANNEL = {
  NEW_NOTIFICATION: 'new_notification',
  REQUEST_TO_LOAD_SCREEN: 'request_to_load_screen',
};

const ERROR_STRING = {
  CALL_ERROR: 'Đã có lỗi xảy ra, không thể thực hiện cuộc gọi!',
};

export const IS_APPROVED = {
  NO: 0,
  YES: 1,
};

const MESS_STATUS = {
  SUCCESS: 1,
  PENDING: 2,
  ERROR: 3,
};

const MESS_TYPE = {
  BRAND: 1,
  PREFIX: 2,
  MULTIEMEDIA: 3,
};

const TOKEN_MIXPANEL = 'f6fb62c31f5a4bb7407d2e0dba8e42a0';

const REGEXP_WITH_NUMBER = /[^0-9]/g;

export const CALL_STATUS = {
  BUSY_CALL_BACK_LASTER: 1,
  DO_NOT_ANSWER_THE_PHONE: 2,
  WRONG_INFORMATION: 3,
  AGREE_TO_EXCHANGE: 4,
  CALL_BACK_APPOINTMENT: 0,
};

export {
  TOKEN_MIXPANEL,
  MESS_TYPE,
  MESS_STATUS,
  ROUTER,
  STRING,
  FIELD_IMPORT,
  NUMBER,
  JOB_LINK,
  SALE_STATUS,
  SALE_STATUS_ALIAS,
  SALE_STATUS_REJECT,
  SALE_STATUS_WAITING,
  ACCEPT_TYPE,
  USER_ROLE,
  ROLE,
  ROLE_AVAILABLE,
  ACTIVE_STATUS,
  JOB_STATUS,
  NUMBER_REGEX,
  PHONE_REGEX,
  EMAIL_REGEX,
  TYPE_MEDIA,
  NULL_ITEM,
  NULL_ARRAY,
  QUESTION_TYPE,
  QUESTION_TYPE_ALIAS,
  TELESALE_JOB_STATUS,
  TELESALE_JOB_STATUS_ALIAS,
  PASS_REGEX,
  IMAGE_URL,
  RESULT_STATUS,
  CALL_ERROR_CODE,
  REPORT_STATUS,
  STATUS_OCCASION,
  LIST_TELECOM,
  VEHICLE,
  FILE_CUSTOMER_EXAMPLE,
  CALL_TYPE,
  SALE_CALL_TYPE,
  CALL_RESULT_STATUS,
  SWITCHBOARD_HOTLINE,
  CONFIG,
  JOB_TYPE,
  STATUS,
  COLOR,
  LIST_STATUS,
  keyStringee,
  DEFAULT_IMAGE,
  STRINGEE_ERROR,
  STRINGEE_ERROR_CODE,
  CALLING_STATUS,
  TELECOM_CODE,
  GENDER,
  DF_CUSTOMER_ACTION_ID,
  TELESALE_ENTERPRISE_STATUS,
  TIME_CODE_TYPE,
  STATISTICS_TYPE,
  FIELD_CONFIG_TELEMARKETING_REPORT,
  TYPE_GROUP_CONFIGS,
  NOTI_TYPE,
  LIST_OPPORTUNITY_STATUS,
  VERIFY_ADDRESS,
  OPPORTUNITY_STATUS,
  CUSTOMER_INTERACTION,
  NOT_UDATE_YET_STRING,
  ROLE_WEB_SALE,
  EVENT_CHANNEL,
  ERROR_STRING,
  CALL_STATUS_STRING,
  REGEXP_WITH_NUMBER,
};

export const TagCallStatus = {
  'Đúng thông tin': '#87d068',
  'Sai thông tin': '#fbb403',
  'Bận gọi lại sau': '#fdd15e',
  'Không nghe máy': '#dc583c',
  'Đồng ý trao đổi': '#8ab4f8',
};

export const QUICKSAND_FONT = "'Quicksand', sans-serif";

export const BOX_SHADOW =
  'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px';
