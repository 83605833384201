import { notifyFail } from '@utils/notify';
import { CALLING_CODE, CALL_STATUS_ERROR_CODE } from './CallConstant';

export const isCallEnded = callStatus => {
  if (
    callStatus === CALLING_CODE.Busy ||
    callStatus === CALLING_CODE.Ended ||
    callStatus === CALLING_CODE.Init ||
    callStatus >= 400
  )
    return true;

  if (isEndWithError(callStatus)) {
    return true;
  }
  return isUnhandledException(callStatus);
};

// những lỗi đã handle
export const isEndWithError = callStatus => {
  const isHandledError = Object.keys(CALL_STATUS_ERROR_CODE).some(key => {
    return CALL_STATUS_ERROR_CODE[key] == callStatus;
  });
  return isHandledError;
};

// Lỗi chưa xác định thì cũng coi là end call
export const isUnhandledException = callStatus => {
  let isUnhandled = true;
  Object.keys(CALLING_CODE).some(key => {
    if (CALLING_CODE[key] == callStatus) isUnhandled = false;
  });

  Object.keys(CALL_STATUS_ERROR_CODE).some(key => {
    if (CALL_STATUS_ERROR_CODE[key] == callStatus) isUnhandled = false;
  });
  return isUnhandled;
};

export const errorDefine = [
  {
    errorCode: 400,
    errorName: 'Bad Request',
    errorMeaning: 'The SIP request không chính xác',
    errorDescription:
      'Yêu cầu của bạn không hợp lệ. Vui lòng kiểm tra lại và thử lại cuộc gọi.',
  },
  {
    errorCode: 401,
    errorName: 'Unauthorized',
    errorMeaning: 'Xác thực không chính xác',
    errorDescription:
      'Bạn cần cung cấp thông tin đăng nhập chính xác để thực hiện cuộc gọi.',
  },
  {
    errorCode: 402,
    errorName: 'Payment Required',
    errorMeaning: 'Lỗi chưa xác định dành cho không đủ hạn mức trả phí',
    errorDescription: 'Vui lòng thanh toán để tiếp tục cuộc gọi.',
  },
  {
    errorCode: 403,
    errorName: 'Forbidden',
    errorMeaning: 'Yêu cầu không hợp lệ',
    errorDescription: 'Bạn không được phép truy cập hoặc gọi số điện thoại này',
  },
  {
    errorCode: 404,
    errorName: 'Not Found',
    errorMeaning: 'Yêu cầu không tồn tại',
    errorDescription:
      'Số máy quý khách vừa gọi chưa đúng. Xin vui lòng kiểm tra và gọi lại!',
  },
  {
    errorCode: 405,
    errorName: 'Method Not Allowed',
    errorMeaning:
      'Phương thức của yêu cầu (ví dụ: SUBSCRIBE hoặc NOTIFY) không được phép',
    errorDescription: '',
  },
  {
    errorCode: 406,
    errorName: 'Not Acceptable',
    errorMeaning: 'Các tùy chọn cuộc gọi không được phép',
    errorDescription: '',
  },
  {
    errorCode: 407,
    errorName: 'Proxy Authentication Required',
    errorMeaning: 'Yêu cầu xác thực proxy',
    errorDescription:
      'Vui lòng cung cấp thông tin xác thực để tiếp tục cuộc gọi.',
  },
  {
    errorCode: 408,
    errorName: 'Request Timeout',
    errorMeaning:
      'Hết thời gian chờ – Yêu cầu không được phản hồi trong một thời gian cho phép chờ.',
    errorDescription:
      'Yêu cầu không nhận được phản hồi từ người nhận trong thời gian cho phép chờ.',
  },
  {
    errorCode: 410,
    errorName: 'Gone',
    errorMeaning:
      'Không có thể liên lạc được với người đăng ký tại 1 địa chỉ đã chỉ định.',
    errorDescription: '',
  },
  {
    errorCode: 412,
    errorName: 'Conditional Request Failed',
    errorMeaning:
      'Không thể đáp ứng yêu cầu các điều kiện tiên quyết để xử lý bởi vì yêu cầu bắt buộc không thành công.',
    errorDescription:
      'Yêu cầu không được thực hiện do không đáp ứng các điều kiện yêu cầu.',
  },
  {
    errorCode: 413,
    errorName: 'Request Entity Too Large',
    errorMeaning: 'Nội dung thông tin quá lớn không cho phép.',
    errorDescription:
      'Yêu cầu bị từ chối vì kích thước của thông tin vượt quá giới hạn cho phép',
  },
  {
    errorCode: 414,
    errorName: 'Request URI Too Long',
    errorMeaning: 'Địa chỉ SIP (URI) yêu cầu quá dài',
    errorDescription: 'Yêu cầu bị từ chối vì đường dẫn URI quá dài.',
  },
  {
    errorCode: 415,
    errorName: 'Unsupported Media Type',
    errorMeaning: 'Bộ giải mã không được hỗ trợ.',
    errorDescription:
      'Yêu cầu bị từ chối vì loại phương tiện không được hỗ trợ.',
  },
  {
    errorCode: 416,
    errorName: 'Unsupported URI Scheme',
    errorMeaning: 'Địa chỉ SIP không chính xác',
    errorDescription: 'Yêu cầu bị từ chối vì giao thức URI không được hỗ trợ.',
  },
  {
    errorCode: 417,
    errorName: 'Unknown Resource-Priority',
    errorMeaning:
      'Yêu cầu phải được xử lý với một độ ưu tiên nhất định, nhưng máy chủ không hiểu chi tiết độ ưu tiên của request.',
    errorDescription: 'Yêu cầu bị từ chối vì ưu tiên tài nguyên không hợp lệ',
  },
  {
    errorCode: 420,
    errorName: 'Bad Extension',
    errorMeaning: 'Máy chủ không hiểu phần giao thức mở rộng.',
    errorDescription:
      'Yêu cầu bị từ chối vì sử dụng phần mở rộng không hợp lệ.',
  },
  {
    errorCode: 421,
    errorName: 'Extension Required',
    errorMeaning: 'Máy chủ cần đuôi giao thức mở rộng.',
    errorDescription:
      'Yêu cầu bị từ chối, cần sử dụng một phần mở rộng cụ thể để tiếp tục.',
  },
  {
    errorCode: 422,
    errorName: 'Session Interval Too Small',
    errorMeaning:
      'Giá trị phiên làm việc hết hạn do quá thấp đối với giá trị máy chủ thiết lập.',
    errorDescription: '',
  },
  {
    errorCode: 423,
    errorName: 'Interval Too Brief',
    errorMeaning: 'Giá trị của thời gian mong muốn quá ngắn.',
    errorDescription: '',
  },
  {
    errorCode: 428,
    errorName: 'Use Identity Header',
    errorMeaning: 'Header nhận dạng bị thiếu',
    errorDescription: '',
  },
  {
    errorCode: 429,
    errorName: 'Provide Referrer Identity',
    errorMeaning: 'Không có mã thông báo chỉ định được giới thiệu hợp lệ.',
    errorDescription:
      'Vui lòng cung cấp thông tin giới thiệu hợp lệ để tiếp tục cuộc gọi',
  },
  {
    errorCode: 430,
    errorName: 'Flow Failed',
    errorMeaning:
      'Phần định tuyến không chính xác (proxy mạng nội bộ, các điểm cuối xem phản hồi tương tự như mã 400)',
    errorDescription:
      'Cuộc gọi không thành công. Vui lòng kiểm tra kết nối mạng và thử lại sau',
  },
  {
    errorCode: 433,
    errorName: 'Anonymity Disallowed',
    errorMeaning: 'Máy chủ từ chối xử lý các yêu cầu ẩn danh.',
    errorDescription:
      'Vui lòng sử dụng các thông tin xác thực để thực hiện cuộc gọi.',
  },
  {
    errorCode: 436,
    errorName: 'Bad Identity-Info',
    errorMeaning:
      'Phần header địa chỉ SIP nhận dạng không hợp lệ, không khả dụng hoặc không được hỗ trợ.',
    errorDescription:
      'Thông tin định danh không hợp lệ. Vui lòng kiểm tra và thử lại.',
  },
  {
    errorCode: 437,
    errorName: 'Unsupported Certificate',
    errorMeaning: 'Không thể xác minh được chứng chỉ trong header SIP',
    errorDescription: '',
  },
  {
    errorCode: 438,
    errorName: 'Invalid Identity Header',
    errorMeaning: 'Chứng chỉ nhận dạng không hợp lệ.',
    errorDescription: '',
  },
  {
    errorCode: 439,
    errorName: 'First Hop Lacks Outbound Support',
    errorMeaning: 'Không hỗ trợ proxy từ nhà cung cấp',
    errorDescription: '',
  },
  {
    errorCode: 440,
    errorName: 'Max-Breadth Exceeded',
    errorMeaning: 'Không có thể lấy được các máy nhánh cùng lúc từ truy vấn.',
    errorDescription: 'Cuộc gọi đã vượt quá số lượng cho phép tham gia.',
  },
  {
    errorCode: 469,
    errorName: 'Bad Info Package',
    errorMeaning: 'Các gói thông tin không phù hợp – Lỗi truyền, gửi lại',
    errorDescription: 'Thông tin không hợp lệ. Vui lòng gửi lại!',
  },
  {
    errorCode: 470,
    errorName: 'Consent Needed',
    errorMeaning:
      'Máy chủ không có quyền truy cập vào ít nhất một trong các địa chỉ SIP được chỉ định.',
    errorDescription:
      'Vui lòng cung cấp quyền truy cập trước khi tiếp tục cuộc gọi',
  },
  {
    errorCode: 480,
    errorName: 'Temporarily Unavailable',
    errorMeaning: 'Bên được gọi hiện không thể liên lạc được.',
    errorDescription:
      'Bên gọi không thể liên lạc được do thuê bao bị nhà mạng chặn hoặc đang bận',
  },
  {
    errorCode: 481,
    errorName: 'Call/Transaction Does Not Exist',
    errorMeaning: 'Cuộc gọi này không tồn tại .',
    errorDescription: 'Cuộc gọi này không tồn tại',
  },
  {
    errorCode: 482,
    errorName: 'Loop Detected',
    errorMeaning: 'Cuộc gọi chuyển tiếp bị lặp',
    errorDescription: 'Cuộc gọi chuyển tiếp bị lặp',
  },
  {
    errorCode: 483,
    errorName: 'Too Many Hops',
    errorMeaning: 'Quá nhiều bước chuyển tiếp phải chuyển đến.',
    errorDescription: 'Số bước chuyển tiếp vượt quá giới hạn cho phép',
  },
  {
    errorCode: 484,
    errorName: 'Address Incomplete',
    errorMeaning: 'Địa chỉ SIP không đầy đủ.',
    errorDescription: 'Địa chỉ liên lạc không đầy đủ',
  },
  {
    errorCode: 485,
    errorName: 'Ambiguous',
    errorMeaning: 'Địa chỉ SIP không thể được giải quyết.',
    errorDescription:
      'Thông tin không rõ ràng trong quá trình xử lý cuộc gọi. Vui lòng kiểm tra lại!',
  },
  {
    errorCode: 486,
    errorName: 'Busy Here',
    errorMeaning: 'Cuộc gọi đến người nhận báo bận.',
    errorDescription: 'SĐT khách hàng đang bận vui lòng liên hệ lại sau',
  },
  {
    errorCode: 487,
    errorName: 'Request Terminated',
    errorMeaning: 'Nỗ lực yêu cầu cuộc gọi đã bị hủy bỏ.',
    errorDescription: 'Yêu cầu cuộc gọi đã bị huỷ bỏ.',
  },
  {
    errorCode: 488,
    errorName: 'Not Acceptable Here',
    errorMeaning: 'Cuộc gọi bất hợp pháp, không cho phép',
    errorDescription: 'Yêu cầu cuộc gọi không được chấp nhận tại địa điểm này.',
  },
  {
    errorCode: 489,
    errorName: 'Bad Event',
    errorMeaning: 'Máy chủ không biết sự kiện được chỉ định.',
    errorDescription:
      'Sự kiện gọi điện không hợp lệ hoặc không được chấp nhận.',
  },
  {
    errorCode: 491,
    errorName: 'Request Pending',
    errorMeaning: 'Yêu cầu vẫn đang được xử lý.',
    errorDescription:
      'Yêu cầu cuộc gọi đang chờ xử lý. Vui lòng đợi và thử lại sau!',
  },
  {
    errorCode: 493,
    errorName: 'Undecipherable',
    errorMeaning:
      'Yêu cầu chứa phần thân MIME được mã hóa mà người nhận không thể giải mã.',
    errorDescription: 'Không đọc được thông tin cuộc gọi. Vui lòng thử lại!',
  },
  {
    errorCode: 494,
    errorName: 'Security Agreement Required',
    errorMeaning:
      'Yêu cầu thiết lập phải bảo mật nhưng không bao gồm các cơ chế bảo mật được máy chủ hỗ trợ.',
    errorDescription:
      'Yêu cầu thỏa thuận bảo mật chưa được đáp ứng. Liên hệ nhà cung cấp dịch vụ.',
  },
  {
    errorCode: 500,
    errorName: 'Server Internal Error',
    errorMeaning: 'Lỗi máy chủ',
    errorDescription: 'Số điện thoại không hoạt động nên không gọi được',
  },
  {
    errorCode: 501,
    errorName: 'Not Implemented',
    errorMeaning: 'Máy chủ không hỗ trợ SIP',
    errorDescription:
      'Chức năng yêu cầu chưa được hỗ trợ. Vui lòng liên hệ với nhà cung cấp dịch vụ',
  },
  {
    errorCode: 502,
    errorName: 'Bad Gateway',
    errorMeaning: 'Gateway SIP request bị lỗi',
    errorDescription: 'Cổng kết nối xảy ra sự cố. Vui lòng thử lại sau!',
  },
  {
    errorCode: 503,
    errorName: 'Service Unavailable',
    errorMeaning: 'Dịch vụ SIP của máy chủ tạm thời không khả dụng',
    errorDescription: 'Dịch vụ không khả dụng tạm thời. Vui lòng thử lại sau!',
  },
  {
    errorCode: 504,
    errorName: 'Server Time-out',
    errorMeaning: 'Hết thời gian thiết lập kết nối tới máy chủ',
    errorDescription: 'Máy chủ không phản hồi. Vui lòng kiểm tra kết nối mạng!',
  },
  {
    errorCode: 505,
    errorName: 'Version Not Supported',
    errorMeaning: 'Phiên bản giao thức SIP không được máy chủ hỗ trợ',
    errorDescription: '',
  },
  {
    errorCode: 513,
    errorName: 'Message Too Large',
    errorMeaning:
      'Thông điệp SIP quá lớn đối với giao thức UDP; TCP đang được sử dụng.',
    errorDescription:
      'Kích thước thông điệp vượt quá giới hạn cho phép. Vui lòng thử lại.',
  },
  {
    errorCode: 580,
    errorName: 'Precondition Failure',
    errorMeaning:
      'Máy chủ không thể hoặc không muốn đáp ứng các yêu cầu để xử lý.',
    errorDescription:
      'Không thể thiết lập cuộc gọi. Vui lòng kiểm tra lại cấu hình mạng.',
  },
  {
    errorCode: 600,
    errorName: 'Busy Everywhere',
    errorMeaning: 'Tất cả các máy nhanh đều bận',
    errorDescription: 'Không thể thực hiện cuộc gọi. Vui lòng thử lại sau.',
  },
  {
    errorCode: 603,
    errorName: 'Declined',
    errorMeaning: 'Bên nhận đã từ chối cuộc gọi',
    errorDescription: 'Người nhận đã từ chối cuộc goi. Vui lòng thử lại sau.',
  },
  {
    errorCode: 604,
    errorName: 'Does Not Exist Anywhere',
    errorMeaning: 'Đầu nhận không còn tồn tại.',
    errorDescription: 'SĐT không tồn tại. Vui lòng kiểm tra lại!',
  },
  {
    errorCode: 606,
    errorName: 'Not Acceptable',
    errorMeaning: 'Yêu cầu SIP không hợp lệ bị từ chối bởi đầu nhận cuộc gọi.',
    errorDescription: '',
  },
  {
    errorCode: 700,
    errorName: 'Party Hangs Up',
    errorMeaning: 'Bên nhận gác máy.',
    errorDescription: 'Người nhận đã kết thúc cuộc gọi',
  },
  {
    errorCode: '',
    errorName: 'GET_USER_MEDIA_ERROR',
    errorMeaning: '',
    errorDescription: 'Vui lòng mở quyền micro để gọi',
  },
  {
    errorCode: '',
    errorName: 'FROM_NUMBER_NOT_BELONG_YOUR_PROJECT',
    errorMeaning: '',
    errorDescription: 'Đường truyện bận xin vui lòng thử lại',
  },
  {
    errorCode: 1,
    errorName: 'ANSWER_URL_EMPTY',
    errorMeaning: '',
    errorDescription:
      'URL trả lời trống. Vui lòng cung cấp URL trả lời cho cuộc gọi.',
  },
  {
    errorCode: 2,
    errorName: 'ANSWER_URL_SCCO_INCORRECT_FORMAT',
    errorMeaning: '',
    errorDescription:
      'Vui lòng kiểm tra lại định dạng URL trả lời cho cuộc gọi',
  },
  {
    errorCode: 3,
    errorName: 'TO_TYPE_IS_NOT_INTERNAL_OR_EXTERNAL',
    errorMeaning: '',
    errorDescription:
      'Vui lòng cấu hình gọi nội mạng hoặc ngoại mạng cho cuộc gọi',
  },
  {
    errorCode: 4,
    errorName: 'From Number Not Found',
    errorMeaning: 'From Number Not Found',
    errorDescription: 'Không tìm thấy hotline!',
  },
  {
    errorCode: 5,
    errorName: 'From Number Not Belong Your Account',
    errorMeaning: 'From Number Not Belong Your Account',
    errorDescription: 'Bạn không được quyền sử dụng hotline này',
  },
  {
    errorCode: 6,
    errorName: 'SIP_TRUNK_NOT_FOUND',
    errorMeaning: 'Sip Trunk Not Found',
    errorDescription:
      'Không tìm thấy đường truyền. Vui lòng kiểm tra lại đường truyền!',
  },
  {
    errorCode: 7,
    errorName: 'SIP_TRUNK_NOT_BELONG_YOUR_ACCOUNT',
    errorMeaning: 'Sip Trunk Not Belong Your Account',
    errorDescription:
      'Đường truyền không được liên kết với tài khoản của bạn. Vui lòng kiểm tra lại!',
  },
  {
    errorCode: 8,
    errorName: 'NOT_ENOUGH_MONEY',
    errorMeaning: 'Not Enough Money',
    errorDescription: 'Tải khoản của bạn không đủ để thực hiện cuộc gọi này',
  },
  {
    errorCode: 9,
    errorName: 'UNKNOW_ERROR_1',
    errorMeaning: 'Connect To Charging Server Error',
    errorDescription:
      'Đã xảy ra lỗi khi kết nối với máy chủ tính phí. Vui lòng thử lại sau!',
  },
  {
    errorCode: 10,
    errorName: 'FROM_NUMBER_OR_TO_NUMBER_INVALID_FORMAT',
    errorMeaning: 'From Number Or To Number Invalid Format',
    errorDescription: 'Số điện thoại hoặc hotline không hợp lệ',
  },
  {
    errorCode: 11,
    errorName: 'CALL_NOT_ALLOWED_BY_YOUR_SERVER',
    errorMeaning: 'Call Not Allowed By Your Server',
    errorDescription:
      'Cuộc gọi bị từ chối bởi máy chủ. Vui lòng kiểm tra lại cấu hình máy chủ.',
  },
  {
    errorCode: 12,
    errorName: 'MAX_CONCURRENT_CALL',
    errorMeaning: 'Max Concurrent Call',
    errorDescription: '',
  },
  {
    errorCode: 13,
    errorName: 'WAIT_TEXT_TO_SPEECH',
    errorMeaning: 'Wait Text To Speech',
    errorDescription: '',
  },
  {
    errorCode: 14,
    errorName: 'TO_NUMBER_INVALID',
    errorMeaning: 'To Number Invalid',
    errorDescription: 'Số điện thoại không hợp lệ',
  },
  {
    errorCode: 15,
    errorName: 'FROM_NUMBER_NOT_BELONG_YOUR_PROJECT',
    errorMeaning: 'From Number Not Belong Your Project',
    errorDescription: 'Số điện thoại hoặc hotline không hợp lệ',
  },
  {
    errorCode: 16,
    errorName: 'NOT_ALLOW_CHAT_USER',
    errorMeaning: 'Not Allow Chat User',
    errorDescription: 'Người dùng không được phép chat',
  },
  {
    errorCode: 17,
    errorName: 'NOT_ALLOW_CALLOUT',
    errorMeaning: 'Not Allow Callout',
    errorDescription: 'Không cho phép gọi đi',
  },
  {
    errorCode: 18,
    errorName: 'REQUEST_ANSWER_URL_ERROR',
    errorMeaning: 'Request Answer Url Error',
    errorDescription: 'Lỗi request trả URL',
  },
  {
    errorCode: 19,
    errorName: 'ACCOUNT_LOCKED',
    errorMeaning: 'Account Locked',
    errorDescription: 'Tài khoản bị khóa',
  },
  {
    errorCode: 1001,
    errorName: 'CREATE_PEER_CONNECTION_ERROR',
    errorMeaning: 'Create peer connection error',
    errorDescription: 'Lỗi tạo kết nối ngang hàng',
  },
];

export function checkAndShowError(callStatus, callErrorName) {
  let exitsError = false;

  let findElement = errorDefine.find(
    item => item.errorCode === callStatus || item.errorName === callErrorName
  );
  console.log(`call ******---afind`,findElement )
  if (findElement) {
    exitsError = true;
    if(findElement.errorCode) {
      notifyFail(`Lỗi ${findElement.errorCode}: ${findElement.errorDescription ||findElement.errorMeaning}`);
    }
    else {
      notifyFail(findElement.errorDescription || findElement.errorMeaning);

    }
  }
  return exitsError;

}
