import {
  GET_LIST_FOR_SALE,
  GET_LIST_FOR_SALE_SUCCESS,
  GET_LIST_FOR_SALE_FAIL,
} from "@actions/type";

const initialState = {
  data: {},
  isLoading: true,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_FOR_SALE:
      return {
        ...state,
        isLoading: true,
      };
    case GET_LIST_FOR_SALE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.response.data,
        paging: action.payload.response.paging,
        error: null,
      };
    case GET_LIST_FOR_SALE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
