import React, { Suspense } from 'react';
import LoadingComponent from '.';

const LazyLoading = ({ children }) => {
  return (
    <Suspense fallback={<LoadingComponent />}>
      <>{children}</>
    </Suspense>
  );
};

export default React.memo(LazyLoading);
