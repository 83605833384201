import moment from 'moment';
import { create_UUID } from '../service';

import {
  SET_CONDITION_PRODUCT,
  SET_CONDITION_PURCHASE_TIME_DATE,
  SET_CONDITION_PURCHASE_TIME_HOUR,
  SET_CONFIG_TYPE,
  SET_DETAIL_EVOUCHER,
  SET_PRODUCTS,
  SET_PROMOTIONS_GENERAL,
  SET_PROMOTIONS_VALUE_OF_THE_VOUCHER,
  SET_PROMOTIONS_VALUE_OF_THE_VOUCHER_IN_GIFTS,
  SET_TIME_BUY,
} from './constants';

const initialState = {
  products: [],
  general: {
    promotionsCode: undefined, //string
    promotionsName: undefined, //string
    preferentialType: {
      discountByTotalAmount: false,
      discountByProduct: false,
      accumulatePoints: false,
      giveAGift: false,
    },
    applyTime: {
      from: undefined, //string
      to: undefined, //string
    },
  },
  valueOfTheVoucher: {
    discountByTotalAmount: {
      selected: 'amountOfMoney',
      amountOfMoney: undefined, //number
      percentage: undefined, //number
    },
    discountByProduct: {
      selected: 'amountOfMoney',
      amountOfMoney: undefined, //number
      percentage: undefined, //number
    },
    accumulatePoints: {
      selected: 'amountOfPoint',
      amountOfPoint: undefined, //number
      percentage: undefined, //number
    },
    giveAGift: {
      products: undefined, //number[]
      gifts: undefined, //number[]
    },
    gifts: {
      isSelected: false,
      givePoints: {
        isSelected: false,
        points: undefined, //number
      },
      promotion: {
        isSelected: false,
        promotionCode: undefined, //number
      },
    },
  },

  // state lưu điều kiện áp dụng
  // lưu danh sách sản phẩm
  condition_product: [
    {
      productName: null,
      quantity: null,
      chooseQuantity: false,
      valueQuantity: null,
      key_id: create_UUID(),

      price: null,
      choosePrice: false,
      valuePrice: null,
    },
  ],
  // kiểm tra xem điều kiện mua hàng của thời gian mua hàng là ngày hay giờ
  timeBuy: 'date',

  // diều kiện thời gian mua hàng đối với ngày
  condition_purchase_time_date: [
    {
      startDate: null,
      endDate: null,
      key_id: create_UUID(),
    },
  ],
  // điều kiện thời gian mua hàng đối với giờ
  condition_purchase_time_hour: [
    {
      startHour: null,
      endHour: null,
      key_id: create_UUID(),
    },
  ],

  // tạo mã theo kiểu nào
  configType: 'auto',
};

function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_PRODUCTS:
      return {
        ...state,
        products: payload,
      };

    case SET_PROMOTIONS_GENERAL:
      let priority = state.preferentialType;
      let from = undefined;
      let to = undefined;

      if (payload.preferentialType) {
        priority = {
          ...priority,
          discountByTotalAmount: payload.preferentialType.includes(
            'discountByTotalAmount'
          ),
          discountByProduct:
            payload.preferentialType.includes('discountByProduct'),
          accumulatePoints:
            payload.preferentialType.includes('accumulatePoints'),
          giveAGift: payload.preferentialType.includes('giveAGift'),
        };
      }

      if (payload.applyTime) {
        from = moment(payload.applyTime?.[0]).format();
        to = moment(payload.applyTime?.[1]).format();
      }

      return {
        ...state,
        general: {
          ...payload,
          preferentialType: priority,
          applyTime: {
            from,
            to,
          },
        },
      };

    case SET_PROMOTIONS_VALUE_OF_THE_VOUCHER:
      for (const [key, value] of Object.entries(payload)) {
        const temporary = state.valueOfTheVoucher;
        temporary[key] = { ...temporary[key], ...value };
        return {
          ...state,
          valueOfTheVoucher: temporary,
        };
      }
      break;

    case SET_PROMOTIONS_VALUE_OF_THE_VOUCHER_IN_GIFTS:
      const { valueOfTheVoucher } = state;
      const { gifts } = valueOfTheVoucher;

      return {
        ...state,
        valueOfTheVoucher: {
          ...valueOfTheVoucher,
          gifts: {
            ...gifts,
            ...payload,
          },
        },
      };

    // xử lý lưu điều kiện áp dụng là danh sách sản phẩm
    case SET_CONDITION_PRODUCT:
      return {
        ...state,
        condition_product: payload,
      };

    // lưu thời gian mua hàng là ngày hay giờ
    case SET_TIME_BUY:
      return {
        ...state,
        timeBuy: payload,
      };

    // xử lý lưu điều kiện thời gian mua hàng là ngày
    case SET_CONDITION_PURCHASE_TIME_DATE:
      return {
        ...state,
        condition_purchase_time_date: payload,
      };

    // xử lý lưu điều kiện thời gian mua hàng là giờ
    case SET_CONDITION_PURCHASE_TIME_HOUR:
      return {
        ...state,
        condition_purchase_time_hour: payload,
      };

    // xử lý lưu cấu hình mã
    case SET_CONFIG_TYPE:
      return {
        ...state,
        configType: payload,
      };

    // xử lý lưu chi tiết evoucher
    case SET_DETAIL_EVOUCHER:
      return {
        ...state,
        detail_evoucher: payload,
      };

    default:
      throw new Error('Invalid action Promotion reducer.');
  }
}

export { initialState };

export default reducer;
