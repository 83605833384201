import {
  GET_USER,
  GET_TELESALE,
  GET_TELESALE_INACTIVE,
  GET_PROVINCE,
  GET_REGISTER,
  GET_REJECTED_REGISTER,
  GET_ACCOUNT,
  CREATE_ACCOUNT,
  UPDATE_ACCOUNT,
  GET_COMPANY,
  GET_CATEGORY,
  GET_HOTLINE,
  GET_CUSTOMER,
  GET_CUSTOMER_GROUP,
  GET_REVIEW,
  GET_JOB,
  GET_JOB_DETAIL,
  CLEAR_JOB_DETAIL,
  GET_CATEGORY_FILTER,
  GET_CUSTOMER_JOB,
  GET_CUSTOMER_GROUP_JOB,
  GET_CUSTOMER_GROUP_STATUS,
  GET_CALL_HISTORY,
  GET_TYPE_JOB,
  GET_LIST_TUTORIAL,
  GET_CALL_STATUS,
  GET_LIST_REVIEWER,
  GET_TASK_REVIEWER,
  GET_GENERAL_QUESTION,
  GET_KEYWORD,
  GET_VPOINT,
  GET_LIST_CALL,
  GET_OCCASION,
  GET_RESULT_REVIEWER,
  GET_JOB_CATEGORY_FILTER,
  GET_JOB_FILTER,
  GET_ENTERPRISES_FILTER,
  GET_TELESALE_FILTER,
  GET_LIST_FOR_SALE,
  GET_QUESTION_ANSWER_FILTER,
  GET_TELESALE_KPI,
  GET_USER_STATISTIC,
  GET_CALL_STATISTIC,
  GET_JOB_STATISTIC,
  GET_JOB_SALE_STATISTIC,
  GET_LIST_PACKAGE,
  GET_LIST_HOTLINE,
  GET_PACKAGE_HISTORY,
  GET_KPI_PROGRESS,
  GET_CALLING_STATUS,
  GET_UPDATE_CALL,
  GET_TIME_DURATION,
  GET_STRINGEE_CALL,
  GET_PHONE_NUMBER,
  GET_IS_NEW_CUSTOMER,
  GET_SHOULD_CALL,
  GET_DTMF,
  ADD_PHONE_NUMBER_PICKED,
  GET_NEXT_CUSTOMER,
  HANDLE_MAKE_CALL_FROM_STRINGEE,
  GET_LISTHOTLINE_NEW,
} from './type';

// chỗ này reload lại dữ liệu evouvcher
export const callbackEvoucher = (payload = false) => ({
  type: 'CALLBACK_EVOUCHER',
  payload: payload,
});

// chỗ này reload lại dũe liệu file import export
export const callbackListFileImportExport = (payload = false) => ({
  type: 'CALLBACK_LIST_FILE_IMPORT_EXPORT',
  payload: payload,
});

export const getIsNewCustomer = payload => ({
  type: GET_IS_NEW_CUSTOMER,
  payload: payload,
});

export const getShouldCall = payload => ({
  type: GET_SHOULD_CALL,
  payload: payload,
});

export const setDTMF = payload => ({
  type: GET_DTMF,
  payload: payload,
});

export const getPhoneNumber = payload => ({
  type: GET_PHONE_NUMBER,
  payload: payload,
});

export const getStringeeCall = payload => ({
  type: GET_STRINGEE_CALL,
  payload: payload,
});

export const getCallingStatus = payload => ({
  type: GET_CALLING_STATUS,
  payload: payload,
});

export const getUpdateCall = payload => ({
  type: GET_UPDATE_CALL,
  payload: payload,
});

export const getTimeDuration = payload => ({
  type: GET_TIME_DURATION,
  payload: payload,
});

export const getKpiProgress = payload => ({
  type: GET_KPI_PROGRESS,
  payload: payload,
});

export const getUserInfo = payload => ({
  type: GET_USER,
  payload: payload,
});

// ACCOUNT
export const getAccountList = payload => ({
  type: GET_ACCOUNT,
  payload: payload,
});

// ACCOUNT CREATE
export const createAccount = payload => ({
  type: CREATE_ACCOUNT,
  payload: payload,
});

// ACCOUNT UPDATE
export const editAccount = payload => ({
  type: UPDATE_ACCOUNT,
  payload: payload,
});

// TELESALE
export const getTelesaleList = payload => ({
  type: GET_TELESALE,
  payload: payload,
});

// TELESALE INACTIVE
export const getTelesaleInactiveList = payload => ({
  type: GET_TELESALE_INACTIVE,
  payload: payload,
});

// PROVINCE
export const getProvinceList = payload => ({
  type: GET_PROVINCE,
  payload: payload,
});

// GET REGISTER LIST
export const getRegisterList = payload => ({
  type: GET_REGISTER,
  payload: payload,
});

// GET REJECTED REGISTER LIST
export const getRejectedRegisterList = payload => ({
  type: GET_REJECTED_REGISTER,
  payload: payload,
});

// COMPANY
export const getCompanyList = payload => ({
  type: GET_COMPANY,
  payload: payload,
});

// JOB CATEGORY
export const getSubJobList = payload => ({
  type: GET_CATEGORY,
  payload: payload,
});

// HOTLINE CONFIG
export const getHotlineList = payload => ({
  type: GET_HOTLINE,
  payload: payload,
});

// CUSTOMER
export const getCustomerList = payload => ({
  type: GET_CUSTOMER,
  payload: payload,
});

// CUSTOMER GROUP
export const getCustomerGroupList = payload => ({
  type: GET_CUSTOMER_GROUP,
  payload: payload,
});
// REVIEW CONFIG
export const getReviewList = payload => ({
  type: GET_REVIEW,
  payload: payload,
});

// JOB
export const getJobList = payload => ({
  type: GET_JOB,
  payload: payload,
});

// JOB DETAIL
export const getJobDetail = payload => ({
  type: GET_JOB_DETAIL,
  payload: payload,
});
// JOB DETAIL CLEAR
export const clearJobDetail = payload => ({
  type: CLEAR_JOB_DETAIL,
});

// JOB CATEGORY IN CREATE
export const getCategoryFilter = payload => ({
  type: GET_CATEGORY_FILTER,
  payload: payload,
});

// CUSTOMER JOB
export const getCustomerJobList = payload => ({
  type: GET_CUSTOMER_JOB,
  payload: payload,
});

// CUSTOMER GROUP JOB
export const getCustomerGroupJobList = payload => ({
  type: GET_CUSTOMER_GROUP_JOB,
  payload: payload,
});

// CUSTOMER GROUP STATUS
export const getCustomerGroupStatus = payload => ({
  type: GET_CUSTOMER_GROUP_STATUS,
  payload: payload,
});

// Call History
export const getCallHistory = payload => ({
  type: GET_CALL_HISTORY,
  payload: payload,
});

// TYPE JOB
export const getTypeJob = payload => ({
  type: GET_TYPE_JOB,
  payload: payload,
});

export const getListTutorial = payload => ({
  type: GET_LIST_TUTORIAL,
  payload: payload,
});

export const getListCallStatus = payload => ({
  type: GET_CALL_STATUS,
  payload: payload,
});

export const getReviewerList = payload => ({
  type: GET_LIST_REVIEWER,
  payload: payload,
});

export const getReviewerTask = payload => ({
  type: GET_TASK_REVIEWER,
  payload: payload,
});

export const getGeneralQuestion = payload => ({
  type: GET_GENERAL_QUESTION,
  payload: payload,
});

export const getListKeyword = payload => ({
  type: GET_KEYWORD,
  payload: payload,
});

export const getListVpoint = payload => ({
  type: GET_VPOINT,
  payload: payload,
});

export const getListCall = payload => ({
  type: GET_LIST_CALL,
  payload: payload,
});

export const getListOccasion = payload => ({
  type: GET_OCCASION,
  payload: payload,
});

export const getResultReviewer = payload => ({
  type: GET_RESULT_REVIEWER,
  payload: payload,
});

export const getJobCategoriesFilter = payload => ({
  type: GET_JOB_CATEGORY_FILTER,
  payload: payload,
});

export const getJobFilter = payload => ({
  type: GET_JOB_FILTER,
  payload: payload,
});

export const getEnterprisesFilter = payload => ({
  type: GET_ENTERPRISES_FILTER,
  payload: payload,
});

export const getTelesalesFilter = payload => ({
  type: GET_TELESALE_FILTER,
  payload: payload,
});

export const getListForSale = payload => ({
  type: GET_LIST_FOR_SALE,
  payload: payload,
});

export const getQuessionsAndAnswerFilter = payload => ({
  type: GET_QUESTION_ANSWER_FILTER,
  payload: payload,
});

export const getTelesaleKpi = payload => ({
  type: GET_TELESALE_KPI,
  payload: payload,
});

export const getUserStatistic = payload => ({
  type: GET_USER_STATISTIC,
  payload: payload,
});

export const getCallStatistic = payload => ({
  type: GET_CALL_STATISTIC,
  payload: payload,
});

export const getJobStatistic = payload => ({
  type: GET_JOB_STATISTIC,
  payload: payload,
});

export const getJobSaleStatistic = payload => ({
  type: GET_JOB_SALE_STATISTIC,
  payload: payload,
});

export const getListPackage = payload => ({
  type: GET_LIST_PACKAGE,
  payload: payload,
});

export const getListHotline = payload => ({
  type: GET_LIST_HOTLINE,
  payload: payload,
});

export const getPackageHistory = payload => ({
  type: GET_PACKAGE_HISTORY,
  payload: payload,
});

export const addPhoneNumberPicked = payload => ({
  type: ADD_PHONE_NUMBER_PICKED,
  payload: payload,
});

export const getNextCustomer = payload => ({
  type: GET_NEXT_CUSTOMER,
  payload: payload,
});

//call new
export const handleMakeCallFromStringee = payload => ({
  type: HANDLE_MAKE_CALL_FROM_STRINGEE,
  payload: payload,
});

export const getListhotlineNew = payload => {
  return { type: GET_LISTHOTLINE_NEW, payload: payload };
};
