import { requestCreateScenario } from '@constants/Api';
import { NULL_ARRAY, ROUTER, STRING } from '@constants/Constant';
import { VietnameseString } from '@constants/VietnameseString';
import Step from '@screens/Enterprise/Job/Add/Component/Context/_Step';
import { getJobDetail } from '@src/redux/actions';
import { ButtonSystemStyle, TitleStyle } from '@styles/Style';
import { checkAllAttrFilled } from '@utils/checkAllAttrFilled';
import { notifyFail, notifySuccess } from '@utils/notify';
import {
  Col as ColAntd,
  Form,
  message,
  Radio,
  Row as RowAntd,
  Skeleton,
} from 'antd';
import { parseInt } from 'lodash';
import React, { Component } from 'react';
import {
  Button,
  Col,
  FormControl,
  FormGroup,
  Modal,
  Row,
} from 'react-bootstrap';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { requestGetJobDetail } from 'src/network/web_sale/JobApi';
import reactotron from 'src/ReactotronConfig';
import {
  JOB_CRON_TIME_CODE,
  JOB_CRON_TIME_REPEAT,
  SCRIPT_TYPE,
} from '../../Constants';
import ScriptOfSmsOrZns from '../Component/ScriptOfSmsOrZns';
import SaleSendSms from '../Component/SaleSendSms';
import ScriptOfZalo from '../Component/ScriptOfZalo';
function FormError(props) {
  if (props.isHidden) {
    return null;
  }
  return (
    <div style={{ color: 'red', width: '100%', textAlign: 'center' }}>
      {props.errorMessage}
    </div>
  );
}

const IS_DISPLAY = {
  YES: 1,
  NO: 0,
};

const defineJobCron = {
  df_sms_type_id: '', //kiểu kịch bản
  message_template_id: '', //id template tin nhắn
  value: '', //thứ/ngày trong tuần/tháng khi ở gửi liên tục
  code: JOB_CRON_TIME_CODE.ALlWAYS,
  time_option: JOB_CRON_TIME_REPEAT.ONCE, //một lần/định kì
  time_repeat: '', //timestamp khi time_option là định kì
  is_display: IS_DISPLAY.NO,
};

class Context extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      tab: '1',
      modalAdd: false,
      modalEdit: false,
      id: '',
      formAddModal: {
        scenario: {
          description: '',
          scenario_steps: [],
          job_cron: defineJobCron,
        },
      },
      formAdd: {
        content: '',
        order: '',
        name: '',
      },

      formEditModal: {
        scenario: {
          description: '',
          scenario_steps: [],
        },
      },
      formEdit: {
        content: '',
        order: '',
        name: '',
      },
      isLoading: false,
      idLoadingDetail: true,
      selectSendSMS: undefined,
      //kịch bản mới
      sms_type_id: SCRIPT_TYPE.FOR_TELEMARKETING, // = df_sms_type_id
    };
    //callback lưu bước 2
    this.saveStep = this.createScenario.bind(this);
  }

  modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      [{ align: [] }],
      ['link', 'image', 'video'],
      ['clean'],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
    ],
  };

  removeStep(index) {
    var newArr = [...this.state.formAddModal.scenario.scenario_steps];
    newArr.splice(index, 1);
    this.setState({
      formAddModal: {
        ...this.state.formAddModal,
        scenario: {
          ...this.state.formAddModal.scenario,
          scenario_steps: newArr,
        },
      },
    });
  }

  updateStep(index) {
    var newArr = [...this.state.formAddModal.scenario.scenario_steps];
    newArr.splice(index, 1);
    const check = this.checkValidationEditModal();
    if (check) {
      this.setState(
        {
          formAddModal: {
            ...this.state.formAddModal,
            scenario: {
              ...this.state.formAddModal.scenario,
              scenario_steps: [this.state.formEdit, ...newArr],
            },
          },
        },
        () => {
          // this.setShow('modalEdit', false);
          this.setState({
            ...this.state,
            modalEdit: false,
            formEdit: {
              content: '',
              order: '',
              name: '',
            },
          });
        }
      );
    }
  }

  componentDidMount() {
    console.log('this.props', this.props);
    if (this.props.jobDetail.data.job_id) {
      (async () => {
        try {
          this.setState({ isLoadingDetail: true });

          const { data } = await requestGetJobDetail({
            id: this.props.jobDetail.data.job_id,
          });
          this.setState({
            ...this.state,
            formAddModal: {
              ...this.state.formAddModal,
              scenario: data.scenario
                ? {
                    ...data.scenario,
                    job_cron: data.job_cron ? data.job_cron : defineJobCron,
                  }
                : {
                    description: '',
                    scenario_steps: [],
                    job_cron: defineJobCron,
                  },
            },
            sms_type_id: data.job_cron
              ? data.job_cron.df_sms_type_id
              : data.sms_message_template_id
              ? SCRIPT_TYPE.SEND_SMS
              : SCRIPT_TYPE.FOR_TELEMARKETING,
            selectSendSMS:
              !data.job_cron && data.sms_message_template_id
                ? data.sms_message_template_id
                : undefined,
          });
        } catch (error) {
          console.log('Error componentDidMount: ', { error });
        } finally {
          this.setState({ isLoadingDetail: false });
          localStorage.setItem(
            'infoInitLoad',
            JSON.stringify({
              sms_type_id_init: this.state.sms_type_id,
              formAddModal_init: this.state.formAddModal,
            })
          );
        }
      })();
    }
    //callback lưu bước 2
    this.props.onSaveStep(this.saveStep);
  }

  componentWillUnmount() {
    localStorage.removeItem('infoInitLoad');
  }

  handleChangeFormAdd(field, value) {
    this.setState({
      formAdd: {
        ...this.state.formAdd,
        [field]: value,
      },
    });
  }

  handleChangeFormEdit(field, value) {
    this.setState({
      formEdit: {
        ...this.state.formEdit,
        [field]: value,
      },
    });
  }

  getData(payload) {
    this.props.getJobDetail({ id: payload });
  }

  checkValidationAddModal = () => {
    const { formAdd, formAddModal } = this.state;

    const checkOrderStep = formAddModal.scenario.scenario_steps.findIndex(
      ques => parseInt(ques.order) === parseInt(formAdd.order)
    );

    if (!formAdd.order.trim()) {
      notifyFail('Vui lòng nhập số bước');
      return false;
    } else {
      if (checkOrderStep !== -1) {
        notifyFail('Vui lòng không nhập trùng số bước!');
        return false;
      }
    }
    if (!formAdd.name.trim()) {
      notifyFail('Vui lòng nhập tên bước');
      return false;
    }
    if (!formAdd.content.trim()) {
      notifyFail('Vui lòng nhập tên nội dung');
      return false;
    }
    return true;
  };

  checkValidationEditModal = () => {
    const { formEdit } = this.state;
    if (!formEdit.order.trim()) {
      notifyFail('Vui lòng nhập số bước');
      return false;
    }
    if (!formEdit.name.trim()) {
      notifyFail('Vui lòng nhập tên bước');
      return false;
    }
    if (!formEdit.content.trim()) {
      notifyFail('Vui lòng nhập tên nội dung');
      return false;
    }
    return true;
  };

  validateFormAdd() {
    const { formAddModal, formAdd } = this.state;
    const job = this.props.jobDetailState.data;
    if (job.job_id === undefined) {
      return false;
    }
    if (!checkAllAttrFilled(formAddModal)) {
      console.log('formAddModal');
      return false;
    }

    // if (!checkAllAttrFilled(formAdd)) {
    //   console.log('formAdd');
    //   return false;
    // }
    // if (NULL_ARRAY.exec(formAddModal?.scenario?.scenario_steps) === null) {
    //   console.log('formAddModal?.scenario?.scenario_step');
    //   return false;
    // }

    return true;
  }

  getResetAdd() {
    return {
      formAddModal: {
        scenario: {
          description: '',
          scenario_steps: [
            {
              content: '',
              order: '',
            },
          ],
        },
      },
    };
  }

  setLoading(value) {
    this.props.setLoading(value);
  }

  onFinish = type => {
    //   ModalAntd.confirm({
    //     title: 'Xác nhận',
    //     icon: <ExclamationCircleOutlined />,
    //     content: 'Bạn có muốn lưu thông tin trước khi chuyển sang nội dung khác?',
    //     okText: 'Lưu',
    //     cancelText: 'Hủy',
    // onOk: () =>

    this.createScenario(type);

    // });
  };

  async createScenario(type) {
    const { formAddModal, formAdd, sms_type_id } = this.state;
    const job = this.props.jobDetailState.data;
    const check = await this.validateFormAdd();

    if (check) {
      let payload = {
        id: job.job_id,
        scenario: {
          description: formAddModal.scenario?.description,
          scenario_steps: formAddModal.scenario?.scenario_steps,
        },
      };

      const { job_cron } = formAddModal.scenario;

      switch (sms_type_id) {
        case SCRIPT_TYPE.FOR_TELEMARKETING:
          // payload.scenario = {
          //   description: formAddModal.scenario?.description,
          //   scenario_steps: formAddModal.scenario?.scenario_steps,
          // };
          break;

        case SCRIPT_TYPE.FOR_ZNS:
          if (!job_cron?.oa_provider_id) {
            message.warning(VietnameseString.please_select_an_account);
            return false;
          }

          if (!job_cron?.message_template_id) {
            message.warning(VietnameseString.please_select_a_template);
            return false;
          }

          if (job_cron.time_option === JOB_CRON_TIME_REPEAT.ONCE) {
            delete job_cron.code;
            delete job_cron.value;
            if (!job_cron.time_repeat) {
              message.warning(VietnameseString.please_select_sending_time);
              return false;
            }
          } else {
            if (job_cron.code === JOB_CRON_TIME_CODE.ALlWAYS) {
              delete job_cron.time_repeat;
              delete job_cron.value;
            } else if (job_cron.code === JOB_CRON_TIME_CODE.DATE) {
              delete job_cron.value;
              if (!job_cron.time_repeat) {
                message.warning(VietnameseString.please_select_sending_time);
                return false;
              }
            } else {
              if (!job_cron.time_repeat || !job_cron.value) {
                message.warning(VietnameseString.please_select_sending_time);
                return false;
              }
            }
          }

          payload.scenario = {
            ...payload.scenario,
            job_cron: {
              ...formAddModal.scenario.job_cron,
              df_sms_type_id: sms_type_id,
            },
          };
          break;

        case SCRIPT_TYPE.FOR_SMS:
          if (!job_cron?.sms_provider_id) {
            message.warning(VietnameseString.please_select_a_number_prefix);
            return false;
          }

          if (!job_cron?.message_template_id) {
            message.warning(VietnameseString.please_select_a_template);
            return false;
          }

          if (job_cron.time_option === JOB_CRON_TIME_REPEAT.ONCE) {
            delete job_cron.code;
            delete job_cron.value;
            if (!job_cron.time_repeat) {
              message.warning(VietnameseString.please_select_sending_time);
              return false;
            }
          } else {
            if (job_cron.code === JOB_CRON_TIME_CODE.ALlWAYS) {
              delete job_cron.time_repeat;
              delete job_cron.value;
            } else if (job_cron.code === JOB_CRON_TIME_CODE.DATE) {
              delete job_cron.value;
              if (!job_cron.time_repeat) {
                message.warning(VietnameseString.please_select_sending_time);
                return false;
              }
            } else {
              if (!job_cron.time_repeat || !job_cron.value) {
                message.warning(VietnameseString.please_select_sending_time);
                return false;
              }
            }
          }

          payload.scenario = {
            ...payload.scenario,
            job_cron: {
              ...formAddModal.scenario.job_cron,
              df_sms_type_id: sms_type_id,
            },
          };
          break;
        case SCRIPT_TYPE.SEND_SMS:
          if (!this.state.selectSendSMS) {
            message.warning('Vui lòng chọn template!');
            return;
          }
          payload.scenario = {
            description: '',
            scenario_steps: [],
            message_template_id: this.state.selectSendSMS,
            // oa_provider_id: '',
            // sms_provider_id: ''
          };
          break;
        case SCRIPT_TYPE.SEND_ZALO_PERSON:
          if (!job_cron?.zalo_account_id) {
            message.warning('Vui lòng chọn tài khoản Zalo!');
            return false;
          }

          if (!job_cron?.message_template_id) {
            message.warning(VietnameseString.please_select_a_template);
            return false;
          }

          if (job_cron.time_option === JOB_CRON_TIME_REPEAT.ONCE) {
            delete job_cron.code;
            delete job_cron.value;
            if (!job_cron.time_repeat) {
              message.warning(VietnameseString.please_select_sending_time);
              return false;
            }
          } else {
            if (job_cron.code === JOB_CRON_TIME_CODE.ALlWAYS) {
              delete job_cron.time_repeat;
              delete job_cron.value;
            } else if (job_cron.code === JOB_CRON_TIME_CODE.DATE) {
              delete job_cron.value;
              if (!job_cron.time_repeat) {
                message.warning(VietnameseString.please_select_sending_time);
                return false;
              }
            } else {
              if (!job_cron.time_repeat || !job_cron.value) {
                message.warning(VietnameseString.please_select_sending_time);
                return false;
              }
            }
          }

          payload.scenario = {
            ...payload.scenario,
            job_cron: {
              ...formAddModal.scenario.job_cron,
              df_sms_type_id: sms_type_id,
            },
          };
          break;
        default:
          break;
      }

      try {
        this.setLoading(true);
        const res = await requestCreateScenario(payload);
        this.setState({ isLoading: false });
        this.props.getJobDetail({ id: res.data.job_id });
        this.setLoading(false);
        const stepStatus = this.props.stepStatus;
        if (type === 'save') {
          notifySuccess('Thêm công việc thành công');
          this.props.history.push(ROUTER.JOB);
        }
        if (type === 'prev') {
          stepStatus[1] = 'finish'; //hoàn thành bước 2
          stepStatus[0] = 'process'; //chuyển trạng thái bước 1
          this.props.setStep(parseInt(this.props.currentStep) - 1, stepStatus);
        }
        if (type === 'next') {
          stepStatus[1] = 'finish'; //hoàn thành bước 2
          stepStatus[2] = 'process'; //chuyển trạng thái bước 3
          this.props.setStep(parseInt(this.props.currentStep) + 1, stepStatus);
        }
        if (type.includes('changeStep')) {
          let step = type.split(':');
          stepStatus[1] = 'finish'; //hoàn thành bước 2
          stepStatus[parseInt(step[1])] = 'process'; // chuyển trạng thái bước được chọn trên menu step
          this.props.setStep(parseInt(step[1]), stepStatus);
        }
      } catch (err) {
        this.setState(
          {
            isLoading: false,
          },
          () => notifyFail(err.msg)
        );
        this.setLoading(false);
      }
    } else {
      const stepStatus = this.props.stepStatus;
      if (type === 'save') {
        notifySuccess('Thêm công việc thành công');
        this.props.history.push(ROUTER.JOB);
      }
      if (type === 'prev') {
        stepStatus[1] = 'finish'; //hoàn thành bước 2
        stepStatus[0] = 'process'; //chuyển trạng thái bước 1
        this.props.setStep(parseInt(this.props.currentStep) - 1, stepStatus);
      }
      if (type === 'next') {
        stepStatus[1] = 'finish'; //hoàn thành bước 2
        stepStatus[2] = 'process'; //chuyển trạng thái bước 3
        this.props.setStep(this.props.currentStep + 1, stepStatus);
      }
      if (type.includes('changeStep')) {
        let step = type.split(':');
        stepStatus[parseInt(step[1])] = 'process'; // chuyển trạng thái bước được chọn trên menu step
        this.props.setStep(parseInt(step[1]), stepStatus);
      }
    }
  }

  checkValidationAdd = () => {
    const { scenario } = this.state.formAddModal;
    const job = this.props.jobDetailState.data;
    if (
      scenario?.scenario_steps?.content === undefined ||
      scenario?.scenario_steps?.order === undefined
    ) {
      return <FormError errorMessage={' Vui lòng nhập đầy đủ thông tin!'} />;
    } else {
      return true;
    }
  };

  addModal = () => {
    const { modalAdd, formAddModal, formAdd } = this.state;
    reactotron.log(formAddModal);
    return (
      <Modal
        show={modalAdd}
        onHide={() => this.setShowModal('modalAdd', false)}
        dialogClassName="modal-90w"
        centered
        scrollable
        value={this.state.formAdd}
        size="lg"
      >
        <Modal.Header closeButton className="bg-primary">
          <h5 className="text-light pb-0">Tạo kịch bản</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          {/* {this.checkValidationAdd()} */}
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Bước</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="number"
                min="1"
                placeholder="Bước"
                value={formAddModal.scenario.scenario_steps?.order}
                onChange={e =>
                  this.handleChangeFormAdd('order', e.target.value)
                }
              ></FormControl>
            </Row>
          </FormGroup>

          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Tên bước</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                placeholder="Tên bước"
                value={formAddModal.scenario.scenario_steps?.name}
                onChange={e => this.handleChangeFormAdd('name', e.target.value)}
              ></FormControl>
            </Row>
          </FormGroup>

          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Nội dung</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                as="textarea"
                rows="6"
                placeholder="Nhập nội dung"
                value={formAddModal.scenario.scenario_steps?.content}
                onChange={e =>
                  this.handleChangeFormAdd('content', e.target.value)
                }
              ></FormControl>
            </Row>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          {/* <Button variant="primary" className="text-light" onClick={() => this.addDescriptionChangeNoCloseModal()}>
            Thêm mới và tiếp tục
          </Button> */}
          <Button
            variant="success"
            className="text-light"
            onClick={() => this.addDescriptionChange()}
          >
            Lưu
          </Button>
          <Button
            variant="danger"
            className="text-light"
            onClick={() => this.setShowModal('modalAdd', false)}
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  editModal = () => {
    const { modalEdit, formEditModal, formEdit } = this.state;
    return (
      <Modal
        show={modalEdit}
        onHide={() => this.setShow('modalEdit', false)}
        dialogClassName="modal-90w"
        centered
        scrollable
        value={this.state.formEdit}
        size="lg"
      >
        <Modal.Header closeButton className="bg-primary">
          <h5 className="text-white">Sửa bước thực hiện</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Bước</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="number"
                min="1"
                placeholder="Bước"
                value={formEdit.order}
                onChange={e =>
                  this.handleChangeFormEdit('order', e.target.value)
                }
              ></FormControl>
            </Row>
          </FormGroup>

          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Tên bước</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                placeholder="Tên bước"
                value={formEdit.name}
                onChange={e =>
                  this.handleChangeFormEdit('name', e.target.value)
                }
              ></FormControl>
            </Row>
          </FormGroup>

          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Nội dung</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                as="textarea"
                rows="6"
                placeholder="Nhập nội dung"
                value={formEdit.content}
                onChange={e =>
                  this.handleChangeFormEdit('content', e.target.value)
                }
              ></FormControl>
            </Row>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="success"
            onClick={() => this.updateStep(formEdit.index)}
          >
            <span className="text-white">Lưu</span>
          </Button>
          <Button
            variant="danger"
            onClick={() => this.setShow('modalEdit', false)}
          >
            <span className="text-white">Đóng</span>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderHeader() {
    return (
      // <Row className="py-3">
      //   <Col sm={12} className="px-0">
      //     <h4>Kịch bản</h4>
      //   </Col>
      // </Row>
      <RowAntd justify="space-between">
        <ColAntd>
          <TitleStyle>{STRING.the_script}</TitleStyle>
        </ColAntd>
        <ColAntd>
          {/* <ButtonPrimaryStyle type="primary" htmlType="button" onClick={() => this.onFinish('save')}>
            <DownloadOutlined style={{ marginRight: '10px' }} />
            Lưu
          </ButtonPrimaryStyle> */}
        </ColAntd>
      </RowAntd>
    );
  }

  checkValidation = () => {
    const { scenario } = this.state.formAddModal;
    const job = this.props.jobDetailState.data;
    const { formAddModal, formAdd } = this.state;

    if (job?.job_id === undefined) {
      return <FormError errorMessage={' Vui lòng nhập thông tin chung!'} />;
    }
    if (
      scenario?.description === '' ||
      scenario?.scenario_steps?.length === 0
    ) {
      return <FormError errorMessage={' Vui lòng điền đầy đủ thông tin!'} />;
    }
    if (!checkAllAttrFilled(formAdd)) {
      return <FormError errorMessage={' Vui lòng điền các bước thực hiện!'} />;
    }
    if (NULL_ARRAY.exec(formAddModal?.scenario?.scenario_steps) === null) {
      return <FormError errorMessage={' Vui lòng điền các bước thực hiện!'} />;
    } else {
      return true;
    }
  };
  handleChangeFrom = value => {
    this.setState({
      ...this.state,
      formAddModal: {
        ...this.state.formAddModal,
        scenario: {
          ...this.state.formAddModal.scenario,
          description: value === '<p><br></p>' ? '' : value,
        },
      },
    });
  };
  handleSelect = valueSelect => {
    console.log('handleSelect', valueSelect);
    this.setState({
      selectSendSMS: valueSelect,
    });
  };
  renderContext() {
    switch (this.state.sms_type_id) {
      case SCRIPT_TYPE.FOR_TELEMARKETING:
        const scenario_steps = this.state.formAddModal.scenario?.scenario_steps;
        scenario_steps.sort(function (a, b) {
          return a.order - b.order;
        });
        return (
          <Row className="">
            {/* {this.props.shouldValidate && this.checkValidation()} */}
            <Col sm={12} className="text-left mb-3" style={{ padding: 0 }}>
              <span>Mô tả kịch bản</span>
              <ReactQuill
                value={this.state.formAddModal.scenario.description}
                modules={this.modules}
                onChange={this.handleChangeFrom}
              />
            </Col>
            <Col sm={12} className="d-flex align-items-center mb-3 p-0">
              <span>Các bước thực hiện</span>
              <i
                className="fas fa-plus px-3 text-green add-btn"
                onClick={() => this.setShowModal('modalAdd', true)}
              ></i>
            </Col>
            <Col sm={12} className="> p-0">
              {scenario_steps.length > 0 &&
                scenario_steps?.map((content, index) => (
                  <div
                    style={{
                      padding: '10px 5px',
                      backgroundColor: '#ffffff',
                      borderRadius: '12px',
                      boxShadow: '0 20px 80px rgba(0, 0, 0, 0.2)',
                      marginTop: '10px',
                    }}
                  >
                    <Step
                      form={content}
                      key={index}
                      index={index}
                      destroyer={this.removeStep.bind(this)}
                      update={this.setShow.bind(this)}
                    />
                  </div>
                ))}
            </Col>
          </Row>
        );

      case SCRIPT_TYPE.FOR_ZNS:
        return (
          <ScriptOfSmsOrZns
            isCheckEvoucher={this.props?.isCheckEvoucher}
            type={SCRIPT_TYPE.FOR_ZNS}
            dataJobCron={this.state.formAddModal.scenario.job_cron}
            handleChangeFormAdd={data => {
              this.setState({
                formAddModal: {
                  scenario: {
                    ...this.state.formAddModal.scenario,
                    job_cron: {
                      ...this.state.formAddModal.scenario.job_cron,
                      ...data,
                    },
                  },
                },
              });
            }}
          />
        );

      case SCRIPT_TYPE.SEND_ZALO_PERSON:
        return (
          <ScriptOfZalo
            isCheckEvoucher={this.props?.isCheckEvoucher}
            type={SCRIPT_TYPE.SEND_ZALO_PERSON}
            dataJobCron={this.state.formAddModal.scenario.job_cron}
            handleChangeFormAdd={data => {
              console.log('data', {
                formAddModal: {
                  scenario: {
                    ...this.state.formAddModal.scenario,
                    job_cron: {
                      ...this.state.formAddModal.scenario.job_cron,
                      ...data,
                    },
                  },
                },
              });
              this.setState({
                formAddModal: {
                  scenario: {
                    ...this.state.formAddModal.scenario,
                    job_cron: {
                      ...this.state.formAddModal.scenario.job_cron,
                      ...data,
                    },
                  },
                },
              });
            }}
          />
        );

      case SCRIPT_TYPE.FOR_SMS:
        return (
          <ScriptOfSmsOrZns
            isCheckEvoucher={this.props?.isCheckEvoucher}
            idEvoucher={this.props?.idEvoucher}
            type={SCRIPT_TYPE.FOR_SMS}
            dataJobCron={this.state.formAddModal.scenario.job_cron}
            handleChangeFormAdd={data => {
              this.setState({
                formAddModal: {
                  scenario: {
                    ...this.state.formAddModal.scenario,
                    job_cron: {
                      ...this.state.formAddModal.scenario.job_cron,
                      ...data,
                    },
                  },
                },
              });
            }}
          />
        );
      case SCRIPT_TYPE.SEND_SMS:
        return (
          <SaleSendSms
            handleSelect={this.handleSelect}
            selectSendSMS={this.state.selectSendSMS}
          />
        );
      default:
        break;
    }
  }

  setShowModal(modal, value) {
    this.setState({
      modalAdd: value,
    });
  }

  setShow(modal, value, form, index) {
    this.setState({
      modalEdit: value,
      formEdit: {
        ...form,
        index: index,
      },
    });
  }

  addDescriptionChange = () => {
    // const { scenario_steps } = this.state.formAddModal.scenario;
    // scenario_steps.sort(function (a, b) {
    //   return a.order - b.order;
    // });
    const check = this.checkValidationAddModal();
    if (check) {
      this.setState(
        {
          formAddModal: {
            ...this.state.formAddModal,
            scenario: {
              ...this.state.formAddModal?.scenario,
              scenario_steps: [
                this.state.formAdd,
                ...this.state.formAddModal?.scenario?.scenario_steps,
              ],
            },
          },
        },
        () => {
          // this.setShowModal('modalAdd', false);
          this.setState({
            ...this.state,
            modalAdd: false,
            formAdd: {
              content: '',
              order: '',
              name: '',
            },
          });
        }
      );
    }
  };

  // addDescriptionChangeNoCloseModal = () => {
  //   this.setState(
  //     {
  //       ...this.state,
  //       formAddModal: {
  //         ...this.state.formAddModal,
  //         scenario: {
  //           ...this.state.formAddModal?.scenario,
  //           scenario_steps: [this.state.formAdd, ...this.state.formAddModal?.scenario?.scenario_steps],
  //         },
  //       },
  //     },
  //     () => this.setShowModal('modalAdd', true)
  //   );
  // };

  addDescription = async (field, value) => {
    // field = discription
    await this.setState({
      // ...this.state,
      formAddModal: {
        ...this.state.formAddModal,
        scenario: {
          ...this.state.formAddModal.scenario,
          [field]: value,
        },
      },
    });
  };

  renderButton() {
    return (
      <RowAntd align="middle" justify="space-between">
        <ColAntd>
          <ButtonSystemStyle
            htmlType="button"
            onClick={() => this.onFinish('prev')}
          >
            Quay lại
          </ButtonSystemStyle>
        </ColAntd>

        <ColAntd>
          <ButtonSystemStyle
            style={{ marginTop: '10px' }}
            htmlType="button"
            onClick={() => this.onFinish('next')}
          >
            Tiếp tục
          </ButtonSystemStyle>
        </ColAntd>
      </RowAntd>
    );
  }

  render() {
    console.log('state', this.state);
    return (
      <>
        {this.renderHeader()}

        {this.state.isLoadingDetail ? (
          <Skeleton active />
        ) : (
          <>
            <Radio.Group
              style={{ marginBottom: '12px' }}
              value={this.state.sms_type_id}
              name="script_group"
              onChange={event => {
                const { formAddModal } = this.state;
                const { scenario } = formAddModal;
                let infoInitLoad = JSON.parse(
                  localStorage.getItem(`infoInitLoad`)
                );
                if (
                  infoInitLoad &&
                  event.target.value === infoInitLoad.sms_type_id_init
                ) {
                  this.setState({
                    sms_type_id: event.target.value,
                    formAddModal: infoInitLoad.formAddModal_init,
                  });
                  return;
                }
                this.setState({
                  sms_type_id: event.target.value,
                  formAddModal: {
                    ...formAddModal,
                    scenario: {
                      ...scenario,
                      job_cron: {
                        ...scenario.job_cron,
                        message_template_id: '', //chọn template
                        oa_provider_id: '', //chọn đầu số
                        sms_provider_id: '', // tài khoản
                      },
                    },
                  },
                });
              }}
            >
              <Radio value={SCRIPT_TYPE.FOR_TELEMARKETING}>
                {VietnameseString.script_for_marketing}
              </Radio>
              <Radio value={SCRIPT_TYPE.FOR_ZNS}>
                {VietnameseString.script_for_zns}
              </Radio>
              <Radio value={SCRIPT_TYPE.FOR_SMS}>
                {VietnameseString.script_for_sms}
              </Radio>
              <Radio value={SCRIPT_TYPE.SEND_SMS}>
                Kịch bản Sale gửi tin nhắn
              </Radio>
              <Radio value={SCRIPT_TYPE.SEND_ZALO_PERSON}>
                Kịch bản Zalo cá nhân
              </Radio>
            </Radio.Group>

            {this.renderContext()}
            {this.renderButton()}
            {this.addModal()}
            {this.editModal()}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  jobDetailState: state.jobDetailReducer,
});

const mapDispatchToProps = {
  getJobDetail,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Context));
