import {
    GET_OCCASION, GET_OCCASION_SUCCESS, GET_OCCASION_FAIL,
} from "@actions/type";

const initialState = {
    data: {},
    isLoading: true,
    error: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_OCCASION:
            return {
                ...state,
                isLoading: true,
            }
        case GET_OCCASION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload.response,
                error: null
            }
        case GET_OCCASION_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        default:
            return state;
    }
}