import Loading from '@src/components/Loading';
import '@styles/Settings.css';
import { notifyFail, notifySuccess } from '@utils/notify';
import React, { useEffect, useState } from 'react';
import { Button, Col, FormControl, Modal, Row, Table } from 'react-bootstrap';
// import Pagination from 'react-js-pagination';
import {
  requestDeleteCustomerAttribute,
  requestGetAttributeDetail,
  requestGetAttributeList,
} from '@constants/Api';
import { Button as ButtonAntd, Empty, Pagination, Select } from 'antd';
import moment from 'moment';
import AddUpdateAttribute from './AddUpdateAttribute';

const { Option } = Select;

// btnEdit fa fa-fw fa-edit

const attributeTypes = [
  { value: 'options', label: 'Select' },
  { value: 'number', label: 'Number' },
  { value: 'date_time', label: 'Datetime' },
  { value: 'text', label: 'Text' },
];

const Header = props => {
  return (
    <>
      <Col sm={12} md={12}>
        <Row>
          <Col sm={12} className="d-flex justify-content-between">
            <h5>Thuộc tính khách hàng</h5>
            <Button
              variant="success"
              className="text-light"
              onClick={() => {
                props.onOpenAttributeModal();
              }}
            >
              Thêm thuộc tính
            </Button>
          </Col>
        </Row>
      </Col>
      <Col sm={12} md={12}>
        <Row>
          <Col md={6} sm={12}>
            <FormControl
              onKeyPress={e => {
                props.onKeyPress(e);
              }}
              type="text"
              placeholder="Nhập tên thuộc tính"
              value={props.searchedName}
              onChange={props.onSearchAttributeName}
            />
          </Col>
          <Col md={3} sm={12}>
            <FormControl
              as="select"
              id=""
              value={props.isActived}
              onChange={props.onSearchAttributeActive}
            >
              <option value="">Trạng thái</option>
              <option value="1">Đang hoạt động</option>
              <option value="2">Tạm Ngưng hoạt động</option>
            </FormControl>
          </Col>
        </Row>
      </Col>
    </>
  );
};

const AttributesTable = props => {
  return (
    <Col sm={12} md={12}>
      <div className="row">
        <Col
          sm={10}
          md={12}
          style={{
            padding: '0px',
            marginTop: '20px',
          }}
        >
          <Row className="table-wrap mx-3">
            <Table striped bordered hover style={{ textAlign: 'center' }}>
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Tên thuộc tính</th>
                  <th>Mã thuộc tính</th>
                  <th>Loại thuộc tính</th>
                  <th>STT hiển thị</th>
                  <th>Trạng thái hoạt động</th>
                  <th>Ngày tạo</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {props.data.length ? (
                  props.data.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          {index +
                            props.formattedPaging.pageSize *
                              (props.formattedPaging.current - 1) +
                            1}
                        </td>
                        <td>{item.name}</td>
                        <td>{item.code}</td>
                        <td>
                          {item.data_type === 'ref'
                            ? 'Thông số mặc định'
                            : item.data_type === 'options'
                            ? 'Select'
                            : item.data_type === 'number'
                            ? 'Number'
                            : item.data_type === 'text'
                            ? 'Text'
                            : item.data_type === 'date_time'
                            ? 'Datetime'
                            : ''}
                        </td>
                        <td>{item.position}</td>
                        <td>
                          {item.is_active === 1
                            ? 'Đang hoạt động'
                            : 'Ngừng hoạt động'}
                        </td>
                        <td>{moment(item.created_at).format('DD/MM/YYYY')}</td>
                        <td>
                          <div className="d-flex justify-content-center align-items-center">
                            <i
                              className="btnEdit fa fa-fw fa-edit"
                              onClick={() => {
                                props.onOpenModalUpdate(item);
                              }}
                            />
                            <i
                              className="btnDelete far fa-trash-alt"
                              onClick={() => {
                                if (item.data_type === 'ref') {
                                  notifyFail(
                                    'Bạn không có quyền truy cập thuộc tính này!'
                                  );
                                } else {
                                  props.onOpenModalDelete(item.id);
                                }
                              }}
                              style={{
                                color: item.data_type === 'ref' ? 'grey' : '',
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="7">
                      <Empty description="Không có dữ liệu" />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Row>
          <div className="mx-3 text-right">
            <Pagination
              {...props.formattedPaging}
              onChange={props.onChangePage}
            />
          </div>
        </Col>
      </div>
    </Col>
  );
};

const ModalConfirmDelete = props => {
  return (
    <Modal
      show={props.modalVisible}
      onHide={() => {
        props.onClose();
      }}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      centered
    >
      <Modal.Header closeButton className="bg-red">
        <h5 className="text-light pb-0">
          Bạn chắc chắn muốn xóa thuộc tính này?
        </h5>
      </Modal.Header>
      <Modal.Body className="custom-body">
        <Row>
          <Col className="button-wrapper" style={{ textAlign: 'center' }}>
            <ButtonAntd
              className="text-light"
              style={{ background: 'green' }}
              onClick={() => {
                props.onConfirm();
              }}
            >
              Có
            </ButtonAntd>
            <ButtonAntd
              style={{ background: 'red' }}
              className="text-light"
              onClick={() => {
                props.onClose();
              }}
            >
              Thoát
            </ButtonAntd>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default function CustomerData() {
  const [isLoading, setIsLoading] = useState(true);
  const [customerData, setCustomerData] = useState([]);
  const [searchedName, setSearchedName] = useState('');
  const [isActived, setIsActived] = useState('');
  const [attributeModalVisible, setAttributeModalVisible] = useState(false);
  const [shouldAddAttribute, setShouldAddAttribute] = useState(true);
  const [attributeModal, setAttributeModal] = useState({
    attributeName: '',
    attributeType: null,
    choices: [],
    numericalOrder: null,
    status: null,
  });
  const [modalConfirmVisible, setModalConfirmVisible] = useState(false);
  const [currentAttributeId, setCurrentAttributeId] = useState(null);
  const [page, setPage] = useState(1);
  const [formattedPaging, setFormattedPaging] = useState({
    total: null,
    current: null,
    pageSize: null,
  });

  useEffect(() => {
    getAttributeList();
  }, [searchedName, page, isActived]);

  const getAttributeList = async () => {
    try {
      setIsLoading(true);
      const res = await requestGetAttributeList({
        is_config: 1,
        page: page,
        limit: 12,
        search: searchedName,
        is_active: isActived,
      });
      setFormattedPaging({
        total: res.paging.totalItemCount,
        current: res.paging.page,
        pageSize: res.paging.limit,
      });
      setCustomerData(res.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  // ModalAddUpdateAttribute funcs
  const handleCloseAttributeModal = () => {
    setAttributeModal({
      attributeName: '',
      attributeType: null,
      choices: [],
      numericalOrder: null,
      status: null,
    });
    setAttributeModalVisible(false);
  };

  const handleChangeValue = (fieldName, value) => {
    setAttributeModal({
      ...attributeModal,
      [fieldName]: value,
    });
  };

  // ModalConfirmDelete funcs
  const handleCloseModalDelete = () => {
    setModalConfirmVisible(false);
  };

  const handleConfirmDelete = async () => {
    try {
      setIsLoading(true);
      const res = await requestDeleteCustomerAttribute({
        id: currentAttributeId,
      });
      if (parseInt(res.status) === 1) {
        notifySuccess('Xóa thành công!');
        setModalConfirmVisible(false);
        const getDataTimeout = setTimeout(() => {
          getAttributeList();
          setIsLoading(false);
        }, 1000);

        return () => clearTimeout(getDataTimeout);
      } else {
        notifyFail(res.msg);
        setIsLoading(false);
      }
    } catch (error) {
      notifyFail(error.msg);
      setModalConfirmVisible(false);
      setIsLoading(false);
    }
  };

  // Header funcs
  const handleOpenAttributeModal = () => {
    setShouldAddAttribute(true);
    setAttributeModalVisible(true);
  };

  // AttributesTable funcs
  const handleOpenModalUpdate = async item => {
    try {
      const res = await requestGetAttributeDetail({ id: item.id });
      setAttributeModal({
        ...res.data,
        attributeName: res.data.name,
        attributeType: res.data.data_type === 'ref' ? null : res.data.data_type,
        choices: res.data.customer_data_field_sources.length
          ? res.data.customer_data_field_sources
          : [],
        numericalOrder: res.data.position,
        status: res.data.is_active,
        data_customer_code: res.data.code,
      });
      setShouldAddAttribute(false);
      setAttributeModalVisible(true);
    } catch (error) {}
  };

  const handleOpenModalDelete = id => {
    setModalConfirmVisible(true);
    setCurrentAttributeId(id);
  };

  return (
    <>
      {isLoading && <Loading />}
      {attributeModalVisible && (
        <AddUpdateAttribute
          modalVisible={attributeModalVisible}
          data={attributeModal}
          shouldAddAttribute={shouldAddAttribute}
          onClose={() => {
            handleCloseAttributeModal();
          }}
          // attributeModal={attributeModal}
          // onChangeValue={handleChangeValue}
          formattedPaging={formattedPaging}
          onSuccess={res => {
            if (res.status) {
              setIsLoading(true);
              notifySuccess(
                shouldAddAttribute ? 'Thêm thành công!' : 'Cập nhật thành công!'
              );
              setAttributeModalVisible(false);
              const getDataTimeout = setTimeout(() => {
                getAttributeList();
                setIsLoading(false);
              }, 1500);

              return () => clearTimeout(getDataTimeout);
            } else {
              notifyFail(res.msg);
              setIsLoading(false);
            }
          }}
          onError={error => {
            notifyFail(error.msg);
            setIsLoading(false);
          }}
        />
      )}
      <ModalConfirmDelete
        modalVisible={modalConfirmVisible}
        onClose={() => handleCloseModalDelete()}
        onConfirm={() => handleConfirmDelete()}
      />
      <Header
        onOpenAttributeModal={() => handleOpenAttributeModal()}
        searchedName={searchedName}
        isActived={isActived}
        onSearchAttributeName={e => setSearchedName(e.target.value || '')}
        onSearchAttributeActive={e => setIsActived(e.target.value || '')}
        onKeyPress={e => {
          getAttributeList();
        }}
      />
      <AttributesTable
        data={customerData}
        onOpenModalUpdate={item => handleOpenModalUpdate(item)}
        onOpenModalDelete={id => handleOpenModalDelete(id)}
        formattedPaging={formattedPaging}
        onChangePage={page => setPage(page)}
      />
    </>
  );
}
