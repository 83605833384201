import { Button, Form, Row } from 'antd';
import React from 'react';
import propTypes from 'prop-types';

const FooterForm = ({ onCancel }) => {
  return (
    <Form.Item style={{ margin: '15px 0 10px 0' }}>
      <Row style={{ width: '100%', gap: 15 }}>
        <Button onClick={onCancel} style={{ flex: 1 }}>
          Quay lại
        </Button>
        <Button style={{ flex: 1 }} htmlType="submit" type="primary">
          Xác nhận
        </Button>
      </Row>
    </Form.Item>
  );
};
FooterForm.propTypes = {
  onCancel: propTypes.func,
};

export default FooterForm;
