import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import FormResult from './Context/_Form';
import ModalAddForm from './Context/_ModalAddForm';
import ModalAddSubForm from './Context/_ModalAddSubForm';
import ModalEditForm from './Context/_ModalEditForm';
import { requestCreateGeneralQuestion, requestDeleteForm, requestDeleteFormSetting } from '@constants/Api';
import { notifyFail, notifySuccess } from '@utils/notify';
import { connect } from 'react-redux';
import { getGeneralQuestion } from '@src/redux/actions';
import { Empty } from 'antd';
import { QUESTION_TYPE } from '@constants/Constant';
import Loading from '@src/components/Loading';
import reactotron from 'reactotron-react-js';

class GeneralQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: '1',
      modalAdd: false,
      subModalAdd: false,
      modalEdit: false,
      questionList: [],
      formAdd: {
        type_input: 1,
        name: '',
        reward_point: '',
        job_form_answers: [],
        is_require: false,
      },
      subFormAdd: {
        type_input: 1,
        name: '',
        reward_point: '',
        job_form_answers: [],
        is_require: false,
      },
      formEdit: {
        type_input: 1,
        name: '',
        reward_point: '',
        job_form_answers: [],
        is_require: false,
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    this.props.getGeneralQuestion();
  }

  handleChangeFormAdd(field, value) {
    this.setState({
      formAdd: {
        ...this.state.formAdd,
        [field]: value,
      },
    });
  }

  handleSubFormAdd(field, value) {
    this.setState({
      subFormAdd: {
        ...this.state.subFormAdd,
        [field]: value,
      },
    });
  }

  handleChangeFormEdit(field, value) {
    this.setState({
      formEdit: {
        ...this.state.formEdit,
        [field]: value,
      },
    });
  }

  async addForm() {
    const jobId = this.props?.jobState.data?.job_id;
    try {
      if (this.validateFormAdd(this.state.formAdd)) {
        const res = await requestCreateGeneralQuestion({ ...this.state.formAdd });
        this.props.getGeneralQuestion({ id: jobId });
        this.setState({
          ...this.state,
          formAdd: {
            type_input: '1',
            name: '',
            reward_point: '',
            job_form_answers: [],
            is_require: false,
          },
        });
        if (res.status === 1) {
          this.showModal('modalAdd', false);
          notifySuccess('Thêm mới thành công!');
        }
      } else {
        return;
      }
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  validateFormAdd(form) {
    for (let answer of form.job_form_answers) {
      if (answer.name == 'Nhập thông tin vào đây' || answer.name.trim() == '') {
        notifyFail('Vui lòng nhập đầy đủ tên đáp án');
        return false;
      }
    }
    if (!form.name.trim()) {
      notifyFail('Vui lòng nhập tên câu hỏi');
      return false;
    }
    if (form.reward_point < 1) {
      notifyFail('Điểm thưởng phải dương');
      return false;
    }
    if (
      [QUESTION_TYPE.SELECT, QUESTION_TYPE.MULTIPLE_SELECT, QUESTION_TYPE.YES_NO].includes(parseInt(form.type_input))
    ) {
      if (form.job_form_answers?.length < 1) {
        notifyFail('Vui lòng thêm lựa chọn');
        return false;
      }
    }
    return true;
  }

  async addSubForm(questionId, optionId) {
    const { subFormAdd } = this.state;
    const jobId = this.props?.jobState.data?.job_id;
    if (this.validateFormAdd(subFormAdd)) {
      try {
        const res = await requestCreateGeneralQuestion({
          job_id: jobId,
          id: 0,
          parent_id: optionId,
          type_input: subFormAdd.type_input,
          name: subFormAdd.name,
          reward_point: subFormAdd.reward_point,
          job_form_answers: subFormAdd.job_form_answers,
          is_require: subFormAdd.is_require,
        });
        this.props.getGeneralQuestion({ id: jobId });
        this.setState({
          subFormAdd: {
            type_input: '1',
            name: '',
            reward_point: '',
            job_form_answers: [],
            is_require: false,
          },
          subModalAdd: false,
        });
        notifySuccess('Thêm mới thành công!');
      } catch (err) {
        notifyFail(err.msg);
      }
    } else {
      return;
    }
  }

  async deleteForm(formId) {
    try {
      await requestDeleteFormSetting({ form_id: formId });
      this.props.getGeneralQuestion({ id: this.props.jobState.data.job_id });

      notifySuccess('Xóa thành công');
    } catch (err) {
      notifyFail('');
    }
  }

  async updateForm() {
    const id = this.props.jobState?.data?.id;
    if (this.validateFormAdd(this.state.formEdit)) {
      try {
        const res = await requestCreateGeneralQuestion({
          id: id,
          ...this.state.formEdit,
        });
        this.props.getGeneralQuestion({ id: id });
        this.setState({
          formEdit: {
            type_input: 1,
            name: '',
            reward_point: '',
            job_form_answers: [],
            is_require: false,
          },
          modalEdit: false,
        });
        if (res.status === 1) {
          notifySuccess('Cập nhật thông tin thành công!');
        }
      } catch (err) {
        notifyFail(err.msg);
      }
    } else {
      return;
    }
  }

  renderHeader() {
    return (
      <Row className="py-3">
        <Col sm={12} className="px-0 d-flex justify-content-between">
          <h4>Câu hỏi thu thập chung</h4>
          <i className="fas fa-plus text-green add-btn" onClick={() => this.showModal('modalAdd', true)}></i>
        </Col>
      </Row>
    );
  }

  renderForm() {
    const questionList = this.props.jobState.data;

    if (questionList?.length) {
      console.log(questionList);
      return (
        <>
          <Row>
            <Col sm={12} className=" mt-2">
              {questionList?.length > 0 &&
                questionList.map((item, index) => (
                  <div
                    style={{
                      padding: '30px 20px',
                      backgroundColor: '#ffffff',
                      borderRadius: '12px',
                      boxShadow: '0 20px 80px rgba(0, 0, 0, 0.2)',
                      marginTop: '10px',
                    }}
                  >
                    <FormResult
                      form={{ ...item }}
                      deleteForm={this.deleteForm.bind(this)}
                      showSubModal={this.showSubModal.bind(this)}
                      showEditModal={this.showEditModal.bind(this)}
                      key={item.id}
                      index={index}
                    />
                  </div>
                ))}
            </Col>
          </Row>
        </>
      );
    } else {
      return (
        <Row>
          <Col sm={12} className=" mt-2">
            <Empty description={<span>Chưa có phiếu kết quả nào được tạo</span>} />
          </Col>
        </Row>
      );
    }
  }

  showModal(modal, value) {
    if (value == false) {
      this.setState({
        modalAdd: value,
        formAdd: {
          type_input: '1',
          name: '',
          reward_point: '',
          job_form_answers: [],
          is_require: false,
        },
      });
    } else {
      this.setState({
        modalAdd: value,
      });
    }
  }

  showSubModal(modal, value, parentName, childName) {
    this.setState({
      subModalAdd: value,
      subFormAdd: {
        ...this.state.subFormAdd,
        parent: parentName,
        parentOption: childName,
      },
    });
  }

  showEditModal(modal, value, form) {
    this.setState({
      modalEdit: value,
      formEdit: { ...form },
    });
  }

  // renderButton() {
  //   return (
  //     <Row className="mt-3 mb-5">
  //       <Col xs={12} className="text-center text-md-right px-0">
  //         <Button variant="primary" className="text-light">
  //           Tiếp tục
  //         </Button>
  //       </Col>
  //     </Row>
  //   );
  // }

  render() {
    const { isLoading } = this.props.jobState;
    return (
      <>
        {isLoading ? <Loading /> : ''}
        <ModalAddSubForm
          handle={this.handleSubFormAdd.bind(this)}
          value={this.state.subFormAdd}
          show={this.state.subModalAdd}
          close={this.showSubModal.bind(this)}
          add={this.addSubForm.bind(this)}
        />
        <ModalAddForm
          handle={this.handleChangeFormAdd.bind(this)}
          value={this.state.formAdd}
          show={this.state.modalAdd}
          close={this.showModal.bind(this)}
          add={this.addForm.bind(this)}
        />
        <ModalEditForm
          handle={this.handleChangeFormEdit.bind(this)}
          value={this.state.formEdit}
          show={this.state.modalEdit}
          close={this.showEditModal.bind(this)}
          update={this.updateForm.bind(this)}
        />
        {this.renderHeader()}
        {this.renderForm()}
        {/* {this.renderButton()} */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  jobState: state.generalQuestionReducer,
});

const mapDispatchToProps = {
  getGeneralQuestion,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralQuestion);
