import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '@styles/Settings.css';
import { Row, Col, FormControl, Button, Modal, FormGroup, Table } from 'react-bootstrap';
import { getListPackage } from '@src/redux/actions';
import { connect } from 'react-redux';
import { requestCreatePackage, requestDeletePackage } from '@constants/Api';
import { notifyFail, notifySuccess } from '@utils/notify';
import { checkAllAttrFilled } from '@utils/checkAllAttrFilled';
import Loading from '@src/components/Loading';
import { PHONE_REGEX, NUMBER_REGEX } from '@constants/Constant';
import { Checkbox, Empty, Pagination, TreeSelect } from 'antd';
import reactotron from 'reactotron-react-js';
import styled from 'styled-components';
let timeout;

function formatNumber(n) {
  return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}
const PaginationStyled = styled(Pagination) `
  .ant-pagination-item-link {
    display: flex;
    align-items: center;

  }
`
function formatPrice(price) {
  if (!price) return '';
  return price.toString().split('.').join('');
}

function FormError(props) {
  if (props.isHidden) {
    return null;
  }

  return <div style={{ color: 'red', width: '100%', textAlign: 'center' }}>{props.errorMessage}</div>;
}
class Package extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalAddPackage: false,
      modalEditPackage: false,
      modalAddPackageField: {
        max_telesale: '',
        max_job: '',
        max_hotline: '',
        call_duration: '',
        level: '',
        name: '',
        price: '',
        id: '',
        type: '',
        is_active: '',
      },
      modalEditPackageField: {
        max_telesale: '',
        max_job: '',
        max_hotline: '',
        call_duration: '',
        level: '',
        name: '',
        price: '',
        id: '',
        type: '',
        is_active: '',
      },
      currentIndex: 0,
      id: '',
      confirmModal: false,
      tableInfo: true,
      activePage: 1,
      isLoading: false,
      query: '',
      page: '1',
      search: '',
    };
  }

  componentDidMount() {
    this.getData({ page: 1 });
  }
  getData({ page, search, level, type }) {
    this.props.getListPackage({
      page: page || '',
      search: search || '',
      level: level || '',
      type: type || '',
    });
  }

  validateFormAdd() {
    const { modalAddPackageField } = this.state;
    if (modalAddPackageField?.name?.trim() == '') {
      notifyFail('Vui lòng nhập đầy đủ các thông tin bắt buộc');
      return false;
    }

    if (NUMBER_REGEX.exec(modalAddPackageField.max_telesale) === null || modalAddPackageField.max_telesale < 0) {
      notifyFail('Số sale phải là một số lớn hơn hoặc bằng 0');
      return false;
    }
    if (NUMBER_REGEX.exec(modalAddPackageField.max_job) === null || modalAddPackageField.max_job < 0) {
      notifyFail('Số công việc phải là một số lớn hơn hoặc bằng 0');
      return false;
    }
    if (NUMBER_REGEX.exec(modalAddPackageField.max_hotline) === null || modalAddPackageField.max_hotline < 0) {
      notifyFail('Số hotline phải là một số lớn hơn hoặc bằng 0');
      return false;
    }
    if (formatPrice(modalAddPackageField.price) < 0) {
      notifyFail('Số tiền phải là một số lớn hơn hoặc bằng 0');
      return false;
    }
    if (NUMBER_REGEX.exec(modalAddPackageField.level) === null || modalAddPackageField.level < 0) {
      notifyFail('Cấp độ phải là một số lớn hơn hoặc bằng 0');
      return false;
    }
    return true;
  }

  getResetAddPackageField() {
    return {
      max_telesale: '',
      max_job: '',
      max_hotline: '',
      call_duration: '',
      level: '',
      name: '',
      price: '',
      id: '',
      type: '',
      is_active: '',
    };
  }

  async createPackage() {
    const { modalAddPackageField } = this.state;
    const check = await this.validateFormAdd();
    if (check) {
      const payload = {
        max_telesale: modalAddPackageField.max_telesale || '0',
        max_job: modalAddPackageField.max_job || '0',
        max_hotline: modalAddPackageField.max_hotline || '0',
        call_duration: modalAddPackageField.call_duration || '0',
        level: modalAddPackageField.level || '0',
        name: modalAddPackageField.name.trim() || '',
        price: formatPrice(modalAddPackageField.price) || '0',
        id: modalAddPackageField.id || '0',
        type: modalAddPackageField.type || '0',
        is_active: modalAddPackageField.is_active || 1,
      };
      try {
        const res = await requestCreatePackage(payload);

        this.props.getListPackage();
        this.setState((prevState) => ({
          modalAddPackageField: {
            ...this.getResetAddPackageField(),
          },
        }));
        this.setState({ isLoading: false });
        this.setShowModal('modalAddPackage', false);
        modalAddPackageField.id == 0 ? notifySuccess('Thêm mới thành công') : notifySuccess('Cập nhật thành công');
      } catch (err) {
        this.setState({ isLoading: false });
        // notifyFail(err.msg);
      }
    }
    return;
  }

  async deletePackage() {
    this.setState({
      isLoading: true,
    });
    try {
      await requestDeletePackage({
        id: this.state.id,
      });
      this.setState({ confirmModal: false, isLoading: false }, () => {
        this.props.getListPackage();
        notifySuccess('Xóa thành công!');
      });
    } catch (err) {
      this.setState(
        {
          confirmModal: false,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  showTableInfo(status) {
    this.setState({
      ...this.state,
      tableInfo: status,
    });
  }

  renderBody() {
    return <div>{this.renderPackage()}</div>;
  }

  renderPagination() {
    const { pagging, activePage, page, search } = this.state;
    const itemsCount = this.props.packageState?.data?.paging?.count;
    const limit = this.props.packageState?.data?.paging?.limit;
    return (
      <>
        {itemsCount && (
          <PaginationStyled
            size="small"
            // current={page}
            total={itemsCount?.length}
            pageSize={limit}
            onChange={(page) => {
              this.setState({
                ...this.state,
                activePage: page,
              });
              this.getData({ page, search });
            }}
          />
        )}
      </>
    );
  }

  renderTableData() {
    const pack = this.props.packageState.data?.data;
    const pageNumber = this.props.packageState?.data?.paging?.page;
    const limit = this.props.packageState?.data?.paging?.limit;
    return (
      <tbody>
        {pack?.length > 0 ? (
          pack?.map((value, index) => (
            <tr>
              <td>{index + 1 + (pageNumber - 1) * limit}</td>
              <td>{value?.name || '--'}</td>
              <td>{value?.max_telesale || '0'}</td>
              <td>{value?.max_job || '0'}</td>
              <td>{parseInt(value?.call_duration / 60) || '0'}</td>
              <td>
                {value?.price
                  ? value?.price?.toLocaleString('vi', {
                      currency: 'VND',
                    }) + '(VNĐ)'
                  : '--'}
              </td>
              <td>{value?.count_enterprise || '0'}</td>
              <td>{value?.max_hotline || '0'}</td>
              <td>{value?.type == 1 ? 'Gói chính' : 'Gói phụ'}</td>
              <td>
                {value?.is_active == 1
                  ? 'Đang hoạt động'
                  : value?.is_active == 2
                  ? 'Tạm ngưng hoạt động'
                  : 'Ngừng hoạt động'}
              </td>
              <td>
                <i
                  className="btnEdit fa fa-fw fa-edit m-0"
                  onClick={() => {
                    this.setShowModal('modalAddPackage', true);
                    this.setState({
                      modalAddPackageField: {
                        max_telesale: value.max_telesale,
                        max_job: value.max_job,
                        max_hotline: value.max_hotline,
                        call_duration: value.call_duration,
                        level: value.level,
                        name: value.name.trim(),
                        price: formatNumber(JSON.stringify(value.price)),
                        id: value.id,
                        type: value.type,
                        is_active: value.is_active,
                      },
                      currentIndex: value.id,
                    });
                  }}
                ></i>
                <i
                  className="far fa-trash-alt step-icon-del"
                  onClick={() => {
                    this.setState({
                      id: value.id,
                      confirmModal: true,
                    });
                  }}
                ></i>
              </td>
            </tr>
          ))
        ) : (
          <td colspan="11">
            <Empty description="Không có dữ liệu" />
          </td>
        )}
      </tbody>
    );
  }
  handleChangeSearch = async (field, value) => {
    this.setState(
      {
        ...this.state,
        [field]: value,
      },
      () => {
        if (field === 'search') {
          const { page, search, level, type } = this.state;
          this.getData({
            page: page || '',
            search: search || '',
            level: level || '',
            type: type || '',
          });
        }
      }
    );
  };
  renderPackage() {
    return (
      <>
        <Col sm={12} md={12}>
          <Row>
            <Col sm={12} className="d-flex justify-content-between" style={{ paddingLeft: '20px' }}>
              <h5>Gói dịch vụ</h5>
            </Col>
          </Row>
        </Col>
        <Row justify-content-between style={{ padding: '0 20px' }}>
          <Col md={4}>
            <FormControl
              type="text"
              id=""
              placeholder="Nhập tên gói"
              value={this.state.search}
              onChange={(e) => this.handleChangeSearch('search', e.target.value)}
            />
          </Col>
          <Col md={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="success" className="text-light" onClick={() => this.setShowModal('modalAddPackage', true)}>
              Thêm mới
            </Button>
          </Col>
        </Row>
        <Col sm={12} md={12}>
          <div className="row">
            <Col
              sm={10}
              md={12}
              style={{
                padding: '0px',
                marginTop: '20px',
              }}
            >
              <Row>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>STT</th>
                      <th>Tên gói</th>
                      <th>Số sale</th>
                      <th>Số công việc</th>
                      <th>Số phút gọi</th>
                      <th>Giá</th>
                      <th>Số Enterprise đang sử dụng</th>
                      <th>Số hotline</th>
                      <th>Loại</th>
                      <th>Trạng thái</th>
                      <th></th>
                    </tr>
                  </thead>
                  {this.renderTableData()}
                </Table>
                <Col md={12} className="d-flex justify-content-center justify-content-md-end p-0">
                  {this.renderPagination()}
                </Col>
              </Row>
            </Col>
          </div>
        </Col>
      </>
    );
  }

  setShowModal = (modal, bool, data) => {
    this.setState({
      [modal]: bool,
    });
    if (data) {
      this.setState({
        modalEditPackageField: {
          max_telesale: data.max_telesale,
          max_job: data.max_job,
          max_hotline: data.max_hotline,
          call_duration: data.call_duration,
          level: data.level,
          name: data.name.trim(),
          price: data.price,
          id: data.id,
          type: data.type,
          is_active: data.is_active,
        },
      });
    } else {
      this.setState((prevState) => ({
        modalAddPackageField: {
          ...this.getResetAddPackageField(),
        },
      }));
    }
  };

  searchBarChange = async (field, value) => {
    this.setState(
      {
        ...this.state,
        [field]: value,
      },
      () => {
        if (field !== 'query') {
          const { query, page } = this.state;
          this.getData({
            query: query || '',
            page: page || '',
          });
        }
      }
    );
  };

  addModalPackage = () => {
    const { modalAddPackage, modalAddPackageField } = this.state;
    return (
      <Modal
        show={modalAddPackage}
        onHide={() => this.setShowModal('modalAddPackage', false)}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton className="bg-blue pb-0">
          <h5 className="text-light">
            {' '}
            {modalAddPackageField.id != 0 || modalAddPackageField.id != '' ? 'Sửa gói' : ' Thêm gói'}
          </h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          {/* {this.checkValidation()} */}
          <FormGroup className="text-left">
            <Row>
              <Col md={4}>
                <label>Tên gói(*)</label>
              </Col>
              <Col md={8}>
                <FormControl
                  type="text"
                  placeholder="Nhập tên gói"
                  value={modalAddPackageField.name}
                  onChange={(e) => this.addPackageFieldChange('name', e.target.value)}
                ></FormControl>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="text-left">
            <Row>
              <Col md={4}>
                <label>Loại(*)</label>
              </Col>
              <Col md={8}>
                {modalAddPackageField.id != 0 || modalAddPackageField.id != '' ? (
                  <FormControl
                    as="select"
                    value={modalAddPackageField.type}
                    onChange={(e) => this.addPackageFieldChange('type', e.target.value)}
                    disabled={true}
                  >
                    <option value="" disabled={true}>
                      Loại gói
                    </option>
                    <option value="1">Gói chính</option>
                    <option value="2">Gói phụ</option>
                  </FormControl>
                ) : (
                  <FormControl
                    as="select"
                    value={modalAddPackageField.type}
                    onChange={(e) => this.addPackageFieldChange('type', e.target.value)}
                  >
                    <option value="">Loại gói</option>
                    <option value="1">Gói chính</option>
                    <option value="2">Gói phụ</option>
                  </FormControl>
                )}
              </Col>
            </Row>
          </FormGroup>
          {modalAddPackageField.type == 1 || modalAddPackageField.type == '' ? (
            <>
              <FormGroup className="text-left">
                <Row>
                  <Col md={4}>
                    <label>Số sale</label>
                  </Col>
                  <Col md={8}>
                    <FormControl
                      type="number"
                      placeholder="Nhập số sale"
                      value={modalAddPackageField.max_telesale}
                      onChange={(e) => this.addPackageFieldChange('max_telesale', e.target.value)}
                      min="0"
                    ></FormControl>
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup className="text-left">
                <Row>
                  <Col md={4}>
                    <label>Số công việc</label>
                  </Col>
                  <Col md={8}>
                    <FormControl
                      type="number"
                      placeholder="Nhập số công việc"
                      value={modalAddPackageField.max_job}
                      onChange={(e) => this.addPackageFieldChange('max_job', e.target.value)}
                      min="0"
                    ></FormControl>
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup className="text-left">
                <Row>
                  <Col md={4}>
                    <label>Số hotline</label>
                  </Col>
                  <Col md={8}>
                    <FormControl
                      type="number"
                      placeholder="Nhập số hotline"
                      value={modalAddPackageField.max_hotline}
                      onChange={(e) => this.addPackageFieldChange('max_hotline', e.target.value)}
                      min="0"
                    ></FormControl>
                  </Col>
                </Row>
              </FormGroup>
            </>
          ) : (
            ''
          )}
          <FormGroup className="text-left">
            <Row>
              <Col md={4}>
                <label>Giá</label>
              </Col>
              <Col md={8}>
                <FormControl
                  placeholder="Nhập giá"
                  value={modalAddPackageField.price}
                  onChange={(e) => this.addPackageFieldChange('price', e.target.value)}
                  min="0"
                ></FormControl>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="text-left">
            <Row>
              <Col md={4}>
                <label>Cấp độ</label>
              </Col>
              <Col md={8}>
                <FormControl
                  type="number"
                  placeholder="Nhập cấp độ"
                  value={modalAddPackageField.level}
                  onChange={(e) => this.addPackageFieldChange('level', e.target.value)}
                  min="0"
                ></FormControl>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="text-left">
            <Row>
              <Col md={4}>
                <label>Số phút gọi</label>
              </Col>
              <Col md={8}>
                <FormControl
                  type="number"
                  placeholder="Nhập số phút gọi"
                  value={parseInt(modalAddPackageField.call_duration / 60)}
                  onChange={(e) => this.addPackageFieldChange('call_duration', e.target.value)}
                  min="0"
                ></FormControl>
              </Col>
            </Row>
          </FormGroup>
          {modalAddPackageField.id != 0 || modalAddPackageField.id != '' ? (
            <FormGroup className="text-left">
              <Row>
                <Col md={4}>
                  <label>Trạng thái</label>
                </Col>
                <Col md={8}>
                  <FormControl
                    as="select"
                    value={modalAddPackageField.is_active}
                    onChange={(e) => this.addPackageFieldChange('is_active', e.target.value)}
                  >
                    <option value="">Trạng thái</option>
                    <option value="1">Đang hoạt động</option>
                    <option value="2">Tạm ngừng hoạt động</option>
                  </FormControl>
                </Col>
              </Row>
            </FormGroup>
          ) : (
            ''
          )}{' '}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="success"
            className="text-light"
            onClick={() => {
              this.createPackage();
            }}
          >
            {modalAddPackageField.id != 0 || modalAddPackageField.id != '' ? 'Cập nhật' : ' Thêm mới'}
          </Button>
          <Button variant="danger" className="text-light" onClick={() => this.setShowModal('modalAddPackage', false)}>
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  addPackageFieldChange = (field, value) => {
    if (field == 'price') {
      this.setState({
        ...this.state,
        modalAddPackageField: {
          ...this.state.modalAddPackageField,
          [field]: formatNumber(value),
        },
      });
    } else if (field == 'call_duration') {
      this.setState({
        ...this.state,
        modalAddPackageField: {
          ...this.state.modalAddPackageField,
          [field]: value * 60,
        },
      });
    } else {
      this.setState({
        ...this.state,
        modalAddPackageField: {
          ...this.state.modalAddPackageField,
          [field]: value,
        },
      });
    }
  };

  renderDeletePackage() {
    const { confirmModal } = this.state;
    return (
      <Modal
        show={confirmModal}
        onHide={() =>
          this.setState({
            confirmModal: false,
          })
        }
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton className="bg-red pb-0">
          <h5 className="text-light">Bạn chắc chắn muốn xóa ?</h5>
        </Modal.Header>
        <Modal.Body className="custom-body">
          <Row>
            <Col className="button-wrapper" style={{ textAlign: 'center' }}>
              <Button
                variant="success"
                className="text-light"
                onClick={() => {
                  this.deletePackage(this.state.id);
                }}
              >
                Có
              </Button>
              <Button
                variant="danger"
                className="text-light"
                onClick={() => {
                  this.setState({
                    confirmModal: false,
                  });
                }}
              >
                Thoát
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  render() {
    const { isLoading } = this.props.packageState;
    let loading = <Loading />;
    if (!isLoading) {
      loading = null;
    }
    return (
      <div >
        {loading}
        {this.addModalPackage()}
        {this.renderDeletePackage()}
        {this.renderBody()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  packageState: state.listPackageReducer,
});

const mapDispatchToProps = {
  getListPackage,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Package));
