import { BASE_URL_IMAGE } from '@constants/Constant';
import { randomColor } from '@constants/funcHelper';
import { DescriptionCustomInteractionHistory } from '@styles/Style';
import { Avatar, Descriptions } from 'antd';
import React from 'react';
import InteractionHistoryHeader from './InteractionHistoryHeader';

export default function DeliverToSale({ interactionHistory }) {
  try {
    const name = interactionHistory.sale_name.split(' ');
    return (
      <>
        <InteractionHistoryHeader interactionHistory={interactionHistory} />

        <DescriptionCustomInteractionHistory column={1}>
          <Descriptions.Item label="Công việc">
            {interactionHistory.job_name_parent ? interactionHistory.job_name_parent : 'Chưa cập nhật'}
          </Descriptions.Item>

          <Descriptions.Item label="Sale chăm sóc">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '10px' }}>
                {interactionHistory.avatar_sale ? (
                  <Avatar src={interactionHistory.avatar_sale && `${interactionHistory.avatar_sale}`}>
                    {interactionHistory.avatar_sale ? '' : name[name.length - 1].charAt(0)?.toUpperCase()}
                  </Avatar>
                ) : (
                  <Avatar
                    style={{
                      backgroundColor: randomColor(name[name.length - 1].charAt(0)?.toUpperCase()),
                    }}
                  >
                    {interactionHistory.avatar_sale ? '' : name[name.length - 1].charAt(0)?.toUpperCase()}
                  </Avatar>
                )}
              </div>

              <div>{interactionHistory.sale_name ? interactionHistory.sale_name : 'Chưa cập nhật'}</div>
            </div>
          </Descriptions.Item>

          <Descriptions.Item label="Nội dung giao việc">
            {interactionHistory.job_name ? interactionHistory.job_name : 'Chưa cập nhật'}
          </Descriptions.Item>
        </DescriptionCustomInteractionHistory>
      </>
    );
  } catch (error) {
    return (
      <>
        <InteractionHistoryHeader interactionHistory={interactionHistory} />

        <DescriptionCustomInteractionHistory column={1}>
          <Descriptions.Item label="Công việc">
            {interactionHistory.job_name_parent ? interactionHistory.job_name_parent : 'Chưa cập nhật'}
          </Descriptions.Item>

          <Descriptions.Item label="Nội dung giao việc">
            {interactionHistory.job_name ? interactionHistory.job_name : 'Chưa cập nhật'}
          </Descriptions.Item>
        </DescriptionCustomInteractionHistory>
      </>
    );
  }
}
