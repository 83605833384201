import { requestGetHotlineV2 } from '@constants/Api';
import { ROUTER, USER_ROLE } from '@constants/Constant';
import { setSearchParamsInUrl } from '@constants/funcHelper';
import Loading from '@src/components/Loading';
import {
  getListOccasion,
  getRegisterList,
  getUserInfo,
} from '@src/redux/actions';
import '@styles/NavBar.css';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import ModalCall from './ModalCall';
import { CALLING_CODE } from './callProviders/CallConstant';
import { isCallEnded } from './callProviders/CallHelper';
import CallUtil from './callProviders/CallUtil';
import useCallStatus from './callProviders/useCallStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const NavBar = props => {
  const history = useHistory();
  const location = useLocation();
  const [resolution, setResolution] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [showPhoneOverlay, setShowPhoneOverlay] = useState(false);
  const callStatus = useCallStatus();
  const { call, callId } = callStatus;

  const updateDimensions = () => {
    setResolution({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  const handleNavToUpdateCustomer = call => {
    // console.log('calling_socket_call_from_app', res);
    // const { call_id, customer_id, call_status } = res;
    // const urlSearchParams = new URLSearchParams(window.location.search);
    // const callIdInUrl = urlSearchParams.get('call_id');
    // dispatch(getCallingStatus({ ...res }));
    // if (res.call_status === CALLING_STATUS.calling) {
    //   dispatch(getUpdateCall({}));
    //   notifySuccess('Có cuộc gọi mới!');
    // }
    // if (location.pathname === ROUTER.CALLING_SALE) {
    //   if (callIdInUrl && String(call_id) === String(callIdInUrl)) {
    //     setSearchParamsInUrlVer2({
    //       call_status: call_status,
    //       numbPad: isNewCustomer,
    //     });
    //   } else {
    //     setSearchParamsInUrlVer2({
    //       call_status: call_status,
    //       numbPad: isNewCustomer,
    //       call_id,
    //       customer_id,
    //     });
    //   }
    // } else {
    //   history.replace({
    //     pathname: ROUTER.CALLING_SALE,
    //     search: `?call_id=${call_id}&call_status=${call_status}&customer_id=${customer_id}&numbPad=${isNewCustomer}`,
    //   });
    // }
    if (location.pathname !== ROUTER.CALLING_SALE) {
      switch (call.status) {
        case CALLING_CODE.Preparing:
        case CALLING_CODE.Calling:
        case CALLING_CODE.Ringing:
        case CALLING_CODE.Busy:
        case CALLING_CODE.Answered:
        case CALLING_CODE.Ended:
          history.replace({
            pathname: ROUTER.CALLING_SALE,
          });
          break;

        default:
          break;
      }
    }
  };
  const handleShowPhoneOverlay = call => {
    if (call && !showPhoneOverlay && !isCallEnded(call.status))
      setShowPhoneOverlay(true);
  };

  useEffect(() => {
    handleShowPhoneOverlay(call);
    handleNavToUpdateCustomer(call);
    return () => {};
  }, [call]);

  const getHotlines = async () => {
    try {
      const res = await requestGetHotlineV2();
      CallUtil.hotlines = res.data?.listHotlineOutput;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserInfo();
    getHotlines();
    window.addEventListener('resize', updateDimensions);
    props.getRegisterList({
      page: 1,
      search: '',
      status: '',
      province_id: '',
    });
    props.getListOccasion({
      telesale_id: '',
      job_id: '',
      status: '',
      opportunity_category_id: '',
      fromDate: '',
      page: 1,
    });

    return () => {};
  }, []);

  const PhoneSvg = () => (
    <svg width="2em" height="2em" fill="currentColor" viewBox="0 0 1024 1024">
      <path d="M885.6 230.2L779.1 123.8a80.83 80.83 0 00-57.3-23.8c-21.7 0-42.1 8.5-57.4 23.8L549.8 238.4a80.83 80.83 0 00-23.8 57.3c0 21.7 8.5 42.1 23.8 57.4l83.8 83.8A393.82 393.82 0 01553.1 553 395.34 395.34 0 01437 633.8L353.2 550a80.83 80.83 0 00-57.3-23.8c-21.7 0-42.1 8.5-57.4 23.8L123.8 664.5a80.89 80.89 0 00-23.8 57.4c0 21.7 8.5 42.1 23.8 57.4l106.3 106.3c24.4 24.5 58.1 38.4 92.7 38.4 7.3 0 14.3-.6 21.2-1.8 134.8-22.2 268.5-93.9 376.4-201.7C828.2 612.8 899.8 479.2 922.3 344c6.8-41.3-6.9-83.8-36.7-113.8z" />
    </svg>
  );
  const { push } = props.history;
  const { isLoading } = props.userInfoState;
  let loading = <Loading color="white" />;
  if (!isLoading) {
    loading = null;
  }

  const renderProAndHRSideBar = push => {
    var pathName = '/' + window.location.pathname.split('/')[1];
    if (pathName === '/') pathName = ROUTER.OVERVIEW;
    return (
      <>
        <li
          className={'nav-item '}
          data-widget={resolution.width < 990 && 'pushmenu'}
          onClick={() => push(ROUTER.OVERVIEW)}
        >
          <a
            className={
              'nav-link cursor ' +
              ([ROUTER.OVERVIEW].includes(pathName) ? 'active' : '')
            }
          >
            <i className="nav-icon fas fa-tachometer-alt" />
            <p className="me-txt-menu">Tổng quan</p>
          </a>
        </li>

        <li className={'nav-item has-treeview'}>
          <a
            className={
              'nav-link cursor ' +
              ([
                ROUTER.TELESALE_ADD,
                ROUTER.TELESALE,
                ROUTER.TELESALE_DETAIL,
                ROUTER.REGISTER_REQUEST,
                ROUTER.REGISTER_REQUEST_DETAIL,
                ROUTER.REGISTER_REQUEST_REJECTED,
                ROUTER.REGISTER_REQUEST_REJECTED_DETAIL,
                ROUTER.TELESALE_INACTIVE,
                ROUTER.TELESALE_INACTIVE_DETAIL,
                ROUTER.TELESALE_EDIT,
              ].includes(pathName)
                ? 'active'
                : '')
            }
          >
            <i className="nav-icon fas fa-user-headset"></i>
            <p className="me-txt-menu">
              Sale
              <i className="fas fa-angle-left right expand-icon" />
            </p>
          </a>
          <ul className="nav nav-treeview">
            <li
              className="nav-item sub-menu hoved "
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.TELESALE_ADD)}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([ROUTER.TELESALE_ADD].includes(pathName) ? 'active' : '')
                }
              >
                <i className="nav-icon fas fa-user-plus me-sub-menu-item"></i>
                <p className="me-txt-menu">Thêm mới sale</p>
              </a>
            </li>
            <li
              className="nav-item sub-menu hoved "
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.TELESALE)}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([ROUTER.TELESALE, ROUTER.TELESALE_DETAIL].includes(pathName)
                    ? 'active'
                    : '')
                }
              >
                <i className="nav-icon fas fa-list-ul me-sub-menu-item"></i>
                <p className="me-txt-menu">Danh sách sale</p>
              </a>
            </li>
            <li
              className="nav-item sub-menu hoved "
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.REGISTER_REQUEST)}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([
                    ROUTER.REGISTER_REQUEST,
                    ROUTER.REGISTER_REQUEST_DETAIL,
                  ].includes(pathName)
                    ? 'active'
                    : '')
                }
              >
                <i className="nav-icon fas fa-user-clock me-sub-menu-item"></i>
                <p className="me-txt-menu">Yêu cầu đăng ký</p>
                <span style={{ float: 'right' }}>
                  {/* {props.registerState?.data?.paging?.totalItemCount < 10 ? (
                    <Badge variant="danger">{props.registerState?.data?.paging?.totalItemCount}</Badge>
                  ) : (
                    <Badge variant="danger">9+</Badge>
                  )} */}
                </span>
              </a>
            </li>
            <li
              className="nav-item sub-menu hoved "
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.REGISTER_REQUEST_REJECTED)}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([
                    ROUTER.REGISTER_REQUEST_REJECTED,
                    ROUTER.REGISTER_REQUEST_REJECTED_DETAIL,
                  ].includes(pathName)
                    ? 'active'
                    : '')
                }
              >
                <i className="nav-icon fas fa-user-times me-sub-menu-item"></i>
                <p className="me-txt-menu">Yêu cầu bị từ chối</p>
              </a>
            </li>
            <li
              className="nav-item sub-menu hoved "
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.TELESALE_INACTIVE)}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([
                    ROUTER.TELESALE_INACTIVE,
                    ROUTER.TELESALE_INACTIVE_DETAIL,
                  ].includes(pathName)
                    ? 'active'
                    : '')
                }
              >
                <i className="nav-icon fas fa-user-slash me-sub-menu-item"></i>
                <p className="me-txt-menu">Ngừng hoạt động</p>
              </a>
            </li>
          </ul>
        </li>
      </>
    );
  };

  const renderEnterpriseSidebar = push => {
    var pathName = '/' + window.location.pathname.split('/')[1];
    if (pathName === '/') pathName = ROUTER.OVERVIEW;
    return (
      <>
        <li
          className={'nav-item '}
          data-widget={resolution.width < 990 && 'pushmenu'}
          onClick={() => push(ROUTER.OVERVIEW)}
        >
          <a
            className={
              'nav-link cursor ' +
              ([ROUTER.OVERVIEW].includes(pathName) ? 'active' : '')
            }
          >
            <i className="nav-icon fas fa-tachometer-alt" />
            <p className="me-txt-menu">Tổng quan</p>
          </a>
        </li>

        <li className={'nav-item has-treeview'}>
          <a
            className={
              'nav-link cursor ' +
              ([
                ROUTER.CUSTOMER,
                ROUTER.CUSTOMER_DETAIL,
                ROUTER.ADD_CUSTOMER,
                ROUTER.EDIT_CUSTOMER,
                ROUTER.CUSTOMER_GROUP,
                ROUTER.CUSTOMER_GROUP_ADD,
                ROUTER.CUSTOMER_GROUP_EDIT,
                ROUTER.CUSTOMER_GROUP_DETAIL,
              ].includes(pathName)
                ? 'active'
                : '')
            }
          >
            <i className="nav-icon fas fa-user"></i>
            <p className="me-txt-menu">
              Khách hàng
              <i className="fas fa-angle-left right expand-icon" />
            </p>
          </a>
          <ul className="nav nav-treeview">
            <li
              className="nav-item sub-menu hoved "
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.CUSTOMER)}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([
                    ROUTER.CUSTOMER,
                    ROUTER.CUSTOMER_DETAIL,
                    ROUTER.ADD_CUSTOMER,
                    ROUTER.EDIT_CUSTOMER,
                  ].includes(pathName)
                    ? 'active'
                    : '')
                }
              >
                <i className="nav-icon fas fa-list-ul me-sub-menu-item"></i>
                <p className="me-txt-menu">Danh sách khách hàng</p>
              </a>
            </li>
            <li
              className="nav-item sub-menu hoved "
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.CUSTOMER_GROUP)}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([
                    ROUTER.CUSTOMER_GROUP,
                    ROUTER.CUSTOMER_GROUP_ADD,
                    ROUTER.CUSTOMER_GROUP_EDIT,
                    ROUTER.CUSTOMER_GROUP_DETAIL,
                  ].includes(pathName)
                    ? 'active'
                    : '')
                }
              >
                <i className="nav-icon fas fa-users me-sub-menu-item"></i>
                <p className="me-txt-menu">Nhóm khách hàng</p>
              </a>
            </li>
          </ul>
        </li>

        <li
          className={'nav-item '}
          data-widget={resolution.width < 990 && 'pushmenu'}
          onClick={() => push(ROUTER.JOB)}
        >
          <a
            className={
              'nav-link cursor ' +
              ([
                ROUTER.JOB,
                ROUTER.JOB_ADD,
                ROUTER.JOB_EDIT,
                ROUTER.JOB_RESULT,
                ROUTER.JOB_GENERAL_ADD,
                ROUTER.JOB_GENERAL_EDIT,
                ROUTER.JOB_RESULT_CHILD,
              ].includes(pathName)
                ? 'active'
                : '')
            }
          >
            <i className="nav-icon fas fa-briefcase"></i>
            <p className="me-txt-menu">Công việc</p>
          </a>
        </li>

        <li
          className={'nav-item '}
          data-widget={resolution.width < 990 && 'pushmenu'}
          onClick={() => push(ROUTER.OPPORTUNITY)}
        >
          <a
            className={
              'nav-link cursor ' +
              ([ROUTER.OPPORTUNITY, ROUTER.OCCASION_DETAIL].includes(pathName)
                ? 'active'
                : '')
            }
          >
            {/* <i className="nav-icon fas fa-bahai"></i> */}
            <i className="nav-icon fas fa-file-signature"></i>
            <p className="me-txt-menu">Cơ hội - hợp đồng</p>
            <span style={{ float: 'right' }}>
              {/* {props.occasionState?.data?.paging?.totalItemCount < 10 ? (
                <Badge variant="danger">{props.occasionState?.data?.paging?.count}</Badge>
              ) : (
                <Badge variant="danger">9+</Badge>
              )} */}
            </span>
          </a>
        </li>

        <li className={'nav-item has-treeview'}>
          <a
            className={
              'nav-link cursor ' +
              ([
                ROUTER.CALL,
                ROUTER.CALL_DETAIL,
                ROUTER.EVALUATION_STAFF,
                ROUTER.ASSIGN,
                ROUTER.EVALUATION_RESULT,
              ].includes(pathName)
                ? 'active'
                : '')
            }
          >
            <i className="nav-icon fas fa-phone-square-alt"></i>
            <p className="me-txt-menu">
              Cuộc gọi
              <i className="fas fa-angle-left right expand-icon" />
            </p>
          </a>
          <ul className="nav nav-treeview">
            <li
              className="nav-item sub-menu hoved "
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.CALL)}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([ROUTER.CALL, ROUTER.CALL_DETAIL].includes(pathName)
                    ? 'active'
                    : '')
                }
              >
                <i className="nav-icon fas fa-address-book me-sub-menu-item"></i>
                <p className="me-txt-menu">Lịch sử cuộc gọi</p>
              </a>
            </li>
            <li className={'nav-item has-treeview'}>
              <a
                className={
                  'nav-link cursor ' +
                  ([
                    ROUTER.EVALUATION_STAFF,
                    ROUTER.ASSIGN,
                    ROUTER.EVALUATION_RESULT,
                  ].includes(pathName)
                    ? 'active'
                    : '')
                }
              >
                <i className="nav-icon fas fa-star-half-alt me-sub-menu-item"></i>
                <p className="me-txt-menu">
                  Đánh giá
                  <i className="fas fa-angle-left right expand-icon" />
                </p>
              </a>
              <ul className="nav nav-treeview me-sub-menu-item">
                <li
                  className="nav-item sub-menu hoved "
                  data-widget={resolution.width < 990 && 'pushmenu'}
                  onClick={() => push(ROUTER.EVALUATION_STAFF)}
                >
                  <a
                    className={
                      'nav-link cursor ' +
                      ([ROUTER.EVALUATION_STAFF].includes(pathName)
                        ? 'active'
                        : '')
                    }
                  >
                    <i className="nav-icon far fa-star-half-alt me-sub-menu-item"></i>
                    <p className="me-txt-menu">Nhân viên đánh giá</p>
                  </a>
                </li>
                <li
                  className="nav-item sub-menu hoved "
                  data-widget={resolution.width < 990 && 'pushmenu'}
                  onClick={() => push(ROUTER.ASSIGN)}
                >
                  <a
                    className={
                      'nav-link cursor ' +
                      ([ROUTER.ASSIGN].includes(pathName) ? 'active' : '')
                    }
                  >
                    <i className="nav-icon fas fa-list-ul me-sub-menu-item"></i>
                    <p className="me-txt-menu">Giao việc</p>
                  </a>
                </li>
                <li
                  className="nav-item sub-menu hoved "
                  data-widget={resolution.width < 990 && 'pushmenu'}
                  onClick={() => push(ROUTER.EVALUATION_RESULT)}
                >
                  <a
                    className={
                      'nav-link cursor ' +
                      ([ROUTER.EVALUATION_RESULT].includes(pathName)
                        ? 'active'
                        : '')
                    }
                  >
                    <i className="nav-icon fas fa-poll-h me-sub-menu-item"></i>
                    <p className="me-txt-menu">Kết quả đánh giá</p>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li className={'nav-item has-treeview'}>
          <a
            className={
              'nav-link cursor ' +
              ([ROUTER.KPI, ROUTER.KPI_PROGRESS].includes(pathName)
                ? 'active'
                : '')
            }
          >
            <i className="nav-icon fas fa-chart-bar"></i>
            <p className="me-txt-menu">
              KPI
              <i className="fas fa-angle-left right expand-icon" />
            </p>
          </a>
          <ul className="nav nav-treeview">
            <li
              className="nav-item sub-menu hoved "
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.KPI)}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([ROUTER.KPI].includes(pathName) ? 'active' : '')
                }
              >
                <i className="nav-icon far fa-sliders-h me-sub-menu-item"></i>
                <p className="me-txt-menu">Thiết lập KPI</p>
              </a>
            </li>
            <li
              className="nav-item sub-menu hoved "
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.KPI_PROGRESS)}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([ROUTER.KPI_PROGRESS].includes(pathName) ? 'active' : '')
                }
              >
                <i className="nav-icon fas fa-tasks me-sub-menu-item"></i>
                <p className="me-txt-menu">Tiến độ KPI</p>
              </a>
            </li>
          </ul>
        </li>

        <li
          className={'nav-item '}
          data-widget={resolution.width < 990 && 'pushmenu'}
        >
          <a
            className={
              'nav-link cursor ' +
              ([
                ROUTER.STATISTIC_JOB,
                ROUTER.STATISTIC_KPI,
                ROUTER.REPORT,
                ROUTER.REPORT_JOB,
                ROUTER.REPORT_DETAIL,
                ROUTER.REPORT_TELESALE,
                ROUTER.REPORT_OPPORTUNITY,
              ].includes(pathName)
                ? 'active'
                : '')
            }
          >
            <i className="nav-icon fas fa-book-reader"></i>
            <p className="me-txt-menu">
              Báo cáo
              <i className="fas fa-angle-left right expand-icon" />
            </p>
          </a>
          <ul className="nav nav-treeview">
            <li
              className="nav-item sub-menu hoved"
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.REPORT_TELESALE)}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([ROUTER.REPORT_TELESALE].includes(pathName) ? 'active' : '')
                }
              >
                <i className="nav-icon fas fa-file-chart-pie me-sub-menu-item"></i>
                <p className="me-txt-menu">Báo cáo Sale</p>
              </a>
            </li>
            <li
              className="nav-item sub-menu hoved "
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.BUSINESS_REPORT)}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([ROUTER.BUSINESS_REPORT].includes(pathName) ? 'active' : '')
                }
              >
                <i className="nav-icon fas fa-file-chart-line me-sub-menu-item"></i>
                <p className="me-txt-menu">Báo cáo doanh số</p>
              </a>
            </li>

            <li
              className="nav-item sub-menu hoved "
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.REPORT_OPPORTUNITY)}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([ROUTER.REPORT_OPPORTUNITY].includes(pathName)
                    ? 'active'
                    : '')
                }
              >
                <i className="nav-icon fas fa-file-invoice me-sub-menu-item"></i>
                <p className="me-txt-menu">Báo cáo cơ hội</p>
              </a>
            </li>

            <li
              className="nav-item sub-menu hoved "
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.REPORT_CALL)}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([ROUTER.REPORT_CALL].includes(pathName) ? 'active' : '')
                }
              >
                <i className="nav-icon fas fa-phone me-sub-menu-item"></i>
                <p className="me-txt-menu">Báo cáo cuộc gọi</p>
              </a>
            </li>

            <li
              className="nav-item sub-menu hoved "
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.LOCATION_REPORT)}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([ROUTER.LOCATION_REPORT].includes(pathName) ? 'active' : '')
                }
              >
                <i className="nav-icon fas fa-map-marked-alt me-sub-menu-item"></i>
                <p className="me-txt-menu">Báo cáo vị trí</p>
              </a>
            </li>

            <li
              className="nav-item sub-menu hoved "
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.MESSAGE_REPORT)}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([ROUTER.MESSAGE_REPORT].includes(pathName) ? 'active' : '')
                }
              >
                <i className="nav-icon fas fa-comments me-sub-menu-item"></i>
                <p className="me-txt-menu">Báo cáo tin nhắn</p>
              </a>
            </li>
          </ul>
        </li>

        <li className={'nav-item has-treeview'}>
          <a
            className={
              'nav-link cursor ' +
              ([
                ROUTER.TELESALE_ADD,
                ROUTER.TELESALE,
                ROUTER.TELESALE_DETAIL,
                ROUTER.REGISTER_REQUEST,
                ROUTER.REGISTER_REQUEST_DETAIL,
                ROUTER.REGISTER_REQUEST_REJECTED,
                ROUTER.REGISTER_REQUEST_REJECTED_DETAIL,
                ROUTER.TELESALE_INACTIVE,
                ROUTER.TELESALE_INACTIVE_DETAIL,
                ROUTER.TELESALE_EDIT,
              ].includes(pathName)
                ? 'active'
                : '')
            }
          >
            <i className="nav-icon fas fa-user-headset"></i>
            <p className="me-txt-menu">
              Nhân viên
              <i className="fas fa-angle-left right expand-icon" />
            </p>
          </a>
          <ul className="nav nav-treeview">
            <li
              className="nav-item sub-menu hoved "
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.TELESALE_ADD)}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([ROUTER.TELESALE_ADD].includes(pathName) ? 'active' : '')
                }
              >
                <i className="nav-icon fas fa-user-plus me-sub-menu-item"></i>
                <p className="me-txt-menu">Thêm mới sale</p>
              </a>
            </li>
            <li
              className="nav-item sub-menu hoved "
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.TELESALE)}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([ROUTER.TELESALE, ROUTER.TELESALE_DETAIL].includes(pathName)
                    ? 'active'
                    : '')
                }
              >
                <i className="nav-icon fas fa-list-ul me-sub-menu-item"></i>
                <p className="me-txt-menu">Danh sách sale</p>
              </a>
            </li>
            <li
              className="nav-item sub-menu hoved "
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.REGISTER_REQUEST)}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([
                    ROUTER.REGISTER_REQUEST,
                    ROUTER.REGISTER_REQUEST_DETAIL,
                  ].includes(pathName)
                    ? 'active'
                    : '')
                }
              >
                <i className="nav-icon fas fa-user-clock me-sub-menu-item"></i>
                <p className="me-txt-menu">Yêu cầu đăng ký</p>
                <span style={{ float: 'right' }}></span>
              </a>
            </li>
            <li
              className="nav-item sub-menu hoved "
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.REGISTER_REQUEST_REJECTED)}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([
                    ROUTER.REGISTER_REQUEST_REJECTED,
                    ROUTER.REGISTER_REQUEST_REJECTED_DETAIL,
                  ].includes(pathName)
                    ? 'active'
                    : '')
                }
              >
                <i className="nav-icon fas fa-user-times me-sub-menu-item"></i>
                <p className="me-txt-menu">Yêu cầu bị từ chối</p>
              </a>
            </li>
            <li
              className="nav-item sub-menu hoved "
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.TELESALE_INACTIVE)}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([
                    ROUTER.TELESALE_INACTIVE,
                    ROUTER.TELESALE_INACTIVE_DETAIL,
                  ].includes(pathName)
                    ? 'active'
                    : '')
                }
              >
                <i className="nav-icon fas fa-user-slash me-sub-menu-item"></i>
                <p className="me-txt-menu">Ngừng hoạt động</p>
              </a>
            </li>
          </ul>
        </li>
        <li
          className={'nav-item'}
          data-widget={resolution.width < 990 && 'pushmenu'}
          onClick={() => push(ROUTER.E_VOUCHER)}
        >
          <a
            className={
              'nav-link cursor ' +
              ([ROUTER.E_VOUCHER].includes(pathName) ? 'active' : '')
            }
          >
            <i className="nav-icon far fa-ticket-alt"></i>
            <p className="me-txt-menu">E-voucher</p>
          </a>
        </li>
        <li className={'nav-item has-treeview'}>
          <a
            className={
              'nav-link cursor ' +
              ([
                ROUTER.SETTINGS,
                ROUTER.GENERAL_CONFIG,
                ROUTER.JOB_CONFIG,
              ].includes(pathName)
                ? 'active'
                : '')
            }
          >
            <i className="nav-icon fas fa-bahai"></i>
            <p className="me-txt-menu">
              Cài đặt nâng cao
              <i className="fas fa-angle-left right expand-icon" />
            </p>
          </a>
          {/* </li> */}
          <ul className="nav nav-treeview me-sub-menu-item">
            <li
              className={'nav-item'}
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.DEPARTMENT)}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([
                    ROUTER.DEPARTMENT,
                    ROUTER.ADD_DEPARTMENT,
                    ROUTER.UPDATE_DEPARTMENT,
                    ROUTER.DEPARTMENT_DETAIL,
                  ].includes(pathName)
                    ? 'active'
                    : '')
                }
              >
                <i className="nav-icon fas fa-building"></i>
                <p className="me-txt-menu">Phòng ban</p>
              </a>
            </li>
            <li
              className={'nav-item'}
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => {
                const SYSTEM_NUMBER = 1;
                push(ROUTER.GENERAL_CONFIG);
                setSearchParamsInUrl('tabId', SYSTEM_NUMBER);
              }}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([ROUTER.GENERAL_CONFIG].includes(pathName) ? 'active' : '')
                }
              >
                <i className="nav-icon far fas fa-cog"></i>
                <p className="me-txt-menu">Cấu hình chung</p>
              </a>
            </li>
            <li
              className={'nav-item'}
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.JOB_CONFIG)}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([ROUTER.JOB_CONFIG].includes(pathName) ? 'active' : '')
                }
              >
                <i className="nav-icon fas fa-server"></i>
                <p className="me-txt-menu">Cấu hình công việc</p>
              </a>
            </li>
            <li
              className={'nav-item '}
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.ACCOUNT)}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([ROUTER.ACCOUNT].includes(pathName) ? 'active' : '')
                }
              >
                <i className="nav-icon fas fa-user-circle" />
                <p className="me-txt-menu">Tài khoản</p>
              </a>
            </li>
          </ul>
        </li>
      </>
    );
  };

  const renderReviewerSidebar = push => {
    var pathName = '/' + window.location.pathname.split('/')[1];
    if (pathName === '/') pathName = ROUTER.HOMEPAGE;
    return (
      <>
        <li
          className={'nav-item '}
          data-widget={resolution.width < 990 && 'pushmenu'}
          onClick={() => push(ROUTER.HOMEPAGE)}
        >
          <a
            className={
              'nav-link cursor ' +
              ([ROUTER.HOMEPAGE].includes(pathName) ? 'active' : '')
            }
          >
            <i className="nav-icon fas fa-home"></i>
            <p className="me-txt-menu">Trang chủ</p>
          </a>
        </li>

        <li
          className={'nav-item '}
          data-widget={resolution.width < 990 && 'pushmenu'}
          onClick={() => push(ROUTER.JOB_REVIEWER)}
        >
          <a
            className={
              'nav-link cursor ' +
              ([
                ROUTER.JOB_REVIEWER,
                ROUTER.JOB_REVIEWER_DETAIL,
                ROUTER.CALL_DETAIL_REVIEWER,
              ].includes(pathName)
                ? 'active'
                : '')
            }
          >
            <i className="nav-icon far fa-calendar-check"></i>
            <p className="me-txt-menu">Công việc</p>
          </a>
        </li>

        <li
          className={'nav-item '}
          data-widget={resolution.width < 990 && 'pushmenu'}
          onClick={() => push(ROUTER.EVALUATE_HISTORY)}
        >
          <a
            className={
              'nav-link cursor ' +
              ([ROUTER.EVALUATE_HISTORY].includes(pathName) ? 'active' : '')
            }
          >
            <i className="nav-icon fas fa-history"></i>
            <p className="me-txt-menu">Lịch sử đánh giá</p>
          </a>
        </li>

        <li
          className={'nav-item '}
          data-widget={resolution.width < 990 && 'pushmenu'}
          onClick={() => push(ROUTER.INFORMATION)}
        >
          <a
            className={
              'nav-link cursor ' +
              ([ROUTER.INFORMATION].includes(pathName) ? 'active' : '')
            }
          >
            <i className="nav-icon fas fa-address-card"></i>
            <p className="me-txt-menu">Thông tin cá nhân</p>
          </a>
        </li>
      </>
    );
  };

  const renderAdminSideBar = push => {
    var pathName = '/' + window.location.pathname.split('/')[1];

    if (pathName === '/') pathName = ROUTER.OVERVIEW;
    return (
      <>
        <li
          className={'nav-item '}
          data-widget={resolution.width < 990 && 'pushmenu'}
          onClick={() => push(ROUTER.OVERVIEW)}
        >
          <a
            className={
              'nav-link cursor ' +
              ([ROUTER.OVERVIEW].includes(pathName) ? 'active' : '')
            }
          >
            <i className="nav-icon fas fa-tachometer-alt" />
            <p className="me-txt-menu">Tổng quan</p>
          </a>
        </li>

        {props.userInfoState.data.role_id == 1 ||
        props.userInfoState.data.role_id == 2 ? (
          <li
            className={'nav-item '}
            data-widget={resolution.width < 990 && 'pushmenu'}
            onClick={() => push(ROUTER.COMPANY)}
          >
            <a
              className={
                'nav-link cursor ' +
                ([
                  ROUTER.COMPANY,
                  ROUTER.COMPANY_ADD,
                  ROUTER.COMPANY_EDIT,
                  ROUTER.COMPANY_DETAIL,
                ].includes(pathName)
                  ? 'active'
                  : '')
              }
            >
              <i className="nav-icon fas fa-building"></i>
              <p className="me-txt-menu">Công ty</p>
            </a>
          </li>
        ) : (
          ''
        )}

        {props.userInfoState.data.role_id == 1 ||
        props.userInfoState.data.role_id == 2 ? (
          <li className={'nav-item '}>
            <a
              className={
                'nav-link cursor ' +
                (pathName.search(ROUTER.ACCOUNT) !== -1 ? 'active' : '')
              }
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.ACCOUNT)}
            >
              <i className="nav-icon fas fa-user-circle" />
              <p className="me-txt-menu">Tài khoản</p>
            </a>
          </li>
        ) : (
          ''
        )}

        <li
          className={'nav-item '}
          data-widget={resolution.width < 990 && 'pushmenu'}
        >
          <a
            className={
              'nav-link cursor ' +
              ([
                ROUTER.TUTORIAL,
                ROUTER.TUTORIAL_DETAIL,
                ROUTER.ADD_TUTORIAL,
              ].includes(pathName)
                ? 'active'
                : '')
            }
            onClick={() => push(ROUTER.TUTORIAL)}
          >
            <i className="nav-icon fas fa-book"></i>
            <p className="me-txt-menu">Hướng dẫn</p>
          </a>
        </li>
        <li
          className={'nav-item '}
          data-widget={resolution.width < 990 && 'pushmenu'}
          onClick={() => push(ROUTER.CALL_LOG)}
        >
          <a
            className={
              'nav-link cursor ' +
              ([ROUTER.CALL_LOG].includes(pathName) ? 'active' : '')
            }
          >
            {/* <i className="nav-icon fas fa-cogs"></i> */}
            <i className="nav-icon fas fa-history"></i>
            <p className="me-txt-menu">Tra cứu log</p>
          </a>
        </li>
        <li
          className={'nav-item '}
          data-widget={resolution.width < 990 && 'pushmenu'}
          onClick={() => push(ROUTER.SETTINGS_ADMIN)}
        >
          <a
            className={
              'nav-link cursor ' +
              ([ROUTER.SETTINGS_ADMIN].includes(pathName) ? 'active' : '')
            }
          >
            <i className="nav-icon fas fa-cogs"></i>
            <p className="me-txt-menu">Cấu hình</p>
          </a>
        </li>
      </>
    );
  };

  const renderSaleSidebar = push => {
    var pathName = '/' + window.location.pathname.split('/')[1];
    if (pathName === '/') pathName = ROUTER.OVERVIEW_SALE;
    return (
      <>
        <li
          className={'nav-item '}
          data-widget={resolution.width < 990 && 'pushmenu'}
          onClick={() => push(ROUTER.OVERVIEW_SALE)}
        >
          <a
            className={
              'nav-link cursor ' +
              ([ROUTER.OVERVIEW_SALE].includes(pathName) ? 'active' : '')
            }
          >
            <i className="nav-icon fas fa-home"></i>
            <p className="me-txt-menu">Tổng quan</p>
          </a>
        </li>

        <li
          className={'nav-item '}
          data-widget={resolution.width < 990 && 'pushmenu'}
          onClick={() => push(ROUTER.CUSTOMER_SALE)}
        >
          <a
            className={
              'nav-link cursor ' +
              ([ROUTER.CUSTOMER_SALE].includes(pathName) ? 'active' : '')
            }
          >
            <i className="nav-icon fas fa-users"></i>
            <p className="me-txt-menu">Khách hàng</p>
          </a>
        </li>
        <li
          className={'nav-item '}
          data-widget={resolution.width < 990 && 'pushmenu'}
          onClick={() => push(ROUTER.JOB_SALE)}
        >
          <a
            className={
              'nav-link cursor ' +
              ([ROUTER.JOB_SALE].includes(pathName) ? 'active' : '')
            }
          >
            <i className="nav-icon fas fa-briefcase"></i>
            <p className="me-txt-menu">Công việc</p>
          </a>
        </li>

        <li
          className={'nav-item '}
          data-widget={resolution.width < 990 && 'pushmenu'}
          onClick={() => push(ROUTER.OPPORTUNITY_SALE)}
        >
          <a
            className={
              'nav-link cursor ' +
              ([ROUTER.OPPORTUNITY_SALE].includes(pathName) ? 'active' : '')
            }
          >
            <i className="nav-icon fas fa-bahai"></i>
            <p className="me-txt-menu">Cơ hội - hợp đồng</p>
          </a>
        </li>
        <li
          className={'nav-item'}
          data-widget={resolution.width < 990 && 'pushmenu'}
          onClick={() => push(ROUTER.E_VOUCHER)}
        >
          <a
            className={
              'nav-link cursor ' +
              ([ROUTER.E_VOUCHER].includes(pathName) ? 'active' : '')
            }
          >
            <i className="nav-icon far fa-ticket-alt"></i>
            <p className="me-txt-menu">E-voucher</p>
          </a>
        </li>
        <li className={'nav-item has-treeview'}>
          <a
            className={
              'nav-link cursor ' +
              ([ROUTER.CALL_HISTORY_SALE, ROUTER.RECALL_SALE].includes(pathName)
                ? 'active'
                : '')
            }
          >
            <i className="nav-icon fas fa-phone-square-alt"></i>
            <p className="me-txt-menu">
              Cuộc gọi
              <i className="fas fa-angle-left right expand-icon" />
            </p>
          </a>
          <ul className="nav nav-treeview">
            <li
              className="nav-item sub-menu hoved "
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.CALL_HISTORY_SALE)}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([ROUTER.CALL_HISTORY_SALE].includes(pathName)
                    ? 'active'
                    : '')
                }
              >
                <i className="nav-icon far fas fa-history me-sub-menu-item"></i>
                <p className="me-txt-menu">Lịch sử cuộc gọi</p>
              </a>
            </li>
            <li
              className="nav-item sub-menu hoved "
              data-widget={resolution.width < 990 && 'pushmenu'}
              onClick={() => push(ROUTER.RECALL_SALE)}
            >
              <a
                className={
                  'nav-link cursor ' +
                  ([ROUTER.RECALL_SALE].includes(pathName) ? 'active' : '')
                }
              >
                <i className="nav-icon fas fa-phone-volume me-sub-menu-item"></i>
                <p className="me-txt-menu">Lịch hẹn gọi lại</p>
              </a>
            </li>
          </ul>
        </li>
      </>
    );
  };

  const renderSideBar = push => {
    const user = props.userInfoState?.data;

    if ([USER_ROLE.ADMIN].includes(user && user.role_id)) {
      return <>{renderAdminSideBar(push)}</>;
    }
    if (
      [USER_ROLE.ENTERPRISE, USER_ROLE.LEADER].includes(user && user.role_id)
    ) {
      return <>{renderEnterpriseSidebar(push)}</>;
    }
    if (
      [USER_ROLE.PROFESSION_MANAGER, USER_ROLE.HR_MANAGER].includes(
        user && user.role_id
      )
    ) {
      return <>{renderProAndHRSideBar(push)}</>;
    }
    if ([USER_ROLE.REVIEWER].includes(user && user.role_id)) {
      return <>{renderReviewerSidebar(push)}</>;
    }
    if ([USER_ROLE.SALE].includes(user && user.role_id)) {
      return <>{renderSaleSidebar(push)}</>;
    }
  };

  return (
    <>
      {/* {loading} */}
      <aside className="main-sidebar sidebar-dark-primary elevation-4 me-sidebar">
        <a
          href={
            props.userInfoState?.data?.role_id === USER_ROLE.SALE
              ? ROUTER.OVERVIEW_SALE
              : ROUTER.OVERVIEW
          }
          class="brand-link logo-switch"
        >
          <img
            src={require('@assets/ez.png')}
            alt="AdminLTE Docs Logo Small"
            className="brand-image-xl logo-xs"
          ></img>
          <img
            src={require('@assets/logo-navbar.png')}
            alt="AdminLTE Docs Logo Large"
            className="brand-image-xs logo-xl"
          ></img>
        </a>
        <a
          className="nav-link cursor brand-image-xs logo-xl hidden-md"
          data-widget="pushmenu"
        >
          <i
            className="fas fa-angle-double-left"
            style={{ color: 'white', margin: '0px 0 5px 200px' }}
          />
        </a>
        <div className="sidebar me-sidebar">
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {renderSideBar(push)}
            </ul>
          </nav>
          {/* Icon gọi điện */}
          {props.userInfoState?.data?.role_id === USER_ROLE.SALE ? (
            <div style={{ padding: 0 }}>
              <div
                class="brand-image-xl logo-xs"
                style={{ margin: '90vh 0 0 0', padding: 0 }}
              >
                <div
                  style={{
                    backgroundColor: '#c4f9ec2e',
                    width: '45px',
                    height: '45px',
                    borderRadius: '30px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // marginTop: 20,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: 'green',
                      color: 'white',
                      width: '35px',
                      height: '35px',
                      textAlign: 'center',
                      borderRadius: '20px',
                      padding: '5px 0 0 0',
                    }}
                  >
                    <PhoneSvg />
                  </div>
                </div>
              </div>
              <div
                class="brand-image-xs logo-xl"
                style={{
                  margin: '90vh 0 0 0',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setShowPhoneOverlay(true);
                }}
              >
                <div
                  style={{
                    backgroundColor: '#c4f9ec2e',
                    width: '45px',
                    height: '45px',
                    borderRadius: '30px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'fixed',
                    // marginTop: 20,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: 'green',
                      color: 'white',
                      width: '35px',
                      height: '35px',
                      textAlign: 'center',
                      borderRadius: '20px',
                      padding: '5px 0 0 0',
                    }}
                  >
                    <PhoneSvg />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </aside>
      {showPhoneOverlay ? (
        <ModalCall
          handleCancel={() => {
            setShowPhoneOverlay(false);
          }}
          callStatus={callStatus}
        />
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  userInfoState: state.userReducer,
});

const mapDispatchToProps = {
  getUserInfo,
  getRegisterList,
  getListOccasion,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavBar));
