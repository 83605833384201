import React, { useEffect, useState } from 'react';
import { Col, DatePicker, Empty, Input, Row, Select } from 'antd';
import moment from 'moment';

import { formatTime, rmVN, setSearchParamsInUrlVer2 } from '@constants/funcHelper';
import { VietnameseString } from '@constants/VietnameseString';
import { requestGetAllJob } from '@constants/Api';
import { TIME_FORMAT_TYPE } from './MessHistory';

const { Option } = Select;
const { RangePicker } = DatePicker;
const TYPE_SELECT = {
  STATUS: 1,
  TYPE: 2,
};
function Filter({ params, setParams, handleChangeSearchParam }) {
  const [jobs, setJobs] = useState([]);

  const getJobs = async () => {
    try {
      const { data } = await requestGetAllJob();
      const jobs = data.map((i) => ({ id: i.id, name: i.name }));

      setJobs(jobs);
    } catch (error) {
      console.log('Error getJobs: ', { error });
    }
  };

  useEffect(() => {
    getJobs();
  }, []);

  const DateFilter = () => {
    return (
      <RangePicker
        style={{ width: '100%' }}
        placeholder={['Từ ngày', 'Đến ngày']}
        value={[params.from_date ? moment(params.from_date) : '', params.to_date ? moment(params.to_date) : '']}
        onChange={(date, dateString) => {
          if (dateString !== null && dateString[0] !== '' && dateString[1] !== '') {
            setSearchParamsInUrlVer2({
              from_date: formatTime(dateString[0], TIME_FORMAT_TYPE),
              to_date: formatTime(dateString[1], TIME_FORMAT_TYPE),
              page: 1,
            });
            setParams({
              ...params,
              from_date: moment(date[0]).format(TIME_FORMAT_TYPE),
              to_date: moment(date[1]).format(TIME_FORMAT_TYPE),
              page: 1,
            });
          } else {
            setSearchParamsInUrlVer2({ from_date: '', to_date: '', page: 1 });
            setParams({
              ...params,
              from_date: '',
              to_date: '',
              page: 1,
            });
          }
        }}
      />
    );
  };
  const SelectFilter = (type, placeholder, listOption, value) => {
    return (
      <Select
        style={{ width: '100%' }}
        mode="multiple"
        optionFilterProp="children"
        filterOption={(input, option) => {
          return rmVN(option.children).indexOf(rmVN(input)) >= 0;
        }}
        placeholder={placeholder}
        allowClear
        maxTagCount={1}
        value={value || []}
        notFoundContent={
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>{VietnameseString.no_data}</span>} />
        }
        onChange={(value) => {
          const field = type === TYPE_SELECT.STATUS ? 'status' : 'sms_type_id';
          handleChangeSearchParam(field, value);
        }}
      >
        {listOption}
      </Select>
    );
  };
  const listOptionMessType = () => {
    return (
      <>
        <Option key={1} value={1}>
          Tin nhắn thương hiệu
        </Option>
        <Option key={2} value={2}>
          Tin nhắn đầu số
        </Option>
        <Option key={3} value={3}>
          Tin nhắn đa phương tiện
        </Option>
        <Option key={4} value={4}>
          ZNS
        </Option>
      </>
    );
  };
  const listOptionMessStatus = () => {
    return (
      <>
        <Option key={1} value={1} style={{ color: 'steelblue' }}>
          Đã gửi
        </Option>
        <Option key={2} value={2} style={{ color: 'orange' }}>
          Chờ gửi
        </Option>
        <Option key={3} value={3} style={{ color: 'tomato' }}>
          Không gửi được
        </Option>
      </>
    );
  };
  const ColSpan = {
    xxl: 4,
    xl: 6,
    lg: 8,
    sm: 12,
    xs: 24,
  };
  return (
    <Row gutter={[16, 16]}>
      <Col {...ColSpan}>
        <Input.Search
          placeholder={VietnameseString.customer_name}
          allowClear
          onSearch={(searchKey) => {
            handleChangeSearchParam('search', searchKey);
          }}
          onChange={(e) => {
            handleChangeSearchParam('search', e.target.value);
          }}
          style={{ width: '100%' }}
          value={params.search}
        />
      </Col>
      <Col {...ColSpan}>
        {SelectFilter(TYPE_SELECT.TYPE, 'Loại tin nhắn', listOptionMessType(), params.sms_type_id)}
      </Col>
      <Col {...ColSpan}>{SelectFilter(TYPE_SELECT.STATUS, 'Trạng thái', listOptionMessStatus(), params.status)}</Col>

      <Col {...ColSpan}>
        <Select
          style={{ width: '100%' }}
          allowClear
          maxTagCount={1}
          mode="multiple"
          optionFilterProp="children"
          placeholder={VietnameseString.job}
          value={params.job_id || []}
          filterOption={(input, option) => {
            return rmVN(option.children).indexOf(rmVN(input)) >= 0;
          }}
          onChange={(value) => {
            handleChangeSearchParam('job_id', value);
          }}
          notFoundContent={
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>{VietnameseString.no_data}</span>} />
          }
        >
          {jobs.map((job) => (
            <Option key={job.id} value={job.id}>
              {job.name}
            </Option>
          ))}
        </Select>
      </Col>

      <Col {...ColSpan}>{DateFilter()}</Col>
    </Row>
  );
}

export default Filter;
