import { Collapse, Empty, List } from 'antd';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import '../style.css';

const { Panel } = Collapse;

const genHeader = (iconClass, title) => (
  <>
    {iconClass && <i class={`${iconClass} fa-lg mr-2`}></i>}
    <label className="m-0">{title}</label>
  </>
);

const Scenario = (props) => {
  return (
    <>
      <Row className="mt-1" style={{ padding: '0px 10px', borderRadius: '8px', marginBottom: 20 }}>
          <Row className="mb-3">
            <Col md={12}>
              <div style={{ paddingLeft: 0 }}>
                <label className="mr-2">Mô tả:</label>
                {props.data.scenario?.description ? (
                  <div dangerouslySetInnerHTML={{ __html: props.data.scenario?.description }}></div>
                ) : (
                  'Chưa cập nhật'
                )}
                {props.data.scenario?.scenario_steps.length ? (
                  props.data.scenario?.scenario_steps.map((item, index) => {
                    return (
                      <Collapse ghost>
                        <Panel header={genHeader('', item.name)} key={index}>
                          <List
                            itemLayout="horizontal"
                            dataSource={[item.content]}
                            renderItem={(text) => <List.Item>{text}</List.Item>}
                          />
                        </Panel>
                      </Collapse>
                    );
                  })
                ) : (
                  <Empty description="Chưa có các bước kịch bản" />
                )}
              </div>
            </Col>
          </Row>
      </Row>
    </>
  );
};

export default Scenario;
