import React, { Component } from 'react';
import { withRouter, Link, useLocation } from 'react-router-dom';
import '@styles/Settings.css';
import { Row, Col, FormControl, Nav } from 'react-bootstrap';
import System from './Components/System';
import Package from './Components/Package';
import FirstNumber from './Components/FirstNumber';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      tableInfo: 1,
    };
  }

  showTableInfo(status) {
    this.setState({
      ...this.state,
      tableInfo: status,
    });
  }

  renderWorkInfo() {
    const { tableInfo } = this.state;
    return (
      <>
        <Row className="content-header table-tabs pl-0 pb-0">
          <Col md={12}>
            <Nav variant="tabs" defaultActiveKey="review">
              <Nav.Item>
                <Nav.Link eventKey="review" onSelect={() => this.showTableInfo(1)} className="tab-item">
                  Thông số hệ thống
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="question" onSelect={() => this.showTableInfo(2)} className="tab-item">
                  Gói dịch vụ
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="type-job" onSelect={() => this.showTableInfo(3)} className="tab-item">
                  Đầu số
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row className="bg-white">
          <Col md={12} className="bg-white pt-4">
            {tableInfo == 1 ? this.renderSystem() : tableInfo == 2 ? this.renderPackage() : this.renderFirstNumber()}
          </Col>
        </Row>
      </>
    );
  }
  renderSystem() {
    return (
      <>
        <System />
      </>
    );
  }

  renderPackage() {
    return (
      <>
        <Package />
      </>
    );
  }

  renderFirstNumber() {
    return (
      <>
        <FirstNumber />
      </>
    );
  }

  renderBody() {
    const { value } = this.state;
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
            <Col md className="bg">
              <Row>
                <h2> Cấu hình </h2>
              </Row>
              <div class="line"></div>
              {this.renderWorkInfo()}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  handleInputChange(field, value) {
    this.setState({
      ...this.state,
      [field]: value,
    });
  }

  render() {
    return <>{this.renderBody()}</>;
  }
}

export default withRouter(Settings);
