import { requestAddNote, requestGetUserInfo } from '@constants/Api';
import { randomColor } from '@constants/funcHelper';
import { notifyFail, notifySuccess } from '@utils/notify';
import { Avatar, Button, Divider, Form, Input, Modal, Row, Space } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { TimelineStyled } from './styled';

OpportunityNote.propTypes = {};

function OpportunityNote({
  dataSource,
  data,
  setOnSave,
  params,
  setLoading,
  loading,
  contentNote,
  setContentNote,
}) {
  const { TextArea } = Input;
  const [visible, setVisible] = useState(false);
  const [userInfor, setUserInfor] = useState();
  const [dataNote, setDataNote] = useState([]);
  useEffect(() => {
    setDataNote(dataSource?.opportunity_notes);
  }, [dataSource]);
  function convertDataToFrom(data) {
    if (!data) {
      return {
        content: null,
        status: null,
        content_en: null,
      };
    } else {
      return {
        ...data,
        content: data.content,
        content_en: data.content_en,
        status: data.status,
      };
    }
  }
  const initialValues = convertDataToFrom(data);
  const onFinish = values => {
    const newData = {
      ...values,
      user_name: userInfor?.data?.full_name,
      created_at: `${new Date()}`,
      content: values.content,
    };
    setDataNote([newData, ...dataNote]);
    form.resetFields();
    setVisible(false);
  };

  // const handleAdd = () => {
  //   const newData = {
  //     user_name: userInfor?.data?.full_name,
  //     created_at: `${new Date()}`,
  //     content: contentNote,
  //   };
  //   setDataNote([newData, ...dataNote]);
  //   form.resetFields();
  //   setVisible(false);
  // };
  const PlusCircleIcon = () => (
    <svg width="20px" height="20px" fill="#ef8737" viewBox="0 0 1024 1024">
      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z" />
    </svg>
  );
  useEffect(() => {
    getUserInfor();
  }, []);
  const getUserInfor = async () => {
    try {
      const res = await requestGetUserInfo();
      setUserInfor(res);
    } catch (err) {
      console.log('err: ', err);
    }
  };
  const [form] = Form.useForm();
  const name = dataSource?.telesale_name?.trim().split(' ');
  return (
    <div>
      <Row style={{ alignItems: 'center' }}>
        <b style={{ fontSize: 18, color: '#ef8737' }}>Lịch sử ghi chú</b>
        <Button
          onClick={() => {
            setVisible(true);
          }}
          type="text"
          icon={<PlusCircleIcon style={{ fontSize: '24px' }} />}
        />
      </Row>
      <TimelineStyled style={{ marginTop: 15 }}>
        {/* <TimelineStyled.Item color="#ef8737">
          <Divider style={{ margin: '0 0 10px 0' }}>
            <div style={{ backgroundColor: '#ef8737', color: 'white', padding: 5, borderRadius: 5 }}>Hôm nay</div>
          </Divider>
        </TimelineStyled.Item> */}
        {dataNote?.map((item, index) => {
          const string = item?.content.split('$');
          if (
            moment(dataNote[index]?.created_at).get('date') ===
            moment(dataNote[index - 1]?.created_at).get('date')
          ) {
            return (
              <>
                <TimelineStyled.Item color="#ef8737">
                  <div
                    style={{
                      backgroundColor: '#f6f6f6',
                      padding: 5,
                      borderRadius: 5,
                    }}
                  >
                    <Row
                      style={{ alignItems: 'center' }}
                      justify="space-between"
                    >
                      <div>
                        <b style={{ color: '#ef8737', fontSize: 16 }}>
                          Ghi chú
                        </b>{' '}
                        ({moment(item?.created_at).format('hh:mm A')})
                      </div>
                      <div>
                        {item?.user_name}{' '}
                        {name ? (
                          <Avatar
                            style={{
                              backgroundColor: randomColor(
                                name[name?.length - 1].charAt(0)?.toUpperCase()
                              ),
                            }}
                            size="small"
                          >
                            {name[name?.length - 1].charAt(0)?.toUpperCase()}
                          </Avatar>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Row>
                    {/* <div>{item?.content}</div> */}
                    {string.map((objTr, index) => (
                      <div
                        style={index == 0 ? {} : { marginLeft: 15 }}
                        key={index}
                      >
                        {objTr?.startsWith(`http`) ? (
                          <a href={objTr} target="_blank" rel="noreferrer">
                            {objTr}
                          </a>
                        ) : (
                          objTr
                        )}
                      </div>
                    ))}
                  </div>
                </TimelineStyled.Item>
              </>
            );
          } else {
            return (
              <TimelineStyled.Item color="#ef8737" key={index}>
                <>
                  <Divider style={{ margin: '0 0 10px 0' }}>
                    <div
                      style={{
                        backgroundColor: '#ef8737',
                        color: 'white',
                        padding: 5,
                        borderRadius: 5,
                      }}
                    >
                      {moment(item?.created_at).format('DD/MM/YYYY')}
                    </div>
                  </Divider>
                  <div
                    style={{
                      backgroundColor: '#f6f6f6',
                      padding: 5,
                      borderRadius: 5,
                    }}
                  >
                    <Row
                      style={{ alignItems: 'center' }}
                      justify="space-between"
                    >
                      <div>
                        <b style={{ color: '#ef8737', fontSize: 16 }}>
                          Ghi chú
                        </b>{' '}
                        ({moment(item?.created_at).format('hh:mm A')})
                      </div>
                      <div>
                        {item?.user_name}{' '}
                        {name ? (
                          <Avatar
                            style={{
                              backgroundColor: randomColor(
                                name[name?.length - 1].charAt(0)?.toUpperCase()
                              ),
                            }}
                            size="small"
                          >
                            {name[name?.length - 1].charAt(0)?.toUpperCase()}
                          </Avatar>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Row>
                    {/* <div>{item?.content}</div> */}
                    {string.map((objTr, index) => (
                      <div
                        style={index == 0 ? {} : { marginLeft: 15 }}
                        key={index}
                      >
                        {objTr?.startsWith(`http`) ? (
                          <a href={objTr} target="_blank" rel="noreferrer">
                            {objTr}
                          </a>
                        ) : (
                          objTr
                        )}
                      </div>
                    ))}
                  </div>
                </>
              </TimelineStyled.Item>
            );
          }
        })}
      </TimelineStyled>

      <Modal
        title="Ghi chú"
        visible={visible}
        // onOk={handleAdd}
        onCancel={() => {
          form.resetFields();
          setVisible(false);
        }}
        footer={null}
      >
        <Form
          name="basic"
          form={form}
          initialValues={{ remember: true }}
          onFinish={values => onFinish(values)}
          autoComplete="off"
          style={{ width: '100%' }}
        >
          <Form.Item
            label="Nội dung"
            name="content"
            rules={[{ required: true, message: 'Vui lòng nhập nội dung' }]}
          >
            <TextArea
              style={{ width: '100%' }}
              rows={4}
              value={contentNote}
              showCount
              maxLength={2000}
              onChange={e => {
                setContentNote(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item
            wrapperCol={{ offset: 20, span: 24 }}
            style={{ margin: 0 }}
          >
            <Button type="primary" htmlType="submit">
              Lưu
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default OpportunityNote;
