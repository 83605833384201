import { handleResult, getAxios } from '@constants/Api';
import { TIME_CODE_TYPE } from '@constants/Constant';

export const requestGetSetupKPI = (
  { telemarketing_id, department_id, from, to, type = TIME_CODE_TYPE.MONTH, page, limit },
  option
) => {
  return handleResult(
    getAxios.get(
      // `/sale/report_kpi_sale?search=${telemarketing_id}&department_id=${department_id}&from=${from}&to=${to}&type=${type}&page=${page}&limit=${limit}`,
      `/sale/report_kpi_sale`,
      {
        params: new URLSearchParams({
          search: telemarketing_id,
          department_id,
          from,
          to,
          type,
          page,
          limit,
        }),
        ...option,
      }
      // option
    )
  );
};

export const requestCreateSetupKPI = (payload) => {
  return handleResult(
    getAxios.post('/admin/createGroupKPI', {
      telesale_ids: payload.telemarketingId,
      call: payload.call,
      opportunity: payload.opportunity,
      contract: payload.contractClosing,
      revenue: payload.revenue ? String(payload.revenue).replaceAll(',', '') : null,
      effective_time: payload.effectiveTime,
      new_customer: payload.newCustomer,
      work_day: payload.workDay,
      sales: payload.sales ? String(payload.sales).replaceAll(',', '') : null,
      auto_extend: payload.autoExtend,
      kpi_other: {
        tags: [...payload.kpiOther.tags],
        products: [...payload.kpiOther.products],
      },
    })
  );
};

export const requestUpdateSetupKPI = (payload) => {
  return handleResult(
    getAxios.post('/admin/updateKPI', {
      id: payload.id,
      telesale_ids: payload.telemarketingId,
      call: payload.call,
      opportunity: payload.opportunity,
      contract: payload.contractClosing,
      revenue: payload.revenue ? String(payload.revenue).replaceAll(',', '') : null,
      effective_time: payload.effectiveTime,
      new_customer: payload.newCustomer,
      work_day: payload.workDay,
      sales: payload.sales ? String(payload.sales).replaceAll(',', '') : null,
      auto_extend: payload.autoExtend,
      kpi_other: {
        tags: [...payload.kpiOther.tags],
        products: [...payload.kpiOther.products],
      },
    })
  );
};

export const requestGetDetailSetupKPI = (user_sale_kpi_id) => {
  return handleResult(
    getAxios.get('/kpi/find_by_id', {
      params: { id: user_sale_kpi_id },
    })
  );
};

export const requestGetDetailSetupKPIFromDate = (telemarketingId, effectiveTime) => {
  return handleResult(
    getAxios.post('/kpi/find_kpi_by_telesaleID_effectime', {
      telesale_id: telemarketingId,
      effective_time: effectiveTime,
    })
  );
};

export const requestCheckCopySetupKPI = (user_sale_kpi_id) => {
  return handleResult(
    getAxios.post('/kpi/find_kpi_by_time_telesale', {
      user_sale_kpi_id: user_sale_kpi_id,
    })
  );
};

export const requestDeleteSetupKPI = (user_sale_kpi_id) => {
  return handleResult(getAxios.post('/sale/delete_kpi_sale', { user_kpi_sale_id: user_sale_kpi_id }));
};
