import { handleResult, getAxios } from '@constants/Api';

export const requestUpdateCall = (payload) => {
  return handleResult(getAxios.post('call/updateForm', payload));
};

export const requestStartCall = (payload) => {
  // call from customer detail screen
  return handleResult(getAxios.post('call/startcall', payload));
};

export const requestStartCallWithNumber = (payload) => {
  // call from number pad screen
  return handleResult(getAxios.post('call/startCallWithNumber', payload));
};

export const requestCheckCallStatusEnded = (call_id) => {
  return handleResult(getAxios.get(`call/callStatus?call_id=${call_id}`));
};
