import { notifyFail } from '@utils/notify';
import React, { useCallback, useEffect, useState } from 'react';
import { useRef } from 'react';
import { CALLING_CODE } from './CallConstant';
import CallUtil from './CallUtil';

export default function useCallStatus() {
  const [call, setCall] = useState(CallUtil.currentCall);
  const [clients, setClients] = useState(CallUtil.currentClient);
  const callIdRef = useRef(CallUtil.currentCallId);
  const maskedRef = useRef('');
  const [callDuration, setCallDuration] = useState({
    start: 0,
    end: 0,
  });
  const channel = React.useMemo(
    () => new BroadcastChannel('CallBroadcast'),
    []
  );

  const checkUnhandledException = callData => {
    let isContain = false;
    Object.keys(CALLING_CODE).some(key => {
      const contains = typeof CALLING_CODE[key] === callData.status;
      if (contains) isContain = true;
    });
    // if (!isContain) {
    //   notifyFail(`Lỗi không xác định (${callData.status})`);
    // }
  };

  const updateTimerIfNeeded = callData => {
    switch (callData.status) {
      case CALLING_CODE.Answered:
        setCallDuration({
          start: parseInt(new Date().getTime() / 1000),
          end: 0,
        });
        break;

      case CALLING_CODE.Busy:
      case CALLING_CODE.Ended:
      case CALLING_CODE.TemporarilyUnavailable:
        // clearInterval(timerRef.current);
        setCallDuration(prev => {
          return { ...prev, end: parseInt(new Date().getTime() / 1000) };
        });
        break;
      default:
        setCallDuration({
          start: 0,
          end: 0,
        });
        break;
    }
  };

  useEffect(() => {
    //handle Broadcast Events
    channel.addEventListener('message', messageEvent => {
      switch (messageEvent.data.type) {
        case 'client':
          {
            const newClientStatus = {
              ...CallUtil.currentClient,
              [messageEvent.data.name]: messageEvent.data.status,
            };
            CallUtil.currentClient = newClientStatus;
            setClients(newClientStatus);
          }
          break;

        case 'call':
          setCall(messageEvent.data);
          CallUtil.currentCall = messageEvent.data;
          updateTimerIfNeeded(messageEvent.data);
          checkUnhandledException(messageEvent.data);
          if (messageEvent.data.status === CALLING_CODE.Preparing) {
            const newCallId = messageEvent.data.data.callId;
            const newMasked = messageEvent.data.data.masked;
            callIdRef.current = newCallId;
            maskedRef.current = newMasked;
          }
          break;

        case 'socket':
          // setCall(messageEvent.data);
          break;

        default:
          break;
      }
    });

    return () => {
      channel.close();
    };
  }, [channel]);

  return {
    clients,
    call,
    callDuration,
    callId: callIdRef.current,
    masked: maskedRef.current,
  };
}
