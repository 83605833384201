import Loading from '@components/Loading';
import { ApiClient, requestCreateJob, requestUpdateJob } from '@constants/Api';
import { JOB_STATUS, ROUTER, STRING } from '@constants/Constant';
import { getJobDetail, getTypeJob } from '@src/redux/actions';
import '@styles/RegisterRequest.css';
import { notifyFail, notifySuccess } from '@utils/notify';
import {
  Checkbox,
  Col as ColAntd,
  Form,
  Input,
  message,
  Row as RowAntd,
  Select,
} from 'antd';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { Col, FormControl, FormGroup, Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import reactotron from 'src/ReactotronConfig';
import { ButtonSystemStyle, TitleStyle } from '@styles/Style';
import mixpanel from 'mixpanel-browser';
import { VietnameseString } from '@constants/VietnameseString';
import { requestValidateApiKey } from '../../services/api';
import SelectEvoucherComponent from './function.components/SelectEvoucherComponent';
import AddTemplate from '@screens/Admin/Company/components/AddTemplate';
import { errorMinigame } from './fnHelper';

const { Option } = Select;

function FormError(props) {
  if (props.isHidden) {
    return null;
  }
  return (
    <div style={{ color: 'red', width: '100%', textAlign: 'center' }}>
      {props.errorMessage}
    </div>
  );
}
class General extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      form: {
        name: `Công việc ${moment(new Date()).format('HH:mm - DD/MM/YYYY')}`,
        type: '',
        job_type_id: '',
        start_at: '',
        end_at: '',
        content: '',
        tutorial: '',
        job_dialer_type: 0,
        enterprise_forward_job_enabled: false,
        enterprise_forward_job_id: '',
        enterprise_forward_job_api_key: '',
        job_evoucher: 0,
        job_evoucher_id: '',
        isShowTempplateMinigame: 0,
        mini_game_id: '',
      
      },
      data: '',
      currentIndex: 0,
      isLoading: false,
      miniGameSelectData: []
    };
    //callback lưu bước 1
    this.saveStep = this.createJob.bind(this);
    this.updateStep = this.updateJob.bind(this);
  }
  modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      [{ align: [] }],
      ['link', 'image', 'video'],
      ['clean'],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
    ],
  };

  componentDidMount() {
    const { jobState, location, match } = this.props;
    if (location.pathname.includes('/sua-cong-viec')) {
      const { id } = match.params;

      this.props.getJobDetail({ id: id });
    } else {
      this.props.getJobDetail();
    }
    this.getData();

    //callback lưu bước 1
    this.props.onSaveStep(this.saveStep);
    this.props.onUpdateStep(this.updateStep);
    this.getDataMiniGame()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.jobState.data?.job_id) {
      const { jobState } = this.props;
      const { data } = jobState;

      if (prevState.id === '' || prevState.id !== this.state.id) {
        if (data.job_id) {
          this.setState({
            ...this.state,
            id: data.job_id,
            form: {
              name: data.name,
              type: data.type,
              job_type_id: data?.job_type?.id,
              start_at: '',
              end_at: '',
              content: data.target,
              tutorial: data.tutorial,
              job_dialer_type: data?.job_dialer_type,
              status: data?.status,
              enterprise_forward_job_enabled:
              data.enterprise_forward_job_enabled,
              enterprise_forward_job_id: data?.enterprise_forward_job_id,
              enterprise_forward_job_api_key:
              data?.enterprise_forward_job_api_key,
              job_evoucher: !!+data?.e_voucher_config_enabled,
              job_evoucher_id: data?.e_voucher_config_id,
              isShowTempplateMinigame: data?.mini_game_id ? true : false,
              mini_game_id: data?.mini_game_id
            },
          });

          this.props.onChangeEvoucherWhenEdit({
            isCheckEvoucher: data?.e_voucher_config_id,
            idEvoucher: !!+data?.e_voucher_config_enabled,
          });
        }
      }
    }
  }

  getData() {
    this.props.getTypeJob();
  }

  getDataMiniGame = async() => {
    try {
        let res = await ApiClient.get(`job/miniGameUrl`)
        if(res.status) {
          this.setState({miniGameSelectData: res.data.map(item => {
            return { ...item, label: item.url, value: item.id}
          })})
        }
    } catch (error) {
      
    }
  }
  renderHeader() {
    const job = this.props.jobState?.data;
    return (
      <RowAntd justify="space-between">
        <ColAntd>
          <TitleStyle>{`${STRING.general_information}`}</TitleStyle>
        </ColAntd>
      </RowAntd>
    );
  }

  validateFormAdd() {
    const { form } = this.state;
    if (
      form?.name.trim() == '' ||
      form?.job_type_id == '' ||
      (form.enterprise_forward_job_enabled &&
        (form.enterprise_forward_job_api_key === '' ||
          form.enterprise_forward_job_id === ''))
      // form?.start_at == '' ||
      // form?.end_at == '' ||
      // form?.target == '' ||
      // form?.tutorial == ''
    ) {
      return false;
    }
    return true;
  }

  getResetAdd() {
    return {
      name: '',
      type: '',
      start_at: '',
      end_at: '',
      target: '',
      tutorial: '',
    };
  }

  setLoading(value) {
    this.props.setLoading(value);
  }

  onFinish = type => {
    const job = this.props.jobState?.data;
    job?.job_id === undefined ? this.createJob(type) : this.updateJob(type);
  };

  onSaveData = type => {
    const job = this.props.jobState?.data;
    job?.job_id === undefined ? this.createJob(type) : this.updateJob(type);
  };

  async createJob(type) {
    const { form } = this.state;
    const check = await this.validateFormAdd();
    if (check) {
      this.setLoading(true);
      const payload = {
        name: form.name,
        type: form.type || '0',
        job_type_id: form.job_type_id,
        start_at: form.start_at || null,
        end_at: form.end_at || null,
        target: form.content === '<p><br></p>' ? '' : form.content,
        tutorial: form.tutorial,
        job_dialer_type: form.job_dialer_type,
        enterprise_forward_job_enabled: +form.enterprise_forward_job_enabled,
        enterprise_forward_job_id: form.enterprise_forward_job_id,
        enterprise_forward_job_api_key: form.enterprise_forward_job_api_key,
        e_voucher_config_enabled: form?.job_evoucher ? 1 : 0,
        e_voucher_config_id: form?.job_evoucher ? form?.job_evoucher_id : 0,
        mini_game_id: form.isShowTempplateMinigame? form?.mini_game_id : ''
      };

      try {
        this.setState({ isLoading: true });

        const res = await requestCreateJob(payload);
        this.props.getJobDetail({ id: res.data.job_id });
        this.setState({ isLoading: false });
        if (type === 'save') {
          notifySuccess('Thêm công việc thành công');
          this.props.history.push(ROUTER.JOB);
          mixpanel.track(`Create job: ${form.name}(id: ${res.data.job_id})`, {
            payload,
          });
        }
        if (type === 'next') {
          const stepStatus = this.props.stepStatus;
          stepStatus[0] = 'finish'; //hoàn thành bước 1
          stepStatus[1] = 'process'; //chuyển trạng thái bước 2
          this.props.setStep(parseInt(this.props.currentStep) + 1, stepStatus);
        }
        if (type.includes('changeStep')) {
          let step = type.split(':');
          const stepStatus = this.props.stepStatus;
          stepStatus[0] = 'finish'; //hoàn thành bước 1
          stepStatus[parseInt(step[1])] = 'process'; //chuyển trạng thái bước được chọn trên menu step
          this.props.setStep(parseInt(step[1]), stepStatus);
        }
        this.setLoading(false);
      } catch (err) {
        this.setState({ isLoading: false });
        notifyFail(err.msg);
        this.setLoading(false);
      } finally {
        this.setState({ isLoading: false });
      }
      return;
    } else {
      if (form.name.trim() === '') {
        notifyFail('Vui lòng nhập tên công việc');
        return;
      }
      notifyFail('Vui lòng nhập đầy đủ thông tin');
    }
  }

  async updateJob(type) {
    const { form } = this.state;

    const { location } = this.props;
    const check = await this.validateFormAdd();
    const job = this.props.jobState.data;
    if (check) {
      let payload = {
        id: job.job_id,
        name: form.name,
        type: form.type || '0',
        job_type_id: form.job_type_id,
        start_at: form.start_at || null,
        end_at: form.end_at || null,
        target: form.content,
        tutorial: form.tutorial,
        job_dialer_type: form.job_dialer_type,
        enterprise_forward_job_enabled: +form.enterprise_forward_job_enabled,
        enterprise_forward_job_id: form.enterprise_forward_job_id,
        enterprise_forward_job_api_key: form.enterprise_forward_job_api_key,
        e_voucher_config_enabled: form?.job_evoucher ? 1 : 0,
        e_voucher_config_id: form?.job_evoucher ? form?.job_evoucher_id : 0,
        mini_game_id: form.isShowTempplateMinigame? form?.mini_game_id : ''
      };
      if (location.pathname.includes('/sua-cong-viec')) {
        payload = { ...payload, status: form.status };
      }

      try {
        const res = await requestUpdateJob({ ...payload, id: job?.job_id });
        this.props.getJobDetail({ id: res.data.job_id });
        this.setState({ isLoading: false });
        if (type === 'save') {
          notifySuccess('Cập nhật thông tin chung thành công');
          this.props.history.push(ROUTER.JOB);
          mixpanel.track(`Update job: ${form.name}(id: ${job.job_id})`, {
            payload,
          });
        }
        if (type === 'next') {
          const stepStatus = this.props.stepStatus;
          stepStatus[0] = 'finish'; //hoàn thành bước 1
          stepStatus[1] = 'process'; //chuyển trạng thái bước 2
          this.props.setStep(parseInt(this.props.currentStep) + 1, stepStatus);
        }
        if (type.includes('changeStep')) {
          let step = type.split(':');
          const stepStatus = this.props.stepStatus;
          stepStatus[0] = 'finish'; //hoàn thành bước 1
          stepStatus[parseInt(step[1])] = 'process'; //chuyển trạng thái bước được chọn trên menu step
          this.props.setStep(parseInt(step[1]), stepStatus);
        }
        // this.props.onNext();
      } catch (err) {
        this.setState({ isLoading: false });
        notifyFail(err.msg);
      }
    } else {
      if (form.name.trim() === '') {
        notifyFail('Vui lòng nhập tên công việc');
        return;
      }
      notifyFail('Vui lòng nhập đầy đủ thông tin');
    }
    return;
  }

  checkValidation = () => {
    const { form } = this.state;
    if (
      form?.name == '' ||
      form?.job_type_id == '' ||
      // form?.start_at == '' ||
      // form?.end_at == '' ||
      form?.target == '' ||
      form?.tutorial == ''
    ) {
      return <FormError errorMessage={'Vui lòng điền đầy đủ thông tin!'} />;
    }

    return true;
  };

  validateApiKey = async (key, job_id) => {
    try {
      await requestValidateApiKey({ key, job_id });
      message.success(VietnameseString.api_key_and_valid_assignment_code);
    } catch (error) {
      console.log('Error validateApiKey: ', { error });
      message.warn(error.msg);
    }
  };

  renderForm() {
    const typeJob = this.props.typeJobState?.data;
    if (typeJob && typeJob.length) {
      const typeId = typeJob.find(type => type.name.includes('Mua bán'))
        ? typeJob.find(type => type.name.includes('Mua bán')).id
        : typeJob[0]?.id;
      if (this.state.form.job_type_id === '') {
        this.setState({
          form: {
            ...this.state.form,
            job_type_id: typeId,
          },
        });
      }
    }
    const { form } = this.state;

    return (
      <Row>
        <FormGroup className="">
          <Col sm={12} className="text-left mb-3 px-0">
            <span>Tên công việc: (*)</span>
            <FormControl
              type="text"
              name="name"
              placeholder="Nhập tên công việc"
              value={form.name}
              maxLength={100}
              onChange={e => this.handleInputChange('name', e.target.value)}
            ></FormControl>
          </Col>

          <Col sm={12} className="text-left mb-3 px-0">
            <div>Loại công việc: (*)</div>
            <Select
              style={{ width: '100%' }}
              value={
                form.job_type_id
                  ? form.job_type_id
                  : typeJob &&
                    typeJob.length &&
                    (typeJob.find(type => type.name.includes('Mua bán'))
                      ? typeJob.find(type => type.name.includes('Mua bán')).id
                      : typeJob[0]?.id)
              }
              onChange={value => {
                this.handleInputChange('job_type_id', value);
              }}
              placeholder="Loại công việc"
            >
              {typeJob && typeJob.length ? (
                typeJob?.map(value => (
                  <Option value={value.id} key={value.id}>
                    {value.name}
                  </Option>
                ))
              ) : (
                <Option></Option>
              )}
            </Select>
          </Col>

          {/* update trạng thái công việc */}
          {this.props.location.pathname.includes('/sua-cong-viec') && (
            <Col sm={12} className="text-left mb-3 px-0">
              <span>Trạng thái:</span>
              <FormControl
                as="select"
                value={form.status}
                onChange={e => this.handleInputChange('status', e.target.value)}
              >
                {JOB_STATUS.map(value => (
                  <option value={value.id} key={value.id}>
                    {value.name}
                  </option>
                ))}
              </FormControl>
            </Col>
          )}

          <RowAntd justify="end">
            <ColAntd>
              {form.enterprise_forward_job_enabled ? (
                <ButtonSystemStyle
                  onClick={() =>
                    this.validateApiKey(
                      form.enterprise_forward_job_api_key,
                      form.enterprise_forward_job_id
                    )
                  }
                >
                  {VietnameseString.check_api_key}
                </ButtonSystemStyle>
              ) : (
                <Fragment />
              )}
            </ColAntd>
          </RowAntd>

          <RowAntd>
            <ColAntd
              span={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'left',
                marginBottom: '10px',
              }}
            >
              {/* <div>
                <Checkbox>Áp dụng chương trình khuyến mãi</Checkbox>
              </div> */}

              <div>
                <Checkbox
                  disabled={this.props?.jobState?.data?.job_id}
                  checked={form.job_dialer_type}
                  onChange={event => {
                    if (event.target.checked) {
                      this.setState({
                        ...this.state,
                        form: {
                          ...this.state.form,
                          job_dialer_type: 1,
                        },
                      });
                    } else {
                      this.setState({
                        ...this.state,
                        form: {
                          ...this.state.form,
                          job_dialer_type: 0,
                        },
                      });
                    }
                  }}
                >
                  Cho phép Call bot thực hiện công việc
                </Checkbox>
              </div>
            </ColAntd>

            <ColAntd
              span={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'left',
                marginBottom: '10px',
              }}
            >
              <div>
                <Checkbox
                  checked={form.enterprise_forward_job_enabled}
                  onChange={event => {
                    this.setState({
                      form: {
                        ...form,
                        enterprise_forward_job_enabled: event.target.checked,
                        enterprise_forward_job_api_key: '',
                        enterprise_forward_job_id: '',
                      },
                    });
                  }}
                >
                  {
                    VietnameseString.allow_synchronization_of_customer_information
                  }
                </Checkbox>

                {form.enterprise_forward_job_enabled ? (
                  <RowAntd gutter={[16, 16]}>
                    <ColAntd span={5}>
                      {VietnameseString.enter_api_key}:
                    </ColAntd>

                    <ColAntd span={19}>
                      <Input
                        placeholder={VietnameseString.api_key}
                        value={form.enterprise_forward_job_api_key}
                        onChange={event => {
                          this.setState({
                            form: {
                              ...form,
                              enterprise_forward_job_api_key:
                                event.target.value,
                            },
                          });
                        }}
                      />
                    </ColAntd>

                    <ColAntd span={5}>
                      {VietnameseString.enter_the_sub_job_code}:
                    </ColAntd>

                    <ColAntd span={19}>
                      <Input
                        placeholder={VietnameseString.sub_job_code}
                        value={form.enterprise_forward_job_id}
                        onChange={event => {
                          this.setState({
                            form: {
                              ...form,
                              enterprise_forward_job_id: event.target.value,
                            },
                          });
                        }}
                      />
                    </ColAntd>
                  </RowAntd>
                ) : (
                  <Fragment />
                )}
              </div>

              {/* <div>
                <Checkbox>Cho phép đồng bộ qua webhook</Checkbox>

                <RowAntd gutter={[16, 16]}>
                  <ColAntd span={6}>Webhook URL:</ColAntd>
                  <ColAntd span={18}>
                    <Input />
                  </ColAntd>
                </RowAntd>
              </div> */}
            </ColAntd>
            <ColAntd
              span={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'left',
                marginBottom: '10px',
              }}
            >
              {/* <div>
                <Checkbox>Áp dụng chương trình khuyến mãi</Checkbox>
              </div> */}

              <div>
                <Checkbox
                  // disabled={this.props?.jobState?.data?.job_id}
                  checked={!!+form.job_evoucher}
                  onChange={event => {
                    if (event.target.checked) {
                      this.setState({
                        ...this.state,
                        form: {
                          ...this.state.form,
                          job_evoucher: 1,
                        },
                      });
                      this.props.onChangeCheckEvoucher(true);
                    } else {
                      this.setState({
                        ...this.state,
                        form: {
                          ...this.state.form,
                          job_evoucher: 0,
                        },
                      });

                      this.props.onChangeCheckEvoucher(false);
                    }
                  }}
                >
                  Áp dụng chương trình ưu đãi
                </Checkbox>
              </div>
              {form.job_evoucher ? (
                <Row style={{ padding: '10px 40px' }}>
                  <Col span={4}>Chọn mã chương trình</Col>
                  <Col span={20}>
                    <SelectEvoucherComponent
                      defaultValue={form.job_evoucher_id || ''}
                      onChangeEvoucher={value => {
                        this.setState({
                          ...this.state,
                          form: {
                            ...this.state.form,
                            job_evoucher_id: value,
                          },
                        });
                        this.props.onChangeIdEvoucher(value);
                      }}
                    />
                  </Col>
                </Row>
              ) : null}
            </ColAntd>

            <ColAntd
              span={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'left',
                marginBottom: '10px',
              }}
            >
              {/* <div>
                <Checkbox>Áp dụng chương trình khuyến mãi</Checkbox>
              </div> */}

              <div>
                <Checkbox
                  // disabled={this.props?.jobState?.data?.job_id}
                  checked={form.isShowTempplateMinigame}
                  onChange={event => {
                    if (event.target.checked) {
                      this.setState({
                        ...this.state,
                        form: {
                          ...this.state.form,
                          isShowTempplateMinigame: 1,
                        },
                      });
                      this.props.isCheckedMiniGame(true)
                    } else {
                      this.setState({
                        ...this.state,
                        form: {
                          ...this.state.form,
                          isShowTempplateMinigame: 0,
                        },
                      });

                      this.props.isCheckedMiniGame(false);
                    }
                  }}
                >
                  Chọn minigame
                </Checkbox>
              </div>
              {form.isShowTempplateMinigame ? (
                <Row style={{ padding: '10px 40px' }}>
                  <Col span={4}>Chọn minigame</Col>
                  <Col span={20}>
                    <Select
                    showSearch
                    allowClear
                    placeholder={`Chọn minigame`}
                    style={{ width: '100%' }}
                    value={form.mini_game_id || null}
                    optionFilterProp ="label"
                    options = {this.state.miniGameSelectData}
                    onChange={value => {
                      this.setState({
                        ...this.state,
                        form: {
                          ...this.state.form,
                          mini_game_id: value,
                        },
                      });
                      this.props.selectedIdMiniGame(value);
                    }}
                    >
         
                  </Select>
                  </Col>
                </Row>
              ) : null}
            </ColAntd>
            {/* <ColAntd
              span={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'left',
                marginBottom: '10px',
              }}
            > */}
            {/* <div>
                <Checkbox>Áp dụng chương trình khuyến mãi</Checkbox>
              </div> */}

            {/* <div>
                <Checkbox
                disabled={this.props?.jobState?.data?.job_id}
                checked={form.job_dialer_type}
                onChange={event => {
                  if (event.target.checked) {
                    this.setState({
                      ...this.state,
                      form: {
                        ...this.state.form,
                        job_dialer_type: 1,
                      },
                    });
                  } else {
                    this.setState({
                      ...this.state,
                      form: {
                        ...this.state.form,
                        job_dialer_type: 0,
                      },
                    });
                  }
                }}
                >
                  Đồng bộ thông tin khách hàng qua webhook
                </Checkbox>
              </div> */}
            {/* </ColAntd> */}
          </RowAntd>

          <Col sm={12} className="text-left mb-3 px-0">
            <span>Mục tiêu</span>
            <ReactQuill
              value={this.state.form.content}
              modules={this.modules}
              onChange={this.handleChangeFromTarget}
            />
          </Col>
        </FormGroup>
      </Row>
    );
  }
 
  renderButton() {
    return (
      <RowAntd justify="space-between">
        <ColAntd></ColAntd>
        <ColAntd>
          <Form.Item>
            <ButtonSystemStyle
              htmlType="button"
              onClick={() => {
                if (
                  this.state.form.job_evoucher &&
                  !this.state.form.job_evoucher_id
                ) {
                  message.error('Vui lòng chọn mã chương trình ưu đãi');
                  return;
                }

                if (
                  !errorMinigame(this.state.form.isShowTempplateMinigame,this.state.form.mini_game_id)
                ) {
                  return;
                }

                this.onFinish('next');
              }}
            >
              Tiếp tục
            </ButtonSystemStyle>
          </Form.Item>
        </ColAntd>
      </RowAntd>
    );
  }

  handleInputChange = (field, value) => {
    const job = this.props.jobState?.data;
    this.setState(
      {
        ...this.state,
        id: job.job_id,
        form: {
          ...this.state.form,
          [field]: value,
        },
      },
      () => reactotron.log(this.state.form)
    );
  };

  handleChangeFromTarget = value => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        content: value,
      },
    });
  };

  handleChangeFromTutorial = value => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        tutorial: value,
      },
    });
  };
  render() {
    return (
      <div ref={this.props.innerRef}>
        {this.state.isLoading && <Loading />}
        {this.renderHeader()}
        {this.renderForm()}
        {this.renderButton()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  jobState: state.jobDetailReducer,
  typeJobState: state.typeJobReducer,
});

const mapDispatchToProps = {
  getJobDetail,
  getTypeJob,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(General));
