import React from 'react';
import { Checkbox, Modal, Skeleton } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { requestTelesaleDetail } from '@constants/Api';

function RemoveCustomerModal(props) {
  const { visible, groupId, handleCancel, handleCreateSubJob } = props;

  const [isLoading, setIsLoading] = React.useState(false);

  const [assign, setAssign] = React.useState(0);
  const [notAssign, setNotAssign] = React.useState(0);

  const [isRemoveCustomer, setIsRemoveCustomer] = React.useState(false);

  async function getSaleInJob() {
    try {
      setIsLoading(true);
      const { data } = await requestTelesaleDetail({
        group_id: groupId,
      });

      setAssign(data.customer.customer_assign);
      setNotAssign(data.customer.customer_not_assign);
      setIsLoading(false);
    } catch (error) {
      console.log('Error getSaleInJob: ', { error });
    }
  }

  React.useEffect(() => {
    getSaleInJob();
  }, []);

  return (
    <ModalStyled
      title="Thông báo"
      visible={visible}
      onOk={() => {
        if (isRemoveCustomer) {
          handleCreateSubJob(true);
        } else {
          handleCreateSubJob();
        }
      }}
      onCancel={handleCancel}
    >
      {isLoading ? (
        <Skeleton active />
      ) : (
        <ul>
          <li>{notAssign} khách hàng có thể giao cho sale chăm sóc</li>
          <li>{assign} khách hàng đã có sale chăm sóc</li>
        </ul>
      )}
      {
      //  props.showCheckBox &&
           <Checkbox
       checked={isRemoveCustomer}
       onChange={event => {
         setIsRemoveCustomer(event.target.checked);
       }}
     >
       Xóa khách hàng đã có sale chăm sóc khỏi nhóm và giao việc
     </Checkbox>
      }
  
    </ModalStyled>
  );
}

RemoveCustomerModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  groupId: PropTypes.number.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleCreateSubJob: PropTypes.func.isRequired,
};

const ModalStyled = styled(Modal)`
  ul {
    padding: 0 32px;
  }
`;

export default RemoveCustomerModal;
