import React from 'react';
import styled from 'styled-components';
import imgNotCall from '@assets/imgcall.png';
import imgCalled from '@assets/phonecall.png';
import imgschedual from '@assets/schedual.png';
import imgclock from '@assets/clock.png';
const FlexContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: -2px;
  font-weight: 500;
  font-size: 12px;
  img {
    width: 20px;
    height: 20px;
    margin-top: -3px;
  }
  .time {
    /* font-size: 13px; */
  }
  .desc {
    font-size: 12px;
    font-weight: bold;
  }
  .time {
    font-size: 12px;
  }
`;
const CallStatus = ({ status, timeCalled }) => {
  return (
    <div>
      {status === 1 && (
        <FlexContainer>
          <div className="icon_callstatus">
            <img src={imgNotCall} alt="" />
          </div>
          <div className="desc">Chưa gọi</div>
        </FlexContainer>
      )}

      {status === 2 && (
        <FlexContainer>
          <div className="icon_callstatus">
            <img src={imgCalled} alt="" />
          </div>
          <div className="desc">Đã gọi</div>
          <div className="time">{timeCalled}</div>
        </FlexContainer>
      )}
      {status === 3 && (
        <FlexContainer>
          <img src={imgschedual} alt="" />
          <div className="desc">Lịch hẹn</div>
          <img src={imgclock} alt="" />
          <div className="time">{timeCalled}</div>
        </FlexContainer>
      )}
    </div>
  );
};

export default CallStatus;
