import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Descriptions } from 'antd';
import PropTypes from 'prop-types';

import {
  getStatusSMSStyle,
  getStatusZNSStyle,
  SENDING_SMS_STATUS,
  SENDING_ZNS_STATUS,
} from '@screens/Enterprise/Report/MessageReport/Utils/constant';
import InteractionHistoryHeader from './InteractionHistoryHeader';
import { VietnameseString } from '@constants/VietnameseString';
import { DF_CUSTOMER_ACTION_ID } from '@constants/Constant';
import { formatPrice } from '@constants/funcHelper';

import { DescriptionCustomInteractionHistory } from '@styles/Style';

const DescriptionsStyled = styled(Descriptions)`
  &&& {
    th.ant-descriptions-item {
      padding-bottom: 0px;
    }

    span.ant-descriptions-item-content {
      text-align: left;
      font-weight: 600;
      /* padding-left: 24px; */
    }
  }
`;

function Message(props) {
  const { interactionHistory } = props;
  const { tags: infoMessage } = interactionHistory;

  const { label: statusLabel, color: statusColor } =
    interactionHistory.df_customer_action_id === DF_CUSTOMER_ACTION_ID.GUI_TIN_NHAN_ZNS
      ? getStatusZNSStyle(infoMessage?.status)
      : getStatusSMSStyle(infoMessage?.status);

  return (
    <Fragment>
      <InteractionHistoryHeader interactionHistory={interactionHistory} />

      <DescriptionCustomInteractionHistory column={2}>
        <Descriptions.Item label={VietnameseString.job}>{infoMessage?.job_name}</Descriptions.Item>

        <Descriptions.Item label={VietnameseString.status}>
          <span style={{ color: statusColor }}>{statusLabel}</span>
        </Descriptions.Item>

        <Descriptions.Item label={VietnameseString.cost}>
          {infoMessage?.status === SENDING_ZNS_STATUS.CAN_NOT_SEND ||
          infoMessage?.status === SENDING_SMS_STATUS.CAN_NOT_SEND
            ? 0
            : formatPrice(infoMessage?.price)}{' '}
          VNĐ
        </Descriptions.Item>
      </DescriptionCustomInteractionHistory>

      {interactionHistory.df_customer_action_id === DF_CUSTOMER_ACTION_ID.GUI_TIN_NHAN_SMS ? (
        <DescriptionsStyled column={1}>
          <Descriptions.Item label={VietnameseString.content}>{infoMessage?.message}</Descriptions.Item>

          {infoMessage?.status === SENDING_SMS_STATUS.CAN_NOT_SEND ? (
            <Descriptions.Item label={VietnameseString.note}>{infoMessage?.response?.message}</Descriptions.Item>
          ) : (
            <Fragment />
          )}
        </DescriptionsStyled>
      ) : (
        <Fragment />
      )}

      {interactionHistory.df_customer_action_id === DF_CUSTOMER_ACTION_ID.GUI_TIN_NHAN_ZNS ? (
        <DescriptionsStyled column={1}>
          <Descriptions.Item label={VietnameseString.template}>{infoMessage?.template_name}</Descriptions.Item>

          {infoMessage?.status === SENDING_ZNS_STATUS.CAN_NOT_SEND ? (
            <Descriptions.Item label={VietnameseString.note}>{infoMessage?.note}</Descriptions.Item>
          ) : (
            <Fragment />
          )}
        </DescriptionsStyled>
      ) : (
        <Fragment />
      )}
    </Fragment>
  );
}

Message.propTypes = {
  interactionHistory: PropTypes.object,
};

export default Message;
