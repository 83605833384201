import React from 'react';

import { rmVN } from '@constants/funcHelper';
import { Col, DatePicker, Empty, Row, Select } from 'antd';
import moment from 'moment';

const { Option } = Select;
function Filter({ params, setParams, departmentList, saleList, handleChangeParam }) {
  const disabledDate = (current) => {
    // Can not select days after today and today
    return current && current > moment().endOf('day');
  };

  return (
    <>
      <Row gutter={(16, 16)} style={{ marginBottom: 10 }}>
        <Col xxl={4} xl={6} lg={8} sm={12} xs={24}>
          <Select
            style={{ width: '100%', marginTop: 10 }}
            showSearch
            allowClear
            mode="multiple"
            maxTagCount={2}
            placeholder="Sale"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return rmVN(option.children).indexOf(rmVN(input)) >= 0;
            }}
            filterSort={(optionA, optionB) =>
              optionA?.children?.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
            notFoundContent={
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>Không có dữ liệu!</span>} />
            }
            onChange={(value) => {
              handleChangeParam('sale_id', value);
            }}
            value={params.sale_id?.length > 0 ? params.sale_id : undefined}
          >
            {saleList
              ? saleList.map((item, index) => (
                  <Option key={index} value={item.telesale_id}>
                    {item.full_name}
                  </Option>
                ))
              : ''}
          </Select>
        </Col>
        <Col xxl={4} xl={6} lg={8} sm={12} xs={24}>
          <Select
            style={{ width: '100%', marginTop: 10 }}
            showSearch
            allowClear
            mode="multiple"
            maxTagCount={2}
            placeholder="Phòng ban"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return rmVN(option.children).indexOf(rmVN(input)) >= 0;
            }}
            filterSort={(optionA, optionB) =>
              optionA?.children?.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
            notFoundContent={
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>Không có dữ liệu!</span>} />
            }
            onChange={(value) => {
              handleChangeParam('department_id', value);
            }}
            value={params.department_id?.length > 0 ? params.department_id : undefined}
          >
            {departmentList
              ? departmentList.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))
              : ''}
          </Select>
        </Col>
        <Col xxl={4} xl={6} lg={6} sm={12} xs={24}>
          <DatePicker
            style={{ width: '100%', marginTop: 10 }}
            format={'YYYY/MM/DD'}
            placeholder="Chọn ngày"
            disabledDate={disabledDate}
            allowClear={false}
            value={params?.date ? moment(params?.date, 'YYYY/MM/DD') : ''}
            onChange={(date, dateString) => {
              if (date !== null) {
                handleChangeParam('date', moment(dateString).format('YYYY/MM/DD'));
              } else {
                handleChangeParam('date', '');
              }
            }}
          />
        </Col>
      </Row>
    </>
  );
}

export default Filter;
