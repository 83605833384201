import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions } from 'antd';

import InteractionHistoryHeader from './InteractionHistoryHeader';
import { DescriptionCustomInteractionHistory } from '@styles/Style';
import { VietnameseString } from '@constants/VietnameseString';
import { renderProductName } from './func';
import { formatPrice } from '@constants/funcHelper';

function DeleteOpportunities(props) {
  const { interactionHistory } = props;
  return (
    <>
      <InteractionHistoryHeader interactionHistory={interactionHistory} />

      <DescriptionCustomInteractionHistory column={2}>
        <Descriptions.Item label="Tên sale">
          {interactionHistory.telesale_name ? interactionHistory.telesale_name : VietnameseString.not_update}
        </Descriptions.Item>

        <Descriptions.Item label="Công việc">
          {interactionHistory.job_name ? interactionHistory.job_name : VietnameseString.not_update}
        </Descriptions.Item>

        <Descriptions.Item label="Loại cơ hội">
          {interactionHistory.opportunity_category_name
            ? interactionHistory.opportunity_category_name
            : VietnameseString.not_update}
        </Descriptions.Item>

        <Descriptions.Item label="Sản phẩm">
          {/* {interactionHistory.product_name ? interactionHistory.product_name : VietnameseString.not_update} */}
          {interactionHistory.product.length
            ? renderProductName(interactionHistory.product)
            : VietnameseString.not_update}
        </Descriptions.Item>

        <Descriptions.Item label="Doanh số">
          {interactionHistory.sales ? `${formatPrice(interactionHistory.sales)}đ` : VietnameseString.not_update}
        </Descriptions.Item>

        <Descriptions.Item label="Doanh thu">
          {interactionHistory.revenue ? `${formatPrice(interactionHistory.revenue)}đ` : VietnameseString.not_update}
        </Descriptions.Item>

        <Descriptions.Item label="Ghi chú">
          {interactionHistory.opportunity_note ? interactionHistory.opportunity_note : VietnameseString.not_update}
        </Descriptions.Item>
      </DescriptionCustomInteractionHistory>
    </>
  );
}

export default DeleteOpportunities;

DeleteOpportunities.propTypes = {
  interactionHistory: PropTypes.object,
};
