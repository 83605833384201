import React from 'react';

const CallingTimer = ({ endCount }) => {
  const [seconds, setSeconds] = React.useState(0);
  const [minutes, setMinutes] = React.useState(0);
  const [hours, setHours] = React.useState(0);
  const timer = React.useRef(null);

  React.useEffect(() => {
    timer.current = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds + 1);
    }, 1000);

    return () => {
      clearInterval(timer.current);
    };
  }, []);

  React.useEffect(() => {
    if (endCount) {
      clearInterval(timer.current);
      // setSeconds(0);
      // setMinutes(0);
      // setHours(0);
    }
  }, [endCount]);

  React.useEffect(() => {
    if (seconds === 60) {
      setSeconds(0);
      setMinutes(prevMinutes => prevMinutes + 1);
    }
  }, [seconds]);

  React.useEffect(() => {
    if (minutes === 60) {
      setMinutes(0);
      setHours(prevHours => prevHours + 1);
    }
  }, [minutes]);

  return (
    <div style={{ fontWeight: 'bold', fontSize: '18px' }}>
      {hours ? `${hours.toString().padStart(2, '0')} :` : ''}
      {minutes.toString().padStart(2, '0')}:
      {seconds.toString().padStart(2, '0')}
    </div>
  );
};

export default CallingTimer;
