import { GET_PACKAGE_HISTORY, GET_PACKAGE_HISTORY_SUCCESS, GET_PACKAGE_HISTORY_FAIL } from '@actions/type';
import reactotron from 'reactotron-react-js';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PACKAGE_HISTORY:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PACKAGE_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: action.payload.response.data,
      };
    case GET_PACKAGE_HISTORY_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
