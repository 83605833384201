import React, { useEffect, useState } from 'react';
import { Col, DatePicker, Row, Select, Tooltip, Input, Empty } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { setSearchParamsInUrl, rmVN } from '@constants/funcHelper';
import moment from 'moment';
import mixpanel from 'mixpanel-browser';

const { Option } = Select;
function Filter({ params, setParams, handleChangeParamSearchCustomers, user }) {
  useEffect(() => {}, []);

  return (
    <>
      <Row gutter={(16, 16)} style={{ marginBottom: 10 }}>
        <Col xxl={6} xl={8} lg={8} sm={12} xs={24}>
          <Input
            allowClear
            style={{ width: '100%', marginTop: 10 }}
            prefix={<SearchOutlined />}
            placeholder="Nội dung giao việc, người giao..."
            value={params?.search}
            onSearch={searchKey =>
              handleChangeParamSearchCustomers('search', searchKey)
            }
            onChange={e =>
              handleChangeParamSearchCustomers('search', e.target.value)
            }
          />
        </Col>
        <Col xxl={4} xl={6} lg={8} sm={12} xs={24} >
          <Select
            style={{ width: '100%', marginTop: 10 }}
            showSearch
            allowClear
            maxTagCount={1}
            placeholder="Trạng thái"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return rmVN(option.children).indexOf(rmVN(input)) >= 0;
            }}
            filterSort={(optionA, optionB) =>
              optionA?.children
                ?.toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu!</span>}
              />
            }
            onChange={value => {
              handleChangeParamSearchCustomers('job_status', value);
            }}
            value={
              params?.job_status?.length > 0 ? params?.job_status : undefined
            }
          >
            <Option key={1} value={1}>
              Chưa nhận việc
            </Option>
            <Option key={4} value={4}>
              Đã nhận việc
            </Option>
            <Option key={2} value={2}>
              Đang thực hiện
            </Option>
            <Option key={3} value={3}>
              Hoàn thành
            </Option>
          </Select>
        </Col>
        <Col xxl={4} xl={6} lg={6} sm={12} xs={24}>
          <DatePicker
            allowClear
            style={{ width: '100%', marginTop: 10 }}
            placeholder="Ngày tạo"
            format={'YYYY/MM/DD'}
            value={
              params?.from_date ? moment(params?.from_date, 'YYYY/MM/DD') : ''
            }
            onChange={(date, dateString) => {
              mixpanel.track(`filter from_date: ${dateString}`);
              if (date !== null) {
                setParams({
                  ...params,
                  from_date: moment(dateString).format('YYYY/MM/DD'),
                  page: 1,
                });
              } else {
                setParams({
                  ...params,
                  from_date: '',
                });
              }
            }}
          />
        </Col>
      </Row>
    </>
  );
}

export default Filter;
