import React from 'react';
import logo from '@assets/logo-ezsale.png';

const EZLoadingFull = () => {
  return (
    <div
      style={{
        zIndex: 999,
        backgroundColor: 'rgba(0,0,0,.4)',
        position: 'fixed',
        inset: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        src={logo}
        class="blink_me"
        alt="logo"
        width="150px"
        height="150px"
      />
    </div>
  );
};

export default EZLoadingFull;
