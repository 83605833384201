import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import reactotron from 'reactotron-react-js';
import { QUESTION_TYPE, QUESTION_TYPE_ALIAS } from '@constants/Constant';
import { getItemFromId } from '@utils/getItemFromId';
import { Popconfirm } from 'antd';

class Step extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: '1',
    };
  }

  renderFormType(form, isChild) {
    if (
      [QUESTION_TYPE.SELECT, QUESTION_TYPE.MULTIPLE_SELECT, QUESTION_TYPE.YES_NO].includes(parseInt(form.type_input))
    ) {
      return <>{this.renderType1(form, isChild)}</>;
    } else {
      return <>{this.renderType2(form)}</>;
    }

    return null;
  }

  renderType1(form, isChild) {
    const input_type = getItemFromId(QUESTION_TYPE_ALIAS, form.type_input)?.name || 'Unknown';
    return (
      <>
        <Row className="py-2">
          <Col md={12} className="px-0">
            <Row>
              <Col md={10} className="px-0">
                <span>{form.name}?</span>
                <b> {`(${form.reward_point} điểm)`}</b>
                <span className="text-red"> {form.required ? '(*)' : ''}</span>
                <span className="text-green"> ( {form && input_type} )</span>
              </Col>
              <Col md={2} className="px-0">
                <Row className="justify-content-end pb-3 pb-md-0">
                  <i
                    className="far fa-edit step-icon-edit mr-2"
                    onClick={() => {
                      this.props.showEditModal('modalAdd', true, form);
                    }}
                  ></i>
                  <Popconfirm
                    title="Bạn muốn xóa câu hỏi này?"
                    onConfirm={() => this.props.deleteForm(form.id)}
                    okText="Có"
                    cancelText="Không"
                  >
                    <i className="far fa-trash-alt step-icon-del"></i>
                  </Popconfirm>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md={12} className={!isChild ? 'pl-5 px-0' : 'ml-0 px-0'}>
            <ul>
              {form.job_form_answers?.length > 0 &&
                form.job_form_answers.map((op, index) => {
                  return (
                    <>
                      <li key={index}>
                        {op.name}
                        {!isChild && (
                          <div xs={12} className="text-blue cursor d-inline-block ml-3">
                            <i className="fas fa-plus mr-1 "></i>
                            <span
                              onClick={() => {
                                this.props.showSubModal('subModalAdd', true, form, op);
                              }}
                            >
                              Thêm câu hỏi phụ
                            </span>
                          </div>
                        )}
                      </li>
                      {op?.job_form_answer_child?.map((item, index) => this.renderForm({ ...item }, true))}
                    </>
                  );
                })}
            </ul>
          </Col>
        </Row>
      </>
    );
  }

  renderType2(form) {
    const input_type = getItemFromId(QUESTION_TYPE_ALIAS, form.type_input)?.name || 'Unknown';
    return (
      <>
        <Row className="pt-0 align-items-start">
          <Col md={10} className="px-0">
            <span>{form.name}?</span>
            <b> {`(${form.reward_point} điểm)`}</b>
            <span className="text-red"> {form.required ? '(*)' : ''}</span>
            <span className="text-green"> ( {form && input_type} )</span>
          </Col>
          <Col md={2} className="px-0">
            <Row className="justify-content-end pb-3 pb-md-0 justify-content-end">
              <i
                className="far fa-edit step-icon-edit mr-2"
                onClick={() => {
                  this.props.showEditModal('modalAdd', true, form);
                }}
              ></i>
              <Popconfirm
                title="Bạn muốn xóa câu hỏi này?"
                onConfirm={() => this.props.deleteForm(form.id)}
                okText="Có"
                cancelText="Không"
              >
                <i className="far fa-trash-alt step-icon-del"></i>
              </Popconfirm>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  renderForm(form, isChild) {
    return (
      <>
        <div className="assessment step-frame d-flex align-items-center mb-2 mt-2">
          <Row className="w-100 align-items-center">
            <Col md={12} className="pl-2">
              {this.renderFormType(form || this.props.form, isChild)}
            </Col>
          </Row>
        </div>
      </>
    );
  }

  render() {
    return <>{this.renderForm()}</>;
  }
}

export default Step;
