import React, { useEffect, useState } from 'react';
import { Button, Row, Tabs, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

import { getSearchParamsInUrl, setSearchParamsInUrl } from '@constants/funcHelper';
import VoucherManagement from './components/voucher/VoucherManagement';
import { VietnameseString } from '@constants/VietnameseString';
import GiftManagement from './components/gift/GiftManagement';
import Wrapper from '@components/ui/wrapper/Wrapper';

import { BreadcrumbAntStyle } from '@styles/Style';
import { HeadingStyled } from './ElectronicVoucherStyled';
import styled from 'styled-components';
import { primaryColor } from '@styles/Color';

function ElectronicVoucher() {
  const [callback, setCallback] = useState(false);

  const [infoElectronicVoucher, setInfoElectronicVoucher] = useState({
    tab: 'voucher-management',
  });

  useEffect(() => {
    getSearchParamsInUrl(infoElectronicVoucher, setInfoElectronicVoucher);
  }, []);

  return (
    <div className="content-wrapper">
      <BreadcrumbAntStyle></BreadcrumbAntStyle>

      <Wrapper>
        <Row justify="space-between" align="center">
          <HeadingStyled>{VietnameseString.electronic_voucher}</HeadingStyled>
          <Tooltip title="Tải lại dữ liệu">
            <Button
              onClick={() => {
                setCallback(!callback);
              }}
              icon={<ReloadOutlined />}
            />
          </Tooltip>
        </Row>

        <TabsAntStyled
          type="card"
          activeKey={infoElectronicVoucher.tab}
          onChange={(activeKey) => {
            setSearchParamsInUrl('tab', activeKey);
            setInfoElectronicVoucher({ ...infoElectronicVoucher, tab: activeKey });
          }}
        >
          <Tabs.TabPane tab={VietnameseString.voucher_management} key="voucher-management">
            <VoucherManagement callbackReload={callback} />
          </Tabs.TabPane>

          <Tabs.TabPane tab={VietnameseString.gift_management} key="gift-management">
            <GiftManagement />
          </Tabs.TabPane>
        </TabsAntStyled>
      </Wrapper>
    </div>
  );
}

export const TabsAntStyled = styled(Tabs)`
  width: 100%;
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${primaryColor};
    font-size: 16px;
    font-weight: 600;
  }

  .ant-tabs-tab {
    &:hover {
      color: ${primaryColor};
    }
  }
`;

export default ElectronicVoucher;
