import { handleResult, getAxios } from '@constants/Api';

export const requestNextCustomerByJob = payload => {
  const {
    job_id,
    uncontacted,
    customer_id,
    search,
    from_date,
    to_date,
    time_recall_status,
  } = payload;
  console.log('requestNextCustomerByJob', { payload });

  let searchString = '';
  console.log('search1111', search);
  if (search) {
    searchString = `&search=${search.search.trim()}&status=${
      search.status
    }&tag_id=${search.tag_id}&call_note=${
      search.call_note ? search.call_note.trim() : ''
    }&call_status_id=${
      search.call_status_id ? search.call_status_id : ''
    }&opportunity_status=${
      search.opportunity_status ? search.opportunity_status : ''
    }&from_date=${search.fromDate ? search.fromDate : ''}&to_date=${
      search.toDate ? search.toDate : ''
    }&from_date_job=${
      search.fromDateJob ? search.fromDateJob : ''
    }&to_date_job=${
      search.toDateJob ? search.toDateJob : ''
    }&time_recall_status=${
      search.time_recall_status || search.time_recall_status == 0
        ? search.time_recall_status
        : ''
    }`;
  }

  console.log('requestNextCustomerByJob', { searchString });

  return handleResult(
    getAxios.get(
      `job/customer/next?job_id=${job_id}&uncontacted=${uncontacted}&customer_id=${customer_id}${searchString}`
    )
  );
};
export const requestGetJobList = payload => {
  return handleResult(
    getAxios.get(
      `job/list?search=${payload.search.trim()}&page=${
        payload.page
      }&job_status=${payload.job_status ? payload.job_status : ''}&from_date=${
        payload.from_date ? payload.from_date : ''
      }&limit=${payload.limit || 12}`,
      payload
    )
  );
};

export const requestConfirmJob = payload => {
  return handleResult(getAxios.post(`job/confirm`, payload));
};

export const requestRefuseJob = payload => {
  return handleResult(getAxios.post(`job/refuse`, payload));
};

export const requestGetJobDetail = payload => {
  return handleResult(getAxios.get(`job/detail?id=${payload.id}`, payload));
};

export const requestGetJobCustomerList = payload => {
  console.log('payload:::', payload);
  return handleResult(
    getAxios.get(
      `job/customer?job_id=${payload.job_id}&page=${
        payload.page
      }&search=${payload.search.trim()}&status=${payload.status}&tag_id=${
        payload.tag_id
      }&call_note=${
        payload.call_note ? payload.call_note.trim() : ''
      }&call_status_id=${
        payload.call_status_id ? payload.call_status_id : ''
      }&opportunity_status=${
        payload.opportunity_status ? payload.opportunity_status : ''
      }&from_date=${payload.fromDate ? payload.fromDate : ''}&to_date=${
        payload.toDate ? payload.toDate : 0
      }&from_date_job=${
        payload.fromDateJob ? payload.fromDateJob : ''
      }&to_date_job=${
        payload.toDateJob ? payload.toDateJob : ''
      }&time_recall_status=${payload.time_recall_status ? 1 : 0}
      `,
      payload
    )
  );
};

export const requestSubmitExam = payload => {
  return handleResult(getAxios.post(`job/submitExam`, payload));
};
