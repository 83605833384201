import { FileImageOutlined } from '@ant-design/icons';
import { Button, Form, Row, Space } from 'antd';
import domtoimage from 'dom-to-image';
import QRCode from 'qrcode.react';
import React from 'react';
import { triggerBase64Download } from 'react-base64-downloader';
import styled from 'styled-components';
import { hooks } from '../../store';
import UploadComponent from '../../utils/UploadFile';

function imageSize(url) {
  const img = document.createElement('img');

  const promise = new Promise((resolve, reject) => {
    img.onload = () => {
      // Natural size is the actual image size regardless of rendering.
      // The 'normal' `width`/`height` are for the **rendered** size.
      const width = img.naturalWidth;
      const height = img.naturalHeight;

      // Resolve promise with the width and height
      resolve({ width, height });
    };

    // Reject promise on error
    img.onerror = reject;
  });

  // Setting the source makes it start downloading and eventually call `onload`
  img.src = url;

  return promise;
}

const VoucherTemplate = React.memo(({ refProps, hori, children }) => {
  return hori ? (
    <VoucherExHori ref={refProps}>{children}</VoucherExHori>
  ) : (
    <VoucherExVer ref={refProps}>{children}</VoucherExVer>
  );
});

const LINK_VOUCHER_CANVA_DOC =
  'https://www.canva.com/design/DAFYAUniTrU/i5JO68j2cHg56kRmkaIsQQ/view?utm_content=DAFYAUniTrU&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview';
const LINK_VOUCHER_CANVA_NGANG =
  'https://www.canva.com/design/DAFYAJJ8rhc/qi2Kvf9tl6-8urtJXWprVQ/view?utm_content=DAFYAJJ8rhc&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview';

const LINK_IMAGE_DOC =
  'https://res.cloudinary.com/hunre/image/upload/v1675043137/windsoft/Ba%CC%89n_sao_cu%CC%89a_Voucher-do%CC%A3c-840x1480_nix0gm.png';
const LINK_IMAGE_NGANG =
  'https://res.cloudinary.com/hunre/image/upload/v1675043138/windsoft/Ba%CC%89n_sao_cu%CC%89a_Voucher-Ngang-1920x1080_ziannc.png';

const QRTemplate = React.memo(({ hori, children }) => {
  return hori ? (
    <ContainerQRCodeHoriStyled>{children}</ContainerQRCodeHoriStyled>
  ) : (
    <ContainerQRCodeVerStyled>{children}</ContainerQRCodeVerStyled>
  );
});

const TemplateVoucher = ({ form }) => {
  // file template voucher
  // const [fileVoucher, setFileVoucher] = React.useState(null);
  const template_voucher = Form.useWatch('template_voucher', form);

  const [exEvoucherSelect, setExEvoucherSelect] = React.useState(null);
  const [exEvouchers, setExEvouchers] = React.useState([
    {
      banner: LINK_IMAGE_DOC,
    },
    {
      banner: LINK_IMAGE_NGANG,
    },
  ]);

  const [demissionsFile, setDemissionsFile] = React.useState({
    height: 0,
    width: 0,
  });

  const [isExport, setIsExport] = React.useState(false);

  const containerImageVoucherRef = React.useRef(null);
  const [state, dispatch] = hooks.usePromotionsStore();

  React.useEffect(() => {
    if (!state?.detail_evoucher) return;
    (async () => {
      form.setFieldsValue({
        template_voucher: state?.detail_evoucher?.e_voucher_image,
      });
      const imageDimensions = await imageSize(
        state?.detail_evoucher?.e_voucher_image
      );
      setDemissionsFile({
        width: imageDimensions.width,
        height: imageDimensions.height,
      });
    })();
  }, [state?.detail_evoucher]);
  return (
    <ContainerBoxVoucher>
      <div className="condition_voucher">
        <h5 className="title_condition_voucher">Mẫu voucher</h5>
        <Row align="middle" justify="space-between">
          <Space align="center">
            {exEvouchers?.map((item, index) => {
              return (
                <ContainerImage
                  style={{
                    borderColor:
                      exEvoucherSelect === index ? '#f37920' : '#ccc',
                  }}
                  key={index}
                >
                  <RemoveVoucherStyled
                    onClick={() => {
                      setExEvouchers(
                        exEvouchers.filter(
                          (item, indexItem) => indexItem !== index
                        )
                      );
                      setExEvoucherSelect(null);
                    }}
                  >
                    X
                  </RemoveVoucherStyled>
                  <img
                    onClick={({ target }) => {
                      setDemissionsFile({
                        width: target.width,
                        height: target.height,
                      });
                      form.setFieldsValue({
                        template_voucher: item?.banner,
                      });
                      setExEvoucherSelect(index);
                    }}
                    src={
                      typeof item?.banner === 'string'
                        ? item?.banner
                        : URL.createObjectURL(item?.banner)
                    }
                    alt="voucher_doc"
                    style={{ display: 'none' }}
                    className="img_voucher_current"
                  />
                  <img
                    onClick={({ target }) => {
                      const findImage = document.getElementsByClassName(
                        'img_voucher_current'
                      );

                      setDemissionsFile({
                        width: findImage[index]?.width,
                        height: findImage[index]?.height,
                      });
                      form.setFieldsValue({
                        template_voucher: item?.banner,
                      });
                      // setFileVoucher(item?.banner);
                      setExEvoucherSelect(index);
                    }}
                    src={
                      typeof item?.banner === 'string'
                        ? item?.banner
                        : URL.createObjectURL(item?.banner)
                    }
                    alt="voucher_doc"
                    className="img_voucher"
                  />
                </ContainerImage>
              );
            })}
            <Row align="center" style={{ height: '100%' }}>
              <UploadComponent
                listType="picture-card"
                // isUploadServerWhenUploading
                onSuccessUpload={value => {
                  if (value) {
                    setExEvouchers([
                      ...exEvouchers,
                      { banner: value?.originFileObj },
                    ]);
                  }
                }}
                accept=".png, .jpg"
              >
                +
              </UploadComponent>
            </Row>
          </Space>
          {template_voucher && (
            <div>
              <ExportImageStyled
                onClick={async () => {
                  setIsExport(true);
                  domtoimage
                    .toPng(containerImageVoucherRef.current, {
                      bgcolor: 'white',
                    })
                    .then(function (dataUrl) {
                      if (dataUrl) {
                        triggerBase64Download(dataUrl, 'voucher_example');
                      }
                    })
                    .catch(function (error) {
                      console.error('oops, something went wrong!', error);
                    })
                    .finally(() => {
                      setIsExport(false);
                    });
                }}
              >
                <FileImageOutlined />
                <span>Tạo ảnh</span>
              </ExportImageStyled>
            </div>
          )}
          <Row gutter={[20, 20]}>
            <BoxEvoucherCanva
              onClick={() => {
                window.open(
                  LINK_VOUCHER_CANVA_DOC,
                  'Mẫu Evoucher EZsale',
                  'fullscreen=yes'
                );
              }}
            >
              <img
                src="https://res.cloudinary.com/hunre/image/upload/v1675043137/windsoft/Ba%CC%89n_sao_cu%CC%89a_Voucher-do%CC%A3c-840x1480_nix0gm.png"
                alt="doc"
              />
              <div>Mẫu dọc</div>
            </BoxEvoucherCanva>
            <BoxEvoucherCanva
              onClick={() => {
                window.open(
                  LINK_VOUCHER_CANVA_NGANG,
                  'Mẫu Evoucher EZsale',
                  'fullscreen=yes'
                );
              }}
            >
              <img
                src="https://res.cloudinary.com/hunre/image/upload/v1675043138/windsoft/Ba%CC%89n_sao_cu%CC%89a_Voucher-Ngang-1920x1080_ziannc.png"
                alt="ngang"
              />
              <div>Mẫu ngang</div>
            </BoxEvoucherCanva>
          </Row>
        </Row>
        <TextNoteVoucher>
          * Hình ảnh phía dưới sẽ là voucher được tạo ra kèm QRcode *
        </TextNoteVoucher>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <VoucherTemplate
            refProps={containerImageVoucherRef}
            hori={demissionsFile.width > demissionsFile.height}
          >
            {template_voucher ? (
              <div>
                {!isExport && (
                  <ClearVoucherStyled
                    onClick={() => {
                      form.setFieldsValue({
                        template_voucher: null,
                      });
                      setExEvoucherSelect(null);
                    }}
                  >
                    X
                  </ClearVoucherStyled>
                )}

                <img
                  src={
                    typeof template_voucher === 'string'
                      ? template_voucher
                      : URL.createObjectURL(template_voucher)
                  }
                  alt="image_voucher"
                />
                <QRTemplate hori={demissionsFile.width > demissionsFile.height}>
                  <BoxQRcodeStyled>
                    <QRCode
                      size={180}
                      imageSettings="H"
                      value="Mẫu voucher"
                      renderAs="canvas"
                    />
                    <CodeVoucherStyled>----------</CodeVoucherStyled>
                  </BoxQRcodeStyled>
                </QRTemplate>
              </div>
            ) : (
              <span style={{ color: '#ccc', fontWeight: '600' }}>
                Mẫu voucher
              </span>
            )}
          </VoucherTemplate>
        </div>
      </div>
      {/* <TemplateVoucherExport
        hori={false}
        imgUrl="https://res.cloudinary.com/hunre/image/upload/v1675043137/windsoft/Ba%CC%89n_sao_cu%CC%89a_Voucher-do%CC%A3c-840x1480_nix0gm.png"
        valueQR="12323132"
      /> */}
    </ContainerBoxVoucher>
  );
};

export default TemplateVoucher;

const ClearVoucherStyled = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 6px 12px;
  border-radius: 999px;
  background-color: #dc2514;
  color: #fff;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    opacity: 0.8;
  }
`;

const RemoveVoucherStyled = styled.div`
  position: absolute;
  top: 1px;
  right: 1px;
  padding: 2.5px 7px;
  border-radius: 999px;
  background-color: #dc2514;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  z-index: 99;
  font-size: 10px;

  &:hover {
    opacity: 0.8;
  }
`;

const ContainerBoxVoucher = styled.div`
  width: 100%;
  /* box condition voucher */
  & .condition_voucher {
    position: relative;
    border: 1px solid #ccc;
    padding: 20px;
    margin-top: 30px;
  }

  & .title_condition_voucher {
    position: absolute;
    background: #fff;
    top: -13px;
    padding: 0 20px;
    left: 10px;
  }
`;

const ButtonStyled = styled(Button)`
  margin-top: 20px;
  display: flex;
  align-items: center;
  border-radius: 6px;
`;

const VoucherExHori = styled.div`
  width: 960px;
  height: 540px;
  border: 1px dashed #ccc;
  /* border-radius: 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & img {
    /* border-radius: 20px; */
    width: 960px;
    height: 540px;
    object-fit: cover;
  }
`;

const VoucherExVer = styled.div`
  width: 420px;
  height: 740px;
  border: 1px dashed #ccc;
  /* border-radius: 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & img {
    /* border-radius: 20px; */
    width: 420px;
    height: 740px;
    object-fit: cover;
  }
`;

const ContainerQRCodeHoriStyled = styled.div`
  position: absolute;
  right: 43px;
  top: 51.6%;
  transform: translateY(-50%);
  height: 343px;
  width: 330px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContainerQRCodeVerStyled = styled.div`
  position: absolute;
  left: 50%;
  top: 61.5%;
  transform: translate(-50%, -50%);
  height: 343px;
  width: 330px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BoxQRcodeStyled = styled.div`
  padding: 10px;
`;

const CodeVoucherStyled = styled.div`
  background: #fff;
  border: 1px dashed #ccc;
  border-radius: 6px;
  padding: 4px 0;
  font-weight: bold;
  margin-top: 16px;
`;

const ContainerImage = styled.div`
  padding: 6px;
  border: 1px dashed #ccc;
  border-radius: 6px;
  margin: 10px 0;
  position: relative;

  &:hover {
    cursor: pointer;

    & .img_voucher {
      scale: 1.1;
    }
  }

  overflow: hidden;
  width: 80px;
  height: 80px;

  & .img_voucher {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

const ExportImageStyled = styled.div`
  border: 1px dashed #ccc;
  padding: 10px 12px;
  border-radius: 6px;
  cursor: pointer;

  transition: all 0.3s;

  &:hover {
    border-color: #f37920;

    color: #f37920;
  }

  & span {
    margin-top: 6px;
    display: block;
    font-size: 12px;
  }
`;

const BoxEvoucherCanva = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
  height: 66px;
  width: 90px;
  border: 1px dashed #ccc;
  border-radius: 6px;
  margin-right: 8px;
  cursor: pointer;

  &:hover {
    border-color: #f37920;
  }

  & img {
    height: 36px;
  }

  & div {
    font-size: 11px;
  }
`;

const TextNoteVoucher = styled.div`
  font-size: 12px;
  margin-bottom: 6px;

  color: crimson;
  font-style: italic;
`;
