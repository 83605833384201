import { requestChangePass } from '@constants/Api';
import { notifyFail, notifySuccess } from '@utils/notify';
import { Spin } from 'antd';
import React from 'react';
import { Button, Col, FormControl, Modal, Row, Table } from 'react-bootstrap';

const currentModal = {
  new_pass: '',
  old_pass: '',
  confirm_pass: '',
};

const checkAllAttrFilled = Obj => {
  for (var key in Obj) {
    if (Obj[key] || key === 'def_group_id') {
      return true;
    } else return false;
  }
  return true;
};

const ChangePasswordModal = ({ showModal, closeModal }) => {
  const [loading, setLoading] = React.useState(false);

  const [modal, setModal] = React.useState(currentModal);

  const validateForm = () => {
    if (!checkAllAttrFilled(modal)) {
      notifyFail('Không được bỏ trống thông tin');
      return false;
    }
    if (modal.new_pass.length < 6 || modal.old_pass.length < 6) {
      notifyFail('Mật khẩu mới phải có độ dài lớn hơn 6 ký tự');
      return false;
    }
    if (modal.new_pass !== modal.confirm_pass) {
      notifyFail('Mật khẩu mới không khớp');
      return false;
    }
    return true;
  };

  const changePassword = async () => {
    const check = validateForm();
    if (check) {
      try {
        setLoading(true);
        await requestChangePass(modal);
        setLoading(false);

        notifySuccess('Cập nhật mật khẩu thành công');
        setModal(currentModal);
        closeModal();
      } catch (error) {
        setLoading(false);
        notifyFail(error.msg);
      }
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={() => closeModal()}
      dialogClassName="modal-90w"
      centered
    >
      <Spin spinning={loading}>
        <Modal.Header closeButton className="bg-blue pb-0">
          <h5 className="text-white">Thay đổi mật khẩu</h5>
        </Modal.Header>

        <Modal.Body className="custom-body mt-3">
          <Row className="mb-3 align-items-center">
            <Col className="modal-field" sm={5}>
              <label>Nhập mật khẩu hiện tại</label>
            </Col>
            <Col sm={7}>
              <FormControl
                type="password"
                placeholder="Nhập mật khẩu hiện tại"
                onChange={e => {
                  setModal({ ...modal, old_pass: e.target.value });
                }}
                value={modal.old_pass}
              ></FormControl>
            </Col>
          </Row>

          <Row className="mb-3 align-items-center">
            <Col className="modal-field" sm={5}>
              <label>Nhập mật khẩu mới</label>
            </Col>
            <Col sm={7}>
              <FormControl
                type="password"
                placeholder="Nhập mật khẩu mới"
                onChange={e => {
                  setModal({ ...modal, new_pass: e.target.value });
                }}
                value={modal.new_pass}
              ></FormControl>
            </Col>
          </Row>

          <Row className="mb-3 align-items-center">
            <Col className="modal-field" sm={5}>
              <label>Xác nhận mật khẩu mới</label>
            </Col>
            <Col sm={7}>
              <FormControl
                type="password"
                placeholder="Xác nhận mật khẩu mới"
                onChange={e => {
                  setModal({ ...modal, confirm_pass: e.target.value });
                }}
                value={modal.confirm_pass}
              ></FormControl>
            </Col>
          </Row>
          <Row
            sm={4}
            style={{ justifyContent: 'center', marginLeft: 30 }}
            className="mt-5"
          >
            <Button
              variant="success"
              className="text-white"
              onClick={changePassword}
            >
              Lưu
            </Button>
          </Row>
        </Modal.Body>
      </Spin>
    </Modal>
  );
};

export default React.memo(ChangePasswordModal);
