import { ROUTER } from '@constants/Constant';
import { randomColor } from '@constants/funcHelper';
import { Avatar, Row, Tooltip } from 'antd';
import React from 'react';
import zalo_icon from '@assets/iconzalo.svg';
import CallStatus from '../CallStatus';
import moment from 'moment';
import ModalMessageZalo from '../components/ModalMessageZalo';
import TagView from '../components/TagView';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import CallUtil from '@components/callProviders/CallUtil';
import BreadcrumbCalling from './Breadcrumb';

const UserOutlined = () => (
  <svg
    width="40px"
    height="40px"
    fill="white"
    viewBox="0 0 1024 1024"
    style={{ margin: '0 0 13px 0' }}
  >
    <path d="M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z" />
  </svg>
);

const NAME_CUSTOMER_STYLE = {
  fontSize: 16,
  fontWeight: 700,
  cursor: 'pointer',
  color: '#ef8737',
};

const CALL_STATUS_STYLE = {
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
};

const PageheaderLeft = ({
  customerDetail,
  isCalled,
  isShowCallbackSchedual,
  callDetail,
  getDetailInteractionHistory,
  job_id,
}) => {
  const history = useHistory();
  const name = customerDetail?.name
    ? customerDetail?.name.trim()?.split(' ')
    : '';
  const [isShowModalMessageZalo, setShowModalMessageZalo] =
    React.useState(false);

  return (
    <div>
      {/* button navigation khi gọi từ công việc */}
      {CallUtil.currentJobId || job_id ? (
        <BreadcrumbCalling job_id={job_id} />
      ) : (
        <></>
      )}
      <Row align="middle">
        <Avatar
          style={{
            backgroundColor: randomColor(
              name ? name[name?.length - 1].charAt(0)?.toUpperCase() : ''
            ),
            marginRight: '5px',
          }}
          size={60}
          icon={name ? null : <UserOutlined />}
        >
          <div style={{ fontSize: 32 }}>
            {name ? name[name?.length - 1].charAt(0)?.toUpperCase() : ''}
          </div>
        </Avatar>
        <div>
          <Row align="middle">
            <span
              onClick={() => {
                history.replace({
                  pathname: `${ROUTER.CUSTOMER_DETAIL_SALE}/${customerDetail.id}`,
                });
              }}
            >
              Gọi đi:{' '}
              <span style={NAME_CUSTOMER_STYLE}>
                {customerDetail?.name ? (
                  customerDetail?.name.length > 30 ? (
                    <Tooltip title={customerDetail?.name}>
                      {customerDetail?.name
                        ? customerDetail?.name.slice(0, 30) + '...'
                        : ''}
                    </Tooltip>
                  ) : (
                    customerDetail?.name
                  )
                ) : (
                  'Khách hàng mới'
                )}
              </span>
            </span>
            <ZaloIconStyled
              src={zalo_icon}
              alt="zalo"
              style={{ marginLeft: '10px' }}
              width={30}
              height={30}
              onClick={() => setShowModalMessageZalo(!isShowModalMessageZalo)}
            />
          </Row>

          <div style={CALL_STATUS_STYLE}>
            {/* <div>
          {moment(customerDetail?.created_at).format('hh:mm A')} -{' '}
          {moment(customerDetail?.created_at).format(
            'DD/MM/YYYY'
          )}
        </div> */}
            {isCalled && (
              <CallStatus
                status={2}
                timeCalled={moment(isCalled).format('DD/MM/YYYY HH:mm')}
              />
            )}
            {isShowCallbackSchedual && (
              <CallStatus
                status={3}
                timeCalled={moment(isShowCallbackSchedual).format(
                  'DD/MM/YYYY HH:mm'
                )}
              />
            )}
          </div>
        </div>
        {isShowModalMessageZalo && (
          <ModalMessageZalo
            isVisible={isShowModalMessageZalo}
            setShowModalMessageZalo={setShowModalMessageZalo}
            customerDetail={customerDetail}
            callDetail={callDetail}
          />
        )}
      </Row>
      <Row align="middle" style={{ padding: '15px 0 0 0' }}>
        <TagView
          onGetDetailInteractionHistory={() =>
            getDetailInteractionHistory(customerDetail.id)
          }
        />
      </Row>
    </div>
  );
};

const ZaloIconStyled = styled.img`
  cursor: pointer;
  &:hover {
    scale: 1.2;
  }
`;

export default React.memo(PageheaderLeft);
