import React from 'react';
import { Descriptions, Divider, Tooltip } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { VietnameseString } from '@constants/VietnameseString';
import { formatPrice } from '@constants/funcHelper';

import { IframeStyled, OneLineTextStyled } from '@styles/Style';

const TemplateContainer = styled.div`
  border: 1px solid steelblue;
  border-radius: 5px;
  padding: 10px;

  display: flex;
  flex-direction: column;
  height: 600px;
`;

const DescriptionsStyled = styled(Descriptions)`
  .ant-descriptions-item {
    padding-bottom: 8px;
    text-align: start;

    span.ant-descriptions-item-label {
      font-weight: 700;
      width: 30%;
    }
  }
`;

const IframeContainer = styled.div`
  flex: 1;
`;

const renderStatus = (status) => {
  if (status) {
    return <div style={{ color: 'green' }}>{VietnameseString.activated}</div>;
  } else {
    return <div style={{ color: 'tomato' }}>{VietnameseString.not_activated}</div>;
  }
};

function TemplateItem(props) {
  const { data, onClick } = props;

  return (
    <TemplateContainer onClick={onClick}>
      <DescriptionsStyled column={1}>
        <Descriptions.Item label={VietnameseString.name}>
          <Tooltip placement="bottom" title={data.template_name}>
            <OneLineTextStyled>{data.template_name}</OneLineTextStyled>
          </Tooltip>
        </Descriptions.Item>
        <Descriptions.Item label={VietnameseString.id}>{data.template_id}</Descriptions.Item>
        <Descriptions.Item label={VietnameseString.status}>{renderStatus(data.status)}</Descriptions.Item>
        <Descriptions.Item label={VietnameseString.price}>
          {data.status ? `${formatPrice(data.price)} đ` : data.price}
        </Descriptions.Item>
      </DescriptionsStyled>

      <Divider style={{ backgroundColor: 'steelblue' }} />

      <IframeContainer>
        <IframeStyled title={data.template_name} src={data.preview_url} />
      </IframeContainer>
    </TemplateContainer>
  );
}

TemplateItem.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default TemplateItem;
