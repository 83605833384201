import { formatPrice } from '@constants/funcHelper';
import { Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';

const StatisticsStyle = styled.span`
  font-weight: 700;
  color: ${(props) => {
    if (props.current - props.before > 0) return 'green';
    if (props.current - props.before < 0) return 'red';
    return 'blue';
  }};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const renderStatistics = (record, type, field) => {
  const render = (ratio, type, record, field) => {
    if (ratio > 0) {
      if (type === 'contract_reports') {
        return (
          <>
            Cùng kỳ: {formatPrice(record?.[type][field.code + '_before'])} đ - Tăng: {ratio.toFixed(2)}%
          </>
        );
      } else {
        return (
          <>
            Cùng kỳ: {record?.[type][field.code + '_before']} - Tăng: {ratio.toFixed(2)}%
          </>
        );
      }
    } else if (ratio < 0) {
      if (type === 'contract_reports') {
        return (
          <>
            Cùng kỳ: {formatPrice(record?.[type][field.code + '_before'])} đ - Giảm: {Math.abs(ratio).toFixed(2)}%
          </>
        );
      } else {
        return (
          <>
            Cùng kỳ: {record?.[type][field.code + '_before']} - Giảm: {Math.abs(ratio).toFixed(2)}%
          </>
        );
      }
    } else {
      return <>Không tăng (so với cùng kỳ)</>;
    }
  };

  try {
    let ratio = 0;

    if (parseInt(record?.[type][field.code + '_before']) !== 0) {
      ratio =
        ((parseInt(record?.[type][field.code]) - parseInt(record?.[type][field.code + '_before'])) /
          parseInt(record?.[type][field.code + '_before'])) *
        100;

      return (
        <Tooltip placement="bottom" title={render(ratio, type, record, field)}>
          <StatisticsStyle current={record[type][field.code]} before={record[type][field.code + '_before']}>
            <div style={{ marginRight: '3px' }}>
              {type === 'contract_reports' ? `${formatPrice(record[type][field.code])} đ` : record[type][field.code]}
            </div>

            {ratio < 0 && <i className="fas fa-caret-down"></i>}
            {ratio > 0 && <i className="fas fa-caret-up"></i>}
            {ratio === 0 && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <i className="fas fa-caret-up" style={{ height: '8px' }}></i>
                <i className="fas fa-caret-down"></i>
              </div>
            )}
          </StatisticsStyle>
        </Tooltip>
      );
    } else {
      ratio = parseInt(record?.[type][field.code]) - parseInt(record?.[type][field.code + '_before']);

      return (
        <StatisticsStyle current={record[type][field.code]} before={record[type][field.code + '_before']}>
          <div style={{ marginRight: '3px' }}>
            {type === 'contract_reports' ? `${formatPrice(record[type][field.code])} đ` : record[type][field.code]}
          </div>
          {ratio < 0 && <i className="fas fa-caret-down"></i>}
          {ratio > 0 && <i className="fas fa-caret-up"></i>}
          {ratio === 0 && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <i className="fas fa-caret-up" style={{ height: '8px' }}></i>
              <i className="fas fa-caret-down"></i>
            </div>
          )}
        </StatisticsStyle>
      );
    }
  } catch (error) {}
};

export const renderStatistics2 = (record) => {
  try {
    let ratio = 0;

    if (parseInt(record?.total_before) !== 0) {
      ratio = ((parseInt(record?.total) - parseInt(record?.total_before)) / parseInt(record?.total_before)) * 100;

      return (
        <Tooltip
          placement="bottom"
          title={
            ratio > 0
              ? `Cùng kỳ: ${record?.total_before} - Tăng: ${ratio.toFixed(2)}%`
              : ratio < 0
              ? `Cùng kỳ: ${record?.total_before} - Giảm: ${Math.abs(ratio).toFixed(2)}%`
              : 'Không tăng (so với cùng kỳ)'
          }
        >
          <StatisticsStyle current={record?.total} before={record?.total_before}>
            <div style={{ marginRight: '3px' }}>{record?.total}</div>
            {ratio < 0 && <i className="fas fa-caret-down"></i>}
            {ratio > 0 && <i className="fas fa-caret-up"></i>}
            {ratio === 0 && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <i className="fas fa-caret-up" style={{ height: '8px' }}></i>
                <i className="fas fa-caret-down"></i>
              </div>
            )}
          </StatisticsStyle>
        </Tooltip>
      );
    } else {
      ratio = parseInt(record?.total) - parseInt(record?.total_before);

      return (
        <StatisticsStyle current={record?.total} before={record?.total_before}>
          <div style={{ marginRight: '3px' }}>{record?.total}</div>
          {ratio < 0 && <i className="fas fa-caret-down"></i>}
          {ratio > 0 && <i className="fas fa-caret-up"></i>}
          {ratio === 0 && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <i className="fas fa-caret-up" style={{ height: '8px' }}></i>
              <i className="fas fa-caret-down"></i>
            </div>
          )}
        </StatisticsStyle>
      );
    }
  } catch (error) {}
};

export const renderStatistics3 = (record, type, field) => {
  const render = (ratio, type, record, field) => {
    if (ratio > 0) {
      if (type === 'contract_report') {
        return (
          <>
            Cùng kỳ: {formatPrice(record?.[type][field + '_before'])} đ - Tăng: {ratio.toFixed(2)}%
          </>
        );
      } else {
        return (
          <>
            Cùng kỳ: {record?.[type][field + '_before']} - Tăng: {ratio.toFixed(2)}%
          </>
        );
      }
    } else if (ratio < 0) {
      if (type === 'contract_report') {
        return (
          <>
            Cùng kỳ: {formatPrice(record?.[type][field + '_before'])} đ - Giảm: {Math.abs(ratio).toFixed(2)}%
          </>
        );
      } else {
        return (
          <>
            Cùng kỳ: {record?.[type][field + '_before']} - Giảm: {Math.abs(ratio).toFixed(2)}%
          </>
        );
      }
    } else {
      return <>Không tăng (so với cùng kỳ)</>;
    }
  };
  try {
    let ratio = 0;

    if (parseInt(record?.[type][field + '_before']) !== 0) {
      ratio =
        ((parseInt(record?.[type][field]) - parseInt(record?.[type][field + '_before'])) /
          parseInt(record?.[type][field + '_before'])) *
        100;

      return (
        <Tooltip placement="bottom" title={render(ratio, type, record, field)}>
          <StatisticsStyle current={record[type][field]} before={record[type][field + '_before']}>
            <div style={{ marginRight: '3px' }}>
              {type === 'contract_report' && record[type][field] !== null
                ? `${formatPrice(record[type][field])} đ`
                : record[type][field]}
            </div>

            {ratio < 0 && <i className="fas fa-caret-down"></i>}
            {ratio > 0 && <i className="fas fa-caret-up"></i>}
            {ratio === 0 && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <i className="fas fa-caret-up" style={{ height: '8px' }}></i>
                <i className="fas fa-caret-down"></i>
              </div>
            )}
          </StatisticsStyle>
        </Tooltip>
      );
    } else {
      ratio = parseInt(record?.[type][field]) - parseInt(record?.[type][field + '_before']);

      return (
        <StatisticsStyle current={record[type][field]} before={record[type][field + '_before']}>
          <div style={{ marginRight: '3px' }}>
            {type === 'contract_report' && record[type][field] !== null
              ? `${formatPrice(record[type][field])} đ`
              : record[type][field]}
          </div>
          {ratio < 0 && <i className="fas fa-caret-down"></i>}
          {ratio > 0 && <i className="fas fa-caret-up"></i>}
          {ratio === 0 && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <i className="fas fa-caret-up" style={{ height: '8px' }}></i>
              <i className="fas fa-caret-down"></i>
            </div>
          )}
        </StatisticsStyle>
      );
    }
  } catch (error) {}
};


