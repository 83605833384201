import { ADD_PHONE_NUMBER_PICKED } from '@actions/type';

const initialState = {
  data: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_PHONE_NUMBER_PICKED:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
}
