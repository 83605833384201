import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TableView } from '@components/ui/table';
import { Card } from '@components/ui/card';
import { Sizing } from '@constants/Theme';
export default class CustomerCallLogList extends Component {
  static propTypes = {
    prop: PropTypes,
  };

  constructor(props) {
    super(props);

    this.columns = [
      { title: 'STT', width: Sizing.tableColumnNoWidth },
      { title: 'Công việc' },
      { title: 'Trạng thái cuộc gọi' },
      { title: 'Thời gian' },
      { title: 'Nhân viên' },
    ];
    this.state = {
      dataSource: [],
    };
  }

  render() {
    const { dataSource } = this.state;
    const { columns } = this;

    return <TableView dataSource={dataSource} columns={columns} />;
  }
}
