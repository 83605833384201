import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { formatPrice } from '@constants/funcHelper';

const ExpandedContent = styled('ul')`
  li {
    list-style-type: none;
  }

  p {
    margin: 8px 0;
  }
`;

const SubContent = styled('div')`
  margin-left: 30px;
`;

function DiscountCodeDetail({ data, isValidVoucher = true }) {
  const voucherData = data?.data_e_voucher;
  const voucherConfig = data?.e_voucher_config;

  const renderProductCondition = () =>
    voucherConfig?.condition_product.map(prod => {
      return (
        <div key={prod.key_id} className="d-flex" style={{ gap: '10%' }}>
          <b>- {prod?.name ? prod?.name : `${prod.product_id}`}</b>
          <span>Số lượng: {prod.amount || 0}</span>
          <span>Thành tiền: {formatPrice(prod.money) || 0}</span>
        </div>
      );
    });

  const renderTimeCondition = () =>
    voucherConfig?.condition_time.map(item => {
      return (
        <div key={item.key_id}>
          <p>
            Từ <b>{item.start_at}</b> đến <b>{item.finish_at}</b>
          </p>
        </div>
      );
    });

  const renderGifts = () => {
    const giftList = voucherConfig?.e_voucher_customer_gifts.map(
      gift => gift.e_voucher_gift_name
    );
    return giftList.join(', ');
  };

  const renderExchangeGifts = () => {
    const exchangeGiftList = voucherConfig?.e_voucher_converted_gifts.map(
      gift => gift.product_name
    );

    return exchangeGiftList.join(', ');
  };

  const renderTotalDiscountValue = () => {
    return voucherConfig?.type_discount_money === 1
      ? formatPrice(voucherConfig?.discount_money)
      : `${voucherConfig?.discount_money}%`;
  };

  const renderProductDiscountValue = () => {
    return voucherConfig?.type_discount_product === 1
      ? formatPrice(voucherConfig?.discount_product)
      : `${voucherConfig?.discount_product}%`;
  };

  const renderSavePoint = () => {
    return voucherConfig?.type_save_point === 1
      ? `Tích điểm: ${formatPrice(voucherConfig?.save_point)} điểm`
      : `Tích điểm: ${formatPrice(
          voucherConfig?.save_point
        )}% theo tổng tiền đơn hàng`;
  };

  const renderGiftPoint = () => {
    return formatPrice(voucherConfig?.recommend_gift?.point);
  };

  return (
    <ExpandedContent>
      <li>
        <p>
          <b>Mã giảm giá:</b> {voucherData?.e_voucher_code}
        </p>
      </li>

      <li className="d-flex" style={{ gap: '14%' }}>
        <p>
          <b>Tên chương trình: </b> <span>{voucherConfig?.name}</span>
        </p>
        <p>
          <b>Mã chương trình:</b> <span>{voucherConfig?.code}</span>
        </p>
      </li>

      <li>
        <p>
          <b>Thời gian áp dụng:</b>
          <span>
            Từ ngày {moment(voucherConfig?.start_at).format('DD/MM/YYYY')}
          </span>{' '}
          <span>
            {voucherConfig?.finish_at
              ? `đến ngày ${moment(voucherConfig?.finish_at).format(
                  'DD/MM/YYYY'
                )}`
              : ''}
          </span>
        </p>
      </li>

      <li>
        <p>
          <b>Điều kiện áp dụng:</b>
        </p>
        <SubContent>
          {!!voucherConfig?.condition_total_money && (
            <p>
              Tổng số tiền đơn hàng:{' '}
              <span>
                {formatPrice(voucherConfig?.condition_total_money)} VNĐ
              </span>
            </p>
          )}
          <div>
            {voucherConfig?.condition_product && (
              <div>
                <p>Sản phẩm:</p>
                <SubContent>{renderProductCondition()}</SubContent>
              </div>
            )}
          </div>
          <div>
            {voucherConfig?.condition_time && (
              <>
                <p>Thời gian mua hàng:</p>
                <SubContent>{renderTimeCondition()}</SubContent>
              </>
            )}
            {voucherConfig?.condition_other && (
              <p>Điều kiện khác: {voucherConfig?.condition_other}</p>
            )}
          </div>
        </SubContent>
      </li>

      <li>
        <p>
          <b>Mã giảm giá:</b>
        </p>
        <SubContent>
          {voucherConfig?.discount_money && (
            <p>
              Giảm giá <b>{renderTotalDiscountValue()}</b> theo tổng tiền
            </p>
          )}
          {voucherConfig?.discount_product && (
            <p>
              Giảm giá <b>{renderProductDiscountValue()}</b> theo sản phẩm
            </p>
          )}
          {voucherConfig?.e_voucher_customer_gifts.length > 0 && (
            <p>Tặng quà: {renderGifts()}</p>
          )}
          {voucherConfig?.e_voucher_converted_gifts.length > 0 && (
            <p>Đổi quà: {renderExchangeGifts()}</p>
          )}
          {voucherConfig?.save_point && <p>{renderSavePoint()}</p>}
          {voucherConfig?.recommend_gift?.point && (
            <p>Tặng điểm cho người giới thiệu: {renderGiftPoint()} điểm</p>
          )}
          {voucherConfig.convert_point?.point && (
            <p>
              Quy đổi điểm:{' '}
              {`${formatPrice(
                voucherConfig?.convert_point?.point
              )} điểm = ${formatPrice(
                voucherConfig?.convert_point?.money
              )} VNĐ`}
            </p>
          )}
        </SubContent>
      </li>

      <li>
        {data?.recommend_customer && (
          <p>
            <b>Người giới thiệu:</b> {data?.recommend_customer?.name} -{' '}
            {data?.recommend_customer?.phone}
          </p>
        )}
      </li>

      <li>
        <b>Tổng thành tiền của cơ hội: </b>
        <SubContent>
          <p>
            <span style={{ display: 'inline-block', minWidth: '86px' }}>
              Tổng tiền:
            </span>{' '}
            <span>{formatPrice(voucherData?.pre_total_price)} VNĐ</span>
          </p>
          {isValidVoucher && (
            <p>
              <span style={{ display: 'inline-block', minWidth: '86px' }}>
                Mã giảm giá:{' '}
              </span>
              <b style={{ color: 'red' }}>
                -{formatPrice(voucherData?.total_discount)} VNĐ
              </b>
            </p>
          )}
        </SubContent>
        {isValidVoucher && (
          <p>
            <b>Tổng thanh toán:</b>{' '}
            <b style={{ color: 'orange' }}>
              {formatPrice(voucherData?.total_price)} VNĐ
            </b>
          </p>
        )}
      </li>
    </ExpandedContent>
  );
}

export default DiscountCodeDetail;
