import React from 'react';

import { Checkbox, DatePicker, Form, Input, Row } from 'antd';
import moment from 'moment';

import { VietnameseString } from '@constants/VietnameseString';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ColStyled } from '../../ElectronicVoucherStyled';
import { voucherService } from '../../service';
import { hooks } from '../../store';
import TotalPriceOrder from './componentsGeneral/TotalPriceOrder';
const { RangePicker } = DatePicker;
function General({ options, form }) {
  const [state, dispatch] = hooks.usePromotionsStore();

  const { conditionApply } = options;
  const applyTime = Form.useWatch('applyTime', form);
  const { id } = useParams();
  // const [state, dispatch] = hooks.usePromotionsStore();

  return (
    <GeneralContainerStyled>
      {/* thông tin chung */}
      <div className="general_voucher">
        <h5 className="title_general_voucher">Thông tin chung</h5>
        <Form.Item
          label={VietnameseString.promotions_code}
          name="code"
          rules={[
            { required: true, message: 'Vui lòng nhập mã chương trình' },
            {
              validator: (_, value) => {
                if (/\s/g.test(value)) {
                  return Promise.reject(
                    new Error('Mã chương trình không được chứa khoảng trắng!')
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
          normalize={value => value?.replace(/^\s+|\s+$|\s+(?=\s)/g, '')}
        >
          <Input
            disabled={id}
            onBlur={e => {
              if (!e.target.value) return;

              voucherService
                .checkVoucherCode({ code: e.target.value })
                .then(res => {
                  if (res?.data?.is_existed) {
                    // validate code

                    form.setFields([
                      {
                        name: 'code',
                        errors: ['Mã chương trình đã tồn tại'],
                      },
                    ]);
                  }
                  return;
                });
            }}
            placeholder={VietnameseString.promotions_code}
          />
        </Form.Item>

        <Form.Item
          label={VietnameseString.promotions_name}
          name="name"
          rules={[
            { required: true, message: 'Vui lòng nhập tên chương trình' },
            () => ({
              validator(_, value) {
                if (!value) return Promise.resolve();
                if (value.startsWith(' ') || value.endsWith(' ')) {
                  return Promise.reject(
                    new Error(
                      'Không được bắt đầu hoặc kết thúc bằng khoảng trắng!'
                    )
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}
          // normalize={(value) => value.trim()}
        >
          <Input placeholder={VietnameseString.name_of_the_discount_program} />
        </Form.Item>

        <Form.Item
          label={VietnameseString.preferential_type}
          name="preferentialType"
          rules={[{ required: true, message: 'Vui lòng chọn loại ưu đãi' }]}
        >
          <Checkbox.Group style={{ width: '100%' }}>
            <Row gutter={[6, 6]}>
              <ColStyled span={24}>
                <Checkbox value="discountByTotalAmount">
                  {VietnameseString.discount_by_total_amount}
                </Checkbox>
              </ColStyled>

              <ColStyled span={24}>
                <Checkbox value="accumulatePoints">
                  {VietnameseString.accumulate_points}
                </Checkbox>
              </ColStyled>

              <ColStyled span={24}>
                <Checkbox value="discountByProduct">
                  {VietnameseString.discount_by_product}
                </Checkbox>
              </ColStyled>

              <ColStyled span={24}>
                <Checkbox value="giveAGift">
                  {VietnameseString.give_a_gift}
                </Checkbox>
              </ColStyled>
            </Row>
          </Checkbox.Group>
        </Form.Item>

        <Form.Item label={VietnameseString.apply_time} name="applyTime">
          <RangePicker
            defaultValue={[moment(), null]}
            disabledDate={current =>
              current && current < moment().subtract(1, 'days').endOf('day')
            }
            format="DD/MM/YYYY"
            style={{ width: '100%' }}
            value={applyTime}
            onCalendarChange={value => {
              form.setFieldsValue({
                applyTime: value,
              });

              // if (value[1]) {
              // dispatch({
              //   type: SET_CONDITION_PURCHASE_TIME_DATE,
              //   payload: [{ startDate: null, endDate: null }],
              // });
              // }
            }}
          />
        </Form.Item>
      </div>

      {/* Điều kiện áp dụng */}
      <div className="condition_voucher">
        <h5 className="title_condition_voucher required_custom">
          Điều kiện áp dụng
        </h5>
        <TotalPriceOrder conditionApply={conditionApply} form={form} />
      </div>
    </GeneralContainerStyled>
  );
}

const GeneralContainerStyled = styled.div`
  /* box config voucher */
  & .general_voucher {
    position: relative;
    border: 1px solid #ccc;
    padding: 20px;
  }

  & .title_general_voucher {
    position: absolute;
    background: #fff;
    top: -13px;
    padding: 0 20px;
    left: 10px;
  }
  & .condition_voucher {
    position: relative;
    border: 1px solid #ccc;
    padding: 20px;
    margin-top: 30px;
  }

  & .title_condition_voucher {
    position: absolute;
    background: #fff;
    top: -13px;
    padding: 0 20px;
    left: 10px;
  }

  & .title_condition_voucher.required_custom::after {
    display: inline-block;
    margin-right: 4px;
    margin-left: 6px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
`;

export default General;
