import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { saveAs } from 'file-saver';
import styled from 'styled-components';
import { Avatar, Breadcrumb, Col, Empty, Row, Tooltip } from 'antd';
import { ExportOutlined } from '@ant-design/icons';

import Wrapper from '@components/ui/wrapper/Wrapper';
import Filter from './components/Filter';
import {
  BreadcrumbAntStyle,
  ButtonSystemStyle,
  TableAntStyle,
} from '@styles/Style';
import { VietnameseString } from '@constants/VietnameseString';
import { greenColor, greenColorHover } from '@styles/Color';
import {
  getSearchParamsInUrl,
  setSearchParamsInUrlVer2,
  randomColor,
} from '@constants/funcHelper';
import { ROUTER, TIME_CODE_TYPE, BASE_URL_IMAGE } from '@constants/Constant';
import { useCancellableApi } from 'src/hooks/useCancellableApi';
import {
  requestExportLocationReportVer2,
  requestGetLocationReport,
} from './services/api';
import { CONDITION, CONDITION_STATUS, TIME_KEEPING_TYPE } from './Constants';
import { notifySuccess } from '@utils/notify';
import SpinInButton from '@components/SpinInButton';

const TextHoverStyle = styled.span`
  font-weight: bold;

  :hover {
    color: green;
  }
`;

function LocationReport() {
  const history = useHistory();

  const columns = [
    {
      title: VietnameseString.ordinal_number,
      fixed: 'left',
      dataIndex: '',
      key: '',
      render: (__value, __record, index) => (
        <span>{(paging.current - 1) * paging.pageSize + index + 1}</span>
      ),
    },
    {
      title: VietnameseString.telemarketing,
      fixed: 'left',
      width: 180,
      dataIndex: 'full_name',
      key: 'full_name',
      render: (value, record) => {
        try {
          const name = value.trim().split(' ');
          return (
            <div
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={() => {
                const locationSearch = window.location.search;

                history.push({
                  pathname: ROUTER.LOCATION_REPORT_DETAIL,
                  search: `telemarketingId=${record.id}`,
                  state: {
                    locationSearch: locationSearch || '?',
                  },
                });
              }}
            >
              <div>
                {value && (
                  <Avatar
                    src={
                      record.profile_image &&
                      BASE_URL_IMAGE + record.profile_image
                    }
                    style={{
                      backgroundColor: randomColor(
                        name[name.length - 1].charAt(0)?.toUpperCase()
                      ),
                      marginRight: '5px',
                    }}
                    size="small"
                  >
                    {name[name.length - 1].charAt(0)?.toUpperCase()}
                  </Avatar>
                )}
              </div>
              <Tooltip placement="bottomLeft" title={value}>
                <div
                  style={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'normal',
                    WebkitLineClamp: 1,
                    width: '100%',
                    textAlign: 'left',
                    fontWeight: 'bold',
                  }}
                >
                  <TextHoverStyle>{value}</TextHoverStyle>
                </div>
              </Tooltip>
            </div>
          );
        } catch (error) {}
      },
    },
    {
      title: 'Tên loại điểm danh',
      dataIndex: 'category_name',
      key: 'category_name',
    },
    // {
    //   title: 'Tên loại điểm danh',
    //   dataIndex: 'time_keepings',
    //   key: 'time_keepings',
    //   render: (value, record) => {
    //     return (
    //       <span>
    //         {value ? value.category_name : NAME_OF_ATTENDANCE_TYPE.find((el) => el.id === record.category_type)?.name}
    //       </span>
    //     );
    //   },
    // },
    {
      title: 'Thời gian cập nhật',
      width: 200,
      dataIndex: '',
      key: '',
      render: (__value, record, __index) => {
        if (record.time_keepings) {
          return (
            <span
              style={{
                display: 'inline-block',
                width: '70%',
                textAlign: 'right',
              }}
            >
              {moment(record.time_keepings.created_at).format(
                'HH:mm DD/MM/YYYY'
              )}
            </span>
          );
        } else {
          return (
            <span
              style={{
                display: 'inline-block',
                width: '70%',
                textAlign: 'right',
              }}
            >
              {record.time}
            </span>
          );
        }
      },
    },
    {
      title: VietnameseString.status,
      dataIndex: '',
      key: '',
      render: (__value, record, __index) => {
        const check = record.time_keepings;
        if (check === null) {
          return <span>{VietnameseString.no_attendance}</span>;
        } else {
          const timeKeepingType = TIME_KEEPING_TYPE.find(
            el => el.id === check.is_late
          );
          return <span>{timeKeepingType.name}</span>;
        }
      },
    },
    {
      title: 'Tên - SĐT khách hàng',
      dataIndex: '',
      key: '',
      render: (__value, record, __index) => {
        const check = record.time_keepings;
        if (check === null) {
          return <span>--</span>;
        } else {
          return (
            <TextHoverStyle
              onClick={() =>
                history.push({
                  pathname: `${ROUTER.CUSTOMER_DETAIL}/${record.time_keepings.customer_id}`,
                })
              }
            >
              <span>{check.customer_name ? check.customer_name : ''}</span>
              <span> - </span>
              <span>{check.customer_phone ? check.customer_phone : ''}</span>
            </TextHoverStyle>
          );
        }
      },
    },
    {
      title: VietnameseString.note,
      dataIndex: 'time_keepings',
      key: 'time_keepings',
      render: value => <span>{value ? value.note : '--'}</span>,
    },
    {
      title: 'Địa chỉ điểm danh',
      dataIndex: 'time_keepings',
      key: 'time_keepings',
      render: value => <span>{value ? value.address : '--'}</span>,
    },
    {
      title: VietnameseString.condition,
      dataIndex: 'time_keepings',
      key: 'time_keepings',
      render: value => {
        if (value) {
          const condition = CONDITION.find(
            el => el.id === value.verify_address
          );
          return (
            <span
              style={{
                fontWeight: 'bold',
                color:
                  value.verify_address === CONDITION_STATUS.KHONG_TRUNG_KHOP
                    ? 'red'
                    : 'green',
              }}
            >
              {condition.name}
            </span>
          );
        } else {
          return <span>--</span>;
        }
      },
    },
  ];

  const apiGetLocationReport = useCancellableApi(requestGetLocationReport);

  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [isLoadingButtonExport, setIsLoadingButtonExport] = useState(false);

  const [locationReportSearch, setLocationReportSearch] = useState({
    telemarketingId: [],
    departmentId: [],
    categoryId: [],
    isLate: [],
    verifyAddress: [],
    timeType: TIME_CODE_TYPE.DATE,
    from: moment(new Date()).subtract(7, 'day').format('YYYY/MM/DD'),
    to: moment(new Date()).endOf('day').format('YYYY/MM/DD'),
    page: 1,
    limit: 20,
  });

  useEffect(() => {
    const searchParamsInUrl = getSearchParamsInUrl();
    setLocationReportSearch({ ...locationReportSearch, ...searchParamsInUrl });
  }, []);

  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 20,
  });

  const [dataLocationReport, setDataLocationReport] = useState([]);

  const handleLocationReportSearchChange = (field, value) => {
    if (field !== 'departmentId') {
      setLocationReportSearch({
        ...locationReportSearch,
        [field]: value,
        page: 1,
      });

      setSearchParamsInUrlVer2({ [field]: value, page: 1 });
    } else {
      setLocationReportSearch({
        ...locationReportSearch,
        [field]: value,
        telemarketingId: [],
        page: 1,
      });

      setSearchParamsInUrlVer2({
        [field]: value,
        telemarketingId: [],
        page: 1,
      });
    }
  };

  const getLocationReport = async payload => {
    try {
      setIsLoadingTable(true);
      const { data, paging } = await apiGetLocationReport({
        telesale_id: payload.telemarketingId,
        department_id: payload.departmentId,
        category_id: payload.categoryId,
        is_late: payload.isLate,
        verify_address: payload.verifyAddress,
        from_date: payload.from,
        to_date: payload.to,
        page: payload.page,
        limit: payload.limit,
      });

      setDataLocationReport(data);

      setPaging({
        total: paging.totalItemCount,
        current: paging.page,
        pageSize: paging.limit,
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoadingTable(false);
    }
  };

  const debounceGetLocationReport = useCallback(_.debounce(getLocationReport), [
    500,
  ]);

  useEffect(() => {
    debounceGetLocationReport(locationReportSearch);
  }, [locationReportSearch]);

  const exportLocationReport = async payload => {
    try {
      setIsLoadingButtonExport(true);
      const result = await requestExportLocationReportVer2({
        telesale_id: payload.telemarketingId,
        department_id: payload.departmentId,
        category_id: payload.categoryId,
        is_late: payload.isLate,
        verify_address: payload.verifyAddress,
        from_date: payload.from,
        to_date: payload.to,
      });
      // saveAs(result.data, 'location_report.xlsx');
    } catch (error) {
    } finally {
      setIsLoadingButtonExport(false);
    }
  };

  return (
    <div className="content-wrapper">
      <BreadcrumbAntStyle>
        <Breadcrumb.Item>{VietnameseString.location_report}</Breadcrumb.Item>
      </BreadcrumbAntStyle>

      <Wrapper>
        <Filter
          locationReportSearch={locationReportSearch}
          onLocationReportSearchChange={handleLocationReportSearchChange}
          onLocationReportSearchTimeChange={(from, to) => {
            setLocationReportSearch({
              ...locationReportSearch,
              from,
              to,
              page: 1,
            });

            setSearchParamsInUrlVer2({ from, to, page: 1 });
          }}
          setTimeFilterType={type => {
            const handle = key => {
              let to = moment(new Date()).endOf(key).format('YYYY/MM/DD');

              if (moment(new Date()).endOf(key) > moment(new Date())) {
                to = moment(new Date()).format('YYYY/MM/DD');
              }

              setSearchParamsInUrlVer2({
                from: moment(new Date()).startOf(key).format('YYYY/MM/DD'),
                to,
                timeType: TIME_CODE_TYPE[key.toUpperCase()],
              });
              setLocationReportSearch({
                ...locationReportSearch,
                timeType: TIME_CODE_TYPE[key.toUpperCase()],
                page: 1,
                from: moment(new Date()).startOf(key).format('YYYY/MM/DD'),
                to: to,
              });
            };
            switch (type) {
              case TIME_CODE_TYPE.DATE:
                setSearchParamsInUrlVer2({
                  from: moment(new Date()).format('YYYY/MM/DD'),
                  to: moment(new Date()).format('YYYY/MM/DD'),
                  timeType: TIME_CODE_TYPE.DATE,
                });
                setLocationReportSearch({
                  ...locationReportSearch,
                  type,
                  timeType: TIME_CODE_TYPE.DATE,
                  page: 1,
                  from: moment(new Date()).format('YYYY/MM/DD'),
                  to: moment(new Date()).format('YYYY/MM/DD'),
                });
                break;
              case TIME_CODE_TYPE.WEEK:
                handle('week');
                break;
              case TIME_CODE_TYPE.MONTH:
                handle('month');
                break;
              case TIME_CODE_TYPE.QUARTER:
                handle('quarter');
                break;
              case TIME_CODE_TYPE.YEAR:
                handle('year');
                break;
              default:
                break;
            }
          }}
        />

        <Row align="middle" justify="space-between">
          <Col>
            Kết quả:{' '}
            <p
              style={{
                fontWeight: 'bold',
                display: 'inline-block',
                margin: '0px',
              }}
            >
              {paging.total}
            </p>
          </Col>

          <Col>
            <ButtonSystemStyle
              bgButtonColor={greenColor}
              bgButtonColorHover={greenColorHover}
              loading={isLoadingButtonExport}
              disabled={isLoadingButtonExport}
              onClick={() => {
                notifySuccess('Vui lòng đợi xử lí trong ít phút');
                exportLocationReport(locationReportSearch);
              }}
            >
              {isLoadingButtonExport ? (
                <SpinInButton />
              ) : (
                <ExportOutlined style={{ marginRight: '10px' }} />
              )}
              {VietnameseString.export_excel}
            </ButtonSystemStyle>
          </Col>
        </Row>

        <TableAntStyle
          loading={isLoadingTable}
          columns={columns}
          dataSource={dataLocationReport}
          scroll={{ x: 'max-content' }}
          pagination={{
            showSizeChanger: false,
            ...paging,
            onChange: page => {
              setLocationReportSearch({ ...locationReportSearch, page });
              setSearchParamsInUrlVer2({ page });
            },
          }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>{VietnameseString.no_data}</span>}
              />
            ),
          }}
        />
      </Wrapper>
    </div>
  );
}

export default LocationReport;
