import {
  ArrowRightOutlined,
  DownOutlined,
  RightOutlined,
} from '@ant-design/icons';
import plusicon from '@assets/plusicon.png';
import { ApiClient } from '@constants/Api';
import { Button, Empty, Image, Skeleton, Spin } from 'antd';
import domtoimage from 'dom-to-image';
import React, { useEffect, useState } from 'react';
import ModalTemplateMsg from './ModalTemplateMsg';
import { copyContentclipBoard } from './copyImage';
import QRCode from 'qrcode.react';
import {
  Divider,
  FlexContainer,
  HeadingMsg,
  ImageContainer,
  MessageContent,
  MessageItemContainer,
  ModalStyled,
  QrCodeStyled,
  RowStyled,
  TitleModal,
  dataTest,
} from './styleModalZalo';

import TemplateVoucherExport from '@screens/Enterprise/ElectronicVoucher/components/templateVoucher/TemplateVoucherExport';
import { ImageStyled } from './styleModalZalo';
const MessageItem = ({
  selected,
  src,
  onChangeRadio,
  itemSelected,
  desc,
  showqrCodeInImage,
  code,
}) => {
  // hướng của voucher
  const [voucherDirection, setVoucherDirection] = useState(null);
  const boxImageRef = React.useRef(null);
  const qrCodeRef = React.useRef(null);
  // ref image mẫu voucher
  const imageTemVoucherRef = React.useRef(null);
  const [imgQr, setImgQr] = useState(null);
  const [loading, setLoading] = React.useState(false);

  const [imgVoucher, setImgVoucher] = React.useState('');
  console.log(
    '🚀 ~ file: ModalMessageZalo.js:29 ~ MessageItem ~ imgVoucher:',
    src
  );

  React.useEffect(() => {
    if (!src) return;
    if (
      imageTemVoucherRef.current?.height > imageTemVoucherRef.current?.width
    ) {
      setVoucherDirection('vertical');
    } else {
      setVoucherDirection('horizontal');
    }
  }, [imageTemVoucherRef?.current?.height, src]);

  React.useEffect(() => {
    if (!boxImageRef.current) return;
    setTimeout(() => {
      (async () => {
        setLoading(true);
        const img = await domtoimage.toPng(boxImageRef.current, {
          bgcolor: 'white',
          cacheBust: true,
        });

        setImgVoucher(img);
        setLoading(false);
      })();
    }, 1500);
  }, [boxImageRef?.current]);

  useEffect(() => {
    let qrElement = document.getElementById(`qrcode111111`);
    if (!qrElement) return;
    setImgQr(qrElement.toDataURL('image/png'));
  }, []);

  console.log('setImgQr', imgQr);
  function handleChange(e) {
    if (itemSelected.content) {
      itemSelected.content = desc?.trim().replace(/\s+/g, ' ');
    }
    console.log('desc', desc.trim());
    onChangeRadio(itemSelected, imgVoucher || imgQr);
  }
  return (
    <Spin spinning={loading} tip="Đang tạo ảnh ...">
      <MessageItemContainer selected={selected}>
        <div className="message_checkbox">
          <input
            type="radio"
            name={'message'}
            value={itemSelected?.id}
            checked={selected}
            onChange={handleChange}
          />
        </div>
        <MessageContent qrCodeInImage={showqrCodeInImage}>
          {/* {src && (
          <ImageContainer ref={boxImageRef}>
            <img src={src} alt={itemSelected?.id} />
           {code && <QrCodeStyled
              id="qrcode111111"
              value={code}
              level={'H'}
              includeMargin={true}
              qrCodeInImage = {showqrCodeInImage}
              renderAs="canvas"
            />} 
          
          </ImageContainer>
        )} */}
          {imgVoucher && showqrCodeInImage == 1 && src && (
            <Image
              src={imgVoucher}
              alt="imgVoucher"
              height={voucherDirection === 'horizontal' ? 'auto' : 300}
              // height={voucherDirection === 'horizontal' ? "auto" : "auto"}
              width={200}
              className="imagevoucher"
              style={{ objectFit: 'contain' }}
            />
          )}
          {src && code && showqrCodeInImage === 1 && (
            <div
              style={{
                height: 0,
                overflow: 'hidden',
                //  width: 0,
                position: 'absolute',
              }}
            >
              <div
                style={{
                  height: 'fit-content',
                  width: 'fit-content',
                }}
                ref={boxImageRef}
              >
                <TemplateVoucherExport
                  hori={voucherDirection === 'horizontal'}
                  imgUrl={src}
                  valueQR={code}
                  objectcontain
                />
              </div>
            </div>
          )}
          {src && showqrCodeInImage == 1 && code && (
            <img
              style={{ display: 'none', border: '1px solid green' }}
              alt="e_voucher_image"
              ref={imageTemVoucherRef}
              src={src}
            />
          )}
          {code && showqrCodeInImage == 1 && !src && (
            <div style={{ width: '200px' }}>
              <QrCodeStyled
                id="qrcode111111"
                value={code}
                level={'H'}
                includeMargin={true}
                // renderAs="canvas"
                ref={qrCodeRef}
              />
            </div>
          )}

          {showqrCodeInImage === undefined && !code && src ? (
            <ImageContainer>
              <Image
                src={src}
                alt={itemSelected?.id}
                // preview = {false}
              />{' '}
            </ImageContainer>
          ) : null}

          <div className="description">{desc}</div>
        </MessageContent>
      </MessageItemContainer>
    </Spin>
  );
};

const ModalMessageZalo = ({
  isVisible,
  callDetail,
  setShowModalMessageZalo,
  customerPhone,
  customerDetail,
}) => {
  const [isShowMsgCustom, setIsDefaultMsgCustom] = useState(false);
  const [isShowModalTemplate, setIsShowModalTemplate] = useState(false);
  const [listMsgCustom, setListMsgCustom] = useState([]);
  const [listMsgJob, setListMsgJob] = useState([]);
  const [isLoadingMsgCustom, setIsLoadingMsgCustom] = useState(false);
  const [itemSelected, setItemSelected] = useState(-1);
  const [isCreateMsgSuccess, setIsCreateMsgSuccess] = useState(false);

  useEffect(() => {
    async function getListMsgCustom() {
      try {
        setIsLoadingMsgCustom(true);
        let res = await ApiClient.get('sale/getListMess');
        if (res?.status) {
          setListMsgCustom(res?.data);
          // setListMsgCustom(listMsgCustomStatic);
        }
      } catch (error) {
        //
      } finally {
        setIsLoadingMsgCustom(false);
      }
    }
    isShowMsgCustom && getListMsgCustom();
  }, [isShowMsgCustom, isCreateMsgSuccess]);

  function converwithRegex(stringDesc) {
    const objReplaceMsg = {
      '[Tên khách hàng]': customerDetail.name,
      '[Giới tính KH]': customerDetail.gender == 1 ? 'Nam' : 'Nữ',
      '[Tên sale]': callDetail.sale_name,
      '[Địa chỉ Công Ty]': customerDetail.address,
      '[Tên công ty]': customerDetail.company,
      '[Tên công việc]': callDetail.job_name,
    };
    const regex =
      /\[Tên khách hàng\]|\[Giới tính KH\]|\[Tên sale\]|\[Địa chỉ Công Ty\]|\[Tên công ty\]|\[Tên công việc\]/g;
    console.log('RegExp, ', regex);
    return stringDesc.replace(regex, m => objReplaceMsg[m] || m);
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const customerId = params.get('customer_id');
    const jobId = params.get('job_id');
    async function getListMsgJob() {
      try {
        let res = await ApiClient.get(
          `sms/messageOfJobV2?customer_id=${customerId}&job_id=${jobId}`
        );
        if (res?.status && res.data) {
          // res.data = dataTest
          const listMsgJobResponse =
            res.data.e_voucher_code === undefined
              ? 
              (res.data.message ? [{ id: Math.random(), message: res.data.message }]
                : [])
              : [
                  {
                    id: Math.random(),
                    message: res.data.message,
                    media_url: res.data.media_url,
                    e_voucher_code: res.data.e_voucher_code,
                    is_sample_voucher: res.data.is_sample_voucher,
                  },
                ];
          if (res.data?.message_template_media?.length > 0) {
            res.data.message_template_media.forEach(item => {
              listMsgJobResponse.push({ ...item, message: res.data.message });
            });
          }
          setListMsgJob(listMsgJobResponse);
          // setListMsgJob(listMsgJobStatic);
        }
      } catch (error) {
        console.log('err', error);
        //
      } finally {
        //
      }
    }

    customerId && jobId && getListMsgJob();
  }, []);

  console.log('listMsgJob', listMsgJob);
  function onShowModalTemplate() {
    setIsShowModalTemplate(!isShowModalTemplate);
  }

  async function onChangeRadio(itemselect, imgVoucher) {
    console.log('imgVoucher', imgVoucher);
    let itemSelectedCopy = { ...itemselect };
    if (
      itemselect.is_sample_voucher === 1 &&
      // || itemselect.is_sample_voucher ===0
      itemselect.e_voucher_code
    ) {
      itemSelectedCopy.media_url = imgVoucher;
    }

    console.log('valueGet', itemSelectedCopy);
    setItemSelected(itemSelectedCopy);
  }

  // function mergeImagesFunc() {
  //   const canvas = document.getElementById("qrcode111111");
  //   canvas.crossOrigin= 'anonymous'
  //   const pngUrl = canvas
  //   .toDataURL("image/jpeg")
  //   console.log('pngUrl' , pngUrl)
  //   if( pngUrl) {
  //     mergeImages([`https://raw.githubusercontent.com/lukechilds/merge-images/HEAD/test/fixtures/body.png`, `https://raw.githubusercontent.com/lukechilds/merge-images/HEAD/test/fixtures/eyes.png`] ).then((result) => {
  //       console.log("resultMerge",result )
  //     })
  //   }
  // }

  return (
    <ModalStyled
      visible={isVisible}
      title={
        <TitleModal
          title={'Copy mẫu tin nhắn'}
          icon={plusicon}
          onClick={onShowModalTemplate}
        />
      }
      onCancel={() => setShowModalMessageZalo(false)}
      footer={null}
      closable={false}
      width={'70%'}
    >
      <div className="message_custom">
        <FlexContainer
          onClick={() => {
            setIsDefaultMsgCustom(!isShowMsgCustom);
          }}
        >
          <HeadingMsg>Mẫu tin nhắn tự tạo</HeadingMsg>
          <span style={{ marginRight: '10px' }}>
            {isShowMsgCustom ? <RightOutlined /> : <DownOutlined />}
          </span>
        </FlexContainer>
        {isShowMsgCustom ? (
          <div className="message_custom-list">
            {isLoadingMsgCustom ? (
              <Skeleton />
            ) : listMsgCustom?.length > 0 ? (
              listMsgCustom.map(item => {
                return (
                  <MessageItem
                    key={item?.id}
                    onChangeRadio={onChangeRadio}
                    itemSelected={item}
                    selected={item?.id == itemSelected?.id}
                    desc={converwithRegex(item?.content)}
                    // desc={<pre dangerouslySetInnerHTML={{__html: item?.content}} />}
                  />
                );
              })
            ) : (
              <Empty description="Tin nhắn tự tạo hiện đang trống" />
            )}
          </div>
        ) : null}
      </div>
      <Divider />

      <div className="message_job">
        <HeadingMsg className="msgjob">Mẫu tin nhắn công việc</HeadingMsg>
        <div className="message_job-list">
          {listMsgJob?.length > 0 ? (
            listMsgJob.map(item => {
              return (
                <MessageItem
                  selected={item?.id == itemSelected?.id}
                  key={item?.id}
                  itemSelected={item}
                  onChangeRadio={onChangeRadio}
                  src={item?.media_url}
                  desc={item?.message}
                  showqrCodeInImage={item.is_sample_voucher}
                  code={item.e_voucher_code}
                />
              );
            })
          ) : (
            <Empty description="Tin nhắn công việc hiện đang trống" />
          )}
        </div>
      </div>
      <RowStyled>
        <Button
          type="primary"
          onClick={() => {
            // mergeImagesFunc()
            copyContentclipBoard(
              itemSelected,
              customerDetail?.unmasked_pnumber
            );
          }}
        >
          <span>Zalo</span>
          <span>
            <ArrowRightOutlined />
          </span>
        </Button>
      </RowStyled>
      {isShowModalTemplate && (
        <ModalTemplateMsg
          isVisible={isShowModalTemplate}
          setIsShowModalTemplate={setIsShowModalTemplate}
          setIsCreateMsgSuccess={setIsCreateMsgSuccess}
          isCreateMsgSuccess={isCreateMsgSuccess}
        />
      )}
    </ModalStyled>
  );
};

export default ModalMessageZalo;
