import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { toDateString } from '../../../Utils/ToDateString';
import { Row, Col, FormControl, Button, Modal } from 'react-bootstrap';
import '../../../styles/Tutorial.css';
import Loading from '@src/components/Loading';
import { ROUTER, STRING, NUMBER } from '@constants/Constant';
import DatePickerCustom from '../../../components/DatePickerCustom';
import { getListTutorial } from '@src/redux/actions';
import reactotron from 'reactotron-react-js';
import { deleteTutorial } from '@constants/Api';
import { notifyFail, notifySuccess } from '@utils/notify';
import Pagination from 'react-js-pagination';
import { Empty } from 'antd';

class TutorialScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      title: '',
      [STRING.fromDate]: '',
      [STRING.toDate]: '',
      confirmModal: false,
      activePage: 1,
      status: '',
    };
  }

  componentDidMount() {
    this.getData('', '', '', 1, '');
  }

  async getData(search, fromDate, toDate, page, status) {
    await this.props.getListTutorial({
      SEARCH: search,
      FROM_DATE: fromDate || 0,
      TO_DATE: toDate || Date.now(),
      PAGE: page,
      status: status,
    });
  }

  handleChange = (field, value) => {
    this.setState({
      [field]: value || '',
    });
  };

  handleKeyPress = (e) => {
    const { title, [STRING.fromDate]: fromDate, [STRING.toDate]: toDate, activePage, status } = this.state;
    if (e.charCode === 13) {
      this.props.getListTutorial({
        SEARCH: title,
        FROM_DATE: new Date(fromDate).valueOf() || 0,
        TO_DATE: new Date(toDate).valueOf() || new Date().valueOf(),
        PAGE: activePage,
        status: status,
      });
    }
  };

  async deleteTutorial(id) {
    const { title, [STRING.fromDate]: fromDate, [STRING.toDate]: toDate, activePage, status } = this.state;
    try {
      await deleteTutorial({
        id: id,
      });
      this.props.getListTutorial({
        SEARCH: title,
        FROM_DATE: new Date(fromDate).valueOf() || 0,
        TO_DATE: new Date(toDate).valueOf() || new Date().valueOf(),
        PAGE: activePage,
        status: status,
      });
      notifySuccess('Xóa thành công');
    } catch (err) {
      alert(err);
      notifyFail('Thao tác thất bại, vui lòng thử lại');
    }
  }

  renderConfirmModal() {
    const { confirmModal } = this.state;
    return (
      <Modal
        show={confirmModal}
        onHide={() =>
          this.setState({
            confirmModal: false,
          })
        }
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton className="bg-primary pb-0">
          <h5 style={{ color: 'white' }}>Bạn chắc chắn muốn xóa ?</h5>
        </Modal.Header>
        <Modal.Body className="custom-body">
          <Row>
            <Col className="button-wrapper">
              <Button
                variant="success"
                onClick={() => {
                  this.deleteTutorial(this.state.id);
                  this.setState({
                    confirmModal: false,
                  });
                }}
                className="text-light"
              >
                Có
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  this.setState({
                    confirmModal: false,
                  });
                }}
                className="text-light"
              >
                {STRING.exit}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  renderTitle() {
    return (
      <>
        <Row>
          <Col sm={12}>
            <h2 className="text-left" style={{ marginBottom: '10px' }}>
              {' '}
              Bài viết
            </h2>
            <div class="line"></div>
          </Col>
        </Row>
      </>
    );
  }

  renderBody() {
    return (
      <div className="content-wrapper">
        <div className="content-header">
          <Row
            className="mt-1"
            style={{ textAlign: 'center', padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}
          >
            <Col md className="bg">
              {this.renderTitle()}
              {this.renderField()}
            </Col>
          </Row>
          <Row
            className="mt-1"
            style={{ textAlign: 'center', padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}
          >
            <Col md className="bg">
              <Row>
                <Col md={12}>{this.renderTable()}</Col>
              </Row>
              <Row>
                <Col md={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {this.renderPagination()}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderPagination = () => {
    const itemsCount = this.props.listTutorial?.paging?.totalItemCount;
    const limit = this.props.listTutorial?.paging?.limit;
    reactotron.log(itemsCount, limit);
    const { title, [STRING.fromDate]: fromDate, [STRING.toDate]: toDate, activePage } = this.state;
    return (
      <Pagination
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled
        activePage={activePage}
        itemsCountPerPage={limit}
        totalItemsCount={itemsCount}
        pageRangeDisplayed={5}
        hideNavigation
        onChange={(page) => {
          this.props.getListTutorial({
            SEARCH: title,
            FROM_DATE: new Date(fromDate).valueOf() || 0,
            TO_DATE: new Date(toDate).valueOf() || new Date().valueOf(),
            PAGE: page,
          });
          this.setState({
            ...this.state,
            activePage: page,
          });
        }}
      />
    );
  };

  renderField() {
    const { title, [STRING.fromDate]: fromDate, [STRING.toDate]: toDate, status } = this.state;
    return (
      <div className="w-100">
        <Row>
          <Col md={3} sm={6}>
            <FormControl
              onKeyPress={this.handleKeyPress}
              type="text"
              id=""
              placeholder={STRING.title}
              value={title}
              onChange={(e) => this.handleChange('title', e.target.value)}
            />
          </Col>
          <Col md={3} sm={6}>
            <DatePickerCustom
              className={`date-picker form-control`}
              placeholderText={STRING.fromDate}
              handleChange={this.handleChange}
              selected={fromDate}
              maxDate={new Date(toDate)}
              dateFormat="dd/MM/yyyy"
            />
          </Col>
          <Col md={3} sm={6}>
            <DatePickerCustom
              className={`date-picker form-control`}
              placeholderText={STRING.toDate}
              handleChange={this.handleChange}
              selected={toDate}
              minDate={new Date(fromDate)}
              dateFormat="dd/MM/yyyy"
            />
          </Col>
          <Col md={3} sm={6}>
            <FormControl
              as="select"
              id=""
              value={status}
              onChange={(e) => this.handleChange('status', e.target.value)}
              className="h-100"
            >
              <option value="">Trạng thái</option>
              <option value={0}>Lưu nháp</option>
              <option value={1}>Đã đăng</option>
            </FormControl>
          </Col>
        </Row>
        {this.renderButton()}
      </div>
    );
  }

  renderButton = () => {
    const { title, [STRING.fromDate]: fromDate, [STRING.toDate]: toDate, activePage, status } = this.state;
    // reactotron.log(fromDate);
    return (
      <Row className="p-0 mr-3 mt-4 mb-4">
        <Col className="button-wrapper">
          <Button variant="primary" className="text-light">
            <div
              className="text-light"
              onClick={() => {
                this.props.getListTutorial({
                  SEARCH: title,
                  FROM_DATE: new Date(fromDate).valueOf() || 0,
                  TO_DATE: new Date(toDate).valueOf() || new Date().valueOf(),
                  PAGE: activePage,
                  status: status,
                });
              }}
            >
              {STRING.search}
            </div>
          </Button>
          <Button variant="success" onClick={() => this.props.history.push(ROUTER.ADD_TUTORIAL)}>
            <div style={{ color: 'white' }}>{STRING.add}</div>
          </Button>
          <Button
            variant="secondary"
            classname="btn"
            onClick={() => {
              this.props.getListTutorial({
                SEARCH: '',
                FROM_DATE: 0,
                TO_DATE: Date.now(),
                PAGE: this.state.activePage,
                status: '',
              });
              this.setState({
                title: '',
                [STRING.fromDate]: '',
                [STRING.toDate]: '',
                status: '',
              });
            }}
          >
            <div style={{ color: 'white' }}>{STRING.clearSearch}</div>
          </Button>
        </Col>
      </Row>
    );
  };

  renderTable() {
    return (
      <Table striped bordered hover>
        <thead style={{ textAlign: 'center' }}>
          <th>{STRING.index}</th>
          <th>{STRING.title}</th>
          <th>{STRING.creater}</th>
          <th>{STRING.dateCreated}</th>
          <th>{STRING.displayIndex}</th>
          <th>{STRING.status}</th>
          <th></th>
        </thead>
        {this.renderTableData()}
      </Table>
    );
  }

  renderTableData = () => {
    // reactotron.log(this.props.listTutorial.data);
    const limit = this.props.listTutorial?.paging?.limit;
    return (
      <tbody>
        {this.props.listTutorial.data?.length > 0 ? (
          this.props.listTutorial.data.map((value, index) => (
            <tr>
              <td>{index + limit * (this.state.activePage - 1) + 1 || ''}</td>
              <td>{value.title || ''}</td>
              <td>{value.creator_name || ''}</td>
              <td>{toDateString(value.create_date) || ''}</td>
              <td>{value.display_index || ''}</td>
              <td>{value.status == 1 ? 'Đã đăng' : 'Lưu nháp' || ''}</td>
              <td>
                <i
                  className="btnEdit fa fa-fw fa-edit"
                  onClick={() => {
                    this.props.history.push(ROUTER.TUTORIAL_DETAIL + '/' + value.id);
                  }}
                />
                <i
                  className="btnDelete far fa-trash-alt"
                  onClick={() => {
                    this.setState({
                      id: value.id,
                      confirmModal: true,
                    });
                  }}
                />
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colspan="7">
              <Empty description={<span>Không có dữ liệu</span>} />
            </td>
          </tr>
        )}
      </tbody>
    );
  };

  render() {
    reactotron.log('page' + this.state.activePage);
    const { [STRING.fromDate]: fromDate } = this.state;
    const { isLoading, error } = this.props.listTutorial;
    return (
      <>
        {isLoading && <Loading />}
        {/* {error && <Error/>} */}
        {this.renderBody()}
        {this.renderConfirmModal()}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  listTutorial: state.listTutorial,
});

const mapDispatchToProps = {
  getListTutorial,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TutorialScreen));
