// export const CALL_STATUS = {
//   calling: 'calling',
//   started: 'started',
//   answered: 'answered',
//   ringing: 'ringing',
//   ended: 'ended',
//   busy: 'busy',
// };

export const PROVIDER_NAME = {
  ITY: 'ITY',
  STRINGEE: 'stringee',
};

export const SIGNALING_STATE = 'signalingState';

export const CLIENT_STATUS = {
  notInit: 'notInit',
  connecting: 'connecting',
  connected: 'connected',
  disconnected: 'disconnected',
  registered: 'registered',
  registrationFailed: 'registrationFailed',
};

export const CALL_STATUS_ERROR_CODE = {
  TemporarilyUnavailable: 480,
  Forbidden: 403,
  AddressIncomplete: 484,
  ServiceUnavailable: 503,
};

export const CALLING_CODE = {
  Preparing: -9,
  Init: 0,
  Calling: 100,
  Answered: 200,
  Ended: -1,
  TemporarilyUnavailable: 480,
  Ringing: 183,
  CallForwarded: 181,
  Busy: 486,
};

export const INCOMING_CALL = {
  INCOMING: 'INCOMING',
};

// const CALL_ERROR_CODE = {
//   NOT_INIT: -1,
//   SUCCESS: 0,
//   ANSWER_URL_EMPTY: 1,
//   FROM_NUMBER_NOT_FOUND: 4,
//   FROM_NUMBER_NOT_BELONG_YOUR_ACCOUNT: 5,
//   NOT_ENOUGH_MONEY: 8,
//   FROM_NUMBER_OR_TO_NUMBER_INVALID_FORMAT: 10,
//   FROM_NUMBER_NOT_BELONG_YOUR_PROJECT: 15,
//   TO_NUMBER_INVALID: 14,
// };
