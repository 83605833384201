import UploadComponent from '@screens/Enterprise/ElectronicVoucher/utils/UploadFile';
import { Row, Space } from 'antd';
import React from 'react';
import styled from 'styled-components';

const UploadImageSms = () => {
  const [images, setImages] = React.useState([]);
  const [imageSelect, setImageSelect] = React.useState(null);
  const rowImageRef = React.useRef(null);

  return (
    <Row
      justify="start"
      style={{
        width: '100%',
        padding: '10px 0',
        overflowX: 'scroll',
        overflowY: 'hidden',
      }}
      ref={rowImageRef}
    >
      <Space align="center">
        {images?.map((item, index) => {
          return (
            <ContainerImage
              style={{
                borderColor: imageSelect === index ? '#f37920' : '#ccc',
              }}
              key={index}
            >
              <RemoveVoucherStyled
                onClick={() => {
                  setImages(
                    images.filter((item, indexItem) => indexItem !== index)
                  );
                  setImageSelect(null);
                }}
              >
                X
              </RemoveVoucherStyled>
              <img
                onClick={({ target }) => {
                  setImageSelect(index);
                }}
                src={
                  typeof item?.banner === 'string'
                    ? item?.banner
                    : URL.createObjectURL(item?.banner)
                }
                alt="voucher_doc"
                style={{ display: 'none' }}
                className="img_voucher_current"
              />
              <img
                onClick={({ target }) => {
                  // setFileVoucher(item?.banner);
                  setImageSelect(index);
                }}
                src={
                  typeof item?.banner === 'string'
                    ? item?.banner
                    : URL.createObjectURL(item?.banner)
                }
                alt="voucher_doc"
                className="img_voucher"
              />
            </ContainerImage>
          );
        })}
        <Row align="center" style={{ height: '100%' }}>
          <UploadComponent
            size="large"
            listType="picture-card"
            // isUploadServerWhenUploading
            onSuccessUpload={value => {
              if (value) {
                setImages([...images, { banner: value?.originFileObj }]);
                rowImageRef.current.scrollLeft =
                  rowImageRef.current.scrollWidth;
              }
            }}
            accept=".png, .jpg, .mp4"
          >
            +
          </UploadComponent>
        </Row>
      </Space>
    </Row>
  );
};

const ContainerImage = styled.div`
  padding: 6px;
  border: 1px dashed #ccc;
  position: relative;
  border-radius: 10px;

  &:hover {
    cursor: pointer;

    & .img_voucher {
      scale: 1.1;
    }
  }

  overflow: hidden;
  width: 140px;
  height: 140px;

  & .img_voucher {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

const RemoveVoucherStyled = styled.div`
  position: absolute;
  top: 1px;
  right: 1px;
  padding: 2.5px 7px;
  border-radius: 999px;
  background-color: #dc2514;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  z-index: 99;
  font-size: 10px;

  &:hover {
    opacity: 0.8;
  }
`;

export default UploadImageSms;
