import React, { Component } from 'react';
import { Empty, Radio } from 'antd';
import { withRouter } from 'react-router-dom';
import { Row, Col, FormControl, Button, Modal, FormGroup, Table } from 'react-bootstrap';
import { connect } from 'react-redux';

import { getListCheckin, deleteCheckin, createCheckin, updateCheckin } from '@constants/Api';
import { notifyFail, notifySuccess } from '@utils/notify';
import Loading from '@src/components/Loading';
import { convertToTime } from '@utils/convertToTime';
import { VietnameseString } from '@constants/VietnameseString';
import '@styles/Settings.css';

const TYPE_CHECK_IN = {
  CHECK_IN: '1',
  CHECK_OUT: '2',
  OTHER: '3',
};

class Checkin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      activePage: 1,
      listCheckin: [],
      modal: {
        id: '',
        name: '',
        type: TYPE_CHECK_IN.CHECK_IN,
        time: '',
        config_time: '',
        is_have_image: 1,
        is_active: '',
      },
      modalDelete: false,
      modalAdd: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    const { search, activePage } = this.state;
    this.getListCheckin(search, activePage);
  }

  handleChange = (field, value) => {
    this.setState({
      ...this.state,
      [field]: value,
    });
  };

  handlePressKeyword = (e) => {
    const { search, activePage } = this.state;
    if (e.charCode === 13) {
      this.getListCheckin(search, activePage);
    }
  };

  handleChangeModal = (field, value) => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        [field]: value,
      },
    });
  };

  handleChangeTime = (field, e) => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        [field]: e.target.value,
      },
    });
  };

  async getListCheckin(search, activePage) {
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const res = await getListCheckin({
        search: search,
        page: activePage,
      });
      this.setState({
        ...this.state,
        listCheckin: res?.data,
        isLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async createCheckin() {
    const { name, type, time, config_time, is_have_image } = this.state.modal;
    const { search, activePage } = this.state;

    if (name === '') {
      notifyFail('Vui lòng nhập tên loại điểm danh');
      return;
    }

    if ((type === TYPE_CHECK_IN.CHECK_IN || type === TYPE_CHECK_IN.CHECK_OUT) && (time === '' || config_time === '')) {
      notifyFail('Vui lòng nhập thời gian điểm danh');
      return;
    }

    this.setState({
      ...this.state,
      isLoading: false,
    });
    try {
      await createCheckin({
        name,
        type,
        time,
        config_time,
        is_have_image,
      });
      this.setState(
        {
          ...this.state,
          modal: {
            id: '',
            name: '',
            type: TYPE_CHECK_IN.CHECK_IN,
            time: '',
            config_time: '',
            is_have_image: 1,
          },
          isLoading: false,
          modalAdd: false,
        },
        () => notifySuccess('Thêm mới thành công')
      );
      this.getListCheckin(search, activePage);
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async updateCheckin() {
    const { id, name, type, time, config_time, is_have_image } = this.state.modal;
    const { search, activePage } = this.state;
    this.setState({
      ...this.state,
      isLoading: false,
    });
    try {
      await updateCheckin({
        id,
        name,
        type,
        time,
        config_time,
        is_have_image,
        is_active: 1,
      });
      this.setState(
        {
          ...this.state,
          modal: {
            id: '',
            name: '',
            type: TYPE_CHECK_IN.CHECK_IN,
            time: '',
            config_time: '',
            is_have_image: 1,
          },
          isLoading: false,
          modalAdd: false,
        },
        () => notifySuccess('Cập nhật thành công')
      );
      this.getListCheckin(search, activePage);
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async deleteCheckin() {
    const { id } = this.state.modal;
    const { search, activePage } = this.state;
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      await deleteCheckin({ id });
      this.getListCheckin(search, activePage);
      this.setState(
        {
          ...this.state,
          modalDelete: false,
          modal: {
            ...this.state.modal,
            id: '',
            name: '',
            type: TYPE_CHECK_IN.CHECK_IN,
            time: '',
            config_time: '',
            is_have_image: 1,
          },
          isLoading: false,
        },
        () => notifySuccess('Xóa thành công')
      );
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  addModalCheckin = () => {
    const { modalAdd, modal } = this.state;
    return (
      <Modal
        show={modalAdd}
        onHide={() => {
          this.handleChange('modalAdd', false);
          this.setState({
            modal: {
              id: '',
              name: '',
              type: TYPE_CHECK_IN.CHECK_IN,
              time: '',
              config_time: '',
              is_have_image: 1,
            },
          });
        }}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton className="bg-primary">
          <h5 className="text-light pb-0">{modal.id ? 'Sửa loại checkin' : 'Thêm loại checkin'}</h5>
          {/* <h5 className="text-light pb-0">Thêm loại checkin</h5> */}
        </Modal.Header>

        <Modal.Body className="custom-body">
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>{VietnameseString.name_of_attendance_type}</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="text"
                placeholder="Nhập nội dung"
                value={modal.name}
                onChange={(e) => this.handleChangeModal('name', e.target.value)}
              ></FormControl>
            </Row>
          </FormGroup>

          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Loại điểm danh</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                as="select"
                id=""
                value={modal.type}
                disabled={modal.id ? true : false}
                onChange={(e) => this.handleChangeModal('type', e.target.value)}
              >
                <option value={TYPE_CHECK_IN.CHECK_IN}>Checkin</option>
                <option value={TYPE_CHECK_IN.CHECK_OUT}>Checkout</option>
                <option value={TYPE_CHECK_IN.OTHER}>Other</option>
              </FormControl>
            </Row>
          </FormGroup>

          {(modal.type === TYPE_CHECK_IN.CHECK_IN || modal.type === TYPE_CHECK_IN.CHECK_OUT) && [
            <FormGroup className="text-left">
              <Row className="modal-row">
                <label>Thời gian</label>
              </Row>
              <Row className="modal-row">
                <FormControl
                  type="time"
                  aria-describedby="basic-addon1"
                  placeholder={`Nhập thời gian`}
                  onChange={(e) => this.handleChangeModal('time', e.target.value)}
                  value={modal.time}
                />
              </Row>
            </FormGroup>,

            <FormGroup className="text-left">
              <Row className="modal-row">
                <label>Thời gian tối đa(phút)</label>
              </Row>
              <Row className="modal-row">
                <FormControl
                  type="text"
                  placeholder="Nhập thời gian"
                  value={modal.config_time}
                  onChange={(e) => this.handleChangeModal('config_time', e.target.value)}
                ></FormControl>
              </Row>
            </FormGroup>,
          ]}

          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Yêu cầu ảnh</label>
            </Row>
            <Row className="modal-row">
              <Radio.Group onChange={(e) => this.handleChangeTime('is_have_image', e)} value={modal.is_have_image}>
                <Radio value={1}>Có</Radio>
                <Radio value={0}>Không</Radio>
              </Radio.Group>
            </Row>
          </FormGroup>
          {modal.id ? (
            <FormGroup className="text-left">
              <Row className="modal-row">
                <label>Trạng thái</label>
              </Row>
              <Row className="modal-row">
                <FormControl
                  as="select"
                  id=""
                  value={modal.is_active}
                  onChange={(e) => this.handleChangeModal('is_active', e.target.value)}
                >
                  <option value="">Trạng thái</option>
                  <option value="1">Đang hoạt động</option>
                  <option value="2">Ngừng hoạt động</option>
                </FormControl>
              </Row>
            </FormGroup>
          ) : (
            ''
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            disabled={this.state.disable}
            variant="success"
            className="text-light"
            onClick={() => {
              modal.id ? this.updateCheckin() : this.createCheckin();
            }}
            // onClick={() => this.createCheckin()}
          >
            {modal.id ? 'Cập nhật' : 'Thêm mới'}
          </Button>
          <Button
            variant="danger"
            className="text-light"
            onClick={() => {
              this.handleChange('modalAdd', false);
              this.setState({
                modal: {
                  id: '',
                  name: '',
                  type: TYPE_CHECK_IN.CHECK_IN,
                  time: '',
                  config_time: '',
                  is_have_image: 1,
                },
              });
            }}
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  addModalDeleteCheckin() {
    const { modalDelete } = this.state;
    return (
      <Modal
        show={modalDelete}
        onHide={() =>
          this.setState({
            modalDelete: false,
            modal: {
              ...this.state.modal,
              id: '',
            },
          })
        }
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton className="bg-red">
          <h5 className="text-light pb-0">Bạn chắc chắn muốn xóa loại điểm danh này?</h5>
        </Modal.Header>
        <Modal.Body className="custom-body">
          <Row>
            <Col className="button-wrapper" style={{ textAlign: 'center' }}>
              <Button variant="success" className="text-light" onClick={() => this.deleteCheckin()}>
                Có
              </Button>
              <Button
                variant="danger"
                className="text-light"
                onClick={() => {
                  this.setState({
                    modalDelete: false,
                    modal: {
                      ...this.state.modal,
                      id: '',
                      name: '',
                      type: TYPE_CHECK_IN.CHECK_IN,
                      time: '',
                      config_time: '',
                      is_have_image: 1,
                    },
                  });
                }}
              >
                Thoát
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  renderBody() {
    return <div>{this.renderCheckin()}</div>;
  }

  renderCheckin() {
    const { search } = this.state;
    return (
      <>
        <Col sm={12} md={12}>
          <Row>
            <Col sm={12} className="d-flex justify-content-between">
              <h5>Loại điểm danh</h5>
              <Button variant="success" className="text-light" onClick={() => this.handleChange('modalAdd', true)}>
                Thêm loại điểm danh
              </Button>
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={12}>
          <Row>
            <Col md={6} sm={12}>
              <FormControl
                onKeyPress={(e) => this.handlePressKeyword(e)}
                type="text"
                id=""
                placeholder="Nhập tên loại điểm danh"
                value={search}
                onChange={(e) => this.handleChange('search', e.target.value)}
              />
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={12}>
          <div className="row">
            <Col
              sm={10}
              md={12}
              style={{
                padding: '0px',
                marginTop: '20px',
              }}
            >
              <Row className="table-wrap mx-3">
                <Table striped bordered hover style={{ textAlign: 'center' }}>
                  <thead>
                    <tr>
                      <th>STT</th>
                      <th>Tên loại điểm danh</th>
                      <th>Thời gian</th>
                      <th>Thời gian tối đa(Phút)</th>
                      <th>Loại điểm danh</th>
                      <th>Trạng thái</th>
                      <th>Ngày tạo</th>
                      <th></th>
                    </tr>
                  </thead>
                  {this.renderTableCheckin()}
                </Table>
              </Row>
            </Col>
          </div>
        </Col>
      </>
    );
  }

  renderTableCheckin() {
    const { listCheckin } = this.state;
    return (
      <tbody>
        {listCheckin?.length > 0 ? (
          listCheckin?.map((value, index) => (
            <tr key={index}>
              <td>{index + 1 || '--'}</td>
              <td>{value.name || '--'}</td>
              <td>{convertToTime(value.time) || '--'}</td>
              <td>{value.config_time / 60000 || '--'}</td>
              <td>{value.type === 1 ? 'Checkin' : (value.type === 2 ? 'Checkout' : 'Other') || '--'}</td>
              <td>{value.is_active === 1 ? 'Đang hoạt động' : 'Ngừng hoạt động' || '--'}</td>
              <td>{Intl.DateTimeFormat('vi-VN').format(new Date(value.created_at)) || '--'}</td>
              <td>
                <div className="d-flex justify-content-center align-items-center">
                  <i
                    className="btnEdit fa fa-fw fa-edit"
                    onClick={() => {
                      this.setState(
                        {
                          modalAdd: true,
                          modal: {
                            id: value.id,
                            name: value.name,
                            time: convertToTime(value.time),
                            config_time: value.config_time / 60000,
                            type: String(value.type),
                            is_have_image: value.is_have_image,
                            is_active: value.is_active,
                          },
                        },
                        () => this.handleChange('modalAdd', true)
                      );
                    }}
                  />
                  <i
                    className="btnDelete far fa-trash-alt"
                    onClick={() => {
                      this.setState({
                        modalDelete: true,
                        modal: {
                          id: value.id,
                        },
                      });
                    }}
                  />
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colspan="8">
              <Empty description={<span>Không có dữ liệu</span>} />
            </td>
          </tr>
        )}
      </tbody>
    );
  }

  // renderPagination() {
  //   const itemsCount = this.props.categoryState?.paging?.totalItemCount;
  //   const limit = this.props.categoryState?.paging?.limit;
  //   const { query } = this.state;
  //   return (
  //     <Pagination
  //       itemClass="page-item"
  //       linkClass="page-link"
  //       hideDisabled
  //       activePage={this.state.activePage}
  //       itemsCountPerPage={limit}
  //       totalItemsCount={itemsCount}
  //       pageRangeDisplayed={5}
  //       hideNavigation
  //       onChange={(page) => {
  //         this.setState({
  //           ...this.state,
  //           activePage: page,
  //         });
  //         this.getListCheckin();
  //       }}
  //     />
  //   );
  // }

  render() {
    const { isLoading } = this.state;
    return (
      <>
        {isLoading && <Loading />}
        {this.addModalCheckin()}
        {this.addModalDeleteCheckin()}
        {this.renderBody()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Checkin));
