import { Popover } from 'antd';
import React from 'react';

import { VietnameseString } from '@constants/VietnameseString';

export const renderProductName = products => {
  const el = products
    .map(product => {
      return product.product_name;
    })
    .filter(pro => pro !== undefined);

  if (el.length === 0) {
    return <span>{VietnameseString.not_update}</span>;
  }

  if (el.length === 1) {
    return <span>{el[0] ? el[0] : VietnameseString.not_update}</span>;
  }

  if (el.length === 2) {
    return <span>{`${el[0]}, ${el[1]}`}</span>;
  }

  if (el.length > 2) {
    return (
      <>
        <span>{`${el[0]}, ${el[1]}`}</span>

        <span>
          <Popover
            content={popoverProduct(el)}
            title=""
            trigger="hover"
            placement="bottom"
          >
            <div
              style={{
                display: 'inline-block',
                margin: 0,
                marginLeft: 10,
                padding: '0px 5px',
                position: 'relative',
                backgroundColor: '#EFEFEF',
                border: 'none',
                borderBottomLeftRadius: '4px',
                borderTopLeftRadius: '4px',
                borderBottomRightRadius: '4px',
                borderTopRightRadius: '4px',
                fontWeight: 'normal',
                height: '20px',
              }}
            >
              +{el.length - 2}
            </div>
          </Popover>
        </span>
      </>
    );
  }
};

const popoverProduct = products => {
  return (
    <ul style={{ marginBottom: '0px' }}>
      {products.map((pro, index) => {
        if (index > 1) {
          return (
            <li key={index} style={{ marginLeft: '12px' }}>
              {pro}
            </li>
          );
        }
      })}
    </ul>
  );
};

let secondInDay = 24 * 60 * 60;
let secondInHour = 60 * 60;
let secondInMinute = 60;

export function convertSecondtoTime(mountSecond) {
  if (!mountSecond) {
    return '';
  }
  let days = Math.floor(mountSecond / secondInDay);
  let hours = Math.floor((mountSecond % secondInDay) / secondInHour);
  let minutes = Math.floor(
    ((mountSecond % secondInDay) % secondInHour) / secondInMinute
  );
  let seconds = Math.floor(
    ((mountSecond % secondInDay) % secondInHour) % secondInMinute
  );
  let arrayNotFalsy = [days, hours, minutes, seconds].filter(item => {
    return item;
  });
  return arrayNotFalsy.join(':');
}
