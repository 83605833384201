import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'antd';

// import { faEdit, faTrashAlt } from '@fortawesome/fontawesome-free-regular';
import { VietnameseString } from '@constants/VietnameseString';
import { requestGetNumberPrefix } from '../services/api';
import AddEditNumberPrefix from './AddEditNumberPrefix';
import { formatTime } from '@constants/funcHelper';
import { ROUTER } from '@constants/Constant';

import { ButtonSystemStyle, OneLineTextStyled, TableBorderedAntStyle } from '@styles/Style';
import { greenColor, greenColorHover } from '@styles/Color';

function SMSConfiguration() {
  const history = useHistory();
  const enterpriseId = useParams().id;

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 12,
  });
  const [numberPrefixData, setNumberPrefixData] = useState([]);

  const columns = [
    {
      title: VietnameseString.sms_type,
      dataIndex: 'sms_type',
      key: 'sms_type',
    },
    {
      title: VietnameseString.brand_name,
      dataIndex: 'brand_name',
      key: 'brand_name',
    },
    {
      title: VietnameseString.number_prefix,
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: VietnameseString.account,
      dataIndex: 'account',
      key: 'account',
      width: '200px',
      render: (value) => <OneLineTextStyled style={{ width: '200px', textAlign: 'center' }}>{value}</OneLineTextStyled>,
    },
    {
      title: VietnameseString.dateCreated,
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value) => <>{formatTime(value, 'DD/MM/YYYY')}</>,
    },
    {
      title: VietnameseString.partner,
      dataIndex: 'partner_name',
      key: 'partner_name',
    },
    // {
    //   title: VietnameseString.action,
    //   dataIndex: 'action',
    //   key: 'action',
    //   render: (data, record, index) => {
    //     return (
    //       <div>
    //         <FontAwesomeIconStyle icon={faEdit} color="green" size="lg" />
    //         <Popconfirm
    //           placement="bottomRight"
    //           title={VietnameseString.are_you_sure_you_want_to_delete}
    //           onConfirm={() => {}}
    //           okText={VietnameseString.yes}
    //           cancelText={VietnameseString.no}
    //         >
    //           <FontAwesomeIconStyle icon={faTrashAlt} color="red" size="lg" />
    //         </Popconfirm>
    //       </div>
    //     );
    //   },
    // },
  ];

  const getNumberPrefix = useCallback(async (enterpriseId, page) => {
    try {
      setLoading(true);
      const { data, paging } = await requestGetNumberPrefix({
        enterpriseId,
        page: page.current,
        limit: page.pageSize,
      });

      setNumberPrefixData(data);
      setPaging({
        total: Number(paging.count),
        current: Number(paging.page),
        pageSize: Number(paging.limit),
      });
    } catch (error) {
      console.log('Error getNumberPrefix: ', { error });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getNumberPrefix(enterpriseId, paging);
  }, [paging.current]);

  return (
    <Fragment>
      <Row gutter={[16, 24]} justify="space-between">
        <Col>
          <h4>{VietnameseString.number_prefix_configuration}</h4>
        </Col>

        <Col>
          <ButtonSystemStyle
            bgButtonColor={greenColor}
            bgButtonColorHover={greenColorHover}
            onClick={() => {
              setVisible(true);
            }}
          >
            {VietnameseString.add_number_prefix}
          </ButtonSystemStyle>
        </Col>
      </Row>

      <TableBorderedAntStyle
        style={{ marginTop: '10px' }}
        loading={loading}
        columns={columns}
        dataSource={numberPrefixData}
        onRow={(record) => {
          return {
            onClick: (event) => {
              history.push({
                pathname: ROUTER.SMS_CONFIGURATION_DETAIL,
                search: `id=${record.id}`,
              });
            },
          };
        }}
        pagination={{
          size: 'small',
          ...paging,
          onChange: (page) => {
            setPaging((prev) => ({ ...prev, current: page }));
          },
        }}
      />

      {visible && (
        <AddEditNumberPrefix
          enterpriseId={enterpriseId}
          visible={visible}
          setVisible={(show) => {
            getNumberPrefix(enterpriseId, paging);
            setVisible(show);
          }}
        />
      )}
    </Fragment>
  );
}

export default SMSConfiguration;
