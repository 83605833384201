import { getAxios, handleResult } from '@constants/Api';

export const headerService = {
  getFile: params => {
    return handleResult(
      getAxios.get(`file/listExport`, {
        params,
      })
    );
  },
  readFile: id => {
    return handleResult(
      getAxios.patch(`file/${id}`, {
        is_read: 1,
      })
    );
  },
  downFile: id => {
    return handleResult(
      getAxios.patch(`file/${id}`, {
        is_download: 1,
      })
    );
  },
};
