import React, { Component } from 'react';
import '@styles/RegisterRequest.css';
import { Row, Col, Form } from 'react-bootstrap';

class Step extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: '1',
    };
  }

  render() {
    const { item, index, question } = this.props;
    // console.log('item', item);
    return (
      <>
        <Row className="w-100 align-items-center">
          <Col md={10}>
            <span>
              {String.fromCharCode(65 + index)}. {item.name}
            </span>
          </Col>
          <Col md={2}>
            <Row className="justify-content-end align-items-center">
              <Col>
                <i
                  className="fas fa-times add-btn text-red cursor"
                  onClick={() => this.props.removeOption(question.number - 1, index)}
                ></i>
              </Col>
              <Col>
                <Form.Check
                  type="checkbox"
                  className="px-0 require-check true-check d-inline-block w-25 cursor"
                  checked={item.result == 1}
                  onChange={() => {
                    this.props.updateResult(question.number - 1, index, !item.result);
                    // console.log('ádsadsadsadasdsa', question.number);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default Step;
