import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Row, Col, FormControl, Button, Image } from 'react-bootstrap';
import { ROUTER, STRING, ACCEPT_TYPE } from '@constants/Constant';
import reactotron from 'reactotron-react-js';
import ReactQuill from 'react-quill';
import { createTutorial, getTutorialDetail, updateTutorial } from '@constants/Api';
import { createFormData } from '@utils/createFormData';
import { notifyFail, notifySuccess } from '@utils/notify';
import Loading from '@src/components/Loading';

class AddTutorialScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        title: '',
        description: '',
        // image: '',
        display_index: '',
        content: '',
        status: '',
      },
      image: '',
      isLoading: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    id && this.getTutorialDetail(id);
  }

  modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      [{ align: [] }],
      ['link', 'image', 'video'],
      ['clean'],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
    ],
  };

  formats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'image',
    'video',
  ];

  handleChangeText = (value) => {
    this.setState({ ...this.state, form: { ...this.state.form, content: value } });
  };

  checkValidation = () => {
    const { title, description, image, display_index, status, content } = this.state.form;
    if (title.trim() == '' || description.trim() == '' || image == '' || display_index == '' || content == '') {
      return false;
    }
    return true;
  };

  async createTutorial() {
    reactotron.log(this.props.history);
    const { title, description, image, display_index, status, content } = this.state.form;
    const formdata = createFormData({ ...this.state.form });
    const check = this.checkValidation();
    if (check) {
      createTutorial(formdata)
        .then((res) => {
          this.setState({ isLoading: false });
          Object.keys(this.state.form).forEach((k) =>
            this.setState({
              form: {
                ...this.state.form,
                [k]: '',
              },
            })
          );
          this.props.history.goBack();
          status == 0 ? notifySuccess('Bài viết đã được lưu nháp') : notifySuccess('Bài viết đã được đăng');
        })
        .catch((err) => {
          notifyFail(err.msg);
        });
    } else {
      notifyFail('Vui lòng nhập thông tin bắt buộc');
    }
  }

  async updateTutorial() {
    const { id } = this.props.match.params;

    reactotron.log(this.props.history);
    const { title, description, image, display_index, status, content } = this.state.form;
    const formdata = createFormData({ ...this.state.form, id: id });
    const check = this.checkValidation();
    if (check) {
      updateTutorial(formdata)
        .then((res) => {
          this.setState({ isLoading: false });
          Object.keys(this.state.form).forEach((k) =>
            this.setState({
              form: {
                ...this.state.form,
                [k]: '',
              },
            })
          );
          this.setState({}, () => this.props.history.push(ROUTER.TUTORIAL));
          status == 0 ? notifySuccess('Sửa bài nháp thành công') : notifySuccess('Sửa bài đăng thành công');
        })
        .catch((err) => {
          notifyFail(err.msg);
        });
    } else {
      notifyFail('Vui lòng nhập thông tin bắt buộc');
    }
  }

  async getTutorialDetail(id) {
    try {
      this.setState({ isLoading: true });
      const res = await getTutorialDetail(id);
      reactotron.log(res);
      this.setState({
        form: {
          title: res.data.title,
          description: res.data.description,
          // image: res.data.image,
          display_index: res.data.display_index,
          content: res.data.content,
        },
        image: res.data.image,
      });
      this.setState({ isLoading: false });
    } catch (err) {}
  }

  handleChange = (field, value) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [field]: value || '',
      },
    });
  };

  handleChangeImage = (type, event) => {
    if (!ACCEPT_TYPE.IMAGE.includes(event.target.files[0]?.type)) {
      // alert('Định dạng ảnh không được hỗ trợ. Vui lòng chọn ảnh khác.');
      return;
    }
    this.setState({
      form: {
        ...this.state.form,
        [type]: event.target.files[0],
      },
      [type]: (window.URL || window.webkitURL).createObjectURL(
        // tạo fake path của file trong html input để hiển thị
        event.target.files[0]
      ),
    });
  };

  renderField() {
    const { title, description, display_index } = this.state.form;
    const { image } = this.state;
    return (
      <div>
        <Row className="mt-2">
          <Col md={2} sm={12}>
            <h5 className="mt-1">{STRING.title}*</h5>
          </Col>
          <Col md={10} sm={12}>
            <FormControl
              type="text"
              id=""
              placeholder={STRING.title}
              value={title}
              onChange={(e) => this.handleChange('title', e.target.value)}
            />
          </Col>
          <Col md={2}></Col>
        </Row>

        <Row className="mt-4">
          <Col md={2} sm={12}>
            <h5 className="mt-1">{STRING.description}*</h5>
          </Col>
          <Col md={10} sm={12}>
            <FormControl
              as="textarea"
              rows={5}
              id=""
              placeholder={STRING.description}
              value={description}
              onChange={(e) => this.handleChange('description', e.target.value)}
            />
          </Col>
          <Col md={2}></Col>
        </Row>

        <Row className="mt-4">
          <Col md={2} sm={12}>
            <h5 className="mt-1">{STRING.avatar}*</h5>
          </Col>
          <Col xl={2} md={3} sm={12}>
            <div className="upload-form-wrapper smaller-upload mb-2">
              <label htmlFor="upload1" className="label-upload" style={{ cursor: 'pointer' }}>
                {image.length ? (
                  <img src={image} className="uploaded-pic" width="200" height="200" style={{ objectFit: 'contain' }} />
                ) : (
                  // <Image src={image} roundedCircle style={{ objectFit: 'cover' }}  width="200" height="200"/>
                  <>
                    <i className="fas fa-user-circle upload-icon m-0"></i>
                    <br />
                    <p>Bấm để chọn ảnh đại diện</p>
                  </>
                )}
              </label>
              <input
                type="file"
                id="upload1"
                accept=".jpg,.jpeg,.png"
                style={{ display: 'none' }}
                onChange={(e) => this.handleChangeImage('image', e)}
              />
            </div>
          </Col>
          <Col xl={8} md={7}></Col>
        </Row>

        <Row className="mt-4">
          <Col md={2} sm={12}>
            <h5 className="mt-1">{STRING.displayIndex}*</h5>
          </Col>
          <Col xl={2} md={3} sm={12}>
            <FormControl
              type="number"
              id=""
              placeholder={STRING.displayIndex}
              value={display_index}
              onChange={(e) => this.handleChange('display_index', e.target.value)}
            />
          </Col>
          <Col xl={10} md={9}></Col>
        </Row>

        <Row className="mt-4">
          <Col md={12} sm={12}>
            <h5 className="mt-1">{STRING.content}*</h5>
          </Col>
        </Row>
        <Row style={{ minHeight: '300px' }}>
          <ReactQuill
            value={this.state.form.content}
            onChange={this.handleChangeText}
            modules={this.modules}
            style={{ width: '100%', margin: '0 15px' }}
            formats={this.formats}
          />
          <div style={{ width: '100%', marginTop: '50px' }}>{this.renderButton()}</div>
        </Row>
      </div>
    );
  }

  renderButton() {
    const { id } = this.props.match.params;
    reactotron.log(id);
    return (
      <Row className="p-0 mr-3 mt-4 mb-4">
        <Col className="button-wrapper" style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="primary"
            className="btn"
            onClick={() => {
              this.setState(
                {
                  ...this.state,
                  form: {
                    ...this.state.form,
                    status: 0,
                  },
                },
                id !== undefined ? () => this.updateTutorial() : () => this.createTutorial()
              );
            }}
          >
            <div style={{ color: 'white' }}>{STRING.saveDraft}</div>
          </Button>
          <Button
            variant="success"
            classname="btn"
            onClick={() => {
              this.setState(
                {
                  ...this.state,
                  form: {
                    ...this.state.form,
                    status: 1,
                  },
                },
                id !== undefined ? () => this.updateTutorial() : () => this.createTutorial()
              );
            }}
          >
            <div style={{ color: 'white' }}>{STRING.post}</div>
          </Button>
          <Button variant="danger" classname="btn" onClick={() => this.props.history.push(ROUTER.TUTORIAL)}>
            <div style={{ color: 'white' }}>{STRING.cancel}</div>
          </Button>
        </Col>
      </Row>
    );
  }

  render() {
    // console.log(this.state.content);
    const { isLoading } = this.state;
    const { id } = this.props.match.params;
    return (
      <div className="content-wrapper">
        <div className="content-header">
          {isLoading && <Loading />}
          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
            <Col md className="bg">
              <Row>
                <h2> {id ? 'Sửa bài viết' : 'Thêm bài viết'}</h2>
              </Row>
              {/* <div class="line"></div> */}
              {this.renderField()}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(AddTutorialScreen);
