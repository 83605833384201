import { Input } from 'antd';
import Icon from '@ant-design/icons';
import styled from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
const { Search } = Input;

const Container = styled.div`
  width: 100%;
  background-color: white;
`;

const TypingAutoSearch = ({ onSearchSubmit, isSearchLoading, placeholder, value }) => {
  const [searchKey, setSearchKey] = useState('');
  const [isTyping, setIsTyping] = useState(true);
  const timeOut = useRef(null);

  useEffect(() => {
    if (!isTyping) onSearchSubmit(searchKey);
  }, [isTyping]);

  return (
    <Container>
      <Search
        loading={isSearchLoading}
        value={value || searchKey}
        allowClear
        addonAfter={
          <Icon
            type="close-circle-o"
            onClick={() => {
              //   onSearchSubmit('')
            }}
          />
        }
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            // onSearchSubmit(searchKey)
          }
        }}
        onSearch={(value, event) => {
          //   onSearchSubmit(value)
        }}
        placeholder={placeholder}
        onChange={(e) => {
          setSearchKey(e.target.value);
          if (timeOut.current) {
            setIsTyping(true);
            clearTimeout(timeOut.current);
          }
          timeOut.current = setTimeout(() => {
            setIsTyping(false);
          }, 1000);
          // setParams({ ...params, search: value })
        }}
      />
    </Container>
  );
};

export default TypingAutoSearch;
