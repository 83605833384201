import React, { useEffect, useState } from 'react';
import { Descriptions, Skeleton } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { VietnameseString } from '@constants/VietnameseString';
import { formatPrice, formatTime, getSearchParamsInUrl } from '@constants/funcHelper';
import { requestGetTemplateZNSDetail } from './services/api';
import Wrapper from '@components/ui/wrapper/Wrapper';

import { BreadcrumbAntStyle, IframeStyled, TagHyperlink } from '@styles/Style';

const Heading = styled.h3`
  margin-bottom: 32px;
`;

const DescriptionsStyled = styled(Descriptions)`
  .ant-descriptions-item {
    padding-bottom: 8px;
    text-align: start;

    span.ant-descriptions-item-label {
      font-weight: 700;
    }
  }
`;

const Container = styled.div`
  display: flex;

  div {
    flex: 1;
  }
`;

function TemplateOADetail() {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [templateOADetail, setTemplateOADetail] = useState({});

  const getTemplateZNSDetail = async () => {
    try {
      const searchParamsInUrl = getSearchParamsInUrl();

      const { data } = await requestGetTemplateZNSDetail(searchParamsInUrl.id);

      setTemplateOADetail(data);
      setIsLoading(false);
    } catch (error) {
      console.log('Error getTemplateZNSDetail: ', { error });
    }
  };

  useEffect(() => {
    getTemplateZNSDetail();
  }, []);

  return (
    <div className="content-wrapper">
      <BreadcrumbAntStyle>
        <BreadcrumbAntStyle.Item
          onClick={() => {
            history.goBack();
          }}
        >
          <TagHyperlink style={{ cursor: 'pointer' }}>{VietnameseString.list_of_templates}</TagHyperlink>
        </BreadcrumbAntStyle.Item>

        <BreadcrumbAntStyle.Item>{VietnameseString.template_detail}</BreadcrumbAntStyle.Item>
      </BreadcrumbAntStyle>

      <Wrapper>
        <Heading>{VietnameseString.template_detail}</Heading>
        {isLoading ? (
          <Skeleton active />
        ) : (
          <Container>
            <div>
              <DescriptionsStyled column={1}>
                <Descriptions.Item label={VietnameseString.model_name_zns}>
                  {templateOADetail.template_name}
                </Descriptions.Item>
                <Descriptions.Item label={VietnameseString.template_id}>
                  {templateOADetail.template_id}
                </Descriptions.Item>
                {/* <Descriptions.Item label={VietnameseString.dateCreated}>
                  {formatTime(templateOADetail.created_at)}
                </Descriptions.Item> */}
                <Descriptions.Item label={VietnameseString.status}>
                  {templateOADetail.status ? VietnameseString.activated : VietnameseString.not_activated}
                </Descriptions.Item>
                <Descriptions.Item label={VietnameseString.price}>
                  {formatPrice(templateOADetail.price)} đ
                </Descriptions.Item>
                <Descriptions.Item label={VietnameseString.content_type}>
                  {templateOADetail.template_tag}
                </Descriptions.Item>
                <Descriptions.Item label={VietnameseString.timeout}>
                  {formatPrice(templateOADetail.time_out)} ms
                </Descriptions.Item>
                <Descriptions.Item label={VietnameseString.the_current_quality_of_the_template}>
                  {templateOADetail.template_quality}
                </Descriptions.Item>
              </DescriptionsStyled>
            </div>

            <div>
              <IframeStyled height={380} title={templateOADetail.template_name} src={templateOADetail.preview_url} />
            </div>
          </Container>
        )}
      </Wrapper>
    </div>
  );
}

TemplateOADetail.propTypes = {};

export default TemplateOADetail;
