import {
  addPhoneNumberPicked,
  getIsNewCustomer,
  getShouldCall,
  getStringeeCall,
  getUpdateCall,
} from '@actions/';
import {
  ImportOutlined,
  LoadingOutlined,
  PhoneFilled,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  requestCustomerList,
  requestDeleteMultiCustomer,
  requestGetDataFieldDisplayCustom,
  requestGetDataFieldFilterCustom,
  requestGetExportCustomer,
  requestGetHotline,
} from '@constants/Api';
import {
  CALLING_STATUS,
  GENDER,
  ROUTER,
  BASE_URL_IMAGE,
} from '@constants/Constant';
import { randomColor, setSearchParamsInUrlVer2 } from '@constants/funcHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImportCustomersModal from '@screens/Enterprise/CustomerGroup/modals/ImportCustomersModal';
import {
  blueColor,
  blueColorHover,
  primaryColor,
  primaryColorHover,
} from '@styles/Color';
import {
  BreadcrumbAntStyle,
  ButtonSystemStyle,
  TableAntStyle,
} from '@styles/Style';
import { selectHotlineAuto } from '@utils/callErrorCodeUtil';
import { notifyFail, notifySuccess } from '@utils/notify';
import {
  Avatar,
  Breadcrumb,
  Col,
  Empty,
  Popconfirm,
  Popover,
  Row,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import axios from 'axios';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Wrapper from 'src/components/ui/wrapper/Wrapper';
import {
  requestStartCall,
  requestStartCallWithNumber,
} from 'src/network/web_sale/CallApi';
import styled from 'styled-components';
import DragAndDropConfigurationModal from './componentsInstead/DragAndDropConfigurationModal';
import Filter from './componentsInstead/Filter';
import ModalPickPhone from './componentsInstead/ModalPickPhone';
import TagCustom from './componentsInstead/TagCustom';
import { showPriceFormat } from '@screens/Enterprise/Customer/MockData';

const BlockStyle = styled.div`
  margin: 10px 0px;
`;

const OneLineStyle = styled.div`
  max-width: 150px;

  /* giới hạn số dòng text */
  display: -webkit-box;
  max-height: 3.2rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  line-height: 1.6rem;
`;

const CustomerNameStyle = styled.div`
  font-weight: 700;
  &:hover {
    color: #52c41a;
  }
`;

const FontAwesomeIconStyle = styled(FontAwesomeIcon)`
  &:hover {
    /* color: #52c41a; */
    cursor: pointer;
  }
`;
export default function Customer() {
  const user = useSelector(states => states.userReducer.data);
  const sourceRef = useRef();
  const dispatch = useDispatch();

  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 0,
  });

  const [params, setParams] = useState({
    search: '',
    province_ids: [],
    page: paging.current,
    telesale_id: [],
  });

  const history = useHistory();

  //cấu hình api theo params url
  const windowLocation = window.location.search;
  const searchParams = new URLSearchParams(windowLocation);

  const handleChangeURLSearchParams = (field, value) => {
    searchParams.set(field, JSON.stringify(value));
    history.push({
      pathname: window.location.pathname,
      search: searchParams.toString(),
    });
  };

  useEffect(() => {
    if (windowLocation) {
      // setIsLoadingTable(true);
      const fieldsSearch = searchParams.entries();
      let payload = {};
      for (let field of fieldsSearch) {
        payload = { ...payload, [field[0]]: JSON.parse(field[1]) };
      }
      if (!JSON.stringify(payload).includes('province_ids')) {
        payload = { ...payload, province_ids: [] };
      }
      if (!JSON.stringify(payload).includes('telesale_id')) {
        payload = { ...payload, telesale_id: [] };
      }
      setParams({ ...payload });
    }
  }, []);

  //kết thúc cấu hình api theo params url.
  const [visibleDisplayConfiguration, setVisibleDisplayConfiguration] =
    useState(false);
  const [visibleFilterConfiguration, setVisibleFilterConfiguration] =
    useState(false);
  const [visibleImportCustomerModal, setVisibleImportCustomerModal] =
    useState(false);
  const [isLoadingButtonExport, setIsLoadingButtonExport] = useState(false);
  const [rowSelected, setRowSelected] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [listDisplayFieldConfigGetAPI, setListDisplayFieldConfigGetAPI] =
    useState([]);
  const [listFilterFieldConfigGetAPI, setListFilterFieldConfigGetAPI] =
    useState([]);
  const [phoneStartCall, setPhoneStartCall] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isDisplayPickPhoneNumberModal, setIsDisplayPickPhoneNumberModal] =
    useState(false);
  const [idCustomerGroupSelected, setIdCustomerGroupSelected] = useState();
  const [dataRecord, setDataRecord] = useState([]);
  const [isLoadingGetListCustomer, setIsLoadingGetListCustomer] =
    useState(false);
  const [isLoadingSetColumns, setIsLoadingSetColumns] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);

  const firstColumns = [
    {
      width: '5px',
      title: '',
      dataIndex: '',
      key: '',
      render: record => (
        <>
          <Tooltip title="Gọi">
            {/* <PhoneFilled
              style={{ color: 'green' }}
              onClick={() => {
                setDataRecord(record);
                if (record?.customer_phone?.length) {
                  setIsDisplayPickPhoneNumberModal(true);
                } else {
                  onCallFromListCustomer(record);
                }
              }}
            /> */}
            <Popconfirm
              title="Bạn có chắc chắn muốn gọi?"
              onConfirm={() => {
                setDataRecord(record);
                if (record?.customer_phone?.length) {
                  setIsDisplayPickPhoneNumberModal(true);
                } else {
                  onCallFromListCustomer(record);
                }
              }}
              onCancel={() => {}}
              okText="Gọi"
              cancelText="Huỷ"
            >
              <PhoneFilled style={{ color: 'green' }} />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ];

  const onCallFromListCustomer = async dataRecord => {
    try {
      const listOfHotlines = await requestGetHotline();
      let hotlineDefault = selectHotlineAuto(
        listOfHotlines.data,
        phoneStartCall?.length ? phoneStartCall : dataRecord?.phone
      );
      if (dataRecord?.job_id) {
        const res = await requestStartCall({
          job_id: parseInt(dataRecord?.job_id),
          job_telesale_customer_id: parseInt(
            res?.data?.job_telesale_customer_id
          ),
          hotline: hotlineDefault.toString(),
        });
        dispatch(getIsNewCustomer({ status: false }));
        dispatch(getShouldCall({ status: true }));
        dispatch(getUpdateCall({}));
        dispatch(
          getStringeeCall({
            customerPhone: phoneStartCall?.length
              ? phoneStartCall
              : dataRecord?.phone,
            customerId: dataRecord?.id,
            jobId: dataRecord?.job_id || '',
            callId: res.data.id,
            job_telesale_customer_id: res?.data?.job_telesale_customer_id,
          })
        );
        dispatch(
          addPhoneNumberPicked(
            phoneStartCall?.length ? phoneStartCall : dataRecord?.phone
          )
        );
      } else {
        const res = await requestStartCallWithNumber({
          phone_number: phoneStartCall?.length
            ? phoneStartCall
            : dataRecord?.phone,
          hotline: hotlineDefault.toString(),
        });
        dispatch(getIsNewCustomer({ status: false }));
        dispatch(getUpdateCall({}));
        dispatch(getShouldCall({ status: true }));
        dispatch(
          getStringeeCall({
            customerPhone: phoneStartCall?.length
              ? phoneStartCall
              : dataRecord?.phone,
            customerId: dataRecord?.id,
            jobId: dataRecord?.job_id || '',
            callId: res.data.id,
            job_telesale_customer_id: res?.data?.job_telesale_customer_id,
          })
        );
        dispatch(
          addPhoneNumberPicked(
            phoneStartCall?.length ? phoneStartCall : dataRecord?.phone
          )
        );
      }
      history.push({
        pathname: ROUTER.CALLING_SALE,
        search: `?call_id=${dataRecord?.id}&call_status=${CALLING_STATUS.calling}`,
      });
    } catch (error) {
      console.log('call from customer detail', error);
    }
  };

  const hanldePickPhoneNumberModalCancel = () => {
    setIsDisplayPickPhoneNumberModal(false);
  };

  const handlePickedPhone = formValues => {
    setIsDisplayPickPhoneNumberModal(false);
    onCallFromListCustomer();
  };

  const popoverTag = tags => {
    return tags.map((item, index) => {
      if (index > 0) {
        return (
          <div key={index} style={{ marginBottom: '5px' }}>
            <TagCustom tagName={item.tag_name} tagColor={item.tag_color} />
          </div>
        );
      }
    });
  };

  useEffect(() => {
    setIsLoadingSetColumns(true);
    if (listDisplayFieldConfigGetAPI) {
      const columnCustomer = listDisplayFieldConfigGetAPI
        .filter((col, index) => col.display_config === 1)
        .map((col, index) => {
          switch (col.code) {
            case 'name':
              return {
                width: 250,
                title: col.lable || col.label || '',
                dataIndex: col.code,
                key: col.code,
                render: (text, record, index) => {
                  try {
                    const name = text.trim().split(' ');
                    return (
                      <div
                        style={{ textAlign: 'left' }}
                        onClick={() => {
                          mixpanel.track(`Go to detail customer: ${text}`);
                          history.push({
                            pathname:
                              ROUTER.CUSTOMER_DETAIL_SALE + `/${record.id}`,
                          });
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div>
                            {text && (
                              <Avatar
                                style={{
                                  backgroundColor: randomColor(
                                    name[name.length - 1]
                                      .charAt(0)
                                      ?.toUpperCase()
                                  ),
                                  marginRight: '5px',
                                }}
                                size="small"
                              >
                                {name[name.length - 1].charAt(0)?.toUpperCase()}
                              </Avatar>
                            )}
                          </div>
                          <div>
                            <Tooltip placement="bottomLeft" title={text}>
                              <Typography.Paragraph
                                style={{ margin: '0px' }}
                                ellipsis={true}
                              >
                                <OneLineStyle style={{ maxWidth: '250px' }}>
                                  <CustomerNameStyle>
                                    {text ? text : '--'}
                                  </CustomerNameStyle>
                                </OneLineStyle>
                              </Typography.Paragraph>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    );
                  } catch (error) {
                    console.log();
                  }
                },
              };

            case 'phone':
              return {
                width: 100,
                title: col.lable || col.label || '',
                dataIndex: col.code,
                key: col.code,
                ellipsis: true,
                render: (text, record, index) => {
                  try {
                    return <div>{text ? text : '--'}</div>;
                  } catch (error) {
                    console.log();
                  }
                },
              };

            case 'gender':
              return {
                width: 100,
                title: col.lable || col.label || '',
                dataIndex: col.code,
                key: col.code,
                ellipsis: true,
                render: (value, record, index) => {
                  try {
                    return (
                      <div>
                        {value === GENDER.MAN
                          ? 'Nam'
                          : value === GENDER.WOMEN
                          ? 'Nữ'
                          : '--'}
                      </div>
                    );
                  } catch (error) {
                    console.log();
                  }
                },
              };

            case 'modified_date':
              return {
                width: 200,
                title: col.lable || col.label || '',
                dataIndex: col.code,
                key: col.code,
                ellipsis: true,
                sortDirections: ['ascend', 'descend'],
                sorter: (a, b) =>
                  a.modified_date &&
                  b.modified_date &&
                  moment(a.modified_date).unix() -
                    moment(b.modified_date).unix(),
                render: (text, record, index) => {
                  try {
                    return (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {record.df_customer_action?.icon ? (
                          <Tooltip
                            placement="bottomLeft"
                            title={record.df_customer_action?.name}
                            key={'#ef8737'}
                            color={'#ef8737'}
                          >
                            <img
                              style={{
                                width: '20px',
                                height: '20px',
                                margin: '5px',
                              }}
                              src={record.df_customer_action?.icon}
                              alt=""
                            />
                          </Tooltip>
                        ) : (
                          <div
                            style={{
                              width: '20px',
                              height: '20px',
                              margin: '5px',
                            }}
                          ></div>
                        )}
                        <>
                          {text
                            ? moment(text).format('HH:mm - DD/MM/YYYY')
                            : '--'}
                        </>
                      </div>
                    );
                  } catch (error) {
                    console.log();
                  }
                },
              };

            case 'job_telesale_customers':
              return {
                width: 150,
                title: col.lable || col.label || '',
                dataIndex: col.code,
                key: col.code,
                ellipsis: true,
                render: (profiles = [], record, index) => {
                  const careSales = _.uniqBy(
                    [...profiles, ...record?.telesale_customers],
                    'id'
                  );
                  console.log('careSales: ', careSales);

                  if (careSales.length > 0) {
                    try {
                      return (
                        <Avatar.Group
                          maxCount={2}
                          maxStyle={{
                            color: '#f56a00',
                            backgroundColor: '#fde3cf',
                          }}
                        >
                          {careSales.map((sale, index) => {
                            if (
                              sale.telesale_name !== null &&
                              sale.profile_image !== null
                            ) {
                              const name = sale.telesale_name.trim().split(' ');
                              return (
                                <Tooltip
                                  key={index}
                                  title={sale.telesale_name}
                                  placement="bottom"
                                >
                                  <Avatar
                                    src={
                                      sale.profile_image && sale.profile_image
                                      // `${BASE_URL_IMAGE}${sale.profile_image}`
                                    }
                                    style={
                                      index >= 7
                                        ? { display: 'none' }
                                        : {
                                            backgroundColor: randomColor(
                                              name[name.length - 1]
                                                .charAt(0)
                                                ?.toUpperCase()
                                            ),
                                          }
                                    }
                                  >
                                    {sale.profile_image
                                      ? ''
                                      : name[name.length - 1]
                                          .charAt(0)
                                          ?.toUpperCase()}
                                  </Avatar>
                                </Tooltip>
                              );
                            } else {
                              return (
                                <Avatar
                                  key={index}
                                  style={{ backgroundColor: '#87d068' }}
                                  icon={<UserOutlined />}
                                />
                              );
                            }
                          })}
                        </Avatar.Group>
                      );
                    } catch (error) {
                      console.log();
                    }
                  } else {
                    return <>--</>;
                  }
                },
              };

            case 'customer_tags':
              return {
                width: 150,
                title: col.lable || col.label || '',
                dataIndex: col.code,
                key: col.code,
                render: (tags = [], record, index) => {
                  try {
                    if (tags.length > 0) {
                      return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {tags &&
                            tags.map((tag, index) => {
                              if (index === 0) {
                                return (
                                  <TagCustom
                                    key={index}
                                    tagName={tag.tag_name}
                                    tagColor={tag.tag_color}
                                  />
                                );
                              }
                            })}

                          {tags && tags.length > 1 && (
                            <Popover
                              content={popoverTag(tags)}
                              title=""
                              trigger="hover"
                            >
                              <div
                                style={{
                                  display: 'inline-block',
                                  margin: 0,
                                  marginLeft: 10,
                                  padding: '0px 5px',
                                  position: 'relative',
                                  backgroundColor: '#EFEFEF',
                                  border: 'none',
                                  borderBottomLeftRadius: '4px',
                                  borderTopLeftRadius: '4px',
                                  borderBottomRightRadius: '4px',
                                  borderTopRightRadius: '4px',
                                  fontWeight: 'normal',
                                  height: '20px',
                                }}
                              >
                                +{tags.length - 1}
                              </div>
                            </Popover>
                          )}
                        </div>
                      );
                    } else {
                      return '--';
                    }
                  } catch (error) {
                    console.log();
                  }
                },
              };

            case 'dob':
              return {
                width: 160,
                title: col.lable || col.label || '',
                dataIndex: col.code,
                key: col.code,
                ellipsis: true,
                render: (text, record, index) => {
                  try {
                    return (
                      <>{text ? moment(text).format('DD/MM/YYYY') : '--'}</>
                    );
                  } catch (error) {
                    console.log();
                  }
                },
              };

            case 'province':
              return {
                width: 160,
                title: col.lable || col.label || '',
                dataIndex: col.code,
                key: col.code,
                ellipsis: true,
                render: (province, record, index) => {
                  try {
                    return (
                      <Tooltip placement="bottom" title={province.name}>
                        <Typography.Paragraph
                          style={{ margin: '0px' }}
                          ellipsis={true}
                        >
                          <OneLineStyle>
                            {province ? province.name : '--'}
                          </OneLineStyle>
                        </Typography.Paragraph>
                      </Tooltip>
                    );
                  } catch (error) {
                    console.log();
                  }
                },
              };

            default:
              return {
                width: 150,
                title: col.lable || col.label || '',
                dataIndex: col.code,
                key: col.code,
                ellipsis: true,
                render: (text, record, index) => {
                  try {
                    if (!text) {
                      const data = record.customer_data.find(
                        item =>
                          item.customer_data_field_code.toLowerCase() ===
                          col.code.toLowerCase()
                      );
                      return (
                        <Tooltip placement="bottom" title={data?.value}>
                          <Typography.Paragraph
                            style={{ margin: '0px' }}
                            ellipsis={true}
                          >
                            <OneLineStyle>
                            {data?.value ? showPriceFormat(col.code.toLowerCase(), data?.value)  : '--'}
                            </OneLineStyle>
                          </Typography.Paragraph>
                        </Tooltip>
                      );
                    } else {
                      return (
                        <Tooltip placement="bottom" title={text}>
                          <Typography.Paragraph
                            style={{ margin: '0px' }}
                            ellipsis={true}
                          >
                            <OneLineStyle>
                            {text ? showPriceFormat(col.code.toLowerCase(), text)  : '--'}
                              </OneLineStyle>
                          </Typography.Paragraph>
                        </Tooltip>
                      );
                    }
                  } catch (error) {
                    console.log();
                  }
                },
              };
          }
        });
      setColumns(columnCustomer);
    }
    setIsLoadingSetColumns(false);
  }, [listDisplayFieldConfigGetAPI]);

  // promise api
  const lastPromise = useRef();

  const getDataCustomers = async (payload = params) => {
    setIsLoadingGetListCustomer(true);
    try {
      if (sourceRef.current) {
        sourceRef.current.cancel();
      }
      sourceRef.current = axios.CancelToken.source();

      //ghi giá trị param lên url search
      for (let field of Object.entries(payload)) {
        handleChangeURLSearchParams(field[0], field[1]);
      }

      // fire the api request
      const currentPromise = requestCustomerList(
        { ...payload, limit: 20 },
        {
          cancelToken: sourceRef.current.token,
        }
      ).then(async data => {
        return data;
      });

      // store the promise to the ref
      lastPromise.current = currentPromise;

      // handle the result with filtering
      currentPromise.then(
        result => {
          // lấy kết quả cuối cùng
          if (currentPromise === lastPromise.current) {
            if (!result?.status) {
              setIsLoadingGetListCustomer(false);
              return;
            }

            const dataCustomers = result.data.map(cus => ({
              ...cus,
              key: cus.id,
            }));

            setCustomers(dataCustomers);
            setPaging({
              total: result.paging.totalItemCount,
              current: result.paging.page,
              pageSize: result.paging.limit,
            });
            setIsLoadingGetListCustomer(false);
          }
        },
        e => {
          if (currentPromise === lastPromise.current) {
            console.warn('fetch failure', e);
          }
        }
      );
    } catch (error) {
      // notifyFail('Có lỗi xảy ra trong quá trình lấy dữ liệu');
      setIsLoadingGetListCustomer(false);
      console.log('Error getDataCustomers: ', { error });
    }
    //  finally {
    //   setIsLoadingGetListCustomer(false);
    // }
  };

  const getDataFieldDisplayCustom = async () => {
    try {
      const result = await requestGetDataFieldDisplayCustom();
      setListDisplayFieldConfigGetAPI(result.data);
    } catch (error) {
      console.log('Error getDataFieldDisplayCustom: ', { error });
    }
  };
  useEffect(() => {
    if (!visibleDisplayConfiguration) {
      getDataFieldDisplayCustom();
    }
  }, [visibleDisplayConfiguration]);

  const getDataFieldFilterCustom = async () => {
    try {
      const result = await requestGetDataFieldFilterCustom();
      setListFilterFieldConfigGetAPI(result.data);
    } catch (error) {
      console.log('Error getDataFieldFilterCustom: ', { error });
    }
  };
  useEffect(() => {
    if (!visibleFilterConfiguration) {
      getDataFieldFilterCustom();
    }
  }, [visibleFilterConfiguration]);

  const debounceGetDataCustomer = useCallback(
    _.debounce(getDataCustomers, 500),
    []
  );

  useEffect(() => {
    debounceGetDataCustomer(params);
  }, [params]);

  const downloadExcelFile = async () => {
    try {
      setIsLoadingButtonExport(true);
      const data = await requestGetExportCustomer({
        ...params,
        userIds: rowSelected,
      });
      saveAs(data.data, 'customer_data.xlsx');
    } catch (e) {
      notifyFail(e?.msg);
    } finally {
      setIsLoadingButtonExport(false);
    }
  };

  const handleChangeParamSearchCustomers = (field, value) => {
    setSearchParamsInUrlVer2({ [field]: value, page: 1 });
    mixpanel.track(`filter customer ${field}: ${value}`);
    setParams({
      ...params,
      [field]: value || '',
      page: 1,
    });
  };

  const handleCheckAllRow = () => {
    const selectAllRows = customers.map((r, index) => r.key);
    setRowSelected(selectAllRows);
  };

  useEffect(() => {
    // if (checkAll) {
    handleCheckAllRow();
    // }
  }, [customers]);

  useEffect(() => {
    if (!isLoadingSetColumns && !isLoadingGetListCustomer) {
      setIsLoadingTable(false);
    } else {
      setIsLoadingTable(true);
    }
  }, [isLoadingSetColumns, isLoadingGetListCustomer]);

  const handleDeleteCustomers = async () => {
    try {
      //all_customer = 1 nếu muốn xóa tất cả khách hàng
      //all_customer = 0 nếu không muốn xóa tất cả khách hàng
      const payload = {
        customer_ids: rowSelected,
        all_customer: 0,
      };
      const result = await requestDeleteMultiCustomer(payload);
      notifySuccess(result.msg);
      setRowSelected([]);
    } catch (error) {
      setRowSelected([]);
      notifyFail(error?.msg);
    } finally {
      debounceGetDataCustomer(params);
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <BreadcrumbAntStyle>
          <Breadcrumb.Item>Danh sách khách hàng</Breadcrumb.Item>
        </BreadcrumbAntStyle>

        <Wrapper id="wrapper">
          <BlockStyle>
            <Filter
              params={params}
              setParams={setParams}
              handleChangeParamSearchCustomers={
                handleChangeParamSearchCustomers
              }
              listFilterFieldConfigGetAPI={listFilterFieldConfigGetAPI}
              setVisibleFilterConfiguration={setVisibleFilterConfiguration}
              setVisibleDisplayConfiguration={setVisibleDisplayConfiguration}
              user={user?.user_enterprise_info?.office_name}
            />
          </BlockStyle>

          <BlockStyle>
            Bộ lọc:{' '}
            <p
              style={{
                fontWeight: 'bold',
                display: 'inline-block',
                margin: '0px',
              }}
            >
              {paging.total}
            </p>{' '}
            khách hàng
          </BlockStyle>

          <BlockStyle>
            <Row justify="end" align="middle">
              <Col>
                <Row gutter={[16, 16]} align="middle">
                  <Col>
                    <ButtonSystemStyle
                      bgButtonColor={primaryColor}
                      bgButtonColorHover={primaryColorHover}
                      onClick={() => history.push(ROUTER.ADD_CUSTOMER)}
                    >
                      <UserAddOutlined style={{ marginRight: '10px' }} />
                      Thêm mới
                    </ButtonSystemStyle>
                  </Col>
                  <Col>
                    <ButtonSystemStyle
                      bgButtonColor={blueColor}
                      bgButtonColorHover={blueColorHover}
                      onClick={() => setVisibleImportCustomerModal(true)}
                    >
                      <ImportOutlined style={{ marginRight: '10px' }} />
                      Import
                    </ButtonSystemStyle>
                  </Col>
                </Row>
              </Col>
            </Row>
          </BlockStyle>

          <BlockStyle>
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              spinning={isLoadingTable}
              tip="Đang tải dữ liệu..."
            >
              {columns.length ? (
                <TableAntStyle
                  // columns={[...firstColumns, ...columns]}
                  columns={columns}
                  dataSource={customers}
                  scroll={{
                    x: 'max-content',
                  }}
                  pagination={{
                    showSizeChanger: false,
                    ...paging,
                    onChange: async (page, pageSize) => {
                      setParams({ ...params, page });
                      handleChangeURLSearchParams('page', page);
                    },
                  }}
                  locale={{
                    emptyText: (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={<span>Không có dữ liệu.</span>}
                      />
                    ),
                    triggerDesc: '',
                    triggerAsc: '',
                    cancelSort: '',
                  }}
                />
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Vui lòng chọn cấu hình hiển thị để hiển thị thêm thông tin!"
                />
              )}
            </Spin>
          </BlockStyle>
        </Wrapper>
      </div>

      {/* chọn sđt gọi điện */}
      <ModalPickPhone
        isDisplayPickPhoneNumberModal={isDisplayPickPhoneNumberModal}
        dataCustomerPhone={dataRecord.customer_phone}
        dataCustomerPhoneFirst={dataRecord?.phone}
        setPhoneStartCall={setPhoneStartCall}
        phoneStartCall={phoneStartCall}
        setIsDisplayPickPhoneNumberModal={setIsDisplayPickPhoneNumberModal}
        onCall={onCallFromListCustomer}
        user={user?.user_enterprise_info?.office_name}
      />

      {/* Modal cấu hình hiển thị */}
      {visibleDisplayConfiguration && (
        <DragAndDropConfigurationModal
          titleModal="Cấu hình hiển thị"
          visible={visibleDisplayConfiguration}
          fieldConfig="displayConfig"
          cancelModal={() => setVisibleDisplayConfiguration(false)}
          listFieldConfigGetAPI={listDisplayFieldConfigGetAPI || []}
          getDataCustomers={getDataCustomers}
          user={user?.user_enterprise_info?.office_name}
        />
      )}

      {/* Modal cấu hình bộ lọc */}
      {visibleFilterConfiguration && (
        <DragAndDropConfigurationModal
          titleModal="Cấu hình bộ lọc"
          fieldConfig="filterConfig"
          visible={visibleFilterConfiguration}
          cancelModal={() => setVisibleFilterConfiguration(false)}
          listFieldConfigGetAPI={listFilterFieldConfigGetAPI || []}
          getDataCustomers={getDataCustomers}
          user={user?.user_enterprise_info?.office_name}
        />
      )}

      {/* import khách hàng */}
      <ImportCustomersModal
        visibleImportCustomerModal={visibleImportCustomerModal}
        setVisibleImportCustomerModal={setVisibleImportCustomerModal}
        isRequiredCustomerGroupSelect={false}
        // id nhóm KH được chọn để import
        idCustomerGroupSelected={idCustomerGroupSelected}
        setIdCustomerGroupSelected={setIdCustomerGroupSelected}
        user={user?.user_enterprise_info?.office_name}
      />
    </>
  );
}
