import propTypes from 'prop-types';
import React from 'react';
import { FormStyled } from './style';

/* TODO
    layoutType: label và input theo dạng dọc hay ngang
    formItems: danh sách form item
    formItemLayout: custom layout responsive
    initialValues: giá trị mặc định cho form
    onSubmit: callback when click button htmlType = submit
    children: jsx node
    form: hook useForm ant form
    grid: grid col
*/

const FormModules = ({
  layoutType = 'horizontal',
  formItemLayout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } },
  initialValues,
  onSubmit,
  children,
  form,
}) => {
  const onFinish = values => {
    onSubmit(values);
  };

  const onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };

  return (
    <FormStyled
      {...(layoutType === 'vertical' ? null : formItemLayout)}
      labelAlign="left"
      name="basic"
      autoComplete="off"
      form={form}
      labelWrap
      layout={layoutType}
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      scrollToFirstError
    >
      {children}
    </FormStyled>
  );
};

FormModules.propTypes = {
  layoutType: propTypes.string,
  formItemLayout: propTypes.object,
  initialValues: propTypes.object,
  onSubmit: propTypes.func,
  children: propTypes.node,
  form: propTypes.object,
};

export default FormModules;
