const primaryColor = '#ef8737';
const primaryColorHover = '#ff6f00';
const blueColor = '#0088ff';
const blueColorHover = '#005fff';
const greenColor = '#28a745';
const greenColorHover = '#218838';
const redColor = '#e43232';
const redColorHover = '#ff0000';

export {
  primaryColor,
  primaryColorHover,
  blueColor,
  blueColorHover,
  greenColor,
  greenColorHover,
  redColor,
  redColorHover,
};
