import React, { Component } from 'react';
import { Row, Col, Button, FormControl, FormGroup, Form, Table } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';

import { ROUTER, STATUS, NUMBER } from '@constants/Constant';
import { notifyFail, notifySuccess } from '@utils/notify';
import reactotron from 'reactotron-react-js';
import { Empty, Checkbox } from 'antd';
import Loading from '@src/components/Loading';

import {
  requestCreateDepartment,
  requestSaleList,
  requestGetDepartmentDetail,
  requestUpdateDepartment,
} from '@constants/Api';

class Screen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      description: '',
      listTelesale: [],
      paging: {},
      isLoading: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      this.requestGetDepartmentDetail(id);
    }

    // this.requestSaleList();
  }

  async requestGetDepartmentDetail(id) {
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const res = await requestGetDepartmentDetail({
        department_id: id,
      });
      this.setState({
        ...this.state,
        name: res.data?.name,
        description: res.data?.note,
        isLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async requestCreateDepartment() {
    const { name, description } = this.state;
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      await requestCreateDepartment({
        name: name,
        note: description,
        is_active: STATUS.active,
      });
      this.props.history.goBack();
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifySuccess('Thêm mới thành công')
      );
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async requestUpdateDepartment() {
    const { name, description } = this.state;
    const { id } = this.props.match.params;
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      await requestUpdateDepartment({
        name: name,
        note: description,
        is_active: STATUS.active,
        department_id: id,
      });
      this.props.history.goBack();
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifySuccess('Cập nhật thành công')
      );
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  // async requestSaleList() {
  //   try {
  //     const res = await requestSaleList();
  //     this.setState({
  //       ...this.state,
  //       listTelesale: res.data,
  //       paging: res.paging,
  //     });
  //   } catch (err) {
  //     notifyFail(err.msg);
  //   }
  // }

  handleChange = (field, value) => {
    this.setState({ ...this.state, [field]: value });
  };

  renderTitle() {
    const { id } = this.props.match.params;
    return <h2> {id ? 'Cập nhật thông tin phòng ban' : 'Tạo phòng ban'} </h2>;
  }

  renderButton() {
    const { id } = this.props.match.params;
    return (
      <div className="text-center text-md-right">
        <Col className="button-wrapper">
          <Button
            variant="primary"
            className="text-light"
            onClick={() => {
              id ? this.requestUpdateDepartment() : this.requestCreateDepartment();
            }}
          >
            Lưu
          </Button>
        </Col>
      </div>
    );
  }

  renderField() {
    const { name, description } = this.state;
    return (
      <>
        <FormGroup className="text-left ">
          <Form.Label>Tên phòng ban</Form.Label>
          <FormControl
            type="text"
            placeholder="Nhập tên phòng ban"
            value={name}
            onChange={(e) => this.handleChange('name', e.target.value)}
          ></FormControl>
        </FormGroup>
        <FormGroup className="text-left ">
          <Form.Label>Mô tả</Form.Label>
          <textarea
            placeholder="Nhập mô tả"
            className="form-control"
            rows="5"
            value={description}
            onChange={(e) => this.handleChange('description', e.target.value)}
          ></textarea>
        </FormGroup>
      </>
    );
  }

  renderHeader() {
    return (
      <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 20 }}>
        <Col md className="bg">
          <Row>
            <Col md={5} sm={12} className="p-0">
              {this.renderTitle()}
            </Col>
            <Col md={7} sm={12} className="p-0">
              {this.renderButton()}
            </Col>
          </Row>
          {/* <div className="line m-0"></div> */}
        </Col>
      </Row>
    );
  }

  renderInformation() {
    return (
      <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 20 }}>
        <Col md className="bg">
          <Row>
            <Col md={5} sm={12} className="p-0">
              <h3>Thông tin chung</h3>
            </Col>
          </Row>
          <Row>{this.renderField()}</Row>
        </Col>
      </Row>
    );
  }

  renderListCustomer() {
    const { listTelesale, paging } = this.state;
    return (
      <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 20 }}>
        <Col md className="bg">
          <Row>
            <Col md={5} sm={12} className="p-0">
              <h3>Danh sách nhân viên</h3>
            </Col>
          </Row>
          <Row>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Tên sale</th>
                  <th>Số điện thoại</th>
                  <th>Email</th>
                  <th>Địa chỉ</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {listTelesale?.length > 0 ? (
                  listTelesale.map((item, index) =>
                    this.renderTableRow(item, index + 1 + (paging?.page - 1) * NUMBER.page_limit)
                  )
                ) : (
                  <td colSpan="6">
                    <Empty description="Không có dữ liệu" />
                  </td>
                )}
              </tbody>
            </Table>
          </Row>
        </Col>
      </Row>
    );
  }

  renderTableRow(item, index) {
    return (
      <>
        <tr>
          <td>{index}</td>
          <td>{item?.full_name || '--'}</td>
          <td>{item?.phone || '--'}</td>
          <td>{item?.email || '--'}</td>
          <td>{item?.user_sale_info?.province?.name || '--'}</td>
          <td>
            <Checkbox />
          </td>
        </tr>
      </>
    );
  }

  renderBody() {
    const { id } = this.props.match.params;
    return (
      <div className="content-wrapper">
        <div className="content-header">
          {this.renderHeader()}
          {this.renderInformation()}
          {/* {id ? this.renderListCustomer() : ''} */}
        </div>
      </div>
    );
  }

  render() {
    const { isLoading } = this.state;
    return (
      <>
        {isLoading && <Loading />}
        {this.renderBody()}
      </>
    );
  }
}

export default withRouter(Screen);
