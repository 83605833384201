import { ExportOutlined } from '@ant-design/icons';
import {
  Col,
  DatePicker,
  Descriptions,
  Empty,
  Image,
  Input,
  Row,
  Select,
  Skeleton,
  Space,
  Tooltip,
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import SpinInButton from '@components/SpinInButton';
import { requestGetAllJob } from '@constants/Api';
import { VietnameseString } from '@constants/VietnameseString';
import {
  formatTime,
  getSearchParamsInUrl,
  rmVN,
  setSearchParamsInUrl,
} from '@constants/funcHelper';
import { getListAccountZalo } from '@screens/Enterprise/Settings/zaloOA/services/api';
import { notifySuccess } from '@utils/notify';
import { useCancellableApi } from 'src/hooks/useCancellableApi';
import {
  getReportMsgZaloHistory,
  requestExportZaloSendingHistory,
  requestGetZaloSendingHistory,
} from '../services/api';
import { EyeOutlined } from '@ant-design/icons';

import { greenColor, greenColorHover } from '@styles/Color';
import { ButtonSystemStyle, TableAntStyle } from '@styles/Style';
import { SENDING_ZALO_STATUS, getStatusZaloStyle } from '../Utils/constant';

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const SelectStyled = styled(Select)`
  width: 100%;
`;

const ContainerInformationStyled = styled.div`
  margin: 12px 0px;
`;

const DescriptionsStyled = styled(Descriptions)`
  span.ant-descriptions-item-label {
    width: 50%;
    font-weight: 600;
  }

  & td.ant-descriptions-item {
    padding-bottom: 0;
    padding-top: 6px;
  }
`;

const ActionStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 12px;

  div {
    display: flex;
  }
`;

const layoutRowCol = {
  xxl: 4,
  xl: 6,
  lg: 6,
  md: 8,
  sm: 12,
  xs: 24,
};

function ZaloSendingHistory() {
  const userInfo = useSelector(store => store.userReducer.data);
  const history = useHistory();

  const timeoutRef = useRef();

  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 12,
  });

  const [params, setParams] = useState({
    enterprise_id: userInfo.enterprise_id,
    search: '',
    status: [],
    job_id: [],
    list_zalo_sms_id: [],
    from_date: formatTime(new Date(), 'YYYY-MM-DD'),
    to_date: formatTime(new Date(), 'YYYY-MM-DD'),
    page: paging.current,
    limit: paging.pageSize,
  });

  const [isLoadingStatic, setIsLoadingStatic] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const [zaloSendingHistory, setZaloSendingHistory] = useState([]);
  const [staticMsgSendingHistory, setStaticMsgSendingHistory] = useState({});

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const columns = [
    {
      title: VietnameseString.ordinal_number,
      dataIndex: '',
      key: '',
      render: (_value, _record, index) => (
        <>{(paging.current - 1) * paging.pageSize + index + 1}</>
      ),
    },
    {
      align: 'left',
      title: VietnameseString.customer_name,
      dataIndex: 'customer_infor',
      key: 'customer_infor',
      render: (value, _record, _index) => <>{value.name}</>,
    },
    {
      title: VietnameseString.phoneNumber,
      dataIndex: 'customer_infor',
      key: 'customer_infor',
      render: (value, _record, _index) => <>{value.phone}</>,
    },
    {
      title: 'Tài khoản gửi tin',
      dataIndex: 'account_send',
      key: 'account_send',
      render: (value, _record, _index) => <>{_record.zalo_account.phone}</>,
    },
    {
      title: VietnameseString.status,
      dataIndex: 'status',
      key: 'status',
      render: (value, _record, _index) => {
        const result = getStatusZaloStyle(value);
        return <span style={{ color: result.color }}>{result.label}</span>;
      },
    },
    {
      title: VietnameseString.sending_time,
      dataIndex: 'modified_date',
      key: 'modified_date',
      render: (value, _record, _index) => <>{formatTime(value)}</>,
    },
    {
      width: '200px',
      align: 'left',
      title: 'Nội dung',
      dataIndex: 'content',
      key: 'content',
      render: (value, _record, _index) => (
        <Tooltip title={_record.message} color="black">
          <p
            style={{
              margin: '8px 0',
              maxWidth: '200px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {_record.message}
          </p>
        </Tooltip>
      ),
    },
    {
      width: '200px',
      align: 'center',
      title: 'Ảnh tin nhắn',
      dataIndex: 'image',
      key: 'image',
      render: (value, _record) => (
        <Image
          src={_record.image}
          alt=""
          width={40}
          height="auto"
          preview={{
            mask: <EyeOutlined />,
          }}
        />
      ),
    },
    {
      align: 'left',
      title: VietnameseString.note,
      dataIndex: 'note',
      key: 'note',
      render: (value, _record, _index) => {
        return <>{_record?.response ? _record.response : ''}</>;
      },
    },
  ];

  /**
   * handleChangeParamZaloSendingHistory
   * @param {[field, value]} data
   */
  const handleChangeParamZaloSendingHistory = data => {
    data.push({ page: 1 });
    data.forEach(el => {
      setParams(prevState => ({
        ...prevState,
        ...el,
      }));
      for (const [key, value] of Object.entries(el)) {
        setSearchParamsInUrl(key, value);
      }
    });
    setSelectedRowKeys([]);
  };

  const apiStaticMessengerHistory = useCancellableApi(getReportMsgZaloHistory);
  const apiGetZaloSendingHistory = useCancellableApi(
    requestGetZaloSendingHistory
  );

  const getStaticMsgHistory = useCallback(async params => {
    try {
      setIsLoadingStatic(true);
      const { page, limit, ...rest } = params;

      const { data } = await apiStaticMessengerHistory(rest);
      setStaticMsgSendingHistory(data[0]);
    } catch (error) {
      console.log('Error getStaticMsgHistory: ', { error });
    } finally {
      setIsLoadingStatic(false);
    }
  }, []);

  const debounceGetStaticZNSHistory = useCallback(
    _.debounce(getStaticMsgHistory, 500),
    []
  );

  const getZaloSendingHistory = useCallback(async params => {
    try {
      setIsLoading(true);

      const { data, paging } = await apiGetZaloSendingHistory(params);

      setZaloSendingHistory(data.map(el => ({ ...el, key: el.id })));
      setPaging({
        total: paging.count,
        current: paging.page,
        pageSize: paging.limit,
      });
    } catch (error) {
      console.log('Error getZaloSendingHistory: ', { error });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const debounceGetZNSHistory = useCallback(
    _.debounce(getZaloSendingHistory, 500),
    []
  );

  const exportZaloSendingHistory = useCallback(
    async params => {
      try {
        setIsLoadingButton(true);
        const { page, limit, ...rest } = params;

        await requestExportZaloSendingHistory({
          ...rest,
          list_zalo_sms_id: selectedRowKeys,
        });
      } catch (error) {
        console.log('Error exportZaloSendingHistory: ', { error });
      } finally {
        setIsLoadingButton(false);
      }
    },
    [selectedRowKeys]
  );

  useEffect(() => {
    const searchParamsInUrl = getSearchParamsInUrl();
    setParams(prevState => ({
      ...prevState,
      ...searchParamsInUrl,
    }));
  }, []);

  useEffect(() => {
    debounceGetStaticZNSHistory(params);
    debounceGetZNSHistory(params);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [params]);

  return (
    <Fragment>
      <Filter
        enterprise_id={userInfo.enterprise_id}
        params={params}
        handleChangeParamZaloSendingHistory={
          handleChangeParamZaloSendingHistory
        }
        setSelectedRowKeys={setSelectedRowKeys}
      />

      {isLoadingStatic ? (
        <Space size="large" style={{ marginTop: '10px' }}>
          <Skeleton.Input active />
          <Skeleton.Input active />
          <Skeleton.Input active />
        </Space>
      ) : (
        <Information staticMsgSendingHistory={staticMsgSendingHistory} />
      )}

      <ActionStyled>
        <div>
          {VietnameseString.result}: {paging.total}
        </div>

        <div>
          <ButtonSystemStyle
            bgButtonColor={greenColor}
            bgButtonColorHover={greenColorHover}
            loading={isLoadingButton}
            onClick={() => {
              notifySuccess(
                VietnameseString.please_wait_for_a_few_minutes_to_process
              );
              exportZaloSendingHistory(params);
            }}
          >
            {isLoadingButton ? (
              <SpinInButton />
            ) : (
              <ExportOutlined style={{ marginRight: '10px' }} />
            )}
            {VietnameseString.export_excel}
          </ButtonSystemStyle>
        </div>
      </ActionStyled>

      <TableAntStyle
        columns={columns}
        dataSource={zaloSendingHistory}
        loading={isLoading}
        scroll={{ x: 'max-content' }}
        rowSelection={{
          selectedRowKeys,
          preserveSelectedRowKeys: true,
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
          },
        }}
        pagination={{
          showSizeChanger: false,
          ...paging,
          onChange: async (page, _pageSize) => {
            setParams({ ...params, page });
            setSearchParamsInUrl('page', page);
          },
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>{VietnameseString.no_data}</span>}
            />
          ),
        }}
      />
    </Fragment>
  );
}

function Filter(props) {
  const { enterprise_id, params, handleChangeParamZaloSendingHistory } = props;

  const [zaloAccounts, setZaloAccounts] = useState([]);

  const [selectFilter, setSelectFilter] = useState({
    job: {
      placeholderText: VietnameseString.job,
      allowClear: true,
      mode: 'multiple',
      options: [],
      value: params.job_id,
      onChange: value => {
        handleSetValueInSelectFilter('job', value);
        handleChangeParamZaloSendingHistory([{ job_id: value }]);
      },
    },
    sendingStatus: {
      placeholderText: 'Trạng thái',
      allowClear: true,
      mode: '',
      options: Object.keys(SENDING_ZALO_STATUS).map(stt => ({
        value: SENDING_ZALO_STATUS[stt],
        ...getStatusZaloStyle(SENDING_ZALO_STATUS[stt]),
      })),
      value: params.status,
      onChange: value => {
        handleSetValueInSelectFilter(
          'sendingStatus',
          value === undefined ? [] : value
        );
        handleChangeParamZaloSendingHistory([
          { status: value === undefined ? [] : value },
        ]);
      },
    },
    sendingMsgAccount: {
      showSearch: true,
      placeholderText: 'Tài khoản gửi tin',
      allowClear: true,
      mode: '',
      options: zaloAccounts.map(account => ({
        value: account.id,
        label: account.phone,
        color: 'black',
      })),
      value: params.zalo_account_id,
      onChange: value => {
        handleSetValueInSelectFilter(
          'sendingMsgAccount',
          value === undefined ? [] : value
        );
        handleChangeParamZaloSendingHistory([
          { zalo_account_id: value === undefined ? [] : value },
        ]);
      },
    },
  });
  const handleSetOptionsInSelectFilter = (field, data) => {
    setSelectFilter(prevState => ({
      ...prevState,
      [field]: {
        ...prevState[field],
        options: data,
      },
    }));
  };

  const handleSetValueInSelectFilter = (field, data) => {
    setSelectFilter(prevState => ({
      ...prevState,
      [field]: {
        ...prevState[field],
        value: data,
      },
    }));
  };

  const getJobs = useCallback(async () => {
    try {
      const { data } = await requestGetAllJob();
      const jobs = data.map(i => ({ value: i.id, label: i.name }));
      handleSetOptionsInSelectFilter('job', jobs);
    } catch (error) {
      console.log('Error getJobs: ', { error });
    }
  }, []);

  useEffect(() => {
    getJobs();
  }, []);

  useEffect(() => {
    getListAccountZalo({ search: '' }).then(res => {
      setZaloAccounts(res.data);
      setSelectFilter(prevState => ({
        ...prevState,
        sendingMsgAccount: {
          ...prevState['sendingMsgAccount'],
          options: res.data.map(account => ({
            value: account.id,
            label: account.phone,
            color: 'black',
          })),
        },
      }));
    });
  }, []);

  useEffect(() => {
    const { sendingMsgAccount, sendingStatus, job } = selectFilter;
    sendingStatus.value = params.status;
    job.value = params.job_id;
    sendingMsgAccount.value = params.zalo_account_id;

    setSelectFilter({ job, sendingMsgAccount, sendingStatus });
  }, [params]);

  return (
    <Row gutter={[16, 16]} align="middle">
      <Col {...layoutRowCol}>
        <Search
          placeholder={[VietnameseString.customer_name].join(', ')}
          value={params.search}
          onChange={event => {
            handleChangeParamZaloSendingHistory([
              { search: event.target.value },
            ]);
          }}
        />
      </Col>

      {Object.keys(selectFilter).map(i => {
        const {
          placeholderText,
          allowClear,
          showSearch,
          mode,
          options,
          value,
          onChange,
        } = selectFilter[i];

        return (
          <Col {...layoutRowCol} key={i}>
            <SelectStyled
              showSearch={showSearch}
              allowClear={allowClear}
              maxTagCount={1}
              mode={mode}
              optionFilterProp="children"
              placeholder={placeholderText}
              value={value}
              filterOption={(input, option) => {
                return rmVN(option.children).indexOf(rmVN(input)) >= 0;
              }}
              onChange={onChange}
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>{VietnameseString.no_data}</span>}
                />
              }
            >
              {options.map(option => (
                <Option
                  key={option.value}
                  style={{ color: option.color || 'black' }}
                  value={option.value}
                >
                  {option.label}
                </Option>
              ))}
            </SelectStyled>
          </Col>
        );
      })}

      <Col {...layoutRowCol}>
        <RangePicker
          format={'DD-MM-YYYY'}
          value={[
            params.from_date ? moment(params.from_date) : undefined,
            params.to_date ? moment(params.to_date) : undefined,
          ]}
          onChange={(dates, dateStrings) => {
            if (dates) {
              handleChangeParamZaloSendingHistory([
                {
                  from_date: formatTime(dates[0], 'YYYY-MM-DD'),
                  to_date: formatTime(dates[1], 'YYYY-MM-DD'),
                },
              ]);
            } else {
              handleChangeParamZaloSendingHistory([
                {
                  from_date: '',
                  to_date: '',
                },
              ]);
            }
          }}
        />
      </Col>
    </Row>
  );
}

function Information(props) {
  const { staticMsgSendingHistory } = props;

  return (
    <ContainerInformationStyled>
      <DescriptionsStyled labelStyle={{ width: 'auto' }} title="" column={3}>
        <Descriptions.Item
          style={{ marginBottom: 0 }}
          label="Số tin nhắn đã gửi"
        >
          <span style={{ color: 'green' }}>
            {staticMsgSendingHistory?.zalo_message_success_count}
          </span>
        </Descriptions.Item>

        <Descriptions.Item
          style={{ marginBottom: 0 }}
          label="Số tin nhắn không được gửi"
        >
          <span style={{ color: 'red' }}>
            {staticMsgSendingHistory?.zalo_message_error_count}
          </span>
        </Descriptions.Item>
        <Descriptions.Item
          style={{ marginBottom: 0 }}
          label="Số tin nhắn chờ gửi"
        >
          <span style={{ color: 'orange' }}>
            {staticMsgSendingHistory?.zalo_message_pending_count}
          </span>
        </Descriptions.Item>
      </DescriptionsStyled>
    </ContainerInformationStyled>
  );
}

Filter.propTypes = {
  enterprise_id: PropTypes.number.isRequired,
  params: PropTypes.shape({
    search: PropTypes.string,
    // template_id: PropTypes.arrayOf(PropTypes.number),
    status: PropTypes.arrayOf(PropTypes.number),
    job_id: PropTypes.arrayOf(PropTypes.number),
    from_date: PropTypes.string,
    to_date: PropTypes.string,
  }),
  handleChangeParamZaloSendingHistory: PropTypes.func.isRequired,
};

Information.propTypes = {
  staticMsgSendingHistory: PropTypes.object,
};

export default ZaloSendingHistory;
