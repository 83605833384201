import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import {
  ColStyled,
  LabelStyled,
} from '@screens/Enterprise/ElectronicVoucher/ElectronicVoucherStyled';
import { voucherService } from '@screens/Enterprise/ElectronicVoucher/service';
import { hooks } from '@screens/Enterprise/ElectronicVoucher/store';
import { SET_CONFIG_TYPE } from '@screens/Enterprise/ElectronicVoucher/store/constants';
import UploadComponent from '@screens/Enterprise/ElectronicVoucher/utils/UploadFile';
import { Button, Checkbox, Form, InputNumber, Radio, Row } from 'antd';
import { saveAs } from 'file-saver';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
// configType: 'auto' | 'template' (Tự đông | Thêm theo mẫu);

const ConfigVoucher = ({ form }) => {
  const [state, dispatch] = hooks.usePromotionsStore();
  // const [configType, setConfigType] = React.useState('auto');
  const [loadingDownloadFile, setLoadingDownloadFile] = React.useState(false);

  const { id } = useParams();

  return (
    <div>
      {/* tạo mã */}

      <Form.Item label="Tạo mã" name="created_code">
        <Radio.Group
          value={state?.configType}
          onChange={e =>
            dispatch({
              type: SET_CONFIG_TYPE,
              payload: e.target.value,
            })
          }
        >
          <Radio
            disabled={id && !state?.detail_evoucher?.created_voucher_auto}
            value="auto"
          >
            Tự động
          </Radio>

          <Radio
            disabled={id && state?.detail_evoucher?.created_voucher_auto}
            value="template"
          >
            Thêm theo mẫu
          </Radio>
        </Radio.Group>
      </Form.Item>

      {/* Tạo mã */}
      {state?.configType === 'auto' && (
        <div>
          {/* số lượng mã */}
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập số lượng mã',
              },
              {
                validator: (_, value) => {
                  if (+value < 1) {
                    return Promise.reject(
                      new Error('Số lượng mã phải lớn hơn 0!')
                    );
                  }

                  if (+value > 10000) {
                    return Promise.reject(
                      new Error('Số lượng nhỏ hơn 10000 mã!')
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
            label="Số lượng mã"
            colon={false}
            name="amount"
            normalize={value => value?.toString()?.replace(/[^\wÀ-úÀ-ÿ]/g, '')}
          >
            <InputNumber
              disabled={id}
              style={{ width: '100%' }}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value =>
                value ? value.replace(/\$\s?|(,*)|\./g, '') : ''
              }
              placeholder="Nhập số lượng mã"
            />
          </Form.Item>
          {id && (
            <Form.Item
              rules={[
                {
                  validator: (_, value) => {
                    if (+value < 1) {
                      return Promise.reject(
                        new Error('Số lượng mã phải lớn hơn 0!')
                      );
                    }

                    if (+value > 10000) {
                      return Promise.reject(
                        new Error('Số lượng nhỏ hơn 10000 mã!')
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
              label="Thêm số lượng mã voucher"
              colon={false}
              name="amount_add_new"
              labelCol={{ span: 14 }}
              wrapperCol={{ span: 10 }}
              normalize={value =>
                value?.toString()?.replace(/[^\wÀ-úÀ-ÿ]/g, '')
              }
            >
              <InputNumber
                style={{ width: '100%' }}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value =>
                  value ? value.replace(/\$\s?|(,*)|\./g, '') : ''
                }
                placeholder="Nhập số lượng mã"
              />
            </Form.Item>
          )}
          {/* quy tắc tạo mã */}
          <Row style={{ marginBottom: '6px' }}>
            <LabelStyled>Quy tắc tạo mã:</LabelStyled>
          </Row>

          {/* số ký tự */}
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập số ký tự',
              },
              {
                validator: (_, value) => {
                  if (+value < 1) {
                    return Promise.reject(
                      new Error('Số ký tự phải lớn hơn 0!')
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
            label="Số ký tự"
            colon={false}
            name="amount_code"
            normalize={value => value?.toString()?.replace(/[^\wÀ-úÀ-ÿ]/g, '')}
          >
            <InputNumber
              disabled={id}
              style={{ width: '100%' }}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value =>
                value ? value.replace(/\$\s?|(,*)|\./g, '') : ''
              }
              placeholder="Nhập số ký tự"
            />
          </Form.Item>
          <Form.Item
            label=" "
            colon={false}
            name="start_voucher_code"
            valuePropName="checked"
          >
            <Checkbox disabled={id}>Bắt đầu bằng mã chương trình</Checkbox>
          </Form.Item>

          {/* Bao gồm */}
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn bao gồm',
              },
            ]}
            label="Bao gồm"
            colon={false}
            name="include"
          >
            <Radio.Group disabled={id}>
              <Row gutter={[0, 6]}>
                <ColStyled span={24}>
                  <Radio value="is_number">Ký tự số ngẫu nhiên</Radio>
                </ColStyled>
                <ColStyled span={24}>
                  <Radio value="is_character">Ký tự chữ ngẫu nhiên</Radio>
                </ColStyled>
                <ColStyled span={24}>
                  <Radio value="is_all">Cả chữ và số</Radio>
                </ColStyled>
              </Row>
            </Radio.Group>
          </Form.Item>
        </div>
      )}

      {/* Thêm theo mẫu */}
      {state?.configType === 'template' && (
        <>
          <Row gutter={[0, 12]}>
            <ColStyled span={6}>Tải xuống file mẫu:</ColStyled>
            <ColStyled span={18}>
              <Button
                loading={loadingDownloadFile}
                onClick={async () => {
                  try {
                    setLoadingDownloadFile(true);
                    const data = await voucherService.downloadExFile();
                    saveAs(data.data, 'voucher_data.xlsx');
                    setLoadingDownloadFile(false);
                  } catch (error) {
                    console.error('Exception :' + error);
                  }
                }}
                style={{ display: 'flex', alignItems: 'center' }}
                type="primary"
                icon={<DownloadOutlined />}
              >
                Mẫu excel
              </Button>
            </ColStyled>
            <ColStyled span={6}>Tải lên file excel:</ColStyled>
            <ColStyled span={18}>
              <Form.Item name="file_config">
                <UploadComponent
                  // isUploadServerWhenUploading
                  onSuccessUpload={value => {
                    form.setFieldsValue({
                      file_config: value?.originFileObj,
                    });
                  }}
                  accept=".xlsx, .xls, .csv"
                >
                  <Button
                    style={{ display: 'flex', alignItems: 'center' }}
                    type="primary"
                    icon={<UploadOutlined />}
                  >
                    File excel
                  </Button>
                </UploadComponent>
              </Form.Item>
            </ColStyled>
          </Row>
        </>
      )}
    </div>
  );
};

export default ConfigVoucher;
