import { Button, Space } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { DeleteOutlined, EyeOutlined, UploadOutlined } from '@ant-design/icons';
import PreviewFile from '@components/headerFunc/PreviewFile';
import PreviewFileSystem from '@components/headerFunc/PreviewFileSystem';
import PreviewFileTxt from '@components/headerFunc/PreviewFileTxt';
import { defaultStyles, FileIcon } from 'react-file-icon';

const SpaceStyled = styled(Space)`
  .ant-space-item {
    display: flex;
    align-items: center;
  }

  & .ant-upload-list-item-list-type-text {
    margin-top: 0;
  }
`;

const checkFileViewSystem = fileName => {
  if (fileName === 'pdf') {
    return true;
  }

  return false;
};

export const checkFileView = (fileName, viewExcel) => {
  // check file pdf, word, txt, image, excel

  if (viewExcel && (fileName === 'xlsx' || fileName === 'xls')) {
    return true;
  }

  if (
    fileName === 'pdf' ||
    fileName === 'docx' ||
    fileName === 'doc' ||
    fileName === 'txt' ||
    fileName === 'png' ||
    fileName === 'jpg' ||
    fileName === 'jpeg'
  ) {
    return true;
  }

  return false;
};

export const checkFileShow = fileName => {
  if (fileName === 'txt') {
    return 'txt';
  }

  if (
    fileName === 'xlsx' ||
    fileName === 'xls' ||
    fileName === 'docx' ||
    fileName === 'doc'
  ) {
    return 'excel';
  }

  return 'view';
};

export const UploadItemRender = React.memo(
  ({
    originNode,
    actions,
    fileUpload,
    setFile,
    setFileView,
    setFileType,
    setIsFileUpload,
  }) => {
    return (
      <div style={{ marginBottom: '10px' }}>
        <SpaceStyled align="center">
          {originNode}
          {checkFileView(
            fileUpload?.url
              ? fileUpload?.url?.split('.')?.pop()
              : fileUpload?.name?.split('.')?.pop(),
            fileUpload?.url
          ) && (
            <>
              <Button
                style={{ border: 'none' }}
                onClick={() => {
                  if (fileUpload?.url) {
                    setFile(fileUpload?.url);
                    setFileType(fileUpload?.url?.split('.')?.pop());
                    setFileView(true);

                    // file đã được tải lên server
                    if (
                      checkFileViewSystem(fileUpload?.url?.split('.')?.pop())
                    ) {
                      setIsFileUpload(false);
                    } else {
                      setIsFileUpload(true);
                    }
                  } else {
                    setIsFileUpload(false);

                    const reader = new FileReader();
                    reader.readAsDataURL(fileUpload?.originFileObj);

                    reader.onload = () => {
                      setFile(reader.result);
                      setFileType(
                        fileUpload?.originFileObj?.name?.split('.')?.pop()
                      );
                      setFileView(true);
                    };
                  }
                }}
                icon={
                  <FileIcon
                    extension={
                      fileUpload?.url?.split('.')?.pop() ||
                      fileUpload?.originFileObj?.name?.split('.')?.pop()
                    }
                    {...defaultStyles[
                      fileUpload?.url?.split('.')?.pop() ||
                        fileUpload?.originFileObj?.name?.split('.')?.pop()
                    ]}
                  />
                }
                size="small"
                shape="circle"
              />
            </>
          )}
        </SpaceStyled>
      </div>
    );
  }
);

export const RenderFileViewer = React.memo(
  ({ file, fileView, setFile, setFileView, fileType, isFileUpload }) => {
    return (
      <>
        {file &&
          (checkFileShow(fileType) === 'excel' ? (
            isFileUpload ? (
              <PreviewFile
                fileCurrent={file}
                fileViewCurrent={fileView}
                setFileCurrent={setFile}
                setFileViewCurrent={setFileView}
              />
            ) : (
              <PreviewFileSystem
                fileCurrent={file}
                fileViewCurrent={fileView}
                setFileCurrent={setFile}
                setFileViewCurrent={setFileView}
                fileTypeCurrent={fileType}
              />
            )
          ) : checkFileShow(fileType) === 'txt' ? (
            <PreviewFileTxt
              fileCurrent={file}
              fileViewCurrent={fileView}
              setFileCurrent={setFile}
              setFileViewCurrent={setFileView}
              fileTypeCurrent={fileType}
            />
          ) : (
            <PreviewFileSystem
              fileCurrent={file}
              fileViewCurrent={fileView}
              setFileCurrent={setFile}
              setFileViewCurrent={setFileView}
              fileTypeCurrent={fileType}
            />
          ))}
      </>
    );
  }
);
