import React, { Fragment } from 'react';
import InteractionHistoryHeader from './InteractionHistoryHeader';
import { DescriptionCustomInteractionHistory } from '@styles/Style';
import { Descriptions } from 'antd';

function OppotunitiesPointSaved({ interactionHistory }) {
  const pointSavedInfo = interactionHistory.data;

  return (
    <Fragment>
      <InteractionHistoryHeader interactionHistory={interactionHistory} />
      <DescriptionCustomInteractionHistory column={1}>
        <Descriptions.Item label="Loại ưu đãi">Tích điểm</Descriptions.Item>
        <Descriptions.Item label="Số điểm">
          {pointSavedInfo.point.point} điểm
        </Descriptions.Item>
        <Descriptions.Item label="Loại tích điểm">
          {pointSavedInfo.type_point}
        </Descriptions.Item>
      </DescriptionCustomInteractionHistory>
    </Fragment>
  );
}

export default OppotunitiesPointSaved;
