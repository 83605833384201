import {
  requestGetFullSaleList,
  requestFullListTag,
  requestListProduct,
} from '@constants/Api';
import { STATUS } from '@constants/Constant';
import { formatPrice } from '@constants/funcHelper';

export const mapping = {
  sales: 'sales',
  revenue: 'revenue',
  opportunity: 'opportunity',
  contractClosing: 'contract',
  call: 'call',
  newCustomer: 'new_customer',
};

export const getAllTelemarketing = async () => {
  try {
    const result = await requestGetFullSaleList();
    const telemarketing =
      result?.data?.map(sale => ({
        ...sale,
        value: sale.id,
        label: sale.full_name,
      })) || [];

    return telemarketing;
  } catch (error) {}
};

export const getAllTag = async () => {
  try {
    const result = await requestFullListTag({});
    return result.data;
  } catch (error) {}
};

export const getAllProduct = async () => {
  try {
    const result = await requestListProduct();
    return result.data;
  } catch (error) {}
};

export const handleSetupRawDataDetailKPI = (
  systemKPI,
  allTag,
  allProduct,
  rawData,
  effectiveTime = null
) => {
  try {
    const newSystemKPI = systemKPI.map(el => {
      if (rawData.data[mapping[el.key]] === null) {
        return { ...el, active: STATUS.un_active };
      } else {
        return el;
      }
    });

    let pro = [];

    if (!rawData.data?.productTargets) {
      pro =
        rawData.data.kpi_other?.products.map(p => {
          const res = allProduct.find(el => el.id === p.id);
          if (res !== undefined) {
            return {
              id: res.id,
              name: res.name,
              kpi: p.kpi,
            };
          } else return;
        }) || [];
    } else {
      pro = rawData.data.productTargets.map(p => {
        const res = allProduct.find(el => el.id === p.id);
        if (res !== undefined) {
          return {
            id: res.id,
            name: res.name,
            kpi: p.kpi,
            current: p.current,
          };
        } else return;
      });
    }

    let t = [];

    if (!rawData.data?.tagTargets) {
      t =
        rawData.data.kpi_other?.tags.map(tag => {
          const res = allTag.find(el => el.id === tag.id);
          if (res !== undefined) {
            return {
              id: res.id,
              name: res.name,
              color: res.color,
              kpi: tag.kpi,
            };
          } else return;
        }) || [];
    } else {
      t = rawData.data.tagTargets.map(tag => {
        const res = allTag.find(el => el.id === tag.id);
        if (res !== undefined) {
          return {
            id: res.id,
            name: res.name,
            color: res.color,
            kpi: tag.kpi,
            current: tag.current,
          };
        } else return;
      });
    }

    return {
      detailDataSetupKPI: {
        telemarketingId: [rawData.data.telesale_id],
        effectiveTime:
          effectiveTime === null ? rawData.data.effective_time : effectiveTime,
        autoExtend: rawData.data.auto_extend,
        workDay: rawData.data.work_day,
        contractClosing: rawData.data.contract,
        newCustomer: rawData.data.new_customer,
        opportunity: rawData.data.opportunity,
        // revenue: formatPrice(rawData.data.revenue),
        // sales: formatPrice(rawData.data.sales),
        revenue: rawData.data.revenue,
        sales: rawData.data.sales,
        call: rawData.data.call,
        kpiOther: {
          tags: [],
          products: [],
        },
      },
      detailDataCurrentSetupKPI: {
        contractClosing: rawData.data.total_contract,
        newCustomer: rawData.data.total_new_customer,
        opportunity: rawData.data.total_opp,
        revenue: rawData.data.total_revenue,
        sales: rawData.data.total_sales,
        call: rawData.data.total_call,
      },
      newSystemKPI,
      productsSelected: pro.filter(v => v !== undefined),
      tagsSelected: t.filter(v => v !== undefined),
      telemarketingDetail: rawData.user_info,
      telemarketingID: rawData.data?.telesale_id,
    };
  } catch (error) {//
  } finally {
    //
  }
};

export function convertDataWithPrefix(data, prefix) {
  let result = {};
  console.log('data', data);
  if (Array.isArray(data) && data.length > 0) {
    data.forEach(item => {
      result = {
        ...result,
        [`${prefix}${item?.id}`]: `${item?.result}/${item?.kpi}`,
        [`${prefix}${item?.id}total`]: Number(item?.result) + Number(item?.kpi),


      };
    });
  }
  return result;
}
export const convertColor = (valueFirst, valueSecond) => {
  if (parseInt(valueFirst) < parseInt(valueSecond)) {
    return 'tomato';
  }
  if (parseInt(valueFirst) === parseInt(valueSecond)) {
    return 'steelblue';
  }
  if (parseInt(valueFirst) > parseInt(valueSecond)) {
    return 'green';
  }
};
