import { VietnameseString } from '@constants/VietnameseString';
import React from 'react';
import {
  MessageContentStyled,
  MultimediaAreaStyled,
} from './ScriptOfSmsOrZns';
import { Image } from 'antd';
import { EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { isImage } from '@constants/funcHelper';

const PreviewSelectData = ({ templatesMessageSelected }) => {
  console.log(`templatesMessageSelected`, templatesMessageSelected);
  return (
    <div>
      {templatesMessageSelected && (
        <div>
          {`Xem trước tin nhắn: ${templatesMessageSelected?.count_character}/${templatesMessageSelected?.count_message} SMS`}
        </div>
      )}

      {templatesMessageSelected && (
        <MessageContentStyled>
          <p>{templatesMessageSelected?.message}</p>

            <MultimediaAreaStyled>
              {
                templatesMessageSelected?.message_template_media?.map(
                  (media, index) => {
                    return (
                      <div key={index} style={{marginRight: "8px"}}>
                        {isImage(media.media_url) ? (
                          <Image
                            width={250}
                            key={index}
                            height={250}
                            src={media.media_url}
                            preview={{
                              mask: (
                                <>
                                  <EyeOutlined />
                                  {VietnameseString.view_image}
                                </>
                              ),
                            }}
                          />
                        ) : 
                        null}
                      </div>
                    );
                  }
                )
              }
            </MultimediaAreaStyled>
   
        </MessageContentStyled>
      )}
    </div>
  );
};

export default PreviewSelectData;
