import { ClockCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import React from 'react';

export default function SubNameJob(timeCreate, isCallBot = 0) {
  return (
    <span
      style={{
        fontSize: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        // lineHeight: '30px',
        height: '24px',
      }}
    >
      <p style={{ display: 'flex', alignItems: 'center', marginBottom: '2px', fontSize: '12px' }}>
        {isCallBot === 1 && (
          <>
            <i className="fas fa-headset" style={{ paddingRight: '3px', fontSize: '18px' }}></i> Call Bot
          </>
        )}
      </p>
      <p style={{ display: 'flex', alignItems: 'center', marginBottom: '2px', fontSize: '12px' }}>
        <ClockCircleOutlined style={{ paddingRight: '3px' }} /> {moment(timeCreate).format('DD/MM/YYYY')}
      </p>
    </span>
  );
}
