import React, { Fragment, useEffect, useState } from 'react';
import { Checkbox, Col, Form, Input, message, Modal, Row, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { VietnameseString } from '@constants/VietnameseString';
import {
  requestCreateTemplateMessage,
  requestGetParameterWithTemplate,
  requestGetTelecom,
} from '../services/api';
import { createFormData } from '@utils/createFormData';
import { smsCharCount } from '@utils/smsCharCount';
import { rmVN } from '@constants/funcHelper';

import {
  ButtonSystemStyle,
  ButtonTransparentStyle,
  TableBorderedAntStyle,
} from '@styles/Style';
import {
  greenColor,
  greenColorHover,
  primaryColor,
  primaryColorHover,
} from '@styles/Color';
import UploadComponent from './upload';
import UploadImageSms from './upload';
import { voucherService } from '@screens/Enterprise/ElectronicVoucher/service';
import { getFileType } from '../utils';
import { isVietnamese } from './upload/fn';

const { TextArea } = Input;

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const FormStyled = styled(Form)`
  width: 100%;

  &&& {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
`;

const WrapperUpload = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  overflow-x: scroll;
  overflow-y: hidden;
  border: 1px solid #d9d9d9;

  ::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    border: 6px solid #d5d5d5;
    border-radius: 100px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 100px;
    background-color: lightgray;
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: #a891a8;
  }
`;

const UploadAreaStyled = styled.div`
  margin: 0px;
  padding: 12px;
  border-radius: 4px;

  white-space: nowrap;

  ::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    border: 6px solid #d5d5d5;
    border-radius: 100px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 100px;
    background-color: lightgray;
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: #a891a8;
  }
`;

const UploadStyled = styled(Upload)`
  &&& {
    .ant-upload-list-picture-card .ant-upload-list-item-info {
      ::before {
        left: 0;
        top: 0;
      }
    }

    div .ant-upload.ant-upload-select-picture-card {
      margin-right: 0px;
      margin-bottom: 0px;
    }
  }
`;

const maxLengthContainsCharactersASCII = 160;
const maxLengthContainsCharactersASCIIWithMultimediaMessage = 1000;
const maxLengthContainsCharactersUnicode = 70;

const ACCEPTED_FILE_SIZE = {
  VIETTEL: 300,
  MOBIFONE: 2000,
};

const ACCEPTED_FILE_VIDEO = ['MOBIFONE'];

function AddTemplate(props) {
  const {
    visible,
    setVisible,
    numberPrefixId,
    enterpriseId,
    codeTypeOfSMS,
    numberPrefix,
    isCheckEvoucher,
    idEvoucher,
  } = props;
  
  const [form] = Form.useForm();
  console.log("numberPrefix",numberPrefixId)
  const [acceptedFileSize, setAcceptedFileSize] = useState(0);
  const [isAcceptVideo, setIsAcceptVideo] = useState(true);

  const [visibleConfigurationModal, setVisibleConfigurationModal] =
    useState(false);

  const [messageDetail, setMessageDetail] = useState({
    characterCount: 0,
    messageCount: 0,
  });
  const [parameterWithTemplate, setParameterWithTemplate] = useState([]);

  const [fileList, setFileList] = useState([]);
  const [fileListPreview, setFileListPreview] = useState([]);

  // có check mẫu voucher không
  const checkSendSampleVoucher = Form.useWatch('is_sample_voucher', form);

  // handle call detail evoucher
  const [detailEvoucher, setDetailEvoucher] = useState(null);

  //track value message form
  const messageValue = Form.useWatch('messageContent', form);
  // let showcheckbox = parameterWithTemplate.some((item) => messageValue?.includes(item.code))
  let showcheckbox = messageValue?.includes("[voucher_code]")
  // console.log('messageValue' , messageValue, parameterWithTemplate.some((item) => messageValue?.includes(item.code)))
  React.useEffect(() => {
    if (!idEvoucher) return;

    voucherService.detailEVoucher(idEvoucher).then(async res => {
      setDetailEvoucher(res?.data);
    });
  }, [idEvoucher]);

  const [loadingButtonCreateTemplate, setLoadingButtonCreateTemplate] =
    useState(false);

  const handleCancelModal = () => {
    form.resetFields();
    setFileList([]);
    setFileListPreview([]);
    setVisible(false);
  };

  const columns = [
    {
      title: VietnameseString.parameter_code,
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: VietnameseString.parameter_name,
      dataIndex: 'label',
      key: 'label',
    },
  ];

  const uploadImage = options => {
    const { onSuccess, file } = options;

    let fileListSize = file.size;

    if (fileListPreview.length) {
      (checkSendSampleVoucher ? fileList.slice(1) : fileList).forEach(
        element => {
          fileListSize += element.size;
        }
      );
    }

    if (fileListSize / 1024 > acceptedFileSize) {
      message.warn(
        VietnameseString.the_file_size_is_larger_than_the_allowed_size
      );
      return;
    }

    const result = window.URL.createObjectURL(file);
    setFileList(prev => [...prev, file]);
    setFileListPreview(prev => [
      ...prev,
      {
        uid: file.uid,
        name: file.name,
        status: 'done',
        url: result,
        type: file.type,
      },
    ]);
    onSuccess('ok');
  };

  const getParameterWithTemplate = async enterpriseId => {
    try {
      const { data } = await requestGetParameterWithTemplate({ enterpriseId });
      setParameterWithTemplate(
        data.map(el => ({ id: el.id, code: `[${el.code}]`, label: el.label }))
      );
    } catch (error) {
      console.log('Error getParameterWithTemplate: ', { error });
    }
  };

  const handleLogicReturnAcceptedFileSize = async numberPrefix => {
    try {
      const { data } = await requestGetTelecom();

      const telecom = data.find(el => el.phone === numberPrefix);

      if (telecom) {
        setAcceptedFileSize(
          ACCEPTED_FILE_SIZE[telecom.description.toUpperCase()]
        );
        setIsAcceptVideo(
          ACCEPTED_FILE_VIDEO.includes(telecom.description.toUpperCase())
        );
      }
    } catch (error) {
      console.log('Error getParameterWithTemplate: ', { error });
    }
  };

  const handleCreateTemplate = async values => {
    try {
      setLoadingButtonCreateTemplate(true);

      let data = {
        message: String(values.messageContent).trim(),
        count_character: messageDetail.characterCount,
        count_message: messageDetail.messageCount,
        enterprise_id: enterpriseId,
        is_sample_voucher: values?.is_sample_voucher ? 1 : 0,
      };
      let checkTextIsVietnamese = isVietnamese(data.message)
      if(numberPrefixId == 57 || numberPrefixId == 53) { //với tin nhắn đầu số, thương hiệu
        if(!checkTextIsVietnamese &&data.message?.length > 160 || checkTextIsVietnamese&& data.message?.length>70  ) {
          message.warning("Số ký tự đã vượt quá số lượng ký tự cho phép");
          setLoadingButtonCreateTemplate(false)
          return 
        }
      }

      if(numberPrefixId == 59 &&data.message?.length > 1000) { //đầu số đa phương tiện select
        message.warning("Số ký tự đã vượt quá số lượng ký tự cho phép");
        setLoadingButtonCreateTemplate(false)
        return 
      }
   

      const formData = createFormData(data);

      if (codeTypeOfSMS === 'multimedia') {
        fileList.sort((f1, f2) => {
          const f1Type = f1.type.toUpperCase();
          const f2Type = f2.type.toUpperCase();
          if (f1Type > f2Type) {
            return -1;
          }
          if (f1Type < f2Type) {
            return 1;
          }
          return 0;
        });

        fileList.forEach(file => {
          formData.append('image', file);
        });
      }

      await requestCreateTemplateMessage({ numberPrefixId, data: formData });
      message.success('Thành công. Mẫu tin nhắn cần đợi được duyệt!');
      handleCancelModal();
    } catch (error) {
      message.error(error.msg);
      setLoadingButtonCreateTemplate(false);
      console.log('Error handleCreateTemplate: ', { error });
    }
  };

  useEffect(() => {
    getParameterWithTemplate(enterpriseId);
    handleLogicReturnAcceptedFileSize(numberPrefix);
  }, []);
  // console.log("fileList", fileList)
  // console.log("fileList111", fileListPreview)
  console.log('parameterWithTemplate', parameterWithTemplate)
  return (
    <Fragment>
      <Modal
        title={VietnameseString.add_template}
        maskClosable={false}
        visible={visible}
        footer={null}
        onCancel={handleCancelModal}
      >
        <HeaderStyled>
          <div>
            {messageDetail.characterCount} {VietnameseString.character}/
            {messageDetail.messageCount} SMS
          </div>

          <ButtonSystemStyle
            bgButtonColor={primaryColor}
            bgButtonColorHover={primaryColorHover}
            onClick={() => {
              setVisibleConfigurationModal(true);
            }}
          >
            {VietnameseString.add_parameter}
          </ButtonSystemStyle>
        </HeaderStyled>

        <FormStyled
          layout="vertical"
          form={form}
          onFinish={handleCreateTemplate}
          onValuesChange={(changedValues, allValues) => {
            const regex = /\[.*?\]/g;

            if (Object.keys(changedValues)[0] === 'messageContent') {
              // if (codeTypeOfSMS === 'multimedia') {
              //   const characterCount = String(changedValues.messageContent.replace(regex, '')).length;

              //   let messageCount = Math.ceil(characterCount / maxLengthContainsCharactersASCIIWithMultimediaMessage);

              //   setMessageDetail({
              //     characterCount,
              //     messageCount: messageCount,
              //   });
              // } else {
              const message = changedValues.messageContent;

              const [gsm7bitUnits, utf16codeUnits] = smsCharCount(
                message.replace(regex, '')
              );

              if (gsm7bitUnits !== null) {
                let messageCount = Math.ceil(
                  gsm7bitUnits / maxLengthContainsCharactersASCII
                );

                setMessageDetail({
                  characterCount: gsm7bitUnits,
                  messageCount: messageCount,
                });
              } else {
                let messageCount = Math.ceil(
                  utf16codeUnits / maxLengthContainsCharactersUnicode
                );

                setMessageDetail({
                  characterCount: utf16codeUnits,
                  messageCount: messageCount,
                });
              }
              // }
            }
          }}
        >
          <Form.Item
            name="messageContent"
            rules={[
              {
                required: true,
                message: VietnameseString.please_enter_template_sms,
              },
              {
                whitespace: true,
                message: VietnameseString.please_enter_template_sms,
              },
            ]}
          >
            <TextArea
              id="message-content"
              rows={4}
              onChange={event => {
                // if (codeTypeOfSMS === 'multimedia') {
                //   form.setFieldsValue({
                //     messageContent: rmVN(event.target.value, {
                //       methodRemovesWhitespace: false,
                //       methodRemoveSpecialCharacters: false,
                //       toUpperCase: false,
                //     }),
                //   });
                // } else {
                form.setFieldsValue({ messageContent: event.target.value });
                // }
              }}
            />
          </Form.Item>

          {codeTypeOfSMS === 'multimedia' && (
            <>
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    padding: '10px 0',
                  }}
                >
                  <strong>{VietnameseString.multimedia}</strong>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    paddingBottom: '10px',
                  }}
                >
                  {showcheckbox && (
                    <Form.Item valuePropName="checked" name="is_sample_voucher">
                      <Checkbox
                        style={{ padding: '4px 0' }}
                        disabled={
                          // !idEvoucher || !detailEvoucher?.e_voucher_image
                          false
                        }
                        onChange={event => {
                          // if (event.target.checked) {
                          //   if (detailEvoucher?.e_voucher_image) {
                          //     getFileType(detailEvoucher?.e_voucher_image).then(
                          //       result => {
                          //         setFileListPreview(prev => [
                          //           {
                          //             uid: Math.random().toString(),
                          //             name: detailEvoucher?.name,
                          //             status: 'done',
                          //             url: detailEvoucher?.e_voucher_image,
                          //             type: result?.fileType,
                          //           },
                          //           ...prev,
                          //         ]);
                          //         setFileList(prev => [result?.blob, ...prev]);
                          //       }
                          //     );
                          //   }
                          // } else {
                          //   setFileListPreview(prev => prev && prev.slice(1));
                          //   setFileList(prev => prev && prev.slice(1));
                          // }
                        }}
                      >
                        <span style={{ fontWeight: 500 }}>
                          Gửi kèm mẫu voucher
                        </span>
                      </Checkbox>
                    </Form.Item>
                  )}
                </div>
              </div>

              <Form.Item>
                <WrapperUpload>
                  <UploadAreaStyled>
                    <UploadStyled
                      style={{ display: 'flex' }}
                      listType="picture-card"
                      accept="image/*"
                      fileList={fileListPreview.filter(el =>
                        String(el.type).includes('image')
                      )}
                      customRequest={uploadImage}
                      onRemove={file => {
                        const removalResultsOfFileList = fileList.filter(
                          item => item.uid !== file.uid
                        );
                        const removalResultsOfFileListPreview =
                          fileListPreview.filter(item => item.uid !== file.uid);
                        setFileList(removalResultsOfFileList);
                        setFileListPreview(removalResultsOfFileListPreview);
                      }}
                    >
                      <div>
                        <PlusOutlined />
                        <div
                          style={{
                            marginTop: 8,
                          }}
                        >
                          Ảnh
                        </div>
                      </div>
                    </UploadStyled>
                  </UploadAreaStyled>

                  {isAcceptVideo ? (
                    <UploadAreaStyled>
                      <UploadStyled
                        listType="picture-card"
                        accept="video/*"
                        fileList={fileListPreview.filter(el =>
                          String(el.type).includes('video')
                        )}
                        customRequest={uploadImage}
                        onRemove={file => {
                          const removalResultsOfFileList = fileList.filter(
                            item => item.uid !== file.uid
                          );
                          const removalResultsOfFileListPreview =
                            fileListPreview.filter(
                              item => item.uid !== file.uid
                            );
                          setFileList(removalResultsOfFileList);
                          setFileListPreview(removalResultsOfFileListPreview);
                        }}
                      >
                        <div>
                          <PlusOutlined />
                          <div
                            style={{
                              marginTop: 8,
                            }}
                          >
                            Video
                          </div>
                        </div>
                      </UploadStyled>
                    </UploadAreaStyled>
                  ) : null}
                </WrapperUpload>
              </Form.Item>
            </>
          )}

          <Form.Item {...tailLayout} style={{ marginTop: '12px' }}>
            <ButtonSystemStyle
              htmlType="submit"
              bgButtonColor={greenColor}
              bgButtonColorHover={greenColorHover}
              loading={loadingButtonCreateTemplate}
              style={{ marginRight: '12px' }}
            >
              {VietnameseString.save}
            </ButtonSystemStyle>

            <ButtonTransparentStyle
              htmlType="button"
              onClick={handleCancelModal}
            >
              {VietnameseString.cancel}
            </ButtonTransparentStyle>
          </Form.Item>
        </FormStyled>
      </Modal>
   
      {visibleConfigurationModal && (
        <Modal
          title={VietnameseString.parameter_configuration}
          width={500}
          maskClosable={false}
          visible={visibleConfigurationModal}
          footer={null}
          onCancel={() => {
            setVisibleConfigurationModal(false);
          }}
        >
          <TableBorderedAntStyle
            columns={columns}
            dataSource={parameterWithTemplate}
            onRow={record => {
              return {
                onClick: event => {
                  const element = document.getElementById('message-content');

                  const cursorPositionInCharacter = element.value.slice(
                    0,
                    element.selectionStart
                  ).length;

                  const messageContent =
                    form.getFieldValue('messageContent') || '';

                  const character = [
                    messageContent.slice(0, cursorPositionInCharacter),
                    messageContent.slice(cursorPositionInCharacter),
                  ];

                  console.log("character", character, character[0] + record.code + character[1])
                  form.setFieldsValue({
                    messageContent: character[0] + record.code + character[1],
                  });
                  setVisibleConfigurationModal(false);
                },
              };
            }}
            pagination={null}
          />
        </Modal>
      )}
    </Fragment>
  );
}

AddTemplate.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  numberPrefixId: PropTypes.number.isRequired,
  enterpriseId: PropTypes.number.isRequired,
  codeTypeOfSMS: PropTypes.string.isRequired,
  numberPrefix: PropTypes.string.isRequired,
};

export default AddTemplate;
