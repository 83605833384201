import React, { Component } from 'react';
import '@styles/RegisterRequest.css';
import { Row, Col, FormControl, Button, Modal, Form } from 'react-bootstrap';
import Option from '@screens/Enterprise/Job/Add/Component/Test/_Option';
import { Popconfirm } from 'antd';
class Step extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: '1',
    };
  }

  render() {
    const { question } = this.props;
    return (
      <>
        <div
          className="assessment step-frame mb-4"
          style={{
            padding: '10px 5px',
            backgroundColor: '#ffffff',
            borderRadius: '12px',
            boxShadow: '0 20px 80px rgba(0, 0, 0, 0.2)',
            marginTop: '10px',
          }}
        >
          <Row className="w-100">
            <div className="alert alert-secondary border-0 w-100 mx-3 mt-2 mb-3 4">
              {`Câu ${question.number}: ${question.name}`}
            </div>
          </Row>

          {question.job_exam_answers?.length > 0 &&
            question.job_exam_answers.map((item, index) => (
              <Option
                item={item}
                question={question}
                index={index}
                key={index}
                removeOption={this.props.removeOption}
                updateResult={this.props.updateResult}
              />
            ))}

          <Row>
            <Col
              xs={12}
              className="text-blue cursor"
              onClick={() => this.props.showModal('addOptionModal', true, question.number - 1)}
            >
              <i className="fas fa-plus mr-1 "></i>
              <span>Thêm lựa chọn</span>
            </Col>
          </Row>
          <Row className="border-top py-2 mt-2">
            <Col xs={12} className="text-right">
              <a
                xs={12}
                className="text-blue cursor"
                onClick={() => {
                  console.log('edit');
                  this.props.editExam(true, question.number - 1, question);
                }}
              >
                <i className="fas fa-edit  add-btn mr-2"></i>
              </a>

              <Popconfirm
                title="Bạn muốn nhân bản câu hỏi này?"
                onConfirm={() => this.props.duplicateQuestion(question)}
                okText="Có"
                cancelText="Không"
              >
                <i className="far fa-copy add-btn px-2" style={{ color: 'gray' }}></i>
              </Popconfirm>
              <Popconfirm
                title="Bạn muốn xóa câu hỏi này"
                onConfirm={() => this.props.removeQuestion(question.number - 1)}
                okText="Có"
                cancelText="Không"
              >
                <i className="far fa-trash-alt add-btn px-2" style={{ color: 'red' }}></i>
              </Popconfirm>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Step;
