import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { Empty } from 'antd';

const TableView = ({ dataSource, columns, ...props }) => {
  return (
    // <Table striped bordered hover>
    //   <thead>
    //     <tr>
    //       <th>STT</th>
    //       <th>Nhãn hiệu</th>
    //       <th>Loại xe</th>
    //       <th>Tên xe</th>
    //       <th>Biển số</th>
    //     </tr>
    //   </thead>
    //   <tbody>
    //     {transportCustomer?.length > 0 ? (
    //       transportCustomer?.map((value, index) => (
    //         <tr>
    //           <td>{index + 1 || '--'}</td>
    //           <td>{value.brand || '--'}</td>
    //           <td>{value.type || '--'}</td>
    //           <td>{value.name || '--'}</td>
    //           <td>{value.bienso || '--'}</td>
    //         </tr>
    //       ))
    //     ) : (
    //       <td colspan="5">
    //         <Empty description="Không có dữ liệu"></Empty>
    //       </td>
    //     )}
    //   </tbody>
    // </Table>
    <Table
      striped
      bordered
      hover
      dataSource={dataSource}
      columns={columns}
      locale={{ emptyText: 'Không có dữ liệu' }}
      {...props}
    />
  );
};

TableView.propTypes = {
  ...Table.propTypes,
};

export default TableView;
