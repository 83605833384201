import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusCircleOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { requesGetCustomerFieldImport } from '@constants/Api';
import { BOX_SHADOW, FIELD_IMPORT, GENDER } from '@constants/Constant';
import { formatTime } from '@constants/funcHelper';
import { notifyFail, notifySuccess } from '@utils/notify';
import { checkNormalizePhone } from '@utils/stringUtils';
import {
  Button,
  Col as ANTDCol,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Radio,
  Row as ANTDRow,
  Select,
  Skeleton,
  Space,
  Tooltip,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  requestCreateCustomerPhone,
  requestDestroyCustomerPhone,
  requestUpdateCustomer,
  requestUpdateCustomerPhone,
} from 'src/network/web_sale/CustomerApi';
import styled from 'styled-components';
import '../style.css';
import EZLoadingBox from 'src/modules/loading/EZ.Loading.Box';

const Wrapper = styled.div`
  text-align: center;
  border-radius: 6px;
  box-shadow: ${BOX_SHADOW};
  height: 100%;
  background-color: white;
  padding: 10px;
`;

const CustomerDetail = props => {
  const {
    customerDetail,
    setCustomerDetail,
    subPhones,
    setSubPhones,
    setDisplayUpdateCustomerModal,
    idCustomer,
    isLoading,
  } = props;
  const provinces = useSelector(state => state.ProvinceReducer.data);
  const history = useHistory();
  const [form] = Form.useForm();
  const [ChangeSubPhoneForm] = Form.useForm();
  const [additionalInfor, setAdditionalInfor] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [displayUpdateSubPhoneModal, setDisplayUpdateSubPhoneModal] =
    useState(false);
  const [subphoneModalInfor, setSubphoneModalInfor] = useState({
    title: '',
    subphone: {
      id: 0,
      phone: '',
      note: '',
    },
  });

  const handleShowCustomerModal = async () => {
    const customerFieldsImport = await requesGetCustomerFieldImport();
    const fields = customerFieldsImport.data
      .filter(item => item.data_type !== 'ref')
      .map(item => {
        const mappingValue =
          customerDetail.customer_data?.find(
            field => field.customer_data_field_code === item.code
          )?.value || '';

        return {
          ...item,
          value:
            item.data_type === FIELD_IMPORT.DATE_TIME
              ? mappingValue
                ? moment(mappingValue, 'DD/MM/YYYY')
                : ''
              : mappingValue,
        };
      });
    setAdditionalInfor(fields);
    setDisplayUpdateCustomerModal(true);
  };
  const handleUpdateSubPhoneModalCancel = () => {
    setDisplayUpdateSubPhoneModal(false);
  };
  const handleUpdateSubPhoneModalOK = async () => {
    const subPhone = ChangeSubPhoneForm.getFieldsValue();

    if (subPhone.phone === undefined || checkNormalizePhone(subPhone.phone)) {
      ChangeSubPhoneForm.setFields([
        {
          name: 'phone',
          errors: ['Vui lòng nhập số điện thoại hợp lệ!'],
        },
      ]);
      return;
    }
    if (!subPhone.note) subPhone.note = '';
    const payload = {
      customer_id: customerDetail.id,
      ...subPhone,
      ...(subphoneModalInfor.subphone.id
        ? { id: subphoneModalInfor.subphone.id }
        : {}),
    };
    try {
      const updateCustomerResponse = payload.id
        ? await requestUpdateCustomerPhone(payload)
        : await requestCreateCustomerPhone(payload);
      setSubPhones(updateCustomerResponse.data.main.customer_phones);
      notifySuccess(updateCustomerResponse.msg);
      setDisplayUpdateSubPhoneModal(false);
    } catch (error) {
      notifyFail(error.msg);
    }
  };

  const renderAdditionalInforInput = item => {
    switch (item.data_type) {
      case FIELD_IMPORT.TEXT:
        return (
          <Input
            placeholder={`Nhập ${item.name.toLowerCase()}`}
            onChange={e => {
              modalFieldChange(`${item?.code}`, e.target.value);
            }}
          />
        );
      case FIELD_IMPORT.OPTIONS:
        return (
          <Select
            allowClear
            defaultValue={`Nhập ${item.name.toLowerCase()}`}
            onChange={value => {
              modalFieldChange(`${item?.code}`, value);
            }}
          >
            {item.customer_data_field_sources.length &&
              item.customer_data_field_sources.map(choice => (
                <Select.Option key={choice.id} value={choice.value}>
                  {choice.value}
                </Select.Option>
              ))}
          </Select>
        );
      case FIELD_IMPORT.DATE_TIME:
        return (
          <DatePicker
            placeholder={`Nhập ${item.name.toLowerCase()}`}
            style={{ width: '100%' }}
            format="DD/MM/YYYY"
            onChange={(date, dateString) => {
              // modalFieldChange(`${item?.code}`, moment.utc(dateString));
              modalFieldChange(item?.code, formatTime(date, 'DD/MM/YYYY'));
            }}
          />
        );
      case FIELD_IMPORT.NUMBER:
        return (
          <InputNumber
            style={{ width: '100%' }}
            placeholder={`Nhập ${item.name.toLowerCase()}`}
            onChange={value => {
              modalFieldChange(`${item?.code}`, value);
            }}
          />
        );
      default:
        break;
    }
  };

  const handleSaveInfor = async infor => {
    if (infor.dob) {
      infor.dob = moment(infor.dob).toISOString();
    }
    for (const property in infor) {
      if (property.includes(FIELD_IMPORT.DATE_TIME)) {
        infor[property.replace(FIELD_IMPORT.DATE_TIME, '')] =
          infor[property] !== null
            ? moment(infor[property]).format('DD/MM/YYYY')
            : null;
        delete infor[property];
      } else {
        if (infor[property] === undefined) {
          infor[property] = '';
        }
      }
    }

    try {
      const updateCustomerResponse = await requestUpdateCustomer({
        id: customerDetail.id,
        ...infor,
      });
      setSubPhones(updateCustomerResponse.data.main.customer_phones);
      setDisplayUpdateCustomerModal(false);
      notifySuccess(updateCustomerResponse.msg);
    } catch (error) {
      notifyFail(error.msg);
    }
  };

  const handleChangeSubPhone = phone => {
    setSubphoneModalInfor({ title: 'Sửa số phụ', subphone: phone });
  };

  const handleAddSubPhone = () => {
    setSubphoneModalInfor({ title: 'Thêm số phụ', subphone: {} });
  };

  const handleDeleteSubPhone = async phone => {
    try {
      const deleteSubPhoneResponse = await requestDestroyCustomerPhone({
        id: phone.id,
        customer_id: customerDetail.id,
      });
      notifySuccess(deleteSubPhoneResponse.msg);
      setSubPhones(deleteSubPhoneResponse.data.main.customer_phones);
    } catch (error) {
      notifySuccess(error.msg);
    }
  };

  const modalFieldChange = (field, value) => {
    setCustomerDetail({
      ...customerDetail,
      [field]: value || '',
    });
  };

  useEffect(() => {
    if (subphoneModalInfor.title) {
      setDisplayUpdateSubPhoneModal(true);
      ChangeSubPhoneForm.setFields([
        {
          name: 'phone',
          value: subphoneModalInfor.subphone.phone,
        },
        {
          name: 'note',
          value: subphoneModalInfor.subphone.note,
        },
      ]);
    }
  }, [subphoneModalInfor]);

  useEffect(() => {
    handleShowCustomerModal();
  }, [idCustomer]);

  return (
    <Wrapper>
      <div>
        <div className="pb-2 text-left">
          <strong style={{ fontSize: '17px', color: '#ef8737' }}>
            Cập nhật thông tin
          </strong>
        </div>
        <Divider style={{ margin: 0 }} />
        {!isLoading && customerDetail?.name ? (
          <Form
            form={form}
            name="UpdateCustomer"
            labelAlign="left"
            autoComplete="off"
            onFinish={handleSaveInfor}
            style={{ fontWeight: 500, width: '100%' }}
            layout="vertical"
          >
            {/* Thông tin cơ bản ================================================================================================ */}
            <ANTDRow justify="space-between">
              <ANTDCol style={{ width: '49%' }}>
                <ANTDRow className="pb-2 pt-2">
                  <strong style={{ fontSize: '16px' }}>Thông tin cơ bản</strong>
                </ANTDRow>
                <div style={{ padding: '0 10px' }}>
                  <Form.Item
                    label="Tên khách hàng"
                    name="name"
                    initialValue={customerDetail?.name}
                    style={{ marginBottom: 10 }}
                  >
                    <Input
                      defaultValue={customerDetail?.name}
                      style={{ width: '100%' }}
                      placeholder="Nhập tên khách hàng"
                      onChange={e => {
                        modalFieldChange('name', e.target.value);
                      }}
                    />
                  </Form.Item>
                  <ANTDRow>
                    <ANTDCol xs={24} md={8}>
                      <ANTDRow justify="start">
                        <strong>Số điện thoại: </strong>
                      </ANTDRow>
                    </ANTDCol>
                    <ANTDCol xs={24} md={16}>
                      <ANTDRow justify="start">
                        <strong style={{ color: 'var(--ant-primary-color)' }}>
                          {customerDetail.phone}
                        </strong>
                      </ANTDRow>
                      {subPhones &&
                        subPhones.map((phone, i) => (
                          <>
                            <ANTDRow justify="start">
                              <span>{phone.phone}</span>
                              <Tooltip placement="top" title="Sửa">
                                <EditOutlined
                                  className="m-1"
                                  onClick={() => handleChangeSubPhone(phone)}
                                />
                              </Tooltip>
                              <Tooltip placement="top" title="Xóa">
                                <Popconfirm
                                  title="Bạn chắc chắn muốn xóa?"
                                  onConfirm={() => handleDeleteSubPhone(phone)}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <DeleteOutlined className="m-1" />
                                </Popconfirm>
                              </Tooltip>
                            </ANTDRow>
                            <ANTDRow justify="start">{phone.note}</ANTDRow>
                            <Divider style={{ margin: 0 }} />
                          </>
                        ))}
                      {subPhones && subPhones.length < 5 && (
                        <Button
                          style={{ width: '100%' }}
                          type="dashed"
                          onClick={() => handleAddSubPhone()}
                        >
                          <Space align="baseline">
                            <PlusCircleOutlined />
                            <span>Thêm số phụ</span>
                          </Space>
                        </Button>
                      )}
                    </ANTDCol>
                  </ANTDRow>

                  <Form.Item
                    label="Email"
                    name="email"
                    initialValue={customerDetail.email}
                    style={{ marginBottom: 10 }}
                  >
                    <Input
                      placeholder="Nhập email"
                      onChange={e => {
                        modalFieldChange('email', e.target.value);
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Giới tính"
                    name="gender"
                    initialValue={customerDetail.gender}
                    style={{ marginBottom: 10 }}
                  >
                    <Radio.Group
                      onChange={event => {
                        setCustomerDetail(prev => ({
                          ...prev,
                          gender: event.target.value,
                        }));
                      }}
                    >
                      <Radio value={GENDER.MAN}>Nam</Radio>
                      <Radio value={GENDER.WOMEN}>Nữ</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    label="Ngày sinh"
                    name="dob"
                    initialValue={
                      customerDetail.dob
                        ? moment.utc(new Date(customerDetail.dob)).local()
                        : null
                    }
                    style={{ marginBottom: 10 }}
                  >
                    <DatePicker
                      placeholder="Chọn ngày sinh"
                      style={{ width: '100%' }}
                      format="DD/MM/YYYY"
                      onChange={(date, dateString) => {
                        // modalFieldChange('dob', moment.utc(dateString));
                        modalFieldChange('dob', moment(date).format());
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Tỉnh, thành phố"
                    name="province_id"
                    initialValue={customerDetail.province?.id}
                    style={{ marginBottom: 10 }}
                  >
                    <Select
                      placeholder="Nhập tỉnh, thành phố"
                      onChange={value => {
                        modalFieldChange('province_id', value);
                      }}
                    >
                      {provinces.map(province => (
                        <Select.Option key={province.id} value={province.id}>
                          {province.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Địa chỉ thường trú"
                    name="address"
                    initialValue={customerDetail.address}
                    style={{ marginBottom: 10 }}
                  >
                    <Input
                      placeholder="Địa chỉ thường trú"
                      onChange={e => {
                        modalFieldChange('address', e.target.value);
                      }}
                    />
                  </Form.Item>
                </div>
              </ANTDCol>

              {/* END Thông tin cơ bản ================================================================================================ */}

              {/* Thông tin thêm ================================================================================================ */}
              <ANTDCol style={{ width: '49%' }}>
                <ANTDRow className="pb-2 pt-2">
                  <strong style={{ fontSize: '16px' }}>Thông tin thêm</strong>
                </ANTDRow>
                <div style={{ padding: '0 10px' }}>
                  {additionalInfor.length &&
                    additionalInfor.map((item, index) => {
                      if (showMore) {
                        return (
                          <>
                            <Form.Item
                              label={`${item.name}`}
                              name={`${
                                item.data_type === FIELD_IMPORT.DATE_TIME
                                  ? `${FIELD_IMPORT.DATE_TIME}`
                                  : ''
                              }${item.code}`}
                              initialValue={
                                item.data_type !== FIELD_IMPORT.DATE_TIME
                                  ? customerDetail.customer_data?.find(
                                      data =>
                                        data.customer_data_field_code ===
                                        item.code
                                    )?.value
                                  : customerDetail.customer_data?.find(
                                      data =>
                                        data.customer_data_field_code ===
                                        item.code
                                    )?.value
                                  ? moment(
                                      customerDetail.customer_data?.find(
                                        data =>
                                          data.customer_data_field_code ===
                                          item.code
                                      )?.value,
                                      'DD/MM/YYYY'
                                    )
                                  : null
                              }
                              style={{ marginBottom: 10 }}
                            >
                              {renderAdditionalInforInput(item)}
                            </Form.Item>
                          </>
                        );
                      } else {
                        return index < 5 ? (
                          <>
                            <Form.Item
                              label={`${item.name}`}
                              name={`${
                                item.data_type === FIELD_IMPORT.DATE_TIME
                                  ? `${FIELD_IMPORT.DATE_TIME}`
                                  : ''
                              }${item.code}`}
                              initialValue={
                                item.data_type !== FIELD_IMPORT.DATE_TIME
                                  ? customerDetail.customer_data?.find(
                                      data =>
                                        data.customer_data_field_code ===
                                        item.code
                                    )?.value
                                  : customerDetail.customer_data?.find(
                                      data =>
                                        data.customer_data_field_code ===
                                        item.code
                                    )?.value
                                  ? moment(
                                      customerDetail.customer_data?.find(
                                        data =>
                                          data.customer_data_field_code ===
                                          item.code
                                      )?.value,
                                      'DD/MM/YYYY'
                                    )
                                  : null
                              }
                              style={{ marginBottom: 10 }}
                            >
                              {renderAdditionalInforInput(item)}
                            </Form.Item>
                          </>
                        ) : null;
                      }
                    })}
                  <ANTDRow
                    className="pb-3"
                    onClick={() => {
                      setShowMore(!showMore);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <span
                      style={
                        showMore ? { display: 'none' } : { color: '#ef8737' }
                      }
                    >
                      Xem thêm
                    </span>
                    <span
                      style={
                        !showMore ? { display: 'none' } : { color: '#ef8737' }
                      }
                    >
                      Ẩn bớt
                    </span>
                    <UpOutlined
                      style={
                        !showMore
                          ? { display: 'none' }
                          : { color: '#ef8737', margin: '7px 0 0 0' }
                      }
                    />
                    <DownOutlined
                      style={
                        !showMore
                          ? { color: '#ef8737', margin: '5px 0 0 0' }
                          : { display: 'none' }
                      }
                    />
                  </ANTDRow>
                </div>
              </ANTDCol>
            </ANTDRow>
            {/* END Thông tin thêm ================================================================================================ */}
          </Form>
        ) : (
          <EZLoadingBox />
        )}
        <Modal
          centered
          visible={displayUpdateSubPhoneModal}
          title={
            <strong style={{ fontSize: '20px' }}>
              {subphoneModalInfor.title}{' '}
            </strong>
          }
          destroyOnClose={true}
          okText="Lưu"
          cancelText="Hủy"
          onCancel={handleUpdateSubPhoneModalCancel}
          onOk={handleUpdateSubPhoneModalOK}
        >
          <Form form={ChangeSubPhoneForm} layout="vertical" autoComplete="off">
            <Form.Item
              label="Số điện thoại"
              name="phone"
              rules={[
                () => ({
                  validator(_, value) {
                    if (!checkNormalizePhone(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('Vui lòng nhập số điện thoại hợp lệ')
                    );
                  },
                }),
              ]}
            >
              <Input
                placeholder="Nhập số điện thoại"
                defaultValue={subphoneModalInfor.subphone.phone}
              />
            </Form.Item>
            <Form.Item label="Ghi chú" name="note">
              <Input.TextArea placeholder="Nhập ghi chú" />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </Wrapper>
  );
};

export default CustomerDetail;
