import React from 'react';
import logo from '@assets/logo-ezsale.png';

const EZLoadingBox = () => {
  return (
    <img src={logo} class="blink_me" alt="logo" width="140px" height="140px" />
  );
};

export default EZLoadingBox;
