import { clearJobDetail } from '@actions/';
import Wrapper from '@components/ui/wrapper/Wrapper';
import { requestJobDetail } from '@constants/Api';
import { ROUTER } from '@constants/Constant';
import AssignAdd from '@screens/Enterprise/Job/Add/Tabs/Assign';
import AssignEdit from '@screens/Enterprise/Job/Edit/Tabs/Assign';
import { Breadcrumb, Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { BreadcrumbAntStyle } from '@styles/Style';
import SelectCustomer from './Components/SelectCustomer';

const { Step } = Steps;

function AddEditSubJob() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(0);
  const [stepStatus, setStepStatus] = useState(['process', 'wait']);

  const handleChangeStepStatus = (step, stepStatus) => {
    setCurrentStep(step);
    setStepStatus(stepStatus);
  };

  const handleChangeStep = (currentStep) => {
    if (currentStep === 0) {
      let status = stepStatus;
      status[0] = 'finish';
      status[1] = 'process';
      handleChangeStepStatus(currentStep + 1, status);
    } else {
      let status = stepStatus;
      status[0] = 'process';
      status[1] = 'finish';
      handleChangeStepStatus(currentStep - 1, status);
    }
  };

  const steps = [
    {
      title: 'Chọn khách hàng',
      content: <SelectCustomer jobId={location?.state?.jobId} handleChangeStep={handleChangeStep} />,
    },
    {
      title: 'Giao việc',
      content: !location.pathname.includes(ROUTER.JOB_GENERAL_EDIT) ? (
        <AssignAdd jobId={location?.state?.jobId} handleChangeStep={handleChangeStep} />
      ) : (
        <AssignEdit handleChangeStep={handleChangeStep} />
      ),
    },
  ];

  // const getDetailSubJob = async () => {
  //   const result = await requestJobDetail({
  //     id: location?.state?.subJobId,
  //   });
  //   setSubJobDetail(result.data);
  // };

  // useEffect(() => {
  //   if (location?.state?.subJobId) {
  //     getDetailSubJob();
  //   }
  // }, [location]);

  useEffect(() => {
    return () => {
      dispatch(clearJobDetail());
    };
  }, []);

  return (
    <div className="content-wrapper job-general">
      <BreadcrumbAntStyle>
        <Breadcrumb.Item>
          <a onClick={() => history.push(ROUTER.JOB)}>Danh sách công việc</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Giao việc</Breadcrumb.Item>
      </BreadcrumbAntStyle>

      <Wrapper>
        <Steps
          type="navigation"
          current={currentStep}
          // onChange={(current) => {
          //   setCurrentStep(current);
          // }}
        >
          {steps.map((step, index) => (
            <Step key={index} status={stepStatus[index]} title={step.title} />
          ))}
        </Steps>
      </Wrapper>

      <Wrapper>
        <div className="steps-content">{steps[currentStep].content}</div>
      </Wrapper>
    </div>
  );
}

export default AddEditSubJob;
