import React, { Component } from 'react';
import Pagi from '@components/PaginationTotalItem';
import { ReactSelect } from '@components/ReactSelect';
import { LIST_OPPORTUNITY_STATUS, STRING } from '@constants/Constant';
import Loading from '@src/components/Loading';
import '@styles/JobGeneral.css';
import { notifyFail } from '@utils/notify';
import { Empty } from 'antd';
import moment from 'moment';
import { Col, FormControl, Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Line } from 'react-chartjs-2';
import { withRouter } from 'react-router-dom';
import DatePickerCustom from '../../../../components/DatePickerCustom';

import {
  requestGetAllDepartment,
  requestGetOpportunityChart,
  requestGetOpportunityList,
  requestGetOpportunityResult,
  requestGetOpportunityStatistic,
  requestGetTelemarketingInDepartment,
  requestJobCategoriesFilter,
} from '@constants/Api';

class Screen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      [STRING.fromDate]: '',
      [STRING.toDate]: '',
      job_id: '',
      opportunity_category_id: '',
      telesale_id: '',
      status: '',
      startDate: '',
      endDate: '',
      dataChart: {
        chartBH: [],
        chartNC: [],
        chartKN: [],
        // labels: [],
        // datasets: [
        //   {
        //     label: 'Thống kê cơ hội',
        //     data: [],
        //     borderColor: '',
        //     fill: false,
        //     pointBackgroundColor: '#1b5e20',
        //     pointBorderColor: 'white',
        //     pointRadius: 5,
        //   },
        // ],
      },
      listJobs: [],
      listTelesales: [],
      listOppotunities: [],
      listStatus: [],
      listReports: [],
      paging: {},
      activePage: 1,
      isLoading: false,
      department_id: '',
      departmentList: [],
      opportunityResult: {
        total_oppor: 0,
        total_revenue: null,
        total_sales: null,
      },
    };
  }

  componentDidMount() {
    const { location, history } = this.props;
    if (location.state && location.state.saleId) {
      this.setState(
        {
          ...this.state,
          telesale_id: location.state.saleId,
          [STRING.fromDate]: location.state.date ? moment(location.state.date)._d : moment().startOf('month')._d,
          [STRING.toDate]: location.state.date ? moment(location.state.date)._d : moment().endOf('month')._d,
        },
        () => {
          this.requestGetOpportunityChart();
          this.requestGetOpportunityStatistic();
          this.getOpportunityResult();
        }
      );
    } else {
      this.requestGetOpportunityChart();
      this.requestGetOpportunityStatistic();
      this.getOpportunityResult();
    }

    this.requestJobCategoriesFilter();
    // this.requestGetJobStatistic();
    this.getTelemarketingInDepartment();
    this.requestGetOpportunityList();
    this.getAllDepartment();

    if (location.state && location.state.saleId) {
      let state = {};
      history.replace({ ...history.location, state });
    }
  }

  startDate = () => {
    const { [STRING.fromDate]: fromDate, [STRING.toDate]: toDate } = this.state;
    if (fromDate == '' && toDate == '') {
      let now = Date.now() - 604800000;
      let d = new Date(now);
      let date = d.getDate() > 9 ? d.getDate() : '0' + d.getDate();
      let month = d.getMonth() + 1 > 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1);
      let year = d.getFullYear();
      let startDate = year + '-' + month + '-' + date + 'T00:00:00.000Z';
      this.setState({
        ...this.state,
        startDate: startDate,
      });
    }
    if (fromDate == '' && toDate != '') {
      let now = new Date(toDate) - 604800000;
      let d = new Date(now);
      let date = d.getDate() > 9 ? d.getDate() : '0' + d.getDate();
      let month = d.getMonth() + 1 > 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1);
      let year = d.getFullYear();
      let startDate = year + '-' + month + '-' + date + 'T00:00:00.000Z';
      this.setState({
        ...this.state,
        startDate: startDate,
      });
    }
  };

  endDate = () => {
    const { [STRING.fromDate]: fromDate, [STRING.toDate]: toDate } = this.state;
    if (toDate == '') {
      let now = Date.now();
      let d = new Date(now);
      let date = d.getDate() > 9 ? d.getDate() : '0' + d.getDate();
      let month = d.getMonth() + 1 > 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1);
      let year = d.getFullYear();
      let endDate = year + '-' + month + '-' + date + 'T00:00:00.000Z';
      this.setState({
        ...this.state,
        endDate: endDate,
      });
    }
  };

  async getOpportunityResult() {
    const {
      [STRING.fromDate]: fromDate,
      [STRING.toDate]: toDate,
      job_id,
      opportunity_category_id,
      telesale_id,
      status,
      department_id,
    } = this.state;
    await this.startDate();
    await this.endDate();
    const { startDate, endDate } = this.state;
    try {
      const res = await requestGetOpportunityResult({
        fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : startDate,
        toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : endDate,
        // job_id: job_id || '',
        sale_id: telesale_id || '',
        opportunity_category_id: opportunity_category_id || '',
        department_id: department_id || '',
        status,
      });
      this.setState({
        ...this.state,
        opportunityResult: {
          ...this.state.opportunityResult,
          ...res.data,
        },
      });
    } catch (err) {}
  }

  async requestGetOpportunityChart() {
    const {
      [STRING.fromDate]: fromDate,
      [STRING.toDate]: toDate,
      job_id,
      opportunity_category_id,
      telesale_id,
      status,
      department_id,
    } = this.state;
    await this.startDate();
    await this.endDate();
    const { startDate, endDate } = this.state;
    let arrDate = [];
    let arrValues = [];
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const res = await requestGetOpportunityChart({
        fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : startDate,
        toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : endDate,
        // job_id: job_id || '',
        telesale_id: telesale_id || '',
        opportunity_category_id: opportunity_category_id || '',
        status: status || '',
        limit: '',
        page: 1,
        department_id: department_id || '',
      });
      // let count = res?.data?.map((value) => {
      //   arrDate.push(value.quarter);
      //   arrValues.push(value.earnings);
      // });
      this.setState({
        ...this.state,
        dataChart: {
          ...res.data,
        },
        isLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  // async getFullDepartment() {
  //   try {
  //     const res = await requestGetFullDepartment();
  //     this.setState({
  //       ...this.state,
  //       departmentList: res.data,
  //     });
  //   } catch (error) {
  //     notifyFail(error.msg);
  //   }
  // }

  async getAllDepartment() {
    try {
      const result = await requestGetAllDepartment();
      this.setState({
        ...this.state,
        departmentList: result.data,
      });
    } catch (error) {
      console.log('error: ', error);
    }
  }

  async requestJobCategoriesFilter() {
    try {
      const res = await requestJobCategoriesFilter({
        enterprise_id: '',
        telesale_id: '',
        have_call: '',
      });
      this.setState({
        ...this.state,
        listJobs: res.data,
      });
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  async requestGetOpportunityStatistic() {
    const {
      [STRING.fromDate]: fromDate,
      [STRING.toDate]: toDate,
      job_id,
      opportunity_category_id,
      telesale_id,
      status,
      department_id,
      activePage,
    } = this.state;
    await this.startDate();
    await this.endDate();
    const { startDate, endDate } = this.state;
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const res = await requestGetOpportunityStatistic({
        fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : startDate,
        toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : endDate,
        // job_id: job_id || '',
        telesale_id: telesale_id || '',
        opportunity_category_id: opportunity_category_id || '',
        status: status || '',
        limit: 12,
        page: activePage,
        department_id: department_id || '',
      });
      this.setState({
        ...this.state,
        listReports: res.data?.data,
        paging: res.paging,
        isLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  // async requestTelesalesFilter() {
  //   try {
  //     const res = await requestTelesalesFilter({
  //       enterprise_id: '',
  //       job_id: '',
  //       sub_job_id: '',
  //       limit: 100,
  //     });
  //     this.setState({
  //       ...this.state,
  //       listTelesales: res.data,
  //     });
  //   } catch (err) {
  //     notifyFail(err.msg);
  //   }
  // }

  async getTelemarketingInDepartment() {
    try {
      const { data } = await requestGetTelemarketingInDepartment(this.state.department_id);

      const telemarketing = data?.map((sale) => ({ id: sale.id, name: sale.full_name, phone: sale.phone })) || [];

      this.setState({
        ...this.state,
        listTelesales: telemarketing,
      });
    } catch (error) {
      console.log('error: ', error);
    }
  }

  async requestGetOpportunityList() {
    try {
      const res = await requestGetOpportunityList({
        call_id: '',
      });
      this.setState({
        ...this.state,
        listOppotunities: res.data,
      });
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  handleChange = (field, value) => {
    this.setState(
      {
        ...this.state,
        [field]: value || '',
      },
      () => {
        this.requestGetOpportunityChart();
        this.requestGetOpportunityStatistic();
        this.getOpportunityResult();
      }
    );
  };

  handleChangeSelect = (selectedOption, field) => {
    this.setState(
      {
        ...this.state,
        [field]: selectedOption.value,
      },
      () => {
        this.getTelemarketingInDepartment();
        this.requestGetOpportunityChart();
        this.requestGetOpportunityStatistic();
        this.getOpportunityResult();
      }
    );
  };

  renderTitle() {
    return (
      <>
        <Row>
          <Col md={5} sm={12}>
            <h2 className="text-left" style={{ marginBottom: '10px' }}>
              Báo cáo tổng hợp cơ hội
            </h2>
            <div class="line"></div>
          </Col>
          <br />
        </Row>
      </>
    );
  }

  renderResult() {
    const { opportunityResult } = this.state;

    return (
      <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
        <Col
          md
          style={{
            padding: '30px 35px',
            backgroundColor: '#ffffff',
            borderRadius: '8px',
            boxShadow: '0 20px 80px rgba(0, 0, 0, 0.07)',
          }}
        >
          <div className="w-100">
            <Row>
              <Col md={2} className="pl-0">
                {/* <span>
                  Kết quả: <b>234</b>
                </span> */}
                <span>
                  <b>Kết quả</b>
                </span>
              </Col>
              <Col md={8}>
                <Row>
                  <Col md={6}>
                    Số cơ hội: <b>{opportunityResult.total_oppor}</b>
                  </Col>
                  <Col md={6}>
                    Doanh số:{' '}
                    <b>
                      {opportunityResult?.total_sales
                        ? parseInt(opportunityResult?.total_sales).toLocaleString('vi', {
                            currency: 'VND',
                          }) + ' (VND)'
                        : '0'}
                    </b>
                  </Col>
                  <Col md={6}>
                    Doanh thu:{' '}
                    <b>
                      {opportunityResult?.total_revenue
                        ? parseInt(opportunityResult?.total_revenue).toLocaleString('vi', {
                            currency: 'VND',
                          }) + ' (VND)'
                        : '0'}
                    </b>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  }

  renderBody() {
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <Row
            className="mt-1"
            style={{ textAlign: 'center', padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}
          >
            <Col md className="bg">
              {this.renderTitle()}
              {this.renderField()}
            </Col>
          </Row>

          {this.renderResult()}

          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
            <Col
              md
              style={{
                padding: '30px 35px',
                backgroundColor: '#ffffff',
                borderRadius: '8px',
                boxShadow: '0 20px 80px rgba(0, 0, 0, 0.07)',
              }}
            >
              {this.renderchart()}
            </Col>
          </Row>
          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
            <Col md className="bg">
              {this.renderTable()}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  convertListArray(arr, label) {
    const new_arr = arr?.map(
      (province) =>
        new Object({
          value: province.id,
          label: province.name,
        })
    );
    new_arr.unshift({ value: '', label: label });
    return new_arr;
  }

  renderField() {
    const {
      job_id,
      [STRING.fromDate]: fromDate,
      [STRING.toDate]: toDate,
      listJobs,
      listTelesales,
      listOppotunities,
      listStatus,
      departmentList,
      telesale_id,
    } = this.state;
    return (
      <div className="w-100">
        <Row>
          <Col md={4} sm={12}>
            <ReactSelect
              handleChangeSelect={(e) => this.handleChangeSelect(e, 'department_id')}
              list={this.convertListArray(departmentList, 'Chọn phòng ban')}
              placeholder="Chọn phòng ban"
            />
          </Col>

          <Col md={4} sm={12}>
            <ReactSelect
              handleChangeSelect={(e) => this.handleChangeSelect(e, 'opportunity_category_id')}
              list={this.convertListArray(listOppotunities, 'Chọn loại cơ hội')}
              placeholder="Chọn loại cơ hội"
            />
          </Col>

          <Col md={4} sm={12}>
            {/* <ReactSelect
              handleChangeSelect={(e) => this.handleChangeSelect(e, 'telesale_id')}
              list={this.convertListArray(listTelesales, 'Chọn telesale')}
              placeholder="Chọn sale"
              hasValue={true}
              value={telesale_id || null}
            /> */}
            <FormControl
              as="select"
              value={telesale_id || ''}
              onChange={(e) => this.handleChangeSelect(e.target, 'telesale_id')}
            >
              <option value="">Chọn sale</option>
              {listTelesales.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </FormControl>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={4} sm={12}>
            <ReactSelect
              handleChangeSelect={(e) => this.handleChangeSelect(e, 'status')}
              list={this.convertListArray(LIST_OPPORTUNITY_STATUS, 'Chọn trạng thái')}
              placeholder="Chọn trạng thái"
            />
          </Col>
          <Col md={4} sm={12}>
            <DatePickerCustom
              className={`date-picker form-control`}
              placeholderText={STRING.fromDate}
              handleChange={this.handleChange}
              selected={fromDate}
              maxDate={new Date(toDate)}
              dateFormat="dd/MM/yyyy"
            />
          </Col>
          <Col md={4} sm={12}>
            <DatePickerCustom
              className={`date-picker form-control`}
              placeholderText={STRING.toDate}
              handleChange={this.handleChange}
              selected={toDate}
              minDate={new Date(fromDate)}
              dateFormat="dd/MM/yyyy"
            />
          </Col>
        </Row>
      </div>
    );
  }

  renderchart() {
    const { dataChart } = this.state;
    const data = {
      labels: dataChart.chartBH.map((item) => item.quarter),
      datasets: [
        {
          data: dataChart.chartNC.map((item) => item.earnings),
          // data: ['0', '0', '0', '0', '0', '0', '0', '0'],
          label: 'Mua bán',
          borderColor: 'rgb(255, 99, 132)',
          fill: false,
        },
        {
          data: dataChart.chartKN.map((item) => item.earnings),
          // data: ['0', '0', '0', '0', '0', '0', '0', '0'],
          label: 'Khiếu nại',
          borderColor: 'rgb(54, 162, 235)',
          fill: false,
        },
        {
          data: dataChart.chartBH.map((item) => item.earnings),
          // data: ['0', '0', '0', '0', '0', '0', '0', '0'],
          label: 'Bảo hành',
          borderColor: 'rgb(255, 205, 86)',
          fill: false,
        },
      ],
    };

    return (
      <div className="w-100 table-wrap">
        <span className="pb-2 d-indivne-block">
          <b>Thống kê cơ hội</b>
        </span>
        <Line
          // data={this.state.dataChart}
          data={data}
        />
        <Row>
          <b className="text-center mt-4" style={{ width: '100%' }}>
            THỐNG KÊ BÁO CÁO CƠ HỘI
          </b>
        </Row>
      </div>
    );
  }

  handlePageChange = (page) => {
    const { search, activePage, [STRING.fromDate]: fromDate, [STRING.toDate]: toDate } = this.state;
    this.setState({ ...this.state, activePage: page }, () => {
      this.requestGetOpportunityStatistic();
    });
  };

  renderPagination() {
    const { paging } = this.state;
    let pagingTable = { page: paging.page, totalItemCount: paging.count, limit: paging.limit };
    return <Pagi paging={pagingTable} handlePageChange={this.handlePageChange} />;
  }

  findStatus(arr, item) {
    return (
      arr.find((value) => {
        return value.id === item;
      })?.name || '--'
    );
    // return '--';
  }

  renderTable() {
    const { listReports, activePage } = this.state;
    return (
      <div className="w-100 table-wrap">
        <span className="pb-2 d-indivne-block">
          <b>Báo cáo chi tiết</b>
        </span>
        <Table bordered responsive className="mt-4">
          <thead>
            <tr>
              <th>STT</th>
              {/* <th>Công việc</th> */}
              <th>Loại cơ hội</th>
              <th>Sale</th>
              <th>Doanh số (VNĐ)</th>
              <th>Doanh thu (VNĐ)</th>
              <th>Trạng thái</th>
              <th>Khách hàng</th>
              <th>Ngày chuyển nhu cầu</th>
            </tr>
          </thead>
          <tbody>
            {listReports?.length > 0 ? (
              listReports?.map((value, index) => (
                <tr>
                  <td>{(activePage - 1) * 12 + index + 1 || '--'}</td>
                  {/* <td>{value?.job_name || '--'}</td> */}
                  <td>{value?.opportunity_category_name || '--'}</td>
                  <td>{value?.telesale_name || '--'}</td>
                  <td>
                    {value?.sales
                      ? value?.sales.toLocaleString('vi', {
                          currency: 'VND',
                        })
                      : '0'}
                  </td>
                  <td>
                    {value?.revenue
                      ? value?.revenue.toLocaleString('vi', {
                          currency: 'VND',
                        })
                      : '0'}
                  </td>
                  <td>{this.findStatus(LIST_OPPORTUNITY_STATUS, value.status)}</td>
                  <td>{value?.customer_name || '--'}</td>
                  <td>{moment(value?.modified_date).format('DD/MM/YYYY')}</td>
                </tr>
              ))
            ) : (
              <td colSpan={8}>
                <Empty description="Không có dữ liệu" />
              </td>
            )}
          </tbody>
        </Table>
        <Row>
          <Col md={12} className="p-0" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {this.renderPagination()}
          </Col>
        </Row>
      </div>
    );
  }

  addFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalAddField: {
        ...this.state.modalAddField,
        [field]: value,
      },
    });
  };

  render() {
    const { isLoading } = this.state;
    return (
      <>
        {isLoading && <Loading />}
        {this.renderBody()}
      </>
    );
  }
}

export default withRouter(Screen);
