import React, { Fragment } from 'react';
import { Card, Descriptions, Image, Switch } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { VietnameseString } from '@constants/VietnameseString';
import { IS_APPROVED } from '@constants/Constant';
import { isImage } from '@constants/funcHelper';

const CardStyled = styled(Card)`
  &&& {
    width: 100%;

    .ant-card-body {
      padding: 16px;
    }
  }
`;

const InformationStyled = styled.div`
  display: flex;
  align-items: center;
`;

const DescriptionsStyled = styled(Descriptions)`
  .ant-descriptions-row > th,
  .ant-descriptions-row > td {
    padding-bottom: 4px;
  }
`;

const MessageContentStyled = styled.div`
  border: 1px solid #f0f0f0;
  padding: 12px;
  border-radius: 8px;
  height: 160px;
  overflow-y: scroll;

  //css scroll
  ::-webkit-scrollbar {
    width: 4px;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #a59a9a;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(199, 199, 199, 0.3);
    background-color: #f5f5f5;
  }
`;

const MultimediaAreaStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4px;

  overflow-x: auto;
  //css scroll
  ::-webkit-scrollbar {
    height: 8px;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #a59a9a;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(199, 199, 199, 0.3);
    background-color: #f5f5f5;
  }
`;

const ItemMediaStyled = styled.div`
  width: 150px;

  + .item-media {
    margin-left: 8px;
  }
`;

function Template(props) {
  const {
    dateCreated,
    status,
    numberOfCharacters,
    numberOfMessage,
    messageContent,
    multimedia,
    onHandleUpdateApprovalStatus,
  } = props;
  return (
    <CardStyled hoverable>
      <InformationStyled>
        <DescriptionsStyled column={1}>
          <Descriptions.Item label={VietnameseString.dateCreated}>{dateCreated}</Descriptions.Item>
          <Descriptions.Item label={VietnameseString.status}>
            {status === IS_APPROVED.YES ? (
              <span style={{ color: 'green', fontWeight: 'bold' }}>{VietnameseString.approved}</span>
            ) : (
              <span style={{ color: 'red', fontWeight: 'bold' }}>{VietnameseString.not_approved_yet}</span>
            )}
          </Descriptions.Item>
          <Descriptions.Item label={VietnameseString.message_content}>
            {numberOfCharacters} {VietnameseString.character}/{numberOfMessage} SMS
          </Descriptions.Item>
        </DescriptionsStyled>

        <Switch checked={status} onChange={onHandleUpdateApprovalStatus} />
      </InformationStyled>

      <MessageContentStyled>
        <p>{messageContent}</p>

        {multimedia.length ? (
          <MultimediaAreaStyled>
            {multimedia.map((media, index) => {
              return (
                <ItemMediaStyled key={index} className="item-media">
                  {isImage(media) ? (
                    <Image
                      width={100}
                      src={media}
                      preview={{
                        mask: (
                          <>
                            <EyeOutlined />
                            {VietnameseString.view_image}
                          </>
                        ),
                      }}
                    />
                  ) : (
                    <video width={150} controls src={media} />
                  )}
                </ItemMediaStyled>
              );
            })}
          </MultimediaAreaStyled>
        ) : (
          <Fragment></Fragment>
        )}
      </MessageContentStyled>
    </CardStyled>
  );
}

Template.propTypes = {
  dateCreated: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
  numberOfCharacters: PropTypes.number.isRequired,
  numberOfMessage: PropTypes.number.isRequired,
  messageContent: PropTypes.string.isRequired,
  multimedia: PropTypes.arrayOf(PropTypes.string),
  onHandleUpdateApprovalStatus: PropTypes.func.isRequired,
};

export default Template;
