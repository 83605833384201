import { handleResult, getAxios } from '@constants/Api';

export const requestGetAllCategoryLocationReport = () => {
  return handleResult(
    getAxios.get(`/timeKeeping/listCategory`, {
      params: new URLSearchParams({
        page: 1,
        limit: 10000,
      }),
    })
  );
};

export const requestGetLocationReport = (
  {
    telesale_id,
    department_id,
    category_id,
    is_late,
    verify_address,
    from_date,
    to_date,
    page,
    limit,
  },
  option
) => {
  return handleResult(
    getAxios.get(`/timeKeeping/report`, {
      params: new URLSearchParams({
        telesale_id,
        department_id,
        category_id,
        is_late,
        verify_address,
        from_date,
        to_date,
        page,
        limit,
      }),
      ...option,
    })
  );
};

export const requestExportLocationReport = ({
  telesale_id,
  department_id,
  category_id,
  is_late,
  verify_address,
  from_date,
  to_date,
}) => {
  return handleResult(
    getAxios.get(`/timeKeeping/exportTimeKeeping`, {
      responseType: 'blob',
      headers: { 'Content-Type': null },

      params: new URLSearchParams({
        telesale_id,
        department_id,
        category_id,
        is_late,
        verify_address,
        from_date,
        to_date,
      }),
    })
  );
};

export const requestExportLocationReportVer2 = ({
  telesale_id,
  department_id,
  category_id,
  is_late,
  verify_address,
  from_date,
  to_date,
}) => {
  return getAxios.get(
    `/timeKeeping/exportTimeKeepingV2?telesale_id=${telesale_id}&department_id=${department_id}&category_id=${category_id}&is_late=${is_late}&verify_address=${verify_address}&from_date=${from_date}&to_date=${to_date}`,
    { responseType: 'blob', headers: { 'Content-Type': null } }
  );
};

export const requestGetLocationReportDetailTimekeeping = (
  { telesale_id, category_id, is_late, fromDate, toDate },
  option
) => {
  return handleResult(
    getAxios.get(`/timeKeeping/listTimekeeping`, {
      params: new URLSearchParams({
        telesale_id,
        category_id,
        is_late,
        fromDate,
        toDate,
        page: 1,
        limit: 1000,
      }),
      ...option,
    })
  );
};

export const requestGetLocationReportDetailSummary = (
  { telesale_id, category_id, fromDate, toDate },
  option
) => {
  return handleResult(
    getAxios.get(`/timeKeeping/summary`, {
      params: new URLSearchParams({
        telesale_id,
        category_id,
        fromDate,
        toDate,
      }),
      ...option,
    })
  );
};

export const requestGetConfigTimeKeeping = () => {
  return handleResult(getAxios.get(`/config/configTimeKeeping`));
};
