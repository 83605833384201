import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Typography } from 'antd';
import moment from 'moment';
import { ZaloIcon } from 'src/common/icon';
import { ROUTER } from '@constants/Constant';
import CallUtil from '@components/callProviders/CallUtil';
import CallStatus from '../CallStatus';

const ContainerStyled = styled.div`
  /* max-width: 230px; */
  /* max-height: 200px; */
  border: 1px dashed gray;
  border-radius: 6px;
  padding: 6px;
  /* margin: 5px 5px 15px 5px; */
  display: inline-flex;
  flex-direction: column;
  gap: 2px;
  margin-left: auto;
  .recall {
    margin-top: 3px;
  }
`;

const TitleStyled = styled.div`
  font-size: 12px;
  text-align: center;
`;

const NameStyled = styled.div`
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  white-space: pre-wrap;
  /* margin-bottom: 4px; */
`;

const PhoneNumberStyled = styled.div`
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 2px;
  text-align: center;
`;

const ActionStyled = styled.div`
  width: 100%;
  text-align: center;

  button {
    width: 75%;
  }
`;

function NextCustomerDetails(props) {
  const { data } = props;
  console.log('=======NextCustomerDetails=======', { data });
  const history = useHistory();

  const [nextCustomerId, setNextCustomerId] = useState(0);

  const nextCustomerDetail = customer => {
    history.push({
      pathname: `${ROUTER.JOB_CUSTOMER_DETAIL_SALE}/${customer.customer_id}`,
      state: {
        isBackFromCalling: false,
        //check
        // recallImmediately: '',
        idJob: CallUtil.currentJobId,
        job_telesale_customer_id: customer.job_telesale_customer_id,
        fromCallSchedule: false,
      },
    });
  };

  return (
    <ContainerStyled>
      <TitleStyled>Khách hàng tiếp theo</TitleStyled>

      <NameStyled>{data?.name}</NameStyled>

      <PhoneNumberStyled>
        <span>{data?.phone}</span>

        {/* <ZaloIcon width={28} height={28} /> */}
      </PhoneNumberStyled>
      {/* <ActionStyled>
        <Button type="link" onClick={() => nextCustomerDetail(data)}>
          Chi tiết
        </Button>
      </ActionStyled> */}
      {/* <div>Lịch hẹn - 13/12/2022 14:29</div>

      <ActionStyled>
        <Button type="primary" onClick={handleNavigation}>
          Chi tiết
        </Button>
      </ActionStyled> */}
      {/*  */}
      <div
        style={{ marginTop: '5px', display: 'flex', justifyContent: 'center' }}
      >
        {data?.call_status_id ? (
          <CallStatus
            status={2}
            timeCalled={moment(data?.call_time).format('DD/MM/YYYY-HH:mm')}
          />
        ) : (
          <CallStatus status={1} />
        )}
      </div>

      {data.time_recall && (
        <div className="recall">
          <CallStatus
            status={3}
            timeCalled={moment(data.time_recall).format('DD/MM/YYYY-HH:mm')}
          />
        </div>
      )}
    </ContainerStyled>
  );
}

NextCustomerDetails.propTypes = {
  currentCustomerId: PropTypes.number.isRequired,
};

export default NextCustomerDetails;
