import { requestCustomerList, requestFilterList, requestFullSaleList, requestListTag } from '@constants/Api';
import { NUMBER } from '@constants/Constant';
import Theme from '@constants/Theme';
import * as provinceSelectors from '@redux/selectors/provinceSelectors';
import CustomerFilter from '@screens/Enterprise/Customer/components/CustomerFilter';
import { Loading, NotAvailableValue } from '@src/components';
import { getProvinceList } from '@src/redux/actions';
import '@styles/Customer.css';
import { TableAntStyle } from '@styles/Style';
import { notifyFail } from '@utils/notify';
import { Checkbox, ConfigProvider, Tag } from 'antd';
import debounce from 'lodash/debounce';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
// import Pagination from '@components/Pagination';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class CustomerList extends Component {
  static propTypes = {
    payload: PropTypes.shape({
      enterprise_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      group_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      exclude_group_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    customerActions: PropTypes.object,
    resultCountText: PropTypes.string,
    onRow: PropTypes.func,
    onSelectionChanged: PropTypes.func,
    showCheckbox: PropTypes.bool,
    checkboxPosition: PropTypes.instanceOf('left', 'right'),
    dataFromStore: PropTypes.bool,

    //callback lấy filter khi chọn tất cả khách hàng
    onFilterChanged: PropTypes.func,
  };
  static defaultProps = {
    showCheckbox: false,
    checkboxPosition: 'left',
    dataFromStore: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      tableLoading: false,
      tagOption: [],
      tableColumns: [
        {
          title: 'STT',
          dataIndex: 'index',
          width: Theme.Sizing.tableColumnNoWidth,
        },
        {
          title: 'Họ tên',
          dataIndex: 'name',
          align: 'left',
        },
        {
          title: 'Số điện thoại',
          dataIndex: 'phone',
        },
        {
          title: 'Tỉnh/thành phố',
          dataIndex: 'province_name',
        },
        {
          title: 'Nguồn khách hàng',
          dataIndex: 'customer_source',
        },
        {
          title: 'Loại hình khách hàng',
          dataIndex: 'customer_type',
        },
        {
          title: 'Thẻ tag',
          dataIndex: 'customer_tags',
          render: (tags) => {
            return (
              <>
                {tags?.length ? (
                  tags.map((item) => {
                    return (
                      <>
                        <Tag key={item.tag_id} style={{ marginRight: 5, marginBottom: 5 }}>
                          <div className="d-flex justify-content-around align-items-center">
                            {/* <div
                              style={{
                                backgroundColor: item.color,
                                borderRadius: '50%',
                                width: '10px',
                                height: '10px',
                                marginRight: '3px',
                              }}
                            ></div> */}
                            {item.tag_name}
                          </div>
                        </Tag>
                      </>
                    );
                  })
                ) : (
                  <span className="text-muted">Chưa cập nhật</span>
                )}
              </>
            );
          },
        },
        {
          title: 'Sale chăm sóc',
          dataIndex: 'job_telesale_customers',
          render: (sales) => {
            return sales?.length ? (
              sales?.map((item) => item.telesale_name).join(', ')
            ) : (
              <span className="text-muted">Chưa cập nhật</span>
            );
          },
        },
        // {
        //   title: 'Ngành nghề',
        //   dataIndex: 'job_name',
        // },
        {
          title: 'Email',
          dataIndex: 'email',
        },
        {
          title: 'Ngày cập nhật',
          dataIndex: 'modified_date',
          render: (modifiedDate) => <p>{moment(modifiedDate).format('HH:mm - DD/MM/YYYY')}</p>,
        },
      ],
      additionalFields: [
        {
          title: 'Nguồn khách hàng',
          dataIndex: 'customer_source',
        },
        {
          title: 'Loại hình khách hàng',
          dataIndex: 'customer_type',
        },
      ],
      cusPayload: {
        search: '',
        telesale_id: '',
        province_ids: [],
      },
      activePage: 1,
      paging: {},
      fullSaleList: [],
      telesaleId: '',

      //isCheck checkbox select all
      // isCheck: false,
    };
  }
  componentDidMount() {
    this.props.getProvinceList();
    // this.props.getTagList();
    this.getDataCustomer({ page: this.state.activePage });
    this.getFilterList();
    this.getFullSaleList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log('this.props: ', this.props);
    if (prevState.tableLoading !== this.state.tableLoading) {
      if (this.props?.checkFullCustomers) {
        const selectedRow = this.state.tableData.map((customer) => customer.key);
        this.handleTableCheck(selectedRow);
        this.props.handleCheckFullCustomer && this.props.handleCheckFullCustomer(true);
      }
    }
  }

  async getFullSaleList() {
    try {
      const res = await requestFullSaleList({ type: 1 });
      const newArray = res?.data?.map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.full_name,
        };
      });
      this.setState({ ...this.state, fullSaleList: [...newArray] });
    } catch (error) {}
  }

  render() {
    return (
      <>
        {this.state.tableLoading ? <Loading /> : null}
        {this.renderBody()}
      </>
    );
  }
  renderBody() {
    const resultCount = this.state.customerState?.paging?.totalItemCount || 0;
    return (
      <div className="w-100">
        <Row>{this.renderInfoField()}</Row>

        <Row className="py-4">
          <Col md={9}>
            <span>
              {this.props.resultCountText}
              <b className="text-danger">{resultCount}</b>
            </span>
          </Col>
          <Col md={3}>
            <div className="text-center text-md-right">{this.props.customerActions}</div>
          </Col>
        </Row>

        <Row className="py-4">
          <Col md={12}>
            {/* {this.props.checkFullCustomers && ( */}
            <Checkbox
              style={{ marginLeft: '17px' }}
              checked={this.props.checkFullCustomers}
              onChange={(event) => {
                if (this.props.checkFullCustomers) {
                  // this.setState({ ...this.state, isCheck: event.target.checked });
                  this.props.handleCheckFullCustomer && this.props.handleCheckFullCustomer(event.target.checked);
                  this.handleTableCheck([]);
                } else {
                  this.props.handleCheckFullCustomer && this.props.handleCheckFullCustomer(event.target.checked);
                  const selectedRow = this.state.tableData.map((customer) => customer.key);
                  this.handleTableCheck(selectedRow);
                }
              }}
            >
              Chọn tất cả
            </Checkbox>
            {/* )} */}
          </Col>
        </Row>

        <Row>
          <Col md={12}>{this.renderTable()}</Col>
        </Row>
        <Row>
          <Col md={12} className="d-flex justify-content-center justify-content-md-end mt-3">
            {/* <Pagination screen="customerState" handlePageChange={this.handlePageChange} /> */}
            {this.renderPagination()}
          </Col>
        </Row>
      </div>
    );
  }

  renderTable() {
    const { checkboxPosition } = this.props;
    let direction = 'ltr';
    let { tableColumns } = this.state;

    if (checkboxPosition === 'right') {
      direction = 'rtl';
      // tableColumns = tableColumns.reverse();
    }
    // console.log(tableColumns);
    return (
      <div className="w-100 table-wrap">
        <ConfigProvider direction={direction}>
          <TableAntStyle
            // loading={this.state.tableLoading}
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: this.state.customer_ids,
              preserveSelectedRowKeys: true,
              onChange: (selectedRowKeys, selectedRows) => {
                this.handleTableCheck(selectedRowKeys);
                this.props.handleCheckFullCustomer && this.props.handleCheckFullCustomer(false);
              },
            }}
            columns={tableColumns}
            dataSource={this.state.tableData}
            pagination={false}
            bordered
            striped
            hover
            onRow={this.props.onRow}
            // footer={() => (
            //   <Row className=" justify-content-md-end justify-content-center">{this.renderPagination()}</Row>
            // )}
            scroll={
              {
                // y: '450px',
              }
            }
          />
        </ConfigProvider>
      </div>
    );
  }

  renderPagination() {
    const { paging } = this.state;
    return (
      <Pagination
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled
        activePage={paging?.page}
        itemsCountPerPage={paging?.limit}
        totalItemsCount={paging?.totalItemCount || 0}
        pageRangeDisplayed={5}
        hideNavigation
        onChange={(page) => this.handlePageChange(page)}
      />
    );
  }

  renderInfoField() {
    const { cusPayload, fullSaleList } = this.state;
    return (
      <CustomerFilter
        defaultPayload={{ enterprise_id: this.props.payload.enterprise_id }}
        provinces={this.props.provinceOptions}
        onChange={this.onFilterChanged}
        sales={fullSaleList}
        shouldHideSubFilter={this.props.shouldHideSubFilter}
      />
    );
  }

  onFilterChanged = async (filterOptions) => {
    const { id } = this.props.match?.params;
    this.setState({ cusPayload: filterOptions, customer_ids: [] }, () => {
      this.getDataCustomerFilterChangedDebounce();

      //callback lấy filter khi chọn tất cả khách hàng
      if (this.props.onFilterChanged) {
        this.props.onFilterChanged(this.state.cusPayload);
      }
    });
  };

  handleTableCheck = (selectedRowKeys) => {
    this.setState({ customer_ids: [...selectedRowKeys] });
    this.props.onSelectionChanged && this.props.onSelectionChanged(selectedRowKeys);
  };

  handlePageChange = (page) => {
    this.getDataCustomer({
      page: page,
    });
    this.setState({ activePage: page });
  };

  getDataCustomerFilterChangedDebounce = debounce(() => {
    this.getDataCustomer({ page: 1 });
  }, 500);

  async getDataCustomer(payload) {
    try {
      this.setState({ tableLoading: true });
      const items = await requestCustomerList({
        ...this.props.payload,
        ...payload,
        ...this.state.cusPayload,
        // telesale_id: this.state.telesaleId,
      });

      this.setState({
        ...this.state,
        paging: items?.paging,
      });

      const fieldObject = {};
      this.state.additionalFields.forEach((v) => (fieldObject[v.dataIndex] = v.dataIndex));
      const customers = (items.data || []).map((cus, index) => {
        const data = {};
        cus.customer_data.length > 0
          ? cus.customer_data
              .filter((v) => {
                return v.customer_data_field_code == fieldObject[v.customer_data_field_code];
              })
              .forEach((v) => (data[v.customer_data_field_code] = v.value || <NotAvailableValue />))
          : this.state.additionalFields.forEach((v) => (data[v.dataIndex] = <NotAvailableValue />));
        return {
          ...cus,
          index: index + 1 + (this.state.activePage - 1) * NUMBER.page_limit,
          key: cus.id,
          name: cus.name || <NotAvailableValue />,
          phone: cus.phone || <NotAvailableValue />,
          email: cus.email || <NotAvailableValue />,
          address: cus.address || <NotAvailableValue />,
          manager: cus.department_name || <NotAvailableValue />,
          province_name: cus.province?.name || <NotAvailableValue />,
          ...data,
        };
      });
      this.setState({ customerState: items, tableData: customers, tableLoading: false }, () => {});
    } catch (error) {
      console.log('error: ', error);
    } finally {
      this.setState({ tableLoading: false });
    }
  }

  async getFilterList() {
    try {
      const res = await requestFilterList({});
      const resTag = await requestListTag({});
      this.setState({
        form: res,
        tagOption: resTag?.data,
      });
    } catch (err) {
      notifyFail(err.msg);
    }
  }
}

const mapStateToProps = (state) => ({
  provinceState: provinceSelectors.getProvinces(state),
  provinceOptions: provinceSelectors.getProvinceOptions(state),
});

const mapDispatchToProps = {
  getProvinceList,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerList));
