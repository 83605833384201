import { GET_PROVINCE, GET_PROVINCE_SUCCESS, GET_PROVINCE_FAIL } from '../../actions/type';

import reactotron from 'reactotron-react-js';

const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PROVINCE:
      return {
        ...state,
        isLoading: true,
        payload: action.payload,
      };
      break;
    case GET_PROVINCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.response.data,
        error: null,
      };
      break;
    case GET_PROVINCE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      return state;
  }
}

export const provinceOptionsSelector = (provincesReducer) => {
  return (
    provincesReducer.data?.map((province) => ({
      title: province.name,
      value: province.id,
      key: province.id,
    })) || []
  );
};
