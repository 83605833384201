export function toQuerystringFromObject(obj) {
  return Object.keys(obj)
    .filter((v) => v)
    .map((v) => {
      const value = obj[v];
      if (Array.isArray(value)) {
        return `${v}=${value.join(',')}`;
      } else {
        return `${v}=${value}`;
      }
    })
    .join('&');
}
