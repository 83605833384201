import React from 'react';
import { Button, Col, FormControl, Row } from 'react-bootstrap';

import { forgetPassword } from '@constants/Api';
import { notifyFail } from '@utils/notify';

import '@styles/Login.css';

const isEmail =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

class ForgetPasswordScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isSend: false,
    };
  }

  async forgetPassword() {
    try {
      if (this.state.email) {
        if (isEmail.test(this.state.email)) {
          await forgetPassword({
            email: this.state.email,
          });
          this.setState({
            isSend: true,
          });
        } else {
          notifyFail('Email sai định dạng!');
        }
      } else {
        notifyFail('Vui lòng nhập email!');
      }
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  handleChange(field, value) {
    this.setState({
      [field]: value,
    });
  }

  render() {
    const { email, isSend } = this.state;
    return (
      <>
        {!isSend ? (
          <Row className="row w-100 h-100 login-screen">
            <Col sm={2} md={7}></Col>
            <Col sm={8} md={4}>
              <div style={{ height: '50%', width: '100%' }}>
                <div className="form-forget-password">
                  <div style={{ marginTop: '1rem', paddingLeft: '1.5rem' }}>
                    <h3>Quên mật khẩu?</h3>
                    <h6 className="text-gray">
                      Vui lòng nhập email của tài khoản, hệ thống sẽ gửi mail
                      qua hòm thư của bạn để lấy lại mật khẩu.
                    </h6>
                  </div>
                  {/* <h4 style={{ marginTop: '2rem', paddingLeft: '1.5rem' }}>
                    Account
                  </h4> */}
                  <div style={{ paddingLeft: '1.5rem' }}>
                    <FormControl
                      type="text"
                      placeholder="Nhập Email (Bắt buộc)"
                      value={email}
                      onChange={e => this.handleChange('email', e.target.value)}
                    ></FormControl>
                    <Button
                      className="text-light ml-0 mt-3"
                      onClick={() => this.forgetPassword()}
                    >
                      Send Email
                    </Button>
                    <Button
                      className="text-light ml-2 mt-3 btn-success"
                      onClick={() => this.props.history.goBack()}
                    >
                      Đăng nhập
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={1} md={1}></Col>
          </Row>
        ) : (
          <Row className="row w-100 h-100 login-screen">
            <Col sm={2} md={7}></Col>
            <Col sm={8} md={4}>
              <div style={{ height: '30%', width: '100%' }}>
                <div className="form-forget-password">
                  <div style={{ marginTop: '1rem', paddingLeft: '1.5rem' }}>
                    <h3>Quên mật khẩu?</h3>
                    <h5 className="text-gray">
                      Chúng tôi gửi thành công mật khẩu mới tới email của bạn.
                    </h5>
                  </div>
                  <Button
                    className="text-light ml-4 mt-3 btn-success"
                    onClick={() => this.props.history.goBack()}
                  >
                    Đăng nhập
                  </Button>
                </div>
              </div>
            </Col>
            <Col sm={1} md={1}></Col>
          </Row>
        )}
        {/* </Row> */}
      </>
    );
  }
}
export default ForgetPasswordScreen;
