import { getJobDetail } from '@actions/index';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Loading from '@components/Loading';
import { requestCreateExam, requestDeleteExam, requestSetMinPoint, updateValidateJob } from '@constants/Api';
import { ROUTER, STRING } from '@constants/Constant';
import Question from '@screens/Enterprise/Job/Add/Component/Test/_Question';
import '@styles/RegisterRequest.css';
import { ButtonSystemStyle, TitleStyle } from '@styles/Style';
import { notifyFail, notifySuccess } from '@utils/notify';
import { Col as ColAntd, Empty, Modal as ModalAntd, Row as RowAntd } from 'antd';
import React, { Component } from 'react';
import { Button, Col, Form, FormControl, Modal, Row } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import reactotron from 'reactotron-react-js';

class Exam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addModal: false,
      addOptionModal: false,
      minPoint: 0,
      questions: [],
      addModalField: {
        name: '',
        order: null,
        id: 0,
      },
      addOptionField: {
        name: '',
        questionIndex: '',
      },
      description: '',
      optionsModal: [],
      index: 0,
      //check create/update câu hỏi
      indexQuestionUpdate: -1,
      isLoading: false,
    };
    //callback lưu bước 1
    this.saveStep = this.submit.bind(this);
  }

  modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      [{ align: [] }],
      ['link', 'image', 'video'],
      ['clean'],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
    ],
  };

  componentDidMount() {
    //callback lưu bước 5
    this.props.onSaveStep(this.saveStep);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.indexQuestionUpdate !== -1) {
      const questionEdit = this.state.questions[this.state.indexQuestionUpdate];
      if (!prevState.addModal && this.state.addModal) {
        this.setState({
          addModalField: {
            name: questionEdit.name,
            order: questionEdit.order,
            id: questionEdit.id,
          },
          optionsModal: questionEdit.job_exam_answers,
        });
      }
    }
  }

  componentWillUnmount() {}

  componentWillReceiveProps(nextProp) {
    if (nextProp.jobState?.data?.job_exam_questions?.length) {
      this.setState({
        questions: [...nextProp.jobState.data.job_exam_questions],
        minPoint: nextProp.jobState.data.min_exam_point,
        description: nextProp.jobState?.data?.exam_description,
      });
    }
  }

  onFinish = (type) => {
    ModalAntd.confirm({
      title: 'Xác nhận',
      icon: <ExclamationCircleOutlined />,
      content: 'Bạn có muốn lưu thông tin trước khi chuyển sang nội dung khác?',
      okText: 'Lưu',
      cancelText: 'Hủy',
      onOk: () => this.submit(type),
    });
  };

  async submit(type) {
    // console.log('type: ', type);
    const checks = this.props.checks;
    if (this.validate()) {
      try {
        const listQuestion = [];
        this.state.questions.map((value) => {
          listQuestion.push({ ...value, exam_description: this.state.description });
        });

        let dem = 0;
        listQuestion.map((item, index) => {
          if (index < listQuestion.length - 1) {
            if (listQuestion[index].name.toUpperCase() === listQuestion[index + 1].name.toUpperCase()) {
              dem++;
            }
          }
        });

        if (dem != 0) {
          notifyFail('Câu hỏi bị trùng lặp!');
          // this.props.setLoading(false);
          return;
        }
        this.setState({ isLoading: true });
        const res = await Promise.all([
          requestSetMinPoint({
            min_exam_point: this.state.minPoint,
            job_id: this.props.jobState?.data.job_id,
          }),
          requestCreateExam({
            job_id: this.props.jobState?.data.job_id,
            listQuestion: listQuestion,
          }),
          updateValidateJob({
            id: this.props.jobState?.data.job_id,
            // validateCreate: {
            //   scenario: checks.context.isCheck ? 1 : 0,
            //   form: checks.formresult.isCheck ? 1 : 0,
            //   edu_infor: checks.education.isCheck ? 1 : 0,
            //   exam: checks.test.isCheck ? 1 : 0,
            // },
            validateCreate: {
              scenario: 0,
              form: 0,
              edu_infor: 0,
              exam: 0,
            },
          }),
        ]);
        this.props.setLoading(false);
        this.setState({ isLoading: false });
        const stepStatus = this.props.stepStatus;
        if (type === 'save') {
          notifySuccess('Thêm công việc thành công');
          this.props.history.push(ROUTER.JOB);
        }
        if (type === 'prev') {
          stepStatus[4] = 'finish'; //hoàn thành bước 5
          stepStatus[3] = 'process'; //chuyển trạng thái bước 4
          this.props.setStep(parseInt(this.props.currentStep) - 1, stepStatus);
        }
        if (type.includes('changeStep')) {
          let step = type.split(':');
          stepStatus[4] = 'finish'; //hoàn thành bước 5
          stepStatus[parseInt(step[1])] = 'process'; // chuyển trạng thái bước được chọn trên menu step
          this.props.setStep(parseInt(step[1]), stepStatus);
        }
        this.setState({ questions: [...res?.data?.job_exam_questions] });
        this.props.getJobDetail();
      } catch (err) {
        this.props.setLoading(false);
        notifyFail(err.msg);
      } finally {
        this.setState({ isLoading: false });
      }
    }
  }

  validate() {
    const job = this.props?.jobState?.data;
    if (job?.job_id === undefined) {
      return false;
    }
    if (this.state.questions?.length < 1) {
      // notifyFail('Vui lòng nhập câu hỏi.');
      // return false;
    } else {
      if (this.state.minPoint < 1) {
        notifyFail('Số câu trả lời đúng không được nhỏ hơn 1');
        return false;
      }
      if (this.state.minPoint > this.state.questions?.length) {
        notifyFail('Số câu trả lời không thể lớn hơn số câu hỏi hiện tại');
        return false;
      }
    }
    return true;
  }

  async deleteExam(questionId) {
    try {
      const res = await requestDeleteExam({
        id: questionId,
        job_id: this.props.jobState.data.job_id,
      });
      this.props.getJobDetail({ id: this.props.jobState?.data?.job_id });
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  removeQuestion(questionIndex) {
    var newQuestion = [...this.state.questions];
    if (newQuestion[questionIndex].id) {
      this.deleteExam(newQuestion[questionIndex].id);
    } else {
      newQuestion.splice(questionIndex, 1);
      this.setState({
        questions: newQuestion,
      });
    }
  }

  removeOption(questionIndex, optionIndex) {
    var targetQuestion = this.state.questions[questionIndex];

    var newOption = targetQuestion.job_exam_answers;
    newOption.splice(optionIndex, 1);
    targetQuestion.job_exam_answers = newOption;

    var newQuestion = this.state.questions.map((question, index) => {
      return index !== questionIndex ? question : targetQuestion;
    });
    this.setState({ questions: newQuestion });
  }

  updateResult(questionIndex, optionIndex, result) {
    var newQuestions = [...this.state.questions];
    var convertedResult = result ? 1 : 0;
    newQuestions[questionIndex].job_exam_answers.splice(optionIndex, 1, {
      ...newQuestions[questionIndex].job_exam_answers[optionIndex],
      result: convertedResult,
    });

    this.setState({
      questions: newQuestions,
    });
  }

  handleChange = (value) => {
    this.setState({
      ...this.state,
      description: value,
    });
  };

  renderHeader() {
    return (
      <Row className="py-3">
        <Col sm={12} className="px-0 d-flex justify-content-between">
          <TitleStyle>{`${STRING.exam}`}</TitleStyle>
          {/* <i className="fas fa-plus px-3 text-green add-btn" onClick={() => this.setShowModal('addModal', true)}></i> */}
        </Col>
        {/* <Col sm={12} className="text-left mb-3 p-0">
          <span>Mô tả</span>
          <ReactQuill value={this.state.description} modules={this.modules} onChange={this.handleChange} />
        </Col> */}
      </Row>
    );
  }

  renderDescription() {
    return (
      <Row className="mb-2">
        <Col sm={12} className="text-left mb-3 p-0">
          <span>Mô tả cho câu hỏi</span>
          <ReactQuill value={this.state.description} modules={this.modules} onChange={this.handleChange} />
        </Col>
      </Row>
    );
  }

  handleMinPointChange(value) {
    this.setState({ minPoint: value > 0 ? value : 0 });
  }

  renderTest() {
    const { questions } = this.state;
    return (
      <>
        <Row className="mb-2">
          <Col sm={12} className="p-0">
            <b className="text-blue d-block  d-sm-inline">Số câu trả lời đúng</b>
            <FormControl
              type="number"
              className="d-inline-block mx-xs-0 mx-md-3 minimum-score"
              value={this.state.minPoint}
              onChange={(e) => this.handleMinPointChange(e.target.value)}
            ></FormControl>
            <span>/ Tổng {this.state.questions.length} câu</span>
            <Button
              className="text-light"
              variant="success"
              onClick={() => this.setShowModal('addModal', true)}
              style={{ float: 'right' }}
            >
              Thêm câu hỏi
            </Button>
          </Col>
        </Row>
        {questions?.length > 0 ? (
          <Row>
            <Col sm={12} className="mt-2 p-0">
              {questions?.length > 0 &&
                questions.map((question, index) => (
                  <Question
                    question={{ ...question, number: index + 1 }}
                    key={index}
                    removeQuestion={this.removeQuestion.bind(this)}
                    removeOption={this.removeOption.bind(this)}
                    updateResult={this.updateResult.bind(this)}
                    showModal={this.setShowModal.bind(this)}
                    editExam={this.showEditQuestion.bind(this)}
                    duplicateQuestion={this.duplicateQuestion.bind(this)}
                  />
                ))}
            </Col>
          </Row>
        ) : (
          <Empty description={<span>Chưa có câu hỏi nào được tạo</span>} />
        )}
      </>
    );
  }

  addQuestion() {
    const options = this.state.optionsModal;
    let objectOption = {};
    for (let option of options) {
      if (objectOption[option.name.trim().toUpperCase()]) {
        notifyFail('Trùng lặp đáp án' + option.name.trim().toLowerCase());
        return false;
      }
      objectOption[option.name.trim().toUpperCase()] = option;
    }
    if (this.state.addModalField.name) {
      var newQuestion = [
        ...this.state.questions,
        {
          name: this.state.addModalField.name,
          order: this.state.addModalField.order || parseInt(this.state.questions.length + 1),
          id: 0,
          job_exam_answers: [...options],
        },
      ];
      newQuestion.sort((current, next) => current.order - next.order);
      this.setState({
        questions: [...newQuestion],
        addModalField: {
          name: '',
          order: null,
          id: 0,
        },
        optionsModal: [],
        addModal: false,
      });
    } else {
      notifyFail('Vui lòng điền đầy đủ thông tin');
      return;
    }
  }

  updateQuestion() {
    const options = this.state.optionsModal;
    const { questions, indexQuestionUpdate } = this.state;
    console.log('options: ', options);
    let objectOption = {};
    for (let option of options) {
      if (objectOption[option.name.trim().toUpperCase()]) {
        notifyFail('Trùng lặp đáp án' + option.name.trim().toLowerCase());
        return false;
      }
      objectOption[option.name.trim().toUpperCase()] = option;
    }
    if (this.state.addModalField.name) {
      let newQuestion = {
        name: this.state.addModalField.name,
        order: questions[indexQuestionUpdate].order,
        id: questions[indexQuestionUpdate].id,
        job_exam_answers: [...this.state.optionsModal],
      };
      questions[indexQuestionUpdate] = newQuestion;
      this.setState({
        questions: questions,
        addModalField: {
          name: '',
          order: null,
          id: 0,
        },
        optionsModal: [],
        addModal: false,
        indexQuestionUpdate: -1,
      });
    } else {
      notifyFail('Vui lòng điền đầy đủ thông tin');
      return;
    }
  }

  addQuestionNoCloseModal() {
    const options = this.state.optionsModal;
    let objectOption = {};
    for (let option of options) {
      if (objectOption[option.name.trim().toUpperCase()]) {
        notifyFail('Trùng lặp đáp án ' + option.name.trim().toLowerCase());
        return false;
      }
      objectOption[option.name.trim().toUpperCase()] = option;
    }

    var newQuestion = [
      ...this.state.questions,
      {
        name: this.state.addModalField.name,
        order: this.state.addModalField.order || parseInt(this.state.questions.length + 1),
        id: 0,
        job_exam_answers: [...this.state.optionsModal],
      },
    ];
    newQuestion.sort((current, next) => current.order - next.order);
    if (this.state.addModalField.name) {
      this.setState({
        questions: [...newQuestion],
        addModalField: {
          name: '',
          order: null,
          id: 0,
        },
        // addModal: false,
        optionsModal: [],
        index: 0,
      });
    } else {
      notifyFail('Vui lòng điền đầy đủ thông tin');
      return;
    }
  }

  addOption() {
    if (this.state.addOptionField.name) {
      const id = this.state.addOptionField.questionIndex;
      var targetQuestion = this.state.questions[id];

      var newOption = targetQuestion.job_exam_answers;
      for (let option of newOption) {
        if (option.name == this.state.addOptionField.name) {
          notifyFail('Đáp án đã tồn tại');
          return;
        }
      }
      // console.log(newOption);
      newOption.push({
        name: this.state.addOptionField.name,
        order: this.state.addOptionField.order || parseInt(this.state.questions[id].job_exam_answers.length + 1),
        result: 0,
      });
      targetQuestion.job_exam_answers = newOption;

      var newQuestion = this.state.questions.map((question, index) => {
        return index !== id ? question : targetQuestion;
      });
      this.setState({
        questions: newQuestion,
        addOptionField: {
          name: '',
          questionIndex: '',
        },
        addOptionModal: false,
      });
    } else {
      notifyFail('Vui lòng điền đầy đủ thông tin');
      return;
    }
  }

  setShowModal(modal, isShow, questionIndex) {
    if (questionIndex || questionIndex === 0) {
      this.setState({
        [modal]: isShow,
        addOptionField: {
          ...this.addOptionField,
          questionIndex: questionIndex,
        },
      });
    } else {
      this.setState({
        [modal]: isShow,
      });
    }
  }
  showEditQuestion = (isShow, questionIndex) => {
    this.setState({ addModal: isShow, indexQuestionUpdate: questionIndex });
  };

  duplicateQuestion = (value) => {
    var newQuestion = [
      ...this.state.questions,
      {
        name: value.name,
        order: parseInt(this.state.questions.length + 1),
        id: 0,
        job_exam_answers: [...value?.job_exam_answers],
      },
    ];
    newQuestion.sort((current, next) => current.order - next.order);
    this.setState({
      questions: [...newQuestion],
      addModalField: {
        name: '',
        order: null,
        id: 0,
      },
      // addModal: false,
      optionsModal: [],
      index: 0,
    });
  };

  handleCloseModal(modal, isShow) {
    this.setState({
      ...this.state,
      [modal]: isShow,
      addModalField: {
        ...this.state.addModalField,
        name: '',
      },
      optionsModal: [],
      index: 0,
      indexQuestionUpdate: -1,
    });
  }

  onFormChange(field, value) {
    this.setState({
      addModalField: {
        ...this.state.addModalField,
        [field]: value,
      },
    });
  }

  onOptionFormChange(field, value) {
    this.setState({
      addOptionField: {
        ...this.state.addOptionField,
        [field]: value,
      },
    });
  }

  AddOptionInModal = () => {
    const { index, optionsModal } = this.state;
    optionsModal.push({ name: '', order: index, result: 0 });
    this.setState({
      ...this.state,
      index: index + 1,
      optionsModal: optionsModal,
    });
  };

  removeOptionInModal = (i) => {
    // console.log(order)
    const { optionsModal, index } = this.state;
    // const findIndex = optionsModal.findIndex((value) => {
    //   return value.order == order;
    // });
    optionsModal.splice(i, 1);
    reactotron.log(optionsModal);
    this.setState({
      optionsModal: optionsModal,
    });
  };
  handleChangeOptionTextByIndex = (index, value) => {
    console.log('index: ', index);
    console.log('value: ', value);
    if (index >= this.state.optionsModal.length) {
      return;
    }
    this.setState((state) => {
      const options = state.optionsModal;
      options[index] = { ...options[index], name: value };
      return { optionsModal: options };
    });
  };

  handleChangeResultInModalByIndex = (optionIndex, isResult) => {
    if (optionIndex >= this.state.optionsModal.length) {
      return;
    }
    this.setState((state) => {
      const options = state.optionsModal;
      const currentSelectIndex = state.optionsModal.findIndex((v) => v.result);
      if (currentSelectIndex > -1) {
        options[currentSelectIndex].result = 0;
      }
      options[optionIndex] = { ...options[optionIndex], result: +isResult };
      return { optionsModal: options };
    });
  };

  handleChangeTextModal = (order, value) => {
    if (value != ' ') {
      const index = this.state.optionsModal.findIndex((value, index) => {
        return value.order == order;
      });
      if (index != -1) {
        this.state.optionsModal.splice(index, 1, { name: value, order: order, result: 0 });
      }
      // const arr = this.state.optionsModal?.push({ name: value, order: order, result: 0 });
      this.setState({
        ...this.state,
        optionsModal: this.state.optionsModal,
      });
    }
  };

  renderForm() {
    const { optionsModal } = this.state;

    return (
      <Modal
        show={this.state.addModal}
        onHide={() => this.handleCloseModal('addModal', false)}
        dialogClassName="modal-90w"
        centered
        size="lg"
      >
        <Modal.Header closeButton className="bg-blue">
          <h5 className="text-light pb-0">{this.state.indexQuestionUpdate === -1 ? 'Tạo câu hỏi' : 'Sửa câu hỏi'}</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          <Row className="">
            <Col md={12}>
              {this.state.indexQuestionUpdate === -1 ? (
                <b>Nội dung câu hỏi số: {this.state.questions.length + 1}</b>
              ) : (
                <b>Nội dung câu hỏi số: {this.state.indexQuestionUpdate + 1}</b>
              )}
            </Col>
            <Col md={12} className="mt-2">
              <textarea
                rows={6}
                className="w-100 form-control"
                value={this.state.addModalField.name}
                onChange={(e) => this.onFormChange('name', e.target.value)}
              ></textarea>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="text-blue">
              <i className="fas fa-plus mr-1 cursor" onClick={() => this.AddOptionInModal()}></i>
              <span onClick={() => this.AddOptionInModal()} className="cursor">
                Thêm lựa chọn
              </span>
            </Col>
          </Row>
          {optionsModal.map((value, index) => (
            <Row className="justify-content-end align-items-center mt-2" key={index}>
              <Col sm={10}>
                <FormControl
                  type="text"
                  value={value.name}
                  onChange={(e) => this.handleChangeOptionTextByIndex(index, e.target.value)}
                />
              </Col>
              <Col sm={1} className="p-0">
                <i className="fas fa-times add-btn text-red cursor" onClick={() => this.removeOptionInModal(index)}></i>
              </Col>
              <Col sm={1}>
                <Form.Check
                  type="checkbox"
                  className="px-0 require-check true-check d-inline-block w-25 cursor"
                  checked={value.result == 1}
                  onChange={() => {
                    this.handleChangeResultInModalByIndex(index, !value.result);
                    // console.log(this.state.index, index, !value.result, 'áádsdgsdsgd');
                  }}
                />
              </Col>
            </Row>
          ))}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          {this.state.indexQuestionUpdate === -1 ? (
            <>
              <Button variant="primary" className="text-light" onClick={() => this.addQuestionNoCloseModal()}>
                Tạo mới và tiếp tục
              </Button>
              <Button variant="success" className="text-light" onClick={() => this.addQuestion()}>
                Tạo mới
              </Button>
            </>
          ) : (
            <Button variant="success" className="text-light" onClick={() => this.updateQuestion()}>
              Cập nhật
            </Button>
          )}

          <Button variant="danger" className="text-light" onClick={() => this.handleCloseModal('addModal', false)}>
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderOptionForm() {
    const { questions, addOptionField } = this.state;
    return (
      <Modal
        show={this.state.addOptionModal}
        onHide={() => this.setShowModal('addOptionModal', false)}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton className="text-white bg-blue">
          <h5 className="text-light">Tạo câu trả lời</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          <Row className="">
            <Col md={12}>
              <span>Nội dung câu trả lời cho câu hỏi: </span>
              <b>{questions[addOptionField.questionIndex]?.name}</b>
            </Col>
            <Col md={12} className="mt-2">
              <textarea
                rows={4}
                className="w-100 form-control"
                value={this.state.addOptionField.name}
                onChange={(e) => this.onOptionFormChange('name', e.target.value)}
              ></textarea>
            </Col>
          </Row>
          {/* <Row className="align-items-center">
                        <Col md={5}>
                            <b>
                                Thứ tự của câu trả lời
                            </b>
                        </Col>
                        <Col md={7} className="mt-2">
                            <FormControl
                                type="number"
                                min="0"
                                value={this.state.addModalField.order || questions[addOptionField.questionIndex]?.job_exam_answers.length + 1}
                                onChange={(e) => this.onOptionFormChange("order", e.target.value)}
                            >
                            </FormControl>
                        </Col>
                    </Row> */}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="success" className="header-btn" onClick={() => this.addOption()}>
            Tạo mới
          </Button>
          <Button variant="danger" className="header-btn" onClick={() => this.setShowModal('addOptionModal', false)}>
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderButton() {
    return (
      <RowAntd justify="space-between">
        <ColAntd>
          <ButtonSystemStyle
            htmlType="button"
            onClick={() => {
              // ModalAntd.confirm({
              //   title: 'Xác nhận',
              //   icon: <ExclamationCircleOutlined />,
              //   content: 'Bạn có muốn lưu thông tin trước khi chuyển sang nội dung khác?',
              //   okText: 'Lưu',
              //   cancelText: 'Hủy',
              //   onOk: () =>
              this.submit('prev');
              // });
            }}
          >
            Quay lại
          </ButtonSystemStyle>
        </ColAntd>
        <ColAntd></ColAntd>
      </RowAntd>
    );
  }

  render() {
    return (
      <>
        {this.state.isLoading && <Loading />}
        {this.renderHeader()}
        {this.renderTest()}
        {this.renderDescription()}
        {this.renderForm()}
        {this.renderOptionForm()}
        {this.renderButton()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  jobState: state.jobDetailReducer,
});

const mapDispatchToProps = {
  getJobDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Exam));
