import { notifyFail } from '@utils/notify';
import { Image, Upload } from 'antd';
import React from 'react';
// import axiosInstance, { ApiClient } from 'services/ApiService';
import styled from 'styled-components';
// import { notificationError } from 'utils/notification';

const UploadComponent = ({
  accept = 'image/*',
  listType = 'text',
  uploadType = 'single',
  isShowFileList = true,
  isUploadServerWhenUploading = false,
  onSuccessUpload,
  children,
  maxLength = 5,
  initialFile,
  disabled,
  size,
}) => {
  const [files, setFiles] = React.useState([]);
  const [progress, setProgress] = React.useState(0);
  const [visiblePreview, setVisiblePreview] = React.useState(false);

  const firstLoad = React.useRef(false);

  const uploadImage = async options => {
    const { onSuccess, onError, file, onProgress } = options;

    if (files.length > maxLength) {
      file.status = 'error';
      const error = new Error('Some error');
      if (uploadType === 'single') {
        setFiles([file]);
      } else {
        setFiles(f => [...f.filter(_f => _f.status !== 'uploading'), file]);
      }
      onError({ error });
      return notifyFail('Vượt quá số lượng cho phép');
    }
    if (isUploadServerWhenUploading) {
      const fmData = new FormData();
      const config = {
        headers: {
          Accept: 'multipart/form-data',
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: event => {
          const percent = Math.floor((event.loaded / event.total) * 100);
          setProgress(percent);
          if (percent === 100) {
            setTimeout(() => setProgress(0), 1000);
          }
          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };
      fmData.append('file', file);
      try {
        // const res = await AxiosClient.post('/files/upload/single/image', fmData, config);
        // if (res.status) {
        //   onSuccessUpload(res?.data);
        //   onSuccess('ok');
        // } else {
        //   file.status = 'error';
        //   const error = new Error('Some error');
        //   if (uploadType === 'single') {
        //     setFiles([file]);
        //   } else {
        //     setFiles((f) => [...f.filter((_f) => _f.status !== 'uploading'), file]);
        //   }
        //   onError({ error });
        // }
      } catch (err) {
        file.status = 'error';
        const error = new Error('Some error');
        if (uploadType === 'single') {
          setFiles([file]);
        } else {
          setFiles(f => [...f.filter(_f => _f.status !== 'uploading'), file]);
        }
        onError({ error });
      }
    } else {
      setTimeout(() => onSuccess('ok'), 1000);
    }
  };

  const handleOnChange = ({ file, fileList, event }) => {
    // check size > 2mb reject
    if (file?.size > 300 * 1024) {
      file.status = 'error';

      return notifyFail('Dung lượng ảnh nhỏ hơn 300KB');
    }

    if (file.status !== 'error') {
      setFiles(fileList);
    }
    if (file.status !== 'removed' && file?.status === 'done') {
      !isUploadServerWhenUploading && onSuccessUpload(file);
    }
  };

  const handlePreview = async file => {
    setVisiblePreview(true);
    return;
  };

  React.useEffect(() => {
    if (firstLoad?.current) return;
    if (initialFile) {
      setFiles(initialFile);
      firstLoad.current = true;
    }
  }, [initialFile]);

  return (
    <>
      <UploadStyled
        size={size}
        disabled={disabled}
        accept={accept}
        customRequest={uploadImage}
        onChange={handleOnChange}
        listType={listType}
        fileList={isShowFileList ? files : []}
        className="image-upload-grid"
        onPreview={handlePreview}
        onRemove={() => onSuccessUpload(null)}
      >
        {files.length >= maxLength ? null : uploadType === 'single' &&
          files.length >= 1 ? null : listType === 'text' ? (
          children
        ) : (
          <div>+</div>
        )}
      </UploadStyled>
      {listType !== 'text' && (
        <Image.PreviewGroup
          preview={{
            visible: visiblePreview,
            onVisibleChange: visible => setVisiblePreview(visible),
          }}
        >
          {files.map((file, index) => {
            return (
              <Image
                key={Math.random().toString()}
                src={file?.thumbUrl || file.url}
                width={0}
                style={{ display: 'none' }}
              />
            );
          })}
        </Image.PreviewGroup>
      )}
    </>
  );
};

const UploadStyled = styled(Upload)`
  width: ${props => (props?.size ? '140px' : '80px')};
  height: ${props => (props?.size ? '140px' : '80px')};
  & img {
    object-fit: none !important;
  }
  & .ant-upload-list-picture-card .ant-upload-list-item,
  .ant-upload-list-picture .ant-upload-list-item {
    padding: 2px;
  }
  & .ant-upload.ant-upload-select-picture-card,
  &
    .ant-upload-list-item.ant-upload-list-item-uploading.ant-upload-list-item-list-type-picture-card {
    width: ${props => (props?.size ? '140px' : '80px')};
    height: ${props => (props?.size ? '140px' : '80px')};
    ${props => (props?.size ? 'border-radius: 10px !important;' : '')};
  }

  & .ant-upload-list-item-thumbnail {
    display: none;
  }
`;

export default UploadComponent;
