import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, DatePicker, Row } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  requestGetTelemarketingInJob,
  requestSubJobFilter,
} from '../services/api';
import { requestGetCallStatus, requestTelesaleDetail } from '@constants/Api';
import MultiSelectControl from '@components/ui/forms/MultiSelectControl';
import { VietnameseString } from '@constants/VietnameseString';
import { OPTION_SCHEDULE_CALL_BACK } from '../Constants';
import CallUtil from '@components/callProviders/CallUtil';

const { RangePicker } = DatePicker;

const colSpanSelect = { xxl: 4, xl: 6, lg: 6, md: 8, sm: 24, xs: 24 };
const colSpanRangePicker = { xxl: 8, xl: 12, lg: 12, md: 8, sm: 24, xs: 24 };

const formatDateTime = 'YYYY/MM/DD';

function CustomersFilter(props) {
  const { isSubJob, jobDetail, params, handleChangeSearchParams } = props;

  const { id: jobId } = useParams();

  const [subJob, setSubJob] = useState([]);
  const [telemarketing, setTelemarketing] = useState([]);
  const [jobStatus, setJobStatus] = useState([]);
  const [callStatus, setCallStatus] = useState([]);

  async function getAllSubJob() {
    try {
      const { data } = await requestSubJobFilter({
        job_category_id: jobId,
        enterprise_id: '',
        telesale_id: '',
        limit: '',
      });
      setSubJob(_prevState => {
        return data.map(sub => ({
          label: sub.name,
          value: sub.id,
        }));
      });
    } catch (error) {
      console.log('Error getAllSubJob: ', { error });
    }
  }

  async function getJobStatus() {
    try {
      const { data } = await requestTelesaleDetail({
        job_id: jobId,
        status: '',
      });

      setJobStatus(_prevState => {
        return data.status.map(s => ({
          label: s.name,
          value: s.id,
        }));
      });
    } catch (error) {
      console.log('Error getJobStatus: ', { error });
    }
  }

  async function getAllTelemarketing() {
    try {
      const { data } = await requestGetTelemarketingInJob({
        job_id: jobId,
        limit: 10000,
      });

      setTelemarketing(_prevState => {
        return data.map(sale => ({
          label: sale.full_name,
          value: sale.id,
        }));
      });
    } catch (error) {
      console.log('Error getAllTelemarketing: ', { error });
    }
  }

  async function getCallStatus() {
    try {
      // const { data } = await requestGetCallStatus();
      const data = CallUtil?.listCallStatus;
      setCallStatus(_prevState => {
        return data.map(s => ({
          label: s.name,
          value: s.id,
        }));
      });
    } catch (error) {
      console.log('Error getCallStatus: ', { error });
    }
  }

  useEffect(() => {
    getAllSubJob();
    getJobStatus();
    getAllTelemarketing();
    getCallStatus();
  }, []);

  useEffect(() => {
    const timestamp1 = moment(new Date()).startOf('week').unix();
    const timestamp2 = moment(jobDetail.created_at).unix();

    if (jobDetail.created_at && timestamp2 > timestamp1) {
      handleChangeSearchParams({
        ...params,
        from: moment(jobDetail.created_at).format(formatDateTime),
      });
    }
  }, [jobDetail]);
  console.log('CallUtil', CallUtil?.listCallStatus);
  return (
    <Row gutter={[16, 16]}>
      {isSubJob ? (
        <Fragment />
      ) : (
        <Col {...colSpanSelect}>
          <MultiSelectControl
            className="w-100"
            options={subJob}
            value={params.job_id}
            onChange={value => handleChangeSearchParams({ job_id: value })}
            placeholder={VietnameseString.sub_job}
          />
        </Col>
      )}

      <Col {...colSpanSelect}>
        <MultiSelectControl
          className="w-100"
          options={telemarketing}
          value={params.telesale_id}
          onChange={value => handleChangeSearchParams({ telesale_id: value })}
          placeholder={VietnameseString.telemarketing}
        />
      </Col>

      <Col {...colSpanSelect}>
        <MultiSelectControl
          className="w-100"
          options={jobStatus}
          value={params.status}
          onChange={value => handleChangeSearchParams({ status: value })}
          placeholder={VietnameseString.status}
        />
      </Col>

      <Col {...colSpanSelect}>
        <MultiSelectControl
          className="w-100"
          options={[
            ...callStatus,
            {
              label: VietnameseString.call_back_appointment,
              value: OPTION_SCHEDULE_CALL_BACK,
            },
          ]}
          value={params.call_status_id}
          onChange={value =>
            handleChangeSearchParams({ call_status_id: value })
          }
          placeholder="Trạng thái cuộc gọi"
        />
      </Col>

      <Col {...colSpanRangePicker}>
        <RangePicker
          className="w-100"
          format={'DD/MM/YYYY'}
          allowClear={true}
          value={
            params.fromDate &&
            params.toDate && [moment(params.fromDate), moment(params.toDate)]
          }
          disabledDate={current => {
            return (
              current &&
              (current < moment(jobDetail.created_at).startOf('day') ||
                current > moment())
            );
          }}
          onChange={dates => {
            let from = '';
            let to = '';
            if (dates) {
              from = moment(dates[0]).format(formatDateTime);
              to = moment(dates[1]).format(formatDateTime);
            }
            handleChangeSearchParams({
              fromDate: from,
              toDate: to,
            });
          }}
        />
      </Col>
    </Row>
  );
}

CustomersFilter.propTypes = {
  isSubJob: PropTypes.bool,
  jobDetail: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  handleChangeSearchParams: PropTypes.func.isRequired,
};

export default CustomersFilter;
