import {
  requestGetAllJob,
  requestQuestionsAndAnswerReport,
} from '@constants/Api';
import {
  formatTime,
  rmVN,
  setSearchParamsInUrlVer2,
} from '@constants/funcHelper';
import {
  requestSubJobFilter,
  requestSubJobFilterUpdate,
} from '@screens/Enterprise/Job/services/api';
import { Col, DatePicker, Empty, Input, Row, Select, Skeleton } from 'antd';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
const { RangePicker } = DatePicker;

const { Option } = Select;

const colSpan = {
  xxl: 4,
  xl: 6,
  lg: 8,
  sm: 12,
  xs: 24,
};

const NONE_TAG = {
  VALUE: 0,
  KEY: -1,
};
const CALL_REVIEW_STATUS = {
  DEFAULT: '',
  NOT_REVIEW: 0,
  REVIEWED: 1,
};
const CALL_TYPE_OPTION = {
  DEFAULT: '',
  CALL_OUT: 1,
  CALLED: 2,
};

export default function Filter(props) {
  const {
    params,
    searchBarChange,
    listTags,
    listDepartment,
    getListDepartment,
    listTelesales,
    getListTelesale,
    callStatus,
    keywordList,
    listReviewer,
    isLoading,
  } = props;

  const userInfo = useSelector(store => store.userReducer);

  const [questions, setQuestions] = React.useState([]);
  const [results, setResults] = React.useState([]);
  const [parentJob, setParentJob] = React.useState([]);
  const [subJob, setSubJob] = React.useState([]);

  const getQuestionInJob = React.useCallback(
    async function (jobId) {
      try {
        const { data } = await requestQuestionsAndAnswerReport({
          enterprise_id: userInfo.data.enterprise_id,
          job_category_id: jobId,
        });

        setQuestions(data);
      } catch (error) {
        console.log('Error getQuestionInJob: ', { error });
      }
    },
    [userInfo.data.enterprise_id]
  );

  const getAllJob = React.useCallback(async () => {
    try {
      const { data } = await requestGetAllJob();
      setParentJob(data);
    } catch (error) {
      console.log('Error getAllJob: ', { error });
    }
  }, []);

  const getAllSubJob = React.useCallback(async function (parentJob) {
    try {
      const { data } = await requestSubJobFilterUpdate({
        job_category_id: parentJob,
        enterprise_id: '',
        telesale_id: '',
        limit: '',
      });
      setSubJob(data);
    } catch (error) {
      console.log('Error getAllSubJob: ', { error });
    }
  }, []);

  React.useEffect(() => {
    getAllJob();
  }, [getAllJob]);

  React.useEffect(() => {
    getAllSubJob(params.job_parent_id);

    let temp = [];
    if (params.job_id.length) {
      temp = params.job_id;
    } else if (params.job_parent_id.length) {
      temp = params.job_parent_id;
    }
    getQuestionInJob(temp);
  }, [getAllSubJob, getQuestionInJob, params.job_id, params.job_parent_id]);

  React.useEffect(() => {
    const { job_form_question_id, job_form_answers_id } = params;
    //Danh sách các đáp án có thể chọn được trong filter kết quả
    //Chia nhóm theo từng câu hỏi
    let arrayResultId = [];
    let result = [];
    job_form_question_id.length &&
      questions.length &&
      job_form_question_id.forEach(id => {
        const temp = questions?.find(ques => ques.id === id);

        result.push({
          label: temp?.name,
          options: temp?.job_form_answers.map(el => {
            arrayResultId.push(el?.id);
            return {
              label: el?.name,
              value: el?.id,
            };
          }),
        });
      });
    setResults(result);

    //Danh sách lựa chọn trong filter câu hỏi thay đổi -> Cập nhật lại lựa chọn trong filter kết quả
    //Loại bỏ những kết quả mà câu hỏi bị hủy chọn
    if (job_form_question_id.length && arrayResultId.length) {
      const answersIdSelected = job_form_answers_id.filter(item =>
        arrayResultId.includes(item)
      );
      if (answersIdSelected.length) {
        searchBarChange({ job_form_answers_id: answersIdSelected });
        setSearchParamsInUrlVer2({ job_form_answers_id: answersIdSelected });
      }
    }
    if (
      job_form_question_id.length === 0 &&
      window.location.search.includes('job_form_answers_id')
    ) {
      searchBarChange({ job_form_answers_id: [] });
    }
  }, [params.job_form_question_id, questions]);

  return (
    <Skeleton loading={isLoading}>
      <Row gutter={[8, 8]}>
        <Col {...colSpan}>
          <Input.Search
            placeholder="Tên khách hàng, số điện thoại, email"
            allowClear
            value={params.search}
            onSearch={searchKey => {
              searchBarChange({ search: String(searchKey) });
              setSearchParamsInUrlVer2({ search: String(searchKey) });
            }}
            onChange={e => {
              searchBarChange({ search: String(e.target.value) });
              setSearchParamsInUrlVer2({ search: String(e.target.value) });
            }}
            style={{ width: '100%' }}
          />
        </Col>

        <Col {...colSpan}>
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return rmVN(option.children).indexOf(rmVN(input)) >= 0;
            }}
            placeholder="Phân loại"
            allowClear
            maxTagCount={1}
            value={params.tag_ids}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu!</span>}
              />
            }
            onChange={value => {
              searchBarChange({ tag_ids: value });
              setSearchParamsInUrlVer2({ tag_ids: value });
            }}
          >
            <Option key={NONE_TAG.KEY} value={NONE_TAG.VALUE}>
              Không có phân loại
            </Option>
            {listTags?.length > 0 &&
              listTags.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.name}
                </Option>
              ))}
          </Select>
        </Col>

        <Col {...colSpan}>
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return rmVN(option.children).indexOf(rmVN(input)) >= 0;
            }}
            placeholder="Phòng ban"
            allowClear
            maxTagCount={1}
            value={params.department_id}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu!</span>}
              />
            }
            onClick={() => {
              getListDepartment(params.telesale_id);
            }}
            onChange={value => {
              searchBarChange({ department_id: value });
              setSearchParamsInUrlVer2({ department_id: value });
            }}
          >
            {listDepartment?.length > 0 &&
              listDepartment.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.name}
                </Option>
              ))}
          </Select>
        </Col>

        <Col {...colSpan}>
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return rmVN(option.children).indexOf(rmVN(input)) >= 0;
            }}
            placeholder="Sale"
            allowClear
            maxTagCount={1}
            value={params.telesale_id}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu!</span>}
              />
            }
            onClick={() => {
              getListTelesale(params.department_id);
            }}
            onChange={value => {
              searchBarChange({ telesale_id: value });
              setSearchParamsInUrlVer2({ telesale_id: value });
            }}
          >
            {listTelesales?.length > 0 &&
              listTelesales.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.full_name}
                </Option>
              ))}
          </Select>
        </Col>

        <Col {...colSpan}>
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return rmVN(option.children).indexOf(rmVN(input)) >= 0;
            }}
            placeholder="Công việc"
            allowClear
            maxTagCount={1}
            value={params.job_parent_id}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu!</span>}
              />
            }
            onChange={value => {
              searchBarChange({
                job_parent_id: value,
                job_id: [],
                job_form_question_id: [],
                job_form_answers_id: [],
              });
              setSearchParamsInUrlVer2({
                job_parent_id: value,
                job_id: [],
                job_form_question_id: [],
                job_form_answers_id: [],
              });
            }}
          >
            {parentJob.map(job => (
              <Option key={job.id} value={job.id}>
                {job.name}
              </Option>
            ))}
          </Select>
        </Col>

        <Col {...colSpan}>
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return rmVN(option.children).indexOf(rmVN(input)) >= 0;
            }}
            placeholder="Giao việc"
            allowClear
            maxTagCount={1}
            value={params.job_id}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu!</span>}
              />
            }
            onChange={value => {
              searchBarChange({
                job_id: value,
                job_form_question_id: [],
                job_form_answers_id: [],
              });
              setSearchParamsInUrlVer2({
                job_id: value,
                job_form_question_id: [],
                job_form_answers_id: [],
              });
            }}
          >
            {subJob.map(sub => (
              <Option key={sub.id} value={sub.id}>
                {sub.name}
              </Option>
            ))}
          </Select>
        </Col>

        <Col {...colSpan}>
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return rmVN(option.children).indexOf(rmVN(input)) >= 0;
            }}
            placeholder="Người đánh giá"
            allowClear
            maxTagCount={1}
            value={params.call_reviewed_created_by}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu!</span>}
              />
            }
            onChange={value => {
              searchBarChange({ call_reviewed_created_by: value });
              setSearchParamsInUrlVer2({ call_reviewed_created_by: value });
            }}
          >
            {listReviewer?.length > 0 &&
              listReviewer.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.full_name}
                </Option>
              ))}
          </Select>
        </Col>

        <Col {...colSpan}>
          <Select
            style={{ width: '100%' }}
            optionFilterProp="children"
            filterOption={(input, option) => {
              return rmVN(option.children).indexOf(rmVN(input)) >= 0;
            }}
            placeholder="Từ khóa cuộc gọi"
            allowClear
            value={params.keyword || null}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu!</span>}
              />
            }
            onChange={value => {
              searchBarChange({ keyword: value });
              setSearchParamsInUrlVer2({ keyword: value });
            }}
          >
            {keywordList?.length > 0 &&
              keywordList.map((item, index) => (
                <Option key={index} value={item.name}>
                  {item.name}
                </Option>
              ))}
          </Select>
        </Col>

        <Col {...colSpan}>
          <Select
            style={{ width: '100%' }}
            placeholder="Trạng thái đánh giá"
            allowClear
            value={params.call_reviewed || null}
            onChange={value => {
              searchBarChange({ call_reviewed: value || '' });
              setSearchParamsInUrlVer2({ call_reviewed: value || '' });
            }}
          >
            <Option value={CALL_REVIEW_STATUS.REVIEWED}>Đã đánh giá</Option>
            <Option value={CALL_REVIEW_STATUS.NOT_REVIEW}>Chưa đánh giá</Option>
          </Select>
        </Col>

        <Col {...colSpan}>
          <Select
            style={{ width: '100%' }}
            placeholder="Loại cuộc gọi"
            allowClear
            value={params.call_type || null}
            onChange={value => {
              searchBarChange({ call_type: value || '' });
              setSearchParamsInUrlVer2({ call_type: value || '' });
            }}
          >
            <Option value={CALL_TYPE_OPTION.CALL_OUT}>Gọi đi</Option>
            <Option value={CALL_TYPE_OPTION.CALLED}>Gọi đến</Option>
          </Select>
        </Col>

        <Col {...colSpan}>
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return rmVN(option.children).indexOf(rmVN(input)) >= 0;
            }}
            placeholder="Trạng thái cuộc gọi"
            allowClear
            maxTagCount={1}
            value={params.call_status}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu!</span>}
              />
            }
            onChange={value => {
              searchBarChange({ call_status: value });
              setSearchParamsInUrlVer2({ call_status: value });
            }}
          >
            {callStatus?.length > 0 &&
              callStatus.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.name}
                </Option>
              ))}
          </Select>
        </Col>

        <Col {...colSpan}>
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return rmVN(option.children).indexOf(rmVN(input)) >= 0;
            }}
            placeholder="Câu hỏi"
            allowClear
            maxTagCount={1}
            value={params.job_form_question_id}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu!</span>}
              />
            }
            onChange={value => {
              searchBarChange({ job_form_question_id: value });
              setSearchParamsInUrlVer2({ job_form_question_id: value });
            }}
          >
            {questions.length > 0 ? (
              questions.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.name}
                </Option>
              ))
            ) : (
              <></>
            )}
          </Select>
        </Col>

        <Col {...colSpan}>
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return rmVN(option.children).indexOf(rmVN(input)) >= 0;
            }}
            placeholder="Kết quả"
            allowClear
            maxTagCount={1}
            value={params.job_form_answers_id}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu!</span>}
              />
            }
            onChange={value => {
              searchBarChange({ job_form_answers_id: value });
              setSearchParamsInUrlVer2({ job_form_answers_id: value });
            }}
            options={results}
          />
        </Col>

        <Col {...colSpan}>
          <RangePicker
            style={{ width: '100%' }}
            placeholder={['Từ ngày', 'Đến ngày']}
            value={[
              params.fromDate ? moment(params.fromDate) : '',
              params.toDate ? moment(params.toDate) : '',
            ]}
            onChange={(date, dateString) => {
              if (
                dateString !== null &&
                dateString[0] !== '' &&
                dateString[1] !== ''
              ) {
                setSearchParamsInUrlVer2({
                  fromDate: formatTime(dateString[0], 'YYYY-MM-DD'),
                  toDate: formatTime(dateString[1], 'YYYY-MM-DD'),
                  page: 1,
                });

                searchBarChange({
                  fromDate: formatTime(dateString[0], 'YYYY-MM-DD'),
                  toDate: formatTime(dateString[1], 'YYYY-MM-DD'),
                });
              } else {
                setSearchParamsInUrlVer2({ fromDate: '', toDate: '', page: 1 });
                searchBarChange({ fromDate: '', toDate: '' });
              }
            }}
          />
        </Col>
      </Row>
    </Skeleton>
  );
}
