import {
    GET_TASK_REVIEWER, GET_TASK_REVIEWER_SUCCESS, GET_TASK_REVIEWER_FAIL,
} from "@actions/type";

const initialState = {
    data: {},
    isLoading: false,
    error: null
};

export default function (state = initialState, action) {

    switch (action.type) {

        // *************  GET INFO ****************//
        case GET_TASK_REVIEWER:
            return {
                ...state,
                isLoading: true,
            }
        case GET_TASK_REVIEWER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload.response.data,
                error: null
            }
        case GET_TASK_REVIEWER_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        default:
            return state;
    }
}