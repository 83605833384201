import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  TimePicker,
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { VietnameseString } from '@constants/VietnameseString';
import { getListAccountZalo } from '@screens/Enterprise/Settings/zaloOA/services/api';
import {
  JOB_CRON_TIME_CODE,
  JOB_CRON_TIME_REPEAT,
  SCRIPT_TYPE,
  VALUES_JOB_CRON_TIME_WEEK,
} from '../../Constants';

import { ScrollStyle } from '@styles/Style';
import useDebounce from 'src/hooks/useDebounce';
import SaleSendSms from './SaleSendSms';

const { Option } = Select;

const FormStyled = styled(Form)`
  &&& {
    text-align: start;
    width: 100%;

    .ant-form-item {
      margin-bottom: 0;
    }
  }
`;

const RadioStyled = styled(Radio)`
  &&& {
    .radio__item {
      display: flex;
      align-items: center;
    }

    .radio__item-value {
      span {
        display: flex;
        align-items: center;

        font-weight: normal;
        margin-left: 12px;

        div {
          display: flex;
          align-items: center;

          + div {
            margin-left: 12px;
          }
        }
      }
    }
  }
`;

const TemplateAreaStyled = styled.div`
  &&& {
    display: flex;
    align-items: center;
    width: 100%;

    .plus-outlined__icon {
      cursor: pointer;

      svg {
        margin-left: 16px;
        font-size: 32px;
        color: red;
      }
    }
  }
`;

export const MessageContentStyled = styled(ScrollStyle)`
  border: 1px solid #f0f0f0;
  padding: 12px;
  border-radius: 8px;
  overflow-y: scroll;
`;

export const MultimediaAreaStyled = styled(ScrollStyle)`
  display: flex;
  align-items: center;
  margin: 4px;

  overflow-x: auto;
`;

export const ItemMediaStyled = styled.div`
  width: 300px;

  + .item-media {
    margin-left: 8px;
  }
`;

const DateInputStyled = styled.div`
  &&& {
    input[type='date']::-webkit-inner-spin-button,
    input[type='date']::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
  }
`;

const ContainerDatePickerStyled = styled.div`
  &&& {
    padding: 12px;

    .wrapper-icon {
      position: absolute;
      top: 0;
      right: 8px;
    }

    .icon svg {
      font-size: 18px;

      :hover {
        color: red;
        font-size: 19px;
      }
    }
  }
`;

const timeFormat = 'DD/MM/YYYY';

function RadioItem(props) {
  const {
    dataJobCron,
    handleChangeFormAdd,
    title,
    radioItemValue,
    radioItemSelected,
    subTitle,
    radioItemExpand,
  } = props;

  return (
    <div className="radio__item">
      <span className="radio__item-title">{title}</span>
      <span className="radio__item-value">
        {radioItemSelected === radioItemValue ? (
          <span>
            {subTitle ? (
              <div>
                {subTitle}:&nbsp;&nbsp;&nbsp;
                <Select
                  value={dataJobCron.value}
                  onClick={event => {
                    event.stopPropagation();
                    event.preventDefault();
                  }}
                  style={{ width: '100px' }}
                  placeholder={subTitle}
                  onChange={value => {
                    handleChangeFormAdd({ value });
                  }}
                >
                  {radioItemExpand.map((el, idx) => (
                    <Option key={idx} value={el.value}>
                      {el.name}
                    </Option>
                  ))}
                </Select>
              </div>
            ) : null}

            <div>
              {VietnameseString.hour}:&nbsp;&nbsp;&nbsp;
              <TimePicker
                format={'HH:mm'}
                value={
                  dataJobCron.time_repeat
                    ? moment(Number(dataJobCron.time_repeat))
                    : undefined
                }
                placeholder={VietnameseString.hour}
                onChange={value => {
                  handleChangeFormAdd({
                    time_repeat: new Date(value).getTime(),
                  });
                }}
              />
            </div>
          </span>
        ) : null}
      </span>
    </div>
  );
}

function ScriptOfZalo(props) {
  const {
    type,
    dataJobCron,
    handleChangeFormAdd,
    isCheckEvoucher,
    idEvoucher,
  } = props;

  const [form] = Form.useForm();

  // const valueTemplate = Form.useWatch('message_template_id', form);

  const [visibleDatePicker, setVisibleDatePicker] = useState(false);

  useEffect(() => {
    if (dataJobCron.zalo_account_id === '') {
      form.setFieldsValue({
        zalo_account_id: undefined,
      });
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      zalo_account_id: dataJobCron?.zalo_account_id,
    });
  }, [dataJobCron?.zalo_account_id]);

  const [searchZaloAccount, setSearchZaloAccount] = useState('');
  const debounceSearchZaloAccount = useDebounce(searchZaloAccount, 500);

  //   zalo account
  const [zaloAccounts, setZaloAccounts] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  //   call list zalo
  React.useEffect(() => {
    setLoadingSearch(true);
    getListAccountZalo({
      search: debounceSearchZaloAccount
        ? debounceSearchZaloAccount?.trim()
        : '',
      limit: 24,
    })
      .then(res => {
        setZaloAccounts(res?.data);
      })
      .finally(() => setLoadingSearch(false));
  }, [debounceSearchZaloAccount]);

  return (
    <Fragment>
      <FormStyled name={'scriptOfZalo'} form={form} initialValues={dataJobCron}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <div>
              <Form.Item name="is_display" label="">
                <Checkbox
                  checked={Boolean(dataJobCron.is_display)}
                  onChange={event =>
                    handleChangeFormAdd({
                      is_display: Number(event.target.checked),
                    })
                  }
                >
                  {
                    VietnameseString.allow_displaying_templates_in_sales_accounts
                  }
                </Checkbox>
              </Form.Item>
            </div>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col lg={12} sm={24} xs={24}>
            <div>
              <Form.Item
                name="zalo_account_id"
                label="Chọn tài khoản"
                rules={[{ required: true, message: '' }]}
              >
                <Select
                  placeholder="Chọn tài khoản"
                  onChange={value => {
                    let dataForm = {};

                    dataForm.zalo_account_id = value;

                    handleChangeFormAdd(dataForm);
                  }}
                  showSearch
                  onSearch={value => setSearchZaloAccount(value)}
                  filterOption={false}
                  loading={loadingSearch}
                >
                  {zaloAccounts.map(el => (
                    <Option key={el.id} value={el.id}>
                      Zalo - {el.phone}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div>
              <Form.Item
                name="time_option"
                label={VietnameseString.sending_time}
                rules={[{ required: true, message: '' }]}
              >
                <Radio.Group
                  value={dataJobCron.time_option}
                  onChange={event => {
                    event.target.value === JOB_CRON_TIME_REPEAT.PERIODICALLY
                      ? handleChangeFormAdd({
                          time_option: event.target.value,
                          time_repeat: '',
                          code: dataJobCron.code || JOB_CRON_TIME_CODE.ALlWAYS,
                        })
                      : handleChangeFormAdd({
                          time_option: event.target.value,
                          time_repeat: '',
                        });
                  }}
                >
                  <Radio value={JOB_CRON_TIME_REPEAT.ONCE}>
                    {VietnameseString.once}
                  </Radio>
                  <Radio value={JOB_CRON_TIME_REPEAT.PERIODICALLY}>
                    {VietnameseString.periodically}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </div>

            {dataJobCron.time_option === JOB_CRON_TIME_REPEAT.ONCE ? (
              <div>
                <DatePicker
                  format={timeFormat + ' HH:mm'}
                  showToday={false}
                  allowClear={false}
                  open={visibleDatePicker}
                  disabledDate={currentDate =>
                    currentDate &&
                    currentDate < moment(new Date()).startOf('day')
                  }
                  value={
                    dataJobCron.time_repeat
                      ? moment(Number(dataJobCron.time_repeat))
                      : undefined
                  }
                  onChange={date =>
                    handleChangeFormAdd({
                      value: '',
                      time_repeat: new Date(date).getTime(),
                    })
                  }
                  onClick={() => {
                    setVisibleDatePicker(true);
                  }}
                  panelRender={panelNode => {
                    // panelNode === element calendar
                    return (
                      <ContainerDatePickerStyled>
                        {/* <div className="wrapper-icon">
                          <CloseCircleOutlined
                            className="icon"
                            onClick={() => {
                              setVisibleDatePicker(false);
                            }}
                          />
                        </div> */}
                        {panelNode}
                        <Row gutter={[16, 16]}>
                          <Col span={12}>
                            <div>{VietnameseString.date}</div>

                            <DateInputStyled
                              onMouseDown={e => e.stopPropagation()}
                            >
                              <Input
                                type="date"
                                value={
                                  dataJobCron.time_repeat
                                    ? moment(
                                        Number(dataJobCron.time_repeat)
                                      ).format('YYYY-MM-DD')
                                    : undefined
                                }
                                onChange={event => {
                                  handleChangeFormAdd({
                                    value: '',
                                    time_repeat: new Date(
                                      event.target.value
                                    ).getTime(),
                                  });
                                }}
                              />
                            </DateInputStyled>
                          </Col>

                          <Col span={12}>
                            <div>{VietnameseString.hour}</div>

                            <div>
                              <TimePicker
                                format={'HH:mm'}
                                disabledTime={() => ({
                                  disabledHours: () => {
                                    const timePrevCurrentDay = [
                                      ...new Array(
                                        Number(moment().format('HH'))
                                      ),
                                    ].map((_, index) => index);
                                    return moment().format('YYYY-MM-DD') !==
                                      moment(dataJobCron.time_repeat).format(
                                        'YYYY-MM-DD'
                                      )
                                      ? []
                                      : [...timePrevCurrentDay];
                                  },
                                  // disabledMinutes: () => {
                                  //   const timePrevCurrentDay = [
                                  //     ...new Array(
                                  //       Number(moment().format('mm'))
                                  //     ),
                                  //   ].map((_, index) => index);

                                  //   return moment().format('YYYY-MM-DD') !==
                                  //     moment(dataJobCron.time_repeat).format(
                                  //       'YYYY-MM-DD'
                                  //     ) ||
                                  //     moment().format('HH') !==
                                  //       moment(dataJobCron.time_repeat).format(
                                  //         'HH'
                                  //       )
                                  //     ? []
                                  //     : [...timePrevCurrentDay];
                                  // },
                                })}
                                value={
                                  dataJobCron.time_repeat
                                    ? moment(Number(dataJobCron.time_repeat))
                                    : undefined
                                }
                                onChange={(time, timeString) => {
                                  handleChangeFormAdd({
                                    value: '',
                                    time_repeat: new Date(time).getTime(),
                                  });
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </ContainerDatePickerStyled>
                    );
                  }}
                />
                {visibleDatePicker && (
                  <div
                    style={{
                      position: 'fixed',
                      inset: 0,
                      backgroundColor: 'transparent',
                    }}
                    onClick={() => setVisibleDatePicker(false)}
                  />
                )}
              </div>
            ) : (
              <div>
                <Form.Item name="code">
                  <Radio.Group
                    value={dataJobCron.code}
                    onChange={event => {
                      handleChangeFormAdd({
                        code: event.target.value,
                        value: '',
                        time_repeat: '',
                      });
                    }}
                  >
                    <Space direction="vertical">
                      <RadioStyled value={JOB_CRON_TIME_CODE.ALlWAYS}>
                        {VietnameseString.continuous}
                      </RadioStyled>

                      <RadioStyled value={JOB_CRON_TIME_CODE.DATE}>
                        <RadioItem
                          dataJobCron={dataJobCron}
                          handleChangeFormAdd={handleChangeFormAdd}
                          title={VietnameseString.date}
                          radioItemValue={JOB_CRON_TIME_CODE.DATE}
                          radioItemSelected={dataJobCron.code}
                        />
                      </RadioStyled>

                      <RadioStyled value={JOB_CRON_TIME_CODE.WEEK}>
                        <RadioItem
                          dataJobCron={dataJobCron}
                          handleChangeFormAdd={handleChangeFormAdd}
                          title={VietnameseString.week}
                          radioItemValue={JOB_CRON_TIME_CODE.WEEK}
                          radioItemSelected={dataJobCron.code}
                          subTitle="Thứ"
                          radioItemExpand={VALUES_JOB_CRON_TIME_WEEK}
                        />
                      </RadioStyled>

                      <RadioStyled value={JOB_CRON_TIME_CODE.MONTH}>
                        <RadioItem
                          dataJobCron={dataJobCron}
                          handleChangeFormAdd={handleChangeFormAdd}
                          title={VietnameseString.month}
                          radioItemValue={JOB_CRON_TIME_CODE.MONTH}
                          radioItemSelected={dataJobCron.code}
                          subTitle={VietnameseString.date}
                          radioItemExpand={Array.from({ length: 31 }).map(
                            (_, i) => {
                              const v = ++i;
                              return { value: v, name: v };
                            }
                          )}
                        />
                      </RadioStyled>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </div>
            )}
          </Col>

          <Col lg={12} sm={24} xs={24}>
            {/* <Form.Item
              name="message_template_id"
              //   label={VietnameseString.choose_a_template}
              rules={[{ required: true, message: '' }]}
            > */}
            <div style={{ width: '100%' }}>
              <SaleSendSms
                handleSelect={value => {
                  // form.setFieldsValue({ message_template_id: value });
                  handleChangeFormAdd({ message_template_id: value });
                }}
                selectSendSMS={dataJobCron?.message_template_id}
              />
            </div>
            {/* </Form.Item> */}
          </Col>
        </Row>
      </FormStyled>
    </Fragment>
  );
}

ScriptOfZalo.propTypes = {
  type: PropTypes.oneOf([SCRIPT_TYPE.FOR_ZNS, SCRIPT_TYPE.FOR_SMS]),
  dataJobCron: PropTypes.object.isRequired,
  handleChangeFormAdd: PropTypes.func.isRequired,
};

export default ScriptOfZalo;
