import React from 'react';
import styled from 'styled-components';

const WrapperStyle = styled.div`
  background-color: white;
  margin: 10px 15px;
  padding: 8px 16px;
  border-radius: 4px;
`;

export default function Wrapper({ children, style }) {
  return <WrapperStyle style={style}>{children}</WrapperStyle>;
}
