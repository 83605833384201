import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, FormControl, Button, Nav, Table, Modal } from 'react-bootstrap';
import { ROUTER, TYPE_MEDIA, USER_ROLE, STRING } from '@constants/Constant';
import { connect } from 'react-redux';
import reactotron from 'reactotron-react-js';
import DatePickerCustom from '../../../../components/DatePickerCustom';
import { getTelesaleKpi } from '@src/redux/actions';
import { Empty } from 'antd';
import { requestCreateKpi, requestUpdateKpi, requestUpdateEditable, requestDeleteKpi } from '@constants/Api';
import { notifyFail, notifySuccess } from '@utils/notify';
import { getMonth, getDateOfMonth } from '@utils/getMonth';
import { checkAllAttrFilled } from '@utils/checkAllAttrFilled';
import Loading from '@src/components/Loading';
import moment from 'moment';
import { DatePicker, Space } from 'antd';

function FormError(props) {
  if (props.isHidden) {
    return null;
  }

  return <div style={{ color: 'red', width: '100%', textAlign: 'center' }}>{props.errorMessage}</div>;
}

function formatNumber(n) {
  return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

function formatPrice(price) {
  if (!price) return '';
  return price.toString().split('.').join('');
}
class KPI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      shouldShowTime: false,
      lock: false,
      confirmDelete: false,
      activePage: 1,
      currentIndex: '',
      isLoading: false,
      modal: {
        // [STRING.time]: '',
        total_call: '',
        ratio_call: '',
        total_opportunity: '',
        ratio_opportunity: '',
        // conversion: '',
        // ratio_conversion: '',
        revenue: '',
        ratio_revenue: '',
        effective_time: '',
        work_day: '',
        new_customer: '',
        new_customer_percent: '',
      },

      isClick: false,
    };
  }

  componentDidMount() {
    this.getData({ page: 1 });
  }

  getData({ page, telesale_id, limit = 1000 }) {
    const { id } = this.props.match.params;
    this.props.getTelesaleKpi({
      page: page || '',
      telesale_id: id || '',
      limit: limit || '',
    });
  }

  validateFormAdd() {
    const { modal } = this.state;
    if (!checkAllAttrFilled(modal)) {
      return false;
    }
    return true;
  }

  getResetAdd() {
    return {
      // inbound_phone: "",
      total_call: '',
      ratio_call: '',
      total_opportunity: '',
      ratio_opportunity: '',
      // conversion: '',
      // ratio_conversion: '',
      revenue: '',
      ratio_revenue: '',
      // effective_time: '',
      work_day: '',
      new_customer: '',
      new_customer_percent: '',
    };
  }

  async createKpi() {
    const { modal } = this.state;
    const { [STRING.time]: effective_time } = this.state.modal;
    const check = await this.validateFormAdd();
    const { id } = this.props.match.params;
    console.log({ modal });

    if (check) {
      this.setState({
        ...this.state,
        isClick: true,
      });
      const payload = {
        telesale_id: id,
        call: modal.total_call,
        call_percent: modal.ratio_call,
        opportunity: modal.total_opportunity,
        opportunity_percent: modal.ratio_opportunity,
        // convertion: modal.conversion,
        // convertion_percent: modal.ratio_conversion,
        // revenue: modal.revenue ? formatNumber(modal.revenue.toString()) : '',
        revenue: modal.revenue.toString().split('.').join(''),
        revenue_percent: modal.ratio_revenue,
        effective_time: moment(effective_time).format('YYYY-MM-DD'),
        work_day: modal.work_day,
        new_customer: parseInt(modal.new_customer),
        new_customer_percent: parseInt(modal.new_customer_percent),
      };

      try {
        const res = await requestCreateKpi(payload);

        this.props.getTelesaleKpi({ telesale_id: id });

        this.setState((prevState) => ({
          modal: {
            ...this.getResetAdd(),
          },
        }));

        this.setState({ ...this.state, isLoading: false, isClick: false }, () =>
          notifySuccess('Thiết lập KPI thành công')
        );
        this.handleChange('edit', false);
      } catch (err) {
        this.setState({ ...this.state, isLoading: false, isClick: false });
        notifyFail(err.msg);
      }
    }
    return;
  }

  async updateKpi() {
    const { modal, currentIndex } = this.state;
    const { [STRING.time]: effective_time } = this.state.modal;
    console.log(modal, 'modallll');
    const check = await this.validateFormAdd();
    const { id } = this.props.match.params;
    if (check) {
      this.setState({
        ...this.state,
        isClick: true,
      });
      const payload = {
        id: currentIndex,
        call: modal.total_call,
        call_percent: modal.ratio_call,
        opportunity: modal.total_opportunity,
        opportunity_percent: modal.ratio_opportunity,
        // convertion: modal.conversion,
        // convertion_percent: modal.ratio_conversion,
        revenue: parseFloat(formatPrice(modal.revenue)),
        revenue_percent: modal.ratio_revenue,
        effective_time: moment(effective_time).format('YYYY-MM-DD'),
        work_day: modal.work_day,
        new_customer: parseInt(modal.new_customer),
        new_customer_percent: parseInt(modal.new_customer_percent),
      };

      try {
        const res = await requestUpdateKpi(payload);

        this.props.getTelesaleKpi({ telesale_id: id });

        // this.setState((prevState) => ({
        //   modal: {
        //     ...this.getResetAddReviewField(),
        //   },
        // }));

        this.setState({ ...this.state, isLoading: false, isClick: false }, () =>
          notifySuccess('Cập nhật thiết lập KPI thành công')
        );
        this.handleChange('edit', false);
      } catch (err) {
        this.setState({ ...this.state, isLoading: false, isClick: false });
        notifyFail(err.msg);
      }
    }
    return;
  }

  checkValidation = () => {
    const { modal } = this.state;
    if (parseInt(this.state.modal.total_call) < parseInt(this.state.modal.total_opportunity)) {
      return <FormError errorMessage={'Số cơ hội phải nhỏ hơn hoặc bằng số cuộc gọi!'} />;
    }
    if (this.state.modal.conversion > 100) {
      return <FormError errorMessage={'Tỷ lệ chuyển đổi nhỏ hơn hoặc bằng 100%!'} />;
    }
    if (!checkAllAttrFilled(this.state.modal)) {
      return <FormError errorMessage={'Vui lòng điền đầy đủ thông tin!'} />;
    }

    return true;
  };
  async handleChangeLock() {
    const { currentIndex } = this.state;
    const payload = {
      id: currentIndex,
    };
    try {
      const res = await requestUpdateEditable(payload);
      this.setState({ lock: !this.state.lock });
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  handleChange = (field, value) => {
    this.setState({
      ...this.state,
      [field]: value,
    });
  };

  handleChangeModal = (field, value) => {
    console.log({ field, value });
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        [field]: value,
      },
    });
  };

  handleChangeRevenueModal = (field, value) => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        [field]: formatNumber(value) || '',
      },
    });
  };

  renderModal() {
    const { edit, modal, currentIndex, isClick, shouldShowTime } = this.state;
    const { [STRING.time]: effective_time } = this.state.modal;
    const dateFormat = 'MM/YYYY';

    return (
      <Modal
        show={edit}
        onHide={() =>
          this.setState({
            edit: false,
          })
        }
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton className="bg-primary pb-0">
          {currentIndex == '' ? (
            <h5 style={{ color: 'white' }}>Thiết lập KPI cho Sale</h5>
          ) : (
            <h5 style={{ color: 'white' }}>Cập nhật thiết lập KPI cho Sale</h5>
          )}
        </Modal.Header>
        <Modal.Body className="custom-body">
          {this.checkValidation()}
          {/* {shouldShowTime && ( */}
          <Row className="mb-3">
            <Col md={6}>Thời gian</Col>
            <Col md={12}>
              {/* <DatePickerCustom
                className={`date-picker form-control`}
                placeholderText={STRING.time}
                value={modal.effective_time}
                handleChange={this.handleChangeModal}
                selected={effective_time}
                dateFormat="MM/yyyy"
              /> */}
              <DatePicker
                style={{ width: '100%', borderRadius: '0.25rem' }}
                picker="month"
                defaultValue={modal.effective_time ? moment(modal.effective_time, 'YYYY/MM/DD') : ''}
                format={dateFormat}
                placeholder="Thời gian"
                onChange={(date) => {
                  console.log(date);
                  this.handleChangeModal('effective_time', date);
                }}
                disabled={this.state.shouldShowTime}
              />
              {/* {console.log(modal, 'modal')} */}
            </Col>
          </Row>
          {/* )} */}
          <Row>
            <Col md={12}>Ngày làm việc</Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <FormControl
                type="number"
                id=""
                min={0}
                placeholder="Ngày làm việc"
                value={modal.work_day}
                onChange={(e) => this.handleChangeModal('work_day', e.target.value)}
              />
            </Col>
          </Row>
          {/* ---------------------------------- */}
          <Row className="">
            <Col md={12}>Số cuộc gọi</Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <FormControl
                type="number"
                id=""
                min={0}
                placeholder="Số cuộc gọi"
                value={modal.total_call}
                onChange={(e) => this.handleChangeModal('total_call', e.target.value)}
              />
            </Col>
            <Col md={6}>
              <FormControl
                type="number"
                id=""
                min={0}
                placeholder="Tỷ trọng (%)"
                value={modal.ratio_call}
                onChange={(e) => this.handleChangeModal('ratio_call', e.target.value)}
              />
            </Col>
          </Row>
          {/* ---------------------------------- */}
          <Row>
            <Col md={12}>Số cơ hội</Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <FormControl
                type="number"
                id=""
                min={0}
                placeholder="Số cơ hội"
                value={modal.total_opportunity}
                onChange={(e) => this.handleChangeModal('total_opportunity', e.target.value)}
              />
            </Col>
            <Col md={6}>
              <FormControl
                type="number"
                id=""
                min={0}
                placeholder="Tỷ trọng (%)"
                value={modal.ratio_opportunity}
                onChange={(e) => this.handleChangeModal('ratio_opportunity', e.target.value)}
              />
            </Col>
          </Row>
          {/* ---------------------------------- */}
          <Row>
            <Col md={12}>Khách hàng mới</Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <FormControl
                type="number"
                id=""
                min={0}
                placeholder="Số lượng"
                value={modal.new_customer}
                onChange={(e) => this.handleChangeModal('new_customer', e.target.value)}
              />
            </Col>
            <Col md={6}>
              <FormControl
                type="number"
                id=""
                min={0}
                placeholder="Tỷ trọng (%)"
                value={modal.new_customer_percent}
                onChange={(e) => this.handleChangeModal('new_customer_percent', e.target.value)}
              />
            </Col>
          </Row>
          {/* ---------------------------------- */}
          <Row>
            <Col md={12}>Doanh thu</Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <FormControl
                // type="number"
                id=""
                min={0}
                placeholder="Doanh thu"
                value={modal.revenue?.toLocaleString('vi', {
                  currency: 'VND',
                })}
                onChange={(e) => this.handleChangeRevenueModal('revenue', e.target.value)}
              />
            </Col>
            <Col md={6}>
              <FormControl
                type="number"
                id=""
                min={0}
                placeholder="Tỷ trọng (%)"
                value={modal.ratio_revenue}
                onChange={(e) => this.handleChangeModal('ratio_revenue', e.target.value)}
              />
            </Col>
          </Row>
          {/* ---------------------------------- */}
          <Row className="mt-4">
            <Col className="text-center">
              <Button
                disabled={isClick}
                variant="success"
                // onClick={() => {
                //   this.setState({
                //     edit: false,
                //   });
                // }}
                onClick={() => (currentIndex == '' ? this.createKpi() : this.updateKpi())}
                className="text-light"
              >
                {STRING.save}
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  this.setState({
                    edit: false,
                  });
                }}
                className="text-light"
              >
                {STRING.exit}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
  async renderDeleteKpi() {
    const { currentIndex } = this.state;
    try {
      await requestDeleteKpi({ id: currentIndex });
      this.setState({ confirmDelete: false }, () => notifySuccess('Xóa thành công'));
      this.getData({ page: 1 });
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  renderDeleteModal() {
    const { confirmDelete } = this.state;
    return (
      <Modal
        show={confirmDelete}
        onHide={() =>
          this.setState({
            confirmDelete: false,
          })
        }
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton className="bg-primary pb-0">
          <h5 style={{ color: 'white' }}>Bạn chắc chắn muốn xóa ?</h5>
        </Modal.Header>
        <Modal.Body className="custom-body">
          <Row>
            <Col className="button-wrapper">
              <Button variant="success" onClick={() => this.renderDeleteKpi()} className="text-light">
                Có
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  this.setState({
                    confirmDelete: false,
                  });
                }}
                className="text-light"
              >
                {STRING.exit}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  renderBody() {
    return (
      <>
        {this.renderDeleteModal()}
        {this.renderModal()}
        <Row>
          <Col>
            <b>KPI của sale</b>
          </Col>
          <Col>{this.renderButton()}</Col>
        </Row>
        <Row className="mt-2">{this.renderTable()}</Row>
      </>
    );
  }

  renderButton() {
    return (
      <Button
        variant="primary"
        className="text-light"
        onClick={() => {
          this.setState(
            {
              shouldShowTime: false,
              currentIndex: '',
              modal: {
                ...this.getResetAdd(),
              },
            },
            () => this.handleChange('edit', true)
          );
        }}
        style={{ float: 'right' }}
      >
        Thiết lập KPI
      </Button>
    );
  }

  renderTable() {
    const telesaleKpi = this.props.telesaleKpi?.data;
    const itemsCount = this.props.telesaleKpi?.paging;
    return (
      <Table striped bordered>
        <thead style={{ textAlign: 'center' }}>
          <th>STT</th>
          <th>Thời gian</th>
          <th>Tiêu chí</th>
          <th>Tỷ trọng(%)</th>
          <th>Kế hoạch</th>
          <th>Thực hiện</th>
          <th>Kết quả(%)</th>
          <th>Đánh giá</th>
          <th></th>
        </thead>
        <tbody>
          {telesaleKpi && telesaleKpi?.length ? (
            telesaleKpi?.map((telesale, index) =>
              this.renderTableData(telesale, index + itemsCount?.limit * (this.state.activePage - 1) + 1)
            )
          ) : (
            <td colspan="9">
              <Empty description={<span>Không có dữ liệu</span>} />
            </td>
          )}
        </tbody>
      </Table>
    );
  }

  renderTableData(telesale, index) {
    const { lock } = this.state;
    // const telesaleKpi = this.props.telesaleKpi?.data;

    console.log(telesale, 'telesale');
    return (
      <>
        <tr>
          <td rowspan="4" style={{ display: 'table-cell', verticalAlign: 'middle' }}>
            {index}
          </td>
          <td rowspan="4" style={{ display: 'table-cell', verticalAlign: 'middle' }}>
            <p className="m-0">{getMonth(telesale?.effective_time)}</p>
            <p className="m-0">({getDateOfMonth(telesale?.effective_time)})</p>
          </td>
          <td>Số cuộc gọi</td>
          <td>{telesale?.call_percent || 'Chưa cập nhật'}</td>
          <td>{telesale?.call || 'Chưa cập nhật'}</td>
          <td>{telesale?.call_result || 'Chưa cập nhật'}</td>
          <td>{telesale?.call_result_persent || 'Chưa cập nhật'}</td>
          <td rowspan="4" style={{ display: 'table-cell', verticalAlign: 'middle' }}>
            {telesale?.result || 'Chưa đánh giá'}
          </td>
          <td rowspan="4" style={{ display: 'table-cell', verticalAlign: 'middle' }}>
            <i
              className={lock ? 'fas fa-lock-open' : 'fas fa-lock'}
              style={{
                fontSize: 20,
                cursor: 'pointer',
                marginRight: 10,
                color: '#ffc400',
              }}
              onClick={() => {
                this.setState(
                  {
                    currentIndex: telesale.id,
                  },
                  () => {
                    this.handleChangeLock();
                  }
                );
              }}
            />
            <i
              className="btnEdit fa fa-fw fa-edit"
              onClick={() => {
                console.log(telesale);
                this.handleChange('edit', true);
                this.setState({
                  shouldShowTime: true,
                  modal: {
                    effective_time: telesale.effective_time,
                    total_call: telesale.call,
                    ratio_call: telesale.call_percent,
                    total_opportunity: telesale.opportunity,
                    ratio_opportunity: telesale.opportunity_percent,
                    // conversion: telesale.convertion,
                    // ratio_conversion: telesale.convertion_percent,
                    new_customer: telesale.new_customer,
                    new_customer_percent: telesale.new_customer_percent,
                    revenue: telesale.revenue,
                    ratio_revenue: telesale.revenue_percent,
                    work_day: telesale.work_day || '',
                  },
                  currentIndex: telesale.id,
                });
                // reactotron.log(this.state.currentIndex, 'currentIndex');
              }}
            />
            <i
              className="btnDelete far fa-trash-alt"
              onClick={() => {
                this.handleChange('confirmDelete', true);
                this.setState({
                  currentIndex: telesale.id,
                });
              }}
            />
          </td>
        </tr>
        <tr>
          <td>Số cơ hội trong tháng</td>
          <td>{telesale?.opportunity_percent || 'Chưa cập nhật'}</td>
          <td>{telesale?.opportunity || 'Chưa cập nhật'}</td>
          <td>{telesale?.opportunity_result || 'Chưa cập nhật'}</td>
          <td>{telesale?.opportunity_result_persent || 'Chưa cập nhật'}</td>
        </tr>
        <tr>
          <td>Khách hàng mới</td>
          <td>{telesale?.new_customer_percent || 'Chưa cập nhật'}</td>
          <td>{telesale?.new_customer || 'Chưa cập nhật'}</td>
          <td>{telesale?.new_customer_result || 'Chưa cập nhật'}</td>
          <td>{telesale?.new_customer_result_persent || 'Chưa cập nhật'}</td>
        </tr>
        <tr>
          <td>Doanh thu</td>
          <td>{telesale?.revenue_percent || 'Chưa cập nhật'}</td>
          <td>
            {telesale?.revenue
              ? telesale?.revenue.toLocaleString('vi', {
                  currency: 'VND',
                }) + '(VNĐ)'
              : '--'}
          </td>
          <td>{telesale?.revenue_result || 'Chưa cập nhật'}</td>
          <td>{telesale?.revenue_result_persent || 'Chưa cập nhật'}</td>
        </tr>
      </>
    );
  }

  render() {
    const { isLoading } = this.props.telesaleKpi;
    return (
      <>
        {isLoading ? <Loading /> : ''}
        {this.renderBody()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  telesaleKpi: state.telesaleKpiReducer,
});

const mapDispatchToProps = {
  getTelesaleKpi,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(KPI));
