import {
  requestCallList,
  requestQuestionsAndAnswerReport,
} from '@constants/Api';
import { BOX_SHADOW, CALL_TYPE, ROUTER } from '@constants/Constant';
import {
  formatTime,
  getFirstLetter,
  getSearchParamsInUrl,
  randomColor,
  rmVN,
  setSearchParamsInUrlVer2,
} from '@constants/funcHelper';
import Loading from '@src/components/Loading';
import {
  getJobFilter,
  getListCallStatus,
  getListKeyword,
} from '@src/redux/actions';
import { primaryColor } from '@styles/Color';
import '@styles/Company.css';
import { AudioStyle } from '@styles/Style';
import {
  Avatar,
  Badge,
  Col as ColAntd,
  DatePicker,
  Empty,
  Pagination,
  Row as RowAntd,
  Select,
  Skeleton,
  Spin,
} from 'antd';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, FormControl, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import EZLoading from 'src/modules/loading/EZ.Loading';
import EZLoadingFull from 'src/modules/loading/EZ.Loading.Full';
import styled from 'styled-components';

const Header = props => {
  const { params, handleChangeParams, total } = props;

  const userInfo = useSelector(store => store.userReducer);
  const jobList = useSelector(state => state.jobFilterReducer.data);
  const statusList = useSelector(state => state.callStatusReducer.data);
  const keywordList = useSelector(state => state.keywordReducer.data);

  const [questions, setQuestions] = React.useState([]);
  const [results, setResults] = React.useState([]);

  const getQuestionInJob = React.useCallback(async function (jobId) {
    try {
      const { data } = await requestQuestionsAndAnswerReport({
        enterprise_id: userInfo.data.enterprise_id,
        job_category_id: jobId,
      });

      setQuestions(data);
    } catch (error) {
      console.log('Error getQuestionInJob: ', { error });
    }
  }, []);

  React.useEffect(() => {
    getQuestionInJob(params.jobId);
  }, [getQuestionInJob, params.jobId]);

  React.useEffect(() => {
    const { job_form_question_id, job_form_answers_id } = params;
    //Danh sách các đáp án có thể chọn được trong filter kết quả
    //Chia nhóm theo từng câu hỏi
    let arrayResultId = [];
    let result = [];
    job_form_question_id.length &&
      questions.length &&
      job_form_question_id.forEach(id => {
        const temp = questions?.find(ques => ques.id === id);

        result.push({
          label: temp?.name,
          options: temp?.job_form_answers.map(el => {
            arrayResultId.push(el?.id);
            return {
              label: el?.name,
              value: el?.id,
            };
          }),
        });
      });
    setResults(result);

    //Danh sách lựa chọn trong filter câu hỏi thay đổi -> Cập nhật lại lựa chọn trong filter kết quả
    //Loại bỏ những kết quả mà câu hỏi bị hủy chọn
    if (job_form_question_id.length && arrayResultId.length) {
      const answersIdSelected = job_form_answers_id.filter(item =>
        arrayResultId.includes(item)
      );
      if (answersIdSelected.length) {
        handleChangeParams({ job_form_answers_id: answersIdSelected });
        setSearchParamsInUrlVer2({ job_form_answers_id: answersIdSelected });
      }
    }
    if (
      job_form_question_id.length === 0 &&
      window.location.search.includes('job_form_answers_id') &&
      params.job_form_answers_id.length
    ) {
      handleChangeParams({ job_form_answers_id: [] });
    }
  }, [params.job_form_question_id, questions]);

  return (
    <div
      style={{
        borderRadius: '8px',
        marginBottom: 6,
      }}
    >
      <HeaderStyled>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '6px',
          }}
        >
          <h2 className="text-left">Lịch sử cuộc gọi</h2>
          <span style={{ marginLeft: '5px' }}>({total} kết quả)</span>
        </div>

        <div className="w-100">
          <Row style={{ gap: 8 }}>
            <Col style={{ minWidth: '200px' }}>
              <FormControl
                type="text"
                value={params.search}
                placeholder="Tên, số điện thoại khách hàng"
                onChange={e => {
                  setSearchParamsInUrlVer2({ search: e.target.value });
                  handleChangeParams({ search: e.target.value });
                }}
              />
            </Col>

            <Col style={{ minWidth: '200px' }}>
              <FormControl
                as="select"
                id=""
                value={params.jobId}
                onChange={e => {
                  setSearchParamsInUrlVer2({
                    jobId: e.target.value,
                    job_form_question_id: [],
                  });
                  handleChangeParams({
                    jobId: e.target.value,
                    job_form_question_id: [],
                  });
                }}
              >
                <option value="">Công việc</option>
                {jobList?.length > 0 &&
                  jobList?.map((value, index) => (
                    <option key={index} value={value.id}>
                      {value.name}
                    </option>
                  ))}
              </FormControl>
            </Col>

            <Col style={{ minWidth: '200px' }}>
              <FormControl
                as="select"
                id=""
                value={params.typeId}
                onChange={e => {
                  setSearchParamsInUrlVer2({ typeId: e.target.value });
                  handleChangeParams({ typeId: e.target.value });
                }}
              >
                <option value="">Loại cuộc gọi</option>
                <option value="1">Gọi đi</option>
                <option value="2">Gọi vào</option>
              </FormControl>
            </Col>

            <Col style={{ minWidth: '200px' }}>
              <FormControl
                as="select"
                id=""
                value={params.statusId}
                onChange={e => {
                  setSearchParamsInUrlVer2({ statusId: e.target.value });
                  handleChangeParams({ statusId: e.target.value });
                }}
              >
                <option value="">Trạng thái</option>
                {statusList.length > 0 &&
                  statusList?.map((value, index) => (
                    <option key={index} value={value.id}>
                      {value.name}
                    </option>
                  ))}
              </FormControl>
            </Col>

            <Col style={{ minWidth: '200px' }}>
              <DatePicker.RangePicker
                style={{ width: '100%' }}
                placeholder={['Từ ngày', 'Đến ngày']}
                value={[
                  params.fromDate ? moment(params.fromDate) : '',
                  params.toDate ? moment(params.toDate) : '',
                ]}
                onChange={(_, dateString) => {
                  if (
                    dateString !== null &&
                    dateString[0] !== '' &&
                    dateString[1] !== ''
                  ) {
                    setSearchParamsInUrlVer2({
                      fromDate: formatTime(dateString[0], 'YYYY-MM-DD'),
                      toDate: formatTime(dateString[1], 'YYYY-MM-DD'),
                      page: 1,
                    });

                    handleChangeParams({
                      fromDate: formatTime(dateString[0], 'YYYY-MM-DD'),
                      toDate: formatTime(dateString[1], 'YYYY-MM-DD'),
                    });
                  } else {
                    setSearchParamsInUrlVer2({
                      fromDate: '',
                      toDate: '',
                      page: 1,
                    });
                    handleChangeParams({ fromDate: '', toDate: '' });
                  }
                }}
              />
            </Col>

            {/* <Col style={{minWidth2"400px"}}>
              <DatePickerCustom
                className={`date-picker form-control`}
                placeholderText={STRING.fromDate}
                handleChange={(_, value) => {
                  setSearchParamsInUrlVer2({ fromDate: value });
                  handleChangeParams({ fromDate: value });
                }}
                selected={params.fromDate}
                dateFormat="dd/MM/yyyy"
                // maxDate={new Date(toDate)}
              />
            </Col>

            <Col style={{minWidth2"400px"}}>
              <DatePickerCustom
                className={`date-picker form-control`}
                placeholderText={STRING.toDate}
                handleChange={(_, value) => {
                  setSearchParamsInUrlVer2({ toDate: value });
                  handleChangeParams({ toDate: value });
                }}
                selected={params.toDate}
                dateFormat="dd/MM/yyyy"
                // minDate={new Date(fromDate)}
              />
            </Col> */}

            <Col style={{ minWidth: '200px' }}>
              <FormControl
                as="select"
                id=""
                value={params.keywordId}
                onChange={e => {
                  setSearchParamsInUrlVer2({ keywordId: e.target.value });
                  handleChangeParams({ keywordId: e.target.value });
                }}
              >
                <option value="">Từ khóa cuộc gọi</option>
                {keywordList?.length > 0 &&
                  keywordList?.map((value, index) => (
                    <option key={index} value={value.name}>
                      {value.name}
                    </option>
                  ))}
              </FormControl>
            </Col>

            <Col style={{ minWidth: '200px' }}>
              <Select
                style={{ width: '100%' }}
                mode="multiple"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return rmVN(option.children).indexOf(rmVN(input)) >= 0;
                }}
                placeholder="Câu hỏi"
                allowClear
                maxTagCount={1}
                value={params.job_form_question_id}
                notFoundContent={
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<span>Không có dữ liệu!</span>}
                  />
                }
                onChange={value => {
                  setSearchParamsInUrlVer2({ job_form_question_id: value });
                  handleChangeParams({ job_form_question_id: value });
                }}
              >
                {questions.length > 0 ? (
                  questions.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </Col>

            <Col style={{ minWidth: '200px' }}>
              <Select
                style={{ width: '100%' }}
                mode="multiple"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return rmVN(option.children).indexOf(rmVN(input)) >= 0;
                }}
                placeholder="Kết quả"
                allowClear
                maxTagCount={1}
                value={params.job_form_answers_id}
                notFoundContent={
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<span>Không có dữ liệu!</span>}
                  />
                }
                onChange={value => {
                  setSearchParamsInUrlVer2({ job_form_answers_id: value });
                  handleChangeParams({ job_form_answers_id: value });
                }}
                options={results}
              />
            </Col>
          </Row>
        </div>
      </HeaderStyled>
    </div>
  );
};

const HeaderStyled = styled.div`
  background: #fff;
  padding: 10px 20px;
  border-radius: 6px;
  box-shadow: ${BOX_SHADOW};

  & h2 {
    font-size: 18px;
  }
`;

const CardHistoryCallStyled = styled.div`
  background: #fff;
  border-radius: 6px;
  padding: 12px 4px;
  box-shadow: ${BOX_SHADOW};
`;

const DataList = props => {
  const { data, paging, onChangePage, isLoading } = props;

  const renderAvatarCustomer = call => {
    const customer_name = call.customer_name?.trim().split(' ');

    return (
      <Avatar
        style={{
          backgroundColor: randomColor(getFirstLetter(customer_name)),
          marginRight: '5px',
        }}
        size={24}
      >
        <span id="avaCus">
          {customer_name[customer_name.length - 1].charAt(0)?.toUpperCase()}
        </span>
      </Avatar>
    );
  };

  const renderAvatarSale = call => {
    const saleName = call.sale_name ? call.sale_name?.trim().split(' ') : 'N';

    return call.sale_profile_image ? (
      <>
        <Avatar size={24} src={call.sale_profile_image} />
        &nbsp;
      </>
    ) : (
      <Avatar
        style={{
          backgroundColor: randomColor(getFirstLetter(saleName)),
          marginRight: '5px',
        }}
        size={24}
      >
        <span id="avaCus">
          {saleName[saleName.length - 1].charAt(0)?.toUpperCase()}
        </span>
      </Avatar>
    );
  };

  return (
    <>
      <RowAntd>
        {data.length
          ? data.map((item, index) => {
              return (
                <ColAntd
                  key={item.id}
                  style={{ padding: '6px' }}
                  xs={24}
                  sm={12}
                  lg={8}
                >
                  <Link
                    to={ROUTER.CALL_DETAIL_SALE + '/' + item.id}
                    style={{ color: 'black' }}
                    onClick={() =>
                      mixpanel.track(
                        `View call detail ${item.customer_phone}, ${
                          item.customer_name || 'Chưa cập nhật'
                        } (id: ${item.id})`
                      )
                    }
                  >
                    <Badge.Ribbon
                      text={
                        paging.current === 1
                          ? index + 1
                          : (paging.current - 1) * paging.pageSize + (index + 1)
                      }
                      color="volcano"
                    >
                      <CardHistoryCallStyled className="item-hov">
                        <Row>
                          <Col md={12}>
                            <b
                              style={{
                                fontSize: 18,
                                color: primaryColor,
                                fontWeight: 'bold',
                                marginRight: '5px',
                              }}
                            >
                              {/* {item.job_name || 'Chưa cập nhật'} */}
                              {CALL_TYPE.getCallType(item.call_type)?.name ||
                                'Chưa cập nhật'}
                            </b>
                            <span>
                              (
                              {item?.start_call_time
                                ? moment(item?.start_call_time).format(
                                    'HH:mm DD/MM/YYYY'
                                  )
                                : 'Chưa cập nhật'}
                              )
                            </span>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col md={12}>
                            <Row className="mt-1">
                              <Col md={6} className="p-0">
                                <p
                                  className="text-gray m-0"
                                  style={{ fontSize: '14px' }}
                                >
                                  Sale
                                </p>
                                <p className="m-0">
                                  {renderAvatarSale(item)}
                                  {item.sale_name || 'Chưa cập nhật'}
                                </p>
                              </Col>
                              <Col md={6} className="p-0">
                                <p
                                  className="text-gray m-0"
                                  style={{ fontSize: '14px' }}
                                >
                                  Khách hàng
                                </p>
                                <p className="m-0">
                                  {renderAvatarCustomer(item)}
                                  {item.customer_name || 'Chưa cập nhật'}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col md={12}>
                            <Row>
                              <Col md={6} className="p-0">
                                <p
                                  className="text-gray m-0"
                                  style={{ fontSize: '14px' }}
                                >
                                  Ngày
                                </p>
                                <p className="m-0">
                                  {item?.start_call_time
                                    ? Intl.DateTimeFormat('vi-VN').format(
                                        new Date(item?.start_call_time)
                                      )
                                    : 'Chưa cập nhật'}
                                </p>
                              </Col>
                              <Col md={6} className="p-0">
                                <p
                                  className="text-gray m-0"
                                  style={{ fontSize: '14px' }}
                                >
                                  Thời gian gọi
                                </p>
                                <p className="m-0">
                                  {item.call_duration
                                    ? `${item.call_duration}s`
                                    : 'Chưa cập nhật'}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={12}>
                            <Row className="mt-3">
                              <Col md={6} className="p-0">
                                <p
                                  className="text-gray m-0"
                                  style={{ fontSize: '14px' }}
                                >
                                  Số điện thoại
                                </p>
                                <p className="m-0">
                                  {item.customer_phone || 'Chưa cập nhật'}
                                </p>
                              </Col>
                              <Col md={6} className="p-0">
                                <p
                                  className="text-gray m-0"
                                  style={{ fontSize: '14px' }}
                                >
                                  Tình trạng
                                </p>
                                {item.status_name === 'Không nghe máy' ? (
                                  <p
                                    className={`m-0 ${
                                      item.status_name && 'text-danger'
                                    }`}
                                  >
                                    {item.status_name || 'Chưa cập nhật'}
                                  </p>
                                ) : item.status_name === 'Đồng ý trao đổi' ? (
                                  <p
                                    className={`m-0 ${
                                      item.status_name && 'text-success'
                                    }`}
                                  >
                                    {item.status_name || 'Chưa cập nhật'}
                                  </p>
                                ) : (
                                  <p
                                    className={`m-0 ${
                                      item.status_name && 'text-warning'
                                    }`}
                                  >
                                    {item.status_name || 'Chưa cập nhật'}
                                  </p>
                                )}
                              </Col>
                            </Row>
                          </Col>
                          <Col md={12} className="mt-3">
                            <p
                              className="text-gray m-0"
                              style={{ fontSize: '14px' }}
                            >
                              File ghi âm
                            </p>
                            <p style={{ width: '100%' }} className="m-0">
                              {isLoading ? (
                                <Skeleton.Input />
                              ) : (
                                <AudioStyle
                                  style={{ width: '100%' }}
                                  controls
                                  autoplay
                                  className="audio-element"
                                >
                                  <source
                                    src={item?.content_audio_url}
                                  ></source>
                                </AudioStyle>
                              )}
                            </p>
                          </Col>
                        </Row>
                      </CardHistoryCallStyled>
                    </Badge.Ribbon>
                  </Link>
                </ColAntd>
              );
            })
          : !isLoading && <Empty description="Không có dữ liệu" />}
      </RowAntd>
      {!isLoading && (
        <div className="text-right mb-3 mt-3">
          <Pagination
            {...paging}
            onChange={onChangePage}
            showSizeChanger={false}
          />
        </div>
      )}
    </>
  );
};

function SaleCallScreen() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const [paging, setPaging] = React.useState({
    total: 0,
    current: 1,
    pageSize: 12,
  });

  const [params, setParams] = React.useState({
    search: '',
    jobId: '',
    typeId: '',
    statusId: '',
    keywordId: '',
    fromDate: '',
    toDate: '',
    job_form_question_id: [],
    job_form_answers_id: [],
    page: paging.current,
  });

  useEffect(() => {
    dispatch(getJobFilter());
    dispatch(getListCallStatus());
    dispatch(getListKeyword());

    const searchParams = getSearchParamsInUrl();
    setParams(prev => ({ ...prev, ...searchParams }));
  }, []);

  function handleChangeParams(data) {
    setSearchParamsInUrlVer2(data);
    setParams(prev => ({ ...prev, ...data, page: 1 }));
  }

  const getData = React.useCallback(async (param = params) => {
    const {
      page,
      typeId,
      jobId,
      statusId,
      fromDate,
      toDate,
      keywordId,
      search,
      job_form_question_id,
      job_form_answers_id,
    } = param;

    try {
      setIsLoading(true);
      const { data, paging } = await requestCallList({
        page: page || '',
        call_type: typeId || '',
        job_id: jobId || '',
        call_status: statusId || '',
        telesale_id: '',
        start_call_time: '',
        limit: 12,
        fromDate: fromDate || '',
        toDate: toDate || '',
        is_late: 0,
        job_category_id: '',
        job_form_question_id: job_form_question_id,
        job_form_answers_id: job_form_answers_id,
        call_review_config_id: '',
        keyword: keywordId || '',
        search: search.trim() || '',
        is_check_kpi: 0,
      });
      setData(data);
      setPaging({
        total: paging.totalItemCount,
        current: paging.page,
        pageSize: paging.limit,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, []);

  const debounceGetData = React.useCallback(_.debounce(getData, 500), []);

  React.useEffect(() => {
    debounceGetData(params);
  }, [debounceGetData, params]);

  return (
    <>
      {isLoading && <EZLoadingFull />}
      <Container className="content-wrapper">
        <div className="content-header" style={{ paddingTop: '10px' }}>
          <div style={{ padding: '0 11px' }}>
            <Header
              params={params}
              total={paging.total}
              handleChangeParams={handleChangeParams}
            />
          </div>

          {/* <div style={{ marginLeft: '24px' }}>
            <b>Kết quả lọc: {paging.total}</b>
          </div> */}

          <div style={{ padding: '0 6px' }}>
            <DataList
              data={data}
              paging={paging}
              isLoading={isLoading}
              onChangePage={page => {
                setParams(prev => ({ ...prev, page }));
              }}
            />
          </div>
        </div>
      </Container>
    </>
  );
}

const Container = styled.div`
  * {
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
  }
`;

export default SaleCallScreen;
