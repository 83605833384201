import { isCallEnded } from '@components/callProviders/CallHelper';
import CallUtil from '@components/callProviders/CallUtil';
import useCallStatus from '@components/callProviders/useCallStatus';
import { ApiClient, filterRecall, requestGetRecallList } from '@constants/Api';
import { ERROR_STRING, ROUTER } from '@constants/Constant';
import { notifyFail } from '@utils/notify';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  List,
  Pagination,
  Popover,
  Row,
  Select,
  Spin,
} from 'antd';
import * as _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { requestStartCall } from 'src/network/web_sale/CallApi';
import styled from 'styled-components';
import './style.css';
import Loading from '@components/Loading';

const ButtonCallStyled = styled.div`
  &&& {
    :hover {
      cursor: ${props => (props.loading ? 'progress' : 'pointer')};
    }
  }
`;

const LoadingContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const SaleRecallScreen = () => {
  const { call } = useCallStatus();

  const [listData, setListData] = useState({});
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [startCallTime, setStartCallTime] = useState('');
  const [jobs, setJobs] = useState([]);
  const [jobID, setJobID] = useState('');
  const [loadingButtonCall, setLoadingButtonCall] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [valueSelectedHotLine, setValueSelectedHotLine] = useState(0);
  const history = useHistory();
  const [isloading, setLoading] = useState(false);
  const hotlineState = useSelector(state => state.hotline?.dataHotline);

  const getJobs = async () => {
    try {
      const data = await filterRecall();
      setJobs(data.data);
    } catch (error) {
      console.error('Exception ' + error);
    }
  };

  const init = async () => {
    setLoading(true);
    try {
      const { data, paging } = await requestGetRecallList({
        page,
        job_id: jobID,
        start_call_time: startCallTime,
      });
      data.map(x => {
        x.sort_time_recall = moment(x.time_recall).format('YYYY-MM-DD');
        return x;
      });
      const groupedData = _.groupBy(data, 'sort_time_recall');
      setListData(groupedData);
      setTotal(paging.totalItemCount);
    } catch (error) {
      notifyFail(error.msg || 'Đã có lỗi xảy ra!');
    } finally {
      setLoading(false);
    }
  };
  console.log('listData', listData);
  const handleMakeNewCall = async (info, hotline) => {
    console.log('callData', info);
    console.log('hotline', CallUtil.hotlines);
    try {
      setLoadingButtonCall(true);
      const { data } = await requestStartCall({
        job_id: parseInt(info.job_id),
        job_telesale_customer_id: parseInt(info.job_telesale_customer_id),
        hotline: hotline.outbound_phone,
      });
      CallUtil.makeCall(
        hotline.type,
        data.customer_phone,
        data.id,
        info.customer_phone,
        data.job_id,
        true
      );

      const callData = {
        customerPhone: info.customer_phone,
        customerId: info.customer_id,
        jobId: info.job_id,
        callId: data.id,
        job_telesale_customer_id: info.job_telesale_customer_id,
      };
      mixpanel.track(`Call: ${info.customer_name} (${info.customer_phone})`, {
        callData,
      });
    } catch (error) {
      console.log('call from customer detail', error);
      notifyFail(ERROR_STRING.CALL_ERROR);
    } finally {
      setLoadingButtonCall(false);
    }
  };

  const getDayName = value => {
    switch (value) {
      case 1:
        return 'Thứ 2';
      case 2:
        return 'Thứ 3';
      case 3:
        return 'Thứ 4';
      case 4:
        return 'Thứ 5';
      case 5:
        return 'Thứ 6';
      case 6:
        return 'Thứ 7';
      default:
        return 'Chủ Nhật';
    }
  };

  const handleChangeTime = callTime => {
    callTime
      ? setStartCallTime(moment(callTime).format('YYYY-MM-DD'))
      : setStartCallTime('');
  };

  const handleChangeJob = id => {
    id ? setJobID(id) : setJobID('');
  };

  const handleChangePage = pageNumber => {
    setPage(pageNumber);
  };

  const handleNavigateCustomerDetail = recallData => {
    history.push({
      pathname: `${ROUTER.JOB_CUSTOMER_DETAIL_SALE}/${recallData.customer_id}`,
      state: {
        isBackFromCalling: false,
        idJob: recallData.job_id,
        job_telesale_customer_id: recallData.job_telesale_customer_id,
        fromCallSchedule: true,
      },
    });
  };

  useEffect(() => {
    init();
  }, [page, startCallTime, jobID]);

  useEffect(() => {
    getJobs();
  }, []);

  useEffect(() => {
    // async function getListHotLine() {
    //   let res = await ApiClient.get('sale/v2/getHotline?isFindAll=1');
    //   if (res.status && res.data?.defind?.type_default == 1) {
    //     setValueSelectedHotLine(res.data.defind.hotline);
    //   }
    // }
    // getListHotLine();

    function getDefaultValueHotLine(data) {
      let defaultHotline = CallUtil?.hotlines?.find(hotline => {
        return hotline.is_default_hotline;
      });
      let valueDropDownDefault = defaultHotline
        ? defaultHotline.outbound_phone
        : 0;
      setValueSelectedHotLine(valueDropDownDefault);
    }
    hotlineState && getDefaultValueHotLine(hotlineState);
  }, [hotlineState]);

  return (
    <>
      {!isloading ? (
        <div className="content-wrapper">
          <Row
            style={{
              backgroundColor: '#fff',
              borderRadius: 'var(--section-radius)',
            }}
            className="p-3"
            justify="space-between"
          >
            <Col xs={24} sm={12} className="p-1">
              <span style={{ fontWeight: 'bold', fontSize: '20px' }}>
                Lịch hẹn gọi lại
              </span>
            </Col>
            <Col xs={24} sm={12} className="pt-1">
              <Row>
                <Col xs={16} className="pr-1">
                  <Select
                    style={{ width: '100%' }}
                    allowClear={true}
                    placeholder="Chọn công việc"
                    onChange={e => handleChangeJob(e)}
                  >
                    {jobs.map(j => (
                      <Select.Option key={j.id} value={j.id}>
                        {j.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                <Col xs={8} className="pl-1">
                  <DatePicker
                    style={{ width: '100%' }}
                    format="DD/MM/YYYY"
                    placeholder="Chọn thời gian"
                    onChange={handleChangeTime}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          {Object.keys(listData).map(key => (
            <>
              <Row
                style={{
                  backgroundColor: '#fff',
                  borderRadius: 'var(--section-radius)',
                }}
                className="my-2 p-3"
                justify="space-between"
              >
                <p className="legend">
                  <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                    {getDayName(moment(key).day())},{' '}
                    {moment(key).format('DD/MM/YYYY')}
                  </span>
                </p>
              </Row>
              {listData[key].map(info => (
                <Row
                  key={info.customer_id}
                  className="px-4 py-5 mb-1"
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 'var(--section-radius)',
                  }}
                  // onClick={() => {
                  //   handleNavigateCustomerDetail(info);
                  // }}
                >
                  <Col xs={24} md={4}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <div>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                          <i
                            className="fas fa-clock"
                            style={{ fontSize: '50px', color: '#fe7a15' }}
                          />
                        </div>
                        <div
                          className="mt-2"
                          style={{
                            textAlign: 'center',
                            fontSize: '25px',
                            color: '#fe7a15',
                          }}
                        >
                          {moment(info.time_recall).format('HH:mm')}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    md={12}
                    className="pb-2"
                    style={{ borderRight: '1px solid #f1f1f1' }}
                  >
                    <p className="mb-1">Khách hàng</p>
                    <p
                      className="mb-1"
                      style={{ fontSize: '16px', fontWeight: '600' }}
                    >
                      {info.customer_name}
                    </p>
                    <p className="mb-1">Công việc</p>
                    <p
                      className="mb-1"
                      style={{ fontSize: '16px', fontWeight: '600' }}
                    >
                      {info.job_name}
                    </p>
                  </Col>
                  <Col xs={24} md={8} className="pl-xs-0 pl-md-5">
                    <Row justify="space-between" align="middle">
                      <div>
                        <p className="mb-1">Số điện thoại</p>
                        <p
                          className="mb-1"
                          style={{ fontSize: '16px', fontWeight: '600' }}
                        >
                          {info.customer_phone}
                        </p>
                        <p className="mb-1">Tỉnh/ thành phố</p>
                        <p
                          className="mb-1"
                          style={{ fontSize: '16px', fontWeight: '600' }}
                        >
                          {info.customer_province_name}
                        </p>
                      </div>
                      <div>
                        <Popover
                          content={() => {
                            return (
                              <>
                                <Divider orientation="center">
                                  Chọn hotline
                                </Divider>
                                <List
                                  size="small"
                                  dataSource={CallUtil.hotlines}
                                  renderItem={hotline => (
                                    <List.Item
                                      onClick={event => {
                                        event.stopPropagation();
                                        handleMakeNewCall(info, hotline);
                                      }}
                                    >
                                      <Button type="link">
                                        {hotline.outbound_phone.toString()}
                                      </Button>
                                    </List.Item>
                                  )}
                                />
                              </>
                            );
                          }}
                          trigger="click"
                          onVisibleChange={isShow => {
                            if (!isShow) {
                              setSelectedItem(null);
                            }
                          }}
                          visible={
                            selectedItem?.job_telesale_customer_id ===
                              info.job_telesale_customer_id &&
                            CallUtil.hotlines.length > 0
                          }
                        >
                          <ButtonCallStyled loading={loadingButtonCall}>
                            <i
                              className="fas fa-phone"
                              style={{
                                borderRadius: '50%',
                                background: 'green',
                                padding: '15px',
                                fontSize: '25px',
                                color: '#fff',
                              }}
                              onClick={e => {
                                if (!isCallEnded(call.status)) {
                                  e.stopPropagation();
                                  notifyFail('Đang có cuộc gọi khác');
                                } else {
                                  e.stopPropagation();
                                  if (!loadingButtonCall) {
                                    const hotlines =
                                      hotlineState?.listHotlineOutput;
                                    if (hotlines.length === 0) {
                                      notifyFail('Không có hotline');
                                    } else if (hotlines.length === 1) {
                                      handleMakeNewCall(info, hotlines[0]);
                                    } else if (valueSelectedHotLine) {
                                      let indexDefault = hotlines?.findIndex(
                                        hotline => {
                                          return (
                                            hotline.outbound_phone ===
                                            valueSelectedHotLine
                                          );
                                        }
                                      );
                                      ((indexDefault && indexDefault > -1) ||
                                        indexDefault === 0) &&
                                        handleMakeNewCall(
                                          info,
                                          hotlines[indexDefault]
                                        );
                                    } else setSelectedItem(info);
                                  }
                                }
                              }}
                            />
                          </ButtonCallStyled>
                        </Popover>
                      </div>
                    </Row>
                  </Col>
                </Row>
              ))}
            </>
          ))}
          {Object.keys(listData).length === 0 && (
            <Row
              className="px-4 py-5 my-1"
              style={{
                backgroundColor: '#fff',
                borderRadius: 'var(--section-radius)',
              }}
            >
              Không có bản ghi nào!
            </Row>
          )}
          <Row className="mt-2" justify="end">
            {total > 0 && (
              <Pagination onChange={handleChangePage} total={total} />
            )}
          </Row>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};
export default SaleRecallScreen;
