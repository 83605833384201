import { Modal, Upload, message } from 'antd';
import Cookies from 'js-cookie';
import React from 'react';
import { useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { notifyFail } from '@utils/notify';

const getBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const UploadAntd = ({
  onRemoveFile,
  acceptType = 'image/jpeg,image/png,image/jpg',
  getDataFromUpload,
  limitUpload = 100,
  listType = 'picture-card',
  defaultFileList,
  name = 'file',
  disabled = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const handlePreview = async file => {
    console.log('filePreview', file);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    // setPreviewImage(file.response?.data || file.thumbUrl);
    setPreviewOpen(true);
    // setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  function handleChangeUpload(value) {
    // khác 3 định dạng error
    const allowedFormats = ['.png', '.jpg', '.jpeg'];

    const fileExtension = value?.file?.name
      ?.substring(value?.file?.name?.lastIndexOf('.'))
      ?.toLowerCase();

    if (!allowedFormats.includes(fileExtension)) {
      message.error('Chỉ chấp nhận tệp có định dạng .png, .jpg, .jpeg');
      return false; // Ngăn không cho tệp được tải lên
    }

    if (!acceptType.includes(value.file?.type)) {
      notifyFail(
        'Định dạng file không hợp lệ. Vui lòng chỉ upload file ảnh có định dạng .png, .jpg, .jpeg.'
      );
    }
    // let listfile = [...fileList, value.file]
    // setFileList(listfile)
    let listFile = value.fileList.filter(item => {
      return acceptType.includes(item.type);
    });

    setFileList(listFile);
    getDataFromUpload(value, listFile);
  }
  function handleClose() {
    setPreviewOpen(false);
    setPreviewImage('');
  }
  return (
    <div className="upload_image">
      <Upload
        name={name}
        listType={listType}
        // className="avatar-uploader"
        // showUploadList={false}

        action="https://api.ezsale.vn/media/uploadAll"
        beforeUpload={file => {
          return false;
        }}
        headers={{
          token: Cookies.get('SESSION_ID'),
        }}
        // beforeUpload={beforeUpload}
        onChange={handleChangeUpload}
        disabled={disabled}
        accept={acceptType}
        onPreview={handlePreview}
        onRemove={onRemoveFile}
        fileList={fileList}
        defaultFileList={defaultFileList}
      >
        {fileList.length >= limitUpload ? null : uploadButton}
      </Upload>
      <Modal
        visible={previewOpen}
        title={null}
        footer={null}
        onCancel={handleClose}
      >
        <img
          alt="previewInModal"
          style={{ width: '100%' }}
          src={previewImage}
        />
      </Modal>
    </div>
  );
};

export default UploadAntd;
