import Wrapper from '@components/ui/wrapper/Wrapper';
import {
  requestAddNote,
  requestDetailOpportunity,
  requestUpdateOpportunity,
} from '@constants/Api';
import { ROUTER } from '@constants/Constant';
import { BreadcrumbAntStyle } from '@styles/Style';
import { createFormData, createFormDataNew } from '@utils/createFormData';
import { notifyFail, notifySuccess } from '@utils/notify';
import {
  Breadcrumb,
  Button,
  Popconfirm,
  Alert,
  Space,
  Empty,
  Skeleton,
  Spin,
} from 'antd';
import { defaults, forEach } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import OpportunityNote from './components/OpportunityNote';
import OpportunityProduct from './components/OpportunityProduct';
import moment from 'moment';
import { useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';

OpportunityDetail.propTypes = {};

function OpportunityDetail(props) {
  const user = useSelector(states => states.userReducer.data);
  const history = useHistory();
  const param = useParams();
  const [listDelete, setListDelete] = useState([]);
  const [dataSource, setDataSource] = useState(null);
  const [dataProduct, setDataProduct] = useState();
  const [addProduct, setAddProduct] = useState(true);
  const [valueAmount, setValueAmount] = useState(true);
  const [valueRevenue, setvalueRevenue] = useState(true);
  const [changeSales, setChangeSales] = useState('a');
  const [changeRevenue, setChangeRevenue] = useState('a');
  const [changeProduct, setChangeProduct] = useState(false);
  const [dataEpoint, setDataEpoint] = useState('a');
  const [onSave, setOnSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contentNote, setContentNote] = useState();
  const [fileList, setFileList] = useState();
  const [params, setParams] = useState({
    id: parseInt(param.id),
    status: '',
    customer_id: '',
    revenue: '',
    sales: '',
    contract_close_date: '',
    contract_exp_date: '',
    contract_cancle_date: '',
    list_product_delete: [],
    list_file_delete: [],
    files: [],
    opportunity_data: [
      {
        commission: '',
        product_id: '',
        name: '',
        amount: '',
        revenue: '',
        note: '',
        product_exp_date: '',
      },
    ],
  });

  const updateNodeOppoturnity = async () => {
    try {
      setOnSave(true);
      setLoading(true);
      let dataPush = {
        opportunity_id: params.id,
        content: contentNote,
      };
      const res = await requestAddNote(dataPush);
      notifySuccess(res.msg);
    } catch (error) {
      notifyFail(error.msg);
    } finally {
      setOnSave(false);
      setLoading(false);
    }
  };

  const updateOppoturnity = async () => {
    try {
      setOnSave(true);
      setLoading(true);
      const idProduct = dataSource?.opportunity_products.map(item => item?.id);
      let dataPush = { ...params };
      dataPush.status = params?.status;
      dataPush.epoint = params?.epoint;
      dataPush.customer_id =
        params?.customer_id === ''
          ? dataSource?.customer_id
          : params?.customer_id;
      dataPush.revenue = params?.revenue ? params?.revenue : 0;
      dataPush.sales = params?.sales ? params?.sales : 0;
      dataPush.contract_close_date = params?.contract_close_date;
      dataPush.contract_exp_date = params?.contract_exp_date;
      dataPush.contract_cancle_date = params?.contract_cancle_date;
      dataPush.list_file_delete = listDelete ? listDelete : [];
      dataPush.list_product_delete = params?.list_product_delete
        ? params?.list_product_delete
        : idProduct;
      dataPush.opportunity_data = changeProduct
        ? params.opportunity_data
            ?.filter(
              item =>
                item?.product_id ||
                item?.name ||
                item?.amount ||
                item?.revenue ||
                item?.note
            )
            .map(item => ({
              product_id: item.product_id,
              name: '1',
              amount: item.amount,
              revenue: item.revenue ? item.revenue : 0,
              note: item.note,
              product_exp_date: item.product_exp_date,
            }))
        : dataSource?.opportunity_products?.map(item => ({
            product_id: item.product_id,
            name: '2',
            amount: item.amount,
            revenue: item.revenue,
            note: item.note,
            product_exp_date: item.product_exp_date,
          }));

      const form = createFormDataNew(dataPush);
      for (let i = 0; i < fileList?.length; i++) {
        if (i > 9) {
          notifyFail('Đính kèm tối đa 10 files');
          return;
        } else {
          form.append('files', fileList[i]?.originFileObj);
        }
      }

      if (dataPush?.sales > 9000000000000000000) {
        notifyFail('Đã quá giới hạn doanh số!');
        return;
      }
      if (dataPush?.revenue > 9000000000000000000) {
        notifyFail('Đã quá giới hạn doanh thu!');
        return;
      } else {
        if (
          (dataPush.revenue &&
            !Number(dataPush.revenue) &&
            !dataPush.revenue?.startsWith('0')) ||
          (dataPush.sales &&
            !Number(dataPush.sales) &&
            !dataPush.sales?.startsWith('0')) ||
          (dataPush.epoint &&
            !Number(dataPush.epoint) &&
            !dataPush.epoint?.startsWith('0'))
        ) {
          if (!Number(dataPush.revenue)) {
            notifyFail('Doanh thu chỉ được nhập số!');
          }
          if (!Number(dataPush.sales)) {
            notifyFail('Doanh số chỉ được nhập số!');
          }
          if (!Number(dataPush.epoint)) {
            notifyFail('Hoa hồng chỉ được nhập số!');
          }
        } else {
          // return;
          if (contentNote) {
            updateNodeOppoturnity();
          }
          const res = await requestUpdateOpportunity(form);
          notifySuccess(res.msg);
          setContentNote(undefined);
          setFileList(undefined);
        }
      }
      mixpanel.track(`Update opportunity: ${dataSource?.customer_name}`);
    } catch (error) {
      notifyFail(error.msg);
    } finally {
      setLoading(false);
      setOnSave(false);
      setChangeProduct(false);
    }
  };

  useEffect(() => {
    detailOppoturnity();
  }, [onSave, param]);

  const detailOppoturnity = async () => {
    try {
      setLoading(true);
      // setDataSource(null);
      const res = await requestDetailOpportunity(param.id);
      const products = res?.data?.opportunity_products?.map((item, index) => {
        return {
          ...item,
          key: item.id,
        };
      });
      const note = res?.data?.opportunity_notes?.map((item, index) => {
        return {
          ...item,
          key: item.id,
        };
      });

      setParams({
        ...param,
        sales: res.data.sales,
        revenue: res.data.revenue,
        status: res?.data.status,
        customer_id: res.data.customer_id,
        epoint: res.data.epoint
          ? res.data.epoint
          : res.data.total_epoint
          ? res.data.total_epoint
          : 0,
        contract_close_date: res.data.contract_close_date
          ? res.data.contract_close_date
          : moment(new Date()).format('YYYY/MM/DD'),
        contract_exp_date: res.data.contract_exp_date
          ? res.data.contract_exp_date
          : '',
        contract_cancle_date: res.data.contract_cancle_date
          ? res.data.contract_cancle_date
          : moment(new Date()).format('YYYY/MM/DD'),
        list_file_delete: [],
        opportunity_data: res.data.opportunity_data
          ? res.data.opportunity_data
          : [],
      });
      setDataProduct(products);
      setDataSource(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  function showButton(data, selectorInfoUser) {
    let resultCheck = true;
    if (
      selectorInfoUser.role_id === 5 &&
      selectorInfoUser?.user_id !== data?.telesale_id
    ) {
      resultCheck = false;
    }
    return resultCheck;
  }
  // if (loading) {
  //   return <Spin />;
  // }
  return (
    <div className="content-wrapper">
      {dataSource !== null ? (
        <>
          <BreadcrumbAntStyle>
            <Breadcrumb.Item
              style={{ cursor: 'pointer' }}
              onClick={() => {
                // history.push({
                //   pathname: `${ROUTER.OPPORTUNITY_SALE}`,
                //   state: {},
                // });
                history.goBack();
              }}
            >
              Cơ hội
            </Breadcrumb.Item>
            <Breadcrumb.Item>Chi tiết</Breadcrumb.Item>
          </BreadcrumbAntStyle>
          <Wrapper>
            {showButton(dataSource, user) && (
              <Popconfirm
                title="Bạn có chắc chắn muốn lưu?"
                disabled={loading ? true : false}
                onConfirm={() => {
                  if (addProduct) {
                    updateOppoturnity();
                  } else {
                    notifyFail('Vui lòng cập nhật sản phẩm!');
                  }
                }}
                onCancel={() => {}}
                okText="Lưu"
                cancelText="Hủy"
              >
                <Button
                  disabled={loading}
                  type="primary"
                  style={{
                    float: 'right',
                    minWidth: 100,
                    borderRadius: 5,
                    // border: '1px solid red',
                  }}
                >
                  <b>Lưu</b>
                </Button>
              </Popconfirm>
            )}

            <div>
              <OpportunityProduct
                dataSource={dataSource}
                setParams={setParams}
                loading={loading}
                params={params}
                setValueAmount={setValueAmount}
                setvalueRevenue={setvalueRevenue}
                setChangeSales={setChangeSales}
                setChangeRevenue={setChangeRevenue}
                setAddProduct={setAddProduct}
                addProduct={addProduct}
                setChangeProduct={setChangeProduct}
                setDataEpoint={setDataEpoint}
                dataEpoint={dataEpoint}
                setFileList={setFileList}
                setListDelete={setListDelete}
                listDelete={listDelete}
              />
            </div>
            <div>
              <OpportunityNote
                dataSource={dataSource}
                setParams={setParams}
                params={params}
                setOnSave={setOnSave}
                loading={loading}
                setLoading={setLoading}
                setContentNote={setContentNote}
                contentNote={contentNote}
              />
            </div>
          </Wrapper>
        </>
      ) : (
        <Empty description={<>Không có dữ liệu!</>} />
      )}
    </div>
  );
}

export default OpportunityDetail;
