import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  requestCustomerGroupDetail,
  requestFilterList,
  requestCustomerGroupUpdate,
  requestCustomerList,
  requestGetExportCustomer,
  requestDeleteGroupCustomer,
  requestExportCustomer,
} from '@constants/Api';
import { ROUTER } from '@constants/Constant';
import '@styles/Customer.css';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { notifyFail, notifySuccess } from '@utils/notify';
import Loading from '@src/components/Loading';
import { Table, TreeSelect, Select } from 'antd';
import { getCustomerList } from '@src/redux/actions';
import { checkAllAttrFilled } from '@utils/checkAllAttrFilled';
import Pagi from '@components/PaginationTotalItem';
import moment from 'moment';
import MultiSelectControl from '@components/ui/forms/MultiSelectControl';
import { Empty } from 'antd';
import { TableView } from '@components/ui/table';
import { getDateRangeString } from '@utils/dateUtils';
import Theme from '@constants/Theme';
import ImportCustomerModal from './modals/ImportCustomerModal';
import { CustomerList } from '@screens/Enterprise/Customer/components';
import { saveAs } from 'file-saver';

class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumns: [
        {
          title: 'STT',
          dataIndex: 'index',
        },
        {
          title: 'Họ tên',
          dataIndex: 'name',
        },
        {
          title: 'Số điện thoại',
          dataIndex: 'phone',
        },
        {
          title: 'Email',
          dataIndex: 'email',
        },
        {
          title: 'Địa chỉ',
          dataIndex: 'address',
        },
        {
          title: 'Bộ phận quản lý',
          dataIndex: 'manager',
        },
      ],
      tableData: [],
      filter: [],
      optionCity: [],
      optionType: [],
      optionResource: [],
      optionCarType: [],
      optionCarCat: [],
      optionLabel: [],
      payload: {
        name: '',
        description: '',
        customer_consult: [],
        // customer_ids: [],
      },
      cusPayload: {
        search: '',
        province_ids: [],
        group_id: '',
      },
      group_id: '',
      form: {},
      isLoading: false,
      tableLoading: true,
      modal: false,
      paging: {},
      modalImportCustomers: {
        shown: false,
      },
    };
  }

  componentDidMount() {
    this.getData();
    this.getDataCustomer({ page: 1 });
    this.getFilterList();
  }

  componentWillReceiveProps(nextProp) {
    // if (nextProp.customerState?.data.length) {
    //     this.getCustomer(nextProp.customerState.data);
    // }
    // if (nextProp.customerState.isLoading) {
    //     this.setState({ tableLoading: true });
    // }
    // if (!nextProp.customerState.isLoading) {
    //     this.setState({ tableLoading: false });
    // }
  }

  async requestDeleteGroupCustomer() {
    const { id } = this.props.match.params;
    try {
      await requestDeleteGroupCustomer({ id: id });
      this.props.history.goBack();
      notifySuccess('Xóa thành công');
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  async getDataCustomer(payload) {
    const { id } = this.props.match?.params;
    this.setState((prevState) => ({
      tableLoading: true,
      group_id: id,
      cusPayload: { ...prevState.cusPayload },
    }));
    const items = await requestCustomerList({
      ...payload,
      ...this.state.cusPayload,
      group_id: id,
    });
    const customers = (items.data || []).map((cus, index) => ({
      index: index + 1 || '--',
      key: cus.id || '--',
      name: cus.name || '--',
      phone: cus.phone || '--',
      email: cus.email || '--',
      address: cus.address || '--',
      manager: cus.department_name || '--',
    }));
    this.setState(
      { ...this.state, paging: items.paging, customerState: items, tableData: customers, tableLoading: false },
      () => {}
    );
  }

  async getData() {
    const { id } = this.props.match?.params;
    try {
      this.setState({ isLoading: true });
      const detail = await requestCustomerGroupDetail({ id: id });
      this.setState({
        payload: {
          name: detail.data.name,
          description: detail.data.description,
          jobs_consult: detail.data.jobs,
          //   customer_ids: detail.data.customer_ids,
        },
        isLoading: false,
      });
      this.getProvince();
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  async getFilterList() {
    try {
      const res = await requestFilterList({
        id: this.props.userState.data.user_id,
      });
      this.setState({
        form: res,
      });
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  getProvince() {
    const provinceState = this.props.provinceState.data;
    var arr = [];
    if (provinceState.length) {
      const mapProvinces = provinceState.map((province) => ({
        label: province.name,
        value: province.id,
        data: province,
      }));
      this.setState({ optionCity: mapProvinces });
    }
  }

  getCustomer(customerList) {
    var arr = [];
    if (customerList.length) {
      customerList.forEach((cus) =>
        arr.push({
          key: cus.id,
          name: cus.name,
          phone: cus.phone,
          email: cus.email,
          address: cus.address,
          manager: cus.department_name,
        })
      );
      this.setState({
        tableData: arr,
      });
    }
    this.setState({ tableLoading: false });
  }

  async updateData() {
    const { id } = this.props.match?.params;
    const { payload } = this.state;
    const check = this.validateForm();
    if (check) {
      try {
        this.setState({ isLoading: true });
        const res = await requestCustomerGroupUpdate({ ...payload, id: id });
        this.setState({ isLoading: false });
        notifySuccess('Cập nhật thông tin thành công!');
      } catch (err) {
        this.setState({ isLoading: false });
        notifyFail(err.msg);
      }
    }
  }

  validateForm() {
    const { payload } = this.state;
    var check = checkAllAttrFilled(payload);
    if (!check) {
      notifyFail('Vui lòng điền đầy đủ thông tin');
      return false;
    }
    return true;
  }

  renderTitle() {
    const { payload } = this.state;
    return (
      <>
        <Row className="mb-3">
          <Col md={5} sm={12} className="p-0">
            <h3 className="m-0">{payload.name}</h3>
          </Col>
          <br />
          <Col md={7} sm={12} className="p-0">
            {this.renderButton()}
          </Col>
        </Row>
      </>
    );
  }

  renderBody() {
    return (
      <div className="content-wrapper">
        <div className="content-header">
          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 10 }}>
            <Col md className="bg">
              {this.renderTitle()}
            </Col>
          </Row>
          <Row style={{ padding: '0px 19px', borderRadius: '8px', marginBottom: 15 }}>
            <Col md={3} className="p-0 mt-1">
              <div className="bg-infor">{this.renderForm()}</div>
            </Col>

            <Col md={9} className="pl-3 pr-0 mt-1">
              <div className="bg-infor">{this.renderHistory()}</div>
            </Col>
          </Row>
          {/* <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 20 }}>
            <Col md className="bg">
              {this.renderForm()}
            </Col>
          </Row>
          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 20 }}>
            <Col md className="bg">
              {this.renderHistory()}
            </Col>
          </Row> */}
          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 20 }}>
            <Col md className="bg">
              {this.renderInfo()}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  deleteModal = () => {
    const { modal } = this.state;
    return (
      <Modal
        show={modal}
        onHide={() => this.setState({ modal: false })}
        dialogClassName="modal-90w"
        centered
        scrollable
      >
        <Modal.Header closeButton className="text-white bg-danger pb-0">
          <h5 className="text-light pb-0">Bạn chắc chắn muốn xóa?</h5>
        </Modal.Header>
        <Modal.Footer className="justify-content-end">
          <Button
            variant="success"
            className="text-light btn-oversize"
            onClick={() => this.requestDeleteGroupCustomer()}
          >
            Có
          </Button>
          <Button variant="danger" className="text-light btn-oversize" onClick={() => this.setState({ modal: false })}>
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderField() {
    const { payload } = this.state;
    return (
      <>
        <Row>
          <h4>Thông tin chung</h4>
        </Row>
        <Row>
          <Col sm={4} className="text-left mb-3 px-0">
            Tên nhóm:
          </Col>
          <Col sm={8} className="text-left mb-3 px-0">
            {payload.name}
          </Col>
        </Row>
        <Row>
          <Col sm={4} className="text-left px-0">
            Mô tả:
          </Col>
          <Col sm={8} className="text-left px-0">
            {payload.description}
          </Col>
        </Row>
      </>
    );
  }

  onFormChange(field, value) {
    this.setState({
      payload: {
        ...this.state.payload,
        [field]: value,
      },
    });
  }

  handleImportPress = () => {
    this.showImportModal();
  };

  downloadExcelFile = async () => {
    try {
      const { id } = this.props.match.params;
      // const data = await requestGetExportCustomer({group_id: id});
      await requestExportCustomer({ group_id: id });
      // saveAs(data.data, 'customer_data.xlsx');
    } catch (e) {
      console.log('download error', e);
    }
  };
  showImportModal = () => {
    this.setState({ modalImportCustomers: { shown: true } });
  };

  renderForm() {
    return (
      <>
        <div className="ml-2">
          {/* <Row>
            <Col md={5} sm={12} className="text-center text-md-left px-0">
              <h3> Thông tin chung </h3>
            </Col>
          </Row>
          <br /> */}
          {this.renderField()}
        </div>
      </>
    );
  }

  renderButton() {
    const { id } = this.props.match.params;
    return (
      <div className="text-center text-md-right">
        <Button
          variant="danger"
          className="text-light"
          onClick={() => {
            this.setState({
              ...this.state,
              modal: true,
            });
          }}
        >
          Xóa
        </Button>
        <Button variant="success" className="text-light" onClick={this.downloadExcelFile}>
          Export khách hàng
        </Button>
        <Button variant="warning" className="text-light" onClick={this.handleImportPress}>
          Import
        </Button>
        <Button
          variant="info"
          className="text-light"
          onClick={() => this.props.history.push(ROUTER.CUSTOMER_GROUP_EDIT + '/' + id)}
        >
          Chỉnh sửa
        </Button>
      </div>
    );
  }

  async filterChange(field, value) {
    console.log(field, value);
    this.setState(
      (prevState) => ({
        cusPayload: {
          ...prevState.cusPayload,
          [field]: value,
        },
      }),
      () => {
        this.getDataCustomer({ page: 1 });
      }
    );
  }

  renderInfoField() {
    const { cusPayload, optionType, form } = this.state;
    console.log(form, 'formmmmmm');
    return (
      <div className="w-100">
        <Row className="pb-1">
          <Col md={3} className="pb-1 pb-md-0 mt-3">
            <MultiSelectControl
              options={this.state.optionCity}
              value={[...cusPayload.province_ids]}
              onChange={(value) => this.filterChange('province_ids', value)}
              placeholder={'Chọn thành phố'}
              className="w-100"
            />
          </Col>
          {form.data?.length > 0 &&
            form.data?.map((v, index) => (
              <Col md={3} className="pb-1 pb-md-0 mt-3" key={v.id}>
                <MultiSelectControl
                  options={v?.customer_data_field_sources?.map((customer, index) => ({
                    label: customer.value,
                    value: customer.item_code,
                  }))}
                  value={cusPayload[v.code]}
                  onChange={(value) => this.filterChange(v.code, value)}
                  placeholder={v?.name}
                  className="w-100"
                />
              </Col>
            ))}
        </Row>
      </div>
    );
  }

  renderInfo() {
    return (
      <>
        <Row className="w-100">
          <Col md={12} className="p-0">
            <Row className="mb-2">
              <h4>Danh sách khách hàng</h4>
            </Row>
            {/* <Row>{this.renderInfoField()}</Row>
            <Row className="py-4">
              <span>
                Khách hàng phù hợp với kết quả lọc : <b>{this.state.customerState?.paging?.totalItemCount || 0}</b>
              </span>
            </Row>
            <Row>{this.renderTable()}</Row> */}
            <CustomerList
              payload={{}}
              resultCountText="Khách hàng phù hợp với kết quả lọc: "
              showCheckbox={false}
              onSelectionChanged={this.onSelectedCustomersChanged}
              shouldHideSubFilter={true}
            />
          </Col>
        </Row>
      </>
    );
  }

  handleTableCheck(selectedRowKeys) {
    this.setState({
      payload: {
        ...this.state.payload,
        customer_ids: selectedRowKeys,
      },
    });
  }

  renderTable() {
    return (
      <div className="w-100 table-wrap">
        <Table
          bordered
          loading={this.state.tableLoading}
          columns={this.state.tableColumns}
          dataSource={this.state.tableData}
          pagination={false}
        />
        <Row className="mt-3 justify-content-center justify-content-md-end">
          <Pagi handlePageChange={this.handlePageChange} paging={this.state.paging} />
        </Row>
      </div>
    );
  }

  handlePageChange = (page) => {
    const { searchBar } = this.state;
    this.getDataCustomer({
      page: page,
    });
  };

  renderHistory() {
    const { payload } = this.state;
    const dataSources =
      payload?.jobs_consult?.map((item, index) => {
        return {
          key: item.id,
          stt: index + 1,
          job_name: item.name || '--',
          job_time: getDateRangeString(item.start_at, item.end_at),
          job_status: item.status == 1 ? 'Đang thực hiện' : 'Đã hoàn thành',
        };
      }) || [];

    const tableData = {
      columns: [
        { title: 'STT', dataIndex: 'stt', width: Theme.Sizing.tableColumnNoWidth },
        { title: 'Công việc', dataIndex: 'job_name', align: 'left' },
        { title: 'Thời gian', dataIndex: 'job_time' },
        { title: 'Trạng thái', dataIndex: 'job_status' },
      ],
      dataSource: dataSources,
      locale: { emptyText: 'Chưa có công việc nào' },
    };
    return (
      <div className="table-wrap">
        <h4>Công việc đã tư vấn</h4>
        <div>
          <TableView {...tableData} />
        </div>
      </div>
    );
  }

  hideImportModal = (callback) => {
    console.log('hideImportModal');
    this.setState({ modalImportCustomers: { shown: false }, callback });
  };

  renderImportModal() {
    return (
      <ImportCustomerModal
        groupId={this.state.group_id}
        shown={this.state.modalImportCustomers.shown}
        onClose={this.hideImportModal}
      />
    );
  }

  render() {
    return (
      <>
        {this.state.isLoading ? <Loading /> : null}
        {this.renderBody()}
        {this.deleteModal()}
        {this.renderImportModal()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  provinceState: state.ProvinceReducer,
  customerState: state.customerReducer,
  userState: state.userReducer,
});

const mapDispatchToProps = {
  getCustomerList,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Add));
