import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Col,
  Empty,
  Input,
  Modal,
  Row,
  Skeleton,
  Typography,
} from 'antd';
import styled from 'styled-components';
import {} from '@ant-design/icons';
import { getListQuestionForReview, updateCallReview } from '@constants/Api';
import { notifyFail, notifySuccess } from '@utils/notify';
import { useSelector } from 'react-redux';
import { ROLE_WEB_SALE } from '@constants/Constant';
import mixpanel from 'mixpanel-browser';

const ModalStyled = styled(Modal)`
  .ant-modal-header {
    background-color: steelblue;
  }
  .ant-modal-title {
    color: white;
  }
  .ant-modal-close {
    display: none;
  }
`;

const CloseCircleOutlined = () => (
  <svg
    width="18px"
    height="18px"
    fill="white"
    viewBox="0 0 1024 1024"
    style={{ margin: '0 3px 3px 0' }}
  >
    <path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 00-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z" />
    <path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
  </svg>
);

const CheckCircleOutlined = () => (
  <svg
    width="18px"
    height="18px"
    fill="white"
    viewBox="0 0 1024 1024"
    style={{ margin: '0 3px 3px 0' }}
  >
    <path d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
    <path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
  </svg>
);

const getPointColor = inputPoint => {
  if (inputPoint < 0) {
    return 'tomato';
  } else {
    return 'green';
  }
};

const { Title } = Typography;

const ModalReviewCall = props => {
  const { data, callId, payload, getData, isVisible, handleCloseModal } = props;
  const user = useSelector(states => states.userReducer.data);
  const [params, setParams] = useState({
    call_id: '',
    options: [],
    note: '',
  });
  const [listOption, setListOption] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const getListOptionForReview = async () => {
    try {
      const res = await getListQuestionForReview(callId);
      setListOption(res.data);
    } catch (err) {
      console.log('err-list-option', err);
    }
  };

  const dataReview = {
    call_id: params.call_id,
    review_options: params.options,
    review_note: params.note,
  };

  const postUpdateCallReview = async () => {
    mixpanel.track(`call review`, { dataReview });
    try {
      const res = await updateCallReview(params);
      notifySuccess('Đánh giá cuộc gọi thành công!');
      if (payload) {
        getData(payload);
      } else {
        getData();
      }
    } catch (err) {
      notifyFail('Đã có lỗi xảy ra!');
      console.log('err-update-call-review', err);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (callId && user.role_id !== ROLE_WEB_SALE) {
      getListOptionForReview();
      setParams({
        call_id: callId,
        options: data?.call_reviews
          ? data.call_reviews.map((item, index) => {
              return item.call_review_config_id;
            })
          : [],
        note: data?.call_review_note ? data.call_review_note : '',
      });
    }
    setIsLoading(false);
  }, [callId]);
  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <ModalStyled
          title="Đánh giá cuộc gọi"
          okText="Đánh giá"
          cancelText="Huỷ"
          visible={isVisible}
          footer={null}
          closeIcon={null}
          destroyOnClose
          centered
        >
          {listOption?.length ? (
            <>
              <Row>
                <div style={{ width: '95%' }}>
                  <Checkbox.Group
                    defaultValue={params.options}
                    onChange={checkedValues => {
                      setParams({ ...params, options: checkedValues });
                    }}
                    style={{ margin: '0 0 15px 0' }}
                  >
                    <Row>
                      {listOption ? (
                        listOption.map((item, index) => (
                          <Col span={24}>
                            <Checkbox value={item.id}>{item.name}</Checkbox>
                          </Col>
                        ))
                      ) : (
                        <Empty description="Không có lựa chọn nào" />
                      )}
                    </Row>
                  </Checkbox.Group>
                </div>
                <div style={{ width: '5%' }}>
                  {listOption.length
                    ? listOption.map((item, index) => (
                        <div
                          style={{
                            color: getPointColor(item.point),
                            textAlign: 'end',
                          }}
                        >
                          {item.point}
                        </div>
                      ))
                    : null}
                </div>
              </Row>
              <div>
                <b>Ghi chú</b>
                <Input.TextArea
                  maxLength={500}
                  showCount
                  defaultValue={params.note}
                  onChange={e => {
                    setParams({ ...params, note: e.target.value });
                  }}
                />
              </div>
            </>
          ) : (
            <Title level={5}>
              Vui lòng cấu hình tiêu chí đánh giá chất lượng cuộc gọi!
            </Title>
          )}
          <br />
          <Row justify="end">
            <Button
              danger
              type="primary"
              icon={<CloseCircleOutlined />}
              onClick={() => {
                handleCloseModal();
              }}
            >
              Huỷ
            </Button>
            &nbsp;&nbsp;&nbsp;
            {listOption?.length ? (
              <Button
                type="primary"
                icon={<CheckCircleOutlined />}
                onClick={() => {
                  if (params.options.length > 0) {
                    postUpdateCallReview();
                    handleCloseModal();
                  } else {
                    notifyFail('Vui lòng chọn thông tin bạn muốn đánh giá!');
                  }
                }}
              >
                Đánh giá
              </Button>
            ) : null}
          </Row>
        </ModalStyled>
      )}
    </>
  );
};

ModalReviewCall.propTypes = {};

export default ModalReviewCall;
