import React, { Component } from 'react';
import { Text, View } from 'react-native';
import PropTypes from 'prop-types';
import { Row, Col, Button, Modal, Table } from 'react-bootstrap';
import { notifyFail, notifySuccess } from '@utils/notify';
import { Tag, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import Loading from '@src/components/Loading';
import { CustomerGroupApi } from '@src/constants/Api';
import { createFormData } from '@utils/createFormData';
import { Spin } from 'antd';

const ACCEPT_TYPES =
  '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
export class ImportCustomerModal extends Component {
  static propTypes = {
    shown: PropTypes.bool,
    onClose: PropTypes.func,
    groupId: PropTypes.number,
    onSuccess: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedFile: [],
      isLoading: false,
      data: {
        inserted: [],
        ignored: [],
        updated: [],
        meta: {},
      },
      addedIdsMapping: {},
      imported: false,

      modalImport: this.props.shown,
      modalResult: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.shown !== this.props.shown) {
      if (this.props.shown) {
        this.resetState(this.props.shown);
      } else {
        this.handleClose();
      }
    }
  }

  resetState = (shown, callback) => {
    this.setState(
      {
        selectedFile: [],
        isLoading: false,
        data: {
          inserted: [],
          ignored: [],
          updated: [],
        },
        meta: {},
        addedIdsMapping: {},
        imported: false,

        modalImport: shown,
        modalResult: false,
      },
      () => callback && callback()
    );
  };

  handleClose = () => {
    if (this.state.imported) {
      if (this.props.onSuccess) {
        this.props.onSuccess();
      } else {
        window.location.reload();
      }
    }
    this.resetState(false, () => {
      this.props.onClose();
    });
  };
  handleFile = (file, fileList) => {
    return false;
  };

  importFile = async () => {
    const form = createFormData({
      file: this.state.selectedFile[0].originFileObj,
      id: this.props.groupId,
    });
    try {
      this.setState({ isLoading: true });
      const res = await CustomerGroupApi.importCustomerFromFile(form);

      const addedIdsMapping = {};
      let added = 0;
      let existing = 0;
      res.meta &&
        res.meta.groups &&
        res.meta.groups.data
          // .filter((v) => !!v.id)
          .forEach((item) => {
            if (!!item.id) {
              added += 1;
            } else {
              existing += 1;
            }
            addedIdsMapping[item.customer_id] = !!item.id
              ? { success: 1, element: <span className="text-success"></span> }
              : { success: 0, element: <span className="text-dark">--</span> };
          });
      this.setState(
        {
          data: res.data,
          meta: res.meta,
          addedIdsMapping,
          added,
          existing,
          imported: true,
          selectedFile: [],
          isLoading: false,
          modalImport: false,
        },
        () => {
          this.setState({ modalResult: true });
        }
      );
      // window.location.reload();
    } catch (err) {
      this.setState({ isLoading: false });
      notifyFail(err.msg);
    }
  };
  handleImportPress = () => {
    if (this.state.selectedFile.length <= 0) {
      return;
    }
    this.importFile();
  };

  handleChangeFile = (info) => {
    if (!this.state.justRemove) {
      console.log(info);
      console.log(info.file.name);
      if (info.file.name.includes('.xlsx')) {
        this.setState({
          ...this.state,
          selectedFile: [{ ...info.fileList[0], status: 'done' }],
        });
      } else {
        notifyFail('Vui lòng chọn file định dạnh xlsx');
      }
    } else {
      this.setState({ justRemove: false });
    }
  };

  removeFile = () => {
    this.setState({
      selectedFile: [],
      justRemove: true,
    });
    return true;
  };

  importedModal = () => {
    const { modalResult } = this.state;
    const { inserted, updated, ignored } = this.state.data;
    const allItems = [inserted, updated, ignored].flat();
    return (
      <Modal show={modalResult} onHide={this.handleClose} dialogClassName="modal-90w" centered size="xl">
        <Modal.Header closeButton className="text-white bg-primary border-top border-warning pb-0">
          <h5 className="text-light pb-0">File dữ liệu đã được upload</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          <Row className="sub-status">
            {/* <div>
              <p className="inserted-point m-0"></p>
              <span>Thành công</span>
            </div> */}
            {/* <div>
              <p className="updated-point m-0"></p>
              <span>Đã cập nhật</span>
            </div> */}
            {/* <div>
              <p className="ignored-point m-0"></p>
              <span>Thất bại</span>
            </div> */}
          </Row>
          <Row>
            <Col sm={12} className="p-0">
              {/* <span>
                Thêm mới: <b className="text-success">{this.state.added || '0'}</b>
              </span>
              <span className="ml-2">
                Đã có: <b className="text-warning">{this.state.existing || '0'}</b>
              </span> */}
              {/* <span className="ml-2">
                Thất bại: <b className="text-danger">{this.state.added || '0'}</b>
              </span> */}
            </Col>
            {/* <Col sm={6} className="p-0">
              <span>
                Thất bại: <b className="text-danger">{ignored.length || '0'}</b>
              </span>
            </Col> */}
          </Row>
          <div>Đang thực hiện import...</div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="success" className="text-light" onClick={this.handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  render() {
    return (
      <>
        <Modal show={this.state.modalImport} onHide={this.handleClose} dialogClassName="modal-90w" centered>
          <Modal.Header closeButton className="text-white bg-warning border-top border-warning pb-0">
            <h5 className="text-light pb-0">Import dữ liệu</h5>
          </Modal.Header>

          <Modal.Body className="custom-body d-flex align-items-center">
            <Upload.Dragger
              // name="file"
              // accept={ACCEPT_TYPES}
              // progress={{ strokeWidth: 2, showInfo: false }}
              // className="w-100"
              // fileList={this.state.selectedFile}
              // beforeUpload={this.handleFile}
              // onChange={(infor) => this.handleChangeFile(infor)}
              // onRemove={this.removeFile}
              name="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              progress={{ strokeWidth: 2, showInfo: false }}
              className="w-100"
              fileList={this.state.selectedFile}
              beforeUpload={(file, fileList) => this.handleFile(file, fileList)}
              onChange={(info) => this.handleChangeFile(info)}
              onRemove={() => this.removeFile()}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Chọn hoặc kéo thả file vào đây để import dữ liệu</p>
              <p className="ant-upload-hint">
                Hỗ trợ import một file, dạng file được chấp nhận là excel. Import những dạng file khác có thể sinh ra
                lỗi hệ thống
              </p>
            </Upload.Dragger>
            {this.state.isLoading && <Loading />}
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button variant="secondary" className="text-light" onClick={this.handleClose}>
              Đóng
            </Button>
            <Button
              variant="warning"
              disabled={!this.state.selectedFile?.length}
              className="text-light"
              onClick={this.handleImportPress}
            >
              Import
            </Button>
          </Modal.Footer>
        </Modal>
        {this.importedModal()}
      </>
    );
  }
}

export default ImportCustomerModal;
