import { GET_USER, GET_USER_SUCCESS, GET_USER_FAIL } from '@actions/type';

import reactotron from 'reactotron-react-js';
const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER: {
      return { ...state, isLoading: true };
    }

    case GET_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        data: action.payload.response.data,
      };
    }

    case GET_USER_FAIL: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    }

    case 'CALLBACK_EVOUCHER':
      return {
        ...state,
        callbackEvoucher: !state?.callbackEvoucher,
      };

    case 'CALLBACK_LIST_FILE_IMPORT_EXPORT':
      return {
        ...state,
        callbackFileImportExport: !state?.callbackFileImportExport,
      };

    default:
      return state;
  }
}
