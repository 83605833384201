import { BASE_URL_IMAGE } from '@constants/Constant';
import { randomColor } from '@constants/funcHelper';
import { DescriptionCustomInteractionHistory } from '@styles/Style';
import { Avatar, Descriptions } from 'antd';
import React from 'react';
import InteractionHistoryHeader from './InteractionHistoryHeader';

export default function Minigame({ interactionHistory }) {
  try {
    return (
      <>
        <InteractionHistoryHeader interactionHistory={interactionHistory} />

        <DescriptionCustomInteractionHistory column={1}>
          <Descriptions.Item label="Tên vòng quay">
            {interactionHistory?.data_gift?.game_name || '-'}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              interactionHistory?.data_gift?.gift_code
                ? 'Tên chương trình'
                : 'Tên quà tặng'
            }
          >
            {interactionHistory?.data_gift?.name || '-'}
          </Descriptions.Item>
          {interactionHistory?.data_gift?.gift_code && (
            <Descriptions.Item label="Mã voucher">
              {interactionHistory?.data_gift?.gift_code || '-'}
            </Descriptions.Item>
          )}
        </DescriptionCustomInteractionHistory>
      </>
    );
  } catch (error) {
    return (
      <>
        <InteractionHistoryHeader interactionHistory={interactionHistory} />

        {/* <DescriptionCustomInteractionHistory column={1}>
          <Descriptions.Item label="Công việc">
            {interactionHistory.job_name_parent
              ? interactionHistory.job_name_parent
              : 'Chưa cập nhật'}
          </Descriptions.Item>

          <Descriptions.Item label="Nội dung giao việc">
            {interactionHistory.job_name
              ? interactionHistory.job_name
              : 'Chưa cập nhật'}
          </Descriptions.Item>
        </DescriptionCustomInteractionHistory> */}
      </>
    );
  }
}
