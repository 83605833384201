import { Spin } from 'antd';
import html2canvas from 'html2canvas';
import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import TemplateVoucherExport from './components/templateVoucher/TemplateVoucherExport';

// Hàm lấy giá trị của tham số từ chuỗi URL
function getQueryParam(url, paramName) {
  const params = new URLSearchParams(url);
  return params.get(paramName);
}

function DataURIToBlob(dataURI) {
  const splitDataURI = dataURI.split(',');
  const byteString =
    splitDataURI[0].indexOf('base64') >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}

const PageExport = () => {
  const location = useLocation();

  const imageString = getQueryParam(location.search, 'image');
  const codeString = getQueryParam(location.search, 'code');
  const image = JSON.parse(imageString);
  const code = JSON.parse(codeString);

  const imageTemVoucherRef = React.useRef(null);

  const imgEvoucher = React.useRef(null);

  const [voucherDirection, setVoucherDirection] = React.useState(null);
  const [progressExport, setProgressExport] = React.useState('Bắt đầu');

  React.useEffect(() => {
    if (!image) return;
    if (imageTemVoucherRef.current.height > imageTemVoucherRef.current.width) {
      setVoucherDirection('vertical');
    } else {
      setVoucherDirection('horizontal');
    }
  }, [imageTemVoucherRef?.current?.height]);

  React.useEffect(() => {
    if (!code) return;
    setTimeout(() => {
      setProgressExport('Đang tạo');
      // domtoimage
      //   .toPng(imgEvoucher.current, {
      //     bgcolor: 'white',
      //   })
      //   .then(function (dataUrl) {
      //     if (dataUrl) {
      //       const file = DataURIToBlob(dataUrl);
      //       const formData = new FormData();
      //       formData.append('file', file);
      //       formData.append('type', 4);
      //       voucherService.uploadFile(formData).then(res => {
      //         if (res?.status) {
      //           setProgressExport('Thành công!');
      //           window.ReactNativeWebView &&
      //             window.ReactNativeWebView.postMessage(res?.data);
      //         } else {
      //           setProgressExport('Thất bại!');
      //           window.ReactNativeWebView &&
      //             window.ReactNativeWebView.postMessage('false');
      //         }
      //       });
      //     }
      //   })
      //   .catch(function (error) {
      //     setProgressExport('Thất bại!');
      //     window.ReactNativeWebView &&
      //       window.ReactNativeWebView.postMessage('false');
      //   })
      //   .finally(() => {});
      html2canvas(imgEvoucher.current, {
        useCORS: true,
      })
        .then(canvas => {
          if (canvas?.toDataURL()) {
            const href = canvas.toDataURL();
            setProgressExport('Thành công!');
            window.ReactNativeWebView &&
              window.ReactNativeWebView.postMessage(href);
          } else {
            setProgressExport('Thất bại!');
            window.ReactNativeWebView &&
              window.ReactNativeWebView.postMessage('false');
          }
        })
        .catch(function (error) {
          setProgressExport('Thất bại!');
          window.ReactNativeWebView &&
            window.ReactNativeWebView.postMessage('false');
        })
        .finally(() => {});
    }, 1500);
  }, [code]);

  return (
    <ContainerStyled>
      <Spin spinning={progressExport === 'Đang tạo'}>
        {progressExport === 'Thất bại!' && 'Có lỗi xảy ra!'}
        {/* lấy chiều dài rộng mẫu voucher */}
        {image && (
          <>
            <img
              style={{ display: 'none' }}
              alt="e_voucher_image"
              ref={imageTemVoucherRef}
              src={image}
            />
            <div>
              <div
                style={{
                  height: 'fit-content',
                  width: 'fit-content',
                }}
                ref={imgEvoucher}
              >
                <TemplateVoucherExport
                  hori={voucherDirection === 'horizontal'}
                  imgUrl={image}
                  valueQR={code}
                />
              </div>
            </div>
          </>
        )}
      </Spin>
    </ContainerStyled>
  );
};
const ContainerStyled = styled.div`
  * {
    font-family: 'Quicksand', sans-serif !important;
  }
`;

export default PageExport;
