import React from 'react';
import { Col, FormControl, Row } from 'react-bootstrap';
import { Collapse, Empty, List, Space } from 'antd';
const { Panel } = Collapse;
const genHeader = (iconClass, title) => (
  <>
    {iconClass && <i class={`${iconClass} fa-lg mr-2`}></i>}
    <label className="m-0">{title}</label>
  </>
);

const UntestedDetail = props => {
  return (
    <Row>
      <Col>
        <Row>
          <Col md={12}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Collapse
                style={{
                  width: '100%',
                  textAlign: 'left',
                  borderRadius: '0.25rem',
                }}
                expandIconPosition="right"
              >
                <Panel
                  header={genHeader('fas fa-crosshairs', 'Mục tiêu')}
                  key="1"
                >
                  {props.data.job_category?.target ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: props.data.job_category?.target,
                      }}
                    ></div>
                  ) : (
                    'Chưa cập nhật'
                  )}
                </Panel>
              </Collapse>
              <Collapse
                style={{
                  width: '100%',
                  textAlign: 'left',
                  borderRadius: '0.25rem',
                }}
                expandIconPosition="right"
              >
                <Panel
                  header={genHeader('fas fa-book-open', 'Hướng dẫn')}
                  key="1"
                >
                  {props.data.job_category?.tutorial ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: props.data.job_category?.tutorial,
                      }}
                    ></div>
                  ) : (
                    'Chưa cập nhật'
                  )}
                </Panel>
              </Collapse>
              <Collapse
                style={{
                  width: '100%',
                  textAlign: 'left',
                  borderRadius: '0.25rem',
                }}
                expandIconPosition="right"
              >
                <Panel
                  header={genHeader('fas fa-graduation-cap', 'Đào tạo')}
                  key="1"
                >
                  <label className="mr-2">Mô tả:</label>
                  {props.data.job_category?.edu_infor ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: props.data.job_category?.edu_infor,
                      }}
                    ></div>
                  ) : (
                    'Chưa cập nhật'
                  )}
                  {props.data.job_category?.edu_url && (
                    <a
                      target="_blank"
                      href={props.data.job_category?.edu_url}
                      rel="noreferrer"
                    >
                      {props.data.job_category?.edu_url.split('/')[5]}
                    </a>
                  )}
                  <br />
                  {props.data.job_category?.edu_video_url && (
                    <video
                      controls
                      src={props.data.job_category?.edu_video_url}
                      className="uploaded-pic img-thumbnail mt-2"
                      style={{ width: '500px', height: '300px' }}
                    ></video>
                  )}
                </Panel>
              </Collapse>
              <Collapse
                style={{
                  width: '100%',
                  textAlign: 'left',
                  borderRadius: '0.25rem',
                }}
                expandIconPosition="right"
              >
                <Panel header={genHeader('fas fa-film', 'Kịch bản')} key="1">
                  <label className="mr-2">Mô tả:</label>
                  {props.data.scenario?.description ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: props.data.scenario?.description,
                      }}
                    ></div>
                  ) : (
                    'Chưa cập nhật'
                  )}
                  {props.data.scenario?.scenario_steps.length ? (
                    props.data.scenario?.scenario_steps.map((item, index) => {
                      return (
                        <Collapse ghost key={index}>
                          <Panel header={genHeader('', item.name)}>
                            <List
                              itemLayout="horizontal"
                              dataSource={[item.content]}
                              renderItem={text => <List.Item>{text}</List.Item>}
                            />
                          </Panel>
                        </Collapse>
                      );
                    })
                  ) : (
                    <Empty description="Chưa có các bước kịch bản" />
                  )}
                </Panel>
              </Collapse>
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default UntestedDetail;
