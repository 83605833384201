import { Modal, Spin } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

const ModalModules = ({
  title,
  modalVisible,
  loading = false,
  children,
  bodyStyle,
  width = 600,
  onCancel,
  closable = true,
}) => {
  return (
    <Modal
      width={width}
      // destroyOnClose
      maskClosable={false}
      closable={closable}
      bodyStyle={bodyStyle}
      footer={null}
      title={title}
      visible={modalVisible}
      onCancel={onCancel && onCancel}
      centered
    >
      <Spin spinning={loading}>{children}</Spin>
    </Modal>
  );
};

ModalModules.propTypes = {
  title: PropTypes.string,
  modalVisible: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.node,
  bodyStyle: PropTypes.object,
  width: PropTypes.number,
  onCancel: PropTypes.func,
  closable: PropTypes.bool,
};

export default React.memo(ModalModules);
