import React, { useState } from 'react';
import { Col, Divider, Empty, Row, Spin, Timeline } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import { DF_CUSTOMER_ACTION_ID } from '@constants/Constant';
import Wrapper from '@components/ui/wrapper/Wrapper';
import { primaryColor } from '@styles/Color';
import {
  AppointmentSchedule,
  Call,
  Create,
  CustomerClosesTheContract,
  CustomerRefuses,
  DeleteOpportunities,
  DeliverToSale,
  EditCustomer,
  LocationReport,
  Message,
  MissedCall,
  PotentialCustomers,
  UpdateTag,
} from '@components/interaction-history';

const TimeLineStyle = styled(Timeline)`
  .ant-timeline-item {
    padding: 0;
  }

  .ant-timeline-item-tail {
    position: absolute;
    top: 10px;
    left: 4px;
    height: calc(100% - 10px);
    border-left: 2px dotted #b3b3b3;
  }
`;

const DividerTextStyle = styled.div`
  color: white;
  background-color: ${primaryColor};
  padding: 0 10px;
  border-radius: 4px;
`;

const ViewMoreStyled = styled.div`
  color: blue;
  text-align: right;

  &:hover {
    cursor: pointer;
  }
`;

export default function HistoryCustomer({
  customerDetail,
  loadingCustomerInteractionHistory,
  customerInteractionHistory,
  setViewMore,
  viewMore,
  cursorId,
}) {
  const user = useSelector(store => store.userReducer.data);

  const [showAllInteractionHistory, setShowAllInteractionHistory] =
    useState(false);

  const timeDivider = date => {
    if (
      new Date().getDate() === new Date(date).getDate() &&
      new Date().getMonth() === new Date(date).getMonth() &&
      new Date().getFullYear() === new Date(date).getFullYear()
    ) {
      return (
        <Divider style={{ marginBottom: '25px' }}>
          <DividerTextStyle>Hôm nay</DividerTextStyle>
        </Divider>
      );
    } else if (new Date().getDate() - new Date(date).getDate() === 1) {
      return (
        <Divider style={{ marginBottom: '25px' }}>
          <DividerTextStyle>Hôm qua</DividerTextStyle>
        </Divider>
      );
    } else {
      return (
        <Divider style={{ marginBottom: '25px' }}>
          <DividerTextStyle>
            {moment(date).format('DD/MM/YYYY')}
          </DividerTextStyle>
        </Divider>
      );
    }
  };

  const interactionHistory = customerInteractionHistory => {
    return (
      <div>
        <TimeLineStyle>
          {customerInteractionHistory.length > 0 ? (
            customerInteractionHistory.map((item, index, array) => {
              if (index === 0) {
                return (
                  <>
                    {timeDivider(item.created_at)}
                    <Timeline.Item
                      key={index}
                      dot={
                        <img
                          src={item.df_customer_action_icon}
                          style={{ width: '25px', height: '25px' }}
                          alt=""
                        />
                      }
                    >
                      <div
                        style={{
                          backgroundColor: 'rgb(246 246 246)',
                          margin: '10px 15px',
                          padding: '8px 16px',
                          borderRadius: '8px',
                        }}
                      >
                        {renderInteractionHistory(item)}
                      </div>
                    </Timeline.Item>
                  </>
                );
              } else {
                const currentDateItem = new Date(
                  array[index].created_at
                ).getDate();
                const prevDateItem = new Date(
                  array[index - 1].created_at
                ).getDate();
                if (currentDateItem === prevDateItem) {
                  return (
                    <Timeline.Item
                      key={index}
                      dot={
                        <img
                          src={item.df_customer_action_icon}
                          style={{ width: '25px', height: '25px' }}
                          alt=""
                        />
                      }
                    >
                      <div
                        style={{
                          backgroundColor: '#f3f3f3',
                          margin: '10px 15px',
                          padding: '8px 16px',
                          borderRadius: '8px',
                        }}
                      >
                        {renderInteractionHistory(item)}
                      </div>
                    </Timeline.Item>
                  );
                } else {
                  return (
                    <>
                      {timeDivider(item.created_at)}
                      <Timeline.Item
                        key={index}
                        dot={
                          <img
                            src={item.df_customer_action_icon}
                            style={{ width: '25px', height: '25px' }}
                            alt=""
                          />
                        }
                      >
                        <div
                          style={{
                            backgroundColor: '#f3f3f3',
                            margin: '10px 15px',
                            padding: '8px 16px',
                            borderRadius: '8px',
                          }}
                        >
                          {renderInteractionHistory(item)}
                        </div>
                      </Timeline.Item>
                    </>
                  );
                }
              }
            })
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>Không có lịch sử.</span>}
            />
          )}
        </TimeLineStyle>
      </div>
    );
  };

  const renderInteractionHistory = interactionHistory => {
    switch (interactionHistory.df_customer_action_id) {
      case DF_CUSTOMER_ACTION_ID.KHACH_HANG_TIEM_NANG:
        return <PotentialCustomers interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.KHACH_HANG_CHOT_HOP_DONG:
        return (
          <CustomerClosesTheContract interactionHistory={interactionHistory} />
        );

      case DF_CUSTOMER_ACTION_ID.KHACH_HANG_TU_CHOI:
        return <CustomerRefuses interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.GOI_DEN:
        return <Call interactionHistory={interactionHistory} user={user} />;

      case DF_CUSTOMER_ACTION_ID.GOI_DI:
        return <Call interactionHistory={interactionHistory} user={user} />;

      case DF_CUSTOMER_ACTION_ID.GOI_NHO:
        return <MissedCall interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.CHINH_SUA_KHACH_HANG:
        return <EditCustomer interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.GIAO_CHO_SALE_CHAM_SOC:
        return <DeliverToSale interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.LICH_HEN:
        return <AppointmentSchedule interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.THEM_MOI:
        return <Create interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.CAP_NHAT_THE_TAG:
        return <UpdateTag interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.XOA_CO_HOI:
        return <DeleteOpportunities interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.BAO_CAO_VI_TRI:
        return <LocationReport interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.GUI_TIN_NHAN_SMS:
        return <Message interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.GUI_TIN_NHAN_ZNS:
        return <Message interactionHistory={interactionHistory} />;

      default:
        break;
    }
  };

  return (
    <div>
      <Wrapper style={{ margin: 0, padding: 0 }}>
        <Row>
          <Col>
            <Spin spinning={loadingCustomerInteractionHistory}>
              {/* {customerInteractionHistory.length > 20 &&
              !showAllInteractionHistory ? (
                <>
                  {interactionHistory(customerInteractionHistory.slice(0, 20))}
                  <ViewMoreStyled
                    onClick={() => {
                      setShowAllInteractionHistory(true);
                    }}
                  >
                    Xem thêm...
                  </ViewMoreStyled>
                </>
              ) : (
                interactionHistory(customerInteractionHistory)
              )} */}

              {interactionHistory(customerInteractionHistory)}
              {cursorId !== null && (
                <ViewMoreStyled
                  onClick={() => {
                    // setShowAllInteractionHistory(true);
                    setViewMore(!viewMore);
                  }}
                >
                  Xem thêm...
                </ViewMoreStyled>
              )}
            </Spin>
          </Col>
        </Row>
      </Wrapper>
    </div>
  );
}
