import { Col, Row } from 'react-bootstrap';

import React from 'react';

const DTMFNumpad = ({ onClickNumberDtmf }) => {
  return (
    <>
      <Row className="mb-3 d-flex justify-content-center">
        <Col xs={4} sm={4} md={4} lg={4} className="d-flex justify-content-end">
          <div
            className="number-btn"
            onClick={() => {
              onClickNumberDtmf('1');
            }}
          >
            1
          </div>
        </Col>
        <Col
          xs={4}
          sm={3}
          md={3}
          lg={3}
          className="d-flex justify-content-center"
        >
          <div
            className="number-btn"
            onClick={() => {
              onClickNumberDtmf('2');
            }}
          >
            2
          </div>
        </Col>
        <Col
          xs={4}
          sm={4}
          md={4}
          lg={4}
          className="d-flex justify-content-start"
        >
          <div
            className="number-btn"
            onClick={() => {
              onClickNumberDtmf('3');
            }}
          >
            3
          </div>
        </Col>
      </Row>
      <Row className="mb-3 d-flex justify-content-center">
        <Col xs={4} sm={4} md={4} lg={4} className="d-flex justify-content-end">
          <div
            className="number-btn"
            onClick={() => {
              onClickNumberDtmf('4');
            }}
          >
            4
          </div>
        </Col>
        <Col
          xs={4}
          sm={3}
          md={3}
          lg={3}
          className="d-flex justify-content-center"
        >
          <div
            className="number-btn"
            onClick={() => {
              onClickNumberDtmf('5');
            }}
          >
            5
          </div>
        </Col>
        <Col
          xs={4}
          sm={4}
          md={4}
          lg={4}
          className="d-flex justify-content-start"
        >
          <div
            className="number-btn"
            onClick={() => {
              onClickNumberDtmf('6');
            }}
          >
            6
          </div>
        </Col>
      </Row>
      <Row className="mb-3 d-flex justify-content-center">
        <Col xs={4} sm={4} md={4} lg={4} className="d-flex justify-content-end">
          <div
            className="number-btn"
            onClick={() => {
              onClickNumberDtmf('7');
            }}
          >
            7
          </div>
        </Col>
        <Col
          xs={4}
          sm={3}
          md={3}
          lg={3}
          className="d-flex justify-content-center"
        >
          <div
            className="number-btn"
            onClick={() => {
              onClickNumberDtmf('8');
            }}
          >
            8
          </div>
        </Col>
        <Col
          xs={4}
          sm={4}
          md={4}
          lg={4}
          className="d-flex justify-content-start"
        >
          <div
            className="number-btn"
            onClick={() => {
              onClickNumberDtmf('9');
            }}
          >
            9
          </div>
        </Col>
      </Row>
      <Row className="mb-3 d-flex justify-content-center">
        <Col xs={4} sm={4} md={4} lg={4} className="d-flex justify-content-end">
          <div
            className="number-btn"
            onClick={() => {
              onClickNumberDtmf('*');
            }}
          >
            *
          </div>
        </Col>
        <Col
          xs={4}
          sm={3}
          md={3}
          lg={3}
          className="d-flex justify-content-center"
        >
          <div
            className="number-btn"
            onClick={() => {
              onClickNumberDtmf('0');
            }}
          >
            0
          </div>
        </Col>
        <Col
          xs={4}
          sm={4}
          md={4}
          lg={4}
          className="d-flex justify-content-start"
        >
          <div
            className="number-btn"
            onClick={() => {
              onClickNumberDtmf('#');
            }}
          >
            #
          </div>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(DTMFNumpad);
