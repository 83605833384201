import React from 'react';
import Draggable from 'react-draggable';
import CallingTimer from './Calling.Timer';
import IconAntd from 'src/modules/IconAntd';
import styled from 'styled-components';
import { Popconfirm } from 'antd';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { returnUsernameCall } from './utils';

const Calling = ({ callDetail, onCancelAnswer, onCloseAnswer }) => {
  const contentRef = React.useRef(null);

  const [currentPosition, setCurrentPosition] = React.useState({
    xRate: window.outerWidth / 2 - 150,
    yRate: 30,
  });

  const onDrag = (e, data) => {
    setCurrentPosition({ xRate: data.lastX, yRate: data.lastY });
  };

  return (
    <Draggable
      position={{
        x: currentPosition.xRate,
        y: currentPosition.yRate,
      }}
      onDrag={onDrag}
    >
      <ContainerStyled ref={contentRef}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <div style={{ width: '140px' }}>
            <span style={{ textAlign: 'center' }}>
              {callDetail?.isEndAnswer
                ? 'Kết thúc cuộc gọi từ '
                : 'Cuộc gọi từ'}{' '}
              <strong>
                {returnUsernameCall(
                  callDetail?.fromAlias ||
                    callDetail?.incomingCall?.fromNumber?.replace('84', '0')
                )}
              </strong>
            </span>
            <CallingTimer endCount={callDetail?.isEndAnswer} />
          </div>
          {callDetail?.isEndAnswer ? (
            <CountdownCircleTimer
              isPlaying
              size={35}
              strokeWidth={1.5}
              duration={3}
              colors={['#004777', '#F7B801', '#A30000']}
              colorsTime={[3, 2, 1]}
              onComplete={() => onCloseAnswer(callDetail?.uuid)}
            >
              {({ remainingTime }) => remainingTime}
            </CountdownCircleTimer>
          ) : (
            <>
              <Popconfirm
                title="Kết thúc cuộc gọi?"
                okText="Đồng ý"
                cancelText="Quay lại"
                onConfirm={() =>
                  onCancelAnswer(callDetail?.uuid, callDetail?.incomingCall)
                }
              >
                <div className="btn_action">
                  <IconAntd color="white" icon="CloseOutlined" />
                </div>
              </Popconfirm>
              <div className="ping" />
            </>
          )}
        </div>
      </ContainerStyled>
    </Draggable>
  );
};

const ContainerStyled = styled.div`
  @keyframes load {
    0% {
      background: green;
      border: 0px solid green;
    }
    50% {
      background: green;
      border: 10px solid #fafafa;
    }
    100% {
      background: green;
      border: 0px solid #fafafa;
    }
  }

  z-index: 9999;
  position: fixed;
  background-color: white;
  padding: 10px 20px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  & .btn_action {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    background-color: red;
    margin-right: 25px;
  }

  .ping {
    background: #fff;
    border-radius: 50%;
    border: 40px solid #f5f5f5;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 15px;
    animation: load 1.2s ease-out infinite;
  }
`;

export default Calling;
