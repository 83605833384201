import { Pagination } from 'antd';
import React from 'react';
import { TABLE_RECORD_SIZE } from './contants';

/*
Todo-> noted component:
    - page: trang hiện tại -> example: 1,2,3...
    - total: tổng số bản ghi -> example: 10,20, ...
    - pageSize: tổng số bản ghi trên 1 trang ->  example: 5,10, ...

    - onChange: callback khi click số trang
*/
// interface IProps {
//     page: number;
//     onChange: (page: number) => void;
//     total: number;
//     pageSize?: number;
// }

const PaginationModules = ({
  page,
  total,
  pageSize = TABLE_RECORD_SIZE,
  onChange,
}) => {
  return (
    <Pagination
      onChange={value => onChange(value)}
      current={Number(page)}
      total={total}
      showSizeChanger={false}
      pageSize={pageSize}
      showQuickJumper={total > 120}
    />
  );
};

export default React.memo(PaginationModules);
