import { requestDownloadLogsFile } from '@constants/Api';
import {
  CALLING_STATUS,
  EVENT_CHANNEL,
  OPPORTUNITY_STATUS,
  ROUTER,
} from '@constants/Constant';
import {
  downloadFileWithSocket,
  setSearchParamsInUrlVer2,
} from '@constants/funcHelper';
import { getBaseURL } from '@src/constants/Api';
import EventEmitter from '@utils/EventEmitter';
import { notifyFail, notifySuccess } from '@utils/notify';
import { Button, notification } from 'antd';
import { saveAs } from 'file-saver';
import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { io } from 'socket.io-client';
import {
  callbackEvoucher,
  callbackListFileImportExport,
  getKpiProgress,
} from '../redux/actions/index';
import { sendSocketBroadCast } from './callProviders/BroadCastUtil';
import CallUtil from './callProviders/CallUtil';

const TOP = 60;

const ConfirmModal = props => {
  const { visible, onConfirm, onClose } = props;
  return (
    <Modal
      show={visible}
      onHide={() => onClose()}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      centered
    >
      <Modal.Header closeButton className="bg-primary">
        <h5 className="text-light pb-0">
          Báo cáo KPI vừa cập nhật số liệu mới, bạn có muốn chuyển sang Tiến độ
          KPI?
        </h5>
      </Modal.Header>
      <Modal.Body className="custom-body">
        <Row>
          <Col className="button-wrapper " style={{ textAlign: 'center' }}>
            <Button
              variant="success"
              style={{ background: 'green' }}
              onClick={() => {
                onConfirm();
              }}
              className="text-light"
            >
              Có
            </Button>
            <Button
              variant="danger"
              style={{ background: 'red' }}
              onClick={() => {
                onClose();
              }}
              className="text-light"
            >
              Hủy
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function SocketComponent(props) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  let query = useQuery();

  const userInfo = useSelector(state => {
    const userReducer = state.userReducer;

    if (!userReducer.data || !userReducer.data.token) {
      return {};
    }

    const data = userReducer.data;
    let enterprise_id = null;

    if (data.role_id == 2 || data.role_id == 5) {
      enterprise_id = data.user_id;
    } else if (data.enterprise_x_users && data.enterprise_x_users.length >= 1) {
      enterprise_id = data.enterprise_x_users[0].enterprise_id;
    }

    if (!enterprise_id) {
      return;
    }

    return {
      token: data.token,
      user_id: data.user_id,
      role_id: data.role_id,
      enterprise_id: enterprise_id,
      socket: userReducer?.socket,
    };
  });

  const isNewCustomer = useSelector(
    state => state.isNewCustomer.data?.status || false
  );

  const stringeeCall = useSelector(state => {
    return state.stringeeCallAway.data;
  });

  React.useEffect(() => {
    const baseURL = getBaseURL();

    if (!baseURL || !userInfo || !userInfo.token || !userInfo.enterprise_id) {
      return;
    }
    // console.log('socket base', { url: `${baseURL}enterprise/${userInfo.enterprise_id}`, token: userInfo.token });
    const socket = io(`${baseURL}enterprise/${userInfo.enterprise_id}`, {
      auth: { token: userInfo.token },
      transport: ['websocket'],
    });

    socket.connect();

    socket.on('connect', () => {
      // console.log('socket connected');
    });

    socket.on(`evoucher_${userInfo?.enterprise_id}`, res => {
      console.log(
        '🚀 ~ file: SocketComponent.js:140 ~ React.useEffect ~ res',
        res
      );
      if (res?.status) {
        dispatch(callbackEvoucher());

        if (!res?.is_true_form_import) {
          return notifyFail('File import không đúng định dạng.');
        }

        return notifySuccess(
          'Tạo thành công ' +
            res?.success_count +
            ' mã voucher' +
            (res?.is_not_enough_code == 'true'
              ? 'Không đủ mã vui lòng tăng số lượng ký tự.'
              : '')
        );
      }
      return notifyFail(res?.error);
    });

    socket.on('connect_error', function (err) {
      console.log('client connect_error: ', err);
    });

    socket.on('import_on_start', res => {
      console.log('import_on_start', res);
    });

    socket.on(`enterprise_${userInfo.enterprise_id}`, res => {
      console.log('kpi_progress_socket', res);
      notifySuccess('Bảng tiến độ KPI có số liệu mới!');
      dispatch(getKpiProgress({ ...res }));
    });

    socket.on(`call_${userInfo.user_id}`, res => {
      console.log({ res });
      // EventEmitter.emit('incomingCall', res);
      sendSocketBroadCast({
        name: 'socket',
        status: 'unknown',
        data: res,
      });
      if (
        res.call_status === CALLING_STATUS.ringing ||
        res.call_status === CALLING_STATUS.ended ||
        res.call_status === CALLING_STATUS.answered ||
        res.call_status === CALLING_STATUS.busy
      ) {
        const { call_id, customer_id, call_status } = res;
        const urlSearchParams = new URLSearchParams(window.location.search);
        const callIdInUrl = urlSearchParams.get('call_id');
        if (location.pathname === ROUTER.CALLING_SALE) {
          // if (callIdInUrl && String(call_id) === String(callIdInUrl)) {
          //   setSearchParamsInUrlVer2({
          //     call_status: call_status,
          //     numbPad: false,
          //   });
          // } else {
          //   setSearchParamsInUrlVer2({
          //     call_status: call_status,
          //     numbPad: false,
          //     call_id,
          //     customer_id,
          //   });
          // }
        } else {
          CallUtil.currentCallId = call_id;
          // history.replace({
          //   pathname: ROUTER.CALLING_SALE,
          // });
        }
      }
    });

    socket.on(`update-call_${userInfo.user_id}`, res => {
      // status to switch to create opportunity button whether sale submit call result from app or on web
      console.log('update_call_socket', res);
      // dispatch(getUpdateCall({ ...res }));
    });

    socket.on(`customer_${userInfo.user_id}`, res => {
      // navigate to customer detail on web when app also dit it
      console.log('customer_detail_socket', res);
      if (location.pathname !== ROUTER.CALLING_SALE) {
        history.replace(ROUTER.CALLING_SALE);
      }
    });

    socket.on(`customer-call_${userInfo.user_id}`, res => {
      // customer call to sale
      console.log('customer-call_socket', res);
    });

    socket.on('import_on_complete', res => {
      EventEmitter.emit('import_on_complete');
      console.log('import_on_complete', res);
      const { summary = {}, outputFile, groups } = res;
      const userId = res?.summary?.user_id;
      if (parseInt(userId) === userInfo?.user_id) {
        notification.success({
          duration: 0,
          top: TOP,
          key: res.filename,
          message: 'Import kết thúc',
          description: (
            <div>
              Thêm mới:{' '}
              <span style={{ color: 'green', marginRight: 10 }}>
                {groups?.customers
                  ? groups?.customers?.inserted?.length
                  : summary.inserted}
              </span>
              Đã có trong hệ thống:{' '}
              <span style={{ color: 'orange', marginRight: 10 }}>
                {groups?.customers
                  ? groups?.customers?.updated?.length
                  : summary.updated}
              </span>
              Có lỗi khi import:{' '}
              <span style={{ color: 'red' }}>
                {groups?.customers
                  ? groups?.customers?.ignored?.length
                  : summary.ignored}
              </span>
              <br />
              {outputFile && (
                <Button
                  onClick={async () => {
                    try {
                      const data = await requestDownloadLogsFile(outputFile);
                      saveAs(data.data, `Ket_qua_import_${Date.now()}.xlsx`);
                    } catch (e) {
                      console.log('download error', e);
                    }
                  }}
                >
                  Tải kết quả
                </Button>
              )}
            </div>
          ),
        });
      }
    });
    socket.on('import_on_progress', res => {
      console.log('import_on_progress', res);
      const { summary = {}, current = {} } = res;
      const currentItems = [
        current.inserted || [],
        current.updated || [],
        current.ignored || [],
      ].flat();
      const currentItem = currentItems.length > 0 ? currentItems[0] : {};
      console.log('current', current);
      const userId = res?.summary?.user_id;
      if (parseInt(userId) === userInfo?.user_id) {
        notification.info({
          duration: 0,
          top: TOP,
          key: res.filename,
          message: 'Đang import khách hàng',
          description: (
            <div>
              Thêm mới:{' '}
              <span style={{ color: 'green', marginRight: 10 }}>
                ${summary.inserted}
              </span>
              Đã có trong hệ thống:{' '}
              <span style={{ color: 'orange', marginRight: 10 }}>
                ${summary.updated}
              </span>
              Có lỗi khi import:{' '}
              <span style={{ color: 'red' }}>${summary.ignored}</span>
              <div>
                {currentItem &&
                  `Đang xử lý dòng số ${currentItem.item.row_number}`}
              </div>
            </div>
          ),
        });
      }
    });

    socket.on('import_on_error', res => {
      console.log('import_on_error', res);
      const userId = res?.summary?.user_id;
      if (parseInt(userId) === userInfo?.user_id) {
        notification.error({
          duration: 0,
          top: TOP,
          key: res.filename,
          message: 'Import khách hàng',
          // description: `Có lỗi xảy ra ${res.error?.message || ''}}`,
          description: `Import không đúng định dạng file mẫu`,
          // description: <div>{res.error?.message}</div>,
        });
      }
    });

    socket.on(`opportunity_note_${userInfo.enterprise_id}`, res => {
      EventEmitter.emit(EVENT_CHANNEL.NEW_NOTIFICATION);
      console.log('opportunity_note_socket', res);
      notifySuccess('Có ghi chú cơ hội mới');
      dispatch(getKpiProgress({ ...res }));
    });

    socket.on(`opportunity_${userInfo.enterprise_id}`, res => {
      EventEmitter.emit(EVENT_CHANNEL.NEW_NOTIFICATION);

      const { type } = res;

      switch (type) {
        case OPPORTUNITY_STATUS.CUSTOMER_POTENTIAL:
          notifySuccess('Có cơ hội mới');
          break;

        case OPPORTUNITY_STATUS.CLOSE_CONTRACT:
          notifySuccess('Cơ hội chuyển sang chốt hợp đồng');
          break;

        case OPPORTUNITY_STATUS.CUSTOMER_REFUSES:
          notifySuccess('Từ chối cơ hội');
          break;

        default:
          notifySuccess('Cập nhật trạng thái cơ hội');
          break;
      }

      dispatch(getKpiProgress({ ...res }));
    });

    socket.on(`export_excel_${userInfo.user_id}`, async result => {
      dispatch(callbackListFileImportExport());

      // downloadFileWithSocket(result.pathFile, 'Danh_sach_KH');
    });
    socket.on(`export_list_call_excel_${userInfo.user_id}`, async res => {
      dispatch(callbackListFileImportExport());
      // downloadFileWithSocket(res.pathFile, 'Danh_sach_cuoc_goi');
    });

    socket.on(
      `export_list_message_history_excel_${userInfo.user_id}`,
      async res => {
        dispatch(callbackListFileImportExport());

        // downloadFileWithSocket(res.pathFile, 'Lich_su_tin_nhan');
      }
    );

    socket.on(
      `export_list_zns_message_history_excel_${userInfo.user_id}`,
      async res => {
        dispatch(callbackListFileImportExport());

        // downloadFileWithSocket(res.pathFile, 'Lich_su_tin_nhan_ZNS');
      }
    );

    socket.on(
      `export_list_zalo_message_history_excel_${userInfo.user_id}`,
      async res => {
        dispatch(callbackListFileImportExport());
      }
    );

    socket.on(`export_excel_time_keeping_${userInfo.user_id}`, async res => {
      dispatch(callbackListFileImportExport());
    });

    socket.on(
      `export_excel_sale_and_revenue_${userInfo.user_id}`,
      async res => {
        dispatch(callbackListFileImportExport());
      }
    );

    socket.on(`timekeeping_${userInfo.enterprise_id}`, res => {
      EventEmitter.emit(EVENT_CHANNEL.NEW_NOTIFICATION);
      console.log('timekeeping_socket', res);
      notifySuccess('Có báo cáo vị trí mới');
    });

    socket.on(`call_review_${userInfo.user_id}`, res => {
      EventEmitter.emit(EVENT_CHANNEL.NEW_NOTIFICATION);
      console.log('call_review', res);
      notifySuccess('Cuộc gọi của bạn đã được đánh giá!');
    });

    // lắng nghe event khi login stringee ở app
    socket.on(`stringee_user_name_changed`, res => {
      console.log('stringee_user_name_changed', res);
    });

    return () => {
      // console.log('socket disconnect');
      socket.disconnect();
    };
  }, [userInfo]);

  return (
    <div>
      {/* <ConfirmModal
        visible={confirmModalVisible}
        onConfirm={() => {
          setConfirmModalVisible(false);
          dispatch(getKpiProgress({ ...kpiProgress } ));
          // history.push({ pathname: ROUTER.KPI_PROGRESS, state: kpiProgress });
          history.push(ROUTER.KPI_PROGRESS);
        }}
        onClose={() => {
          setConfirmModalVisible(false);
        }}
      /> */}
    </div>
  );
}

export default SocketComponent;
