import React, { useEffect, useState } from 'react';
import { Col, DatePicker, Empty, Input, Row, Select, Tooltip } from 'antd';
import moment from 'moment';

import TagCustom from '@screens/Enterprise/Customer/components/TagCustom';
import { rmVN, setSearchParamsInUrlVer2 } from '@constants/funcHelper';
import { LIST_OPPORTUNITY_STATUS } from '@constants/Constant';
import {
  getListCategory,
  requestFullListTag,
  requestListDepartment,
  requestListProduct,
  requestListTelesale,
} from '@constants/Api';
import mixpanel from 'mixpanel-browser';
import {
  requestEVoucherList,
  requestGetCustomerSource,
} from '@screens/Enterprise/Job/services/api';

Filter.propTypes = {};
const { Option } = Select;
const { RangePicker } = DatePicker;
function Filter({
  params,
  setParams,
  user,
  handleChangeOpportunitySearchParam,
}) {
  const [listTelesale, setListTelesale] = useState();
  const [listDepartment, setListDepartment] = useState();
  const [idTelesale, setIdTelesale] = useState();
  const [idDepartment, setIdDepartment] = useState();
  const [tags, setTags] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [listProduct, setListProduct] = useState();
  const [listEVoucher, setListEVoucher] = useState([]);

  const [sourceCustomers, setSourceCustomers] = React.useState([]);

  useEffect(() => {
    getProduct();
    getFullTags();
    getTypeOppo();
    getSourceCustomers();
    getEVoucherList();
  }, []);
  useEffect(() => {
    getTelesale();
  }, [idDepartment]);
  useEffect(() => {
    getDepartment();
  }, [idTelesale]);

  const eVoucherNameList = listEVoucher.map(item => {
    return {
      id: item.id,
      name: item.user ? `${item.name} - ${item.user.full_name}` : item.name,
    };
  });

  const getFullTags = async () => {
    try {
      const res = await requestFullListTag({});
      const options =
        res?.data?.map(tag => ({
          ...tag,
          label: tag.name,
          value: tag.id,
        })) || [];
      setTags(options);
    } catch (err) {
      console.log('Error getFullTag: ', { err });
    }
  };
  const getTypeOppo = async () => {
    try {
      const res = await getListCategory({
        query: '',
        page: 1,
      });
      setTypeList(res.data);
    } catch (error) {
      console.log('err: ', error);
    }
  };
  const getTelesale = async () => {
    try {
      const res = await requestListTelesale(idDepartment ? idDepartment : '');
      const options = res?.data?.map(item => ({
        ...item,
        label: item.full_name,
        value: item.id,
      }));
      if (options) {
        setListTelesale(options);
      }
    } catch (err) {
      console.log('err: ', err);
    }
  };
  const getDepartment = async () => {
    try {
      const res = await requestListDepartment(idTelesale ? idTelesale : '');
      const options = res?.data?.map(item => ({
        ...item,
        label: item.name,
        value: item.id,
      }));
      if (options) {
        setListDepartment(options);
      }
    } catch (err) {
      console.log('err: ', err);
    }
  };
  const getProduct = async () => {
    try {
      const res = await requestListProduct({});
      const options = res?.data?.map(item => ({
        ...item,
        label: item.name,
        value: item.id,
      }));
      if (options) {
        setListProduct(options);
      }
    } catch (err) {
      console.log('err: ', err);
    }
  };

  const getSourceCustomers = async () => {
    try {
      const { data } = await requestGetCustomerSource();

      setSourceCustomers(data || []);
    } catch (error) {
      console.log('Error getSourceCustomers: ', { error });
    }
  };

  const getEVoucherList = async () => {
    try {
      const res = await requestEVoucherList();

      setListEVoucher(res.data);
    } catch (err) {
      console.log('Get list e-voucher err', err);
    }
  };

  return (
    <>
      <Row gutter={(16, 16)} style={{ marginBottom: 10 }}>
        <Col xxl={4} xl={6} lg={8} sm={12} xs={24}>
          <Input.Search
            placeholder="Khách hàng, số điện thoại"
            allowClear
            onSearch={searchKey =>
              handleChangeOpportunitySearchParam('search', searchKey)
            }
            onChange={e =>
              handleChangeOpportunitySearchParam('search', e.target.value)
            }
            style={{ width: '100%', marginTop: 10 }}
            value={params?.search}
          />
        </Col>

        <Col xxl={4} xl={6} lg={6} sm={12} xs={24}>
          <Select
            style={{ width: '100%', marginTop: 10 }}
            mode="multiple"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return rmVN(option.children).indexOf(rmVN(input)) >= 0;
            }}
            placeholder="Nguồn khách hàng"
            allowClear
            maxTagCount={1}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu!</span>}
              />
            }
            onChange={value => {
              handleChangeOpportunitySearchParam('customer_source', value);
            }}
            value={params?.customer_source || []}
          >
            {sourceCustomers.map(source => (
              <Option key={source.value} value={source.value}>
                {source.value}
              </Option>
            ))}
          </Select>
        </Col>

        <Col xxl={4} xl={6} lg={8} sm={12} xs={24}>
          <Select
            style={{ width: '100%', marginTop: 10 }}
            showSearch
            allowClear
            mode="multiple"
            maxTagCount={1}
            placeholder="Sale chăm sóc"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return rmVN(option.children).indexOf(rmVN(input)) >= 0;
            }}
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu!</span>}
              />
            }
            onChange={value => {
              handleChangeOpportunitySearchParam('telesale_id', value);
              setIdTelesale(value);
            }}
            value={params.telesale_id ? params.telesale_id : undefined}
          >
            {listTelesale
              ? listTelesale.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.full_name}
                  </Option>
                ))
              : ''}
          </Select>
        </Col>
        <Col xxl={4} xl={6} lg={6} sm={12} xs={24}>
          <Select
            style={{ width: '100%', marginTop: 10 }}
            showSearch
            allowClear
            mode="multiple"
            maxTagCount={1}
            placeholder="Phòng ban"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return rmVN(option.children).indexOf(rmVN(input)) >= 0;
            }}
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu!</span>}
              />
            }
            onChange={value => {
              handleChangeOpportunitySearchParam('department_id', value);
              setIdDepartment(value);
            }}
            value={params?.department_id ? params?.department_id : undefined}
          >
            {listDepartment
              ? listDepartment.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))
              : ''}
          </Select>
        </Col>
        <Col xxl={4} xl={6} lg={6} sm={12} xs={24}>
          <Select
            style={{ width: '100%', marginTop: 10 }}
            showSearch
            allowClear
            placeholder="Trạng thái cơ hội"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu!</span>}
              />
            }
            onChange={value => {
              handleChangeOpportunitySearchParam('status', value || '');
            }}
            value={params?.status ? params?.status : null}
          >
            {LIST_OPPORTUNITY_STATUS.map(statusOpportunity => (
              <Option key={statusOpportunity.id} value={statusOpportunity.id}>
                {statusOpportunity.name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xxl={4} xl={6} lg={6} sm={12} xs={24}>
          <Select
            style={{ width: '100%', marginTop: 10 }}
            showSearch
            allowClear
            mode="multiple"
            placeholder="Sản phẩm"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return rmVN(option.children).indexOf(rmVN(input)) >= 0;
            }}
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu!</span>}
              />
            }
            onChange={value => {
              handleChangeOpportunitySearchParam('product_id', value);
            }}
            value={params?.product_id ? params?.product_id : undefined}
          >
            {listProduct
              ? listProduct.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))
              : ''}
          </Select>
        </Col>

        <Col xxl={4} xl={6} lg={6} sm={12} xs={24}>
          <Select
            style={{ width: '100%', marginTop: 10 }}
            // mode="multiple"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return (
                rmVN(option.children.props.tagName).indexOf(rmVN(input)) >= 0
              );
            }}
            placeholder="Loại cơ hội"
            allowClear
            maxTagCount={1}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu!</span>}
              />
            }
            onChange={value => {
              handleChangeOpportunitySearchParam('type_id', value || '');
            }}
            value={params?.type_id ? params?.type_id : null}
          >
            {typeList.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Col>

        <Col xxl={4} xl={6} lg={6} sm={12} xs={24}>
          <Select
            style={{ width: '100%', marginTop: 10 }}
            mode="multiple"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return (
                rmVN(option.children.props.tagName).indexOf(rmVN(input)) >= 0
              );
            }}
            placeholder="Phân loại"
            allowClear
            maxTagCount={1}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu!</span>}
              />
            }
            onChange={value => {
              handleChangeOpportunitySearchParam('tag_id', value);
            }}
            value={params?.tag_id ? params?.tag_id : undefined}
          >
            {tags.map((item, index) => (
              <Option key={index} value={item.value}>
                <TagCustom tagName={item.label} tagColor={item.color} />
              </Option>
            ))}
          </Select>
        </Col>

        <Col xxl={4} xl={6} lg={6} sm={12} xs={24}>
          <Select
            showSearch
            style={{ width: '100%', marginTop: 10 }}
            mode=""
            optionFilterProp="children"
            placeholder="Chương trình giảm giá"
            allowClear
            maxTagCount={1}
            filterOption={(input, option) => {
              return option.props.children
                .trim()
                .toLowerCase()
                .includes(input.trim().toLowerCase());
            }}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu!</span>}
              />
            }
            onChange={value => {
              handleChangeOpportunitySearchParam('e_voucher_config_id', value);
            }}
            value={
              params?.e_voucher_config_id ? params?.e_voucher_config_id : []
            }
          >
            {eVoucherNameList?.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Col>

        {/* </Row>
      <Row gutter={(16, 16)}> */}
        <Col xxl={4} xl={6} lg={6} sm={12} xs={24}>
          <Tooltip title={'Thời gian chuyển trạng thái'} trigger={'hover'}>
            <RangePicker
              allowClear
              style={{ width: '100%', marginTop: 10 }}
              placeholder={['Từ ngày', 'đến ngày']}
              format="YYYY/MM/DD"
              renderExtraFooter={() => (
                <b style={{ fontSize: 16 }}>Thời gian chuyển trạng thái</b>
              )}
              value={[
                params?.fromDate ? moment(params?.fromDate, 'YYYY/MM/DD') : '',
                params?.toDate ? moment(params?.toDate, 'YYYY/MM/DD') : '',
              ]}
              onChange={(date, dateString) => {
                mixpanel.track(
                  `filter opportunity from_modified_date: ${
                    date ? moment(date[0]).format('YYYY/MM/DD') : ''
                  } /to_modified_date: ${
                    date ? moment(date[1]).format('YYYY/MM/DD') : ''
                  }`
                );
                if (date !== null) {
                  setSearchParamsInUrlVer2({
                    fromDate: moment(date[0]).format('YYYY/MM/DD'),
                    toDate: moment(date[1]).format('YYYY/MM/DD'),
                    page: 1,
                  });
                  setParams({
                    ...params,
                    fromDate: moment(date[0]).format('YYYY/MM/DD'),
                    toDate: moment(date[1]).format('YYYY/MM/DD'),
                    page: 1,
                  });
                } else {
                  setSearchParamsInUrlVer2({
                    fromDate: '',
                    toDate: '',
                    page: 1,
                  });
                  setParams({
                    ...params,
                    fromDate: '',
                    toDate: '',
                    page: 1,
                  });
                }
              }}
            />
          </Tooltip>
        </Col>
        <Col xxl={4} xl={6} lg={6} sm={12} xs={24}>
          <Tooltip title={'Thời gian hợp đồng hết hạn'} trigger={'hover'}>
            <RangePicker
              allowClear
              style={{ width: '100%', marginTop: 10 }}
              placeholder={['Từ ngày', 'đến ngày']}
              format={'YYYY/MM/DD'}
              renderExtraFooter={() => (
                <b style={{ fontSize: 16 }}>Thời gian hợp đồng hết hạn</b>
              )}
              value={[
                params?.fromDateContractExp
                  ? moment(params?.fromDateContractExp, 'YYYY/MM/DD')
                  : '',
                params?.toDateContractExp
                  ? moment(params?.toDateContractExp, 'YYYY/MM/DD')
                  : '',
              ]}
              onChange={(date, dateString) => {
                mixpanel.track(
                  `filter opportunity from_contract_exp_date: ${
                    date ? moment(date[0]).format('YYYY/MM/DD') : ''
                  } /to_contract_exp_date: ${
                    date ? moment(date[1]).format('YYYY/MM/DD') : ''
                  }`
                );
                if (date !== null) {
                  setSearchParamsInUrlVer2({
                    fromDateContractExp: moment(date[0]).format('YYYY/MM/DD'),
                    toDateContractExp: moment(date[1]).format('YYYY/MM/DD'),
                    page: 1,
                  });

                  setParams({
                    ...params,
                    fromDateContractExp: moment(date[0]).format('YYYY/MM/DD'),
                    toDateContractExp: moment(date[1]).format('YYYY/MM/DD'),
                    page: 1,
                  });
                } else {
                  setSearchParamsInUrlVer2({
                    fromDateContractExp: '',
                    toDateContractExp: '',
                    page: 1,
                  });

                  setParams({
                    ...params,
                    fromDateContractExp: '',
                    toDateContractExp: '',
                    page: 1,
                  });
                }
              }}
            />
          </Tooltip>
        </Col>
        <Col xxl={4} xl={6} lg={6} sm={12} xs={24}>
          <Tooltip title={'Ngày tạo'} trigger={'hover'}>
            <RangePicker
              allowClear
              style={{ width: '100%', marginTop: 10 }}
              placeholder={['Từ ngày', 'đến ngày']}
              format={'YYYY/MM/DD'}
              renderExtraFooter={() => <b style={{ fontSize: 16 }}>Ngày tạo</b>}
              value={[
                params?.fromDateCreate
                  ? moment(params?.fromDateCreate, 'YYYY/MM/DD')
                  : '',
                params?.toDateCreate
                  ? moment(params?.toDateCreate, 'YYYY/MM/DD')
                  : '',
              ]}
              onChange={(date, dateString) => {
                mixpanel.track(
                  `filter opportunity from_create_date: ${
                    date ? moment(date[0]).format('YYYY/MM/DD') : ''
                  } /to_create_date: ${
                    date ? moment(date[1]).format('YYYY/MM/DD') : ''
                  }`
                );
                if (date !== null) {
                  setSearchParamsInUrlVer2({
                    fromDateCreate: moment(date[0]).format('YYYY/MM/DD'),
                    toDateCreate: moment(date[1]).format('YYYY/MM/DD'),
                    page: 1,
                  });

                  setParams({
                    ...params,
                    fromDateCreate: moment(date[0]).format('YYYY/MM/DD'),
                    toDateCreate: moment(date[1]).format('YYYY/MM/DD'),
                    page: 1,
                  });
                } else {
                  setSearchParamsInUrlVer2({
                    fromDateCreate: '',
                    toDateCreate: '',
                    page: 1,
                  });

                  setParams({
                    ...params,
                    fromDateCreate: '',
                    toDateCreate: '',
                    page: 1,
                  });
                }
              }}
            />
          </Tooltip>
        </Col>
      </Row>
    </>
  );
}

export default Filter;
