import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '@styles/Settings.css';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  FormControl,
  Button,
  Modal,
  FormGroup,
  Table,
} from 'react-bootstrap';
import { getListHotline } from '@src/redux/actions';
import {
  requestGetListHotline,
  requestSaleList,
  requestUpdateHotline,
  requestGetHotlineInfo,
} from '@constants/Api';
import { LIST_TELECOM } from '@constants/Constant';
import { Empty, Pagination } from 'antd';
import reactotron from 'reactotron-react-js';
import { notifyFail, notifySuccess } from '@utils/notify';
import 'antd/dist/antd.css';
import { Select, Radio } from 'antd';
import Loading from '@src/components/Loading';

const { Option } = Select;

const optionsListTelecom = [],
  temp = LIST_TELECOM.map(value => {
    const countTelecom = optionsListTelecom.push(
      <Option key={value}>{value}</Option>
    );
  });

// var children = [];

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};
class Hotline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listHotline: [],
      searchHotline: '',
      active_page: 1,
      telesales: [],
      sale_list: [],
      modalEdit: false,
      optionsTelesale: [],
      telesaleSelected: [],
      telesale: 0,
      hotline_id: '',
      unSelectedTelesales: [],
      defaultValue: [],
      value: [],
      isLoading: false,

      children: [],
      paging: {
        total: 0,
        current: 1,
        pageSize: 0,
      },
    };
  }

  componentDidMount() {
    this.requestSaleList();
    this.requestGetListHotline();
  }

  async requestGetListHotline() {
    const { searchHotline } = this.state;
    console.log('search', this.state);
    try {
      const { data, pagging } = await requestGetListHotline({
        enterprise_id: 11,
        search: searchHotline,
        page: this.state.active_page,
      });

      this.setState({
        ...this.state,
        listHotline: data,
        isLoading: false,
        paging: {
          total: pagging.totalItemCount,
          current: pagging.page,
          pageSize: pagging.limit,
        },
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async requestSaleList() {
    try {
      const res = await requestSaleList({
        search: '',
        province_id: '',
        status: '',
        work_type: '',
        active: '',
        type: 1,
        page: 1,
        enterprise_id: '',
      });
      let telesales = [];
      for (var i = 0; i < res?.data?.length; i++) {
        telesales.push(
          <Option key={res?.data[i]?.id} label={res?.data[i]?.full_name}>
            {res?.data[i]?.full_name}
          </Option>
        );
      }
      this.setState({
        ...this.state,
        sale_list: res?.data,
        children: telesales,
      });
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  async requestGetHotlineInfo(id, value) {
    try {
      const res = await requestGetHotlineInfo({ enterprise_id: '', id: id });
      let telesaleFilter =
        this.state.sale_list?.length &&
        this.state.sale_list.filter(item =>
          res?.data?.listTelesale.includes(item?.id.toString())
        );

      this.setState({
        ...this.state,
        defaultValue: res?.data?.listTelesale,
        value: res?.data?.listTelesale,
        telesale: res?.data?.telesale,
        modalEdit: true,
        hotline_id: id,
        telesaleSelected: telesaleFilter,
        hotline: value,
      });
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  async updateHotline() {
    const {
      hotline_id,
      unSelectedTelesales,
      telesale,
      defaultValue,
      value,
      telesaleSelected,
    } = this.state;
    console.log({ hotline_id, unSelectedTelesales, telesale, value });
    var telesales = [];
    const temp = defaultValue?.forEach(value => {
      if (value == telesale) {
        telesales.push({ telesale_id: value, is_default: 1 });
      } else {
        telesales.push({ telesale_id: value, is_default: 0 });
      }
    });

    if (telesale != 0) {
      this.setState({
        ...this.state,
        isLoading: true,
      });
      try {
        const listSale =
          telesaleSelected?.length >= 0
            ? telesaleSelected.map(u => ({
                telesale_id: u.id,
                is_default: u.id == telesale ? 1 : 0,
              }))
            : [];

        console.log({
          listSale,
        });
        await requestUpdateHotline({
          id: hotline_id,
          telesales: listSale,
        });
        this.setState(
          {
            ...this.state,
            modalEdit: false,
            optionsTelesale: [],
            telesaleSelected: [],
            telesale: '',
            hotline_id: '',
            unSelectedTelesales: [],
            defaultValue: [],
            value: [],
            isLoading: false,
          },
          () => notifySuccess('Cập nhật thành công')
        );
        this.requestGetListHotline('');
      } catch (err) {
        this.setState(
          {
            ...this.state,
            isLoading: false,
          },
          () => notifyFail(err.msg)
        );
      }
    } else {
      notifyFail('Vui lòng chọn một sale cố định');
    }
  }

  handleChangeModal = (field, value) => {
    this.setState({
      ...this.state,
      [field]: value,
      telesaleSelected: [],
      telesale: '',
      hotline_id: '',
      unSelectedTelesales: [],
      defaultValue: [],
      value: [],
    });
  };

  handleChangeHotlineSearch = search => {
    console.log(search);
    this.setState(
      {
        searchHotline: search,
        active_page: 1,
      },
      () => {
        this.requestGetListHotline();
      }
    );
  };

  handleChangeModalField = (field, value) => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        [field]: value,
      },
    });
  };

  handleChange = value => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        telecom: `${value}`,
      },
    });
  };

  handleChangeSelect = value => {
    if (this.state?.hotline?.type === 'ity') {
      value = [value];
    } else {
      if (value?.includes('all')) {
        value = this.state.children?.map(item => item?.key);
      }
    }

    const { telesale } = this.state;
    var telesales = [];
    const temp = value?.forEach(item => {
      if (item == telesale) {
        telesales.push({ telesale_id: item, is_default: 1 });
      } else {
        telesales.push({ telesale_id: item, is_default: 0 });
      }
    });
    console.log('telesales', telesales);

    let telesaleFilter =
      this.state.sale_list?.length &&
      this.state.sale_list.filter(item => value.includes(item?.id.toString()));

    if (this.state?.hotline?.type === 'ity') {
      // this.handleChangeTelesaleWhenIty(telesaleFilter?.[0]?.id);
      let telesalesIty = [];
      telesalesIty.push({
        telesale_id: telesaleFilter?.[0]?.id,
        is_default: 1,
      });
      var telesaleFilterIty = telesaleFilter?.filter(
        item => item.id != telesaleFilter?.[0]?.id
      );
      for (var v of telesaleFilterIty) {
        telesales.push({ telesale_id: v.id, is_default: 0 });
      }
      this.setState({
        ...this.state,
        telesale: telesaleFilter?.[0]?.id,
        telesaleSelected: telesaleFilter,
        unSelectedTelesales: telesales,
        value,
      });
    } else {
      this.setState({
        ...this.state,
        telesaleSelected: telesaleFilter,
        unSelectedTelesales: telesales,
        value,
      });
    }
  };

  handleChangeTelesale = e => {
    const { telesaleSelected } = this.state;
    var telesales = [];
    telesales.push({ telesale_id: e.target.value, is_default: 1 });
    var telesaleFilter = telesaleSelected?.filter(
      item => item.id != e.target.value
    );
    for (var v of telesaleFilter) {
      telesales.push({ telesale_id: v.id, is_default: 0 });
    }
    this.setState({
      ...this.state,
      telesale: e.target.value,
      unSelectedTelesales: telesales,
    });
  };

  EditModal = () => {
    const {
      hotline,
      modalEdit,
      telesaleSelected,
      telesale,
      defaultValue,
      value,
      children,
    } = this.state;

    reactotron.log(children);
    return (
      <Modal
        show={modalEdit}
        onHide={() => this.handleChangeModal('modalEdit', false)}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton className="bg-blue">
          <h5 className="text-light pb-0">Cập nhật Hotline</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Chọn Sale phụ trách</label>
            </Row>
            <Row className="modal-row">
              <Select
                mode={hotline?.type === 'ity' ? 'single' : 'multiple'}
                allowClear
                style={{ width: '100%' }}
                placeholder="Chọn telesale phụ trách"
                value={value}
                onChange={this.handleChangeSelect}
                optionFilterProp="label"
              >
                {hotline?.type === 'ity'
                  ? children
                  : [
                      <Option key="all" label="Chọn tất cả">
                        Chọn tất cả
                      </Option>,
                      ...children,
                    ]}
              </Select>
            </Row>
          </FormGroup>
          {telesaleSelected?.length > 0 ? (
            <FormGroup className="text-left">
              <Row className="modal-row">
                <label>Chọn một Sale cố định</label>
              </Row>
              <Row className="modal-row">
                <Radio.Group
                  onChange={this.handleChangeTelesale}
                  value={telesale}
                >
                  {telesaleSelected?.map(value => (
                    <Radio style={radioStyle} value={value.id}>
                      {value?.full_name}
                    </Radio>
                  ))}
                </Radio.Group>
              </Row>
            </FormGroup>
          ) : (
            ''
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            disabled={this.state.disable}
            variant="success"
            className="text-light"
            onClick={() => {
              this.updateHotline();
            }}
          >
            Cập nhật
          </Button>
          <Button
            variant="danger"
            className="text-light"
            onClick={() => this.handleChangeModal('modalEdit', false)}
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderBody() {
    return (
      <>
        <Col sm={12} md={12}>
          <Row>
            <Col
              sm={12}
              className="d-flex justify-content-between"
              style={{ paddingLeft: '20px' }}
            >
              <h5>Hotline</h5>
            </Col>
          </Row>
        </Col>
        <Row justify-content-between style={{ padding: '0 20px' }}>
          <Col md={4}>
            <FormControl
              type="text"
              id=""
              placeholder="Nhập hotline"
              value={this.state.searchHotline}
              onChange={e => this.handleChangeHotlineSearch(e.target.value)}
            />
          </Col>
          <Col md={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {/* <Button
              variant="success"
              className="text-light"
              onClick={() => this.handleChangeModal('modalAddHotline', true)}
            >
              Thêm mới
            </Button> */}
          </Col>
        </Row>
        <Col sm={12} md={12}>
          <div className="row">
            <Col
              sm={10}
              md={12}
              style={{
                padding: '0px',
                marginTop: '20px',
              }}
            >
              <Row className="table-wrap mx-4">
                <Table striped bordered hover style={{ textAlign: 'center' }}>
                  <thead>
                    <th>STT</th>
                    <th>Đầu số</th>
                    <th>Số lần gọi</th>
                    <th>Các nhà mạng có thể gọi</th>
                    <th>Trạng thái</th>
                    <th></th>
                  </thead>
                  <tbody>{this.renderTableData()}</tbody>
                </Table>
              </Row>
            </Col>
          </div>
          <Pagination
            {...this.state.paging}
            onChange={page =>
              this.setState({ active_page: page }, () => {
                this.requestGetListHotline();
              })
            }
          />
        </Col>
      </>
    );
  }

  renderTableData() {
    const { listHotline, listCompany, paging } = this.state;
    return (
      <>
        {listHotline?.length > 0 ? (
          listHotline?.map((value, index) => (
            <tr>
              <td>{(paging.current - 1) * paging.pageSize + index + 1}</td>
              <td>{value?.outbound_phone || '--'}</td>
              <td>{value?.count_to_change || '--'}</td>
              <td>{value?.telecom || '--'}</td>
              <td>
                {value?.is_active == 1 ? 'Đang hoạt động' : 'Ngừng hoạt động'}
              </td>
              <td>
                <i
                  className="btnEdit fa fa-fw fa-edit"
                  onClick={() => this.requestGetHotlineInfo(value?.id, value)}
                ></i>
              </td>
            </tr>
          ))
        ) : (
          <td colSpan={6}>
            <Empty description="Không có dữ liệu"></Empty>
          </td>
        )}
      </>
    );
  }

  render() {
    const { isLoading } = this.state;
    return (
      <>
        {isLoading && <Loading />}
        {this.renderBody()}
        {this.EditModal()}
      </>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Hotline));
