import React, { Component } from 'react';
import '@styles/Loading.css';
import { Spin, Space } from 'antd';
export default class Loading extends Component {
  render() {
    const tip = this.props.tip || 'Đang tải...';
    return (
      <div className={`loader-container bg-${this.props.color}`}>
        {/* <div class="spinner-border text-danger loader" role="status">
                    <span class="sr-only">Loading...</span>
                </div> */}
        <Spin size="large" tip={tip} className="" />
      </div>
    );
  }
}
