import { CALL_ERROR_CODE, TELECOM_CODE } from '@constants/Constant';
import { notifyFail } from './notify';

export const switchCallErrorCode = (code, message) => {
  switch (code) {
    case CALL_ERROR_CODE.FROM_NUMBER_NOT_FOUND:
      return 'Không tìm thấy hotline!';

    case CALL_ERROR_CODE.FROM_NUMBER_NOT_BELONG_YOUR_ACCOUNT:
      return 'Bạn không được quyền sử dụng hotline này!';

    case CALL_ERROR_CODE.FROM_NUMBER_OR_TO_NUMBER_INVALID_FORMAT:
    case CALL_ERROR_CODE.FROM_NUMBER_NOT_BELONG_YOUR_PROJECT:
      return 'Số điện thoại hoặc hotline không hợp lệ!';

    case CALL_ERROR_CODE.NOT_ENOUGH_MONEY:
      return 'Tải khoản của bạn không đủ để thực hiện cuộc gọi này!';

    case CALL_ERROR_CODE.TO_NUMBER_INVALID:
      return 'Số điện thoại không hợp lệ!';

    case CALL_ERROR_CODE.NOT_INIT:
      return 'Mất kết nối với tổng đài!';

    case CALL_ERROR_CODE.REACH_PLAN_LIMIT:
      return 'Gói cước của bạn đã đạt số phút gọi giới hạn!';

    default:
      return message;
  }
};

export const getCallEndCause = (info) => {
  let obj = {};
  try {
    if (typeof info == 'string') {
      obj = JSON.parse(info);
    } else if (typeof info == 'object') {
      obj = info;
    } else {
      obj = {};
    }
  } catch (e) {
    obj = {};
  }
  const allCauses = {
    USER_END_CALL: '',
    MAX_CONNECT_TIME: '(MAX_CONNECT_TIME) Không thể thưc hiện cuộc gọi.',
    '487 Request Terminated': '(487) Đã ngắt cuộc gọi.',
    '486 Busy Here': '(486) Khách hàng đang bận xin vui lòng liên hệ lại sau.',
    '600 Busy Everywhere': '(600) Có thể người nhận đang bận.',
    '480 Temporarily Unavailable': '(480) Tạm thời không thể kết nối đến số người nhận.',
    TIMEOUT_WAIT_SDP_TO_MAKECALL: '(TIMEOUT_WAIT_SDP_TO_MAKECALL) Không thể thưc hiện cuộc gọi.',
    MEDIA_TIMEOUT: '(MEDIA_TIMEOUT) Không thể thưc hiện cuộc gọi.',
    '408 Request Timeout': '(408) Không thể kết nối đến số người nhận.',
    '488 Not Acceptable Here': '(488) Khách hàng đang bận xin vui lòng liên hệ lại sau.',
    '503 Service Unavailable': '(503 )Không có dịch vụ.',
    CAN_NOT_MAKE_CALL: '(CAN_NOT_MAKE_CALL) Không thể thưc hiện cuộc gọi. Không có dịch vụ.',
    '404 Not Found': '(404) Số điện thoại không đúng hoặc không tồn tại.',
  };

  const endCause = obj?.endCallCause;

  if (!endCause) {
    return null;
  }
  const endCallMessage = allCauses[endCause];
  if (endCallMessage === undefined) {
    return `Không thể thưc hiện cuộc gọi.\nMã lỗi: ${endCause}`;
  }
  if (endCallMessage === '') {
    return;
  }
  const message = allCauses[endCause];
  return message;
};

/**
 *
 * @param {[{outbound_phone, telecom}]} hotlines
 * @param {*} toNumber
 * @returns
 */
export const selectHotlineAuto = (hotlines, toNumber) => {
  if (hotlines.length === 0) {
    notifyFail(switchCallErrorCode(CALL_ERROR_CODE.FROM_NUMBER_NOT_FOUND));
    return '';
  }
  if (hotlines.length === 1) {
    return hotlines[0].outbound_phone;
  }
  const checkPhone = toNumber.slice(0, 3);
  const toNumberBelongsTelcos = TELECOM_CODE.filter((list) => {
    return list.includes(checkPhone);
  });
  const telcos = toNumberBelongsTelcos.length > 0 ? toNumberBelongsTelcos[0] : TELECOM_CODE[0];

  let foundHotlines = hotlines.filter((v) => telcos.includes(v.outbound_phone.slice(0, 3)));
  if (foundHotlines.length <= 0) {
    foundHotlines = hotlines.filter((v) => TELECOM_CODE[0].includes(v.outbound_phone.slice(0, 3)));
  }
  if (foundHotlines.length > 0) {
    return foundHotlines[0].outbound_phone;
  }
  return hotlines[0].outbound_phone;
};

export const selectHotlineAuto_old = (hotlines, toNumber) => {
  if (hotlines.length === 0) {
    return '';
  }
  const checkPhone = toNumber.slice(0, 3);
  let hotlineDefault = hotlines[0].outbound_phone;
  if (hotlines.length > 1) {
    TELECOM_CODE.forEach((code, index, array) => {
      if (code.includes(checkPhone)) {
        const hotline = hotlines.find((hotline) => code.includes(hotline.outbound_phone.slice(0, 3)));
        if (hotline) {
          hotlineDefault = hotline.outbound_phone;
        }
      } else {
        const hotline = hotlines.find((hotline) => array[0].includes(hotline.outbound_phone.slice(0, 3)));
        if (hotline) {
          hotlineDefault = hotline.outbound_phone;
        }
      }
    });
  }
  return hotlineDefault;
};
