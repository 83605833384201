import Wrapper from '@components/ui/wrapper/Wrapper';
import { AudioStyle, DescriptionCustomInteractionHistory } from '@styles/Style';
import { Avatar, Button, Col, Descriptions, Empty, Row } from 'antd';
import React, { useState } from 'react';
import InteractionHistoryHeader from './InteractionHistoryHeader';
import { faArrowAltCircleDown } from '@fortawesome/fontawesome-free-regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CaretDownOutlined, CloseOutlined } from '@ant-design/icons';
import ModalReviewCall from '@screens/Enterprise/Call/components/ModalReviewCall';
import { formatPrice, formatTime, randomColor } from '@constants/funcHelper';
import { NOT_UDATE_YET_STRING, ROLE_WEB_SALE } from '@constants/Constant';
import { convertSecondtoTime } from './func';

const CheckCircleOutlined = () => (
  <svg
    width="18px"
    height="18px"
    fill="white"
    viewBox="0 0 1024 1024"
    style={{ margin: '0 3px 3px 0' }}
  >
    <path d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
    <path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
  </svg>
);

export default function Call({ interactionHistory, getData, user }) {
  const [viewMore, setViewMore] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const handleCloseModal = () => {
    setIsVisible(false);
  };
  const getPointColor = inputPoint => {
    if (inputPoint < 0) {
      return 'tomato';
    } else {
      return 'green';
    }
  };
  const reviewerName = interactionHistory?.created_by?.full_name
    ? interactionHistory?.created_by?.full_name.trim().split(' ')
    : null;
  return (
    <>
      <InteractionHistoryHeader interactionHistory={interactionHistory} />
      <Row justify="end" align="bottom">
        <Col span={22}>
          <DescriptionCustomInteractionHistory column={1}>
            <Descriptions.Item label="Trạng thái">
              {interactionHistory.status_call
                ? interactionHistory.status_call
                : 'Chưa cập nhật'}
            </Descriptions.Item>

            <Descriptions.Item label="File ghi âm">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AudioStyle controls autoplay className="audio-element">
                  <source src={interactionHistory?.content_audio_url}></source>
                </AudioStyle>
                {/* <div>
                  <FontAwesomeIcon icon={faArrowAltCircleDown} size="lg" />
                </div> */}
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="Thời gian gọi">
              <div>
                {convertSecondtoTime(interactionHistory?.call_duration)}
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="Ghi chú cuộc gọi">
              {interactionHistory.call_note
                ? interactionHistory.call_note
                : 'Chưa cập nhật'}
            </Descriptions.Item>
          </DescriptionCustomInteractionHistory>
        </Col>

        <Col span={2}>
          <a
            onClick={() => setViewMore(!viewMore)}
            style={{ marginBottom: '10px' }}
          >
            {!viewMore && (
              <div style={{ color: 'green' }}>
                Chi tiết <CaretDownOutlined />
              </div>
            )}
          </a>
        </Col>
      </Row>

      {viewMore && (
        <Wrapper>
          <Row gutter={[16, 16]}>
            <Col span={14}>
              {/* <Wrapper> */}
              <h5>Phiếu kết quả</h5>
              {interactionHistory.result_cards.length ? (
                interactionHistory.result_cards.map((item, index) => {
                  return (
                    <div key={index}>
                      <b>
                        {index + 1}. {item.questions}
                      </b>
                      <div style={{ margin: '0 0 0 20px' }}>
                        {item.answer ? item.answer : 'Chưa cập nhật'}
                      </div>
                    </div>
                  );
                })
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Không có dữ liệu"
                />
              )}
              {/* </Wrapper> */}
            </Col>
            <Col span={10}>
              {/* <Wrapper> */}
              <Row justify="space-between">
                <div>
                  <h5>Đánh giá cuộc gọi</h5>
                  {interactionHistory?.call_reviews?.length > 0
                    ? formatTime(
                        interactionHistory.call_reviews?.[0].created_at
                      )
                    : null}
                </div>
                {user?.role_id !== ROLE_WEB_SALE ? (
                  <Button
                    type="primary"
                    onClick={() => {
                      setIsVisible(true);
                    }}
                  >
                    <CheckCircleOutlined />
                    Đánh giá
                  </Button>
                ) : null}
              </Row>
              {interactionHistory?.call_reviews?.length > 0 ? (
                <>
                  <Row>
                    - Người đánh giá:&nbsp;&nbsp;
                    {interactionHistory?.created_by?.profile_image ? (
                      <Avatar
                        src={`${interactionHistory?.created_by?.profile_image}`}
                      />
                    ) : reviewerName?.length > 0 ? (
                      <Avatar
                        style={{
                          backgroundColor: randomColor(
                            reviewerName[reviewerName.length - 1]
                              .charAt(0)
                              ?.toUpperCase()
                          ),
                          marginRight: '5px',
                        }}
                        size={24}
                      >
                        <span id="avaCus">
                          {reviewerName[reviewerName.length - 1]
                            .charAt(0)
                            ?.toUpperCase()}
                        </span>
                      </Avatar>
                    ) : null}
                    {interactionHistory?.created_by?.full_name}
                  </Row>
                  <Row style={{ width: '100%' }}>
                    - Đánh giá:
                    {interactionHistory?.call_reviews?.length > 0
                      ? interactionHistory?.call_reviews?.map((item, index) => (
                          <Row
                            style={{ marginLeft: '20px', width: '100%' }}
                            justify="space-between"
                          >
                            <div style={{ width: '85%', fontWeight: 600 }}>
                              {item.call_review_config.name}
                            </div>
                            <div
                              style={{
                                color: getPointColor(
                                  item.call_review_config.point
                                ),
                                width: '15%',
                                textAlign: 'end',
                                fontWeight: 600,
                              }}
                            >
                              {formatPrice(item.call_review_config.point)}
                            </div>
                          </Row>
                        ))
                      : null}
                  </Row>
                  <Row style={{ width: '100%' }} justify="space-between">
                    - Tổng điểm:
                    <div
                      style={{
                        color: getPointColor(
                          interactionHistory?.call_review_point
                        ),
                        textAlign: 'end',
                        fontWeight: 600,
                      }}
                    >
                      {formatPrice(interactionHistory?.call_review_point)}
                    </div>
                  </Row>
                  <Row>
                    - Ghi chú:&nbsp;
                    {interactionHistory?.call_review_note
                      ? interactionHistory?.call_review_note
                      : NOT_UDATE_YET_STRING}
                  </Row>
                </>
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Không có dữ liệu"
                />
              )}
              {/* </Wrapper> */}
            </Col>
          </Row>
          <Row justify="end" align="bottom">
            <Col span={22}></Col>
            <Col span={2}>
              <a onClick={() => setViewMore(!viewMore)}>
                {viewMore && (
                  <div style={{ color: 'red' }}>
                    Đóng <CloseOutlined />
                  </div>
                )}
              </a>
            </Col>
          </Row>
          <ModalReviewCall
            isVisible={isVisible}
            handleCloseModal={handleCloseModal}
            callId={interactionHistory?.call_id}
            getData={getData}
            data={interactionHistory}
          />
        </Wrapper>
      )}
    </>
  );
}
