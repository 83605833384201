import { getAxios, handleResult } from '@constants/Api';

export const requestGetCustomerDataType = ({ type }) => {
  return handleResult(getAxios.get(`/job/dataTypeCustomer?type=${type}`));
};

export const requestGetOptionsWithCustomerDataType = ({ code }) => {
  return handleResult(getAxios.get(`/customer/dataFilter?code=${code}`));
};

export const requestGetCustomerSource = () => {
  return handleResult(
    getAxios.get(`/customer/dataCustomerSource?code=customer_source`)
  );
};

/**
 * requestValidateApiKey
 * @param {{key, job_id}} payload
 * @returns Promise
 */
export const requestValidateApiKey = payload => {
  return handleResult(getAxios.put(`job/validateApiKey`, payload));
};

/**
 * requestGetReportCallInJob
 * @param {{job_id, telesale_id, department_id, from, to, page, limit }} params
 * @returns Promise
 */
export function requestGetReportCallInJob(params, option = {}) {
  return handleResult(
    getAxios.get(`job/reportCall`, {
      params: new URLSearchParams({ ...params, page: 1 }),
      ...option,
    })
  );
}

/**
 * requestGetTelemarketingInJob
 * @param {search, province_id, status, type, job_id, sub_job_id, page, limit} params
 * @returns Promise
 */
export function requestGetTelemarketingInJob(params) {
  return handleResult(
    getAxios.get('sale/list', {
      params: new URLSearchParams(params),
    })
  );
}

//clone từ api requestJobFilter
export const requestSubJobFilter = (
  payload = {
    enterprise_id: '',
    telesale_id: '',
    job_category_id: '',
    limit: '',
  }
) => {
  return handleResult(
    getAxios.get(
      `filter/subJobs?enterprise_id=${payload.enterprise_id}` +
        `&telesale_id=${payload.telesale_id}` +
        `&limit=${payload.limit}` +
        `&job_category_id=${payload.job_category_id}`,
      { ...payload }
    )
  );
};

export const requestSubJobFilterUpdate = (
  payload = {
    enterprise_id: '',
    telesale_id: '',
    job_category_id: '',
    limit: '',
  }
) => {
  return handleResult(
    getAxios.get(
      `filter/jobs?enterprise_id=${payload.enterprise_id}` +
        `&telesale_id=${payload.telesale_id}` +
        `&limit=${payload.limit}` +
        `&job_category_id=${payload.job_category_id}`,
      { ...payload }
    )
  );
};
/**
 * requestDeleteCustomerInGroup
 * @param {jobId} payload
 * @returns Promise
 */
export const requestDeleteCustomerInGroup = payload => {
  const { jobId } = payload;

  const data = {
    job_id: jobId,
  };

  return handleResult(getAxios.post(`job/deleteCustomerInGroup`, data));
};

export const requestRollBack = payload => {
  return handleResult(getAxios.post(`job/roleBackJobs`, payload));
};

export const requestEVoucherList = () =>
  handleResult(getAxios.get('evoucher/listAllEvoucher?search='));

export const checkIsValidVoucher = payload =>
  handleResult(getAxios.post(`evoucher/usedOpportunity`, payload));
