import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { faLightbulb } from '@fortawesome/fontawesome-free-regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { requestUpdateDataFieldDisplayCustom, requestUpdateDataFieldFilterCustom } from '@constants/Api';
import { notifyFail, notifySuccess } from '@utils/notify';
import mixpanel from 'mixpanel-browser';

DragAndDropConfigurationModal.propTypes = {
  titleModal: PropTypes.string,
  fieldConfig: PropTypes.string,
  visible: PropTypes.bool,
  cancelModal: PropTypes.func,
  listFieldConfigGetAPI: PropTypes.array,
  getDataCustomers: PropTypes.func,
};

export default function DragAndDropConfigurationModal({
  cancelModal,
  visible,
  titleModal,
  fieldConfig,
  listFieldConfigGetAPI,
  user,
}) {
  // const itemsFromBackend = [
  //   { id: 'itemsFromBackend1', content: 'First task' },
  //   { id: 'itemsFromBackend2', content: 'Second task' },
  //   { id: 'itemsFromBackend3', content: 'Third task' },
  //   { id: 'itemsFromBackend4', content: 'Fourth task' },
  //   { id: 'itemsFromBackend5', content: 'Fifth task' },
  // ];

  // const columnsFromBackend = {
  //   columnsFromBackend1: {
  //     name: 'Requested',
  //     items: itemsFromBackend,
  //   },
  //   columnsFromBackend2: {
  //     name: 'To do',
  //     items: [],
  //   },
  // };

  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const [itemsDisplay, setItemsDisplay] = useState(() => {
    //cấu hình hiển thị
    if (fieldConfig === 'displayConfig') {
      const arr = listFieldConfigGetAPI.filter((item, index) => item.display_config === 1);
      const newArr = arr.map((item, index) => ({ ...item, id: String(item.id) }));
      return newArr;
    }
    //cấu hình bộ lọc
    if (fieldConfig === 'filterConfig') {
      const arr = listFieldConfigGetAPI.filter((item, index) => item.filterable === 1);
      const newArr = arr.map((item, index) => ({ ...item, id: String(item.id) }));
      return newArr;
    }
  });

  const [itemNonDisplay, setItemsNonDisplay] = useState(() => {
    //cấu hình hiển thị
    if (fieldConfig === 'displayConfig') {
      const arr = listFieldConfigGetAPI.filter((item, index) => item.display_config === 0);
      const newArr = arr.map((item, index) => ({ ...item, id: String(item.id) }));
      return newArr;
    }
    //cấu hình bộ lọc
    if (fieldConfig === 'filterConfig') {
      const arr = listFieldConfigGetAPI.filter((item, index) => item.filterable === 0);
      const newArr = arr.map((item, index) => ({ ...item, id: String(item.id) }));
      return newArr;
    }
  });

  const displayConfiguration = {
    display: {
      name: 'Thông tin hiển thị',
      items: itemsDisplay || [],
    },
    nonDisplay: {
      name: 'Thông tin khác',
      items: itemNonDisplay || [],
    },
  };

  const onDragEnd = (result, columns, setColumns) => {
    //cấu hình hiển thị
    if (!result.destination) return;
    const { source, destination } = result;
    // if (
    //   fieldConfig === 'displayConfig' &&
    //   source.droppableId === 'display' &&
    //   destination.droppableId === 'nonDisplay'
    // ) {
    //   //danh sách những item không thể ẩn hiển thị
    //   const codesDisplay = ['name', 'modified_date', 'email', 'job_telesale_customers', 'customer_tags'];

    //   const itemsDisplay = columns.display.items;
    //   if (codesDisplay.includes(itemsDisplay[source.index].code)) {
    //     notifyFail('Không thể ẩn thông tin hiển thị mặc định');
    //     return;
    //   }
    // }
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  const [columns, setColumns] = useState(displayConfiguration);

  const handleConfirm = async () => {
    if (fieldConfig === 'displayConfig') {
      mixpanel.track(`displayConfig customer`);
      const colDisplay = columns.display.items.map((item, index) => ({ ...item, display_config: 1 }));
      const colNonDisplay = columns.nonDisplay.items.map((item, index) => ({ ...item, display_config: 0 }));
      const payload = [...colDisplay, ...colNonDisplay].map((item, index) => {
        if (item.id) {
          return {
            ...item,
            id: parseInt(item.id),
            position: index + 1,
          };
        } else {
          return {
            ...item,
            position: index + 1,
          };
        }
      });
      try {
        setIsLoadingButton(true);

        await requestUpdateDataFieldDisplayCustom(payload);
        // notifySuccess(result.data.msg);
        cancelModal();
        // getDataCustomers();
      } catch (error) {
        notifyFail(error.msg);
      } finally {
        setIsLoadingButton(false);
      }
    }
    if (fieldConfig === 'filterConfig') {
      mixpanel.track(`filterConfig customer`);
      const colDisplay = columns.display.items.map((item, index) => ({ ...item, filterable: 1 }));
      const colNonDisplay = columns.nonDisplay.items.map((item, index) => ({ ...item, filterable: 0 }));
      const payload = [...colDisplay, ...colNonDisplay].map((item, index) => ({
        id: parseInt(item.id),
        position: index + 1,
        label: item.label,
        filterable: item.filterable,
      }));

      try {
        setIsLoadingButton(true);
        await requestUpdateDataFieldFilterCustom(payload);
        // await requestUpdateDataFieldFilterCustom([...colDisplay, ...colNonDisplay]);
        // notifySuccess(result.data.msg);
        cancelModal();
        // getDataCustomers();
      } catch (error) {
        notifyFail(error.msg);
      } finally {
        setIsLoadingButton(false);
      }
    }
  };

  return (
    <Modal
      width={600}
      title={titleModal}
      visible={visible}
      onCancel={cancelModal}
      onOk={handleConfirm}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={cancelModal}>
          Huỷ
        </Button>,
        <Button key="submit" type="primary" onClick={handleConfirm} loading={isLoadingButton}>
          Xác nhận
        </Button>,
      ]}
    >
      <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
        <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                key={columnId}
              >
                <div style={{ fontSize: '18px', fontWeight: 500 }}>{column.name}</div>
                <div style={{ margin: 8 }}>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: snapshot.isDraggingOver ? '#ffe7bd' : 'white',
                            padding: '15px 20px',
                            width: 250,
                            // minHeight: 500,
                            border: '1px dashed gray',
                            borderRadius: '10px',
                          }}
                        >
                          {column.items.map((item, index) => {
                            return (
                              <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      // style={{
                                      //   userSelect: 'none',
                                      //   padding: 16,
                                      //   margin: '0 0 8px 0',
                                      //   minHeight: '50px',
                                      //   backgroundColor: snapshot.isDragging ? '#263B4A' : '#456C86',
                                      //   color: 'white',
                                      //   ...provided.draggableProps.style,
                                      // }}
                                      style={{
                                        // userSelect: 'none',
                                        padding: '0px 10px',
                                        margin: '0 0 8px 0',
                                        // minHeight: '50px',
                                        backgroundColor: snapshot.isDragging ? '#fe7a15' : 'white',
                                        color: snapshot.isDragging ? 'white' : 'black',
                                        fontWeight: snapshot.isDragging ? 500 : 400,
                                        border: '1px solid gray',
                                        borderRadius: '6px',
                                        ...provided.draggableProps.style,
                                      }}
                                    >
                                      {/* {item.content} */}
                                      {item.code === 'note_recall' ? 'Ghi chú công việc' : item.label || item.name}
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </div>
            );
          })}
        </DragDropContext>
      </div>
      <div style={{ marginBottom: '0px' }}>
        <FontAwesomeIcon icon={faLightbulb} style={{ marginRight: '5px', color: 'yellowgreen' }} size="2x" />
        <span style={{ color: 'red' }}>Kéo thả để thay đổi thứ tự hiển thị.</span>
      </div>
    </Modal>
  );
}
