import { DescriptionCustomInteractionHistory } from '@styles/Style';
import { Descriptions } from 'antd';
import React from 'react';
import InteractionHistoryHeader from './InteractionHistoryHeader';
import { formatPrice } from '@constants/funcHelper';

const sortData = updatedInfoArr => {
  const currentSort = ['Doanh số', 'Doanh thu', 'Công nợ'];
  let arr = [...updatedInfoArr];

  arr.sort(function (a, b) {
    const indexA = currentSort.indexOf(a.filed);
    const indexB = currentSort.indexOf(b.filed);

    if (indexA === -1 && indexB === -1) {
      return 0;
    } else if (indexA === -1) {
      return 1;
    } else if (indexB === -1) {
      return -1;
    } else {
      return indexA - indexB;
    }
  });

  return arr;
};

const availField = ['Doanh số', 'Doanh thu', 'Công nợ', 'Điểm'];

export default function EditCustomer({ interactionHistory }) {
  return (
    <>
      <InteractionHistoryHeader interactionHistory={interactionHistory} />
      <div style={{ marginLeft: '20px', fontWeight: 500 }}>
        Thông tin cập nhật
      </div>
      <div style={{ marginLeft: '40px' }}>
        <DescriptionCustomInteractionHistory column={1}>
          {interactionHistory.results_update &&
            sortData(interactionHistory.results_update).map((item, index) => {
              if (availField.includes(item?.filed)) {
                return (
                  <Descriptions.Item key={index} label={item.filed}>
                    {formatPrice(item.value)}
                  </Descriptions.Item>
                );
              } else {
                return (
                  <Descriptions.Item key={index} label={item.filed}>
                    {item.value}
                  </Descriptions.Item>
                );
              }
            })}
        </DescriptionCustomInteractionHistory>
      </div>
    </>
  );
}
