import { GET_STRINGEE_CALL } from '@actions/type';

const initialState = {
  data: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_STRINGEE_CALL:
      return {
        ...state,
        data: action.payload,
      };

    default:
      return state;
  }
}
