import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  PauseCircleOutlined,
  RedoOutlined,
  SyncOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';
import Wrapper from '@components/ui/wrapper/Wrapper';
import { changeActive } from '@constants/Api';
import { JOB_STATUS, ROUTER, BASE_URL_IMAGE } from '@constants/Constant';
import { randomColor } from '@constants/funcHelper';
import { getJobDetail } from '@src/redux/actions';
import { notifyFail } from '@utils/notify';
import {
  Avatar,
  Dropdown,
  Empty,
  Menu,
  Modal,
  Progress,
  Tag,
  Tooltip,
} from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { JobNameInTableStyle, TableAntStyle } from '@styles/Style';
import SubNameJob from '@components/SubNameJob';
import mixpanel from 'mixpanel-browser';

export default function SubJob(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const columns = [
    {
      //   width: '20%',
      title: 'Danh sách giao việc',
      dataIndex: '',
      key: '',
      render: (text, record, index) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <JobNameInTableStyle
              onClick={event => {
                event.stopPropagation();
                history.push(ROUTER.JOB_RESULT_CHILD + `/${record.id}`);
              }}
            >
              {record.name}
            </JobNameInTableStyle>
          </div>
          {SubNameJob(record.created_at)}
        </div>
      ),
    },
    {
      width: 170,
      title: 'Tiến độ',
      dataIndex: '',
      key: '',
      render: (text, record, index) => (
        <>
          <Progress
            percent={
              record.countCustomer !== 0
                ? ((record.countCalled / record.countCustomer) * 100).toFixed(1)
                : 0
            }
            strokeWidth={12}
            strokeColor={{
              '0%': '#108ee9',
              '100%': '#87d068',
            }}
          />
          <p>{`${record.countCalled}/${record.countCustomer} khách hàng`}</p>
        </>
      ),
    },
    {
      width: 120,
      title: 'Sales',
      dataIndex: 'profiles',
      key: 'profiles',
      render: profiles => {
        if (profiles) {
          try {
            // let sales = JSON.parse(profiles);
            return (
              <Avatar.Group
                maxCount={2}
                maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
              >
                {profiles.map((sale, index) => {
                  if (sale.full_name !== null && sale.profile_image !== null) {
                    const name = sale.full_name.trim().split(' ');
                    return (
                      <Tooltip
                        key={index}
                        title={sale.full_name}
                        placement="bottom"
                      >
                        <Avatar
                          src={
                            sale.profile_image &&
                            `${BASE_URL_IMAGE}${sale.profile_image}`
                          }
                          style={
                            index >= 7
                              ? { display: 'none' }
                              : {
                                  backgroundColor: randomColor(
                                    name[name.length - 1]
                                      .charAt(0)
                                      ?.toUpperCase()
                                  ),
                                }
                          }
                        >
                          {sale.profile_image
                            ? ''
                            : name[name.length - 1].charAt(0)?.toUpperCase()}
                        </Avatar>
                      </Tooltip>
                    );
                  } else {
                    return (
                      <Avatar
                        key={index}
                        style={{ backgroundColor: '#87d068' }}
                        icon={<UserOutlined />}
                      />
                    );
                  }
                })}
              </Avatar.Group>
            );
          } catch (error) {
            console.log('error: ', error);
          }
        } else {
          return <>--</>;
        }
      },
    },
    {
      width: 160,
      title: 'Tình trạng',
      dataIndex: 'status',
      key: 'status',
      render: value => {
        // let name = '--';
        let tag = {
          color: 'cyan',
          name: '--',
        };
        JOB_STATUS.map(item => {
          if (item.id === value) {
            // name = item.name;
            switch (value) {
              case 1:
                tag = {
                  color: 'warning',
                  name: item.name,
                  icon: <ClockCircleOutlined />,
                };
                break;

              case 2:
                tag = {
                  color: 'processing',
                  name: item.name,
                  icon: <SyncOutlined />,
                };
                break;

              case 3:
                tag = {
                  color: 'success',
                  name: item.name,
                  icon: <CheckCircleOutlined />,
                };
                break;

              default:
                break;
            }
          }
        });
        return (
          <Tag
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
            color={tag.color}
            icon={tag.icon}
          >
            {tag.name}
          </Tag>
        );
      },
    },
    {
      width: 50,
      title: '',
      dataIndex: '',
      key: '',
      render: (text, record, index) => (
        <Dropdown overlay={menu(record)} trigger={['click']}>
          <a className="ant-dropdown-link" onClick={e => e.stopPropagation()}>
            <UnorderedListOutlined />
          </a>
        </Dropdown>
      ),
    },
  ];

  const menu = subJob => {
    return (
      <Menu
        onClick={({ item, key, keyPath, domEvent }) => {
          domEvent.stopPropagation();
          switch (key) {
            case 'edit':
              dispatch(getJobDetail({ id: subJob.id }));
              history.push({
                pathname: ROUTER.JOB_GENERAL_EDIT + `/${subJob.id}`,
                state: {
                  subJobId: subJob.id,
                },
              });
              break;

            case 'pause':
              modalConfirmChangeActiveJob(
                subJob.id,
                subJob.is_active,
                subJob.nsme
              );
              break;

            case 'start':
              modalConfirmChangeActiveJob(
                subJob.id,
                subJob.is_active,
                subJob.nsme
              );
              break;

            case 'detail':
              mixpanel.track(`Go to detail result job: ${subJob.name}`);
              history.push(ROUTER.JOB_RESULT_CHILD + `/${subJob.id}`);
              break;

            default:
              break;
          }
        }}
      >
        {[2, 3].includes(subJob?.job_call_type) ? (
          <>
            <Menu.Item key="detail" icon={<InfoCircleOutlined />}>
              Chi tiết kết quả
            </Menu.Item>
          </>
        ) : (
          <>
            <Menu.Item key="detail" icon={<InfoCircleOutlined />}>
              Chi tiết kết quả
            </Menu.Item>
            <Menu.Item key="edit" icon={<EditOutlined />}>
              Chỉnh sửa
            </Menu.Item>
            {subJob.is_active === 1 && (
              <Menu.Item key="pause" icon={<PauseCircleOutlined />}>
                Tạm ngừng hoạt động
              </Menu.Item>
            )}
            {subJob.is_active === 2 && (
              <Menu.Item key="start" icon={<RedoOutlined />}>
                Khôi phục hoạt động
              </Menu.Item>
            )}
          </>
        )}
      </Menu>
    );
  };

  const modalConfirmChangeActiveJob = (id, isActive, name) => {
    Modal.confirm({
      title: 'Xác nhận',
      icon: <ExclamationCircleOutlined />,
      content:
        isActive === 1
          ? 'Bạn chắc chắn muốn tạm ngừng hoạt động'
          : 'Bạn chắc chắn muốn khôi phục hoạt động!',
      okText: isActive === 1 ? 'Tạm ngừng' : 'Khôi phục',
      cancelText: 'Quay lại',
      onOk: () => handleChangeActive(id, isActive, name),
    });
  };

  const handleChangeActive = async (id, isActive, name) => {
    mixpanel.track(`Change status job: ${name}`);
    try {
      await changeActive({
        isActive: isActive === 1 ? 2 : 1,
        job_id: id,
      });
      props.getData();
    } catch (err) {
      notifyFail(err.msg);
    }
  };

  return (
    <Wrapper>
      <TableAntStyle
        dataSource={props.data.subJobs.reverse()}
        columns={columns}
        bordered={true}
        // scroll={{
        //   // x: '992px',
        //   scrollToFirstRowOnChange: true,
        //   y: '200px',
        // }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>Không có dữ liệu.</span>}
            />
          ),
        }}
        pagination={{
          showSizeChanger: false,
          pageSize: 5,
          // ...paging,
          // onChange: async (page, pageSize) => {
          //   setParams({ ...params, page });
          // },
        }}
      />
    </Wrapper>
  );
}
