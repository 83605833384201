import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  requestCustomerGroupDetail,
  requestFilterList,
  requestCustomerGroupUpdate,
  requestCustomerList,
  requestCustomerGroupRemoveCustomers,
} from '@constants/Api';
import '@styles/Customer.css';
import { Row, Col, FormControl, Button, FormGroup, Form, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { notifyFail, notifySuccess } from '@utils/notify';
import Loading from '@src/components/Loading';
import { Table, TreeSelect } from 'antd';
import { getCustomerList } from '@src/redux/actions';
import { checkAllAttrFilled } from '@utils/checkAllAttrFilled';
import Pagination from '@components/Pagination';
import { CustomerFilter, CustomerList } from '@screens/Enterprise/Customer/components';
import AddCustomersToGroupModal from './modals/AddCustomersToGroupModal';
import * as provinceSelectors from '@redux/selectors/provinceSelectors';
import { ROUTER, STRING, NUMBER } from '@constants/Constant';
import ConfirmDeleteModal from './modals/ConfirmDeleteModal';
import ImportCustomerModal from './modals/ImportCustomerModal';
class EditGroupCustomerScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionLabel: [],
      payload: {
        name: '',
        description: '',
        // customer_ids: [],
      },
      group_id: this.props.match?.params.id,
      isLoading: false,
      deleting_customer_ids: [],
      modalAdd: {
        shown: false,
      },
      modalConfirmDelete: {
        shown: false,
      },
      modalImportCustomers: {
        shown: false,
      },
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    const { id } = this.props.match?.params;
    this.setState({ ...this.state, isLoading: true });
    try {
      const detail = await requestCustomerGroupDetail({ id: id });
      this.setState({
        ...this.state,
        payload: {
          name: detail.data.name,
          description: detail.data.description,
        },
        isLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async updateData() {
    const { id } = this.props.match?.params;
    const { payload } = this.state;
    const check = this.validateForm();
    if (check) {
      try {
        this.setState({ isLoading: true });
        const res = await requestCustomerGroupUpdate({ ...payload, id: id });
        this.setState({ isLoading: false });
        this.props.history.goBack();
        notifySuccess('Cập nhật thông tin thành công!');
      } catch (err) {
        this.setState({ isLoading: false });
        notifyFail(err.msg);
      }
    }
  }

  validateForm() {
    const { payload } = this.state;
    var check = checkAllAttrFilled(payload);
    if (!check) {
      notifyFail('Vui lòng điền đầy đủ thông tin');
      return false;
    }
    return true;
  }

  onSelectedCustomersChanged = (customerIds = []) => {
    this.setState({ deleting_customer_ids: customerIds });
  };
  onFormChange = (field, value) => {
    this.setState({
      payload: {
        ...this.state.payload,
        [field]: value,
      },
    });
  };

  showDeleteCustomerConfirmation = () => {
    this.setState({ modalConfirmDelete: { shown: true } });
  };
  hideDeleteCustomerConfirmation = (callback) => {
    this.setState({ modalConfirmDelete: { shown: false } }, callback);
  };

  showImportModal = () => {
    this.setState({ modalImportCustomers: { shown: true } });
  };
  hideImportModal = (callback) => {
    console.log('hideImportModal');
    this.setState({ modalImportCustomers: { shown: false }, callback });
  };

  handleImportPress = () => {
    this.showImportModal();
  };

  onDeleteCustomerPress = () => {
    this.hideDeleteCustomerConfirmation(() => {
      const { group_id, deleting_customer_ids } = this.state;
      const requestPayload = {
        customer_ids: deleting_customer_ids,
        id: group_id,
      };
      this.setState({ isLoading: true });
      requestCustomerGroupRemoveCustomers(requestPayload)
        .then((res) => {
          this.setState({ isLoading: false, deleting_customer_ids: [] }, () =>
            notifySuccess('Xóa khách hàng khỏi nhóm thành công')
          );
          window.location.reload();
        })
        .catch((err) => {
          this.setState({ isLoading: false }, () => notifyFail(err.msg));
        });
    });
  };

  render() {
    return (
      <>
        {this.state.isLoading ? <Loading /> : null}
        {this.renderBody()}
        {this.renderModal()}
      </>
    );
  }

  renderBody() {
    return (
      <div className="content-wrapper">
        <div className="content-header">
          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 20 }}>
            <Col md className="bg">
              {this.renderTitle()}
            </Col>
          </Row>
          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 20 }}>
            <Col md className="bg">
              <Col md={12}>{this.renderSectionBaseInfoForm()}</Col>
            </Col>
          </Row>

          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
            <Col md className="bg">
              <Col md={12} className="p-0">
                {this.renderSectionCustomerList()}
              </Col>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
  renderModal() {
    return (
      <AddCustomersToGroupModal
        shown={this.state.modalAdd.shown}
        onClose={() => this.setState({ modalAdd: { shown: false } })}
        payload={{ exclude_group_id: this.state.group_id }}
        group_id={this.state.group_id}
        schemas={[]}
      />
    );
  }

  renderTitle() {
    return (
      <>
        <Row className="mb-3">
          <Col md={5} sm={12}>
            <h2 className="m-0">Sửa nhóm khách hàng</h2>
          </Col>
          <br />
          <Col md={7} sm={12}></Col>
        </Row>
        <div></div>
      </>
    );
  }

  renderSectionBaseInfoForm() {
    const { payload } = this.state;
    return (
      <>
        <div>
          <Row>
            <Col md={5} sm={12} className="text-center text-md-left px-0">
              <h3> Thông tin chung </h3>
            </Col>
          </Row>
          <br />
          <Row>
            <FormGroup className="">
              <Col sm={12} className="text-left mb-3 px-0">
                <span>Tên nhóm*</span>
                <FormControl
                  type="text"
                  placeholder="Nhập tên công việc"
                  value={payload && payload.name}
                  onChange={(e) => this.onFormChange('name', e.target.value)}
                ></FormControl>
              </Col>

              <Col sm={12} className="text-left px-0">
                <span>Mô tả*</span>
                <Form.Control
                  as="textarea"
                  rows="4"
                  value={payload && payload.description}
                  onChange={(e) => this.onFormChange('description', e.target.value)}
                />
              </Col>
            </FormGroup>
          </Row>
          <div className="text-center text-md-right">
            <Button variant="primary" className="text-light" onClick={() => this.updateData()}>
              Lưu
            </Button>
          </div>
        </div>
      </>
    );
  }

  renderSectionCustomerList() {
    console.log(this.state);
    const selectingCount = this.state.deleting_customer_ids?.length || 0;
    return (
      <>
        <Row>
          <Col md={12} className="p-0">
            <Row className="mb-2">
              <Col md={8}>
                <h3>Danh sách khách hàng</h3>
              </Col>
              <Col md={4}>
                <Row className="justify-content-center justify-content-md-end">
                  <Button
                    variant="danger"
                    hidden={!selectingCount}
                    className="text-light"
                    onClick={this.showDeleteCustomerConfirmation}
                  >
                    Xoá (<b>{selectingCount}</b>)
                  </Button>

                  
                  <Button variant="warning" className="text-light" onClick={this.handleImportPress}>
                    Import
                  </Button>
                  <Button
                    variant="success"
                    className="text-light"
                    onClick={() => this.setState({ modalAdd: { shown: true } })}
                  >
                    Thêm mới
                  </Button>
                </Row>
              </Col>
            </Row>
            <Row>
              <CustomerList
                payload={{ group_id: this.state.group_id }}
                resultCountText="Khách hàng phù hợp với kết quả lọc: "
                showCheckbox
                onSelectionChanged={this.onSelectedCustomersChanged}
                // customerActions={
                //   <Button
                //     variant="danger"
                //     disabled={!this.state.deleting_customer_ids?.length}
                //     className="text-light"
                //     onClick={this.onDeleteCustomerPress}
                //   >
                //     Xóa
                //   </Button>
                // }
                shouldHideSubFilter={true}
              />
            </Row>
          </Col>
          <ConfirmDeleteModal
            title={`Bạn có chắc chắn muốn xoá ${selectingCount} khách hàng khỏi nhóm?`}
            shown={this.state.modalConfirmDelete.shown}
            onClose={this.hideDeleteCustomerConfirmation}
            onConfirm={this.onDeleteCustomerPress}
          />
          <ImportCustomerModal
            groupId={this.state.group_id}
            shown={this.state.modalImportCustomers.shown}
            onClose={this.hideImportModal}
          />
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  provinceState: provinceSelectors.getProvinces(state),
  provinceOptions: provinceSelectors.getProvinceOptions(state),
  customerState: state.customerReducer,
  userState: state.userReducer,
});

const mapDispatchToProps = {
  getCustomerList,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditGroupCustomerScreen));
