import { Descriptions, Divider, Input, Progress, Select, Tabs, Tag, Timeline, Upload } from 'antd';
import styled from 'styled-components';
import { PlusCircleOutlined } from '@ant-design/icons';
import { PlusCircleFilled, DeleteOutlined } from '@ant-design/icons';
const SelectStyled = styled(Select)`
  border-bottom: 1px solid;
  margin: 0 0 0 15px;
  width: 185px;
`;

const TimelineStyled = styled(Timeline)`
  &&.last-item {
    border-left: none;
  }
  .ant-timeline-item-tail {
    border-left: 2px dotted #b3b3b3;
  }
`;

const NameCustomer = styled.span`
  font-size: 18px;
  cursor: pointer;
  :hover {
    color: #ef8737;
    text-decoration: underline;
  }
`;

const InputStyled = styled.input`
  border: none;
  padding: 0 0 0 10px;
  :focus-visible {
    fill: none;
  }
`;

const UploadStyled = styled(Upload)`
  .ant-upload-list-item-error {
    color: unset !important;
  }
  .ant-upload-list-item-name {
    color: unset !important;
  }
  .ant-upload-text-icon > .anticon {
    color: unset !important;
  }
  .ant-tooltip-inner {
    display: none;
  }
`;
export { UploadStyled, InputStyled, NameCustomer, SelectStyled, TimelineStyled };
