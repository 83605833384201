import { Modal, Row } from 'antd';
import styled from 'styled-components';
import React from 'react';
import plusicon from '@assets/plusicon.png';
import closeicon from '@assets/closeicon.png';
import { memo } from 'react';
import QRCode from 'qrcode.react';
export const MessageItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 5px 0 5px;
  gap: 10px;
  input[type='radio'] {
  border:2px solid white;
  box-shadow:0 0 0 1px #EF8737;
  appearance:none;
  border-radius:50%;
  width:16px;
  height:16px;
  background-color:#fff;
  transition:all ease-in 0.2s;
    &:checked{
  background-color:#EF8737;
}
}



  .message_checkbox {
    width: 30px;
  }

`;


export const ImageStyled = styled(Image) `

`
export const MessageContent = styled.div`
    position: ${props => props.qrCodeInImage ? 'relative' : 'static'} ;
    flex: 1;
    display: flex;
    margin: 5px 0 0 0;
    padding: 10px;
    border-radius: 10px;
    gap: 20px;
    border: ${props =>
      props.selected ? '1.5px solid #EF8737' : '1.5px solid #ccc'};
    box-shadow: rgba(0, 0, 0, 0) 0px 3px 8px;
    background-color: white;
 
    .description {
      flex: 1;
    }
`

export const ImageContainer = styled.div `
      /* width: 150px;
      height: 150px; */
      img {
        width: 200px;
        /* width: 100%; */
        /* height: 100%; */
        object-fit: cover;
      }
`
export const QrCodeStyled = styled(QRCode) `
  &#qrcode111111 {
    /* width: 100px !important;
    height: 100px !important; */
    position: ${props => props.qrCodeInImage ? 'absolute' : 'static'} ;
    left: 50px;
    top: 160px;
    /* border: 1px solid red; */
    width: 180px !important;
    height: 180px !important
  }

`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #ef8737;
  align-items: center;
  color: white;
  font-weight: 500;
  padding: 10px 24px;
  .iconplus {
    font-size: 20px !important;
    color: green !important;
  }
  .title-heading {
    font-size: 18px
  }
`;
export const ModalStyled = styled(Modal)`
  min-width: 600px;
  max-height: 100vh;
  .ant-modal-header {
    padding: 0;
  }
  .ant-modal-body {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .message_custom-list {
    max-height: 200px;
    overflow-y: auto;
  }

  .message_job-list {
    max-height: 400px;
    overflow-y: auto;
  }
`;

export const ModalStyleTemplate = styled(ModalStyled) `
min-width: 0;
text-align: left !important;
.ant-form-item-required {
    color: #ef8737;

}
.ant-form-item-explain {
    text-align: left;
  }
    
`
export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Divider = styled.div`
  position: relative;
  width: 100%;
  border-bottom: 0.5px solid #efe3e3;
  width: calc(100% + 48px);
  margin-top: 20px;
  margin-left: -24px;
`;

export const HeadingMsg = styled.div`
  font-size: 16px;
  font-weight: 500;
  padding: 0 0 10px 0;

  &.msgjob {
    margin-top: 8px;
  }
`;

export const RowStyled = styled(Row) `
    justify-content: center;
    margin-top: 20px;
    button {
        min-width: 100px;
        display:flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        & span:first-child {
            font-weight: 500;
            
        }
        & span:last-child {
            position: relative;
            top: -1px;
        }
    }
`
export const TitleModal = ({onClick}) => {
    return (
      <TitleContainer onClick = {onClick}>
        <div className="title-heading">Copy mẫu tin nhắn</div>
        <img src={plusicon} width={20} height={20} alt="plus" />
      </TitleContainer>
    );
  };


export const TitleModalTemplate = memo(({onCloseModal}) => {
    function handleClose() {
        console.log("close modal11111")
        onCloseModal()
    }
    return (
      <TitleContainer>
        <div className="title-heading">Thêm tin nhắn mẫu</div>
        <img src={closeicon} 
        onClick={handleClose}
        width={16} height={16} alt="close" />
      </TitleContainer>
    );
  });
  

  export const FlexContainerItem = styled.div`
    display: flex;
    flex-direction: column;
    .list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 15px;
    }
  `
  export const ContentWrapper = styled.span `
    border: 1.5px solid #504e4e;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: ${props => props.selected ? "#ef8737": "white"};
    border-color: ${props => props.selected ? "#ef8737": "#504e4e"};
    color: ${props => props.selected ? "white": "black"};
`

export function clearSelected(dataArr) {
    if(!Array.isArray(dataArr)) {
      alert("Data không phải 1 mảng")
      return []
    }
    return dataArr.map(ele => {
      return {...ele, selected: false}
    })
}




// export const dataTest = {
//   "message": "tessssssssssssssssssssssssssssssssss",
//   "is_sample_voucher": 0,

 
// }