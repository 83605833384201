import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Select } from 'antd';
import styled from 'styled-components';
import moment from 'moment';

import { VietnameseString } from '@constants/VietnameseString';
import { requestGetAllCategoryLocationReport } from '../services/api';
import { rmVN } from '@constants/funcHelper';

const { RangePicker } = DatePicker;

const StyledFilter = styled.div`
  display: flex;
  justify-content: space-between;
`;

function LocationReportDetailFilter(props) {
  const { locationReportDetailSearch, onLocationReportDetailSearchChange, onLocationReportDetailSearchTimeChange } =
    props;

  const [categories, setCategories] = useState([]);

  const getAllCategoryLocationReport = async () => {
    try {
      const { data } = await requestGetAllCategoryLocationReport();
      setCategories(data);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getAllCategoryLocationReport();
  }, []);

  return (
    <StyledFilter>
      <RangePicker
        allowClear={false}
        format={'DD/MM/YYYY'}
        disabledDate={(currentDate) => currentDate && currentDate > moment().endOf('day')}
        value={[moment(locationReportDetailSearch?.fromDate), moment(locationReportDetailSearch?.toDate)]}
        onChange={(date) =>
          onLocationReportDetailSearchTimeChange(
            moment(date[0]).format('YYYY-MM-DD'),
            moment(date[1]).format('YYYY-MM-DD')
          )
        }
      />

      <Select
        style={{ width: '288px' }}
        mode="multiple"
        optionFilterProp="children"
        filterOption={(input, option) => {
          return rmVN(option.children).indexOf(rmVN(input)) >= 0;
        }}
        placeholder={VietnameseString.name_of_attendance_type}
        onClear={() => {
          onLocationReportDetailSearchChange('categoryId', []);
        }}
        allowClear
        maxTagCount={1}
        value={locationReportDetailSearch.categoryId}
        onChange={(value) => {
          onLocationReportDetailSearchChange('categoryId', value);
        }}
      >
        {categories.map((item, index) => (
          <Select.Option key={index} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
    </StyledFilter>
  );
}

export default LocationReportDetailFilter;

LocationReportDetailFilter.propTypes = {
  locationReportDetailSearch: PropTypes.shape({
    telemarketingId: PropTypes.number,
    categoryId: PropTypes.arrayOf(PropTypes.number),
    isLate: PropTypes.number,
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
  }),
  onLocationReportDetailSearchChange: PropTypes.func,
  onLocationReportDetailSearchTimeChange: PropTypes.func,
};
