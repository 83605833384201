import { ROUTER } from '@constants/Constant';
import '@styles/Customer.css';
import { formatDateTimeString } from '@utils/dateUtils';
import {
  Spin,
  Table as TableAntd,
  Tabs,
  Tag,
  Typography,
  Descriptions,
  Empty,
  Divider,
  Pagination,
  Row,
  Col,
  Select,
  DatePicker,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory } from 'react-router-dom';

const { TabPane } = Tabs;
const { Text, Title, Link: LinkAntd } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const NotAvailableText = () => <Text type="secondary">Chưa cập nhật</Text>;
const timeout = 500;
let locale = {
  emptyText: 'Không có dữ liệu',
};

const ChanceList = ({
  data,
  types,
  handleChangeParams,
  handleChangeFromDate,
  handleChangeToDate,
  formattedPage,
  onChangePage,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  let history = useHistory();

  const renderOpportunityTag = (item) => {
    switch (item?.id) {
      case 4:
        return <Tag color="error">{item.status}</Tag>;
      case 3:
        return <Tag color="success">{item.status}</Tag>;
      default:
        return <Tag color="processing">Đã tiếp nhận</Tag>;
    }
  };

  useEffect(() => {
    let loadingTimeout = setTimeout(() => setIsLoading(false), timeout);
    return () => clearTimeout(loadingTimeout);
  }, []);

  const columns = [
    { title: 'STT', dataIndex: 'order', key: 'order' },
    {
      title: 'Công việc',
      dataIndex: 'job_name',
      key: 'job_name',
      render: (value) => <Text>{value || <NotAvailableText />}</Text>,
    },
    {
      title: 'Danh mục',
      dataIndex: 'opportunity_category_name',
      key: 'opportunity_category_name',
      render: (value) => <Text>{value || <NotAvailableText />}</Text>,
    },
    {
      title: 'Doanh thu',
      dataIndex: 'revenue',
      key: 'revenue',
      render: (value) => <Text danger>{value || <NotAvailableText />}</Text>,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (value) => (
        <Tag color={value ? (value === 1 ? 'green' : 'blue') : 'blue'}>{value === 1 ? 'Thành công' : 'Đang xử lý'}</Tag>
      ),
    },
    {
      title: 'Nhân viên',
      dataIndex: 'telesale_name',
      key: 'telesale_name',
      render: (value) => <Text>{value || <NotAvailableText />}</Text>,
    },
    {
      title: 'SĐT nhân viên',
      dataIndex: ' ',
      key: 'telesale_phone',
      render: (value) => <Text style={{ color: '#1890ff' }}>{value || <NotAvailableText />}</Text>,
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Row gutter={16} style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <Select
            style={{ width: '100%' }}
            placeholder="Chọn loại cơ hội"
            onChange={(value) => handleChangeParams('type', value)}
            allowClear
          >
            {types.length ? (
              types.map((item) => {
                return (
                  <Option key={item.id} value={item.shortDesc}>
                    {item.fullDesc}
                  </Option>
                );
              })
            ) : (
              <></>
            )}
          </Select>
        </Col>
        <Col span={8}>
          <RangePicker
            placeholder={['Từ ngày', 'Đến ngày']}
            format="YYYY-DD-MM"
            onChange={(date, dateString) => {
              console.log(dateString, 'dateString');
              handleChangeFromDate(date && date[0]._d);
              handleChangeToDate(date && date[1]._d);
            }}
          />
        </Col>
      </Row>
      {data.length ? (
        data.map((item, index) => {
          return (
            <div
              key={item.id}
              // onClick={() => {
              //   // console.log({ item });
              //   history.push(`${ROUTER.OCCASION_DETAIL}/${item.id}`);
              // }}
            >
              <Divider orientation="left" style={{ marginBottom: 0 }}>
                {item.job_name}
              </Divider>
              <Descriptions
                key={index}
                style={{
                  marginTop: 0,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 12,
                  border: '0.5px solid #CDCDCD',
                  borderTop: 'none',
                  borderRadius: '10px',
                }}
              >
                <Descriptions.Item label={'Loại cơ hội'} labelStyle={{ color: 'grey' }}>
                  <Text strong={true}>{item.opportunity_category_name || <NotAvailableText />}</Text>
                </Descriptions.Item>
                <Descriptions.Item label={'Doanh thu'} labelStyle={{ color: 'grey' }}>
                  <Text strong={true} danger>
                    {item.revenue ? (
                      item.revenue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    ) : (
                      <NotAvailableText />
                    )}
                  </Text>
                </Descriptions.Item>
                <Descriptions.Item label={'Trạng thái'} labelStyle={{ color: 'grey' }}>
                  {/* <Tag color={item.status ? (item.status === 1 ? 'green' : 'blue') : 'blue'}>
                    {item.status === 1 ? 'Thành công' : 'Đang xử lý'}
                  </Tag> */}
                  {renderOpportunityTag(item)}
                </Descriptions.Item>
                <Descriptions.Item label={'Nhân viên'} labelStyle={{ color: 'grey' }}>
                  <Text strong={true}>{item.telesale_name || <NotAvailableText />}</Text>
                </Descriptions.Item>
                <Descriptions.Item label={'Khách hàng'} labelStyle={{ color: 'grey' }}>
                  <Text strong={true} style={{ color: 'var(--ant-primary-color)' }}>
                    {item.customer_name} ({item.customer_phone})
                  </Text>
                </Descriptions.Item>
                <Descriptions.Item label={'Ngày cập nhật'} labelStyle={{ color: 'grey' }}>
                  <Text strong={true} style={{ color: '#1890ff' }}>
                    {moment(item.modified_date).format('DD/MM/YYYY') || <NotAvailableText />}
                  </Text>
                </Descriptions.Item>
              </Descriptions>
            </div>
          );
        })
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Không có dữ liệu" />
      )}
      <Pagination
        defaultCurrent={1}
        total={10}
        style={{ float: 'right', marginTop: '20px' }}
        {...formattedPage}
        onChange={(page) => onChangePage(page)}
        showSizeChanger={false}
      />
      {/* <TableAntd locale={locale} columns={columns} dataSource={data} /> */}
    </Spin>
  );
};

export default ChanceList;
