import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';

const ButtonStyled = styled(Button)`
  ${props =>
    props?.color === 'primary'
      ? 'background: linear-gradient(45deg, #ff6928 30%, #f6a62c 90%); color: white; border: none;'
      : ''}

  font-weight: 600;
  border-radius: 4px;

  display: flex;
  align-items: center;

  &:hover {
    ${props =>
      props?.color === 'primary'
        ? 'background: linear-gradient(45deg, #f6a62c 30%, #ff6928 90%); color: white;'
        : ''}
  }

  &:focus {
    ${props =>
      props?.color === 'primary'
        ? 'background: linear-gradient(45deg, #f6a62c 30%, #ff6928 90%); color: white;'
        : ''}
  }
`;

const ButtonBorderStyled = styled(Button)`
  ${props =>
    props?.color === 'primary'
      ? 'border:  1px dashed #ff6928; color: #000;'
      : ''}

  font-weight: 600;
  border-radius: 4px;

  display: flex;
  align-items: center;

  & * {
    color: #ff6928;
  }

  &:hover {
    ${props =>
      props?.color === 'primary'
        ? 'border:  1px dashed #ff6928;color:#ff6928;'
        : ''}
  }

  &:focus {
    ${props =>
      props?.color === 'primary' ? 'background: transparent;color: #000;' : ''}
  }
`;

const ButtonModules = ({
  children,
  icon,
  color = 'default',
  onClick,
  type,
  loading,
  disabled,
  border,
}) => {
  return border ? (
    <ButtonBorderStyled
      loading={loading}
      disabled={disabled}
      color={loading ? 'default' : color}
      icon={icon && <span style={{ marginRight: '5px' }}>{icon}</span>}
      onClick={!loading && onClick}
      type={type}
    >
      {children}
    </ButtonBorderStyled>
  ) : (
    <ButtonStyled
      loading={loading}
      disabled={disabled}
      color={loading ? 'default' : color}
      icon={icon && <span style={{ marginRight: '5px' }}>{icon}</span>}
      onClick={!loading && onClick}
      type={type}
    >
      {children}
    </ButtonStyled>
  );
};

export default ButtonModules;
