import {
    GET_REVIEW, GET_REVIEW_SUCCESS, GET_REVIEW_FAIL,
} from "@actions/type";

const initialState = {
    data: {},
    isLoading: false,
    error: null
};

export default function (state = initialState, action) {

    switch (action.type) {

        // *************  GET INFO ****************//
        case GET_REVIEW:
            return {
                ...state,
                isLoading: true,
            }
        case GET_REVIEW_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload.response.data,
                error: null
            }
        case GET_REVIEW_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        default:
            return state;
    }
}