import React from 'react';
import { BeatLoader } from 'react-spinners';

const LoadingComponent = () => {
  return (
    <div
      style={{
        position: 'fixed',
        inset: 0,
        zIndex: 999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'all 0.3s',
      }}
    >
      <div
        style={{
          backgroundColor: 'rgba(0,0,0,.2)',
          padding: '40px',
          borderRadius: '12px',
        }}
      >
        <BeatLoader size={20} color="#F67A17" />
      </div>
    </div>
  );
};

export default LoadingComponent;
