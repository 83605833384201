import { requestCustomerGroupCreate } from '@constants/Api';
import { notifyFail } from '@utils/notify';
import { Button, Form, Input, Modal } from 'antd';
import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';

const ModalStyled = styled(Modal)`
  top: 25%;
  z-index: 1000;

  .form-add-customer-group {
    width: 100%;
  }
`;

export default function AddCustomerGroupModal({ visible, setVisible, handleResultCreateCustomerGroup }) {
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  return (
    <ModalStyled
      title="Tạo nhóm"
      visible={visible}
      onOk={() => {}}
      onCancel={() => {
        setVisible(false);
      }}
      destroyOnClose={true}
      okText={'Lưu'}
      cancelText={'Hủy'}
      footer={null}
    >
      <Form
        className="form-add-customer-group"
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 19,
        }}
        onFinish={async (values) => {
          try {
            setIsLoadingButton(true);
            const payload = {
              ...values,
              name: values.name.trim(),
            };
            const result = await requestCustomerGroupCreate(payload);
            const item = {
              description: '',
              id: result.data.id,
              key: result.data.id,
              name: result.data.name,
            };
            handleResultCreateCustomerGroup(item);
            setVisible(false);
          } catch (error) {
            notifyFail(error.msg);
            console.log('Exception: ' + error);
          } finally {
            setIsLoadingButton(false);
          }
        }}
      >
        <Form.Item
          label="Tên nhóm"
          name="name"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập tên nhóm!',
            },
            {
              whitespace: false,
              message: 'Vui lòng không nhập khoảng trắng!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Mô tả" name="description">
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 5, span: 19 }}>
          <Button type="primary" htmlType="submit" loading={isLoadingButton}>
            Lưu
          </Button>
        </Form.Item>
      </Form>
    </ModalStyled>
  );
}
