export const Spacing = {
  contentPadding: '20px',
  sectionMarginBottom: '20px',
};
export const Sizing = {
  tableColumnNoWidth: '50px',
};

export const Colors = {};

export const Typo = {};

export const Palette = {};

const Theme = {
  Spacing,
  Sizing,
  Colors,
  Typo,
  Palette,
};

export default Theme;
