import React, { Component } from 'react';
// import Loading from '@src/components/Loading';
import { Row, Col, FormControl } from 'react-bootstrap';
// import { ReactSelect } from '@components/ReactSelect';
import { ROUTER, USER_ROLE, DEFAULT_IMAGE } from '@constants/Constant';
import { Empty, Switch } from 'antd';
import { getTelesaleList, getProvinceList } from '@src/redux/actions';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

class DetailJobScreen extends Component {
  customerListJob() {
    return (
      <Row className="mt-3">
        <Col md={12} className="mb-3">
          <b>Thông tin giao việc</b>
        </Col>
        <Col md={6}>
          <Row className="mb-3">
            <Col md={3} className="p-0">
              Trạng thái
            </Col>
            <Col md={9}>{'--'}</Col>
          </Row>
          <Row className="mb-3">
            <Col md={3} className="p-0">
              Mục tiêu
            </Col>
            <Col md={9}>{'--'}</Col>
          </Row>
          <Row className="mb-3">
            <Col md={3} className="p-0">
              Thời gian
            </Col>
            <Col md={9}>{'--'}</Col>
          </Row>
          <Row className="mb-3">
            <Col md={3} className="p-0">
              Tiến độ
            </Col>
            <Col md={9}>{'--'}</Col>
          </Row>
        </Col>
        <Col md={6}>
          <Row className="mb-3">
            <Col md={4} className="p-0">
              <b>Kết quả đánh giá</b>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4} className="p-0">
              Rõ ràng rành mạnh
            </Col>
            <Col md={8}>{'--'}</Col>
          </Row>
          <Row className="mb-3">
            <Col md={4} className="p-0">
              Không trung thực
            </Col>
            <Col md={8}>{'--'}</Col>
          </Row>
          <Row className="mb-3">
            <Col md={4} className="p-0">
              Nói không rõ ràng
            </Col>
            <Col md={8}>{'--'}</Col>
          </Row>
        </Col>
      </Row>
    );
  }

  customerListCall() {
    return (
      <div className="w-100">
        <h5>Danh sách cuộc gọi</h5>
        <br></br>
        <Row>
          <Col md={3} sm={12}>
            <FormControl
              as="select"
              id=""
              //   value={searchBar.work}
              //   onChange={(e) => this.searchBarChange('work', e.target.value)}
              className="h-100"
            >
              <option value="">Công việc cha</option>
              <option value="1">Toànxxxxx</option>
              <option value="2">Báxxxx</option>
            </FormControl>
          </Col>
          <Col md={3} sm={12}>
            <FormControl
              as="select"
              id=""
              //   value={searchBar.work}
              //   onChange={(e) => this.searchBarChange('work', e.target.value)}
              className="h-100"
            >
              <option value="">Công việc con</option>
              <option value="1">Toànxxxxx</option>
              <option value="2">Báxxxx</option>
            </FormControl>
          </Col>
          <Col md={3} sm={12}>
            <FormControl
              as="select"
              id=""
              //   value={searchBar.work}
              //   onChange={(e) => this.searchBarChange('work', e.target.value)}
              className="h-100"
            >
              <option value="">Telesale</option>
              <option value="1">Toànxxxxx</option>
              <option value="2">Báxxxx</option>
            </FormControl>
          </Col>
          <Col md={3} sm={12}>
            <FormControl
              as="select"
              id=""
              //   value={searchBar.work}
              //   onChange={(e) => this.searchBarChange('work', e.target.value)}
              className="h-100"
            >
              <option value="">Trạng thái đánh giá</option>
              <option value="1">Toànxxxxx</option>
              <option value="2">Báxxxx</option>
            </FormControl>
          </Col>
        </Row>
      </div>
    );
  }

  customerInfo() {
    const saleList = this.props.telesaleState?.data;
    const { paging } = this.props.telesaleState;
    const err = this.props.telesaleState?.error;
    // if (err) {
    //   notifyFail(err?.msg);
    // }
    return (
      <div className="w-100 table-wrap">
        <span className="pb-2 d-inline-block">
          Kết quả lọc: <b className="text-danger">{paging?.totalItemCount || 0}</b>
        </span>
        {saleList.length ? (
          saleList.map((sale) => this.renderItem(sale))
        ) : (
          <Empty description={<span>{(err && err?.msg) || 'Không có dữ liệu'}</span>} />
        )}
      </div>
    );
  }

  renderItem(sale) {
    return (
      <Row className="mt-1" style={{ padding: '0px 0px', borderRadius: '8px', marginBottom: 20 }}>
        <Col
          md
          className="tele-item"
          style={{
            padding: '20px 0px 25px 30px',
            backgroundColor: '#ffffff',
            borderRadius: '8px',
          }}
        >
          <Row>
            <Col md={10} className="content">
              <Row className="upper mb-2">
                <b>{'--'}</b>
              </Row>
              <Row className="lower">
                <Col md={3}>
                  <Row className="justify-content-center justify-content-md-start pb-1">
                    <span>{'--'}</span>
                  </Row>
                  <Row className="justify-content-center justify-content-md-start pb-1">
                    <span>{'--'}</span>
                  </Row>
                  <Row className="justify-content-center justify-content-md-start pb-1">
                    <span>{'--'}</span>
                  </Row>
                </Col>
                <Col md={3} xs={6}>
                  <Row className="center-row">
                    <span>Kinh nghiệm</span>
                  </Row>
                  <Row className="center-row">
                    <span className="text-red">{'0'}</span>
                  </Row>
                </Col>
                <Col md={3} xs={6} className="center-row">
                  <Row className="center-row">
                    <span>Số cuộc gọi TB</span>
                  </Row>
                  <Row className="center-row">
                    <span className="accept">{'0'} cuộc/ngày</span>
                  </Row>
                </Col>
                <Col md={3}>
                  <Row>
                    <Col md={12} xs={4} className="text-center offset-2 offset-md-0 px-0">
                      <span>Trạng thái</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="pt-2">
                <Col md={4} className="px-0 pb-1">
                  <Row>
                    <Col xs={8} className="px-0">
                      <Row>
                        <Col md={12} xs={10} className="px-0">
                          Công việc
                        </Col>
                        <Col md={12} xs={2} className="text-center text-lg-left px-0">
                          <b>{'0'}</b>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col md={4} className="px-0 pb-1">
                  <Row>
                    <Col xs={8} className="px-0">
                      <Row>
                        <Col md={12} xs={10} className="px-0">
                          <span>Cuộc gọi còn lại</span>
                        </Col>
                        <Col md={12} xs={2} className="text-center text-lg-left px-0">
                          <b>{'0'}</b>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col md={4} className="px-0 pb-1">
                  <Row>
                    <Col xs={8} className="px-0">
                      <Row>
                        <Col md={12} xs={10} className="px-0">
                          <span>Cuộc gọi thành công</span>
                        </Col>
                        <Col md={12} xs={2} className="text-center text-lg-left px-0">
                          <b>{'0'}</b>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  renderJob() {
    // return <div className="w-100 table-wrap"></div>;
    return (
      <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 15 }}>
        <Col md className="bg">
          {this.customerListJob()}
        </Col>
      </Row>
    );
  }

  renderListCall() {
    return (
      <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 15 }}>
        <Col md className="bg">
          {this.customerListCall()}
          <br></br>
          {this.customerInfo()}
        </Col>
      </Row>
    );
  }

  renderBody() {
    return (
      <>
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <div className="content-header">
            <Row className="mt-3" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
              <Col md className="bg">
                <Row>
                  <h2> Chi tiết công việc </h2>
                </Row>
              </Col>
            </Row>
            <Row className="mt-3" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
              <Col md className="bg">
                <Row>
                  <Col md={12} sm={12}>
                    {this.renderJob()}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-3" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
              <Col md className="bg">
                <Row>
                  <Col md={12} sm={12}>
                    {this.renderListCall()}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
  render() {
    // return (
    //   <div className="content-wrapper">
    //     {/* Content Header (Page header) */}
    //     <div className="content-header">
    //       <div> Hello world</div>
    //     </div>
    //   </div>
    // );

    return (
      <>
        {this.renderBody()}
        {/* {this.renderPagination()} */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  telesaleState: state.TelesaleReducer,
  provinceState: state.ProvinceReducer,
  userState: state.userReducer,
});

const mapDispatchToProps = {
  getTelesaleList,
  getProvinceList,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DetailJobScreen));
