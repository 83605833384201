import { getAxios, handleResult } from '@constants/Api';

export const requestGetNumberPrefix = ({ enterpriseId, page, limit = 12 }) => {
  return handleResult(
    getAxios.get('/sms/provider', {
      params: new URLSearchParams({
        enterprise_id: enterpriseId,
        page,
        limit,
      }),
    })
  );
};

export const requestGetAllNumberPrefix = ({ enterpriseId, page }) => {
  return handleResult(
    getAxios.get('/sms/providerV2', {
      params: new URLSearchParams({
        enterprise_id: enterpriseId,
        page,
      }),
    })
  );
};

export const requestGetPartner = () => {
  return handleResult(getAxios.get('/sms/partner'));
};

export const requestGetTelecom = () => {
  return handleResult(getAxios.get('/sms/telco'));
};

export const requestGetTypesOfSMS = () => {
  return handleResult(getAxios.get('/sms/type'));
};

export const requestCreateNumberPrefix = ({
  enterpriseId,
  SMSTypeId,
  phone,
  account,
  password,
  partnerId,
  brandName,
}) => {
  let data = {
    enterprise_id: enterpriseId,
    sms_type_id: SMSTypeId,
    phone: phone,
    account,
    password,
    partner_id: partnerId,
    brand_name: brandName,
  };

  if (!brandName) delete data.brand_name;

  return handleResult(getAxios.post('/sms/provider', data));
};

export const requestGetNumberPrefixInformation = ({ numberPrefixId }) => {
  return handleResult(getAxios.get(`/sms/provider/${numberPrefixId}`));
};

export const requestGetTemplatesMessage = ({ numberPrefixId, search, isApproved, fromDate, toDate, page, limit }) => {
  return handleResult(
    getAxios.get(`/sms/provider/${numberPrefixId}/messageTemplate`, {
      params: new URLSearchParams({
        search,
        is_approved: isApproved,
        from_date: fromDate,
        to_date: toDate,
        page,
        limit,
      }),
    })
  );
};

export const requestGetAllTemplatesMessage = ({ numberPrefixId, search, isApproved, fromDate, toDate, page }) => {
  return handleResult(
    getAxios.get(`/sms/provider/${numberPrefixId}/messageTemplateV2`, {
      params: new URLSearchParams({
        search,
        is_approved: isApproved,
        from_date: fromDate,
        to_date: toDate,
        page,
      }),
    })
  );
};

export const requestCreateTemplateMessage = ({ numberPrefixId, data }) => {
  return handleResult(getAxios.post(`sms/provider/${numberPrefixId}/messageTemplate`, data));
};

export const requestUpdateTemplateMessage = ({ numberPrefixId, templateMessageId, isApproved }) => {
  return handleResult(
    getAxios.put(`/sms/provider/${numberPrefixId}/messageTemplate/${templateMessageId}`, {
      is_approved: isApproved,
    })
  );
};

export const requestGetParameterWithTemplate = ({ enterpriseId }) => {
  return handleResult(
    getAxios.get(`/sms/dataCustom`, {
      params: new URLSearchParams({
        enterprise_id: enterpriseId,
      }),
    })
  );
};
