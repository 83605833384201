import styled from 'styled-components';

export const PopupStyled = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  .modal {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.65);
    display: ${(props) => (props.visible ? 'block' : 'none')};
  }

  #control-modal:checked ~ .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fade 1s forwards;
    opacity: 0;
  }

  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .modal-content {
    background-color: white;
    width: 512px;
    /* height: 256px; */
    border-radius: 3px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .modal-header {
    padding: 12px 24px 0px;
    text-align: center;
    position: relative;
    justify-content: center;
    border: none;

    i {
      font-size: 20px;
      color: #ff8800;

      margin-right: 12px;
    }
  }

  .modal-close {
    position: absolute;
    background-color: gray;
    color: white;
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 50%;
    top: -10px;
    right: -10px;
    cursor: pointer;
  }

  .modal-body {
    padding: 12px 24px 24px;

    p {
      font-size: 16px;
      text-align: center;
      margin-bottom: 4px;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;

    padding: 0px;

    button {
      display: inline-block;
      width: 50%;

      margin: 0px;
      padding: 4px 0px;

      border: none;
      background-color: transparent;

      font-size: 18px;

      :hover {
        background-color: #e2e2e2;
        font-weight: 500;
      }

      + button {
        border-left: 1px solid #dee2e6;
      }
    }
  }
`;
