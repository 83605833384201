import {
  requestCreateHotline,
  requestDeleteHotline,
  requestEnterprisesFilter,
  requestGetListHotline,
  requestUpdateHotline,
} from '@constants/Api';
import {
  keyStringee,
  LIST_TELECOM,
  SWITCHBOARD_HOTLINE,
} from '@constants/Constant';
import Loading from '@src/components/Loading';
import { getListHotline } from '@src/redux/actions';
import '@styles/Settings.css';
import { notifyFail, notifySuccess } from '@utils/notify';
import { Empty, Select } from 'antd';
import 'antd/dist/antd.css';
import React, { Component } from 'react';
import {
  Button,
  Col,
  FormControl,
  FormGroup,
  Modal,
  Row,
  Table,
} from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { enterNumbersOnly, formatPrice } from '@constants/funcHelper';
import styled from 'styled-components';

const { Option } = Select;

const optionsListTelecom = [],
  temp = LIST_TELECOM.map(value => {
    const countTelecom = optionsListTelecom.push(
      <Option key={value}>{value}</Option>
    );
  });

const ContainerStyled = styled.div`
  & label::after {
    display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    margin-left: 4px;
    content: '*';
  }
`;

const defaultValueFormITY = {
  uri: 'wss://cc95.siptrunk.vn:58089/ws',
  domain: 'cc95.siptrunk.vn',
};

class FirstNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listHotline: [],
      listCompany: [],
      searchHotline: '',
      typeSelected: '',
      telecomSelected: '',
      modalAddHotline: false,
      modalEditHotline: false,
      modalDeleteHotline: false,
      switchboard: '',
      modal: {
        outbound_phone: '',
        telecom: [],
        count_to_change: '',
        enterprise: '',
        // enterprise_id: '',
        type: 2,
        uri: defaultValueFormITY.uri,
        domain: defaultValueFormITY.domain,
        gateway_id: '',
        api_keyid: keyStringee.api_keyid,
        api_keysec: keyStringee.api_keysec,
        api_account_keyid: keyStringee.api_account_keyid,
        api_account_keysec: keyStringee.api_account_keysec,
      },
      pagging: {},
      id: '',
      activePage: 1,
      isLoading: false,
      tempNumber: '',
    };
  }

  componentDidMount() {
    this.requestGetListHotline('', 1, '');
    this.requestEnterprisesFilter();
  }

  // getData() {
  //   this.props.getListHotline({ enterprise_id: '', search: '' });
  // }

  async requestGetListHotline(search, page, type) {
    const { activePage } = this.state;
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const res = await requestGetListHotline({
        enterprise_id: '',
        search: search,
        page: page,
        type: type,
      });
      this.setState({
        ...this.state,
        listHotline: res?.data,
        // typeSelected: ,
        pagging: res?.pagging,
        isLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async requestEnterprisesFilter() {
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const res = await requestEnterprisesFilter({
        search: '',
        limit: 1000,
        type: '',
      });
      this.setState({
        ...this.state,
        listCompany: res?.data,
        isLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async requestCreateHotline() {
    const { modal, listHotline } = this.state;
    this.setState({
      ...this.state,
    });

    const filteredIndex = listHotline.length
      ? listHotline.findIndex(
          item => item.outbound_phone === modal.outbound_phone
        )
      : -1;

    if (!this.checkTelephone()) {
      // console.log('ádh');
      return;
    }
    // if (filteredIndex > -1) {
    //   notifyFail('Đầu số bị trùng!');
    //   return;
    // }

    // check ity
    if (modal.type === SWITCHBOARD_HOTLINE.ITY && !modal?.uri) {
      notifyFail('Vui lòng nhập uri!');
      return;
    }
    if (modal.type === SWITCHBOARD_HOTLINE.ITY && !modal.extension) {
      notifyFail('Vui lòng nhập extension!');
      return;
    }
    if (modal.type === SWITCHBOARD_HOTLINE.ITY && !modal.pass) {
      notifyFail('Vui lòng chọn Pass!');
      return;
    }

    if (!modal.count_to_change) {
      notifyFail('Vui lòng nhập số lần gọi!');
      return;
    }
    if (modal.count_to_change <= 0) {
      notifyFail('Vui lòng nhập số lần gọi lớn hơn 0!');
      return;
    }
    if (parseInt(modal.count_to_change) > 1000000) {
      notifyFail('Số lần cuộc gọi không được vượt quá 1000.000!');
      return;
    }

    try {
      await requestCreateHotline({
        outbound_phone: modal.outbound_phone,
        note: 'Ghi chu',
        ...(modal.enterprise && { enterprise_id: modal.enterprise }),
        count_to_change: modal.count_to_change,
        telecom: modal.telecom,
        type: modal.type,
        domain: modal.domain,
        gateway_id: modal.gateway_id,
        api_keyid: modal.api_keyid,
        api_keysec: modal.api_keysec,
        api_account_keyid: modal.api_account_keyid,
        api_account_keysec: modal.api_account_keysec,
        uri: modal.uri,
        extension: modal.extension,
        pass: modal.pass,
      });
      this.setState(
        {
          ...this.state,
          modalAddHotline: false,
          modal: {
            outbound_phone: '',
            telecom: [],
            count_to_change: '',
            enterprise: '',
            type: 2,
            domain: defaultValueFormITY.domain,
            gateway_id: '',
            api_keyid: keyStringee.api_keyid,
            api_keysec: keyStringee.api_keysec,
            api_account_keyid: keyStringee.api_account_keyid,
            api_account_keysec: keyStringee.api_account_keysec,
            uri: defaultValueFormITY.uri,
            extension: '',
            pass: '',
          },
          isLoading: false,
        },
        () => notifySuccess('Thêm mới thành công')
      );

      this.requestGetListHotline('', 1, '');
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async requestUpdateHotline() {
    const { modal, id, listHotline, tempNumber } = this.state;

    this.setState({
      ...this.state,
      isLoading: true,
    });

    const filteredIndex = listHotline.length
      ? listHotline.findIndex(
          item => item.outbound_phone === modal.outbound_phone
        )
      : -1;

    if (!this.checkTelephone()) {
      // console.log('ádh');
      return;
    }
    // if (filteredIndex > -1 && modal.outbound_phone !== tempNumber) {
    //   notifyFail('Đầu số bị trùng!');
    //   return;
    // }
    if (modal.type === SWITCHBOARD_HOTLINE.STRINGEE && !modal.outbound_phone) {
      notifyFail('Vui lòng nhập số điện thoại!');
      return;
    }

    // check ity
    if (modal.type === SWITCHBOARD_HOTLINE.ITY && !modal?.uri) {
      notifyFail('Vui lòng nhập uri!');
      return;
    }
    if (modal.type === SWITCHBOARD_HOTLINE.ITY && !modal.extension) {
      notifyFail('Vui lòng nhập extension!');
      return;
    }
    if (modal.type === SWITCHBOARD_HOTLINE.ITY && !modal.pass) {
      notifyFail('Vui lòng chọn Pass!');
      return;
    }

    if (!modal.count_to_change) {
      notifyFail('Vui lòng nhập số lần gọi!');
      return;
    }
    if (modal.count_to_change <= 0) {
      notifyFail('Vui lòng nhập số lần gọi lớn hơn 0!');
      return;
    }
    if (parseInt(modal.count_to_change) > 1000000) {
      notifyFail('Số lần cuộc gọi không được vượt quá 1000.000!');
      return;
    }

    try {
      await requestUpdateHotline({
        id: id,
        outbound_phone: modal.outbound_phone,
        note: 'Ghi chu',
        ...(modal.enterprise && { enterprise_id: modal.enterprise }),
        count_to_change: modal.count_to_change,
        domain: modal.domain,
        type: modal.type,
        gateway_id: modal.gateway_id,
        api_keyid: modal.api_keyid,
        api_keysec: modal.api_keysec,
        api_account_keyid: modal.api_account_keyid,
        api_account_keysec: modal.api_account_keysec,
        telecom: modal.telecom.join(','),
        uri: modal.uri,
        extension: modal.extension,
        pass: modal.pass,
      });

      this.requestGetListHotline('', 1, '');
      this.setState(
        {
          ...this.state,
          modalAddHotline: false,
          id: '',
          modal: {
            outbound_phone: '',
            telecom: [],
            count_to_change: '',
            enterprise: '',
            type: 2,
            domain: defaultValueFormITY.domain,
            gateway_id: '',
            api_keyid: keyStringee.api_keyid,
            api_keysec: keyStringee.api_keysec,
            api_account_keyid: keyStringee.api_account_keyid,
            api_account_keysec: keyStringee.api_account_keysec,
            uri: defaultValueFormITY.uri,
            extension: '',
            pass: '',
          },
          isLoading: false,
        },
        () => notifySuccess('Cập nhật thành công')
      );
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async requestDeleteHotline() {
    const { id } = this.state;
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      await requestDeleteHotline({
        id: id,
      });
      this.requestGetListHotline('', 1, '');
      this.setState(
        {
          ...this.state,
          modalDeleteHotline: false,
          id: '',
          isLoading: false,
        },
        () => notifySuccess('Xóa thành công')
      );
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  checkTelephone = () => {
    // let checkPhone = true;
    let newArr = [...this.state.listHotline];
    let phone = parseInt(this.state.modal.outbound_phone);
    let check = newArr.filter(v => parseInt(v.outbound_phone) === phone);
    console.log(check, phone, newArr, 'check');
    // if (check.length != 0) {
    //   notifyFail('Số hotline đã được tồn tại !');
    //   return false;
    // }
    return true;
  };

  handleChangeHotlineSearch = search => {
    this.setState({
      ...this.state,
      searchHotline: search,
    });
  };

  handleChangeTypeSearch = type => {
    this.setState({
      ...this.state,
      typeSelected: type,
    });
    setTimeout(() => {
      this.requestGetListHotline(this.state?.searchHotline, 1, type);
    }, 200);
  };

  handleKeyPress = e => {
    const { searchHotline, typeSelected } = this.state;
    if (e.charCode === 13) {
      this.requestGetListHotline(
        searchHotline ? searchHotline?.trim() : '',
        1,
        typeSelected
      );
    }
  };

  handleChangeModal = (field, value) => {
    this.setState({
      ...this.state,
      [field]: value,
    });
  };

  handleClearDataModal = (field, value) => {
    this.setState({
      ...this.state,
      [field]: value,
      id: '',
      modal: {
        outbound_phone: '',
        telecom: [],
        count_to_change: '',
        enterprise: '',
        type: 2,
        domain: defaultValueFormITY.domain,
        gateway_id: '',
        api_keyid: keyStringee.api_keyid,
        api_keysec: keyStringee.api_keysec,
        api_account_keyid: keyStringee.api_account_keyid,
        api_account_keysec: keyStringee.api_account_keysec,
        uri: defaultValueFormITY.uri,
        extension: '',
        pass: '',
      },
      isLoading: false,
    });
  };

  handleChangeModalField = (field, value) => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        [field]: value,
      },
    });
  };

  handleChange = value => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        telecom: `${value}`,
      },
    });
  };

  renderPagination() {
    const { pagging, activePage } = this.state;
    const itemsCount = pagging.totalItemCount;
    const limit = pagging.limit;
    const { searchHotline } = this.state;
    const { typeSelected } = this.state;
    return (
      <Pagination
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled
        activePage={activePage}
        itemsCountPerPage={limit}
        totalItemsCount={itemsCount}
        pageRangeDisplayed={5}
        hideNavigation
        onChange={page => {
          this.setState(
            {
              activePage: page,
            },
            () => {
              this.requestGetListHotline(searchHotline, page, typeSelected);
            }
          );
        }}
      />
    );
  }

  renderBody() {
    return (
      <div>
        <Col sm={12} md={12}>
          <Row>
            <Col
              sm={12}
              className="d-flex justify-content-between"
              style={{ paddingLeft: '20px' }}
            >
              <h5>Đầu số</h5>
            </Col>
          </Row>
        </Col>
        <Row justify-content-between style={{ padding: '0 20px' }}>
          <Col>
            <Row>
              <Col md={4}>
                <FormControl
                  type="text"
                  id=""
                  placeholder="Nhập đầu số, tên công ty"
                  value={this.state.searchHotline}
                  onChange={e => this.handleChangeHotlineSearch(e.target.value)}
                  onKeyPress={e => this.handleKeyPress(e)}
                />
              </Col>
              <Col md={4}>
                <FormControl
                  as="select"
                  id=""
                  // value={this.state.typeSelected}
                  onChange={e => this.handleChangeTypeSearch(e.target.value)}
                >
                  <option>Loại tổng đài</option>
                  {/* <option value={1}>Ezconnect</option> */}
                  <option value={2}>Stringee</option>
                  <option value={SWITCHBOARD_HOTLINE.ITY}>ITY</option>
                </FormControl>
              </Col>
            </Row>
          </Col>
          <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="success"
              className="text-light"
              onClick={() => this.handleChangeModal('modalAddHotline', true)}
            >
              Thêm mới
            </Button>
          </Col>
        </Row>
        <Col sm={12} md={12}>
          <div className="row">
            <Col
              sm={10}
              md={12}
              style={{
                padding: '0px',
                marginTop: '20px',
              }}
            >
              <Row className="table-wrap mx-4">
                <div style={{ margin: '0 0 5px 0' }}>
                  Bộ lọc: {this.state.pagging.totalItemCount}
                </div>
                <Table striped bordered hover style={{ textAlign: 'center' }}>
                  <thead>
                    <th>STT</th>
                    <th>Đầu số</th>
                    <th>Tổng đài</th>
                    <th>Enterprise</th>
                    {/* <th>Trạng thái</th> */}
                    <th style={{ width: 80 }}></th>
                  </thead>
                  <tbody>{this.renderTableData()}</tbody>
                </Table>
                <Col
                  md={12}
                  className="d-flex justify-content-center justify-content-md-end p-0"
                >
                  {this.renderPagination()}
                </Col>
              </Row>
            </Col>
          </div>
        </Col>
      </div>
    );
  }

  renderTableData() {
    const { listHotline, listCompany } = this.state;

    const { pagging } = this.state;
    const pageNumber = pagging.page;
    const itemPerNumber = pagging.limit;
    return (
      <>
        {listHotline?.length > 0 ? (
          listHotline?.map((value, index) => (
            <tr>
              <td>{index + 1 + (pageNumber - 1) * itemPerNumber}</td>
              <td>{value?.outbound_phone || '--'}</td>
              <td>
                {value?.type === 'windsoft'
                  ? 'EZconnect'
                  : value?.type === 'ity'
                  ? 'ITY'
                  : 'Stringee'}
              </td>
              <td>{value?.user?.user_enterprise_info?.office_name || '--'}</td>
              {/* <td>{value?.is_active == 1 ? 'Đang hoạt động' : 'Ngừng hoạt động'}</td> */}
              <td>
                <i
                  className="btnEdit fa fa-fw fa-edit"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      id: value.id,
                      modalAddHotline: true,
                      modal: {
                        ...this.state.modal,
                        type:
                          value?.type === 'windsoft'
                            ? SWITCHBOARD_HOTLINE.EZCONNECT
                            : value?.type === 'ity'
                            ? SWITCHBOARD_HOTLINE.ITY
                            : SWITCHBOARD_HOTLINE.STRINGEE,
                        outbound_phone: value.outbound_phone,
                        telecom: value.telecom.split(','),
                        count_to_change: value.count_to_change,
                        enterprise: value.user ? value.user?.id : '',
                        domain: value.domain,
                        gateway_id: value.gateway_id,
                        api_keyid: value.api_keyid,
                        api_keysec: value.api_keysec,
                        api_account_keyid: value.api_account_keyid,
                        api_account_keysec: value.api_account_keysec,
                        uri:
                          value?.type === 'ity'
                            ? value.uri
                            : defaultValueFormITY.uri,
                        extension: value.extension,
                        pass: value.pass,
                      },
                      tempNumber: value.outbound_phone,
                    });
                  }}
                ></i>
                <i
                  className="far fa-trash-alt step-icon-del"
                  onClick={() => {
                    this.setState({
                      id: value.id,
                      modalDeleteHotline: true,
                    });
                  }}
                ></i>
              </td>
            </tr>
          ))
        ) : (
          <td colspan={5}>
            <Empty description="Không có dữ liệu"></Empty>
          </td>
        )}
      </>
    );
  }

  addModalHotline = () => {
    const { modalAddHotline, modal, listCompany, id, switchboard } = this.state;
    console.log('add hotline', this.state);
    console.log('modal.type', modal);
    return (
      <Modal
        show={modalAddHotline}
        onHide={() => this.handleClearDataModal('modalAddHotline', false)}
        dialogClassName="modal-90w"
        centered
      >
        <ContainerStyled>
          <Modal.Header closeButton className="bg-primary pb-0">
            <h5 className="text-light">
              {!id ? 'Thêm hotline' : 'Sửa Hotline'}
            </h5>
          </Modal.Header>
          <Modal.Body className="custom-body">
            <FormGroup className="text-left">
              <Row className="modal-row">
                <label>Đầu số</label>
              </Row>
              <Row className="modal-row">
                <FormControl
                  type="number"
                  max={12}
                  placeholder="Nhập đầu số"
                  value={modal.outbound_phone}
                  name="hotlinenumber"
                  onChange={e => {
                    e.target.value.length > 12
                      ? notifyFail('Vui lòng nhập đầu số từ 8 đến 12 chữ số')
                      : this.handleChangeModalField(
                          'outbound_phone',
                          e.target.value
                        );
                  }}
                ></FormControl>
              </Row>
            </FormGroup>

            <FormGroup className="text-left">
              <Row className="modal-row">
                <label>Các nhà mạng có thể gọi</label>
              </Row>
              <Row className="modal-row">
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Chọn đầu số"
                  defaultValue={modal.telecom}
                  onChange={this.handleChange}
                  disabled={!id ? false : true}
                >
                  {optionsListTelecom}
                </Select>
              </Row>
            </FormGroup>

            <FormGroup className="text-left">
              <Row className="modal-row">
                <label>Số lần gọi</label>
              </Row>
              <Row className="modal-row">
                <FormControl
                  min={0}
                  placeholder="Nhập số lần gọi"
                  value={formatPrice(modal.count_to_change)}
                  // value={modal.count_to_change}
                  name="note"
                  onChange={e => {
                    const REGEX = /^\d+$/;
                    console.log(
                      'regex',
                      REGEX.test(e.target.value.replaceAll(',', ''))
                    );
                    if (REGEX.test(e.target.value.replaceAll(',', ''))) {
                      this.handleChangeModalField(
                        'count_to_change',
                        e.target.value.replaceAll(',', '')
                      );
                    }
                  }}
                ></FormControl>
              </Row>
            </FormGroup>

            <FormGroup className="text-left">
              <Row className="modal-row">
                <label>Công ty</label>
              </Row>
              <Row className="modal-row">
                <Select
                  style={{ width: '100%' }}
                  placeholder="Chọn công ty"
                  defaultValue={
                    modal.enterprise ? modal.enterprise : 'Chọn công ty'
                  }
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={value => {
                    this.handleChangeModalField('enterprise', value);
                  }}
                  // disabled={!id ? false : true}
                >
                  {listCompany?.length > 0 &&
                    listCompany?.map(value => (
                      <Option value={value?.id}>{value?.full_name}</Option>
                    ))}
                </Select>
              </Row>
            </FormGroup>

            <FormGroup className="text-left">
              <Row className="modal-row">
                <label>Tổng đài</label>
              </Row>
              <Row className="modal-row">
                <FormControl
                  as="select"
                  id=""
                  value={modal.type === 'windsoft' ? 1 : modal.type}
                  // defaultValue={''}
                  onChange={e => {
                    this.handleChangeModalField('type', Number(e.target.value));
                  }}
                >
                  <option value={SWITCHBOARD_HOTLINE.STRINGEE}>Stringee</option>
                  {/* <option value={SWITCHBOARD_HOTLINE.EZCONNECT}>Ezconnect</option> */}
                  <option value={SWITCHBOARD_HOTLINE.ITY}>ITY</option>
                </FormControl>
              </Row>
            </FormGroup>

            {modal.type == 1 || modal.type === 'windsoft' ? (
              <>
                <FormGroup className="text-left">
                  <Row className="modal-row">
                    <label>Domain</label>
                  </Row>
                  <Row className="modal-row">
                    <FormControl
                      type="text"
                      // placeholder="Nhập đầu số"
                      value={modal.domain}
                      name="domain"
                      onChange={e =>
                        this.handleChangeModalField('domain', e.target.value)
                      }
                    ></FormControl>
                  </Row>
                </FormGroup>
                <FormGroup className="text-left">
                  <Row className="modal-row">
                    <label>Gateway_id</label>
                  </Row>
                  <Row className="modal-row">
                    <FormControl
                      type="text"
                      // placeholder="Nhập đầu số"
                      value={modal.gateway_id}
                      name="gateway_id"
                      onChange={e =>
                        this.handleChangeModalField(
                          'gateway_id',
                          e.target.value
                        )
                      }
                    ></FormControl>
                  </Row>
                </FormGroup>
              </>
            ) : // cấu hình ity
            modal.type === SWITCHBOARD_HOTLINE.ITY ? (
              <>
                <FormGroup className="text-left">
                  <Row className="modal-row">
                    <label>URI</label>
                  </Row>
                  <Row className="modal-row">
                    <FormControl
                      type="text"
                      placeholder="Nhập uri"
                      value={modal.uri || ''}
                      name="uri"
                      onChange={e =>
                        this.handleChangeModalField('uri', e.target.value)
                      }
                    ></FormControl>
                  </Row>
                </FormGroup>

                <FormGroup className="text-left">
                  <Row className="modal-row">
                    <label>Domain</label>
                  </Row>
                  <Row className="modal-row">
                    <FormControl
                      type="text"
                      placeholder="Nhập domain"
                      value={modal.domain || ''}
                      name="domain"
                      onChange={e =>
                        this.handleChangeModalField('domain', e.target.value)
                      }
                    ></FormControl>
                  </Row>
                </FormGroup>

                <FormGroup className="text-left">
                  <Row className="modal-row">
                    <label>Extension</label>
                  </Row>
                  <Row className="modal-row">
                    <FormControl
                      type="text"
                      placeholder="Nhập extension"
                      value={modal.extension || ''}
                      name="extension"
                      onChange={e =>
                        this.handleChangeModalField('extension', e.target.value)
                      }
                    ></FormControl>
                  </Row>
                </FormGroup>

                <FormGroup className="text-left">
                  <Row className="modal-row">
                    <label>Pass</label>
                  </Row>
                  <Row className="modal-row">
                    <FormControl
                      type="text"
                      placeholder="Nhập pass"
                      value={modal.pass || ''}
                      name="pass"
                      onChange={e =>
                        this.handleChangeModalField('pass', e.target.value)
                      }
                    ></FormControl>
                  </Row>
                </FormGroup>
              </>
            ) : (
              // cấu hình stringee
              <>
                <FormGroup className="text-left">
                  <Row className="modal-row">
                    <label>API KEYSID</label>
                  </Row>
                  <Row className="modal-row">
                    <FormControl
                      type="text"
                      placeholder="Nhập api_keyid"
                      value={modal.api_keyid || ''}
                      name="api_keyid"
                      onChange={e =>
                        this.handleChangeModalField('api_keyid', e.target.value)
                      }
                    ></FormControl>
                  </Row>
                </FormGroup>

                <FormGroup className="text-left">
                  <Row className="modal-row">
                    <label>API KEYSEC</label>
                  </Row>
                  <Row className="modal-row">
                    <FormControl
                      type="text"
                      placeholder="Nhập api_keysec"
                      value={modal.api_keysec || ''}
                      name="api_keysec"
                      onChange={e =>
                        this.handleChangeModalField(
                          'api_keysec',
                          e.target.value
                        )
                      }
                    ></FormControl>
                  </Row>
                </FormGroup>

                <FormGroup className="text-left">
                  <Row className="modal-row">
                    <label>API ACCOUNT KEYSID</label>
                  </Row>
                  <Row className="modal-row">
                    <FormControl
                      type="text"
                      placeholder="Nhập api_account_keyid"
                      value={modal.api_account_keyid || ''}
                      name="api_account_keyid"
                      onChange={e =>
                        this.handleChangeModalField(
                          'api_account_keyid',
                          e.target.value
                        )
                      }
                    ></FormControl>
                  </Row>
                </FormGroup>

                <FormGroup className="text-left">
                  <Row className="modal-row">
                    <label>API ACCOUNT KEYSEC</label>
                  </Row>
                  <Row className="modal-row">
                    <FormControl
                      type="text"
                      placeholder="Nhập api_keysec"
                      value={modal.api_account_keysec || ''}
                      name="api_account_keysec"
                      onChange={e =>
                        this.handleChangeModalField(
                          'api_account_keysec',
                          e.target.value
                        )
                      }
                    ></FormControl>
                  </Row>
                </FormGroup>
              </>
            )}
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button
              variant="success"
              className="text-light"
              onClick={() => {
                // debugger
                if (
                  modal?.count_to_change &&
                  modal?.enterprise &&
                  modal?.telecom.length > 0
                ) {
                  if (!modal?.outbound_phone) {
                    notifyFail('Vui lòng điền số điện thoại');
                  }
                  if (
                    (modal.type === SWITCHBOARD_HOTLINE.EZCONNECT ||
                      modal.type === 'windsoft') &&
                    (!modal?.domain || !modal?.gateway_id)
                  ) {
                    if (!modal?.domain) {
                      notifyFail('Vui lòng điền Domain');
                    }
                    if (!modal?.gateway_id) {
                      notifyFail('Vui lòng điền Gateway_id');
                    }
                    return;
                  }
                  if (
                    (modal.type === SWITCHBOARD_HOTLINE.STRINGEE ||
                      !modal.type) &&
                    (!modal?.api_account_keyid ||
                      !modal?.api_account_keysec ||
                      !modal?.api_keyid ||
                      !modal?.api_keysec)
                  ) {
                    if (!modal?.api_keyid) {
                      notifyFail('Vui lòng điền API KEYSID');
                    }
                    if (!modal?.api_keysec) {
                      notifyFail('Vui lòng điền API KEYSEC');
                    }
                    if (!modal?.api_account_keyid) {
                      notifyFail('Vui lòng điền API ACCOUNT KEYSID');
                    }
                    if (!modal?.api_account_keysec) {
                      notifyFail('Vui lòng điền API ACCOUNT KEYSEC');
                    }
                    return;
                  }
                  !id
                    ? this.requestCreateHotline()
                    : this.requestUpdateHotline();
                } else {
                  if (
                    !modal?.count_to_change &&
                    !modal?.enterprise &&
                    !modal?.telecom.length < 1
                  ) {
                    notifyFail('Vui lòng điền đầy đủ thông tin');
                  } else {
                    if (!modal?.count_to_change) {
                      notifyFail('Vui lòng điền số lần gọi');
                    }
                    if (!modal?.enterprise) {
                      notifyFail('Vui lòng điền tên công ty');
                    }
                    if (modal?.telecom.length < 1) {
                      notifyFail('Vui lòng điền các nhà mạng có thể gọi');
                    }
                  }
                }
              }}
            >
              {!id ? 'Thêm mới' : 'Cập nhật'}
            </Button>
            <Button
              variant="danger"
              className="text-light"
              onClick={() =>
                this.handleClearDataModal('modalAddHotline', false)
              }
            >
              Đóng
            </Button>
          </Modal.Footer>
        </ContainerStyled>
      </Modal>
    );
  };

  renderDeleteHotline() {
    const { modalDeleteHotline } = this.state;
    return (
      <Modal
        show={modalDeleteHotline}
        onHide={() =>
          this.setState({
            modalDeleteHotline: false,
          })
        }
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton className="bg-primary pb-0">
          <h5 className="text-light">Bạn chắc chắn muốn xóa ?</h5>
        </Modal.Header>
        <Modal.Body className="custom-body">
          <Row>
            <Col className="button-wrapper" style={{ textAlign: 'center' }}>
              <Button
                variant="success"
                className="text-light"
                onClick={() => {
                  this.requestDeleteHotline();
                }}
              >
                Có
              </Button>
              <Button
                variant="danger"
                className="text-light"
                onClick={() => {
                  this.setState({
                    modalDeleteHotline: false,
                  });
                }}
              >
                Thoát
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  render() {
    const { isLoading } = this.state;
    console.log('this.state.modal', this.state.modal);
    return (
      <>
        {isLoading && <Loading />}
        {this.renderBody()}
        {this.addModalHotline()}
        {this.renderDeleteHotline()}
      </>
    );
  }
}

const mapStateToProps = state => ({
  listHotlineState: state.listHotline,
});

const mapDispatchToProps = {
  getListHotline,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FirstNumber));
