import {
  requestCreateJobExpress,
  requestJobList,
  requestListSubJob,
  requestRoleBackJob,
  requestSaleAssignExpress,
  requestTypeJobList,
} from '@constants/Api';
import { ROUTER } from '@constants/Constant';
import {
  formatPrice,
  formatTime,
  getFirstLetter,
  randomColor,
  rmVN,
} from '@constants/funcHelper';
import { primaryColor } from '@styles/Color';
import { ModalStyle } from '@styles/Style';
import { notifyFail, notifySuccess } from '@utils/notify';
import {
  Avatar,
  Button,
  Checkbox,
  Col,
  Empty,
  Input,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import useDebounce from 'src/hooks/useDebounce';
import styled from 'styled-components';
import CreateJobModal from './CreateJobModal';
import FilterAssignmentModal from './FilterAssignmentModal';

const { Title, Paragraph } = Typography;
const { Option } = Select;

const InputCountCustomer = styled(Input)`
  width: 90px;
`;
const TitleFilterStyle = styled.div`
  width: 150px;
  font-weight: 500;
`;

const RowFilterStyle = styled(Row)`
  margin: 0 0 10px 0;
`;

const TableStyle = styled(Table)`
  .ant-table-cell {
    padding: 5px 10px;
  }
`;

const CheckboxStyle = styled(Checkbox)`
  height: 40px;
  .ant-checkbox-inner {
    height: 20px;
    width: 20px;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    height: 12px;
    width: 8px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ef8737;
    border-color: #ef8737;
  }
`;

const renderAvatarWithText = (value, size) => {
  return (
    <Avatar
      style={{
        backgroundColor: randomColor(getFirstLetter(value)),
        marginRight: '5px',
      }}
      size={size}
    >
      {getFirstLetter(value)}
    </Avatar>
  );
};

const FileAddOutlined = () => (
  <svg width="25px" height="25px" fill="currentColor" viewBox="0 0 1024 1024">
    <path d="M480 580H372a8 8 0 00-8 8v48a8 8 0 008 8h108v108a8 8 0 008 8h48a8 8 0 008-8V644h108a8 8 0 008-8v-48a8 8 0 00-8-8H544V472a8 8 0 00-8-8h-48a8 8 0 00-8 8v108zm374.6-291.3c6 6 9.4 14.1 9.4 22.6V928c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32V96c0-17.7 14.3-32 32-32h424.7c8.5 0 16.7 3.4 22.7 9.4l215.2 215.3zM790.2 326L602 137.8V326h188.2z" />
  </svg>
);

const FilterOutlined = () => (
  <svg width="25px" height="25px" fill="currentColor" viewBox="0 0 1024 1024">
    <path d="M349 838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V642H349v196zm531.1-684H143.9c-24.5 0-39.8 26.7-27.5 48l221.3 376h348.8l221.3-376c12.1-21.3-3.2-48-27.7-48z" />
  </svg>
);

const IS_FULL_CUSTOMER = 1;

const AssignmentModal = props => {
  const {
    isVisible,
    setIsVisible,
    customerSearchParameters,
    dataRecover,
    listFilterFieldConfigGetAPI,
    setVisibleFilterConfiguration,
    setVisibleDisplayConfiguration,
    customerIds,
    user,
    //select all
    isSelectAll,
    totalCustomer,
  } = props;

  const [isAssign, setIsAssign] = useState(true);
  const [isRecover, setIsRecover] = useState(false);

  const [isSaleConfirm, setIsSaleConfirm] = useState(true);

  const [isVisibleCreateJob, setIsVisibleCreateJob] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCreateJob, setIsLoadingCreateJob] = useState(false);
  const [isLoadingRecover, setIsLoadingRecover] = useState(false);
  const [isLoadingGetListSale, setIsLoadingGetListSale] = useState(false);

  const [isVisibleFilter, setIsVisibleFilter] = useState(false);

  const [acceptText, setAcceptText] = useState();
  const [selectedAssignRows, setSelectedAssignRows] = useState([]);
  const [selectedRecoveryRows, setSelectedRecoveryRows] = useState([]);
  const [idJob, setIdJob] = useState();
  const [listJob, setListJob] = useState([]);
  const [listSubJob, setListSubJob] = useState([]);
  const [listSale, setListSale] = useState([]);
  const [selectedSales, setSelectedSales] = useState();
  const [checkedSales, setCheckedSales] = useState();
  const [selectedJob, setSelectedJob] = useState();
  const [nameJob, setNameJob] = useState(
    `Giao việc nhanh ${formatTime(new Date(), 'DD/MM/YYYY')}`
  );
  const [listTypeOfJob, setListTypeOfJob] = useState([]);
  // const [selectTableAll, setSelectTableAll] = useState(false);
  const [selectTableAllRecover, setSelectTableAllRecover] = useState(false);
  const [totalCountCustomer, setTotalCountCustomer] = useState({});
  const [params, setParams] = useState({
    search: '',
    province_ids: [],
    telesale_id: [],
  });
  const [paramsSubJob, setParamsSubJob] = useState({
    search: '',
    list_customer_id: customerIds,
  });

  const debouncedSearchTerm = useDebounce(paramsSubJob.search, 500);

  useEffect(() => {
    setTotalCountCustomer(parseInt(_.sumBy(checkedSales, 'amount')));
  }, [checkedSales]);

  useEffect(() => {
    // if (selectTableAll) {
    //   handleSelectAllRow();
    // }
    if (!selectedSales?.length) {
      setSelectedSales(listSale);
    }
  }, [dataRecover, selectedSales]);

  useEffect(() => {
    if (isAssign && isRecover) {
      setAcceptText('Thu hồi và giao việc');
    }
    if (isAssign && !isRecover) {
      setAcceptText('Giao việc');
    }
    if (isRecover && !isAssign) {
      setAcceptText('Thu hồi');
    }
  }, [isAssign, isRecover]);

  useEffect(() => {
    getListTypeJob();
  }, []);

  useEffect(() => {
    setParamsSubJob(prevState => ({
      ...prevState,
      list_customer_id: customerIds,
    }));
  }, [customerIds]);

  useEffect(() => {
    if (isVisible) {
      getListSubJob();
    }
  }, [debouncedSearchTerm, isVisible]);

  useEffect(() => {
    getListSale();
  }, [params, isVisible]);

  useEffect(() => {
    getListJob();
  }, [isVisibleCreateJob]);

  useEffect(() => {
    if (isLoadingCreateJob || isLoadingRecover || isLoadingGetListSale) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoadingCreateJob, isLoadingRecover, isLoadingGetListSale]);

  // const handleSelectAllRow = () => {
  //   if (dataRecover) {
  //     const selectAllRows = dataRecover.map((r, index) => r.key);
  //     setSelectedRecoveryRows(selectAllRows);
  //   }
  //   if (selectedSales) {
  //     const selectAllRows = selectedSales.map((r, index) => r.key);
  //     setSelectedAssignRows(selectAllRows);
  //   }
  // };

  const handleSelectSale = value => {
    if (value) {
      console.log('valueeee', value);
      const data = value.map(item => ({
        key: JSON.parse(item).telesale_id,
        telesale_id: JSON.parse(item).telesale_id,
        full_name: JSON.parse(item).full_name,
        amount: 1,
        is_default: 0,
        profiles: item.profiles,
        total_customer: JSON.parse(item).total_customer,
      }));
      setSelectedSales(data);
    }
  };

  const handleChangeAmount = (saleId, value) => {
    setCheckedSales(prev =>
      prev.map(v => {
        if (v.telesale_id == saleId) {
          return { ...v, amount: value };
        }
        return v;
      })
    );
  };

  const handleDeleteSelectedSale = saleId => {
    setCheckedSales(prev =>
      prev.filter(item => {
        if (item.telesale_id !== saleId) {
          return item;
        }
      })
    );
    setSelectedAssignRows(prev =>
      prev.filter(item => {
        if (item !== saleId) {
          return item;
        }
      })
    );
  };

  const handleSelectJob = value => {
    if (value) {
      setIdJob(JSON.parse(value)?.id);
    }
  };

  const handleCheckTableAssign = (
    selectedRowKeys,
    selectedRows,
    amountCustomer
  ) => {
    let avgCustomerAmount = Math.floor(amountCustomer / selectedRows?.length);

    let remainCustomerCount = amountCustomer % selectedRows.length;

    selectedRows.forEach(r => {
      r.amount = avgCustomerAmount;
      if (remainCustomerCount > 0) {
        r.amount += 1;
        remainCustomerCount--;
      }
    });

    setSelectedAssignRows([...selectedRowKeys]);
    setCheckedSales([...selectedRows]);
    // selectTableAll && setSelectTableAll(false);
  };

  const handleCheckTableRecover = (selectedRowKeys, selectedRows) => {
    let ids = [];
    selectedRows.forEach(selectedRow => {
      selectedRow.job_telesales.forEach(el => {
        ids.push(el.id);
      });
    });
    setSelectedRecoveryRows([...selectedRowKeys]);
    setSelectedJob(ids);
    selectTableAllRecover && setSelectTableAllRecover(false);
  };

  const getListJob = async () => {
    try {
      const res = await requestJobList({
        page: '',
        limit: 1000,
        search: '',
        job_category_id: '',
        status: '',
        enterprise_id: '',
        job_status: '',
        fromDate: '',
        toDate: '',
      });
      const data = res.data.map(job => ({
        ...job,
        key: job.id,
        full_name: job.name,
      }));
      setListJob(data);
    } catch (error) {
      console.error('error', error);
    }
  };

  const getListSubJob = async () => {
    setIsLoadingRecover(true);
    try {
      const payload = paramsSubJob;

      if (isSelectAll) {
        payload.list_customer_id = [];
      }

      const res = await requestListSubJob(payload);
      const data = res.data.map((job, index) => ({
        ...job,
        key: job.job_telesales[0].id,
      }));
      setListSubJob(data);
    } catch (error) {
      console.error('error', error);
    } finally {
      setIsLoadingRecover(false);
    }
  };

  const getListTypeJob = async () => {
    try {
      const res = await requestTypeJobList();
      const data = res.data.map((item, index) => ({
        id: item.id,
        name: item.name,
      }));
      setListTypeOfJob(data);
    } catch (error) {
      console.error('error', error);
    }
  };

  const getListSale = async () => {
    setIsLoadingGetListSale(true);
    try {
      const res = await requestSaleAssignExpress(params);
      const listOfSelectedSales = res.data.map(item => ({
        ...item,
        key: item.telesale_id,
        telesale_id: item.telesale_id,
        full_name: item.name,
        amount: 1,
        is_default: 0,
      }));
      setListSale(listOfSelectedSales);
      setSelectedSales(listOfSelectedSales);
    } catch (error) {
      console.error('error get list sale', error);
    } finally {
      setIsLoadingGetListSale(false);
    }
  };

  const createJobFast = async () => {
    setIsLoadingCreateJob(true);
    let dataPush = {
      parent_id: idJob,
      name: nameJob.trim(),
      customer_ids: customerIds,
      is_require_accept: isSaleConfirm ? 1 : 0,
      job_telesales: checkedSales,
    };

    if (isSelectAll) {
      dataPush.customer_ids = [];
      dataPush.full_customer_ids = IS_FULL_CUSTOMER;
    }

    try {
      // const res = await requestCreateJobExpress(dataPush);
      const res = await requestCreateJobExpress({
        params: customerSearchParameters,
        payload: dataPush,
      });

      notifySuccess(res?.msg);
      handleCloseModal();
      mixpanel.track(`Assign Job express: ${nameJob.trim()}`);
    } catch (error) {
      notifyFail(error?.msg);
      console.log('error', error);
    }
    setIsLoadingCreateJob(false);
  };

  const recoverJob = async () => {
    if (!selectedJob) {
      notifyFail('Cần tối thiểu 1 giao việc để thực hiện!');
      return;
    }

    let dataPush = {
      list_customer_id: customerIds,
      job_telesale_ids: selectedJob,
    };

    if (isSelectAll) {
      dataPush.list_customer_id = [];
      dataPush.full_customer_ids = IS_FULL_CUSTOMER;
    }

    try {
      setIsLoadingRecover(true);
      if (selectedJob.length) {
        // const res = await requestRoleBackJob(dataPush);
        const res = await requestRoleBackJob({
          params: customerSearchParameters,
          payload: dataPush,
        });
        notifySuccess(res?.msg);
        handleCloseModal();
        mixpanel.track(`Recovery ${selectedJob.length} job express `);
      } else {
        notifyFail('Vui lòng chọn một công việc để thu hồi!');
      }
    } catch (error) {
      notifyFail(error?.msg);
      console.log('error', error);
    }
    setIsLoadingRecover(false);
  };

  const handleCreateJobFast = () => {
    if (isAssign && isRecover) {
      createJobFast();
      recoverJob();
    }
    if (isAssign && !isRecover) {
      createJobFast();
    }
  };

  const renderCustomer = record => {
    const count = record?.length;
    const name = record?.[0]?.name || '(Chưa cập nhật)';
    const placeholder = String(name)?.trim().split(' ');
    const listName = record?.map((item, index) => {
      return <div key={index}>{item?.name || '(Chưa cập nhật)'}</div>;
    });
    const tooltipTitle = () => {
      return (
        <>
          {listName.slice(1, 16)}
          {count > 16 && <div>và {count - 16} khánh hàng khác</div>}
        </>
      );
    };
    return (
      <Avatar.Group>
        <>
          <Tooltip title={name}>{renderAvatarWithText(placeholder)}</Tooltip>
          {count > 1 ? (
            <Tooltip placement="bottom" title={tooltipTitle}>
              <Avatar
                style={{
                  backgroundColor: 'lightblue',
                  marginRight: '5px',
                }}
              >
                +{count - 1}
              </Avatar>
            </Tooltip>
          ) : null}
        </>
      </Avatar.Group>
    );
  };

  const recoverColumn = [
    {
      title: <b>Công việc</b>,
      dataIndex: '',
      key: '',
      render: (value, record) => {
        return (
          <Paragraph
            style={{ textAlign: 'start' }}
            ellipsis={{ tooltip: record.job_category.name }}
          >
            {record.job_category.name}
          </Paragraph>
        );
      },
    },
    {
      title: <b>Giao việc</b>,
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => {
        return (
          <Paragraph
            style={{ textAlign: 'start' }}
            ellipsis={{ tooltip: value }}
          >
            {value}
          </Paragraph>
        );
      },
    },
    {
      title: <b>Tên khách hàng</b>,
      dataIndex: '',
      key: '',
      render: (value, record) => {
        return renderCustomer(record?.job_telesales?.[0].list_customers);
      },
    },
  ];

  const renderAvatarSale = (record, name) => {
    return record.profiles ? (
      <>
        <Avatar src={record.profiles} size="small" />
        &nbsp;
      </>
    ) : (
      renderAvatarWithText(name, 'small')
    );
  };

  const assignColumn = [
    {
      width: '70%',
      title: <b>Họ tên</b>,
      dataIndex: 'full_name',
      key: 'full_name',
      render: (value, record) => {
        const name = value ? value.trim().split(' ') : 'N';
        return (
          <div style={{ fontSize: '16px', textAlign: 'start' }}>
            {value ? (
              <>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${ROUTER.TELESALE_DETAIL}/${record.key}`}
                >
                  {renderAvatarSale(record, name)}
                  <Tooltip title={value}>{value ? value : '---'}</Tooltip>
                </a>
              </>
            ) : (
              '---'
            )}
          </div>
        );
      },
    },
    {
      width: '30%',
      title: <b>Số khách hàng được giao</b>,
      dataIndex: 'total_customer',
      key: 'total_customer',
      render: (value, record) => {
        return <Row justify="start">{formatPrice(value)}</Row>;
      },
    },
  ];

  const handleOnAccept = () => {
    // if (
    //   idJob &&
    //   selectedSales.length > 0 &&
    //   nameJob.trim() &&
    //   customerIds.length == totalCountCustomer
    // ) {
    //   handleCreateJobFast();
    // }
    if (!idJob) {
      notifyFail('Vui lòng chọn công việc!');
      return;
    }
    if (selectedSales.length === 0) {
      notifyFail('Cần tối thiểu 1 sale để thực hiện!');
      return;
    }

    if (selectedAssignRows.length === 0) {
      notifyFail('Cần tối thiểu 1 sale để thực hiện!');
      return;
    }

    if (!nameJob.trim()) {
      notifyFail('Không được để trống nội dung giao việc!');
      return;
    }
    if (!isSelectAll && customerIds.length != totalCountCustomer) {
      notifyFail(
        'Tổng số khách hàng giao cho sale phải bằng số khách hàng đã chọn!'
      );
      return;
    }
    handleCreateJobFast();
  };

  const handleCloseModal = () => {
    setIdJob();
    setIsVisible(false);
    setIsAssign(true);
    setIsRecover(false);
    setSelectedAssignRows([]);
    setSelectedRecoveryRows([]);
    setCheckedSales();
    setSelectedJob();
    setSelectedSales(listSale);
    setIsSaleConfirm(true);
    setNameJob(`Giao việc nhanh ${formatTime(new Date(), 'DD/MM/YYYY')}`);
    setParams({
      search: '',
      province_ids: [],
      telesale_id: [],
    });
  };

  const renderFooterModal = () => {
    return (
      <Row justify="end">
        <Button danger onClick={handleCloseModal}>
          Huỷ
        </Button>
        {!isRecover && !isAssign ? null : (
          <>
            &nbsp;
            <Button
              disabled={isLoading ? true : false}
              style={{ backgroundColor: primaryColor, color: 'white' }}
              onClick={() => {
                if (isRecover && !isAssign) {
                  recoverJob();
                } else {
                  handleOnAccept();
                }
              }}
            >
              {acceptText}
            </Button>
          </>
        )}
      </Row>
    );
  };

  const renderCheckboxRecover = () => {
    return (
      <>
        <CheckboxStyle
          checked={isRecover}
          onChange={() => {
            setIsRecover(!isRecover);
          }}
        >
          <Title level={5} style={{ color: primaryColor }}>
            Thu hồi
          </Title>
        </CheckboxStyle>
      </>
    );
  };

  const renderCheckboxAssign = () => {
    return (
      <>
        <CheckboxStyle
          checked={isAssign}
          onChange={() => {
            setIsAssign(!isAssign);
          }}
        >
          <Title level={5} style={{ color: primaryColor }}>
            Giao việc
          </Title>
        </CheckboxStyle>
      </>
    );
  };

  const tableRecover = () => {
    return (
      <>
        <Input.Search
          placeholder="Tên công việc, giao việc..."
          allowClear
          onChange={e => {
            setParamsSubJob({
              ...paramsSubJob,
              search: e.target?.value.trim(),
            });
          }}
          style={{ width: '50%', marginBottom: 10 }}
        />
        <TableStyle
          columns={recoverColumn}
          loading={isLoadingRecover}
          dataSource={listSubJob}
          rowSelection={{
            type: 'select',
            selectedRowKeys: selectedRecoveryRows,
            preserveSelectedRowKeys: true,
            onChange: (selectedRowKeys, selectedRows) => {
              handleCheckTableRecover(selectedRowKeys, selectedRows);
            },
          }}
          scroll={{
            y: 200,
          }}
          pagination={false}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu.</span>}
              />
            ),
            triggerDesc: '',
            triggerAsc: '',
            cancelSort: '',
          }}
        />
      </>
    );
  };

  const multipleSelect = (
    placeholder,
    mode,
    listOption,
    onChange,
    allowClear
  ) => {
    return (
      <Select
        placeholder={placeholder}
        mode={mode}
        optionFilterProp="children"
        allowClear={allowClear}
        showSearch
        style={{ width: '50%' }}
        filterOption={(input, option) => {
          return rmVN(option.children).indexOf(rmVN(input)) >= 0;
        }}
        maxTagCount={1}
        value={props.params?.group_id}
        notFoundContent={
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>Không có dữ liệu!</span>}
          />
        }
        onChange={value => {
          onChange(value);
        }}
      >
        {listOption?.length > 0
          ? listOption.map((item, index) => (
              <Option key={index} value={JSON.stringify(item)}>
                {item.full_name}
              </Option>
            ))
          : null}
      </Select>
    );
  };

  const renderFilter = () => {
    return (
      <>
        <RowFilterStyle>
          <TitleFilterStyle>Công việc: </TitleFilterStyle>
          {multipleSelect('Chọn công việc', null, listJob, handleSelectJob)}
          &nbsp;
          <Tooltip title="Tạo mới">
            <Button
              type="text"
              icon={<FileAddOutlined />}
              onClick={() => {
                setIsVisibleCreateJob(true);
              }}
            />
          </Tooltip>
        </RowFilterStyle>
        <RowFilterStyle>
          <TitleFilterStyle>Nội dung giao việc: </TitleFilterStyle>
          <Input
            allowClear
            value={nameJob}
            style={{ width: '50%' }}
            onChange={e => {
              setNameJob(e.target.value);
            }}
          />
        </RowFilterStyle>
        <RowFilterStyle>
          <TitleFilterStyle>Sale chăm sóc: </TitleFilterStyle>
          {multipleSelect(
            'Chọn sale chăm sóc',
            'multiple',
            listSale,
            handleSelectSale,
            true
          )}
          &nbsp;
          <Tooltip title="Bộ lọc">
            <Button
              type="text"
              icon={<FilterOutlined />}
              onClick={() => {
                setIsVisibleFilter(true);
              }}
            />
          </Tooltip>
        </RowFilterStyle>
      </>
    );
  };

  const tableAssign = () => {
    return (
      <>
        <TableStyle
          columns={assignColumn}
          dataSource={selectedSales}
          loading={isLoading}
          rowSelection={{
            type: 'select',
            selectedRowKeys: selectedAssignRows,
            preserveSelectedRowKeys: true,
            onChange: (selectedRowKeys, selectedRows) => {
              handleCheckTableAssign(
                selectedRowKeys,
                selectedRows,
                isSelectAll ? totalCustomer : customerIds.length
              );
            },
          }}
          scroll={{
            y: 200,
          }}
          pagination={false}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu.</span>}
              />
            ),
            triggerDesc: '',
            triggerAsc: '',
            cancelSort: '',
          }}
        />
        <br />
      </>
    );
  };

  const listSelectedSale = () => {
    return (
      <Row style={{ width: '100%' }}>
        {checkedSales
          ? checkedSales.map((item, index) => (
              <>
                <div style={{ width: '49.5%' }}>
                  <Row justify="space-between" style={{ margin: '0 0 10px 0' }}>
                    <div style={{ maxWidth: 220 }}>
                      <Paragraph
                        style={{ textAlign: 'start' }}
                        ellipsis={{ tooltip: item.full_name }}
                      >
                        {renderAvatarWithText(
                          item.full_name?.trim().split(' '),
                          'small'
                        )}
                        {item.full_name}
                      </Paragraph>
                    </div>
                    <Col>
                      <InputCountCustomer
                        value={item.amount}
                        onChange={e => {
                          if (
                            e.target.value >= 0 &&
                            !e.target.value.includes('.')
                          ) {
                            handleChangeAmount(
                              item.telesale_id,
                              parseInt(e.target.value) || 0
                            );
                          }
                        }}
                      />
                      <Button
                        type="text"
                        onClick={() => {
                          handleDeleteSelectedSale(item.telesale_id);
                        }}
                      >
                        <span>❌</span>
                      </Button>
                    </Col>
                  </Row>
                </div>
                {index % 2 === 0 ? (
                  <div
                    style={{
                      width: 1,
                      marginRight: 6,
                      backgroundColor: '#d7d7d7d9',
                    }}
                  ></div>
                ) : null}
              </>
            ))
          : null}
      </Row>
    );
  };

  const infoAssign = () => {
    return (
      <>
        <b>
          Số lượng khách hàng:{' '}
          {isSelectAll
            ? formatPrice(totalCustomer)
            : formatPrice(customerIds.length)}
        </b>
        <br />
        <b>Sale chăm sóc: </b>
        {listSelectedSale()}
        <br />
        <Checkbox
          checked={isSaleConfirm}
          onChange={() => {
            setIsSaleConfirm(!isSaleConfirm);
          }}
        >
          Giao trực tiếp không cần sale xác nhận.
        </Checkbox>
      </>
    );
  };
  return (
    <>
      <ModalStyle
        buttonCloseX={'none'}
        destroyOnClose
        visible={isVisible}
        title={
          <Title level={4} style={{ color: primaryColor }}>
            Giao cho sale chăm sóc
          </Title>
        }
        width={800}
        footer={renderFooterModal()}
      >
        <Spin spinning={isLoading}>
          {/* Thu hồi */}
          <div>
            {renderCheckboxRecover()}
            {isRecover ? <div>{tableRecover()}</div> : null}
          </div>
          <br />
          {/* Giao việc */}
          <div>
            {renderCheckboxAssign()}
            {isAssign ? (
              <>
                <div>
                  {renderFilter()}
                  {tableAssign()}
                </div>
                <div>{infoAssign()}</div>
              </>
            ) : null}
          </div>
          <CreateJobModal
            user={user}
            isVisible={isVisibleCreateJob}
            setIsVisible={setIsVisibleCreateJob}
            listTypeOfJob={listTypeOfJob}
          />
          <FilterAssignmentModal
            isVisibleFilter={isVisibleFilter}
            setIsVisibleFilter={setIsVisibleFilter}
            params={params}
            setParams={setParams}
            listFilterFieldConfigGetAPI={listFilterFieldConfigGetAPI}
            setVisibleFilterConfiguration={setVisibleFilterConfiguration}
            setVisibleDisplayConfiguration={setVisibleDisplayConfiguration}
          />
        </Spin>
      </ModalStyle>
    </>
  );
};
export default AssignmentModal;
