import React, { Component } from 'react';
import { requestJobDetail, requestDeleteJob, requestCheckCreateSubJob, requestJobDetailSimple } from '@constants/Api';
import { withRouter, Link } from 'react-router-dom';
import '@styles/RegisterRequest.css';
import { Row, Col, FormControl, Button, Nav, Table, ProgressBar, Modal } from 'react-bootstrap';
import { ROUTER, STRING, JOB_STATUS } from '@constants/Constant';
import reactotron from 'reactotron-react-js';
import { toDateString } from '@utils/ToDateString';
import { Empty } from 'antd';
import moment from 'moment';
import { Switch, Progress } from 'antd';
import { getItemFromId } from '@utils/getItemFromId';
import { notifyFail, notifySuccess } from '@utils/notify';
import { getJobList } from '@src/redux/actions';
import Loading from '@src/components/Loading';

class ResultParent extends Component {
  constructor(props) {
    super();
    this.state = {
      modalDelete: false,
      modalEditField: {
        job_id: '',
      },
      item: {},
      isLoading: false,
    };
  }

  componentDidMount() {
    const { id } = this.props;
    this.getData(id);
  }

  async getData(id) {
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const res = await requestJobDetailSimple({ id: id });
      this.setState({
        ...this.state,
        item: res.data,
        isLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async requestDeleteJob() {
    const { job_id } = this.state.modalEditField;
    const { item } = this.state;
    const payload = {
      job_id: job_id || '',
    };

    try {
      await requestDeleteJob(payload);
      this.setState({ modalDelete: false });
      this.props.history.push(ROUTER.JOB);
      notifySuccess('Xóa thành công');
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  setShowModal = (modal, bool, data) => {
    this.setState({
      [modal]: bool,
    });
    if (data) {
      this.setState({
        modalEditField: {
          job_id: data?.job_id || '',
        },
      });
    }
  };

  deleteModal = () => {
    const { modalDelete, modalEditField } = this.state;
    return (
      <Modal
        show={modalDelete}
        onHide={() => this.setShowModal('modalDelete', false)}
        dialogClassName="modal-90w"
        centered
        scrollable
      >
        <Modal.Header closeButton className="text-white bg-primary" style={{ color: 'white' }}>
          <h5 className="text-light pb-0">Bạn chắc chắn muốn xóa?</h5>
        </Modal.Header>
        <Modal.Footer className="justify-content-end">
          <Button variant="success" className="text-light" onClick={() => this.requestDeleteJob()}>
            Có
          </Button>
          <Button variant="danger" className="text-light" onClick={() => this.setShowModal('modalDelete', false)}>
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderTitle() {
    const { item } = this.state;
    const { id } = this.props.match.params;
    return (
      <>
        <Row className="job-title">
          <Col md={9} className="mb-2 p-0">
            {/* <h2 className="d-inline">Công việc chung</h2> */}
            {/* <i className="fas fa-grip-lines-vertical px-4"></i> */}
            <h3 className="d-inline">{item?.name || '--'}</h3>
          </Col>
          <Col md={3} className="text-center text-md-right p-0">
            <div className="text-center text-md-right">
              {item?.isDelete == 1 ? (
                // <Col md={2} className="text-center text-md-right p-0">
                <Link>
                  <Button
                    variant="danger"
                    className="text-light"
                    style={{ marginBottom: 10 }}
                    onClick={() => {
                      this.setShowModal('modalDelete', true, item);
                    }}
                  >
                    Xóa
                  </Button>
                </Link>
              ) : (
                // </Col>
                ''
              )}
              <Link>
                <Button
                  variant="success"
                  className="text-light"
                  style={{ marginBottom: 10 }}
                  // onClick={() => this.props.history.push(ROUTER.JOB_GENERAL_ADD + `/${id}`)}
                  onClick={async () => {
                    try {
                      await requestCheckCreateSubJob({ job_id: this.props.id });
                      this.props.history.push({
                        pathname: ROUTER.JOB_GENERAL_ADD + `/${this.props.id}`,
                        state: { jobId: this.props.id },
                      });
                    } catch (error) {
                      // console.error('Exception ' + error);
                      notifyFail(error.msg || 'Không thể giao việc');
                    }
                  }}
                >
                  Giao việc
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
        {/* <div class="line"></div> */}
      </>
    );
  }

  renderBody() {
    const { item } = this.state;
    return (
      <Row>
        <Col md={12} className="p-0">
          <div className="w-100">
            <Row className="">
              <Col md={12} className="content p-0">
                <Row className=" text-center text-md-left">
                  <Col md={8} className="p-0">
                    <b className="m-0" style={{ fontSize: '16px' }}>
                      Danh sách giao việc
                    </b>
                  </Col>
                </Row>
                <Row>
                  {item?.subJob?.length > 0 ? (
                    <Table striped bordered hover responsive className="text-center">
                      <thead>
                        <th>STT</th>
                        <th>Tên giao việc</th>
                        <th>Nhóm</th>
                        <th>Tiến độ hoàn thành</th>
                      </thead>
                      <tbody>
                        {item?.subJob?.map((value, index) => (
                          <tr onClick={() => this.props.history.push(ROUTER.JOB_RESULT_CHILD + `/${value.id}`)}>
                            <td>{index + 1 || '--'}</td>
                            <td>{value?.name || '--'}</td>
                            <td>{value?.group_name || '--'}</td>
                            <td>
                              {value.countCustomer != 0 && value.countCustomer != null ? (
                                <Progress
                                  strokeColor="green"
                                  trailColor="#9e9e9e"
                                  percent={parseFloat((value.countCalled / value.countCustomer) * 100).toFixed(1)}
                                  style={{ marginTop: '5px' }}
                                />
                              ) : (
                                <Progress strokeColor="green" trailColor="#9e9e9e" style={{ marginTop: '5px' }} />
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <Empty description="Không có công việc đã giao" style={{ margin: 'auto' }}></Empty>
                  )}
                </Row>
                <Row className="mt-3">
                  <Col md className="text-center">
                    <p className="m-0">Giao việc</p>
                    <p className="m-0">
                      <span style={{ fontWeight: '600', fontSize: 35 }}>{item.subJob?.length || '0'}</span>
                      {/* <i class=" fas fa-toolbox tele-icon fa-2x" style={{ marginLeft: '30px', color: '#4DBD74' }}></i> */}
                    </p>
                  </Col>
                  <Col md className="text-center">
                    <p className="m-0">Khách hàng</p>
                    <p className="m-0">
                      <span style={{ fontWeight: '600', fontSize: 35 }}>{item?.countCustomer || '0'}</span>
                      {/* <i class=" fas fa-toolbox tele-icon fa-2x" style={{ marginLeft: '30px', color: '#4DBD74' }}></i> */}
                    </p>
                  </Col>
                  <Col md className="text-center">
                    <p className="m-0">Số sale</p>
                    <p className="m-0">
                      <span style={{ fontWeight: '600', fontSize: 35 }}>{item?.countTelesale || '0'}</span>
                      {/* <i class=" fas fa-toolbox tele-icon fa-2x" style={{ marginLeft: '30px', color: '#4DBD74' }}></i> */}
                    </p>
                  </Col>
                  <Col md className="text-center">
                    <p className="m-0">Cuộc gọi thành công</p>
                    <p className="m-0">
                      <span style={{ fontWeight: '600', fontSize: 35 }}>{item?.countCalled || '0'}</span>
                      {/* <i class=" fas fa-toolbox tele-icon fa-2x" style={{ marginLeft: '30px', color: '#4DBD74' }}></i> */}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  }

  render() {
    const { isLoading } = this.state;
    return (
      <>
        {isLoading ? <Loading /> : ''}
        <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px' }}>
          <Col md className="bg bg-2">
            {this.renderTitle()}
            {this.deleteModal()}
            {this.renderBody()}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(ResultParent);
