import { getAxios } from '@constants/Api';
import React from 'react';
import DebounceSelect from './DebounceSelect';

function SelectComponentV2({
  apiUrl,
  params,
  placeholder,
  onChange,
  defaultSelect,
  disabled,
  fieldShow = 'name',
  value,
  limit,
}) {
  // const [value, setValue] = useState<any>(undefined);

  const [defaultOption, setDefaultOption] = React.useState();

  const fetchUserList = React.useCallback(
    async (search) => {
      return getAxios.get(`${apiUrl}`, { params: { ...params, search, limit: limit } }).then((body) =>
        body.data.data.map((data) => ({
          label: `${data[fieldShow]}`,
          value: data.id,
        }))
      );
    },
    [apiUrl, params, fieldShow]
  );

  React.useEffect(() => {
    if (disabled) return;
    fetchUserList().then((res) => {
      return setDefaultOption(res);
    });
  }, [disabled, params]);

  return (
    <DebounceSelect
      disabled={disabled}
      placeholder={placeholder}
      defaultOption={defaultOption}
      allowClear
      value={value || undefined}
      fetchOptions={fetchUserList}
      onChange={(newValue) => {
        onChange(newValue ? newValue : undefined);
      }}
      style={{ width: '100%' }}
    />
  );
}

export default SelectComponentV2;
