import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '@styles/Account.css';
import Table from 'react-bootstrap/Table';
import { Row, Col, FormControl, Button, Modal, FormGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getAccountList, createAccount, getProvinceList } from '@src/redux/actions';
import { ROLE_AVAILABLE, PHONE_REGEX, EMAIL_REGEX } from '@constants/Constant';
import { getItemFromId } from '@utils/getItemFromId';
import { renderSelection } from '@utils/renderSelection';
import { checkNormalizePhone } from '@utils/stringUtils';
import Loading from '@src/components/Loading';
import {
  requestCreateAccount,
  requestResetPassword,
  requestUpdateAccount,
  requestDeleteAccount,
  getListRole,
} from '@constants/Api';
import { notifyFail, notifySuccess } from '@utils/notify';
import TableNullData from '@components/TableNullData';
import Pagination from 'react-js-pagination';
import reactotron from 'reactotron-react-js';
import { Empty } from 'antd';
class Screen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modelAdd: false,
      modelEdit: false,
      modelDelete: false,
      modalAddField: {
        name: '',
        phone: '',
        email: '',
        role: '',
        password: '',
        repassword: '',
      },
      modalEditField: {
        name: '',
        // phone: "",
        email: '',
        role: '',
      },
      list_role: [],
      activePage: 1,
      isLoading: false,
      search: '',
      role_id: '',
      page: '',
    };
  }

  componentDidMount() {
    this.getData({ page: 1 });
    this.props.getProvinceList();
    this.getListRole();
  }

  getData({ page, search, role_id }) {
    this.props.getAccountList({
      page: page || '',
      search: search || '',
      role_id: role_id || '',
    });
  }

  async getListRole() {
    try {
      const res = await getListRole();
      this.setState({ list_role: res.data });
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  renderTitle() {
    return (
      <>
        <Row>
          <Col md={5} sm={12}>
            <h2 className="text-left" style={{ marginBottom: '10px' }}>
              {' '}
              Tài khoản
            </h2>
            <div class="line"></div>
          </Col>
          <br />
          <Col md={7} sm={12}>
            {this.renderButton()}
          </Col>
        </Row>
      </>
    );
  }

  renderBody() {
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <Row
            className="mt-1"
            style={{ textAlign: 'center', padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}
          >
            <Col md className="bg">
              {this.renderTitle()}
              {this.renderField()}
            </Col>
          </Row>
          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
            <Col md className="bg">
              <Row>
                <Col md={12}>{this.renderTable()}</Col>
              </Row>
              <Row>
                <Col md={12} className="d-flex justify-content-center justify-content-md-end">
                  {this.renderPagination()}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderButton() {
    return (
      <div className="text-center text-md-right">
        <Button variant="success" className="text-light" onClick={() => this.setShowModal('modalAdd', true)}>
          Thêm tài khoản
        </Button>
      </div>
    );
  }

  renderField() {
    const { search, role_id, list_role } = this.state;
    reactotron.log(list_role);
    return (
      <div className="w-100">
        <Row>
          <Col md={6} sm={12}>
            <FormControl
              type="text"
              id=""
              placeholder="Tên hoặc số điện thoại tài khoản"
              value={search}
              onChange={(e) => this.searchBarChange('search', e.target.value)}
              onKeyPress={(e) => this.handleKeyPress(e)}
            />
          </Col>
          <Col md={6} sm={12}>
            <FormControl
              as="select"
              id=""
              value={role_id}
              onChange={(e) => this.searchBarChange('role_id', e.target.value)}
            >
              <option value="">-- Loại tài khoản --</option>
              {renderSelection(list_role)}
            </FormControl>
          </Col>
        </Row>
      </div>
    );
  }

  renderTable() {
    const accountList = this.props.accountState?.data?.data;
    const pageNumber = this.props.accountState?.data?.paging?.page;
    const itemPerNumber = this.props.accountState?.data?.paging?.limit;
    const err = this.props.accountState?.data?.error;
    const itemsCount = this.props.accountState?.data?.paging?.totalItemCount;
    if (err) {
      notifyFail(err?.msg);
    }
    return (
      <>
        <div className="w-100 table-wrap">
          <span className="pb-2 d-inline-block">
            Kết quả lọc: <b className="text-danger">{itemsCount}</b>
          </span>
          <Table striped bordered>
            <thead>
              <tr>
                <th>STT</th>
                <th>Họ và tên</th>
                <th>Số điện thoại</th>
                <th>Emai</th>
                <th>Loại tài khoản</th>
                <th>Ngày tạo</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {accountList && accountList.length > 0 ? (
                accountList.map((account, index) =>
                  this.renderTableRow(account, index + 1 + (pageNumber - 1) * itemPerNumber)
                )
              ) : (
                <td colSpan="7">
                  <Empty description="Không có dữ liệu" />
                </td>
              )}
            </tbody>
          </Table>
        </div>
      </>
    );
  }

  renderTableRow(account, index) {
    const role = getItemFromId(ROLE_AVAILABLE, account.role_id);
    return (
      <tr>
        <td>{index}</td>
        <td>{account.full_name || '###'}</td>
        <td>{account.phone || '###'}</td>
        <td>{account.email || '###'}</td>
        <td>{role && role.name}</td>
        <td>{Intl.DateTimeFormat('vi-VN').format(new Date(account.created_at)) || '###'}</td>
        <td>
          <i className="btnEdit fa fa-fw fa-edit" onClick={() => this.setShowModal('modalEdit', true, account)} />
          <i
            className="btnDelete far fa-trash-alt"
            onClick={() => {
              this.setShowModal('modalDelete', true, account);
            }}
          />
        </td>
      </tr>
    );
  }

  setShowModal = (modal, bool, data) => {
    this.setState({
      [modal]: bool,
    });
    if (data) {
      this.setState({
        modalEditField: {
          name: data.full_name,
          phone: data.phone,
          email: data.email,
          role: data.role_id,
          id: data.id,
        },
      });
    }
  };

  clearModal = () => {
    this.setState({
      modalAddField: {
        name: '',
        phone: '',
        email: '',
        role: '',
        password: '',
        repassword: '',
      },
    });
  };

  addModal = () => {
    const { modalAdd, modalAddField, list_role } = this.state;
    return (
      <Modal
        show={modalAdd}
        onHide={() => {
          this.setShowModal('modalAdd', false);
          this.clearModal();
        }}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton className="bg-blue pb-0">
          <h5 className="text-light pb-0">Thêm tài khoản</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          <FormGroup className="text-left">
            <Row className="modal-row">
              <Col md={5}>
                <Modal.Header>Tên người dùng(*)</Modal.Header>
              </Col>
              <Col md={7}>
                <FormControl
                  type="text"
                  placeholder="Nhập tên người dùng"
                  value={modalAddField.name}
                  onChange={(e) => this.addFieldChange('name', e.target.value)}
                ></FormControl>
              </Col>
            </Row>
            <Row className="modal-row">
              <Col md={5}>
                <Modal.Header>Số điện thoại(*)</Modal.Header>
              </Col>
              <Col md={7}>
                <FormControl
                  type="text"
                  placeholder="Nhập số điện thoại"
                  value={modalAddField.phone}
                  onChange={(e) => this.addFieldChange('phone', e.target.value)}
                ></FormControl>
              </Col>
            </Row>
            <Row className="modal-row">
              <Col md={5}>
                <Modal.Header>Email</Modal.Header>
              </Col>
              <Col md={7}>
                <FormControl
                  type="text"
                  placeholder="Nhập email"
                  value={modalAddField.email}
                  onChange={(e) => this.addFieldChange('email', e.target.value)}
                ></FormControl>
              </Col>
            </Row>
            <Row className="modal-row">
              <Col md={5}>
                <Modal.Header>Loại tài khoản(*)</Modal.Header>
              </Col>
              <Col md={7}>
                <FormControl
                  value=""
                  as="select"
                  id=""
                  value={modalAddField.role}
                  onChange={(e) => this.addFieldChange('role', e.target.value)}
                >
                  <option value="" disabled selected>
                    -- Loại tài khoản --
                  </option>
                  {renderSelection(list_role)}
                </FormControl>
              </Col>
            </Row>
            <Row className="modal-row">
              <Col md={5}>
                <Modal.Header>Mật khẩu(*)</Modal.Header>
              </Col>
              <Col md={7}>
                <FormControl
                  type="password"
                  placeholder="Nhập mật khẩu"
                  value={modalAddField.password}
                  onChange={(e) => this.addFieldChange('password', e.target.value)}
                ></FormControl>
              </Col>
            </Row>
            <Row className="modal-row">
              <Col md={5}>
                <Modal.Header>Xác nhận mật khẩu(*)</Modal.Header>
              </Col>
              <Col md={7}>
                <FormControl
                  type="password"
                  placeholder="Nhập lại mật khẩu"
                  value={modalAddField.repassword}
                  onChange={(e) => this.addFieldChange('repassword', e.target.value)}
                ></FormControl>
              </Col>
            </Row>
          </FormGroup>
          <Row>
            {/* <Col sm={8}></Col> */}
            <Col style={{ color: 'gray', textAlign: 'end' }}>
              <div>
                <i>* Quy tắc đặt mật khẩu</i>
              </div>
              <div>
                <i> - Mật khẩu dài 8 ký tự</i>
              </div>
              <i>- Mật khẩu bao gồm chữ hoa, chữ thường, ký tự số, ký tự đặc biệt</i>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="success" className="text-light" onClick={() => this.createAccount()}>
            Xác nhận
          </Button>
          <Button
            variant="danger"
            className="text-light"
            onClick={() => {
              this.setShowModal('modalAdd', false);
              this.clearModal();
            }}
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  editModal = () => {
    const { modalEdit, modalEditField, list_role } = this.state;
    return (
      <Modal
        show={modalEdit}
        onHide={() => this.setShowModal('modalEdit', false)}
        dialogClassName="modal-90w"
        centered
        scrollable
      >
        <Modal.Header closeButton className="bg-primary pb-0">
          <h5 className="text-light pb-0">Sửa tài khoản</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          <FormGroup className="text-left">
            <Row className="modal-row">
              <Col md={5}>
                <Modal.Header>Tên người dùng</Modal.Header>
              </Col>
              <Col md={7}>
                <FormControl
                  type="text"
                  placeholder="Nhập tên người dùng"
                  value={modalEditField.name}
                  onChange={(e) => this.editFieldChange('name', e.target.value)}
                ></FormControl>
              </Col>
            </Row>
            <Row className="modal-row">
              <Col md={5}>
                <Modal.Header>Số điện thoại</Modal.Header>
              </Col>
              <Col md={7}>
                <FormControl
                  type="text"
                  placeholder="Nhập số điện thoại"
                  value={modalEditField.phone}
                  disabled
                ></FormControl>
              </Col>
            </Row>
            <Row className="modal-row">
              <Col md={5}>
                <Modal.Header>Email</Modal.Header>
              </Col>
              <Col md={7}>
                <FormControl
                  type="text"
                  placeholder="Nhập email"
                  value={modalEditField.email}
                  onChange={(e) => this.editFieldChange('email', e.target.value)}
                ></FormControl>
              </Col>
            </Row>
            <Row className="modal-row">
              <Col md={5}>
                <Modal.Header>Loại tài khoản</Modal.Header>
              </Col>
              <Col md={7}>
                <FormControl
                  value=""
                  as="select"
                  id=""
                  value={modalEditField.role}
                  onChange={(e) => this.editFieldChange('role', e.target.value)}
                >
                  <option value="0" disabled>
                    -- Loại tài khoản --
                  </option>
                  {renderSelection(list_role)}
                </FormControl>
              </Col>
            </Row>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="dark" className="text-light" onClick={() => this.resetPassword()}>
            Reset mật khẩu
          </Button>
          <Button variant="success" className="text-light" onClick={() => this.updateAccount()}>
            Lưu
          </Button>
          <Button variant="danger" className="text-light" onClick={() => this.setShowModal('modalEdit', false)}>
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  deleteModal = () => {
    const { modalDelete, modalEditField } = this.state;
    return (
      <Modal
        show={modalDelete}
        onHide={() => this.setShowModal('modalDelete', false)}
        dialogClassName="modal-90w"
        centered
        scrollable
      >
        <Modal.Header closeButton className="text-white bg-primary pb-0" style={{ color: 'white' }}>
          <h5 className="text-light pb-0">Bạn chắc chắn muốn xóa?</h5>
        </Modal.Header>
        <Modal.Footer className="justify-content-end">
          <Button variant="success" className="text-light" onClick={() => this.requestDeleteAccount(modalEditField.id)}>
            Có
          </Button>
          <Button variant="danger" className="text-light" onClick={() => this.setShowModal('modalDelete', false)}>
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  addFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalAddField: {
        ...this.state.modalAddField,
        [field]: value,
      },
    });
  };

  editFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalEditField: {
        ...this.state.modalEditField,
        [field]: value,
      },
    });
  };

  searchBarChange = async (field, value) => {
    this.setState(
      {
        ...this.state,
        [field]: value,
      },
      () => {
        if (field !== 'search') {
          const { search, role_id, page } = this.state;
          this.getData({
            search: search || '',
            role_id: role_id || '',
            page: page || '',
          });
        }
      }
    );
  };

  handleKeyPress = (e) => {
    let { search, role_id, page } = this.state;
    if (e.charCode === 13) {
      search = search || '';
      page = 1;
      role_id = role_id || '';
      this.getData({
        search,
        page,
        role_id,
      });
    }
  };

  async createAccount() {
    const { modalAddField } = this.state;
    const check = this.validateForm();

    if (check) {
      this.setState({ isLoading: true });
      const payload = {
        full_name: modalAddField.name,
        user_name: modalAddField.phone,
        password: modalAddField.password,
        email: modalAddField.email || '',
        role_id: modalAddField.role,
      };

      try {
        const res = await requestCreateAccount(payload);

        this.props.getAccountList();
        Object.keys(this.state.modalAddField).forEach((k) =>
          this.setState({
            modalAddField: {
              ...this.state.modalAddField,
              [k]: '',
            },
          })
        );

        this.setState({ isLoading: false });
        this.setShowModal('modalAdd', false);
        notifySuccess('Thêm tài khoản thành công');
      } catch (err) {
        this.setState({ isLoading: false });
        notifyFail(err.msg);
      }
    }
    return;
  }

  async updateAccount() {
    const { modalEditField } = this.state;
    const check = this.validateEditForm();

    if (check) {
      this.setState({ isLoading: true });
      const payload = {
        full_name: modalEditField.name,
        email: modalEditField.email || '',
        role_id: modalEditField.role,
        user_id: modalEditField.id,
      };

      try {
        const res = await requestUpdateAccount(payload);

        this.props.getAccountList();
        Object.keys(this.state.modalEditField).forEach((k) =>
          this.setState({
            modalEditField: {
              ...this.state.modalEditField,
              [k]: '',
            },
          })
        );

        this.setState({ isLoading: false });
        this.setShowModal('modalEdit', false);
        notifySuccess('Sửa tài khoản thành công');
      } catch (err) {
        this.setState({ isLoading: false });
        notifyFail(err.msg);
      }
    }
    return;
  }

  async requestDeleteAccount(id) {
    try {
      await requestDeleteAccount({ id: id });
      this.setState({ modalDelete: false }, () => notifySuccess('Xóa thành công'));
      this.getData({ page: 1 });
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  validateForm() {
    let { modalAddField } = this.state;
    if (!modalAddField.name || !modalAddField.phone || !modalAddField.password || !modalAddField.role) {
      notifyFail('Vui lòng điền đầy đủ các thông tin bắt buộc');
      return false;
    }
    // reactotron.log("phone", modalAddField.phone)
    if (checkNormalizePhone(modalAddField.phone)) {
      notifyFail('Số điện thoại không hợp lệ');
      return false;
    }
    if (modalAddField.email && EMAIL_REGEX.exec(modalAddField.email) === null) {
      notifyFail('Email không hợp lệ');
      return false;
    }

    if (modalAddField.password !== modalAddField.repassword) {
      notifyFail('Mật khẩu không khớp');
      return false;
    }

    if (modalAddField.password.length < 8) {
      notifyFail('Mật khẩu phải có tối thiểu 8 ký tự.');
      return false;
    }
    var uppercase = 0,
      lowercase = 0,
      specialChar = 0,
      number = 0;
    var specialCharList = '<>@!#$%^&*()_+[]{}?:;|\'"\\,./~`-=';
    for (var i = 0; i < modalAddField.password.length; i++) {
      if (modalAddField.password[i].charCodeAt() >= 65 && modalAddField.password[i].charCodeAt() <= 90) {
        uppercase = 1;
      }
      if (modalAddField.password[i].charCodeAt() >= 97 && modalAddField.password[i].charCodeAt() <= 122) {
        lowercase = 1;
      }
      if (modalAddField.password[i].charCodeAt() >= 48 && modalAddField.password[i].charCodeAt() <= 57) {
        number = 1;
      }
      if (specialCharList.indexOf(modalAddField.password[i]) !== -1) {
        specialChar = 1;
      }
    }
    if (!(uppercase && lowercase && specialChar && number)) {
      notifyFail('Mật khẩu chưa đủ mạnh');
      return false;
    }

    return true;
  }

  validateEditForm() {
    let { modalEditField } = this.state;
    if (modalEditField.name == '') {
      notifyFail('Vui lòng nhập tên người dùng');
      return false;
    } else if (modalEditField.email && EMAIL_REGEX.exec(modalEditField.email) === null) {
      notifyFail('Email không hợp lệ');
      return false;
    }
    return true;
  }

  renderPagination() {
    const itemsCount = this.props.accountState?.data?.paging?.totalItemCount;
    const limit = this.props.accountState?.data?.paging?.limit;
    const { search, role_id } = this.state;
    return (
      <Pagination
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled
        activePage={this.state.activePage}
        itemsCountPerPage={limit}
        totalItemsCount={itemsCount}
        pageRangeDisplayed={5}
        hideNavigation
        onChange={(page) => {
          this.setState({
            ...this.state,
            activePage: page,
          });
          this.getData({ page, search, role_id });
        }}
      />
    );
  }

  async resetPassword() {
    const { modalEditField } = this.state;
    if (modalEditField.email.length < 12) {
      notifyFail('Tài khoản không có email khả dụng');
      return;
    }
    try {
      this.setState({ isLoading: true });
      const res = await requestResetPassword({ id: modalEditField.id });
      this.setState({ isLoading: false });
      notifySuccess('Mật khẩu mới đã được gửi về email');
    } catch (err) {
      this.setState({ isLoading: false });
      notifyFail(err.msg);
    }
  }

  render() {
    const { isLoading, error } = this.props.accountState;
    let loading = <Loading />;
    if (!isLoading) {
      loading = null;
    }
    if (error) {
      notifyFail(error.msg);
    }
    return (
      <>
        {this.state.isLoading ? <Loading /> : null}
        {loading}
        {this.addModal()}
        {this.editModal()}
        {this.deleteModal()}
        {this.renderBody()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  accountState: state.AccountReducer,
  provinceState: state.ProvinceReducer,
});

const mapDispatchToProps = {
  getAccountList,
  createAccount,
  getProvinceList,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Screen));
