import { getListhotlineNew } from '@actions/index';
import SelectHotLine from '@components/selectHotline/SelectHotLine';
import { ApiClient } from '@constants/Api';
import { notifyFail } from '@utils/notify';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MicrophoneList from 'src/network/microphone';

const HotlineHeader = () => {
  const stateListHotline = useSelector(state => state.hotline);
  const dispatch = useDispatch();
  const [openDropDown, setOpenDropDown] = React.useState(false);
  const [listHotLine, setListHotLine] = React.useState([]);
  const [valueDropDown, setValueDropDown] = React.useState(null);

  React.useEffect(() => {
    getListHotLine();
  }, []);

  const getListHotLine = async () => {
    let res = stateListHotline?.dataHotline;

    if (
      Array.isArray(res?.listHotlineOutput) &&
      res?.listHotlineOutput.length > 0
    ) {
      let convertListHotline = res.listHotlineOutput.map(item => {
        return {
          id: item.id,
          label: item.outbound_phone,
          value: item.outbound_phone,
          key: item.id,
        };
      });
      let defaultHotline = res.listHotlineOutput.find(hotline => {
        return hotline.is_default_hotline;
      });
      let valueDropDownDefault = defaultHotline
        ? defaultHotline.outbound_phone
        : 0;

      setListHotLine([
        { id: 0, label: 'Chọn Hotline', key: 0, value: 0 },
        ...convertListHotline,
      ]);
      setValueDropDown(valueDropDownDefault);
    }
  };

  const onChangeListHotLine = async value => {
    // console.log('value', value);
    // this.setState({ valueDropDown: value });

    let dataPostChangeHotLine = undefined;
    if (value === 0) {
      //nếu mặc định
      dataPostChangeHotLine = {
        type_default: null,
        hotline: '',
      };
    }
    if (value) {
      //nếu chọn hotline
      dataPostChangeHotLine = {
        type_default: 1,
        hotline: value,
      };
    }
    if (dataPostChangeHotLine) {
      let res = await ApiClient.post(
        'sale/hotlineDefault',
        dataPostChangeHotLine
      );
      if (!res.status) {
        notifyFail('Có lỗi xảy ra. Vui lòng thử lại!');
        return;
      }
      dispatch(getListhotlineNew());
    }
  };

  return (
    <>
      <SelectHotLine
        openDropDown={openDropDown}
        openDropDownFn={() => setOpenDropDown(!openDropDown)}
        onChangeListHotLine={onChangeListHotLine}
        listHotLine={listHotLine}
        valueDropDown={
          valueDropDown

          // Cookie.get('valueDropDown') || this.state.valueDropDown
        }
      />
      <MicrophoneList />
    </>
  );
};

export default React.memo(HotlineHeader);
