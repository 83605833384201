export function getLabelText(valueTextAreaWatch) {
    let countCharacter = getLengthCharacter(valueTextAreaWatch);
    let countSMS = valueTextAreaWatch?.length ? 1 : 0;
    return (`${countCharacter} ký tự /${countSMS}SMS `);

  }

  export function convertDataUpload(obj) {
    let formData = new FormData()
     Object.keys(obj).forEach(key =>{
      if(key === `uploadFile` && Array.isArray( obj[key]) ) {
        obj[key].forEach(item => {
          formData.append(`image`, item.originFileObj)
        })
      }
      else {
        formData.append(key, obj[key])
      }
    })
    return formData
  }

  export function getLengthCharacter(string) {
    return string?.replace(/\[.*?\]/g, '').length ? string.replace(/\[.*?\]/g, '').length : 0
  }