import { combineReducers } from 'redux';
import { HANDLE_MAKE_CALL_FROM_STRINGEE, RESET } from '../actions/type';
// Reducer chung
import UserReducer from './both/UserReducer';
import TelesaleReducer from './both/TelesaleReducer';
import ProvinceReducer from './both/ProvinceReducer';
// Admin Reducer
import AccountReducer from './admin/AccountReducer';
import CompanyReducer from './admin/CompanyReducer';
import RegisterReducer from './admin/RegisterReducer';
import RejectedRegisterReducer from './admin/RejectedRegisterReducer';
import TelesaleInactiveReducer from './admin/TelesaleInactiveReducer';
import ListTutorial from './admin/TutorialReducer';
import ListPackageReducer from './admin/ListPackageReducer';
import ListHotline from './admin/HotlineReducer';
// Enterprise Reducer
import CategoryReducer from './enterprise/CategoryReducer';
import CustomerReducer from './enterprise/CustomerReducer';
import CustomerGroupReducer from './enterprise/CustomerGroupReducer';
import HotlineReducer from './enterprise/HotlineReducer';
import ReviewReducer from './enterprise/ReviewReducer';
import JobReducer from './enterprise/JobReducer';
import JobDetailReducer from './enterprise/JobDetailReducer';
import CategoryFilterReducer from './enterprise/CategoryFilterReducer';
import CustomerJobReducer from './enterprise/CustomerJobReducer';
import CustomerGroupFilterReducer from './enterprise/CustomerGroupFilterReducer';
import CustomerGroupStatusReducer from './enterprise/CustomerGroupStatusReducer';
import CallHistoryReducer from './enterprise/CallHistoryReducer';
import TypeJobReducer from './enterprise/TypeJobReducer';
import CallStatusReducer from './enterprise/CallStatusReducer';
import ReviewerListReducer from './enterprise/ReviewerListReducer';
import ReviewerTaskReducer from './enterprise/ReviewerTaskReducer';
import GeneralQuestionReducer from './enterprise/GeneralQuestionReducer';
import KeywordReducer from './enterprise/KeywordReducer';
import VpointReducer from './enterprise/VpointReducer';
import OccasionReducer from './enterprise/OccasionReducer';
import ListCallReducer from './enterprise/ListCallReducer';
import ResultReviewerReducer from './enterprise/ResultReviewerReducer';
import EnterpriseFilterReducer from './Filter/EnterpriseReducer';
import JobCategoryFilterReducer from './Filter/JobCategoryReducer';
import JobFilterReducer from './Filter/JobFilterReducer';
import TelesaleFilterReducer from './Filter/TelesaleReducer';
import ReportTelesaleReducer from './enterprise/ReportTelesaleReducer';
import QuessionsAndAnswerReducer from './Filter/QuessionsAndAnswerReducer';
import TelesaleKpiReducer from './admin/TelesaleKpiReducer';
import UserStatisticReducer from './enterprise/UserStatisticReducer';
import CallStatisticReducer from './enterprise/CallStatisticReducer';
import JobStatisticReducer from './enterprise/JobStatisticReducer';
import JobSaleStatisticReducer from './admin/JobSaleStatisticReducer';
import PackageHistoryReducer from './admin/PackageHistoryReducer';
import KpiProgressReducer from './both/KpiProgressReducer';
import CallingStatusReducer from './both/CallingStatusReducer';
import GetUpdateCallReducer from './both/GetUpdateCallReducer';
import GetTimeDurationReducer from './both/GetTimeDurationReducer';
import StringeeCallReducer from './both/StringeeCallReducer';
import GetPhoneNumberReducer from './both/GetPhoneNumberReducer';
import NewCustomerReducer from './both/NewCustomerReducer';
import ShouldCallReducer from './both/ShouldCallReducer';
import PhoneNumberPickedReducer from './both/PhoneNumberPickedReducer';
import NextCustomerReducer from './both/NextCustomerReducer';
import produce from 'immer';
import HotlineReducerV2 from './both/HotLineReducerV2';

let appReducer = combineReducers({
  // both
  ProvinceReducer: ProvinceReducer,
  TelesaleReducer: TelesaleReducer,
  userReducer: UserReducer,

  // admin
  AccountReducer: AccountReducer,
  companyReducer: CompanyReducer,
  RegisterReducer: RegisterReducer,
  RejectedRegisterReducer: RejectedRegisterReducer,
  telesaleInactiveReducer: TelesaleInactiveReducer,
  listTutorial: ListTutorial,
  listPackageReducer: ListPackageReducer,
  listHotline: ListHotline,
  packageHistoryReducer: PackageHistoryReducer,

  // enterprise
  callHistoryReducer: CallHistoryReducer,
  categoryReducer: CategoryReducer,
  categoryFilterReducer: CategoryFilterReducer,
  customerJobReducer: CustomerJobReducer,
  customerGroupFilterReducer: CustomerGroupFilterReducer,
  customerGroupStatusReducer: CustomerGroupStatusReducer,
  customerReducer: CustomerReducer,
  customerGroupReducer: CustomerGroupReducer,
  hotlineReducer: HotlineReducer,
  jobReducer: JobReducer,
  jobDetailReducer: JobDetailReducer,
  reviewReducer: ReviewReducer,
  typeJobReducer: TypeJobReducer,
  callStatusReducer: CallStatusReducer,
  reviewerListReducer: ReviewerListReducer,
  reviewerTaskReducer: ReviewerTaskReducer,
  generalQuestionReducer: GeneralQuestionReducer,
  keywordReducer: KeywordReducer,
  vpointReducer: VpointReducer,
  listCallReducer: ListCallReducer,
  occasionReducer: OccasionReducer,
  resultReviewerReducer: ResultReviewerReducer,
  enterpriseFilterReducer: EnterpriseFilterReducer,
  jobCategoryFilterReducer: JobCategoryFilterReducer,
  jobFilterReducer: JobFilterReducer,
  telesaleFilterReducer: TelesaleFilterReducer,
  reportTelesaleReducer: ReportTelesaleReducer,
  quessionsAndAnswerReducer: QuessionsAndAnswerReducer,
  telesaleKpiReducer: TelesaleKpiReducer,
  userStatisticReducer: UserStatisticReducer,
  callStatisticReducer: CallStatisticReducer,
  jobStatisticReducer: JobStatisticReducer,
  jobSaleStatisticReducer: JobSaleStatisticReducer,
  kpiProgress: KpiProgressReducer,
  callingStatus: CallingStatusReducer,
  updateCallStatus: GetUpdateCallReducer,
  timeDuration: GetTimeDurationReducer,
  stringeeCallAway: StringeeCallReducer,
  phoneNumber: GetPhoneNumberReducer,
  isNewCustomer: NewCustomerReducer,
  shouldCall: ShouldCallReducer,
  phoneNumberPicked: PhoneNumberPickedReducer,
  nextCustomer: NextCustomerReducer,

  //hotline
  hotline: HotlineReducerV2,
});
const initialState = appReducer({}, {});

export default (state, action) => {
  if (action.type === RESET) {
    state = initialState;
  }

  if (action.type === HANDLE_MAKE_CALL_FROM_STRINGEE) {
    const { payload } = action;
    state = produce(state, draft => {
      draft.stringeeCallAway.data =
        payload.stringeeCall.callId && payload.stringeeCall;
      draft.shouldCall.data = payload.shouldCall.status && payload.shouldCall;
    });
  }

  return appReducer(state, action);
};
