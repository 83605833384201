export const VietnameseString = {
    effective_time: "Thởi gian",
    search: "Tìm kiếm",
    add: "Thêm mới",
    clearSearch: "Xóa tìm kiếm",
    title: "Tiêu đề bài viết",
    fromDate: "Từ ngày",
    toDate: "Đến ngày",
    index: "STT",
    creater: "Người tạo",
    dateCreated: "Ngày tạo",
    displayIndex: "Thứ tự hiển thị",
    status: "Trạng thái",
    dob: "Ngày sinh",
    phoneNumber: "Số điện thoại",
    email: "Email",
    gender: "Giới tính",
    city: "Thành phố",
    identicalId: "Số CMND",
    income: "Thu nhập mong muốn",
    experience: "Kinh nghiệm",
    profile: "Hồ sơ",
    realAddress: "Địa chỉ cụ thể",
    description: "Mô tả",
    avatar: "Ảnh đại diện",
    content: "Nội dung",
    saveDraft: "Lưu nháp",
    save: "Lưu",
    post: "Đăng bài",

    job: "Công việc",
    customer_data: "Dữ liệu khách hàng",
    customer_source: "Nguồn khách hàng",
    customer_attribute: "Thuộc tính khách hàng",
    customer: "Khách hàng",
    occasion_type: "Loại cơ hội",
    telesale: "NV Kinh doanh",
    time: "Thời gian",
    change_to_date: "Ngày chuyển nhu cầu",
    full_name: "Họ tên",
    province_city: "Tỉnh/Thành phố",
    product_type: "Loại sản phẩm",
    note: "Ghi chú",
    revenue: "Doanh thu",
    sale: "Doanh số",
    key_word: "Từ khóa",
    to_Vpoint: "Quy đổi Vpoint",
    checkin: "Loại điểm danh",
    start_call_time: "Thời gian",
    startDate: "Bắt đầu",
    endDate: "Kết thúc",
    re_time: "Thời gian gọi lại",
    general_information: "Thông tin chung",
    the_script: "Kịch bản",
    result_card: "Phiếu kết quả",
    educate: "Đào tạo",
    exam: "Đề thi",
    call: "Gọi điện",
    classify: "Phân loại",
    opportunity: "Cơ hội",
    contract: "Hợp đồng",
    quantity: "Số lượng",
    telemarketing: "Sale chăm sóc",
    no_data: "Không có dữ liệu!",
    department: "Phòng ban",
    report: "Báo cáo",
    customer_interacted: "KH đã tương tác",
    display_data: "Dữ liệu hiển thị",
    existing_data: "Dữ liệu hiện có",
    scale_display: "Hiển thị tỉ lệ",
    export_report: "Xuất báo cáo",
    export_excel: "Xuất Excel",
    date: "Ngày",
    week: "Tuần",
    month: "Tháng",
    quarter: "Quý",
    year: "Năm",
    yesterday: "Hôm qua",
    last_week: "Tuần trước",
    last_month: "Tháng trước",
    last_quarter: "Quý trước",
    last_year: "Năm trước",
    business_report: "Báo cáo doanh số",
    location_report: "Báo cáo vị trí",
    telemarketing_report: "Báo cáo sale",
    call_reports: "Báo cáo cuộc gọi",
    KPI: "KPI",
    setup_KPI: "Thiết lập KPI",
    update_setup_KPI: "Cập nhật thiết lập KPI",
    edit_setup_KPI: "Chỉnh sửa thiết lập KPI",
    detail_setup_KPI: "Chi tiết thiết lập KPI",
    realization_of_target_revenue: "Tình hình thực hiện mục tiêu doanh số",
    sales_of_closed_contracts_over_time: "Doanh số của hợp đồng chốt được theo thời gian",
    earn_money_by_product: "Thành tiền theo sản phẩm",
    opportunity_KPI: "KPI cơ hội",
    opportunity_KPI_completion_rate: "Tỉ lệ hoàn thành KPI cơ hội",
    contract_closing: "Chốt hợp đồng",
    contract_closing_rate: "Tỉ lệ chốt hợp đồng",
    sales_KPI: "KPI doanh số",
    sales_completion_rate: "Tỉ lệ hoàn thành doanh số",
    average_value_of_one_contract: "Giá trị trung bình của một hợp đồng",
    commission_for_sales: "Hoa hồng cho sale",
    into_money: "Thành tiền",
    product: "Sản phẩm",
    no_product: "Không có sản phẩm",
    customer_name: "Tên khách hàng",
    call_count_KPI: "KPI số cuộc gọi",
    new_customer_KPI: "KPI khách hàng mới",
    revenue_KPI: "KPI doanh thu",
    list_of_applicable_sales: "Danh sách sale áp dụng",
    month_of_application: "Tháng áp dụng",
    working_days_of_the_month: "Ngày làm việc trong tháng",
    autoRenew_for_next_month: "Tự động gia hạn cho tháng kế tiếp",
    system_KPI: "KPI hệ thống",
    other_KPI: "KPI khác",
    targets: "Chỉ tiêu",
    reality: "Thực tế",
    targets_name: "Tên chỉ tiêu",

    please_enter_the_number_of_working_days_in_the_month: "Vui lòng nhập số ngày làm trong tháng!",
    are_you_sure_you_want_to_delete: "Bạn có chắc chắn muốn xóa?",

    //confirm
    yes: "Có",
    no: "Không",
    agree: "Đồng ý",
    cancel: "Hủy",
    exit: "Thoát",
    name_of_attendance_type: "Tên loại điểm danh",
    condition: "Tình trạng",
    ordinal_number: "STT",
    not_update: "Chưa cập nhật",
    address_match: "Địa chỉ trùng khớp",
    address_does_not_match: "Địa chỉ không trùng khớp",
    number_of_customer_visits: "Số lần đi gặp khách hàng",
    number_of_checkins_on_time: "Số lần checkin đúng giờ",
    number_of_late_checkins: "Số lần checkin muộn",
    number_of_on_time_checkouts: "Số lần checkout đúng giờ",
    number_of_early_checkouts: "Số lần checkout sớm",
    number_of_times_without_attendance: "Số lần không điểm danh",
    number_of_times_the_address_does_not_match: "Số lần địa chỉ không trùng khớp",
    attendance_history: "Lịch sử điểm danh",
    no_attendance: "Không điểm danh",
    distance: "Khoảng cách",
    address_of_the_place_name: "Địa chỉ điểm danh",

    activated: "Đã kích hoạt",
    not_activated: "Chưa kích hoạt",

    electronic_voucher: "E-voucher",
    voucher_management: "Quản lý voucher",
    gift_management: "Quản lý quà tặng",
    voucher_code_details: "Chi tiết mã voucher",
    voucher_code: "Mã voucher",
    activate_date: "Ngày kích hoạt",
    promotions_detail: "Chi tiết chương trình",
    promotions_code: "Mã chương trình",
    promotions_name: "Tên chương trình",
    add_promotions: "Thêm chương trình",

    number_prefix_configuration: "Cấu hình đầu số",
    add_number_prefix: "Thêm đầu số",
    sms_type: "Loại tin nhắn",
    brand_name: "Tên thương hiệu",
    number_prefix: "Đầu số",
    account: "Tài khoản",
    password: "Mật khẩu",
    partner: "Đối tác",
    action: "Hoạt động",
    management_partner: "Đối tác quản lí",
    management_partner_name: "Tên đối tác quản lí",
    please_enter_the_name_of_the_management_partner: "Vui lòng nhập tên đối tác quản lí!",
    please_choose_type_of_sms: "Vui lòng chọn loại SMS!",
    please_enter_numeric_prefix: "Vui lòng nhập đầu số!",
    please_enter_brand_name: "Vui lòng nhập tên thương hiệu!",
    please_enter_account: "Vui lòng nhập tài khoản!",
    please_enter_password: "Vui lòng nhập mật khẩu!",
    please_enter_a_password_longer_than_6_characters:
        "Vui lòng nhập độ dài mật khẩu lớn hơn 6 ký tự!",
    company_detail: "Chi tiết công ty",
    sms_configuration_detail: "Chi tiết cấu hình tin nhắn",
    numeric_prefix_details: "Chi tiết đầu số",
    message_content: "Nội dung tin nhắn",
    list_of_templates: "Danh sách template",
    result: "Kết quả",
    add_template: "Thêm template",
    character: "ký tự",
    configuration: "Cấu hình",
    multimedia: "Đa phương tiện",
    parameter_configuration: "Cấu hình tham số",
    parameter_code: "Mã tham số",
    parameter_name: "Tên tham số",
    please_enter_template_sms: "Vui lòng nhập nội dung SMS",
    the_file_size_is_larger_than_the_allowed_size: "Dung lượng file lớn hơn dung lượng cho phép!",
    approved: "Đã duyệt",
    not_approved_yet: "Chờ duyệt",
    add_parameter: "Thêm tham số",
    view_image: "Xem ảnh",
    script_for_marketing: "Kịch bản cho sale",
    script_for_zns: "Kịch bản ZNS",
    script_for_sms: "Kịch bản SMS",
    select_account: "Chọn tài khoản",
    select_number_prefix: "Chọn đầu số",
    select_account_zns: "Chọn tài khoản",
    select_sample_template: "Chọn mẫu template",
    please_select_an_account: "Vui lòng chọn tài khoản!",
    please_select_a_number_prefix: "Vui lòng chọn đầu số!",
    please_select_a_template: "Vui lòng chọn template!",
    sending_time: "Thời gian gửi",
    please_select_sending_time: "Vui lòng chọn thời gian gửi",
    once: "Một lần",
    periodically: "Định kỳ",
    continuous: "Liên tục",
    choose_a_template: "Chọn template",
    hour: "Giờ",
    monday: "Thứ 2",
    tuesday: "Thứ 3",
    wednesday: "Thứ 4",
    thursday: "Thứ 5",
    friday: "Thứ 6",
    saturday: "Thứ 7",
    sunday: "Chủ nhật",
    list_of_conditions: "Danh sách điều kiện",
    create_condition: "Thêm điều kiện",
    automatically_delete_customers_when_ineligible: "Tự động xóa khách hàng khi không đủ điều kiện",
    valid_for_new_customers_only: "Chỉ áp dụng cho khách hàng mới",
    number: "Số",
    text: "Văn bản",
    select: "Lựa chọn",
    attribute: "Thuộc tính",
    comparative_attribute: "Thuộc tính so sánh",
    required: "Bắt buộc",
    manipulation: "Thao tác",
    all: "Tất cả",
    please_select_attribute: "Vui lòng chọn thuộc tính!",
    are_you_sure_you_want_to_choose_this_group: "Bạn có chắc chắn muốn chọn nhóm này không?",
    warning: "Cảnh báo",
    allow_displaying_templates_in_sales_accounts:
        "Cho phép hiển thị mẫu template ở tài khoản sale ",
    assign_work_without_sales_confirmation: "Giao việc không cần sale xác nhận",
    divide_by_number_of_customers: "Chia theo số lượng khách hàng",
    proportional_division: "Chia theo tỉ lệ",

    access_token: "Access token",
    please_enter_access_token: "Vui lòng nhập access token!",
    refresh_token: "Refresh token",
    please_enter_refresh_token: "Vui lòng nhập refresh token!",
    application_id: "ID ứng dụng",
    please_enter_application_id: "Vui lòng nhập ID ứng dụng!",
    private_key: "Khóa bí mật",
    please_enter_private_key: "Vui lòng nhập Khóa bí mật!",

    add_zalo_account: "Thêm tài khoản Zalo",
    name: "Tên",
    id: "ID",
    price: "Giá",
    confirm: "Xác nhận",
    add_zalo_oa_account: "Thêm tài khoản Zalo OA",
    verified: "Đã xác thực",
    non_verified: "Chưa xác thực",
    oa_account: "Tài khoản OA",
    general_configuration: "Cấu hình chung",
    template_sync: "Đồng bộ template",
    template_detail: "Chi tiết template",
    model_name_zns: "Tên mẫu ZNS",
    template_id: "Template ID",
    content_type: "Loại nội dung",
    timeout: "Timeout",
    the_current_quality_of_the_template: "Chất lượng hiện tại của template",
    used: "Đã sử dụng",
    number_of_codes: "Số lượng mã",
    start_day: "Ngày bắt đầu",
    end_day: "Ngày kết thúc",
    name_of_the_discount_program: "Tên chương trình giảm giá",
    preferential_type: "Loại ưu đãi",
    discount_by_total_amount: "Giảm giá theo tổng tiền",
    accumulate_points: "Tích điểm",
    discount_by_product: "Giảm giá theo sản phẩm",
    give_a_gift: "Tặng quà",
    apply_time: "Thời gian áp dụng",
    value_of_the_voucher: "Giá trị của voucher",
    redeem_gifts: "Đổi quà",
    gifts: "Quà tặng",
    amount_of_money: "Số tiền",
    message_report: "Báo cáo tin nhắn",
    message_history: "Lịch sử tin nhắn",
    sms_recharge_history: "Lịch sử nạp tiền SMS",
    zns_recharge_history: "Lịch sử nạp tiền ZNS",
    sms_sending_history: "Lịch sử gửi SMS",
    zns_sending_history: "Lịch sử gửi ZNS",
    template: "Template",
    sending_status: "Trạng thái gửi",
    cost: "Chi phí",
    time_the_customer_receives_the_zns: "Thời gian KH nhận được ZNS",
    remaining_zns: "Số ZNS còn lại",
    number_of_zns_sent: "Số ZNS được gửi",
    please_wait_for_a_few_minutes_to_process: "Vui lòng đợi xử lí trong ít phút!",
    success: "Thành công",
    send: "Đã gửi",
    waiting: "Chờ gửi",
    can_not_send: "Không gửi được",
    unknown: "Không xác định",
    error_code: "Mã lỗi",
    re_send: "Gửi lại",
    resend_selected_message: "Gửi lại tin nhắn đã chọn?",
    number_of_messages_sent_successfully: "Số tin nhắn gửi thành công",
    number_of_messages_sent_fail: "Số tin nhắn không gửi được",
    number_of_messages_sent: "Số tin nhắn đã gửi",
    number_of_price_sent: "Số tiền đã gửi",
    title_config_voucher: "Cấu hình mã voucher",
    allow_synchronization_of_customer_information: "Cho phép đồng bộ thông tin khách hàng",
    enter_api_key: "Nhập API key",
    api_key: "API key",
    enter_the_sub_job_code: "Nhập mã giao việc",
    sub_job_code: "Mã giao việc",
    api_key_and_valid_assignment_code: "API key và mã giao việc hợp lệ",
    check_api_key: "Kiểm tra API key",
    sub_job: "Giao việc",
    total_number_of_calls: "Tổng số cuộc gọi",
    busy_call_back_later: "Bận gọi lại sau",
    do_not_answer_the_phone: "Không nghe máy",
    wrong_information: "Sai thông tin",
    agree_to_exchange: "Đồng ý trao đổi",
    unreported: "Chưa báo cáo",
    call_back_appointment: "Hẹn gọi lại",
    picked_up_the_phone: "Đã nhấc máy",
    customer_called: "KH đã gọi",
    total_customer: "Tổng số KH",
    total_call_time: "Tổng thời gian gọi",
    average: "Trung bình",
};
