import React from 'react';

import { ROUTER } from '@constants/Constant';
// import reactotron from 'reactotron-react-js';

// static component
import PrivateRoute from '../PrivateRoute';

//Screen web reviewer
import EvaluateHistoryScreen from '@screens/Review/EvaluateHistory/EvaluateHistoryScreen';
import HomePageScreen from '@screens/Review/HomePage/HomePageScreen';
import InformationScreen from '@screens/Review/Information/InformationScreen';
import CallDetailReviewerScreen from '@screens/Review/Job/CallDetailScreen';
import JobDetailScreen from '@screens/Review/Job/JobDetailScreen';
import JobScreen from '@screens/Review/Job/JobScreen';

export const renderReviewerRoute = () => {
  return (
    <>
      <PrivateRoute path="/" exact Component={HomePageScreen} />
      <PrivateRoute path={ROUTER.HOMEPAGE} exact Component={HomePageScreen} />
      <PrivateRoute path={ROUTER.JOB_REVIEWER} exact Component={JobScreen} />
      <PrivateRoute
        path={ROUTER.JOB_REVIEWER_DETAIL + '/:id'}
        exact
        Component={JobDetailScreen}
      />
      <PrivateRoute
        path={ROUTER.EVALUATE_HISTORY}
        exact
        Component={EvaluateHistoryScreen}
      />
      <PrivateRoute
        path={ROUTER.INFORMATION}
        exact
        Component={InformationScreen}
      />
      <PrivateRoute
        path={ROUTER.CALL_DETAIL_REVIEWER + '/:id'}
        exact
        Component={CallDetailReviewerScreen}
      />
    </>
  );
};
