/* eslint-disable no-unused-expressions */
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import Loading from '@components/Loading';
import {
  ApiClient,
  requestChangePass,
  requestGetCallStatus,
  requestGetHotlineV2,
  requestListNoti,
  requestLogout,
  requestReadNoti,
} from '@constants/Api';
import {
  EVENT_CHANNEL,
  NOTI_TYPE,
  ROLE_WEB_SALE,
  ROUTER,
  TOKEN_MIXPANEL,
  USER_ROLE,
} from '@constants/Constant';
import { randomColor } from '@constants/funcHelper';
import {
  ContainerSelectHotlineStyled,
  SelectHotlineStyled,
} from '@screens/Sale/Call/NumberPad';
import {
  getPackageHistory,
  getProvinceList,
  getUserInfo,
  getListhotlineNew,
} from '@src/redux/actions';
import '@styles/Header.css';
import { checkAllAttrFilled } from '@utils/checkAllAttrFilled';
import EventEmitter from '@utils/EventEmitter';
import { notifyFail, notifySuccess } from '@utils/notify';
import {
  Avatar,
  Badge,
  Divider,
  Dropdown,
  Empty,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import Cookie from 'js-cookie';
import mixpanel from 'mixpanel-browser';
import QRCode from 'qrcode.react';
import React, { Component } from 'react';
import { Button, Col, FormControl, Modal, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { CLIENT_STATUS, PROVIDER_NAME } from './callProviders/CallConstant';
import ImportExport from './headerFunc/ImportExport';
import HotlineImage from '@assets/hotline.png';
import { PhoneOutlined, DownOutlined } from '@ant-design/icons';
import SelectHotLine from './selectHotline/SelectHotLine';
import CallUtil from './callProviders/CallUtil';
import MicrophoneList from 'src/network/microphone';
const { Paragraph } = Typography;
const BellNotiIcon = () => (
  <svg
    width="25px"
    height="25px"
    fill="#ef8737"
    viewBox="0 0 1024 1024"
    style={{ margin: '5px 0 0 0', cursor: 'pointer' }}
  >
    <path d="M816 768h-24V428c0-141.1-104.3-257.7-240-277.1V112c0-22.1-17.9-40-40-40s-40 17.9-40 40v38.9c-135.7 19.4-240 136-240 277.1v340h-24c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h216c0 61.8 50.2 112 112 112s112-50.2 112-112h216c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM512 888c-26.5 0-48-21.5-48-48h96c0 26.5-21.5 48-48 48zM304 768V428c0-55.6 21.6-107.8 60.9-147.1S456.4 220 512 220c55.6 0 107.8 21.6 147.1 60.9S720 372.4 720 428v340H304z" />
  </svg>
);
export const WrapperSelectHotlineStyled = styled.div`
  /* width: 100%; */
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  margin: 3px 10px 0 26px;
  cursor: pointer;
  position: relative;
  &::before {
    content: 'Hotline';
    position: absolute;
    top: -8px;
    font-size: 8px;
    left: 50%;
    transform: translateX(-50%);
  }

  & .ant-select-selector {
    padding-left: 10px !important;
  }

  & .icon_phone {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 6px;
    position: relative;
    padding-right: 6px;
    img {
      height: 14px;
      width: 14px;
    }
    /* & * {
      color: #248037;
    } */

    & .icon_down {
      display: flex;
      align-items: center;
      padding-left: 4px;
    }

    & .icon_down * {
      font-size: 10px;
      color: #999;
      font-weight: 600;
    }

    & span {
      font-size: 10px;
    }
  }

  & .icon_phone::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background: #e5e5e5;
    right: 0;
  }
`;

const StarNotiIcon = () => (
  <svg
    width="25px"
    height="25px"
    fill="white"
    viewBox="0 0 1024 1024"
    style={{ margin: '0 1px 4px 0' }}
  >
    <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 00.6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0046.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z" />
  </svg>
);
const ItemNoti = styled.div`
  cursor: pointer;
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  padding: 5px 0 5px 5px;
  &:hover {
    background-color: #ffd4b3;
  }
`;

function AvatarUser({ userInfo }) {
  let userName = '';
  let srcImage = '';

  if (userInfo.role_id === USER_ROLE.ENTERPRISE) {
    userName = userInfo?.user_enterprise_info?.office_name?.trim();
    srcImage = userInfo?.user_enterprise_info?.profile_image;
  } else {
    userName = userInfo?.full_name?.trim();
  }

  if (userInfo.role_id === USER_ROLE.SALE) {
    srcImage = userInfo?.user_sale_info?.profile_image;
  }

  return (
    <Avatar
      style={{
        backgroundColor: userName
          ? randomColor(userName[userName?.length - 1].charAt(0)?.toUpperCase())
          : 'unset',
        margin: '3px 0 0 0',
      }}
      src={srcImage}
    >
      {userName && userName[userName?.length - 1].charAt(0)?.toUpperCase()}
    </Avatar>
  );
}

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      showModal: false,
      editPassword: false,
      modal: {
        new_pass: '',
        old_pass: '',
        confirm_pass: '',
      },
      isLoading: false,
      showInfoModal: false,
      visibleNotiDropdown: false,
      listNoti: [],
      listMoreNoti: [],
      currentNotificationPage: 1,
      count: 0,
      countUnread: 0,
      idNotiDetail: '',
      statusNoti: '',
      openDropDown: false,
      valueDropDown: 0,
      listHotLine: [],
    };
    this.logout = this.logout.bind(this);
    this.scrollRef = React.createRef();
  }

  componentDidMount() {
    this.getUserInfo();
    this.getListNoti();
    this.props.getProvinceList();
    // this.getListHotLine();
    this.props.getListhotlineNew();
    this.getCallStatus();
    EventEmitter.addListener(EVENT_CHANNEL.NEW_NOTIFICATION, () => {
      this.getListNoti();
    });
  }

  componentDidUpdate(prevProps) {
    // console.log(8888, this.props.listHotLine);
    if (prevProps.listHotLine !== this.props.listHotLine) {
      this.getListHotLine();
    }
  }

  componentWillUnmount() {
    EventEmitter.removeAllListeners(EVENT_CHANNEL.NEW_NOTIFICATION);
  }

  openDropDownFn = () => {
    this.setState({ openDropDown: !this.state.openDropDown });
  };
  onChangeListHotLine = async value => {
    // console.log('value', value);
    // this.setState({ valueDropDown: value });

    let dataPostChangeHotLine = undefined;
    if (value === 0) {
      //nếu mặc định
      dataPostChangeHotLine = {
        type_default: null,
        hotline: '',
      };
    }
    if (value) {
      //nếu chọn hotline
      dataPostChangeHotLine = {
        type_default: 1,
        hotline: value,
      };
    }
    if (dataPostChangeHotLine) {
      let res = await ApiClient.post(
        'sale/hotlineDefault',
        dataPostChangeHotLine
      );
      if (!res.status) {
        notifyFail('Có lỗi xảy ra. Vui lòng thử lại!');
        return;
      }
      this.props.getListhotlineNew();
    }
  };

  getCallStatus = async () => {
    let res = await requestGetCallStatus();
    // alert("header")
    CallUtil.listCallStatus = res.data;
  };
  getListHotLine = async () => {
    let res = this.props.listHotLine?.dataHotline;
    console.log('res', res);

    if (
      Array.isArray(res?.listHotlineOutput) &&
      res?.listHotlineOutput.length > 0
    ) {
      let convertListHotline = res.listHotlineOutput.map(item => {
        return {
          id: item.id,
          label: item.outbound_phone,
          value: item.outbound_phone,
          key: item.id,
        };
      });
      let defaultHotline = res.listHotlineOutput.find(hotline => {
        return hotline.is_default_hotline;
      });
      let valueDropDownDefault = defaultHotline
        ? defaultHotline.outbound_phone
        : 0;
      this.setState({
        listHotLine: [
          { id: 0, label: 'Chọn Hotline', key: 0, value: 0 },
          ...convertListHotline,
        ],
        valueDropDown: valueDropDownDefault,
      });
    }
  };
  getUserInfo = async () => {
    await this.props.getUserInfo();
    if (this.props.userInfoState.err) alert('Phiên đăng nhập hết hạn');
  };

  setShowModal = bool => {
    this.setState({
      showModal: bool,
    });
  };

  setShowModalInfo = bool => {
    this.setState(
      {
        showInfoModal: bool,
      },
      () => {
        if (bool && this.props.userInfoState?.data?.user_id) {
          this.props.getPackageHistory({
            enterprise_id: this.props.userInfoState?.data?.user_id,
          });
        }
      }
    );
  };

  handleVisibleChange = flag => {
    this.setState({ visibleNotiDropdown: flag });
  };

  getListNoti = async () => {
    try {
      this.setState({ isLoading: true });
      const res = await requestListNoti(this.state.currentNotificationPage);
      this.setState({
        ...this.state,
        listNoti: res?.data,
        count: res.paging.count,
        countUnread: res.paging.countUnread,
        isLoading: false,
      });
    } catch (err) {
      console.log('err: ', err);
      this.setState({ ...this.state, isLoading: false });
    }
  };

  getListMoreNoti = async () => {
    try {
      this.setState({ isLoading: true });
      const res = await requestListNoti(this.state.currentNotificationPage + 1);
      this.setState({
        ...this.state,
        listNoti: this.state.listNoti.concat(res?.data),
        currentNotificationPage: res.paging.page,
        count: res.paging.count,
        countUnread: res.paging.countUnread,
        isLoading: false,
      });
    } catch (err) {
      console.log('err: ', err);
      this.setState({ ...this.state, isLoading: false });
    }
  };

  async readNoti() {
    try {
      this.setState({ isLoading: true });
      const res = await requestReadNoti({
        id: undefined,
        id_read_all: 0,
      });
      this.setState({ ...this.state, read_noti: true, isLoading: false });
    } catch (err) {
      console.log('err: ', err);
      this.setState({ ...this.state, isLoading: false });
    }
  }

  async logout() {
    try {
      this.setState({ isLoading: true });
      const res = await requestLogout();
      this.setState({ isLoading: false });
    } catch (err) {
      this.setState({ isLoading: false });
      alert('Phiên đăng nhập hết hạn');
      Cookie.remove('SESSION_ID');
      window.location.href = '/login';
    }
    Cookie.remove('SESSION_ID');
    window.location.href = '/login';
  }

  async changePassword() {
    const check = this.validateForm();
    if (check) {
      try {
        this.setState({ isLoading: true });
        const res = await requestChangePass(this.state.modal);
        this.setState({ isLoading: false });
        this.setShowModal(false);
        notifySuccess('Cập nhật mật khẩu thành công');
        Object.keys(this.state.modal).forEach(key => {
          this.setState({
            modal: {
              ...this.state.modal,
              [key]: '',
            },
          });
        });
      } catch (error) {
        this.setState({ isLoading: false });
        notifyFail(error.msg);
      }
    }
  }

  validateForm() {
    const { modal } = this.state;
    if (!checkAllAttrFilled(modal)) {
      notifyFail('Không được bỏ trống thông tin');
      return false;
    }
    if (modal.new_pass.length < 6 || modal.old_pass.length < 6) {
      notifyFail('Mật khẩu mới phải có độ dài lớn hơn 6 ký tự');
      return false;
    }
    if (modal.new_pass !== modal.confirm_pass) {
      notifyFail('Mật khẩu mới không khớp');
      return false;
    }
    return true;
  }

  handleChange(field, value) {
    this.setState({
      modal: {
        ...this.state.modal,
        [field]: value,
      },
    });
  }

  changePasswordModal = () => {
    const { editPassword, showModal, modal } = this.state;
    return (
      <Modal
        show={showModal}
        onHide={() => this.setShowModal(false)}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton className="bg-blue pb-0">
          <h5 className="text-white">Thay đổi mật khẩu</h5>
        </Modal.Header>

        <Modal.Body className="custom-body mt-3">
          <Row className="mb-3 align-items-center">
            <Col className="modal-field" sm={5}>
              <label>Nhập mật khẩu hiện tại</label>
            </Col>
            <Col sm={7}>
              <FormControl
                type="password"
                placeholder="Nhập mật khẩu hiện tại"
                onChange={e => {
                  this.handleChange('old_pass', e.target.value);
                }}
                value={modal.old_pass}
              ></FormControl>
            </Col>
          </Row>

          <Row className="mb-3 align-items-center">
            <Col className="modal-field" sm={5}>
              <label>Nhập mật khẩu mới</label>
            </Col>
            <Col sm={7}>
              <FormControl
                type="password"
                placeholder="Nhập mật khẩu mới"
                onChange={e => {
                  this.handleChange('new_pass', e.target.value);
                }}
                value={modal.new_pass}
              ></FormControl>
            </Col>
          </Row>

          <Row className="mb-3 align-items-center">
            <Col className="modal-field" sm={5}>
              <label>Xác nhận mật khẩu mới</label>
            </Col>
            <Col sm={7}>
              <FormControl
                type="password"
                placeholder="Xác nhận mật khẩu mới"
                onChange={e => {
                  this.handleChange('confirm_pass', e.target.value);
                }}
                value={modal.confirm_pass}
              ></FormControl>
            </Col>
          </Row>
          <Row
            sm={4}
            style={{ justifyContent: 'center', marginLeft: 30 }}
            className="mt-5"
          >
            <Button
              variant="success"
              className="text-white"
              onClick={() => this.changePassword()}
            >
              Lưu
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  companyInfoModal = () => {
    const { showInfoModal } = this.state;
    const detail = this.props.userInfoState.data;
    const packageHistory = this.props.packageHistoryState?.data;
    const isLoadingHistory = this.props.packageHistoryState?.isLoading;
    return (
      <Modal
        show={showInfoModal}
        size={'xl'}
        scrollable={true}
        onHide={() => this.setShowModalInfo(false)}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton className="pb-0">
          <h5>Thông tin công ty</h5>
        </Modal.Header>

        <Modal.Body
          className="custom-body"
          style={{ backgroundColor: '#EFF3F9' }}
        >
          <Row xs={12}>
            {/* Thông tin công ty*/}
            <Col lg={4} className="p-0">
              <Col className="bg mb-3 p-4">
                <Row>
                  <Col xs={12} className="text-center">
                    <Avatar
                      src={detail.user_enterprise_info?.profile_image}
                      size={144}
                    ></Avatar>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} class="py-2">
                    <h5 class="text-center">
                      {detail.user_enterprise_info?.office_name}
                    </h5>
                  </Col>
                </Row>
                <Row className="mt-2 flex-center">
                  <Col xs={12} className="p-0">
                    <Tag
                      icon={<i className="far fa-gem mr-2"></i>}
                      color="#FE7A15"
                    >
                      {detail?.user_enterprise_info?.package_service?.name ||
                        ''}
                    </Tag>
                  </Col>
                </Row>

                <hr class="mb-3"></hr>

                <Row>
                  <Col xs={12} className="p-0">
                    <label>Mã công ty</label>:{' '}
                    {detail.user_enterprise_info?.code}
                  </Col>
                </Row>
                <Row className="text-center">
                  <Col xs={12} className="text-center">
                    <QRCode
                      id="qrcode"
                      value={detail.user_enterprise_info?.code}
                      level={'H'}
                      includeMargin={true}
                    />
                  </Col>
                </Row>

                <hr class="mb-3"></hr>
                <Row>
                  <Col sm={4} lg={12} className="p-0">
                    <label>Số điện thoại:</label>
                  </Col>
                  <Col xs={8} className="p-0">
                    <p className="p-0">{detail?.phone || 'Chưa cập nhật'}</p>
                  </Col>
                </Row>

                <Row>
                  <Col sm={4} lg={12} className="p-0">
                    <label>Email:</label>
                  </Col>
                  <Col sm={8} className="p-0">
                    <p className="p-0">{detail?.email || 'Chưa cập nhật'}</p>
                  </Col>
                </Row>

                <Row>
                  <Col sm={4} lg={12} className="p-0">
                    <label>Địa chỉ:</label>
                  </Col>
                  <Col sm={8} className="p-0">
                    <p className="p-0">
                      {detail.user_enterprise_info?.address || 'Chưa cập nhật'}
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col sm={4} lg={12} className="p-0">
                    <label>Người đại diện:</label>
                  </Col>
                  <Col sm={8} className="p-0">
                    <p className="p-0">
                      {detail?.full_name || 'Chưa cập nhật'}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Col>
            <Col lg={8} className="p-0 pl-lg-3">
              {/* Thông tin công việc */}
              <Col className="bg mb-3 p-4">
                <Row className="pb-2">
                  <h5>Thông tin công việc</h5>
                </Row>
                <Row>
                  <Col md={6} className="p-0">
                    <Row className="p-0">
                      <Col xs={7} className="p-0">
                        Số công việc:
                      </Col>
                      <Col xs={5} className="p-0">
                        <b>{detail?.user_enterprise_info?.countJob || '0'}</b>
                      </Col>
                    </Row>
                    <Row className="p-0">
                      <Col xs={7} className="p-0">
                        Số sale:
                      </Col>
                      <Col xs={5} className="p-0">
                        <b>
                          {detail?.user_enterprise_info?.countTelesale || '0'}
                        </b>
                      </Col>
                    </Row>
                    <Row className="p-0">
                      <Col xs={7} className="p-0">
                        Đầu số:
                      </Col>
                      <Col xs={5} className="p-0">
                        <b>
                          {detail?.user_enterprise_info?.countHotline || '0'}
                        </b>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} className="p-0">
                    <Row className="p-0">
                      <Col xs={7} className="p-0">
                        Số sale của hệ thống:
                      </Col>
                      <Col xs={5} className="p-0">
                        <b>{'0'}</b>
                      </Col>
                    </Row>
                    <Row className="p-0">
                      <Col xs={7} className="p-0">
                        Số phút gọi còn lại:
                      </Col>
                      <Col xs={5} className="p-0">
                        <b>
                          {parseInt(
                            detail?.user_enterprise_info?.call_duration_remain /
                              60
                          ) || '0'}
                        </b>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              {/** Lịch sử giao dịch */}
              <Col className="bg mb-3 p-4">
                <Row className="pb-2">
                  <h5>Lịch sử giao dịch</h5>
                </Row>

                <Row>
                  <div class="table-responsive">
                    <Table bordered hover>
                      <thead>
                        <tr>
                          <th>STT</th>
                          <th>Tên nhóm</th>
                          <th>Số sale</th>
                          <th>Số công việc</th>
                          <th>Số phút gọi</th>
                          <th>Thời gian</th>
                          <th>Trạng thái</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoadingHistory && (
                          <tr>
                            <td colspan="100%">
                              <Space size="middle">
                                <Spin />
                              </Space>
                            </td>
                          </tr>
                        )}
                        {packageHistory && packageHistory.length ? (
                          packageHistory?.map((pack, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{pack?.package_name || ''}</td>
                              <td>{pack?.max_telesale || '0'}</td>
                              <td>{pack?.max_job || '0'}</td>
                              <td>
                                {parseInt(pack?.call_duration / 60) || '0'}
                              </td>
                              <td>
                                {Intl.DateTimeFormat('vi-VN').format(
                                  new Date(pack?.created_at)
                                ) || null}
                              </td>
                              <td>
                                {pack?.is_active == 1 ? (
                                  <Tag color="success">Đang hoạt động</Tag>
                                ) : pack?.is_active == 2 ? (
                                  <Tag color="warning">Tạm ngưng</Tag>
                                ) : (
                                  <Tag color="error">Ngừng hoạt động</Tag>
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <td colspan="7">
                            <Empty
                              description={<span>Không có dữ liệu</span>}
                            />
                          </td>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Row>
                {/* <Row>
          <PaginationComponent paging={paging} handlePageChange={this.props.getPackageHistory({ enterprise_id: id })} />
        </Row> */}
              </Col>
            </Col>
          </Row>
          {/* <Row sm={4} style={{ justifyContent: 'center' }} className="mt-2">
            <Button variant="secondary" className="text-white" onClick={() => this.setShowModalInfo(false)}>
              Đóng
            </Button>
          </Row> */}
        </Modal.Body>
      </Modal>
    );
  };

  ClientStatus = () => {
    const { callClients } = this.props;
    return (
      <FlexColumnContainer>
        <CaretUpOutlined
          style={{
            color:
              callClients[PROVIDER_NAME.STRINGEE] === CLIENT_STATUS.registered
                ? 'green'
                : 'red',
            marginBottom: '-2px',
          }}
        />
        <CaretDownOutlined
          style={{
            color:
              callClients[PROVIDER_NAME.ITY] === CLIENT_STATUS.registered
                ? 'green'
                : 'red',
            marginTop: '-2px',
          }}
        />
      </FlexColumnContainer>
    );
  };

  render() {
    const Username =
      this.props.userInfoState?.data?.role_id == USER_ROLE.ENTERPRISE
        ? this.props.userInfoState?.data?.user_enterprise_info?.office_name
        : this.props.userInfoState?.data?.full_name;

    const listNotis = this.state.listNoti;
    const listNotifications = (
      <div
        style={{
          backgroundColor: 'white',
          width: '350px',
          margin: 10,
          border: '1px rgba(0,0,0,.29) solid',
          boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 18%)',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexFlow: 'nowrap',
            backgroundColor: 'white',

            justifyContent: 'space-between',
            padding: 5,
          }}
        >
          <div style={{ fontWeight: 500, fontSize: 16 }}>Thông báo</div>
          <div
            style={{ cursor: 'pointer' }}
            onClick={async () => {
              try {
                this.setState({ isLoading: true });
                const res = await requestReadNoti({
                  id: 0,
                  is_read_all: 1,
                });
                this.setState({
                  ...this.state,
                  read_noti: true,
                  isLoading: false,
                });
              } catch (err) {
                console.log('err: ', err);
                this.setState({ ...this.state, isLoading: false });
              }
              this.getListNoti();
            }}
          >
            Đọc hết
          </div>
        </div>
        <div
          style={{ overflowY: 'auto', maxHeight: '400px' }}
          ref={this.scrollRef}
          // onScroll={() => {
          //   console.log('this.scrollRef: ', this.scrollRef);
          //   if (
          //     this.scrollRef.current!.scrollTop +
          //       this.scrollRef.current!.clientHeight >=
          //     this.scrollRef.current!.scrollHeight
          //   ) {
          //     let old = this.scrollRef.current!.scrollTop
          //     if (loadMoreNotification) {
          //       console.log('loadMoreNotification: ', loadMoreNotification)
          //       this.scrollRef.current!.scrollTop = old - 50
          //       getMoreDataNotify()
          //     }
          //   }
          // }}
        >
          {listNotis.length ? (
            listNotis.map(item => (
              <div>
                {/* <Spin> */}
                <Tooltip
                  style={{}}
                  title={item.content}
                  // overlayStyle={{ margin: '15px 0px 15px 10px' }}
                  placement="leftTop"
                >
                  <li
                    onClick={async () => {
                      const metaData = JSON.parse(item.meta_data);
                      try {
                        this.setState({ isLoading: true });
                        const res = await requestReadNoti({
                          id: item?.id,
                          is_read_all: 0,
                        });
                        this.setState({
                          ...this.state,
                          read_noti: true,
                          isLoading: false,
                          idNotiDetail: metaData.opportunity_id,
                          statusNoti: item?.status,
                        });
                        this.getListNoti();
                      } catch (err) {
                        console.log('err: ', err);
                        this.setState({ ...this.state, isLoading: false });
                      }

                      // navigate cho sale
                      if (this.props.userInfoState?.data?.role_id === 5) {
                        if (
                          item?.type === NOTI_TYPE.NEW_JOB ||
                          item?.type === NOTI_TYPE.ROLL_BACK
                        ) {
                          this.props?.history?.push(ROUTER.JOB_SALE);
                        }

                        if (item?.type === NOTI_TYPE.TASK_RECALL) {
                          this.props?.history?.push(ROUTER.RECALL_SALE);
                        }
                      }

                      if (
                        item?.type === NOTI_TYPE?.OPPORTUNITY_STATUS ||
                        item?.type === NOTI_TYPE?.NEW_OPPORTUNITY ||
                        item?.type === NOTI_TYPE?.SUCCESS_OPPORTUNITY ||
                        item?.type === NOTI_TYPE?.FAIL_OPPORTUNITY ||
                        item?.type === NOTI_TYPE?.OPPORTUNITY_NOTE ||
                        item?.type === NOTI_TYPE?.OPPORTUNITY_CREATE
                      ) {
                        if (this.props.userInfoState?.data?.role_id == 5) {
                          this.props?.history?.push(
                            ROUTER.OPPORTUNITY_DETAIL_SALE +
                              `/${metaData.opportunity_id}`
                          );
                        } else {
                          this.props?.history?.push(
                            ROUTER.OCCASION_DETAIL +
                              `/${metaData.opportunity_id}`
                          );
                        }
                      }
                      if (item?.type === NOTI_TYPE?.LOCATION_REPORT) {
                        if (
                          this.props.userInfoState?.data?.role_id ==
                          ROLE_WEB_SALE
                        ) {
                          this.props?.history?.push(
                            ROUTER.LOCATION_REPORT_DETAIL +
                              `?telemarketingId=${metaData.user_id}`
                          );
                        } else {
                          this.props?.history?.push(
                            ROUTER.LOCATION_REPORT_DETAIL +
                              `?telemarketingId=${metaData.user_id}`
                          );
                        }
                      }
                      if (item?.type === NOTI_TYPE?.CALL_REVIEW) {
                        if (
                          this.props.userInfoState?.data?.role_id ==
                          ROLE_WEB_SALE
                        ) {
                          this.props?.history?.push(
                            ROUTER.CALL_DETAIL_SALE + `/${metaData.call_id}`
                          );
                        }
                      }
                      if (item?.type === NOTI_TYPE?.RECALL) {
                        this.props?.history?.push(ROUTER.RECALL_SALE);
                      }
                      if (item?.type === NOTI_TYPE.CHANGE_FROM_SALE) {
                        this.props?.history?.push(ROUTER.JOB_SALE);
                      }
                    }}
                  >
                    <ItemNoti
                      style={item?.status !== 2 ? { fontWeight: 700 } : {}}
                    >
                      <div>
                        <Avatar
                          style={{
                            backgroundColor: '#ffce3d',
                            verticalAlign: 'middle',
                          }}
                          icon={<StarNotiIcon />}
                        />
                      </div>
                      <Paragraph
                        style={{ margin: '0 10px 0 10px' }}
                        ellipsis={{ rows: 3 }}
                      >
                        <div>{item?.content}</div>
                      </Paragraph>
                      {item?.status === 2 ? (
                        <></>
                      ) : (
                        <Badge status="processing" />
                      )}
                      {}
                    </ItemNoti>
                    <Divider style={{ margin: 0 }} />
                  </li>
                </Tooltip>
                {/* </Spin> */}
              </div>
            ))
          ) : (
            <Empty description="Không có thông báo" />
          )}
          <div
            style={{
              fontSize: '25px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {/* {loadMoreNotification ? <LoadingOutlined /> : <></>} */}
          </div>
          {this.state.count === listNotis.length ? (
            <></>
          ) : (
            <div
              style={{
                marginTop: '5px',
                height: '30px',
                display: 'flex',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                this.getListMoreNoti();
              }}
            >
              [ Xem thêm ]{/* {!loadMoreNotification ? '[ Xem thêm ]' : ''} */}
            </div>
          )}
        </div>
      </div>
    );
    mixpanel.init(TOKEN_MIXPANEL, { debug: true });
    mixpanel.register({ userName: `${Username}` });
    return (
      <>
        {this.state.isLoading ? <Loading /> : null}
        {this.changePasswordModal()}
        {this.companyInfoModal()}
        <nav
          className="main-header navbar navbar-expand navbar-light me-header"
          style={{ zIndex: 1 }}
        >
          {/* Left navbar links */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link cursor" data-widget="pushmenu">
                <i className="fas fa-bars" />
              </a>
            </li>
          </ul>

          {/* Right navbar links */}
          {this.ClientStatus()}
          {this.props.userInfoState?.data?.role_id == 5 && (
            <div style={{ display: 'flex' }}>
              <SelectHotLine
                openDropDown={this.state.openDropDown}
                openDropDownFn={this.openDropDownFn}
                onChangeListHotLine={this.onChangeListHotLine}
                listHotLine={this.state.listHotLine}
                valueDropDown={
                  this.state.valueDropDown

                  // Cookie.get('valueDropDown') || this.state.valueDropDown
                }
              />
              <MicrophoneList />
            </div>
          )}
          <ul className="navbar-nav ml-auto">
            {this.props.userInfoState?.data?.role_id == 6 && (
              <>
                <i
                  className="fas fa-bell fa-2x bell mr-3"
                  data-toggle="dropdown"
                ></i>
                <div
                  className="dropdown-menu dropdown-menu-lg dropdown-menu-right"
                  style={{
                    width: '250px',
                    marginTop: '-10px',
                    marginRight: '45px',
                    padding: '0',
                  }}
                >
                  <a
                    className="dropdown-item cursor"
                    style={{
                      borderBottom: '1px solid #eeeeee',
                      padding: '20px 10px',
                    }}
                  >
                    <div className="dropdown-admin-item">
                      <p className="me-txt-admin-drop">Thông báo</p>
                    </div>
                  </a>
                  <a
                    className="dropdown-item cursor"
                    style={{
                      borderBottom: '1px solid #eeeeee',
                      padding: '20px 10px',
                    }}
                  >
                    <div className="dropdown-admin-item">
                      <p className="me-txt-admin-drop">Thông báo</p>
                    </div>
                  </a>
                </div>
              </>
            )}

            {/* dropdown import export */}
            <ImportExport />

            {/* {this.props.userInfoState?.data?.role_id == 5 ? (
              <></>
            ) : ( */}
            <li className="nav-item">
              <Dropdown
                overlay={listNotifications}
                trigger={['click']}
                onVisibleChange={this.handleVisibleChange}
                visible={this.state.visibleNotiDropdown}
              >
                <a
                  style={{ margin: '0 10px 0 0' }}
                  onClick={e => {
                    e.preventDefault();
                  }}
                >
                  <Badge
                    count={this.state.countUnread}
                    size="small"
                    overflowCount={99}
                    offset={[0, 2]}
                    color="#ef8737"
                  >
                    <BellNotiIcon />
                  </Badge>
                </a>
              </Dropdown>
            </li>
            {/* )} */}

            {/* Dropdown Admin Menu */}
            <li>
              <AvatarUser userInfo={this.props.userInfoState?.data} />
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link" data-toggle="dropdown" href="#">
                <p className="me-txt-menu">
                  <span className="user-name">
                    {this.props.userInfoState?.data?.role_id == 2
                      ? this.props.userInfoState?.data?.user_enterprise_info
                          ?.office_name
                      : this.props.userInfoState?.data?.full_name}
                  </span>
                  <i className="fas fa-chevron-down px-1"></i>
                </p>
              </a>

              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                {this.props.userInfoState.data?.role_id === 2 && (
                  <a
                    className="dropdown-item cursor"
                    onClick={() => this.setShowModalInfo(true)}
                  >
                    <div className="dropdown-admin-item">
                      <p className="me-txt-admin-drop">Thông tin công ty</p>
                    </div>
                  </a>
                )}
                <a
                  className="dropdown-item cursor"
                  onClick={() => this.setShowModal(true)}
                >
                  <div className="dropdown-admin-item">
                    <p className="me-txt-admin-drop">Đổi mật khẩu</p>
                  </div>
                </a>
                <a className="dropdown-item cursor" onClick={this.logout}>
                  <div className="dropdown-admin-item">
                    <p className="me-txt-admin-drop">Đăng xuất</p>
                  </div>
                </a>
              </div>
            </li>
          </ul>
        </nav>
      </>
    );
  }
}

const mapStateToProps = state => ({
  userInfoState: state.userReducer,
  packageHistoryState: state.packageHistoryReducer,
  listHotLine: state.hotline,
});

const mapDispatchToProps = {
  getUserInfo,
  getProvinceList,
  getPackageHistory,
  getListhotlineNew,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
