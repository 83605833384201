import {
    GET_ACCOUNT, GET_ACCOUNT_SUCCESS, GET_ACCOUNT_FAIL,
    CREATE_ACCOUNT, CREATE_ACCOUNT_SUCCESS, CREATE_ACCOUNT_FAIL,
    UPDATE_ACCOUNT, UPDATE_ACCOUNT_SUCCESS, UPDATE_ACCOUNT_FAIL,
} from "@actions/type";

const initialState = {
    data: {},
    isLoading: false,
    error: null
};

export default function (state = initialState, action) {

    switch (action.type) {

        // *************  GET INFO ****************//
        case GET_ACCOUNT:
            return {
                ...state,
                isLoading: true,
            }
            break;
        case GET_ACCOUNT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload.response,
                error: null
            }
            break;
        case GET_ACCOUNT_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
            break;


        // **************  CREATE  ****************//
        case CREATE_ACCOUNT:
            return {
                ...state,
                isLoading: false,
                data: [...state.data, action.payload],
                error: null
            }

        // **************  UPDATE  ****************//
        default:
            return state;
    }
}