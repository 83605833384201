import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import '@styles/Company.css';
import Table from 'react-bootstrap/Table';
import { Row, Col, FormControl, Button, Modal, FormGroup } from 'react-bootstrap';
import { ACTIVE_STATUS, DEFAULT_IMAGE } from '@constants/Constant';
import Pagination from 'react-js-pagination';
import { getCompanyList } from '@src/redux/actions';
import { getItemFromId } from '@utils/getItemFromId';
import reactotron from 'reactotron-react-js';
import { ROUTER } from '@constants/Constant';
import Loading from '@src/components/Loading';
import { Empty } from 'antd';
import { requestDeleteAccount } from '@constants/Api';
import { notifyFail, notifySuccess } from '@utils/notify';

class CompanyScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modelAdd: false,
      modelEdit: false,
      modalDelete: false,
      id: '',
      modalAddField: {
        name: '',
        phone: '',
        email: '',
        password: '',
        repassword: '',
      },
      modalEditField: {
        name: 'Nguyễn Tài Thao',
        phone: '0976557026',
        email: 'thaogreenhouses@gmail.com',
      },
      activePage: 1,
      isLoading: false,
      search: '',
      is_active: '',
      page: '',
    };
  }
  componentDidMount() {
    this.getData({ page: 1 });
  }

  getData({ page, search, is_active }) {
    this.props.getCompanyList({
      page: page || '',
      search: search || '',
      is_active: is_active || '',
    });
  }

  async requestDeleteAccount(id) {
    try {
      await requestDeleteAccount({ id: id });
      this.setState({ modalDelete: false }, () => notifySuccess('Xóa thành công'));
      this.getData({ page: 1 });
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  renderTitle() {
    return (
      <>
        <Row>
          <Col md={5} sm={12}>
            <h2 className="text-left" style={{ marginBottom: '10px' }}>
              {' '}
              Công ty
            </h2>
            <div class="line"></div>
          </Col>
          <br />
          <Col md={7} sm={12}>
            {this.renderButton()}
          </Col>
        </Row>
      </>
    );
  }

  renderBody() {
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <Row
            className="mt-1"
            style={{ textAlign: 'center', padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}
          >
            <Col md className="bg">
              {this.renderTitle()}
              {this.renderField()}
            </Col>
          </Row>
          <Row>
            <Col md={12} style={{ padding: '0 20px' }}>
              {this.renderTable()}
            </Col>
          </Row>
          <Row>
            <Col md={12} className="d-flex justify-content-center justify-content-md-end" style={{ padding: '0 20px' }}>
              {this.renderPagination()}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderButton() {
    return (
      <div className="text-center text-md-right">
        <Link to={ROUTER.COMPANY_ADD}>
          <Button variant="success" className="text-light" onClick={() => this.setShowModal('modalAdd', true)}>
            Thêm công ty
          </Button>
        </Link>
      </div>
    );
  }

  renderField() {
    const { search, is_active } = this.state;
    return (
      <div className="w-100">
        <Row>
          <Col md={6} sm={12}>
            <FormControl
              type="text"
              id=""
              placeholder="Tên, số điện thoại công ty, tên người liên hệ"
              value={search}
              onChange={(e) => this.searchBarChange('search', e.target.value)}
              onKeyPress={(e) => this.handleKeyPress(e)}
            />
          </Col>
          <Col md={6} sm={12}>
            <FormControl
              as="select"
              id=""
              value={is_active}
              onChange={(e) => this.searchBarChange('is_active', e.target.value)}
            >
              <option value="">Trạng thái</option>
              <option value="1">Đang hoạt động</option>
              <option value="2">Tạm Ngưng hoạt động</option>
            </FormControl>
          </Col>
        </Row>
      </div>
    );
  }

  deleteModal = () => {
    const { modalDelete } = this.state;
    const { id } = this.state;
    return (
      <Modal
        show={modalDelete}
        onHide={() => this.handleChangeModal('modalDelete', false)}
        dialogClassName="modal-90w"
        centered
        scrollable
      >
        <Modal.Header closeButton className="text-white bg-primary pb-0" style={{ color: 'white' }}>
          <h5 className="text-light pb-0">Bạn chắc chắn muốn xóa?</h5>
        </Modal.Header>
        <Modal.Footer className="justify-content-end">
          <Button variant="success" className="text-light" onClick={() => this.requestDeleteAccount(id)}>
            Có
          </Button>
          <Button variant="danger" className="text-light" onClick={() => this.handleChangeModal('modalDelete', false)}>
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderTable() {
    const companyList = this.props.companyState?.data?.data;
    const itemsCount = this.props.companyState?.data?.paging?.totalItemCount;
    return (
      <div className="w-100 table-wrap">
        <span className="pb-2 d-inline-block">
          Kết quả lọc: <b className="text-danger">{itemsCount || 0}</b>
        </span>
        <Table  hover>
          <tbody>
            {companyList?.length ? (
              companyList.map((company) => this.renderItem(company))
            ) : (
              <Empty description={<span>Không có dữ liệu</span>} />
            )}
          </tbody>
        </Table>
      </div>
    );
  }

  renderItem(company) {
    const is_active = getItemFromId(ACTIVE_STATUS, company.is_active);
    return (
      <>
        <Row className="mt-1" style={{ padding: '0px 0px', borderRadius: '8px', marginBottom: 30 }}>
          <Col
            md
            style={{
              padding: '20px 0px 25px 30px',
              backgroundColor: '#ffffff',
              borderRadius: '8px',
            }}
          >
            <Row
              style={{
                marginLeft: '30px',
                marginRight: '30px',
                padding: '10px',
                marginBottom: '15px',
                minHeight: '150px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Col md={3} className="avatar">
                <img
                  src={company.user_enterprise_info?.profile_image || DEFAULT_IMAGE}
                  className="w-100 avt-pic text-blue"
                  alt=" "
                  style={{ objectFit: 'contain', borderRadius: 10 }}
                />
              </Col>
              <Col md={6}>
                <Row className="company">
                  <Link>
                    {' '}
                    <span className="company-name">{company.user_enterprise_info?.office_name || '--'}</span>
                  </Link>
                </Row>
                <Row>
                  <Col md={6}>
                    <i className="fas fa-envelope icon-contact"></i>
                    {company.email || '###'}
                  </Col>
                  <Col md={6}>
                    <i className="fas fa-user icon-contact"></i>
                    {company.full_name || '###'}
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <i className="fas fa-phone-alt icon-contact"></i>
                    {company.phone || '--'}
                  </Col>
                  <Col md={6} className="text-success">
                    <i class="far fa-check-circle icon-contact"></i>
                    <b>{(is_active && is_active.name) || '--'}</b>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <i className="fas fa-map-marker icon-contact"></i>
                    {company.user_enterprise_info?.address || '--'}
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <i class="fab fa-centercode icon-contact"></i>
                    {company.user_enterprise_info?.code || '--'}
                  </Col>
                </Row>
              </Col>

              <Col md={3} className="function-area">
                <Link to={ROUTER.COMPANY_DETAIL + `/${company.id}`}>
                  <Button variant="secondary" className="function-btn rounded text-light">
                    Chi tiết
                  </Button>
                </Link>
                <br />
                <Link to={ROUTER.COMPANY_EDIT + `/${company.id}`}>
                  <Button variant="info" className="function-btn rounded text-light">
                    Chỉnh sửa
                  </Button>
                </Link>
                <br />
                <Button
                  variant="danger"
                  className="function-btn rounded text-light"
                  onClick={() => this.handleChangeModal('modalDelete', true, company.id)}
                >
                  Xóa
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  handleChangeModal = (fieldName, value, id) => {
    this.setState({
      ...this.state,
      [fieldName]: value,
      id: id,
    });
  };

  renderPagination() {
    const itemsCount = this.props.companyState?.data?.paging?.totalItemCount;
    const limit = this.props.companyState?.data?.paging?.limit;
    const { search, is_active } = this.state;
    return (
      <Pagination
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled
        activePage={this.state.activePage}
        itemsCountPerPage={limit}
        totalItemsCount={itemsCount}
        pageRangeDisplayed={5}
        hideNavigation
        onChange={
          (page) => {
            this.setState({
              ...this.state,
              activePage: page,
            });
            this.getData({ page, search, is_active });
          }
          //   this.getData(page, search, is_active))
        }
      />
    );
  }

  setShowModal = (modal, bool) => {
    this.setState({
      [modal]: bool,
    });
  };
  searchBarChange = async (field, value) => {
    this.setState(
      {
        ...this.state,
        [field]: value,
      },
      () => {
        if (field !== 'search') {
          const { search, is_active, page } = this.state;
          this.getData({
            search: search || '',
            is_active: is_active || '',
            page: page || '',
          });
        }
      }
    );
  };

  handleKeyPress = (e) => {
    let { search, is_active, page } = this.state;
    if (e.charCode === 13) {
      search = search || '';
      page = 1;
      is_active = is_active || '';
      this.getData({
        search,
        page,
        is_active,
      });
    }
  };

  render() {
    const { isLoading, error } = this.props.companyState;
    let loading = <Loading />;
    if (error) {
      notifyFail(error.msg);
    }
    if (!isLoading) {
      loading = null;
    }
    return (
      <>
        {loading}
        {this.deleteModal()}
        {this.renderBody()}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  companyState: state.companyReducer,
});

const mapDispatchToProps = {
  getCompanyList,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompanyScreen));
