export function removeVietnameseDiacritics(str) {
  const AccentsMap = [
    'aàảãáạăằẳẵắặâầẩẫấậ',
    'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
    'dđ',
    'DĐ',
    'eèẻẽéẹêềểễếệ',
    'EÈẺẼÉẸÊỀỂỄẾỆ',
    'iìỉĩíị',
    'IÌỈĨÍỊ',
    'oòỏõóọôồổỗốộơờởỡớợ',
    'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
    'uùủũúụưừửữứự',
    'UÙỦŨÚỤƯỪỬỮỨỰ',
    'yỳỷỹýỵ',
    'YỲỶỸÝỴ',
    '/',
    ' ',
  ];

  // const regex = /[^a-zA-Z0-9]/g;
  // // eslint-disable-next-line no-param-reassign
  // str = str.replace(/[^a-zA-Z0-9]/g, '');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < AccentsMap.length; i++) {
    const re = new RegExp(`[${AccentsMap[i].substr(1)}]`, 'g');
    const char = AccentsMap[i][0];
    // eslint-disable-next-line no-param-reassign
    str = str.replace(re, char);
  }
  let replaceCharacternotInregex = str?.replace(/[^\w -]/g, '');
  let valueAtrCode = replaceCharacternotInregex?.replace(/\s+/g, '_');
  return valueAtrCode;
}
