import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Col, DatePicker, Row, Table } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

import MultiSelectControl from '@components/ui/forms/MultiSelectControl';
import { VietnameseString } from '@constants/VietnameseString';
import { CALL_STATUS, ROUTER } from '@constants/Constant';
import {
  requestGetReportCallInJob,
  requestGetTelemarketingInJob,
  requestSubJobFilter,
} from '../services/api';

import { TableBorderedAntStyle } from '@styles/Style';

const { RangePicker } = DatePicker;
const { Column, ColumnGroup } = Table;

const colSpanSelect = { xxl: 4, xl: 6, lg: 6, md: 8, sm: 24, xs: 24 };
const colSpanRangePicker = { xxl: 8, xl: 12, lg: 12, md: 8, sm: 24, xs: 24 };
const widthColumnInTable = 120;

const formatDateTime = 'YYYY/MM/DD';
const noneRation = [VietnameseString.average];

function General(props) {
  const {
    isSubJob,
    jobDetail,
    handleChangeSearchParams,
    handleNavigatingToCustomersTab,
    handleNavigatingToCallList,
  } = props;

  const { id: jobId } = useParams();
  const history = useHistory();

  const columns = [
    {
      title: '',
      width: widthColumnInTable,
      fixed: 'left',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: VietnameseString.total_number_of_calls,
      width: widthColumnInTable,
      dataIndex: 'total',
      key: 'total',
      render: (value, record) => renderValueColumn(value, record),
      onCell: record => {
        return {
          onClick: () => {
            handleNavigatingToCustomersTab({
              params,
              record,
            });
          },
        };
      },
    },
    {
      title: VietnameseString.busy_call_back_later,
      width: widthColumnInTable,
      dataIndex: 'callBusyCallBackLater',
      key: 'callBusyCallBackLater',
      render: (value, record) => renderValueColumn(value, record),
      onCell: record => {
        return {
          onClick: () => {
            handleNavigatingToCustomersTab({
              params,
              record,
              callStatus: CALL_STATUS.BUSY_CALL_BACK_LASTER,
            });
          },
        };
      },
    },
    {
      title: VietnameseString.do_not_answer_the_phone,
      width: widthColumnInTable,
      dataIndex: 'callNotAnswer',
      key: 'callNotAnswer',
      render: (value, record) => renderValueColumn(value, record),
      onCell: record => {
        return {
          onClick: () => {
            handleNavigatingToCustomersTab({
              params,
              record,
              callStatus: CALL_STATUS.DO_NOT_ANSWER_THE_PHONE,
            });
          },
        };
      },
    },
    {
      title: VietnameseString.wrong_information,
      width: widthColumnInTable,
      dataIndex: 'callWrong',
      key: 'callWrong',
      render: (value, record) => renderValueColumn(value, record),
      onCell: record => {
        return {
          onClick: () => {
            handleNavigatingToCustomersTab({
              params,
              record,
              callStatus: CALL_STATUS.WRONG_INFORMATION,
            });
          },
        };
      },
    },
    {
      title: VietnameseString.agree_to_exchange,
      width: widthColumnInTable,
      dataIndex: 'callAgree',
      key: 'callAgree',
      render: (value, record) => renderValueColumn(value, record),
      onCell: record => {
        return {
          onClick: () => {
            handleNavigatingToCustomersTab({
              params,
              record,
              callStatus: CALL_STATUS.AGREE_TO_EXCHANGE,
            });
          },
        };
      },
    },
    {
      title: VietnameseString.unreported,
      width: widthColumnInTable,
      dataIndex: 'callNotUpdateResult',
      key: 'callNotUpdateResult',
      render: (value, record) => renderValueColumn(value, record),
    },
    {
      title: VietnameseString.call_back_appointment,
      width: widthColumnInTable,
      dataIndex: 'callBackAppointment',
      key: 'callBackAppointment',
      render: (value, record) => renderValueColumn(value, record, false),
      onCell: record => {
        return {
          onClick: () => {
            handleNavigatingToCustomersTab({
              params,
              record,
              callStatus: CALL_STATUS.CALL_BACK_APPOINTMENT,
            });
          },
        };
      },
    },
    {
      title: VietnameseString.picked_up_the_phone,
      width: widthColumnInTable,
      dataIndex: 'callDurationGreat0s',
      key: 'callDurationGreat0s',
      render: (value, record) => renderValueColumn(value, record),
    },
    {
      title:
        VietnameseString.customer_called +
        '/' +
        VietnameseString.total_customer,
      width: widthColumnInTable,
      dataIndex: 'callCalled',
      key: 'callCalled',
      render: (value, record) => {
        // const total = dataTable.find((el) => (el.title = VietnameseString.total_number_of_calls));

        if (noneRation.includes(record.title)) {
          return (
            <span>
              <span style={{ color: 'blue' }}>{value}</span>
            </span>
          );
        } else {
          return (
            <span>
              <span style={{ color: 'blue' }}>{value}</span> |{' '}
              {record.callCountCustomer}
            </span>
          );
        }
      },
    },
    {
      title: VietnameseString.total_call_time + '(s)',
      width: widthColumnInTable,
      dataIndex: 'totaTimeCall',
      key: 'totaTimeCall',
      render: (value, record) => renderValueColumn(value, record, false),
    },
    {
      title: 'Số cuộc gọi trên 10s',
      width: widthColumnInTable,
      dataIndex: 'callDurationGreat10s',
      key: 'callDurationGreat10s',
      render: (value, record) => renderValueColumn(value, record),
    },
  ];

  const [params, setParams] = useState(() => {
    let p = {
      job_id: '',
      sub_job_id: [],
      telesale_id: [],
      from: moment(new Date()).startOf('week').format(formatDateTime),
      to: moment(new Date()).format(formatDateTime),
      department_id: [],
    };
    if (isSubJob) {
      p.sub_job_id = [Number(jobId)];
    } else {
      p.job_id = jobId;
    }

    return p;
  });
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [dataQuestion, setDataQuestion] = useState({
    question: '',
    answer: [],
  });

  function handleChangeParam(data) {
    handleChangeSearchParams(data);
    setParams(prevState => ({
      ...prevState,
      ...data,
      page: 1,
    }));
  }

  async function getReportCallInJob(params) {
    try {
      setIsLoading(true);
      const { data } = await requestGetReportCallInJob(params);

      const data_table = [
        { title: VietnameseString.total_number_of_calls, ...data.dataTotal },
        { title: VietnameseString.average, ...data.dataAvg },
      ];

      for (const [key, value] of Object.entries(data.result)) {
        data_table.push({ ...value, title: moment(key).format('DD/MM/YYYY') });
      }

      setDataTable(data_table);

      setDataQuestion({
        question: data.question,
        answer: data.jobAnswer,
      });
    } catch (error) {
      console.log('Error getReportCallInJob: ', { error });
    } finally {
      setIsLoading(false);
    }
  }

  function renderValueColumn(value, record, showRatio = true) {
    let ratio = 0;

    if (value) {
      ratio = Number((value / record.total) * 100).toFixed(2);
    }

    if (showRatio && !noneRation.includes(record.title)) {
      return (
        <span>
          <span style={{ color: 'blue' }}>{value}</span> | {ratio}%
        </span>
      );
    } else {
      return <span style={{ color: 'blue' }}>{value}</span>;
    }
  }

  function renderValueColumQuestion(value, record, answer) {
    let ratio = 0;
    const temp = value?.find(i => i.id === answer.id);
    if (temp) {
      ratio = Number((temp?.count / record.total) * 100).toFixed(2);
    }

    if (!noneRation.includes(record.title)) {
      return (
        <span>
          <span style={{ color: 'blue' }}>{temp ? temp.count : 0}</span> |{' '}
          {ratio}%
        </span>
      );
    } else {
      return <span style={{ color: 'blue' }}>{temp ? temp.count : 0}</span>;
    }
  }

  useEffect(() => {
    getReportCallInJob(params);
  }, [params]);

  useEffect(() => {
    const timestamp1 = moment(new Date()).startOf('week').unix();
    const timestamp2 = moment(jobDetail.created_at).unix();

    if (jobDetail.created_at && timestamp2 > timestamp1) {
      setParams({
        ...params,
        from: moment(jobDetail.created_at).format(formatDateTime),
      });
    }
  }, [jobDetail]);

  return (
    <div>
      <Filter
        isSubJob={isSubJob}
        params={params}
        jobDetail={jobDetail}
        handleChangeParam={handleChangeParam}
      />

      <TableBorderedAntStyle
        style={{ marginTop: '16px' }}
        loading={isLoading}
        scroll={{
          x: 'max-content',
          y: 450,
        }}
        dataSource={dataTable}
        pagination={false}
      >
        {columns.map((col, index) => (
          <Column key={index} {...col} />
        ))}
        {dataQuestion.question?.name && (
          <ColumnGroup title={dataQuestion.question?.name}>
            {dataQuestion.answer.map(answer => (
              <Column
                title={answer.name}
                dataIndex="callQuestion"
                key="callQuestion"
                width={widthColumnInTable}
                render={(value, record) =>
                  renderValueColumQuestion(value, record, answer)
                }
                onCell={record => {
                  return {
                    onClick: () => {
                      handleNavigatingToCallList({
                        params,
                        record,
                        dataQuestion,
                        answer,
                      });
                    },
                  };
                }}
              />
            ))}
          </ColumnGroup>
        )}
      </TableBorderedAntStyle>
    </div>
  );
}

function Filter(props) {
  const { isSubJob, params, jobDetail, handleChangeParam } = props;

  const { id: jobId } = useParams();

  const [subJob, setSubJob] = useState([]);
  const [telemarketing, setTelemarketing] = useState([]);

  async function getAllSubJob() {
    try {
      const { data } = await requestSubJobFilter({
        job_category_id: jobId,
        enterprise_id: '',
        telesale_id: '',
        limit: '',
      });
      setSubJob(_prevState => {
        return data.map(sub => ({
          label: sub.name,
          value: sub.id,
        }));
      });
    } catch (error) {
      console.log('Error getAllSubJob: ', { error });
    }
  }

  async function getAllTelemarketing() {
    try {
      const { data } = await requestGetTelemarketingInJob({
        job_id: jobId,
        limit: 10000,
      });

      setTelemarketing(_prevState => {
        return data.map(sale => ({
          label: sale.full_name,
          value: sale.id,
        }));
      });
    } catch (error) {
      console.log('Error getAllTelemarketing: ', { error });
    }
  }

  useEffect(() => {
    getAllSubJob();
    getAllTelemarketing();
  }, []);

  return (
    <Row gutter={[16, 16]}>
      {isSubJob ? (
        <Fragment />
      ) : (
        <Col {...colSpanSelect}>
          <MultiSelectControl
            className="w-100"
            options={subJob}
            value={params.sub_job_id}
            onChange={value => handleChangeParam({ sub_job_id: value })}
            placeholder={VietnameseString.sub_job}
          />
        </Col>
      )}

      <Col {...colSpanSelect}>
        <MultiSelectControl
          className="w-100"
          options={telemarketing}
          value={params.telesale_id}
          onChange={value => handleChangeParam({ telesale_id: value })}
          placeholder={VietnameseString.telemarketing}
        />
      </Col>

      <Col {...colSpanRangePicker}>
        <RangePicker
          className="w-100"
          format={'DD/MM/YYYY'}
          allowClear={false}
          value={[moment(params.from), moment(params.to)]}
          disabledDate={current => {
            return (
              current &&
              (current < moment(jobDetail.created_at).startOf('day') ||
                current > moment())
            );
          }}
          onChange={dates => {
            handleChangeParam({
              from: moment(dates[0]).format(formatDateTime),
              to: moment(dates[1]).format(formatDateTime),
            });
          }}
        />
      </Col>
    </Row>
  );
}

General.propTypes = {
  isSubJob: PropTypes.bool,
  jobDetail: PropTypes.object.isRequired,
  handleChangeSearchParams: PropTypes.func.isRequired,
  handleNavigatingToCustomersTab: PropTypes.func.isRequired,
  handleNavigatingToCallList: PropTypes.func.isRequired,
};

Filter.propTypes = {
  isSubJob: PropTypes.bool,
  params: PropTypes.shape({
    job_id: PropTypes.arrayOf(PropTypes.number).isRequired,
    telesale_id: PropTypes.arrayOf(PropTypes.number).isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    page: PropTypes.number.isRequired,
    department_id: PropTypes.arrayOf(PropTypes.number),
  }),
  jobDetail: PropTypes.object.isRequired,
  handleChangeParam: PropTypes.func.isRequired,
};

export default General;
