import './ReactotronConfig';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { SENTRY } from './constants/CFG';

console.log(process.env.npm_package_name + '@' + process.env.npm_package_version);
if (process.env.REACT_APP_SENTRY_RELEASE && SENTRY.DNS) {
  const SENTRY_RELEASE = process.env.REACT_APP_SENTRY_RELEASE;

  // Sentry.init({
  //   dsn: SENTRY.DNS,
  //   integrations: [new Integrations.BrowserTracing()],
  //   tracesSampleRate: 1.0,
  //   release: SENTRY_RELEASE,
  //   environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || process.env.NODE_ENV,
  //   ignoreErrors: [
  //     // Random plugins/extensions
  //     'top.GLOBALS',
  //     // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  //     'originalCreateNotification',
  //     'canvas.contentDocument',
  //     'MyApp_RemoveAllHighlights',
  //     'http://tt.epicplay.com',
  //     "Can't find variable: ZiteReader",
  //     'jigsaw is not defined',
  //     'ComboSearch is not defined',
  //     'http://loading.retry.widdit.com/',
  //     'atomicFindClose',
  //     // Facebook borked
  //     'fb_xd_fragment',
  //     // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  //     // reduce this. (thanks @acdha)
  //     // See http://stackoverflow.com/questions/4113268
  //     'bmi_SafeAddOnload',
  //     'EBCallBackMessageReceived',
  //     // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  //     'conduitPage',
  //   ],
  //   denyUrls: [
  //     // Facebook flakiness
  //     /graph\.facebook\.com/i,
  //     // Facebook blocked
  //     /connect\.facebook\.net\/en_US\/all\.js/i,
  //     // Woopra flakiness
  //     /eatdifferent\.com\.woopra-ns\.com/i,
  //     /static\.woopra\.com\/js\/woopra\.js/i,
  //     // Chrome extensions
  //     /extensions\//i,
  //     /^chrome:\/\//i,
  //     // Other plugins
  //     /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  //     /webappstoolbarba\.texthelp\.com\//i,
  //     /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  //   ],
  // });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
