import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';
import {
  Col,
  DatePicker,
  Descriptions,
  Empty,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Skeleton,
} from 'antd';
import { ExportOutlined, RedoOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

import {
  requestGetAllTemplateZNS,
  requestGetOA,
} from '@screens/Enterprise/Settings/zaloOA/services/api';
import {
  formatPrice,
  formatTime,
  getSearchParamsInUrl,
  rmVN,
  setSearchParamsInUrl,
} from '@constants/funcHelper';
import { useCancellableApi } from 'src/hooks/useCancellableApi';
import { VietnameseString } from '@constants/VietnameseString';
import SpinInButton from '@components/SpinInButton';
import { requestGetAllJob } from '@constants/Api';
import { notifySuccess } from '@utils/notify';
import { ROUTER } from '@constants/Constant';
import {
  requestExportZNSHistory,
  requestGetZNSHistory,
  requestResendZNS,
  requestStaticZNSHistory,
} from '../services/api';

import {
  greenColor,
  greenColorHover,
  blueColor,
  blueColorHover,
} from '@styles/Color';
import { ButtonSystemStyle, TableAntStyle } from '@styles/Style';
import { getStatusZNSStyle, SENDING_ZNS_STATUS } from '../Utils/constant';

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const SelectStyled = styled(Select)`
  width: 100%;
`;

const ContainerInformationStyled = styled.div`
  margin: 12px 0px;
`;

const DescriptionsStyled = styled(Descriptions)`
  span.ant-descriptions-item-label {
    width: 50%;
    font-weight: 600;
  }
`;

const ActionStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 12px;

  div {
    display: flex;
  }
`;

const layoutRowCol = {
  xxl: 4,
  xl: 6,
  lg: 6,
  md: 8,
  sm: 12,
  xs: 24,
};

function ZNSSendingHistory() {
  const userInfo = useSelector(store => store.userReducer.data);
  const history = useHistory();

  const timeoutRef = useRef();

  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 20,
  });
  const [params, setParams] = useState({
    enterprise_id: userInfo.enterprise_id,
    search: '',
    template_id: [],
    oa_provider_id: [],
    status: [],
    job_id: [],
    from_date: formatTime(new Date(), 'YYYY-MM-DD'),
    to_date: formatTime(new Date(), 'YYYY-MM-DD'),
    page: paging.current,
    limit: paging.pageSize,
  });

  const [isLoadingStatic, setIsLoadingStatic] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const [znsSendingHistory, setZnsSendingHistory] = useState([]);
  const [staticZNSSendingHistory, setStaticZNSSendingHistory] = useState({});

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const columns = [
    {
      title: VietnameseString.ordinal_number,
      dataIndex: '',
      key: '',
      render: (_value, _record, index) => (
        <>{(paging.current - 1) * paging.pageSize + index + 1}</>
      ),
    },
    {
      align: 'left',
      title: VietnameseString.customer_name,
      dataIndex: 'customer_infor',
      key: 'customer_infor',
      render: (value, _record, _index) => <>{value.name}</>,
    },
    {
      title: VietnameseString.phoneNumber,
      dataIndex: 'customer_infor',
      key: 'customer_infor',
      render: (value, _record, _index) => <>{value.phone}</>,
    },
    {
      align: 'left',
      title: VietnameseString.template,
      dataIndex: 'template_name',
      key: 'template_name',
      render: (value, record, _index) => (
        <span
          onClick={() => {
            const searchParamsInUrl = new URLSearchParams({
              id: JSON.stringify(record.template_id),
            });
            history.push({
              pathname: ROUTER.DETAIL_TEMPLATE_ZNS,
              search: searchParamsInUrl.toString(),
            });
          }}
        >
          {value}
        </span>
      ),
    },
    {
      title: VietnameseString.cost,
      dataIndex: 'price',
      key: 'price',
      render: (value, record, _index) => (
        <>
          -
          {record.status !== SENDING_ZNS_STATUS.CAN_NOT_SEND
            ? formatPrice(value)
            : 0}{' '}
          VNĐ
        </>
      ),
    },

    {
      title: 'Số tiền còn lại sau khi gửi',
      dataIndex: 'remain_money',
      key: 'remain_money',
      render: (value, _record, _index) => <>{formatPrice(value)} VNĐ</>,
    },
    {
      title: VietnameseString.status,
      dataIndex: 'status',
      key: 'status',
      render: (value, _record, _index) => {
        const result = getStatusZNSStyle(value);
        return <span style={{ color: result.color }}>{result.label}</span>;
      },
    },
    {
      title: VietnameseString.sending_time,
      dataIndex: 'modified_date',
      key: 'modified_date',
      render: (value, _record, _index) => <>{formatTime(value)}</>,
    },
    {
      align: 'left',
      title: VietnameseString.note,
      dataIndex: 'note',
      key: 'note',
    },
  ];

  /**
   * handleChangeParamZNSSendingHistory
   * @param {[field, value]} data
   */
  const handleChangeParamZNSSendingHistory = data => {
    data.push({ page: 1 });
    data.forEach(el => {
      setParams(prevState => ({
        ...prevState,
        ...el,
      }));
      for (const [key, value] of Object.entries(el)) {
        setSearchParamsInUrl(key, value);
      }
    });
  };

  const apiStaticZNSHistory = useCancellableApi(requestStaticZNSHistory);
  const apiGetZNSHistory = useCancellableApi(requestGetZNSHistory);

  const getStaticZNSHistory = useCallback(async params => {
    try {
      setIsLoadingStatic(true);
      const { page, limit, ...rest } = params;

      const { data } = await apiStaticZNSHistory(rest);
      setStaticZNSSendingHistory(data);
    } catch (error) {
      console.log('Error getStaticZNSHistory: ', { error });
    } finally {
      setIsLoadingStatic(false);
    }
  }, []);
  const debounceGetStaticZNSHistory = useCallback(
    _.debounce(getStaticZNSHistory, 500),
    []
  );

  const getZNSHistory = useCallback(async params => {
    try {
      setIsLoading(true);

      const { data, paging } = await apiGetZNSHistory(params);

      setZnsSendingHistory(data.map(el => ({ ...el, key: el.id })));
      setPaging({
        total: paging.count,
        current: paging.page,
        pageSize: paging.limit,
      });
    } catch (error) {
      console.log('Error getZNSHistory: ', { error });
    } finally {
      setIsLoading(false);
    }
  }, []);
  const debounceGetZNSHistory = useCallback(_.debounce(getZNSHistory, 500), []);

  const exportZNSHistory = useCallback(async params => {
    try {
      setIsLoadingButton(true);
      const { page, limit, ...rest } = params;

      await requestExportZNSHistory(rest);
    } catch (error) {
      console.log('Error exportZNSHistory: ', { error });
    } finally {
      setIsLoadingButton(false);
    }
  }, []);

  const handleRequestResendZNS = useCallback(
    async (selectedRowKeys, params) => {
      try {
        if (!selectedRowKeys.length) {
          message.warn('Vui lòng chọn tin nhắn có trạng thái Không gửi được!');
          return;
        }

        const { msg } = await requestResendZNS({
          message_history_ids: selectedRowKeys,
        });
        notifySuccess(msg);
        setSelectedRowKeys([]);

        timeoutRef.current = setTimeout(() => {
          getStaticZNSHistory(params);
          getZNSHistory(params);
        }, 1500);
      } catch (error) {
        console.log('Error handleRequestResendZNS:', { error });
      }
    },
    []
  );

  useEffect(() => {
    const searchParamsInUrl = getSearchParamsInUrl();
    setParams(prevState => ({
      ...prevState,
      ...searchParamsInUrl,
    }));
  }, []);

  useEffect(() => {
    debounceGetStaticZNSHistory(params);
    debounceGetZNSHistory(params);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [params]);

  return (
    <Fragment>
      <Filter
        enterprise_id={userInfo.enterprise_id}
        params={params}
        handleChangeParamZNSSendingHistory={handleChangeParamZNSSendingHistory}
      />

      {isLoadingStatic ? (
        <Skeleton active />
      ) : (
        <Information staticZNSSendingHistory={staticZNSSendingHistory} />
      )}

      <ActionStyled>
        <div>
          {VietnameseString.result}: {paging.total}
        </div>

        <div>
          <Popconfirm
            placement="bottomRight"
            title={VietnameseString.resend_selected_message}
            okText={VietnameseString.agree}
            cancelText={VietnameseString.cancel}
            onConfirm={() => handleRequestResendZNS(selectedRowKeys, params)}
          >
            <ButtonSystemStyle
              bgButtonColor={blueColor}
              bgButtonColorHover={blueColorHover}
            >
              <RedoOutlined style={{ marginRight: '10px' }} />
              {VietnameseString.re_send}
            </ButtonSystemStyle>
          </Popconfirm>

          <ButtonSystemStyle
            bgButtonColor={greenColor}
            bgButtonColorHover={greenColorHover}
            loading={isLoadingButton}
            onClick={() => {
              notifySuccess(
                VietnameseString.please_wait_for_a_few_minutes_to_process
              );
              exportZNSHistory(params);
            }}
          >
            {isLoadingButton ? (
              <SpinInButton />
            ) : (
              <ExportOutlined style={{ marginRight: '10px' }} />
            )}
            {VietnameseString.export_excel}
          </ButtonSystemStyle>
        </div>
      </ActionStyled>

      <TableAntStyle
        columns={columns}
        dataSource={znsSendingHistory}
        loading={isLoading}
        scroll={{ x: 'max-content' }}
        rowSelection={{
          selectedRowKeys,
          preserveSelectedRowKeys: true,
          getCheckboxProps: record => {
            return {
              disabled: record.status !== SENDING_ZNS_STATUS.CAN_NOT_SEND,
            };
          },
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
          },
        }}
        pagination={{
          showSizeChanger: false,
          ...paging,
          onChange: async (page, _pageSize) => {
            setParams({ ...params, page });
            setSearchParamsInUrl('page', page);
          },
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>{VietnameseString.no_data}</span>}
            />
          ),
        }}
      />
    </Fragment>
  );
}

function Filter(props) {
  const { enterprise_id, params, handleChangeParamZNSSendingHistory } = props;

  const [selectFilter, setSelectFilter] = useState({
    template: {
      placeholderText: VietnameseString.template,
      allowClear: true,
      mode: 'multiple',
      options: [],
      value: params.template_id,
      onChange: value => {
        handleSetValueInSelectFilter('template', value);
        handleChangeParamZNSSendingHistory([{ template_id: value }]);
      },
    },
    oa: {
      placeholderText: 'OA',
      allowClear: false,
      mode: '',
      options: [],
      value: [],
      onChange: value => {
        handleSetValueInSelectFilter('oa', value);
        handleChangeParamZNSSendingHistory([{ oa_provider_id: value }]);
      },
    },
    sendingStatus: {
      placeholderText: VietnameseString.status,
      allowClear: true,
      mode: 'multiple',
      options: Object.keys(SENDING_ZNS_STATUS).map(stt => ({
        value: SENDING_ZNS_STATUS[stt],
        ...getStatusZNSStyle(SENDING_ZNS_STATUS[stt]),
      })),
      value: params.status,
      onChange: value => {
        handleSetValueInSelectFilter('sendingStatus', value);
        handleChangeParamZNSSendingHistory([{ status: value }]);
      },
    },
    job: {
      placeholderText: VietnameseString.job,
      allowClear: true,
      mode: 'multiple',
      options: [],
      value: params.job_id,
      onChange: value => {
        handleSetValueInSelectFilter('job', value);
        handleChangeParamZNSSendingHistory([{ job_id: value }]);
      },
    },
  });

  const handleSetOptionsInSelectFilter = (field, data) => {
    setSelectFilter(prevState => ({
      ...prevState,
      [field]: {
        ...prevState[field],
        options: data,
      },
    }));
  };

  const handleSetValueInSelectFilter = (field, data) => {
    setSelectFilter(prevState => ({
      ...prevState,
      [field]: {
        ...prevState[field],
        value: data,
      },
    }));
  };

  const getJobs = useCallback(async () => {
    try {
      const { data } = await requestGetAllJob();
      const jobs = data.map(i => ({ value: i.id, label: i.name }));
      handleSetOptionsInSelectFilter('job', jobs);
    } catch (error) {
      console.log('Error getJobs: ', { error });
    }
  }, []);

  const getAllTemplateZNS = useCallback(async () => {
    try {
      const { data } = await requestGetAllTemplateZNS({ enterprise_id });
      const templates = data.map(i => ({
        value: i.template_id,
        label: i.template_name,
      }));
      handleSetOptionsInSelectFilter('template', templates);
    } catch (error) {
      console.log('Error getAllTemplateZNS: ', { error });
    }
  }, []);

  const getOA = useCallback(async () => {
    try {
      const { data } = await requestGetOA({ enterprise_id });
      const oa = data.map(i => ({ value: i.id, label: i.name }));
      handleSetOptionsInSelectFilter('oa', oa);
    } catch (error) {
      console.log('Error getOA: ', { error });
    }
  }, []);

  useEffect(() => {
    getAllTemplateZNS();
    getJobs();
    getOA();
  }, []);

  useEffect(() => {
    if (selectFilter.oa.options[0]) {
      handleSetValueInSelectFilter('oa', [selectFilter.oa.options[0]?.value]);
      handleChangeParamZNSSendingHistory([
        { oa_provider_id: [selectFilter.oa.options[0]?.value] },
      ]);
    }
  }, [selectFilter.oa.options]);

  useEffect(() => {
    const { template, oa, sendingStatus, job } = selectFilter;

    template.value = params.template_id;
    oa.value = params.oa_provider_id;
    sendingStatus.value = params.status;
    job.value = params.job_id;

    setSelectFilter({ template, oa, sendingStatus, job });
  }, [params]);

  return (
    <Row gutter={[16, 16]} align="middle">
      <Col {...layoutRowCol}>
        <Search
          placeholder={[
            VietnameseString.customer_name,
            VietnameseString.phoneNumber,
          ].join(', ')}
          value={params.search}
          onChange={event => {
            handleChangeParamZNSSendingHistory([
              { search: event.target.value },
            ]);
          }}
        />
      </Col>

      {Object.keys(selectFilter).map(i => {
        const { placeholderText, allowClear, mode, options, value, onChange } =
          selectFilter[i];

        return (
          <Col {...layoutRowCol} key={i}>
            <SelectStyled
              allowClear={allowClear}
              maxTagCount={1}
              mode={mode}
              optionFilterProp="children"
              placeholder={placeholderText}
              value={value}
              filterOption={(input, option) => {
                return rmVN(option.children).indexOf(rmVN(input)) >= 0;
              }}
              onChange={onChange}
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>{VietnameseString.no_data}</span>}
                />
              }
            >
              {options.map(option => (
                <Option
                  key={option.value}
                  style={{ color: option.color || 'black' }}
                  value={option.value}
                >
                  {option.label}
                </Option>
              ))}
            </SelectStyled>
          </Col>
        );
      })}

      <Col {...layoutRowCol}>
        <RangePicker
          format={'DD-MM-YYYY'}
          value={[
            params.from_date ? moment(params.from_date) : undefined,
            params.to_date ? moment(params.to_date) : undefined,
          ]}
          onChange={(dates, dateStrings) => {
            if (dates) {
              handleChangeParamZNSSendingHistory([
                {
                  from_date: formatTime(dates[0], 'YYYY-MM-DD'),
                  to_date: formatTime(dates[1], 'YYYY-MM-DD'),
                },
              ]);
            } else {
              handleChangeParamZNSSendingHistory([
                { from_date: '', to_date: '' },
              ]);
            }
          }}
        />
      </Col>
    </Row>
  );
}

function Information(props) {
  const { staticZNSSendingHistory } = props;

  return (
    <ContainerInformationStyled>
      <DescriptionsStyled title="" column={2}>
        <Descriptions.Item
          label={VietnameseString.number_of_messages_sent_successfully}
        >
          <span style={{ color: 'green' }}>
            {staticZNSSendingHistory?.message_success_count}
          </span>
        </Descriptions.Item>

        <Descriptions.Item
          label={VietnameseString.number_of_messages_sent_fail}
        >
          <span style={{ color: 'red' }}>
            {staticZNSSendingHistory?.message_error_count}
          </span>
        </Descriptions.Item>

        <Descriptions.Item label={VietnameseString.number_of_messages_sent}>
          <span style={{ color: 'blue' }}>
            {staticZNSSendingHistory?.message_sent_count}
          </span>
        </Descriptions.Item>

        <Descriptions.Item label={VietnameseString.number_of_price_sent}>
          {formatPrice(staticZNSSendingHistory?.total_price)} VNĐ
        </Descriptions.Item>
      </DescriptionsStyled>

      <div style={{ fontWeight: 600 }}>
        {VietnameseString.remaining_zns} / {VietnameseString.number_of_zns_sent}
        : {staticZNSSendingHistory.remaining_quota}/
        {staticZNSSendingHistory.daily_quota}
      </div>
    </ContainerInformationStyled>
  );
}

Filter.propTypes = {
  enterprise_id: PropTypes.number.isRequired,
  params: PropTypes.shape({
    search: PropTypes.string,
    template_id: PropTypes.arrayOf(PropTypes.number),
    status: PropTypes.arrayOf(PropTypes.number),
    job_id: PropTypes.arrayOf(PropTypes.number),
    from_date: PropTypes.string,
    to_date: PropTypes.string,
  }),
  handleChangeParamZNSSendingHistory: PropTypes.func.isRequired,
};

Information.propTypes = {
  staticZNSSendingHistory: PropTypes.object,
};

export default ZNSSendingHistory;
