import React from 'react';
import { FlexColumnContainer } from '../style';
import {
  CLIENT_STATUS,
  PROVIDER_NAME,
} from '@components/callProviders/CallConstant';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

const ClientStatus = ({ callClients }) => {
  return (
    <FlexColumnContainer>
      <CaretUpOutlined
        style={{
          color:
            callClients[PROVIDER_NAME.STRINGEE] === CLIENT_STATUS.registered
              ? 'green'
              : 'red',
          marginBottom: '-2px',
        }}
      />
      <CaretDownOutlined
        style={{
          color:
            callClients[PROVIDER_NAME.ITY] === CLIENT_STATUS.registered
              ? 'green'
              : 'red',
          marginTop: '-2px',
        }}
      />
    </FlexColumnContainer>
  );
};

export default ClientStatus;
