import { Empty, Skeleton, Tabs } from 'antd';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { getNextCustomer } from '@actions/';
import { requestFullListTag } from '@constants/Api';
import { OPPORTUNITY_STATUS, ROUTER } from '@constants/Constant';
import { useCancellableApi } from 'src/hooks/useCancellableApi';
import {
  requestGetJobCustomerList,
  requestGetJobDetail,
} from '../../../network/web_sale/JobApi';
import ModalAddOpportunity from '../Call/components/ModalAddOpportunity';
import { useNextCustomer } from '../Call/hook/useNextCustomer';
import Test from './Test';

import { BreadcrumbAntStyle } from '@styles/Style';
import '../../../styles/Job.css';

import CustomerList from './component/JobDetailTabs/TabCustomer';
import { TabsStyle, TabStyledTitle } from './StyleJob';
import Detail from './component/InfoGeneralDetails';
import UntestedDetail from './component/JobDetailTabs/InfoGeneralUntested';
const { TabPane } = Tabs;

function JobDetail(props) {
  const telesale_id = useSelector(
    states => states.userReducer.data.user_sale_info.id
  );
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [jobDetail, setJobDetail] = useState({});
  const [customerList, setCustomerList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [formattedPaging, setFormattedPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 0,
  });
  const [recordCustomer, setRecordCustomer] = useState();
  const [updateCustomerOppo, setUpdateCustomerOppo] = useState(false);
  const [visibleOppoModal, setVisibleOppoModal] = useState(false);
  const [params, setParams] = useState({
    job_id: Number(id),
    search: '',
    status: '',
    page: 1,
    tag_id: '',
    call_note: '',
    call_status_id: '',
    opportunity_status: '',
    fromDate: '',
    toDate: '',
    fromDateJob: '',
    toDateJob: '',
    telesale_id: telesale_id,
    time_recall_status: 0,
  });
  useEffect(() => {
    getDetail();
    getFullTag();
    if (!nextCustomerStore.nextId) {
      dispatch(
        getNextCustomer({
          jobId: id,
          currentPageNumber: formattedPaging.current,
        })
      );
    }
  }, []);

  //tester yêu cầu update cơ hội trong table không reload trang
  useEffect(() => {
    function findIndexCustomer() {
      let indexFinded = customerList?.findIndex(item => {
        return item.customer_id == recordCustomer?.customer_id;
      });
      if ((indexFinded && indexFinded > -1) || indexFinded === 0) {
        let customerListCopy = [...customerList];
        customerListCopy[indexFinded].opportunity_status =
          OPPORTUNITY_STATUS.CUSTOMER_POTENTIAL; //khách hàng tiềm năng
        setCustomerList([...customerListCopy]);
        setUpdateCustomerOppo(false);
        // setRecordCustomer
      }
    }
    if (updateCustomerOppo && recordCustomer?.customer_id) {
      findIndexCustomer();
    }
  }, [updateCustomerOppo, recordCustomer?.customer_id, customerList]);

  const nextCustomerStore = useSelector(state => state.nextCustomer.data);

  //cấu hình api theo params url
  const windowLocation = window.location.search;

  const searchParams = new URLSearchParams(windowLocation);

  const handleChangeURLSearchParams = (field, value) => {
    searchParams.set(field, JSON.stringify(value));
    history.push({
      pathname: window.location.pathname,
      search: searchParams.toString(),
    });
  };

  const handleChangeParamSearchCustomers = (field, value) => {
    console.log('value1111', value);

    if (field === 'call_status_id') {
      if (value == 0 && value !== '') {
        setParams({
          //nếu chọn hẹn gọi lại sau gửi  time_recall_status: 1,call_status_id =0
          ...params,
          [field]: 0,
          time_recall_status: 1,
          page: 1,
        });
      } else {
        //nếu không chọn hẹn gọi lại sau gửi  time_recall_status: 0,call_status_id =value
        setParams({
          ...params,
          [field]: value || '',
          time_recall_status: 0,
          page: 1,
        });
      }
    } else {
      setParams({
        ...params,
        [field]: value || '',
        page: 1,
        // time_recall_status: 0,
      });
    }
  };
  useEffect(() => {
    if (windowLocation) {
      // setIsLoadingTable(true);
      const fieldsSearch = searchParams.entries();

      let payload = {};
      for (let field of fieldsSearch) {
        payload = { ...payload, [field[0]]: JSON.parse(field[1]) };
      }
      if (!JSON.stringify(payload).includes('province_ids')) {
        payload = { ...payload, province_ids: [] };
      }
      if (!JSON.stringify(payload).includes('telesale_id')) {
        payload = { ...payload, telesale_id: [] };
      }

      setParams({ ...payload });
    }
  }, []);

  //kết thúc cấu hình api theo params url
  const apiGetListCustomerJob = useCancellableApi(requestGetJobCustomerList);

  const getDetail = async () => {
    try {
      setIsLoading(true);
      const res = await requestGetJobDetail({
        id: id,
      });
      setJobDetail({ ...res.data });
    } catch (error) {
      console.log('error', error);
    }
    setIsLoading(false);
  };

  const getCustomerList = async (payload = params) => {
    // console.log('getCustomerList', payload);

    try {
      //ghi giá trị param lên url search
      for (let field of Object.entries(payload)) {
        handleChangeURLSearchParams(field[0], field[1]);
      }

      setIsLoading(true);

      payload.telesale_id = telesale_id;
      const res = await apiGetListCustomerJob(payload);
      // const res = await ApiClient.get('job/customer', payload);
      setCustomerList([...res.data]);
      setFormattedPaging({
        total: res.paging.totalItemCount,
        current: res.paging.page,
        pageSize: res.paging.limit,
      });
    } catch (error) {
      console.log('error', error);
    }
    setIsLoading(false);
  };
  console.log('customerList', customerList);
  const debounceGetDataCustomerJob = useCallback(
    _.debounce(getCustomerList, 500),
    []
  );

  useEffect(() => {
    debounceGetDataCustomerJob(params);
  }, [params]);

  const getFullTag = async () => {
    try {
      setIsLoading(true);
      const res = await requestFullListTag();
      const convertedArr =
        res?.data?.map(tag => ({
          title: tag.name,
          label: tag.name,
          value: tag.id,
          key: tag.id,
        })) || [];
      setTagList(convertedArr);
    } catch (error) {
      console.log('Error getFullTag: ', { error });
    }
    setIsLoading(false);
  };

  const handleNavigate = (customerId, job_telesale_customer_id) => {
    dispatch(
      getNextCustomer({ jobId: id, currentPageNumber: formattedPaging.current })
    );
    history.replace({
      pathname: `${ROUTER.CUSTOMER_DETAIL_SALE}/${customerId}`,
      state: {
        job_telesale_customer_id: job_telesale_customer_id,
        jobId: id,
      },
    });
  };

  const handleChangePaging = page => {
    setParams({ ...params, page: page });
  };
  const { currentPageNumber } = nextCustomerStore;
  const { getNextCustomerWithJobId } = useNextCustomer({
    JobId: id,
    currentPageNumber: currentPageNumber,
  });

  return (
    <>
      {jobDetail?.exam_result !== undefined ? (
        <div className="content-wrapper">
          <BreadcrumbAntStyle>
            <BreadcrumbAntStyle.Item
              style={{ cursor: 'pointer' }}
              onClick={() => {
                history.push({
                  pathname: `${ROUTER.JOB_SALE}`,
                  state: {},
                });
              }}
            >
              Danh sách công việc
            </BreadcrumbAntStyle.Item>
            <BreadcrumbAntStyle.Item>
              Chi tiết công việc
            </BreadcrumbAntStyle.Item>
          </BreadcrumbAntStyle>

          <div className="content-header">
            <div
              style={{
                padding: '0px 5px 15px 5px',
                margin: '0 10px',
                borderRadius: '8px',
                backgroundColor: 'white',
              }}
            >
              <h4 style={{ padding: '15px 0 0 5px' }}>{jobDetail.name}</h4>

              <TabsStyle
                defaultActiveKey={jobDetail?.exam_result === 1 ? '1' : '3'}
                style={{ width: '100%' }}
              >
                {/* <TabsStyle> */}
                <TabPane
                  tab={<TabStyledTitle>Khách hàng</TabStyledTitle>}
                  key="1"
                >
                  {jobDetail?.exam_result === 1 ? (
                    <>
                      <CustomerList
                        data={customerList}
                        handleChangeParamSearchCustomers={
                          handleChangeParamSearchCustomers
                        }
                        telesale_id={telesale_id}
                        params={params}
                        setParams={setParams}
                        recordCustomer={recordCustomer}
                        setRecordCustomer={setRecordCustomer}
                        setVisibleOppoModal={setVisibleOppoModal}
                        tagList={tagList}
                        formattedPaging={formattedPaging}
                        onNavigate={handleNavigate}
                        getNextCustomerWithJob={getNextCustomerWithJobId}
                        onChangePage={handleChangePaging}
                        loading={isLoading}
                      />

                      <ModalAddOpportunity
                        visible={visibleOppoModal}
                        setVisible={setVisibleOppoModal}
                        idCustomer={recordCustomer?.customer_id}
                        jobId={jobDetail?.job_id}
                        getData={debounceGetDataCustomerJob}
                        customerName={recordCustomer?.name}
                        setUpdateCustomerOppo={setUpdateCustomerOppo}
                      />
                    </>
                  ) : (
                    <Empty
                      description={<span>Vui lòng hoàn thành bài thi!</span>}
                    />
                  )}
                </TabPane>
                <TabPane
                  tab={<TabStyledTitle>Thông tin chung</TabStyledTitle>}
                  key="2"
                >
                  {jobDetail?.exam_result === 1 ? (
                    <Detail data={jobDetail} />
                  ) : (
                    <UntestedDetail data={jobDetail} />
                  )}
                </TabPane>

                <TabPane tab={<TabStyledTitle>Bài thi</TabStyledTitle>} key="3">
                  {jobDetail?.exam_result === 1 ? (
                    <Empty
                      description={<span>Bạn đã hoàn thành bài thi!</span>}
                    />
                  ) : (
                    <Test data={jobDetail} getDetail={getDetail} />
                  )}
                </TabPane>
              </TabsStyle>
            </div>
          </div>
        </div>
      ) : (
        <Skeleton />
      )}
    </>
  );
}

export default JobDetail;
