import React, { Fragment } from 'react';
import InteractionHistoryHeader from './InteractionHistoryHeader';
import { DescriptionCustomInteractionHistory } from '@styles/Style';
import { Descriptions } from 'antd';

function PointSaved({ interactionHistory }) {
  return (
    <Fragment>
      <InteractionHistoryHeader interactionHistory={interactionHistory} />
      <DescriptionCustomInteractionHistory column={1}>
        <Descriptions.Item label="Số điểm tích">
          {interactionHistory.data.point.point} điểm
        </Descriptions.Item>
      </DescriptionCustomInteractionHistory>
    </Fragment>
  );
}

export default PointSaved;
