import { formatPrice } from "@constants/funcHelper";

export const interactionHistoryData = {
  status: 1,
  code: 1,
  msg: 'Thành công',
  data: [
    {
      df_customer_action_id: 5,
      df_customer_action_name: 'Gọi đi',
      df_customer_action_icon:
        'https://ezsale.s3-ap-southeast-1.amazonaws.com/upload/image/ff85643f62770d8622dc4d40ea5e84ea.png',
      job_name: 'Gọi đi cho khách hàng mới',
      status_call: '',
      content_text_call: null,
      content_audio_url: null,
      created_at: '2022-03-08T14:28:15.000Z',
      updated_by: 'linh 001',
      result_cards: [],
      point_of_call: '',
      reviewer_of_call: '',
    },
    {
      df_customer_action_id: 2,
      df_customer_action_name: 'Khách hàng chốt hợp đồng',
      df_customer_action_icon:
        'https://ezsale.s3-ap-southeast-1.amazonaws.com/upload/image/b410e66683e15e108901d30c9ae1a9e6.png',
      opportunity_category_name: 'Bảo Hành',
      opportunity_note: null,
      opportunity_id: 438,
      telesale_name: 'Linh 003',
      product_name: null,
      created_at: '2022-03-08 11:44:31.000000',
    },
    {
      df_customer_action_id: 1,
      df_customer_action_name: 'Khách hàng tiềm năng',
      df_customer_action_icon:
        'https://ezsale.s3-ap-southeast-1.amazonaws.com/upload/image/575b2262c3fb8a7f50709fd86903681c.png',
      opportunity_category_name: 'Bảo Hành',
      opportunity_note: null,
      opportunity_id: 438,
      telesale_name: 'Linh 003',
      product_name: null,
      created_at: '2022-03-08 11:44:31.000000',
    },
    {
      df_customer_action_id: 3,
      df_customer_action_name: 'Khách hàng từ chối',
      df_customer_action_icon:
        'https://ezsale.s3-ap-southeast-1.amazonaws.com/upload/image/27eca387f4dcb7a24e3d31d38555700b.png',
      opportunity_category_name: 'Bảo Hành',
      opportunity_note: null,
      opportunity_id: 438,
      telesale_name: 'Linh 003',
      product_name: null,
      created_at: '2022-03-07 11:44:31.000000',
    },
    {
      df_customer_action_id: 5,
      df_customer_action_name: 'Gọi đi',
      df_customer_action_icon:
        'https://ezsale.s3-ap-southeast-1.amazonaws.com/upload/image/ff85643f62770d8622dc4d40ea5e84ea.png',
      call_id: 54778,
      job_name: 'Gọi đi cho khách hàng mới',
      status_call: 'Đồng ý trao đổi',
      content_text_call: null,
      content_audio_url: 'resources/stringee/f4a2c5c8-de00-42f0-8b55-03dc0c4fcd42.mp3',
      created_at: '2022-03-04T08:30:15.000Z',
      updated_by: 'linh 001',
      result_cards: [
        {
          questions: 'Khách hàng có nhu cầu khác không?',
          answer: null,
        },
      ],
      point_of_call: '-5',
      reviewer_of_call: null,
    },
    {
      df_customer_action_id: 2,
      df_customer_action_name: 'Khách hàng chốt hợp đồng',
      df_customer_action_icon:
        'https://ezsale.s3-ap-southeast-1.amazonaws.com/upload/image/b410e66683e15e108901d30c9ae1a9e6.png',
      opportunity_category_name: 'Bảo Hành',
      opportunity_note: null,
      opportunity_id: 438,
      telesale_name: 'Linh 003',
      product_name: null,
      created_at: '2022-03-07 11:44:31.000000',
    },
    {
      df_customer_action_id: 1,
      df_customer_action_name: 'Khách hàng tiềm năng',
      df_customer_action_icon:
        'https://ezsale.s3-ap-southeast-1.amazonaws.com/upload/image/575b2262c3fb8a7f50709fd86903681c.png',
      opportunity_category_name: 'Bảo Hành',
      opportunity_note: null,
      opportunity_id: 438,
      telesale_name: 'Linh 003',
      product_name: null,
      created_at: '2022-03-07 11:44:31.000000',
    },
    {
      df_customer_action_id: 3,
      df_customer_action_name: 'Khách hàng từ chối',
      df_customer_action_icon:
        'https://ezsale.s3-ap-southeast-1.amazonaws.com/upload/image/27eca387f4dcb7a24e3d31d38555700b.png',
      opportunity_category_name: 'Bảo Hành',
      opportunity_note: null,
      opportunity_id: 438,
      telesale_name: 'Linh 003',
      product_name: null,
      created_at: '2022-03-07 11:44:31.000000',
    },
    {
      df_customer_action_id: 11,
      df_customer_action_name: 'Cập nhật thẻ tag',
      df_customer_action_icon:
        'https://ezsale.s3-ap-southeast-1.amazonaws.com/upload/image/5ce969b39716dffab2139e770980d873.png',
      tag_id: 53,
      tag_name: 'Đúng thông tin',
      tag_color: '#e13333',
      updated_by: 'Nguyeenx Vawn A',
      created_at: '2022-03-07T08:54:12.000Z',
    },
    {
      df_customer_action_id: 2,
      df_customer_action_name: 'Khách hàng chốt hợp đồng',
      df_customer_action_icon:
        'https://ezsale.s3-ap-southeast-1.amazonaws.com/upload/image/b410e66683e15e108901d30c9ae1a9e6.png',
      opportunity_category_name: 'Bảo Hành',
      opportunity_note: null,
      opportunity_id: 438,
      telesale_name: 'Linh 003',
      product_name: null,
      revenue: null,
      sales: null,
      created_at: '2022-03-07 11:44:31.000000',
    },
    {
      df_customer_action_id: 3,
      df_customer_action_name: 'Khách hàng từ chối',
      df_customer_action_icon:
        'https://ezsale.s3-ap-southeast-1.amazonaws.com/upload/image/27eca387f4dcb7a24e3d31d38555700b.png',
      opportunity_category_name: 'Bảo Hành',
      opportunity_note: null,
      opportunity_id: 438,
      telesale_name: 'Linh 003',
      product_name: null,
      revenue: null,
      sales: null,
      created_at: '2022-03-07 11:44:31.000000',
    },
    {
      df_customer_action_id: 1,
      df_customer_action_name: 'Khách hàng tiềm năng',
      df_customer_action_icon:
        'https://ezsale.s3-ap-southeast-1.amazonaws.com/upload/image/575b2262c3fb8a7f50709fd86903681c.png',
      opportunity_category_name: 'Bảo Hành',
      opportunity_note: null,
      opportunity_id: 438,
      telesale_name: 'Linh 003',
      product_name: null,
      revenue: null,
      sales: null,
      created_at: '2022-03-07 11:44:31.000000',
    },
  ],
};

export const detailCustomer = {
  main: {
    id: 1030854,
    code: null,
    name: 'Linh call bot',
    province_id: 61,
    dob: null,
    phone: '0968726027',
    email: '',
    address: '24/03/2027',
    gender: null,
    department_id: 1,
    modified_date: '2022-03-07T14:28:15.000Z',
    status: 1,
    enterprise_id: 1058,
    is_active: 1,
    created_at: '2022-01-26T15:29:16.000Z',
    is_sales_lead: null,
    job_name: null,
    job_id: null,
    status_name: 'chưa giao việc',
    customer_tags: [
      {
        tag_id: 53,
        tag_name: 'Đúng thông tin',
        tag_color: '#e13333',
      },
      {
        tag_id: 59,
        tag_name: 'Khách hàng tiềm năng',
        tag_color: '#eee2e2',
      },
      {
        tag_id: 60,
        tag_name: 'KH thu nhập cao',
        tag_color: '#164116',
      },
      {
        tag_id: 61,
        tag_name: 'KH thu nhập trung bình',
        tag_color: '#0d1149',
      },
      {
        tag_id: 45,
        tag_name: 'kh quan trọng',
        tag_color: '#000000',
      },
    ],
    unmasked_pnumber: '0968726027',
    last_contacted: '2022-03-07T14:28:15.000Z',
    df_department: {
      name: 'Kinh doanh xe',
      id: 1,
    },
    province: {
      name: 'Hà Giang',
      id: 61,
    },
    df_customer_action: {
      name: 'Gọi đi',
      id: 5,
      icon: 'https://ezsale.s3-ap-southeast-1.amazonaws.com/upload/image/ff85643f62770d8622dc4d40ea5e84ea.png',
    },
    customer_phones: [
      {
        phone: '0852144578',
        note: 'qư',
        id: 2029,
      },
    ],
    customer_data: [
      {
        customer_data_field_code: 'customer_source',
        value: '',
        id: 266092,
        customer_data_field_source_code: null,
        name: 'Nguồn khách hàng',
      },
      {
        customer_data_field_code: 'customer_type',
        value: '',
        id: 266093,
        customer_data_field_source_code: null,
        name: null,
      },
      {
        customer_data_field_code: 'company',
        value: '',
        id: 266094,
        customer_data_field_source_code: null,
        name: null,
      },
      {
        customer_data_field_code: 'position',
        value: '',
        id: 266095,
        customer_data_field_source_code: null,
        name: 'Chức vụ',
      },
      {
        customer_data_field_code: 'Thong_tin',
        value: '',
        id: 266096,
        customer_data_field_source_code: null,
        name: 'Thông tin',
      },
      {
        customer_data_field_code: 'Thu_nhap',
        value: '',
        id: 266097,
        customer_data_field_source_code: null,
        name: null,
      },
      {
        customer_data_field_code: 'Nam_thanh_lap',
        value: '',
        id: 266098,
        customer_data_field_source_code: null,
        name: 'Ngày ký hợp đồng',
      },
      {
        customer_data_field_code: 'Trang_thai',
        value: '1',
        id: 373913,
        customer_data_field_source_code: null,
        name: null,
      },
    ],
  },
  list_field_configs: [
    {
      id: 290,
      code: 'name',
      name: 'Tên khách hàng',
    },
    {
      id: 291,
      code: 'phone',
      name: 'Số điện thoại',
    },
    {
      id: 292,
      code: 'email',
      name: 'Email',
    },
    {
      id: 293,
      code: 'dob',
      name: 'Ngày sinh',
    },
    {
      id: 294,
      code: 'province',
      name: 'Tỉnh/ thành phố',
    },
    {
      id: 295,
      code: 'gender',
      name: 'Giới tính',
    },
    {
      id: 296,
      code: 'address',
      name: 'Địa chỉ thường trú',
    },
    {
      id: 302,
      code: 'verhicle',
      name: 'Loại xe sở hữu',
    },
    {
      id: 303,
      code: 'verhicle_brand',
      name: 'Nhãn hiệu xe',
    },
    {
      id: 304,
      code: 'verhicle_type',
      name: 'Loại xe',
    },
    {
      id: 305,
      code: 'verhicle_name',
      name: 'Tên xe',
    },
    {
      id: 306,
      code: 'verhicle_license_plate',
      name: 'Biển số',
    },
    {
      id: 2241,
      code: 'Trang_thai',
      name: 'Trạng thái',
    },
    {
      id: 2242,
      code: 'Thong_tin_KH',
      name: 'Thông tin KH',
    },
    {
      id: 2253,
      code: 'Loai_san_pham_quan_tam',
      name: 'Loại sản phảm quan tâm',
    },
    {
      id: 2267,
      code: 'them_truong',
      name: 'thêm trường',
    },
    {
      id: 2292,
      code: 'Kinh_nghiem',
      name: 'Kinh nghiệm',
    },
  ],
  customer_historys: [
    {
      df_customer_action_id: 11,
      df_customer_action_name: 'Cập nhật thẻ tag',
      df_customer_action_icon:
        'https://ezsale.s3-ap-southeast-1.amazonaws.com/upload/image/5ce969b39716dffab2139e770980d873.png',
      tags: [
        {
          tag_id: 55,
          tag_name: 'Sai thông tin',
          tag_color: '#da67d6',
        },
        {
          tag_id: 56,
          tag_name: 'Mẫu hợp đồng',
          tag_color: '#ac6c6c',
        },
        {
          tag_id: 61,
          tag_name: 'KH thu nhập trung bình',
          tag_color: '#0d1149',
        },
      ],
      updated_by: 'A Đông',
      created_at: '2022-03-14 10: 27: 01',
    },
    {
      df_customer_action_id: 10,
      df_customer_action_name: 'Thêm mới',
      df_customer_action_icon:
        'https://ezsale.s3-ap-southeast-1.amazonaws.com/upload/image/80402a4464fb8d54807cdde8a25386aa.png',
      created_at: '2022-03-14 08:39:40.000000',
      updated_by: 'Linh Bee',
    },
    {
      df_customer_action_id: 9,
      df_customer_action_name: 'Lịch hẹn',
      df_customer_action_icon:
        'https://ezsale.s3-ap-southeast-1.amazonaws.com/upload/image/d7ada7bc1c332be9396c26bbeae1b6b6.png',
      time_recall: '2022-03-07 09:12:47.000000',
      note_recall: null,
      updated_by: 'REVIEWER_A Dong',
      created_at: '2022-03-07 23:53:30',
    },
    {
      df_customer_action_id: 8,
      df_customer_action_name: 'Giao cho sale chăm sóc, nhận công việc chuyển tiếp',
      df_customer_action_icon:
        'https://ezsale.s3-ap-southeast-1.amazonaws.com/upload/image/e9f1db98923456c905b18ddd27cd0d0a.png',
      job_name: 'Chăm sóc khách hàng Miền Bắc',
      sale_name: 'Linh Bee',
      avatar_sale: 'upload/image/2bc43fc0ce3213014cb14f63d244ce76.jpeg',
      job_name_parent: 'Chiến dịch chăm sóc khách hàng',
      updated_by: 'A Dong',
      created_at: '2022-03-14 08:39:40',
    },
    {
      df_customer_action_id: 6,
      df_customer_action_name: 'Gọi nhỡ',
      df_customer_action_icon:
        'https://ezsale.s3-ap-southeast-1.amazonaws.com/upload/image/c38c3da14c38eaf65c3538c8d19e0470.png',
      call_id: 55189,
      job_name: 'Chăm sóc khách hàng Miền Bắc',
      status_call: 'Sai thông tin',
      content_text_call: null,
      content_audio_url: null,
      created_at: '2022-03-14 08:39:40',
      updated_by: 'Linh Bee',
      result_cards: [
        {
          questions: 'Khách hàng có nhu cầu khác không?',
          answer: 'Nhu cầu khiếu nại',
        },
        {
          questions: 'Khách hàng đã sử dụng sản phẩm của công ty chưa?',
          answer: 'Đã sử dụng',
        },
        {
          questions: 'Khách hàng có muốn khiếu nại gì về sản phẩm và dịch vụ của công ty?',
          answer: 'Khiếu nại thái độ phục vụ',
        },
      ],
      point_of_call: '',
      reviewer_of_call: null,
    },
    {
      df_customer_action_id: 5,
      df_customer_action_name: 'Gọi đi',
      df_customer_action_icon:
        'https://ezsale.s3-ap-southeast-1.amazonaws.com/upload/image/ff85643f62770d8622dc4d40ea5e84ea.png',
      call_id: 55189,
      job_name: 'Chăm sóc khách hàng Miền Bắc',
      status_call: 'Sai thông tin',
      content_text_call: null,
      content_audio_url: null,
      created_at: '2022-03-14 08:39:40',
      updated_by: 'Linh Bee',
      result_cards: [
        {
          questions: 'Khách hàng có nhu cầu khác không?',
          answer: 'Nhu cầu khiếu nại',
        },
        {
          questions: 'Khách hàng đã sử dụng sản phẩm của công ty chưa?',
          answer: 'Đã sử dụng',
        },
        {
          questions: 'Khách hàng có muốn khiếu nại gì về sản phẩm và dịch vụ của công ty?',
          answer: 'Khiếu nại thái độ phục vụ',
        },
      ],
      point_of_call: '',
      reviewer_of_call: null,
    },
    {
      df_customer_action_id: 4,
      df_customer_action_name: 'Gọi đến',
      df_customer_action_icon:
        'https://ezsale.s3-ap-southeast-1.amazonaws.com/upload/image/cfd3be6a67e2d1a49b41c3e8f946d21a.png',
      call_id: 55151,
      job_name: 'Gọi đi cho khách hàng mới',
      status_call: null,
      content_text_call: null,
      content_audio_url: null,
      created_at: '2022-03-14 08:39:40',
      updated_by: 'Nguyễn Quốc Thái',
      result_cards: [],
      point_of_call: '',
      reviewer_of_call: null,
    },
    {
      df_customer_action_id: 3,
      df_customer_action_name: 'Khách hàng từ chối',
      df_customer_action_icon:
        'https://ezsale.s3-ap-southeast-1.amazonaws.com/upload/image/27eca387f4dcb7a24e3d31d38555700b.png',
      opportunity_category_name: 'Bảo Hành',
      opportunity_note: null,
      opportunity_id: 459,
      telesale_name: 'Phạm mai hoa',
      product_name: null,
      revenue: null,
      sales: null,
      created_at: '2022-03-09 04:11:18.000000',
    },
    {
      df_customer_action_id: 2,
      df_customer_action_name: 'Khách hàng chốt hợp đồng',
      df_customer_action_icon:
        'https://ezsale.s3-ap-southeast-1.amazonaws.com/upload/image/b410e66683e15e108901d30c9ae1a9e6.png',
      opportunity_category_name: 'Bảo Hành',
      opportunity_note: null,
      opportunity_id: 460,
      telesale_name: 'Thuận Nguyễn',
      product_name: null,
      revenue: null,
      sales: null,
      created_at: '2022-03-09 09:55:48.000000',
    },
    {
      df_customer_action_id: 1,
      df_customer_action_name: 'Khách hàng tiềm năng',
      df_customer_action_icon:
        'https://ezsale.s3-ap-southeast-1.amazonaws.com/upload/image/575b2262c3fb8a7f50709fd86903681c.png',
      opportunity_category_name: 'Mua Bán',
      opportunity_note: null,
      opportunity_id: 314,
      telesale_name: 'Hồ Văn Phi',
      product_name: 'EZSale (DT<=10tr)',
      revenue: null,
      sales: null,
      created_at: '2022-03-09 09:55:48.000000',
    },
    {
      df_customer_action_id: 2,
      df_customer_action_name: 'Khách hàng chốt hợp đồng',
      df_customer_action_icon:
        'https://ezsale.s3-ap-southeast-1.amazonaws.com/upload/image/b410e66683e15e108901d30c9ae1a9e6.png',
      opportunity_category_name: 'Bảo Hành',
      opportunity_note: null,
      opportunity_id: 460,
      telesale_name: 'Thuận Nguyễn',
      product_name: null,
      revenue: 10000000,
      sales: 5000000,
      created_at: '2022-03-09 09:55:48.000000',
    },
    {
      df_customer_action_id: 7,
      df_customer_action_name: 'Chỉnh sửa khách hàng',
      df_customer_action_icon:
        'https://ezsale.s3-ap-southeast-1.amazonaws.com/upload/image/cfa3b635c0016c9997b5cab62890c217.png',
      results_update: [
        {
          filed: 'phone',
          value: '09699126',
        },
      ],
      updated_by: 'A Dong',
      updated_by_icon: null,
      created_at: '2022-03-14 08:39:40',
    },
  ],
  items: [],
};


export const listFormat = [`customer_debt`, `customer_sales`, `customer_revenue`]

export function  showPriceFormat(field, value) {
  if(listFormat.includes(field)) {
    return formatPrice(value)
  } 
  return value
}