import { USER_ROLE } from '@constants/Constant';
import { randomColor } from '@constants/funcHelper';
import { Avatar } from 'antd';
import React from 'react';
export function AvatarUser({ userInfo }) {
  let userName = '';
  let srcImage = '';

  if (userInfo.role_id === USER_ROLE.ENTERPRISE) {
    userName = userInfo?.user_enterprise_info?.office_name?.trim();
    srcImage = userInfo?.user_enterprise_info?.profile_image;
  } else {
    userName = userInfo?.full_name?.trim();
  }

  if (userInfo.role_id === USER_ROLE.SALE) {
    srcImage = userInfo?.user_sale_info?.profile_image;
  }

  return (
    <Avatar
      style={{
        backgroundColor: userName
          ? randomColor(userName[userName?.length - 1].charAt(0)?.toUpperCase())
          : 'unset',
        margin: '3px 0 0 0',
      }}
      src={srcImage}
    >
      {userName && userName[userName?.length - 1].charAt(0)?.toUpperCase()}
    </Avatar>
  );
}
