import React, { useEffect, useState } from 'react';
import { primaryColor } from '@styles/Color';
import { ModalStyle } from '@styles/Style';
import { Button, Row, Typography } from 'antd';
import Filter from '../components/Filter';
const { Title } = Typography;
function FilterAssignmentModal(props) {
  const {
    isVisibleFilter,
    setIsVisibleFilter,
    isLoading,
    params,
    setParams,
    setVisibleDisplayConfiguration,
    setVisibleFilterConfiguration,
    listFilterFieldConfigGetAPI,
  } = props;
  const [dataFilter, setDataFilter] = useState({
    province_ids: [],
    telesale_id: [],
  });
  const handleChangeParamSearchCustomers = (field, value) => {
    setDataFilter({
      ...dataFilter,
      [field]: value || '',
    });
  };

  useEffect(() => {
    if (isVisibleFilter) {
      setDataFilter(params);
    }
  }, [isVisibleFilter]);

  const renderFooterModal = () => {
    return (
      <Row justify="end">
        <Button
          danger
          onClick={() => {
            setIsVisibleFilter(false);
          }}
        >
          Huỷ
        </Button>
        <>
          &nbsp;
          <Button
            disabled={isLoading ? true : false}
            style={{ backgroundColor: primaryColor, color: 'white' }}
            onClick={() => {
              setParams(dataFilter);
              setIsVisibleFilter(false);
            }}
          >
            Áp dụng
          </Button>
        </>
      </Row>
    );
  };

  return (
    <ModalStyle
      width={700}
      marginTop={'100px'}
      buttonCloseX={'none'}
      visible={isVisibleFilter}
      title={
        <Title level={4} style={{ color: primaryColor }}>
          Bộ lọc
        </Title>
      }
      footer={renderFooterModal()}
    >
      <Filter
        isAssignmentModal={true}
        params={dataFilter}
        setParams={setDataFilter}
        handleChangeParamSearchCustomers={handleChangeParamSearchCustomers}
        listFilterFieldConfigGetAPI={listFilterFieldConfigGetAPI}
        setVisibleFilterConfiguration={setVisibleFilterConfiguration}
        setVisibleDisplayConfiguration={setVisibleDisplayConfiguration}
      />
    </ModalStyle>
  );
}
export default FilterAssignmentModal;
