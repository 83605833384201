export const CHANNEL_NAME = 'CallBroadcast';

const channel = new BroadcastChannel(CHANNEL_NAME);

/**
 * @typedef {object} ChannelPayload
 * @property {string} name provider name
 * @property {string} status
 * @property {object} data
 */

/**
 *
 * @param {ChannelPayload} data
 */
export const sendClientBroadCast = (data) => {
  channel.postMessage({ ...data, type: 'client' });
};

/**
 *
 * @param {ChannelPayload} data
 */
export const sendCallBroadCast = (data) => {
  channel.postMessage({ ...data, type: 'call' });
};

/**
 *
 * @param {ChannelPayload} data
 */
export const sendSocketBroadCast = (data) => {
  channel.postMessage({ ...data, type: 'socket' });
};
