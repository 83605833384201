import React, { Component } from 'react';
import '@styles/review.css';
import './HomePage.css';
import DatePickerCustom from '@components/DatePickerCustom';
import { requestGetReviewerStatistic, requestGetReviewerChart, requestGetStatistic } from '@constants/Api';
import { notifyFail, notifySuccess } from '@utils/notify';
import Loading from '@src/components/Loading';

import { withRouter } from 'react-router-dom';
import { Doughnut, Line } from 'react-chartjs-2';
import { Row, Col } from 'react-bootstrap';
import { DatePicker, Space } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;
class HomePageScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: ['01/07', '02/07', '03/07', '04/07', '05/07', '06/07', '07/07'],
        datasets: [
          {
            label: 'Thống kê cuộc gọi',
            data: [100, 150, 200, 170, 270, 300, 500],
            borderColor: '#607d8b',
            fill: false,
            pointBackgroundColor: '#1b5e20',
            pointBorderColor: 'white',
            pointRadius: 5,
          },
        ],
      },
      isLoading: false,
      dataStatistic: [],
      reviewStatistic: {},
      fromDate: moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD'),
      toDate: moment(new Date()).format('YYYY-MM-DD'),
    };
  }

  componentDidMount() {
    const { fromDate, toDate } = this.state;
    this.requestGetReviewerStatistic();
    this.requestGetReviewerChart(fromDate, toDate);
    this.requestGetStatistic();
  }

  async requestGetReviewerStatistic() {
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const res = await requestGetReviewerStatistic();
      this.setState({
        ...this.state,
        dataStatistic: res?.data,
        isLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async requestGetReviewerChart(fromDate, toDate) {
    let arrDate = [];
    let arrValues = [];
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const res = await requestGetReviewerChart({ fromDate: fromDate, toDate: toDate });
      let count = res.data?.map((value) => {
        arrDate.push(value.quarter);
        arrValues.push(value.earnings);
      });
      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          labels: arrDate,
          datasets: [
            {
              label: 'Thống kê cuộc gọi',
              borderColor: '#607d8b',
              fill: false,
              pointBackgroundColor: '#1b5e20',
              pointBorderColor: 'white',
              pointRadius: 5,
              data: arrValues,
            },
          ],
        },
        isLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async requestGetStatistic() {
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const res = await requestGetStatistic();
      this.setState({
        ...this.state,
        reviewStatistic: res?.data[0]?.call_review_configs,
        isLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  handleChangeDatePicker = (e) => {
    if (e == null) {
      this.setState({
        ...this.state,
        fromDate: moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD'),
        toDate: moment(new Date()).format('YYYY-MM-DD'),
      });
      this.requestGetReviewerChart(
        moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD'),
        moment(new Date()).format('YYYY-MM-DD')
      );
    } else {
      this.setState({
        ...this.state,
        fromDate: moment(e[0]._d).format('YYYY-MM-DD'),
        toDate: moment(e[1]._d).format('YYYY-MM-DD'),
      });
      this.requestGetReviewerChart(moment(e[0]._d).format('YYYY-MM-DD'), moment(e[1]._d).format('YYYY-MM-DD'));
    }
  };

  renderTitle() {
    return <h2 className="title-tab">Trang chủ</h2>;
  }

  renderBoxOverView() {
    const { dataStatistic } = this.state;
    return (
      <div className="row">
        <div className="col-md-3 col-sm-6 col-xs-12">
          <div className="info-box">
            <span className="info-box-icon bg-blue">
              <i class="far fa-calendar-minus icon-overview"></i>
            </span>
            <div className="info-box-content">
              <span className="info-box-text">Cuộc gọi cần đánh giá</span>
              <span className="info-box-number">{dataStatistic[0]?.not_review || '0'}</span>
            </div>
            {/* /.info-box-content */}
          </div>
          {/* /.info-box */}
        </div>
        {/* /.col */}
        <div className="col-md-3 col-sm-6 col-xs-12">
          <div className="info-box">
            <span className="info-box-icon bg-green">
              <i class="far fa-calendar-check icon-overview"></i>
            </span>
            <div className="info-box-content">
              <span className="info-box-text">Đã đánh giá</span>
              <span className="info-box-number">{dataStatistic[0]?.reviewed || '0'}</span>
            </div>
            {/* /.info-box-content */}
          </div>
          {/* /.info-box */}
        </div>
        {/* /.col */}
        {/* fix for small devices only */}
        <div className="clearfix visible-sm-block" />
        <div className="col-md-3 col-sm-6 col-xs-12">
          <div className="info-box">
            <span className="info-box-icon bg-yellow">
              <i class="fas fa-user-ninja icon-overview"></i>
            </span>
            <div className="info-box-content">
              <span className="info-box-text">Công việc chưa hoàn thành</span>
              <span className="info-box-number">{dataStatistic[0]?.task_processing || '0'}</span>
            </div>
            {/* /.info-box-content */}
          </div>
          {/* /.info-box */}
        </div>
        {/* /.col */}
        <div className="col-md-3 col-sm-6 col-xs-12">
          <div className="info-box">
            <span className="info-box-icon bg-red">
              <i class="far fa-calendar-times icon-overview"></i>
            </span>
            <div className="info-box-content">
              <span className="info-box-text">Công việc quá hạn</span>
              <span className="info-box-number">{dataStatistic[0]?.task_expired || '0'}</span>
            </div>
            {/* /.info-box-content */}
          </div>
          {/* /.info-box */}
        </div>
        {/* /.col */}
      </div>
    );
  }

  renderContentOverView() {
    const { reviewStatistic } = this.state;
    let labels = [];
    let datasets = [];
    const temp1 = reviewStatistic?.length && reviewStatistic?.map((value) => labels.push(value?.name));
    const temp2 = reviewStatistic?.length && reviewStatistic?.map((value) => datasets.push(value?.count));
    return (
      <div className="row">
        <div className="col-sm-12 col-md-6 mt-2">
          <div className="box box-solid">
            <div
              className="box-body"
              style={{
                backgroundColor: 'white',
                fontSize: 18,
                textAlign: 'center',
                padding: '7px 10px',
                marginTop: 0,
              }}
            >
              <h4>KẾT QUẢ ĐÁNH GIÁ</h4>
              <Space direction="vertical" size={12}>
                <RangePicker />
              </Space>
              <Doughnut
                data={{
                  labels: labels,

                  datasets: [
                    {
                      backgroundColor: [
                        '#ff8f00',
                        '#098A00',
                        '#ff3d00',
                        '#37474f',
                        '#4e342e',
                        '#23A0CB',
                        '#CF1322',
                        '#9e9e9e',
                        '#FFB300',
                      ],

                      data: datasets,
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-6 mt-2">
          <div className="box box-solid">
            <div
              className="box-body"
              style={{
                backgroundColor: 'white',
                fontSize: 18,
                textAlign: 'center',
                padding: '7px 10px',
                marginTop: 0,
              }}
            >
              <h4>THỐNG KÊ</h4>
              <Space direction="vertical" size={12}>
                <RangePicker onChange={(e) => this.handleChangeDatePicker(e)} />
              </Space>
              <Line data={this.state.data} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderBody() {
    const { isLoading } = this.state;
    return (
      <div className="content-wrapper">
        {isLoading ? <Loading /> : null}
        {this.renderTitle()}
        {this.renderBoxOverView()}
        {this.renderContentOverView()}
      </div>
    );
  }

  render() {
    return <>{this.renderBody()}</>;
  }
}

export default withRouter(HomePageScreen);
