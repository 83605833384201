import React from 'react';
import PropTypes from 'prop-types';

const CardHeader = ({ children, ...props }) => {
  return <>{children}</>;
};
const CardFooter = ({ children, ...props }) => {
  return <>{children}</>;
};
const CardBody = ({ children, ...props }) => {
  return <>{children}</>;
};

function Card({ children, ...props }) {
  return (
    <div className="bg" {...props}>
      {children}
    </div>
  );
}
Card.propTypes = {};

Card.Body = CardBody;
Card.Header = CardHeader;
Card.Footer = CardFooter;

export default Card;
