import { handleResult, getAxios, toQuerystringFromObject } from '@constants/Api';

// export const requestUpdateCustomer = (payload) => {
//   return handleResult(getAxios.post('customer/update', payload));
// };

export const requestUpdateCustomer = (payload) => {
  return handleResult(getAxios.post('customer/update-v2', payload));
};

export const requestGetDynamicCustomerField = (payload) => {
  return handleResult(getAxios.get('customer/customerFieldImport?limit=1000', payload));
};

export const requestCreateCustomerPhone = (payload) => {
  return handleResult(getAxios.post('customer/createCustomerPhone', payload));
};

export const requestUpdateCustomerPhone = (payload) => {
  return handleResult(getAxios.post('customer/updateCustomerPhone', payload));
};

export const requestDestroyCustomerPhone = (payload) => {
  return handleResult(getAxios.post('customer/destroyCustomerPhone', payload));
};

/**
 * requestForwardToEnterprise
 * @param {{call_id}} payload
 * @returns Promise
 */
export const requestForwardToEnterprise = (payload) => {
  return handleResult(getAxios.post('call/forwardToEnterprise', payload));
};
