import { Form, Select } from 'antd';
import React, { useEffect, useMemo } from 'react';
import iconplus from '@assets/plus.png';
import ModalTemplate from './ModalTemplate';
import { SendSMScontainer } from './styleTemplate';
import { useState } from 'react';
import { ApiClient } from '@constants/Api';
import styled from 'styled-components';
import PreviewSelectData from './PreviewSelectData';

const SelectStyled = styled(Select)`
  .ant-select-selection-item {
    white-space: break-spaces;
    word-break: break-word;
  }
`;
const SaleSendSms = ({ handleSelect, selectSendSMS }) => {
  const [showModal, setShowModal] = useState(false);
  const [listTemplate, setListTemplate] = useState([]);
  const [isCallBack, setIsCallBack] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  useEffect(() => {
    async function getListTemplate() {
      try {
        let res = await ApiClient.get(`sms/messageSaleTemplate`);
        if (res.status) {
          let optionSelect = res.data.map(item => {
            return { ...item, label: item.message, value: item.id };
          });
          setListTemplate(optionSelect);
        }
      } catch (error) {
        //
      }
    }
    getListTemplate();
  }, [isCallBack]);

  useMemo(() => {
    setSelectedItem(listTemplate.find(item => item.id === selectSendSMS));
  }, [listTemplate, selectSendSMS]);

  function handleChangeSelect(value) {
    handleSelect(value);
  }
  function filterOption(input, option) {
    return option.label?.toLowerCase()?.includes(input?.toLowerCase()?.trim());
  }
  return (
    <SendSMScontainer>
      <Form
        name="basic"
        autoComplete="off"
        style={{ width: '100%', textAlign: 'left' }}
      >
        <Form.Item
          label="Chọn template"
          name="select_template"
          rules={[{ required: true, message: 'Vui lòng chọn template' }]}
          style={{ width: '100%' }}
        >
          <div className="flex">
            <SelectStyled
              placeholder="Chọn template"
              optionFilterProp="label"
              options={listTemplate}
              onChange={handleChangeSelect}
              value={selectSendSMS || undefined}
              showSearch
              filterOption={filterOption}
            />
            <img
              src={iconplus}
              alt="plus"
              width={40}
              height={40}
              onClick={() => setShowModal(true)}
            />
          </div>
        </Form.Item>
      </Form>
      <ModalTemplate
        isVisible={showModal}
        setIsCallBack={setIsCallBack}
        setShowModal={setShowModal}
        handleChangeSelect={handleChangeSelect}
      />
      <PreviewSelectData templatesMessageSelected={selectedItem} />
    </SendSMScontainer>
  );
};

export default SaleSendSms;
