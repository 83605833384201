export const DEFAULT_DATA = {
  USER_NAME: process.env.REACT_APP_DEFAULT_USER_NAME || '',
  USER_PASS: process.env.REACT_APP_DEFAULT_USER_PASS || '',
};

export const BASE_URL = process.env.REACT_APP_API_URL;

export const SENTRY = {
  RELEASE: process.env.REACT_APP_SENTRY_RELEASE,
  DNS: process.env.REACT_APP_SENTRY_DNS,
  ENVIRONMENT: process.env.REACT_APP_SENTRY_ENVIRONMENT,
};
