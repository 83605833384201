import { getNextCustomer } from '@actions/';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  getListCall,
  getListOpportunityList,
  getSaleCustomers,
  requestCustomerCollection,
  requestCustomerDetail,
  requestDeleteCustomer,
  requestFullJobFilter,
} from '@constants/Api';
import { ROUTER, TELESALE_JOB_STATUS_ALIAS } from '@constants/Constant';
import '@styles/Customer.css';
import { notifyFail, notifySuccess } from '@utils/notify';
import { Modal as ModalAntd, Spin, Tabs, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
// import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  requestAddCustomerTags,
  requestCreateOpportunity,
  requestGetJobDetailAllTags,
  requestGetOpportunities,
  requestGetProducts,
} from 'src/network/web_sale/GeneralApi';
import { requestGetJobCustomerList } from 'src/network/web_sale/JobApi';
import ModalAddOpportunity from '../Call/components/ModalAddOpportunity';
import ModalAddTag from '../Call/components/ModalAddTag';
import { useNextCustomer } from '../Call/hook/useNextCustomer';
import CallHistory from './components/CallHistory';
import ChanceList from './components/ChanceList';
import CustomerInfo from './components/CustomerInfo';
import ModalUpdateHistory from './components/ModalUpdateHistory';

const { TabPane } = Tabs;
const timeout = 500;

function CustomerDetail(props) {
  const { id } = useParams();
  const { state } = useLocation();
  const nextCustomerStore = useSelector(store => store.nextCustomer.data);
  const user = useSelector(states => states.userReducer.data);
  const [isLoading, setIsLoading] = useState(false);
  const [customerDetail, setCustomerDetail] = useState({});
  const [callHistoryList, setCallHistoryList] = useState([]);
  const [updateHistoryList, setUpdateHistoryList] = useState([]);
  const [opportunityList, setOpportunityList] = useState([]);
  const [modalUpdateHistoryVisible, setModalUpdateHistoryVisible] =
    useState(false);
  const [callHistoryParams, setCallHistoryParams] = useState({ type: '' });
  const [callFromDate, setCallFromDate] = useState('');
  const [callToDate, setCallToDate] = useState('');
  const [chanceListParams, setChanceListParams] = useState({
    type: '',
  });
  const [chanceFromDate, setChanceFromDate] = useState('');
  const [chanceToDate, setChanceToDate] = useState('');

  const [fullJobs, setFullJobs] = useState([]);

  const [allTags, setAllTags] = useState([]);
  const [modalAddTagVisible, setModalAddTagVisible] = useState(false);

  const [callHistoryPage, setCallHistoryPage] = useState(1);
  const [formattedCallHistoryPage, setFormattedCallHistoryPage] = useState({
    total: null,
    current: null,
    pageSize: null,
  });
  const [chancePage, setChancePage] = useState(1);
  const [formattedChancePage, setFormattedChancePage] = useState({
    total: null,
    current: null,
    pageSize: null,
  });
  const [modalAddOpportunityVisible, setModalAddOpportunityVisible] =
    useState(false);
  const [subOpportunities, setSubOpportunities] = useState([]);
  const [subProducts, setSubProducts] = useState([]);
  const [opportunityId, setOpportunityId] = useState('');
  const [productId, setProductId] = useState('');
  const [opportunityNote, setOpportunityNote] = useState('');
  const chanceTypes = [
    {
      shortDesc: 'nc',
      fullDesc: 'Mua bán',
    },
    {
      shortDesc: 'bh',
      fullDesc: 'Bảo hành',
    },
    {
      shortDesc: 'kn',
      fullDesc: 'Khiếu nại',
    },
  ];

  const { jobId, currentPageNumber } = nextCustomerStore;

  const { getNextCustomerWithJobId } = useNextCustomer({
    JobId: jobId,
    currentPageNumber: currentPageNumber,
  });

  useEffect(() => {
    getNextCustomerWithJobId(id);
    getCustomerDetail();
    getUpdateHistory();
    getFullJob();
    getAllTags();
    getSubOpportunityList(id);
    getSubProductList(id);
  }, []);

  useEffect(() => {
    getCallHistory();
  }, [callHistoryParams, callFromDate, callToDate, callHistoryPage]);

  useEffect(() => {
    getOpportunities();
  }, [chanceListParams, chanceFromDate, chanceToDate, chancePage]);

  const getCustomerDetail = async () => {
    try {
      setIsLoading(true);
      const res = await requestCustomerDetail(id);
      setCustomerDetail(res.data.main);
      setIsLoading(false);
    } catch (error) {
      console.log('Error getCustomerDetail: ', { error });
    }
  };

  const getCallHistory = async () => {
    try {
      const res = await getListCall({
        customer_id: id,
        job_id: callHistoryParams.type || '',
        fromDate: callFromDate || '',
        toDate: callToDate || '',
        page: callHistoryPage,
      });
      const newArr = res.data.length
        ? res.data.map((item, index) => {
            return {
              ...item,
              order: index + 1,
              key: item.id,
            };
          })
        : [];
      setCallHistoryList(newArr);
      setFormattedCallHistoryPage({
        total: res.paging.totalItemCount,
        current: res.paging.page,
        pageSize: res.paging.limit,
      });
    } catch (err) {
      console.log('Error getCallHistory: ', { err });
    }
  };

  const getUpdateHistory = async () => {
    try {
      const res = await requestCustomerCollection({ customer_id: id });
      const newArr = res.data.length
        ? res.data.map((item, index) => {
            const additionalDataItems = item?.customer_data_collections || [];
            const addData = {};
            additionalDataItems.forEach(value => {
              addData[value.customer_data_field_code] = value;
            });
            return {
              ...item,
              order: index + 1,
              key: item.id,
              customer_source: addData['customer_source']?.value,
              customer_type: addData['customer_type']?.value,
            };
          })
        : [];
      setUpdateHistoryList(newArr);
    } catch (error) {
      console.log('Error getUpdateHistory: ', { error });
    }
  };

  const getOpportunities = async () => {
    try {
      const res = await getListOpportunityList({
        id: id,
        type: chanceListParams.type || '',
        fromDate: chanceFromDate || '',
        toDate: chanceToDate || '',
        page: chancePage,
      });
      const newArr = res.data.length
        ? res.data.map((item, index) => {
            return {
              ...item,
              order: index + 1,
              key: item.id,
            };
          })
        : [];
      setFormattedChancePage({
        total: res.paging.totalItemCount,
        current: res.paging.page,
        pageSize: res.paging.limit,
      });
      setOpportunityList(newArr);
    } catch (error) {
      console.log('Error getOpportunities: ', { error });
    }
  };

  const getAllTags = async () => {
    try {
      const res = await requestGetJobDetailAllTags();
      const newArr = res.data.length
        ? res.data.map(item => {
            return {
              ...item,
              isCheck: false,
            };
          })
        : [];
      setAllTags(newArr);
    } catch (error) {
      console.log('Error getAllTags: ', { error });
    }
  };

  const getSubOpportunityList = async customerId => {
    try {
      const res = await requestGetOpportunities({
        // call_id: callId,
        customer_id: customerId,
      });
      setSubOpportunities(res.data);
    } catch (error) {
      console.log('Error getSubOpportunityList: ', { error });
    }
  };

  const getSubProductList = async customerId => {
    try {
      const res = await requestGetProducts({
        // call_id: callId,
        customer_id: customerId,
      });
      setSubProducts(res.data);
    } catch (error) {
      console.log('Error getSubProductList: ', { error });
    }
  };

  const getFullJob = async () => {
    try {
      const res = await requestFullJobFilter();
      setFullJobs(res.data);
    } catch (error) {
      console.log('Error getFullJob: ', { error });
    }
  };

  const handleChangeChanceParams = (fieldName, value) => {
    setChanceListParams({
      ...chanceListParams,
      [fieldName]: value,
    });
  };

  const handleChangeFromDateChance = value => {
    setChanceFromDate(moment(value).format('YYYY-MM-DD'));
  };

  const handleChangeToDateChance = value => {
    setChanceToDate(moment(value).format('YYYY-MM-DD'));
  };

  const handleChangeCallParams = (fieldName, value) => {
    setCallHistoryParams({
      ...callHistoryParams,
      [fieldName]: value,
    });
  };

  const handleChangeFromDateCall = (field, value) => {
    setCallFromDate(value);
  };

  const handleChangeToDateCall = (field, value) => {
    setCallToDate(value);
  };

  const handleChangeSelectTags = (id, checked) => {
    const tagsArr = [...allTags];
    const newArr = tagsArr.map(item => {
      if (item.id === id) {
        return {
          ...item,
          isCheck: !checked,
        };
      } else {
        return item;
      }
    });
    setAllTags(newArr);
  };

  const handleCloseModalAddTag = () => {
    const tagsArr = [...allTags];
    const newArr = tagsArr.map(item => {
      return {
        ...item,
        isCheck: false,
      };
    });
    setAllTags(newArr);
    setModalAddTagVisible(false);
  };

  const handleSaveTags = async () => {
    try {
      const payload = {
        customer_id: id,
        tag_ids: allTags.length
          ? allTags.filter(item => item.isCheck).map(item => item.id)
          : [],
      };
      if (user?.config_tag == 0 && payload?.tag_ids.length > 1) {
        notifyFail('Chỉ được chọn 1 phân loại!');
      } else {
        const res = await requestAddCustomerTags(payload);
        notifySuccess('Sửa tag thành công!');
        getCustomerDetail();
        setModalAddTagVisible(false);
      }
    } catch (error) {
      console.log('Error handleSaveTags: ', { error });
    }
  };

  const handleOpenModalAddTag = () => {
    const tagsArr = [...allTags];
    const selectedTags =
      customerDetail.customer_tags?.map(item => item.tag_id) || [];
    const newArr = tagsArr.map(item => {
      if (selectedTags.includes(item.id)) {
        return {
          ...item,
          isCheck: true,
        };
      } else {
        return item;
      }
    });
    setAllTags(newArr);
    setModalAddTagVisible(true);
  };

  // ModalAddOpportunity funcs
  const handleCloseModalAddOpportunity = () => {
    setModalAddOpportunityVisible(false);
  };

  const handleSaveOpportunity = async () => {
    try {
      const purchaseOpportunity = subOpportunities.length
        ? subOpportunities.find(item => item.name.toUpperCase() === 'MUA BÁN')
        : {};
      if (!opportunityId) {
        notifyFail('Vui lòng chọn cơ hội!');
        return;
      }
      if (parseInt(opportunityId) === purchaseOpportunity.id && !productId) {
        notifyFail('Vui lòng chọn sản phẩm!');
        return;
      }

      if (opportunityNote.trim() === '') {
        notifyFail('Vui lòng nhập ghi chú!');
        return;
      }

      const payload = {
        opportunity_category_id: opportunityId ? parseInt(opportunityId) : '',
        // call_id: callingStatusSocket.call_id,
        customer_id: parseInt(id),
        product_id: productId ? parseInt(productId) : '',
        note: opportunityNote,
      };

      const res = await requestCreateOpportunity(payload);
      notifySuccess('Thêm cơ hội thành công!');
      setModalAddOpportunityVisible(false);
    } catch (error) {
      console.log(error);
      notifyFail('Thêm cơ hội thất bại!');
    }
  };

  return (
    <Spin spinning={isLoading}>
      <div className="content-wrapper">
        <div className="content-header">
          <ModalUpdateHistory
            data={updateHistoryList}
            visible={modalUpdateHistoryVisible}
            setVisible={setModalUpdateHistoryVisible}
          />
          <ModalAddTag
            visible={modalAddTagVisible}
            tags={allTags}
            onChange={(id, checked) => handleChangeSelectTags(id, checked)}
            onClose={() => handleCloseModalAddTag()}
            onSave={() => handleSaveTags()}
          />
          <CustomerInfo
            recallImmediately={state?.recallImmediately}
            data={customerDetail}
            onClickHistory={() => setModalUpdateHistoryVisible(true)}
            onOpenModalAddTag={() => handleOpenModalAddTag()}
            jobId={state?.idJob}
            fromCallSchedule={state?.fromCallSchedule}
            job_telesale_customer_id={state?.job_telesale_customer_id}
            onOpenModalAddOpportunity={() =>
              setModalAddOpportunityVisible(true)
            }
          />
          <ModalAddOpportunity
            jobId={state?.idJob}
            visible={modalAddOpportunityVisible}
            setVisible={setModalAddOpportunityVisible}
            onClose={() => handleCloseModalAddOpportunity()}
            onSave={() => handleSaveOpportunity()}
            opportunityList={subOpportunities}
            productList={subProducts}
            opportunityId={opportunityId}
            productId={productId}
            onChangeOpportunity={value => setOpportunityId(value)}
            onChangeProduct={value => setProductId(value)}
            note={opportunityNote}
            onChangeNote={value => setOpportunityNote(value)}
            customerName={customerDetail?.name}
          />
          <br />
          <Row>
            <Col md={12}>
              <div className="bg p-4">
                <Tabs defaultActiveKey="1" onChange={() => {}}>
                  <TabPane tab="Lịch sử chăm sóc" key="1">
                    <CallHistory
                      data={callHistoryList}
                      jobs={fullJobs}
                      handleChange={handleChangeCallParams}
                      handleChangeFromDate={handleChangeFromDateCall}
                      handleChangeToDate={handleChangeToDateCall}
                      formattedPage={formattedCallHistoryPage}
                      onChangePage={page => setCallHistoryPage(page)}
                    />
                  </TabPane>
                  <TabPane tab="Danh sách cơ hội" key="2">
                    <ChanceList
                      data={opportunityList}
                      types={chanceTypes}
                      handleChangeParams={handleChangeChanceParams}
                      handleChangeFromDate={handleChangeFromDateChance}
                      handleChangeToDate={handleChangeToDateChance}
                      formattedPage={formattedChancePage}
                      onChangePage={page => setChancePage(page)}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Spin>
  );
}

export default CustomerDetail;
