import React, { useCallback, useEffect, useState } from 'react';
import {
  Avatar,
  Breadcrumb,
  Row,
  Table,
  Tooltip,
  Tag,
  Typography,
  Empty,
} from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import {
  formatPrice,
  getSearchParamsInUrl,
  randomColor,
  setSearchParamsInUrlVer2,
} from '@constants/funcHelper';
import { useCancellableApi } from 'src/hooks/useCancellableApi';
import TagCustom from '../Customer/components/TagCustom';
import { requestListOpportunity } from '@constants/Api';
import Wrapper from '@components/ui/wrapper/Wrapper';
import { BreadcrumbAntStyle } from '@styles/Style';
import { ROUTER } from '@constants/Constant';
import Filter from './components/Filter';
import { useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';

function Screen(props) {
  const history = useHistory();
  const user = useSelector(states => states.userReducer.data);
  const { Paragraph } = Typography;
  const [isLoading, setIssLoading] = useState(false);
  const [dataSource, setDataSource] = useState();
  const [params, setParams] = useState({
    tag_id: '',
    search: '',
    product_id: '',
    telesale_id: '',
    department_id: '',
    e_voucher_config_id: '',
    status: '',
    type_id: '',
    opportunity_category_id: '',
    job_id: '',
    toDateContractExp: '',
    fromDate: '',
    fromDateContractExp: '',
    toDate: '',
    toDateCreate: '',
    fromDateCreate: '',
    customer_source: [],
    limit: 12,
    page: 1,
  });

  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 0,
  });

  const handleChangeOpportunitySearchParam = (field, value) => {
    setSearchParamsInUrlVer2({ [field]: value, page: 1 });
    mixpanel.track(`filter opportunity ${field}: ${value}`);
    setParams({
      ...params,
      [field]: value || '',
      page: 1,
    });
  };

  useEffect(() => {
    const searchParamsInUrl = getSearchParamsInUrl();
    setParams({ ...params, ...searchParamsInUrl });
  }, []);

  //kết thúc cấu hình api theo params url

  const apiGetListOpportunity = useCancellableApi(requestListOpportunity);
  const getListOppoturnity = async (payload = params) => {
    setIssLoading(true);
    try {
      const res = await apiGetListOpportunity(payload);
      setDataSource(res);
      setPaging({
        ...paging,
        current: res.paging?.page,
        total: res.paging?.totalItemCount,
        pageSize: res.paging?.limit,
      });
      setIssLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const debounceGetDataOppo = useCallback(
    _.debounce(getListOppoturnity, 100),
    []
  );

  useEffect(() => {
    debounceGetDataOppo(params);
  }, [debounceGetDataOppo, params]);

  const column = [
    {
      title: <b>Khách hàng</b>,
      dataIndex: 'customer_name',
      key: 'customer_name',
      render: (value, record) => {
        const name = value ? value.trim().split(' ') : '';
        return (
          <div style={{ fontSize: '16px', textAlign: 'start' }}>
            {value ? (
              <>
                <Paragraph ellipsis={{ rows: 2 }}>
                  <Avatar
                    style={{
                      backgroundColor: randomColor(
                        name[name.length - 1].charAt(0)?.toUpperCase()
                      ),
                      marginRight: '5px',
                    }}
                    size="small"
                  >
                    {name[name.length - 1].charAt(0)?.toUpperCase()}
                  </Avatar>
                  <Tooltip title={value}>{value ? value : '---'}</Tooltip>
                </Paragraph>
              </>
            ) : (
              '---'
            )}
          </div>
        );
      },
    },
    {
      title: <b>Phân loại</b>,
      dataIndex: 'customer',
      key: 'customer',
      render: (value, record) => {
        const ListNameTag = record.customer?.customer_tag_news.map(item => (
          <>
            {item?.tag_name}
            <br />
          </>
        ));
        return record.customer?.customer_tag_news.length > 1 ? (
          <Tooltip
            title={ListNameTag.slice(
              1,
              record.customer?.customer_tag_news.length
            )}
          >
            {value?.customer_tag_news[0]?.tag_name ? (
              <TagCustom
                tagName={
                  value?.customer_tag_news[0]?.tag_name
                    ? value?.customer_tag_news[0]?.tag_name
                    : '---'
                }
                tagColor={value?.customer_tag_news[0]?.tag_color}
              />
            ) : (
              '---'
            )}
            {record.customer?.customer_tag_news.length > 1 ? (
              <TagCustom
                tagName={
                  '+' + `${record.customer?.customer_tag_news.length - 1}`
                }
              />
            ) : (
              ''
            )}
          </Tooltip>
        ) : (
          <>
            {value?.customer_tag_news[0]?.tag_name ? (
              <TagCustom
                tagName={
                  value?.customer_tag_news[0]?.tag_name
                    ? value?.customer_tag_news[0]?.tag_name
                    : '---'
                }
                tagColor={value?.customer_tag_news[0]?.tag_color}
              />
            ) : (
              '---'
            )}
            {record.customer?.customer_tag_news.length > 1 ? (
              <TagCustom
                tagName={
                  '+' + `${record.customer?.customer_tag_news.length - 1}`
                }
              />
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      title: <b>Loại cơ hội</b>,
      dataIndex: 'opportunity_category_name',
      key: 'opportunity_category_name',
      render: value => <>{value ? value : '---'}</>,
    },
    {
      width: '17%',
      title: <b>Sản phẩm</b>,
      dataIndex: 'opportunity_products',
      key: 'opportunity_products',
      render: (value, record) => {
        const ListNameProduct = record.opportunity_products.map(item => (
          <>
            {item?.product?.name}
            <br />
          </>
        ));
        return record.opportunity_products.length > 1 ? (
          <Tooltip
            title={ListNameProduct.slice(1, record.opportunity_products.length)}
          >
            <Paragraph ellipsis={{ rows: 2 }}>
              <Tag>
                {value[0]?.product?.name ? value[0]?.product?.name : '---'}
              </Tag>
              {record.opportunity_products.length > 1 ? (
                <Tag>+{record.opportunity_products.length - 1}</Tag>
              ) : (
                ''
              )}
            </Paragraph>
          </Tooltip>
        ) : (
          <Paragraph ellipsis={{ rows: 2 }}>
            <Tag>
              {value[0]?.product?.name ? value[0]?.product?.name : '---'}
            </Tag>
            {record.opportunity_products.length > 1 ? (
              <Tag>+{record.opportunity_products.length - 1}</Tag>
            ) : (
              ''
            )}
          </Paragraph>
        );
      },
    },
    {
      title: <b>Người tạo</b>,
      dataIndex: 'telesale_name',
      key: 'telesale_name',
      render: (value, record) => {
        const name = value ? value.trim().split(' ') : '';
        return (
          <>
            {value ? (
              record?.profile_image_sale ? (
                <Tooltip title={value}>
                  <Avatar
                    style={{
                      marginRight: '5px',
                    }}
                    src={`https://ezsale.s3-ap-southeast-1.amazonaws.com/${record?.profile_image_sale}`}
                    size="small"
                  >
                    {name[name.length - 1].charAt(0)?.toUpperCase()}
                  </Avatar>
                </Tooltip>
              ) : (
                <Tooltip title={value}>
                  <Avatar
                    style={{
                      backgroundColor: randomColor(
                        name[name.length - 1].charAt(0)?.toUpperCase()
                      ),
                      marginRight: '5px',
                    }}
                    size="small"
                  >
                    {name[name.length - 1].charAt(0)?.toUpperCase()}
                  </Avatar>
                </Tooltip>
              )
            ) : (
              '---'
            )}
          </>
        );
      },
    },
    {
      title: <b>Trạng thái</b>,
      dataIndex: 'status',
      key: 'status',
      render: value => {
        switch (value) {
          case 1:
            return 'Khách hàng tiềm năng';
          case 3:
            return 'Chốt hợp đồng';
          case 4:
            return 'Khách hàng từ chối';
          default:
            return '---';
        }
      },
    },
    {
      title: <b>Chương trình giảm giá</b>,
      dataIndex: 'promotion',
      key: 'promotion',
      render: (value, record) => {
        return (
          <>
            {record?.e_voucher_config_name ? record.e_voucher_config_name : ''}
          </>
        );
      },
    },
    {
      title: <b>Doanh số</b>,
      dataIndex: 'sales',
      key: 'sales',
      render: value => <>{value !== 0 ? formatPrice(value) : '0'}</>,
    },
    {
      title: <b>Doanh thu</b>,
      dataIndex: 'revenue',
      key: 'revenue',
      render: value => <>{value !== 0 ? formatPrice(value) : '0'}</>,
    },
    // trạng thái chốt hợp đồng mới có công nợ
    {
      title: <b>Công nợ</b>,
      dataIndex: 'debt',
      key: 'debt',
      // render: (value, row) =>
      //   row?.status === 3
      //     ? formatPrice((row?.sales || 0) - (row?.revenue || 0))
      //     : '---',
      render: (value, row) =>
        formatPrice((row?.sales || 0) - (row?.revenue || 0)),
    },
    {
      title: <b>Hoa hồng</b>,
      dataIndex: 'epoint',
      key: 'epoint',
      render: (value, record) => {
        const epoint =
          record?.epoint || Number(record?.epoint) === 0
            ? record?.epoint
            : record?.total_epoint;
        return epoint === 0 ? '0' : epoint === -1 ? '---' : formatPrice(epoint);
      },
    },
    {
      width: '10%',
      title: <b>Ghi chú</b>,
      dataIndex: 'note',
      key: 'note',
      render: value => {
        const objStr = value?.split('$');
        return (
          <>
            <Paragraph ellipsis={{ rows: 2 }}>
              <Tooltip
                title={objStr?.map((item, index) => (
                  <div key={index} style={index == 0 ? {} : { marginLeft: 15 }}>
                    {item}
                  </div>
                ))}
              >
                {value ? objStr[0].slice(0, 25) : '---'}
              </Tooltip>
            </Paragraph>
          </>
        );
      },
    },
    {
      title: <b>Ngày cập nhật</b>,
      dataIndex: 'modified_date',
      key: 'modified_date',
      render: value => <>{moment(value).format('DD/MM/YYYY')}</>,
    },
    {
      title: <b>Ngày hết hạn</b>,
      dataIndex: 'contract_exp_date',
      key: 'contract_exp_date',
      render: value => (
        <>{value ? moment(value).format('DD/MM/YYYY') : '---'}</>
      ),
    },
  ];

  return (
    <div className="content-wrapper">
      <BreadcrumbAntStyle>
        <Breadcrumb.Item>Cơ hội</Breadcrumb.Item>
      </BreadcrumbAntStyle>
      <Wrapper>
        <Filter
          params={params}
          setParams={setParams}
          handleChangeOpportunitySearchParam={
            handleChangeOpportunitySearchParam
          }
          user={user?.user_enterprise_info?.office_name}
        />
      </Wrapper>

      <Wrapper>
        <Row justify="space-between" style={{ marginBottom: 20, fontSize: 18 }}>
          <Row>
            <span className="mr-1">Số cơ hội:</span>
            <b>{paging.total}</b>
          </Row>
          <Row>
            <span className="mr-1">Doanh số (tiền trên hợp đồng) :</span>
            <b>{formatPrice(dataSource?.sumSales)}</b>(VND)
            <span className="mr-1" style={{ marginLeft: 10 }}>
              Doanh thu (tiền khách thanh toán) :
            </span>
            <b>{formatPrice(dataSource?.sumRevenue)}</b>(VND)
            <span className="mr-1" style={{ marginLeft: 10 }}>
              Công nợ (tiền khách phải trả) :
            </span>
            <b>{formatPrice(dataSource?.sumSales - dataSource?.sumRevenue)}</b>
            (VND)
          </Row>
        </Row>
        <Table
          size="middle"
          loading={isLoading}
          bordered
          columns={column}
          dataSource={dataSource?.data}
          scroll={{
            x: 800,
            scrollToFirstRowOnChange: true,
            // y: 'calc(100vh - 430px)',
          }}
          onRow={(record, rowIndex) => ({
            onClick: () => {
              mixpanel.track(`Go to detail oportunity ${record.customer_name}`);
              history.push({
                pathname: `${ROUTER.OCCASION_DETAIL + '/' + record.id}`,
                state: {},
              });
            },
          })}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu.</span>}
              />
            ),
            triggerDesc: '',
            triggerAsc: '',
            cancelSort: '',
          }}
          pagination={{
            ...paging,
            showSizeChanger: false,
            onChange: async (page, pageSize) => {
              setParams({ ...params, page });
              setSearchParamsInUrlVer2({ page });
            },
          }}
        />
      </Wrapper>
    </div>
  );
}

export default Screen;
