import styled from 'styled-components';
import { Col, Form, Input, Radio } from 'antd';

import { primaryColor } from '@styles/Color';

export const HeadingStyled = styled.h1`
  color: ${primaryColor};
  font-weight: 600;
  font-size: 24px;
  padding: 10px;
`;

export const WrapperContentActionStyled = styled.div`
  i {
    font-size: 20px;

    + i {
      margin-left: 4px;
    }
  }

  .icon-edit {
    color: green;
  }

  .icon-trash {
    color: red;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;

  .actions {
    display: flex;
    align-items: center;
  }
`;

export const FormStyled = styled(Form)`
  .ant-row.ant-form-item {
    margin-bottom: 8px;
  }
`;

export const ColStyled = styled(Col)`
  display: flex;
  justify-content: left;
`;

export const LabelStyled = styled.div`
  font-weight: 500;
  color: ${primaryColor};
  margin-bottom: 4px;
`;

export const InputStyled = styled(Input)`
  &&& {
    border-color: #d9d9d9;
  }
`;

export const RadioGroupStyled = styled(Radio.Group)`
  &&& {
    width: 100%;

    .ant-space.ant-space-vertical {
      width: 100%;
    }

    .radio-option {
      width: 100%;

      span {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      span:last-child {
        flex: 1;

        span:first-child {
          width: 220px;
        }
      }
    }
  }
`;
