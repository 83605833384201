import {
   GET_LIST_TUTORIAL,
   GET_LIST_TUTORIAL_SUCCESS,
   GET_LIST_TUTORIAL_FAIL
} from "@actions/type";

const initialState = {
    data: {},
    isLoading: false,
    paging: {},
    error: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_LIST_TUTORIAL:
            return {
                ...state,
                isLoading: true,
                payload: action.payload
            }
            break;
        case GET_LIST_TUTORIAL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload.response.data,
                paging: action.payload.response.paging,
                error: null
            }
            break;
        case GET_LIST_TUTORIAL_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
            break;
        default:
            return state;
    }
}