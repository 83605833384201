import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Empty, Popconfirm, Row, Spin, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import {
  formatPrice,
  formatTime,
  getSearchParamsInUrl,
  setSearchParamsInUrlVer2,
} from '@constants/funcHelper';
import { useCancellableApi } from 'src/hooks/useCancellableApi';
import { VietnameseString } from '@constants/VietnameseString';
import { MESS_STATUS, MESS_TYPE } from '@constants/Constant';
import { notifySuccess } from '@utils/notify';
import Filter from './Filter';
import {
  requestExportMessageHistory,
  requestGetMessageHistory,
  requestResendMessage,
  requestStaticMessageHistory,
} from '../services/api';

import { OneLineTextStyled, TableAntStyle } from '@styles/Style';

const ReSendIcon = () => (
  <svg
    width="20px"
    height="20px"
    fill="white"
    viewBox="0 0 1024 1024"
    style={{ margin: '0 2px 5px 0' }}
  >
    <path d="M758.2 839.1C851.8 765.9 912 651.9 912 523.9 912 303 733.5 124.3 512.6 124 291.4 123.7 112 302.8 112 523.9c0 125.2 57.5 236.9 147.6 310.2 3.5 2.8 8.6 2.2 11.4-1.3l39.4-50.5c2.7-3.4 2.1-8.3-1.2-11.1-8.1-6.6-15.9-13.7-23.4-21.2a318.64 318.64 0 01-68.6-101.7C200.4 609 192 567.1 192 523.9s8.4-85.1 25.1-124.5c16.1-38.1 39.2-72.3 68.6-101.7 29.4-29.4 63.6-52.5 101.7-68.6C426.9 212.4 468.8 204 512 204s85.1 8.4 124.5 25.1c38.1 16.1 72.3 39.2 101.7 68.6 29.4 29.4 52.5 63.6 68.6 101.7 16.7 39.4 25.1 81.3 25.1 124.5s-8.4 85.1-25.1 124.5a318.64 318.64 0 01-68.6 101.7c-9.3 9.3-19.1 18-29.3 26L668.2 724a8 8 0 00-14.1 3l-39.6 162.2c-1.2 5 2.6 9.9 7.7 9.9l167 .8c6.7 0 10.5-7.7 6.3-12.9l-37.3-47.9z" />
  </svg>
);

const ExcelIcon = () => (
  <svg
    width="20px"
    height="20px"
    fill="white"
    viewBox="0 0 1024 1024"
    style={{ margin: '0 2px 5px 0' }}
  >
    <path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z" />
  </svg>
);

const LIMIT_TABLE = 20;
export const TIME_FORMAT_TYPE = 'YYYY-MM-DD';

function MessHistory() {
  const user = useSelector(states => states.userReducer.data);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState('');
  const [dataStatic, setDataStatic] = useState('');
  const [rowSelected, setRowSelected] = useState([]);
  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: LIMIT_TABLE,
  });
  const [params, setParams] = useState({
    enterprise_id: user.enterprise_id,
    search: '',
    job_id: '',
    from_date: moment().format(TIME_FORMAT_TYPE),
    to_date: moment().format(TIME_FORMAT_TYPE),
    status: '',
    sms_type_id: '',
    page: paging.current,
    limit: paging.pageSize,
  });

  const handleChangeSearchParam = (field, value) => {
    setSearchParamsInUrlVer2({ [field]: value, page: 1 });

    setParams({
      ...params,
      [field]: value || '',
      page: 1,
    });
  };

  useEffect(() => {
    const searchParamsInUrl = getSearchParamsInUrl();
    setParams({ ...params, ...searchParamsInUrl });
  }, []);

  const apiGetMessageHistory = useCancellableApi(requestGetMessageHistory);
  const apiStaticMessageHistory = useCancellableApi(
    requestStaticMessageHistory
  );
  const getMessHistory = async (payload = params) => {
    setIsLoading(true);
    try {
      const resData = await apiGetMessageHistory(payload);
      const resStatic = await apiStaticMessageHistory(payload);
      setDataSource(resData.data.map(i => ({ ...i, key: i.id })));
      setDataStatic(resStatic.data);
      setIsLoading(false);
      setPaging({
        ...paging,
        current: resData.paging?.page,
        total: resData.paging?.count,
        pageSize: resData.paging?.limit,
      });
    } catch (error) {
      console.log('Error getMessHistory: ', { error });
    }
  };
  const debounceGetData = useCallback(_.debounce(getMessHistory, 100), []);

  useEffect(() => {
    debounceGetData(params);
  }, [params]);

  const downloadExcelFile = async () => {
    try {
      await requestExportMessageHistory(params);
      notifySuccess('Đang trong quá trình xuất excel!');
    } catch (error) {
      console.log('Error downloadExcelFile: ', { error });
    }
  };

  const handleResendMessage = async () => {
    try {
      await requestResendMessage(rowSelected);
      setRowSelected([]);
      getMessHistory();
    } catch (error) {
      console.log('Error handleResendMessage: ', { error });
    }
  };

  const messStatus = status => {
    switch (status) {
      case MESS_STATUS.SUCCESS:
        return 'Đã gửi';
      case MESS_STATUS.PENDING:
        return 'Chờ gửi';
      case MESS_STATUS.ERROR:
        return 'Không gửi được';
      default:
        return '---';
    }
  };

  const messStatusColor = status => {
    switch (status) {
      case MESS_TYPE.BRAND:
        return { color: 'steelblue' };
      case MESS_TYPE.PREFIX:
        return { color: 'orange' };
      case MESS_TYPE.MULTIEMEDIA:
        return { color: 'tomato' };
      default:
        return '---';
    }
  };

  const messType = type => {
    switch (type) {
      case MESS_TYPE.BRAND:
        return 'Tin nhắn thương hiệu';
      case MESS_TYPE.PREFIX:
        return 'Tin nhắn đầu số';
      case MESS_TYPE.MULTIEMEDIA:
        return 'Tin nhắn đa phương tiện';
      default:
        return '---';
    }
  };

  const renderContent = (value, length, title) => {
    if (value.length > length) {
      return (
        <Tooltip
          overlayInnerStyle={{ width: 350 }}
          title={value}
          placement="topLeft"
        >
          {value.slice(0, length)}...
        </Tooltip>
      );
    } else {
      return value;
    }
  };

  const columns = [
    {
      title: VietnameseString.ordinal_number,
      dataIndex: '',
      render: (text, record, index) => {
        return <>{(params.page - 1) * LIMIT_TABLE + index + 1}</>;
      },
    },
    {
      title: VietnameseString.customer_name,
      dataIndex: 'customer_name',
      render: value => <>{value || '---'}</>,
    },
    {
      title: 'Loại tin nhắn',
      dataIndex: 'sms_provider',
      render: value => <>{messType(value.df_sms_type.id)}</>,
    },
    {
      title: 'Số tin nhắn',
      dataIndex: 'count_message',
      render: value => <>{value !== 0 ? formatPrice(value) : '0'}</>,
    },
    {
      title: 'Chi phí',
      dataIndex: 'price',
      render: value => <>-{value !== 0 ? formatPrice(value) : '0'} VNĐ</>,
    },
    {
      title: 'Số tiền sau khi gửi',
      dataIndex: 'remain_money',
      render: value => <>{value !== 0 ? formatPrice(value) : '0'} VNĐ</>,
    },
    {
      title: VietnameseString.status,
      dataIndex: 'status',
      render: value => (
        <div style={messStatusColor(value)}>{messStatus(value)}</div>
      ),
    },
    {
      title: VietnameseString.sending_time,
      dataIndex: 'modified_date',
      render: value => <>{formatTime(value)}</>,
    },
    {
      width: 250,
      align: 'left',
      title: VietnameseString.content,
      dataIndex: 'message',
      render: (value, record) => (
        <Tooltip placement="bottomRight" title={value}>
          <OneLineTextStyled>{value}</OneLineTextStyled>
        </Tooltip>
      ),
    },
    {
      title: VietnameseString.note,
      dataIndex: 'response',
      render: (value, record) => {
        if (record.status === MESS_STATUS.ERROR) {
          return JSON.parse(`${value}`).message;
        } else {
          return '---';
        }
      },
    },
  ];

  const InfoItem = (title, value, unit, color) => {
    return (
      <Col span={12}>
        <Spin spinning={isLoading}>
          <Row>
            <div style={{ width: '50%' }}>{title}</div>
            <div style={{ color: color }}>{value ? formatPrice(value) : 0}</div>
            &nbsp;{unit}
          </Row>
        </Spin>
      </Col>
    );
  };

  const InfoBlock = () => {
    return (
      <Row gutter={[16, 16]} style={{ fontWeight: 600 }}>
        {InfoItem(
          'Số tin nhắn đã gửi:',
          dataStatic?.[0]?.message_success_count,
          '',
          'steelblue'
        )}
        {InfoItem(
          'Số tin nhắn chờ gửi:',
          dataStatic?.[0]?.message_pending_count,
          '',
          'orange'
        )}
        {InfoItem(
          'Số tin nhắn không được gửi:',
          dataStatic?.[0]?.message_error_count,
          '',
          'tomato'
        )}
        {InfoItem(
          'Số tiền đã gửi:',
          dataStatic?.[0]?.total_price,
          'VNĐ',
          'tomato'
        )}
      </Row>
    );
  };

  const TableHeader = () => {
    return (
      <Row justify="space-between">
        <div>
          Kết quả: <b>{paging.total}</b>
        </div>
        <Row>
          <Popconfirm
            placement="bottomRight"
            title={'Gửi lại toàn bộ tin nhắn đã chọn?'}
            onConfirm={handleResendMessage}
            okText={VietnameseString.agree}
            cancelText={VietnameseString.cancel}
          >
            <Button type="primary">
              <ReSendIcon />
              Gửi lại
            </Button>
          </Popconfirm>
          &nbsp;&nbsp;
          <Button
            type="primary"
            onClick={() => {
              downloadExcelFile();
            }}
          >
            <ExcelIcon />
            Xuất excel
          </Button>
        </Row>
      </Row>
    );
  };

  const handleSelectTable = (selectedRowKeys, selectedRows) => {
    setRowSelected([...selectedRowKeys]);
  };

  return (
    <>
      <Filter
        params={params}
        setParams={setParams}
        handleChangeSearchParam={handleChangeSearchParam}
      />
      <br />
      {InfoBlock()}
      <br />
      {TableHeader()}
      <br />
      <TableAntStyle
        columns={columns}
        dataSource={dataSource}
        loading={isLoading}
        scroll={{
          x: 'max-content',
        }}
        rowSelection={{
          type: 'select',
          selectedRowKeys: rowSelected,
          preserveSelectedRowKeys: true,
          onChange: (selectedRowKeys, selectedRows) => {
            handleSelectTable(selectedRowKeys, selectedRows);
          },
          getCheckboxProps: record => ({
            disabled: record.status !== MESS_STATUS.ERROR,
          }),
        }}
        bordered
        pagination={{
          ...paging,
          showSizeChanger: false,
          onChange: async (page, pageSize) => {
            setParams({ ...params, page });
            setSearchParamsInUrlVer2({ page });
          },
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>Không có dữ liệu.</span>}
            />
          ),
          triggerDesc: '',
          triggerAsc: '',
          cancelSort: '',
        }}
      />
    </>
  );
}

export default MessHistory;
