import { GET_REGISTER, GET_REGISTER_SUCCESS, GET_REGISTER_FAIL } from '@actions/type'
import reactotron from "reactotron-react-js"

const initialState = {
    data: {},
    isLoading: false,
    error: null
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_REGISTER:
            return {
                ...state,
                isLoading: true
            }
        case GET_REGISTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: null,
                data: action.payload.response
            }
        case GET_REGISTER_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        default:
            return state
    }
}