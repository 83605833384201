import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Collapse,
  Empty,
  message,
  Row,
  Space,
  Table,
  Tooltip,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { VietnameseString } from '@constants/VietnameseString';
import AddZaloAccount from './components/AddZaloAccount';
import { requestCreateZNSProvider, requestGetOA } from './services/api';
import { ROUTER } from '@constants/Constant';

import { primaryColor } from '@styles/Color';
import reactotron from 'src/ReactotronConfig';
import styled from 'styled-components';
import ZaloPerson from './components/Zalo.Person';
import { TableStyled } from './style';

const DEFAULT_AVATAR =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const renderAvatar = value => {
  const srcImg = value || DEFAULT_AVATAR;
  return (
    <img
      alt=""
      src={srcImg}
      width="100%"
      height="80"
      style={{ objectFit: 'cover' }}
    />
  );
};

const renderStatus = value => {
  return value ? (
    <b style={{ color: 'green' }}>{VietnameseString.verified}</b>
  ) : (
    <b style={{ color: 'tomato' }}>{VietnameseString.non_verified}</b>
  );
};

const renderContent = (value = [], length, title) => {
  if (value.length > length) {
    return (
      <Tooltip
        overlayInnerStyle={
          title === 'content' ? { width: 550, marginLeft: -140 } : null
        }
        title={value}
        placement="top"
      >
        {value.slice(0, length)}...
      </Tooltip>
    );
  } else {
    return value;
  }
};

function ZaloOA() {
  const history = useHistory();
  const userInfo = useSelector(store => store.userReducer.data);

  const [visibleAddZaloAccount, setVisibleAddZaloAccount] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [listZaloOA, setListZaloOA] = useState([]);

  const [isFormZaloPerson, setIsFormZaloPerson] = React.useState(false);

  const selectedProvider = useRef(null);

  const getOA = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await requestGetOA({
        enterprise_id: userInfo.enterprise_id,
      });
      setListZaloOA(data);
    } catch (error) {
      console.log('Error getOA: ', { error });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const channelRef = useRef(new BroadcastChannel('zaloLogin'));
  const channel = channelRef.current;

  const addOAProvider = async code => {
    try {
      const payloadCreateOA = {
        ...selectedProvider.current,
        code,
      };

      if (selectedProvider.current === null) return;

      await requestCreateZNSProvider(payloadCreateOA);
      message.success('Cập nhật thành công');
    } catch (error) {
      message.error(error?.msg);
    } finally {
      selectedProvider.current = null;
    }
  };

  useEffect(() => {
    channel.addEventListener('message', e => {
      addOAProvider(e.data.code);
    });
    return () => {
      channel.close();
    };
  }, []);

  useEffect(() => {
    getOA();
  }, []);

  const columns = [
    {
      width: '20%',
      title: <b>{VietnameseString.avatar}</b>,
      dataIndex: 'avatar',
      key: 'avatar',
      render: (value, record) => renderAvatar(value),
    },
    {
      width: '20%',
      title: <b>{VietnameseString.account}</b>,
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => renderContent(value, 50, 'name'),
    },
    {
      width: '50%',
      title: <b>{VietnameseString.description}</b>,
      dataIndex: 'description',
      key: 'description',
      render: (value, record) => renderContent(value, 300, 'description'),
    },
    {
      width: '10%',
      title: <b>{VietnameseString.status}</b>,
      dataIndex: 'is_active',
      key: 'is_active',
      render: (value, record) => renderStatus(value),
    },
    {
      // title: 'Action',
      // key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Row>
            <Button
              type="link"
              onClick={() => {
                // const widthPopup = Math.round(window.screen.availWidth * (1 / 3));
                // const heightPopup = Math.round(window.screen.availHeight * (3 / 4));
                const widthPopup = 600;
                const heightPopup = 800;

                selectedProvider.current = record.zns_provider;

                window.open(
                  selectedProvider.current.login_url,
                  'popup',
                  `width=${widthPopup},height=${heightPopup}
                  ,left=${(window.screen.availWidth - widthPopup) / 2},top=${
                    (window.screen.availHeight - heightPopup) / 2
                  }
                  `
                );
              }}
            >
              Đăng nhập
            </Button>
            <Button
              type="link"
              onClick={() => {
                const urlSearchParams = { oa_provider_id: record.id };
                history.push({
                  pathname: ROUTER.ZALO_OA_DETAIL,
                  search: new URLSearchParams(urlSearchParams).toString(),
                });
              }}
            >
              Chi tiết
            </Button>
          </Row>
        </Space>
      ),
    },
  ];

  return (
    <>
      <CollapseStyled defaultActiveKey={['1', '2']}>
        <Collapse.Panel
          extra={
            <Button
              type="primary"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setVisibleAddZaloAccount(true);
              }}
            >
              {VietnameseString.add_zalo_oa_account}
            </Button>
          }
          header={<strong>{VietnameseString.oa_account}</strong>}
          key="1"
        >
          <TableStyled
            columns={columns}
            dataSource={listZaloOA}
            loading={isLoading}
            bordered
            // onRow={(record, rowIndex) => ({
            //   onClick: () => {
            //     const urlSearchParams = { oa_provider_id: record.id };
            //     history.push({
            //       pathname: ROUTER.ZALO_OA_DETAIL,
            //       search: new URLSearchParams(urlSearchParams).toString(),
            //     });
            //   },
            // })}
            size="small"
            scroll={{
              x: 800,
              scrollToFirstRowOnChange: true,
            }}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>{VietnameseString.no_data}</span>}
                />
              ),
              triggerDesc: '',
              triggerAsc: '',
              cancelSort: '',
            }}
          />

          {visibleAddZaloAccount && (
            <AddZaloAccount
              visible={visibleAddZaloAccount}
              onHide={() => {
                getOA();
                setVisibleAddZaloAccount(false);
              }}
            />
          )}
        </Collapse.Panel>
        <Collapse.Panel
          header={<strong>Tài khoản Zalo cá nhân</strong>}
          key="2"
          extra={
            <Button
              type="primary"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setIsFormZaloPerson(true);
              }}
            >
              Thêm tài khoản Zalo cá nhân
            </Button>
          }
        >
          <ZaloPerson
            isFormZaloPerson={isFormZaloPerson}
            setIsFormZaloPerson={setIsFormZaloPerson}
          />
        </Collapse.Panel>
      </CollapseStyled>
    </>
  );
}

const CollapseStyled = styled(Collapse)`
  .ant-collapse-header {
    display: flex !important;
    align-items: center !important;
  }
  & .ant-collapse-header div:first-child {
    display: flex !important;
    align-items: center !important;
  }
`;

export default ZaloOA;
