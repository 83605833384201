import React, { useCallback, useEffect, useState } from 'react';
import {
  ImportOutlined,
  PlusSquareOutlined,
  OrderedListOutlined,
} from '@ant-design/icons';
import { Checkbox, Col, Empty, Form, Input, message, Row, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import styled from 'styled-components';
import moment from 'moment';
import {
  createSubJob,
  requestCustomerJobList,
  requestGetCustomerGroups,
  updateSubJob,
} from '@constants/Api';
import AddCustomersToGroupModal from '@screens/Enterprise/CustomerGroup/modals/AddCustomersToGroupModal';
import AddCustomerGroupModal from '@screens/Enterprise/CustomerGroup/modals/AddCustomerGroupModal';
import ImportCustomersModal from '@screens/Enterprise/CustomerGroup/modals/ImportCustomersModal';
import { VietnameseString } from '@constants/VietnameseString';
import PopupWarning from '@components/popup/PopupWarning';
import { JOB_STATUS, ROUTER } from '@constants/Constant';
import { getJobDetail } from '@src/redux/actions';
import EventEmitter from '@utils/EventEmitter';
import ConditionModal from './ConditionModal';
import { notifyFail } from '@utils/notify';

import { ButtonSystemStyle, TableAntStyle } from '@styles/Style';
import RemoveCustomerModal from './RemoveCustomerModal';
import { DELETE_CUSTOMER_IN_GROUP } from '../../Constants';

const { Option } = Select;

const FormStyle = styled(Form)`
  .ant-col.ant-form-item-label label {
    color: #fe7a15;
    font-size: 20px;
  }

  div.ant-form-item-explain-error {
    text-align: left;
  }
  width: 100%;
`;

const ActionsOnCustomerListStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .actions {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
`;

function SelectCustomer(props) {
  const [form] = Form.useForm();
  const location = useLocation();
  const dispatch = useDispatch();
  const jobDetail = useSelector(state => state.jobDetailReducer.data);
  // console.log('jobDetail', jobDetail);
  // if (
  //   !jobDetail.job_id &&
  //   location.pathname.includes(ROUTER.JOB_GENERAL_EDIT)
  // ) {
  //   const arr = location.pathname.split('/');
  //   const subJobId = arr[arr.length - 1];
  //   dispatch(getJobDetail({ id: parseInt(subJobId) }));
  // }
  useEffect(() => {
    if (
      !jobDetail.job_id &&
      location.pathname.includes(ROUTER.JOB_GENERAL_EDIT)
    ) {
      const arr = location.pathname.split('/');
      const subJobId = arr[arr.length - 1];
      dispatch(getJobDetail({ id: parseInt(subJobId) }));
    }
  }, [jobDetail.job_id, dispatch, location.pathname]);

  const [visibleAddCustomerGroupModal, setVisibleAddCustomerGroupModal] =
    useState(false);
  const [visibleImportCustomerModal, setVisibleImportCustomerModal] =
    useState(false);
  const [customers, setCustomers] = useState([]);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [isLoadingSelectSearch, setIsLoadingSelectSearch] = useState(false);
  const [modalAddCustomer, setModalAddCustomer] = useState({
    shown: false,
    loading: false,
  });

  const [visibleConditionModal, setVisibleConditionModal] = useState(false);

  const [paramCustomers, setParamCustomers] = useState({
    job_id: props.jobId,
    group_id: '',
    page: '1',
    status: '',
    fromDate: '',
    toDate: '',
  });

  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 0,
  });

  const [condition, setCondition] = useState([]);
  const [configuration, setConfiguration] = useState({
    isDeleteWhenIneligible: false,
    isValidForNewCustomersOnly: false,
  });

  const [
    isConfirmTheSelectedCustomerGroup,
    setIsConfirmTheSelectedCustomerGroup,
  ] = useState(false);
  const [
    visibleConfirmTheSelectedCustomerGroup,
    setVisibleConfirmTheSelectedCustomerGroup,
  ] = useState(false);

  const [visibleRemoveCustomerModal, setVisibleRemoveCustomerModal] =
    React.useState(false);

  const onFinish = values => {
    if (values.not_assign_customer_enable) {
      setVisibleRemoveCustomerModal(true);
    } else {
      handleCreateSubJob();
    }
  };

  const handleCreateSubJob = async (isRemoveCustomer = false) => {
    const formValue = form.getFieldsValue();

    mixpanel.track(`Assign job: ${formValue.subJobName.trim()}`);
    const job_conditions = condition.map(c => {
      const { id, label, ...spread } = c;
      return spread;
    });

    const handleAddConditionToPayload = payload => {
      payload.job_conditions = [...job_conditions];
      payload.auto_delete_customer = configuration.isDeleteWhenIneligible;
      payload.apply_new_customer = configuration.isValidForNewCustomersOnly;
    };

    try {
      let result;
      if (!jobDetail.job_id) {
        let payload = {
          parent_id: props.jobId,
          name: formValue.subJobName.trim(),
          group_id: formValue.customerGroup,
          not_assign_customer_enable: Number(
            formValue.not_assign_customer_enable
          ),
        };

        if (isRemoveCustomer) {
          payload.delete_customer_in_group = DELETE_CUSTOMER_IN_GROUP.APPROVE;
        }

        if (condition.length) {
          handleAddConditionToPayload(payload);
        }

        result = await createSubJob(payload);
      } else {
        let payload = {
          parent_id: props.jobId,
          name: formValue.subJobName.trim(),
          group_id: formValue.customerGroup,
          job_id: jobDetail.job_id,
          not_assign_customer_enable: Number(
            formValue.not_assign_customer_enable
          ),
        };

        if (location.pathname.includes(ROUTER.JOB_GENERAL_EDIT)) {
          payload.status = formValue.status;
        }

        if (isRemoveCustomer) {
          payload.delete_customer_in_group = DELETE_CUSTOMER_IN_GROUP.APPROVE;
        }

        if (condition.length) {
          handleAddConditionToPayload(payload);
        }

        result = await updateSubJob(payload);
      }

      dispatch(getJobDetail({ id: result.data.job_id }));
      props.handleChangeStep(0);
    } catch (error) {
      notifyFail(error.msg);
    }
  };

  const getCustomersInGroup = useCallback(async payload => {
    let dataCustomer = [],
      countJobsUsedTheGroup = 0;
    let pageInfo = {
      total: 0,
      current: 0,
      pageSize: 0,
    };

    try {
      setIsLoadingTable(true);
      const { data, countJobUsedGroup, paging } = await requestCustomerJobList(
        payload
      );

      dataCustomer = data.map(customer => ({ ...customer, key: customer.id }));

      pageInfo = {
        ...pageInfo,
        total: paging.totalItemCount,
        current: paging.page,
        pageSize: paging.limit,
      };
      countJobsUsedTheGroup = countJobUsedGroup;
    } catch (error) {
      console.log('Error getCustomersInGroup: ', { error });
    } finally {
      setIsLoadingTable(false);
    }

    return [dataCustomer, pageInfo, countJobsUsedTheGroup];
  }, []);

  const handleGetCustomers = async paramCustomers => {
    let payload = {
      ...paramCustomers,
    };

    if (location.pathname.includes(ROUTER.JOB_GENERAL_EDIT)) {
      payload.group_id = '';
    } else if (!payload.group_id) return;

    const [dataCustomer, pageInfo, countJobsUsedTheGroup] =
      await getCustomersInGroup(payload);

    if (
      payload.group_id &&
      countJobsUsedTheGroup &&
      isConfirmTheSelectedCustomerGroup
    ) {
      setVisibleConfirmTheSelectedCustomerGroup(true);
    }

    setCustomers(dataCustomer);
    setPaging({
      total: pageInfo.total,
      current: pageInfo.current,
      pageSize: pageInfo.pageSize,
    });
  };

  useEffect(() => {
    handleGetCustomers(paramCustomers);
  }, [paramCustomers.group_id, paramCustomers.page, modalAddCustomer]);

  useEffect(() => {
    const eventEmitter = EventEmitter.addListener('import_on_complete', () => {
      handleGetCustomers(paramCustomers);
    });
    return () => {
      eventEmitter.removeAllListeners(['import_on_complete']);
    };
  }, [paramCustomers]);

  /**
   * @example customerGroup {
   *    id: number,
   *    name: string,
   *    description: string,
   * }
   */
  const [customerGroups, setCustomerGroups] = useState([]);
  const [idCustomerGroupSelected, setIdCustomerGroupSelected] = useState();
  useEffect(() => {
    getCustomerGroups();
    setParamCustomers({
      ...paramCustomers,
      job_id: props.jobId,
      group_id: idCustomerGroupSelected,
    });
    form.setFieldsValue({ customerGroup: idCustomerGroupSelected });
  }, [idCustomerGroupSelected]);

  const getCustomerGroups = async () => {
    try {
      setIsLoadingSelectSearch(true);
      const result = await requestGetCustomerGroups({
        search: '',
      });
      const data = result.data.map(customerGroup => ({
        ...customerGroup,
        key: customerGroup.id,
      }));
      setCustomerGroups([...data]);
    } catch (error) {
      console.log('Error getCustomerGroups: ', { error });
    } finally {
      setIsLoadingSelectSearch(false);
    }
  };

  useEffect(() => {
    getCustomerGroups();
  }, []);

  const columns = [
    {
      width: 70,
      title: 'STT',
      dataIndex: '',
      key: '',
      render: (text, record, index) => (
        <div style={{ textAlign: 'center', display: 'block' }} id={record.id}>
          {(paging.current - 1) * paging.pageSize + index + 1}
        </div>
      ),
      // ellipsis: true,
    },
    {
      title: 'Họ tên',
      dataIndex: 'name',
      key: 'name',
      render: name => (
        <div style={{ textAlign: 'left' }}>{name ? name : '--'}</div>
      ),
    },
    {
      width: 210,
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone',
      render: phone => <div>{phone ? phone : '--'}</div>,
    },
    {
      title: 'Tỉnh/Thành phố',
      dataIndex: 'province',
      key: 'province',
      render: province => <div>{province?.name ? province.name : '--'}</div>,
    },
    {
      width: 210,
      title: 'Trạng thái',
      dataIndex: 'status_name',
      key: 'status_name',
      render: status_name => <div>{status_name ? status_name : '--'}</div>,
    },
  ];

  useEffect(() => {
    if (jobDetail?.job_id) {
      form.setFieldsValue({
        subJobName: jobDetail?.name,
        customerGroup: jobDetail?.group?.id,
        status: jobDetail?.status,
        not_assign_customer_enable: Boolean(
          jobDetail?.not_assign_customer_enable
        ),
      });

      setIdCustomerGroupSelected(jobDetail?.group?.id);
      setParamCustomers({
        ...paramCustomers,
        job_id: jobDetail?.job_id,
        group_id: jobDetail?.group?.id,
      });
    }
  }, [jobDetail]);

  useEffect(() => {
    const { job_customer_conditions, job_config_condition_setting } = jobDetail;

    if (job_customer_conditions?.length) {
      setCondition(job_customer_conditions);
      setConfiguration({
        isDeleteWhenIneligible:
          job_config_condition_setting?.auto_delete_customer,
        isValidForNewCustomersOnly:
          job_config_condition_setting?.apply_new_customer,
      });
    }
  }, [jobDetail?.job_customer_conditions]);



  return (
    <div>
      <FormStyle form={form} layout="vertical" onFinish={onFinish} 
        initialValues={{subJobName: location.pathname.includes(ROUTER.JOB_GENERAL_ADD) ? `Giao việc ${moment(undefined).format("DD/MM/YYYY HH:mm")}`: null}}
      >
        {location.pathname.includes(ROUTER.JOB_GENERAL_EDIT) && (
          <Form.Item
            label="Trạng thái"
            name="status"
            rules={[
              { required: true, message: 'Vui lòng chọn nhóm khách hàng' },
            ]}
          >
            <Select style={{ width: '50%' }}>
              {JOB_STATUS.map(v => (
                <option key={v.id} value={v.id}>
                  {v.name}
                </option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          label="Nội dung giao việc"
          name="subJobName"
          rules={[
            { required: true, message: 'Vui lòng nhập nội dung giao việc' },
            { whitespace: true, message: 'Vui lòng không nhập khoảng trắng' },
          ]}
        >
          <Input placeholder="Nội dung giao việc" />
        </Form.Item>

        <Form.Item
          label="Nhóm khách hàng"
          name="customerGroup"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn nhóm khách hàng',
            },
          ]}
        >
          <Select
            style={{ width: '50%' }}
            allowClear
            showSearch
            optionFilterProp="children"
            loading={isLoadingSelectSearch}
            value={idCustomerGroupSelected}
            placeholder="Tập khách hàng"
            disabled={location.pathname.includes(ROUTER.JOB_GENERAL_EDIT)}
            onClear={() => {
              setCustomers([]);
              setParamCustomers({ ...paramCustomers, group_id: '' });
              setIdCustomerGroupSelected();
            }}
            onChange={async value => {
              setIsConfirmTheSelectedCustomerGroup(true);
              setParamCustomers({ ...paramCustomers, group_id: value });
            }}
          >
            {customerGroups.map((customerGroup, index) => {
              return (
                <Option value={customerGroup.id} key={index}>
                  {customerGroup.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item valuePropName="checked" name="not_assign_customer_enable">
          <Checkbox>
            Không giao việc cho các khách hàng đã có sale chăm sóc
          </Checkbox>
        </Form.Item>

        <ActionsOnCustomerListStyled>
          <div>Danh sách khách hàng</div>

          <div className="actions">
            <ButtonSystemStyle
              type="button"
              style={{ marginLeft: '30px', padding: '5px 10px' }}
              onClick={() => {
                if (paramCustomers.group_id) {
                  setVisibleConditionModal(true);
                } else {
                  message.warn(
                    ' Vui lòng chọn nhóm hoặc tạo nhóm mới trước khi đặt điều kiện!'
                  );
                }
              }}
            >
              <OrderedListOutlined style={{ marginRight: '10px' }} />
              Điều kiện
            </ButtonSystemStyle>

            {paramCustomers.group_id && (
              <ButtonSystemStyle
                type="button"
                style={{ marginLeft: '30px', padding: '5px 10px' }}
                onClick={event => {
                  setModalAddCustomer({
                    shown: true,
                    loading: false,
                  });
                }}
              >
                <PlusSquareOutlined style={{ marginRight: '10px' }} />
                Thêm khách hàng
              </ButtonSystemStyle>
            )}

            {!location.pathname.includes(ROUTER.JOB_GENERAL_EDIT) && (
              <ButtonSystemStyle
                type="button"
                style={{ marginLeft: '30px', padding: '5px 10px' }}
                onClick={event => {
                  setVisibleAddCustomerGroupModal(true);
                }}
              >
                <PlusSquareOutlined style={{ marginRight: '10px' }} />
                Tạo nhóm
              </ButtonSystemStyle>
            )}

            <ButtonSystemStyle
              type="button"
              style={{ marginLeft: '30px', padding: '5px 10px' }}
              onClick={event => {
                setVisibleImportCustomerModal(true);
              }}
            >
              <ImportOutlined style={{ marginRight: '10px' }} />
              Import
            </ButtonSystemStyle>
          </div>
        </ActionsOnCustomerListStyled>

        <TableAntStyle
          loading={isLoadingTable}
          dataSource={customers}
          columns={columns}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu.</span>}
              />
            ),
          }}
          pagination={{
            ...paging,
            showSizeChanger: false,
            onChange: async (page, pageSize) => {
              setParamCustomers({ ...paramCustomers, page });
            },
          }}
        />

        <Form.Item>
          <Row style={{ width: '100%' }} align="middle" justify="space-between">
            <Col></Col>
            <Col>
              <ButtonSystemStyle htmlType="submit">Tiếp tục</ButtonSystemStyle>
            </Col>
          </Row>
        </Form.Item>
      </FormStyle>

      {/* Modal */}
      <AddCustomersToGroupModal
        shown={modalAddCustomer.shown}
        onClose={() =>
          setModalAddCustomer({
            shown: false,
            loading: false,
          })
        }
        payload={{ exclude_group_id: paramCustomers.group_id }}
        group_id={paramCustomers.group_id}
        schemas={[]}
        onSuccess={() => handleGetCustomers(paramCustomers)}
      />

      {/* import mới */}
      <ImportCustomersModal
        // ẩn hiện modal
        visibleImportCustomerModal={visibleImportCustomerModal}
        setVisibleImportCustomerModal={setVisibleImportCustomerModal}
        isRequiredCustomerGroupSelect={true}
        // id nhóm KH được chọn để import
        idCustomerGroupSelected={idCustomerGroupSelected}
        setIdCustomerGroupSelected={setIdCustomerGroupSelected}
      />

      {/* Modal tạo nhóm khách hàng */}
      <AddCustomerGroupModal
        visible={visibleAddCustomerGroupModal}
        setVisible={setVisibleAddCustomerGroupModal}
        handleResultCreateCustomerGroup={customerGroup => {
          /**
           * @example customerGroup {
           *    id: number,
           *    name: string,
           *    description: string,
           * }
           */
          setCustomerGroups([customerGroup, ...customerGroups]);
          setIdCustomerGroupSelected(customerGroup.id);
        }}
      />

      {visibleConditionModal && (
        <ConditionModal
          visible={visibleConditionModal}
          onCancelModal={() => setVisibleConditionModal(false)}
          conditionSelected={condition}
          onAddCondition={condition => {
            setCondition(condition);
          }}
          selectedConfiguration={configuration}
          onChangeConfiguration={configuration => {
            setConfiguration(configuration);
          }}
        />
      )}

      <PopupWarning
        visible={visibleConfirmTheSelectedCustomerGroup}
        content="Các giao việc chọn cùng nhóm sẽ bị ảnh hưởng!"
        confirmationContent={
          VietnameseString.are_you_sure_you_want_to_choose_this_group
        }
        okText={VietnameseString.yes}
        cancelText={VietnameseString.no}
        onOk={() => {
          setVisibleConfirmTheSelectedCustomerGroup(false);
          setIsConfirmTheSelectedCustomerGroup(false);
        }}
        onCancel={() => {
          setVisibleConfirmTheSelectedCustomerGroup(false);
          setIsConfirmTheSelectedCustomerGroup(false);
          setCustomers([]);
          form.setFieldsValue({ customerGroup: '' });
          setIdCustomerGroupSelected();
          setParamCustomers({ ...paramCustomers, group_id: '' });
        }}
      />

      {visibleRemoveCustomerModal && (
        <RemoveCustomerModal
          visible={visibleRemoveCustomerModal}
          groupId={Number(form.getFieldValue('customerGroup'))}
          handleCancel={() => {
            setVisibleRemoveCustomerModal(false);
          }}
          showCheckBox = {  location.pathname.includes(ROUTER.JOB_GENERAL_ADD)}
          handleCreateSubJob={handleCreateSubJob}
        />
      )}
    </div>
  );
}

export default SelectCustomer;
