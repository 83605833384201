import styled from 'styled-components';
import { Table } from 'antd';

export const StyledTableBlinking = styled(Table)`
  .table-kpi-progress .ant-table-cell {
    position: relative;
    font-weight: bold;
  }

  .table-kpi-progress {
    table-layout: fixed !important;
    .ant-tooltip-top {
      background-color: black;
      color: white;
    }
    .dot {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: red;
      border: 1px solid red;
    }
  }
`;

export const StyledBlinking = styled.div`
  font-weight: bold;
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  animation-name: ${props => (props.blinking ? 'example-color' : '')};
  animation-duration: 3s;
  animation-iteration-count: 5;

  @keyframes example-color {
    0% {
      /* color: rgb(54, 162, 235); */
      background-color: rgb(255, 255, 255);
    }
    25% {
      /* color: rgb(255, 99, 132); */
      background-color: rgb(204, 204, 204);
    }
    50% {
      /* color: rgb(255, 205, 86); */
      background-color: rgb(255, 255, 255);
    }
    75% {
      /* color: rgb(90, 164, 93); */
      background-color: rgb(204, 204, 204);
    }
    100% {
      /* color: rgb(42, 108, 133); */
      background-color: rgb(255, 255, 255);
    }
  }
`;
