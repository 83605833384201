import { create_UUID } from '@screens/Enterprise/ElectronicVoucher/service';
import EventEmitter from '@utils/EventEmitter';
import { notifyFail } from '@utils/notify';
import { Popconfirm, Tooltip } from 'antd';
import React from 'react';
import IconAntd from 'src/modules/IconAntd';
import { sendCallBroadCast } from '../BroadCastUtil';
import { CALLING_CODE, INCOMING_CALL, PROVIDER_NAME } from '../CallConstant';
import Calling from './Calling';
import audioCall from './call.mp3';
import { getCustomerDetailInboundCall } from './service';
import { ModalModulesStyled } from './style';
// import { getCustomerDetailInboundCall } from './service';
import { ROUTER } from '@constants/Constant';
import { useHistory } from 'react-router-dom';
import CallUtil from '../CallUtil';
import useCallStatus from '../useCallStatus';
import { returnUsernameCall } from './utils';

const CODE_OTHERDEVICE = {
  ANSWER: 200,
  REJECT: 486,
};

const IncomingCallStringee = ({ isTabActive, remoteVideoRef }) => {
  const history = useHistory();

  const audioRef = React.useRef(null);
  const [incomingCall, setIncomingCall] = React.useState(null);
  const callStatus = useCallStatus();

  // trả lời
  const [isAnswer, setIsAnswer] = React.useState(false);

  // mở modal thông báo cuộc gọi
  const [openModalIncomingCall, setOpenModalIncomingCall] =
    React.useState(false);

  // không trả lời khi có cuộc gọi đến
  const [isIncomingNotAnswer, setIsIncomingNotAnswer] = React.useState(false);

  // danh sách các cuộc gọi đến khi trả lời
  const [listCall, setListCall] = React.useState([]);

  const removeIncomingCall = () => {
    setIncomingCall(null);
  };

  React.useEffect(() => {
    const getFirstTabInfo = () => {
      console.log('window.chrome.tabs', navigator);
      // window.chrome.tabs.query({ currentWindow: true }, tabs => {
      //   if (tabs.length > 0) {
      //     const firstTab = tabs[0];
      //     console.log(firstTab);
      //     // Xử lý thông tin của tab đầu tiên ở đây
      //   }
      // });
    };

    getFirstTabInfo();
  }, []);

  // handle call api all and linking
  const handleDetailCall = (done, call) => {
    if (!incomingCall && !call) return;
    const dataServer = JSON.parse(
      call
        ? call.customDataFromYourServer
        : incomingCall.customDataFromYourServer
    );

    getCustomerDetailInboundCall({
      hotline: dataServer.hotline,
      customer_phone: call ? call.fromNumber : incomingCall.fromNumber,
      enterprise_id: dataServer.enterprise_id,
      customer_id: dataServer.customer_id,
    })
      .then(res => {
        if (res.status) {
          // xử lý trường hợp server chưa trả api page dang-goi
          setTimeout(() => {
            if (res?.data?.listJobNew?.id) {
              const router = {
                pathname: ROUTER.CALLING_SALE,
                search: `?call_id=${dataServer?.call_id}&customer_id=${res?.data?.id}&job_id=${res?.data?.listJobNew?.id}`,
              };
              history.replace(router);
            } else {
              const router = {
                pathname: ROUTER.CALLING_SALE,
                search: `?call_id=${dataServer?.call_id}&customer_id=${res?.data?.id}`,
              };
              history.replace(router);
            }
          }, 1000);
        }
      })
      .finally(() => {
        // trả lời cuộc gọi
        done();
      });
  };

  const cancelCall = () => {
    // từ chối cuộc gọi
    incomingCall &&
      incomingCall.reject(function (res) {
        console.log('reject res', res);
      });

    // remove cuộc gọi đến hiện tại
    removeIncomingCall();

    // reset audio
    resetAudio();
    // close modal
    setOpenModalIncomingCall(false);

    // hết thời gian k trả lời
    setIsIncomingNotAnswer(false);

    // trả lời
    setIsAnswer(false);

    // trả lời và kết thúc
  };

  const resetAudio = () => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
  };

  React.useEffect(() => {
    EventEmitter.addListener('incomingCall', data => {
      console.log(
        '🚀 ~ file: IncomingCall.Stringee.jsx:136 ~ React.useEffect ~ data:',
        data
      );
      // xử lý khi có cuộc gọi đến

      if (data?.code === INCOMING_CALL.INCOMING) {
        const call = data.incomingCall;
        handelIncomingCall(call);
      }

      if (data?.sipCode === CALLING_CODE.Ended) {
        handleEndCall();
      }
    });

    return () => {
      EventEmitter.removeAllListeners(['incomingCall']);
    };
  }, [isAnswer, incomingCall]);

  const handelIncomingCall = call => {
    // nếu đang tồn tại cuộc gọi và chưa trả lời reject cuộc gọi mới nhất

    setIncomingCall(prevCall => {
      // if (prevCall && !isAnswer) {
      //   return prevCall;
      // } else {
      incomingCallEventHandlers(call);
      return call;
      // }
    });
    setIsIncomingNotAnswer(false);
    setOpenModalIncomingCall(true);
    audioRef.current.play();
  };

  const handleEndCall = () => {
    // nếu kết thúc cuộc gọi
    // set audio
    resetAudio();
    // remove cuộc gọi đến hiện tại
    // removeIncomingCall();

    if (isAnswer) {
      // update isEndAnswer from listCall last item
      setListCall(prev => {
        const lastItem = prev[prev.length - 1];
        return [
          ...prev.slice(0, prev.length - 1),
          {
            ...lastItem,
            isEndAnswer: true,
          },
        ];
      });
      handleDetailCall(() => {
        console.log('done');
      });
    } else {
      setIsIncomingNotAnswer(true);
      setIsAnswer(false);
    }
  };

  // handle events incoming call
  const incomingCallEventHandlers = call => {
    call.on('mediastate', state => {
      console.log('call mediastate ', state);
    });
    call.on('error', info => {
      console.log('call on error: ' + JSON.stringify(info));
    });

    call.on('addlocalstream', stream => {
      console.log('call on addlocalstream', stream);
    });

    call.on('addremotestream', stream => {
      console.log('call on addremotestream', stream);
      remoteVideoRef.current.srcObject = stream;
      // remoteVideoRef.current.srcObject = stream;
    });

    call.on('signalingstate', data => {
      console.log('call on signalingstate', data);
      sendCallBroadCast({
        name: PROVIDER_NAME.STRINGEE,
        status: data.sipCode,
        data,
      });

      EventEmitter.emit('incomingCall', data);
    });

    call.on('info', info => {
      console.log('call on info', info);
    });

    call.on('otherdevice', data => {
      if (data.code === CODE_OTHERDEVICE.ANSWER) {
        handleDetailCall(() => {
          cancelCall();
        }, call);
      }

      if (data.code === CODE_OTHERDEVICE.REJECT) cancelCall();
    });
  };

  // trả lời
  const onAnswer = async () => {
    // hangup cuộc gọi đang gọi đi
    CallUtil.hangupCall();
    // hangup toàn bộ cuộc gọi đến
    hangupAllCall();

    setListCall([]);

    // `call/getCustomerDetailInboundCall?hotline=${payload.hotline}&customer_phone=${payload.customer_phone}&enterprise_id=${payload.enterprise_id}&customer_id=${payload.customer_id}`

    handleDetailCall(() => {
      incomingCall &&
        incomingCall.answer(function (res) {
          console.log('answer res', res);
          if (res?.r !== 0) {
            return notifyFail('Không thể kết nối cuộc gọi');
          }

          setListCall([
            {
              uuid: create_UUID(),
              isEndAnswer: false,
              incomingCall,
            },
          ]);
          // tắt audio
          resetAudio();
          // trả lời
          setIsAnswer(true);
          // đóng modal
          setOpenModalIncomingCall(false);
        });
    });
  };

  const hangupAllCall = () => {
    listCall.forEach(item => {
      item.incomingCall.hangup(function (res) {
        console.log('hangupAllCall res', res);
      });
    });

    const cloneListCall = [...listCall];
    const cloneListCallMap = cloneListCall.map(item => ({
      ...item,
      isEndAnswer: true,
    }));
    setListCall(cloneListCallMap);
  };

  const onCancelAnswer = (uuid, calling) => {
    const dataServer = JSON.parse(calling.customDataFromYourServer);

    // hangup cuộc gọi khi đang nghe
    calling.hangup(function (res) {
      resetAudio();
      setOpenModalIncomingCall(false);
      // remove cuộc gọi đến hiện tại
      removeIncomingCall();
      const cloneListCall = [...listCall];
      const cloneListCallMap = cloneListCall.map(item =>
        item.uuid === uuid
          ? {
              ...item,
              isEndAnswer: true,
            }
          : item
      );
      setIsAnswer(false);
      setListCall(cloneListCallMap);
    });
    // handleDetailCall(() => {});
  };

  const onCloseAnswer = uuid => {
    setIsAnswer(false);
    resetAudio();
    setIsIncomingNotAnswer(false);
    setListCall(prev => prev.filter(item => item.uuid !== uuid));
  };

  // kiểm tra nếu đang nghe cuộc gọi thì confirm
  React.useEffect(() => {
    if (!isAnswer) return;
    const handleBeforeUnload = event => {
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isTabActive, isAnswer]);

  return (
    <>
      <audio ref={audioRef} loop src={audioCall} />
      <ModalModulesStyled
        width={320}
        maskClosable={false}
        closable={false}
        footer={null}
        // visible
        visible={openModalIncomingCall}
        centered
      >
        <div onClick={cancelCall} className="btn_close">
          <IconAntd icon="CloseOutlined" />
        </div>
        <h3>
          <strong>
            {returnUsernameCall(
              incomingCall?.fromAlias ||
                incomingCall?.fromNumber.replace('84', '0')
            )}
          </strong>{' '}
          {/* bỏ lỡ cuộc gọi */}
          {isIncomingNotAnswer ? 'đã gọi cho bạn' : 'đang gọi cho bạn'}
        </h3>
        <p>
          {/* bỏ lỡ cuộc gọi */}
          {isIncomingNotAnswer
            ? 'Bạn đã bỏ lỡ cuộc gọi'
            : 'Cuộc gọi sẽ bắt đầu ngay khi bạn chấp nhận'}
        </p>
        <div className="row_btn">
          {isIncomingNotAnswer ? (
            <Tooltip title="Không nghe">
              <div onClick={cancelCall} className="btn_action btn_cancel">
                <IconAntd color="white" icon="CloseOutlined" />
              </div>
            </Tooltip>
          ) : (
            <Popconfirm
              title="Bạn chắc chắn không nghe cuộc gọi?"
              okText="Đồng ý"
              cancelText="Quay lại"
              onConfirm={cancelCall}
            >
              <Tooltip title="Không nghe">
                <div className="btn_action btn_cancel">
                  <IconAntd color="white" icon="CloseOutlined" />
                </div>
              </Tooltip>
            </Popconfirm>
          )}
          {/* Cuộc gọi còn tồn tại */}
          {!isIncomingNotAnswer &&
            (isAnswer || callStatus?.call?.status === CALLING_CODE.Answered ? (
              <Popconfirm
                title="Các cuộc gọi bạn đang thực hiện sẽ bị huỷ?"
                okText="Đồng ý nghe"
                cancelText="Quay lại"
                onConfirm={onAnswer}
              >
                <Tooltip title="Nghe máy">
                  <div className="btn_action btn_accept">
                    <IconAntd color="white" icon="PhoneOutlined" />
                  </div>
                </Tooltip>
              </Popconfirm>
            ) : (
              <Tooltip title="Nghe máy">
                <div onClick={onAnswer} className="btn_action btn_accept">
                  <IconAntd color="white" icon="PhoneOutlined" />
                </div>
              </Tooltip>
            ))}
        </div>
      </ModalModulesStyled>

      {/* hiển thị khi chấp nhận cuộc gọi  */}
      {listCall &&
        listCall.map((call, index) => (
          <Calling
            key={index}
            callDetail={call}
            // isEndAnswer={isEndAnswer}
            onCloseAnswer={onCloseAnswer}
            onCancelAnswer={onCancelAnswer}
          />
        ))}
    </>
  );
};

export default IncomingCallStringee;
