import { formatTime } from '@constants/funcHelper';
import { Button, Empty, Popconfirm, Row, Table,Typography } from 'antd';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { requestCreatAPIKey, requestDeleteAPIKey, requestListAPIKey } from '@constants/Api';
import { notifyFail, notifySuccess } from '@utils/notify';
import { primaryColor } from '@styles/Color';
import { ContainerStyledCopy } from '@screens/Enterprise/Customer/CustomerDetail';
import {
  CopyOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
const DeleteIcon = () => (
  <svg width="20px" height="20px" fill="tomato" viewBox="0 0 1024 1024">
    <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z" />
  </svg>
);

function APIkey(props) {
  const [isLoading, setIsLoading] = useState();
  const [listAPIKey, setListAPIKey] = useState();
  const [params, setParams] = useState({
    page: 1,
    limit: 5,
  });

  useEffect(() => {
    getListAPIKey();
  }, []);

  const getListAPIKey = async (payload = params) => {
    setIsLoading(true);
    try {
      const res = await requestListAPIKey(payload);
      setListAPIKey(res.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const createApiKey = async () => {
    setIsLoading(true);
    try {
      const res = await requestCreatAPIKey();
      getListAPIKey();
      notifySuccess('Thêm mới API Key thành công!');
    } catch (error) {
      console.log(error);
      notifyFail('Đã có lỗi xảy ra, không thể thêm API Key!');
    }
    setIsLoading(false);
  };

  const deleteApiKey = async (id) => {
    setIsLoading(true);
    try {
      const res = await requestDeleteAPIKey(id);
      getListAPIKey();
      notifySuccess('Xoá API Key thành công!');
    } catch (error) {
      console.log(error);
      notifyFail('Đã có lỗi xảy ra, không thể xoá API Key!');
    }
    setIsLoading(false);
  };

  const columns = [
    {
      width: '20%',
      title: <b>Ngày tạo</b>,
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value, record) => formatTime(value),
    },
    {
      width: '70%',
      title: <b>Mã API Key</b>,
      dataIndex: 'value',
      key: 'value',
      render: (value, record) =>           <ContainerStyledCopy
      className="typograph_copy"
      style={{justifyContent:"center"}}
      copyable={{
        icon: [
          <CopyOutlined key="copy-icon" />,
          <CheckCircleOutlined key="copied-icon" />,
        ],
        tooltips: ['Copy', 'Đã Copy'],
      }}
    >
      {value}
    </ContainerStyledCopy>
    },
    {
      width: '10%',
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (value, record) => {
        return (
          <>
            <Popconfirm
              placement="left"
              title="Bạn có chắc chắn muốn xoá mã API Key này không?"
              onConfirm={() => {
                deleteApiKey(value);
              }}
              okText="Xác nhận"
              cancelText="Huỷ"
            >
              <span>
                <DeleteIcon />
              </span>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div style={{ fontSize: 20, fontWeight: 600, color: primaryColor }}>Thiết lập API Key</div>
      <Row justify="end">
        <Button
          type="primary"
          onClick={() => {
            createApiKey();
          }}
        >
          Tạo API Key
        </Button>
      </Row>
      <br />
      <Table
        columns={columns}
        dataSource={listAPIKey}
        loading={isLoading}
        bordered
        scroll={{
          x: 800,
          scrollToFirstRowOnChange: true,
          // y: 'calc(100vh - 430px)',
        }}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>Không có dữ liệu.</span>} />,
          triggerDesc: '',
          triggerAsc: '',
          cancelSort: '',
        }}
        pagination={{
          size: 'small',
          showSizeChanger: false,
          pageSize: 5,
        }}
      />
    </>
  );
}

export default APIkey;
