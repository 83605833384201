import {
    GET_KEYWORD, GET_KEYWORD_SUCCESS, GET_KEYWORD_FAIL,
} from "@actions/type";

const initialState = {
    data: {},
    isLoading: true,
    error: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_KEYWORD:
            return {
                ...state,
                isLoading: true,
            }
        case GET_KEYWORD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload.response.data,
                error: null
            }
        case GET_KEYWORD_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        default:
            return state;
    }
}