/* eslint-disable jsx-a11y/alt-text */
import {
  getJobChart,
  requestCallListVer2,
  requestCustomerJobList,
  requestExportCustomerInJob,
  requestJobDetail,
  requestJobDetailSimple,
  requestQuestionsAndAnswerReport,
  requestResultReviewer,
  requestStatisticByJob,
  requestTelesaleDetail,
} from '@constants/Api';
import { ROUTER, STRING } from '@constants/Constant';
import Loading from '@src/components/Loading';
import {
  getCallHistory,
  getJobFilter,
  getJobStatistic,
  getListCallStatus,
  getQuessionsAndAnswerFilter,
  getReviewList,
} from '@src/redux/actions';
import '@styles/RegisterRequest.css';
import { notifyFail, notifySuccess } from '@utils/notify';
import { toDateString } from '@utils/ToDateString';
import { Avatar, Empty, Skeleton, Tabs, Tooltip } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { Button, Col, FormControl, Row, Table } from 'react-bootstrap';
import { Doughnut, Line } from 'react-chartjs-2';
import 'react-datepicker/dist/react-datepicker.css';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DatePickerCustom from '../../../components/DatePickerCustom';

import { UserOutlined } from '@ant-design/icons';
import { dateFormatConversion, randomColor } from '@constants/funcHelper';
import { VietnameseString } from '@constants/VietnameseString';
import { TabsStyled } from '@styles/Style';
import TagCustom from '../Customer/components/TagCustom';
import { OPTION_SCHEDULE_CALL_BACK, TIME_RECALL_STATUS } from './Constants';
import CustomersFilter from './Results/CustomersFilter';
import General from './Results/General';
import ResultChild from './Results/ResultChild';
import ResultParent from './Results/ResultParent';
import styled from 'styled-components';

const CustomerNameStyled = styled.label`
  font-size: 18px;

  &:hover {
    color: green;
    cursor: pointer;
  }
`;

const noneNavigation = [VietnameseString.average];
function convertTimeWhenNavigating(params, record) {
  let from = '';
  let to = '';

  if (record.title === VietnameseString.total_number_of_calls) {
    from = params.from;
    to = params.to;
  } else {
    from = dateFormatConversion(record.title);
    to = dateFormatConversion(record.title);
  }

  return { from, to };
}

class Result extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'general',
      evaluate: {},
      activePage: 1,
      searchBar: {
        // general infor field
        gen_work: '0',
        gen_start: '',
        gen_end: '',
        // list user field
        list_work: '0',
        list_start: '',
        list_end: '',
        // work detail field
        job_form_question_id: '',
        job_form_answers_id: '',
        call_review_config_id: '',
        job_id: '',
        call_status: '',
        [STRING.fromDate]: '',
        [STRING.toDate]: '',
        job_id: '',
      },
      search: {
        [STRING.fromDate]: '',
        [STRING.toDate]: '',
        job_id: '',
      },
      searchUser: {
        job_id: [],
        status: [],
        call_status_id: [],
        telesale_id: [],
        fromDate: '',
        toDate: '',
      },
      listCustomer: [],
      listStatus: [],
      listTelesale: [],
      status: '',
      datachart: {
        labels: ['1', '2', '3', '4', '5'],
        datasets: [
          {
            label: 'Số cuộc gọi trong ngày',
            borderColor: '#aeea00',
            fill: false,
            pointBackgroundColor: '#aeea00',
            pointBorderColor: 'white',
            pointRadius: 5,
            data: [41, 15, 6, 17, 28],
          },
        ],
      },
      startDate: '',
      endDate: '',
      jobInfo: {},
      static_opportunities: {},
      opportunities_status: [],
      opportunities_cate: [],
      paging: {
        page: 1,
        totalItemCount: 0,
        limit: 12,
      },
      questionsAndAnswer: [],
      callHistory: {
        data: [],
        paging: {
          page: 1,
          totalItemCount: 0,
          limit: 12,
        },
      },
      isLoadingTableDataCustomer: false,
    };
    this.chartReference = React.createRef();
    this.isSubJob = window.location.pathname.includes(ROUTER.JOB_RESULT_CHILD);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.paging.page !== this.state.paging.page) {
      this.getListCustomer();
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.getEvaluate();
    this.getCallHistoryVer2();
    this.props.getListCallStatus();
    this.props.getJobFilter({
      job_category_id: id,
      enterprise_id: '',
      telesale_id: '',
      limit: '',
    });
    this.props.getReviewList();
    // this.props.getQuessionsAndAnswerFilter({
    //   // enterprise_id: ,
    //   job_category_id: id,
    // });
    this.getListCustomer();
    this.getListTelesale(id, '');
    this.props.getJobFilter({
      job_category_id: id,
      enterprise_id: '',
      telesale_id: '',
      limit: '',
    });
    this.props.getJobStatistic({
      fromDate: '',
      toDate: '',
      job_id: id,
    });
    this.getJobChart();
    this.getJobInfo(id);
    this.requestStatisticByJob(id);
    this.getQuestionInJob();
  }

  async getQuestionInJob() {
    const { id } = this.props.match.params;
    try {
      const { data } = await requestQuestionsAndAnswerReport({
        enterprise_id: this.props.user?.data?.enterprise_id,
        job_category_id: id,
      });

      this.setState({ questionsAndAnswer: data });
    } catch (error) {
      console.log('Error getQuestionInJob: ', { error });
    }
  }

  async requestStatisticByJob(id) {
    const { [STRING.fromDate]: fromDate, [STRING.toDate]: toDate } =
      this.state.search;
    await this.startDate();
    await this.endDate();
    const { startDate, endDate } = this.state;
    try {
      const res = await requestStatisticByJob({
        fromDate: fromDate || startDate,
        toDate: toDate || endDate,
        job_id: id,
      });
      this.setState({
        ...this.state,
        static_opportunities: res.data,
        opportunities_status: res.data?.opportunities_status,
        opportunities_cate: res.data?.opportunities_cate,
      });
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  async getJobInfo(id) {
    try {
      const res = await requestJobDetailSimple({ id: id });
      this.setState({
        ...this.state,
        jobInfo: res.data,
      });
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  startDate() {
    const { [STRING.fromDate]: fromDate, [STRING.toDate]: toDate } =
      this.state.search;
    if (fromDate == '' && toDate == '') {
      let now = Date.now() - 604800000;
      let d = new Date(now);
      let date = d.getDate() > 9 ? d.getDate() : '0' + d.getDate();
      let month =
        d.getMonth() + 1 > 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1);
      let year = d.getFullYear();
      let startDate = year + '-' + month + '-' + date + 'T00:00:00.000Z';
      this.setState({
        startDate: startDate,
      });
    }
    if (fromDate == '' && toDate != '') {
      let now = new Date(toDate) - 604800000;
      let d = new Date(now);
      let date = d.getDate() > 9 ? d.getDate() : '0' + d.getDate();
      let month =
        d.getMonth() + 1 > 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1);
      let year = d.getFullYear();
      let startDate = year + '-' + month + '-' + date + 'T00:00:00.000Z';
      this.setState({
        startDate: startDate,
      });
    }
  }

  endDate() {
    const { [STRING.fromDate]: fromDate, [STRING.toDate]: toDate } =
      this.state.search;
    if (toDate == '') {
      let now = Date.now();
      let d = new Date(now);
      let date = d.getDate() > 9 ? d.getDate() : '0' + d.getDate();
      let month =
        d.getMonth() + 1 > 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1);
      let year = d.getFullYear();
      let endDate = year + '-' + month + '-' + date + 'T00:00:00.000Z';
      this.setState({
        endDate: endDate,
      });
    }
  }

  async getJobChart() {
    const {
      [STRING.fromDate]: fromDate,
      [STRING.toDate]: toDate,
      job_id,
    } = this.state.search;
    await this.startDate();
    await this.endDate();
    const { startDate, endDate } = this.state;
    let arrDate = [];
    let arrValues = [];
    const { id } = this.props.match.params;
    try {
      const res = await getJobChart({
        fromDate: fromDate || startDate,
        toDate: toDate || endDate,
        job_id: job_id,
        telesale_id: '',
      });
      let count = res?.data?.map(value => {
        arrDate.push(value.quarter);
        arrValues.push(value.earnings);
      });
      this.setState({
        ...this.state,
        datachart: {
          ...this.state.datachart,
          labels: arrDate,
          datasets: [
            {
              label: 'Số cuộc gọi trong ngày',
              borderColor: '#aeea00',
              fill: false,
              pointBackgroundColor: '#aeea00',
              pointBorderColor: 'white',
              pointRadius: 5,
              data: arrValues,
            },
          ],
        },
      });
    } catch (err) {
      alert(err);
    }
  }

  async getListCustomer() {
    const { status, job_id, paging, fromDate, toDate, call_status_id } =
      this.state.searchUser;

    const { id } = this.props.match.params;

    const payload = {
      ...this.state.searchUser,
      job_id: job_id.length ? job_id : id,
      group_id: '',
      page: this.state?.paging?.page,
      status: status || '',
      fromDate,
      toDate,
    };

    try {
      this.setState({ isLoadingTableDataCustomer: true });

      const isScheduleCallBack = call_status_id.includes(
        OPTION_SCHEDULE_CALL_BACK
      );

      if (isScheduleCallBack) {
        payload.call_status_id = call_status_id.filter(
          el => el != OPTION_SCHEDULE_CALL_BACK
        );
        payload.time_recall_status = TIME_RECALL_STATUS.APPROVE;
      } else {
        payload.time_recall_status = TIME_RECALL_STATUS.NONE;
      }

      const res = await requestCustomerJobList(payload);
      this.setState({
        listCustomer: res.data,
        paging: {
          page: res.paging.page,
          totalItemCount: res.paging.totalItemCount,
          limit: res.paging.limit,
        },
      });
    } catch (err) {
      console.log('Error getListCustomer: ', { err });
    } finally {
      this.setState({ isLoadingTableDataCustomer: false });
    }
  }

  async getListTelesale(id, status) {
    try {
      const res = await requestTelesaleDetail({
        job_id: id,
        status: status,
      });
      this.setState({
        listTelesale: res.data.listSale,
        listStatus: res.data.status,
      });
    } catch (err) {}
  }

  async getCallHistoryVer2() {
    const {
      call_type,
      job_id,
      call_status,
      telesale_id,
      [STRING.start_call_time]: start_call_time,
      limit = 12,
      fromDate,
      toDate,
      is_late = 0,
      job_form_question_id,
      job_form_answers_id,
      call_review_config_id,
    } = this.state.searchBar;

    const { callHistory } = this.state;

    const { id } = this.props.match.params;
    const { url } = this.props.match;
    const find = url.search(ROUTER.JOB_RESULT_CHILD);

    try {
      const { data, paging } = await requestCallListVer2({
        page: callHistory.paging.page || '',
        call_type: call_type || '',
        job_id: find != -1 ? id : job_id || '',
        call_status: call_status || '',
        telesale_id: telesale_id || '',
        start_call_time: start_call_time
          ? moment(start_call_time).format('YYYY-MM-DD')
          : '',
        limit: limit || '',
        fromDate: fromDate || '',
        toDate: toDate || '',
        is_late: is_late,
        job_category_id: find == -1 ? id : '',
        job_form_question_id: job_form_question_id || '',
        job_form_answers_id: job_form_answers_id || '',
        call_review_config_id: call_review_config_id || '',
      });

      this.setState({
        callHistory: {
          data,
          paging,
        },
      });
    } catch (error) {
      console.log('Error getCallHistoryVer2: ', { error });
    }
  }

  async getEvaluate() {
    const { id } = this.props.match.params;
    try {
      const res = await requestResultReviewer({
        job_category_id: '',
        job_id: id,
        telesale_id: '',
        status: '',
        call_status: '',
        page: '',
        limit: '',
      });
      this.setState({
        evaluate: res,
      });
    } catch (err) {}
  }

  renderBody() {
    return (
      <div className="content-wrapper job-general pb-5">
        {this.renderInfo()}
        <br />
        {this.renderWorkInfo()}
      </div>
    );
  }

  renderInfo() {
    const { id } = this.props.match.params;
    const { url } = this.props.match;
    const find = url.search(ROUTER.JOB_RESULT_CHILD);
    return (
      <Row>
        <Col xs={12} className="p-0">
          {find != -1 ? <ResultChild id={id} /> : <ResultParent id={id} />}
        </Col>
      </Row>
    );
  }

  renderWorkInfo() {
    const { listStatus } = this.state;
    const calledStatus = listStatus.find(el => el?.name === 'Đã gọi')?.id;

    return (
      <>
        <Row
          className="mt-1"
          style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}
        >
          <Col md className="bg bg-2">
            <Row>
              <h3>Kết quả công việc</h3>
            </Row>
            {/* <div class="line"></div> */}

            <Row className="content-header table-tabs pl-0 pb-0 pr-0 mr-0">
              <TabsStyled
                type="card"
                activeKey={this.state.tab}
                defaultActiveKey="general"
                onChange={activeKey => {
                  this.showTab(activeKey);
                }}
              >
                <Tabs.TabPane tab="Tổng quan" key="general">
                  <General
                    isSubJob={this.isSubJob}
                    jobDetail={this.state.jobInfo}
                    handleChangeSearchParams={_data => {}}
                    handleNavigatingToCustomersTab={({
                      params,
                      record,
                      callStatus,
                    }) => {
                      if (noneNavigation.includes(record.title)) return;

                      const { from, to } = convertTimeWhenNavigating(
                        params,
                        record
                      );

                      this.setState(
                        {
                          searchUser: {
                            job_id: [...params.sub_job_id],
                            call_status_id:
                              callStatus !== undefined ? [callStatus] : [],
                            status: [calledStatus],
                            telesale_id: [...params.telesale_id],
                            fromDate: from,
                            toDate: to,
                          },
                        },
                        () => {
                          this.showTab('customer-list');
                          this.getListCustomer();
                        }
                      );
                    }}
                    handleNavigatingToCallList={({
                      params,
                      record,
                      dataQuestion,
                      answer,
                    }) => {
                      if (noneNavigation.includes(record.title)) return;
                      const { from, to } = convertTimeWhenNavigating(
                        params,
                        record
                      );
                      const dataSearch = {
                        job_id: [...params.sub_job_id],
                        telesale_id: [...params.telesale_id],
                        job_form_question_id: [dataQuestion.question.id],
                        job_form_answers_id: [answer?.id],
                        fromDate: from,
                        toDate: to,
                      };

                      const searchParams = new URLSearchParams();
                      for (const [key, value] of Object.entries(dataSearch)) {
                        searchParams.set(key, JSON.stringify(value));
                      }

                      this.props.history.push({
                        pathname: ROUTER.CALL,
                        search: searchParams.toString(),
                      });
                    }}
                  />
                </Tabs.TabPane>

                <Tabs.TabPane tab="Báo cáo chi tiết" key="detail">
                  {this.renderDetail()}
                </Tabs.TabPane>

                <Tabs.TabPane tab="Danh sách khách hàng" key="customer-list">
                  {this.renderUserList()}
                </Tabs.TabPane>

                <Tabs.TabPane tab="Danh sách sale" key="telesale-list">
                  {this.renderTelesaleList()}
                </Tabs.TabPane>

                <Tabs.TabPane tab="Đánh giá" key="evaluate-list">
                  {this.renderEvaluate()}
                </Tabs.TabPane>
              </TabsStyled>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
  downloadExcelFile = async () => {
    notifySuccess('Đang trong quá trình xuất excel!');

    const { searchUser } = this.state;

    const search = { ...searchUser };

    try {
      const { id } = this.props.match.params;

      //nếu chọn lịch hẹn gọi lại thì search thêm property time_recall_status = 1
      //nếu không chọn lịch hẹn gọi lại thì search thêm property time_recall_status = 0
      const isScheduleCallBack = search.call_status_id.includes(
        OPTION_SCHEDULE_CALL_BACK
      );

      if (isScheduleCallBack) {
        search.call_status_id = search.call_status_id.filter(
          el => el != OPTION_SCHEDULE_CALL_BACK
        );
        search.time_recall_status = TIME_RECALL_STATUS.APPROVE;
      } else {
        search.time_recall_status = TIME_RECALL_STATUS.NONE;
      }

      await requestExportCustomerInJob({
        ...search,
        job_id: id,
      });
    } catch (e) {
      console.log('download error', e);
    }
  };

  renderGeneral() {
    const jobStatistic = this.props.jobStatistic?.data;
    const { static_opportunities, opportunities_status, opportunities_cate } =
      this.state;
    const labels_status = [];
    const datasets_status = [];

    const labels_cate = [];
    const datasets_cate = [];
    const temp1 =
      opportunities_status?.length &&
      opportunities_status?.map(value => labels_status.push(value?.name));
    const temp2 =
      opportunities_status?.length &&
      opportunities_status?.map(value => datasets_status.push(value?.count));

    const temp3 =
      opportunities_cate?.length &&
      opportunities_cate?.map(value => labels_cate.push(value?.name));
    const temp4 =
      opportunities_cate?.length &&
      opportunities_cate?.map(value => datasets_cate.push(value?.count));
    return (
      <>
        <Row>{this.renderFieldGeneral()}</Row>
        <br></br>
        {jobStatistic?.length > 0 &&
          jobStatistic?.map((value, index) => (
            <>
              <Row>
                <Col md={12} className="pl-0">
                  <Row>
                    <Col md={6} className="pl-0 pr-0 pr-md-3">
                      <Row>
                        <label>Khách hàng</label>
                      </Row>
                      <Row>
                        <Table style={{ width: '100%' }}>
                          <tbody>
                            <tr>
                              <td>Đã giao việc</td>
                              <th>{value?.customers_assigned}</th>
                            </tr>

                            <tr>
                              <td>Đã nhận việc</td>
                              <th>{value?.customer_received}</th>
                            </tr>

                            <tr>
                              <td>Đã gọi</td>
                              <th>{value?.customers_called}</th>
                            </tr>

                            <tr>
                              <td>Từ chối</td>
                              <th>{value?.customers_rejected}</th>
                            </tr>
                          </tbody>
                        </Table>
                      </Row>
                    </Col>
                    <Col md={6} className="px-0">
                      <Row>
                        <label>Thống kê kết quả tư vấn</label>
                      </Row>

                      <Row>
                        <Table style={{ width: '100%' }}>
                          <tbody>
                            {value?.callStatus?.length > 0 &&
                              value?.callStatus?.map((item, index) => (
                                <tr key={index}>
                                  <td>{item?.name || '--'}</td>
                                  <th>{item?.count || '0'}</th>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="p-0">
                  <Row>
                    <label>Kết quả tư vấn</label>
                  </Row>
                  <Row>
                    {value?.formstatistic?.length > 0 &&
                      value?.formstatistic?.map((item, index) => (
                        <Col key={index} md={6}>
                          <p className="m-0">{item?.name}</p>
                          {item?.job_form_answers?.length > 0 &&
                            item?.job_form_answers?.map((item, index) => (
                              <li key={index} style={{ marginLeft: '10px' }}>
                                {item?.name}{' '}
                                <b style={{ color: 'red' }}>
                                  {item?.countResult}
                                </b>
                              </li>
                            ))}
                        </Col>
                      ))}
                  </Row>
                </Col>
              </Row>
            </>
          ))}
        <Row className="pt-4">
          <label>Cơ hội</label>
        </Row>
        <Row className="w-100">
          <div className="col-md-6">
            <div className="info-box mb-3 bg-primary text-light">
              <span className="info-box-icon">
                <i class="fab fa-salesforce icon-oppotunity"></i>
              </span>
              <div className="info-box-content">
                <span className="info-box-text">Doanh số</span>
                <span className="info-box-number">
                  {static_opportunities?.sales || '0'}
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="info-box mb-3 bg-success">
              <span className="info-box-icon">
                <i class="fas fa-money-bill-wave icon-oppotunity"></i>
              </span>
              <div className="info-box-content">
                <span className="info-box-text">Doanh thu</span>
                <span className="info-box-number">
                  {static_opportunities?.revenue || '0'}
                </span>
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <Col md={6}>
            <Doughnut
              data={{
                labels: labels_status,

                datasets: [
                  {
                    backgroundColor: [
                      '#23A0CB',
                      '#CF1322',
                      '#9e9e9e',
                      '#098A00',
                      '#FFB300',
                    ],

                    data: datasets_status,
                  },
                ],
              }}
            />
            <Row>
              <b style={{ margin: 'auto' }}>TRẠNG THÁI XỬ LÝ</b>
            </Row>
          </Col>
          <Col md={6}>
            <Doughnut
              data={{
                labels: labels_cate,

                datasets: [
                  {
                    backgroundColor: [
                      '#23A0CB',
                      '#CF1322',
                      '#9e9e9e',
                      '#098A00',
                      '#FFB300',
                    ],

                    data: datasets_cate,
                  },
                ],
              }}
            />
            <Row>
              <b style={{ margin: 'auto' }}>LOẠI CƠ HỘI</b>
            </Row>
          </Col>
        </Row>
        <Row className="pt-4">
          <label>Biểu đồ số cuộc gọi trong ngày</label>
        </Row>
        <Row>
          <Line
            Option={{
              responsive: true,
              fill: false,
              lineTension: true,
            }}
            data={this.state.datachart}
          />
        </Row>
      </>
    );
  }
  renderPaginationDetail() {
    const { paging } = this.state.callHistory;
    return (
      <Pagination
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled
        activePage={paging.page}
        itemsCountPerPage={paging.limit}
        totalItemsCount={paging.totalItemCount}
        pageRangeDisplayed={5}
        hideNavigation
        onChange={page => {
          const { callHistory } = this.state;

          this.setState(
            {
              callHistory: {
                ...callHistory,
                paging: {
                  ...callHistory.paging,
                  page,
                },
              },
            },
            this.getCallHistoryVer2
          );
        }}
      />
    );
  }

  renderDetail() {
    // const callHistory = this.props.callHistoryState?.data;
    const { callHistory } = this.state;
    const { paging } = this.props.callHistoryState;
    return (
      <>
        <Row>
          <Col>{this.renderFieldDetail()}</Col>
        </Row>

        <br></br>

        <Row className="pb-2">
          <Col xs={12} className="px-0">
            <h4>Danh sách cuộc gọi</h4>
          </Col>
        </Row>

        <b>Kết quả lọc: {callHistory.paging.totalItemCount}</b>

        {callHistory.data.length ? (
          callHistory.data.map(call => (
            <Row
              className="content-header assessment"
              style={{
                padding: '30px 40px',
                backgroundColor: '#FFFFFE',
                borderRadius: '8px',
                boxShadow: '0 20px 80px rgb(0 0 0 / 26%)',
              }}
              key={call.id}
              onClick={() => {
                this.props.history.push({
                  pathname: `${ROUTER.CALL_DETAIL}/${call.id}`,
                });
              }}
            >
              <Col md={12}>
                <Row>
                  <span>
                    <img
                      src={require('../../../assets/telesale-icon.png')}
                      style={{ marginRight: '14px', marginLeft: '12px' }}
                    />
                  </span>

                  <span>
                    <CustomerNameStyled
                      className="m-0"
                      onClick={event => {
                        event.stopPropagation();

                        this.props.history.push({
                          pathname: `${ROUTER.CUSTOMER_DETAIL}/${call.customer_id}`,
                        });
                      }}
                    >
                      {call?.customer_name}
                    </CustomerNameStyled>

                    <div>
                      <span>
                        <i
                          class="fal fa-phone-alt"
                          style={{ marginRight: '8px' }}
                        ></i>
                      </span>

                      <span>{call?.customer_phone}</span>
                    </div>
                  </span>
                </Row>

                <Row>
                  <Col md={6} style={{ marginTop: '30px' }}>
                    <Row style={{ color: '#7F8FA4' }}>Công việc</Row>

                    <Row>{call?.job_name || ''}</Row>

                    <Row style={{ marginTop: '16px' }}>
                      <Col md={6} className="p-0">
                        <Row style={{ color: '#7F8FA4' }}>Ngày</Row>

                        <Row>
                          <span>
                            {call?.start_call_time
                              ? moment(call?.start_call_time).format(
                                  ' HH:mm DD-MM-YYYY'
                                )
                              : '--'}
                          </span>
                        </Row>
                      </Col>

                      <Col md={6} className="p-0">
                        <Row style={{ color: '#7F8FA4' }}>Thời gian gọi</Row>

                        <Row>
                          {(call?.call_duration &&
                            `${call?.call_duration}  giây`) ||
                            '--'}
                        </Row>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: '15px' }}>
                      <audio controls className="audio-player">
                        <source src={call?.content_audio_url} />
                        Your browser does not support the audio element.
                      </audio>
                    </Row>
                  </Col>

                  <Col md={3} style={{ marginTop: '30px' }}>
                    <Row style={{ color: '#7F8FA4' }}>Telesale</Row>

                    <Row>{call?.sale_name || ''}</Row>
                  </Col>

                  <Col md={3} style={{ marginTop: '30px' }}>
                    <Row style={{ color: '#7F8FA4' }}>Trạng thái</Row>

                    <Row className="waiting">{call?.status_name || ''}</Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          ))
        ) : (
          <Empty description={<span>Không có dữ liệu</span>} />
        )}
        <Row>
          <Col
            md={12}
            className="d-flex justify-content-center justify-content-md-end p-0"
          >
            {this.renderPaginationDetail()}
          </Col>
        </Row>
      </>
    );
  }

  renderGroup(jobInfo) {
    const listGroup = jobInfo?.subJob
      ? jobInfo?.subJob.map(value => value.group_name)
      : [];
    if (jobInfo?.group) {
      return (
        <Row>
          <Col md={12}>
            <b>{jobInfo?.group?.name || '--'}</b>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col md={12}>
            <span>Nhóm khách hàng: </span>
            {listGroup &&
              listGroup.map((value, index) => (
                <Row key={index}>
                  <Col md={12}>
                    <b> - {value || '--'}</b>
                  </Col>
                </Row>
              ))}
          </Col>
        </Row>
      );
    }
  }

  renderUserList(push) {
    const { searchUser, listStatus, listCustomer, jobInfo, paging } =
      this.state;
    var pathName = '/' + window.location.pathname.split('/')[1];
    if (pathName === '/') pathName = ROUTER.OVERVIEW_SALE;
    return (
      <>
        {/* {this.renderFieldList()} */}
        <CustomersFilter
          isSubJob={this.isSubJob}
          jobDetail={this.state.jobInfo}
          params={this.state.searchUser}
          handleChangeSearchParams={data => {
            const { searchUser } = this.state;

            this.setState(
              {
                searchUser: { ...searchUser, ...data },
                paging: {
                  ...paging,
                  page: 1,
                },
              },
              this.getListCustomer
            );
          }}
        />

        <br></br>
        <Row>
          <h4>Nhóm khách hàng</h4>
        </Row>
        {this.renderGroup(jobInfo)}
        <br></br>
        <Row>
          <Col>
            <h4>Danh sách khách hàng</h4>
          </Col>
          <Col md={2} xs={6} className="d-flex justify-content-end">
            {jobInfo.group_id && (
              <Button
                variant="success"
                className="text-light"
                style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                onClick={this.downloadExcelFile}
              >
                Export khách hàng
              </Button>
            )}
          </Col>
        </Row>

        <b>Kết quả lọc: {paging.totalItemCount}</b>

        <br></br>
        <Row className="table-wrap">
          {this.state.isLoadingTableDataCustomer ? (
            <Skeleton active />
          ) : (
            <div className="w-100">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>STT</th>
                    <th>Họ tên</th>

                    <th>Trạng thái cuộc gọi</th>
                    <th>Tương tác gần nhất</th>

                    <th>Phân loại</th>
                    <th>Số điện thoại</th>
                    <th>Sale chăm sóc</th>
                    <th>Thời gian</th>
                    <th>Trạng thái</th>
                  </tr>
                </thead>
                <tbody>
                  {listCustomer.length > 0 ? (
                    listCustomer?.map((value, index) => {
                      const ListNameTag = value?.customer_tags.map(item => (
                        <>
                          {item?.tag_name}
                          <br />
                        </>
                      ));
                      return (
                        <tr key={index}>
                          <td>
                            {(this.state?.paging?.page - 1) *
                              this.state?.paging?.limit +
                              index +
                              1 || '--'}
                          </td>
                          <td>
                            <div
                              onClick={() =>
                                this.props.history.push(
                                  ROUTER.CUSTOMER_DETAIL + `/${value?.id}`
                                )
                              }
                            >
                              {value.name || '--'}
                            </div>
                          </td>

                          <td>{value.df_call_status_name || '--'}</td>
                          <td>{value.action_name || '--'}</td>

                          <td>
                            {value?.customer_tags.length > 1 ? (
                              <>
                                <Tooltip
                                  title={ListNameTag.slice(
                                    1,
                                    value?.customer_tags.length
                                  )}
                                >
                                  {value?.customer_tags[0]?.tag_name ? (
                                    <TagCustom
                                      tagName={
                                        value?.customer_tags[0]?.tag_name
                                          ? value?.customer_tags[0]?.tag_name
                                          : '---'
                                      }
                                      tagColor={
                                        value?.customer_tags[0]?.tag_color
                                      }
                                    />
                                  ) : (
                                    '---'
                                  )}
                                  {value?.customer_tags.length > 1 ? (
                                    <TagCustom
                                      tagName={
                                        '+' +
                                        `${value?.customer_tags.length - 1}`
                                      }
                                    />
                                  ) : (
                                    ''
                                  )}
                                </Tooltip>
                              </>
                            ) : (
                              <>
                                {value?.customer_tags[0]?.tag_name ? (
                                  <TagCustom
                                    tagName={
                                      value?.customer_tags[0]?.tag_name
                                        ? value?.customer_tags[0]?.tag_name
                                        : '---'
                                    }
                                    tagColor={
                                      value?.customer_tags[0]?.tag_color
                                    }
                                  />
                                ) : (
                                  '---'
                                )}
                                {value?.customer_tags.length > 1 ? (
                                  <TagCustom
                                    tagName={
                                      '+' + `${value?.customer_tags.length - 1}`
                                    }
                                  />
                                ) : (
                                  ''
                                )}
                              </>
                            )}
                          </td>
                          <td>{value.phone || '--'}</td>
                          <td>
                            <Avatar.Group
                              maxCount={2}
                              maxStyle={{
                                color: '#f56a00',
                                backgroundColor: '#fde3cf',
                              }}
                            >
                              {value.job_telesale_customers.map(
                                (sale, index) => {
                                  if (
                                    sale.telesale_name !== null &&
                                    sale.profile_image !== null
                                  ) {
                                    const name = sale.telesale_name
                                      .trim()
                                      .split(' ');
                                    return (
                                      <Tooltip
                                        key={index}
                                        title={sale.telesale_name}
                                        placement="bottom"
                                      >
                                        <Avatar
                                          src={
                                            sale.profile_image &&
                                            sale.profile_image
                                          }
                                          style={
                                            index >= 7
                                              ? { display: 'none' }
                                              : {
                                                  backgroundColor: randomColor(
                                                    name[name.length - 1]
                                                      .charAt(0)
                                                      ?.toUpperCase()
                                                  ),
                                                }
                                          }
                                        >
                                          {sale.profile_image
                                            ? ''
                                            : name[name.length - 1]
                                                .charAt(0)
                                                ?.toUpperCase()}
                                        </Avatar>
                                      </Tooltip>
                                    );
                                  } else {
                                    return (
                                      <Avatar
                                        key={index}
                                        style={{ backgroundColor: '#87d068' }}
                                        icon={<UserOutlined />}
                                      />
                                    );
                                  }
                                }
                              )}
                            </Avatar.Group>
                          </td>
                          <td>
                            {value.created_at
                              ? moment(value.created_at).format(
                                  'hh:mm DD-MM-YYYY'
                                )
                              : '--'}
                          </td>
                          <td>{value.status_name || '--'}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colspan="8">
                        <Empty description={<span>Không có dữ liệu</span>} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <div
                style={{
                  marginTop: '10px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flex: 1,
                  width: '100%',
                }}
              >
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  hideDisabled
                  activePage={Number(this.state?.paging?.page)}
                  itemsCountPerPage={this.state?.paging?.limit}
                  totalItemsCount={this.state?.paging?.totalItemCount || 0}
                  pageRangeDisplayed={5}
                  hideNavigation
                  onChange={page => {
                    try {
                      this.setState({
                        paging: {
                          ...this.state?.paging,
                          page,
                        },
                      });
                    } catch (error) {}
                  }}
                />
              </div>
            </div>
          )}
        </Row>
      </>
    );
  }

  renderTelesaleList() {
    const { status, listTelesale, listStatus } = this.state;
    return (
      <>
        <Row>
          <Col>
            <h4>Danh sách telesale</h4>
          </Col>
          <Col md={2} xs={6} className="d-flex justify-content-end p-0">
            <FormControl
              as="select"
              id=""
              className=""
              value={status}
              onChange={e => this.handleChangeStatus('status', e.target.value)}
            >
              <option value="">Trạng thái</option>
              {listStatus.map(value => (
                <option key={value.id} value={value.id}>
                  {value.name}
                </option>
              ))}
            </FormControl>
          </Col>
        </Row>
        <br></br>
        <Row className="table-wrap">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>STT</th>
                <th>Họ tên</th>
                <th>Số điện thoại</th>
                <th>Địa chỉ</th>
                <th>Trạng thái công việc</th>
                <th>Công việc</th>
                <th>Khách hàng cần chăm sóc</th>
                <th>Đã chăm sóc</th>
              </tr>
            </thead>
            <tbody>
              {listTelesale.length > 0 ? (
                listTelesale.map((value, index) => (
                  <tr key={index}>
                    <td>{index + 1 || '--'}</td>
                    <td>{value.full_name || '--'}</td>
                    <td>{value?.phone || '--'}</td>
                    <td>{value?.address || '--'}</td>
                    <td>{value.name || '--'}</td>
                    <td>{value.job_name || '--'}</td>
                    <td>{value.totalSale}</td>
                    <td>{value.called}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan="8">
                    <Empty description={<span>Không có dữ liệu</span>} />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Row>
      </>
    );
  }

  renderEvaluate() {
    return (
      <>
        <Row>
          <b style={{ fontSize: '1.5rem', fontWeight: '500' }}>
            Đánh giá cuộc gọi
          </b>
        </Row>
        {/* <Row className="mt-3">
          <Col md={3}>
            <span className="mr-1">Cần đánh giá: </span>
            <span>
              <b>500</b>
            </span>
          </Col>
          <Col md={3}>
            <span className="mr-1">Đã đánh giá:</span>
            <span>
              <b>250</b>
            </span>
          </Col>
          <Col md={3}>
            <span className="mr-1">Số nhân viên đánh giá: </span>
            <span>
              <b>2</b>
            </span>
          </Col>
          <Col md={3}></Col>
        </Row> */}
        <Row className="mt-3 ml-3">
          {this.state.evaluate.data?.review_config?.length > 0 &&
            this.state.evaluate.data?.review_config?.map((item, index) => (
              <Col key={index}>
                <span className="mr-1">{item.name}:</span>
                <span>
                  <b>{item.count}</b>
                </span>
              </Col>
            ))}
        </Row>

        <Row className="mt-3">{this.renderTable()}</Row>
        <Row>
          <Col md={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {this.renderPagination()}
          </Col>
        </Row>
      </>
    );
  }

  renderTable() {
    return (
      <table
        id="example2"
        className="table table-bordered table-striped table-responsive-sm table-responsive-md"
      >
        <thead className="text-center bg-thead">
          <tr>
            <th>STT</th>
            <th>Nhân viên đánh giá</th>
            <th>Công việc</th>
            <th>Sale</th>
            <th>Trạng thái cuộc gọi</th>
            <th>Khách hàng có nhu cầu</th>
            <th>Thời gian</th>
            <th>Số lượng</th>
            <th>Đã hoàn thành</th>
            <th>Nhận xét</th>
          </tr>
        </thead>
        {this.renderTableData()}
      </table>
    );
  }

  renderTableData() {
    const { evaluate } = this.state;
    return (
      <tbody>
        {evaluate.data?.list_task?.length > 0 ? (
          evaluate.data?.list_task?.map((value, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{value?.full_name || '--'}</td>
              <td>{value.jobs[0]?.name || '--'}</td>
              <td>{value.telesales[0]?.name || '--'}</td>
              <td>{value.call_status[0]?.name || '--'}</td>
              <td>{value.status == 1 ? 'Có' : 'Không' || '--'}</td>
              <td>
                {toDateString(value.start_date)}-
                {toDateString(value.end_date) || '--'}
              </td>
              <td>{value.amount || '--'}</td>
              <td>{value.called}</td>
              <td>
                {value.review_configs?.map((item, index) => (
                  <div key={index}>
                    <span className="mr-1">{item.name}:</span>
                    <span>
                      <b>{item.count}</b>
                    </span>
                  </div>
                ))}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colspan="10">
              <Empty description={<span>Không có dữ liệu</span>} />
            </td>
          </tr>
        )}
      </tbody>
    );
  }

  renderPagination() {
    const itemsCount = this.state.evaluate?.paging?.count;
    const limit = this.state.evaluate?.paging?.limit;
    // const {
    //   telesale_id,
    //   job_id,
    //   status,
    //   opportunity_category_id,
    //   fromDate,
    //   toDate,
    //   activePage,
    // } = this.state;
    return (
      <Pagination
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled
        activePage={this.state.activePage}
        itemsCountPerPage={limit}
        totalItemsCount={itemsCount}
        pageRangeDisplayed={5}
        hideNavigation
        onChange={page => {
          this.getEvaluate();
          this.setState({
            ...this.state,
            activePage: page,
          });
        }}
      />
    );
  }

  showTab(tab) {
    this.setState({
      ...this.state,
      tab: tab,
    });
  }

  renderFieldGeneral() {
    const { search } = this.state;
    const { [STRING.fromDate]: fromDate, [STRING.toDate]: toDate } =
      this.state.search;
    const job = this.props.subJobState?.data;
    const { url } = this.props.match;
    const find = url.search(ROUTER.JOB_RESULT_CHILD);
    return (
      <div className="w-100">
        <Row>
          {find == -1 ? (
            <Col md={4} sm={12} className="pl-3 pl-md-0">
              <FormControl
                as="select"
                id=""
                value={search.job_id}
                onChange={e => this.searchBar('job_id', e.target.value)}
              >
                <option value="">Công việc</option>
                {job?.length > 0 &&
                  job?.map(value => (
                    <option key={value.id} value={value.id}>
                      {value.name}
                    </option>
                  ))}
              </FormControl>
            </Col>
          ) : (
            ''
          )}
          <Col md={4} sm={12} className="p-0">
            <DatePickerCustom
              className={`date-picker form-control`}
              placeholderText={STRING.fromDate}
              handleChange={this.searchBar}
              selected={fromDate}
              maxDate={toDate ? new Date(toDate) : Date.now()}
              dateFormat="dd/MM/yyyy"
            />
          </Col>
          <Col md={4} sm={12}>
            <DatePickerCustom
              className={`date-picker form-control`}
              placeholderText={STRING.toDate}
              handleChange={this.searchBar}
              selected={toDate}
              minDate={new Date(fromDate)}
              dateFormat="dd/MM/yyyy"
            />
          </Col>
        </Row>
      </div>
    );
  }

  renderFieldDetail() {
    const { searchBar } = this.state;
    const callStatus = this.props.statusState.data;
    const job = this.props.jobState?.data;
    const review = this.props.reviewState.data;
    const { url } = this.props.match;
    const find = url.search(ROUTER.JOB_RESULT_CHILD);
    return (
      <div className="w-100">
        <Row>
          <Col md sm={12} className="pl-3 pl-md-0">
            <FormControl
              as="select"
              id=""
              value={searchBar.call_status}
              onChange={e =>
                this.searchBarChange('call_status', e.target.value)
              }
            >
              <option value="">Trạng thái</option>
              {callStatus.length > 0 &&
                callStatus?.map((value, index) => (
                  <option value={value.id}>{value.name}</option>
                ))}
            </FormControl>
          </Col>
          {find == -1 ? (
            <Col md sm={12} className="pl-3 pl-md-0">
              <FormControl
                as="select"
                id=""
                value={searchBar.job_id}
                onChange={e => this.searchBarChange('job_id', e.target.value)}
              >
                <option value="">Công việc</option>
                {job?.length > 0 &&
                  job?.map((value, index) => (
                    <option value={value.id}>{value.name}</option>
                  ))}
              </FormControl>
            </Col>
          ) : (
            ''
          )}

          <Col md sm={12} className="pl-3 pl-md-0">
            <FormControl
              as="select"
              id=""
              value={searchBar.job_form_question_id}
              onChange={e =>
                this.searchBarChange('job_form_question_id', e.target.value)
              }
            >
              <option value="">Câu hỏi</option>
              {this.state.questionsAndAnswer?.length > 0 &&
                this.state.questionsAndAnswer?.map((value, index) => (
                  <option value={value.id}>{value.name}</option>
                ))}
            </FormControl>
          </Col>
          <Col md sm={12} className="pl-3 pl-md-0">
            <FormControl
              as="select"
              id=""
              value={searchBar.job_form_answers_id}
              onChange={e =>
                this.searchBarChange('job_form_answers_id', e.target.value)
              }
            >
              <option value="">Kết quả</option>
              {searchBar?.job_form_question_id != ''
                ? this.state.questionsAndAnswer.length > 0 &&
                  this.state.questionsAndAnswer?.map((value, index) =>
                    value.id == searchBar?.job_form_question_id
                      ? value?.job_form_answers?.length > 0 &&
                        value?.job_form_answers?.map((item, index) => (
                          <option value={item.id}>{item.name}</option>
                        ))
                      : ''
                  )
                : ''}
            </FormControl>
          </Col>
          <Col md sm={12} className="pl-3 pl-md-0">
            <FormControl
              as="select"
              id=""
              value={searchBar.call_review_config_id}
              onChange={e =>
                this.searchBarChange('call_review_config_id', e.target.value)
              }
            >
              <option value="">Đánh giá</option>
              {review?.length > 0 &&
                review?.map((value, index) => (
                  <option value={value.id}>{value.name}</option>
                ))}
            </FormControl>
          </Col>
        </Row>
      </div>
    );
  }

  renderFieldList() {
    const { searchUser, tab, listStatus } = this.state;
    const { [STRING.fromDate]: fromDate, [STRING.toDate]: toDate } =
      this.state.searchUser;
    const job = this.props.subJobState?.data;
    const { url } = this.props.match;
    const find = url.search(ROUTER.JOB_RESULT_CHILD);
    const { id } = this.props.match.params;
    return (
      <div className="w-100">
        <Row>
          {find == -1 ? (
            <Col md={4} sm={12} className="pl-3 pl-md-0">
              <FormControl
                as="select"
                id=""
                value={searchUser?.job_id}
                onChange={e => this.searchUser('job_id', e.target.value)}
              >
                <option value={id}>Công việc</option>
                {job?.length > 0 &&
                  job?.map(value => (
                    <option value={value.id}>{value.name}</option>
                  ))}
              </FormControl>
            </Col>
          ) : (
            ''
          )}
          {tab == '3' ? (
            <Col md={4} sm={12} className="pl-3 pl-md-0">
              <FormControl
                as="select"
                id=""
                className=""
                value={searchUser?.status}
                onChange={e => this.searchUser('status', e.target.value)}
              >
                <option value="">Trạng thái</option>
                {listStatus.map(value => (
                  <option value={value.id}>{value.name}</option>
                ))}
              </FormControl>
            </Col>
          ) : (
            ''
          )}
          {tab != '3' ? (
            <>
              <Col md={4} sm={12}>
                <DatePickerCustom
                  className={`date-picker form-control`}
                  placeholderText={STRING.fromDate}
                  handleChange={this.searchUser}
                  selected={fromDate}
                  maxDate={new Date(toDate)}
                  dateFormat="dd/MM/yyyy"
                />
              </Col>
              <Col md={4} sm={12}>
                <DatePickerCustom
                  className={`date-picker form-control`}
                  placeholderText={STRING.toDate}
                  handleChange={this.searchUser}
                  selected={toDate}
                  minDate={new Date(fromDate)}
                  dateFormat="dd/MM/yyyy"
                />
              </Col>
            </>
          ) : (
            ''
          )}
        </Row>
      </div>
    );
  }

  handleChange(field, value) {
    this.setState({
      ...this.state,
      [field]: value,
    });
  }

  handleChangeStatus(field, value) {
    const { status } = this.state;
    const { id } = this.props.match.params;
    this.setState(
      {
        ...this.state,
        [field]: value,
      },
      () => this.getListTelesale(id, value)
    );
  }

  searchBarChange(field, value) {
    const { callHistory, searchBar } = this.state;
    this.setState(
      {
        ...this.state,
        searchBar: {
          ...searchBar,
          [field]: value,
        },
        callHistory: {
          ...callHistory,
          paging: {
            ...callHistory.paging,
            page: 1,
          },
        },
      },
      this.getCallHistoryVer2
    );
  }

  searchBar(field, value) {
    this.setState(
      {
        ...this.state,
        search: {
          ...this.state.search,
          [field]: value,
        },
      },
      () => {
        if (field !== '') {
          const {
            job_id,
            [STRING.fromDate]: fromDate,
            [STRING.toDate]: toDate,
          } = this.state.search;
          const { id } = this.props.match.params;
          this.props.getJobStatistic({
            job_id: job_id || id,
            fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : '',
            toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : '',
          });
          this.getJobChart();
          job_id
            ? this.requestStatisticByJob(job_id)
            : this.requestStatisticByJob(id);
        }
      }
    );
  }

  searchUser(field, value) {
    this.setState(
      {
        ...this.state,
        searchUser: {
          ...this.state.searchUser,
          [field]: value,
        },
      },
      () => {
        if (field !== '') {
          const {
            status,
            job_id,
            [STRING.fromDate]: fromDate,
            [STRING.toDate]: toDate,
          } = this.state.searchUser;
          const { id } = this.props.match.params;
          // requestCustomerJobList({
          //   group_id: '',
          //   page: '',
          //   status: status || '',
          //   job_id: job_id || id,
          //   fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : '',
          //   toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : '',
          // });
          this.getListCustomer();
        }
      }
    );
  }

  render() {
    const {
      [STRING.fromDate]: fromDate,
      [STRING.toDate]: toDate,
      job_id,
    } = this.state.search;
    const { isLoading } = this.props.callHistoryState;
    let loading = <Loading />;
    if (!isLoading) {
      loading = null;
    }
    return (
      <>
        {/* <Row className="d-flex justify-content-center justify-content-md-end">{loading}</Row> */}
        {loading}
        {this.renderBody()}
      </>
    );
  }
}

const mapStateToProps = state => ({
  callHistoryState: state.callHistoryReducer,
  statusState: state.callStatusReducer,
  jobState: state.jobFilterReducer,
  reviewState: state.reviewReducer,
  quessionsAndAnswerState: state.quessionsAndAnswerReducer,
  subJobState: state.jobFilterReducer,
  jobStatistic: state.jobStatisticReducer,
});

const mapDispatchToProps = {
  getCallHistory,
  getListCallStatus,
  getJobFilter,
  getReviewList,
  getQuessionsAndAnswerFilter,
  getJobFilter,
  getJobStatistic,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Result));
