import { message } from 'antd'

export const errorMinigame= (checked, selected) =>{
    let passCheck = true
      if(checked && !selected) {
        message.warning('Vui lòng chọn link minigame');
        passCheck = false
      }
      return passCheck
  }

