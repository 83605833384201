import React, { Component } from 'react';
import Select from 'react-select';
import { customStyles } from '@utils/styleReactSelect';

export class ReactSelect extends Component {
  render() {
    const { list, handleChangeSelect, placeholder, value, hasValue } = this.props;
    return hasValue ? (
      <Select
        placeholder={placeholder}
        options={list}
        onChange={handleChangeSelect}
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#bdbdbd',
            primary: '#bdbdbd',
          },
        })}
        value={value}
      />
    ) : (
      <Select
        placeholder={placeholder}
        options={list}
        onChange={handleChangeSelect}
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#bdbdbd',
            primary: '#bdbdbd',
          },
        })}
      />
    );
  }
}
