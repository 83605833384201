import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import '../../../styles/Job.css';
import { Row, Col, Button } from 'react-bootstrap';
import Loading from '@src/components/Loading';
import { requestGetJobDetail, requestSubmitExam } from '../../../network/web_sale/JobApi';
import { notifyFail, notifySuccess } from '@utils/notify';
import { Empty, Radio } from 'antd';

const TestBoard = (props) => {
  return (
    <Row style={{}}>
      <Col>
        <Row>
          <Col md={5} sm={12}>
            <h4 className="text-left" style={{ marginBottom: '10px' }}>
              Danh sách câu hỏi
            </h4>
          </Col>
        </Row>
        <Row>
          {props?.data?.job_exam_questions?.length ? (
            props?.data?.job_exam_questions.map((item, index) => {
              return (
                <Col key={item.id} md={12} sm={12} className="mb-3">
                  <div
                    className="d-flex justify-content-start p-3"
                    style={{ border: '1px solid #D5D8DC', borderRadius: '0.3rem' }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div className="d-flex justify-content-start mb-2">
                        Câu {index + 1}:<label className="ml-2">{item.name}</label>
                      </div>
                      <Radio.Group>
                        {item.job_exam_answers.map((answer) => {
                          return (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <Radio
                                key={answer.id}
                                value={answer.id}
                                onChange={(e) => {
                                  props.onSelectAnswer(item.id, answer.id);
                                }}
                              >
                                {answer.name}
                              </Radio>
                            </div>
                          );
                        })}
                      </Radio.Group>
                    </div>
                  </div>
                </Col>
              );
            })
          ) : (
            <Col md={12} sm={12} className="mb-3">
              <Empty description="Chưa có câu hỏi nào" />
            </Col>
          )}
        </Row>
        {props.data.job_exam_questions?.length ? (
          <Row>
            <Col md={12} sm={12}>
              <Button variant="primary" className="text-light float-right" onClick={() => props.onSubmitExam()}>
                Hoàn thành
              </Button>
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
};

function Test(props) {
  const { id } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  // const [jobDetail, setJobDetail] = useState({});
  const [answerList, setAnswerList] = useState([]);
  // useEffect(() => {
  //   getDetail();
  // }, []);

  // const getDetail = async () => {
  //   try {
  //     setIsLoading(true);
  //     const res = await requestGetJobDetail({
  //       id: id,
  //     });
  //     setJobDetail({ ...res.data });
  //     setIsLoading(false);
  //   } catch (error) {
  //     setIsLoading(false);
  //   }
  // };

  const handleSelectAnswer = (questId, answerId) => {
    const newAnswers = [...answerList];
    const index = newAnswers.findIndex((item) => item.id === questId);
    if (newAnswers.length === 0 || index === -1) {
      newAnswers.push({
        id: questId,
        job_exam_answers: [answerId],
      });
      setAnswerList(newAnswers);
    } else {
      const newArr = newAnswers.map((item) => {
        if (item.id === questId) {
          return {
            ...item,
            job_exam_answers: [answerId],
          };
        } else {
          return item;
        }
      });
      setAnswerList(newArr);
    }
  };

  const handleSubmitExam = async () => {
    try {
      if (answerList.length < props.data.job_exam_questions.length) {
        notifyFail('Vui lòng trả lời đủ các câu hỏi đưa ra!');
        return;
      }
      const payload = {
        job_id: id,
        job_exam_questions: answerList,
      };
      const res = await requestSubmitExam(payload);
      notifySuccess('Bạn đã vượt qua bài thi!');
      // history.goBack();
      props.getDetail();
    } catch (error) {
      console.log('error', error);
      notifyFail(error.msg);
    }
  };


  return (
    <>
      {isLoading && <Loading />}
      <TestBoard data={props.data} onSelectAnswer={handleSelectAnswer} onSubmitExam={() => handleSubmitExam()} />
    </>
  );
}

export default Test;
