import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '@styles/Company.css';
import {
  Row,
  Col,
  FormControl,
  Button,
  FormGroup,
  Form,
} from 'react-bootstrap';
// import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  ACCEPT_TYPE,
  TYPE_MEDIA,
  PHONE_REGEX,
  EMAIL_REGEX,
} from '@constants/Constant';
import { createFormData } from '@utils/createFormData';
import { renderSelection } from '@utils/renderSelection';
import { checkNormalizePhone } from '@utils/stringUtils';
import { connect } from 'react-redux';
import { getProvinceList } from '@src/redux/actions';
import { requestSaleDetail, requestUpdateTelesale } from '@constants/Api';
import Loading from '@src/components/Loading';
import { notifyFail, notifySuccess } from '@utils/notify';
import { DatePicker, Space } from 'antd';
import moment from 'moment';

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        full_name: '',
        email: '',
        user_name: '',
        gender: '',
        id_number: '',
        province_id: '',
        dob: '',
        address: ' ',
        work_experience: '',
        password: '',
        repassword: '',
        profile: '',
        front_id: '',
        back_id: '',
        doc_url: '',
        is_active: '',
        work_type: '',
      },
      profile: '',
      front_id: '',
      back_id: '',
      titleUploader: '',
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData(payload) {
    this.props.getProvinceList();
    this.getSaleDetail();
  }

  async getSaleDetail() {
    this.setState({ isLoading: true });
    const { id } = this.props.match.params;
    const res = await requestSaleDetail({ id: id });
    const data = res.data;
    let profile = '',
      front_id = '',
      back_id = '';
    if (data) {
      data.user_sale_media.forEach(media => {
        if (media.type === TYPE_MEDIA.PROFILE_IMAGE) profile = media.url;
        if (media.type === TYPE_MEDIA.BACK_IMAGE) back_id = media.url;
        if (media.type === TYPE_MEDIA.FRONT_IMAGE) front_id = media.url;
      });
      await this.setState({
        form: {
          full_name: data.full_name,
          email: data.email,
          user_name: data.phone,
          gender: data.user_sale_info.gender,
          id_number: data.user_sale_info.id_number,
          province_id: data.user_sale_info.province_id,
          dob: data.user_sale_info.dob
            ? new Date(data.user_sale_info.dob)
            : null,
          address: data.user_sale_info.address,
          work_experience: data.user_sale_info.work_experience,
          work_type: data.user_sale_info.work_type,
          profile: '',
          front_id: '',
          back_id: '',
          doc: data.user_sale_info.cv_url,
          user_id: id,
          is_active: data.is_active,
        },
        isLoading: false,
        profile: data.user_sale_info.profile_image,
        front_id: front_id,
        back_id: back_id,
        titleUploader: data.user_sale_info.cv_url,
      });
    }
  }

  update() {
    this.setState({ isLoading: true });

    const { form } = this.state;

    const formdata = createFormData({
      ...form,
      full_name: form.full_name.trim(),
    });
    const check = this.validateForm();
    if (check) {
      const res = requestUpdateTelesale(formdata)
        .then(res => {
          this.setState({ isLoading: false });
          this.props.history.goBack();
          notifySuccess(res.msg);
        })
        .catch(error => {
          this.setState({ isLoading: false });
          notifyFail(error.msg);
        });
    } else {
      this.setState({ isLoading: false });
    }
  }

  validateForm() {
    const { form } = this.state;
    console.log(form);
    if (EMAIL_REGEX.exec(form.email) === null) {
      notifyFail('Email không hợp lệ');
      return false;
    }
    if (!form.full_name || !form.email || !form.user_name) {
      notifyFail('Vui lòng nhập đầy đủ thông tin bắt buộc');
      return false;
    }

    if (checkNormalizePhone(form.user_name)) {
      notifyFail('Số điện thoại không hợp lệ');
      return false;
    }
    if (
      !form.id_number ||
      (form.id_number.length &&
        form.id_number.length != 9 &&
        form.id_number.length != 12)
    ) {
      notifyFail('Số CMND không hợp lệ (bao gồm 9 hoặc 12 số)');
      return false;
    }
    return true;
  }

  handleChangeImage = (type, event) => {
    if (!ACCEPT_TYPE.IMAGE.includes(event.target.files[0].type)) {
      alert('Định dạng ảnh không được hỗ trợ. Vui lòng chọn ảnh khác.');
      return;
    }
    this.setState({
      form: {
        ...this.state.form,
        [type]: event.target.files[0],
      },
      [type]: (window.URL || window.webkitURL).createObjectURL(
        event.target.files[0]
      ),
    });
  };

  handleInputChange = (field, value) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [field]: value || '',
      },
    });
  };

  handleFileUploader(event) {
    if (
      event.target.files[0] &&
      !ACCEPT_TYPE.DOCUMENT.includes(event.target.files[0]?.type)
    ) {
      // alert('Định dạng không được hỗ trợ. Vui lòng chọn file khác.');
      return;
    }
    this.setState({
      titleUploader: event.target.files[0].name,
      form: {
        ...this.state.form,
        doc: event.target.files[0],
      },
    });
  }

  renderBody() {
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <Row
            className="mt-1"
            style={{
              padding: '0px 20px',
              borderRadius: '8px',
              marginBottom: 30,
            }}
          >
            <Col md className="bg">
              <Row>
                <h2> Cập nhật thông tin sale </h2>
              </Row>
              {/* <div className="line"></div> */}
              <Row>{this.renderField()}</Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderButton() {
    return (
      <div className="text-center text-md-right">
        <Button
          variant="success"
          className="text-light"
          onClick={() => this.update()}
        >
          Lưu thay đổi
        </Button>
      </div>
    );
  }

  // renderField() {
  //   const { form } = this.state;
  //   return (
  //     <div className="w-100">
  //       <Row>
  //         <Col md={4} className="">
  //           <Row>{this.renderPicUploader()}</Row>
  //         </Col>
  //         <Col md={8}>
  //           <Row>
  //             <label>Thông tin cá nhân</label>
  //           </Row>
  //           <FormGroup className="add-company-form">
  //             <Row>
  //               <Col md={6} className="tele-input-col">
  //                 <label htmlFor="name">Họ và tên(*)</label>
  //                 <FormControl
  //                   type="text"
  //                   name="full_name"
  //                   placeholder="Nhập họ và tên"
  //                   value={form.full_name}
  //                   onChange={(e) => this.handleInputChange('full_name', e.target.value)}
  //                 ></FormControl>

  //                 <label htmlFor="phone">Số điện thoại(*)</label>
  //                 <FormControl
  //                   type="tel"
  //                   name="phone"
  //                   placeholder="Nhập số điện thoại"
  //                   value={form.user_name}
  //                   disabled
  //                 ></FormControl>

  //                 <br />
  //                 <label htmlFor="gender">Giới tính</label>
  //                 <Form.Check
  //                   inline
  //                   type="radio"
  //                   label="Nam"
  //                   name="gender"
  //                   className="radio-button offset-2 tele-radio"
  //                   value="2"
  //                   checked={form.gender == 2}
  //                   onChange={(e) => this.handleInputChange('gender', e.target.value)}
  //                 />
  //                 <Form.Check
  //                   inline
  //                   type="radio"
  //                   label="Nữ"
  //                   name="gender"
  //                   className="radio-button tele-radio"
  //                   value="1"
  //                   checked={form.gender == 1}
  //                   onChange={(e) => this.handleInputChange('gender', e.target.value)}
  //                 />
  //                 {/* <br />
  //                 <br /> */}

  //                 {/* <label htmlFor="cmnd">Số CMND(*)</label>
  //                 <FormControl
  //                   type="cmnd"
  //                   name="phone"
  //                   placeholder="Nhập số CMND"
  //                   value={form.id_number}
  //                   onChange={(e) => this.handleInputChange('id_number', e.target.value)}
  //                 ></FormControl> */}
  //               </Col>

  //               <Col md={6} className="tele-input-col">
  //                 <label htmlFor="email">Email(*)</label>
  //                 <FormControl
  //                   type="email"
  //                   name="email"
  //                   placeholder="Nhập email"
  //                   value={form.email}
  //                   onChange={(e) => this.handleInputChange('email', e.target.value)}
  //                 ></FormControl>

  //                 <label htmlFor="email">Ngày sinh</label>
  //                 <DatePicker
  //                   className="form-control"
  //                   selected={form && form.dob}
  //                   dateFormat="dd/MM/yyyy"
  //                   onChange={(date) => this.handleInputChange('dob', date)}
  //                 />

  //                 <label htmlFor="city">Tỉnh - Thành phố</label>
  //                 <FormControl
  //                   as="select"
  //                   id="provice"
  //                   value={form.province_id}
  //                   onChange={(e) => this.handleInputChange('province_id', e.target.value)}
  //                 >
  //                   <option value="" selected disabled>
  //                     Tỉnh/Thành phố
  //                   </option>
  //                   {renderSelection(this.props.provinceState.data)}
  //                 </FormControl>
  //               </Col>
  //             </Row>
  //             <Row>
  //               <Col xs={12} className="tele-input-col">
  //                 <label htmlFor="cmnd">Số CMND(*)</label>
  //                 <FormControl
  //                   type="cmnd"
  //                   name="phone"
  //                   placeholder="Nhập số CMND"
  //                   value={form.id_number}
  //                   onChange={(e) => this.handleInputChange('id_number', e.target.value)}
  //                 ></FormControl>
  //               </Col>
  //             </Row>
  //             <Row>
  //               <Col xs={12} className="tele-input-col">
  //                 <label htmlFor="address ">Địa chỉ cụ thể</label>
  //                 <FormControl
  //                   type="text"
  //                   name="address"
  //                   placeholder="Nhập địa chỉ cụ thể"
  //                   value={form.address}
  //                   onChange={(e) => this.handleInputChange('address', e.target.value)}
  //                 ></FormControl>
  //               </Col>
  //             </Row>
  //             <Row>
  //               <label className="big-title">Thông tin công việc</label>
  //             </Row>
  //             <Row>
  //               <Col md={6}>
  //                 <Row>
  //                   <label>Kinh nghiệm làm việc</label>
  //                 </Row>
  //                 <Row>
  //                   <textarea
  //                     className="form-control"
  //                     rows="6"
  //                     onChange={(e) => this.handleInputChange('word_experence', e.target.value)}
  //                     value={form.work_experience}
  //                   >
  //                     {form.word_experence}
  //                   </textarea>
  //                 </Row>
  //               </Col>
  //               <Col md={6}>
  //                 <Row>
  //                   <label>Hình thức làm việc</label>
  //                 </Row>
  //                 <Row>
  //                   <FormControl
  //                     as="select"
  //                     id=""
  //                     value={form.work_type}
  //                     onChange={(e) => this.handleInputChange('work_type', e.target.value)}
  //                   >
  //                     <option value="" selected disabled>
  //                       Hình thức làm việc
  //                     </option>
  //                     <option value={1}>Fulltime</option>
  //                     <option value={2}>Parttime</option>
  //                   </FormControl>
  //                 </Row>
  //                 <Row>
  //                   <Col xs={6} className="text-left pl-0">
  //                     <label>Hồ sơ</label>
  //                   </Col>
  //                   <Col xs={6}>
  //                     <a href={form.doc} className={form.doc_url ? 'text-primary' : 'text-danger'} target="_blank">
  //                       {form.doc ? 'Click here to read' : 'none'}
  //                     </a>
  //                   </Col>
  //                 </Row>
  //                 <Row>
  //                   <label htmlFor="status">Trạng thái</label>
  //                   <FormControl
  //                     as="select"
  //                     name="status"
  //                     value={form.is_active}
  //                     onChange={(e) => this.handleInputChange('is_active', e.target.value)}
  //                   >
  //                     <option value="1">Đang hoạt động</option>
  //                     <option value="2">Tạm dừng hoạt động</option>
  //                   </FormControl>
  //                 </Row>
  //               </Col>
  //             </Row>
  //             <Row className="justify-content-center mt-4">{this.renderButton()}</Row>
  //           </FormGroup>
  //         </Col>
  //       </Row>
  //     </div>
  //   );
  // }

  renderField() {
    const { form, profile, front_id, back_id } = this.state;
    // const { [STRING.dob]: dob } = this.state.form;
    const { RangePicker } = DatePicker;

    const dateFormat = 'DD/MM/YYYY';

    return (
      <div className="w-100">
        <Row>
          <Col md={12} className="">
            <Row className="justify-content-center">
              {this.renderPicUploader()}
            </Row>
          </Col>
          <Col md={12}>
            {/* <Row>
              <label className="big-title">Thông tin cá nhân</label>
            </Row> */}
            <FormGroup className="add-company-form">
              <Row>
                <Col md={6} className="tele-input-col">
                  <label htmlFor="name">Họ và tên(*)</label>
                  <FormControl
                    type="text"
                    name="name"
                    placeholder="Nhập họ và tên"
                    value={form.full_name}
                    onChange={e =>
                      this.handleInputChange('full_name', e.target.value)
                    }
                  ></FormControl>

                  <label htmlFor="phone">Số điện thoại(*)</label>
                  <FormControl
                    type="number"
                    name="phone"
                    disabled
                    placeholder="Nhập số điện thoại"
                    value={form.user_name}
                    onChange={e =>
                      this.handleInputChange('user_name', e.target.value)
                    }
                  ></FormControl>

                  <br />
                  <label htmlFor="gender">Giới tính</label>
                  <Form.Check
                    inline
                    type="radio"
                    label="Nam"
                    name="gender"
                    className="radio-button offset-2 tele-radio"
                    value="2"
                    checked={form.gender == 2}
                    onChange={e =>
                      this.handleInputChange('gender', e.target.value)
                    }
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="Nữ"
                    name="gender"
                    className="radio-button tele-radio"
                    value="1"
                    checked={form.gender == 1}
                    onChange={e =>
                      this.handleInputChange('gender', e.target.value)
                    }
                  />
                  <br />
                  <br />

                  {/* <label htmlFor="cmnd">Số CMND</label>
                  <FormControl
                    type="number"
                    name="phone"
                    placeholder="Nhập số CMND (9 hoặc 12 số)"
                    value={form.id_number}
                    onChange={(e) => this.handleInputChange('id_number', e.target.value)}
                  ></FormControl> */}
                </Col>

                <Col md={6} className="tele-input-col">
                  <label htmlFor="email">Email(*)</label>
                  <FormControl
                    type="email"
                    name="email"
                    placeholder="Nhập email"
                    value={form.email}
                    onChange={e =>
                      this.handleInputChange('email', e.target.value)
                    }
                  ></FormControl>
                  <label htmlFor="email">Ngày sinh</label>
                  <br></br>
                  <Space direction="vertical" size={12}>
                    <DatePicker
                      style={{ width: '529px' }}
                      defaultValue={
                        form.dob ? moment(form.dob, 'DD/MM/YYYY') : ''
                      }
                      format={dateFormat}
                      onChange={date => this.handleInputChange('dob', date)}
                    />
                  </Space>
                  <br></br>
                  <br></br>
                  <label htmlFor="city">Tỉnh - Thành phố</label>
                  <FormControl
                    as="select"
                    id="provice"
                    value={form.province_id}
                    onChange={e =>
                      this.handleInputChange('province_id', e.target.value)
                    }
                  >
                    <option value="" selected disabled>
                      Tỉnh/Thành phố
                    </option>
                    {renderSelection(this.props.provinceState.data)}
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="tele-input-col">
                  <label htmlFor="cmnd">Số CMND (*)</label>
                  <FormControl
                    type="number"
                    name="phone"
                    placeholder="Nhập số CMND (9 hoặc 12 số)"
                    value={form.id_number}
                    onChange={e =>
                      this.handleInputChange('id_number', e.target.value)
                    }
                  ></FormControl>
                </Col>
                <Col md={6} className="tele-input-col">
                  <label htmlFor="address ">Địa chỉ cụ thể</label>
                  <FormControl
                    type="text"
                    name="address"
                    placeholder="Nhập địa chỉ cụ thể"
                    value={form.address}
                    onChange={e =>
                      this.handleInputChange('address', e.target.value)
                    }
                  ></FormControl>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Row>
                    <Col md={6} className="pl-0">
                      <Row>
                        <label>Ảnh mặt trước CMND(*)</label>
                      </Row>
                      <div className="upload-form-wrapper mb-2">
                        <label
                          htmlFor="upload2"
                          className="label-upload"
                          style={{ cursor: 'pointer' }}
                        >
                          {front_id.length ? (
                            <img
                              src={front_id}
                              className="uploaded-pic"
                              width="200"
                              height="150"
                              style={{
                                objectFit: 'contain',
                                borderRadius: '8px',
                              }}
                            />
                          ) : (
                            <>
                              <i className="fas fa-image upload-icon"></i>
                              <p>Bấm để chọn ảnh</p>
                              {/* <span>(Định dạng ảnh được hỗ trợ: jpg, jpeg, png)</span> */}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          id="upload2"
                          accept=".jpg,.jpeg,.png"
                          style={{ display: 'none' }}
                          onChange={e => this.handleChangeImage('front_id', e)}
                        />
                        <br />
                      </div>
                    </Col>
                    <Col md={6} className="pr-0">
                      <Row>
                        <label>Ảnh mặt sau CMND(*)</label>
                      </Row>
                      <div className="upload-form-wrapper mb-2">
                        <label
                          htmlFor="upload3"
                          className="label-upload"
                          style={{ cursor: 'pointer' }}
                        >
                          {back_id.length ? (
                            <img
                              src={back_id}
                              className="uploaded-pic"
                              width="200"
                              height="150"
                              style={{
                                objectFit: 'contain ',
                                borderRadius: '8px',
                              }}
                            />
                          ) : (
                            <>
                              <i className="fas fa-image upload-icon"></i>
                              <p>Bấm để chọn ảnh</p>
                              {/* <span>(Định dạng ảnh được hỗ trợ: jpg, jpeg, png)</span> */}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          id="upload3"
                          accept=".jpg,.jpeg,.png"
                          style={{ display: 'none' }}
                          onChange={e => this.handleChangeImage('back_id', e)}
                        />
                        <br />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <label className="big-title">Thông tin công việc</label>
              </Row>
              <Row>
                <Col md={6}>
                  <Row>
                    <label>Kinh nghiệm làm việc</label>
                  </Row>
                  <Row>
                    <textarea
                      placeholder="Mô tả kinh ngiệm làm việc"
                      className="form-control"
                      rows="5"
                      onChange={e =>
                        this.handleInputChange(
                          'work_experience',
                          e.target.value
                        )
                      }
                      value={form.work_experience || ''}
                    ></textarea>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row>
                    <label>Hình thức làm việc</label>
                  </Row>
                  <Row>
                    <FormControl
                      as="select"
                      id=""
                      value={form.work_type}
                      onChange={e =>
                        this.handleInputChange('work_type', e.target.value)
                      }
                    >
                      <option value="" selected disabled>
                        Hình thức làm việc
                      </option>
                      <option value="1">Fulltime</option>
                      <option value="2">Parttime</option>
                    </FormControl>
                  </Row>
                  <Row>
                    <label>Hồ sơ</label>
                  </Row>
                  <Row>
                    <Col sm={12} className="px-0 text-left">
                      <label
                        htmlFor="uploader"
                        className="text-blue uploader-label"
                      >
                        <i
                          className="fas fa-paperclip mr-2"
                          style={{ fontSize: 16 }}
                        ></i>
                        {this.state.titleUploader
                          ? this.state.titleUploader
                          : 'Đính kèm hồ sơ (.doc, .pdf)'}
                      </label>
                      <input
                        type="file"
                        id="uploader"
                        className="d-none"
                        onChange={e => this.handleFileUploader(e)}
                      ></input>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center mb-4">{this.renderButton()}</Row>
      </div>
    );
  }

  renderPicUploader() {
    const { form, profile, front_id, back_id } = this.state;
    return (
      <>
        <form ctype="multipart/form-data" className="">
          {/* <Row>
            <label>Ảnh đại diện(*)</label>
          </Row> */}
          <div className=" mb-2">
            <label
              htmlFor="upload1"
              className="label-upload"
              style={{ cursor: 'pointer' }}
            >
              {profile.length ? (
                <img
                  src={profile}
                  width="200"
                  height="200"
                  style={{
                    objectFit: 'contain',
                    width: 100,
                    height: 100,
                    borderRadius: '50%',
                  }}
                />
              ) : (
                <div
                  style={{
                    width: 100,
                    height: 100,
                    backgroundColor: '#bdbdbd',
                    borderRadius: '50%',
                    position: 'relative',
                  }}
                >
                  <i
                    class="fas fa-plus"
                    style={{
                      fontSize: '20px',
                      color: '#fff',
                      position: 'absolute',
                      bottom: '39%',
                      right: '40%',
                    }}
                  ></i>
                </div>
              )}
            </label>
            <input
              type="file"
              id="upload1"
              accept=".jpg,.jpeg,.png"
              style={{ display: 'none' }}
              onChange={e => this.handleChangeImage('profile', e)}
            />
            <br />
          </div>
        </form>
      </>
    );
  }
  render() {
    return (
      <>
        {this.state.isLoading ? <Loading /> : null}
        {this.renderBody()}
      </>
    );
  }
}

const mapStateToProps = state => ({
  provinceState: state.ProvinceReducer,
});

const mapDispatchToProps = {
  getProvinceList,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Edit));
