import {
    PhoneFilled,
  } from '@ant-design/icons';
import { Button, Tabs } from 'antd';
import styled from 'styled-components';
export const colSpan = {
    xxl: 6,
    xl: 8,
    lg: 8,
    sm: 12,
    xs: 24,
  };
  export const ButtonStyled = styled(Button)`
    border-width: 1px;
    border-style: solid;
    color: black;
    /* font-weight: 500; */
  `;
export   const PhoneFilledStyled = styled(PhoneFilled)`
    color: green;
    :hover {
      cursor: ${props => (props.loading ? 'progress' : 'pointer')};
    }
  `;

export const TabStyledTitle = styled.div`
    font-size: 20px;
    font-weight: 600;
    color: #ef8737;
`

export const TabsStyle = styled(Tabs)`
  width: 100%;
  .ant-tabs-ink-bar {
    background: #ef8737;
  }
`;