import {
    GET_LIST_CALL, GET_LIST_CALL_SUCCESS, GET_LIST_CALL_FAIL,
} from "@actions/type";

import reactotron from 'reactotron-react-js'

const initialState = {
    data: {},
    isLoading: false,
    error: null
};

export default function (state = initialState, action) {

    switch (action.type) {

        // *************  GET INFO ****************//
        case GET_LIST_CALL:
            return {
                ...state,
                isLoading: true,
            }
        case GET_LIST_CALL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload.response.data,
                paging: action.payload.response.paging,
                error: null
            }
        case GET_LIST_CALL_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        default:
            return state;
    }
}