import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Button, Col, Empty, Row } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import moment from 'moment';
import { NOT_UDATE_YET_STRING, BASE_URL_IMAGE } from '@constants/Constant';
import {
  formatPrice,
  randomColor,
  formatTime,
  getFirstLetter,
} from '@constants/funcHelper';
import ButtonModules from 'src/modules/Button';
import IconAntd from 'src/modules/IconAntd';
import ModalModules from 'src/modules/Modal';

const ButtonReview = styled.div`
  &:hover {
    color: #ef8737;
  }
  margin: 10px 0 10px 0;
  cursor: pointer;
  width: 80px;
`;

const EmptyReview = styled(Empty)`
  .ant-empty-img-default {
    height: 50px;
  }
  .ant-empty-image {
    height: unset;
  }
`;

const CallReviewDetail = props => {
  const { data, params } = props;
  const [isViewDetail, setIsViewDetail] = useState(false);
  const [listQuestion, setListQuestion] = useState([]);
  const getPointColor = inputPoint => {
    if (inputPoint < 0) {
      return 'tomato';
    } else {
      return 'green';
    }
  };
  useEffect(() => {
    if (data.call_reviews) {
      setListQuestion(data.call_reviews);
    }
  }, [data.call_reviews]);
  useEffect(() => {
    setIsViewDetail(false);
  }, [params]);
  const reviewerName = data.full_name_reviewer
    ? data.full_name_reviewer.trim().split(' ')
    : null;
  return (
    <Col span={12}>
      <ButtonModules
        color="primary"
        // style={!isViewDetail ? { color: 'green' } : { color: 'tomato' }}
        onClick={() => {
          setIsViewDetail(!isViewDetail);
        }}
        icon={<IconAntd style={{ color: 'white' }} icon="EyeOutlined" />}
      >
        Chi tiết
      </ButtonModules>

      <ModalModules
        width={800}
        modalVisible={isViewDetail}
        onCancel={() => setIsViewDetail(!isViewDetail)}
      >
        {isViewDetail ? (
          <>
            <div>
              <strong>Ghi chú cuộc gọi:</strong>{' '}
              {data.call_note || NOT_UDATE_YET_STRING}
            </div>
            <div className="mt-2">
              <strong>Chuyển đổi văn bản:</strong>{' '}
              {data.content_text || NOT_UDATE_YET_STRING}
            </div>
            <Row>
              <div style={{ width: '50%' }}>
                <div style={{ borderRadius: 5, margin: 15, padding: 20 }}>
                  <h5>Phiếu kết quả</h5>
                  {data.result_cards?.length ? (
                    data.result_cards.map((item, index) => {
                      return (
                        <div key={index}>
                          <b>
                            {index + 1}. {item.questions}
                          </b>
                          <div style={{ margin: '0 0 0 20px' }}>
                            {item.answer ? item.answer : 'Chưa cập nhật'}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <EmptyReview description="Không có dữ liệu!" />
                  )}
                </div>
              </div>
              <div style={{ width: '50%' }}>
                <div style={{ borderRadius: 5, margin: 15, padding: 20 }}>
                  <h5>Đánh giá cuộc gọi</h5>
                  {data.call_reviews?.[0]?.created_at &&
                    `(${formatTime(data.call_reviews?.[0]?.created_at)})`}
                  {data.call_reviews?.length ? (
                    <>
                      <ul style={{ margin: '0 0 0 25px' }}>
                        <li>
                          <Row>
                            <div>Người đánh giá &nbsp;</div>&nbsp;
                            {data.profile_image_reviewer ? (
                              <Avatar src={`${data.profile_image_reviewer}`} />
                            ) : reviewerName?.length ? (
                              <Avatar
                                style={{
                                  backgroundColor: randomColor(
                                    // reviewerName[reviewerName.length - 1].charAt(0)?.toUpperCase()
                                    getFirstLetter(reviewerName)
                                  ),
                                  marginRight: '5px',
                                }}
                                size={24}
                              >
                                <span id="avaCus">
                                  {reviewerName[reviewerName.length - 1]
                                    .charAt(0)
                                    ?.toUpperCase()}
                                </span>
                              </Avatar>
                            ) : null}
                            &nbsp;
                            <div>{data.full_name_reviewer}</div>
                          </Row>
                        </li>
                        <li>
                          <div>Đánh giá:</div>
                          <div>
                            {listQuestion?.length > 0
                              ? listQuestion?.map((item, index) => (
                                  <Row
                                    key={index}
                                    style={{ marginLeft: '20px' }}
                                  >
                                    <div
                                      style={{ width: '85%', fontWeight: 600 }}
                                    >
                                      {item.call_review_config.name}
                                    </div>
                                    <div
                                      style={{
                                        color: getPointColor(
                                          item.call_review_config.point
                                        ),
                                        width: '15%',
                                        textAlign: 'end',
                                        fontWeight: 600,
                                      }}
                                    >
                                      {formatPrice(
                                        item.call_review_config.point
                                      )}
                                    </div>
                                  </Row>
                                ))
                              : null}
                            <Row style={{ marginLeft: '20px' }}>
                              <div style={{ width: '80%' }}>Tổng điểm: </div>
                              <div
                                style={{
                                  color: getPointColor(data.call_review_point),
                                  width: '20%',
                                  textAlign: 'end',
                                  fontWeight: 600,
                                }}
                              >
                                {formatPrice(data.call_review_point)}
                              </div>
                            </Row>
                          </div>
                        </li>
                        <li>
                          <Row>
                            <div>Ghi chú:</div>&nbsp;
                            <div>
                              {data.call_review_note
                                ? data.call_review_note
                                : NOT_UDATE_YET_STRING}
                            </div>
                          </Row>
                        </li>
                      </ul>
                    </>
                  ) : (
                    <EmptyReview description="Chưa có đánh giá!" />
                  )}
                </div>
              </div>
            </Row>
          </>
        ) : null}
      </ModalModules>
    </Col>
  );
};

CallReviewDetail.propTypes = {};

export default CallReviewDetail;
