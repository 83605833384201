import { createSelector } from 'reselect';

export const getProvinces = (state) => state.ProvinceReducer;

export const getProvinceOptions = createSelector([getProvinces], (provinces) => {
  return (
    provinces.data?.map((province) => ({
      title: province.name,
      label: province.name,
      value: province.id,
      key: province.id,
    })) || []
  );
});
