import {
    GET_TELESALE_INACTIVE,
    GET_TELESALE_INACTIVE_SUCCESS,
    GET_TELESALE_INACTIVE_FAIL,
} from "@actions/type";

const initialState = {
    data: {},
    isLoading: false,
    paging: {},
    error: null
};

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_TELESALE_INACTIVE:
            return {
                ...state,
                isLoading: true,
                payload: action.payload
            }
            break;
        case GET_TELESALE_INACTIVE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload.response.data,
                paging: action.payload.response.paging,
                error: null
            }
            break;
        case GET_TELESALE_INACTIVE_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
            break;
        default:
            return state;
    }
}