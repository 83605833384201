export const OPPORTUNITY_CHANGE = {
  YES: 1,
  NO: 0,
};

export const REVENUE_CHANGE = {
  YES: 1,
  NO: 0,
};

export const SALE_CHANGE = {
  YES: 1,
  NO: 0,
};
