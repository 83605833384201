import { Descriptions, Image, Switch, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';

import { DF_CUSTOMER_ACTION_ID } from '@constants/Constant';
import { VietnameseString } from '@constants/VietnameseString';
import {
  getStatusSMSStyle,
  getStatusZaloStyle,
} from '@screens/Enterprise/Report/MessageReport/Utils/constant';
import InteractionHistoryHeader from './InteractionHistoryHeader';
import { EyeOutlined } from '@ant-design/icons';

import { DescriptionCustomInteractionHistory } from '@styles/Style';

const DescriptionsStyled = styled(Descriptions)`
  &&& {
    th.ant-descriptions-item {
      padding-bottom: 0px;
    }

    span.ant-descriptions-item-content {
      text-align: left;
      font-weight: 600;
    }
  }
`;

function MessageZaloPerson(props) {
  const { Paragraph } = Typography;

  const { interactionHistory } = props;

  const { tags: infoMessage } = interactionHistory;

  const { label: statusLabel, color: statusColor } =
    interactionHistory.df_customer_action_id ===
    DF_CUSTOMER_ACTION_ID.GUI_TIN_NHAN_ZALO_CA_NHAN
      ? getStatusZaloStyle(infoMessage?.status)
      : getStatusSMSStyle(infoMessage?.status);

  return (
    <Fragment>
      <InteractionHistoryHeader interactionHistory={interactionHistory} />
      <DescriptionCustomInteractionHistory column={1}>
        <Descriptions.Item label={VietnameseString.job}>
          {infoMessage?.job_name}
        </Descriptions.Item>

        <Descriptions.Item label={VietnameseString.status}>
          <span style={{ color: statusColor }}>{statusLabel}</span>
        </Descriptions.Item>

        <Descriptions.Item span={2} label="Tài khoản gửi tin">
          {infoMessage?.zalo_account_phone}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Nội dung">
          <Paragraph
            ellipsis={{
              rows: 2,
              expandable: true,
              symbol: <span style={{ color: '#0675c4' }}>Xem thêm</span>,
            }}
            style={{ marginBottom: 0 }}
          >
            {infoMessage?.message}
          </Paragraph>
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Hình ảnh">
          <Image
            src={infoMessage?.image}
            atl=""
            width={70}
            height="auto"
            preview={{
              mask: <EyeOutlined />,
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Ghi chú">
          {infoMessage?.response ? infoMessage?.response : ''}
        </Descriptions.Item>
      </DescriptionCustomInteractionHistory>
    </Fragment>
  );
}

MessageZaloPerson.propTypes = {
  interactionHistory: PropTypes.object,
};

export default MessageZaloPerson;
