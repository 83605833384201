import React from 'react';

function compare(a, b) {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  let comparison = 0;
  if (nameA > nameB) {
    comparison = 1;
  } else if (nameA < nameB) {
    comparison = -1;
  }
  return comparison;
}

export const renderSelection = (list) => {
  const listSort = list?.sort(compare);
  return (
    <>
      {list?.length &&
        listSort.map((item, index) => (
          <option value={item.id} key={index}>
            {item.name}
          </option>
        ))}
    </>
  );
};
