import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Avatar, Breadcrumb, Button, Col, Collapse, Empty, Modal, Row, Tooltip, Tabs, Spin } from 'antd';
import { faLightbulb } from '@fortawesome/fontawesome-free-regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CaretRightOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import styled from 'styled-components';
import moment from 'moment';
import _ from 'lodash';

import { formatPrice, setSearchParamsInUrlVer2, getSearchParamsInUrl, randomColor } from '@constants/funcHelper';
import { FIELD_CONFIG_TELEMARKETING_REPORT, TIME_CODE_TYPE, TYPE_GROUP_CONFIGS } from '@constants/Constant';
import DragAndDropConfiguration from './components/DragAndDropConfiguration';
import {  renderStatistics, renderStatistics2 } from '../functionHelper';
import { useCancellableApi } from 'src/hooks/useCancellableApi';
import { VietnameseString } from '@constants/VietnameseString';
import Wrapper from '@components/ui/wrapper/Wrapper';
import ReportItem from '../components/ReportItem';
import Filter from './components/Filter';
import {
  requestGetConfigDisplayTelemarketing,
  requestGetTelemarketingReportData,
  requestGetTelemarketingReportDataTotal,
  requestUpdateConfigDisplayTelemarketing,
} from '@constants/Api';

import { BreadcrumbAntStyle, TableBorderedAntStyle, TabsStyled } from '@styles/Style';

const IconLoading = () => (
  <span style={{ marginTop: 24 }} role="img" aria-label="loading" class="anticon anticon-loading anticon-spin">
    <svg width="35px" height="35px" fill="currentColor" viewBox="0 0 1024 1024" data-icon="loading">
      <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z" />
    </svg>
  </span>
);

const TitleColumnTableReport = styled.span`
  font-size: 10px;
`;

const ModalStyle = styled(Modal)`
  .ant-modal-body {
    padding: 10px 24px;
  }
`;

const configTelemarketingReportPositionField = [
  FIELD_CONFIG_TELEMARKETING_REPORT.CUSTOMER,
  FIELD_CONFIG_TELEMARKETING_REPORT.CALL,
  FIELD_CONFIG_TELEMARKETING_REPORT.TAGS,
  FIELD_CONFIG_TELEMARKETING_REPORT.OPPORTUNITY,
  FIELD_CONFIG_TELEMARKETING_REPORT.CONTRACT,
];

export default function TelemarketingReport() {
  const user = useSelector((states) => states.userReducer.data);
  const apiGetTelemarketingReportData = useCancellableApi(requestGetTelemarketingReportData);
  const apiGetTelemarketingReportDataTotal = useCancellableApi(requestGetTelemarketingReportDataTotal);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 10
  });

  const [param, setParam] = useState({
    telemarketing_id: [],
    department_id: [],
    customer_data_field_source_code: [],
    type: TIME_CODE_TYPE.DATE,
    from: moment(new Date()).startOf('date').format('YYYY/MM/DD'),
    to: moment(new Date()).endOf('date').format('YYYY/MM/DD'),
    page: paging.current,
    limit: paging.pageSize,
    customer_action_type_id: undefined,
    job_form_question_id: [],
    job_form_answers_id: [],
    job_parent_id: [],
    job_id: [],

  });

  const [visibleDisplayConfiguration, setVisibleDisplayConfiguration] = useState(false);
  //field config lấy từ API
  const [displayFieldConfigGetAPI, setDisplayFieldConfigGetAPI] = useState({});
  //field config được xử lí phía giao diện để đẩy lên API update
  const [displayFieldConfig, setDisplayFieldConfig] = useState({});

  //"hiển thị tỉ lệ" lấy từ API
  const [scaleDisplayConfigGetAPI, setScaleDisplayConfigGetAPI] = useState({
    scaleDisplayTag: 0,
    scaleDisplayCustomer: 0,
  });
  //"hiển thị tỉ lệ" được xử lí phía giao diện để đẩy lên API update
  const [scaleDisplayConfig, setScaleDisplayConfig] = useState({
    scaleDisplayTag: 0,
    scaleDisplayCustomer: 0,
  });

  const [telemarketingReportData, setTelemarketingReportData] = useState([]);
  const [telemarketingReportDataTotal, setTelemarketingReportDataTotal] = useState([]);
  const [loadingReport, setLoadingReport] = useState(false);
  const handleChangeParamTelemarketingReport = (field, value) => {
    mixpanel.track(`filter employeeReport ${field}: ${value}`);
    if (field !== 'department_id') {
      setParam({
        ...param,
        [field]: value,
        page: 1,
      });
      setSearchParamsInUrlVer2({ [field]: value, page: 1 });
    } else {
      setParam({
        ...param,
        [field]: value,
        telemarketing_id: [],
        page: 1,
      });
      setSearchParamsInUrlVer2({ [field]: value, telemarketing_id: [], page: 1 });
    }
  };
  console.log("loadingReport", loadingReport)
  const handleChangeParamTimeTelemarketingReport = (from, to) => {
    mixpanel.track(`filter employeeReport from: ${from}/ to: ${to}`);
    setParam({
      ...param,
      from: from,
      to: to,
      page: 1,
    });
    setSearchParamsInUrlVer2({ from, to, page: 1 });
  };

  const getConfigDisplayTelemarketing = async () => {
    try {
      const result = await requestGetConfigDisplayTelemarketing();
      setDisplayFieldConfigGetAPI(result.data);
      localStorage.setItem(`init`,JSON.stringify(result.data))
      setDisplayFieldConfig(result.data);
      setScaleDisplayConfigGetAPI({
        ...scaleDisplayConfigGetAPI,
        scaleDisplayTag: result.data?.Ratio[0],
        scaleDisplayCustomer: result.data?.Ratio[1],
      });
      setScaleDisplayConfig({
        ...scaleDisplayConfig,
        scaleDisplayTag: result.data?.Ratio[0],
        scaleDisplayCustomer: result.data?.Ratio[1],
      });
    } catch (error) {
      console.log('Error getConfigDisplayTelemarketing: ', { error });
    }
  };

  const handleFieldConfig = (displayFieldConfigGetAPI, scaleDisplayConfigGetAPI) => {
    if (displayFieldConfigGetAPI) {
      const columnConfig = [
        {
          title: <TitleColumnTableReport>{VietnameseString.telesale}</TitleColumnTableReport>,
          dataIndex: 'full_name',
          key: 'full_name',
          fixed: 'left',
          width: 180,
          render: (text, record, index) => {
            const name = text.trim().split(' ');
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  {text && (
                    <Avatar
                      src={record.avatar && record.avatar}
                      style={{
                        backgroundColor: randomColor(name[name.length - 1].charAt(0)?.toUpperCase()),
                        marginRight: '5px',
                      }}
                      size="small"
                    >
                      {name[name.length - 1].charAt(0)?.toUpperCase()}
                    </Avatar>
                  )}
                </div>
                <Tooltip placement="bottomLeft" title={text}>
                  <div
                    style={{
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal',
                      WebkitLineClamp: 1,
                      width: '100%',
                      textAlign: 'left',
                    }}
                  >
                    {text}
                  </div>
                </Tooltip>
              </div>
            );
          },
        },
        {
          title: (
            <>
              <i className="fas fa-users"></i> {VietnameseString.customer}
            </>
          ),
          children:
            displayFieldConfigGetAPI.Customer &&
            displayFieldConfigGetAPI.Customer.filter((i) => i.display_config === 1).map((field, index, array) => {
              // console.log(`field`, field, array)
              if (field.code !== 'total_customer_action') {
                if (scaleDisplayConfigGetAPI.scaleDisplayCustomer) {
                  console.log("filed111", field,array )
                  return {
                    title: <TitleColumnTableReport>{field.label}</TitleColumnTableReport>,
                    children: [
                      {
                        title: <TitleColumnTableReport>{VietnameseString.quantity}</TitleColumnTableReport>,
                        width: 90,
                        dataIndex: field.code,
                        key: field.code,
                        render: (text, record, index) => {
                          if (record.customer_repots[field.code] !== undefined) {
                            return <>{record.customer_repots[field.code]}</>;
                          } else {
                            const item = record.customer_repots.list_source_data.find((f) => f.item === field.code);
                            return renderStatistics2(item);
                          }
                        },
                      },
                      {
                        title: (
                          <TitleColumnTableReport >
                            {field.label}/{VietnameseString.customer_interacted} (%)
                          </TitleColumnTableReport>
                        ),
                        width: 90,
                        render: (text, record, index) => {
                          // console.log(`record`, record.customer_repots.total_customer_action, record.customer_repots[field.code])
                          // if(field.code === `total_customer_forward_telesale` ||field.code === `total_customer_create_telesale` ) {
                            
                          return  (record.customer_repots.total_customer_action &&  record.customer_repots[field.code]) ? 
                             <>{(Number(record.customer_repots[field.code])/Number(record.customer_repots.total_customer_action)*100).toFixed(2)}%</> : <>--%</>

                          // }
                          // const item = record.customer_repots.list_source_data.find((f) => f.item === field.code);
                          // return <>{item ? item.percent_ratio.toFixed(2) : '--'}%</>;
 
                        },
                      },
                    ],
                  };
                } else {
                  return {
                    title: <TitleColumnTableReport>{field.label}</TitleColumnTableReport>,
                    width: 90,
                    dataIndex: field.code,
                    key: field.code,
                    render: (text, record, index) => {
                      if (record.customer_repots[field.code] !== undefined) {
                        return <>{record.customer_repots[field.code]}</>;
                      } else {
                        const item = record.customer_repots.list_source_data.find((f) => f.item === field.code);
                        return renderStatistics2(item);
                      }
                    },
                  };
                }
              } else {
                return {
                  title: <TitleColumnTableReport>{field.label}</TitleColumnTableReport>,
                  width: 90,
                  dataIndex: field.code,
                  key: field.code,
                  render: (text, record, index) => {
                    return renderStatistics(record, 'customer_repots', field);
                  },
                };
              }
            }),
        },
        {
          title: (
            <>
              <i className="fas fa-phone"></i> {VietnameseString.call}
            </>
          ),
          children:
            displayFieldConfigGetAPI.Call &&
            displayFieldConfigGetAPI.Call.filter((i) => i.display_config === 1).map((field) => {
              return {
                title: <TitleColumnTableReport>{field.label}</TitleColumnTableReport>,
                width: 90,
                dataIndex: field.code,
                key: field.code,
                render: (text, record, index) => {
                  if (record?.call_reports[field.code + '_before'] !== undefined) {
                    return renderStatistics(record, 'call_reports', field);
                  } else {
                    return <>{record.call_reports[field.code]}</>;
                  }
                },
              };
            }),
        },
        {
          title: (
            <>
              <i className="fas fa-tag"></i> {VietnameseString.classify}
            </>
          ),
          children:
            displayFieldConfigGetAPI.Tags &&
            displayFieldConfigGetAPI.Tags.filter((i) => i.display_config === 1).map((field, index) => {
              if (scaleDisplayConfigGetAPI.scaleDisplayTag) {
                return {
                  title: (
                    <TitleColumnTableReport style={{ color: field.color || 'black' }}>
                      {field.label}
                    </TitleColumnTableReport>
                  ),
                  children: [
                    {
                      title: <TitleColumnTableReport>{VietnameseString.quantity}</TitleColumnTableReport>,
                      width: 90,
                      dataIndex: field.code,
                      key: field.code,
                      render: (text, record, index) => {
                        const tag = record.tag_reports.list_tag_data.find((t) => t.id === field.id);
                        return <>{tag && tag.total}</>;
                      },
                    },
                    {
                      title: (
                        <TitleColumnTableReport>
                          {field.label}/{VietnameseString.customer_interacted} (%)
                        </TitleColumnTableReport>
                      ),
                      width: 90,
                      render: (text, record, index) => {
                        //-- code cũ
                                  const tag = record.tag_reports.list_tag_data.find((t) => t.id === field.id);
                        // return <>{tag && tag.percent_ratio.toFixed(2)}%</>;
                        // ---code cũ
                        return  (record.customer_repots.total_customer_action && tag.total ) ? 
                             <>{(Number(tag.total)/Number(record.customer_repots.total_customer_action)*100).toFixed(2)}%</> : <>--%</>
                       
                   
                      },
                    },
                  ],
                };
              } else {
                return {
                  title: (
                    <TitleColumnTableReport style={{ color: field.color || 'black' }}>
                      {field.label}
                    </TitleColumnTableReport>
                  ),
                  width: 90,
                  dataIndex: field.code,
                  key: field.code,
                  render: (text, record, index) => {
                    const tag = record.tag_reports.list_tag_data.find((t) => t.id === field.id);
                    return <>{tag && tag.total}</>;
                  },
                };
              }
            }),
        },
        {
          title: (
            <>
              <i className="fad fa-badge"></i> {VietnameseString.opportunity}
            </>
          ),
          children:
            displayFieldConfigGetAPI.Opportunity &&
            displayFieldConfigGetAPI.Opportunity.filter((i) => i.display_config === 1).map((field, index) => {
              return {
                title: <TitleColumnTableReport>{field.label}</TitleColumnTableReport>,
                width: 90,
                dataIndex: field.code,
                key: field.code,
                render: (text, record, index) => {
                  if (record?.opportunity_reports[field.code + '_before'] !== undefined) {
                    return renderStatistics(record, 'opportunity_reports', field);
                  } else {
                    return <>{record.opportunity_reports[field.code]}</>;
                  }
                },
              };
            }),
        },
        {
          title: (
            <>
              <i className="far fa-money-bill"></i> {VietnameseString.contract}
            </>
          ),
          children:
            displayFieldConfigGetAPI.Contract &&
            displayFieldConfigGetAPI.Contract.filter((i) => i.display_config === 1).map((field, index) => {
              return {
                title: <TitleColumnTableReport>{field.label} (VND)</TitleColumnTableReport>,
                width: 150,
                dataIndex: field.code,
                key: field.code,
                render: (text, record, index) => {
                  if (record?.contract_reports[field.code + '_before'] !== undefined) {
                    return renderStatistics(record, 'contract_reports', field);
                  } else {
                    return <>{formatPrice(record.contract_reports[field.code])} VND</>;
                  }
                },
              };
            }),
        },
      ];
      return columnConfig;
    } else {
      return [];
    }
  };

  const columns = useMemo(
    () => handleFieldConfig(displayFieldConfigGetAPI, scaleDisplayConfigGetAPI),
    [displayFieldConfigGetAPI, scaleDisplayConfigGetAPI]
  );

  console.log("columns", columns)

  function handleChangeTypeOfInteraction(value) {
    console.log("value", value)
    setParam({...param,customer_action_type_id: value })
    setSearchParamsInUrlVer2({ customer_action_type_id: value, page: 1 });
  }


  useEffect(() => {
    const searchParamsInUrl = getSearchParamsInUrl();
    console.log(`searchParamsInUrl`, searchParamsInUrl)
  
    setParam({ ...param, ...searchParamsInUrl });
    getConfigDisplayTelemarketing();
    return () => localStorage.removeItem("init")
  }, []);

  const getTelemarketingReportData = async (payload = param) => {
    try {
      setIsLoading(true);
      const result = await apiGetTelemarketingReportData({
        telemarketing_id: payload.telemarketing_id,
        department_id: payload.department_id,
        customer_data_field_source_code: payload.customer_data_field_source_code,
        page: payload.page,
        limit: payload.limit,
        from: payload.from,
        to: payload.to,
        type: payload.type,
        customer_action_type_id: payload.customer_action_type_id,
        job_form_question_id: payload.job_form_question_id,
        job_form_answers_id: payload.job_form_answers_id,
        job_parent_id: payload.job_parent_id,
        job_id: payload.job_id,
        
      });
      if (result?.data?.dataHandel) {
        setTelemarketingReportData(result.data.dataHandel);
        setPaging({
          total: parseInt(result.data.paging.totalItemCount),
          current: parseInt(result.data.paging.page),
          pageSize: parseInt(result.data.paging.limit),
        });
      setIsLoading(false);

      }
    } catch (error) {//
      if(error.msg !== `EZExceptionApiCancel`) {
        setIsLoading(false)
      }
    } 
    // finally {
    //   setIsLoading(false);
    // }
  };
  const getTelemarketingReportDataTotal = async (payload = param) => {
    setLoadingReport(true)

    try {
      const result = await apiGetTelemarketingReportDataTotal({
        telemarketing_id: payload.telemarketing_id,
        department_id: payload.department_id,
        customer_data_field_source_code: payload.customer_data_field_source_code,
        from: payload.from,
        to: payload.to,
        type: payload.type,
        customer_action_type_id: payload.customer_action_type_id,
        job_form_question_id: payload.job_form_question_id,
        job_form_answers_id: payload.job_form_answers_id,
        job_parent_id: payload.job_parent_id,
        job_id: payload.job_id,
      });
      const data = result.data.dataHandel.map((item, idx) => ({
        id: item.id || '',
        code: item.code,
        dataName: item.name,
        currentStatistics: item.total_from_to,
        previousStatistics: item.total_from_to_befores,
        ratio: item.percent,
        type: item.type,
        typeGroup: item.type_group,
      }));
      setLoadingReport(false)
      setTelemarketingReportDataTotal(data);
    
    } catch (error) {
      if(error.msg !==`EZExceptionApiCancel`) {
        setLoadingReport(false)
      }
    } finally {//
    }
  };

  const debounceGetTelemarketingReportData = useCallback(_.debounce(getTelemarketingReportData, 500), []);
  const debounceGetTelemarketingReportDataTotal = useCallback(_.debounce(getTelemarketingReportDataTotal, 500), []);

  useEffect(() => {
    debounceGetTelemarketingReportData(param);
    debounceGetTelemarketingReportDataTotal(param);
  }, [param]);


  function processData(group, filterConfig, data) {
    let r = [];

    r = group.map((g) => {
      return {
        name: g.name,
        key: g.key,
        type: g.type,
        value: filterConfig[g.type]
          ?.filter((f) => f.display_config === 1)
          ?.flatMap((f) => {
            return data.find((item) => {
              if (item.id) {
                return f.id === item.id && g.key === item.typeGroup;
              }
              if (item.code) {
                return f.code === item.code && g.key === item.typeGroup;
              }
            });
          })
          ?.filter((item) => !!item),
      };
    });

    return r;
  }

  const handleRenderReportItem = (displayFieldConfigGetAPI, telemarketingReportDataTotal) => {
    const displayFieldConfig = processData(TYPE_GROUP_CONFIGS, displayFieldConfigGetAPI, telemarketingReportDataTotal);

    return (<div style = {{width: "100%"}}>
     { <Spin spinning={loadingReport}   tip="Loading...">
      <TabsStyled
             type="card"
             onTabClick={() => {}}
             onChange={(activeKey) => {
               mixpanel.track(`changeTab employeeReport - ${activeKey} `);
             }}
           >
             {displayFieldConfig.map((f, idx, arr) => {
               return (
                 <Tabs.TabPane tab={f.name} key={f.type}>
                   <Row gutter={[16, 16]} style={{ marginBottom: '10px' }}>
                     {f?.value?.map((item, index) => {
                       return (
                         <Col key={index} xxl={4} xl={4} lg={4} md={6} sm={8} xs={12}>
                           <ReportItem
                             timeFilterType={param.type}
                             dataName={item.dataName}
                             currentStatistics={item.currentStatistics}
                             previousStatistics={item.previousStatistics}
                             ratio={item.ratio}
                             statisticalType={item.type}
                           />
                         </Col>
                       );
                     })}
                   </Row>
                 </Tabs.TabPane>
               );
             })}
           </TabsStyled>
           </Spin>}
      
    </div>
     
     
    );
  };

  const memoHandleRenderReportItem = useMemo(
    () => handleRenderReportItem(displayFieldConfigGetAPI, telemarketingReportDataTotal),
    [displayFieldConfigGetAPI, telemarketingReportDataTotal,loadingReport]
  );
  return (
    <>
      <div className="content-wrapper">
        <BreadcrumbAntStyle>
          <Breadcrumb.Item>{VietnameseString.telemarketing_report}</Breadcrumb.Item>
        </BreadcrumbAntStyle>

        <Wrapper>
          <Filter
            param={param}
            timeFilterType={param.type}
            setTimeFilterType={(type) => {
              const handle = (key) => {
                setSearchParamsInUrlVer2({
                  from: moment(new Date()).startOf(key).format('YYYY/MM/DD'),
                  to: moment(new Date()).endOf(key).format('YYYY/MM/DD'),
                  type: type,
                  page: 1,
                });
                setParam({
                  ...param,
                  type,
                  page: 1,
                  from: moment(new Date()).startOf(key).format('YYYY/MM/DD'),
                  to: moment(new Date()).endOf(key).format('YYYY/MM/DD'),
                });
              };

              switch (type) {
                case TIME_CODE_TYPE.DATE:
                  setSearchParamsInUrlVer2({
                    type,
                    page: 1,
                    from: moment(new Date()).format('YYYY/MM/DD'),
                    to: moment(new Date()).format('YYYY/MM/DD'),
                  });
                  setParam({
                    ...param,
                    type,
                    page: 1,
                    from: moment(new Date()).format('YYYY/MM/DD'),
                    to: moment(new Date()).format('YYYY/MM/DD'),
                  });
                  break;

                case TIME_CODE_TYPE.WEEK:
                  handle('week');
                  break;

                case TIME_CODE_TYPE.MONTH:
                  handle('month');
                  break;

                case TIME_CODE_TYPE.QUARTER:
                  handle('quarter');
                  break;

                case TIME_CODE_TYPE.YEAR:
                  handle('year');
                  break;

                default:
                  break;
              }
            }}
            setParam = {setParam}
            handleChangeParamTelemarketingReport={handleChangeParamTelemarketingReport}
            handleChangeParamTimeTelemarketingReport={handleChangeParamTimeTelemarketingReport}
            handleChangeTypeOfInteraction = {handleChangeTypeOfInteraction}
         />

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  margin: '10px 0px',
                }}
              >
                <span style={{ fontSize: '16px' }}>
                  {param.type === TIME_CODE_TYPE.DATE ? (
                    <>Dữ liệu trong ngày {moment(param.from).format('DD/MM/YYYY')}</>
                  ) : (
                    <>
                      Dữ liệu từ ngày {moment(param.from).format('DD/MM/YYYY')} đến{' '}
                      {moment(param.to).format('DD/MM/YYYY')}
                    </>
                  )}
                </span>

                {/* <ButtonSystemStyle
                  bgButtonColor={greenColor}
                  bgButtonColorHover={greenColorHover}
                  // loading={isLoadingButtonExport}
                  // disabled={isLoadingButtonExport}
                  onClick={() => {
                    // notifySuccess('Vui lòng đợi xử lí trong ít phút');
                    // downloadExcelFile();
                  }}
                >
                  {isLoadingButtonExport ? <SpinInButton /> : <ExportOutlined style={{ marginRight: '10px' }} />}
                  Xuất báo cáo
                </ButtonSystemStyle> */}
              </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]} style={{ marginBottom: '10px' }}>
            {memoHandleRenderReportItem}
          </Row>

          <Row gutter={[16, 16]} style={{ marginBottom: '10px' }} align="middle" justify="space-between">
            <Col>
              <span style={{ fontSize: '16px' }}>Chi tiết NV Kinh doanh</span>
            </Col>
            <Col>
              <i
                className="far fa-sun"
                style={{ fontSize: '28px', cursor: 'pointer' }}
                onClick={() => setVisibleDisplayConfiguration(true)}
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]} style={{ marginBottom: '10px' }}>
            <TableBorderedAntStyle
              loading={isLoading}
              columns={columns || []}
              dataSource={telemarketingReportData}
              scroll={{
                x: 'max-content',
              }}
              pagination={{
                showSizeChanger: false,
                ...paging,
                onChange: async (page, pageSize) => {
                  setParam({ ...param, page });
                  setSearchParamsInUrlVer2({ page });
                },
              }}
              locale={{
                // emptyText: <Empty image={<IconLoading />} description={<span>Đang tải dữ liệu...</span>} />,
                triggerDesc: '',
                triggerAsc: '',
                cancelSort: '',
              }}
            />
          </Row>
        </Wrapper>
      </div>

      {visibleDisplayConfiguration && (
        <ModalStyle
          width={600}
          title="Cấu hình hiển thị"
          visible={visibleDisplayConfiguration}
          onCancel={() => {
            setVisibleDisplayConfiguration(false);
            setScaleDisplayConfig({
              ...scaleDisplayConfig,
              scaleDisplayCustomer: scaleDisplayConfigGetAPI.scaleDisplayCustomer,
              scaleDisplayTag: scaleDisplayConfigGetAPI.scaleDisplayTag,

            });
            setDisplayFieldConfigGetAPI(JSON.parse(localStorage.getItem(`init`)))
          }}
          maskClosable={false}
          footer={[
            <Button
              key="back"
              onClick={() => {
                setVisibleDisplayConfiguration(false);
                setScaleDisplayConfig({
                  ...scaleDisplayConfig,
                  scaleDisplayCustomer: scaleDisplayConfigGetAPI.scaleDisplayCustomer,
                  scaleDisplayTag: scaleDisplayConfigGetAPI.scaleDisplayTag,
                });
              setDisplayFieldConfigGetAPI(JSON.parse(localStorage.getItem(`init`)))
            
            }}
            >
              Huỷ
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={async () => {
                try {
                  mixpanel.track('DisplayConfig salesReport', {
                    ...displayFieldConfig,
                    Ratio: [...Object.values(scaleDisplayConfig)],
                  });
                  setIsLoadingButton(true);
                  await requestUpdateConfigDisplayTelemarketing({
                    ...displayFieldConfig,
                    Ratio: [...Object.values(scaleDisplayConfig)],
                  });
                  setVisibleDisplayConfiguration(false);
                  await getConfigDisplayTelemarketing();
                } catch (error) {
                } finally {
                  setIsLoadingButton(false);
                }
              }}
              loading={isLoadingButton}
            >
              Xác nhận
            </Button>,
          ]}
        >
          <>
            <div style={{ width: '100%', display: 'flex' }}>
              <div style={{ width: '50%', textAlign: 'center', fontSize: '20px', fontWeight: 600 }}>
                {VietnameseString.display_data}
              </div>

              <div style={{ width: '50%', textAlign: 'center', fontSize: '20px', fontWeight: 600 }}>
                {VietnameseString.existing_data}
              </div>
            </div>
            <Collapse
              bordered={false}
              defaultActiveKey={['']}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              className="site-collapse-custom-collapse"
            >
              {configTelemarketingReportPositionField.map((field) => {
                if (field === 'Ratio') return;
                return (
                  <Collapse.Panel
                    header={
                      <span>
                        {field === FIELD_CONFIG_TELEMARKETING_REPORT.CALL
                          ? VietnameseString.call
                          : field === FIELD_CONFIG_TELEMARKETING_REPORT.CONTRACT
                          ? VietnameseString.contract
                          : field === FIELD_CONFIG_TELEMARKETING_REPORT.CUSTOMER
                          ? VietnameseString.customer
                          : field === FIELD_CONFIG_TELEMARKETING_REPORT.OPPORTUNITY
                          ? VietnameseString.opportunity
                          : field === FIELD_CONFIG_TELEMARKETING_REPORT.TAGS
                          ? VietnameseString.classify
                          : ''}
                      </span>
                    }
                    key={field}
                    className="site-collapse-custom-panel"
                  >
                    <DragAndDropConfiguration
                      title={field}
                      listFieldConfigGetAPI={displayFieldConfigGetAPI[field] || []}
                      scaleDisplayConfig={scaleDisplayConfig}
                      handleChangeScaleDisplay={(field) => {
                        setScaleDisplayConfig({
                          ...scaleDisplayConfig,
                          [field]: scaleDisplayConfig[field] ? 0 : 1,
                        });
                      }}
                      handleChange={(value) => {
                        const displayFieldConfigNew = handleChangePosition(displayFieldConfigGetAPI, value, field);
                        setDisplayFieldConfig(displayFieldConfigNew);
                      }}
                    />
                  </Collapse.Panel>
                );
              })}
            </Collapse>

            <div style={{ marginBottom: '0px', marginTop: '10px' }}>
              <FontAwesomeIcon icon={faLightbulb} style={{ marginRight: '5px', color: 'yellowgreen' }} size="2x" />
              <span style={{ color: 'red' }}>Kéo thả để thay đổi thứ tự hiển thị.</span>
            </div>
          </>
        </ModalStyle>
      )}
    </>
  );
}

const handleChangePosition = (displayFieldConfigGetAPI, itemDisplayFieldConfigUI, field) => {
  const displayFieldConfigNew = displayFieldConfigGetAPI[field].map((f) => {
    if (f.code) {
      const item = itemDisplayFieldConfigUI.find((i) => i.code === f.code);
      return { ...f, position: item.position, display_config: item.display_config };
    }
    if (f.id) {
      const item = itemDisplayFieldConfigUI.find((i) => parseInt(i.id) === f.id);
      return { ...f, position: item.position, display_config: item.display_config };
    }
  });

  displayFieldConfigGetAPI[field] = _.sortBy(displayFieldConfigNew, 'position');

  return displayFieldConfigGetAPI;
};
