import { Button, Checkbox, Form, Input, Modal, Spin } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import UploadAntd from '@components/upload/Upload';
import {
  ButtonStyledBorder,
  ModalStyled,
  WrapperPosition,
} from './styleTemplate';
import { TableBorderedAntStyle } from '@styles/Style';
import { requestGetParameterWithTemplate } from '@screens/Admin/Company/services/api';
import { useSelector } from 'react-redux';
import { convertDataUpload, getLabelText, getLengthCharacter } from './fnhelper';
import { ApiClient } from '@constants/Api';
import { notifySuccess } from '@utils/notify';
import { notifyFail } from '@utils/notify';

const ModalTemplate = ({
  isVisible,
  setShowModal,
  setIsCallBack,
  handleChangeSelect,
}) => {
  const [form] = Form.useForm();
  const [showModalConfig, setShowModalConfig] = useState(false);
  const [parameterWithTemplate, setParameterWithTemplate] = useState([]);
  const { enterprise_id } = useSelector(redux => redux.userReducer.data);
  const valueTextAreaWatch = Form.useWatch('message', form);
  const [loadingBtn, setLoadingBtn] = useState(false);
  function getDataFromUpload(value, fileList) {
    form.setFieldsValue({
      uploadFile: fileList,
    });
  }
  useEffect(() => {
    const getParameterWithTemplate = async enterpriseId => {
      try {
        const { data } = await requestGetParameterWithTemplate({
          enterpriseId,
        });
        setParameterWithTemplate(
          data.map(el => ({
            id: el.id,
            code: `[${el.code}]`,
            label: el.label,
            key: Math.random(),
          }))
        );
      } catch (error) {
        console.log('Error getParameterWithTemplate: ', { error });
      }
    };
    getParameterWithTemplate(enterprise_id);
  }, [enterprise_id]);


  console.log(`parameterWithTemplate`, parameterWithTemplate)
  const columns = [
    {
      title: 'Mã tham số',
      dataIndex: 'code',
      key: 'code',
      render: (value, record) => {
        return (
          <div onClick={() => handleClickAddTemplate(value, record)}>
            {value}
          </div>
        );
      },
    },
    {
      title: 'Tên tham số',
      dataIndex: 'label',
      key: 'label',
    },
  ];

  function handleClickAddTemplate(value, record) {
    console.log('click', value, record);
    form.setFieldsValue({
      message: `${valueTextAreaWatch || ''}${value}`,
    });
    setShowModalConfig(false);
  }

  async function handleSubmit(value) {
    let payload = {
      message: value.message,
      count_character:   getLengthCharacter(value?.message),
      count_message: 1,
      enterprise_id: enterprise_id,
      is_sample_voucher: value.is_sample_voucher ? 1 : 0,
      uploadFile: value.uploadFile,
    };
    let formDataPayload = convertDataUpload(payload);
    try {
      setLoadingBtn(true);
      let res = await ApiClient.postFormData(
        `sms/messageSaleTemplate`,
        formDataPayload
      );
      if (res.status) {
        notifySuccess('Tạo template thành công');
        setShowModal(false);
        handleChangeSelect(res.data.id);
        setIsCallBack(prev => !prev);
      }
    } catch (error) {
      notifyFail('Có lỗi xảy ra. Vui lòng thử lại!');
    } finally {
      setLoadingBtn(false);
    }

    form.resetFields();
  }

  function handleShowModalAddParam(e) {
    setShowModalConfig(true);
    e.preventDefault();
  }
  function handleCloseModalAddTemplate() {
    setShowModal(false);
    form.resetFields();
  }
  return (
    <Fragment>
      <ModalStyled
        visible={isVisible}
        title={'Thêm Template'}
        onCancel={handleCloseModalAddTemplate}
        footer={null}
        width={'50%'}
      >
        <Spin spinning={loadingBtn} tip="Đang tạo dữ liệu...">
          <Form
            name="create_template"
            autoComplete="off"
            style={{ width: '100%', textAlign: 'left' }}
            labelAlign="left"
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
          >
            <WrapperPosition>
              <Form.Item
                label={getLabelText(valueTextAreaWatch)}
                name="message"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập nội dung tin nhắn',
                  },
                  {
                    validator: async (rule, value) => {
                      console.log(`value`, value);
                      if (/^\s*$/.test(value) && value.length) {
                        throw new Error(
                          'Không cho phép nhập toàn khoảng trắng'
                        );
                      }
                    },
                  },
                ]}
              >
                <Input.TextArea placeholder="Nhập nội dung tin nhắn" rows={4} />
              </Form.Item>
              <button onClick={handleShowModalAddParam}>Thêm tham số</button>
            </WrapperPosition>

            <div className="multi_media">Đa phương tiện</div>
            {valueTextAreaWatch?.includes(`[voucher_code]`) && (
              <Form.Item
                name="is_sample_voucher"
                valuePropName="checked"
                className="checkbox_form"
              >
                <Checkbox>Gửi kèm mẫu voucher</Checkbox>
              </Form.Item>
            )}

            <Form.Item name={'uploadFile'}>
              <UploadAntd getDataFromUpload={getDataFromUpload} />
            </Form.Item>
            <div className="button_wrapper">
              <ButtonStyledBorder danger onClick={handleCloseModalAddTemplate}>
                Hủy bỏ
              </ButtonStyledBorder>
              <ButtonStyledBorder type="primary" htmlType="submit">
                Lưu
              </ButtonStyledBorder>
            </div>
          </Form>
        </Spin>
      </ModalStyled>

      <Modal
        title={'Cấu hình tham số'}
        width={500}
        maskClosable={false}
        visible={showModalConfig}
        footer={null}
        onCancel={() => {
          setShowModalConfig(false);
        }}
      >
        <TableBorderedAntStyle
          columns={columns}
          dataSource={parameterWithTemplate}
        />
      </Modal>
    </Fragment>
  );
};

export default ModalTemplate;
