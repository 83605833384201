import React, { Component } from 'react';
import { requestSaleDetail } from '@constants/Api';
import { withRouter } from 'react-router-dom';
import '@styles/RegisterRequest.css';
import { Row, Col, FormControl, Button, Nav, Table } from 'react-bootstrap';
import { ROUTER, TYPE_MEDIA, USER_ROLE } from '@constants/Constant';
import { connect } from 'react-redux';
import { getTelesaleList } from '@src/redux/actions';
import Loading from '@src/components/Loading';
import reactotron from 'reactotron-react-js';
import _ from 'lodash';
import { toast } from 'react-toastify';
import Toast from '@components/Toast';
import 'react-toastify/dist/ReactToastify.css';
import { Avatar, Image } from 'antd';

class TelesaleDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableInfo: true,
      isLoading: false,
      searchBar: {
        searchKey: '',
        status: '0',
        work: '0',
      },
      detail: {},
    };
  }

  componentDidMount() {
    this.getData();
  }

  notifyFail = (mes) =>
    toast.error(mes, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  async getData() {
    this.setState({ isLoading: true });
    const { id } = this.props.match.params;
    let profile = '',
      front_id = '',
      back_id = '';
    try {
      const res = await requestSaleDetail({ id: id });
      if (res.data.user_sale_media) {
        res.data.user_sale_media.forEach((media) => {
          if (media.type === TYPE_MEDIA.PROFILE_IMAGE) profile = media.url;
          if (media.type === TYPE_MEDIA.BACK_IMAGE) back_id = media.url;
          if (media.type === TYPE_MEDIA.FRONT_IMAGE) front_id = media.url;
        });
      }
      this.setState({
        detail: res.data,
        isLoading: false,
        front_id: front_id,
        back_id: back_id,
        profile: profile,
      });
    } catch (err) {
      this.notifyFail(err.msg);
      this.setState({ isLoading: false });
    }
  }

  renderBody() {
    if (!_.isEmpty(this.state.detail)) {
      return (
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
            <Col md className="bg" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
              {this.renderInfo()}
              {this.renderWorkInfo()}
            </Col>
          </Row>
        </div>
      );
    } else {
      return null;
    }
  }

  renderHeaderButton() {
    const { detail } = this.state;
    if (
      this.props.userState?.data?.role_id === USER_ROLE.ADMIN ||
      this.props.userState?.data?.role_id === USER_ROLE.ENTERPRISE
    ) {
      return (
        <Row className="justify-content-center justify-content-md-end">
          <Button variant="danger" className="header-btn mr-2 rounded">
            Xóa
          </Button>
          <Button
            variant="warning"
            className="header-btn rounded"
            onClick={() => this.props.history.push(ROUTER.TELESALE_EDIT + `/${detail.user_id}`)}
          >
            Sửa
          </Button>
        </Row>
      );
    } else {
      return (
        <Row className="justify-content-center justify-content-md-end">
          <Button variant="danger" className="header-btn mr-2 rounded">
            Xóa
          </Button>
        </Row>
      );
    }
  }

  renderInfo() {
    const sale = this.state.detail;
    console.log(sale);
    const { profile, front_id, back_id } = this.state;
    return (
      <div className="content-header personal-info">
        <Row className="title pl-md-3">
          <Avatar src={sale.user_sale_info.profile_image} size={50} className="bg-blue text-white">
            {sale.user_sale_info.profile_image !== '' ? null : sale.full_name[0].toUpperCase()}
          </Avatar>
          <Col md={8} className="name">
            <h3> {sale && sale.full_name} </h3>
          </Col>
          <Col md={3} className="px-0">
            {this.renderHeaderButton()}
          </Col>
        </Row>
        <br />
        <Row className="info">
          <Col md={6}>
            <Row>
              <Col md={3} xs={6} className="px-0">
                <label>Số điện thoại</label>
              </Col>
              <Col md={9} xs={6} className="px-0">
                <span>{sale && sale.phone}</span>
              </Col>
            </Row>
            <Row>
              <Col md={3} xs={6} className="px-0">
                <label>Email</label>
              </Col>
              <Col md={9} xs={6} className="px-0">
                <span>{sale && sale.email}</span>
              </Col>
            </Row>
            <Row>
              <Col md={3} xs={6} className="px-0">
                <label>Ngày sinh</label>
              </Col>
              <Col md={9} xs={6} className="px-0">
                <span>
                  {sale.user_sale_info && sale.user_sale_info?.dob
                    ? Intl.DateTimeFormat('vi-VN').format(new Date(sale.user_sale_info.dob))
                    : '--'}
                </span>
              </Col>
            </Row>
            <Row>
              <Col md={3} xs={6} className="px-0">
                <label>Giới tính</label>
              </Col>
              <Col md={9} xs={6} className="px-0">
                <span>{sale.user_sale_info && sale.user_sale_info.gender ? 'Nam' : 'Nữ'}</span>
              </Col>
            </Row>
            <Row>
              <Col md={3} xs={6} className="px-0">
                <label>Thành phố</label>
              </Col>
              <Col md={9} xs={6} className="px-0">
                <span>{sale.user_sale_info && sale.user_sale_info.province.name}</span>
              </Col>
            </Row>
            <Row>
              <Col md={3} xs={6} className="px-0">
                <label>Địa chỉ cụ thể</label>
              </Col>
              <Col md={9} xs={6} className="px-0">
                <span>{sale.user_sale_info && sale.user_sale_info.address}</span>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col md={3} xs={6} className="px-0">
                <label>Số CMND</label>
              </Col>
              <Col md={9} xs={6} className="px-0">
                <span>{sale.user_sale_info && sale.user_sale_info.id_number}</span>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="text-center text-md-left mt-0 px-0 pr-0 pr-md-1 mb-2 mb-md-0">
                <Image
                  width={100}
                  src={(front_id && front_id) || require('../../../assets/no-image.png')}
                  style={{ cursor: 'pointer' }}
                />
              </Col>
              <Col md={6} className="text-center text-md-left pl-0 pl-md-1 mb-2 px-0">
                <Image
                  width={100}
                  src={(back_id && back_id) || require('../../../assets/no-image.png')}
                  style={{ cursor: 'pointer', marginLeft: 10 }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3} xs={6} className="px-0">
                <label>Kinh nghiệp</label>
              </Col>
              <Col md={9} xs={6} className="px-0">
                <span>{sale.user_sale_info && sale.user_sale_info.word_experience}</span>
              </Col>
            </Row>
            <Row>
              <Col md={3} xs={6} className="px-0">
                <label>Hồ sơ</label>
              </Col>
              <Col md={9} xs={6} className="px-0">
                <a href={sale.user_sale_info && sale.user_sale_info.cv_url} target="_blank">
                  {sale.user_sale_info && sale.user_sale_info.cv_url ? 'Click here to read' : 'none'}
                </a>
              </Col>
            </Row>
            <Row>
              <Col md={3} xs={6} className="px-0">
                <label>Trạng thái</label>
              </Col>
              <Col md={9} xs={6} className="px-0">
                <span className={sale.user_sale_info && sale.is_active ? 'text-success' : 'text-danger'}>
                  {sale.is_active ? 'Đang hoạt động' : 'Không hoạt động'}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
  // renderRowJob(row, index) {
  //     return (
  //         <tr>
  //             <td>{index}</td>
  //             <td>{row.}</td>
  //             <td>{row.}</td>
  //             <td>{row.}</td>
  //             <td>{row.}</td>
  //         </tr>
  //     )
  // }

  renderWorkInfo() {
    const { tableInfo, detail } = this.state;

    return (
      <>
        <Row>
          <Col md={6}>
            <h4>Thông tin công việc</h4>
          </Col>
        </Row>

        <Row className="content-header table-tabs pl-0 pb-0">
          <Col md={12}>
            <Nav variant="tabs" defaultActiveKey="general-info">
              <Nav.Item>
                <Nav.Link eventKey="general-info" onSelect={() => this.showTableInfo(true)}>
                  Thông tin chung
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="call-history" onSelect={() => this.showTableInfo(false)}>
                  Lịch sử chăm sóc
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row className="">
          <Col md={12} className="bg-white pt-4">
            {tableInfo ? this.renderGeneralInfo(detail) : this.renderCallHistory(detail)}
          </Col>
        </Row>
      </>
    );
  }

  renderGeneralInfo(detail) {
    return (
      <>
        <Row className="pb-3">
          <Col md={2} xs={6}>
            <span>Kinh nghiệm</span>
          </Col>
          <Col md={1} xs={6} className="text-center text-md-left">
            <b className="waiting">{detail.user_sale_info && detail.user_sale_info.reward_point}</b>
          </Col>
          <Col md={2} xs={6}>
            <span>KPI trung bình</span>
          </Col>
          <Col md={2} xs={6} className="text-center text-md-left">
            <b className="accept">{detail.user_sale_info && detail.user_sale_info.kpi_average}</b>
            <b className="accept"> cuộc/ngày</b>
          </Col>
          <Col md={2} xs={6}>
            <span>Số cuộc gọi trung bình</span>
          </Col>
          <Col md={2} xs={6} className="text-center text-md-left">
            <b className="accept">{detail.user_sale_info && detail.user_sale_info.time_average}</b>
            <b className="accept"> cuộc/ngày</b>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="w-100 table-wrap">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>STT</th>
                    <th>Công việc</th>
                    <th>Số cuộc gọi</th>
                    <th>Số cuộc gọi thành công</th>
                    <th>Trạng thái</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </>
    );
  }

  renderCallHistory(detail) {
    return (
      <>
        <Row>
          <Col>{this.renderField()}</Col>
        </Row>

        {detail && detail.user_sale_status_histories.map((history) => this.renderHistory(history, detail))}
      </>
    );
  }

  renderHistory(history, detail) {
    const date = new Date(history.created_at);
    return (
      <Row className="content-header personal-info">
        <Col md={12}>
          <Row>
            <Col md={8}>
              <Row>
                <Col md={4} className="text-center text-md-left">
                  <label>{detail.full_name}</label>
                </Col>
                <Col md={6} className="text-center text-md-left">
                  <i className="fas fa-ellipsis-v mr-2"></i>
                  <span>{detail.phone}</span>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="text-center text-md-left">
                  <i className="fas fa-briefcase mr-2"></i>
                  <span>Chiến dịch giới thiệu dòng xe mới tới khách hàng tại Hà Nội</span>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="text-center text-md-left">
                  <i className="far fa-clock mr-2 mt-2 mb-3"></i>
                  <span>
                    {date.getHours()}:{date.getMinutes()}{' '}
                  </span>
                  <span>{Intl.DateTimeFormat('vi-VN').format(date)}</span>
                  <i className="fas fa-grip-lines-vertical ml-5 mr-5"></i>
                  <label>4:44s</label>
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <Row>
                <Col md={12} className="text-center pt-1">
                  <i className="fas fa-user mr-2"></i>
                  <span>{history.user.full_name}</span>
                </Col>
                <Col md={12} xs={6} className="text-center pt-0 pt-md-1">
                  <span>Trạng thái</span>
                </Col>
                <Col md={12} xs={6} className="text-center pt-0 pt-md-1">
                  <span className="waiting">Bận, gọi lại sau</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6}>
              <audio controls className="audio-player">
                <source src="horse.mp3" type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </Col>
            <Col md={4} className="offset-0 offset-md-2 text-purple">
              <i className="far fa-thumbtack mr-1"></i>
              <span>
                Đánh giá: {history.review_voice}, {history.review_fluent}, {history.review_comfident},{' '}
                {history.review_other}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  showTableInfo(status) {
    this.setState({
      ...this.state,
      tableInfo: status,
    });
  }

  renderField() {
    const { searchBar } = this.state;
    return (
      <div className="w-100">
        <Row>
          <Col md={5} sm={12} className="pl-0 pr-0 pr-md-2">
            <FormControl
              type="text"
              id=""
              placeholder="Tìm kiếm"
              value={searchBar.searchKey}
              onChange={(e) => this.searchBarChange('searchKey', e.target.value)}
            />
          </Col>
          <Col md={2} sm={12} className="pl-0 pr-0 pr-md-2">
            <FormControl
              as="select"
              id=""
              value={searchBar.city}
              onChange={(e) => this.searchBarChange('work', e.target.value)}
            >
              <option value="0">Công việc</option>
              <option value="1">Hà Nội</option>
              <option value="2">TP HCM</option>
              <option value="3">Bắc Giang</option>
              <option value="4">Bạc Liêu</option>
            </FormControl>
          </Col>
          <Col md={2} sm={12} className="pl-0 pr-0 pr-md-2">
            <FormControl
              as="select"
              id=""
              value={searchBar.status}
              onChange={(e) => this.searchBarChange('status', e.target.value)}
            >
              <option value="0">Trạng thái</option>
              <option value="1">Đã chọn</option>
              <option value="2">Chưa chọn</option>
              <option value="3">Chưa xác định</option>
            </FormControl>
          </Col>
        </Row>
      </div>
    );
  }

  searchBarChange = (field, value) => {
    this.setState({
      ...this.state,
      searchBar: {
        ...this.state.searchBar,
        [field]: value,
      },
    });
  };

  render() {
    const { isLoading } = this.props.telesaleState;
    return (
      <>
        {this.state.isLoading ? <Loading /> : null}
        {this.renderBody()}
        <Toast />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  telesaleState: state.TelesaleReducer,
  userState: state.userReducer,
});

const mapDispatchToProps = {
  getTelesaleList,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TelesaleDetail));
