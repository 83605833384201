export const RESET = 'windsoft/reset';

// USER
export const GET_USER = 'televimer/getUser';
export const GET_USER_SUCCESS = 'televimer/getUserSuccess';
export const GET_USER_FAIL = 'televimer/getUserFail';

// ACCOUNT
export const GET_ACCOUNT = 'televimer/getAccount';
export const GET_ACCOUNT_SUCCESS = 'televimer/getAccountSuccess';
export const GET_ACCOUNT_FAIL = 'televimer/getAccountFail';

// ACCOUNT CREATE
export const CREATE_ACCOUNT = 'televimer/createAccount';
export const CREATE_ACCOUNT_SUCCESS = 'televimer/createAccountSuccess';
export const CREATE_ACCOUNT_FAIL = 'televimer/createAccountFail';

// ACCOUNT EDIT
export const UPDATE_ACCOUNT = 'televimer/updateAccount';
export const UPDATE_ACCOUNT_SUCCESS = 'televimer/updateAccountSuccess';
export const UPDATE_ACCOUNT_FAIL = 'televimer/updateAccountFail';

// TELESALE
export const GET_TELESALE = 'televimer/getTelesale';
export const GET_TELESALE_SUCCESS = 'televimer/getTelesaleSuccess';
export const GET_TELESALE_FAIL = 'televimer/getTelesaleFail';

// TELESALE INACTIVE
export const GET_TELESALE_INACTIVE = 'televimer/getSaleInactive';
export const GET_TELESALE_INACTIVE_SUCCESS = 'televimer/getSaleInactiveSuccess';
export const GET_TELESALE_INACTIVE_FAIL = 'televimer/getSaleInactiveFail';

// PROVINCE
export const GET_PROVINCE = 'televimer/getProvince';
export const GET_PROVINCE_SUCCESS = 'televimer/getProvinceSuccess';
export const GET_PROVINCE_FAIL = 'televimer/getProvinceFail';

// REGISTER LIST
export const GET_REGISTER = 'televimer/getRegister';
export const GET_REGISTER_SUCCESS = 'televimer/getRegisterSuccess';
export const GET_REGISTER_FAIL = 'televimer/getRegisterFail';

// REJECTED REGISTER LIST
export const GET_REJECTED_REGISTER = 'televimer/getRejectedRegister';
export const GET_REJECTED_REGISTER_SUCCESS =
  'televimer/getRejectedRegisterSuccess';
export const GET_REJECTED_REGISTER_FAIL = 'televimer/getRejectedRegisterFail';

// COMPANY
export const GET_COMPANY = 'televimer/getCompany';
export const GET_COMPANY_SUCCESS = 'televimer/getCompanySuccess';
export const GET_COMPANY_FAIL = 'televimer/getCompanyFail';

// JOB CATEGORY
export const GET_CATEGORY = 'televimer/getCategory';
export const GET_CATEGORY_SUCCESS = 'televimer/getCategorySuccess';
export const GET_CATEGORY_FAIL = 'televimer/getCategoryFail';

// HOTLINE CONFIG
export const GET_HOTLINE = 'televimer/getConfigHotline';
export const GET_HOTLINE_SUCCESS = 'televimer/getConfigHotlineSuccess';
export const GET_HOTLINE_FAIL = 'televimer/getConfigHotlineFail';

// CUSTOMER
export const GET_CUSTOMER = 'televimer/getCustomer';
export const GET_CUSTOMER_SUCCESS = 'televimer/getCustomerSuccess';
export const GET_CUSTOMER_FAIL = 'televimer/getCustomerFail';

// CUSTOMER GROUP
export const GET_CUSTOMER_GROUP = 'televimer/getCustomerGroup';
export const GET_CUSTOMER_GROUP_SUCCESS = 'televimer/getCustomerGroupSuccess';
export const GET_CUSTOMER_GROUP_FAIL = 'televimer/getCustomerGroupFail';

// REVIEW CONFIG
export const GET_REVIEW = 'televimer/getConfigReview';
export const GET_REVIEW_SUCCESS = 'televimer/getConfigReviewSuccess';
export const GET_REVIEW_FAIL = 'televimer/getConfigReviewFail';

// JOB
export const GET_JOB = 'televimer/getJob';
export const GET_JOB_SUCCESS = 'televimer/getJobSuccess';
export const GET_JOB_FAIL = 'televimer/getJobFail';

// JOB DETAIL
export const GET_JOB_DETAIL = 'televimer/getJobDetail';
export const GET_JOB_DETAIL_SUCCESS = 'televimer/getJobDetailSuccess';
export const GET_JOB_DETAIL_FAIL = 'televimer/getJobDetailFail';
export const CLEAR_JOB_DETAIL = 'televimer/clearJobDetail';

// JOB CATEGORY
export const GET_CATEGORY_FILTER = 'televimer/getCategoryFilter';
export const GET_CATEGORY_FILTER_SUCCESS = 'televimer/getCategoryFilterSuccess';
export const GET_CATEGORY_FILTER_FAIL = 'televimer/getCategoryFilterFail';

// CUSTOMER JOB
export const GET_CUSTOMER_JOB = 'televimer/getCustomerJob';
export const GET_CUSTOMER_JOB_SUCCESS = 'televimer/getCustomerJobSuccess';
export const GET_CUSTOMER_JOB_FAIL = 'televimer/getCustomerJobFail';

// CUSTOMER GROUP JOB
export const GET_CUSTOMER_GROUP_JOB = 'televimer/getCustomerGroupJob';
export const GET_CUSTOMER_GROUP_JOB_SUCCESS =
  'televimer/getCustomerGroupJobSuccess';
export const GET_CUSTOMER_GROUP_JOB_FAIL = 'televimer/getCustomerGroupJobFail';

// CUSTOMER GROUP STATUS
export const GET_CUSTOMER_GROUP_STATUS = 'televimer/getCustomerGroupStatus';
export const GET_CUSTOMER_GROUP_STATUS_SUCCESS =
  'televimer/getCustomerGroupStatusSuccess';
export const GET_CUSTOMER_GROUP_STATUS_FAIL =
  'televimer/getCustomerGroupStatusFail';

// CUSTOMER GROUP STATUS
export const GET_CALL_HISTORY = 'televimer/getCallHistory';
export const GET_CALL_HISTORY_SUCCESS = 'televimer/getCallHistorySuccess';
export const GET_CALL_HISTORY_FAIL = 'televimer/getCallHistoryFail';

// LIST TYPE JOB
export const GET_TYPE_JOB = 'televimer/getTypeJob';
export const GET_TYPE_JOB_SUCCESS = 'televimer/getTypeJobSuccess';
export const GET_TYPE_JOB_FAIL = 'televimer/getTypeJobFail';

//LIST TUTORIAL
export const GET_LIST_TUTORIAL = 'televimer/getListTutorial';
export const GET_LIST_TUTORIAL_SUCCESS = 'televimer/getListTutorialSuccess';
export const GET_LIST_TUTORIAL_FAIL = 'televimer/getListTutorialFail';

//LIST CALL STATUS
export const GET_CALL_STATUS = 'televimer/getCallStatus';
export const GET_CALL_STATUS_SUCCESS = 'televimer/getCallStatusSuccess';
export const GET_CALL_STATUS_FAIL = 'televimer/getCallStatusFail';

//LIST REVIEWER LIST
export const GET_LIST_REVIEWER = 'televimer/getReviewerList';
export const GET_LIST_REVIEWER_SUCCESS = 'televimer/getReviewerListSuccess';
export const GET_LIST_REVIEWER_FAIL = 'televimer/getReviewerListFail';

//LIST REVIEWER TASK
export const GET_TASK_REVIEWER = 'televimer/getReviewerTask';
export const GET_TASK_REVIEWER_SUCCESS = 'televimer/getReviewerTaskSuccess';
export const GET_TASK_REVIEWER_FAIL = 'televimer/getReviewerTaskFail';

export const GET_GENERAL_QUESTION = 'televimer/getGeneralQuestion';
export const GET_GENERAL_QUESTION_SUCCESS =
  'televimer/getGeneralQuestionSuccess';
export const GET_GENERAL_QUESTION_FAIL = 'televimer/getGeneralQuestionFail';

//KEYWORD
export const GET_KEYWORD = 'televimer/getListKeyword';
export const GET_KEYWORD_SUCCESS = 'televimer/getListKeywordSuccess';
export const GET_KEYWORD_FAIL = 'televimer/getListKeywordFail';

//KEYWORD
export const GET_VPOINT = 'televimer/getListVpoint';
export const GET_VPOINT_SUCCESS = 'televimer/getListVpointSuccess';
export const GET_VPOINT_FAIL = 'televimer/getListVpointFail';

//LIST CALL
export const GET_LIST_CALL = 'televimer/getListCall';
export const GET_LIST_CALL_SUCCESS = 'televimer/getListCallSuccess';
export const GET_LIST_CALL_FAIL = 'televimer/getListCallFail';

//OCCASION
export const GET_OCCASION = 'televimer/getOccasion';
export const GET_OCCASION_SUCCESS = 'televimer/getOccasionSuccess';
export const GET_OCCASION_FAIL = 'televimer/getOccasionFail';

//RESULT REVIEWER
export const GET_RESULT_REVIEWER = 'televimer/getResultReviewer';
export const GET_RESULT_REVIEWER_SUCCESS = 'televimer/getResultReviewerSuccess';
export const GET_RESULT_REVIEWER_FAIL = 'televimer/getResultReviewerFail';

//FILTER
export const GET_JOB_CATEGORY_FILTER = 'televimer/getJobCategoriesFilter';
export const GET_JOB_CATEGORY_FILTER_SUCCESS =
  'televimer/getJobCategoriesFilterSuccess';
export const GET_JOB_CATEGORY_FILTER_FAIL =
  'televimer/getCategoriesJobFilterFail';

export const GET_ENTERPRISES_FILTER = 'televimer/getEnterprisesFilter';
export const GET_ENTERPRISES_FILTER_SUCCESS =
  'televimer/getEnterprisesFilterSuccess';
export const GET_ENTERPRISES_FILTER_FAIL = 'televimer/getEnterprisesFilterFail';

export const GET_TELESALE_FILTER = 'televimer/getTelesalesFilter';
export const GET_TELESALE_FILTER_SUCCESS =
  'televimer/getTelesalesFilterSuccess';
export const GET_TELESALE_FILTER_FAIL = 'televimer/getTelesalesFilterFail';

export const GET_JOB_FILTER = 'televimer/getJobFilter';
export const GET_JOB_FILTER_SUCCESS = 'televimer/getJobFilterSuccess';
export const GET_JOB_FILTER_FAIL = 'televimer/getJobFilterFail';

export const GET_LIST_FOR_SALE = 'televimer/getListForSale';
export const GET_LIST_FOR_SALE_SUCCESS = 'televimer/getListForSaleSuccess';
export const GET_LIST_FOR_SALE_FAIL = 'televimer/getListForSaleFail';

export const GET_QUESTION_ANSWER_FILTER =
  'televimer/getQuessionsAndAnswerFilter';
export const GET_QUESTION_ANSWER_FILTER_SUCCESS =
  'televimer/getQuessionsAndAnswerFilterSuccess';
export const GET_QUESTION_ANSWER_FILTER_FAIL =
  'televimer/getQuessionsAndAnswerFilterFail';

export const GET_TELESALE_KPI = 'televimer/getTelesaleKpi';
export const GET_TELESALE_KPI_SUCCESS = 'televimer/getTelesaleKpiSuccess';
export const GET_TELESALE_KPI_FAIL = 'televimer/getTelesaleKpiFail';

export const GET_USER_STATISTIC = 'televimer/getUserStatistic';
export const GET_USER_STATISTIC_SUCCESS = 'televimer/getUserStatisticSuccess';
export const GET_USER_STATISTIC_FAIL = 'televimer/getUserStatisticFail';

export const GET_CALL_STATISTIC = 'televimer/getCallStatistic';
export const GET_CALL_STATISTIC_SUCCESS = 'televimer/getCallStatisticSuccess';
export const GET_CALL_STATISTIC_FAIL = 'televimer/getCallStatisticFail';

export const GET_JOB_STATISTIC = 'televimer/getJobStatistic';
export const GET_JOB_STATISTIC_SUCCESS = 'televimer/getJobStatisticSuccess';
export const GET_JOB_STATISTIC_FAIL = 'televimer/getJobStatisticFail';

export const GET_JOB_SALE_STATISTIC = 'televimer/getJobSaleStatistic';
export const GET_JOB_SALE_STATISTIC_SUCCESS =
  'televimer/getJobSaleStatisticSuccess';
export const GET_JOB_SALE_STATISTIC_FAIL = 'televimer/getJobSaleStatisticFail';

export const GET_LIST_PACKAGE = 'televimer/getListPackage';
export const GET_LIST_PACKAGE_SUCCESS = 'televimer/getListPackageSuccess';
export const GET_LIST_PACKAGE_FAIL = 'televimer/getListPackageFail';

export const GET_LIST_HOTLINE = 'televimer/getListHotline';
export const GET_LIST_HOTLINE_SUCCESS = 'televimer/getListHotlineSuccess';
export const GET_LIST_HOTLINE_FAIL = 'televimer/getListHotlineFail';

export const GET_PACKAGE_HISTORY = 'televimer/getPackageHistory';
export const GET_PACKAGE_HISTORY_SUCCESS = 'televimer/getPackageHistorySuccess';
export const GET_PACKAGE_HISTORY_FAIL = 'televimer/getPackageHistoryFail';

export const GET_KPI_PROGRESS = 'enterprise/kpiProgress';

export const GET_CALLING_STATUS = 'sale/callingStatus';

export const GET_UPDATE_CALL = 'sale/updateCall';
export const GET_TIME_DURATION = 'sale/timeDuration';

export const GET_STRINGEE_CALL = 'sale/stringee/call';
export const GET_PHONE_NUMBER = 'sale/stringee/call/phoneNumber';

export const GET_IS_NEW_CUSTOMER = 'sale/stringee/isNewCustomer';
export const GET_SHOULD_CALL = 'sale/stringee/shouldCall';
export const GET_DTMF = 'sale/stringee/callDtmf';

export const ADD_PHONE_NUMBER_PICKED = 'add/phoneNumberPicked';

export const GET_NEXT_CUSTOMER = 'sale/getNextCustomer';

export const HANDLE_MAKE_CALL_FROM_STRINGEE = 'sale/stringee/makeCall';

export const GET_LISTHOTLINE_NEW = 'getlisthostline_new';
export const GET_LISTHOTLINE_NEW_SUCCESS = 'getlisthostline_new_success';
