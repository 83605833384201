import {
  GET_LISTHOTLINE_NEW,
  GET_LISTHOTLINE_NEW_SUCCESS as GET_HOTLINES_V2_SUCCESS,
} from '@actions/type';
import CallUtil from '@components/callProviders/CallUtil';

const initialState = {
  dataHotline: {
    defind: {},
    listHotline: [],
  },
};

export default function HotlineReducerV2(state = initialState, action) {
  switch (action.type) {
    case GET_HOTLINES_V2_SUCCESS:
      //save to CallUtil
      CallUtil.hotlines = action.payload.data?.listHotlineOutput;
      return { ...state, dataHotline: action.payload.data };
    default:
      return state;
  }
}
