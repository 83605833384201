import QRCode from 'qrcode.react';
import React from 'react';
import styled from 'styled-components';
const VoucherTemplate = React.memo(({ refProps, hori, children }) => {
  return hori ? (
    <VoucherExHori ref={refProps}>{children}</VoucherExHori>
  ) : (
    <VoucherExVer ref={refProps}>{children}</VoucherExVer>
  );
});

const QRTemplate = React.memo(({ hori, children }) => {
  return hori ? (
    <ContainerQRCodeHoriStyled>{children}</ContainerQRCodeHoriStyled>
  ) : (
    <ContainerQRCodeVerStyled>{children}</ContainerQRCodeVerStyled>
  );
});

const WIDTH_IMAGE_VOUCHER_HORI = 1920 / 2;
const HEIGHT_IMAGE_VOUCHER_HORI = 1080 / 2;
const WIDTH_IMAGE_VOUCHER_VER = 840 / 2;
const HEIGHT_IMAGE_VOUCHER_VER = 1408 / 2;

const TemplateVoucherExport = ({ hori, imgUrl, valueQR, objectcontain }) => {
  const containerImageVoucherRef = React.useRef(null);

  return (
    <div style={{ display: 'flex', justifyContent: 'center'}}>
      <VoucherTemplate refProps={containerImageVoucherRef} hori={hori}>
        <div>
          <img
            style={objectcontain ?{ objectFit: 'contain' } : { objectFit: 'cover' }}
            src={imgUrl}
            alt="image_voucher"
          />
          <QRTemplate hori={hori}>
            <BoxQRcodeStyled>
              <QRCode
                size={hori ? 210 : 190}
                imageSettings="H"
                value={valueQR}
                renderAs="canvas"
              />
              <CodeVoucherStyled>{valueQR}</CodeVoucherStyled>
            </BoxQRcodeStyled>
          </QRTemplate>
        </div>
      </VoucherTemplate>
    </div>
  );
};

export default React.memo(TemplateVoucherExport);

const VoucherExHori = styled.div`
  width: ${WIDTH_IMAGE_VOUCHER_HORI}px;
  height: ${HEIGHT_IMAGE_VOUCHER_HORI}px;
  border: 1px dashed #ccc;
  /* border-radius: 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & img {
    /* border-radius: 20px; */
    width: ${WIDTH_IMAGE_VOUCHER_HORI}px;
    height: ${HEIGHT_IMAGE_VOUCHER_HORI}px;
    object-fit: cover;
  }
`;

const VoucherExVer = styled.div`
  width: ${WIDTH_IMAGE_VOUCHER_VER}px;
  height: ${HEIGHT_IMAGE_VOUCHER_VER}px;
  border: 1px dashed #ccc;
  /* border-radius: 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & img {
    /* border-radius: 20px; */
    width: ${WIDTH_IMAGE_VOUCHER_VER}px;
    height: ${HEIGHT_IMAGE_VOUCHER_VER}px;
    object-fit: cover;
  }
`;

const ContainerQRCodeHoriStyled = styled.div`
  position: absolute;
  right: 92px;
  top: 50.4%;
  transform: translateY(-50%);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContainerQRCodeVerStyled = styled.div`
  position: absolute;
  left: 50%;
  top: 62%;
  transform: translate(-50%, -50%);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BoxQRcodeStyled = styled.div`
  padding: 10px;
`;

const CodeVoucherStyled = styled.div`
  background: #fff;
  border: 1px dashed #ccc;
  border-radius: 6px;
  padding: 2px 0;
  font-weight: bold;
  margin-top: 5px;
  text-align: center;
  font-size: 13px;
`;
