import { Select, Spin } from 'antd';
import { debounce } from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
// import debounce from '../lodash/lodash_debounce';

function DebounceSelect({ fetchOptions, debounceTimeout = 500, defaultOption, ...props }) {
  const [options, setOptions] = useState(defaultOption);
  const fetchRef = useRef(0);

  React.useEffect(() => {
    setOptions(defaultOption);
  }, [defaultOption]);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={<>Không có dữ liệu</>}
      showSearch
      {...props}
      options={options}
    />
  );
}

export default React.memo(DebounceSelect);
