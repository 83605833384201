import React from 'react';

import { VietnameseString } from '@constants/VietnameseString';
import styled from 'styled-components';
import { hooks } from '../../store';

import AccumulatePoints from './components/AccumulatePoints';
import ConfigVoucher from './components/ConfigVoucher';
import DiscountByProduct from './components/DiscountByProduct';
import DiscountByTotalAmount from './components/DiscountByTotalAmount';
import GiftForReferrer from './components/GiftForReferrer';
import GiveAGift from './components/GiveAGift';

const ValueOfTheVoucherContainer = styled.div`
  .gift-container {
    /* margin-top: 12px; */
    /* padding-top: 8px; */
    /* border-top: 1px solid #d7d3d3; */
    display: flex;
    flex-direction: column;

    align-items: start;
    .gift-options {
      display: flex;
      flex-direction: column;

      margin-left: 16px;

      .gift-value {
        display: flex;
        flex-direction: row;

        margin-left: 16px;

        .ant-row.ant-row-middle {
          width: 100%;
        }
      }
    }
  }

  .redeem-points {
    display: flex;
    align-items: center;

    margin-top: 12px;
    padding-top: 8px;
    /* border-top: 1px solid #d7d3d3; */

    font-weight: 600;

    .title-redeem-points {
      /* flex: 1; */
    }

    .input-group {
      flex: 1;
    }

    .site-input-group-wrapper .site-input-split {
      background-color: #fff;
    }

    .site-input-group-wrapper .site-input-right {
      border-left-width: 0;
    }

    .site-input-group-wrapper .site-input-right:hover,
    .site-input-group-wrapper .site-input-right:focus {
      border-left-width: 1px;
    }

    .site-input-group-wrapper .ant-input-rtl.site-input-right {
      border-right-width: 0;
    }

    .site-input-group-wrapper .ant-input-rtl.site-input-right:hover,
    .site-input-group-wrapper .ant-input-rtl.site-input-right:focus {
      border-right-width: 1px;
    }
  }

  /* styled box value voucher */
  & .value_voucher {
    position: relative;
    border: 1px solid #ccc;
    padding: 20px;
  }

  & .title_value_voucher {
    position: absolute;
    background: #fff;
    top: -13px;
    padding: 0 20px;
    left: 10px;
  }
  & .title_value_voucher.required_custom:after {
    display: inline-block;
    margin-right: 4px;
    margin-left: 6px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }

  /* box config voucher */
  & .config_voucher {
    position: relative;
    border: 1px solid #ccc;
    padding: 20px;
    margin-top: 30px;
  }

  & .title_config_voucher {
    position: absolute;
    background: #fff;
    top: -13px;
    padding: 0 20px;
    left: 10px;
  }

  & .title_config_voucher.required_custom::after {
    display: inline-block;
    margin-right: 4px;
    margin-left: 6px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
`;

function ValueOfTheVoucher({
  preferentialType,
  typeVoucher,
  form,
  created_voucher_auto,
}) {
  const [state, dispatch] = hooks.usePromotionsStore();

  return (
    <ValueOfTheVoucherContainer>
      {/* giá trị của voucher */}
      <div className="value_voucher">
        <h5 className="title_value_voucher required_custom">
          {VietnameseString.value_of_the_voucher}
        </h5>

        <div className="value-of-the-voucher">
          {/* giàm giá theo tổng tiền */}
          {preferentialType?.includes('discountByTotalAmount') && (
            <DiscountByTotalAmount typeVoucher={typeVoucher} />
          )}

          {/* Giảm theo sản phẩm */}
          {preferentialType?.includes('discountByProduct') && (
            <DiscountByProduct typeVoucher={typeVoucher} />
          )}

          {/* Tích điểm */}
          {preferentialType?.includes('accumulatePoints') && (
            <AccumulatePoints typeVoucher={typeVoucher} />
          )}

          {/* Tặng quà */}
          {preferentialType?.includes('giveAGift') && <GiveAGift form={form} />}
        </div>

        {/* quà tặng cho người giới thiệu */}
        <GiftForReferrer form={form} preferentialType={preferentialType} />
      </div>

      {/* cấu hình mã voucher */}
      <div className="config_voucher">
        <h5 className="title_config_voucher required_custom">
          {VietnameseString.title_config_voucher}
        </h5>

        <div className="value-of-the-voucher">
          <ConfigVoucher form={form} />
        </div>
      </div>
    </ValueOfTheVoucherContainer>
  );
}

export default ValueOfTheVoucher;
