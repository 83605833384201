import { formatPrice } from '@constants/funcHelper';
import { DescriptionCustomInteractionHistory } from '@styles/Style';
import { Descriptions, Row } from 'antd';
import React from 'react';
import InteractionHistoryHeader from './InteractionHistoryHeader';
import { renderProductName } from './func';

export default function CustomerClosesTheContract({ interactionHistory }) {
  const kindOfOpportunity = ['Bảo Hành', 'Khiếu Nại'];

  const handleRenderCustomerClosesTheContract = interactionHistory => {
    if (
      !kindOfOpportunity.includes(interactionHistory.opportunity_category_name)
    )
      return (
        <>
          <Descriptions.Item label="Sản phẩm">
            {interactionHistory.product.length
              ? renderProductName(interactionHistory.product)
              : 'Chưa cập nhật'}
          </Descriptions.Item>

          <Descriptions.Item label="Doanh số">
            {interactionHistory.sales
              ? formatPrice(interactionHistory.sales) + 'đ'
              : 'Chưa cập nhật'}
          </Descriptions.Item>

          <Descriptions.Item label="Doanh thu">
            {interactionHistory.revenue
              ? formatPrice(interactionHistory.revenue) + 'đ'
              : 'Chưa cập nhật'}
          </Descriptions.Item>
        </>
      );
  };
  const stringConver = interactionHistory.opportunity_status?.split('$');
  return (
    <>
      <InteractionHistoryHeader
        isNavigate={true}
        interactionHistory={interactionHistory}
      />

      <DescriptionCustomInteractionHistory column={2}>
        <Descriptions.Item label="Loại cơ hội">
          {interactionHistory.opportunity_category_name
            ? interactionHistory.opportunity_category_name
            : 'Chưa cập nhật'}
        </Descriptions.Item>

        {handleRenderCustomerClosesTheContract(interactionHistory)}

        <Descriptions.Item style={{ fontWeight: 'unset' }}>
          <div>
            <div style={{ fontWeight: 600 }}>Ghi chú:</div>
            {interactionHistory.opportunity_status
              ? stringConver.map((objTr, index) => (
                  <div
                    key={objTr}
                    style={
                      index === 0 ? { fontWeight: 600 } : { marginLeft: '15px' }
                    }
                  >
                    {objTr}
                  </div>
                ))
              : 'Chưa cập nhật'}
          </div>
        </Descriptions.Item>
      </DescriptionCustomInteractionHistory>
    </>
  );
}
