import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input, message, Modal, Select } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { requestGetPartner, requestGetTypesOfSMS, requestGetTelecom, requestCreateNumberPrefix } from '../services/api';
import { VietnameseString } from '@constants/VietnameseString';

import { ButtonSystemStyle, ButtonTransparentStyle } from '@styles/Style';
import { greenColor, greenColorHover } from '@styles/Color';

const { Option } = Select;

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const ModalStyled = styled(Modal)`
  &&& {
    .ant-modal-body {
      padding: 16px;
    }
  }
`;

const FormStyled = styled(Form)`
  width: 100%;
`;

const ACCEPT_NUMBER_PREFIX_WITH_MULTIMEDIA_MESSAGE = ['VIETTEL', 'MOBIFONE'];

function AddEditNumberPrefix(props) {
  const { enterpriseId, visible, setVisible } = props;

  const [form] = Form.useForm();

  const valueTypesOfSMS = Form.useWatch('valueTypesOfSMS', form);

  const [partners, setPartners] = useState([]);
  const [telecomList, setTelecomList] = useState([]);
  const [typesOfSMS, setTypesOfSMS] = useState([]);

  const [loadingButtonAddNumberPrefix, setLoadingButtonAddNumberPrefix] = useState(false);

  const getInformationNumberPrefix = useCallback(async () => {
    const handleErrorCallAPI = (error) => {
      message.error(error.msg);
      console.log('Error: ', { error });
    };

    const [resultGetPartner, resultGetTelecom, resultGetTypesOfSMS] = await Promise.all([
      requestGetPartner().catch(handleErrorCallAPI),
      requestGetTelecom().catch(handleErrorCallAPI),
      requestGetTypesOfSMS().catch(handleErrorCallAPI),
    ]);

    if (resultGetPartner) {
      form.setFieldsValue({ partner: resultGetPartner.data[0].id });
      setPartners(resultGetPartner.data);
    }

    if (resultGetTelecom) {
      setTelecomList(resultGetTelecom.data);
    }

    if (resultGetTypesOfSMS) {
      setTypesOfSMS(resultGetTypesOfSMS.data);
    }
  }, []);

  useEffect(() => {
    getInformationNumberPrefix();
  }, []);

  const handleCancelModal = () => {
    form.resetFields();
    setVisible(false);
  };

  const handleAddNumberPrefix = useCallback(async (values, enterpriseId) => {
    const { valueTypesOfSMS, numberPrefix, account, password, partner } = values;

    try {
      setLoadingButtonAddNumberPrefix(true);

      const data = {
        enterpriseId,
        SMSTypeId: JSON.parse(valueTypesOfSMS).id,
        phone: numberPrefix,
        account: String(account).trim(),
        password: String(password).trim(),
        partnerId: partner,
      };

      if (values.brandName) data.brandName = String(values.brandName).trim();

      const { msg } = await requestCreateNumberPrefix(data);
      message.success(msg);
      handleCancelModal();
    } catch (error) {
      message.error(error.msg);
      console.log('Error handleAddNumberPrefix: ', { error });
    } finally {
      setLoadingButtonAddNumberPrefix(false);
    }
  }, []);

  //Tiền tố số với tin nhắn đa phương tiện
  const getDataWithSpecialCarrier = (telecomList, provider) => {
    return telecomList
      .filter((tel) => {
        if (provider) {
          return provider.includes(String(tel.description).toUpperCase());
        } else return true;
      })
      .map((telecom) => (
        <Option key={telecom.id} value={telecom.phone}>
          {telecom.phone} - {telecom.description}
        </Option>
      ));
  };

  return (
    <ModalStyled
      title={VietnameseString.add_number_prefix}
      visible={visible}
      maskClosable={false}
      footer={null}
      onCancel={handleCancelModal}
    >
      <FormStyled
        {...layout}
        name="addNumberPrefix"
        labelAlign="left"
        form={form}
        onFinish={(values) => handleAddNumberPrefix(values, enterpriseId)}
      >
        <Form.Item
          name="partner"
          label={VietnameseString.management_partner}
          rules={[{ required: true, message: VietnameseString.please_enter_the_name_of_the_management_partner }]}
        >
          <Select placeholder={VietnameseString.management_partner_name}>
            {partners.map((partner) => (
              <Option key={partner.id} value={partner.id}>
                {partner.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="valueTypesOfSMS"
          label={VietnameseString.sms_type}
          rules={[{ required: true, message: VietnameseString.please_choose_type_of_sms }]}
        >
          <Select placeholder={VietnameseString.sms_type}>
            {typesOfSMS.map((typeOfSMS) => (
              <Option key={typeOfSMS.id} value={JSON.stringify(typeOfSMS)}>
                {typeOfSMS.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {valueTypesOfSMS && (
          <>
            {JSON.parse(valueTypesOfSMS).code !== 'brand' && (
              <Form.Item
                name="numberPrefix"
                label={VietnameseString.number_prefix}
                rules={[{ required: true, message: VietnameseString.please_enter_numeric_prefix }]}
              >
                <Select placeholder={VietnameseString.number_prefix}>
                  {JSON.parse(valueTypesOfSMS).code === 'multimedia'
                    ? getDataWithSpecialCarrier(telecomList, ACCEPT_NUMBER_PREFIX_WITH_MULTIMEDIA_MESSAGE)
                    : getDataWithSpecialCarrier(telecomList)}
                </Select>
              </Form.Item>
            )}

            {(JSON.parse(valueTypesOfSMS).code === 'brand' || JSON.parse(valueTypesOfSMS).code === 'multimedia') && (
              <Form.Item
                name="brandName"
                label={VietnameseString.brand_name}
                rules={[
                  { required: true, message: VietnameseString.please_enter_brand_name },
                  { whitespace: true, message: VietnameseString.please_enter_brand_name },
                ]}
              >
                <Input placeholder={VietnameseString.brand_name} />
              </Form.Item>
            )}

            <Form.Item
              name="account"
              label={VietnameseString.account}
              rules={[
                { required: true, message: VietnameseString.please_enter_account },
                { whitespace: true, message: VietnameseString.please_enter_account },
              ]}
            >
              <Input placeholder={VietnameseString.account} />
            </Form.Item>

            <Form.Item
              name="password"
              label={VietnameseString.password}
              rules={[
                { required: true, message: VietnameseString.please_enter_password },
                { whitespace: true, message: VietnameseString.please_enter_password },
                // { min: 6, message: VietnameseString.please_enter_a_password_longer_than_6_characters },
              ]}
            >
              <Input.Password placeholder={VietnameseString.password} />
            </Form.Item>
          </>
        )}

        <Form.Item {...tailLayout}>
          <ButtonSystemStyle
            bgButtonColor={greenColor}
            bgButtonColorHover={greenColorHover}
            style={{ marginRight: '12px' }}
            htmlType="submit"
            loading={loadingButtonAddNumberPrefix}
          >
            {VietnameseString.add_number_prefix}
          </ButtonSystemStyle>

          <ButtonTransparentStyle htmlType="button" onClick={handleCancelModal}>
            {VietnameseString.cancel}
          </ButtonTransparentStyle>
        </Form.Item>
      </FormStyled>
    </ModalStyled>
  );
}

AddEditNumberPrefix.propTypes = {
  enterpriseId: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};

export default AddEditNumberPrefix;
