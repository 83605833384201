import React from 'react';
import { Col, DatePicker, Row, Select } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

import TagCustom from '@screens/Enterprise/Customer/components/TagCustom';
import MultiSelectControl from '@components/ui/forms/MultiSelectControl';
import { VietnameseString } from '@constants/VietnameseString';
import { formatTime } from '@constants/funcHelper';
import {
  OPTION_SCHEDULE_CALL_BACK,
  TIME_RECALL_STATUS,
} from '@screens/Enterprise/Job/Constants';
import {
  requestCustomerJobList,
  requestGetCallStatus,
  requestTelesaleDetail,
} from '@constants/Api';

import { TableBorderedAntStyle } from '@styles/Style';
import CallUtil from '@components/callProviders/CallUtil';

const { RangePicker } = DatePicker;

const colSpan = { xxl: 4, xl: 4, lg: 6, md: 12, sm: 12, xs: 12 };
const formatDateTime = 'YYYY/MM/DD';

function Customers(props) {
  const {
    jobInfo,
    customerParams,
    customerPaging,
    handleChangeCustomerParams,
    handleChangeCustomerPaging,
    handleChangePage,
  } = props;

  const [isTableLoading, setIsTableLoading] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
 
  const columns = [
    
    {
      title: VietnameseString.index,
      dataIndex: '',
      key: '',
      render: (_, __, index) => (
        <span>
          {(customerPaging.current - 1) * customerPaging.pageSize + index + 1}
        </span>
      ),
    },
    {
      title: VietnameseString.full_name,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: VietnameseString.phoneNumber,
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Trạng thái cuộc gọi',
      dataIndex: 'df_call_status_name',
      key: 'df_call_status_name',
    },
    {
      title: 'Tương tác gần nhất',
      dataIndex: 'action_name',
      key: 'action_name',
    },
    {
      title: VietnameseString.classify,
      dataIndex: 'customer_tags',
      key: 'customer_tags',
      render: tags => {
        return (
          <span style={{ display: 'inline-flex' }}>
            {tags.length ? (
              <>
                {tags.map((i, idx) => {
                  if (idx < 3) {
                    return (
                      <TagCustom
                        key={i.tag_id}
                        tagColor={i.tag_color}
                        tagName={i.tag_name}
                      />
                    );
                  } else return <></>;
                })}
                {tags.length > 3 ? (
                  <TagCustom tagColor={''} tagName={`+ ${tags.length - 3}`} />
                ) : (
                  <></>
                )}
              </>
            ) : (
              VietnameseString.not_update
            )}
          </span>
        );
      },
    },

    {
      title: VietnameseString.time,
      dataIndex: 'created_at',
      key: 'created_at',
      render: time => <span>{formatTime(time)}</span>,
    },
    {
      title: VietnameseString.status,
      dataIndex: 'status_name',
      key: 'status_name',
    },
  ];

  const getCustomerListInJob = React.useCallback(async function (
    params = customerParams
  ) {
    try {
      setIsTableLoading(true);

      //nếu chọn lịch hẹn gọi lại thì params thêm property time_recall_status = 1
      //nếu không chọn lịch hẹn gọi lại thì params thêm property time_recall_status = 0
      const isScheduleCallBack = params.call_status_id.includes(
        OPTION_SCHEDULE_CALL_BACK
      );

      if (isScheduleCallBack) {
        params.call_status_id = params.call_status_id.filter(
          el => el != OPTION_SCHEDULE_CALL_BACK
        );
        params.time_recall_status = TIME_RECALL_STATUS.APPROVE;
      } else {
        params.time_recall_status = TIME_RECALL_STATUS.NONE;
      }

      const { data, paging } = await requestCustomerJobList(params);
      setTableData(data);
      handleChangeCustomerPaging({
        total: paging.totalItemCount,
        current: paging.page,
        pageSize: paging.limit,
      });
    } catch (error) {
      console.log('Error getCustomerListInJob: ', { error });
    } finally {
      setIsTableLoading(false);
    }
  },
  []);

  React.useEffect(() => {
    if (jobInfo.job_id) {
      getCustomerListInJob({ ...customerParams, job_id: jobInfo.job_id });
    }
  }, [jobInfo.job_id, customerParams, getCustomerListInJob]);

  return (
    <div>
      <Filter
        jobInfo={jobInfo}
        params={customerParams}
        handleChangeParam={handleChangeCustomerParams}
      />

      <div style={{ margin: '8px 0', fontWeight: 700 }}>
        Kết quả lọc: {customerPaging.total}
      </div>

      <TableBorderedAntStyle
        loading={isTableLoading}
        columns={columns}
        dataSource={tableData}
        pagination={{
          ...customerPaging,
          showSizeChanger: false,
          onChange: page => {
            handleChangePage(page);
          },
        }}
      />
    </div>
  );
}

function Filter(props) {
  const { jobInfo, params, handleChangeParam } = props;

  const [callStatus, setCallStatus] = React.useState([]);
  const [customerStatusInJob, setCustomerStatusInJob] = React.useState([]);

  async function getCallStatus() {
    try {
      // const { data } = await requestGetCallStatus();
      let data = CallUtil?.listCallStatus;
      const temp = data.map(sub => ({
        label: sub.name,
        value: sub.id,
      }));
      temp.push({
        label: 'Lịch hẹn gọi lại',
        value: OPTION_SCHEDULE_CALL_BACK,
      });

      setCallStatus(temp);
    } catch (error) {
      console.log('Error getCallStatus: ', { error });
    }
  }

  async function getCustomerStatusInJob(jobId) {
    try {
      const { data } = await requestTelesaleDetail({
        job_id: jobId,
        status: '',
      });

      const temp = data.status.filter(
        s => s.name === 'Đã gọi' || s.name === 'Đã nhận việc'
      );
      if (temp.length)
        setCustomerStatusInJob(() => {
          return temp.map(i => {
            if (i.name === 'Đã nhận việc') {
              return {
                ...i,
                name: 'Chưa gọi',
              };
            }
            return i;
          });
        });
    } catch (error) {
      console.log('Error getCustomerStatusInJob: ', { error });
    }
  }

  React.useEffect(() => {
    getCallStatus();

    if (jobInfo.job_id) {
      getCustomerStatusInJob(jobInfo.job_id);
    }
  }, [jobInfo.job_id]);

  return (
    <Row gutter={[16, 16]}>
      <Col {...colSpan}>
        <Select
          style={{ width: '100%' }}
          allowClear
          value={params.status || null}
          placeholder="Trạng thái"
          onChange={value => {
            handleChangeParam({ status: value || '' });
          }}
        >
          {customerStatusInJob.map(s => (
            <Select.Option key={s.id} value={s.id}>
              {s.name}
            </Select.Option>
          ))}
        </Select>
      </Col>

      <Col {...colSpan}>
        <MultiSelectControl
          className="w-100"
          options={callStatus}
          value={params.call_status_id}
          onChange={value => {
            handleChangeParam({ call_status_id: value });
          }}
          placeholder="Trạng thái cuộc gọi"
        />
      </Col>

      <Col xxl={8} xl={12} lg={12} md={12} sm={12} xs={12}>
        <RangePicker
          style={{ width: '100%' }}
          allowClear
          value={
            params.fromDate &&
            params.toDate && [moment(params.fromDate), moment(params.toDate)]
          }
          disabledDate={current => {
            return (
              current &&
              (current < moment(jobInfo?.created_at).startOf('day') ||
                current > moment())
            );
          }}
          onChange={dates => {
            dates
              ? handleChangeParam({
                  fromDate: moment(dates[0]).format(formatDateTime),
                  toDate: moment(dates[1]).format(formatDateTime),
                })
              : handleChangeParam({
                  fromDate: '',
                  toDate: '',
                });
          }}
        />
      </Col>
    </Row>
  );
}

Customers.propTypes = {
  jobInfo: PropTypes.object.isRequired,
};

Filter.propTypes = {
  jobInfo: PropTypes.object.isRequired,
  params: PropTypes.shape({
    job_id: PropTypes.arrayOf(PropTypes.number).isRequired,
    group_id: PropTypes.arrayOf(PropTypes.number).isRequired,
    status: PropTypes.arrayOf(PropTypes.number).isRequired,
    fromDate: PropTypes.string.isRequired,
    toDate: PropTypes.string.isRequired,
    page: PropTypes.number.isRequired,
  }),
  handleChangeParam: PropTypes.func.isRequired,
};

export default Customers;
