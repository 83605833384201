import { Breadcrumb, Button, Col, Form, message, Row, Spin } from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Wrapper from '@components/ui/wrapper/Wrapper';
import { requestListProduct } from '@constants/Api';
import { ROUTER } from '@constants/Constant';
import { VietnameseString } from '@constants/VietnameseString';
import { redColor, redColorHover } from '@styles/Color';
import {
  BreadcrumbAntStyle,
  ButtonSystemStyle,
  TagHyperlink,
} from '@styles/Style';
import moment from 'moment';
import styled from 'styled-components';
import General from './components/promotions/General';
import ValueOfTheVoucher from './components/promotions/ValueOfTheVoucher';
import {
  FormStyled,
  HeaderContainer,
  HeadingStyled,
} from './ElectronicVoucherStyled';
import { voucherService } from './service';
import { hooks, PromotionProvider } from './store';
import {
  SET_CONDITION_PRODUCT,
  SET_CONDITION_PURCHASE_TIME_DATE,
  SET_CONDITION_PURCHASE_TIME_HOUR,
  SET_CONFIG_TYPE,
  SET_DETAIL_EVOUCHER,
  SET_PRODUCTS,
  SET_TIME_BUY,
} from './store/constants';

import TemplateVoucher from './components/templateVoucher';

// ghi chú điều kiện áp dụng
// - totalPriceOrder: tổng tiền của đơn hàng
// - product: Sản phẩm
// - timeBuy: Thời gian mua hàng
// - orther: Điều kiện khác

// xử lý điều kiện áp dụng
const handleConditionsApply = (values, globalState) => {
  let dataValues = {};

  // điều kiện áp dụng tổng tiền của đơn hàng
  if (values?.condition_apply?.includes('totalPriceOrder')) {
    dataValues = {
      ...dataValues,
      condition_total_money: values?.condition_total_money,
    };
  }

  // điều kiện áp dụng sản phẩm
  if (values?.condition_apply?.includes('product')) {
    dataValues = {
      ...dataValues,
      condition_product: globalState?.condition_product.map(item => ({
        name: item?.productName,
        product_id: item?.product_id,
        amount: item?.valueQuantity || item?.quantity,
        money: item?.valuePrice || item?.price,
        key_id: item?.key_id,
      })),
    };
  }

  // điều kiện áp dụng thời gian mua hàng
  if (values?.condition_apply?.includes('timeBuy')) {
    if (globalState?.timeBuy === 'date') {
      dataValues = {
        ...dataValues,
        condition_time: globalState?.condition_purchase_time_date?.map(item => {
          return {
            start_at: moment(item?.startDate).format('YYYY-MM-DD HH:mm'),
            finish_at: moment(item?.endDate).format('YYYY-MM-DD HH:mm'),
            key_id: item?.key_id,
          };
        }),
      };
    } else {
      dataValues = {
        ...dataValues,
        condition_time: globalState?.condition_purchase_time_hour?.map(item => {
          return {
            start_at: moment(item?.startDate).format('HH:mm'),
            finish_at: moment(item?.endDate).format('HH:mm'),
            key_id: item?.key_id,
          };
        }),
      };
    }
  }

  if (values?.condition_apply?.includes('orther')) {
    dataValues = {
      ...dataValues,
      condition_other: values?.condition_other,
    };
  }

  return dataValues;
};

// xử lý loại ưu đãi
const handlePreferentialType = values => {
  let dataValues = {};

  // loại ưu đãi giảm giá theo tổng tiền
  if (values?.preferentialType?.includes('discountByTotalAmount')) {
    dataValues = {
      ...dataValues,
      discount_money:
        values?.type_discount_money === 'amountOfMoney'
          ? values?.discount_money
          : values?.discount_money_percent,
      type_discount_money:
        values?.type_discount_money === 'amountOfMoney' ? 1 : 2,
    };
  } else {
    dataValues = {
      ...dataValues,
      discount_money: null,
      type_discount_money: null,
    };
  }

  // loại ưu đãi tích điểm
  if (values?.preferentialType?.includes('accumulatePoints')) {
    dataValues = {
      ...dataValues,
      save_point:
        values?.type_save_point === 'amountOfMoney'
          ? values?.save_point
          : values?.save_point_percent,
      type_save_point: values?.type_save_point === 'amountOfMoney' ? 1 : 2,
    };
  } else {
    dataValues = {
      ...dataValues,
      save_point: null,
      type_save_point: null,
    };
  }

  // loại ưu đãi giảm giá theo sản phẩm
  if (values?.preferentialType?.includes('discountByProduct')) {
    dataValues = {
      ...dataValues,
      discount_product:
        values?.type_discount_product === 'amountOfMoney'
          ? values?.discount_product
          : values?.discount_product_percent,
      type_discount_product:
        values?.type_discount_product === 'amountOfMoney' ? 1 : 2,
    };
  } else {
    dataValues = {
      ...dataValues,
      discount_product: null,
      type_discount_product: null,
    };
  }

  // loại ưu đãi tặng quà
  if (values?.preferentialType?.includes('giveAGift')) {
    dataValues = {
      ...dataValues,
      converted_gift: values?.converted_gift,
      customer_gift: values?.customer_gift,
    };
  } else {
    dataValues = {
      ...dataValues,
      converted_gift: null,
      customer_gift: null,
    };
  }

  return dataValues;
};

// handle the value loại ưu đãi
const handlePreferentialTypeValue = (values, form) => {
  let dataValues = [];
  if (values?.discount_money) {
    dataValues.push('discountByTotalAmount');

    const discount_money_check =
      values?.type_discount_money == 1
        ? {
            discount_money: values?.discount_money,
          }
        : {
            discount_money_percent: values?.discount_money,
          };

    form.setFieldsValue({
      type_discount_money:
        values?.type_discount_money == 1 ? 'amountOfMoney' : 'percentage',
      ...discount_money_check,
    });
  }
  if (values?.save_point) {
    dataValues.push('accumulatePoints');
    const save_point_check =
      values?.type_save_point == 1
        ? {
            save_point: values?.save_point,
          }
        : {
            save_point_percent: values?.save_point,
          };

    form.setFieldsValue({
      type_save_point:
        values?.type_save_point == 1 ? 'amountOfMoney' : 'percentage',
      ...save_point_check,
      start_point: values?.convert_point?.point,
      end_point: values?.convert_point?.money,
    });
  }

  if (values?.recommend_gift?.point) {
    form.setFieldsValue({
      start_point: values?.convert_point?.point,
      end_point: values?.convert_point?.money,
    });
  }

  if (values?.discount_product) {
    dataValues.push('discountByProduct');

    const type_discount_product_check =
      values?.type_discount_product == 1
        ? {
            discount_product: values?.discount_product,
          }
        : {
            discount_product_percent: values?.discount_product,
          };

    form.setFieldsValue({
      type_discount_product:
        values?.type_discount_product == 1 ? 'amountOfMoney' : 'percentage',
      // discount_product: values?.discount_product,
      ...type_discount_product_check,
    });
  }
  if (
    values?.e_voucher_converted_gifts?.length > 0 ||
    values?.e_voucher_customer_gifts?.length > 0
  ) {
    dataValues.push('giveAGift');
    form.setFieldsValue({
      converted_gift: values?.e_voucher_converted_gifts?.map(
        item => item?.product_id
      ),
      customer_gift: values?.e_voucher_customer_gifts?.map(
        item => item?.e_voucher_gift_id
      ),
    });
  }
  return dataValues;
};

// handle the value điều kiện áp dụng
const handleConditionApplyValue = (values, form, dispatch) => {
  let dataValues = [];
  if (values?.condition_total_money) {
    dataValues.push('totalPriceOrder');
    form.setFieldsValue({
      condition_total_money: values?.condition_total_money,
    });
  }
  if (values?.condition_product) {
    dataValues.push('product');

    dispatch({
      type: SET_CONDITION_PRODUCT,
      payload: values?.condition_product?.map((item, index) => {
        form.setFieldsValue({
          [`condition_product_${item?.key_id}`]: item?.product_id,
          [`condition_product_quantity_${item?.key_id}`]: item?.amount,
          [`condition_product_price_${item?.key_id}`]: item?.money,
        });

        return {
          productName: item?.product_id,
          product_id: item?.product_id,
          quantity: item?.amount,
          chooseQuantity: !!item?.amount,
          price: item?.money,
          key_id: item?.key_id,
          choosePrice: !!item?.money,
        };
      }),
    });
  }
  if (values?.condition_time) {
    dataValues.push('timeBuy');
    dispatch({
      type: SET_TIME_BUY,
      payload: values?.condition_time?.[0]?.start_at?.includes('-')
        ? 'date'
        : 'hour',
    });
    if (values?.condition_time?.[0]?.start_at?.includes('-')) {
      dispatch({
        type: SET_CONDITION_PURCHASE_TIME_DATE,
        payload: values?.condition_time
          ? (Array.isArray(values?.condition_time)
              ? values?.condition_time
              : [values?.condition_time]
            )?.map((item, index) => {
              form.setFieldsValue({
                [`condition_product_date_${item?.key_id}`]: [
                  moment(item?.start_at),
                  moment(item?.finish_at),
                ],
              });

              return {
                startDate: moment(item?.start_at),
                endDate: moment(item?.finish_at),
                key_id: item?.key_id,
              };
            })
          : [],
      });
    } else {
      dispatch({
        type: SET_CONDITION_PURCHASE_TIME_HOUR,
        payload: values?.condition_time
          ? (Array.isArray(values?.condition_time)
              ? values?.condition_time
              : [values?.condition_time]
            )?.map((item, index) => {
              form.setFieldsValue({
                [`condition_product_hour_${item?.key_id}`]: [
                  moment(item?.start_at, 'HH:mm'),
                  moment(item?.finish_at, 'HH:mm'),
                ],
              });

              return {
                startDate: moment(item?.start_at, 'HH:mm'),
                endDate: moment(item?.finish_at, 'HH:mm'),
                key_id: item?.key_id,
              };
            })
          : [],
      });
    }
  }
  if (values?.condition_other) {
    dataValues.push('orther');
    form.setFieldsValue({
      condition_other: values?.condition_other,
    });
  }
  return dataValues;
};

function AddEditPromotions() {
  const { id } = useParams();
  const history = useHistory();

  return (
    <PromotionProvider>
      <div className="content-wrapper">
        <BreadcrumbAntStyle>
          <Breadcrumb.Item>
            <TagHyperlink onClick={() => history.push(ROUTER.E_VOUCHER)}>
              {VietnameseString.voucher_management}
            </TagHyperlink>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            {id ? 'Sửa chương trình' : VietnameseString.add_promotions}
          </Breadcrumb.Item>
        </BreadcrumbAntStyle>

        <FormComponent />
      </div>
      <InitState />
    </PromotionProvider>
  );
}

export const FormComponent = React.memo(() => {
  const [state, dispatch] = hooks.usePromotionsStore();
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  // handle form global page voucher
  const [form] = Form.useForm();

  // loại ưu đãi
  const preferentialType = Form.useWatch('preferentialType', form);
  // Giá trị của voucher khi chọn các loại ưu đãi
  const type_discount_money = Form.useWatch('type_discount_money', form);
  const type_save_point = Form.useWatch('type_save_point', form);
  const type_discount_product = Form.useWatch('type_discount_product', form);

  // điều kiện áp dụng
  const conditionApply = Form.useWatch('condition_apply', form);
  const { id } = useParams();

  // xử lý edit
  React.useEffect(() => {
    if (!id) return;
    setLoading(true);

    voucherService
      .detailEVoucher(id)
      .then(({ data: stateLocation }) => {
        console.log('🚀 ~ stateLocation:', stateLocation);
        dispatch({
          type: SET_DETAIL_EVOUCHER,
          payload: {
            ...stateLocation,
          },
        });

        if (stateLocation?.created_voucher_auto) {
          dispatch({
            type: SET_CONFIG_TYPE,
            payload: 'auto',
          });
        } else {
          dispatch({
            type: SET_CONFIG_TYPE,
            payload: 'template',
          });
        }

        form.setFieldsValue({
          // ** Thông tin chung
          // mã chương trình
          code: stateLocation?.code,
          // tên chương trình
          name: stateLocation?.name,
          // loại ưu đãi
          preferentialType: handlePreferentialTypeValue(stateLocation, form),
          // thời gian áp dụng
          applyTime: [
            stateLocation?.start_at ? moment(stateLocation?.start_at) : null,
            stateLocation?.finish_at ? moment(stateLocation?.finish_at) : null,
          ],
          created_code: stateLocation?.created_voucher_auto
            ? 'auto'
            : 'template',

          // ** điều kiện áp dụng
          condition_apply: handleConditionApplyValue(
            stateLocation,
            form,
            dispatch
          ),
          gift_for_referrer:
            stateLocation?.recommend_gift?.point ||
            stateLocation?.recommend_gift?.voucher_id,
          gift_for_referrer_point: stateLocation?.recommend_gift?.point,
          gift_for_referrer_voucher:
            stateLocation?.job_id || stateLocation?.parent_job_id,
          recommend_gift_point: stateLocation?.recommend_gift?.point,
          recommend_gift_voucher_id: stateLocation?.recommend_gift?.voucher_id,
          recommend_job_id: stateLocation?.job_id,
          recommend_parent_job_id: stateLocation?.parent_job_id,

          // ** cấu hình mã voucher
          amount: stateLocation?.created_voucher_auto?.amount,
          amount_code: stateLocation?.created_voucher_auto?.amount_code,
          start_voucher_code:
            !!stateLocation?.created_voucher_auto?.start_voucher_code,
          include: stateLocation?.created_voucher_auto?.is_character
            ? 'is_character'
            : stateLocation?.created_voucher_auto?.is_number
            ? 'is_number'
            : 'is_all',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  // end handle edit

  const onSubmitForm = async values => {
    if (loading) return;
    // Kiểm tra điều kiện áp dụng và trả về dữ liệu
    setLoading(true);
    // xử lý loại ưu đãi
    const dataPreferentialType = handlePreferentialType(values);

    // check điều kiện áp dụng
    const dataConditionApply = handleConditionsApply(values, state);

    // dữ liệu cấu hình
    let dataConfig = {};
    if (state?.configType === 'auto') {
      dataConfig = {
        // sửa thì amount cũ + thêm amount thêm
        amount: id
          ? `${+values?.amount + +(values?.amount_add_new || 0)}`
          : values?.amount,
        amount_code: values?.amount_code,
        start_voucher_code: values?.start_voucher_code ? 1 : 0,
        is_number:
          values?.include === 'is_number' || values?.include === 'is_all'
            ? 1
            : 0,
        is_character:
          values?.include === 'is_character' || values?.include === 'is_all'
            ? 1
            : 0,
      };
    } else {
      try {
        const formData = new FormData();
        formData.append('file', values?.file_config);
        const resCodeConfigVoucher = await voucherService.importExcelVoucher(
          formData
        );

        dataConfig = resCodeConfigVoucher?.data;
      } catch (err) {
        if (err?.msg) {
          message.error(err?.msg);
        } else {
          message.error('Có lỗi xảy ra');
        }
      }
    }

    // xử lý tải mẫu voucher
    let template_image_url = '';

    if (values?.template_voucher) {
      // nếu là ảnh mẫu thì lưu thẳng
      if (typeof values?.template_voucher === 'string') {
        template_image_url = values?.template_voucher;
      } else {
        // xử lý upload file ảnh
        const formData = new FormData();
        formData.append('image', values?.template_voucher);
        const resUploadImage = await voucherService.uploadVoucher(formData);
        template_image_url = resUploadImage?.data || '';
      }
    }

    // recommend_gift
    const recommend_gift = {
      point: values?.recommend_gift_point,
      voucher_id: values?.recommend_gift_voucher_id,
    };

    // convert_point
    const convert_point = values?.start_point && {
      point: values?.start_point,
      money: values?.end_point,
    };

    // quà tặng cho người giới thiệu
    const dataSend = {
      code: values?.code,
      name: values?.name,
      start_at: values?.applyTime?.[0]
        ? moment(values?.applyTime?.[0]).format('YYYY-MM-DD')
        : null,
      finish_at: values?.applyTime?.[1]
        ? moment(values?.applyTime?.[1]).format('YYYY-MM-DD')
        : null,
      recommend_gift,
      convert_point,
      ...dataPreferentialType,
      ...dataConditionApply,
      template_image_url,
      recommend_job_id: values.recommend_job_id,
      recommend_parent_job_id: values.recommend_parent_job_id,
    };

    const checkBetweenConditionTime = date => {
      return moment(date).isBetween(
        moment(values?.applyTime?.[0]).format('YYYY-MM-DD'),
        (values?.applyTime?.[1] ? moment(values?.applyTime?.[1]) : moment())
          .add(values?.applyTime?.[1] ? 1 : 2000, 'days')
          .format('YYYY-MM-DD')
      );
    };

    //

    // xử lý validate thời gian mua hàng trong thời gian áp dụng
    if (
      dataSend.condition_time &&
      dataSend.condition_time?.[0]?.start_at?.includes('-')
    ) {
      const findDateWithinStartFinish = dataSend.condition_time.every(
        (item, index) => {
          if (
            !(
              item.start_at &&
              item.finish_at &&
              checkBetweenConditionTime(item.finish_at) &&
              checkBetweenConditionTime(item.start_at)
            )
          ) {
            form.setFields([
              {
                name: `condition_product_date_${item?.key_id}`,
                errors: [
                  'Thời gian mua hàng không nằm trong thời gian áp dụng',
                ],
              },
            ]);
          }
          //
          return (
            item.start_at &&
            item.finish_at &&
            checkBetweenConditionTime(item.finish_at) &&
            checkBetweenConditionTime(item.start_at)
          );
        }
      );

      if (!findDateWithinStartFinish) return setLoading(false);
    }

    if (state?.configType === 'auto') {
      dataSend.created_voucher_auto = dataConfig;
    } else {
      dataSend.excel_import_url = dataConfig;
    }

    if (!convert_point || !(Object.keys(convert_point).length > 0)) {
      delete dataSend?.convert_point;
    }

    if (dataSend.condition_time) {
      if (
        typeof dataSend.condition_time === 'object' &&
        !Array.isArray(dataSend.condition_time) &&
        dataSend.condition_time !== null
      ) {
        dataSend.condition_time = [dataSend.condition_time];
      }
    }

    if (id) {
      voucherService
        .updateVoucher(dataSend, id)
        .then(res => {
          if (res?.status) {
            message.success('Cập nhật khuyến mãi thành công');
            history.push(ROUTER.E_VOUCHER);
          }
        })
        .catch(err => {
          if (err?.msg) {
            message.error(err?.msg);
          } else {
            message.error('Có lỗi xảy ra');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      voucherService
        .createVoucher(dataSend)
        .then(res => {
          if (res?.status) {
            message.success('Thêm mới khuyến mãi thành công');
            history.push(ROUTER.E_VOUCHER);
          }
        })
        .catch(err => {
          if (err?.msg) {
            message.error(err?.msg);
          } else {
            message.error('Có lỗi xảy ra');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Spin spinning={loading}>
      <FormStyled
        style={{ width: '100%' }}
        form={form}
        labelAlign="left"
        autoComplete="off"
        labelWrap
        name="general"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        scrollToFirstError
        onFinish={onSubmitForm}
        initialValues={{
          type_discount_money: 'amountOfMoney',
          type_save_point: 'amountOfMoney',
          type_discount_product: 'amountOfMoney',
          created_code: 'auto',
          // include: 'is_all',
        }}
      >
        <Wrapper>
          <HeaderContainer>
            <HeadingStyled>
              {id ? 'Sửa chương trình' : VietnameseString.add_promotions}
            </HeadingStyled>
            <Action loading={loading} />
          </HeaderContainer>
          <Row gutter={[28, 28]}>
            <Col span={12}>
              {/* layout form item left */}
              <General options={{ conditionApply }} form={form} />
            </Col>

            <Col span={12}>
              {/* layout form item right */}
              <ValueOfTheVoucher
                form={form}
                typeVoucher={{
                  type_discount_money,
                  type_save_point,
                  type_discount_product,
                }}
                preferentialType={preferentialType}
              />
            </Col>
          </Row>
          {/* mẫu voucher */}
          <FormItemStyled
            name="template_voucher"
            style={{ width: '100%' }}
            wrapperCol={{ span: 24 }}
          >
            <TemplateVoucher form={form} />
          </FormItemStyled>
        </Wrapper>
      </FormStyled>
    </Spin>
  );
});

const InitState = React.memo(() => {
  const [state, dispatch] = hooks.usePromotionsStore();

  const getAllProduct = React.useCallback(async () => {
    try {
      const { data } = await requestListProduct();
      dispatch({
        type: SET_PRODUCTS,
        payload: data,
      });
    } catch (error) {
      console.log('Error getAllProduct: ', { error });
    }
  }, []);

  // const getAllGift = React.useCallback(async () => {
  //   try {
  //     const { data } = await requestListGift();
  //     dispatch({
  //       type: SET_GIFTS,
  //       payload: data,
  //     });
  //   } catch (error) {
  //     console.log('Error getAllGift: ', { error });
  //   }
  // }, []);

  React.useEffect(() => {
    getAllProduct();
    // getAllGift()
  }, []);

  return null;
});

const Action = ({ loading }) => {
  const history = useHistory();
  const { id } = useParams();
  return (
    <div className="actions">
      {/* <ButtonSystemStyle
        bgButtonColor={greenColor}
        bgButtonColorHover={greenColorHover}
        // onClick={() => {
        //   console.log('👿 ~ file: AddEditPromotions.jsx:65 ~ Action ~ state', state);
        // }}
        htmlType="submit"
      >
        {VietnameseString.add}
      </ButtonSystemStyle> */}
      <Button
        style={{ borderRadius: '6px', marginRight: '10px' }}
        type="primary"
        htmlType="submit"
        loading={loading}
      >
        {id ? 'Cập nhật' : VietnameseString.add}
      </Button>

      <ButtonSystemStyle
        bgButtonColor={redColor}
        bgButtonColorHover={redColorHover}
        onClick={() => history.push(ROUTER.E_VOUCHER)}
      >
        {VietnameseString.cancel}
      </ButtonSystemStyle>
    </div>
  );
};

const FormItemStyled = styled(Form.Item)`
  width: 100%;
`;

export default AddEditPromotions;
