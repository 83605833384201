import React from 'react';
import { Descriptions } from 'antd';

import { formatPrice } from '@constants/funcHelper';
import { VietnameseString } from '@constants/VietnameseString';
import InteractionHistoryHeader from './InteractionHistoryHeader';
import { DescriptionCustomInteractionHistory } from '@styles/Style';
import { renderProductName } from './func';
import { ROUTER } from '@constants/Constant';
import { useHistory } from 'react-router-dom';

export default function PotentialCustomers({ interactionHistory }) {
  const kindOfOpportunity = ['Bảo Hành', 'Khiếu Nại'];

  const handleRenderPotentialCustomers = interactionHistory => {
    if (
      !kindOfOpportunity.includes(interactionHistory.opportunity_category_name)
    )
      return (
        <>
          <Descriptions.Item label="Sản phẩm">
            {/* {interactionHistory.product_name ? interactionHistory.product_name : VietnameseString.not_update} */}
            {interactionHistory.product.length
              ? renderProductName(interactionHistory.product)
              : VietnameseString.not_update}
          </Descriptions.Item>

          <Descriptions.Item label="Doanh số dự kiến">
            {interactionHistory.sales
              ? formatPrice(interactionHistory.sales) + 'đ'
              : VietnameseString.not_update}
          </Descriptions.Item>

          <Descriptions.Item label="Doanh thu dự kiến">
            {interactionHistory.revenue
              ? formatPrice(interactionHistory.revenue) + 'đ'
              : VietnameseString.not_update}
          </Descriptions.Item>
        </>
      );
  };
  const stringConver = interactionHistory.opportunity_status?.split('$');

  return (
    <>
      <InteractionHistoryHeader
        isNavigate={true}
        interactionHistory={interactionHistory}
      />

      <DescriptionCustomInteractionHistory column={2}>
        <Descriptions.Item
          label="Loại cơ hội"
          // onClick = {navigateOpportunityDetails(ROUTER.OCCASION_DETAIL, interactionHistory.opportunity_id)}
        >
          {interactionHistory.opportunity_category_name
            ? interactionHistory.opportunity_category_name
            : VietnameseString.not_update}
        </Descriptions.Item>

        {handleRenderPotentialCustomers(interactionHistory)}

        <Descriptions.Item style={{ fontWeight: 'unset' }}>
          <div>
            <div style={{ fontWeight: 600 }}>Ghi chú:</div>
            {interactionHistory.opportunity_status
              ? stringConver.map((objTr, index) => (
                  <div
                    key={index}
                    style={
                      index === 0 ? { fontWeight: 600 } : { marginLeft: '15px' }
                    }
                  >
                    {objTr}
                  </div>
                ))
              : VietnameseString.not_update}
          </div>
        </Descriptions.Item>
      </DescriptionCustomInteractionHistory>
    </>
  );
}
