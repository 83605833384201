import { ExportOutlined } from '@ant-design/icons';
import SpinInButton from '@components/SpinInButton';
import Wrapper from '@components/ui/wrapper/Wrapper';
import {
  requestExportBusinessReport,
  requestGetBusinessReportData,
  requestGetBusinessReportDataTotal,
  requestGetBusinessReportChart,
  requestGetBusinessReportChartWithProduct,
} from '@constants/Api';
import { STATISTICS_TYPE, TIME_CODE_TYPE } from '@constants/Constant';
import {
  formatPrice,
  getSearchParamsInUrl,
  randomColor,
  setSearchParamsInUrlVer2,
} from '@constants/funcHelper';
import { VietnameseString } from '@constants/VietnameseString';
import { greenColor, greenColorHover } from '@styles/Color';
import {
  BreadcrumbAntStyle,
  ButtonSystemStyle,
  TableAntStyle,
} from '@styles/Style';
import { notifyFail, notifySuccess } from '@utils/notify';
import {
  Avatar,
  Breadcrumb,
  Col,
  Empty,
  Row,
  Skeleton,
  Table,
  Tooltip,
} from 'antd';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCancellableApi } from 'src/hooks/useCancellableApi';
import ReportItem from '../components/ReportItem';
import { renderStatistics3 } from '../functionHelper';
import BusinessReportChart from './components/BusinessReportChart';
import Filter from './components/Filter';
import Axios from 'axios';

const columns = [
  {
    title: VietnameseString.telemarketing,
    dataIndex: 'full_name',
    key: 'full_name',
    fixed: 'left',
    width: 180,
    render: (text, record, index) => {
      try {
        const name = text.trim().split(' ');
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              {text && (
                <Avatar
                  src={record.avatar && record.avatar}
                  style={{
                    backgroundColor: randomColor(
                      name[name.length - 1].charAt(0)?.toUpperCase()
                    ),
                    marginRight: '5px',
                  }}
                  size="small"
                >
                  {name[name.length - 1].charAt(0)?.toUpperCase()}
                </Avatar>
              )}
            </div>
            <Tooltip placement="bottomLeft" title={text}>
              <div
                style={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'normal',
                  WebkitLineClamp: 1,
                  width: '100%',
                  textAlign: 'left',
                }}
              >
                {text}
              </div>
            </Tooltip>
          </div>
        );
      } catch (error) {}
    },
  },
  {
    title: VietnameseString.opportunity_KPI,
    dataIndex: '',
    key: '',
    width: 140,
    sorter: {
      compare: (a, b) =>
        a.opportunity.kpi_opportunity - b.opportunity.kpi_opportunity,
      multiple: 1,
    },
    render: (text, record, index) => {
      return <>{renderStatistics3(record, 'opportunity', 'kpi_opportunity')}</>;
    },
  },
  {
    title: VietnameseString.opportunity,
    dataIndex: '',
    key: '',
    width: 140,
    sorter: {
      compare: (a, b) =>
        a.opportunity.total_opportunity - b.opportunity.total_opportunity,
      multiple: 1,
    },
    render: (text, record, index) => {
      return (
        <>{renderStatistics3(record, 'opportunity', 'total_opportunity')}</>
      );
    },
  },
  {
    title: VietnameseString.opportunity_KPI_completion_rate,
    dataIndex: '',
    key: '',
    width: 220,
    sorter: {
      compare: (a, b) =>
        a.opportunity.percent_opportunity_finish -
        b.opportunity.percent_opportunity_finish,
      multiple: 1,
    },
    render: (text, record, index) => {
      return <>{record.opportunity?.percent_opportunity_finish}</>;
    },
  },
  {
    title: VietnameseString.contract_closing,
    dataIndex: '',
    key: '',
    width: 180,
    sorter: {
      compare: (a, b) =>
        a.opportunity.opportunity_success - b.opportunity.opportunity_success,
      multiple: 1,
    },
    render: (text, record, index) => {
      return (
        <>{renderStatistics3(record, 'opportunity', 'opportunity_success')}</>
      );
    },
  },
  {
    title: VietnameseString.contract_closing_rate,
    dataIndex: '',
    key: '',
    width: 200,
    sorter: {
      compare: (a, b) =>
        parseFloat(a.opportunity.percent_opportunity_success) -
        parseFloat(b.opportunity.percent_opportunity_success),
      multiple: 1,
    },
    render: (text, record, index) => {
      return <>{record.opportunity?.percent_opportunity_success}</>;
    },
  },
  {
    title: VietnameseString.sales_KPI,
    dataIndex: '',
    key: '',
    width: 200,
    sorter: {
      compare: (a, b) =>
        a.contract_report.kpi_sales - b.contract_report.kpi_sales,
      multiple: 1,
    },
    render: (text, record, index) => {
      return <>{renderStatistics3(record, 'contract_report', 'kpi_sales')}</>;
    },
  },
  {
    title: VietnameseString.sale,
    dataIndex: '',
    key: '',
    width: 200,
    sorter: {
      compare: (a, b) =>
        a.contract_report.sales_contract - b.contract_report.sales_contract,
      multiple: 1,
    },
    render: (text, record, index) => {
      return (
        <>{renderStatistics3(record, 'contract_report', 'sales_contract')}</>
      );
    },
  },
  {
    title: VietnameseString.revenue,
    dataIndex: '',
    key: '',
    width: 200,
    sorter: {
      compare: (a, b) =>
        a.contract_report.revanue_contract - b.contract_report.revanue_contract,
      multiple: 1,
    },
    render: (text, record, index) => {
      return (
        <>{renderStatistics3(record, 'contract_report', 'revanue_contract')}</>
      );
    },
  },
  {
    title: VietnameseString.sales_completion_rate,
    dataIndex: '',
    key: '',
    width: 200,
    sorter: {
      compare: (a, b) =>
        parseFloat(a.contract_report.percent_revanue_contract_finish) -
        parseFloat(b.contract_report.percent_revanue_contract_finish),
      multiple: 1,
    },
    render: (text, record, index) => {
      return <>{record.contract_report?.percent_revanue_contract_finish}</>;
    },
  },
  {
    title: VietnameseString.average_value_of_one_contract,
    dataIndex: '',
    key: '',
    width: 200,
    sorter: {
      compare: (a, b) =>
        a.contract_report.avg_value_contract -
        b.contract_report.avg_value_contract,
      multiple: 1,
    },
    render: (text, record, index) => {
      return (
        <>
          {renderStatistics3(record, 'contract_report', 'avg_value_contract')}
        </>
      );
    },
  },
  {
    title: VietnameseString.commission_for_sales,
    dataIndex: '',
    key: '',
    width: 200,
    sorter: {
      compare: (a, b) => a.contract_report.epoint - b.contract_report.epoint,
      multiple: 1,
    },
    render: (text, record, index) => {
      return <>{renderStatistics3(record, 'contract_report', 'epoint')}</>;
    },
  },
];

const type_time_code = {
  DATE: 1,
  WEEK: 2,
  MONTH: 3,
  QUARTER: 4,
  YEAR: 5,
  MULTIPLE_MONTH: 6,
  MULTIPLE_QUARTER: 7,
  MULTIPLE_YEAR: 8,
};

export default function BusinessReport() {
  const user = useSelector(states => states.userReducer.data);
  const apiGetBusinessReportData = useCancellableApi(
    requestGetBusinessReportData
  );
  const apiGetBusinessReportDataTotal = useCancellableApi(
    requestGetBusinessReportDataTotal
  );
  const apiGetBusinessReportChart = useCancellableApi(
    requestGetBusinessReportChart
  );
  const apiGetBusinessReportChartWithProduct = useCancellableApi(
    requestGetBusinessReportChartWithProduct
  );

  const [businessReportData, setBusinessReportData] = useState([]);
  const businessReportDataRef = useRef([]);
  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 12,
  });
  const pageRef = useRef({
    total: 0,
    current: 1,
    pageSize: 12,
  });

  const [businessReportDataTotal, setBusinessReportDataTotal] = useState([]);
  const [businessReportChart, setBusinessReportChart] = useState({});
  const [businessReportChartWithProduct, setBusinessReportChartWithProduct] =
    useState([]);

  const [isLoadingTotal, setIsLoadingTotal] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [isLoadingChart, setIsLoadingChart] = useState(false);
  const [isLoadingChartWithProduct, setIsLoadingChartWithProduct] =
    useState(false);

  const [param, setParam] = useState({
    telemarketing_id: [],
    department_id: [],
    customer_data_field_source_code: [],
    list_products: [],
    type: TIME_CODE_TYPE.MONTH,
    typeTimeCode: type_time_code.MONTH,
    from: moment(new Date()).startOf('month').format('YYYY/MM/DD'),
    to: moment(new Date()).endOf('month').format('YYYY/MM/DD'),
    page: paging.current,
    limit: paging.pageSize,
  });

  const [isLoadingButtonExport, setIsLoadingButtonExport] = useState(false);

  useEffect(() => {
    const searchParamsInUrl = getSearchParamsInUrl();
    setParam({ ...param, ...searchParamsInUrl });
  }, []);

  // promise api
  const lastPromise = useRef();
  const lastPromiseApiTotal = useRef();
  const sourceRef = useRef();

  const getBusinessReportData = async payload => {
    setIsLoadingTable(true);
    if (!payload) payload = param;
    try {
      if (sourceRef.current) {
        sourceRef.current.cancel();
      }
      sourceRef.current = Axios.CancelToken.source();

      // fire the api request
      const currentPromise = apiGetBusinessReportData(
        {
          telemarketing_id: payload.telemarketing_id,
          department_id: payload.department_id,
          customer_data_field_source_code:
            payload.customer_data_field_source_code,
          list_products: payload.list_products,
          page: payload.page,
          limit: payload.limit,
          from: payload.from,
          to: payload.to,
          type: payload.type,
        },
        {
          cancelToken: sourceRef.current.token,
        }
      ).then(async data => {
        return data;
      });

      // store the promise to the ref
      lastPromise.current = currentPromise;

      // handle the result with filtering
      currentPromise.then(
        result => {
          // lấy kết quả cuối cùng
          if (currentPromise === lastPromise.current) {
            if (!result?.status) {
              setIsLoadingTable(false);
              return;
            }

            if (payload.page === 1) {
              businessReportDataRef.current = result.data.dataHandel;
              setBusinessReportData(result.data.dataHandel);
            } else {
              const data = [
                ...businessReportDataRef.current,
                ...result.data.dataHandel,
              ];
              businessReportDataRef.current = data;
              setBusinessReportData(data);
            }

            setPaging({
              total: parseInt(result.data.paging.totalItemCount),
              current: parseInt(result.data.paging.page),
              pageSize: parseInt(result.data.paging.limit),
            });
            pageRef.current = {
              total: parseInt(result.data.paging.totalItemCount),
              current: parseInt(result.data.paging.page),
              pageSize: parseInt(result.data.paging.limit),
            };
            setIsLoadingTable(false);
          }
        },
        e => {
          if (currentPromise === lastPromise.current) {
            console.warn('fetch failure', e);
          }
        }
      );
      // .finally(() => setIsLoadingTable(false));
    } catch (error) {
      console.log('Error getBusinessReportData:', { error });
    }
  };
  const getBusinessReportDataTotal = async payload => {
    setIsLoadingTotal(true);

    if (!payload) payload = param;
    try {
      // fire the api request
      const currentPromise = apiGetBusinessReportDataTotal(
        {
          telemarketing_id: payload.telemarketing_id,
          department_id: payload.department_id,
          customer_data_field_source_code:
            payload.customer_data_field_source_code,
          list_products: payload.list_products,
          from: payload.from,
          to: payload.to,
          type: payload.type,
        },
        {
          cancelToken: sourceRef.current.token,
        }
      ).then(async data => {
        return data;
      });

      // store the promise to the ref
      lastPromiseApiTotal.current = currentPromise;

      // handle the result with filtering
      currentPromise.then(
        result => {
          // lấy kết quả cuối cùng
          if (currentPromise === lastPromiseApiTotal.current) {
            if (!result?.status) {
              setIsLoadingTotal(false);
              return;
            }

            setBusinessReportDataTotal(
              _.orderBy(result.data.total_data, 'index', 'asc')
            );

            setIsLoadingTotal(false);
          }
        },
        e => {
          if (currentPromise === lastPromiseApiTotal.current) {
            console.warn('fetch failure', e);
          }
        }
      );
    } catch (error) {
      console.log('Error getBusinessReportDataTotal:', { error });
    }
  };

  const getBusinessReportChart = async payload => {
    if (!payload) payload = param;
    try {
      setIsLoadingChart(true);
      const result = await apiGetBusinessReportChart({
        telemarketing_id: payload.telemarketing_id,
        department_id: payload.department_id,
        customer_data_field_source_code:
          payload.customer_data_field_source_code,
        list_products: payload.list_products,
        from: payload.from,
        to: payload.to,
        typeTimeCode: payload.typeTimeCode,
      });
      setBusinessReportChart(result.data);
    } catch (error) {
      console.log('Error getBusinessReportChart:', { error });
    } finally {
      setIsLoadingChart(false);
    }
  };
  const getBusinessReportChartWithProduct = async payload => {
    if (!payload) payload = param;
    try {
      setIsLoadingChartWithProduct(true);
      const result = await apiGetBusinessReportChartWithProduct({
        telemarketing_id: payload.telemarketing_id,
        department_id: payload.department_id,
        customer_data_field_source_code:
          payload.customer_data_field_source_code,
        list_products: payload.list_products,
        from: payload.from,
        to: payload.to,
        type: payload.type,
      });
      setBusinessReportChartWithProduct(result.data);
    } catch (error) {
      console.log('Error getBusinessReportChartWithProduct:', { error });
    } finally {
      setIsLoadingChartWithProduct(false);
    }
  };

  const debounceGetBusinessReportData = useCallback(
    _.debounce(getBusinessReportData, 500),
    []
  );
  const debounceGetBusinessReportDataTotal = useCallback(
    _.debounce(getBusinessReportDataTotal, 500),
    []
  );
  const debounceGetBusinessReportChart = useCallback(
    _.debounce(getBusinessReportChart, 500),
    []
  );
  const debounceGetBusinessReportChartWithProduct = useCallback(
    _.debounce(getBusinessReportChartWithProduct, 500),
    []
  );

  useEffect(() => {
    debounceGetBusinessReportData(param);
    debounceGetBusinessReportDataTotal(param);
    debounceGetBusinessReportChart(param);
    debounceGetBusinessReportChartWithProduct(param);

    //infinity scroll
    var tableContent = document.querySelector('.ant-table-body');

    const handleScroll = event => {
      let maxScroll = event.target.scrollHeight - event.target.clientHeight;
      let currentScroll = event.target.scrollTop;
      if (currentScroll > maxScroll * 0.95) {
        const pageMax = Math.ceil(
          pageRef.current.total / pageRef.current.pageSize
        );
        if (pageRef.current.current < pageMax) {
          setParam({
            ...param,
            page: pageRef.current.current + 1,
          });
        }
      }
    };

    tableContent.addEventListener('scroll', handleScroll);

    return () => {
      tableContent.removeEventListener('scroll', handleScroll);
    };
  }, [param]);

  const handleChangeParamTelemarketingReport = (field, value) => {
    mixpanel.track(`filter businessReport ${field}: ${value}`);
    if (field !== 'department_id') {
      setParam({
        ...param,
        [field]: value,
        page: 1,
      });
      setSearchParamsInUrlVer2({ [field]: value, page: 1 });
    } else {
      setParam({
        ...param,
        [field]: value,
        telemarketing_id: [],
        page: 1,
      });
      setSearchParamsInUrlVer2({
        [field]: value,
        telemarketing_id: [],
        page: 1,
      });
    }
  };

  const handleChangeParamTimeTelemarketingReport = (from, to) => {
    const fromDate = moment(from);
    const toDate = moment(to);
    let typeTimeCode = param.typeTimeCode;
    switch (param.type) {
      case TIME_CODE_TYPE.DATE:
        typeTimeCode = type_time_code.DATE;
        break;

      case TIME_CODE_TYPE.WEEK:
        typeTimeCode = type_time_code.WEEK;
        break;

      case TIME_CODE_TYPE.MONTH:
        const diffInMonth = toDate.diff(fromDate, 'month');
        if (diffInMonth === 0) {
          typeTimeCode = type_time_code.MONTH;
        } else {
          typeTimeCode = type_time_code.MULTIPLE_MONTH;
        }
        break;

      case TIME_CODE_TYPE.QUARTER:
        const diffInQuarter = toDate.diff(fromDate, 'quarter');
        if (diffInQuarter === 0) {
          typeTimeCode = type_time_code.QUARTER;
        } else {
          typeTimeCode = type_time_code.MULTIPLE_QUARTER;
        }
        break;

      case TIME_CODE_TYPE.YEAR:
        const diffInYear = toDate.diff(fromDate, 'year');
        if (diffInYear === 0) {
          typeTimeCode = type_time_code.YEAR;
        } else {
          typeTimeCode = type_time_code.MULTIPLE_YEAR;
        }
        break;

      default:
        typeTimeCode = param.typeTimeCode;
        break;
    }

    setParam({
      ...param,
      from: from,
      to: to,
      page: 1,
      typeTimeCode,
    });
    setSearchParamsInUrlVer2({ from, to, typeTimeCode, page: 1 });
    mixpanel.track(`filter businessReport from: ${from}/ to: ${to}`);
  };

  const downloadExcelFile = async payload => {
    if (!payload) payload = param;
    try {
      mixpanel.track(`Export excel businessReport`);
      setIsLoadingButtonExport(true);
      const data = await requestExportBusinessReport({
        telemarketing_id: payload.telemarketing_id,
        department_id: payload.department_id,
        customer_data_field_source_code:
          payload.customer_data_field_source_code,
        list_products: payload.list_products,
        from: payload.from,
        to: payload.to,
        type: payload.type,
      });
      // saveAs(data.data, 'business_report.xlsx');
    } catch (e) {
      notifyFail(e?.msg);
    } finally {
      setIsLoadingButtonExport(false);
    }
  };

  return (
    <div className="content-wrapper">
      <BreadcrumbAntStyle>
        <Breadcrumb.Item>{VietnameseString.business_report}</Breadcrumb.Item>
      </BreadcrumbAntStyle>

      <Wrapper>
        <Filter
          param={param}
          timeFilterType={param.type}
          setTimeFilterType={type => {
            const handle = key => {
              setSearchParamsInUrlVer2({
                from: moment(new Date()).startOf(key).format('YYYY/MM/DD'),
                to: moment(new Date()).endOf(key).format('YYYY/MM/DD'),
                typeTimeCode: type_time_code[key.toUpperCase()],
                page: 1,
              });
              mixpanel.track(
                `filter businessReport from_date: ${moment(new Date())
                  .startOf(key)
                  .format('YYYY/MM/DD')}/ to_date: ${moment(new Date())
                  .endOf(key)
                  .format('YYYY/MM/DD')}`
              );
              setParam({
                ...param,
                type,
                typeTimeCode: type_time_code[key.toUpperCase()],
                page: 1,
                from: moment(new Date()).startOf(key).format('YYYY/MM/DD'),
                to: moment(new Date()).endOf(key).format('YYYY/MM/DD'),
              });
            };
            switch (type) {
              case TIME_CODE_TYPE.DATE:
                setSearchParamsInUrlVer2({
                  from: moment(new Date()).format('YYYY/MM/DD'),
                  to: moment(new Date()).format('YYYY/MM/DD'),
                  typeTimeCode: type_time_code.DATE,
                  page: 1,
                });
                mixpanel.track(
                  `filter businessReport from_date: ${moment(new Date()).format(
                    'YYYY/MM/DD'
                  )}/ to_date: ${moment(new Date()).format('YYYY/MM/DD')}`
                );
                setParam({
                  ...param,
                  type,
                  typeTimeCode: type_time_code.DATE,
                  page: 1,
                  from: moment(new Date()).format('YYYY/MM/DD'),
                  to: moment(new Date()).format('YYYY/MM/DD'),
                });
                break;
              case TIME_CODE_TYPE.WEEK:
                handle('week');
                break;
              case TIME_CODE_TYPE.MONTH:
                handle('month');
                break;
              case TIME_CODE_TYPE.QUARTER:
                handle('quarter');
                break;
              case TIME_CODE_TYPE.YEAR:
                handle('year');
                break;
              default:
                break;
            }
          }}
          handleChangeParamTelemarketingReport={
            handleChangeParamTelemarketingReport
          }
          handleChangeParamTimeTelemarketingReport={
            handleChangeParamTimeTelemarketingReport
          }
        />

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '10px 0px',
              }}
            >
              <span style={{ fontSize: '16px' }}>
                {param.type === TIME_CODE_TYPE.DATE ? (
                  <>
                    Dữ liệu trong ngày {moment(param.from).format('DD/MM/YYYY')}
                  </>
                ) : (
                  <>
                    Dữ liệu từ ngày {moment(param.from).format('DD/MM/YYYY')}{' '}
                    đến {moment(param.to).format('DD/MM/YYYY')}
                  </>
                )}
              </span>

              <ButtonSystemStyle
                bgButtonColor={greenColor}
                bgButtonColorHover={greenColorHover}
                loading={isLoadingButtonExport}
                disabled={isLoadingButtonExport}
                onClick={() => {
                  notifySuccess('Vui lòng đợi xử lí trong ít phút');
                  downloadExcelFile(param);
                }}
              >
                {isLoadingButtonExport ? (
                  <SpinInButton />
                ) : (
                  <ExportOutlined style={{ marginRight: '10px' }} />
                )}
                {VietnameseString.export_report}
              </ButtonSystemStyle>
            </div>
          </Col>
        </Row>

        <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
          {isLoadingTotal ? (
            <Skeleton active />
          ) : (
            businessReportDataTotal.map((item, index) => {
              if (item.name !== null) {
                return (
                  <Col key={index} xxl={4} xl={6} lg={6} md={6} sm={8} xs={12}>
                    <ReportItem
                      timeFilterType={param.type}
                      dataName={item.name}
                      currentStatistics={
                        item.total_from_to !== null
                          ? parseFloat(item.total_from_to)
                          : null
                      }
                      previousStatistics={parseFloat(item.total_from_to_before)}
                      ratio={
                        item.total_from_to_before !== null &&
                        parseFloat(item.total_from_to_before) !== 0 &&
                        item.total_from_to !== null
                          ? (
                              ((parseFloat(item.total_from_to) -
                                parseFloat(item.total_from_to_before)) /
                                parseFloat(item.total_from_to_before)) *
                              100
                            ).toFixed(2)
                          : null
                      }
                      statisticalType={item.type}
                    />
                  </Col>
                );
              }
            })
          )}
        </Row>

        <Row
          gutter={[16, 16]}
          style={{ marginBottom: '10px' }}
          align="middle"
          justify="space-between"
        >
          <Col>
            <span style={{ fontSize: '16px' }}>
              {VietnameseString.realization_of_target_revenue}
            </span>
          </Col>
        </Row>

        <Row
          gutter={[16, 16]}
          style={{ marginBottom: '20px', width: '100%' }}
          align="middle"
          justify="space-between"
        >
          <Col span={24}>
            <TableAntStyle
              loading={isLoadingTable}
              columns={columns}
              dataSource={businessReportData}
              // scroll={{ x: 'max-cont ent' }}
              scroll={{
                y: 300,
                // x: 'auto',
              }}
              pagination={false}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<span>Không có dữ liệu.</span>}
                  />
                ),
                triggerDesc: '',
                triggerAsc: '',
                cancelSort: '',
              }}
              summary={() => {
                if (businessReportData.length > 0)
                  return (
                    <Table.Summary fixed>
                      <Table.Summary.Row style={{ fontWeight: 'bold' }}>
                        <Table.Summary.Cell index={0}>
                          Tổng số
                        </Table.Summary.Cell>
                        {businessReportDataTotal.map((item, index) => {
                          if (item.index !== 11) {
                            if (item.type === STATISTICS_TYPE.MONEY) {
                              return (
                                <Table.Summary.Cell key={index}>
                                  {item.total_from_to !== null
                                    ? formatPrice(
                                        parseFloat(item.total_from_to)
                                      ) + ' đ'
                                    : ''}
                                </Table.Summary.Cell>
                              );
                            } else if (item.type === STATISTICS_TYPE.RATIO) {
                              return (
                                <Table.Summary.Cell key={index}>
                                  {item.total_from_to !== null &&
                                    `${item.total_from_to}%`}
                                </Table.Summary.Cell>
                              );
                            } else {
                              return (
                                <Table.Summary.Cell key={index}>
                                  {item.total_from_to}
                                </Table.Summary.Cell>
                              );
                            }
                          } else {
                            return (
                              <Table.Summary.Cell
                                key={index}
                              ></Table.Summary.Cell>
                            );
                          }
                        })}
                      </Table.Summary.Row>
                    </Table.Summary>
                  );
              }}
            />
          </Col>
        </Row>

        <BusinessReportChart
          isLoadingChart={isLoadingChart}
          businessReportChart={businessReportChart}
          typeTimeCode={param.typeTimeCode}
          from={param.from}
          to={param.to}
          type_time_code={type_time_code}
          isLoadingChartWithProduct={isLoadingChartWithProduct}
          businessReportChartWithProduct={businessReportChartWithProduct}
        />
      </Wrapper>
    </div>
  );
}
