import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import reactotron from 'reactotron-react-js';
import { QUESTION_TYPE, QUESTION_TYPE_ALIAS } from '@constants/Constant';
import { getItemFromId } from '@utils/getItemFromId';
import { Popconfirm } from 'antd';

class Step extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: '1',
    };
  }

  renderFormType(form, isChild) {
    if (
      [
        QUESTION_TYPE.SELECT,
        QUESTION_TYPE.MULTIPLE_SELECT,
        QUESTION_TYPE.YES_NO,
      ].includes(parseInt(form.type_input))
    ) {
      return <>{this.renderType1(form, isChild)}</>;
    } else {
      return <>{this.renderType2(form)}</>;
    }
  }

  renderLabelConclusions() {
    return <b style={{ color: 'green' }}>(Kết luận)</b>;
  }
  renderLabelRequire() {
    return <b style={{ color: 'red', padding: '0 8px' }}>(Bắt buộc)</b>;
  }

  renderType1(form, isChild) {
    const input_type =
      getItemFromId(QUESTION_TYPE_ALIAS, form.type_input)?.name || 'Unknown';
    return (
      <>
        <Row className="py-2">
          <Col md={12} className="px-0">
            <Row>
              <Col md={10} className="px-0">
                <span>{form.name}?</span>
                <b> {`(${form.reward_point} điểm)`}</b>
                <span className="text-green"> ( {form && input_type} )</span>
                {/* <span className="text-red">
                  {" "}
                  {form.is_require ? "(*)" : ""}
                </span> */}
                {/* <br /> */}
                {form.is_require ? this.renderLabelRequire() : ''}
                {form.is_conclusions ? this.renderLabelConclusions() : ''}
              </Col>

              <Col md={2} className="px-0">
                <Row className="justify-content-end pb-3 pb-md-0">
                  {!form.job_dialer_type && (
                    <Popconfirm
                      title="Bạn muốn xóa câu hỏi này?"
                      onConfirm={() => this.props.deleteForm(form.id)}
                      okText="Có"
                      cancelText="Không"
                    >
                      <i className="far fa-trash-alt step-icon-del mr-2"></i>
                    </Popconfirm>
                  )}
                  <i
                    className="far fa-edit step-icon-edit"
                    onClick={() => {
                      this.props.showEditModal('modalAdd', true, form);
                    }}
                  ></i>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col md={12} className={!isChild ? 'pl-5 px-0' : 'ml-0 px-0'}>
            <ul>
              {form.job_form_answers?.length > 0 &&
                form.job_form_answers.map((op, index) => {
                  return (
                    <>
                      <li key={index}>
                        <span>
                          {op.name}

                          {op.enterprise_forward_answer_enabled ? (
                            <span className="text-green">
                              &nbsp;(Đáp án tích hợp đồng bộ)
                            </span>
                          ) : (
                            <></>
                          )}
                        </span>
                        {!isChild && (
                          <div
                            xs={12}
                            className="text-blue cursor d-inline-block ml-3"
                          >
                            <i className="fas fa-plus mr-1 "></i>
                            <span
                              onClick={() => {
                                this.props.showSubModal(
                                  'subModalAdd',
                                  true,
                                  form,
                                  op
                                );
                              }}
                            >
                              Thêm câu hỏi phụ
                            </span>
                          </div>
                        )}
                      </li>
                      {op?.job_form_answer_child?.map((item, index) =>
                        this.renderForm({ ...item }, true)
                      )}
                    </>
                  );
                })}
            </ul>
          </Col>
        </Row>
      </>
    );
  }

  renderType2(form) {
    const input_type =
      getItemFromId(QUESTION_TYPE_ALIAS, form.type_input)?.name || 'Unknown';
    return (
      <>
        <Row className="pt-0 align-items-start">
          <Col md={10} className="px-0">
            <span>{form.name}?</span>
            <b> {`(${form.reward_point} điểm)`}</b>
            <span className="text-green"> ( {form && input_type} )</span>
            {/* <span className="text-red"> {form.is_require ? '(*)' : ''}</span> */}
            <br />
            <span>{form.is_require ? this.renderLabelRequire() : ''}</span>
            <span>
              {form.is_conclusions ? this.renderLabelConclusions() : ''}
            </span>
          </Col>
          <Col md={2} className="px-0">
            <Row className="justify-content-end pb-3 pb-md-0 justify-content-end">
              {!form.job_dialer_type && (
                <Popconfirm
                  title="Bạn muốn xóa câu hỏi này?"
                  onConfirm={() => this.props.deleteForm(form.id)}
                  okText="Có"
                  cancelText="Không"
                >
                  <i className="far fa-trash-alt step-icon-del mr-2"></i>
                </Popconfirm>
              )}
              <i
                className="far fa-edit step-icon-edit"
                onClick={() => {
                  this.props.showEditModal('modalAdd', true, form);
                }}
              ></i>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  renderForm(form, isChild) {
    return (
      <>
        <div className="assessment step-frame d-flex align-items-center mb-2 mt-2">
          <Row className="w-100 align-items-center">
            <Col md={12} className="pl-2">
              {this.renderFormType(form || this.props.form, isChild)}
            </Col>
          </Row>
        </div>
      </>
    );
  }

  render() {
    return <>{this.renderForm()}</>;
  }
}

export default Step;
