import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '@styles/Settings.css';
import { Row, Col, FormControl, Button, Modal, FormGroup } from 'react-bootstrap';
import { getListVpoint } from '@src/redux/actions';
import { connect } from 'react-redux';
import { deleteVpoint, createVpoint, updateVpoint } from '@constants/Api';
import { notifyFail, notifySuccess } from '@utils/notify';
import Loading from '@src/components/Loading';
import Pagination from 'react-js-pagination';
import reactotron from 'reactotron-react-js';
import { Empty } from 'antd';
let timeout;

class Vpoint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalAddVpoint: false,
      modaldeleteVpoint: false,
      modalVpointField: {
        id: '',
        name: '',
        ratio: '',
      },
      Vpoint: '',
      modalEditVpoint: false,
      activePage: 1,
      isLoading: false,
      query: '',
      page: '',
      listVpoint: [],
      disable: false,
    };
  }

  componentDidMount() {
    this.getData('', 1);
  }

  getData = (search, page) => {
    this.props.getListVpoint({
      search: search,
      page: page,
    });
  };

  addVpointFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalVpointField: {
        ...this.state.modalVpointField,
        [field]: value,
      },
    });
  };

  handlePressVpoint = (e) => {
    let { Vpoint, activePage } = this.state;
    if (e.charCode === 13) {
      this.getData(Vpoint, 1);
    }
  };

  addModalVpoint = () => {
    const { modalAddVpoint, modalVpointField, modalEditVpoint } = this.state;
    return (
      <Modal
        show={modalAddVpoint}
        onHide={() => {
          this.setShowModal('modalAddVpoint', false);
          this.setState({
            modalEditVpoint: false,
            modalVpointField: {
              id: '',
              name: '',
              ratio: '',
            },
          });
        }}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton className={modalEditVpoint ? 'bg-orange' : 'bg-blue'}>
          <h5 className="text-light pb-0">{modalEditVpoint ? 'Sửa quy đổi' : 'Thêm quy đổi'}</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          {/* {this.checkValidationType()} */}
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>Tên loại sản phẩm</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="text"
                placeholder="Nhập nội dung"
                value={modalVpointField.name}
                onChange={(e) => this.addVpointFieldChange('name', e.target.value)}
              ></FormControl>
            </Row>
          </FormGroup>
          <FormGroup className="text-left">
            <Row className="modal-row">
              <label>% tỷ lệ quy đổi</label>
            </Row>
            <Row className="modal-row">
              <FormControl
                type="number"
                placeholder="Nhập tỷ lệ"
                value={modalVpointField.ratio}
                onChange={(e) => this.addVpointFieldChange('ratio', e.target.value)}
              ></FormControl>
            </Row>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            disabled={this.state.disable}
            variant="success"
            className="text-light"
            onClick={() => {
              modalEditVpoint ? this.updateVpoint() : this.createVpoint();
            }}
          >
            Thêm mới
          </Button>
          <Button
            variant="danger"
            className="text-light"
            onClick={() => {
              this.setShowModal('modalAddVpoint', false);
              this.setState({
                modalEditVpoint: false,
                modalVpointField: {
                  id: '',
                  name: '',
                },
              });
            }}
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  async deleteVpoint(id) {
    this.setState({
      isLoading: true,
    });
    try {
      await deleteVpoint({
        id: id,
      });
      this.setState({ modaldeleteVpoint: false, isLoading: false }, () => {
        this.getData('', 1);
        notifySuccess('Xóa thành công!');
      });
      // this.props.deleteStaffAction(this.state.userId);
    } catch (err) {
      this.setState(
        {
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async createVpoint() {
    this.setState({
      isLoading: true,
      disable: true,
    });
    const { name, ratio } = this.state.modalVpointField;
    if (!name) {
      notifyFail('Vui lòng nhập tên loại sản phẩm!');
      this.setState({
        disable: false,
      });
      return;
    }
    if (!ratio) {
      notifyFail('Vui lòng nhập tỷ lệ quy đổi!');
      this.setState({
        disable: false,
      });
      return;
    }
    if (ratio < 0) {
      notifyFail('Vui lòng nhập tỷ lệ quy đổi lớn hơn 0!');
      this.setState({
        disable: false,
      });
      return;
    }
    // if (!name) {
    //   notifyFail('Vui lòng nhập tên loại sản phẩm!')
    //   return
    // }
    try {
      await createVpoint({
        name: name,
        ratio: ratio,
      });
      this.setState(
        {
          ...this.state,
          modalAddVpoint: false,
          isLoading: false,
          disable: false,
          modalVpointField: {
            name: '',
            ratio: '',
          },
        },
        () => {
          this.getData('', 1);
          notifySuccess('Thêm mới thành công!');
        }
      );
      // this.props.deleteStaffAction(this.state.userId);
    } catch (err) {
      this.setState(
        {
          isLoading: false,
          disable: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async updateVpoint() {
    this.setState({
      isLoading: true,
    });
    const { name, id, ratio } = this.state.modalVpointField;
    if (!name) {
      notifyFail('Vui lòng nhập tên loại sản phẩm!');
      this.setState({
        disable: false,
      });
      return;
    }
    if (!ratio) {
      notifyFail('Vui lòng nhập tỷ lệ quy đổi!');
      this.setState({
        disable: false,
      });
      return;
    }
    if (ratio < 0) {
      notifyFail('Vui lòng nhập tỷ lệ quy đổi lớn hơn 0!');
      this.setState({
        disable: false,
      });
      return;
    }
    try {
      await updateVpoint({
        id: id,
        name: name,
        ratio: ratio,
      });
      this.setState(
        {
          ...this.state,
          modalAddVpoint: false,
          isLoading: false,
          modalVpointField: {
            name: '',
            ratio: '',
          },
        },
        () => {
          this.getData('', 1);
          notifySuccess('Sửa thành công!');
        }
      );
      // this.props.deleteStaffAction(this.state.userId);
    } catch (err) {
      this.setState(
        {
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  addmodaldeleteVpoint() {
    const { modaldeleteVpoint } = this.state;
    const { id } = this.state.modalVpointField;
    return (
      <Modal
        show={modaldeleteVpoint}
        onHide={() =>
          this.setState({
            modaldeleteVpoint: false,
          })
        }
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton className="bg-red">
          <h5 className="text-light">Bạn chắc chắn muốn xóa sản phẩm này?</h5>
        </Modal.Header>
        <Modal.Body className="custom-body">
          <Row>
            <Col className="button-wrapper" style={{ textAlign: 'center' }}>
              <Button
                variant="success"
                className="text-light"
                onClick={() => {
                  if (timeout) clearTimeout(timeout);
                  this.deleteVpoint(id);
                }}
              >
                Có
              </Button>
              <Button
                variant="danger"
                className="text-light"
                onClick={() => {
                  this.setState({
                    modaldeleteVpoint: false,
                  });
                }}
              >
                Thoát
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  handleChange(fieldName, value) {
    this.setState({
      ...this.state,
      [fieldName]: value || '',
    });
  }

  setShowModal = (fieldName, value) => {
    this.setState({
      ...this.state,
      [fieldName]: value || '',
    });
  };

  renderBody() {
    return <div>{this.renderVpoint()}</div>;
  }

  renderVpoint() {
    const { Vpoint } = this.state;
    return (
      <>
        <Col sm={12} md={12}>
          <Row>
            <Col sm={12} className="d-flex justify-content-between">
              <h5>Quy đổi Vpoint</h5>
              <Button
                variant="success"
                className="text-light"
                onClick={() => this.setShowModal('modalAddVpoint', true)}
              >
                Thêm quy đổi
              </Button>
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={12}>
          <Row>
            <Col md={6} sm={12}>
              <FormControl
                onKeyPress={this.handlePressVpoint}
                type="text"
                id=""
                placeholder="Nhập tên loại sản phẩm quy đổi"
                value={Vpoint}
                onChange={(e) => this.handleChange('Vpoint', e.target.value)}
              />
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={12}>
          <div className="row">
            <Col
              sm={10}
              md={12}
              style={{
                padding: '0px',
                marginTop: '20px',
              }}
            >
              <Row className="table-wrap mx-3">
                <table
                  id="example2"
                  className="table table-bordered table-striped table-responsive-sm table-responsive-md"
                >
                  <thead>
                    <tr>
                      <th>STT</th>
                      <th>Tên loại sản phẩm</th>
                      <th>% tỷ lệ quy đổi</th>
                      <th>Ngày tạo</th>
                      <th></th>
                    </tr>
                  </thead>
                  {this.renderTableVpoint()}
                  <tfoot>{this.renderPagination()}</tfoot>
                </table>
              </Row>
            </Col>
          </div>
        </Col>
      </>
    );
  }

  renderTableVpoint() {
    const { paging } = this.props.VpointState;
    return (
      <tbody>
        {this.props.VpointState.data?.length > 0 ? (
          this.props.VpointState.data?.map((value, index) => (
            <tr>
              <td>{(parseFloat(paging?.page) - 1) * parseFloat(paging?.limit) + index + 1}</td>
              <td>{value.name || '--'}</td>
              <td>{value?.ratio}</td>
              <td>{Intl.DateTimeFormat('vi-VN').format(new Date(value.created_at)) || '--'}</td>
              <td>
                <i
                  className="btnEdit fa fa-fw fa-edit"
                  onClick={() => {
                    this.setState(
                      {
                        modalEditVpoint: true,
                        modalVpointField: {
                          id: value.id,
                          name: value.name,
                          ratio: value.ratio,
                        },
                      },
                      () => this.setShowModal('modalAddVpoint', true)
                    );
                  }}
                />
                <i
                  className="btnDelete far fa-trash-alt"
                  onClick={() => {
                    this.setState({
                      modaldeleteVpoint: true,
                      modalVpointField: {
                        id: value.id,
                      },
                    });
                  }}
                />
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colspan="5">
              <Empty description={<span>Không có dữ liệu</span>} />
            </td>
          </tr>
        )}
      </tbody>
    );
  }

  renderPagination() {
    const { paging } = this.props.VpointState;
    const { Vpoint } = this.state;
    return (
      <Pagination
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled
        activePage={paging?.page || 1}
        itemsCountPerPage={paging?.limit || 12}
        totalItemsCount={paging?.totalItemCount || 0}
        pageRangeDisplayed={5}
        hideNavigation
        onChange={(page) => {
          this.setState({
            ...this.state,
            activePage: page,
          });
          this.getData(Vpoint, page);
        }}
      />
    );
  }

  render() {
    const { isLoading } = this.props.VpointState;
    let loading = <Loading />;
    if (!isLoading) {
      loading = null;
    }
    return (
      <>
        {loading}
        {this.addModalVpoint()}
        {this.addmodaldeleteVpoint()}
        {this.renderBody()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  VpointState: state.vpointReducer,
});

const mapDispatchToProps = {
  getListVpoint,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Vpoint));
