import { useContext } from 'react';

import Context from './Context';

const hooks = {
  usePromotionsStore: () => {
    const [state, dispatch] = useContext(Context);
    return [state, dispatch];
  },
};

export default hooks;
