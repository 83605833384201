import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { Row, Col, FormControl } from 'react-bootstrap';
import '@styles/JobGeneral.css';
import { ROUTER, STRING, NUMBER } from '@constants/Constant';
import DatePickerCustom from '../../../components/DatePickerCustom';

class KPIScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      [STRING.fromDate]: '',
      [STRING.toDate]: '',
    };
  }

  handleChange = (field, value) => {
    this.setState({
      [field]: value || '',
    });
  };

  renderTitle() {
    return (
      <>
        <Row>
          <Col sm={12}>
            <h2 className="text-left" style={{ marginBottom: '10px' }}>
              {' '}
              Báo cáo KPI nhân viên
            </h2>
            <div class="line"></div>
          </Col>
        </Row>
      </>
    );
  }

  renderBody() {
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <Row
            className="mt-1"
            style={{ textAlign: 'center', padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}
          >
            <Col md className="bg">
              {this.renderTitle()}
              {this.renderField()}
            </Col>
          </Row>
          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
            <Col md className="bg">
              <Row>
                <Col md={12}>{this.renderTable()}</Col>
              </Row>
              {/* <Row>
                <Col md={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {this.renderPagination()}
                </Col>
              </Row> */}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderField() {
    const { name, [STRING.fromDate]: fromDate, [STRING.toDate]: toDate } = this.state;
    return (
      <div className="w-100">
        <Row>
          <Col md={4} sm={12}>
            <FormControl
              type="text"
              id=""
              placeholder="Tên hoặc số điện thoại Sale"
              value={name}
              onChange={(e) => this.handleChange('name', e.target.value)}
            />
          </Col>
          <Col md={4} sm={12}>
            <DatePickerCustom
              className={`date-picker form-control`}
              placeholderText={STRING.fromDate}
              handleChange={this.handleChange}
              selected={fromDate}
              maxDate={new Date(toDate)}
              dateFormat="dd/MM/yyyy"
            />
          </Col>
          <Col md={4} sm={12}>
            <DatePickerCustom
              className={`date-picker form-control`}
              placeholderText={STRING.toDate}
              handleChange={this.handleChange}
              selected={toDate}
              minDate={new Date(fromDate)}
              dateFormat="dd/MM/yyyy"
            />
          </Col>
        </Row>
      </div>
    );
  }

  renderTable() {
    return (
      <div className="w-100 table-wrap">
        <span className="pb-2 d-inline-block">
          Kết quả lọc: <b className="text-danger">230</b>
        </span>
        <span style={{ float: 'right' }}>
          <span className="pb-2 d-inline-block">
            Đạt: <b className="text-green">2</b>
          </span>
          <span>&nbsp;</span>
          <span className="pb-2 d-inline-block">
            Không đạt: <b className="text-red">0</b>
          </span>
        </span>

        <Table bordered>
          <thead>
            <tr>
              <th>STT</th>
              <th>Sale</th>
              <th>Thời gian</th>
              <th>Tiêu chí</th>
              <th>Tỷ trọng(%)</th>
              <th>Kế hoạch</th>
              <th>Thực hiện</th>
              <th>Kết quả(%)</th>
              <th>Đánh giá</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan="3">1</td>
              <td rowSpan="3">Dương Văn Chiến</td>
              <td>Tháng 9/2020(01/09 - 30/09/2020)</td>
              <td>Số cuộc gọi</td>
              <td>80</td>
              <td>2000</td>
              <td>2000</td>
              <td>90</td>
              <td>ĐẠT</td>
            </tr>
            <tr>
              <td>Số cơ hội trong tháng</td>
              <td>10</td>
              <td>20</td>
              <td>10</td>
              <td>5</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Tỷ lệ chuyển đổi</td>
              <td>10</td>
              <td>20</td>
              <td>10</td>
              <td>5</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td rowSpan="3">2</td>
              <td rowSpan="3">Ngô Thị Hồng</td>
              <td>Tháng 9/2020(01/09 - 30/09/2020)</td>
              <td>Số cuộc gọi</td>
              <td>80</td>
              <td>2000</td>
              <td>2000</td>
              <td>90</td>
              <td>ĐẠT</td>
            </tr>
            <tr>
              <td>Số cơ hội trong tháng</td>
              <td>10</td>
              <td>20</td>
              <td>10</td>
              <td>5</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Tỷ lệ chuyển đổi</td>
              <td>10</td>
              <td>20</td>
              <td>10</td>
              <td>5</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }

  addFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalAddField: {
        ...this.state.modalAddField,
        [field]: value,
      },
    });
  };
  render() {
    return <>{this.renderBody()}</>;
  }
}

export default withRouter(KPIScreen);
