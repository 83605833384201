import React from 'react';
import { Form, Input, InputNumber, Modal } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { VietnameseString } from '@constants/VietnameseString';

import {
  greenColor,
  greenColorHover,
  redColor,
  redColorHover,
} from '@styles/Color';
import { ButtonSystemStyle } from '@styles/Style';
import { voucherService } from '../../service';
import { notifyFail, notifySuccess } from '@utils/notify';
import UploadComponent from './Upload';

const { TextArea } = Input;

const FormStyled = styled(Form)`
  width: 100%;
`;

function AddEditGift(props) {
  const { visible, onCancel, giftDetail, callback, setCallback, onSuccess } =
    props;

  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [fileImage, setFileImage] = React.useState(null);
  const currentImage = React.useRef(null);

  const onFinish = async values => {
    if (loading) return;
    setLoading(true);

    console.log(fileImage);
    if (fileImage) {
      const formDataUploadImage = new FormData();
      formDataUploadImage.append('file', fileImage);

      const resImage = await voucherService.uploadFile(formDataUploadImage);

      if (resImage.status) {
        values.image = resImage.data.substring(
          resImage.data.indexOf('upload/')
        );
      }
    }

    if (giftDetail) {
      values.image = values?.image?.substring(
        values?.image?.indexOf('upload/')
      );

      voucherService
        .updateGiftVoucher(giftDetail?.id, values)
        .then(res => {
          if (res?.status) {
            notifySuccess('Cập nhật thành công quà tặng');
            setCallback(!callback);
            onCancel();
          }
        })
        .catch(err => {
          if (err?.msg) {
            notifyFail(err?.msg);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      voucherService
        .createGiftVoucher(values)
        .then(res => {
          if (res?.status) {
            notifySuccess('Tạo thành công quà tặng ' + values?.name);
            onSuccess && onSuccess(res?.data);
            setCallback(!callback);
            onCancel();
          }
        })
        .catch(err => {
          if (err?.msg) {
            notifyFail(err?.msg);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  React.useEffect(() => {
    if (!giftDetail) return;

    form.setFieldsValue({ ...giftDetail, image: giftDetail?.e_voucher_image });
  }, [giftDetail]);

  return (
    <Modal
      title={giftDetail ? 'Sửa quà tặng' : 'Thêm quà tặng'}
      footer={null}
      visible={visible}
      onCancel={onCancel}
      destroyOnClose
    >
      <FormStyled
        name="form-add-edit-gift"
        labelAlign="left"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={giftDetail}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label="Tên quà tặng"
          name="name"
          rules={[{ required: true, message: 'Vui lòng nhập tên quà tặng!' }]}
          normalize={value => value?.toString()?.trimStart()}
        >
          <Input placeholder="Nhập tên quà tặng" />
        </Form.Item>

        <Form.Item
          label="Giá trị"
          name="price"
          rules={[
            { required: true, message: 'Vui lòng nhập giá trị!' },
            {
              validator: (_, value) => {
                if (+value < 1) {
                  return Promise.reject(new Error('Giá trị phải lớn hơn 0!'));
                }

                if (+value >= 100000000000) {
                  return Promise.reject(
                    new Error('Số lượng hiện có nhỏ hơn 10 tỷ!')
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
          normalize={value => value?.toString()?.replace(/[^\wÀ-úÀ-ÿ]/g, '')}
        >
          <InputNumber
            placeholder="Nhập giá trị"
            addonAfter="VNĐ"
            style={{ width: '100%' }}
            formatter={value =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={value => (value ? value.replace(/\$\s?|(,*)|\./g, '') : '')}
            controls={false}
          />
        </Form.Item>

        <Form.Item
          label="Ảnh phần quà"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn ảnh phần quà!',
            },
          ]}
          name="image"
        >
          <UploadComponent
            listType="picture-card"
            initialFile={
              giftDetail?.e_voucher_image && [
                {
                  uid: '1',
                  name: 'image.png',
                  url: giftDetail?.e_voucher_image,
                },
              ]
            }
            // isUploadServerWhenUploading
            onSuccessUpload={value => {
              if (value) {
                setFileImage(value?.originFileObj);
                form.setFieldsValue({ image: value?.originFileObj });
              }
            }}
            accept=".png, .jpg"
          >
            +
          </UploadComponent>
        </Form.Item>

        <Form.Item
          label="Số lượng hiện có"
          name="remain"
          rules={[
            { required: true, message: 'Vui lòng nhập số lượng hiện có!' },
            {
              validator: (_, value) => {
                if (+value < 1) {
                  return Promise.reject(
                    new Error('Số lượng hiện có phải lớn hơn 0!')
                  );
                }

                if (+value >= 100000000000) {
                  return Promise.reject(
                    new Error('Số lượng hiện có nhỏ hơn 10 tỷ!')
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber
            placeholder="Nhập số lượng hiện có"
            style={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item label="Chất liệu" name="material">
          <Input placeholder="Nhập chất liệu" />
        </Form.Item>

        <Form.Item label="Hình dáng" name="shaped">
          <Input placeholder="Nhập hình dáng" />
        </Form.Item>

        <Form.Item label="Đối tác" name="partner">
          <Input placeholder="Nhập tên đối tác" />
        </Form.Item>

        <Form.Item label="Mô tả" name="description">
          <TextArea placeholder="Nhập mô tả" rows={4} />
        </Form.Item>

        <Form.Item
          wrapperCol={{ offset: 8, span: 16 }}
          style={{ marginTop: '12px' }}
        >
          <ButtonSystemStyle
            htmlType="submit"
            bgButtonColor={greenColor}
            bgButtonColorHover={greenColorHover}
            loading={loading}
            style={{ marginRight: '12px' }}
          >
            {VietnameseString.save}
          </ButtonSystemStyle>

          <ButtonSystemStyle
            onClick={() => {
              form.resetFields();
              onCancel();
            }}
            bgButtonColor={redColor}
            bgButtonColorHover={redColorHover}
          >
            {VietnameseString.cancel}
          </ButtonSystemStyle>
        </Form.Item>
      </FormStyled>
    </Modal>
  );
}

AddEditGift.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  giftDetail: PropTypes.shape({
    id: PropTypes.number,
    giftName: PropTypes.string,
    value: PropTypes.string,
    quantity: PropTypes.number,
    materials: PropTypes.string,
    shape: PropTypes.string,
    partner: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default React.memo(AddEditGift);
