import { GET_TYPE_JOB, GET_TYPE_JOB_SUCCESS, GET_TYPE_JOB_FAIL } from '@actions/type';

import reactotron from 'reactotron-react-js';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    // *************  GET INFO ****************//
    case GET_TYPE_JOB:
      return {
        ...state,
        isLoading: true,
      };
    case GET_TYPE_JOB_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.response.data,
        paging: action.payload.response.paging,
        error: null,
      };
    case GET_TYPE_JOB_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
