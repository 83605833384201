import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, Input, message, Modal, Tooltip } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { requestCreateOA, requestCreateZNSProvider } from '../services/api';
import { VietnameseString } from '@constants/VietnameseString';
import { CopyOutlined } from '@ant-design/icons';
import { greenColor, greenColorHover, primaryColor } from '@styles/Color';
import { ButtonSystemStyle, ButtonTransparentStyle } from '@styles/Style';
import crypto from 'crypto';
import reactotron from 'src/ReactotronConfig';

const ModalStyled = styled(Modal)`
  &&& {
    .ant-modal-header {
      /* background-color: ${primaryColor}; */
    }

    .ant-modal-body {
      padding-bottom: 12px;
    }
  }
`;

const FormStyled = styled(Form)`
  width: 100%;

  .ant-row.ant-form-item {
    margin: 0px 0px 12px;

    .ant-col.ant-form-item-label {
      padding: 0px 0px 4px;
    }
  }
`;

const { useForm } = Form;

function AddZaloAccount(props) {
  const { visible, onHide } = props;
  const [form] = useForm();

  const userInfo = useSelector((store) => store.userReducer.data);

  const [isLoadingButtonAddZaloAccount, setIsLoadingButtonAddZaloAccount] = useState(false);
  // const channel = React.useMemo(() => new BroadcastChannel('zaloLogin'), []);
  // useEffect(() => {
  //   channel.addEventListener('message', (e) => {
  //     // alert(JSON.stringify(e.data));
  //     addOAProvices(e.data.code);
  //   });
  //   return channel.close;
  // }, []);

  const channelRef = useRef(new BroadcastChannel('zaloLogin'));
  const channel = channelRef.current;

  useEffect(() => {
    channel.addEventListener('message', (e) => {
      addOAProvider(e.data.code);
    });

    return () => {
      channel.close();
    };
  }, []);

  const handleCancelModal = () => {
    form.resetFields();
    onHide();
  };

  const addOAProvider = async (code) => {
    try {
      setIsLoadingButtonAddZaloAccount(true);
      const payloadCreateOA = {
        ...form.getFieldsValue(),
        code,
        code_verifier: verifyCode,
      };
      const res = await requestCreateZNSProvider(payloadCreateOA);
      handleCancelModal();
      message.success('Thêm thành công');
    } catch (error) {
      message.error(error?.msg);
    } finally {
      setIsLoadingButtonAddZaloAccount(false);
    }
  };

  const handleSubmitForm = async (value) => {
    // const widthPopup = Math.round(window.screen.availWidth * (1 / 3));
    // const heightPopup = Math.round(window.screen.availHeight * (3 / 4));
    const widthPopup = 600;
    const heightPopup = 800;

    try {
      // test
      // value.oa_login_url =
      //   'http://localhost:3000/zalo-login?oa_id=4553105859991254217&code=uGVFKw46827_6zfgy15-0S5Pabp2INmQWLleL_XkE12DNyLMt3e4AzamYMBaHcOswMpxIvbyOJEIS8DvvKLwCOz0lKsu7WGAjN7hKxbu3X3iRvHZe7PL0QqTu1cQL4bAc4JK8Ob8Q2_K0S4mt4zNMwmOza60F1aWn3cU3zDV4tNQGVyjiL4BVOi7rbhNQMP7t0JT6R8I8aksCxaWfpai6TvItKd97Hjdq6EhU_i8JIhZHVSvkHvrGx1dgG2q07LsxtMvMxS-1OJ5EilLvXypwHafhPOKK4R24a2iydXcDPbKNk3YD5bldszqX-HRASWK0KWJUJMNT7SmiTay2QbT0nwErrONZajqUPQp5Gd_8L5jYRnsDUerRoc7laaczyjDTzmm6Ym';
      window.open(
        value.login_url,
        'popup',
        `width=${widthPopup},height=${heightPopup}
      ,left=${(window.screen.availWidth - widthPopup) / 2},top=${(window.screen.availHeight - heightPopup) / 2}
      `
      );

      setIsLoadingButtonAddZaloAccount(true);
    } catch (error) {
      console.log('Error handleSubmitForm: ', { error });
      message.error(error?.msg);
    } finally {
      setIsLoadingButtonAddZaloAccount(false);
    }
  };
  const URL_CALLBACK = process.env.REACT_APP_ZNS_LOGIN_CALLBACK
    ? process.env.REACT_APP_ZNS_LOGIN_CALLBACK
    : 'https://dev.admin.ezsale.vn/zalo-login';

  const randomVerifyCode = (length = 34) => {
    // var result = '';Ezsale6789112345678923456789013456780123456
    // var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    // var charactersLength = characters.length;
    // for (var i = 0; i < length; i++) {
    //   result += characters.charAt(Math.floor(Math.random() * charactersLength));
    // }
    // return `EZSaleNo1${result}`;
    return 'Ezsale6789112345678923456789013456780123456';
  };

  const getChallengeCode = (verifyCode) => {
    const challengeCode = crypto.createHash('sha256').update(verifyCode).digest('base64');
    return challengeCode.substring(0, challengeCode.length - 1);
  };

  const verifyCode = randomVerifyCode();

  return (
    <div>
      <ModalStyled
        title={VietnameseString.add_zalo_account}
        visible={visible}
        footer={null}
        onCancel={handleCancelModal}
      >
        <FormStyled
          layout="vertical"
          name="add-zalo-account"
          form={form}
          onFinish={handleSubmitForm}
          initialValues={
            {
              //   access_token:
              //     'eAsbBQ7WI4IZgw8YekLEHgEgf2MY-0KinE-CK9V_U36IxfCKoiSXTg7Om7Jbd5eJkABM0zcAP3FVkuaRjV47A_VvbqhrpJfeyD-tQ9BlMs7Y-BT_uTG67ARocYZzwI0AZQRDDiQiRbQNuELzoF4C6g_ZasQjt313gjgXQjpW2MAdy9XGxyGuIf_tfa_gpKWgxfp44kMpSGhkhlmjiQ93JlEIg6-5emHq-fxlGgRQ3dAYr9DnqyalPu20l4FbbYbsaQUxU9kQ4txRX8TLgiqcDzNoirRNjmqrYeAI0esV7HJWr80WaVWuDfV1eYNXXG46u8s8PR-Z9NxbZx1EXAahBUEUnIyuGp9ysL6c_qrA',
              //   refresh_token:
              //     'zKT482VoO7hhMr4PTkK-MjKP6Yj5fpbokHu675AV7tkm7oCHJROg9UCqVN5yzLu1xaH9IY_5VngoSLbzKFPa5xbr9tGLp7KZXWzQL1U_GH_6DdPYJRzoJhr_1782XsXJr59yH23E3YEyU2rnHSOQ9TqsH48Dj7WlX7zvJnJnFmV_C6aoMkvGtlL6MRXUvvoRuLUOmYZdojRQJkcH8vwhYjm_vCjPdwo4eaFoX5Esqe-_Su-29eQSE71QmLft',
              //   app_id: '1631480737325533323',
              //   secret_key: '7OJDNrPJUSWG9H2p77BV',
            }
          }
        >
          <Form.Item label={'Challenge Code'} name="app_id">
            <Input disabled style={{ width: '90%' }} defaultValue={getChallengeCode(verifyCode)} />
            <Tooltip title="Sao chép challenge code">
              <Button
                onClick={() => {
                  // navigator.clipboard.writeText(window.location.toString());
                  navigator.clipboard.writeText(getChallengeCode(verifyCode));
                }}
                icon={<CopyOutlined />}
              />
            </Tooltip>
          </Form.Item>
          <Form.Item label={'Official Account Callback Url'} name="app_id">
            <Input disabled style={{ width: '90%' }} defaultValue={URL_CALLBACK} />
            <Tooltip title="Sao chép Official Account Callback Url">
              <Button
                onClick={() => {
                  // navigator.clipboard.writeText(window.location.toString());
                  navigator.clipboard.writeText(URL_CALLBACK);
                }}
                icon={<CopyOutlined />}
              />
            </Tooltip>
          </Form.Item>

          <Form.Item
            label={'Đường dẫn yêu cầu cấp quyền'}
            name="login_url"
            rules={[{ required: true, message: 'Vui lòng nhập đường dẫn yêu cầu cấp quyền' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={VietnameseString.application_id}
            name="app_id"
            rules={[{ required: true, message: VietnameseString.please_enter_application_id }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={VietnameseString.private_key}
            name="secret_key"
            rules={[{ required: true, message: VietnameseString.please_enter_private_key }]}
          >
            <Input />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <ButtonSystemStyle
              bgButtonColor={greenColor}
              bgButtonColorHover={greenColorHover}
              htmlType="submit"
              loading={isLoadingButtonAddZaloAccount}
            >
              {VietnameseString.confirm}
            </ButtonSystemStyle>

            <ButtonTransparentStyle htmlType="button" onClick={handleCancelModal}>
              {VietnameseString.cancel}
            </ButtonTransparentStyle>
          </Form.Item>
        </FormStyled>
      </ModalStyled>
    </div>
  );
}

const tailLayout = {
  wrapperCol: { offset: 7, span: 17 },
};

AddZaloAccount.propTypes = {
  visible: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default AddZaloAccount;
