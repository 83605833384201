import React from 'react';

import { ROUTER } from '@constants/Constant';
// import reactotron from 'reactotron-react-js';

// static component
import PrivateRoute from '../PrivateRoute';

import {
  AddEditPromotions,
  ElectronicVoucher,
} from '@screens/Enterprise/ElectronicVoucher';

// Copy y hết của admin =))

// import CallScreen from '@screens/Enterprise/Call/CallScreen';

//Screen web reviewer
// import { ElectronicVoucher, AddEditPromotions } from '@screens/Enterprise/ElectronicVoucher';
// Screen of web sale
import SaleDashboardScreen from '@screens/Sale/Dashboard/Screen';
import JobSaleScreen from '@screens/Sale/Job/Job';
import JobDetailSaleScreen from '@screens/Sale/Job/JobDetail';
import JobResultSale from '@screens/Sale/Job/Result';
import Test from '@screens/Sale/Job/Test';
// import SaleCustomerScreen from '@screens/Sale/Customer/Screen';
import SaleCustomerScreen from '@screens/Sale/Customer/Customer'; //danh sách KH mới
import JobCustomerDetailScreen from '@screens/Sale/Customer/CustomerDetail'; //danh sách kh được giao việc
import SaleCustomerDetailScreen from '@screens/Sale/Customer/CustomerDetailNew'; //chi tiết KH mới
import SaleUpdateCustomerScreen from '@screens/Sale/Customer/UpdateCustomer';
import SaleOpportunityScreen from '@screens/Sale/Opportunity/Opportunity';
// import SaleOpportunityScreen from '@screens/Sale/Opportunity/Screen';
import SaleCallDetailScreen from '@screens/Sale/Call/CallDetail';
import SaleCallingScreen from '@screens/Sale/Call/Calling';
import SaleCallScreen from '@screens/Sale/Call/Screen';
import AddCustomerSale from '@screens/Sale/Customer/Add';
import SaleOpportunityDetailScreen from '@screens/Sale/Opportunity/OpportunityDetailNew';
import SaleReCallScreen from '@screens/Sale/Recall/Screen';

export const renderSaleRoute = () => {
  return (
    <>
      <PrivateRoute path="/" exact Component={SaleDashboardScreen} />
      <PrivateRoute
        path={ROUTER.OVERVIEW_SALE}
        exact
        Component={SaleDashboardScreen}
      />
      {/* <PrivateRoute path={ROUTER.JOB_SALE} exact Component={SaleJobScreen} /> */}
      <PrivateRoute path={ROUTER.JOB_SALE} exact Component={JobSaleScreen} />
      <PrivateRoute
        path={ROUTER.JOB_DETAIL_SALE + '/:id'}
        exact
        Component={JobDetailSaleScreen}
      />
      <PrivateRoute
        path={ROUTER.JOB_RESULT_SALE + '/:id'}
        exact
        Component={JobResultSale}
      />
      <PrivateRoute
        path={ROUTER.JOB_TEST_SALE + '/:id'}
        exact
        Component={Test}
      />
      {/* <PrivateRoute path={ROUTER.PENDING_JOB_SALE} exact Component={SalePendingJobScreen} /> */}
      <PrivateRoute
        path={ROUTER.CUSTOMER_SALE}
        exact
        Component={SaleCustomerScreen}
      />
      {/* Date.now() just for trigger rerender, for feature next coustomer only */}
      <PrivateRoute
        path={ROUTER.CUSTOMER_DETAIL_SALE + '/:id'}
        // key={Date.now()}
        exact
        Component={SaleCustomerDetailScreen}
      />
      <PrivateRoute
        path={ROUTER.JOB_CUSTOMER_DETAIL_SALE + '/:id'}
        key={Date.now()}
        exact
        Component={JobCustomerDetailScreen}
      />
      <PrivateRoute
        path={ROUTER.UPDATE_CUSTOMER_SALE + '/:id'}
        exact
        Component={SaleUpdateCustomerScreen}
      />
      <PrivateRoute
        path={ROUTER.CALL_HISTORY_SALE}
        exact
        Component={SaleCallScreen}
      />
      <PrivateRoute
        path={ROUTER.CALL_DETAIL_SALE + '/:id'}
        exact
        Component={SaleCallDetailScreen}
      />
      <PrivateRoute
        path={ROUTER.CALLING_SALE}
        exact
        Component={SaleCallingScreen}
      />
      <PrivateRoute
        path={ROUTER.RECALL_SALE}
        exact
        Component={SaleReCallScreen}
      />
      {/* <PrivateRoute path={ROUTER.NUMBER_PAD} exact Component={SaleNumberPad} /> */}
      <PrivateRoute
        path={ROUTER.OPPORTUNITY_SALE}
        exact
        Component={SaleOpportunityScreen}
      />
      <PrivateRoute
        path={ROUTER.OPPORTUNITY_DETAIL_SALE + '/:id'}
        exact
        Component={SaleOpportunityDetailScreen}
      />
      <PrivateRoute
        path={ROUTER.ADD_CUSTOMER}
        exact
        Component={AddCustomerSale}
      />
      <PrivateRoute
        path={ROUTER.EDIT_CUSTOMER + '/:id'}
        exact
        Component={AddCustomerSale}
      />

      {/* e-voucher */}
      <PrivateRoute path={ROUTER.E_VOUCHER} Component={ElectronicVoucher} />
      <PrivateRoute
        path={ROUTER.ADD_PROMOTIONS}
        Component={AddEditPromotions}
      />
      <PrivateRoute
        path={ROUTER.EDIT_PROMOTIONS + '/:id'}
        Component={AddEditPromotions}
      />
    </>
  );
};
