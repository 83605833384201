import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import '@styles/JobGeneral.css';
import { ROUTER, STRING } from '@constants/Constant';
import { Row, Col, FormControl, Button, Modal, FormGroup } from 'react-bootstrap';
class ReportScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job: '',
      telesale: '',
      staff: '',
      callStatus: '',
      status: '',
    };
  }

  handleChange = (field, value) => {
    this.setState({
      [field]: value || '',
    });
  };

  renderTitle() {
    return (
      <>
        <Row>
          <Col sm={12}>
            <h2 className="text-left" style={{ marginBottom: '10px' }}>
              {' '}
              Báo cáo KPI nhân viên
            </h2>
            <div class="line"></div>
          </Col>
        </Row>
      </>
    );
  }

  renderBody() {
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <Row
            className="mt-1"
            style={{ textAlign: 'center', padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}
          >
            <Col md className="bg">
              {this.renderTitle()}
              {this.renderField()}
            </Col>
          </Row>
          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
            <Col md className="bg">
              <Row>
                <Col md={12}>{this.renderTable()}</Col>
              </Row>
              {/* <Row>
                <Col md={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {this.renderPagination()}
                </Col>
              </Row> */}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderField() {
    const { job, telesale, staff, callStatus, status } = this.state;
    return (
      <div className="w-100">
        <Row>
          <Col md="4">
            <FormControl as="select" id="" value={job} onChange={(e) => this.handleChange('job', e.target.value)}>
              <option value="">Công việc</option>
              <option value="Công việc 1">Công việc 1</option>
              <option value="Công việc 2">Công việc 2</option>
              <option value="Công việc 3">Công việc 3</option>
              <option value="Công việc 4">Công việc 4</option>
            </FormControl>
          </Col>

          <Col md="4">
            <FormControl as="select" id="" value={telesale} onChange={(e) => this.handleChange('job', e.target.value)}>
              <option value="">Sale</option>
              <option value="Sale 1">Sale 1</option>
              <option value="Sale 2">Sale 2</option>
              <option value="Sale 3">Sale 3</option>
              <option value="Sale 4">Sale 4</option>
            </FormControl>
          </Col>

          <Col md="4">
            <FormControl as="select" id="" value={staff} onChange={(e) => this.handleChange('staff', e.target.value)}>
              <option value="">Nhân viên</option>
              <option value="Nhân viên 1">Nhân viên 1</option>
              <option value="Nhân viên 2">Nhân viên 2</option>
              <option value="Nhân viên">Nhân viên 3</option>
              <option value="Nhân viên 4">Nhân viên 4</option>
            </FormControl>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md="4">
            <FormControl
              as="select"
              id=""
              value={callStatus}
              onChange={(e) => this.handleChange('callStatus', e.target.value)}
            >
              <option value="">Trạng thái cuộc gọi</option>
              <option value="Trạng thái cuộc gọi 1">Trạng thái cuộc gọi 1</option>
              <option value="Trạng thái cuộc gọi 2">Trạng thái cuộc gọi 2</option>
              <option value="Trạng thái cuộc gọi 3">Trạng thái cuộc gọi 3</option>
              <option value="Trạng thái cuộc gọi 4">Trạng thái cuộc gọi 4</option>
            </FormControl>
          </Col>

          <Col md="4">
            <FormControl as="select" id="" value={status} onChange={(e) => this.handleChange('status', e.target.value)}>
              <option value="">Trạng thái</option>
              <option value="Trạng thái 1">Trạng thái 1</option>
              <option value="Trạng thái 2">Trạng thái 2</option>
              <option value="Trạng thái 3">Trạng thái 3</option>
              <option value="Trạng thái 4">Trạng thái 4</option>
            </FormControl>
          </Col>
        </Row>
      </div>
    );
  }

  renderTable() {
    return (
      <div className="w-100 table-wrap">
        <span className="pb-2 d-inline-block">
          Kết quả lọc: <b className="text-danger">230</b>
        </span>
        <Row className="mb-3">
          <Col>
            <b>Nói tốt, trôi chảy:</b>
            <span>
              <b>80</b>
            </span>
          </Col>
          <Col>
            <b>Khai thác nhiều thông tin:</b>
            <span>
              <b>80</b>
            </span>
          </Col>
          <Col>
            <b>Cập nhật sai thông tin:</b>
            <span>
              <b>80</b>
            </span>
          </Col>
          {/* </Row>
        <Row> */}
          <Col>
            <b>Gian lận:</b>
            <span>
              <b>80</b>
            </span>
          </Col>
          <Col>
            <b>Thái độ không tốt:</b>
            <span>
              <b>80</b>
            </span>
          </Col>
        </Row>
        <Table triped bordered hover>
          <thead>
            <tr>
              <th>STT</th>
              <th>Nhân viên đánh giá</th>
              <th>Công việc được giao</th>
              <th>Sale</th>
              <th>Số lượng</th>
              <th>Đã hoàn thành</th>
              <th>Nhận xét</th>
            </tr>
          </thead>
          <tbody>
            <tr onClick={() => this.props.history.push(ROUTER.REPORT_DETAIL + '/' + '1')}>
              <td>1</td>
              <td>Dương Văn Chiến</td>
              <td>3</td>
              <td>
                <div>Ngô Thị Hồng</div>
                <div>Ngô Thị Hồng</div>
              </td>
              <td>200</td>
              <td>100</td>
              <td>
                <div>Nói tốt, trôi chảy: 80</div>
                <div>Khai thác nhiều thông tin: 80</div>
                <div>Cập nhật sai: 80</div>
                <div>Gian lận: 80</div>
                <div>Thái độ không tốt: 80</div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }

  render() {
    return <>{this.renderBody()}</>;
  }
}

export default withRouter(ReportScreen);
