import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import '@styles/Company.css';
import Table from 'react-bootstrap/Table';
import { Row, Col, FormControl, Button, Modal } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import {
  getEnterprisesFilter,
  getJobCategoriesFilter,
  getJobFilter,
  getTelesalesFilter,
  getListCallStatus,
  getReviewerList,
  getListCall,
  getQuessionsAndAnswerFilter,
} from '@src/redux/actions';
import { ROUTER, STRING } from '@constants/Constant';
import Loading from '@src/components/Loading';
import { notifyFail, notifySuccess } from '@utils/notify';
import { Empty, TreeSelect } from 'antd';
import DatePickerCustom from '../../../components/DatePickerCustom';
import reactotron from 'reactotron-react-js';
import { requestAssignReviewer } from '@constants/Api';
import { checkAllAttrFilled } from '@utils/checkAllAttrFilled';
import { toDateString } from '@utils/ToDateString';
import { getListKeyword } from '@src/redux/actions';

class AssignScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      isLoading: false,
      search: '',
      is_active: '',
      page: '',
      form: {
        job_category_id: '',
        job_id: '',
        telesale_id: '',
        [STRING.fromDate]: '',
        [STRING.toDate]: '',
        call_status: '',
        enterprise_id: '',
        job_form_question_id: '',
        job_form_answers_id: '',
        keyword: '',
      },
      modal: false,
      reviewer: {
        amount: '',
        reviewer_id: '',
        [STRING.startDate]: '',
        [STRING.endDate]: '',
      },
    };
  }
  componentDidMount() {
    const { form } = this.state.form;
    this.getData({ page: 1 });
    this.props.getJobCategoriesFilter({ enterprise_id: form?.enterprise_id || '', telesale_id: '', have_call: 1 });
    this.props.getJobFilter({
      enterprise_id: form?.enterprise_id || '',
      telesale_id: '',
      job_category_id: form?.job_category_id || '',
      limit: '',
      have_call: 1,
    });
    this.props.getTelesalesFilter({
      enterprise_id: form?.enterprise_id || '',
      job_id: form?.job_category_id || '',
      sub_job_id: form?.job_id || '',
    });
    this.props.getListCallStatus();
    this.props.getReviewerList();
    this.props.getEnterprisesFilter({
      limit: '',
    });
    this.props.getQuessionsAndAnswerFilter({
      enterprise_id: form?.enterprise_id || '',
      job_category_id: form?.job_category_id,
    });
    this.getListKeyword();
  }

  getListKeyword = (search, page) => {
    const { form } = this.state.form;
    this.props.getListKeyword({
      search: search || '',
      page: page || '',
      enterprise_id: form?.enterprise_id || '',
    });
  };

  getData({
    job_category_id,
    job_id,
    telesale_id,
    fromdate,
    todate,
    call_status,
    page,
    enterprise_id,
    job_form_question_id,
    job_form_answers_id,
    keyword,
  }) {
    this.props.getListCall({
      page: page || '',
      job_category_id: job_category_id || '',
      job_id: job_id || '',
      telesale_id: telesale_id || '',
      fromdate: fromdate || '',
      todate: todate || '',
      call_status: call_status || '',
      enterprise_id: enterprise_id || '',
      job_form_question_id: job_form_question_id || '',
      job_form_answers_id: job_form_answers_id || '',
      keyword: keyword || '',
    });
    this.setState({
      ...this.state,
      form: {
        job_category_id: job_category_id || '',
        job_id: job_id || '',
        telesale_id: telesale_id || '',
        [STRING.fromDate]: fromdate || '',
        [STRING.toDate]: todate || '',
        call_status: call_status || '',
        enterprise_id: enterprise_id || '',
        job_form_question_id: job_form_question_id || '',
        job_form_answers_id: job_form_answers_id || '',
        keyword: keyword || '',
      },
    });
  }

  validateFormAdd() {
    const { id } = this.props.match?.params;
    const { reviewer } = this.state;
    // id == undefined ? (reviewer.reviewer_id = id) : (reviewer.reviewer_id = reviewer.reviewer_id);
    if (id == undefined && !checkAllAttrFilled(reviewer)) {
      return notifyFail('Vui lòng chọn đầy đủ các thông tin trong phần giao việc');
    }
    if ((id != undefined && reviewer.amount == '') || reviewer.startDate == '' || reviewer.endDate == '') {
      return notifyFail('Vui lòng chọn đầy đủ các thông tin trong phần giao việc');
    }
    return true;
  }

  async Assign() {
    const { id } = this.props.match?.params;
    const { form, reviewer } = this.state;
    const { [STRING.fromDate]: fromdate, [STRING.toDate]: todate } = this.state.form;
    const { [STRING.startDate]: start_date, [STRING.endDate]: end_date } = this.state.reviewer;
    const check = await this.validateFormAdd();
    if (check) {
      const payload = {
        amount: reviewer.amount,
        reviewer_id: id == undefined || id == '' ? reviewer.reviewer_id : id,
        enterprise_id: form.enterprise_id,
        job_category_id: form.job_category_id,
        fromdate: fromdate,
        job_id: form.job_id,
        telesale_id: form.telesale_id,
        todate: todate,
        start_date: start_date,
        end_date: end_date,
      };
      try {
        const res = await requestAssignReviewer(payload);
        this.setState({ isLoading: false });
        notifySuccess('Thêm thông tin thành công');
        this.props.history.push(ROUTER.EVALUATION_RESULT);
      } catch (err) {
        this.setState(
          {
            isLoading: false,
          },
          () => notifyFail(err.msg)
        );
      }
    }
  }

  renderBody() {
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <Row className="mt-3" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
            <Col md className="bg">
              <Row>
                <Col md={5} sm={12}>
                  <h2> Giao việc </h2>
                  <div className="line" />
                  <h4>Danh sách cuộc gọi cần đánh giá</h4>
                </Col>
              </Row>
              <Row>
                <Col md={12}>{this.renderField()}</Col>
              </Row>
              <Row className="mt-2">{this.renderTable()}</Row>
            </Col>
          </Row>
          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
            <Col md className="bg">
              <Row>{this.renderAssign()}</Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderButton() {
    return (
      <div className="text-center text-md-right">
        <Button variant="primary" onClick={() => this.Assign()} className="text-light">
          Giao việc
        </Button>
      </div>
    );
  }

  renderField() {
    const {
      job_id,
      call_status,
      telesale_id,
      job_category_id,
      enterprise_id,
      job_form_question_id,
      job_form_answers_id,
      keyword,
    } = this.state.form;
    const categoryList = this.props.jobState?.data;
    const job = this.props.subJobState?.data;
    const saleList = this.props.telesaleState?.data;
    const callStatus = this.props.statusState?.data;
    const company = this.props.companyState?.data;
    const { [STRING.fromDate]: fromdate, [STRING.toDate]: todate } = this.state.form;
    const quessionsAndAnswer = this.props.quessionsAndAnswerState.data;
    reactotron.log(categoryList);
    return (
      <div className="w-100">
        <Row className="mb-2">
          {/* <Col md className="pb-1 pb-md-0">
            <FormControl
              as="select"
              value={enterprise_id}
              onChange={(e) => this.searchBarChange('enterprise_id', e.target.value)}
            >
              <option value="">Công ty</option>
              {company && company?.length
                ? company?.map((value) => (
                    <option value={value.id} key={value.id}>
                      {value.full_name}
                    </option>
                  ))
                : ''}
            </FormControl>
          </Col> */}
          <Col md className="pb-1 pb-md-0">
            <FormControl
              as="select"
              value={job_category_id}
              onChange={(e) => this.searchBarChange('job_category_id', e.target.value)}
            >
              <option value="">Công việc chung</option>
              {categoryList && categoryList.length
                ? categoryList?.map((value) => (
                    <option value={value.id} key={value.id}>
                      {value.name}
                    </option>
                  ))
                : ''}
            </FormControl>
          </Col>
          <Col md className="pb-1 pb-md-0">
            <FormControl
              as="select"
              id=""
              value={job_id}
              onChange={(e) => this.searchBarChange('job_id', e.target.value)}
            >
              <option value="">Công việc</option>
              {job_category_id != ''
                ? job?.length > 0 && job?.map((value, index) => <option value={value.id}>{value.name}</option>)
                : ''}
            </FormControl>
          </Col>
          <Col md className="pb-1 pb-md-0">
            <FormControl
              as="select"
              id=""
              value={call_status}
              onChange={(e) => this.searchBarChange('call_status', e.target.value)}
            >
              <option value="">Trạng thái</option>
              {callStatus.length > 0 &&
                callStatus?.map((value, index) => <option value={value.id}>{value.name}</option>)}
            </FormControl>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md className="pb-1 pb-md-0">
            <FormControl
              as="select"
              id=""
              value={telesale_id}
              onChange={(e) => this.searchBarChange('telesale_id', e.target.value)}
            >
              <option value="">Sale</option>
              {job_id != ''
                ? saleList?.length > 0 &&
                  saleList?.map((value, index) => (
                    <option value={value.id}>
                      {value.name} - {value?.phone}
                    </option>
                  ))
                : ''}
            </FormControl>
          </Col>
          <Col md className="pb-1 pb-md-0">
            <FormControl
              as="select"
              id=""
              value={job_form_question_id}
              onChange={(e) => this.searchBarChange('job_form_question_id', e.target.value)}
            >
              <option value="0">Câu hỏi</option>
              {job_category_id != ''
                ? quessionsAndAnswer?.length > 0 &&
                  quessionsAndAnswer?.map((value, index) => <option value={value.id}>{value.name}</option>)
                : ''}
            </FormControl>
          </Col>
        </Row>

        <Row>
          <Col md className="pb-1 pb-md-0">
            <FormControl
              as="select"
              id=""
              value={job_form_answers_id}
              onChange={(e) => this.searchBarChange('job_form_answers_id', e.target.value)}
            >
              <option value="0">Kết quả</option>
              {job_form_question_id != ''
                ? quessionsAndAnswer.length > 0 &&
                  quessionsAndAnswer?.map((value, index) =>
                    value.id == job_form_question_id
                      ? value?.job_form_answers?.length > 0 &&
                        value?.job_form_answers?.map((item, index) => <option value={item.id}>{item.name}</option>)
                      : ''
                  )
                : ''}
            </FormControl>
          </Col>
          <Col md className="pb-1 pb-md-0">
            <FormControl
              as="select"
              id=""
              value={keyword}
              onChange={(e) => this.searchBarChange('keyword', e.target.value)}
            >
              <option value="0">Nội dung cảnh báo</option>
              {this.props.keywordState.data?.length > 0
                ? this.props.keywordState.data?.map((value, index) => (
                    <option value={value.id}>{value.name || '--'}</option>
                  ))
                : ''}
            </FormControl>
          </Col>
          <Col md className="pb-1 pb-md-0">
            <DatePickerCustom
              className={`date-picker form-control`}
              placeholderText={STRING.fromDate}
              handleChange={this.searchBarChange}
              selected={fromdate}
              dateFormat="dd/MM/yyyy"
              maxDate={todate === null ? '' : new Date(todate)}
            />
          </Col>
          <Col md className="pb-1 pb-md-0">
            <DatePickerCustom
              className={`date-picker form-control`}
              placeholderText={STRING.toDate}
              handleChange={this.searchBarChange}
              selected={todate}
              dateFormat="dd/MM/yyyy"
              minDate={new Date(fromdate)}
            />
          </Col>
        </Row>
      </div>
    );
  }

  renderTable() {
    const callHistory = this.props.callState?.data;
    const itemsCount = this.props.callState?.paging?.count;
    return (
      <div className="w-100 table-wrap">
        <Row>
          <Col md={2}>
            <span className="d-inline-block">
              Kết quả lọc: <b className="text-danger">{itemsCount || 0}</b>
            </span>
          </Col>
          <Col md={3} style={{ color: 'blue' }}>
            <i onClick={() => this.setShowModal('modal', true)} style={{ cursor: 'pointer' }} className="list_result">
              {'Xem danh sách kết quả>>'}
            </i>
          </Col>
        </Row>
      </div>
    );
  }

  setShowModal = (modal, bool, data) => {
    this.setState({
      [modal]: bool,
    });
  };

  editModal = () => {
    const { modal } = this.state;
    return (
      <Modal
        show={modal}
        onHide={() => this.setShowModal('modal', false)}
        dialogClassName="modal-100w"
        centered
        scrollable
        size="lg"
      >
        <Modal.Header closeButton className="text-white bg-primary pb-0">
          <h5 className="text-light pb-0">Kết quả lọc cuộc gọi</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">{this.renderModal()}</Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="danger" onClick={() => this.setShowModal('modal', false)} className="text-light">
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderModal() {
    const callHistory = this.props.callState?.data;
    const itemsCount = this.props.callState?.paging?.count;
    const { form } = this.state;
    const categoryList = this.props.jobState?.data;
    const job = this.props.subJobState?.data;
    const saleList = this.props.telesaleState.data;
    const callStatus = this.props.statusState.data;
    const company = this.props.companyState.data;
    const { [STRING.fromDate]: fromdate, [STRING.toDate]: todate } = this.state.form;
    return (
      <div className="w-100 table-wrap">
        <Row>
          <b>Điều kiện lọc :</b>
        </Row>
        <Row>
          <Col md={6}>
            <Row>
              <Col md={5}>Công ty:</Col>
              <Col md={7}>
                {company && company?.length
                  ? company?.map((value) => (
                      <label value={form.enterprise_id}>
                        {form?.enterprise_id == value.id ? value?.full_name : ''}
                      </label>
                    ))
                  : ''}
              </Col>
            </Row>
            <Row>
              <Col md={5}>Công việc chung:</Col>
              <Col md={7}>
                {categoryList && categoryList?.length
                  ? categoryList?.map((value) => (
                      <label value={form.job_category_id}>{form?.job_category_id == value.id ? value?.name : ''}</label>
                    ))
                  : ''}
              </Col>
            </Row>
            <Row>
              <Col md={5}>Giao việc:</Col>
              <Col md={7}>
                {job && job?.length
                  ? job?.map((value) => (
                      <label value={form.job_id}>{form?.job_id == value.id ? value?.name : ''}</label>
                    ))
                  : '--'}
              </Col>
            </Row>
            <Row>
              <Col md={5}>Sale:</Col>
              <Col md={7}>
                {' '}
                {saleList && saleList?.length
                  ? saleList?.map((value) => (
                      <label value={form.telesale_id}>{form?.telesale_id == value.id ? value?.name : ''}</label>
                    ))
                  : '--'}
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col md={5}>Trạng thái:</Col>
              <Col md={7}>
                {callStatus && callStatus?.length
                  ? callStatus?.map((value) => (
                      <label value={form?.call_status}>{form?.call_status == value.id ? value?.name : ''}</label>
                    ))
                  : '--'}
              </Col>
            </Row>
            <Row>
              <Col md={5}>Thời gian:</Col>
              <Col md={7}>
                <p className="m-0">
                  {fromdate ? toDateString(fromdate) : ''}-{todate ? toDateString(todate) : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={5}>Câu hỏi:</Col>
              <Col md={7}></Col>
            </Row>
            <Row>
              <Col md={5}>Nội dung cảnh báo:</Col>
              <Col md={7}></Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <b className="d-inline-block">Kết quả lọc: {itemsCount || 0}</b>
        </Row>
        <Table striped hover>
          <tbody>
            {callHistory?.length ? (
              callHistory.map((call) => this.renderItem(call))
            ) : (
              <Empty description={<span>Không có dữ liệu</span>} />
            )}
          </tbody>
          <Row>
            <Col md={12} className="d-flex justify-content-center justify-content-md-end p-0">
              {this.renderPagination()}
            </Col>
          </Row>
        </Table>
      </div>
    );
  }
  renderItem(call) {
    return (
      <>
        <div className="w-100 table-wrap ">
          <Row className="group-info">
            <Col md={9} sm={9} xs={9}>
              <b style={{ fontSize: 17 }}>{call.customer_name || '--'}</b> <span>: {call.customer_phone}</span>
            </Col>
            <Col md={3} sm={3} xs={3}>
              <i className="fa fa-user" aria-hidden="true"></i>
              <span className="ml-2">{call.telesale_name}</span>
            </Col>
            <Col md={9} sm={9} xs={9}>
              <i className="fa fa-suitcase" aria-hidden="true"></i> <span> {call.job_name}</span>
            </Col>
            {/* <Col md={3} sm={3} xs={3}>
                <span className="ml-2">{call.df_call_status_id || "--"}</span>
              </Col> */}
            <Col md={3} sm={3} xs={3}>
              <span className="ml-2 text-success">{call.df_call_status_name || '--'}</span>
            </Col>
            <Col md={9} sm={9} xs={9}>
              <i className="fa fa-clock-o" aria-hidden="true"></i>
              <span>{Intl.DateTimeFormat('vi-VN').format(new Date(call?.start_call_time || '--'))} </span>
              <span>
                <b className="ml-2">| {call?.call_duration || '--'} giây</b>{' '}
              </span>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  renderAssign() {
    const reviewerList = this.props.reviewerState?.data;
    const { reviewer } = this.state.reviewer;
    const { [STRING.startDate]: start_date, [STRING.endDate]: end_date } = this.state.reviewer;
    const { id } = this.props.match?.params;
    return (
      <div className="w-100">
        <b className="d-inline-block">Giao việc</b>
        <Row className="mb-1">
          <Col md={12} className="p-0">
            <Row className="m-3">
              <span>
                <b> Nhân viên đánh giá</b>
              </span>
            </Row>
            <Row>
              <Col md={12}>
                <FormControl
                  as="select"
                  id=""
                  value={reviewer?.reviewer_id}
                  onChange={(e) => this.handleKeyPress('reviewer_id', e.target.value)}
                >
                  <option value="">Nhân viên đánh giá</option>
                  {reviewerList?.length > 0 &&
                    reviewerList?.map((value, index) =>
                      id == undefined || id == '' ? (
                        <option value={value.id}>
                          {value?.full_name} ( Đang thực hiện {value?.totalReviewed || '--'}, còn lại{' '}
                          {value?.remain || '--'})
                        </option>
                      ) : id == value.id ? (
                        <option value={value.id} selected>
                          {value?.full_name} ( Đang thực hiện {value?.totalReviewed || '--'}, còn lại{' '}
                          {value?.remain || '--'})
                        </option>
                      ) : (
                        ''
                      )
                    )}
                </FormControl>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={4} className="p-0">
            <b className="m-3">Số lượng</b>
            <Row>
              <Col md={12}>
                <FormControl
                  type="number"
                  id=""
                  placeholder="Nhập số lượng"
                  value={reviewer?.amount}
                  onChange={(e) => this.handleKeyPress('amount', e.target.value)}
                ></FormControl>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <b>Bắt đầu</b>
            <DatePickerCustom
              className={`date-picker form-control`}
              placeholderText={STRING.startDate}
              handleChange={this.handleKeyPress}
              selected={start_date}
              dateFormat="dd/MM/yyyy"
              maxDate={end_date === null ? '' : new Date(end_date)}
            />
          </Col>
          <Col md={4}>
            <b>Kết thúc</b>
            <DatePickerCustom
              className="form-control"
              placeholderText={STRING.endDate}
              dateFormat="dd/MM/yyyy"
              selected={end_date}
              handleChange={this.handleKeyPress}
              minDate={new Date()}
            />
          </Col>
        </Row>

        <Row className="justify-content-center mt-4">{this.renderButton()}</Row>
        {/* </tbody>
        </Table> */}
      </div>
    );
  }

  renderPagination() {
    const itemsCount = this.props.callState?.paging?.count;
    const limit = this.props.callState?.paging?.limit;
    const page = this.props.callState?.paging?.page;
    const {
      job_category_id,
      job_id,
      telesale_id,
      fromdate,
      todate,
      call_status,
      enterprise_id,
      job_form_question_id,
      job_form_answers_id,
      keyword,
    } = this.state.form;
    return (
      <Pagination
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled
        activePage={page}
        itemsCountPerPage={limit}
        totalItemsCount={itemsCount}
        pageRangeDisplayed={5}
        hideNavigation
        onChange={(page) => {
          this.setState({
            ...this.state,
            activePage: page,
          });
          this.getData({
            page,
            job_category_id,
            job_id,
            telesale_id,
            fromdate,
            todate,
            call_status,
            enterprise_id,
            job_form_question_id,
            job_form_answers_id,
            keyword,
          });
        }}
      />
    );
  }
  searchBarChange = async (field, value) => {
    this.setState(
      {
        ...this.state,
        form: {
          ...this.state.form,
          [field]: value,
        },
        // [field]: value,
      },
      () => {
        if (field !== 'search') {
          const {
            page,
            job_id,
            call_status,
            telesale_id,
            job_category_id,
            enterprise_id,
            [STRING.fromDate]: fromdate,
            [STRING.toDate]: todate,
            job_form_question_id,
            job_form_answers_id,
            keyword,
          } = this.state.form;
          this.getData({
            page: page || '',
            job_category_id: job_category_id || '',
            job_id: job_id || '',
            call_status: call_status || '',
            telesale_id: telesale_id || '',
            fromdate: fromdate || '',
            todate: todate || '',
            enterprise_id: enterprise_id || '',
            job_form_question_id: job_form_question_id || '',
            job_form_answers_id: job_form_answers_id || '',
            keyword: keyword || '',
          });
          this.props.getJobCategoriesFilter({ enterprise_id: enterprise_id || '', telesale_id: '', have_call: 1 });
          this.props.getJobFilter({
            enterprise_id: enterprise_id || '',
            telesale_id: '',
            job_category_id: job_category_id || '',
            limit: '',
            have_call: 1,
          });
          this.props.getQuessionsAndAnswerFilter({
            enterprise_id: enterprise_id || '',
            job_category_id: job_category_id || '',
          });
          this.props.getTelesalesFilter({
            enterprise_id: enterprise_id || '',
            job_id: job_category_id || '',
            sub_job_id: job_id || '',
          });
          this.props.getListKeyword({
            search: '',
            page: '',
            enterprise_id: enterprise_id || '',
          });
        }
      }
    );
  };

  handleKeyPress = async (field, value) => {
    this.setState({
      ...this.state,
      reviewer: {
        ...this.state.reviewer,
        [field]: value,
      },
    });
  };

  render() {
    const { isLoading } = this.props.callState;
    const { [STRING.fromDate]: fromDate, [STRING.toDate]: toDate } = this.state.form;
    let loading = <Loading />;
    if (!isLoading) {
      loading = null;
    }
    return (
      <>
        {loading}
        {this.editModal()}
        {this.renderBody()}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  companyState: state.enterpriseFilterReducer,
  jobState: state.jobCategoryFilterReducer,
  subJobState: state.jobFilterReducer,
  telesaleState: state.telesaleFilterReducer,
  statusState: state.callStatusReducer,
  reviewerState: state.reviewerListReducer,
  callState: state.listCallReducer,
  quessionsAndAnswerState: state.quessionsAndAnswerReducer,
  keywordState: state.keywordReducer,
});

const mapDispatchToProps = {
  getEnterprisesFilter,
  getJobCategoriesFilter,
  getJobFilter,
  getTelesalesFilter,
  getListCallStatus,
  getReviewerList,
  getListCall,
  getQuessionsAndAnswerFilter,
  getListKeyword,
  // createAccount,
  // getProvinceList
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AssignScreen));
