import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import '@styles/RegisterRequest.css';
import Loading from '@src/components/Loading';
import { Row, Col, FormControl, Button } from 'react-bootstrap';
import { ROUTER } from '@constants/Constant';
import { SALE_STATUS_REJECT } from '@constants/Constant';
import { connect } from 'react-redux';
import { getRejectedRegisterList, getProvinceList } from '@src/redux/actions';
import { getItemFromId } from '@utils/getItemFromId';
import { renderSelection } from '@utils/renderSelection';
import { notifyFail } from '@utils/notify';
import Pagination from '@components/Pagination';
import { Empty, Avatar } from 'antd';
import { ReactSelect } from '@components/ReactSelect';

class Screen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBar: {
        searchKey: '',
        status: '',
        city: '',
      },
    };
  }

  componentDidMount() {
    this.getData();
  }

  handleChangeSelect = (selectedOption, field) => {
    this.setState(
      {
        ...this.state,
        searchBar: {
          ...this.state.searchBar,
          [field]: selectedOption.value,
        },
      },
      () => {
        const { searchBar } = this.state;
        this.getData({
          search: searchBar.searchKey,
          province_id: searchBar.city,
          status: searchBar.status,
        });
      }
    );
  };

  getData(payload) {
    this.props.getRejectedRegisterList(payload);
    this.props.getProvinceList();
  }

  handlePageChange = (page) => {
    const { searchBar } = this.state;
    this.getData({
      search: searchBar.searchKey,
      province_id: searchBar.city,
      status: searchBar.status,
      page: page,
    });
  };

  renderTitle() {
    return (
      <>
        <Row>
          <Col md={5} sm={12}>
            <h2 className="text-left" style={{ marginBottom: '10px' }}>
              {' '}
              Yêu cầu bị từ chối
            </h2>
            <div class="line"></div>
          </Col>
        </Row>
      </>
    );
  }

  renderBody() {
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <Row
            className="mt-1"
            style={{ textAlign: 'center', padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}
          >
            <Col md className="bg">
              {this.renderTitle()}
              {this.renderField()}
            </Col>
          </Row>
          <Row>
            <Col md={12} style={{ padding: '0 20px' }}>
              {this.renderTable()}
            </Col>
          </Row>
          <Row>
            <Col md={12} className="d-flex justify-content-md-end justify-content-center" style={{ padding: '0 20px' }}>
              <Pagination screen="rejectedRegisterState" handlePageChange={this.handlePageChange} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderButton() {
    return (
      <div className="text-center text-md-right">
        <Link to={ROUTER.COMPANY_ADD}>
          <Button
            variant="primary"
            className="header-btn btn-oversize"
            onClick={() => this.setShowModal('modalAdd', true)}
          >
            Thêm Công ty
          </Button>
        </Link>
      </div>
    );
  }

  renderField() {
    const { searchBar } = this.state;
    const listProvince = this.props.provinceState.data?.map(
      (province) =>
        new Object({
          value: province.id,
          label: province.name,
        })
    );
    listProvince.unshift({ value: '', label: 'Chọn tỉnh thành phố' });
    return (
      <div className="w-100">
        <Row>
          <Col md={6} sm={12}>
            <FormControl
              type="text"
              id=""
              placeholder="Tên hoặc số điện thoại ứng viên"
              value={searchBar.searchKey}
              onChange={(e) => this.searchBarChange('searchKey', e.target.value)}
              onKeyPress={(e) => this.handleKeyPress(e)}
              className="h-100"
            />
          </Col>
          <Col md={3} sm={12}>
            <ReactSelect
              handleChangeSelect={(e) => this.handleChangeSelect(e, 'city')}
              list={listProvince}
              placeholder="Chọn tỉnh thành phố"
            />
          </Col>
          <Col md={3} sm={12}>
            <FormControl
              as="select"
              id=""
              value={searchBar.status}
              onChange={(e) => this.searchBarChange('status', e.target.value)}
              className="h-100"
            >
              <option value="">Trạng thái</option>
              {renderSelection(SALE_STATUS_REJECT)}
            </FormControl>
          </Col>
        </Row>
      </div>
    );
  }

  renderTable() {
    const rejectedRegister = this.props.rejectedRegisterState?.data;
    const { paging } = this.props.rejectedRegisterState;
    const err = this.props.rejectedRegisterState?.error;
    if (err) {
      notifyFail(err?.msg);
    }
    return (
      <div className="w-100 table-wrap">
        <span className="pb-2 d-inline-block">
          Kết quả lọc: <b className="text-danger">{paging?.totalItemCount || 0}</b>
        </span>
        {rejectedRegister.length ? (
          rejectedRegister.map((item) => this.renderItem(item))
        ) : (
          <Empty description={<span>Không có dữ liệu</span>} />
        )}
      </div>
    );
  }

  renderItem(item) {
    const saleStatus = getItemFromId(SALE_STATUS_REJECT, item.user_sale_info.user_sale_status_id);
    return (
      <>
        <Row style={{ borderRadius: '8px', marginBottom: '20px' }}>
          <Col
            md
            style={{
              padding: '20px 0px 25px 40px',
              backgroundColor: '#ffffff',
              borderRadius: '8px',
            }}
            className="req-hov"
          >
            <Row
              //   className="group-info group-info-request"
              onClick={() => this.props.history.push(ROUTER.REGISTER_REQUEST_REJECTED_DETAIL + `/${item.id}`)}
            >
              <Col md={12} className="header">
                <Row>
                  <Col md={22} className="lower d-flex px-0">
                    <Avatar src={item.user_sale_info.profile_image} size={50} className="bg-blue text-white">
                      {item.user_sale_info.profile_image !== '' ? null : item.full_name[0]?.toUpperCase()}
                    </Avatar>
                  </Col>
                  <Col md={10}>
                    <span style={{ fontWeight: '700', fontSize: '18px', marginRight: 10 }}>{item.full_name}</span>
                    <span className={`lower ${saleStatus && saleStatus.color}`}>
                      <span style={{ fontSize: '14px' }}>{saleStatus && saleStatus?.name}</span>
                    </span>
                    <div>
                      <p
                        style={{
                          color: '#23A0CB',
                        }}
                      >
                        {item.user_sale_info.code}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={12} className="content p-0">
                <Row>
                  <Col md={1} className="p-0"></Col>
                  <Col md={11} className="p-0">
                    <Row className="lower" style={{ color: '#666666' }}>
                      <Col md={4} className="p-0">
                        <i className="fas fa-phone-alt icon-contact"></i>
                        <span>{item.phone || '--'}</span>
                      </Col>
                      <Col md={4} className="p-0">
                        <i className="fas fa-envelope icon-contact"></i>
                        <span>{item.email || '--'}</span>
                      </Col>
                      <Col md={4} className="p-0">
                        <i className="fas fa-map-marker icon-contact"></i>
                        <span>{item.user_sale_info.province?.name || '--'}</span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  searchBarChange = async (field, value) => {
    await this.setState({
      ...this.state,
      searchBar: {
        ...this.state.searchBar,
        [field]: value,
      },
    });

    if (field !== 'searchKey') {
      const { searchBar } = this.state;
      this.getData({
        search: searchBar.searchKey,
        province_id: searchBar.city,
        status: searchBar.status,
      });
    }
  };

  handleKeyPress = (e) => {
    const { searchBar } = this.state;
    if (e.charCode === 13) {
      this.getData({
        search: searchBar.searchKey,
        province_id: searchBar.city,
        status: searchBar.status,
      });
    }
  };

  render() {
    const { isLoading, error } = this.props.rejectedRegisterState;
    let loading = <Loading />;
    if (error) {
      notifyFail(error.msg);
    }
    if (!isLoading) {
      loading = null;
    }
    return (
      <>
        {loading}
        {this.renderBody()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  provinceState: state.ProvinceReducer,
  rejectedRegisterState: state.RejectedRegisterReducer,
});

const mapDispatchToProps = {
  getRejectedRegisterList,
  getProvinceList,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Screen));
