import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, FormControl, Button, Nav, Table } from 'react-bootstrap';
import { ROUTER, TYPE_MEDIA, USER_ROLE, STRING } from '@constants/Constant';
import { connect } from 'react-redux';
import reactotron from 'reactotron-react-js';
import { getJobFilter, getListCallStatus, getCallHistory } from '@src/redux/actions';
import { Empty } from 'antd';
class CallHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        search: '',
        job_id: '',
        call_status: '',
      },
    };
  }

  handleChange = async (field, value) => {
    this.setState(
      {
        ...this.state,
        form: {
          ...this.state.form,
          [field]: value,
        },
      },
      () => {
        if (field !== 'search') {
          const { job_id, call_status } = this.state.form;
          this.getData({
            job_id: job_id || '',
            call_status: call_status || '',
          });
        }
      }
    );
  };

  componentDidMount() {
    this.props.getJobFilter();
    this.props.getListCallStatus();
    this.getData({ page: 1 });
  }
  getData({
    page,
    call_type,
    job_id,
    call_status,
    telesale_id,
    start_call_time,
    limit = 1000,
    fromDate,
    toDate,
    is_late = 0,
    job_category_id,
    job_form_question_id,
    job_form_answers_id,
    call_review_config_id,
    keyword,
    search,
  }) {
    const { id } = this.props.match?.params;
    this.props.getCallHistory({
      page: page || '',
      call_type: call_type || '',
      job_id: job_id || '',
      call_status: call_status || '',
      telesale_id: id || '',
      start_call_time: start_call_time || '',
      limit: limit || '',
      fromDate: fromDate || '',
      toDate: toDate || '',
      is_late: is_late,
      job_category_id: job_category_id || '',
      job_form_question_id: job_form_question_id || '',
      job_form_answers_id: job_form_answers_id || '',
      call_review_config_id: call_review_config_id || '',
      keyword: keyword || '',
      search: search || '',
    });
  }
  handleKeyPress = (e) => {
    let { search, job_id, call_status } = this.state.form;
    if (e.charCode === 13) {
      search = search || '';
      this.getData({
        search,
        job_id,
        call_status,
      });
    }
  };
  renderField() {
    const { search, job_id, call_status } = this.state.form;
    const job = this.props.subJobState?.data;
    const callStatus = this.props.statusState.data;
    return (
      <div className="w-100">
        <Row>
          <Col md={6} sm={12} className="pl-0 pr-0 pr-md-2">
            <FormControl
              type="text"
              id=""
              placeholder="Tìm kiếm"
              value={search}
              onChange={(e) => this.handleChange('search', e.target.value)}
              onKeyPress={(e) => this.handleKeyPress(e)}
            />
          </Col>
          <Col md={3} sm={12} className="pl-0 pr-0 pr-md-2">
            <FormControl as="select" id="" value={job_id} onChange={(e) => this.handleChange('job_id', e.target.value)}>
              <option value="">Công việc</option>
              {job?.length > 0 && job?.map((value, index) => <option value={value.id}>{value.name}</option>)}
            </FormControl>
          </Col>
          <Col md={3} sm={12} className="pl-0 pr-0 pr-md-2">
            <FormControl
              as="select"
              id=""
              value={call_status}
              onChange={(e) => this.handleChange('call_status', e.target.value)}
            >
              <option value="0">Trạng thái</option>
              {callStatus.length > 0 &&
                callStatus?.map((value, index) => <option value={value.id}>{value.name}</option>)}
            </FormControl>
          </Col>
        </Row>
      </div>
    );
  }

  renderHistory(history, detail) {
    const date = new Date(history.start_call_time);
    return (
      <Row className="content-header personal-info">
        <Col md={12}>
          <Row>
            <span>
              <img
                src={require('../../../../assets/telesale-icon.png')}
                style={{ marginRight: '14px', marginLeft: '12px' }}
              />
            </span>
            <span>
              <label className="m-0" style={{ fontSize: 18 }}>
                {history?.customer_name}
              </label>
              <div>
                <span>
                  <i class="fal fa-phone-alt" style={{ marginRight: '8px' }}></i>
                </span>
                <span>{history?.customer_phone}</span>
              </div>
            </span>
          </Row>
          <Row>
            <Col md={6} style={{ marginTop: '30px', borderRight: '1px solid' }}>
              <Row style={{ color: '#7F8FA4' }}>Chiến dịch</Row>
              <Row>{history?.job_name}</Row>
              <Row style={{ marginTop: '16px' }}>
                <Col md={6} className="p-0">
                  <Row style={{ color: '#7F8FA4' }}>Ngày</Row>
                  <Row>
                    <span>
                      {date.getHours()}:{date.getMinutes()}{' '}
                    </span>
                    <span>{Intl.DateTimeFormat('vi-VN').format(date)}</span>
                  </Row>
                </Col>
                <Col md={6} className="p-0">
                  <Row style={{ color: '#7F8FA4' }}>Thời gian gọi</Row>
                  <Row>{history?.call_duration || '--'}s</Row>
                </Col>
              </Row>
              {/* <Row style={{ marginTop: '15px' }}>
                <audio controls className="audio-player">
                  <source src="horse.mp3" type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </Row> */}
            </Col>
            <Col md={3} style={{ marginTop: '30px' }}>
              <Row style={{ color: '#7F8FA4' }}>Sale</Row>
              <Row>{history.sale_name}</Row>
              <Row style={{ color: '#7F8FA4', marginTop: '16px' }}>Đánh giá</Row>
              <Row>
                {history?.call_reviews?.length > 0 &&
                  history?.call_reviews?.map((v, index) => v?.call_review_config?.name + ', ')}
              </Row>
            </Col>
            <Col md={3} style={{ marginTop: '30px' }}>
              <Row style={{ color: '#7F8FA4' }}>Trạng thái</Row>
              <Row className="waiting">{history?.status_name}</Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  render() {
    const detail = this.props.detail;
    const callHistory = this.props.callHistoryState?.data;
    return (
      <>
        <Row>
          <Col>{this.renderField()}</Col>
        </Row>

        {callHistory.length > 0 ? (
          callHistory?.map((history) => this.renderHistory(history, detail))
        ) : (
          <Empty description="Không có dữ liệu"></Empty>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  subJobState: state.jobFilterReducer,
  statusState: state.callStatusReducer,
  callHistoryState: state.callHistoryReducer,
});

const mapDispatchToProps = {
  getJobFilter,
  getListCallStatus,
  getCallHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CallHistory));
