import React, { Component } from 'react';
import { Row, Col, FormControl, Button, Modal, FormGroup, Form } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import StepAdd from './_StepAdd';
import { QUESTION_TYPE } from '@constants/Constant';
import reactotron from 'reactotron-react-js';

class ModalAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 0,
      selected_option: [1, 1, 1],
    };
  }

  handleTypeChange(value) {
    setTimeout(() => this.props.handle('type_input', value), 100);
    if (value == QUESTION_TYPE.YES_NO) {
      this.addOption(true);
    } else {
      this.resetOption();
    }
  }

  addOption(isYesNoQues) {
    if (isYesNoQues) {
      this.props.handle('job_form_answers', [
        {
          name: 'Có',
          value: this.props.value.job_form_answers.length,
          job_form_answer_child: [],
          forward_job_id: '',
        },
        {
          name: 'Không',
          value: this.props.value.job_form_answers.length,
          job_form_answer_child: [],
          forward_job_id: '',
        },
      ]);
    } else {
      this.props.handle('job_form_answers', [
        ...this.props.value.job_form_answers,
        {
          name: 'Nhập thông tin vào đây',
          value: this.props.value.job_form_answers.length,
          forward_job_id: '',
          opportunity_category_id: '',
          product_id: '',
          job_form_answer_child: [],
          selected_option: 0,
        },
      ]);
    }
  }

  resetOption() {
    this.props.handle('job_form_answers', []);
  }

  renderOption = () => {
    return (
      <>
        <Row className="modal-row scroll-option">
          <Col md={5} className="">
            <Modal.Header>Lựa chọn</Modal.Header>
          </Col>
          <Col md={7} className="px-0">
            <i
              className={`fas fa-plus text-green add-btn ${
                this.props.value.type_input == QUESTION_TYPE.YES_NO ? ' not-allowed' : null
              }`}
              onClick={this.props.value.type_input != QUESTION_TYPE.YES_NO ? () => this.addOption() : null}
            ></i>
          </Col>
          {this.props.value.job_form_answers.map((item, index) => (
            <Col sm={12} key={index}>
              <StepAdd
                content={item}
                handle={this.props.handle}
                index={index}
                option={this.props.value.job_form_answers}
                value={this.props.value}
                isYesNoQues={this.props.value.type_input == QUESTION_TYPE.YES_NO}
                // handleCheckNameQuestion={this.handleCheckNameQuestion(value )}
              />
            </Col>
          ))}
        </Row>
      </>
    );
  };

  // changOption(value, index) {
  //   let selected_option = [...this.state.selected_option];
  //   selected_option[index] = value;
  //   this.setState({
  //     selected_option,
  //   });
  // }

  render() {
    const { value } = this.props;
    return (
      <>
        <Modal
          show={this.props.show}
          onHide={() => this.props.close('modalAdd', false)}
          dialogClassName="modal-90w"
          centered
          size="lg"
        >
          <Modal.Header closeButton className="bg-primary">
            <h5 className="text-light pb-0">Tạo câu hỏi</h5>
          </Modal.Header>

          <Modal.Body className="custom-body">
            <FormGroup className="text-left">
              <Row className="modal-row">
                <Col md={5}>
                  <Modal.Header>Tên câu hỏi</Modal.Header>
                </Col>
                <Col md={7}>
                  <FormControl
                    type="text"
                    placeholder="Nhập câu hỏi"
                    value={value.name}
                    onChange={(e) => this.props.handle('name', e.target.value)}
                  ></FormControl>
                </Col>
              </Row>

              <Row className="modal-row">
                <Col md={5}>
                  <Modal.Header>Chọn giá trị</Modal.Header>
                </Col>
                <Col md={7}>
                  <FormControl
                    as="select"
                    id=""
                    value={value.type_input}
                    onChange={(e) => this.handleTypeChange(parseInt(e.target.value))}
                  >
                    <option value={QUESTION_TYPE.TEXT}>Nhập chữ</option>
                    <option value={QUESTION_TYPE.SELECT}>Chọn 1 đáp án</option>
                    <option value={QUESTION_TYPE.MULTIPLE_SELECT}>Chọn nhiều đáp án</option>
                    <option value={QUESTION_TYPE.DATE}>Nhập ngày</option>
                    <option value={QUESTION_TYPE.YES_NO}>Chọn có hoặc không</option>
                  </FormControl>
                </Col>
              </Row>
              <Row className="modal-row">
                <Col md={5}>
                  <Modal.Header>Điểm kinh nghiệm</Modal.Header>
                </Col>
                <Col md={7}>
                  <FormControl
                    type="number"
                    placeholder="Nhập điểm kinh nghiệm"
                    value={value.reward_point}
                    onChange={(e) => this.props.handle('reward_point', parseInt(e.target.value))}
                  ></FormControl>
                </Col>
              </Row>
              {/* <Row className="modal-row">
                <Col md={5}>
                  <Modal.Header>Loại cơ hội</Modal.Header>
                </Col>
                <Col md={7}>
                  <FormControl
                    as="select"
                    // value={this.props.content.opportunity_category_id}
                    // onChange={(e) => this.handleTypeChange('opportunity_category_id', e.target.value)}
                  >
                    <option value="0">Không phải Cơ hội</option>
                    <option value="1">Khiếu nại</option>
                    <option value="2">Bảo hiểm</option>
                    <option value="3">Mua hàng</option>
                  </FormControl>
                </Col>
              </Row> */}

              {[QUESTION_TYPE.SELECT, QUESTION_TYPE.MULTIPLE_SELECT, QUESTION_TYPE.YES_NO].includes(
                parseInt(value.type_input)
              )
                ? this.renderOption()
                : null}
              <Row className="modal-row">
                <Col md={5}>
                  <Modal.Header>Bắt buộc</Modal.Header>
                </Col>
                <Col md={7} className="px-0">
                  <Form.Check
                    type="checkbox"
                    className="px-0 require-check"
                    onChange={(e) => this.props.handle('is_require', e.target.checked)}
                    checked={value.is_require}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button variant="success" className="text-light" onClick={() => this.props.add()}>
              Tạo mới
            </Button>
            <Button variant="danger" className="text-light" onClick={() => this.props.close('modalAdd', false)}>
              Đóng
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ModalAdd;
