import {
  getListhotlineNew,
  getProvinceList,
  getUserInfo,
} from '@actions/index';
import CallUtil from '@components/callProviders/CallUtil';
import ImportExport from '@components/headerFunc/ImportExport';
import { requestGetCallStatus } from '@constants/Api';
import { TOKEN_MIXPANEL } from '@constants/Constant';
import { Tooltip } from 'antd';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IconAntd from 'src/modules/IconAntd';
import { AvatarUser } from './components/AvatarUser.Header';
import ClientStatus from './components/ClientStatus.Header';
import DropdownHeader from './components/Dropdown.Header';
import HotlineHeader from './components/Hotline.Header';
import NotiHeader from './components/Noti.Header';
import { UpdateStyled } from './style';

// eslint-disable-next-line no-undef
const DEV_DEV = process?.env?.REACT_APP_ENV === 'dev';

const Header = ({ callClients }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userInfo = useSelector(state => state.userReducer);

  if (userInfo.err) alert('Phiên đăng nhập hết hạn');
  const userName =
    userInfo?.data?.role_id == 2
      ? userInfo?.data?.user_enterprise_info?.office_name
      : userInfo?.data?.full_name;

  React.useEffect(() => {
    dispatch(getUserInfo());
    dispatch(getProvinceList());
    dispatch(getListhotlineNew());
    // this.getListHotLine();
    getCallStatus();
    mixpanel.init(TOKEN_MIXPANEL, { debug: true });
    mixpanel.register({ userName: `${userName}` });
  }, []);

  const getCallStatus = async () => {
    let res = await requestGetCallStatus();
    // alert("header")
    CallUtil.listCallStatus = res.data;
  };

  return (
    <>
      {/* {this.state.isLoading ? <Loading /> : null} */}

      <nav
        className="main-header navbar navbar-expand navbar-light me-header"
        style={{
          zIndex: 1,
          backgroundColor: 'white',
          border: 'none',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px',
        }}
      >
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link cursor" data-widget="pushmenu">
              <i className="fas fa-bars" />
            </a>
          </li>
        </ul>

        {/* Right navbar links */}

        {userInfo?.data?.role_id == 5 && (
          <>
            <ClientStatus callClients={callClients} />
            <HotlineHeader />
          </>
        )}
        <ul className="navbar-nav ml-auto">
          {userInfo?.data?.role_id == 6 && (
            <>
              <i
                className="fas fa-bell fa-2x bell mr-3"
                data-toggle="dropdown"
              ></i>
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-right"
                style={{
                  width: '250px',
                  marginTop: '-10px',
                  marginRight: '45px',
                  padding: '0',
                }}
              >
                <a
                  className="dropdown-item cursor"
                  style={{
                    borderBottom: '1px solid #eeeeee',
                    padding: '20px 10px',
                  }}
                >
                  <div className="dropdown-admin-item">
                    <p className="me-txt-admin-drop">Thông báo</p>
                  </div>
                </a>
                <a
                  className="dropdown-item cursor"
                  style={{
                    borderBottom: '1px solid #eeeeee',
                    padding: '20px 10px',
                  }}
                >
                  <div className="dropdown-admin-item">
                    <p className="me-txt-admin-drop">Thông báo</p>
                  </div>
                </a>
              </div>
            </>
          )}

          <div style={{ display: 'flex', alignItems: 'center' }}>
            {DEV_DEV && (
              <Tooltip title="Xem những thay đổi">
                <UpdateStyled onClick={() => history.push('/release')}>
                  <div className="update">Nâng cấp</div>
                  <div style={{ display: 'flex', gap: '5px' }}>
                    <span className="text_update">Xem ngay(1.0)</span>
                    <IconAntd
                      className="icon_update"
                      icon="ArrowRightOutlined"
                    />
                  </div>
                </UpdateStyled>
              </Tooltip>
            )}
            {/* dropdown import export */}
            <ImportExport />

            {userInfo?.data?.role_id == 5 ? (
              <></>
            ) : (
              <div style={{ margin: '0 15px 0 10px' }}>
                <NotiHeader />
              </div>
            )}

            {/* Dropdown Admin Menu */}
            <li style={{ marginLeft: '5px' }}>
              <AvatarUser userInfo={userInfo?.data} />
            </li>
          </div>

          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#">
              <p className="me-txt-menu">
                <span className="user-name">{userName}</span>
                <i className="fas fa-chevron-down px-1"></i>
              </p>
            </a>

            <DropdownHeader />
          </li>
        </ul>
      </nav>
    </>
  );
};

export default React.memo(Header);
