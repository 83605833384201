import React, { useEffect, useRef, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { CALLING_CODE } from '@components/callProviders/CallConstant';
import useCallStatus from '@components/callProviders/useCallStatus';
import { Row, Typography } from 'antd';
import '../style.css';
import {
  requestCallHistoryDetail,
  requestCustomerDetail,
} from '@constants/Api';
import { isCallEnded } from '@components/callProviders/CallHelper';

const CallStatusBar = props => {
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [customerDetail, setCustomerDetail] = useState(null);
  const { call, callDuration, callId } = props.callStatus;
  const [formattedCallTime, setFormattedCallTime] = useState('');

  const timerRef = useRef();

  useEffect(() => {
    setCustomerDetail(null);
    getCustomerInfo();
  }, [callId]);

  useEffect(() => {
    if (call.status !== CALLING_CODE.Answered && timerRef.current) {
      clearInterval(timerRef.current);
    } else {
      setFormattedCallTime('00:00');
      timerRef.current = setInterval(() => {
        setFormattedCallTime(formatCallDuration());
      }, 1000);
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [callDuration, call]);

  const getCustomerInfo = async () => {
    if (!callId) {
      return;
    }
    try {
      setLoadingCustomer(true);
      const callDetail = await requestCallHistoryDetail({ id: callId });
      const res = await requestCustomerDetail(callDetail.data.customer_id);
      setCustomerDetail(res.data.main);
      console.log({ res });
    } catch (error) {
      console.log('getCustomerInfo', error);
    } finally {
      setLoadingCustomer(false);
    }
  };

  function str_pad_left(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }

  const UserInfo = () => {
    if (loadingCustomer) return <LoadingOutlined />;
    return (
      <Typography.Paragraph ellipsis style={{ padding: '0 10px', margin: 0 }}>
        {customerDetail?.name ? customerDetail?.name : 'Khách hàng mới'}
      </Typography.Paragraph>
    );
  };

  const formatCallDuration = () => {
    let endTime = callDuration.end
      ? callDuration.end
      : parseInt(new Date().getTime() / 1000);
    if (callDuration.start === 0) {
      endTime = 0;
    }
    const time = endTime - callDuration.start;
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    return str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);
  };

  const renderStatus = call => {
    if (call) {
      if (isCallEnded(call.status)) {
        return <></>;
      }

      switch (call.status) {
        case CALLING_CODE.Calling:
          return (
            <Row justify="center">
              <div style={{ textAlign: 'center', width: '100%' }}>
                <UserInfo />
                <br />
                <b style={{ color: 'white' }}>...</b>
              </div>
            </Row>
          );

        case CALLING_CODE.Ringing:
          return (
            <Row justify="center">
              <div style={{ textAlign: 'center', width: '100%' }}>
                <UserInfo />
                <br />
                <Row>
                  <b>Đang đổ chuông</b>
                  <div class="dot-flashing"></div>
                </Row>
              </div>
            </Row>
          );

        case CALLING_CODE.Answered:
          return (
            <Row justify="center">
              <div style={{ textAlign: 'center', width: '100%' }}>
                <UserInfo />
                <br />
                <b>Đang gọi {formattedCallTime}</b>
              </div>
            </Row>
          );

        default:
          return <></>;
      }
    } else {
      return <></>;
    }
  };

  return <>{renderStatus(call)}</>;
};

export default CallStatusBar;
