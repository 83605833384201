import { GET_REJECTED_REGISTER, GET_REJECTED_REGISTER_SUCCESS, GET_REJECTED_REGISTER_FAIL } from '@actions/type'
import reactotron from "reactotron-react-js"

const initialState = {
    data: {},
    isLoading: false,
    error: null,
    paging: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_REJECTED_REGISTER:
            return {
                ...state,
                isLoading: true
            }
        case GET_REJECTED_REGISTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: null,
                data: action.payload.response.data,
                paging: action.payload.response.paging,
            }
        case GET_REJECTED_REGISTER_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        default:
            return state
    }
}