import { requestCallToken } from '@constants/Api';
import EventEmitter from '@utils/EventEmitter';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import reactotron from 'src/ReactotronConfig';
import { sendCallBroadCast, sendClientBroadCast } from '../BroadCastUtil';
import {
  CALLING_CODE,
  CLIENT_STATUS,
  INCOMING_CALL,
  PROVIDER_NAME,
} from '../CallConstant';
import { checkAndShowError } from '../CallHelper';
import IncomingCallStringee from './IncomingCall.Stringee';
import useIsMainWindow from 'src/hooks/useIsMainWindow';

const stringeeSdk =
  'https://cdn.stringee.com/sdk/web/latest/stringee-web-sdk.min.js';
const StringeeCallClient = forwardRef((props, ref) => {
  const stringeeClient = useRef(null);

  const stringeeCall = useRef(null);
  const remoteVideoRef = useRef();
  const isTabActive = useIsMainWindow();
  console.log(
    '🚀 ~ file: StringeeCallClient.jsx:29 ~ StringeeCallClient ~ isTabActive:',
    isTabActive
  );

  useImperativeHandle(ref, () => ({
    login,
    makeCall,
    hangupCall,
    sendDtmf,
  }));

  const login = async () => {
    if (props.user.user_id) {
      try {
        const res = await requestCallToken();
        console.log('🚀 ~ file: StringeeCallClient.jsx:50 ~ login ~ res:', res);
        stringeeClient.current.connect(res.data.call_token);
      } catch (error) {
        // console.log({ error });
      }
    } else {
      // request logout
    }
  };
  const sendDtmf = async dtmf => {
    stringeeCall.current.sendDtmf(dtmf, callback => {
      console.log('send DTMF success', callback);
    });
  };
  const makeCall = async (destination, callId, masked = null) => {
    try {
      const call = new window.StringeeCall(
        stringeeClient.current,
        `call_${callId}`,
        '84' + destination.substring(1, destination.length)
      );
      try {
        call.makeCall(function (res) {
          if (res.r != 0) {
            sendCallBroadCast({
              name: PROVIDER_NAME.STRINGEE,
              status: CALLING_CODE.Ended,
              data: {},
            });
            // Object.hasOwn(errorDefinedNotErrorCode, res.message) &&     notifyFail(
            //   errorDefinedNotErrorCode[res.message]
            // );
            if (checkAndShowError(res.r, res.message)) {
              return;
            }

            // notifyFail(
            //   `Dịch vụ tạm thời bị gián đoạn hoặc không khả dụng. (${res.message})`
            // );
            // throw new Error(res.message);
          }
        });
      } catch (error) {
        console.log('makeCall error', error);
      }
      stringeeCall.current = call;
      callEventHandlers(call);
    } catch (error) {
      console.log('make new call stringee error', error);
    }
  };

  const hangupCall = () => {
    try {
      stringeeCall.current.hangup();
      sendCallBroadCast({
        name: PROVIDER_NAME.STRINGEE,
        status: CALLING_CODE.Ended,
        data: {},
      });
    } catch (error) {
      console.log('hangupCall', { error });
    }
  };

  const clientEventHandlers = () => {
    stringeeClient.current.on('connect', () => {
      console.log('++++++++++++++ connected to StringeeServers');
    });

    stringeeClient.current.on('authen', res => {
      console.log(
        '🚀 ~ file: StringeeCallClient.jsx:102 ~ clientEventHandlers ~ res:',
        res
      );
      sendClientBroadCast({
        name: PROVIDER_NAME.STRINGEE,
        status: CLIENT_STATUS.registered,
        data: res,
      });
    });
    // Nhận cuộc gọi đến
    stringeeClient.current.on('incomingcall', incomingcall => {
      // send event khi có cuộc gọi đến
      EventEmitter.emit('incomingCall', {
        note: 'có cuộc gọi đến',
        code: INCOMING_CALL.INCOMING,
        incomingCall: incomingcall,
      });
    });

    stringeeClient.current.on('otherdeviceauthen', data => {
      console.log('otherdeviceauthen_stringee: ', data);
    });
  };
  // event make call
  const callEventHandlers = call => {
    call.on('error', info => {
      console.log('call on error: ' + JSON.stringify(info));
    });

    call.on('addlocalstream', stream => {
      console.log('call on addlocalstream', stream);
    });

    call.on('addremotestream', stream => {
      console.log('call on addremotestream', stream);
      remoteVideoRef.current.srcObject = stream;
    });

    call.on('signalingstate', data => {
      console.log('call on signalingstate', data);
      sendCallBroadCast({
        name: PROVIDER_NAME.STRINGEE,
        status: data.sipCode,
        data,
      });
    });

    call.on('mediastate', state => {
      console.log('call mediastate ', state);
      // sendCallBroadCast({
      //   name: PROVIDER_NAME.STRINGEE,
      //   status: 'mediastate',
      //   data: state,
      // });
    });

    call.on('info', info => {
      console.log('call on info', info);
    });

    call.on('otherdevice', data => {
      console.log('call on otherdevice:' + JSON.stringify(data));
    });
  };

  const initStringee = () => {
    try {
      const script = document.createElement('script');
      script.src = stringeeSdk;
      script.async = true;
      document.body.appendChild(script);
      stringeeClient.current = new window.StringeeClient();
      // if already  logged in ezsale then login stringee
      login();
      clientEventHandlers();
    } catch (error) {
      console.log('connectStringee error', error);
    }
  };

  useEffect(() => {
    reactotron.log('Init StringeeCallClient');
    console.log('init-call');
    initStringee();

    remoteVideoRef.current.autoplay = true;
  }, []);

  return (
    <>
      <video
        id="remoteVideo"
        ref={remoteVideoRef}
        style={{ display: 'none' }}
      ></video>

      {isTabActive && (
        <IncomingCallStringee
          // makeCall={stringeeCall.current}
          remoteVideoRef={remoteVideoRef}
          isTabActive={isTabActive}
        />
      )}
    </>
  );
});

export default StringeeCallClient;
