import '@styles/Customer.css';
import { Modal as ModalAntd, Table as TableAntd, Tabs, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';

const { TabPane } = Tabs;
const { Text, Title, Link: LinkAntd } = Typography;

const NotAvailableText = () => <Text type="secondary">Chưa cập nhật</Text>;
const timeout = 500;
let locale = {
  emptyText: 'Không có dữ liệu',
};

const ModalUpdateHistory = ({ data, visible, setVisible }) => {
  const columns = [
    { title: 'STT', dataIndex: 'order', key: 'order', fixed: 'left' },
    {
      title: 'Họ tên',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      render: (value) => <Text>{value || <NotAvailableText />}</Text>,
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'dob',
      key: 'dob',
      render: (value) => <Text>{value ? moment(value).format('DD-MM-YYYY') : <NotAvailableText />}</Text>,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone',
      render: (value) => <Text style={{ color: '#1890ff' }}>{value || <NotAvailableText />}</Text>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (value) => <Text>{value || <NotAvailableText />}</Text>,
    },
    {
      title: 'Địa chỉ cụ thể',
      dataIndex: 'address',
      key: 'address',
      render: (value) => <Text>{value || <NotAvailableText />}</Text>,
    },
    {
      title: 'Giới tính',
      dataIndex: 'gender',
      key: 'gender',
      render: (value) => <Text>{value != null ? value === 1 ? 'Nam' : 'Nữ' : <NotAvailableText />}</Text>,
    },
    {
      title: 'Tỉnh/Thành phố',
      dataIndex: 'province_name',
      key: 'province_name',
      render: (value) => <Text>{value || <NotAvailableText />}</Text>,
    },
    {
      title: 'Nguồn khách hàng',
      dataIndex: 'customer_source',
      key: 'customer_source',
      render: (value) => <Text>{value || <NotAvailableText />}</Text>,
    },
    {
      title: 'Loại hình khách hàng',
      dataIndex: 'customer_type',
      key: 'customer_type',
      render: (value) => <Text>{value || <NotAvailableText />}</Text>,
    },
    {
      title: 'Sale',
      dataIndex: 'created_by',
      key: 'created_by',
      render: (value) => <Text>{value || <NotAvailableText />}</Text>,
    },
    {
      title: 'Ngày yêu cầu',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value) => <Text>{value ? moment(value).format('DD-MM-YYYY') : <NotAvailableText />}</Text>,
    },
  ];

  return (
    <ModalAntd
      title="Lịch sử yêu cầu cập nhật thông tin khách hàng"
      centered
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={1000}
      zIndex={10000}
    >
      <TableAntd locale={locale} pagination={false} columns={columns} dataSource={data} scroll={{ x: 1500, y: 360 }} />
    </ModalAntd>
  );
};

export default ModalUpdateHistory;
