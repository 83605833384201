import React, { Component } from 'react';
import '@styles/RegisterRequest.css';
import { Row, Col, FormControl } from 'react-bootstrap';
import { notifyFail } from '@utils/notify';
import ReactDOM from 'react-dom';
import { getJobList, getListOccasion, getListVpoint } from '@src/redux/actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { requestGetUserInfo, requestJobFilter, getListCategory } from '@constants/Api';
import { Label } from 'reactstrap';
import reactotron from 'src/ReactotronConfig';

class StepAdd extends Component {
  constructor(props) {
    super(props);
    const stepData = props.option && props.option[props.index];
    this.state = {
      input: false,
      enterprise_id: '',
      job: [],
      list_product: [],
      option: stepData?.forward_job_id ? 1 : stepData?.opportunity_category_id ? 2 : 0,
      opportunity_category: [],
    };
  }

  componentDidMount() {
    this.getUserInfor();
    // this.props.getListOccasion({
    //   page: '',
    //   telesale_id: '',
    //   job_id: '',
    //   status: '',
    //   opportunity_category_id: '',
    //   fromDate: '',
    //   toData: '',
    // });
    this.getListCategory();
    this.props.getListVpoint({
      search: '',
      page: '',
    });
  }

  async getListCategory() {
    try {
      const res = await getListCategory({ auto_convert: 1 });
      this.setState({
        opportunity_category: res.data,
      });
    } catch (error) {}
  }

  async getUserInfor() {
    try {
      const res = await requestGetUserInfo();
      this.requestJobFilter(res.data.user_id);
    } catch (err) {}
  }

  async requestJobFilter(id) {
    const { enterprise_id } = this.state;
    try {
      // const id = await requestGetUserInfo();
      // this.setState({ enterprise_id: res.data.user_id });
      const res = await requestJobFilter({
        enterprise_id: id,
        telesale_id: '',
        limit: '',
        job_category_id: '',
      });
      this.setState({
        job: res.data,
      });
    } catch (err) {}
  }

  handleDoubleClick(e) {
    this.setState(
      {
        input: true,
      },
      () => this.refs.optionInput.focus()
    );
  }

  checkDuplicateOption(index) {
    var job_form_answers = this.props.value?.job_form_answers;
    var duplicate = job_form_answers?.filter(
      (ele) => ele.name === job_form_answers[index].name && ele.name !== 'Nhập thông tin vào đây'
    );
    if (duplicate?.length > 1) {
      notifyFail('Các đáp án không được trùng nhau.');
      return false;
    }
    return true;
  }

  handleOnBlur(e, index) {
    var isPassed = this.checkDuplicateOption(index);
    if (!isPassed) {
      e.target.focus();
      return;
    }
    this.setState({
      input: false,
    });
  }

  handleTextChange(field, value) {
    var newOption = [];
    if (field === 'name') {
      newOption = this.props.option.map((item, index) =>
        index === this.props.index ? { ...item, name: value, is_active: 1 } : item
      );
    } else if (field === 'opportunity_category_id') {
      newOption = this.props.option.map((item, index) =>
        index === this.props.index ? { ...item, is_active: 1, opportunity_category_id: value } : item
      );
    } else if (field === 'product_id') {
      newOption = this.props.option.map((item, index) =>
        index === this.props.index ? { ...item, is_active: 1, product_id: value } : item
      );
    } else if (field === 'clear_opp') {
      newOption = this.props.option.map((item, index) =>
        index === this.props.index ? { ...item, is_active: 1, product_id: null, opportunity_category_id: null } : item
      );
    } else if (field === 'clear_opp_job') {
      newOption = this.props.option.map((item, index) =>
        index === this.props.index
          ? { ...item, is_active: 1, product_id: null, opportunity_category_id: null, forward_job_id: null }
          : item
      );
    } else {
      newOption = this.props.option.map((item, index) =>
        index === this.props.index ? { ...item, is_active: 1, forward_job_id: value } : item
      );
    }
    this.props.handle('job_form_answers', newOption);
  }

  remove(targetIndex) {
    const newOption = [...this.props.option];
    newOption.splice(targetIndex, 1);
    this.props.handle('job_form_answers', newOption);
  }

  // changOptionState(value, index) {
  //   reactotron.log('value', value, index);
  //   let option = [...this.state.option];
  //   option[index] = value;
  //   this.setState({ option });
  // }
  checkAllNull() {
    if (
      !this.props.content.forward_job_id &&
      !this.props.content.product_id &&
      !this.props.content.opportunity_category_id
    ) {
      return true;
    } else return false;
  }

  checkOppNull() {
    if (this.props.content.opportunity_category_id && this.props.content.product_id) {
      return true;
    } else return false;
  }

  async handleChangeOption(value) {
    await this.setState({
      ...this.state,
      option: value,
    });
  }

  render() {
    var style = document.activeElement === ReactDOM.findDOMNode(this.refs.optionInput) ? 'border border-primary' : null;
    const { job, option, opportunity_category } = this.state;
    const product_list = this.props.vpointState.data;
    reactotron.log('option', this.props.index);
    return (
      <>
        <div className={`assessment option-frame d-flex align-items-center mb-2 ${style}`}>
          <Row className="w-100">
            <Col md={5} className="d-flex justify-content-between px-0">
              {this.state.input ? (
                <FormControl
                  type="text"
                  className="border-0"
                  style={{ border: '1px solid #ced4da' }}
                  onBlur={(e) => this.handleOnBlur(e, this.props.index)}
                  value={this.props.content.name}
                  onChange={(e) => this.handleTextChange('name', e.target.value)}
                  onFocus={(e) => e.target.select(e)}
                  ref="optionInput"
                ></FormControl>
              ) : (
                <div className="w-100 d-flex align-items-center" onClick={(e) => this.handleDoubleClick(e)}>
                  <span className="d-block px-3">{this.props.content.name}</span>
                </div>
              )}
            </Col>
            <Col md={7} className="d-flex justify-content-between">
              <div>
                <div className="radio m-auto p-auto row">
                  <label className="row align-items-center">
                    <FormControl
                      type="radio"
                      name={this.props.index}
                      // value="0"
                      checked={this.checkAllNull() || option == 0}
                      onClick={() => {
                        this.handleChangeOption(0);
                        this.handleTextChange('clear_opp_job', null);
                      }}
                    />
                    <span className="ml-1">Không</span>
                  </label>
                </div>
              </div>
              <div>
                <div className="radio m-auto p-auto row">
                  <label className="row align-items-center">
                    <FormControl
                      type="radio"
                      name="option"
                      name={this.props.index}
                      // value="1"
                      checked={this.props.content.forward_job_id ? true : false || option == 1}
                      onClick={() => {
                        this.handleChangeOption(1);
                        this.handleTextChange('clear_opp', null);
                      }}
                    />
                    <span className="ml-1">Công việc</span>
                  </label>
                </div>
              </div>
              {/* <div>
                <div className="radio m-auto p-auto row">
                  <label className="row align-items-center">
                    <FormControl
                      type="radio"
                      name="option"
                      name={this.props.index}
                      // value
                      checked={this.checkOppNull() || option == 2}
                      onClick={() => {
                        this.handleChangeOption(2);
                        this.handleTextChange('forward_job_id', '');
                      }}
                    />
                    <span className="ml-1">Cơ hội</span>
                  </label>
                </div>
              </div> */}
              <i
                className={`fas fa-times add-btn text-red p-1 pl-0 pl-md-4 ${
                  this.props.isYesNoQues ? 'not-allowed' : 'cursor'
                }`}
                onClick={this.props.isYesNoQues ? null : () => this.remove(this.props.index)}
              ></i>
            </Col>
            <Col md={5}></Col>
            <Col md={7}>
              {(this.props.content.forward_job_id || option == 1) && (
                <div className="row mb-2 ml-2" style={{ flex: 'auto' }}>
                  <div className="col-10">
                    <FormControl
                      as="select"
                      value={this.props.content.forward_job_id}
                      onChange={(e) => this.handleTextChange('forward_job_id', parseInt(e.target.value))}
                    >
                      <option value="" defaultValue>
                        Danh sách công việc
                      </option>
                      {job?.map((item, index) => (
                        <option value={item.id} key={index}>
                          {item.name}
                        </option>
                      ))}
                    </FormControl>
                  </div>
                </div>
              )}
              {((this.props.content.product_id && this.props.content.opportunity_category_id) || option == 2) && (
                <div className="row mx-2 mb-2" style={{ flex: 'auto' }}>
                  {/* <div className="col-5 mr-2">
                  <FormControl
                    as="select"
                    value={this.props.content.product_id}
                    onChange={(e) => this.handleTextChange('product_id', parseInt(e.target.value))}
                  >
                    <option value="" defaultValue>
                      Danh sách sản phẩm
                    </option>
                    {product_list?.length &&
                      product_list?.map((item, index) => (
                        <option value={item.product_id} key={index}>
                          {item.name}
                        </option>
                      ))}
                  </FormControl>
                </div> */}
                  <div className="col-10">
                    <FormControl
                      as="select"
                      value={this.props.content.opportunity_category_id}
                      onChange={(e) => this.handleTextChange('opportunity_category_id', parseInt(e.target.value))}
                    >
                      <option value="" defaultValue>
                        Danh sách cơ hội
                      </option>
                      {opportunity_category?.length &&
                        opportunity_category?.map((value, index) => (
                          <option value={value.id} key={index}>
                            {value.name}
                          </option>
                        ))}
                    </FormControl>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  jobState: state.jobReducer,
  vpointState: state.vpointReducer,
});

const mapDispatchToProps = {
  getJobList,
  getListVpoint,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StepAdd));
