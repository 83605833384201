import React from 'react';
import { Skeleton } from 'antd';

export const LoadingSkeletonUserInfo = () => {
  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Skeleton.Avatar size={60} active />
        <div style={{ marginLeft: '10px' }}>
          <div>
            <Skeleton.Input
              style={{
                height: '20px',
                width: '200px',
              }}
              active
              size="small"
            />
          </div>
          <div>
            <Skeleton.Input
              style={{
                height: '20px',
                width: '163px',
              }}
              active
              size="small"
            />
          </div>
        </div>
      </div>
      <Skeleton.Input
        style={{
          height: '28px',
          width: '100px',
          marginTop: '10px',
        }}
        active
        size="small"
      />
    </div>
  );
};
