import { VietnameseString } from '@constants/VietnameseString';
import { Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

export default function DragAndDropConfiguration({
  title,
  listFieldConfigGetAPI,
  scaleDisplayConfig,
  handleChangeScaleDisplay,
  handleChange,
}) {
  const [columns, setColumns] = useState({});
  // console.log('scaleDisplayConfig: ', scaleDisplayConfig);
  console.log(`listFieldConfigGetAPI`, listFieldConfigGetAPI)
  useEffect(() => {
    if (listFieldConfigGetAPI.length > 0) {
      const display = [...listFieldConfigGetAPI] 
        .filter((i) => i.display_config === 1)
        .map((i) => {
          if (!i.id) {
            return { ...i, id: i.code };
          } else {
            return { ...i, id: `${i.id}` };
          }
        });

      const nonDisplay = [...listFieldConfigGetAPI]
        .filter((i) => i.display_config === 0)
        .map((i) => {
          if (!i.id) {
            return { ...i, id: i.code };
          } else {
            return { ...i, id: `${i.id}` };
          }
        });

      setColumns({
        display: {
          name: VietnameseString.display_data,
          items: display || [],
        },
        nonDisplay: {
          name: VietnameseString.existing_data,
          items: nonDisplay || [],
        },
  
      });
    }
  }, [listFieldConfigGetAPI]);

  const handleChangePosition = (col) => {
    const colDisplay = col.display.items.map((item, index) => ({ ...item, display_config: 1 }));
    const colNonDisplay = col.nonDisplay.items.map((item, index) => ({ ...item, display_config: 0 }));
    const arr = [...colDisplay, ...colNonDisplay].map((item, index) => ({
      ...item,
      position: index + 1,
    }));
    handleChange(arr);
  };
  console.log(`columns`, columns)
  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });

      handleChangePosition({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
      handleChangePosition({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
        <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                key={columnId}
              >
                {title === 'Customer' &&
                  (column.name === VietnameseString.display_data ? (
                    <div style={{ width: '100%', float: 'left', height: '22px' }}>
                      <Checkbox
                        style={{ marginLeft: '5px' }}
                        checked={scaleDisplayConfig.scaleDisplayCustomer}
                        onChange={() => {
                          handleChangeScaleDisplay('scaleDisplayCustomer');
                        }}
                      >
                        {VietnameseString.scale_display}
                      </Checkbox>
                    </div>
                  ) : (
                    <div style={{ width: '100%', height: '22px' }}></div>
                  ))}
                {title === 'Tags' &&
                  (column.name === VietnameseString.display_data ? (
                    <div style={{ width: '100%', float: 'left', height: '22px' }}>
                      <Checkbox
                        style={{ marginLeft: '5px' }}
                        checked={scaleDisplayConfig.scaleDisplayTag}
                        onChange={() => {
                          handleChangeScaleDisplay('scaleDisplayTag');
                        }}
                      >
                        {VietnameseString.scale_display}
                      </Checkbox>
                    </div>
                  ) : (
                    <div style={{ width: '100%', height: '22px' }}></div>
                  ))}

                <div style={{ margin: 8 }}>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: snapshot.isDraggingOver ? '#ffe7bd' : 'white',
                            padding: '15px 20px',
                            width: 250,
                            // minHeight: 500,
                            border: '1px dashed gray',
                            borderRadius: '10px',
                          }}
                        >
                          {column.items.map((item, index, array) => {
                            return (
                              <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        userSelect: 'none',
                                        padding: '0px 10px',
                                        margin: '0 0 8px 0',
                                        backgroundColor: snapshot.isDragging ? '#fe7a15' : 'white',
                                        color: snapshot.isDragging ? 'white' : 'black',
                                        fontWeight: snapshot.isDragging ? 500 : 400,
                                        border: '1px solid gray',
                                        borderRadius: '6px',
                                        ...provided.draggableProps.style,
                                      }}
                                    >
                                      {item.label}
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </div>
            );
          })}
        </DragDropContext>
      </div>
    </>
  );
}
