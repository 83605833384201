import {
  requestGetAllDepartment,
  requestGetDataFieldFilterCustom,
  requestGetTelemarketingInDepartment,
  requestListProduct,
} from '@constants/Api';
import { TIME_CODE_TYPE } from '@constants/Constant';
import { setSearchParamsInUrlVer2, rmVN } from '@constants/funcHelper';
import { VietnameseString } from '@constants/VietnameseString';
import { Col, DatePicker, Empty, Row, Select } from 'antd';
// import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

const { RangePicker } = DatePicker;

export default function Filter({
  param,
  timeFilterType,
  setTimeFilterType,
  handleChangeParamTelemarketingReport,
  handleChangeParamTimeTelemarketingReport,
}) {
  const [telemarketing, setTelemarketing] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [customerSource, setCustomerSource] = useState([]);
  const [products, setProducts] = useState([]);

  const getAllTelemarketing = async () => {
    try {
      const result = await requestGetTelemarketingInDepartment(
        param.department_id
      );
      const telemarketing =
        result?.data?.map(sale => ({
          ...sale,
          value: sale.id,
          label: sale.full_name,
        })) || [];
      setTelemarketing(telemarketing);
    } catch (error) {}
  };

  const getAllDepartment = async () => {
    try {
      const result = await requestGetAllDepartment();
      setDepartments(result.data);
    } catch (error) {}
  };

  const getCustomerSource = async () => {
    try {
      // const { data } = await requestGetDataFieldFilterCustom('customer_source');
      const { data } = await requestGetDataFieldFilterCustom();
      if (data && data.length) {
        const dataFilter =
          data?.find(item => item.code === 'customer_source')
            ?.customer_data_field_sources || [];

        setCustomerSource(() =>
          dataFilter.map(s => ({ label: s.value, code: s.item_code }))
        );
      }
    } catch (error) {
      console.log('Error getCustomerSource: ', { error });
    }
  };

  const getAllProduct = async () => {
    try {
      const { data } = await requestListProduct({});
      if (data && data.length) {
        setProducts(() => data.map(p => ({ id: p.id, name: p.name })));
      }
    } catch (error) {
      console.log('Error getAllProduct: ', { error });
    }
  };

  useEffect(() => {
    getAllDepartment();
    getCustomerSource();
    getAllProduct();
  }, []);

  useEffect(() => {
    getAllTelemarketing();
  }, [param.department_id]);

  const handleRenderTimeFilter = () => {
    switch (timeFilterType) {
      case TIME_CODE_TYPE.DATE:
        return (
          <DatePicker
            allowClear={false}
            style={{ width: '100%' }}
            locale={locale}
            format={'DD/MM/YYYY'}
            value={moment(param.from)}
            disabledDate={currentDate =>
              currentDate && currentDate > moment().endOf('day')
            }
            onChange={(date, dateString) => {
              handleChangeParamTimeTelemarketingReport(
                moment(date).format('YYYY/MM/DD'),
                moment(date).format('YYYY/MM/DD')
              );
            }}
          />
        );

      case TIME_CODE_TYPE.WEEK:
        return (
          <DatePicker
            allowClear={false}
            style={{ width: '100%' }}
            locale={locale}
            value={moment(param.from)}
            disabledDate={currentDate =>
              currentDate && currentDate > moment().endOf('week')
            }
            onChange={(date, dateString) => {
              handleChangeParamTimeTelemarketingReport(
                moment(date).startOf('week').format('YYYY/MM/DD'),
                moment(date).endOf('week').format('YYYY/MM/DD')
              );
            }}
            picker="week"
          />
        );

      case TIME_CODE_TYPE.MONTH:
        return (
          <RangePicker
            allowClear={false}
            style={{ width: '100%' }}
            format={'MM/YYYY'}
            locale={locale}
            value={[moment(param.from), moment(param.to)]}
            disabledDate={currentDate =>
              currentDate && currentDate > moment().endOf('month')
            }
            onChange={(date, dateString) => {
              handleChangeParamTimeTelemarketingReport(
                moment(date[0]).startOf('month').format('YYYY/MM/DD'),
                moment(date[1]).endOf('month').format('YYYY/MM/DD')
              );
            }}
            picker="month"
          />
        );

      case TIME_CODE_TYPE.QUARTER:
        return (
          <RangePicker
            allowClear={false}
            style={{ width: '100%' }}
            locale={locale}
            value={[moment(param.from), moment(param.to)]}
            disabledDate={currentDate =>
              currentDate && currentDate > moment().endOf('month')
            }
            onChange={(date, dateString) => {
              handleChangeParamTimeTelemarketingReport(
                moment(date[0]).startOf('quarter').format('YYYY/MM/DD'),
                moment(date[1]).endOf('quarter').format('YYYY/MM/DD')
              );
            }}
            picker="quarter"
          />
        );

      case TIME_CODE_TYPE.YEAR:
        return (
          <RangePicker
            allowClear={false}
            style={{ width: '100%' }}
            locale={locale}
            value={[moment(param.from), moment(param.to)]}
            disabledDate={currentDate =>
              currentDate && currentDate > moment().endOf('month')
            }
            onChange={(date, dateString) => {
              handleChangeParamTimeTelemarketingReport(
                moment(date[0]).startOf('year').format('YYYY/MM/DD'),
                moment(date[1]).endOf('year').format('YYYY/MM/DD')
              );
            }}
            picker="year"
          />
        );

      default:
        break;
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
        <Select
          style={{ width: '100%' }}
          mode="multiple"
          optionFilterProp="children"
          filterOption={(input, option) => {
            return rmVN(option.children).indexOf(rmVN(input)) >= 0;
          }}
          placeholder={VietnameseString.telemarketing}
          allowClear
          maxTagCount={1}
          value={param.telemarketing_id}
          notFoundContent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>{VietnameseString.no_data}</span>}
            />
          }
          onChange={value => {
            handleChangeParamTelemarketingReport('telemarketing_id', value);
          }}
        >
          {telemarketing.map((item, index) => (
            <Select.Option key={index} value={item.id}>
              {item.full_name}
            </Select.Option>
          ))}
        </Select>
      </Col>

      <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
        <Select
          style={{ width: '100%' }}
          mode="multiple"
          optionFilterProp="children"
          filterOption={(input, option) => {
            return rmVN(option.children).indexOf(rmVN(input)) >= 0;
          }}
          placeholder={VietnameseString.department}
          onClear={() => {
            //khi clear phòng ban thì clear sale chăm sóc và clear sale chăm sóc trên url
            handleChangeParamTelemarketingReport('telemarketing_id', []);
          }}
          allowClear
          maxTagCount={1}
          value={param.department_id}
          notFoundContent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>{VietnameseString.no_data}</span>}
            />
          }
          onChange={value => {
            handleChangeParamTelemarketingReport('department_id', value);
          }}
        >
          {departments.map((item, index) => (
            <Select.Option key={index} value={item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Col>

      <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
        <Select
          style={{ width: '100%' }}
          mode="multiple"
          optionFilterProp="children"
          placeholder={VietnameseString.customer_source}
          value={param.customer_data_field_source_code}
          filterOption={(input, option) => {
            return rmVN(option.children).indexOf(rmVN(input)) >= 0;
          }}
          allowClear
          maxTagCount={1}
          onChange={value => {
            handleChangeParamTelemarketingReport(
              'customer_data_field_source_code',
              value
            );
          }}
        >
          {customerSource.map(s => (
            <Select.Option key={s.code} value={s.code}>
              {s.label}
            </Select.Option>
          ))}
        </Select>
      </Col>

      <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
        <Select
          style={{ width: '100%' }}
          mode="multiple"
          optionFilterProp="children"
          placeholder={VietnameseString.product}
          value={param.list_products}
          filterOption={(input, option) => {
            return rmVN(option.children).indexOf(rmVN(input)) >= 0;
          }}
          allowClear
          maxTagCount={1}
          onChange={value => {
            handleChangeParamTelemarketingReport('list_products', value);
          }}
        >
          {products.map(p => (
            <Select.Option key={p.id} value={p.id}>
              {p.name}
            </Select.Option>
          ))}
        </Select>
      </Col>

      <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
        <Select
          style={{ width: '100%' }}
          value={timeFilterType}
          onChange={value => {
            setTimeFilterType(value);
            setSearchParamsInUrlVer2({ type: value, page: 1 });
          }}
        >
          <Select.Option value={TIME_CODE_TYPE.DATE}>
            {VietnameseString.date}
          </Select.Option>
          <Select.Option value={TIME_CODE_TYPE.WEEK}>
            {VietnameseString.week}
          </Select.Option>
          <Select.Option value={TIME_CODE_TYPE.MONTH}>
            {VietnameseString.month}
          </Select.Option>
          <Select.Option value={TIME_CODE_TYPE.QUARTER}>
            {VietnameseString.quarter}
          </Select.Option>
          <Select.Option value={TIME_CODE_TYPE.YEAR}>
            {VietnameseString.year}
          </Select.Option>
        </Select>
      </Col>

      <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
        {handleRenderTimeFilter()}
      </Col>
    </Row>
  );
}
