import { STATISTICS_TYPE, TIME_CODE_TYPE } from '@constants/Constant';
import { Row, Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { VietnameseString } from '@constants/VietnameseString';
import { formatPrice } from '@constants/funcHelper';

const BorderStyle = styled.div`
  width: 100%;
  min-height: 90px;
  /* border: 1px solid gray; */
  /* background-color: #efefef; */
  border-radius: 6px;
  padding: 14px 20px;
  //test
  background-color: #fcf2e9;
`;

const NameStyle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  height: 40px;
  //test
  color: #d35400;

  /* giới hạn số dòng text */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
`;

const StatisticsStyle = styled.div`
  display: flex;
  align-items: flex-end;
`;

const CurrentStatisticsStyle = styled.div`
  flex: 1;
  font-size: 14px;
  font-weight: 700;

  @media only screen and (max-width: 1600px) {
    .money {
      font-size: 14px;
    }
    .text-money {
      font-size: 10px;
    }
  }

  @media only screen and (max-width: 1200px) {
    .money {
      font-size: 14px;
    }
    .text-money {
      font-size: 10px;
    }
  }

  @media only screen and (max-width: 992px) {
    .money {
      font-size: 14px;
    }
    .text-money {
      font-size: 10px;
    }
  }
`;

const RatioStyle = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 0 0 0 5px;
  /* padding-bottom: 2px; */
  color: ${(props) => {
    if (props.ratio > 0) return 'green';
    if (props.ratio < 0) return 'red';
    return 'blue';
  }};
`;

const handleReturnStatistics = (statisticalType, statistics) => {
  if (statistics !== null) {
    switch (statisticalType) {
      case STATISTICS_TYPE.SUM:
        return statistics;

      case STATISTICS_TYPE.RATIO:
        return `${parseFloat(statistics).toFixed(2)}%`;

      case STATISTICS_TYPE.MONEY:
        return (
          <div className="money">
            {formatPrice(statistics)} <span className="text-money">đ</span>
          </div>
        );

      default:
        return '';
    }
  } else return '';
};

export default function ReportItem({
  timeFilterType,
  dataName,
  currentStatistics,
  previousStatistics,
  ratio,
  statisticalType,
}) {
  return (
    <BorderStyle>
      <Row>
        <Tooltip placement="bottomLeft" title={dataName} color={'black'} key={'black'}>
          <NameStyle>{dataName}</NameStyle>
        </Tooltip>
      </Row>

      <Row>
        <StatisticsStyle>
          <CurrentStatisticsStyle>{handleReturnStatistics(statisticalType, currentStatistics)}</CurrentStatisticsStyle>

          <Tooltip
            placement="bottom"
            title={
              <>
                <div>
                  {timeFilterType === TIME_CODE_TYPE.DATE && (
                    <div>
                      {VietnameseString.yesterday}: {handleReturnStatistics(statisticalType, previousStatistics)}
                    </div>
                  )}
                </div>
                <div>
                  {timeFilterType === TIME_CODE_TYPE.WEEK && (
                    <div>
                      {VietnameseString.last_week}: {handleReturnStatistics(statisticalType, previousStatistics)}
                    </div>
                  )}
                </div>
                <div>
                  {timeFilterType === TIME_CODE_TYPE.MONTH && (
                    <div style={{ fontSize: '10px' }}>
                      {VietnameseString.last_month}: {handleReturnStatistics(statisticalType, previousStatistics)}
                    </div>
                  )}
                </div>
                <div>
                  {timeFilterType === TIME_CODE_TYPE.QUARTER && (
                    <div>
                      {VietnameseString.last_quarter}: {handleReturnStatistics(statisticalType, previousStatistics)}
                    </div>
                  )}
                </div>
                <div>
                  {timeFilterType === TIME_CODE_TYPE.YEAR && (
                    <div>
                      {VietnameseString.last_year}: {handleReturnStatistics(statisticalType, previousStatistics)}
                    </div>
                  )}
                </div>
              </>
            }
          >
            <RatioStyle ratio={parseInt(ratio)}>
              {parseInt(ratio) < 0 && <i className="fas fa-caret-down" style={{ fontSize: '12px' }}></i>}
              {parseInt(ratio) > 0 && <i className="fas fa-caret-up" style={{ fontSize: '12px' }}></i>}
              {parseInt(ratio) === 0 && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <i className="fas fa-caret-up" style={{ height: '5px', fontSize: '12px' }}></i>
                  <i className="fas fa-caret-down" style={{ fontSize: '12px' }}></i>
                </div>
              )}
              <div style={{ marginLeft: '4px', fontSize: '8px' }}>
                {ratio !== null && `${parseFloat(ratio).toFixed(0)} %`}
              </div>
            </RatioStyle>
          </Tooltip>
        </StatisticsStyle>
      </Row>
    </BorderStyle>
  );
}
