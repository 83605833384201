import React from 'react';
import { Checkbox, Descriptions, Modal, Radio } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { DELETE_CUSTOMER_IN_GROUP } from '../../Constants';
import { requestRollBack } from '../../services/api';
import { notifySuccess } from '@utils/notify';

import { TableAntStyle } from '@styles/Style';
import { VietnameseString } from '@constants/VietnameseString';

const DescriptionsStyled = styled(Descriptions)`
  .ant-descriptions-item-container {
    display: flex;
  }

  span.ant-descriptions-item-label {
    flex: 1;
  }
`;

function RollbackModal(props) {
  const { visible, handleCancel, selectedTelemarketing, listSale } = props;

  const [payload, setPayload] = React.useState({
    list_customer_id: [],
    job_telesale_ids: selectedTelemarketing,
    delete_customer_in_group: DELETE_CUSTOMER_IN_GROUP.NONE,
  });
  const [isLoading, setIsLoading] = React.useState(false);

  async function rollback(data = payload) {
    try {
      setIsLoading(true);
      await requestRollBack(data);
      notifySuccess('Thành công!');
      handleCancel();
    } catch (error) {
      console.log('Error rollback: ', { error });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal
      title="Thu hồi dữ liệu khách hàng"
      visible={visible}
      onOk={() => {
        rollback(payload);
      }}
      onCancel={handleCancel}
      confirmLoading={isLoading}
      width="700px"
    >
      <TableAntStyle
        columns={[
          {
            title: VietnameseString.telemarketing,
            dataIndex: 'full_name',
            key: 'full_name',
          },
          {
            title: 'Số KH chưa tư vấn/KH được giao',
            dataIndex: '',
            key: '',
            render: (_, record) => {
              return (
                <div>
                  {record?.not_called}/{record?.totalSale}
                </div>
              );
            },
          },
        ]}
        dataSource={listSale.filter(s => selectedTelemarketing.includes(s.id))}
        scroll={{
          y: `200px`,
        }}
        pagination={false}
        summary={pageData => {
          const totalCustomersNeedAdvice = pageData.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue.not_called,
            0
          );

          return (
            <>
              <TableAntStyle.Summary.Row>
                <TableAntStyle.Summary.Cell index={0}>
                  <b>Tổng số KH có thể thu hồi</b>
                </TableAntStyle.Summary.Cell>
                <TableAntStyle.Summary.Cell index={1}>
                  <b>{totalCustomersNeedAdvice}</b>
                </TableAntStyle.Summary.Cell>
              </TableAntStyle.Summary.Row>
            </>
          );
        }}
      />

      <div style={{ margin: '8px 0px' }}>
        <span
          style={{ display: 'inline-block', width: '25%', fontWeight: 'bold' }}
        >
          Thu hồi
        </span>

        <Radio checked disabled>
          Tất cả
        </Radio>
      </div>

      <Checkbox
        checked={payload.delete_customer_in_group}
        onChange={e =>
          setPayload(prev => ({
            ...prev,
            delete_customer_in_group: Number(e.target.checked),
          }))
        }
      >
        Xoá khách hàng ra khỏi nhóm và giao việc
      </Checkbox>
    </Modal>
  );
}

RollbackModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  selectedTelemarketing: PropTypes.arrayOf(PropTypes.number).isRequired,
  listSale: PropTypes.array.isRequired,
};

export default RollbackModal;
