import React, { Component } from 'react';
import '@styles/review.css';
import './Information.css';
import { requestGetUserInfo, requestUpdateAccountEnterprise } from '@constants/Api';
import { notifyFail, notifySuccess } from '@utils/notify';
import Loading from '@src/components/Loading';
import { STRING } from '@constants/Constant';
import DatePickerCustom from '@components/DatePickerCustom';
import { getProvinceList } from '@src/redux/actions';

import { withRouter } from 'react-router-dom';
import { Row, Col, FormControl, Form, Modal, Button, FormGroup } from 'react-bootstrap';
import moment from 'moment';
import { connect } from 'react-redux';

class InformationScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: {
        user_id: '',
        name: '',
        gender: '',
        [STRING.dob]: '',
        province_id: '',
        province_name: '',
        address: '',
        phone: '',
        email: '',
      },
      editDetail: {
        user_id: '',
        name: '',
        gender: '',
        [STRING.dob]: '',
        province_id: '',
        province_name: '',
        address: '',
        phone: '',
        email: '',
      },
      modal: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.requestGetUserInfo();
    this.props.getProvinceList();
  }

  async requestGetUserInfo() {
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const res = await requestGetUserInfo();
      this.setState({
        ...this.state,
        detail: {
          user_id: res.data?.user_id,
          name: res.data?.full_name,
          gender: res.data?.user_info?.gender,
          [STRING.dob]: Date.parse(res.data?.user_info?.dob),
          province_id: res.data?.user_info?.province_id,
          province_name: res.data?.user_info?.province.name,
          address: res.data?.user_info?.address,
          phone: res.data?.phone,
          email: res.data?.email,
        },
        isLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async requestUpdateAccountEnterprise() {
    const { editDetail, detail } = this.state;
    const { [STRING.dob]: dob } = this.state.editDetail;
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      await requestUpdateAccountEnterprise({
        user_id: editDetail.user_id,
        full_name: editDetail.name,
        user_name: editDetail.phone,
        email: editDetail.email,
        province_id: editDetail.province_id,
        address: editDetail.address,
        dob: dob,
        gender: editDetail.gender,
      });
      this.setState({ ...this.state, modal: false, isLoading: false }, () => this.requestGetUserInfo());
      notifySuccess('Cập nhật thông tin thành công');
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  setShowModal = (field, value) => {
    if (value) {
      this.setState(
        {
          ...this.state,
          [field]: value,
        },
        () => this.getInforUser()
      );
    } else {
      this.setState({
        ...this.state,
        [field]: value,
      });
    }
  };

  editFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      editDetail: {
        ...this.state.editDetail,
        [field]: value,
      },
    });
  };

  getInforUser = () => {
    const { detail } = this.state;
    const { [STRING.dob]: dob } = this.state.detail;
    this.setState({
      ...this.state,
      editDetail: {
        user_id: detail.user_id,
        name: detail.name,
        gender: detail.gender,
        [STRING.dob]: dob,
        province_id: detail.province_id,
        province_name: detail.province_name,
        address: detail.address,
        phone: detail.phone,
        email: detail.email,
      },
    });
  };

  editModal = () => {
    const { modal, detail, editDetail } = this.state;
    const { [STRING.dob]: dob } = this.state.editDetail;
    const province = this.props.provinceState.data;
    return (
      <Modal
        show={modal}
        onHide={() => this.setShowModal('modal', false)}
        dialogClassName="modal-90w"
        centered
        scrollable
        size="lg"
      >
        <Modal.Header closeButton className="bg-primary">
          <h5 className="text-light pb-0">Sửa tài khoản</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          <FormGroup className="add-company-form">
            <Row>
              <Col md={6} className="tele-input-col">
                <label htmlFor="name">Họ tên</label>
                <FormControl
                  type="text"
                  placeholder="Nhập họ tên"
                  value={editDetail.name}
                  onChange={(e) => this.editFieldChange('name', e.target.value)}
                  disabled
                ></FormControl>
                <label htmlFor="phone">Số điện thoại</label>
                <FormControl
                  type="text"
                  placeholder="Nhập số điện thoại"
                  value={editDetail.phone}
                  disabled
                ></FormControl>
                <label htmlFor="email">Email(*)</label>
                <FormControl
                  type="text"
                  placeholder="Nhập email"
                  value={editDetail.email}
                  onChange={(e) => this.editFieldChange('email', e.target.value)}
                ></FormControl>
                <br />
                <label htmlFor="gender">Giới tính</label>
                <Form.Check
                  inline
                  type="radio"
                  label="Nam"
                  name="gender"
                  className="radio-button offset-2 tele-radio"
                  checked={editDetail.gender == 1}
                  value={1}
                  onChange={(e) => this.editFieldChange('gender', e.target.value)}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Nữ"
                  name="gender"
                  className="radio-button tele-radio"
                  checked={editDetail.gender == 0}
                  value={0}
                  onChange={(e) => this.editFieldChange('gender', e.target.value)}
                />
                <br />
              </Col>
              <Col md={6} className="tele-input-col">
                <Row>
                  <Col xs={12} className="tele-input-col">
                    <label htmlFor="email">Ngày sinh</label>
                    <DatePickerCustom
                      className={`date-picker form-control`}
                      placeholderText={STRING.dob}
                      handleChange={this.editFieldChange}
                      selected={dob}
                      dateFormat="dd/MM/yyyy"
                      maxDate={Date.now()}
                    />
                    <label htmlFor="city">Tỉnh - Thành phố</label>
                    <FormControl
                      as="select"
                      id=""
                      value={editDetail.province_id}
                      onChange={(e) => this.editFieldChange('province_id', e.target.value)}
                    >
                      <option value="" selected disabled>
                        Tỉnh/Thành phố
                      </option>
                      {province && province.length
                        ? province?.map((value, index) => <option value={value?.id}>{value?.name}</option>)
                        : ''}
                    </FormControl>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="tele-input-col">
                    <label htmlFor="address ">Địa chỉ cụ thể</label>
                    <FormControl
                      type="text"
                      name="address"
                      placeholder="Nhập địa chỉ cụ thể"
                      value={editDetail.address}
                      onChange={(e) => this.editFieldChange('address', e.target.value)}
                    ></FormControl>
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="success" className="text-light" onClick={() => this.requestUpdateAccountEnterprise()}>
            Lưu
          </Button>
          <Button variant="danger" className="text-light" onClick={() => this.setShowModal('modal', false)}>
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderTitle() {
    return <h2 className="title-tab">Thông tin cá nhân</h2>;
  }

  renderInfor() {
    const { detail } = this.state;
    const { [STRING.dob]: dob } = this.state.detail;
    return (
      <div>
        <Row className="mt-1" style={{ padding: '5px 18px', borderRadius: '8px', marginBottom: 20 }}>
          <Col md className="bg">
            <Row>
              <Col md={5}>
                <Row>
                  <Col md={6}>Họ tên:</Col>
                  <Col md={6}>
                    <b>{detail.name || '--'}</b>
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col md={6}>Giới tính:</Col>
                  <Col md={6}>
                    <b>{detail.gender == 1 ? 'Nam' : 'Nữ' || '--'}</b>
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col md={6}>Ngày sinh:</Col>
                  <Col md={6}>
                    <b>{dob ? moment(dob).format('DD-MM-YYYY') : '--'}</b>
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col md={6}>Tỉnh/thành phố:</Col>
                  <Col md={6}>
                    <b>{detail.province_name || '--'}</b>
                  </Col>
                </Row>
              </Col>
              <Col md={5}>
                <Row>
                  <Col md={6}>Địa chỉ cụ thể:</Col>
                  <Col md={6}>
                    <b>{detail.address || '--'}</b>
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col md={6}>Số điện thoại:</Col>
                  <Col md={6}>
                    <b>{detail.phone || '--'}</b>
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col md={6}>Email:</Col>
                  <Col md={6}>
                    <b>{detail.email || '--'}</b>
                  </Col>
                </Row>
              </Col>
              <Col md={2} style={{ textAlign: 'end' }}>
                <i
                  class="far fa-edit"
                  style={{ fontSize: '20px', color: 'green', cursor: 'pointer' }}
                  onClick={() => this.setShowModal('modal', true)}
                ></i>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }

  renderBody() {
    const { isLoading } = this.state;
    return (
      <div className="content-wrapper">
        {isLoading && <Loading />}
        {this.renderTitle()}
        {this.renderInfor()}
        {this.editModal()}
      </div>
    );
  }

  render() {
    return <>{this.renderBody()}</>;
  }
}

const mapStateToProps = (state) => ({
  provinceState: state.ProvinceReducer,
});

const mapDispatchToProps = {
  getProvinceList,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InformationScreen));
