import {
  GET_LIST_PACKAGE,
  GET_LIST_PACKAGE_SUCCESS,
  GET_LIST_PACKAGE_FAIL,
  // CREATE_COMPANY, CREATE_COMPANY_SUCCESS, CREATE_COMPANY_FAIL,
  // UPDATE_COMPANY, UPDATE_COMPANY_SUCCESS, UPDATE_COMPANY_FAIL,
} from '@actions/type';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    // *************  GET INFO ****************//
    case GET_LIST_PACKAGE:
      return {
        ...state,
        isLoading: true,
      };
    case GET_LIST_PACKAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.response,
        error: null,
      };
    case GET_LIST_PACKAGE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    // **************  CREATE  ****************//
    // case CREATE_COMPANY:
    //     return {
    //         ...state,
    //         isLoading: true,
    //     }
    // case CREATE_COMPANY_SUCCESS:
    //     const payload = {...action.payload, created_at:new Date(), phone:action.payload.user_name}
    //     return {
    //         ...state,
    //         isLoading: false,
    //         data: [...state.data, payload],
    //         error: null
    //     }
    // case CREATE_COMPANY_FAIL:
    //     return {
    //         ...state,
    //         isLoading: false,
    //         error: action.payload.error
    //     }

    // **************  UPDATE  ****************//
    default:
      return state;
  }
}
