import {
  CloudDownloadOutlined,
  GroupOutlined,
  PictureOutlined,
} from '@ant-design/icons';
import { formatPrice } from '@constants/funcHelper';
import { VietnameseString } from '@constants/VietnameseString';
import { primaryColor } from '@styles/Color';
import { TableAntStyle } from '@styles/Style';
import {
  Button,
  Col,
  Descriptions,
  Image,
  message,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
} from 'antd';
import domtoimage from 'dom-to-image';
import JSZip from 'jszip';
import moment from 'moment';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import React, { useState } from 'react';
import { triggerBase64Download } from 'react-base64-downloader';
import styled from 'styled-components';
import { voucherService } from '../../service';
import TemplateVoucherExport from '../templateVoucher/TemplateVoucherExport';
import VoucherDetail from './VoucherDetail';
const { Option } = Select;

const PAGE_SIZE = 12;

const WrapperFilterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 8px;

  div {
    display: flex;
    align-items: center;

    .title-filter {
      font-weight: bold;
      margin: 0 8px 0 0;
    }

    .select-filter {
      width: 300px;
    }
  }
`;

const typeDiscount = {
  money: 1,
  percent: 2,
};

// render qrcode components
const RenderQRCode = ({ _record }) => {
  const [isModalVisibleScan, setIsModalVisibleScan] = useState(false);
  const qrRef = React.useRef(null);

  const onDownloadQr = () => {
    domtoimage
      .toPng(qrRef.current, {
        bgcolor: '#fff',
        style: {
          width: '100%',
          height: '100%',
          backgroundColor: '#fff',
        },
      })
      .then(function (dataUrl) {
        triggerBase64Download(dataUrl, _record?.code);
      });
  };

  return (
    <>
      <Row
        justify="center"
        onClick={() => setIsModalVisibleScan(true)}
        align="middle"
      >
        <QRCode
          size={60}
          imageSettings="H"
          value={_record?.code}
          renderAs="canvas"
        />
      </Row>
      <Modal
        destroyOnClose
        centered
        visible={isModalVisibleScan}
        // title="Thanh toán ViettinBank"
        closable={false}
        footer={null}
        onCancel={() => {
          setIsModalVisibleScan(false);
        }}
        bodyStyle={{
          background: 'linear-gradient(to right, #2980B9, #6DD5FA, #2980B9)',
          width: 'fit-content',
          padding: '40px',
        }}
        width="fit-content"
      >
        <ContainerQRCode>
          <div>
            <Row justify="center">
              <BoxWrap>
                <div
                  ref={qrRef}
                  style={{ height: 'fit-content', width: 'fit-content' }}
                >
                  <QRCode
                    size={260}
                    imageSettings="H"
                    value={_record?.code}
                    renderAs="canvas"
                  />
                </div>
              </BoxWrap>
            </Row>
            <div className="text_total_payment">{_record?.code}</div>

            <div className="border_dot" />
            <Row justify="center">
              <Space>
                <Button
                  onClick={() => setIsModalVisibleScan(false)}
                  type="text"
                >
                  Đóng
                </Button>
                <Button onClick={onDownloadQr} type="primary">
                  Tải xuống
                </Button>
              </Space>
            </Row>
          </div>
        </ContainerQRCode>
      </Modal>
    </>
  );
};

function PromotionDetail(props) {
  const { visible, id, onCancel } = props;

  const [visibleVoucherDetail, setVisibleVoucherDetail] = useState(false);
  const [idVoucherDetail, setIdVoucherDetail] = useState(undefined);

  const [loading, setLoading] = React.useState(false);

  const [viewStatus, setViewStatus] = React.useState(null);

  const [evoucher, setEVoucher] = useState(null);
  const [vouchers, setVouchers] = useState([]);

  const [total, setTotal] = React.useState(0);
  const [page, setPage] = useState(1);

  // select row export
  const [rowSelected, setRowSelected] = useState([]);

  // ref image mẫu voucher
  const imageTemVoucherRef = React.useRef(null);

  // hướng của voucher
  const [voucherDirection, setVoucherDirection] = useState(null);

  // loading khi ghép mẫu
  const [loadingMerge, setLoadingMerge] = useState(false);

  // lưu ref element
  const [elRefs, setElRefs] = React.useState([]);

  React.useEffect(() => {
    if (!vouchers && vouchers.length === 0) return;
    // add or remove refs
    setElRefs(elRefs =>
      //@ts-ignore
      Array(vouchers?.length)
        ?.fill(undefined)
        ?.map((_, i) => elRefs[i] || React.createRef())
    );
  }, [vouchers?.length]);

  React.useEffect(() => {
    if (!id) return;
    setLoading(true);
    // get detail evoucher
    voucherService
      .detailEVoucher(id)
      .then(res => {
        setEVoucher(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  React.useEffect(() => {
    if (!id) return;

    // get list voucher by id evoucher
    voucherService
      .listVoucher({
        e_voucher_config_id: id,
        is_used: viewStatus,
        page: page,
      })
      .then(res => {
        setVouchers(res.data);
        setTotal(res?.paging?.count);
      })
      .catch(err => {
        console.log('err', err);
      });
  }, [id, viewStatus, page]);

  React.useEffect(() => {
    if (!evoucher?.e_voucher_image) return;
    if (imageTemVoucherRef.current.height > imageTemVoucherRef.current.width) {
      setVoucherDirection('vertical');
    } else {
      setVoucherDirection('horizontal');
    }
  }, [imageTemVoucherRef?.current?.height]);

  const handleDownloadAllTemplate = async () => {
    setLoading(true);
    function download(data) {
      const a = document.createElement('a');
      a.href = 'data:application/zip;base64,' + data;
      a.setAttribute('download', 'danh_sach_mau.zip');
      a.style.display = 'none';
      a.addEventListener('click', e => e.stopPropagation()); // not relevant for modern browsers
      document.body.appendChild(a);
      setTimeout(() => {
        // setTimeout - not relevant for modern browsers
        a.click();
        document.body.removeChild(a);
      }, 0);
    }

    function download_all() {
      var zip1 = new JSZip();
      [...rowSelected].forEach((index, i) =>
        zip1.file(
          'evoucher_' + i + '.png',
          images[index].replace(/data:.*?;base64,/, ''),
          {
            base64: true,
          }
        )
      );
      zip1.generateAsync({ type: 'base64' }).then(download);
    }

    download_all();
    setLoading(false);
  };

  // xử lý generate image and qr code
  const [images, setImages] = React.useState([]);

  // React.useEffect(() => {
  //   if (vouchers && vouchers.length > 0 && elRefs && elRefs.length > 0 && voucherDirection) {
  //     setTimeout(() => {
  //       ()();
  //     }, 1000);
  //   }
  // }, [vouchers, elRefs, voucherDirection]);

  const handleGenerateImage = async () => {
    setLoadingMerge(true);
    let dataImages = [];
    for (let index = 0; index < vouchers.length; index++) {
      try {
        const img = await domtoimage.toPng(elRefs[index].current, {
          bgcolor: 'white',
          cacheBust: true,
        });
        if (img) {
          dataImages.push(img);
        } else {
          dataImages.push('');
        }
        if (index === vouchers.length - 1) {
          setImages(dataImages);
          setLoadingMerge(false);
        }
      } catch (error) {
        setImages(dataImages);
        setLoadingMerge(false);
        message.error('Có lỗi xảy ra, vui lòng thử lại');
        return;
      }
    }
  };

  return (
    <>
      <Modal
        width={1200}
        footer={null}
        visible={visible}
        onCancel={onCancel}
        destroyOnClose
      >
        <Spin spinning={loading}>
          <TabsAntStyled
            type="card"

            // activeKey={infoElectronicVoucher.tab}
            // onChange={(activeKey) => {
            //   setSearchParamsInUrl('tab', activeKey);
            //   setInfoElectronicVoucher({ ...infoElectronicVoucher, tab: activeKey });
            // }}
          >
            <Tabs.TabPane tab="Thông tin chương trình" key="voucher-management">
              <div
                style={{
                  padding: '10px',
                }}
              >
                <Descriptions column={2}>
                  <Descriptions.Item label={VietnameseString.promotions_code}>
                    {evoucher?.code || '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label={VietnameseString.quantity}>
                    {evoucher?.e_voucher_total}
                  </Descriptions.Item>
                  <Descriptions.Item label={VietnameseString.promotions_name}>
                    {evoucher?.name || '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label={VietnameseString.dateCreated}>
                    {moment(evoucher?.created_at).format('DD/MM/YYYY')}
                  </Descriptions.Item>
                  <Descriptions.Item label="Thời gian áp dụng">
                    {moment(evoucher?.start_at).format('DD/MM/YYYY')} -{' '}
                    {evoucher?.finish_at
                      ? moment(evoucher?.finish_at).format('DD/MM/YYYY')
                      : '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label={VietnameseString.creater}>
                    {evoucher?.created_name || '-'}
                  </Descriptions.Item>
                </Descriptions>

                {/* <Descriptions column={2} title="Điều kiện sử dụng">
            <Descriptions.Item label="Tổng số tiền của đơn hàng">
              {formatPrice(evoucher?.condition_total_money)} VNĐ
            </Descriptions.Item>
            <Descriptions.Item label={<strong>Giá trị của voucher</strong>}></Descriptions.Item>
          </Descriptions> */}

                <Row style={{ marginBottom: '10px' }}>
                  <Col span={12}>
                    <strong>Điều kiện áp dụng</strong>
                    <ConditionsApply evoucher={evoucher} />
                  </Col>
                  <Col span={12}>
                    <strong>Giá trị của voucher</strong>

                    <ValueVoucher evoucher={evoucher} />
                  </Col>
                  <Col span={12}>
                    {/* Quà tặng cho người giới thiệu - điểm */}
                    {evoucher?.recommend_gift?.point ||
                    evoucher?.recommend_gift_voucher_name ? (
                      <Row>
                        <div className="mb ml">
                          {evoucher?.recommend_gift?.point ? (
                            <strong>
                              Quà tặng cho người giới thiệu:
                              <strong
                                style={{ padding: '0 8px', fontWeight: '500' }}
                              >
                                {formatPrice(evoucher?.recommend_gift?.point)}
                              </strong>
                              Điểm
                            </strong>
                          ) : null}

                          {evoucher?.recommend_gift_voucher_name ? (
                            <strong>
                              {evoucher?.recommend_gift?.point ? ', ' : ''}Giảm
                              giá tặng quà:
                              <strong
                                style={{ padding: '0 8px', fontWeight: '500' }}
                              >
                                {evoucher?.recommend_gift_voucher_name}
                              </strong>
                            </strong>
                          ) : null}
                        </div>
                      </Row>
                    ) : null}
                  </Col>
                </Row>
              </div>
            </Tabs.TabPane>

            <Tabs.TabPane tab="Quản lý mẫu voucher" key="gift-management">
              <div
                style={{
                  padding: '10px',
                }}
              >
                <WrapperFilterContent>
                  <div style={{ paddingBottom: '10px' }}>
                    <p className="title-filter">Danh sách mã voucher: </p>
                    <Select
                      onChange={value => setViewStatus(value)}
                      allowClear
                      className="select-filter"
                      placeholder="Trạng thái"
                    >
                      <Option value={0}>
                        {VietnameseString.not_activated}
                      </Option>
                      <Option value={1}>{VietnameseString.activated}</Option>
                    </Select>
                  </div>
                  <Space>
                    {rowSelected && rowSelected?.length > 0 && (
                      <ButtonStyled
                        type="primary"
                        icon={<PictureOutlined />}
                        onClick={handleDownloadAllTemplate}
                      >
                        Tải nhiều mẫu
                      </ButtonStyled>
                    )}

                    {vouchers &&
                      vouchers?.length > 0 &&
                      evoucher?.e_voucher_image &&
                      images?.length === 0 && (
                        <ButtonStyled
                          onClick={() => {
                            handleGenerateImage();

                            // saveAs(evoucher?.e_voucher_image, 'e_voucher_image.jpg');
                          }}
                          type="primary"
                          danger
                          icon={<GroupOutlined />}
                          loading={loadingMerge}
                        >
                          Ghép mẫu
                        </ButtonStyled>
                      )}
                    {/* <ButtonSystemStyle
                bgButtonColor={greenColor}
                bgButtonColorHover={greenColorHover}
                onClick={async () => {
                  // await voucherService.exportExcelVoucher({
                  //   e_voucher_config_id: id,
                  // });
                }}
              >
                {VietnameseString.export_excel}
              </ButtonSystemStyle> */}
                  </Space>
                </WrapperFilterContent>

                <TableAntStyle
                  rowSelection={
                    images &&
                    images.length > 0 && {
                      onChange: (selectedRowKeys, selectedRows) => {
                        setRowSelected(selectedRowKeys);
                      },
                    }
                  }
                  rowKey={(record, index) => index}
                  columns={[
                    // {
                    //   title: 'ID',
                    //   dataIndex: 'id',
                    //   key: 'id',
                    //   render: (row, record, index) => (page === 1 ? ++index : (page - 1) * 12 + ++index),
                    // },

                    {
                      title: 'Mã voucher',
                      dataIndex: 'code',
                      key: 'code',
                      render: value =>
                        value?.length > 50
                          ? value?.slice(0, 50) + '...'
                          : value,
                    },
                    {
                      title: VietnameseString.status,
                      dataIndex: 'is_used',
                      key: 'is_used',
                      render: (value, _record, _index) =>
                        value ? (
                          <span style={{ color: 'green' }}>
                            {VietnameseString.activated}
                          </span>
                        ) : (
                          <span style={{ color: 'red' }}>
                            {VietnameseString.not_activated}
                          </span>
                        ),
                    },
                    {
                      title: 'Mã QR',
                      dataIndex: 'qr_link',
                      key: 'qr_link',
                      render: (value, _record, _index) => (
                        <RenderQRCode _record={_record} />
                      ),
                    },
                    {
                      title: 'Mẫu voucher',
                      dataIndex: 'e_voucher_image',
                      key: 'e_voucher_image',
                      render: (value, _record, _index) =>
                        evoucher?.e_voucher_image ? (
                          <Image
                            src={images?.[_index] || evoucher?.e_voucher_image}
                            style={{ objectFit: 'cover' }}
                            height={60}
                            alt="voucher"
                          />
                        ) : (
                          <i>Chưa có mẫu voucher!</i>
                        ),
                    },
                    images && images.length > 0
                      ? {
                          title: 'Tải mẫu',
                          dataIndex: 'action',
                          key: 'action',
                          render: (value, _record, _index) =>
                            evoucher?.e_voucher_image && (
                              <Button
                                icon={<CloudDownloadOutlined />}
                                onClick={async () => {
                                  if (!images?.[_index]) return;
                                  triggerBase64Download(
                                    images?.[_index],
                                    'e_voucher_example_' + _record?.code
                                  );
                                }}
                              />
                            ),
                        }
                      : {
                          title: '',
                          width: 0,
                          className: 'hidden',
                        },
                  ]}
                  dataSource={vouchers}
                  loading={loadingMerge}
                  onRow={(record, _rowIndex) => {
                    return {
                      onClick: _event => {
                        if (record.is_used) {
                          setVisibleVoucherDetail(true);
                          setIdVoucherDetail(record.id);
                        }
                      },
                    };
                  }}
                  pagination={
                    total > PAGE_SIZE
                      ? {
                          total,
                          pageSize: PAGE_SIZE,
                          showSizeChanger: false,
                          onChange: page => {
                            setPage(page);
                            setImages([]);
                          },
                        }
                      : null
                  }
                />

                {/* Xử lý tạo ảnh voucher theo qrcode */}

                {/* lấy chiều dài rộng mẫu voucher */}
                {evoucher?.e_voucher_image && (
                  <img
                    style={{ display: 'none' }}
                    alt="e_voucher_image"
                    ref={imageTemVoucherRef}
                    src={evoucher?.e_voucher_image}
                  />
                )}
                <div style={{ height: '0', overflow: 'hidden' }}>
                  {vouchers && vouchers?.length > 0
                    ? vouchers?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              height: 'fit-content',
                              width: 'fit-content',
                            }}
                            ref={elRefs[index]}
                          >
                            <TemplateVoucherExport
                              hori={voucherDirection === 'horizontal'}
                              imgUrl={evoucher?.e_voucher_image}
                              valueQR={item?.code}
                            />
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            </Tabs.TabPane>
          </TabsAntStyled>
        </Spin>
      </Modal>

      {visibleVoucherDetail && idVoucherDetail && (
        <VoucherDetail
          visible={visibleVoucherDetail}
          id={idVoucherDetail}
          onCancel={() => {
            setVisibleVoucherDetail(false);
            setIdVoucherDetail(undefined);
          }}
        />
      )}
    </>
  );
}

const ConditionsApply = React.memo(({ evoucher }) => {
  return (
    <ContainerValueVoucher>
      {/* Tổng số tiền của đơn hàng */}
      {evoucher?.condition_total_money && (
        <div className="mb ml">
          - Tổng số tiền của đơn hàng:
          <strong style={{ padding: '0 8px' }}>{`${formatPrice(
            evoucher?.condition_total_money
          )}`}</strong>
          VNĐ
        </div>
      )}

      {/* sản phẩm */}
      {evoucher?.condition_product &&
        evoucher?.condition_product?.length > 0 && (
          <div className="mb ml">
            <strong>Sản phẩm:</strong>
            {evoucher?.condition_product?.map(item => {
              return (
                <Row key={item?.name} className="ml mb">
                  <Col span={8}>{item?.name}</Col>
                  <Col span={8}>
                    Số lượng: <strong>{item?.amount || 0}</strong>
                  </Col>
                  <Col span={8}>
                    Thành tiền:{' '}
                    <strong>
                      {item?.money ? formatPrice(item?.money) : 0}
                    </strong>
                  </Col>
                </Row>
              );
            })}
          </div>
        )}

      {/* thời gian mua hàng */}
      {evoucher?.condition_time && evoucher?.condition_time?.length > 0 && (
        <div className="mb ml">
          <strong>Thời gian mua hàng:</strong>
          <Row>
            <div className="mb ml">
              -{' '}
              {evoucher?.condition_time?.[0]?.start_at?.includes('-')
                ? 'Ngày: '
                : 'Giờ: '}
            </div>
            <div className="mb ml">
              {evoucher?.condition_time?.map(item => {
                return (
                  <div key={item?.start_at} className="ml mb">
                    {item?.start_at?.includes('-')
                      ? moment(item?.start_at).format('HH:mm DD/MM/YYYY')
                      : item?.start_at || '-'}{' '}
                    <strong>-</strong>{' '}
                    {item?.finish_at?.includes('-')
                      ? moment(item?.finish_at).format('HH:mm DD/MM/YYYY')
                      : item?.finish_at || '-'}
                  </div>
                );
              })}
            </div>
          </Row>
        </div>
      )}

      {/* Điều kiện khác */}
      {evoucher?.condition_other && (
        <div className="mb ml">
          - Điều kiện khác:
          <strong style={{ padding: '0 8px' }}>
            {evoucher?.condition_other}
          </strong>
        </div>
      )}
    </ContainerValueVoucher>
  );
});

const ValueVoucher = React.memo(({ evoucher }) => {
  return (
    <ContainerValueVoucher>
      {/* Giảm giá theo tổng tiền */}
      {evoucher?.discount_money ? (
        <div className="mb ml">
          - Giảm giá
          <strong style={{ padding: '0 8px' }}>
            {evoucher?.type_discount_money == typeDiscount.money
              ? `${formatPrice(evoucher?.discount_money)} VNĐ`
              : `${evoucher?.discount_money}%`}
          </strong>
          theo tổng tiền
        </div>
      ) : null}

      {/* Giảm giá theo sản phẩm */}
      {evoucher?.discount_product ? (
        <div className="mb ml">
          - Giảm giá
          <strong style={{ padding: '0 8px' }}>
            {evoucher?.type_discount_product == typeDiscount.money
              ? `${formatPrice(evoucher?.discount_product)} VNĐ`
              : `${evoucher?.discount_product}%`}
          </strong>
          theo sản phẩm
        </div>
      ) : null}
      {/* Tích điểm theo giá trị đơn hàng */}
      {evoucher?.save_point ? (
        <div className="mb ml">
          - Tích điểm:
          <strong style={{ padding: '0 8px' }}>
            {evoucher?.type_save_point == typeDiscount.money
              ? `${formatPrice(evoucher?.save_point)} Điểm`
              : `${evoucher?.save_point}%`}
          </strong>
          {evoucher?.type_save_point == typeDiscount.percent &&
            'theo giá trị đơn hàng'}
        </div>
      ) : null}

      {/* đổi quà */}
      {evoucher?.e_voucher_converted_gifts &&
        evoucher?.e_voucher_converted_gifts?.length > 0 && (
          <div className="mb ml">
            - Đổi quà:
            <strong style={{ padding: '0 8px' }}>
              {evoucher?.e_voucher_converted_gifts?.map((item, index) => {
                return index !== evoucher?.e_voucher_converted_gifts?.length - 1
                  ? item?.product_name + ', '
                  : item?.product_name;
              })}
            </strong>
          </div>
        )}

      {/* tặng quà */}
      {evoucher?.e_voucher_customer_gifts &&
        evoucher?.e_voucher_customer_gifts.length > 0 && (
          <div className="mb ml">
            - Tặng quà:
            <strong style={{ padding: '0 8px' }}>
              {evoucher?.e_voucher_customer_gifts?.map((item, index) => {
                return index !== evoucher?.e_voucher_customer_gifts?.length - 1
                  ? item?.e_voucher_gift_name + ', '
                  : item?.e_voucher_gift_name;
              })}
            </strong>
          </div>
        )}

      {/* Quy đổi điểm */}
      {evoucher?.convert_point &&
      (evoucher?.recommend_gift?.point || evoucher?.save_point) ? (
        <div className="mb ml">
          - Quy đổi điểm:
          <strong style={{ padding: '0 8px' }}>{`${
            evoucher?.convert_point?.point || 0
          } Điểm = ${evoucher?.convert_point?.money || 0} VNĐ`}</strong>
        </div>
      ) : null}
    </ContainerValueVoucher>
  );
});

const ContainerValueVoucher = styled.div`
  margin-top: 10px;
  & .mb {
    margin-bottom: 10px;
  }
  & .ml {
    margin-left: 15px;
  }
`;

const ButtonStyled = styled(Button)`
  border-radius: 6px;
  display: flex;
  align-items: center;
`;

export const TabsAntStyled = styled(Tabs)`
  width: 100%;
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${primaryColor};
  }

  .ant-tabs-tab {
    &:hover {
      color: ${primaryColor};
    }
  }
`;

const ContainerQRCode = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 20px 60px;

  & .title_bank {
    /* width: 200px; */
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 15px;
  }

  & .text_total_payment {
    text-align: center;
    font-size: 15px;
    padding: 10px 0 4px 0;
  }

  & .total_payment {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
  }

  & .border_dot {
    border-bottom: 1px dashed;
    margin: 10px 0;
  }
`;

const BoxWrap = styled.div`
  width: fit-content;

  background: linear-gradient(to right, black 4px, transparent 4px) 0 0,
    linear-gradient(to right, black 4px, transparent 4px) 0 100%,
    linear-gradient(to left, black 4px, transparent 4px) 100% 0,
    linear-gradient(to left, black 4px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, black 4px, transparent 4px) 0 0,
    linear-gradient(to bottom, black 4px, transparent 4px) 100% 0,
    linear-gradient(to top, black 4px, transparent 4px) 0 100%,
    linear-gradient(to top, black 4px, transparent 4px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 20px 20px;
  padding: 20px;
`;

PromotionDetail.propTypes = {
  visible: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default React.memo(PromotionDetail);
