import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Checkbox, DatePicker, Dropdown, Input, Menu, message, Modal, Select } from 'antd';
import { faSun, faTrashAlt } from '@fortawesome/fontawesome-free-regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';

import { requestGetCustomerDataType, requestGetOptionsWithCustomerDataType } from '../../services/api';
import ChooseCustomerDataTypeModal from './ChooseCustomerDataTypeModal';
import { VietnameseString } from '@constants/VietnameseString';
import { formatPrice } from '@constants/funcHelper';

import { ButtonSystemStyle, TableBorderedAntStyle } from '@styles/Style';
import '@styles/Dropdown.css';

const { Option } = Select;

const ModalStyled = styled(Modal)`
  &&& {
    .ant-modal-body {
      padding: 16px;
    }
  }
`;

const HeaderStyled = styled.div`
  margin-bottom: 8px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  margin-left: 12px;

  font-size: 24px;
  cursor: pointer;

  &&& {
    + .svg-inline--fa {
      margin-left: 10px;
    }
  }
`;

const CheckboxStyled = styled(Checkbox)`
  &&& {
    margin-left: 0px;
  }
`;

const FooterModalStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
`;

const ComparativeAttributeStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    margin: 2px;
  }
`;

const IconStyle = styled.div`
  background: yellow;
  border: 1px solid gray;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);

  i {
    transform: rotate(-45deg);
    padding-left: 4px;
  }
`;

const COMPARISON_CODE = {
  EQUAL: '=',
  GT: '>',
  GTE: '>=',
  LT: '<',
  LTE: '<=',
};

const COMPARISON = [
  { code: COMPARISON_CODE.EQUAL, label: '=' },
  { code: COMPARISON_CODE.GT, label: '>' },
  { code: COMPARISON_CODE.GTE, label: '>=' },
  { code: COMPARISON_CODE.LT, label: '<' },
  { code: COMPARISON_CODE.LTE, label: '<=' },
];

const DATA_TYPE = {
  DATE_TIME: 'date_time',
  NUMBER: 'number',
  TEXT: 'text',
  OPTIONS: 'options',
};

const regExpWithNumber = /[^0-9]/g;

const returnComparisonWithDataType = (dataType, comparison) => {
  let result = [];
  if (dataType === 'options' || dataType === 'text') {
    result = comparison.filter((el) => el.code === '=');
  } else {
    result = comparison;
  }
  return result;
};

const validateFormCondition = (data) => {
  //nếu chỉ có code và tất cả null => true
  //nếu có code và có condition thì bắt buộc nhập attribute_value hoặc attribute_code
  //nếu có code và attribute_value hoặc attribute_code thì bắt buộc nhập condition
  //nếu có code và calculation thì bắt buộc nhập condition và attribute_value hoặc attribute_code và calculation_value
  //nếu có code và calculation_value thì bắt buộc nhập condition và attribute_value hoặc attribute_code và calculation

  let check = true;
  let index = 0;
  for (let i = 0; i < data.length; i++) {
    if (data[i].condition === COMPARISON_CODE.EQUAL) {
      // nếu tồn tại calculation hoặc calculation_value
      if (data[i].calculation || data[i].calculation_value) {
        if (
          (!data[i].attribute_code && !data[i].attribute_value) ||
          !data[i].calculation ||
          !data[i].calculation_value
        ) {
          check = false;
          index = i;
          break;
        }
      }
    } else {
      // nếu tồn tại condition và không tồn tại (attribute_code && attribute_value)
      if (data[i].condition && !data[i].attribute_code && !data[i].attribute_value) {
        check = false;
        index = i;
        break;
      }

      // nếu tồn tại condition và tồn tại (attribute_code || attribute_value)
      if (data[i].condition && (data[i].attribute_code || data[i].attribute_value)) {
        if (data[i].calculation && !data[i].calculation_value) {
          check = false;
          index = i;
          break;
        }

        if (!data[i].calculation && data[i].calculation_value) {
          check = false;
          index = i;
          break;
        }
      }

      // nếu tồn tại calculation hoặc calculation_value
      if (data[i].calculation || data[i].calculation_value) {
        if (
          !data[i].condition ||
          (!data[i].attribute_code && !data[i].attribute_value) ||
          !data[i].calculation ||
          !data[i].calculation_value
        ) {
          check = false;
          index = i;
          break;
        }
      }

      //nếu tồn tại (attribute_code || attribute_value) mà không có condition
      if ((data[i].attribute_code || data[i].attribute_value) && !data[i].condition) {
        check = false;
        index = i;
        break;
      }
    }
  }

  return [check, index];
};

const formatNumberWithDataType = (data_type, attribute_value) => {
  let result = attribute_value;
  if (data_type === DATA_TYPE.NUMBER) {
    result = formatPrice(attribute_value);
  }
  return result;
};

const ComparativeAttribute = (props) => {
  const { index, record, customerDataType, handleChangeCondition } = props;
  const { data_type, attribute_value, attribute_code, code, calculation_value } = record;

  const [attributeCode, setAttributeCode] = useState('');
  const [attributeValue, setAttributeValue] = useState('');

  const [optionsWithCustomerDataType, setOptionsWithCustomerDataType] = useState([]);

  const getOptionsWithCustomerDataType = useCallback(async ({ customerDataType, code }) => {
    try {
      const result = await requestGetOptionsWithCustomerDataType({ code: code });

      let data = result.data.map((el) => ({
        code: el.value,
        label: el.name,
      }));

      setOptionsWithCustomerDataType(data);
    } catch (error) {
      console.log('Error getOptionsWithCustomerDataType: ', { error });
    }
  }, []);

  useEffect(() => {
    try {
      if (attributeCode) {
        let value = '';
        if (data_type === DATA_TYPE.OPTIONS) {
          value = optionsWithCustomerDataType.find((el) => el.code == attributeCode)?.label;
        } else {
          value = customerDataType.find((type) => type.code === attributeCode)?.label;
        }

        setAttributeValue(`[${value}]`);
      }
    } catch (error) {
      console.log('Exception effect in ComparativeAttribute: ', { error });
    }
  }, [attributeCode, customerDataType, optionsWithCustomerDataType]);

  useEffect(() => {
    if (data_type === DATA_TYPE.OPTIONS) {
      getOptionsWithCustomerDataType({ customerDataType, code });
    }
    if (attribute_code) setAttributeCode(attribute_code);
  }, []);

  const getPlaceholder = () => {
    let placeholderText = 'Nhập giá trị so sánh...';

    if (attributeValue) {
      placeholderText = attributeValue;
    }

    return placeholderText;
  };

  return (
    <ComparativeAttributeStyled>
      {data_type === DATA_TYPE.DATE_TIME || data_type === DATA_TYPE.NUMBER ? (
        <Dropdown
          trigger={['click']}
          placement="bottomLeft"
          overlay={() => {
            return (
              <Menu
                items={customerDataType
                  .filter((type) => type.data_type === data_type && type.code !== code)
                  .map((el, idx) => {
                    return {
                      key: idx,
                      label: `[ ${el.label} ]`,
                      data: el,
                    };
                  })}
                onClick={({ item, key, keyPath, domEvent }) => {
                  setAttributeValue('');
                  setAttributeCode(item.props.data.code);
                  handleChangeCondition(index, 'attribute_value', null);
                  handleChangeCondition(index, 'attribute_code', item.props.data.code);
                }}
              />
            );
          }}
        >
          <IconStyle>
            <i class="far fa-ellipsis-h-alt"></i>
          </IconStyle>
        </Dropdown>
      ) : data_type === DATA_TYPE.OPTIONS ? (
        <Dropdown
          trigger={['click']}
          placement="bottomLeft"
          overlay={() => {
            return (
              <Menu
                items={optionsWithCustomerDataType.map((el, idx) => {
                  return {
                    key: idx,
                    label: `[ ${el.label} ]`,
                    data: el,
                  };
                })}
                onClick={({ item, key, keyPath, domEvent }) => {
                  setAttributeValue('');
                  if (item.props.data.code) {
                    setAttributeCode(item.props.data.code);
                    handleChangeCondition(index, 'attribute_value', null);
                    handleChangeCondition(index, 'attribute_code', item.props.data.code);
                  } else {
                    setAttributeCode(item.props.data.code);
                    handleChangeCondition(index, 'attribute_code', null);
                    handleChangeCondition(index, 'attribute_value', item.props.data.label);
                  }
                }}
              />
            );
          }}
        >
          <IconStyle>
            <i class="far fa-ellipsis-h-alt"></i>
          </IconStyle>
        </Dropdown>
      ) : (
        <Fragment />
      )}

      <div style={{ flex: 1 }}>
        {data_type === DATA_TYPE.DATE_TIME ? (
          <DatePicker
            style={{ width: '100%' }}
            value={attribute_value && moment(attribute_value)}
            placeholder={getPlaceholder()}
            onChange={(date, dateString) => {
              setAttributeValue('');
              setAttributeCode('');
              handleChangeCondition(index, 'attribute_code', null);
              handleChangeCondition(index, 'attribute_value', dateString);
            }}
          />
        ) : (
          <Input
            disabled={data_type === DATA_TYPE.OPTIONS}
            value={attribute_value ? formatNumberWithDataType(data_type, attribute_value) : attributeValue}
            placeholder={getPlaceholder()}
            allowClear
            onChange={(event) => {
              setAttributeValue('');
              setAttributeCode('');
              handleChangeCondition(index, 'attribute_code', null);
              if (data_type === DATA_TYPE.NUMBER) {
                handleChangeCondition(index, 'attribute_value', event.target.value.replaceAll(regExpWithNumber, ''));
              } else {
                handleChangeCondition(index, 'attribute_value', event.target.value);
              }
            }}
          />
        )}
      </div>

      {data_type === DATA_TYPE.DATE_TIME || data_type === DATA_TYPE.NUMBER ? (
        <div>
          <Select
            style={{ width: '60px' }}
            value={record.calculation}
            allowClear
            onClear={() => {
              handleChangeCondition(index, 'calculation', null);
            }}
            onChange={(value) => {
              handleChangeCondition(index, 'calculation', value);
            }}
          >
            <Option value="+">+</Option>
            <Option value="-">-</Option>
          </Select>
        </div>
      ) : (
        <Fragment />
      )}

      {data_type === DATA_TYPE.DATE_TIME || data_type === DATA_TYPE.NUMBER ? (
        <div>
          <Input
            height="32px"
            width="150px"
            value={calculation_value && formatPrice(calculation_value)}
            onChange={(event) => {
              handleChangeCondition(index, 'calculation_value', event.target.value.replaceAll(regExpWithNumber, ''));
            }}
          />
        </div>
      ) : (
        <Fragment />
      )}
    </ComparativeAttributeStyled>
  );
};

function ConditionModal(props) {
  const { visible, onCancelModal, conditionSelected, onAddCondition, selectedConfiguration, onChangeConfiguration } =
    props;

  const [visibleConfigurationModal, setVisibleConfigurationModal] = useState(false);
  const [visibleChooseCustomerDataTypeModal, setVisibleChooseCustomerDataTypeModal] = useState(false);

  const [configuration, setConfiguration] = useState({
    isDeleteWhenIneligible: false,
    isValidForNewCustomersOnly: false,
  });

  const [customerDataType, setCustomerDataType] = useState([]);

  /**
   * @example condition  [{
            "label": "Ngày sinh", //thuộc tính dùng để hiển thị
            "data_type": "date_time",
            "code": "dob",
            "condition": ">",
            "attribute_code": "dob",
            "attribute_value": null,
            "calculation": "+",
            "calculation_value": 2,
            "required": 0
        }, ...]
   */
  const [condition, setCondition] = useState([]);

  const handleChangeCondition = (conditionIndex, propertyConditionChange, valueConditionChange) => {
    let intermediateVariable = condition;
    intermediateVariable[conditionIndex][propertyConditionChange] = valueConditionChange;

    setCondition([...intermediateVariable]);
  };

  const columns = [
    {
      title: VietnameseString.attribute,
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Điều kiện',
      dataIndex: '',
      key: 'condition',
      render: (value, record, index) => {
        return (
          <Select
            value={record.condition}
            style={{ width: '100%' }}
            allowClear
            onClear={() => {
              handleChangeCondition(index, 'condition', null);
            }}
            onChange={(value) => {
              handleChangeCondition(index, 'condition', value);
            }}
          >
            {returnComparisonWithDataType(record.data_type, COMPARISON).map((el, index) => (
              <Option key={index} value={el.code}>
                {el.label}
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: VietnameseString.comparative_attribute,
      dataIndex: '',
      key: 'attribute_code',
      render: (value, record, index) => {
        return (
          <ComparativeAttribute
            key={record.code}
            index={index}
            record={record}
            customerDataType={customerDataType}
            handleChangeCondition={handleChangeCondition}
          />
        );
      },
    },
    {
      title: VietnameseString.required,
      dataIndex: '',
      key: 'required',
      render: (value, record, index) => {
        return (
          <Checkbox
            checked={record.required}
            onChange={(event) => {
              handleChangeCondition(index, 'required', Number(event.target.checked));
            }}
          />
        );
      },
    },
    {
      title: VietnameseString.manipulation,
      dataIndex: '',
      key: 'action',
      render: (value, record, index) => {
        return (
          <div>
            {/* <FontAwesomeIconStyled
              icon={faEdit}
              size="lg"
              color="green"
              onClick={(event) => {}}
            /> */}
            <FontAwesomeIconStyled
              icon={faTrashAlt}
              size="lg"
              color="red"
              onClick={(event) => {
                let intermediateVariable = [...condition];
                intermediateVariable.splice(index, 1);
                setCondition(intermediateVariable);
              }}
            />
          </div>
        );
      },
    },
  ];

  const handleAddCondition = () => {
    const [check, index] = validateFormCondition(condition);
    if (check) {
      onAddCondition(condition);
      onCancelModal();
    } else {
      message.warning(`Vui lòng nhập đủ điều kiện cho thuộc tính ${condition[index].label}`);
    }
  };

  const getCustomerDataType = async () => {
    try {
      const { data } = await requestGetCustomerDataType({ type: '' });
      setCustomerDataType(
        data.map((dataType) => ({
          code: dataType.code,
          label: dataType.label,
          data_type: dataType.data_type,
          data_type_df: dataType.data_type_df,
        }))
      );
    } catch (error) {
      console.log('Error getCustomerDataType: ', { error });
    }
  };

  useEffect(() => {
    getCustomerDataType();

    setConfiguration(selectedConfiguration);
  }, []);

  useEffect(() => {
    if (conditionSelected.length && customerDataType.length) {
      conditionSelected.forEach((element, index) => {
        if (!element.label) {
          const label = customerDataType.find((type) => type.code === element.code)?.label;

          conditionSelected[index].label = label;
        }
      });

      setCondition(conditionSelected);
    }
  }, [conditionSelected, customerDataType]);

  return (
    <Fragment>
      <Modal
        width="75%"
        title={VietnameseString.list_of_conditions}
        visible={visible}
        maskClosable={false}
        footer={null}
        onCancel={onCancelModal}
      >
        <HeaderStyled>
          <ButtonSystemStyle onClick={() => setVisibleChooseCustomerDataTypeModal(true)}>
            {VietnameseString.create_condition}
          </ButtonSystemStyle>

          <FontAwesomeIconStyled icon={faSun} onClick={() => setVisibleConfigurationModal(true)} />
        </HeaderStyled>

        <div>{VietnameseString.list_of_conditions}</div>

        <TableBorderedAntStyle dataSource={condition} columns={columns} pagination={false} />

        <FooterModalStyled>
          <ButtonSystemStyle onClick={handleAddCondition}>{VietnameseString.save}</ButtonSystemStyle>
        </FooterModalStyled>
      </Modal>

      {visibleConfigurationModal && (
        <ModalStyled
          width={320}
          title={VietnameseString.configuration}
          visible={visibleConfigurationModal}
          maskClosable={false}
          footer={null}
          onCancel={() => {
            setVisibleConfigurationModal(false);
          }}
        >
          <div>
            <CheckboxStyled
              checked={configuration.isDeleteWhenIneligible}
              onChange={(event) => {
                setConfiguration((prev) => ({
                  ...prev,
                  isDeleteWhenIneligible: event.target.checked,
                }));
              }}
            >
              {VietnameseString.automatically_delete_customers_when_ineligible}
            </CheckboxStyled>

            <CheckboxStyled
              checked={configuration.isValidForNewCustomersOnly}
              onChange={(event) => {
                setConfiguration((prev) => ({
                  ...prev,
                  isValidForNewCustomersOnly: event.target.checked,
                }));
              }}
            >
              {VietnameseString.valid_for_new_customers_only}
            </CheckboxStyled>
          </div>

          <FooterModalStyled>
            <ButtonSystemStyle
              onClick={() => {
                onChangeConfiguration(configuration);
                setVisibleConfigurationModal(false);
              }}
            >
              {VietnameseString.save}
            </ButtonSystemStyle>
          </FooterModalStyled>
        </ModalStyled>
      )}

      {visibleChooseCustomerDataTypeModal && (
        <ChooseCustomerDataTypeModal
          visible={visibleChooseCustomerDataTypeModal}
          onCancelModal={() => setVisibleChooseCustomerDataTypeModal(false)}
          onAddCustomerDataType={(data) => {
            const dataTypeSelected = data.map((type) => ({
              label: type.label,
              data_type: type.data_type,
              code: type.code,
              condition: null,
              attribute_code: null,
              attribute_value: null,
              calculation: null,
              calculation_value: null,
              required: 0,
            }));

            setCondition((prev) => [...prev, ...dataTypeSelected]);
          }}
        />
      )}
    </Fragment>
  );
}

ConditionModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancelModal: PropTypes.func.isRequired,
  conditionSelected: PropTypes.array,
  onAddCondition: PropTypes.func.isRequired,
  selectedConfiguration: PropTypes.shape({
    isDeleteWhenIneligible: PropTypes.bool,
    isValidForNewCustomersOnly: PropTypes.bool,
  }),
  onChangeConfiguration: PropTypes.func,
};

export default ConditionModal;
