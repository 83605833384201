import React from 'react';
import { Button, Col, Modal, Row, FormControl } from 'react-bootstrap';

function ModalSubPhone(props) {
  const { visible, onClose, onSave, data, onChangeData } = props;

  return (
    <Modal
      show={visible}
      onHide={() => onClose()}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      centered
    >
      <Modal.Header closeButton className="bg-primary">
        <h5 className="text-light pb-0">{!data.id ? 'Thêm số phụ' : 'Cập nhật số phụ'}</h5>
      </Modal.Header>
      <Modal.Body className="custom-body">
        <Row className="mb-3">
          <Col md={12} className="mb-3 p-0">
            <label>Số điện thoại</label>
            <FormControl
              type="text"
              aria-describedby="basic-addon1"
              value={data.phone}
              placeholder="Số điện thoại"
              onChange={(e) => onChangeData('phone', e.target.value)}
            ></FormControl>
          </Col>

          <Col md={12} className="mb-3 p-0">
            <label>Ghi chú</label>
            <FormControl
              as="textarea"
              aria-describedby="basic-addon1"
              value={data.note}
              placeholder="Ghi chú"
              onChange={(e) => onChangeData('note', e.target.value)}
            ></FormControl>
          </Col>
        </Row>

        <Row>
          <Col className="button-wrapper" style={{ textAlign: 'center' }}>
            <Button
              variant="danger"
              style={{ background: 'red' }}
              onClick={() => {
                onClose();
              }}
              className="text-light"
            >
              Hủy
            </Button>
            <Button
              variant="success"
              style={{ background: 'green' }}
              onClick={() => {
                onSave();
              }}
              className="text-light"
            >
              Lưu
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default ModalSubPhone;
