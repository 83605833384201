import {
  // web admin
  watchGetUser,
  watchGetAccountList,
  watchGetTelesale,
  watchGetTelesaleInactive,
  watchGetProvinceList,
  watchGetRegisterList,
  watchGetRejectedRegisterList,
  watchGetCompanyList,
  WatchGetListTutorial,
  WatchGetListPackage,
  WatchGetListHotLine,
  WatchGetPackageHistory,

  // web enterprise
  watchGetCategoryList,
  watchCustomerList,
  watchGetCustomerGroupList,
  watchGetHotlineList,
  watchGetReviewList,
  watchGetJobList,
  watchGetJobDetail,
  watchGetCategoryFilter,
  watchCustomerJobList,
  watchCustomerGroupJobList,
  watchCustomerGroupStatus,
  watchCallHistory,
  watchTypeJob,
  WatchGetListCallStatus,
  WatchGetReviewerList,
  WatchGetReviewerTask,
  WatchGetGeneralQuestion,
  WatchGetKeyword,
  WatchGetVpoint,
  WatchGetListCall,
  WatchGetListOccasion,
  WatchGetResultReviewer,
  WatchGetJobCategoriesFilter,
  WatchGetJobFilter,
  WatchGetTelesalesFilter,
  WatchGetEnterprisesFilter,
  WatchGetListForSale,
  WatchGetQuessionsAndAnswerFilter,
  WatchGetTelesaleKpi,
  WatchGetUserStatistic,
  WatchGetCallStatistic,
  WatchGetJobStatistic,
  WatchGetJobSaleStatistic,
  WatchGetListHotLineNew,
} from './NetworkSaga';

export default function* rootSaga() {
  // web admin
  yield watchGetUser;
  yield watchGetAccountList;
  yield watchGetTelesale;
  yield watchGetTelesaleInactive;
  yield watchGetProvinceList;
  yield watchGetRegisterList;
  yield watchGetRejectedRegisterList;
  yield watchGetCompanyList;
  yield WatchGetListTutorial;
  yield WatchGetListPackage;
  yield WatchGetListHotLine;
  yield WatchGetPackageHistory;

  // web enterprise
  yield watchGetCategoryList;
  yield watchCustomerList;
  yield watchGetCustomerGroupList;
  yield watchGetHotlineList;
  yield watchGetReviewList;
  yield watchGetJobList;
  yield watchGetJobDetail;
  yield watchGetCategoryFilter;
  yield watchCustomerJobList;
  yield watchCustomerGroupJobList;
  yield watchCustomerGroupStatus;
  yield watchCallHistory;
  yield watchTypeJob;
  yield WatchGetListCallStatus;
  yield WatchGetReviewerList;
  yield WatchGetReviewerTask;
  yield WatchGetGeneralQuestion;
  yield WatchGetKeyword;
  yield WatchGetVpoint;
  yield WatchGetListCall;
  yield WatchGetListOccasion;
  yield WatchGetResultReviewer;
  yield WatchGetJobCategoriesFilter;
  yield WatchGetJobFilter;
  yield WatchGetEnterprisesFilter;
  yield WatchGetTelesalesFilter;
  yield WatchGetListForSale;
  yield WatchGetQuessionsAndAnswerFilter;
  yield WatchGetTelesaleKpi;
  yield WatchGetUserStatistic;
  yield WatchGetCallStatistic;
  yield WatchGetJobStatistic;
  yield WatchGetJobSaleStatistic;

  yield WatchGetListHotLineNew;
}
