import React, { Component } from 'react';
import { createFormData } from '@utils/createFormData';
import { withRouter } from 'react-router-dom';
import '@styles/Company.css';
import { Row, Col, FormControl, Button, FormGroup } from 'react-bootstrap';
import { ACCEPT_TYPE } from '@constants/Constant';
import { requesCreateCompany } from '@constants/Api';
import { PHONE_REGEX, EMAIL_REGEX, PASS_REGEX } from '@constants/Constant';
import { ToastContainer } from 'react-toastify';
import Loading from '@src/components/Loading';
import { notifyFail, notifySuccess } from '@utils/notify';

function FormError(props) {
  if (props.isHidden) {
    return null;
  }
  return (
    <div
      style={{
        color: 'red',
        width: '100%',
        textAlign: 'right',
        paddingRight: '10%',
      }}
    >
      {props.errorMessage}
    </div>
  );
}

class CompanyAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        user_name: '',
        full_name: '',
        email: '',
        office_name: '',
        address: '',
        password: '',
        repassword: '',
      },
      is_sync: false,
      syncInfo: {
        create_sale_link: 'http://3.1.13.10:2021/api/v1/agent/verson2',
        create_enterprise_link: 'http://3.1.13.10:2021/api/v1/enterprises/verson2',
        name: 'Shopcloud',
        deeplink_ios: 'dev.shopcloud://',
        deeplink_android: 'dev.shopcloud://',
        app_store_id: '1570483795',
        play_store_id: 'vn.com.shopcloud',
      },
      isLoading: false,
      file: '',
    };
  }

  async createCompany() {
    const { is_sync } = this.state;
    const {
      create_sale_link,
      create_enterprise_link,
      name,
      deeplink_ios,
      deeplink_android,
      app_store_id,
      play_store_id,
    } = this.state.syncInfo;

    const check = this.validateForm();

    if (is_sync) {
      if (!name) {
        notifyFail('Vui lòng nhập tên app!');
        return;
      }
      if (!create_enterprise_link) {
        notifyFail('Vui lòng nhập link tạo công ty!');
        return;
      }
      if (!create_sale_link) {
        notifyFail('Vui lòng nhập link tạo sale!');
        return;
      }
    }

    const newSyncInfo = {
      create_sale_link: is_sync ? create_sale_link.trim() : '',
      create_enterprise_link: is_sync ? create_enterprise_link.trim() : '',
      name: is_sync ? name.trim() : '',
      deeplink_ios: is_sync ? deeplink_ios.trim() : '',
      deeplink_android: is_sync ? deeplink_android.trim() : '',
      app_store_id: is_sync ? app_store_id.trim() : '',
      play_store_id: is_sync ? play_store_id.trim() : '',
    };

    if (check) {
      let payload = { ...this.state.form };
      if (is_sync) {
        payload = Object.assign({ ...this.state.form }, { ...newSyncInfo, is_sync: is_sync ? '1' : '0', status: '1' });
      }
      const formdata = createFormData(payload);
      console.log(formdata.file);
      this.setState({ isLoading: true });
      requesCreateCompany(formdata)
        .then((res) => {
          this.setState({ isLoading: false });
          Object.keys(this.state.form).forEach((k) =>
            this.setState({
              form: {
                ...this.state.form,
                [k]: '',
              },
            })
          );
          this.props.history.goBack();
          notifySuccess('Thêm mới thành công!');
        })
        .catch((err) => {
          this.setState({ isLoading: false }, () => notifyFail(err.msg));
        });
    }
    return;
  }

  validateForm() {
    const { form, file } = this.state;
    if (!form.user_name?.trim() || !form.full_name?.trim() || !form.email?.trim() || !form.office_name?.trim()) {
      return false;
    }
    if (file === '') {
      return false;
    }
    // if (PHONE_REGEX.exec(form.user_name.trim()) === null) {
    //   return false;
    // }

    if (EMAIL_REGEX.exec(form.email) === null) {
      return false;
    }

    if (form.password !== form.repassword) {
      return false;
    }

    if (form.password.length < 6) {
      return false;
    }
    return true;
  }

  checkValidation = () => {
    const { form, file } = this.state;
    if (
      !form.user_name?.trim() ||
      !form.full_name?.trim() ||
      !form.email?.trim() ||
      !form.office_name?.trim() ||
      !form.password?.trim() ||
      !form.repassword?.trim()
    ) {
      return <FormError errorMessage={' Vui lòng điền đầy đủ thông tin!'} />;
    }
    if (file === '') {
      return <FormError errorMessage={' Vui lòng thêm logo công ty'} />;
    }
    if (EMAIL_REGEX.exec(form.email) === null) {
      return <FormError errorMessage={' Email không hợp lệ!'} />;
    }
    // if (PHONE_REGEX.exec(form.user_name.trim()) === null) {
    //   return <FormError errorMessage={'Số điện thoại không hợp lệ!'} />;
    // }

    if (PASS_REGEX.exec(form.password) === null) {
      return (
        <FormError
          errorMessage={
            'Mật khẩu phải chứa ít nhất tám ký tự, ít nhất một số và cả chữ thường, chữ hoa và các ký tự đặc biệt'
          }
        />
      );
    }

    if (form.password !== form.repassword) {
      return <FormError errorMessage={'Mật khẩu không khớp!'} />;
    } else {
      return true;
    }
  };

  handleChangeImage = (type, event) => {
    if (!ACCEPT_TYPE.IMAGE.includes(event.target.files[0]?.type)) {
      // alert('Định dạng ảnh không được hỗ trợ. Vui lòng chọn ảnh khác.');
      return;
    }
    this.setState({
      form: {
        ...this.state.form,
        [type]: event.target.files[0],
      },
      [type]: (window.URL || window.webkitURL).createObjectURL(
        // tạo fake path của file trong html input để hiển thị
        event.target.files[0]
      ),
    });
  };

  handleInputChange = (field, value) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [field]: value,
      },
    });
  };

  renderBody() {
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
            <Col md className="bg">
              <Row>
                <h2> Thêm công ty </h2>
              </Row>
              {/* <div class="line"></div> */}
              <Row>
                {this.checkValidation()}
                {this.renderField()}
              </Row>
              <Row>
                <Col md={7} sm={12}>
                  {this.renderButton()}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderButton() {
    return (
      <div className="text-center text-md-right">
        <Button
          variant="success"
          className="text-light"
          style={{ width: '100px' }}
          onClick={() => this.createCompany()}
        >
          Lưu
        </Button>
      </div>
    );
  }

  handleChangeSyncInfo(fieldName, value) {
    this.setState({
      ...this.state,
      syncInfo: {
        ...this.state.syncInfo,
        [fieldName]: value,
      },
    });
  }

  renderField() {
    const { form } = this.state;
    const {
      create_sale_link,
      create_enterprise_link,
      name,
      deeplink_ios,
      deeplink_android,
      app_store_id,
      play_store_id,
    } = this.state.syncInfo;

    return (
      <div className="w-100">
        <Row>
          <Col md={4} className="offset-0 offset-md-1">
            <Row>
              <label>Ảnh logo (*)</label>
            </Row>
            <Row>{this.renderUploader()}</Row>
            <Row className="mt-3">
              <Col md={10} className="pl-0">
                <label>Đồng bộ với hệ thống khác</label>
              </Col>
              <Col md={2}>
                <FormControl
                  type="checkbox"
                  style={{ width: '20px', height: '20px' }}
                  checked={this.state.is_sync}
                  onChange={() =>
                    this.setState({
                      ...this.state,
                      is_sync: !this.state.is_sync,
                    })
                  }
                />
              </Col>
            </Row>
            {this.state.is_sync && (
              <Row className="mt-3">
                <Col md={3} className="pl-0 d-flex align-items-center">
                  <i class="fas fa-mobile-alt fa-lg mr-2"></i>
                  <label>Tên App (*)</label>
                </Col>
                <Col md={9}>
                  <FormControl
                    type="text"
                    placeholder="Tên app"
                    value={name}
                    // disabled
                    onChange={(e) => this.handleChangeSyncInfo('name', e.target.value)}
                  />
                </Col>
                <Col md={12} className="pl-0 mt-3">
                  <label>Link tạo công ty (*)</label>
                </Col>
                <Col md={12} className="pl-0">
                  <FormControl
                    type="text"
                    placeholder="Link tạo công ty"
                    value={create_enterprise_link}
                    // disabled
                    onChange={(e) => this.handleChangeSyncInfo('create_enterprise_link', e.target.value)}
                  />
                </Col>
                <Col md={12} className="pl-0 mt-3">
                  <label>Link tạo sale (*)</label>
                </Col>
                <Col md={12} className="pl-0">
                  <FormControl
                    type="text"
                    placeholder="Link tạo sale"
                    value={create_sale_link}
                    // disabled
                    onChange={(e) => this.handleChangeSyncInfo('create_sale_link', e.target.value)}
                  />
                </Col>
                {/* -------------------------- */}
                <Col md={12} className="pl-0 mt-3">
                  <i class="fab fa-apple fa-lg mr-2"></i>
                  <label>Mã app store</label>
                </Col>
                <Col md={12} className="pl-0">
                  <FormControl
                    type="text"
                    placeholder="Mã app store"
                    value={app_store_id}
                    // disabled
                    onChange={(e) => this.handleChangeSyncInfo('app_store_id', e.target.value)}
                  />
                </Col>
                <Col md={12} className="pl-0 mt-3">
                  <i class="fab fa-android fa-lg mr-2"></i>
                  <label>Mã play store</label>
                </Col>
                <Col md={12} className="pl-0">
                  <FormControl
                    type="text"
                    placeholder="Mã play store"
                    value={play_store_id}
                    // disabled
                    onChange={(e) => this.handleChangeSyncInfo('play_store_id', e.target.value)}
                  />
                </Col>
                <Col md={12} className="pl-0 mt-3">
                  <i class="fab fa-apple fa-lg mr-2"></i>
                  <label>Deeplink IOS</label>
                </Col>
                <Col md={12} className="pl-0">
                  <FormControl
                    type="text"
                    placeholder="Deeplink IOS"
                    value={deeplink_ios}
                    // disabled
                    onChange={(e) => this.handleChangeSyncInfo('deeplink_ios', e.target.value)}
                  />
                </Col>
                <Col md={12} className="pl-0 mt-3">
                  <i class="fab fa-android fa-lg mr-2"></i>
                  <label>Deeplink Android</label>
                </Col>
                <Col md={12} className="pl-0">
                  <FormControl
                    type="text"
                    placeholder="Deeplink Android"
                    value={deeplink_android}
                    // disabled
                    onChange={(e) => this.handleChangeSyncInfo('deeplink_android', e.target.value)}
                  />
                </Col>
              </Row>
            )}
          </Col>
          <Col md={6}>
            <FormGroup className="add-company-form">
              <Row>
                <label>Tên công ty (*)</label>
                <FormControl
                  type="text"
                  placeholder="Nhập tên công ty"
                  value={form.office_name}
                  onChange={(e) => this.handleInputChange('office_name', e.target.value)}
                ></FormControl>
              </Row>
              <Row>
                <label>Email (*)</label>
                <FormControl
                  type="email"
                  placeholder="Nhập email"
                  value={form.email}
                  onChange={(e) => this.handleInputChange('email', e.target.value)}
                ></FormControl>
              </Row>
              <Row>
                <label>Số điện thoại (*)</label>
                <FormControl
                  type="tel"
                  placeholder="Nhập số điện thoại"
                  value={form.user_name}
                  onChange={(e) => this.handleInputChange('user_name', e.target.value)}
                ></FormControl>
              </Row>
              <Row>
                <label>Người liên hệ (*)</label>
                <FormControl
                  type="text"
                  placeholder="Nhập tên người liên hệ"
                  value={form.full_name}
                  onChange={(e) => this.handleInputChange('full_name', e.target.value)}
                ></FormControl>
              </Row>
              <Row>
                <label>Địa chỉ</label>
                <FormControl
                  type="text"
                  placeholder="Nhập địa chỉ công ty"
                  value={form.address}
                  onChange={(e) => this.handleInputChange('address', e.target.value)}
                ></FormControl>
              </Row>
              <Row>
                <label htmlFor="address ">Mật khẩu (*)</label>
                <FormControl
                  type="password"
                  name="address"
                  value={form.password}
                  onChange={(e) => this.handleInputChange('password', e.target.value)}
                  placeholder="Nhập mật khẩu"
                ></FormControl>
              </Row>
              <Row>
                <label htmlFor="address ">Xác nhận mật khẩu (*)</label>
                <FormControl
                  type="password"
                  name="address"
                  value={form.repassword}
                  onChange={(e) => this.handleInputChange('repassword', e.target.value)}
                  placeholder="Nhập lại mật khẩu"
                ></FormControl>
              </Row>
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }

  renderUploader() {
    const { file } = this.state;
    return (
      <div
        style={{
          width: '100%',
          height: '300px',
          borderRadius: '7px',
          textAlign: 'center',
          backgroundColor: '#e0e0e0',
        }}
      >
        <label htmlFor="upload-button" className="label-upload">
          {file.length ? (
            <img
              src={file}
              className="uploaded-pic"
              width="300"
              height="300"
              style={{ objectFit: 'contain', borderRadius: '8px' }}
            />
          ) : (
            <div style={{ marginTop: '5rem' }}>
              <i className="fas fa-image upload-icon m-0"></i>
              <br />
              <p className="m-0">Bấm để chọn ảnh logo của công ty</p>
              {/* <span>(Định dạng ảnh được hỗ trợ: jpg, jpeg, png)</span> */}
            </div>
          )}
        </label>
        <input
          type="file"
          id="upload-button"
          accept=".jpg,.jpeg,.png"
          style={{ display: 'none' }}
          onChange={(e) => this.handleChangeImage('file', e)}
        />
        <br />
      </div>
    );
  }
  render() {
    return (
      <>
        {this.state.isLoading ? <Loading /> : null}
        {this.renderBody()}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ToastContainer />
      </>
    );
  }
}

export default withRouter(CompanyAdd);
