import React, { Component } from 'react';
import { requestSaleDetail } from '@constants/Api';
import { withRouter } from 'react-router-dom';
import '@styles/RegisterRequest.css';
import { Row, Col, FormControl, Button, Nav, Table } from 'react-bootstrap';
import { TYPE_MEDIA, SALE_STATUS, USER_ROLE, SALE_STATUS_ALIAS, STRING, ROUTER } from '@constants/Constant';
import { toDateString } from '../../../Utils/ToDateString';
import { connect } from 'react-redux';
import { getTelesaleList, getUserInfo } from '@src/redux/actions';
import Loading from '@src/components/Loading';
import reactotron from 'reactotron-react-js';
import _ from 'lodash';
import { toast } from 'react-toastify';
import Toast from '@components/Toast';
import 'react-toastify/dist/ReactToastify.css';
import { Avatar, Image } from 'antd';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { getItemFromId } from '@utils/getItemFromId';

import General from './JobInfo/General';
import CallHistory from './JobInfo/CallHistory';
import Report from './JobInfo/Report';
import CallLate from './JobInfo/CallLate';
import KPI from './JobInfo/KPI';
import { requestDeleteSale } from '@constants/Api';
import { Modal } from 'react-bootstrap';
import { notifySuccess } from '@utils/notify';

class TelesaleDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableInfo: 1,
      isLoading: false,
      searchBar: {
        searchKey: '',
        status: '0',
        work: '0',
      },
      detail: {},
      modalDeleteSaleVisible: false,
    };
  }

  componentDidMount() {
    this.props.getUserInfo();
    this.getData();
  }

  notifyFail = (mes) =>
    toast.error(mes, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  async getData() {
    this.setState({ isLoading: true });
    const { id } = this.props.match.params;
    let profile = '',
      front_id = '',
      back_id = '';
    try {
      const res = await requestSaleDetail({ id: id });
      if (res.data.user_sale_media) {
        res.data.user_sale_media.forEach((media) => {
          if (media.type === TYPE_MEDIA.PROFILE_IMAGE) profile = media.url;
          if (media.type === TYPE_MEDIA.BACK_IMAGE) back_id = media.url;
          if (media.type === TYPE_MEDIA.FRONT_IMAGE) front_id = media.url;
        });
      }
      this.setState({
        detail: res.data,
        isLoading: false,
        front_id: front_id,
        back_id: back_id,
        profile: profile,
      });
    } catch (err) {
      this.notifyFail(err.msg);
      this.setState({ isLoading: false });
    }
  }

  renderTab = () => {
    const { tableInfo, detail } = this.state;
    if (tableInfo == 1) {
      return this.renderGeneralInfo(detail);
    }
    if (tableInfo == 2) {
      return this.renderCallHistory(detail);
    }
    if (tableInfo == 3) {
      return this.renderCallLate();
    }
    if (tableInfo == 4) {
      return this.renderReport();
    }
    // if (tableInfo == 5 && this.props.userInfoState.data.role_id == 1) {
    //   return this.renderKPI();
    // }
    if (tableInfo == 5) {
      return this.renderKPI();
    }
  };

  renderBody() {
    if (!_.isEmpty(this.state.detail)) {
      return (
        <div className="content-wrapper request-body">
          <Row
            // className="mt-5"
            style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}
          >
            <Col md className="bg" style={{ marginTop: '20px' }}>
              {this.renderInfo()}
              {this.renderTable()}
            </Col>
          </Row>
          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px' }}>
            <Col md className="bg" style={{ marginBottom: '30px' }}>
              {this.renderWorkInfo()}
            </Col>
          </Row>
        </div>
      );
    } else {
      return null;
    }
  }

  renderTable() {
    const sale = this.state.detail;
    const saleStatus = getItemFromId(SALE_STATUS, sale.user_sale_info?.user_sale_status_id);
    return (
      <>
        <Table bordered id="infor" style={{ display: 'none' }}>
          <tbody>
            <tr>
              <td style={{ fontWeight: 'bold', color: '#2196f3' }}>THÔNG TIN TELESALE</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Họ và tên</td>
              <td>{sale?.full_name}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Số điện thoại</td>
              <td>{sale?.phone}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{sale?.email}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Ngày sinh</td>
              <td>{sale?.user_sale_info && sale.user_sale_info.dob ? toDateString(sale.user_sale_info.dob) : ''}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Tỉnh/Thành phố</td>
              <td>{sale?.user_sale_info && sale.user_sale_info.province?.name}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Số CMND</td>
              <td>{sale?.user_sale_info && sale.user_sale_info?.id_number}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Thu nhập mong muốn</td>
              <td>{sale?.user_sale_info && sale.user_sale_info?.expected_salary}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Kinh nghiệm</td>
              <td>{sale?.user_sale_info && sale.user_sale_info?.work_experience}</td>
              <td></td>
              <td></td>
            </tr>
            <tr></tr>
            <tr>
              <td style={{ fontWeight: 'bold', color: '#2196f3' }}>KẾT QUẢ DUYỆT</td>
              <td
                className={
                  sale?.user_sale_info && sale.user_sale_info?.user_sale_status_id == 1 ? 'waiting' : 'text-blue'
                }
              >
                {saleStatus && saleStatus?.name}
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr></tr>
            <tr>
              <td style={{ fontWeight: 'bold', color: '#2196f3' }}>KẾT QUẢ CHI TIẾT</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td style={{ backgroundColor: '#1976d2' }}>Chức vụ</td>
              <td style={{ backgroundColor: '#1976d2' }}>Người duyệt</td>
              <td style={{ backgroundColor: '#1976d2' }}>Trạng thái</td>
              <td style={{ backgroundColor: '#1976d2' }}>Nội dung</td>
            </tr>
            {sale?.user_sale_status_histories?.length &&
              sale?.user_sale_status_histories?.map((value) => (
                <tr>
                  <td>
                    {value?.user?.role_id == 1
                      ? 'Admin'
                      : value?.user?.role_id == 3
                      ? 'Trưởng phòng chuyên môn'
                      : 'Trưởng phòng nhân sự'}
                  </td>
                  <td>{value?.user?.full_name}</td>
                  <td>Đã phê duyệt</td>
                  {value?.user?.role_id != 1 && (
                    <td>
                      <div>Chất giọng: {value?.review_voice}</div>
                      <div>Trôi chảy: {value?.review_fluent}</div>
                      <div>Sự tự tin: {value?.review_comfident}</div>
                      <div>Khác: {value?.review_other}</div>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </Table>
      </>
    );
  }

  renderHeaderButton() {
    const { detail } = this.state;
    const sale = this.state.detail;
    if (
      [USER_ROLE.ADMIN, USER_ROLE.PROFESSION_MANAGER, USER_ROLE.ENTERPRISE, USER_ROLE.LEADER].includes(
        this.props.userState?.data?.role_id
      )
    ) {
      return (
        <Row className="justify-content-center justify-content-md-end">
          <Col>
            {detail.is_active == 1 ? (
              <Button
                variant="info"
                className="text-light"
                onClick={() => this.props.history.push(ROUTER.TELESALE_EDIT + `/${detail.user_id}`)}
                style={{ marginBottom: '5px' }}
              >
                Sửa
              </Button>
            ) : (
              ''
            )}
            {/* <Button
              variant="danger"
              className="text-light"
              onClick={() => this.setState({ ...this.state, modalDeleteSaleVisible: true })}
              style={{ marginBottom: '5px' }}
            >
              Xóa
            </Button> */}
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button btn-warning"
              table="infor"
              filename={`bang-tinh-${sale?.full_name}`}
              sheet="tablexls"
              buttonText="Xuất kết quả"
            ></ReactHTMLTableToExcel>
          </Col>
        </Row>
      );
    }
  }

  modalDeleteSale() {
    const { modalDeleteSaleVisible } = this.state;

    return (
      <Modal
        show={modalDeleteSaleVisible}
        onHide={() =>
          this.setState({
            ...this.state,
            modalDeleteSaleVisible: false,
          })
        }
        centered
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton className="text-white bg-primary pb-0">
          <h5 className="text-light pb-0">Bạn có chắc chắn muốn xóa sale này?</h5>
        </Modal.Header>
        {/* <Modal.Body>
          <h4>
            {selectedSale.full_name} (
            <label className="text-info" style={{ fontSize: '20px' }}>
              {selectedSale.phone}
            </label>
            )
          </h4>
        </Modal.Body> */}
        <Modal.Footer className="justify-content-end">
          <Button variant="success" className="text-light btn-oversize" onClick={() => this.handleDeleteSale()}>
            Có
          </Button>
          <Button
            variant="danger"
            className="text-light btn-oversize"
            onClick={() =>
              this.setState({
                ...this.state,
                modalDeleteSaleVisible: false,
              })
            }
          >
            Hủy
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  async handleDeleteSale() {
    const { id } = this.props.match.params;
    try {
      const res = await requestDeleteSale({ id: id });
      notifySuccess('Xóa thành công!');
      this.setState({
        ...this.state,
        modalDeleteSaleVisible: false,
      });
      this.props.history.goBack();
    } catch (error) {}
  }

  renderInfo() {
    const sale = this.state.detail;
    console.log(sale, 'sale');
    const { profile, front_id, back_id } = this.state;
    return (
      <>
        <Row>
          <Col md={4} className="text-center">
            <Row>
              <Col md={12}>
                <Avatar src={sale.user_sale_info.profile_image} size={100} className="bg-blue text-white">
                  {sale.user_sale_info.profile_image !== '' ? null : sale?.full_name[0]?.toUpperCase()}
                </Avatar>
              </Col>
              <Col md={12}>
                <p style={{ fontSize: '20px', fontWeight: 500, marginTop: '19px' }} className="m-0">
                  {sale && sale.full_name}
                </p>
              </Col>
              <Col md={12}>
                <span
                  className={sale.user_sale_info && sale.is_active == 1 ? 'text-success' : 'text-danger'}
                  style={{ marginTop: '12px' }}
                >
                  {sale.is_active == 1 ? 'Đang hoạt động' : 'Không hoạt động'}
                </span>
              </Col>
              <Col md={12} style={{ marginTop: '18px', marginLeft: '2rem' }}>
                <Row>
                  <Col md="8" className="text-left m-0">
                    <span style={{ fontSize: 16, color: '#666666' }}>Điểm kinh nghiệm </span>
                  </Col>
                  <Col md="4" className="text-left m-0">
                    <span style={{ fontSize: 16, fontWeight: '700' }} className="text-danger">
                      {sale?.user_sale_info?.reward_point}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col md={12} style={{ marginLeft: '2rem' }}>
                <Row>
                  <Col md="8" className="text-left">
                    <span style={{ fontSize: 16, color: '#666666' }}>Số cuộc gọi trung bình </span>
                  </Col>
                  <Col md="4" className="text-left">
                    <span style={{ fontSize: 16, fontWeight: '700' }} className="text-danger">
                      {sale?.user_sale_info?.success_call}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col md={12} style={{ marginLeft: '2rem' }}>
                <Row>
                  <Col md="8" className="text-left">
                    <span style={{ fontSize: 16, color: '#666666' }}>KPI trung bình </span>
                  </Col>
                  <Col md="4" className="text-left">
                    <span style={{ fontSize: 16, fontWeight: '700' }} className="text-danger">
                      {sale?.user_sale_info?.kpi_average}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md={8} style={{ borderLeft: '1px solid #D6D9DC' }}>
            <Row>
              <Col md={8}>
                <Row>
                  <Col>
                    <span>{`${STRING.phoneNumber}:`}</span>
                  </Col>
                  <Col md={9} xs={6} className="px-0">
                    <label>{sale && sale.phone}</label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span>{`${STRING.email}:`}</span>
                  </Col>
                  <Col md={9} xs={6} className="px-0">
                    <label>{sale && sale.email}</label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span>{`${STRING.dob}:`}</span>
                  </Col>
                  <Col md={9} xs={6} className="px-0">
                    <label>
                      {(sale.user_sale_info?.dob &&
                        Intl.DateTimeFormat('vi-VN').format(new Date(sale.user_sale_info.dob))) ||
                        '--'}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span>{`${STRING.gender}:`}</span>
                  </Col>
                  <Col md={9} xs={6} className="px-0">
                    <label>{sale.user_sale_info && sale.user_sale_info.gender == 2 ? 'Nam' : 'Nữ'}</label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span>{`${STRING.city}:`}</span>
                  </Col>
                  <Col md={9} xs={6} className="px-0">
                    <label>{(sale.user_sale_info && sale.user_sale_info?.province?.name) || '--'}</label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span>{`${STRING.realAddress}:`}</span>
                  </Col>
                  <Col md={9} xs={6} className="px-0">
                    <label>{(sale.user_sale_info && sale.user_sale_info.address) || '--'}</label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span>{`${STRING.identicalId}:`}</span>
                  </Col>
                  <Col md={9} xs={6} className="px-0">
                    <label>{sale.user_sale_info && sale.user_sale_info.id_number}</label>
                  </Col>
                </Row>
                <Row>
                  <Col></Col>
                  <Col md={9} className="p-0 mb-3">
                    <Image
                      width={150}
                      height={85}
                      src={(front_id && front_id) || require('../../../assets/no-image.png')}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                    <Image
                      width={150}
                      height={85}
                      src={(back_id && back_id) || require('../../../assets/no-image.png')}
                      style={{ cursor: 'pointer', objectFit: 'contain', marginLeft: 10 }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span>{`${STRING.experience}:`}</span>
                  </Col>
                  <Col md={9} xs={6} className="px-0">
                    <label>{sale.user_sale_info?.work_experience}</label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span>{`${STRING.profile}:`}</span>
                  </Col>
                  <Col md={9} xs={6} className="px-0">
                    {/* <a href={sale.user_sale_info && sale.user_sale_info.cv_url} target="_blank">
                      {sale.user_sale_info && sale.user_sale_info.cv_url ? 'Click here to read' : '--'}
                    </a> */}
                    {sale?.user_sale_info && sale.user_sale_info?.cv_url ? (
                      <a
                        href={sale?.user_sale_info && sale.user_sale_info?.cv_url}
                        className="link-infor"
                        style={{ color: 'red' }}
                        target="_blank"
                      >
                        Click để xem
                      </a>
                    ) : (
                      '--'
                    )}
                  </Col>
                </Row>
                {/* <Row>
                  <Col>
                    <label>{`${STRING.status}:`}</label>
                  </Col>
                  <Col md={9} xs={6} className="px-0">
                    <span className={sale.user_sale_info && sale.is_active ? 'text-success' : 'text-danger'}>
                      {sale.is_active ? 'Đang hoạt động' : 'Không hoạt động'}
                    </span>
                  </Col>
                </Row> */}
              </Col>
              <Col md={4}>{this.renderHeaderButton()}</Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  renderWorkInfo() {
    const { tableInfo, detail } = this.state;

    return (
      <>
        <Row>
          <Col md={6}>
            <h4>Thông tin công việc</h4>
          </Col>
        </Row>

        <Row className="content-header table-tabs pl-0 pb-0">
          <Col md={12}>
            <Nav variant="tabs" defaultActiveKey="general-info">
              <Nav.Item>
                <Nav.Link eventKey="general-info" onSelect={() => this.showTableInfo(1)} className="tab-item">
                  Thông tin chung
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="call-history" onSelect={() => this.showTableInfo(2)} className="tab-item">
                  Lịch sử chăm sóc
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="call-late" onSelect={() => this.showTableInfo(3)} className="tab-item">
                  Khách hàng chăm sóc muộn
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="report" onSelect={() => this.showTableInfo(4)} className="tab-item">
                  Báo cáo khách hàng
                </Nav.Link>
              </Nav.Item>

              {/* {this.props.userInfoState.data.role_id == 1 ? (
                <Nav.Item>
                  <Nav.Link eventKey="kpi" onSelect={() => this.showTableInfo(5)} className="tab-item">
                    KPI
                  </Nav.Link>
                </Nav.Item>
              ) : (
                ''
              )} */}
              <Nav.Item>
                <Nav.Link eventKey="kpi" onSelect={() => this.showTableInfo(5)} className="tab-item">
                  KPI
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row className="">
          <Col md={12} className="bg-white pt-4">
            {this.renderTab()}
          </Col>
        </Row>
      </>
    );
  }

  renderGeneralInfo = (detail) => {
    return <General detail={detail} />;
  };

  renderCallHistory = (detail) => {
    return <CallHistory detail={detail} />;
  };

  renderCallLate = () => {
    return <CallLate />;
  };

  renderReport = () => {
    return <Report />;
  };

  renderKPI = () => {
    return <KPI />;
  };

  showTableInfo(status) {
    this.setState({
      ...this.state,
      tableInfo: status,
    });
  }

  render() {
    const { isLoading } = this.props.telesaleState;
    return (
      <>
        {this.state.isLoading ? <Loading /> : null}
        {this.renderBody()}
        {this.modalDeleteSale()}
        <Toast />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  telesaleState: state.TelesaleReducer,
  userState: state.userReducer,
  userInfoState: state.userReducer,
});

const mapDispatchToProps = {
  getTelesaleList,
  getUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TelesaleDetail));
