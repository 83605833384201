import React, { Component } from 'react';
import '@styles/review.css';
import './EvaluateHistory.css';
import { ReactSelect } from '@components/ReactSelect';
import { getCallHistory } from '@src/redux/actions';
import Pagination from '@components/PaginationTotal';
import { ROUTER } from '@constants/Constant';
import { requestResultReviewer, requestCallHistoryList } from '@constants/Api';
import { notifyFail, notifySuccess } from '@utils/notify';
import Loading from '@src/components/Loading';

import { withRouter, Link } from 'react-router-dom';
import { Row, Col, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Empty } from 'antd';

class EvaluateHistoryScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job_id: '',
      telesale_id: '',
      status: '',
      listJob: [],
      listTelesale: [],
      listStatus: [],

      generalInfor: {},
      listReview: [],
      paging: {},
      isLoading: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.getData({ page: 1 });
    this.requestResultReviewer();
  }

  async getData({ page, job_id, telesale_id, status }) {
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const res = await requestCallHistoryList({
        page: page,
        job_id: job_id || '',
        call_status: status || '',
        telesale_id: telesale_id || '',
      });
      this.setState({
        ...this.state,
        listReview: res.data?.listReview,
        generalInfor: res.data,
        paging: res?.paging,
        isLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async requestResultReviewer() {
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const res = await requestResultReviewer();
      this.setState({
        ...this.state,
        listJobCategory: res.data?.list_task[0]?.job_categories,
        listJob: res.data?.list_task[0]?.jobs,
        listTelesale: res.data?.list_task[0]?.telesales,
        listStatus: res.data?.list_task[0]?.call_status,
      });
    } catch (err) {
      this.setState({ ...this.state, isLoading: false }, () => notifyFail(err.msg));
    }
  }

  handleChangeSelect = (selectedOption, field) => {
    this.setState({ [field]: selectedOption.value }, () => {
      const { job_category_id, job_id, telesale_id, status } = this.state;
      this.getData({
        job_category_id: job_category_id,
        job_id: job_id,
        telesale_id: telesale_id,
        call_status: status,
      });
    });
  };

  renderTitle() {
    return <h2 className="title-tab">Lịch sử đánh giá</h2>;
  }

  renderField() {
    const { listJob, listTelesale, listStatus } = this.state;
    let listJobArr = listJob?.map(
      (province) =>
        new Object({
          value: province.id,
          label: province.name,
        })
    );
    listJobArr?.length > 0 && listJobArr.unshift({ value: '', label: 'Giao ' });

    const listTelesaleArr = listTelesale?.map(
      (province) =>
        new Object({
          value: province.id,
          label: province.name,
        })
    );
    listTelesaleArr?.length > 0 && listTelesaleArr.unshift({ value: '', label: 'Telesale' });

    const listStatusArr = listStatus?.map(
      (province) =>
        new Object({
          value: province.id,
          label: province.name,
        })
    );
    listStatusArr?.length > 0 && listStatusArr.unshift({ value: '', label: 'Trạng thái' });
    return (
      <Row>
        <Col md={4}>
          <ReactSelect
            handleChangeSelect={(e) => this.handleChangeSelect(e, 'job_id')}
            list={listJobArr}
            placeholder="Giao việc"
          />
        </Col>

        <Col md={4}>
          <ReactSelect
            handleChangeSelect={(e) => this.handleChangeSelect(e, 'telesale_id')}
            list={listTelesaleArr}
            placeholder="Sale"
          />
        </Col>

        <Col md={4}>
          <ReactSelect
            handleChangeSelect={(e) => this.handleChangeSelect(e, 'status')}
            list={listStatusArr}
            placeholder="Trạng thái"
          />
        </Col>
      </Row>
    );
  }

  renderTable() {
    const callHistory = this.state.listReview;
    const itemsCount = this.state.paging?.count;
    const { listReview, paging } = this.state;
    return (
      <div className="w-100">
        <span className="pb-2 d-inline-block m-0" style={{ marginLeft: '21px' }}>
          Kết quả lọc: <b className="text-danger">{itemsCount || 0}</b>
        </span>

        {callHistory?.length ? (
          callHistory.map((call) => this.renderCol(call))
        ) : (
          <Empty description={<span>Không có dữ liệu</span>} />
        )}
      </div>
    );
  }

  renderCol(call) {
    return (
      <Link to={ROUTER.CALL_DETAIL_REVIEWER + '/' + call.id} style={{ color: 'black' }}>
        <Row className="mt-1" style={{ padding: '0px 0px', borderRadius: '8px', marginBottom: 24 }}>
          <Col md className="bg item-hov">
            <Row>
              <Col md={12}>
                <b style={{ fontSize: 18 }}>{call.job_name || '--'}</b>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <p className="text-gray m-0" style={{ fontSize: '14px' }}>
                Sale
                </p>
                <p className="m-0">{call?.telesale_name || '--'}</p>
              </Col>
              <Col md={6}>
                <p className="text-gray m-0" style={{ fontSize: '14px' }}>
                  Khách hàng
                </p>
                <p className="m-0">{call.customer_name || '--'}</p>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <Row>
                  <Col md={6} className="p-0">
                    <p className="text-gray m-0" style={{ fontSize: '14px' }}>
                      Ngày
                    </p>
                    <p className="m-0">
                      {Intl.DateTimeFormat('vi-VN').format(new Date(call?.start_call_time || '--'))}
                    </p>
                  </Col>
                  <Col md={6} className="p-0">
                    <p className="text-gray m-0" style={{ fontSize: '14px' }}>
                      Thời gian gọi
                    </p>
                    <p className="m-0">{call?.call_duration || '--'} giây</p>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={6} className="p-0">
                    <p className="text-gray m-0" style={{ fontSize: '14px' }}>
                      Số điện thoại
                    </p>
                    <p className="m-0">{call.customer_phone || '--'}</p>
                  </Col>
                  <Col md={6} className="p-0">
                    <p className="text-gray m-0" style={{ fontSize: '14px' }}>
                      Tình trạng
                    </p>
                    <p className="m-0 text-success">{call.call_status_name || '---'}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12} style={{ padding: '10px', backgroundColor: '#fdd835', borderRadius: '7px' }}>
                <span>
                  <i class="fas fa-user-edit" style={{ fontSize: '24px', marginRight: '5px' }}></i>
                </span>
                {call?.call_reviews?.length &&
                  call?.call_reviews?.map((value) => <span>{value?.call_review_config?.name + ', ' || '--'}</span>)}
              </Col>
            </Row>
          </Col>
        </Row>
      </Link>
    );
  }

  renderPagination() {
    const { paging } = this.state;
    const pagingNew = {
      page: paging?.page,
      totalItemCount: paging?.count,
      limit: paging?.limit,
    };
    console.log('ok', pagingNew);
    return <Pagination paging={pagingNew} />;
  }

  renderInforGeneral() {
    const { generalInfor } = this.state;
    return (
      <Row className="mt-1" style={{ padding: '0px 0px', borderRadius: '8px', marginBottom: 24 }}>
        <Col className="bg" style={{ margin: '0 16px' }}>
          <Row>
            <h4>Tổng quan đánh giá</h4>
          </Row>
          <Row>
            <Col md={12} className="p-0">
              <span>Cuộc gọi đã đánh giá:</span>
              <b className="text-green ml-2">{generalInfor?.reviewed || '0'}</b>
            </Col>
          </Row>
          <Row>
            <Table bordered hover striped className="text-center">
              <thead>
                <th>STT</th>
                <th>Ghi chú</th>
                <th>Số lượng</th>
              </thead>
              <tbody>
                {generalInfor?.statistic?.length > 0 ? (
                  generalInfor?.statistic?.map((value, index) => (
                    <tr>
                      <td>{index + 1 || '--'}</td>
                      <td>{value?.name || '--'}</td>
                      <td>{value?.count || '0'}</td>
                    </tr>
                  ))
                ) : (
                  <td colspan="3">
                    <Empty description="Không có dữ liệu" />
                  </td>
                )}
              </tbody>
            </Table>
          </Row>
        </Col>
      </Row>
    );
  }

  renderBody() {
    return (
      <div className="content-wrapper">
        {this.renderTitle()}
        {this.renderInforGeneral()}
        {this.renderField()}
        <Row className="mt-4">
          <Col md={12} sm={12}>
            {this.renderTable()}
          </Col>
        </Row>
        <Row>
          <Col md={12} className="d-flex justify-content-center justify-content-md-end">
            {this.renderPagination()}
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { isLoading } = this.state;

    return (
      <>
        {isLoading && <Loading />}
        {this.renderBody()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  callHistoryState: state.callHistoryReducer,
});

const mapDispatchToProps = {
  getCallHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EvaluateHistoryScreen));
