import { getNextCustomer } from '@actions/';
import { TELESALE_JOB_STATUS_ALIAS } from '@constants/Constant';
import { useDispatch } from 'react-redux';
import { requestGetJobCustomerList } from 'src/network/web_sale/JobApi';
export const useNextCustomer = (props) => {
  const { JobId, currentPageNumber } = props;
  const dispatch = useDispatch();
  const getNextCustomerWithJobId = async (customerId) => {
    const params = {
      search: '',
      tag_id: [],
      status: TELESALE_JOB_STATUS_ALIAS.SALE_ACCEPTED,
      job_id: JobId,
      page: currentPageNumber,
    };
    try {
      const customersWillBeCall = await requestGetJobCustomerList(params);
      const { limit, totalItemCount } = customersWillBeCall.paging;
      let totalPage = Math.floor(totalItemCount / limit);
      if (totalItemCount % limit > 0) {
        totalPage += 1;
      }
      params.page = totalPage > currentPageNumber ? currentPageNumber + 1 : 1;
      const indexOfCurrentCustomer = customersWillBeCall?.data.findIndex(
        (customer) => parseInt(customer.customer_id) === parseInt(customerId)
      );
      const getNextCustomerPayload = {
        jobId: JobId,
        currentPageNumber,
        nextId: customersWillBeCall?.data[indexOfCurrentCustomer + 1]?.customer?.id,
        nextJobTelesaleCustomerId: customersWillBeCall?.data[indexOfCurrentCustomer + 1]?.job_telesale_customer_id,
      };
      // Nếu user ở cuối của danh sách, lấy danh sách customers của trang tiếp theo hoặc quay lại trang đầu
      if (parseInt(indexOfCurrentCustomer) === customersWillBeCall?.data.length - 1) {
        try {
          const nextCustomersWillBeCall = await requestGetJobCustomerList(params);
          getNextCustomerPayload.currentPageNumber =
            totalPage === currentPageNumber ? 1 : currentPageNumber + 1;
          getNextCustomerPayload.nextId = nextCustomersWillBeCall?.data[0]?.customer?.id;
          getNextCustomerPayload.nextJobTelesaleCustomerId = nextCustomersWillBeCall?.data[0]?.job_telesale_customer_id;
        } catch (error) {
          console.log('error', error);
        }
      }
      dispatch(getNextCustomer(getNextCustomerPayload));
    } catch (error) {
      console.log('error', error);
    }
  };
  return {
    getNextCustomerWithJobId,
  };
};
