import { BOX_SHADOW, QUICKSAND_FONT } from '@constants/Constant';
import { PageHeader } from 'antd';
import styled from 'styled-components';

export const ContainerCallingStyled = styled.div`
  font-family: ${QUICKSAND_FONT};
  /* font-weight: 500; */
`;

export const PageHeaderCallingStyled = styled(PageHeader)`
  box-shadow: ${BOX_SHADOW};
  padding: 8px 16px !important;
  flex: 1;
`;
