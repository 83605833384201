import React from 'react';
import { useParams } from 'react-router-dom';
const IncomingCallPage = () => {
  // const { callId } = useParams();

  // React.useEffect(() => {
  //   if(!callId) return;
  //   const client = new window.StringeeClient();
  //   client.connect(callId);

  //   const handleIncomingCall = (incomingCall) => {
  //     // Xử lý cuộc gọi đến
  //     // Ví dụ: hiển thị thông báo hoặc phát âm thanh chuông

  //     // Trả lời cuộc gọi
  //     incomingCall.answer();

  //     // Hoặc từ chối cuộc gọi
  //     // incomingCall.reject();
  //   };

  //   client.on('incomingcall', handleIncomingCall);

  //   return () => {
  //     client.off('incomingcall', handleIncomingCall);
  //   };
  // }, [callId]);

  return <div>IncomingCallPage</div>;
};

export default IncomingCallPage;
