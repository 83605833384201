import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Image, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/fontawesome-free-regular';

import InteractionHistoryHeader from './InteractionHistoryHeader';
import { DescriptionCustomInteractionHistory } from '@styles/Style';
import { BASE_URL_IMAGE, VERIFY_ADDRESS } from '@constants/Constant';
import { VietnameseString } from '@constants/VietnameseString';
import styled from 'styled-components';
import GoongMapWeb from '../goongMapWeb/GoongMapWeb';

const DivScrollStyle = styled.div`
  overflow-x: auto;
  width: 100%;
  white-space: nowrap;

  ::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    border: 6px solid #d5d5d5;
    border-radius: 100px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 100px;
    background-color: lightgray;
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: #a891a8;
  }
`;

const LocationStyle = styled.span`
  color: #4444e6;
  :hover {
    text-decoration: underline;
  }
`;

const ModalStyle = styled(Modal)`
  .ant-modal-body {
    padding: 8px;
  }
`;

function LocationReport(props) {
  const { interactionHistory } = props;
  const [visible, setVisible] = useState(false);
  const [modalKey, setModalKey] = useState(undefined);

  return (
    <>
      <InteractionHistoryHeader interactionHistory={interactionHistory} />
      <DescriptionCustomInteractionHistory column={1}>
        <Descriptions.Item label="Địa chỉ cụ thể (vị trí khách hàng)">
          {interactionHistory.tags.address ? interactionHistory.tags.address : VietnameseString.not_update}
        </Descriptions.Item>
        <Descriptions.Item label="Địa chỉ định vị (vị trí nhân viên)">
          {interactionHistory.tags.latitude && interactionHistory.tags.longitude ? (
            <LocationStyle
              onClick={() => {
                setVisible(true);
                setModalKey(String(Date.now()));
              }}
            >
              {interactionHistory.tags.latitude}, {interactionHistory.tags.longitude}
            </LocationStyle>
          ) : interactionHistory.tags.address_sale ? (
            interactionHistory.tags.address_sale
          ) : (
            VietnameseString.not_update
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Trạng thái">
          {interactionHistory.tags?.verify_address === VERIFY_ADDRESS.YES ? (
            <span style={{ color: 'green' }}>{VietnameseString.address_match}</span>
          ) : (
            <span style={{ color: 'red' }}>{VietnameseString.address_does_not_match}</span>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Ghi chú">
          {interactionHistory.tags.note ? interactionHistory.tags.note : VietnameseString.not_update}
        </Descriptions.Item>
        <Descriptions.Item label="File ảnh">
          {interactionHistory.tags.listImageCreate.length > 0 ? (
            <DivScrollStyle>
              {interactionHistory.tags.listImageCreate.map((img, index) => (
                <Image
                  key={index}
                  preview={{
                    mask: (
                      <>
                        <FontAwesomeIcon icon={faEye} />
                        Phóng to
                      </>
                    ),
                  }}
                  style={{
                    height: '140px',
                    objectFit: 'cover',
                    padding: '10px 10px',
                  }}
                  src={`${BASE_URL_IMAGE}${img.path}`}
                  alt=""
                />
              ))}
            </DivScrollStyle>
          ) : (
            VietnameseString.not_update
          )}
        </Descriptions.Item>
      </DescriptionCustomInteractionHistory>

      <ModalStyle
        title="Địa chỉ cụ thể (vị trí khách hàng)"
        visible={visible}
        footer={null}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <div style={{ width: '100%', height: '50vh' }}>
          <GoongMapWeb
            key={modalKey}
            data={[
              {
                longitude: interactionHistory.tags.longitude,
                latitude: interactionHistory.tags.latitude,
                location: interactionHistory.tags.address_sale,
              },
            ]}
          />
        </div>
      </ModalStyle>
    </>
  );
}

LocationReport.propTypes = {
  interactionHistory: PropTypes.object,
};

export default LocationReport;
