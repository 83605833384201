import {
  GET_TELESALE,
  GET_TELESALE_SUCCESS,
  GET_TELESALE_FAIL,
} from '@actions/type';

import reactotron from 'reactotron-react-js';

const initialState = {
  data: {},
  isLoading: false,
  paging: {},
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TELESALE:
      return {
        ...state,
        isLoading: true,
        payload: action.payload,
      };
      break;
    case GET_TELESALE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.response.data,
        paging: action.payload.response.paging,
        error: null,
      };
      break;
    case GET_TELESALE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      return state;
  }
}
