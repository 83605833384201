import { Avatar, Empty, Space, Spin, Tag } from 'antd';
import React from 'react';
import { Col, Modal, Row, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import QRCode from 'qrcode.react';

const CompanyInfoModal = ({ showInfoModal, closeModal }) => {
  // const detail = this.props.userInfoState.data;
  const userInfo = useSelector(state => state.userReducer?.data);
  const statePackageHistory = useSelector(state => state.packageHistoryReducer);

  const packageHistory = statePackageHistory?.data;
  const isLoadingHistory = statePackageHistory?.isLoading;
  return (
    <Modal
      show={showInfoModal}
      size={'xl'}
      scrollable={true}
      onHide={() => closeModal()}
      dialogClassName="modal-90w"
      centered
    >
      <Modal.Header closeButton className="pb-0">
        <h5>Thông tin công ty</h5>
      </Modal.Header>

      <Modal.Body
        className="custom-body"
        style={{ backgroundColor: '#EFF3F9' }}
      >
        <Row xs={12}>
          {/* Thông tin công ty*/}
          <Col lg={4} className="p-0">
            <Col className="bg mb-3 p-4">
              <Row>
                <Col xs={12} className="text-center">
                  {/* <Avatar
                    src={detail.user_enterprise_info?.profile_image}
                    size={144}
                  ></Avatar> */}
                </Col>
              </Row>
              <Row>
                <Col xs={12} class="py-2">
                  <h5 class="text-center">
                    {userInfo.user_enterprise_info?.office_name}
                  </h5>
                </Col>
              </Row>
              <Row className="mt-2 flex-center">
                <Col xs={12} className="p-0">
                  <Tag
                    icon={<i className="far fa-gem mr-2"></i>}
                    color="#FE7A15"
                  >
                    <span style={{ fontSize: '12px' }}>
                      {userInfo?.user_enterprise_info?.package_service?.name ||
                        ''}
                    </span>
                  </Tag>
                </Col>
              </Row>

              <hr class="mb-3"></hr>

              <Row>
                <Col xs={12} className="p-0">
                  <label>Mã công ty</label>:{' '}
                  {userInfo.user_enterprise_info?.code}
                </Col>
              </Row>
              <Row className="text-center">
                <Col xs={12} className="text-center">
                  <QRCode
                    id="qrcode"
                    value={userInfo?.user_enterprise_info?.code}
                    level={'H'}
                    includeMargin={true}
                  />
                </Col>
              </Row>

              <hr class="mb-3"></hr>
              <Row>
                <Col sm={4} lg={12} className="p-0">
                  <label>Số điện thoại:</label>
                </Col>
                <Col xs={8} className="p-0">
                  <p className="p-0">{userInfo?.phone || 'Chưa cập nhật'}</p>
                </Col>
              </Row>

              <Row>
                <Col sm={4} lg={12} className="p-0">
                  <label>Email:</label>
                </Col>
                <Col sm={8} className="p-0">
                  <p className="p-0">{userInfo?.email || 'Chưa cập nhật'}</p>
                </Col>
              </Row>

              <Row>
                <Col sm={4} lg={12} className="p-0">
                  <label>Địa chỉ:</label>
                </Col>
                <Col sm={8} className="p-0">
                  <p className="p-0">
                    {userInfo.user_enterprise_info?.address || 'Chưa cập nhật'}
                  </p>
                </Col>
              </Row>

              <Row>
                <Col sm={4} lg={12} className="p-0">
                  <label>Người đại diện:</label>
                </Col>
                <Col sm={8} className="p-0">
                  <p className="p-0">
                    {userInfo?.full_name || 'Chưa cập nhật'}
                  </p>
                </Col>
              </Row>
            </Col>
          </Col>
          <Col lg={8} className="p-0 pl-lg-3">
            {/* Thông tin công việc */}
            <Col className="bg mb-3 p-4">
              <Row className="pb-2">
                <h5>Thông tin công việc</h5>
              </Row>
              <Row>
                <Col md={6} className="p-0">
                  <Row className="p-0">
                    <Col xs={7} className="p-0">
                      Số công việc:
                    </Col>
                    <Col xs={5} className="p-0">
                      <b>{userInfo?.user_enterprise_info?.countJob || '0'}</b>
                    </Col>
                  </Row>
                  <Row className="p-0">
                    <Col xs={7} className="p-0">
                      Số sale:
                    </Col>
                    <Col xs={5} className="p-0">
                      <b>
                        {userInfo?.user_enterprise_info?.countTelesale || '0'}
                      </b>
                    </Col>
                  </Row>
                  <Row className="p-0">
                    <Col xs={7} className="p-0">
                      Đầu số:
                    </Col>
                    <Col xs={5} className="p-0">
                      <b>
                        {userInfo?.user_enterprise_info?.countHotline || '0'}
                      </b>
                    </Col>
                  </Row>
                </Col>
                <Col md={6} className="p-0">
                  <Row className="p-0">
                    <Col xs={7} className="p-0">
                      Số sale của hệ thống:
                    </Col>
                    <Col xs={5} className="p-0">
                      <b>{'0'}</b>
                    </Col>
                  </Row>
                  <Row className="p-0">
                    <Col xs={7} className="p-0">
                      Số phút gọi còn lại:
                    </Col>
                    <Col xs={5} className="p-0">
                      <b>
                        {parseInt(
                          userInfo?.user_enterprise_info?.call_duration_remain /
                            60
                        ) || '0'}
                      </b>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            {/** Lịch sử giao dịch */}
            <Col className="bg mb-3 p-4">
              <Row className="pb-2">
                <h5>Lịch sử giao dịch</h5>
              </Row>

              <Row>
                <div class="table-responsive">
                  <Table bordered hover>
                    <thead>
                      <tr>
                        <th>STT</th>
                        <th>Tên nhóm</th>
                        <th>Số sale</th>
                        <th>Số công việc</th>
                        <th>Số phút gọi</th>
                        <th>Thời gian</th>
                        <th>Trạng thái</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoadingHistory && (
                        <tr>
                          <td colspan="100%">
                            <Space size="middle">
                              <Spin />
                            </Space>
                          </td>
                        </tr>
                      )}
                      {packageHistory && packageHistory?.length ? (
                        packageHistory?.map((pack, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{pack?.package_name || ''}</td>
                            <td>{pack?.max_telesale || '0'}</td>
                            <td>{pack?.max_job || '0'}</td>
                            <td>{parseInt(pack?.call_duration / 60) || '0'}</td>
                            <td>
                              {Intl.DateTimeFormat('vi-VN').format(
                                new Date(pack?.created_at)
                              ) || null}
                            </td>
                            <td>
                              {pack?.is_active == 1 ? (
                                <Tag color="success">Đang hoạt động</Tag>
                              ) : pack?.is_active == 2 ? (
                                <Tag color="warning">Tạm ngưng</Tag>
                              ) : (
                                <Tag color="error">Ngừng hoạt động</Tag>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <td colspan="7">
                          <Empty description={<span>Không có dữ liệu</span>} />
                        </td>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Row>
              {/* <Row>
    <PaginationComponent paging={paging} handlePageChange={this.props.getPackageHistory({ enterprise_id: id })} />
  </Row> */}
            </Col>
          </Col>
        </Row>
        {/* <Row sm={4} style={{ justifyContent: 'center' }} className="mt-2">
      <Button variant="secondary" className="text-white" onClick={() => this.setShowModalInfo(false)}>
        Đóng
      </Button>
    </Row> */}
      </Modal.Body>
    </Modal>
  );
};

export default CompanyInfoModal;
