import { Button, Modal } from 'antd';
import { default as styled } from 'styled-components';

export const ModalStyled = styled(Modal)`
  .ant-form-item-label label {
    font-weight: 400;
  }
  .multi_media {
    font-weight: 500;
    padding-bottom: 5px;
  }
  .checkbox_form {
    margin-bottom: 10px;
  }
  .ant-form-item-control-input-content label {
    font-weight: 400;
  }
  .upload_image {
    border: 1px solid #eee;
    border-radius: 5px;
    display: flex;
    padding: 7px 0 0 5px;
    width: 100%;
    /* margin-top: -8px; */
  }
  .button_wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    button {
      min-width: 100px;
    }
  }
`;

export const SendSMScontainer = styled.div`
  .flex {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    width: 100%;
    img {
      position: relative;
      top: -5px;
      cursor: pointer;
    }
  }
  .ant-form-item-label {
    text-align: left;
    margin-right: 20px;
  }
`;

export const WrapperPosition = styled.div`
  position: relative;
  button {
    position: absolute;
    background-color: rgb(239, 135, 55);
    color: white;
    top: -5px;
    right: 0;
    padding: 5px 10px;
    margin-right: 3px;
    outline: none;
    border: none;
    border-radius: 5px
  }
`;


export const ButtonStyledBorder = styled(Button) `
   border-radius: 5px 
`