import React from 'react';
import PropTypes from 'prop-types';
import MultiSelectControl from '@components/ui/forms/MultiSelectControl';
import { Row, Col, Button, FormControl } from 'react-bootstrap';
import { notifyFail, notifySuccess } from '@utils/notify';
import { requestFilterList, requestListTag } from '@constants/Api';
import { Select } from 'antd';
import DatePickerCustom from '../../../../components/DatePickerCustom';
import { DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

const { Option } = Select;

class CustomerFilter extends React.Component {
  constructor(props) {
    super(props);
    const { onChange, defaultPayload, provinces = [] } = props;
    this.state = {
      filterSchemas: { data: null, loading: false, error: null },
      filterOptions: props.defaultOptions || {},
      tagOption: [],
    };
  }
  componentDidMount() {
    this.requestData();
    this.getFilterList();
  }
  render() {
    const { onChange, defaultPayload, provinces = [], tags = [] } = this.props;
    const { filterOptions, filterSchemas, tagOption } = this.state;

    return (
      <div className="w-100">
        <Row className="pb-1">
          <Col md={3} className="pb-1 pb-md-0 mt-3">
            <FormControl
              type="text"
              id=""
              placeholder="Tìm kiếm"
              value={filterOptions.search}
              onChange={e => this.onFilterChanged('search', e.target.value)}
            />
          </Col>
          <Col md={3} className="pb-1 pb-md-0 mt-3">
            <MultiSelectControl
              options={provinces}
              value={filterOptions.province_ids}
              onChange={value => this.onFilterChanged('province_ids', value)}
              placeholder={'Chọn thành phố'}
              className="w-100"
            />
          </Col>

          <Col md={3} className="pb-1 pb-md-0 mt-3">
            <MultiSelectControl
              options={tagOption}
              value={filterOptions.tag_ids}
              onChange={value => this.onFilterChanged('tag_ids', value)}
              placeholder={'Chọn thẻ tag'}
              className="w-100"
            />
          </Col>

          {filterSchemas.data?.length > 0 &&
            filterSchemas.data?.map((v, index) => {
              if (this.props.shouldHideSubFilter) {
                if (
                  v.code === 'customer_source' ||
                  v.code === 'customer_type'
                ) {
                  return (
                    <Col md={3} className="pb-1 pb-md-0 mt-3">
                      <MultiSelectControl
                        options={v?.customer_data_field_sources?.map(
                          (customer, index) => ({
                            label: customer.value,
                            value: customer.item_code,
                            data: customer,
                          })
                        )}
                        value={filterOptions[v.code]}
                        onChange={value => this.onFilterChanged(v.code, value)}
                        placeholder={v?.name}
                        className="w-100"
                      />
                    </Col>
                  );
                } else {
                  return <></>;
                }
              } else {
                if (v.data_type === 'text') {
                  return (
                    <Col md={3} className="pb-1 pb-md-0 mt-3">
                      <FormControl
                        type="text"
                        id=""
                        placeholder={v.name}
                        value={filterOptions[v.code]}
                        onChange={e =>
                          this.onFilterChanged(v.code, e.target.value)
                        }
                      />
                    </Col>
                  );
                } else if (v.data_type === 'date_time') {
                  return (
                    <Col md={3} className="pb-1 pb-md-0 mt-3">
                      {/* <DatePickerCustom
                        className={`date-picker form-control`}
                        placeholderText={v.name}
                        handleChange={(value) => {
                          this.onFilterChanged(v.code, value);
                        }}
                        selected={filterOptions[v.code]}
                        dateFormat="dd/MM/yyyy"
                        // maxDate={Date.now()}
                      /> */}
                      <DatePicker
                        style={{ width: '100%' }}
                        placeholder={v.name}
                        value={
                          filterOptions[v.code]
                            ? moment(filterOptions[v.code])
                            : ''
                        }
                        onChange={(date, dateString) => {
                          // this.onFilterChanged(v.code, date?._d.toString() || '');
                          this.onFilterChanged(v.code, dateString || '');
                        }}
                      />
                    </Col>
                  );
                } else if (v.data_type === 'number') {
                  return (
                    <Col md={3} className="pb-1 pb-md-0 mt-3">
                      <FormControl
                        type="number"
                        id=""
                        placeholder={v.name}
                        value={filterOptions[v.code]}
                        onChange={e =>
                          this.onFilterChanged(v.code, e.target.value)
                        }
                      />
                    </Col>
                  );
                } else {
                  return (
                    <Col md={3} className="pb-1 pb-md-0 mt-3">
                      <MultiSelectControl
                        options={v?.customer_data_field_sources?.map(
                          (customer, index) => ({
                            label: customer.value,
                            value: customer.value,
                            data: customer,
                          })
                        )}
                        value={filterOptions[v.code]}
                        onChange={value => this.onFilterChanged(v.code, value)}
                        placeholder={v?.name}
                        className="w-100"
                      />
                    </Col>
                  );
                }
              }
            })}
          <Col md={3} className="pb-1 pb-md-0 mt-3">
            <Select
              options={this.props.sales}
              showSearch
              onChange={value =>
                this.onFilterChanged('telesale_id', value || '')
              }
              placeholder={'Chọn sale'}
              className="w-100"
              allowClear
            />
          </Col>

          {/* Thêm filter từ ngày đến ngày */}
          <Col md={3} className="pb-1 pb-md-0 mt-3">
            <RangePicker
              style={{ width: '100%' }}
              placeholder={['Từ ngày', 'Đến ngày']}
              format={'DD/MM/YYYY'}
              onChange={(date, dateString) => {
                if (date !== null) {
                  this.onFilterChanged(
                    'from_date',
                    moment(date[0]).format('YYYY/MM/DD')
                  );
                  this.onFilterChanged(
                    'to_date',
                    moment(date[1]).format('YYYY/MM/DD')
                  );
                } else {
                  this.onFilterChanged('from_date', '');
                  this.onFilterChanged('to_date', '');
                }
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }
  setFilterSchemas = filterSchemas => {
    this.setState({ filterSchemas }, () => {});
  };

  onFilterChanged = async (field, value) => {
    this.setState(
      ({ filterOptions }) => ({
        filterOptions: { ...filterOptions, [field]: value },
      }),
      () => {
        this.props.onChange(this.state.filterOptions);
      }
    );
  };

  async getFilterList() {
    try {
      const res = await requestListTag({});
      this.setState({
        tagOption:
          res?.data?.map(tag => ({
            title: tag.name,
            label: tag.name,
            value: tag.id,
            key: tag.id,
          })) || [],
      });
    } catch (err) {
      notifyFail(err.msg);
    }
  }
  requestData = () => {
    requestFilterList({ ...this.props.defaultPayload })
      .then(res => {
        this.setFilterSchemas({
          data: res.data,
          loading: false,
          error: res.error,
        });
      })
      .catch(err => notifyFail(err.msg));
  };
}

CustomerFilter.propTypes = {
  onChange: PropTypes.func,
  defaultOptions: PropTypes.object,
  provinces: PropTypes.object,
  tags: PropTypes.object,
  defaultPayload: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};
export default CustomerFilter;
