import { requestCreateJob } from '@constants/Api';
import { TOKEN_MIXPANEL } from '@constants/Constant';
import { formatTime, rmVN } from '@constants/funcHelper';
import { primaryColor } from '@styles/Color';
import { ModalStyle } from '@styles/Style';
import { notifyFail, notifySuccess } from '@utils/notify';
import { Button, Col, Empty, Input, Row, Select, Spin, Typography } from 'antd';
import mixpanel from 'mixpanel-browser';
import React, { useState } from 'react';
const { Title } = Typography;
const { Option } = Select;
const PURCHASE_JOB_NAME = 'Mua bán';
function CreateJobModal(props) {
  const { isVisible, setIsVisible, listTypeOfJob, user } = props;
  const checkTypeJob = listTypeOfJob.find((type) => type.name === PURCHASE_JOB_NAME);
  const [jobName, setJobName] = useState(`Công việc giao nhanh ${formatTime(new Date(), 'DD/MM/YYYY')}`);
  const [typeJobId, setTypeJobId] = useState(checkTypeJob && checkTypeJob.id);
  const [targetJob, setTargetJob] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const createJobExpress = async () => {
    setIsLoading(true);
    let dataPush = {
      name: jobName.trim(),
      job_type_id: typeJobId,
      target: targetJob,
    };
    try {
      const res = await requestCreateJob(dataPush);
      notifySuccess(res?.msg);
      setTimeout(() => {
        setIsVisible(false);
        setIsLoading(false);
      }, 3000);
      mixpanel.track(`Create Job express: ${jobName.trim()}`);
    } catch (error) {
      notifyFail(error?.msg);
      console.log('error', error);
      setIsLoading(false);
    } finally {
    }
  };

  const titleModal = () => {
    return (
      <Title level={4} style={{ color: primaryColor }}>
        Tạo công việc
      </Title>
    );
  };

  const SelectJobBlock = () => {
    return (
      <Select
        style={{ width: '100%' }}
        placeholder="Chọn công việc"
        showSearch
        destroyOnClose
        filterOption={(input, option) => {
          return rmVN(option.children).indexOf(rmVN(input)) >= 0;
        }}
        value={typeJobId}
        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>Không có dữ liệu!</span>} />}
        onChange={(value) => {
          setTypeJobId(value);
        }}
      >
        {listTypeOfJob.map((item, index) => (
          <Option key={index} value={item.id}>
            {item.name}
          </Option>
        ))}
      </Select>
    );
  };

  const GetNameJobBlock = () => {
    return (
      <Row>
        <Col span={8}>Nội dung công việc (*):</Col>
        <Col span={16}>
          <Input
            allowClear
            // value={jobName}
            defaultValue={`Công việc giao nhanh ${formatTime(new Date(), 'DD/MM/YYYY')}`}
            onChange={(e) => {
              setJobName(e.target.value);
            }}
          />
        </Col>
      </Row>
    );
  };

  const GetTargetJobBlock = () => {
    return (
      <Row>
        <Col span={8}>Mục tiêu</Col>
        <Col span={16}>
          <Input.TextArea
            rows={3}
            allowClear
            showCount
            maxLength={256}
            onChange={(e) => {
              setTargetJob(e.target.value);
            }}
          />
        </Col>
      </Row>
    );
  };

  const renderFooterModal = () => {
    return (
      <Row justify="end">
        <Button
          danger
          onClick={() => {
            setIsVisible(false);
          }}
        >
          Huỷ
        </Button>
        <>
          &nbsp;
          <Button
            disabled={isLoading ? true : false}
            style={{ backgroundColor: primaryColor, color: 'white' }}
            onClick={() => {
              if (jobName.trim() && typeJobId) {
                createJobExpress();
              }
              if (!jobName.trim()) {
                notifyFail('Nội dung công việc không được để trống!');
              }
              if (!typeJobId) {
                notifyFail('Vui lòng chọn loại công việc!');
              }
            }}
          >
            Lưu
          </Button>
        </>
      </Row>
    );
  };

  return (
    <ModalStyle
      width={600}
      marginTop={'100px'}
      buttonCloseX={'none'}
      destroyOnClose
      visible={isVisible}
      title={titleModal()}
      footer={renderFooterModal()}
    >
      <Spin spinning={isLoading}>
        {GetNameJobBlock()}
        <br />
        <Row>
          <Col span={8}>Loại công việc (*):</Col>
          <Col span={16}>{SelectJobBlock()}</Col>
        </Row>
        <br />
        {GetTargetJobBlock()}
      </Spin>
    </ModalStyle>
  );
}
export default CreateJobModal;
