import React from 'react';
import { Col, FormControl, Row } from 'react-bootstrap';
import '../style.css';

const SuitableJob = (props) => {
  const { customerDetail, jobList, jobId, onChangeJob } = props;

  return (
    <div style={{ width: '100%', margin: '20px 0 0 0' }}>
      <b>Công việc phù hợp với khách hàng</b>
      <FormControl as="select" value={jobId} onChange={(e) => onChangeJob(e.target.value)}>
        <option value="0">Chọn công việc</option>
        {jobList?.length ? (
          jobList?.map((item) => {
            return (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            );
          })
        ) : (
          <></>
        )}
      </FormControl>
    </div>
  );
};

export default SuitableJob;
