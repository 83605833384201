import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { requestCustomerGroupCreate } from '@constants/Api';
import '@styles/CustomerGroup.css';
import { Row, Col, FormControl, Button, FormGroup, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { notifyFail, notifySuccess } from '@utils/notify';
import { getCustomerList } from '@src/redux/actions';
import { checkAllAttrFilled } from '@utils/checkAllAttrFilled';
import Loading from '@src/components/Loading';
import { CustomerList } from '@screens/Enterprise/Customer/components';
import { ROUTER, STRING, NUMBER } from '@constants/Constant';

class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: {
        name: '',
        description: '',
        customer_ids: [],
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.location.data) {
      this.getPassedId();
    }
  }

  getPassedId() {
    const { data } = this.props.location;
    if (data?.length !== 0) {
      this.setState({
        payload: {
          ...this.state.payload,
          customer_ids: [...data],
        },
      });
    }
  }

  renderTitle() {
    return (
      <>
        <Row className="mt-1" style={{ padding: '0px 19px', borderRadius: '8px', marginBottom: 15 }}>
          <Col md className="bg">
            <Row>
              <Col md={5} sm={12} className="p-0">
                <h2 className="m-0">Tạo nhóm khách hàng</h2>
              </Col>
              <br />
              <Col md={7} sm={12}>
                {this.renderButton()}
              </Col>
            </Row>
            {/* <div className="line"></div> */}
          </Col>
        </Row>
      </>
    );
  }

  renderBody() {
    return (
      <div className="content-wrapper">
        <div className="content-header">
          {this.renderTitle()}
          <Row>
            <Col md={12}>{this.renderForm()}</Col>
          </Row>
          <br />
          <Row>
            <Col md={12}>{this.renderInfo()}</Col>
          </Row>
        </div>
      </div>
    );
  }

  renderField() {
    const { payload } = this.state;
    return (
      <>
        <Row>
          <FormGroup className="">
            <Col sm={12} className="text-left mb-3 px-0">
              <span>Tên nhóm*</span>
              <FormControl
                type="text"
                placeholder="Nhập tên nhóm khách hàng"
                value={payload.name}
                onChange={(e) => this.handleFormChange('name', e.target.value)}
              ></FormControl>
            </Col>

            <Col sm={12} className="text-left px-0">
              <span>Mô tả*</span>
              <Form.Control
                as="textarea"
                placeholder="Nhập mô tả"
                rows="4"
                value={payload.description}
                onChange={(e) => this.handleFormChange('description', e.target.value)}
              />
            </Col>
          </FormGroup>
        </Row>
      </>
    );
  }

  handleFormChange(field, value) {
    this.setState({
      payload: {
        ...this.state.payload,
        [field]: value,
      },
    });
  }

  onSelectedCustomersChanged = (customerIds = []) => {
    this.setState((prevState) => ({ payload: { ...prevState.payload, customer_ids: customerIds } }));
  };

  async createGroup() {
    if (this.validateForm()) {
      try {
        this.setState({ isLoading: true });
        const res = await requestCustomerGroupCreate({ ...this.state.payload });
        notifySuccess('Thêm mới thành công!');
        this.setState(
          {
            isLoading: false,
            payload: {
              name: '',
              description: '',
              customer_ids: [],
            },
          },
          () => {
            this.props.history.push(`${ROUTER.CUSTOMER_GROUP}`);
          }
        );
      } catch (err) {
        this.setState({ isLoading: false });
        notifyFail(err.msg);
      }
    }
  }

  validateForm() {
    const { payload } = this.state;
    var check = checkAllAttrFilled(payload);
    if (!check) {
      notifyFail('Vui lòng điền đầy đủ thông tin');
      return false;
    }
    return true;
  }

  renderForm() {
    return (
      <>
        <Row className="mt-1" style={{ textAlign: 'center', padding: '0px 3px', borderRadius: '8px' }}>
          <Col md className="bg">
            <div>
              <Row>
                <h3> Thông tin chung </h3>
              </Row>
              {/* <div class="line"></div> */}
              <br />
              {this.renderField()}
            </div>
          </Col>
        </Row>
      </>
    );
  }

  renderButton() {
    return (
      <div className="text-center text-md-right">
        <Button variant="primary" className="text-light" onClick={() => this.createGroup()}>
          Lưu
        </Button>
      </div>
    );
  }

  renderInfo() {
    return (
      <>
        <Row style={{ padding: '0px 4px', borderRadius: '8px' }}>
          <Col md className="bg">
            <Row className="w-100 py-3">
              <Col md={12}>
                <Row className="mb-2">
                  <h3>Danh sách khách hàng</h3>
                </Row>
                {/* <div class="line"></div> */}
                <CustomerList
                  payload={{}}
                  resultCountText="Khách hàng phù hợp với kết quả lọc: "
                  showCheckbox={false}
                  onSelectionChanged={this.onSelectedCustomersChanged}
                  shouldHideSubFilter={true}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    return (
      <>
        {this.state.isLoading ? <Loading /> : null}
        {this.renderBody()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  customerState: state.customerReducer,
  userState: state.userReducer,
});

const mapDispatchToProps = {
  getCustomerList,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Add));
