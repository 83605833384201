var gsm7bitChars =
  '@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !"#¤%&\'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà';
var gsm7bitExChar = '^{}\\[~]|€';

export function smsCharCount(message) {
  var gsm7bitUnits = 0;
  var utf16codeUnits = 0;

  for (var i = 0, len = message.length; i < len; i++) {
    if (gsm7bitUnits != null) {
      if (gsm7bitChars.indexOf(message.charAt(i)) > -1) {
        gsm7bitUnits++;
      } else if (gsm7bitExChar.indexOf(message.charAt(i)) > -1) {
        gsm7bitUnits += 2;
      } else {
        gsm7bitUnits = null;
      }
    }
    utf16codeUnits += message.charCodeAt(i) < 0x10000 ? 1 : 2;
  }
  return [gsm7bitUnits, utf16codeUnits];
}
