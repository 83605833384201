import React, { Component } from 'react';


class TableNullData extends Component{
    render(){
        return(
            <tr >
                <td colSpan="100" className="alert alert-info">Không có dữ liệu</td>
            </tr>
        )
    }
}
export default TableNullData