export const getMonth = (date) => {
  let start = new Date(date);
  let MonthAndYear = `Tháng ${
    start.getMonth() + 1 < 10 ? '0' + (start.getMonth() + 1) : start.getMonth() + 1
  }/${start.getFullYear()}`;
  return MonthAndYear;
};

export const getDateOfMonth = (date) => {
  let start = new Date(date);
  let month = start.getMonth() + 1;
  let year = start.getFullYear();
  let time;
  if (month == 1 || month == 3 || month == 5 || month == 7 || month == 8 || month == 10 || month == 12) {
    time = `01/${month}-31/${month}/${year}`;
  } else if (month == 2) {
    time = `01/${month}-28/${month}/${year}`;
  } else {
    time = `01/${month}-30/${month}/${year}`;
  }
  return time;
};
