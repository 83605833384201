import { downloadFile } from '@screens/Enterprise/ElectronicVoucher/utils';
import { Button, Modal, Row, Space, Spin } from 'antd';
import Axios from 'axios';
import React from 'react';
import FileViewer from 'react-file-viewer';
import styled from 'styled-components';

const FileViewerStyled = styled(Row)`
  .pg-viewer-wrapper {
    width: 100% !important;
  }
  .react-grid-Canvas {
    width: 100% !important;
  }
`;

const PreviewTextFile = ({ fileContent }) => {
  return (
    <div>
      <p style={{ maxWidth: '88vw', padding: '10px 0' }}>{fileContent}</p>
    </div>
  );
};

const PreviewFileTxt = ({
  fileCurrent,
  fileViewCurrent,
  fileNameCurrent,
  fileTypeCurrent,
  setFileCurrent,
  setFileViewCurrent,
  setFileNameCurrent,
  setFileTypeCurrent,
}) => {
  const [fileContent, setFileContent] = React.useState(null);

  React.useEffect(() => {
    Axios.get(fileCurrent).then(response => {
      setFileContent(response.data);
    });
  }, [fileCurrent]);

  return (
    <>
      <div
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Modal
          centered
          bodyStyle={{ height: '90vh', padding: '0 10px 0' }}
          width="100vw"
          visible={fileViewCurrent}
          onCancel={() => {
            setFileCurrent('');
            setFileViewCurrent(false);
          }}
          destroyOnClose
          footer={null}
          closable={false}
        >
          <>
            <FileViewerStyled
              justify="center"
              style={{
                maxWidth: '100%',
                height: '94%',
                overflowY: 'scroll',
                overflowX: 'hidden',
              }}
            >
              {fileCurrent &&
                (fileContent ? (
                  <PreviewTextFile fileContent={fileContent} />
                ) : (
                  <p>Loading...</p>
                ))}
            </FileViewerStyled>

            <Row justify="center" style={{ height: '56px' }}>
              <Space>
                <Button
                  onClick={() => {
                    setFileViewCurrent(false);
                    setFileCurrent('');
                  }}
                >
                  Đóng
                </Button>
                {fileNameCurrent && (
                  <Button
                    type="primary"
                    onClick={() => {
                      downloadFile(fileCurrent, fileNameCurrent);
                      setFileCurrent('');
                      setFileViewCurrent(false);
                    }}
                  >
                    Tải xuống
                  </Button>
                )}
              </Space>
            </Row>
          </>
        </Modal>
      </div>
    </>
  );
};

export default React.memo(PreviewFileTxt);
