import { Typography } from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

const { Title } = Typography;

const ZaloOALoginCallback = props => {
  const channel = React.useMemo(() => new BroadcastChannel('zaloLogin'), []);

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    setTimeout(() => {
      channel.postMessage(parsed);
      closeTab();
    }, 100);

    return () => {};
  }, []);
  const closeTab = () => {
    window.opener = null;
    window.open('', '_self');
    window.close();
  };

  return (
    <>
      <Title>Đăng nhập thành công....</Title>
    </>
  );
};

export default ZaloOALoginCallback;
