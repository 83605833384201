import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import {
  Breadcrumb,
  Col,
  Row,
  Select,
  Empty,
  DatePicker,
  Input,
  Radio,
  Table,
  Avatar,
  Spin,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/fontawesome-free-regular';
import locale from 'antd/es/date-picker/locale/vi_VN';

import { VietnameseString } from '@constants/VietnameseString';
import Wrapper from '@components/ui/wrapper/Wrapper';
import TagCustom from '@screens/Enterprise/components/TagCustom';
import { BreadcrumbAntStyle, ButtonSystemStyle } from '@styles/Style';
import { greenColor, greenColorHover, primaryColor } from '@styles/Color';
import { formatPrice, randomColor, rmVN } from '@constants/funcHelper';
import {
  requestCheckCopySetupKPI,
  requestCreateSetupKPI,
  requestGetDetailSetupKPI,
  requestUpdateSetupKPI,
} from './services/api';
import { ROUTER, STATUS } from '@constants/Constant';
import { notifyFail } from '@utils/notify';
import {
  getAllProduct,
  getAllTag,
  getAllTelemarketing,
  handleSetupRawDataDetailKPI,
} from './Utils/func';
import mixpanel from 'mixpanel-browser';

const TitleMoreTargetsStyle = styled.div`
  color: #3a3aff;
  user-select: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const renderTitleMoreTargets = handleMoreTargets => {
  return (
    <TitleMoreTargetsStyle onClick={() => handleMoreTargets()}>
      (+) thêm chỉ tiêu
    </TitleMoreTargetsStyle>
  );
};

export default function CreateEditSetupKPI() {
  const history = useHistory();
  const location = useLocation();

  const [isLoadingButtonSave, setIsLoadingButtonSave] = useState(false);

  const [idSetupKPI, setIdSetupKPI] = useState(undefined);
  const [isUpdate, setIsUpdate] = useState(false);
  const [telemarketingDetail, setTelemarketingDetail] = useState({});

  const [systemKPI, setSystemKPI] = useState([
    {
      key: 'sales',
      name: VietnameseString.sale,
      type: 'price',
      active: STATUS.active,
    },
    {
      key: 'revenue',
      name: VietnameseString.revenue,
      type: 'price',
      active: STATUS.active,
    },
    {
      key: 'opportunity',
      name: VietnameseString.opportunity,
      type: 'quantity',
      active: STATUS.active,
    },
    {
      key: 'contractClosing',
      name: VietnameseString.contract_closing,
      type: 'quantity',
      active: STATUS.active,
    },
    {
      key: 'call',
      name: VietnameseString.call,
      type: 'quantity',
      active: STATUS.active,
    },
    {
      key: 'newCustomer',
      name: 'Số khách hàng tự thêm',
      type: 'quantity',
      active: STATUS.active,
    },
  ]);

  const [dataCreateSetupKPI, setDataCreateSetupKPI] = useState({
    telemarketingId: [],
    effectiveTime: null,
    autoExtend: 0,
    workDay: null,
    call: null,
    contractClosing: null,
    newCustomer: null,
    opportunity: null,
    revenue: null,
    sales: null,
    kpiOther: {
      tags: [],
      products: [],
    },
  });

  const [telemarketing, setTelemarketing] = useState([]);
  const [tags, setTags] = useState([]);
  const [products, setProducts] = useState([]);

  const [tagsSelected, setTagsSelected] = useState([
    { id: undefined, name: undefined, color: undefined, kpi: undefined },
  ]);
  const [productsSelected, setProductsSelected] = useState([
    { id: undefined, name: undefined, kpi: undefined },
  ]);

  const handleChangeDataCreateSetupKPI = (key, value) => {
    setDataCreateSetupKPI({
      ...dataCreateSetupKPI,
      [key]: value !== '' ? value : null,
    });
  };

  const handleMoreTargetsSystemKPI = () => {
    setSystemKPI([
      ...systemKPI,
      {
        key: undefined,
        name: undefined,
        type: undefined,
        active: STATUS.active,
      },
    ]);
  };

  useEffect(() => {
    getAllTelemarketing().then(res => setTelemarketing(res));
    getAllTag().then(res => setTags(res));
    getAllProduct().then(res => setProducts(res));
  }, []);

  const handleRequestCreateEditSetupKPI = async () => {
    try {
      setIsLoadingButtonSave(true);
      if (dataCreateSetupKPI.telemarketingId.length === 0) {
        notifyFail('Cần chọn ít nhất một sale chăm sóc để thiết lập!');
        return;
      }

      if (dataCreateSetupKPI.effectiveTime === null) {
        notifyFail('Cần chọn tháng áp dụng để thiết lập!');
        return;
      }

      if (
        dataCreateSetupKPI.workDay === '' ||
        dataCreateSetupKPI.workDay === null
      ) {
        notifyFail('Cần chọn số ngày làm việc trong tháng để thiết lập!');
        return;
      }

      if (
        parseFloat(dataCreateSetupKPI.workDay) >
        moment(dataCreateSetupKPI.effectiveTime).daysInMonth()
      ) {
        notifyFail('Số ngày làm việc lớn hơn số ngày trong tháng!');
        return;
      }

      if (
        dataCreateSetupKPI.sales === null &&
        dataCreateSetupKPI.revenue === null &&
        dataCreateSetupKPI.opportunity === null &&
        dataCreateSetupKPI.contractClosing === null &&
        dataCreateSetupKPI.call === null &&
        dataCreateSetupKPI.newCustomer === null &&
        [...tagsSelected, ...productsSelected].filter(
          el => el.id !== undefined && el.kpi !== undefined
        ).length === 0
      ) {
        notifyFail('Cần nhập ít nhất một KPI để thiết lập!');
        return;
      }

      const payload = {
        ...dataCreateSetupKPI,
        kpiOther: {
          tags: tagsSelected
            .filter(el => el.id !== undefined && el.kpi !== undefined)
            .map(el => ({
              id: el.id,
              kpi: el?.kpi && el.kpi?.replaceAll(',', ''),
            })),
          products: productsSelected
            .filter(el => el.id !== undefined && el.kpi !== undefined)
            .map(el => ({
              id: el.id,
              kpi: el?.kpi && el.kpi?.replaceAll(',', ''),
            })),
        },
      };

      mixpanel.track(
        `${
          isUpdate ? `Update setup KPI (id: ${idSetupKPI})` : 'Create setup KPI'
        }`,
        { payload }
      );

      if (!isUpdate) {
        await requestCreateSetupKPI(payload);
      } else {
        await requestUpdateSetupKPI({ ...payload, id: idSetupKPI });
      }
      history.goBack();
    } catch (error) {
      notifyFail(error.msg);
    } finally {
      setIsLoadingButtonSave(false);
    }
  };

  const [loading, setLoading] = useState(false);

  const getDetailSetupKPI = async user_sale_kpi_id => {
    try {
      setLoading(true);
      setIsUpdate(true);
      const result = await requestGetDetailSetupKPI(user_sale_kpi_id);

      console.log('result', result);
      const data = handleSetupRawDataDetailKPI(
        systemKPI,
        tags,
        products,
        result
      );

      if (data !== undefined) {
        setDataCreateSetupKPI(data?.detailDataSetupKPI);
        setSystemKPI(data?.newSystemKPI);
        setProductsSelected(data?.productsSelected);
        setTagsSelected(data?.tagsSelected);
        setTelemarketingDetail(data?.telemarketingDetail);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getDataCopySetupKPI = async user_sale_kpi_id => {
    try {
      setLoading(true);
      const result = await requestCheckCopySetupKPI(user_sale_kpi_id);
      if (result.type_obj === 'current') {
        const data = handleSetupRawDataDetailKPI(
          systemKPI,
          tags,
          products,
          result,
          moment(result.data.effective_time).add(1, 'months').toString()
        );

        if (data !== undefined) {
          setDataCreateSetupKPI(data?.detailDataSetupKPI);
          setSystemKPI(data?.newSystemKPI);
          setProductsSelected(data?.productsSelected);
          setTagsSelected(data?.tagsSelected);
          setTelemarketingDetail(data?.telemarketingDetail);
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const param = searchParams.entries();

    if (location.pathname === ROUTER.EDIT_KPI_SETUP) {
      for (let key of param) {
        if (key[0] === 'id') {
          setIdSetupKPI(key[1]);
          getDetailSetupKPI(key[1]);
        }
      }
    }

    if (
      location.pathname === ROUTER.CREATE_KPI_SETUP &&
      location?.state?.action === 'action-copy'
    ) {
      getDataCopySetupKPI(location.state.id);
    }
  }, [products, tags]);

  const columnsSystemKPI = [
    {
      title: VietnameseString.KPI,
      dataIndex: '',
      key: '',
      render: (value, record, index) => {
        return (
          <Select
            style={{ width: '100%' }}
            value={record.key}
            placeholder={VietnameseString.system_KPI}
            onChange={value => {
              if (record.key === undefined) {
                let kpi = [...systemKPI];
                const selectedItem = kpi.find(v => v.key === value);
                const indexSelectedItem = kpi.findIndex(v => v.key === value);
                kpi.splice(indexSelectedItem, 1);
                setSystemKPI([
                  ...kpi.filter(v => v.key !== undefined),
                  { ...selectedItem, active: STATUS.active },
                ]);
              } else {
                let kpi = [...systemKPI];
                const selectedItem = kpi.find(v => v.key === value);
                const indexSelectedItem = kpi.findIndex(v => v.key === value);
                kpi.splice(indexSelectedItem, 1);

                const unSelectedItem = kpi.find(v => v.key === record.key);
                const indexUnSelectedItem = kpi.findIndex(
                  v => v.key === record.key
                );
                kpi.splice(indexUnSelectedItem, 1, {
                  ...selectedItem,
                  active: STATUS.active,
                });

                setSystemKPI([
                  ...kpi.filter(v => v.key !== undefined),
                  { ...unSelectedItem, active: STATUS.un_active },
                ]);
              }
            }}
          >
            {systemKPI
              .filter(
                v =>
                  v.key !== undefined &&
                  (v.active === STATUS.un_active || v.key === record.key)
              )
              .map((item, index) => {
                return (
                  <Select.Option key={index} value={item.key}>
                    {item.name}
                  </Select.Option>
                );
              })}
          </Select>
        );
      },
    },
    {
      title: VietnameseString.targets,
      dataIndex: '',
      key: '',
      render: (value, record, index) => {
        if (record.type === 'quantity') {
          return (
            <Input
              placeholder={VietnameseString.quantity}
              value={formatPrice(dataCreateSetupKPI[record.key])}
              allowClear
              onChange={e =>
                handleChangeDataCreateSetupKPI(
                  record.key,
                  e.target.value.replace(/[^\d,]/g, '')
                )
              }
            />
          );
        }
        if (record.type === 'price') {
          return (
            <Input
              placeholder="đ"
              value={formatPrice(dataCreateSetupKPI[record.key])}
              allowClear
              onChange={e => {
                handleChangeDataCreateSetupKPI(
                  record.key,
                  e.target.value.replace(/[^\d,]/g, '')
                );
              }}
            />
          );
        }
        return (
          <Input
            allowClear
            disabled={record.key === undefined ? true : false}
          />
        );
      },
    },
    {
      title: '',
      width: 50,
      dataIndex: '',
      key: '',
      render: (text, record, index) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <FontAwesomeIcon
            icon={faTrashAlt}
            style={{ color: 'red', fontWeight: 700 }}
            onClick={() => {
              if (record.key !== undefined) {
                const result = systemKPI.map(item => {
                  if (item.key === record.key) {
                    return { ...item, active: STATUS.un_active };
                  } else {
                    return { ...item };
                  }
                });
                handleChangeDataCreateSetupKPI(record.key, null);
                setSystemKPI(result);
              } else {
                setSystemKPI(systemKPI.filter(v => v.key !== undefined));
              }
            }}
          />
        </div>
      ),
    },
  ];

  const handleMoreTargetsOtherKPITags = () => {
    setTagsSelected([
      ...tagsSelected,
      { id: undefined, name: undefined, color: undefined, kpi: undefined },
    ]);
  };

  const handleMoreTargetsOtherKPIProducts = () => {
    setProductsSelected([
      ...productsSelected,
      { id: undefined, name: undefined, kpi: undefined },
    ]);
  };

  const handleRenderOtherKPIItem = (
    title,
    type,
    arrSelected,
    setSelected,
    optionSelect
  ) => {
    console.log('optionSelect', optionSelect);
    return (
      <Row>
        <Col span={4}>
          <span style={{ fontWeight: 'bold' }}>{title}</span>
        </Col>
        <Col span={20}>
          {arrSelected.map((select, index, array) => {
            if (select !== undefined) {
              return (
                <Row
                  key={index}
                  gutter={[16, 16]}
                  style={{ marginBottom: '10px' }}
                >
                  <Col span={10}>
                    {type === 'tag' ? (
                      <Select
                        style={{ width: '100%' }}
                        value={select.id}
                        placeholder={title}
                        onChange={(value, option) => {
                          let selected = [...array];
                          selected.splice(index, 1, {
                            id: value,
                            name: option.children.props.tagName,
                            color: option.children.props.tagColor,
                            kpi: undefined,
                          });
                          setSelected(selected);
                        }}
                      >
                        {[..._.xorBy(optionSelect, array, 'id'), select].map(
                          (tag, idx) => {
                            if (tag?.id !== undefined) {
                              return (
                                <Select.Option key={idx} value={tag.id}>
                                  <TagCustom
                                    tagName={tag.name}
                                    tagColor={tag.color}
                                  />
                                </Select.Option>
                              );
                            } else return;
                          }
                        )}
                      </Select>
                    ) : (
                      <Select
                        style={{ width: '100%' }}
                        value={select.id}
                        placeholder={title}
                        onChange={(value, option) => {
                          let selected = [...array];
                          selected.splice(index, 1, {
                            id: value,
                            name: option.children,
                            kpi: undefined,
                          });
                          setSelected(selected);
                        }}
                      >
                        {[..._.xorBy(optionSelect, array, 'id'), select].map(
                          (pro, idx) => {
                            if (pro?.id !== undefined) {
                              return (
                                <Select.Option key={idx} value={pro.id}>
                                  {pro.name}
                                </Select.Option>
                              );
                            } else return;
                          }
                        )}
                      </Select>
                    )}
                  </Col>
                  <Col span={10}>
                    {select.id !== undefined && type === 'tag' ? (
                      <Input
                        placeholder={VietnameseString.quantity}
                        allowClear
                        value={select.kpi}
                        onChange={event => {
                          let selected = [...array];
                          if (event.target.value !== '') {
                            selected.splice(index, 1, {
                              ...select,
                              kpi: event.target.value.replace(/[^\d,]/g, ''),
                            });
                            setSelected(selected);
                          } else if (event.target.value === '') {
                            selected.splice(index, 1, {
                              ...select,
                              kpi: undefined,
                            });
                            setSelected(selected);
                          }
                        }}
                      />
                    ) : select.id !== undefined && type === 'product' ? (
                      <Input
                        placeholder={'đ'}
                        allowClear
                        value={formatPrice(select.kpi)}
                        onChange={event => {
                          let selected = [...array];
                          if (event.target.value !== '') {
                            selected.splice(index, 1, {
                              ...select,
                              kpi: event.target.value.replace(/[^\d,]/g, ''),
                            });
                            setSelected(selected);
                          } else if (event.target.value === '') {
                            selected.splice(index, 1, {
                              ...select,
                              kpi: undefined,
                            });
                            setSelected(selected);
                          }
                        }}
                      />
                    ) : (
                      <Input allowClear disabled />
                    )}
                  </Col>

                  <Col span={4}>
                    <div style={{ padding: '6px 10px' }}>
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        style={{ color: 'red', fontWeight: 700 }}
                        onClick={() => {
                          setSelected(
                            arrSelected.filter(el => el.id !== select.id)
                          );
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              );
            }
          })}
        </Col>

        <Col span={20} offset={4}>
          {type === 'tag' && (
            <div>{renderTitleMoreTargets(handleMoreTargetsOtherKPITags)}</div>
          )}
          {type === 'product' && (
            <div>
              {renderTitleMoreTargets(handleMoreTargetsOtherKPIProducts)}
            </div>
          )}
        </Col>
      </Row>
    );
  };

  return (
    <div className="content-wrapper">
      <BreadcrumbAntStyle>
        <Breadcrumb.Item>
          <a onClick={() => history.goBack()}>{VietnameseString.KPI}</a>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          {isUpdate
            ? VietnameseString.update_setup_KPI
            : VietnameseString.setup_KPI}
        </Breadcrumb.Item>
      </BreadcrumbAntStyle>

      <Spin spinning={loading}>
        <Wrapper>
          <Row
            style={{ marginBottom: '10px' }}
            align="middle"
            justify="space-between"
          >
            <Col>
              <h2 style={{ fontWeight: 'bold', color: primaryColor }}>
                {isUpdate
                  ? VietnameseString.update_setup_KPI
                  : VietnameseString.setup_KPI}
              </h2>
            </Col>

            <Col>
              <ButtonSystemStyle
                loading={isLoadingButtonSave}
                bgButtonColor={greenColor}
                bgButtonColorHover={greenColorHover}
                onClick={handleRequestCreateEditSetupKPI}
              >
                {VietnameseString.save}
              </ButtonSystemStyle>
            </Col>
          </Row>

          <Row style={{ marginBottom: '10px' }} align="middle">
            {!isUpdate ? (
              <>
                <Col xl={4} xs={12}>
                  {VietnameseString.list_of_applicable_sales}
                </Col>

                <Col xl={8} xs={12}>
                  <Select
                    style={{ width: '100%' }}
                    mode="multiple"
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return rmVN(option.children).indexOf(rmVN(input)) >= 0;
                    }}
                    placeholder={VietnameseString.telemarketing}
                    allowClear
                    maxTagCount={2}
                    value={dataCreateSetupKPI.telemarketingId}
                    notFoundContent={
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={<span>{VietnameseString.no_data}</span>}
                      />
                    }
                    onChange={value =>
                      handleChangeDataCreateSetupKPI('telemarketingId', value)
                    }
                  >
                    {telemarketing.map((item, index) => (
                      <Select.Option key={index} value={item.id}>
                        {item.full_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              </>
            ) : (
              <>
                <Col xl={4} xs={12}>
                  {VietnameseString.telemarketing}
                </Col>

                <Col xl={8} xs={12}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                      {telemarketingDetail?.full_name && (
                        <Avatar
                          src={
                            telemarketingDetail.profile_image &&
                            telemarketingDetail.profile_image
                          }
                          style={{
                            backgroundColor: randomColor(
                              telemarketingDetail?.full_name
                                .trim()
                                .split(' ')
                                [
                                  telemarketingDetail?.full_name
                                    .trim()
                                    .split(' ').length - 1
                                ].charAt(0)
                                ?.toUpperCase()
                            ),
                            marginRight: '5px',
                          }}
                          size="small"
                        >
                          {telemarketingDetail?.full_name
                            .trim()
                            .split(' ')
                            [
                              telemarketingDetail?.full_name.trim().split(' ')
                                .length - 1
                            ].charAt(0)
                            ?.toUpperCase()}
                        </Avatar>
                      )}
                    </div>

                    <div>
                      <span>
                        {telemarketingDetail?.full_name
                          ? telemarketingDetail?.full_name
                          : '--'}
                      </span>
                    </div>
                  </div>
                </Col>
              </>
            )}
          </Row>

          <Row style={{ marginBottom: '10px' }} align="middle">
            <Col xl={4} xs={12}>
              {VietnameseString.month_of_application}
            </Col>

            <Col xl={8} xs={12}>
              <DatePicker
                disabled={isUpdate ? true : false}
                allowClear={false}
                style={{ width: '100%' }}
                format={'MM/YYYY'}
                locale={locale}
                value={
                  dataCreateSetupKPI.effectiveTime !== null &&
                  moment(dataCreateSetupKPI.effectiveTime)
                }
                disabledDate={currentDate =>
                  currentDate && currentDate < moment().startOf('month')
                }
                onChange={(date, dateString) =>
                  handleChangeDataCreateSetupKPI(
                    'effectiveTime',
                    moment(date).startOf('month').format('YYYY/MM/DD')
                  )
                }
                picker="month"
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: '10px' }} align="middle">
            <Col xl={4} xs={12}>
              {VietnameseString.working_days_of_the_month}
            </Col>

            <Col xl={8} xs={12}>
              <Input
                min={1}
                value={dataCreateSetupKPI.workDay}
                placeholder={VietnameseString.working_days_of_the_month}
                onChange={event =>
                  handleChangeDataCreateSetupKPI(
                    'workDay',
                    event.target.value.replace(/[^\d,]/g, '')
                  )
                }
              />
            </Col>
          </Row>

          {!isUpdate && (
            <Row style={{ marginBottom: '10px' }} align="middle">
              <Col xl={4} xs={12}>
                {VietnameseString.autoRenew_for_next_month}
              </Col>

              <Col xl={8} xs={12}>
                <Radio.Group
                  value={dataCreateSetupKPI.autoExtend}
                  onChange={event =>
                    handleChangeDataCreateSetupKPI(
                      'autoExtend',
                      event.target.value
                    )
                  }
                >
                  <Radio value={1}>{VietnameseString.yes}</Radio>
                  <Radio value={0}>{VietnameseString.no}</Radio>
                </Radio.Group>
              </Col>
            </Row>
          )}

          <Row gutter={[32, 32]} style={{ marginBottom: '10px' }}>
            <Col xxl={10} xl={12} sm={24} xs={24}>
              <Row style={{ marginBottom: '10px' }} align="middle">
                <Col span={24}>
                  <h5 style={{ fontWeight: 'bold', color: primaryColor }}>
                    {VietnameseString.system_KPI}
                  </h5>
                </Col>
              </Row>

              <Row align="middle">
                <Col span={24}>
                  <Table
                    bordered={true}
                    columns={columnsSystemKPI}
                    dataSource={systemKPI.filter(
                      v => v.active === STATUS.active
                    )}
                    scroll={{ x: 'max-content' }}
                    pagination={false}
                  />
                </Col>
              </Row>

              {systemKPI.filter(v => v.active === STATUS.active).length < 6 && (
                <Row align="middle">
                  <Col>
                    {renderTitleMoreTargets(handleMoreTargetsSystemKPI)}
                  </Col>
                </Row>
              )}
            </Col>

            <Col xxl={14} xl={12} sm={24} xs={24}>
              <Row style={{ marginTop: '10px' }} align="middle">
                <Col>
                  <h5 style={{ fontWeight: 'bold', color: primaryColor }}>
                    {VietnameseString.other_KPI}
                  </h5>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Row style={{ fontWeight: 'bold' }}>
                    <Col span={4}>
                      <span
                        style={{ display: 'inline-block', padding: '16px' }}
                      >
                        {VietnameseString.KPI}
                      </span>
                    </Col>
                    <Col span={8}>
                      <span
                        style={{ display: 'inline-block', padding: '16px' }}
                      >
                        {VietnameseString.targets_name}
                      </span>
                    </Col>
                    <Col span={8}>
                      <span
                        style={{ display: 'inline-block', padding: '16px' }}
                      >
                        {VietnameseString.targets}
                      </span>
                    </Col>
                    <Col span={4}></Col>
                  </Row>
                </Col>

                <Col
                  gutter={[16, 16]}
                  span={24}
                  style={{ marginBottom: '10px' }}
                >
                  {handleRenderOtherKPIItem(
                    VietnameseString.classify,
                    'tag',
                    tagsSelected,
                    setTagsSelected,
                    tags
                  )}
                  <div style={{ marginBottom: '20px' }}></div>
                  {handleRenderOtherKPIItem(
                    VietnameseString.product,
                    'product',
                    productsSelected,
                    setProductsSelected,
                    products
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Wrapper>
      </Spin>
    </div>
  );
}
