import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '@styles/Account.css';
import Table from 'react-bootstrap/Table';
import { Row, Col, FormControl, Button, Modal, FormGroup, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getAccountList, createAccount, getProvinceList } from '@src/redux/actions';
import { checkNormalizePhone } from '@utils/stringUtils';
import { ROLE_AVAILABLE, PHONE_REGEX, EMAIL_REGEX, STRING } from '@constants/Constant';
import { getItemFromId } from '@utils/getItemFromId';
import { renderSelection } from '@utils/renderSelection';
import Loading from '@src/components/Loading';
import {
  requestCreateAccount,
  requestResetPassword,
  requestGetReviewerDetail,
  requestDeleteAccount,
  requestUpdateAccountEnterprise,
  getListRole,
} from '@constants/Api';
import { notifyFail, notifySuccess } from '@utils/notify';
import TableNullData from '@components/TableNullData';
import Pagination from 'react-js-pagination';
import DatePickerCustom from '../../../components/DatePickerCustom';
import moment from 'moment';
import { Input, Space, Empty } from 'antd';
class Screen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalAdd: false,
      modalEdit: false,
      modalDelete: false,
      modalAddField: {
        name: '',
        phone: '',
        email: '',
        role: '',
        password: '',
        repassword: '',
        [STRING.dob]: '',
        gender: '',
        address: '',
        province_id: '',
      },
      modalEditField: {
        name: '',
        phone: '',
        email: '',
        role: '',
        password: '',
        repassword: '',
        [STRING.dob]: '',
        gender: '',
        address: '',
        province_id: '',
        status: '',
        id: '',
      },
      activePage: 1,
      isLoading: false,
      search: '',
      role_id: '',
      page: '',
      list_role: [],
      isClick: false,
    };
  }

  componentDidMount() {
    this.getData({ page: 1 });
    this.props.getProvinceList();
    this.getListRole();
  }

  getData({ page, search, role_id }) {
    this.props.getAccountList({
      page: page || '',
      search: search || '',
      role_id: role_id || '',
    });
  }

  async getListRole() {
    try {
      const res = await getListRole();
      this.setState({ list_role: res.data });
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  async requestGetReviewerDetail(id) {
    try {
      const res = await requestGetReviewerDetail({ id: id });
      this.setState({
        ...this.state,
        modalEditField: {
          name: res.data?.full_name,
          phone: res.data?.user_name,
          email: res.data?.email,
          role: res.data?.role_id,
          // password: '',
          // repassword: '',
          [STRING.dob]: Date.parse(res.data?.dob),
          gender: res.data?.gender,
          address: res.data?.address,
          province_id: res.data?.province_id,
          status: res.data?.status,
          id: id,
        },
      });
    } catch (err) {
      notifyFail(err);
    }
  }

  async requestUpdateAccountEnterprise() {
    const { modalEditField } = this.state;
    const { [STRING.dob]: dob } = this.state.modalEditField;
    this.setState({
      ...this.state,
      isLoading: true,
      isClick: true,
    });
    try {
      await requestUpdateAccountEnterprise({
        full_name: modalEditField.name,
        user_name: modalEditField.phone,
        is_active: modalEditField.status,
        // password: '',
        email: modalEditField.email,
        role_id: modalEditField.role,
        province_id: modalEditField.province_id,
        address: modalEditField.address,
        dob: dob,
        gender: modalEditField.gender,
        user_id: modalEditField.id,
      });
      this.setState({ ...this.state, modalEdit: false, isClick: false, isLoading: false }, () =>
        this.getData({ page: 1 })
      );
      notifySuccess('Cập nhật thành công');
    } catch (err) {
      this.setState({ ...this.state, modalEdit: false, isClick: false, isLoading: false }, () => notifyFail(err.msg));
    }
  }

  renderTitle() {
    return (
      <>
        <Row>
          <Col md={5} sm={12}>
            <h2 className="text-left" style={{ marginBottom: '10px' }}>
              {' '}
              Tài khoản
            </h2>
            <div class="line"></div>
          </Col>
          <br />
          <Col md={7} sm={12}>
            {this.renderButton()}
          </Col>
        </Row>
      </>
    );
  }

  renderBody() {
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <Row
            className="mt-1"
            style={{ textAlign: 'center', padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}
          >
            <Col md className="bg">
              {this.renderTitle()}
              {this.renderField()}
            </Col>
          </Row>

          <Row className="mt-1" style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 30 }}>
            <Col md className="bg">
              <Row>
                <Col md={12}>{this.renderTable()}</Col>
              </Row>
              <Row>
                <Col md={12} className="d-flex justify-content-center justify-content-md-end">
                  {this.renderPagination()}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderButton() {
    return (
      <div className="text-center text-md-right">
        <Button variant="success" className="text-light" onClick={() => this.setShowModal('modalAdd', true)}>
          Thêm tài khoản
        </Button>
      </div>
    );
  }

  renderField() {
    const { search, role_id, list_role } = this.state;
    return (
      <div className="w-100">
        <Row>
          <Col md={6} sm={12}>
            <FormControl
              type="text"
              id=""
              placeholder="Tên hoặc số điện thoại tài khoản"
              value={search}
              onChange={(e) => this.searchBarChange('search', e.target.value)}
              onKeyPress={(e) => this.handleKeyPress(e)}
            />
          </Col>
          <Col md={6} sm={12}>
            <FormControl
              as="select"
              id=""
              value={role_id}
              onChange={(e) => this.searchBarChange('role_id', e.target.value)}
            >
              <option value="">-- Loại tài khoản --</option>
              {renderSelection(list_role)}
            </FormControl>
          </Col>
        </Row>
      </div>
    );
  }

  renderTable() {
    const accountList = this.props.accountState?.data?.data;
    const pageNumber = this.props.accountState?.data?.paging?.page;
    const itemPerNumber = this.props.accountState?.data?.paging?.limit;
    const err = this.props.accountState?.data?.error;
    const itemsCount = this.props.accountState?.data?.paging?.totalItemCount;
    if (err) {
      notifyFail(err?.msg);
    }
    return (
      <>
        <div className="w-100 table-wrap">
          <span className="pb-2 d-inline-block">
            Kết quả lọc: <b className="text-danger">{itemsCount}</b>
          </span>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>STT</th>
                <th>Họ và tên</th>
                <th>Số điện thoại</th>
                <th>Emai</th>
                <th>Loại tài khoản</th>
                <th>Ngày tạo</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {accountList && accountList.length ? (
                accountList.map((account, index) =>
                  this.renderTableRow(account, index + 1 + (pageNumber - 1) * itemPerNumber)
                )
              ) : (
                <tr>
                  <td colSpan="100">
                    <Empty description={<span>Không có dữ liệu</span>} />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </>
    );
  }

  renderTableRow(account, index) {
    const role = getItemFromId(ROLE_AVAILABLE, account.role_id);
    console.log(' account.role_id', account.role_id);
    return (
      <tr>
        <td>{index}</td>
        <td>{account.full_name || '###'}</td>
        <td>{account.phone || '###'}</td>
        <td>{account.email || '###'}</td>
        <td>{role && role.name}</td>
        <td>{Intl.DateTimeFormat('vi-VN').format(new Date(account.created_at)) || '###'}</td>
        <td>
          <i
            className="btnEdit fa fa-fw fa-edit"
            onClick={() => {
              this.setShowModal('modalEdit', true, account);
              this.requestGetReviewerDetail(account.id);
            }}
          />
          <i
            className="btnDelete far fa-trash-alt"
            onClick={() => {
              this.setShowModal('modalDelete', true, account);
            }}
          />
        </td>
      </tr>
    );
  }

  setShowModal = (modal, bool, data) => {
    this.setState({
      [modal]: bool,
    });
    console.log(data);
    if (data) {
      this.setState({
        modalEditField: {
          id: data.id,
        },
      });
    }
  };

  clearModal = () => {
    this.setState({
      modalAddField: {
        name: '',
        phone: '',
        email: '',
        role: '',
        password: '',
        repassword: '',
      },
    });
  };

  handleInputChange = (field, value) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [field]: value,
      },
    });
    console.log(this.state.form);
  };

  addModal = () => {
    const { modalAdd, modalAddField, list_role, isClick } = this.state;
    console.log(list_role, 'list_role');
    const { [STRING.dob]: dob } = this.state.modalAddField;
    const province = this.props.provinceState.data;
    return (
      <Modal
        show={modalAdd}
        onHide={() => {
          this.setShowModal('modalAdd', false);
          this.clearModal();
        }}
        dialogClassName="modal-90w"
        centered
        size="lg"
      >
        <Modal.Header closeButton className="bg-blue pb-0">
          <h5 className="text-light pb-0">Thêm tài khoản</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          <FormGroup className="add-company-form">
            <Row>
              <Col md={6} className="tele-input-col">
                <label htmlFor="name">Tên nhân viên(*)</label>
                <FormControl
                  type="text"
                  placeholder="Nhập tên người dùng"
                  value={modalAddField.name}
                  onChange={(e) => this.addFieldChange('name', e.target.value)}
                ></FormControl>

                <label htmlFor="phone">Số điện thoại(*)</label>
                <FormControl
                  type="text"
                  placeholder="Nhập số điện thoại"
                  value={modalAddField.phone}
                  onChange={(e) => this.addFieldChange('phone', e.target.value)}
                ></FormControl>

                <label htmlFor="email">Email(*)</label>
                <FormControl
                  type="text"
                  placeholder="Nhập email"
                  value={modalAddField.email}
                  onChange={(e) => this.addFieldChange('email', e.target.value)}
                ></FormControl>

                <label htmlFor="email">Ngày sinh</label>
                <DatePickerCustom
                  className={`date-picker form-control`}
                  placeholderText={STRING.dob}
                  handleChange={this.addFieldChange}
                  selected={dob}
                  dateFormat="dd/MM/yyyy"
                  maxDate={Date.now()}
                />

                <br />
                <label htmlFor="gender">Giới tính</label>
                <Form.Check
                  inline
                  type="radio"
                  label="Nam"
                  name="gender"
                  className="radio-button offset-2 tele-radio"
                  value="1"
                  checked={modalAddField.gender == 1}
                  onChange={(e) => this.addFieldChange('gender', e.target.value)}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Nữ"
                  name="gender"
                  className="radio-button tele-radio"
                  value="0"
                  checked={modalAddField.gender == 0}
                  onChange={(e) => this.addFieldChange('gender', e.target.value)}
                />
                <br />
              </Col>
              <Col md={6} className="tele-input-col">
                <Row>
                  <Col xs={12} className="tele-input-col">
                    <label htmlFor="city">Tỉnh - Thành phố</label>
                    <FormControl
                      as="select"
                      id="province"
                      value={modalAddField.province_id}
                      onChange={(e) => this.addFieldChange('province_id', e.target.value)}
                    >
                      <option value="" selected disabled>
                        Tỉnh/Thành phố
                      </option>
                      {province && province.length
                        ? province?.map((value, index) => <option value={value?.id}>{value?.name}</option>)
                        : ''}
                    </FormControl>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="tele-input-col">
                    <label htmlFor="address ">Địa chỉ cụ thể</label>
                    <FormControl
                      type="text"
                      name="address"
                      placeholder="Nhập địa chỉ cụ thể"
                      value={modalAddField.address}
                      onChange={(e) => this.addFieldChange('address', e.target.value)}
                    ></FormControl>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="tele-input-col">
                    <label htmlFor="city">Loại tài khoản (*)</label>
                    <FormControl
                      value=""
                      as="select"
                      id=""
                      value={modalAddField.role}
                      onChange={(e) => this.addFieldChange('role', e.target.value)}
                    >
                      <option value="" disabled selected>
                        -- Loại tài khoản --
                      </option>
                      {renderSelection(list_role)}
                    </FormControl>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="tele-input-col">
                    <Row>
                      <label htmlFor="address ">Mật khẩu (*)</label>
                    </Row>
                    <Row>
                      <Col md={12} className="m-0 p-0">
                        <Space
                          direction="vertical"
                          name="password"
                          autoComplete="username email"
                          onChange={(e) => this.addFieldChange('password', e.target.value)}
                          style={{ width: '100%' }}
                        >
                          <Input.Password placeholder="Nhập mật khẩu" value={modalAddField.password} />
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={12} className="tele-input-col">
                    <Row>
                      <label htmlFor="address ">Xác nhận mật khẩu (*)</label>
                    </Row>
                    <Row>
                      <Col md={12} className="m-0 p-0">
                        <Space
                          direction="vertical"
                          name="repassword"
                          onChange={(e) => this.addFieldChange('repassword', e.target.value)}
                          style={{ width: '100%' }}
                        >
                          <Input.Password placeholder="Nhập lại mật khẩu" value={modalAddField.repassword} />
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormGroup>
          <Row>
            {/* <Col sm={8}></Col> */}
            <Col style={{ color: 'gray', textAlign: 'end' }}>
              <div>
                <i>* Quy tắc đặt mật khẩu</i>
              </div>
              <div>
                <i> - Mật khẩu dài 6 ký tự</i>
              </div>
              {/* <i>- Mật khẩu bao gồm chữ hoa, chữ thường, ký tự số, ký tự đặc biệt</i> */}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="success" className="text-light" disabled={isClick} onClick={() => this.createAccount()}>
            Thêm mới
          </Button>
          <Button
            variant="danger"
            className="text-light"
            onClick={() => {
              this.setShowModal('modalAdd', false);
              this.clearModal();
            }}
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  editModal = () => {
    const { modalEdit, modalEditField, list_role, isClick } = this.state;
    const { [STRING.dob]: dob } = this.state.modalEditField;
    const province = this.props.provinceState.data;
    return (
      <Modal
        show={modalEdit}
        onHide={() => this.setShowModal('modalEdit', false)}
        dialogClassName="modal-90w"
        centered
        scrollable
        size="lg"
      >
        <Modal.Header closeButton className="bg-primary pb-0">
          <h5 className="text-light pb-0">Sửa tài khoản</h5>
        </Modal.Header>

        <Modal.Body className="custom-body">
          <FormGroup className="add-company-form">
            <Row>
              <Col md={6} className="tele-input-col">
                <label htmlFor="name">Tên nhân viên(*)</label>
                <FormControl
                  type="text"
                  placeholder="Nhập tên người dùng"
                  value={modalEditField.name}
                  onChange={(e) => this.editFieldChange('name', e.target.value)}
                ></FormControl>
                <label htmlFor="phone">Số điện thoại(*)</label>
                <FormControl
                  type="text"
                  placeholder="Nhập số điện thoại"
                  value={modalEditField.phone}
                  disabled
                ></FormControl>
                <label htmlFor="email">Email(*)</label>
                <FormControl
                  type="text"
                  placeholder="Nhập email"
                  value={modalEditField.email}
                  onChange={(e) => this.editFieldChange('email', e.target.value)}
                ></FormControl>
                <label htmlFor="email">Ngày sinh</label>
                <DatePickerCustom
                  className={`date-picker form-control`}
                  placeholderText={STRING.dob}
                  handleChange={this.editFieldChange}
                  selected={dob}
                  dateFormat="dd/MM/yyyy"
                  maxDate={Date.now()}
                />
                <br />
                <label htmlFor="gender">Giới tính</label>
                <Form.Check
                  inline
                  type="radio"
                  label="Nam"
                  name="gender"
                  className="radio-button offset-2 tele-radio"
                  checked={modalEditField.gender == 1}
                  value="1"
                  onChange={(e) => this.editFieldChange('gender', e.target.value)}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Nữ"
                  name="gender"
                  className="radio-button tele-radio"
                  checked={modalEditField.gender == 0}
                  value="0"
                  onChange={(e) => this.editFieldChange('gender', e.target.value)}
                />
                <br />
              </Col>
              <Col md={6} className="tele-input-col">
                <Row>
                  <Col xs={12} className="tele-input-col">
                    <label htmlFor="city">Tỉnh - Thành phố</label>
                    <FormControl
                      as="select"
                      id=""
                      value={modalEditField.province_id}
                      onChange={(e) => this.editFieldChange('province_id', e.target.value)}
                    >
                      <option value="" selected disabled>
                        Tỉnh/Thành phố
                      </option>
                      {province && province.length
                        ? province?.map((value, index) => <option value={value?.id}>{value?.name}</option>)
                        : ''}
                    </FormControl>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="tele-input-col">
                    <label htmlFor="address ">Địa chỉ cụ thể</label>
                    <FormControl
                      type="text"
                      name="address"
                      placeholder="Nhập địa chỉ cụ thể"
                      value={modalEditField.address}
                      onChange={(e) => this.editFieldChange('address', e.target.value)}
                    ></FormControl>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="tele-input-col">
                    <label htmlFor="role">Loại tài khoản</label>
                    <FormControl
                      disabled
                      value=""
                      as="select"
                      id=""
                      value={modalEditField.role}
                      onChange={(e) => this.editFieldChange('role', e.target.value)}
                    >
                      <option value="0">-- Loại tài khoản --</option>
                      {renderSelection(list_role)}
                      {/* {renderSelection(ROLE_AVAILABLE)} */}
                    </FormControl>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="tele-input-col">
                    <label htmlFor="city">Trạng thái</label>
                    <FormControl
                      as="select"
                      id=""
                      onChange={(e) => this.editFieldChange('status', e.target.value)}
                      value={modalEditField.status}
                    >
                      <option value="">Trạng thái</option>
                      <option value="1">Đang hoạt động</option>
                      <option value="2">Ngừng hoạt động</option>
                    </FormControl>
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="dark" className="text-light btn-oversize" onClick={() => this.resetPassword()}>
            Reset mật khẩu
          </Button>
          <Button
            variant="success"
            className="text-light"
            disabled={isClick}
            onClick={() => this.requestUpdateAccountEnterprise()}
          >
            {/* <Button variant="success" className="text-light"> */}
            Lưu
          </Button>
          <Button variant="danger" className="text-light" onClick={() => this.setShowModal('modalEdit', false)}>
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  deleteModal = () => {
    const { modalDelete, modalEditField } = this.state;
    return (
      <Modal
        show={modalDelete}
        onHide={() => this.setShowModal('modalDelete', false)}
        dialogClassName="modal-90w"
        centered
        scrollable
      >
        <Modal.Header closeButton className="text-white bg-primary pb-0">
          <h5 className="text-light pb-0">Bạn chắc chắn muốn xóa?</h5>
        </Modal.Header>
        <Modal.Footer className="justify-content-end">
          <Button
            variant="success"
            className="text-light btn-oversize"
            onClick={() => this.requestDeleteAccount(modalEditField.id)}
          >
            Có
          </Button>
          <Button
            variant="danger"
            className="text-light btn-oversize"
            onClick={() => this.setShowModal('modalDelete', false)}
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  addFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalAddField: {
        ...this.state.modalAddField,
        [field]: value,
      },
    });
  };

  editFieldChange = (field, value) => {
    this.setState({
      ...this.state,
      modalEditField: {
        ...this.state.modalEditField,
        [field]: value,
      },
    });
  };

  searchBarChange = async (field, value) => {
    this.setState(
      {
        ...this.state,
        [field]: value,
      },
      () => {
        if (field !== 'search') {
          const { search, role_id, page } = this.state;
          this.getData({
            search: search || '',
            role_id: role_id || '',
            page: page || '',
          });
        }
      }
    );
  };

  handleKeyPress = (e) => {
    let { search, role_id, page } = this.state;
    if (e.charCode === 13) {
      search = search || '';
      page = 1;
      role_id = role_id || '';
      this.getData({
        search,
        page,
        role_id,
      });
    }
  };

  async createAccount() {
    const { [STRING.dob]: dob } = this.state.modalAddField;
    let formatedDob = dob ? moment(dob).format('YYYY-MM-DD 00:00:00') : '';
    const { modalAddField } = this.state;
    const check = this.validateForm();

    if (check) {
      this.setState({ ...this.state, isLoading: true, isClick: true });
      const payload = {
        full_name: modalAddField.name,
        user_name: modalAddField.phone,
        password: modalAddField.password,
        email: modalAddField.email || '',
        role_id: modalAddField.role,
        dob: formatedDob,
        gender: modalAddField.gender || '0',
        address: modalAddField.address,
        province_id: modalAddField.province_id,
      };

      try {
        const res = await requestCreateAccount(payload);

        this.props.getAccountList();
        Object.keys(this.state.modalAddField).forEach((k) =>
          this.setState({
            modalAddField: {
              ...this.state.modalAddField,
              [k]: '',
            },
          })
        );

        this.setState({ ...this.state, isLoading: false, isClick: false });
        this.setShowModal('modalAdd', false);
        notifySuccess('Thêm tài khoản thành công');
      } catch (err) {
        this.setState({ ...this.state, isLoading: false, isClick: false });
        notifyFail(err.msg);
      }
    }
    return;
  }

  async requestDeleteAccount(id) {
    try {
      await requestDeleteAccount({ id: id });
      this.setState({ modalDelete: false }, () => notifySuccess('Xóa thành công'));
      this.getData({ page: 1 });
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  validateForm() {
    let { modalAddField } = this.state;
    // for (var key in modalAddField) {
    //   if (!modalAddField[key] || modalAddField[key] === "0") {
    //     notifyFail("Vui lòng điền đầy đủ thông tin");
    //     return false;
    //   }
    // }
    if (!modalAddField.name || !modalAddField.phone || !modalAddField.password || !modalAddField.role) {
      notifyFail('Vui lòng điền đầy đủ các thông tin bắt buộc');
      return false;
    }
    // reactotron.log("phone", modalAddField.phone)
    if (checkNormalizePhone(modalAddField.phone)) {
      notifyFail('Số điện thoại không hợp lệ');
      return false;
    }
    if (modalAddField.email && EMAIL_REGEX.exec(modalAddField.email) === null) {
      notifyFail('Email không hợp lệ');
      return false;
    }

    if (modalAddField.password !== modalAddField.repassword) {
      notifyFail('Mật khẩu không khớp');
      return false;
    }

    if (modalAddField.password.length < 6) {
      notifyFail('Mật khẩu phải có tối thiểu 6 ký tự.');
      return false;
    }
    // var uppercase = 0,
    //   lowercase = 0,
    //   specialChar = 0,
    //   number = 0;
    // var specialCharList = '<>@!#$%^&*()_+[]{}?:;|\'"\\,./~`-=';
    // for (var i = 0; i < modalAddField.password.length; i++) {
    //   if (modalAddField.password[i].charCodeAt() >= 65 && modalAddField.password[i].charCodeAt() <= 90) {
    //     uppercase = 1;
    //   }
    //   if (modalAddField.password[i].charCodeAt() >= 97 && modalAddField.password[i].charCodeAt() <= 122) {
    //     lowercase = 1;
    //   }
    //   if (modalAddField.password[i].charCodeAt() >= 48 && modalAddField.password[i].charCodeAt() <= 57) {
    //     number = 1;
    //   }
    //   if (specialCharList.indexOf(modalAddField.password[i]) !== -1) {
    //     specialChar = 1;
    //   }
    // }
    // if (!(uppercase && lowercase && specialChar && number)) {
    //   notifyFail('Mật khẩu chưa đủ mạnh');
    //   return false;
    // }
    return true;
  }

  renderPagination() {
    const itemsCount = this.props.accountState?.data?.paging?.totalItemCount;
    const limit = this.props.accountState?.data?.paging?.limit;
    const { search, role_id } = this.state;
    return (
      <Pagination
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled
        activePage={this.state.activePage}
        itemsCountPerPage={limit}
        totalItemsCount={itemsCount}
        pageRangeDisplayed={5}
        hideNavigation
        onChange={(page) => {
          this.setState({
            ...this.state,
            activePage: page,
          });
          this.getData({ page, search, role_id });
        }}
      />
    );
  }

  async resetPassword() {
    const { modalEditField } = this.state;
    if (modalEditField.email.length < 12) {
      notifyFail('Tài khoản không có email khả dụng');
      return;
    }
    try {
      this.setState({ isLoading: true });
      const res = await requestResetPassword({ id: modalEditField.id });
      this.setState({ isLoading: false });
      notifySuccess('Mật khẩu mới đã được vửi về email');
    } catch (err) {
      this.setState({ isLoading: false });
      notifyFail(err.msg);
    }
  }

  render() {
    const { isLoading, error } = this.props.accountState;
    let loading = <Loading />;
    if (!isLoading) {
      loading = null;
    }
    if (error) {
      notifyFail(error.msg);
    }
    return (
      <>
        {this.state.isLoading ? <Loading /> : null}
        {loading}
        {this.addModal()}
        {this.editModal()}
        {this.deleteModal()}
        {this.renderBody()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  accountState: state.AccountReducer,
  provinceState: state.ProvinceReducer,
});

const mapDispatchToProps = {
  getAccountList,
  createAccount,
  getProvinceList,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Screen));
