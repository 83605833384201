import { handleResult, getAxios, toQuerystringFromObject } from '@constants/Api';

export const requestGetJobDetailAllTags = (payload) => {
  return handleResult(getAxios.get(`sale/getTag?search&page=1&limit=9999`, payload));
};

export const requestAddCustomerTags = (payload) => {
  return handleResult(getAxios.post('sale/addTag', payload));
};

export const requestCreateOpportunity = (payload) => {
  return handleResult(getAxios.post('call/createOpportunity', payload));
};

export const requestGetOpportunities = (payload) => {
  const convertedParams = toQuerystringFromObject(payload);
  return handleResult(getAxios.get(`filter/opportunitys?${convertedParams}`), payload);
};

export const requestGetProducts = (payload) => {
  const convertedParams = toQuerystringFromObject(payload);
  return handleResult(getAxios.get(`filter/product?${convertedParams}`), payload);
};

export const requestSubmitForm = (payload) => {
  return handleResult(getAxios.post('call/submitForm', payload));
};

export const requestSubmitIncomingCall = (payload) => {
  return handleResult(getAxios.post('call/submitIncomingCall', payload));
};

export const requestGetCustomerDetailInboundCall = (payload) => {
  return handleResult(
    getAxios.get(
      `call/getCustomerDetailInboundCall?hotline=${payload.hotline}&customer_phone=${payload.customer_phone}&customer_id=${payload.customer_id}&enterprise_id=${payload.enterprise_id}`,
      payload
    )
  );
};
