import React, { Component } from 'react';
// import { requestGetUserInfo } from '@constants/Api';
import { withRouter, Link } from 'react-router-dom';
import '@styles/RegisterRequest.css';
import { Row, Col, Button, Nav, Modal } from 'react-bootstrap';
import { ROUTER } from '@constants/Constant';
import {
  requestCallHistoryDetail,
  requestCreateCallReview,
  requestCallReviewConfig,
  requestReviewList,
} from '@constants/Api';
import { notifyFail, notifySuccess } from '@utils/notify';
import { getListCallStatus } from '@src/redux/actions';
import { connect } from 'react-redux';
import reactotron from 'src/ReactotronConfig';
import Loading from '@src/components/Loading';
import { Checkbox } from 'antd';
class CallDetailScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableInfo: true,
      detail: {},
      form: {
        call_id: '',
        options: [],
      },
      formAdd: '',
      reviews: {},
      play: false,
      CallReview: {},
      modal: false,
      listStatus: [],
      listResult: [],
    };
  }

  componentDidMount() {
    this.getData();
    this.props.getListCallStatus();
    this.callReview();
    this.requestReviewList();
  }

  async getData() {
    const { id } = this.props.match?.params;
    this.setState({
      ...this.state,
      isLoading: true,
    });
    try {
      const detail = await requestCallHistoryDetail({ id: id });
      this.setState({
        ...this.state,
        detail: detail.data,
        isLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          ...this.state,
          isLoading: false,
        },
        () => notifyFail(err.msg)
      );
    }
  }

  async callReview() {
    const { id } = this.props.match?.params;
    try {
      const callReview = await requestCallReviewConfig({ call_id: id });
      this.setState({
        CallReview: callReview.data,
      });
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  async requestReviewList() {
    try {
      const res = await requestReviewList();
      this.setState({
        ...this.state,
        listStatus: res?.data,
      });
    } catch (err) {
      notifyFail(err.msg);
    }
  }

  handleChangeFormAdd(field, value) {
    this.setState({
      [field]: value,
    });
  }

  setShowModal = (field, value) => {
    this.setState({
      ...this.state,
      [field]: value,
    });
  };

  refreshPage() {
    window.location.reload(false);
  }

  async createReview() {
    const { form, listResult } = this.state;
    const { id } = this.props.match.params;
    const payload = {
      call_id: id,
      options: listResult,
    };
    try {
      const res = await requestCreateCallReview(payload);
      // this.refreshPage();
      this.setState(
        {
          ...this.state,
          modal: false,
        },
        () => notifySuccess('Đánh giá cuộc gọi thành công')
      );
      this.getData();
    } catch (err) {
      this.setState({ isLoading: false });
      notifyFail(err.msg);
    }
  }

  handleChange = date => {
    this.setState({
      startDate: date,
    });
  };

  onChangeResult = checkedValues => {
    console.log(checkedValues);
    this.setState({
      ...this.state,
      listResult: checkedValues,
    });
  };

  renderBody() {
    return (
      <div className="content-wrapper request-body">
        {/* Content Header (Page header) */}
        {this.renderInfo()}
        {this.renderWorkInfo()}
        {this.modalStatus()}
      </div>
    );
  }

  renderHeaderButton() {
    return (
      <Row className="justify-content-center justify-content-md-end">
        <Button variant="danger" className="header-btn mr-2">
          Xóa
        </Button>
        <Link to={ROUTER.TELESALE_EDIT}>
          <Button variant="warning" className="header-btn">
            Sửa
          </Button>
        </Link>
      </Row>
    );
  }

  renderInfo() {
    const call = this.state.detail;
    return (
      <div className="content-header ">
        <Row
          className="mt-1"
          style={{ padding: '0px 20px', borderRadius: '8px', marginBottom: 10 }}
        >
          <Col md className="bg">
            <Row>
              <h2>Chi tiết cuộc gọi</h2>
            </Row>
            {/* <div class="line"></div> */}
            <Row className="title">
              <Col md={6} sm={6}>
                <Row>
                  <Col md={12}>
                    <b>Thông tin khách hàng </b>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4} sm={4} xs={4}>
                    Họ tên
                  </Col>
                  <Col md={8} sm={8} xs={8}>
                    {call?.customer_name}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4} sm={4} xs={4}>
                    Số điện thoại
                  </Col>
                  <Col md={8} sm={8} xs={8}>
                    {call?.customer_phone}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4} sm={4} xs={4}>
                    Tỉnh/thành phố
                  </Col>
                  <Col md={8} sm={8} xs={8}>
                    {call?.province_name}
                  </Col>
                </Row>
              </Col>
              <Col md={6} sm={6}>
                <Row>
                  <Col md={12}>
                    <b>Thông tin cuộc gọi </b>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4} sm={4} xs={4}>
                    Sale
                  </Col>
                  <Col md={8} sm={8} xs={8}>
                    {call?.sale_name}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4} sm={4} xs={4}>
                    Công việc
                  </Col>
                  <Col md={8} sm={8} xs={8}>
                    {call?.job_name}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4} sm={4} xs={4}>
                    Thời gian
                  </Col>
                  <Col md={8} sm={8} xs={8}>
                    <span>
                      {' '}
                      {Intl.DateTimeFormat('vi-VN').format(
                        new Date(call?.start_call_time || null)
                      )}
                    </span>{' '}
                    | <b>{call?.call_duration || '--'} giây</b>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4} sm={4} xs={4}>
                    Trạng thái
                  </Col>
                  <Col
                    md={8}
                    sm={8}
                    xs={8}
                    className={
                      call?.status_name == 'Không nghe máy'
                        ? 'text-danger'
                        : 'text-success'
                    }
                  >
                    {call?.status_name}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }

  modalStatus = () => {
    const { modal, listStatus } = this.state;
    return (
      <Modal
        show={modal}
        onHide={() => this.setShowModal('modal', false)}
        dialogClassName="modal-90w"
        centered
        scrollable
        size="lg"
      >
        <Modal.Header closeButton className="bg-primary">
          <h5 className="text-light pb-0">Đánh giá cuộc gọi</h5>
        </Modal.Header>

        <Modal.Body className="custom-body" style={{ margin: 'auto' }}>
          <Checkbox.Group
            style={{ width: '100%' }}
            onChange={e => this.onChangeResult(e)}
          >
            {listStatus?.length > 0 &&
              listStatus?.map(value => (
                <Row>
                  <Checkbox value={value.id} className="mt-1">
                    {value?.name}
                  </Checkbox>
                </Row>
              ))}
          </Checkbox.Group>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="success"
            className="text-light"
            onClick={() => this.createReview()}
          >
            Đánh giá
          </Button>
          <Button
            variant="danger"
            className="text-light"
            onClick={() => this.setShowModal('modal', false)}
          >
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderWorkInfo() {
    const { tableInfo, detail } = this.state;
    return (
      <>
        <Row
          className="mt-1"
          style={{
            padding: '0px 20px',
            borderRadius: '8px',
            paddingBottom: 30,
          }}
        >
          <Col md className="bg">
            <Row>
              <h2>Kết quả cuộc gọi</h2>
            </Row>
            {/* <div class="line"></div> */}

            <Row className="content-header table-tabs pl-0 pb-0">
              <Col md={12}>
                <Nav variant="tabs" defaultActiveKey="scenario">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="scenario"
                      onSelect={() => this.showTableInfo(1)}
                      className="tab-item"
                    >
                      Kịch bản
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="general-info"
                      onSelect={() => this.showTableInfo(2)}
                      className="tab-item"
                    >
                      Dữ liệu thu thập
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="call-history"
                      onSelect={() => this.showTableInfo(3)}
                      className="tab-item"
                    >
                      Ghi âm cuộc gọi
                    </Nav.Link>
                  </Nav.Item>
                  {detail?.call_reviews?.length > 0 ? (
                    <Nav.Item>
                      <Nav.Link
                        eventKey="rate"
                        onSelect={() => this.showTableInfo(4)}
                        className="tab-item"
                      >
                        Đánh giá
                      </Nav.Link>
                    </Nav.Item>
                  ) : (
                    ''
                  )}
                </Nav>
              </Col>
            </Row>
            <Row className="bg-white">
              <Col
                md={12}
                className="bg-white pt-4"
                style={{ minHeight: '700px' }}
              >
                {tableInfo == 1
                  ? this.renderScenario()
                  : tableInfo == 2
                  ? this.renderGeneralInfo()
                  : tableInfo == 3
                  ? this.renderCallHistory()
                  : this.renderRate()}
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  renderScenario() {
    const call = this.state.detail;
    return (
      <>
        <Row className="mt-2">
          <Col md={12}>
            <h4>Kịch bản</h4>
          </Col>
        </Row>
        {call.job?.scenario?.scenario_steps?.length > 0 ? (
          call.job?.scenario?.scenario_steps?.map((value, index) => (
            <>
              <Row className="mt-3" key={index}>
                <Col md={12} xs={12} sm={12}>
                  <strong>
                    Bước {index + 1}: {value.name}
                  </strong>
                </Col>
              </Row>
              <Row className="mt-3" key={index}>
                <Col md={12} xs={12} sm={12}>
                  <p>
                    {/* <i className="fal fa-angle-right"></i>{' '} */}
                    <span style={{ whiteSpace: 'pre-wrap' }}>
                      {value?.content}
                    </span>
                  </p>
                </Col>
              </Row>
            </>
          ))
        ) : (
          <Row className="mt-2">
            <Col md={12} xs={12} sm={12}>
              <span className="text-muted">Không có kịch bản</span>
            </Col>
          </Row>
        )}
        <Row className="mt-3">
          <Col md={12}>
            <h4>Mục tiêu</h4>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={12} xs={12} sm={12}>
            {call.job?.target ? (
              <div dangerouslySetInnerHTML={{ __html: call.job?.target }}></div>
            ) : (
              <span className="text-muted">Không có mục tiêu</span>
            )}
          </Col>
        </Row>

        {call?.call_reviews?.length > 0 ? (
          ''
        ) : (
          <Row className="mt-4">
            <Col md={12} className="d-flex justify-content-center">
              <Button
                variant="success"
                className="text-light"
                onClick={() => this.setShowModal('modal', true)}
              >
                Đánh giá
              </Button>
            </Col>
          </Row>
        )}
      </>
    );
  }

  renderGeneralInfo() {
    const call = this.state.detail;
    const callStatus = this.props.statusState.data;
    return (
      <>
        <Row>
          {/* <Col md={12}>
            <h6>
              <b>Phiếu kết quả</b>
            </h6>
          </Col> */}
          <Col md={1}>
            <h6>
              <b>Gọi lại?</b>
            </h6>
          </Col>
          <Col md={6}>
            <p> Có | 9h30</p>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={6} sm={12} xs={12} style={styles.questionContainer}>
            <Row>
              <b>Trạng thái cuộc gọi (*)</b>
            </Row>
            {callStatus.length > 0 &&
              callStatus?.map((value, index) => (
                <Row className="mt-3" key={index}>
                  <Col md={12} sm={12} xs={12} className="p-0">
                    {/* <Button as="input" type="radio" value={value.id} checked={value.id == call?.status}/> */}
                    <input
                      style={styles.answerRadioMargin}
                      type="radio"
                      name=""
                      value={value.id}
                      checked={value.id === call?.status}
                    />
                    <span>{value.name}</span>
                  </Col>
                </Row>
              ))}
            {call.default_forms?.length > 0 &&
              call.default_forms?.map((value, index) => (
                <Col style={styles.questionContainer}>
                  <Row>
                    <b>{value.name}</b>
                  </Row>
                  {value.type_input == 1 ? (
                    <Row>
                      {value?.text_result ? (
                        <Col
                          md={8}
                          className="mt-3"
                          style={{
                            border: '1px solid #979090d9',
                            backgroundColor: '#dbdbdc',
                            borderRadius: '5px',
                          }}
                        >
                          {value?.text_result}
                        </Col>
                      ) : (
                        <span className="text-muted">Không có dữ liệu</span>
                      )}
                    </Row>
                  ) : value.type_input == 4 ? (
                    <Row>
                      <Col
                        md={8}
                        className="mt-3"
                        style={{
                          border: '1px solid #979090d9',
                          backgroundColor: '#dbdbdc',
                          borderRadius: '5px',
                        }}
                      >
                        {Intl.DateTimeFormat('vi-VN').format(
                          new Date(value?.text_result || null)
                        )}
                      </Col>
                    </Row>
                  ) : (
                    value.job_form_answers.length > 0 &&
                    value.job_form_answers?.map((item, index) => (
                      <>
                        <Row className="mt-3" key={index}>
                          <Col md={12} sm={12} xs={12} className="p-0">
                            <input
                              type="radio"
                              style={styles.answerRadioMargin}
                              name=""
                              value={item.id}
                              // checked={value.number_result?.filter((e) => e == index + 1).length > 0 ? true : false}
                              checked={value.number_result?.includes(item.id)}
                            />
                            <span>{item.name}</span>
                          </Col>
                        </Row>
                        <Row className="pl-4">
                          {item?.job_form_answer_child?.length > 0 &&
                            item?.job_form_answer_child?.map((v, i) => (
                              <Col style={styles.childQuestionContainer}>
                                <Row>
                                  <b>{v.name}</b>
                                </Row>
                                {v.type_input == 1 ? (
                                  <Row>
                                    {v?.text_result ? (
                                      <Col
                                        md={7}
                                        className="mt-3"
                                        style={{
                                          border: '1px solid #979090d9',
                                          backgroundColor: '#dbdbdc',
                                          borderRadius: '5px',
                                        }}
                                      >
                                        {v?.text_result}
                                      </Col>
                                    ) : (
                                      <span className="text-muted">
                                        Không có dữ liệu
                                      </span>
                                    )}
                                  </Row>
                                ) : v.type_input == 4 ? (
                                  <Row>
                                    <Col
                                      md={8}
                                      className="mt-3"
                                      style={{
                                        border: '1px solid #979090d9',
                                        backgroundColor: '#dbdbdc',
                                        borderRadius: '5px',
                                      }}
                                    >
                                      {Intl.DateTimeFormat('vi-VN').format(
                                        new Date(v?.text_result || null)
                                      )}
                                    </Col>
                                  </Row>
                                ) : (
                                  v.job_form_answers?.length > 0 &&
                                  v.job_form_answers?.map((item, index) => (
                                    <>
                                      <Row className="mt-3" key={index}>
                                        <Col
                                          md={1}
                                          sm={1}
                                          xs={1}
                                          className="p-0"
                                        >
                                          <input
                                            style={styles.answerRadioMargin}
                                            type="radio"
                                            name=""
                                            value={item.id}
                                            // checked={
                                            //   value.number_result?.filter((e) => e == index + 1).length > 0
                                            //     ? true
                                            //     : false
                                            // }
                                            checked={value.number_result?.includes(
                                              item?.id
                                            )}
                                          />
                                        </Col>
                                        <Col
                                          md={11}
                                          sm={11}
                                          xs={11}
                                          className="p-0"
                                        >
                                          {item.name}
                                        </Col>
                                      </Row>
                                    </>
                                  ))
                                )}
                              </Col>
                            ))}
                        </Row>
                      </>
                    ))
                  )}
                </Col>
              ))}
          </Col>
          <Col md={6} sm={12} xs={12}>
            {call.job_form_questions?.length > 0 &&
              call.job_form_questions?.map((value, index) => (
                <Col style={styles.questionContainer}>
                  <Row>
                    <b>{value.name}</b>
                  </Row>
                  {value.type_input == 1 ? (
                    <Row>
                      {value?.text_result ? (
                        <Col
                          md={8}
                          className="mt-3"
                          style={{
                            border: '1px solid #979090d9',
                            backgroundColor: '#dbdbdc',
                            borderRadius: '5px',
                          }}
                        >
                          {value?.text_result}
                        </Col>
                      ) : (
                        <span className="text-muted">Không có dữ liệu</span>
                      )}
                    </Row>
                  ) : value.type_input == 4 ? (
                    <Row>
                      <Col
                        md={8}
                        className="mt-3"
                        style={{
                          border: '1px solid #979090d9',
                          backgroundColor: '#dbdbdc',
                          borderRadius: '5px',
                        }}
                      >
                        {Intl.DateTimeFormat('vi-VN').format(
                          new Date(value?.text_result || null)
                        )}
                      </Col>
                    </Row>
                  ) : (
                    value.job_form_answers.length > 0 &&
                    value.job_form_answers?.map((item, index) => (
                      <>
                        <Row className="mt-3" key={index}>
                          <Col md={12} sm={12} xs={12} className="p-0">
                            <input
                              style={styles.answerRadioMargin}
                              type="radio"
                              name=""
                              value={item.id}
                              // checked={value.number_result?.filter((e) => e == index + 1).length > 0 ? true : false}
                              checked={value.number_result?.includes(item?.id)}
                            />
                            <span>{item.name}</span>
                          </Col>
                          {/* <Col md={11} sm={11} xs={11} className="p-0"></Col> */}
                        </Row>
                        <Row className="pl-4">
                          {item?.job_form_answer_child?.length > 0 &&
                            item?.job_form_answer_child?.map((v, i) => (
                              <Col style={styles.childQuestionContainer}>
                                <Row>
                                  <b>{v.name}</b>
                                </Row>
                                {v.type_input == 1 ? (
                                  <Row>
                                    {v?.text_result ? (
                                      <Col
                                        md={7}
                                        className="mt-3"
                                        style={{
                                          border: '1px solid #979090d9',
                                          backgroundColor: '#dbdbdc',
                                          borderRadius: '5px',
                                        }}
                                      >
                                        {v?.text_result}
                                      </Col>
                                    ) : (
                                      <span className="text-muted">
                                        Không có dữ liệu
                                      </span>
                                    )}
                                  </Row>
                                ) : v.type_input == 4 ? (
                                  <Row>
                                    <Col
                                      md={8}
                                      className="mt-3"
                                      style={{
                                        border: '1px solid #979090d9',
                                        backgroundColor: '#dbdbdc',
                                        borderRadius: '5px',
                                      }}
                                    >
                                      {Intl.DateTimeFormat('vi-VN').format(
                                        new Date(v?.text_result || null)
                                      )}
                                    </Col>
                                  </Row>
                                ) : (
                                  v.job_form_answers?.length > 0 &&
                                  v.job_form_answers?.map((item, index) => (
                                    <>
                                      <Row className="mt-3" key={index}>
                                        <Col
                                          md={1}
                                          sm={1}
                                          xs={1}
                                          className="p-0"
                                        >
                                          <input
                                            style={styles.answerRadioMargin}
                                            type="radio"
                                            name=""
                                            value={item.id}
                                            // checked={
                                            //   value.number_result?.filter((e) => e == index + 1).length > 0
                                            //     ? true
                                            //     : false
                                            // }
                                            checked={value.number_result?.includes(
                                              item?.id
                                            )}
                                          />
                                        </Col>
                                        <Col
                                          md={11}
                                          sm={11}
                                          xs={11}
                                          className="p-0"
                                        >
                                          {item.name}
                                        </Col>
                                      </Row>
                                    </>
                                  ))
                                )}
                              </Col>
                            ))}
                        </Row>
                      </>
                    ))
                  )}
                </Col>
              ))}
            <Col className="mt-3" style={styles.questionContainer}>
              <Row>
                <b>Ghi chú</b>
              </Row>
              <Row>
                {call?.note ? (
                  <Col
                    md={8}
                    className="mt-3"
                    style={{
                      border: '1px solid #979090d9',
                      backgroundColor: '#dbdbdc',
                      borderRadius: '5px',
                    }}
                  >
                    {call?.note}
                  </Col>
                ) : (
                  <span className="text-muted">Không có ghi chú nào</span>
                )}
              </Row>
            </Col>
          </Col>
        </Row>
      </>
    );
  }

  playAudio() {
    const audioEl = document.getElementsByClassName('audio-element')[0];
    audioEl.play();
  }
  renderCallHistory() {
    const call = this.state.detail;
    return (
      <>
        <Row className="mt-2">
          <Col md={12}>
            <b>File ghi âm</b>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={6} xs={6} sm={6}>
            <audio controls autoplay className="audio-element">
              <source src={call?.content_audio_url}></source>
            </audio>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <b>Chuyển đổi văn bản</b>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={12} xs={12} sm={12}>
            <p>{call?.content_text || 'Không có dữ liệu'}</p>
          </Col>
        </Row>
      </>
    );
  }

  renderRate() {
    const call = this.state.detail;
    const callReview = call?.call_reviews || [];
    const callReviewConfigs = this.state.CallReview;
    const reviewMappings = {};
    callReview?.length > 0 &&
      callReview.forEach(v => (reviewMappings[v.id] = v));
    return (
      <>
        <Row>
          <Col md={6} xs={6} sm={6}>
            <Row md={12} className="mb-2 ml-3">
              <b>Kết quả đánh giá</b>
            </Row>
          </Col>
        </Row>
        {callReview?.length > 0 ? (
          (callReviewConfigs || callReview)?.map((value, index) => (
            <Row>
              <Col md={12} xs={12} sm={12}>
                <Row md={12} className="mt-2">
                  <Col md={12} sm={12} xs={12}>
                    <input
                      type="checkbox"
                      name=""
                      value={value.id}
                      checked={reviewMappings[value.id]}
                      readonly
                      onChange={e =>
                        this.handleChangeFormAdd('options', e.target.value)
                      }
                    />
                    <span className="ml-2">{value.name}</span>
                  </Col>
                </Row>
              </Col>
            </Row>
          ))
        ) : (
          <Row className="mt-2">
            <Col md={12} sm={12} xs={12}>
              <p className="text-muted">Chưa có đánh giá</p>
            </Col>
          </Row>
        )}
      </>
    );
  }

  addDescriptionChange = (check, id) => {
    let new_arr = [];
    if (check == true) {
      this.state.form.options.push(id);
    } else if (check == false) {
      let index = this.state.form.options.indexOf(id);
      this.state.form.options.splice(index, 1);
    }
    console.log(this.state.form.options);
  };

  showTableInfo(status) {
    this.setState({
      ...this.state,
      tableInfo: status,
    });
  }

  searchBarChange = (field, value) => {
    this.setState({
      ...this.state,
      searchBar: {
        ...this.state.searchBar,
        [field]: value,
      },
    });
  };

  render() {
    // const { isLoading } = this.props.statusState;
    const { isLoading } = this.state;
    let loading = <Loading />;
    if (!isLoading) {
      loading = null;
    }
    return (
      <>
        {/* {isLoading ? <Loading /> : null} */}
        {loading}
        {this.renderBody()}
      </>
    );
  }
}

const styles = {
  questionContainer: {
    marginTop: 10,
    marginBottom: 20,
  },
  answerRadioMargin: {
    marginRight: 10,
  },
  childQuestionContainer: {
    marginTop: 5,
    marginBottom: 5,
  },
};

const mapStateToProps = state => ({
  statusState: state.callStatusReducer,
});

const mapDispatchToProps = {
  getListCallStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CallDetailScreen));
