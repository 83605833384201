import React, { useCallback, useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import {
  Breadcrumb,
  Col,
  DatePicker,
  Descriptions,
  Empty,
  Input,
  message as messageAntd,
  Pagination,
  Row,
  Select,
  Skeleton,
} from 'antd';

import { formatTime, getSearchParamsInUrl } from '@constants/funcHelper';
import { VietnameseString } from '@constants/VietnameseString';
import Wrapper from '@components/ui/wrapper/Wrapper';
import AddTemplate from './components/AddTemplate';
import { IS_APPROVED } from '@constants/Constant';
import Template from './components/Template';
import {
  requestGetNumberPrefixInformation,
  requestGetTemplatesMessage,
  requestUpdateTemplateMessage,
} from './services/api';

import { greenColor, greenColorHover, primaryColor } from '@styles/Color';
import { BreadcrumbAntStyle, ButtonSystemStyle } from '@styles/Style';

const { RangePicker } = DatePicker;
const { Option } = Select;

const Heading1Styled = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  color: ${primaryColor};
`;

const Heading2Styled = styled.h2`
  font-size: 1.5rem;
  color: ${primaryColor};
`;

const DescriptionsStyled = styled(Descriptions)`
  .ant-descriptions-item-label {
    font-weight: bold;
  }
`;

const ResultStyled = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

function SMSConfigurationDetail({ isCheckEvoucher }) {
  const history = useHistory();

  const [visible, setVisible] = useState(false);

  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 12,
  });
  const [param, setParam] = useState({
    id: '',
    search: '',
    isApproved: '',
    fromDate: '',
    toDate: '',
    page: paging.current,
    limit: paging.pageSize,
  });

  const [loading, setLoading] = useState({
    information: false,
    templatesMessage: false,
  });
  const [numberPrefixInformation, setNumberPrefixInformation] = useState({
    account: undefined,
    typeOfSMS: {
      id: undefined,
      name: undefined,
      code: undefined,
    },
    numberPrefix: undefined,
    partner: undefined,
    brandName: undefined,
    createdAt: undefined,
    enterpriseId: undefined,
  });
  const [templatesMessage, setTemplatesMessage] = useState([]);

  const handleChangeParamSearchTemplateMessage = useCallback(
    _.debounce(data => {
      setParam(prev => ({ ...prev, ...data }));
    }, 500),
    []
  );

  const getNumberPrefixInformation = useCallback(async id => {
    try {
      setLoading(prev => ({ ...prev, information: true }));
      const { data } = await requestGetNumberPrefixInformation({
        numberPrefixId: id,
      });

      const {
        account,
        brand_name,
        created_at,
        partner_name,
        phone,
        df_sms_type,
        enterprise_id,
      } = data;
      setNumberPrefixInformation(prev => ({
        ...prev,
        account,
        typeOfSMS: {
          id: df_sms_type.id,
          name: df_sms_type.name,
          code: df_sms_type.code,
        },
        numberPrefix: phone,
        partner: partner_name,
        brandName: brand_name,
        createdAt: created_at,
        enterpriseId: enterprise_id,
      }));

      setLoading(prev => ({ ...prev, information: false }));
    } catch (error) {
      console.log(' Error getNumberPrefixInformation: ', { error });
    }
  }, []);

  const getTemplatesMessage = useCallback(async param => {
    try {
      const { id, search, isApproved, fromDate, toDate, page, limit } = param;

      setLoading(prev => ({ ...prev, templatesMessage: true }));

      const { data, paging } = await requestGetTemplatesMessage({
        numberPrefixId: id,
        search,
        isApproved,
        fromDate,
        toDate,
        page,
        limit,
      });
      setTemplatesMessage(data);
      setPaging({
        total: Number(paging.count),
        current: Number(paging.page),
        pageSize: Number(paging.limit),
      });
    } catch (error) {
      console.log(' Error getTemplatesMessage: ', { error });
    } finally {
      setLoading(prev => ({ ...prev, templatesMessage: false }));
    }
  }, []);

  useEffect(() => {
    const searchParamsInUrl = getSearchParamsInUrl();
    setParam({ ...param, ...searchParamsInUrl });
  }, []);

  useEffect(() => {
    console.log('id:::::::::', param.id)
    if (param.id) getNumberPrefixInformation(param.id);
  }, [param.id]);

  useEffect(() => {
    if (param.id) getTemplatesMessage(param);
  }, [param]);

  return (
    <div className="content-wrapper">
      <BreadcrumbAntStyle>
        <Breadcrumb.Item>
          <a href="#!" onClick={() => history.goBack()}>
            {VietnameseString.company_detail}
          </a>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          {VietnameseString.sms_configuration_detail}
        </Breadcrumb.Item>
      </BreadcrumbAntStyle>

      <Wrapper>
        <Heading1Styled>
          {VietnameseString.sms_configuration_detail}
        </Heading1Styled>

        {loading.information ? (
          <Skeleton active loading />
        ) : (
          <DescriptionsStyled
            column={{ xxl: 5, xl: 3, lg: 3, md: 3, sm: 2, xs: 2 }}
          >
            <Descriptions.Item label={VietnameseString.account}>
              {numberPrefixInformation.account}
            </Descriptions.Item>
            <Descriptions.Item label={VietnameseString.sms_type}>
              {numberPrefixInformation.typeOfSMS.name}
            </Descriptions.Item>
            <Descriptions.Item label={VietnameseString.number_prefix}>
              {numberPrefixInformation.numberPrefix || '--'}
            </Descriptions.Item>
            <Descriptions.Item label={VietnameseString.partner}>
              {numberPrefixInformation.partner}
            </Descriptions.Item>
            <Descriptions.Item label={VietnameseString.brand_name}>
              {numberPrefixInformation.brandName}
            </Descriptions.Item>
            <Descriptions.Item label={VietnameseString.dateCreated}>
              {formatTime(numberPrefixInformation.createdAt, 'DD/MM/YYYY')}
            </Descriptions.Item>
          </DescriptionsStyled>
        )}

        <Heading2Styled>{VietnameseString.list_of_templates}</Heading2Styled>

        <div>
          <Row gutter={[16, 16]}>
            <Col xxl={6}>
              <Input.Search
                placeholder={VietnameseString.message_content}
                allowClear
                onChange={event =>
                  handleChangeParamSearchTemplateMessage({
                    search: event.target.value,
                  })
                }
              />
            </Col>

            <Col xxl={6}>
              <Select
                style={{ width: '100%' }}
                placeholder={VietnameseString.status}
                allowClear
                onChange={value => {
                  let isApproved;

                  if (value !== undefined) {
                    isApproved = value;
                  } else {
                    isApproved = '';
                  }

                  handleChangeParamSearchTemplateMessage({ isApproved });
                }}
              >
                <Option value={IS_APPROVED.YES}>
                  {VietnameseString.approved}
                </Option>
                <Option value={IS_APPROVED.NO}>
                  {VietnameseString.not_approved_yet}
                </Option>
              </Select>
            </Col>

            <Col xxl={6}>
              <RangePicker
                allowClear
                onChange={(date, dateStrings) => {
                  handleChangeParamSearchTemplateMessage({
                    fromDate: dateStrings[0],
                    toDate: dateStrings[1],
                  });
                }}
              />
            </Col>
          </Row>
        </div>

        <ResultStyled>
          {VietnameseString.result}: {paging.total}
          <ButtonSystemStyle
            bgButtonColor={greenColor}
            bgButtonColorHover={greenColorHover}
            onClick={() => {
              setVisible(true);
            }}
          >
            {VietnameseString.add_template}
          </ButtonSystemStyle>
        </ResultStyled>

        {loading.templatesMessage ? (
          <Skeleton active loading />
        ) : (
          <Fragment>
            <Row gutter={[8, 8]}>
              {templatesMessage.length ? (
                templatesMessage.map(item => {
                  const {
                    id,
                    created_at,
                    is_approved,
                    count_character,
                    count_message,
                    message,
                    message_template_media,
                  } = item;

                  return (
                    <Col key={id} xxl={6} xl={8} lg={8} md={12} sm={24} xs={24}>
                      <Template
                        key={id}
                        dateCreated={formatTime(created_at)}
                        status={is_approved}
                        numberOfCharacters={count_character}
                        numberOfMessage={count_message}
                        messageContent={message}
                        multimedia={message_template_media.map(
                          el => el.media_url
                        )}
                        onHandleUpdateApprovalStatus={async () => {
                          try {
                            const { msg } = await requestUpdateTemplateMessage({
                              numberPrefixId: param.id,
                              templateMessageId: id,
                              isApproved:
                                is_approved === IS_APPROVED.YES
                                  ? IS_APPROVED.NO
                                  : IS_APPROVED.YES,
                            });
                            messageAntd.success(msg);
                            getTemplatesMessage(param);
                          } catch (error) {
                            console.log(
                              ' Error onHandleUpdateApprovalStatus: ',
                              { error }
                            );
                          }
                        }}
                      />
                    </Col>
                  );
                })
              ) : (
                <Col span={24}>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<span>Không có mẫu SMS.</span>}
                  />
                </Col>
              )}
            </Row>

            {templatesMessage.length ? (
              <Pagination
                style={{ marginTop: '10px', textAlign: 'right' }}
                total={paging.total}
                current={paging.current}
                pageSize={paging.pageSize}
                onChange={page => {
                  setParam(prev => ({ ...prev, page: page }));
                }}
              />
            ) : (
              <Fragment></Fragment>
            )}
          </Fragment>
        )}
      </Wrapper>

      {visible && (
        <AddTemplate
          isCheckEvoucher={isCheckEvoucher}
          visible={visible}
          setVisible={show => {
            setVisible(show);
            setTimeout(() => getTemplatesMessage(param), 500);
          }}
          numberPrefixId={param.id}
          enterpriseId={numberPrefixInformation.enterpriseId}
          codeTypeOfSMS={numberPrefixInformation.typeOfSMS.code}
          numberPrefix={numberPrefixInformation.numberPrefix}
        />
      )}
    </div>
  );
}

export default SMSConfigurationDetail;
