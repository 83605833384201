import { Popover, Row } from 'antd';
import React from 'react';
import TagCustom from '@screens/Enterprise/components/TagCustom';
import InteractionHistoryHeader from './InteractionHistoryHeader';

export default function UpdateTag({ interactionHistory }) {
  const popoverTag = (tags) => {
    return tags.map((item, index) => {
      if (index > 3) {
        return (
          <div key={index} style={{ marginBottom: '5px' }}>
            <TagCustom tagName={item.tag_name} tagColor={item.tag_color} />
          </div>
        );
      }
    });
  };

  return (
    <>
      <InteractionHistoryHeader interactionHistory={interactionHistory} />

      <Row>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          {interactionHistory.tags.length ? (
            interactionHistory.tags.map((tag, index) => {
              if (index <= 3) {
                return (
                  <div key={index} style={{ display: 'inline-block', marginRight: '10px' }}>
                    <TagCustom key={index} tagName={tag.tag_name} tagColor={tag.tag_color} />
                  </div>
                );
              }
            })
          ) : (
            <></>
          )}

          {interactionHistory.tags.length && interactionHistory.tags.length > 4 ? (
            <Popover content={popoverTag(interactionHistory.tags)} title="" trigger="hover">
              <div
                style={{
                  display: 'inline-block',
                  margin: 0,
                  padding: '0px 5px',
                  position: 'relative',
                  backgroundColor: '#EFEFEF',
                  border: 'none',
                  borderBottomLeftRadius: '4px',
                  borderTopLeftRadius: '4px',
                  borderBottomRightRadius: '4px',
                  borderTopRightRadius: '4px',
                  fontWeight: 'normal',
                  height: '20px',
                }}
              >
                +{interactionHistory.tags.length - 4}
              </div>
            </Popover>
          ) : (
            <></>
          )}
        </div>
      </Row>
    </>
  );
}
