import {
  DownOutlined,
  CopyOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import Wrapper from '@components/ui/wrapper/Wrapper';
import {
  requestCustomerDetail,
  requestCustomerDetailInteractionHistory,
  requestFullListTag,
  requestUpdateTagsCustomer,
} from '@constants/Api';
import { DF_CUSTOMER_ACTION_ID, GENDER, ROUTER } from '@constants/Constant';
import { formatPrice, randomColor, rmVN } from '@constants/funcHelper';
import { faEdit } from '@fortawesome/fontawesome-free-regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { primaryColor } from '@styles/Color';
import { BreadcrumbAntStyle, TimeLineStyle } from '@styles/Style';
import { notifyFail, notifySuccess } from '@utils/notify';
import {
  Avatar,
  Breadcrumb,
  Col,
  Descriptions,
  Divider,
  Dropdown,
  Empty,
  Menu,
  Row,
  Select,
  Spin,
  Timeline,
  Tooltip,
  Typography,
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import TagCustom from '@screens/Enterprise/components/TagCustom';
import {
  AppointmentSchedule,
  Call,
  Create,
  CustomerClosesTheContract,
  CustomerRefuses,
  DeleteOpportunities,
  DeliverToSale,
  EditCustomer,
  LocationReport,
  Message,
  MissedCall,
  PotentialCustomers,
  UpdateTag,
} from '@src/components/interaction-history';
import TimeDivider from '@components/TimeDivider';
import mixpanel from 'mixpanel-browser';
import Minigame from '@components/interaction-history/Minigame';
import { listFormat, showPriceFormat } from './MockData';
import MessageZaloPerson from '@components/interaction-history/Message.ZaloPerson';
import EVoucherActivator from '@components/interaction-history/EVoucherActivator';
import OppotunitiesPointSaved from '@components/interaction-history/OppotunitiesPointSaved';
import PointSaved from '@components/interaction-history/PointSaved';

const { Option } = Select;

const InfoCustomerFlexCenter = styled.div`
  width: 100%;
  display: flex;
  /* justify-content: center; */
  font-size: 20px;
  font-weight: 700;
`;

const FontAwesomeIconStyle = styled(FontAwesomeIcon)`
  &:hover {
    /* color: #52c41a; */
    cursor: pointer;
  }
`;

const DescriptionStyle = styled(Descriptions)`
  .ant-descriptions-row > th,
  .ant-descriptions-row > td {
    padding-bottom: 0px;
  }

  .ant-descriptions-item-label {
    color: gray;
    font-size: 13px;
  }

  .ant-descriptions-item-content {
    font-size: 16px;
    /* color: #2626ff; */
    padding-bottom: 10px;
    text-align: start;
  }
`;

const AvatarStyle = styled(Avatar)`
  @media (min-width: 1600px) {
    #avaCus {
      font-size: 32px;
    }
  }
  @media (min-width: 1200px and max-width: 1600px) {
    #avaCus {
      font-size: 24px;
    }
  }

  @media and (min-width: 992px and max-width: 1200px) {
    #avaCus {
      font-size: 20px;
    }
  }
`;
export const ContainerStyledCopy = styled(Typography.Paragraph)`
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 6px;
  .ant-typography-copy {
    position: relative;
    top: -3px;
    color: grey;
  }
`;
export default function CustomerDetail() {
  const history = useHistory();
  const params = useParams();
  const user = useSelector(states => states.userReducer.data);
  const returnCustomerId = useCallback(() => {
    return params.id;
  }, []);

  const [extraPhoneNumber, setExtraPhoneNumber] = useState([]);
  const [mainPhoneNumber, setMainPhoneNumber] = useState('');

  const [customerDetailInfo, setCustomerDetailInfo] = useState({});
  const [customerInteractionHistory, setCustomerInteractionHistory] = useState(
    []
  );

  const [
    loadingCustomerInteractionHistory,
    setLoadingCustomerInteractionHistory,
  ] = useState(false);

  const [tags, setTags] = useState([]);
  const [checkSelectTag, setCheckSelectTag] = useState(false);
  const [customerTags, setCustomerTags] = useState([]);

  const getFullTags = async () => {
    try {
      const res = await requestFullListTag({});
      const options =
        res?.data?.map(tag => ({
          tag_id: tag.id,
          tag_name: tag.name,
          tag_color: tag.color,
        })) || [];
      setTags(options);
    } catch (err) {}
  };

  const getDetailCustomer = async () => {
    try {
      const result = await requestCustomerDetail(returnCustomerId());

      setCustomerTags(result.data.main.customer_tags.map(item => item.tag_id));
      setCustomerDetailInfo(result.data);
      setMainPhoneNumber(result.data.main.phone);
      if (result.data.main.customer_phones.length) {
        const arr = result.data.main.customer_phones.map(item => item.phone);
        setExtraPhoneNumber(arr);
      }
    } catch (error) {
      console.log('Exception: ', error);
    }
  };

  const getDetailInteractionHistory = async () => {
    try {
      setLoadingCustomerInteractionHistory(true);
      const result = await requestCustomerDetailInteractionHistory({
        id: returnCustomerId(),
        page: 1,
        limit: 1000,
      });
      setCustomerInteractionHistory(result.data);
    } catch (error) {
      console.log('Exception: ', error);
    } finally {
      setLoadingCustomerInteractionHistory(false);
    }
  };

  useEffect(() => {
    getDetailInteractionHistory();
  }, []);

  useEffect(() => {
    getDetailCustomer();
    getFullTags();
  }, []);

  const handleUpdateTagsCustomer = async tags => {
    // debugger
    const tagsCustomerCurrent = customerDetailInfo.main.customer_tags.map(
      (tag, index) => tag.tag_id
    );
    const check = _.isEqual(
      tags?.sort((a, b) => a - b),
      tagsCustomerCurrent?.sort((a, b) => a - b)
    );
    if (!check) {
      try {
        const result = await requestUpdateTagsCustomer({
          customerId: returnCustomerId(),
          tags: tags,
        });
        notifySuccess(result.data.msg);
        getDetailCustomer();
        getDetailInteractionHistory();
      } catch (error) {
        setCustomerTags(
          customerDetailInfo.main.customer_tags.map((tag, index) => tag.tag_id)
        );
        notifyFail('Cập nhật không thành công');
      }
    }
  };

  const infoCustomer = customerDetailInfo => {
    const name = customerDetailInfo.main.name?.trim().split(' ');
    const tagName = customerDetailInfo?.main.customer_tags?.map(
      (item, index) => (
        <div key={index}>
          <div>{item.tag_name}</div>
        </div>
      )
    );
    const tooltipTag = tagName.slice(1, tagName.length);
    return (
      <>
        <Row gutter={[16, 16]} align="middle" justify="center">
          <Col span={7}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <AvatarStyle
                style={{
                  backgroundColor: randomColor(
                    name[name.length - 1].charAt(0)?.toUpperCase()
                  ),
                  marginRight: '5px',
                }}
                size={{ xs: 24, sm: 32, md: 40, lg: 50, xl: 45, xxl: 75 }}
              >
                <span id="avaCus">
                  {name[name.length - 1].charAt(0)?.toUpperCase()}
                </span>
              </AvatarStyle>
            </div>
          </Col>

          <Col span={17}>
            <Row>
              <InfoCustomerFlexCenter>
                {customerDetailInfo.main.name}
              </InfoCustomerFlexCenter>
            </Row>

            <Row>
              <InfoCustomerFlexCenter>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div style={{ margin: '3px 10px 0px 0px' }}>
                    {mainPhoneNumber}
                  </div>
                  <div>
                    {extraPhoneNumber.length ? (
                      <Dropdown
                        placement="bottomRight"
                        overlay={
                          <Menu
                            onClick={(item, key, keyPath, domEvent) => {
                              const extra = [...extraPhoneNumber];
                              const idx = extra.findIndex(
                                phone => phone === item.key
                              );
                              extra.splice(idx, 1, mainPhoneNumber);
                              setExtraPhoneNumber(extra);
                              setMainPhoneNumber(item.key);
                            }}
                          >
                            {extraPhoneNumber.map((phone, index) => {
                              return <Menu.Item key={phone}>{phone}</Menu.Item>;
                            })}
                          </Menu>
                        }
                        trigger={['click']}
                      >
                        <DownOutlined />
                      </Dropdown>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </InfoCustomerFlexCenter>
            </Row>
          </Col>
        </Row>
        {user?.config_tag == 0 ? (
          !checkSelectTag ? (
            customerDetailInfo?.main.customer_tags?.[0]?.tag_name ? (
              <Row>
                <TagCustom
                  tagName={
                    customerDetailInfo?.main.customer_tags?.[0]?.tag_name
                  }
                  tagColor={
                    customerDetailInfo?.main.customer_tags?.[0]?.tag_color
                  }
                />
                {customerDetailInfo?.main.customer_tags?.length > 1 ? (
                  <Tooltip title={tooltipTag}>
                    <div
                      style={{
                        margin: '5px 0 0 10px',
                        padding: '0px 5px',
                        backgroundColor: '#EFEFEF',
                        borderRadius: 4,
                        height: '20px',
                      }}
                    >
                      +{customerDetailInfo?.main.customer_tags.length - 1}
                    </div>
                  </Tooltip>
                ) : null}
              </Row>
            ) : null
          ) : null
        ) : null}
        <Row>
          <Select
            allowClear
            style={{ width: '100%', marginTop: '10px' }}
            bordered={false}
            mode={user?.config_tag == 1 ? 'multiple' : null}
            optionFilterProp="children"
            filterOption={(input, option) => {
              return (
                rmVN(option.children.props.tagName).indexOf(rmVN(input)) >= 0
              );
            }}
            placeholder="Phân loại"
            maxTagCount={user?.config_tag == 1 ? 1 : null}
            // value={customerTags}
            defaultValue={user?.config_tag == 1 ? customerTags : null}
            // defaultValue={
            //   user?.config_tag == 0
            //     ? customerDetailInfo?.main.customer_tags?.length > 1
            //       ? `${customerDetailInfo?.main.customer_tags?.[0]?.tag_name} + ${
            //           customerDetailInfo?.main.customer_tags?.length - 1
            //         }`
            //       : customerDetailInfo?.main.customer_tags.length === 0
            //       ? undefined
            //       : `${customerDetailInfo?.main.customer_tags?.[0]?.tag_name}`
            //     : customerTags
            // }
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Không có dữ liệu!</span>}
              />
            }
            onChange={value => {
              mixpanel.track(
                ` Update tagId: ${value}, customer ${customerDetailInfo.main.name}`
              );
              setCheckSelectTag(true);
              setCustomerTags(value);
              if (user?.config_tag == 0) {
                if (!value) {
                  handleUpdateTagsCustomer([]);
                } else {
                  handleUpdateTagsCustomer([value]);
                }
              }
            }}
            onBlur={() => {
              if (user?.config_tag == 1) {
                handleUpdateTagsCustomer(customerTags);
              }
            }}
          >
            {_.uniqBy(
              [...tags, ...customerDetailInfo.main.customer_tags],
              'tag_id'
            ).map((item, index) => (
              <Option key={index} value={item.tag_id}>
                <TagCustom tagName={item.tag_name} tagColor={item.tag_color} />
              </Option>
            ))}
          </Select>
        </Row>

        <Row style={{ marginTop: '10px' }}>
          Cập nhật lần cuối{' '}
          {moment(customerDetailInfo.modified_date).format('HH:mm DD/MM/YYYY')}{' '}
          bởi{' '}
          {customerInteractionHistory.length
            ? customerInteractionHistory[0].updated_by
            : ''}
        </Row>
      </>
    );
  };
  const titleInfoMoreCustomer = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ fontSize: '20px', marginRight: '20px' }}>
          Thông tin cá nhân
        </div>
        <FontAwesomeIconStyle
          icon={faEdit}
          size="lg"
          onClick={() => {
            try {
              history.push({
                pathname: ROUTER.EDIT_CUSTOMER + `/${returnCustomerId()}`,
                state: { key: 'detail' },
              });
            } catch (error) {
              console.log('Exception: ', error);
            }
          }}
        />
      </div>
    );
  };

  const infoMoreCustomer = customerDetailInfo => {
    return (
      <DescriptionStyle
        title={titleInfoMoreCustomer()}
        column={1}
        layout="vertical"
      >
        {customerDetailInfo.list_field_configs &&
          customerDetailInfo.list_field_configs.map((field, index, array) => {
            try {
              switch (field.code) {
                case 'province':
                  if (customerDetailInfo?.main?.province) {
                    return (
                      <Descriptions.Item key={index} label={field.label}>
                        {customerDetailInfo?.main?.province.name}
                      </Descriptions.Item>
                    );
                  } else break;

                case 'gender':
                  if (
                    customerDetailInfo?.main?.gender === GENDER.MAN ||
                    customerDetailInfo?.main?.gender === GENDER.WOMEN
                  ) {
                    return customerDetailInfo?.main?.gender === GENDER.MAN ? (
                      <Descriptions.Item key={index} label={field.label}>
                        Nam
                      </Descriptions.Item>
                    ) : (
                      <Descriptions.Item key={index} label={field.label}>
                        Nữ
                      </Descriptions.Item>
                    );
                  } else break;

                case 'modified_date':
                  // if (customerDetailInfo?.main?.modified_date) {
                  //   return (
                  //     <Descriptions.Item key={index} label={field.label}>
                  //       {moment(customerDetailInfo?.main?.modified_date).format('DD/MM/YYYY')}
                  //     </Descriptions.Item>
                  //   );
                  // } else break;
                  break;

                case 'dob':
                  if (customerDetailInfo?.main?.dob) {
                    return (
                      <Descriptions.Item key={index} label={field.label}>
                        {moment(customerDetailInfo?.main?.dob).format(
                          'DD/MM/YYYY'
                        )}
                      </Descriptions.Item>
                    );
                  } else break;

                case 'customer_tags':
                  break;

                default:
                  try {
                    if (customerDetailInfo?.main?.[field.code.toLowerCase()]) {
                      return (
                        <Descriptions.Item key={index} label={field.label}>
                          {showPriceFormat(
                            field.code.toLowerCase(),
                            customerDetailInfo?.main?.[field.code]
                          )}
                        </Descriptions.Item>
                      );
                    } else {
                      const data = customerDetailInfo.main.customer_data.find(
                        item =>
                          item.customer_data_field_code.toLowerCase() ===
                          field.code.toLowerCase()
                      );
                      if (data.value) {
                        return (
                          <Descriptions.Item key={index} label={data.name}>
                            {showPriceFormat(
                              field.code.toLowerCase(),
                              data.value
                            )}
                          </Descriptions.Item>
                        );
                      }
                    }
                  } catch (error) {
                    return <></>;
                  }
              }
            } catch (error) {
              console.log('Exception: ', error);
            }
          })}
        <Descriptions.Item key={'customerCode'} label={'Mã Khách Hàng'}>
          <ContainerStyledCopy
            className="typograph_copy"
            copyable={{
              icon: [
                <CopyOutlined key="copy-icon" />,
                <CheckCircleOutlined key="copied-icon" />,
              ],
              tooltips: ['Copy', 'Đã Copy'],
            }}
          >
            {params?.id}
          </ContainerStyledCopy>
        </Descriptions.Item>
      </DescriptionStyle>
    );
  };

  const interactionHistory = customerInteractionHistory => {
    return (
      <div>
        <div
          style={{
            fontSize: '28px',
            fontWeight: 700,
            marginBottom: '00px',
            color: primaryColor,
          }}
        >
          Lịch sử tương tác
        </div>
        <TimeLineStyle>
          {customerInteractionHistory.length > 0 ? (
            customerInteractionHistory.map((item, index, array) => {
              if (index === 0) {
                return (
                  <Fragment key={index}>
                    {/* {timeDivider(item.created_at)} */}
                    <TimeDivider date={item.created_at} />
                    <Timeline.Item
                      key={index}
                      dot={
                        <img
                          src={item.df_customer_action_icon}
                          style={{ width: '25px', height: '25px' }}
                          alt=""
                        />
                      }
                    >
                      <div
                        style={{
                          backgroundColor: 'rgb(246 246 246)',
                          margin: '10px 15px',
                          padding: '8px 16px',
                          borderRadius: '8px',
                        }}
                      >
                        {renderInteractionHistory(item)}
                      </div>
                    </Timeline.Item>
                  </Fragment>
                );
              } else {
                const currentDateItem = new Date(
                  array[index].created_at
                ).getDate();
                const prevDateItem = new Date(
                  array[index - 1].created_at
                ).getDate();
                if (currentDateItem === prevDateItem) {
                  return (
                    <Timeline.Item
                      key={index}
                      dot={
                        <img
                          src={item.df_customer_action_icon}
                          style={{ width: '25px', height: '25px' }}
                          alt=""
                        />
                      }
                    >
                      <div
                        style={{
                          backgroundColor: '#f3f3f3',
                          margin: '10px 15px',
                          padding: '8px 16px',
                          borderRadius: '8px',
                        }}
                      >
                        {renderInteractionHistory(item)}
                      </div>
                    </Timeline.Item>
                  );
                } else {
                  return (
                    <Fragment key={index}>
                      {/* {timeDivider(item.created_at)} */}
                      <TimeDivider date={item.created_at} />
                      <Timeline.Item
                        key={index}
                        dot={
                          <img
                            src={item.df_customer_action_icon}
                            style={{ width: '25px', height: '25px' }}
                            alt=""
                          />
                        }
                      >
                        <div
                          style={{
                            backgroundColor: '#f3f3f3',
                            margin: '10px 15px',
                            padding: '8px 16px',
                            borderRadius: '8px',
                          }}
                        >
                          {renderInteractionHistory(item)}
                        </div>
                      </Timeline.Item>
                    </Fragment>
                  );
                }
              }
            })
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>Không có lịch sử.</span>}
            />
          )}
        </TimeLineStyle>
      </div>
    );
  };

  const renderInteractionHistory = interactionHistory => {
    switch (interactionHistory.df_customer_action_id) {
      case DF_CUSTOMER_ACTION_ID.KHACH_HANG_TIEM_NANG:
        return <PotentialCustomers interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.KHACH_HANG_CHOT_HOP_DONG:
        return (
          <CustomerClosesTheContract interactionHistory={interactionHistory} />
        );

      case DF_CUSTOMER_ACTION_ID.KHACH_HANG_TU_CHOI:
        return <CustomerRefuses interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.GOI_DEN:
        return (
          <Call
            interactionHistory={interactionHistory}
            getData={getDetailInteractionHistory}
            user={user}
          />
        );

      case DF_CUSTOMER_ACTION_ID.GOI_DI:
        return (
          <Call
            interactionHistory={interactionHistory}
            getData={getDetailInteractionHistory}
            user={user}
          />
        );

      case DF_CUSTOMER_ACTION_ID.GOI_NHO:
        return <MissedCall interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.CHINH_SUA_KHACH_HANG:
        return <EditCustomer interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.GIAO_CHO_SALE_CHAM_SOC:
        return <DeliverToSale interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.LICH_HEN:
        return <AppointmentSchedule interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.THEM_MOI:
        return <Create interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.CAP_NHAT_THE_TAG:
        return <UpdateTag interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.XOA_CO_HOI:
        return <DeleteOpportunities interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.BAO_CAO_VI_TRI:
        return <LocationReport interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.GUI_TIN_NHAN_SMS:
        return <Message interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.GUI_TIN_NHAN_ZNS:
        return <Message interactionHistory={interactionHistory} />;

      case DF_CUSTOMER_ACTION_ID.VONG_QUAY_MAY_MAN:
        return <Minigame interactionHistory={interactionHistory} />;
      case DF_CUSTOMER_ACTION_ID.GUI_TIN_NHAN_ZALO_CA_NHAN:
        return <MessageZaloPerson interactionHistory={interactionHistory} />;
      case DF_CUSTOMER_ACTION_ID.KICH_HOAT_EVOUCHER:
        return <EVoucherActivator interactionHistory={interactionHistory} />;
      case DF_CUSTOMER_ACTION_ID.TICH_DIEM_CO_HOI:
        return (
          <OppotunitiesPointSaved interactionHistory={interactionHistory} />
        );
      case DF_CUSTOMER_ACTION_ID.TICH_DIEM:
        return (
          <PointSaved interactionHistory={interactionHistory}></PointSaved>
        );

      default:
        break;
    }
  };

  return (
    <div className="content-wrapper">
      <BreadcrumbAntStyle>
        <Breadcrumb.Item>
          {/* <a onClick={() => history.push(ROUTER.CUSTOMER)}>Danh sách khách hàng</a> */}
          <a onClick={() => history.goBack()}>Danh sách khách hàng</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Chi tiết khách hàng</Breadcrumb.Item>
      </BreadcrumbAntStyle>

      <Wrapper>
        <Row gutter={[16, 16]}>
          <Col xxl={6} xl={6} lg={7} sm={24} xs={24}>
            <Row>
              <Col span={24}>
                {customerDetailInfo?.main?.id &&
                  infoCustomer(customerDetailInfo)}
              </Col>
            </Row>

            <Row>
              <Divider />
            </Row>

            <Row>
              <Col span={24}>
                {customerDetailInfo?.main?.id &&
                  infoMoreCustomer(customerDetailInfo)}
              </Col>
            </Row>
          </Col>

          {/* <Divider type="vertical" /> */}
          {/* <div style={{ borderLeft: '1px solid black', height: 'auto' }}></div> */}

          <Col
            xxl={{ span: 17, offset: 1 }}
            xl={{ span: 17, offset: 1 }}
            lg={{ span: 16, offset: 1 }}
            sm={{ span: 24 }}
            xs={24}
          >
            <Spin spinning={loadingCustomerInteractionHistory}>
              {interactionHistory(customerInteractionHistory)}
            </Spin>
          </Col>
        </Row>
      </Wrapper>
    </div>
  );
}
