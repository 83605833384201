import { DownloadOutlined, UploadOutlined, WarningOutlined } from '@ant-design/icons';
import {
  requesDownloadSampleFile,
  requesGetCustomerFieldImport,
  requesSaveFilePath,
  requestCustomerImport,
  requestGetCustomerGroups,
  requestImportCustomerDesign,
} from '@constants/Api';
import { ROUTER, TOKEN_MIXPANEL } from '@constants/Constant';
import { ButtonSystemStyle, TableAntStyle } from '@styles/Style';
import { createFormData } from '@utils/createFormData';
import { notifyFail } from '@utils/notify';
import { Button, Col, Input, Modal, Radio, Row, Select, Upload } from 'antd';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AddCustomerGroupModal from './AddCustomerGroupModal';

const { Option } = Select;
export default function ImportCustomersModal({
  visibleImportCustomerModal,
  setVisibleImportCustomerModal,
  isRequiredCustomerGroupSelect,
  idCustomerGroupSelected,
  setIdCustomerGroupSelected,
  user,
}) {
  const location = useLocation();

  const [visibleAddCustomerGroupModal, setVisibleAddCustomerGroupModal] = useState(false);

  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [fileSelected, setFileSelected] = useState();
  const [startRow, setStartRow] = useState(2);

  const [isLoadingSelectSearch, setIsLoadingSelectSearch] = useState(false);

  const [tableFieldImport, setTableFieldImport] = useState([]);

  /**
   * @example
   *  1: import nhanh
   *  2: import theo mẫu
   */
  const [importType, setImportType] = useState(1);

  /**
   * @example customerGroup {
   *    id: number,
   *    name: string,
   *    description: string,
   * }
   */
  const [customerGroups, setCustomerGroups] = useState([]);

  const getCustomerGroups = async () => {
    try {
      setIsLoadingSelectSearch(true);
      const result = await requestGetCustomerGroups({
        search: '',
      });
      const data = result.data.map((customerGroup) => ({ ...customerGroup, key: customerGroup.id }));
      setCustomerGroups([...data]);
    } catch (error) {
      console.error('Exception ' + error);
    } finally {
      setIsLoadingSelectSearch(false);
    }
  };

  const getFieldImport = async () => {
    const result = await requesGetCustomerFieldImport();
    const data = result.data.map((field) => ({ ...field, key: field.id }));

    const defaultSelectedRow = data.filter((field) => field.code === 'name' || field.code === 'phone');
    setSelectedRow(defaultSelectedRow.map((field) => field.id));

    setTableFieldImport(data);
  };

  useEffect(() => {
    if (visibleImportCustomerModal) {
      getCustomerGroups();
      getFieldImport();
    }
  }, [visibleImportCustomerModal]);

  const columns = [
    {
      width: '50%',
      title: 'Mục',
      dataIndex: 'name',
      render: (name) => <div style={{ float: 'left' }}>{name}</div>,
    },
    {
      width: '50%',
      title: 'Cột',
      dataIndex: '',
      render: (record) => {
        const item = selectedRow.find((item) => item === record.id);

        return (
          // <InputNumber
          //   disabled={item ? false : true}
          //   style={{ width: '100%' }}
          //   size={'small'}
          //   value={item && record.position}
          //   // onChange={(value) => handleChangePosition(value, record)}
          //   onBlur={(event) => handleCheckPosition(parseInt(event.target.value), record)}
          // />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              disabled={item ? false : true}
              style={{ width: '90%' }}
              size={'small'}
              value={item && record.position}
              onChange={(event) => {
                const { value } = event.target;
                const reg = /[0-9]/;
                if ((!isNaN(value) && reg.test(value)) || value === '') {
                  const num = value.replace('.', '');
                  handleChangePosition(num, record);
                }
              }}
              onBlur={(event) => {
                handleCheckPosition(event.target.value, record);
                if (!event.target.value) {
                  if (record.code !== 'name' && record.code !== 'phone') {
                    // const arr = selectedRow.filter((r) => r !== record.id);
                    // setSelectedRow([...arr]);
                  } else {
                    const field = tableFieldImport.filter((x) => selectedRow.includes(x.id));
                    const maxPosition = _.maxBy(field, 'position').position;
                    handleChangePosition(parseInt(maxPosition) + 1, record);
                  }
                }
              }}
            />
            {rowWarning.findIndex((r) => r.id === record.id) !== -1 && (
              <WarningOutlined style={{ color: 'red', marginLeft: '5px' }} />
            )}
          </div>
        );
      },
    },
  ];

  const handleChangePosition = (value, field) => {
    const fields = [...tableFieldImport];
    const index = fields.findIndex((item) => item.id === field.id);
    let item = fields.find((item) => item.id === field.id);
    item.position = value;
    fields[index] = item;
    const newFields = fields.map((field, idx) => {
      if (index !== idx) {
        if (field.position === value) {
          const a = { ...field, position: '' };
          field = a;
        }
      }
      return field;
    });
    setTableFieldImport([...newFields]);
    setTableFieldImport([...fields]);
  };

  const handleCheckPosition = (value, field) => {
    const fields = [...tableFieldImport];
    const index = fields.findIndex((item) => item.id === field.id);
    let item = fields.find((item) => item.id === field.id);
    item.position = value;
    fields[index] = item;
    const newFields = fields.map((field, idx) => {
      if (index !== idx) {
        if (parseInt(field.position) === parseInt(value)) {
          const a = { ...field, position: '' };
          field = a;
        }
      }
      return field;
    });
    setTableFieldImport([...newFields]);
  };

  const [selectedRow, setSelectedRow] = useState([]);
  const rowSelection = {
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      if (selected) {
        //tìm ra số thứ tự lớn nhất trong mảng đã check và tăng thêm 1
        _.remove(selectedRows, function (currentObject) {
          return currentObject?.id === record.id;
        });
        const maxPosition = _.maxBy(selectedRows, 'position').position;
        record.position = parseInt(maxPosition) + 1;

        //arr những row được chọn
        setSelectedRow([...selectedRow, record.id]);

        //set lại giá trị bảng theo thứ tự mới
        const newFields = [...tableFieldImport];
        const index = newFields.findIndex((field) => field.id === record.id);
        newFields[index] = record;
        setTableFieldImport([...newFields]);
      } else {
        const arr = selectedRow.filter((item) => item !== record.id);
        setSelectedRow([...arr]);
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selected) {
        //tìm ra những phần tử đã được chọn để lấy ra số thứ tự lớn nhất: selectedRows.filter((x) => !changeRows.includes(x));
        const maxPosition = _.maxBy(
          selectedRows.filter((x) => !changeRows.includes(x)),
          'position'
        ).position;

        const newFields = [...tableFieldImport];
        const arr = changeRows.map((r, idx) => {
          const index = newFields.findIndex((field) => field.id === r.id);
          newFields[index] = { ...r, position: parseInt(maxPosition) + idx + 1 };
          setTableFieldImport([...newFields]);
          return { ...r, position: parseInt(maxPosition) + idx + 1 };
        });
        setSelectedRow([...selectedRow, ...arr.map((r) => r.id)]);
      } else {
        const arr = selectedRows.map((r) => r && r.id);
        setSelectedRow([...selectedRows.filter((x) => !changeRows.includes(x)), ...arr]);
      }
    },
    getCheckboxProps: (record) => {
      return {
        disabled: record.code === 'name' || record.code === 'phone',
      };
    },
  };

  const [rowWarning, setRowWarning] = useState([]);

  const handleImport = async () => {
    if (isRequiredCustomerGroupSelect) {
      if (!idCustomerGroupSelected) {
        notifyFail('Vui lòng chọn nhóm khách hàng khi import');
        return;
      }
    }
    if (fileSelected) {
      mixpanel.track(`Import customer`);
      if (importType === 1) {
        //danh sách những row được tích chọn
        const field = tableFieldImport.filter((x) => selectedRow.includes(x.id));
        //danh sách những row không được tích chọn
        const field2 = tableFieldImport.filter((x) => !selectedRow.includes(x.id));
        //gán position = '' cho những row khồn được tích chọn
        const field3 = field2.map((field) => ({ ...field, position: '' }));
        //tìm ra những row được tích chọn mà có position = '' hoặc < 1
        const rWarn = field.filter((r) => {
          if (!r.position) return r.id;
          if (r.position < 1) return r.id;
        });
        if (rWarn.length > 0) {
          setRowWarning(rWarn);
        } else {
          const form = createFormData({
            file: fileSelected.originFileObj,
          });
          try {
            setIsLoadingButton(true);
            const result = await requesSaveFilePath(form);
            const payload = {
              startRow,
              filepath: result.data,
              id: idCustomerGroupSelected ? idCustomerGroupSelected : '',
              dataFields: [...field, ...field3],
            };
            await requestImportCustomerDesign(payload);
            handleCancelModal();
          } catch (error) {
            console.log('Exception :', error);
            notifyFail(error?.msg);
          } finally {
            setIsLoadingButton(false);
          }
        }
      } else {
        try {
          setIsLoadingButton(true);
          const form = idCustomerGroupSelected
            ? createFormData({
                file: fileSelected.originFileObj,
                id: idCustomerGroupSelected,
              })
            : createFormData({
                file: fileSelected.originFileObj,
              });
          await requestCustomerImport(form);
          handleCancelModal();
        } catch (error) {
          console.error('Exception: ' + error);
          notifyFail(error?.msg);
        } finally {
          setIsLoadingButton(false);
        }
      }
    } else {
      notifyFail('Vui lòng chọn file excel để import!');
    }
  };

  const handleCancelModal = () => {
    setVisibleImportCustomerModal && setVisibleImportCustomerModal(false);
    setSelectedRow([]);
    setFileSelected();
  };

  return (
    <>
      <Modal
        // title={<p style={{ color: 'orange', fontSize: '20px' }}>Import</p>}
        title="Import"
        visible={visibleImportCustomerModal && visibleImportCustomerModal}
        onOk={() => handleImport()}
        onCancel={() => handleCancelModal()}
        okText={'Lưu'}
        cancelText={'Hủy'}
        destroyOnClose={true}
        confirmLoading={isLoadingButton}
      >
        <Row gutter={[16, 16]} align="middle" justify="space-between">
          <Col span={7}>Chọn nhóm:</Col>
          <Col span={12}>
            <Select
              style={{ width: '100%' }}
              optionFilterProp="children"
              loading={isLoadingSelectSearch}
              value={idCustomerGroupSelected && idCustomerGroupSelected}
              placeholder="Tập khách hàng"
              showSearch
              disabled={location.pathname.includes(ROUTER.JOB_GENERAL_EDIT)}
              // onPopupScroll={(event) => {
              //   let isEndOfList = event.target.scrollTop >= 0.2 * event.target.scrollHeight;
              //   isEndOfList && setParamCustomerGroup({ ...paramCustomerGroup, page: paramCustomerGroup.page + 1 });
              // }}
              onChange={async (value, option) => {
                setIdCustomerGroupSelected && setIdCustomerGroupSelected(value);
              }}
            >
              {customerGroups.map((customerGroup, index) => {
                return (
                  <Option value={customerGroup.id} key={index}>
                    {customerGroup.name}
                  </Option>
                );
              })}
            </Select>
          </Col>

          <Col span={5}>
            {!location.pathname.includes(ROUTER.JOB_GENERAL_EDIT) && (
              <ButtonSystemStyle
                type="button"
                onClick={(event) => {
                  setVisibleAddCustomerGroupModal(true);
                }}
              >
                <i className="fas fa-user-friends"></i>
                <i className="fas fa-plus" style={{ marginLeft: '5px' }}></i>
              </ButtonSystemStyle>
            )}
          </Col>
        </Row>

        <Row gutter={[16, 16]} align="middle" justify="space-between" style={{ marginTop: '10px' }}>
          <Col span={7}>Loại:</Col>
          <Col span={17}>
            <Radio.Group
              onChange={(event) => {
                setImportType(event.target.value);
                setFileSelected();
              }}
              value={importType}
            >
              <Radio value={1}>Import nhanh</Radio>
              <Radio value={2}>Import theo mẫu</Radio>
            </Radio.Group>
          </Col>
        </Row>

        {importType === 1 ? (
          <>
            <Row gutter={[16, 16]} align="middle" justify="space-between" style={{ marginTop: '10px' }}>
              <Col span={7}>Tải lên file excel:</Col>
              <Col span={17}>
                <Upload
                  name="file"
                  maxCount={1}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={(info) => {
                    setFileSelected({ ...info.fileList[0], status: 'done' });
                  }}
                >
                  <Button style={{ border: 'none', display: 'flex' }} icon={<UploadOutlined />}>
                    File excel
                  </Button>
                </Upload>
              </Col>
            </Row>

            <Row gutter={[16, 16]} align="middle" justify="space-between" style={{ marginTop: '10px' }}>
              <Col span={7}>Chọn dòng bắt đầu:</Col>
              <Col span={17}>
                <Input
                  style={{ width: '100%' }}
                  value={startRow}
                  onChange={(event) => {
                    const { value } = event.target;
                    const reg = /[0-9]/;
                    if ((!isNaN(value) && reg.test(value)) || value === '') {
                      const num = value.replace('.', '');
                      setStartRow(num);
                    }
                  }}
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]} align="middle" justify="space-between" style={{ marginTop: '10px' }}>
              <Col span={24}>
                <TableAntStyle
                  rowSelection={{ ...rowSelection, selectedRowKeys: selectedRow }}
                  columns={columns}
                  dataSource={tableFieldImport}
                  pagination={{
                    pageSize: 10000,
                    hideOnSinglePage: true,
                  }}
                  scroll={{
                    scrollToFirstRowOnChange: true,
                    y: '350px',
                  }}
                />
                {/* <p style={{ color: 'red' }}>Vui lòng nhập</p> */}
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row gutter={[16, 16]} align="middle" justify="space-between" style={{ marginTop: '10px' }}>
              <Col span={7}>Tải xuống file mẫu:</Col>
              <Col span={17}>
                <Button
                  style={{ border: 'none', display: 'flex' }}
                  icon={<DownloadOutlined />}
                  onClick={async () => {
                    try {
                      const data = await requesDownloadSampleFile();
                      saveAs(data.data, 'customer_data.xlsx');
                    } catch (error) {
                      console.error('Exception :' + error);
                    }
                  }}
                >
                  Mẫu excel
                </Button>
              </Col>
            </Row>
            <Row gutter={[16, 16]} align="middle" justify="space-between" style={{ marginTop: '10px' }}>
              <Col span={7}>Tải lên file excel:</Col>
              <Col span={17}>
                <Upload
                  name="file"
                  maxCount={1}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={(info) => {
                    setFileSelected({ ...info.fileList[0], status: 'done' });
                  }}
                >
                  <Button style={{ border: 'none', display: 'flex' }} icon={<UploadOutlined />}>
                    File excel
                  </Button>
                </Upload>
              </Col>
            </Row>
          </>
        )}
      </Modal>

      {/* Modal tạo nhóm khách hàng */}
      <AddCustomerGroupModal
        visible={visibleAddCustomerGroupModal}
        setVisible={setVisibleAddCustomerGroupModal}
        handleResultCreateCustomerGroup={(customerGroup) => {
          /**
           * @example customerGroup {
           *    id: number,
           *    name: string,
           *    description: string,
           * }
           */
          setCustomerGroups([customerGroup, ...customerGroups]);
          setIdCustomerGroupSelected && setIdCustomerGroupSelected(customerGroup.id);
        }}
      />
    </>
  );
}
