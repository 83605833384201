import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import {
  Avatar,
  Breadcrumb,
  Checkbox,
  Col,
  Empty,
  Popconfirm,
  Popover,
  Row,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import styled from 'styled-components';
import {
  ExportOutlined,
  ImportOutlined,
  LoadingOutlined,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/fontawesome-free-regular';
import SpinInButton from '@components/SpinInButton';
import {
  requestCustomerList,
  requestDeleteMultiCustomer,
  requestExportCustomer,
  requestGetDataFieldDisplayCustom,
  requestGetDataFieldFilterCustom,
} from '@constants/Api';
import { GENDER, ROUTER } from '@constants/Constant';
import {
  getSearchParamsInUrl,
  setSearchParamsInUrlVer2,
  randomColor,
  formatPrice,
} from '@constants/funcHelper';
import {
  blueColor,
  blueColorHover,
  greenColor,
  greenColorHover,
  primaryColor,
  primaryColorHover,
} from '@styles/Color';
import {
  BreadcrumbAntStyle,
  ButtonSystemStyle,
  TableAntStyle,
} from '@styles/Style';
import EventEmitter from '@utils/EventEmitter';
import { notifyFail, notifySuccess } from '@utils/notify';
import Wrapper from 'src/components/ui/wrapper/Wrapper';
import ImportCustomersModal from '../CustomerGroup/modals/ImportCustomersModal';
import DragAndDropConfigurationModal from './components/DragAndDropConfigurationModal';
import Filter from './components/Filter';
import TagCustom from '@screens/Enterprise/components/TagCustom';
import AssignmentModal from './detail_components/AssignmentModal';
import {
  CODE_EZ_EXCEPTION_API_CANCEL,
  useCancellableApi,
} from 'src/hooks/useCancellableApi';
import { useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import Axios from 'axios';
import { showPriceFormat } from './MockData';

const BlockStyle = styled.div`
  margin: 10px 0px;
`;

const OneLineStyle = styled.div`
  max-width: 150px;

  /* giới hạn số dòng text */
  display: -webkit-box;
  max-height: 3.2rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  line-height: 1.6rem;
`;

const CustomerNameStyle = styled.div`
  font-weight: 700;
  &:hover {
    color: #52c41a;
  }
`;

const FontAwesomeIconStyle = styled(FontAwesomeIcon)`
  &:hover {
    /* color: #52c41a; */
    cursor: pointer;
  }
`;

const DELETE_CUSTOMER = {
  ALL: 1,
  SOME: 0,
};

const PAGE_SIZE = 20;

export default function Customer() {
  const user = useSelector(states => states.userReducer.data);
  const [paging, setPaging] = useState({
    total: 0,
    current: 1,
    pageSize: 0,
  });

  const [params, setParams] = useState({
    search: '',
    province_ids: [],
    page: paging.current,
    telesale_id: [],
  });

  const history = useHistory();

  useEffect(() => {
    const searchParamsInUrl = getSearchParamsInUrl();
    setParams({ ...params, ...searchParamsInUrl });
  }, []);

  const [visibleDisplayConfiguration, setVisibleDisplayConfiguration] =
    useState(false);
  const [visibleFilterConfiguration, setVisibleFilterConfiguration] =
    useState(false);
  const [visibleImportCustomerModal, setVisibleImportCustomerModal] =
    useState(false);
  const [isVisibleAssignModal, setIsVisibleAssignModal] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [isLoadingExportButton, setIsLoadingExportButton] = useState(false);
  const [rowSelected, setRowSelected] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [idCustomerGroupSelected, setIdCustomerGroupSelected] = useState();
  const [listDisplayFieldConfigGetAPI, setListDisplayFieldConfigGetAPI] =
    useState([]);
  const [listFilterFieldConfigGetAPI, setListFilterFieldConfigGetAPI] =
    useState([]);
  const [columns, setColumns] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);

  const popoverTag = tags => {
    return tags.map((item, index) => {
      if (index > 0) {
        return (
          <div key={index} style={{ marginBottom: '5px' }}>
            <TagCustom tagName={item.tag_name} tagColor={item.tag_color} />
          </div>
        );
      }
    });
  };

  useEffect(() => {
    if (listDisplayFieldConfigGetAPI) {
      const columnCustomer = listDisplayFieldConfigGetAPI
        .filter((col, index) => col.display_config === 1)
        .map((col, index) => {
          switch (col.code) {
            case 'name':
              return {
                width: 250,
                title: col.lable || col.label || '',
                dataIndex: col.code,
                key: col.code,
                render: (text, record, index) => {
                  try {
                    const name = text.trim().split(' ');
                    return (
                      <div
                        style={{ textAlign: 'left' }}
                        onClick={() => {
                          mixpanel.track(`Go to detail customer: ${text}`);
                          history.push({
                            pathname: ROUTER.CUSTOMER_DETAIL + `/${record.id}`,
                          });
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div>
                            {text && (
                              <Avatar
                                style={{
                                  backgroundColor: randomColor(
                                    name[name.length - 1]
                                      .charAt(0)
                                      ?.toUpperCase()
                                  ),
                                  marginRight: '5px',
                                }}
                                size="small"
                              >
                                {name[name.length - 1].charAt(0)?.toUpperCase()}
                              </Avatar>
                            )}
                          </div>
                          <div>
                            <Tooltip placement="bottomLeft" title={text}>
                              <Typography.Paragraph
                                style={{ margin: '0px' }}
                                ellipsis={true}
                              >
                                <OneLineStyle style={{ maxWidth: '250px' }}>
                                  <CustomerNameStyle>
                                    {text ? text : '--'}
                                  </CustomerNameStyle>
                                </OneLineStyle>
                              </Typography.Paragraph>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    );
                  } catch (error) {
                    console.log();
                  }
                },
              };

            case 'phone':
              return {
                width: 100,
                title: col.lable || col.label || '',
                dataIndex: col.code,
                key: col.code,
                ellipsis: true,
                render: (text, record, index) => {
                  try {
                    return <div>{text ? text : '--'}</div>;
                  } catch (error) {
                    console.log();
                  }
                },
              };

            case 'gender':
              return {
                width: 100,
                title: col.lable || col.label || '',
                dataIndex: col.code,
                key: col.code,
                ellipsis: true,
                render: (value, record, index) => {
                  try {
                    return (
                      <div>
                        {value === GENDER.MAN
                          ? 'Nam'
                          : value === GENDER.WOMEN
                          ? 'Nữ'
                          : '--'}
                      </div>
                    );
                  } catch (error) {
                    console.log();
                  }
                },
              };

            case 'modified_date':
              return {
                width: 200,
                title: col.lable || col.label || '',
                dataIndex: col.code,
                key: col.code,
                ellipsis: true,
                sortDirections: ['ascend', 'descend'],
                sorter: (a, b) =>
                  a.modified_date &&
                  b.modified_date &&
                  moment(a.modified_date).unix() -
                    moment(b.modified_date).unix(),
                render: (text, record, index) => {
                  try {
                    return (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {record.df_customer_action?.icon ? (
                          <Tooltip
                            placement="bottomLeft"
                            title={record.df_customer_action?.name}
                            key={'#ef8737'}
                            color={'#ef8737'}
                          >
                            <img
                              style={{
                                width: '20px',
                                height: '20px',
                                margin: '5px',
                              }}
                              src={record.df_customer_action?.icon}
                              alt=""
                            />
                          </Tooltip>
                        ) : (
                          <div
                            style={{
                              width: '20px',
                              height: '20px',
                              margin: '5px',
                            }}
                          ></div>
                        )}
                        <>
                          {text
                            ? moment(text).format('HH:mm - DD/MM/YYYY')
                            : '--'}
                        </>
                      </div>
                    );
                  } catch (error) {
                    console.log();
                  }
                },
              };

            case 'job_telesale_customers':
              return {
                width: 150,
                title: col.lable || col.label || '',
                dataIndex: col.code,
                key: col.code,
                ellipsis: true,
                render: (profiles = [], record, index) => {
                  const careSales = _.uniqBy(
                    [...profiles, ...record?.telesale_customers],
                    'id'
                  );

                  if (careSales.length > 0) {
                    try {
                      return (
                        <Avatar.Group
                          maxCount={2}
                          maxStyle={{
                            color: '#f56a00',
                            backgroundColor: '#fde3cf',
                          }}
                        >
                          {careSales.map((sale, index) => {
                            if (
                              sale.telesale_name !== null &&
                              sale.profile_image !== null
                            ) {
                              const name = sale.telesale_name.trim().split(' ');
                              return (
                                <Tooltip
                                  key={index}
                                  title={sale.telesale_name}
                                  placement="bottom"
                                >
                                  <Avatar
                                    src={
                                      sale.profile_image && sale.profile_image
                                    }
                                    style={
                                      index >= 7
                                        ? { display: 'none' }
                                        : {
                                            backgroundColor: randomColor(
                                              name[name.length - 1]
                                                .charAt(0)
                                                ?.toUpperCase()
                                            ),
                                          }
                                    }
                                  >
                                    {sale.profile_image
                                      ? ''
                                      : name[name.length - 1]
                                          .charAt(0)
                                          ?.toUpperCase()}
                                  </Avatar>
                                </Tooltip>
                              );
                            } else {
                              return (
                                <Avatar
                                  key={index}
                                  style={{ backgroundColor: '#87d068' }}
                                  icon={<UserOutlined />}
                                />
                              );
                            }
                          })}
                        </Avatar.Group>
                      );
                    } catch (error) {
                      console.log();
                    }
                  } else {
                    return <>--</>;
                  }
                },
              };

            case 'customer_tags':
              return {
                width: 150,
                title: col.lable || col.label || '',
                dataIndex: col.code,
                key: col.code,
                render: (tags = [], record, index) => {
                  try {
                    if (tags.length > 0) {
                      return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {tags &&
                            tags.map((tag, index) => {
                              if (index === 0) {
                                return (
                                  <TagCustom
                                    key={index}
                                    tagName={tag.tag_name}
                                    tagColor={tag.tag_color}
                                  />
                                );
                              }
                            })}

                          {tags && tags.length > 1 && (
                            <Popover
                              content={popoverTag(tags)}
                              title=""
                              trigger="hover"
                            >
                              <div
                                style={{
                                  display: 'inline-block',
                                  margin: 0,
                                  marginLeft: 10,
                                  padding: '0px 5px',
                                  position: 'relative',
                                  backgroundColor: '#EFEFEF',
                                  border: 'none',
                                  borderBottomLeftRadius: '4px',
                                  borderTopLeftRadius: '4px',
                                  borderBottomRightRadius: '4px',
                                  borderTopRightRadius: '4px',
                                  fontWeight: 'normal',
                                  height: '20px',
                                }}
                              >
                                +{tags.length - 1}
                              </div>
                            </Popover>
                          )}
                        </div>
                      );
                    } else {
                      return '--';
                    }
                  } catch (error) {
                    console.log();
                  }
                },
              };

            case 'dob':
              return {
                width: 160,
                title: col.lable || col.label || '',
                dataIndex: col.code,
                key: col.code,
                ellipsis: true,
                render: (text, record, index) => {
                  try {
                    return (
                      <>{text ? moment(text).format('DD/MM/YYYY') : '--'}</>
                    );
                  } catch (error) {
                    console.log();
                  }
                },
              };

            case 'province':
              return {
                width: 160,
                title: col.lable || col.label || '',
                dataIndex: col.code,
                key: col.code,
                ellipsis: true,
                render: (province, record, index) => {
                  try {
                    return (
                      <Tooltip placement="bottom" title={province.name}>
                        <Typography.Paragraph
                          style={{ margin: '0px' }}
                          ellipsis={true}
                        >
                          <OneLineStyle>
                            {province ? province.name : '--'}
                          </OneLineStyle>
                        </Typography.Paragraph>
                      </Tooltip>
                    );
                  } catch (error) {
                    console.log();
                  }
                },
              };

            default:
              return {
                width: 150,
                title: col.lable || col.label || '',
                dataIndex: col.code,
                key: col.code,
                ellipsis: true,
                render: (text, record, index) => {
                  try {
                    if (!text) {
                      const data = record.customer_data.find(
                        item =>
                          item.customer_data_field_code.toLowerCase() ===
                          col.code.toLowerCase()
                      );

                      return (
                        <Tooltip placement="bottom" title={data?.value}>
                          <Typography.Paragraph
                            style={{ margin: '0px' }}
                            ellipsis={true}
                          >
                            <OneLineStyle>
                              {data?.value ? formatPrice(data?.value) : '--'}
                            </OneLineStyle>
                          </Typography.Paragraph>
                        </Tooltip>
                      );
                    } else {
                      return (
                        <Tooltip placement="bottom" title={text}>
                          <Typography.Paragraph
                            style={{ margin: '0px' }}
                            ellipsis={true}
                          >
                            <OneLineStyle>
                              {text
                                ? showPriceFormat(col.code.toLowerCase(), text)
                                : '--'}
                            </OneLineStyle>
                          </Typography.Paragraph>
                        </Tooltip>
                      );
                    }
                  } catch (error) {
                    console.log();
                  }
                },
              };
          }
        });
      setColumns(columnCustomer);
    }
  }, [listDisplayFieldConfigGetAPI]);

  const apiGetCustomerList = useCancellableApi(requestCustomerList);

  // promise api
  const lastPromise = useRef();
  const sourceRef = useRef();

  const getDataCustomers = async (payload = params) => {
    setIsLoadingTable(true);

    try {
      if (sourceRef.current) {
        sourceRef.current.cancel();
      }
      sourceRef.current = Axios.CancelToken.source();

      // fire the api request
      const currentPromise = apiGetCustomerList({ ...payload, limit: 20 }).then(
        async data => {
          return data;
        }
      );

      // store the promise to the ref
      lastPromise.current = currentPromise;

      // handle the result with filtering
      currentPromise.then(
        result => {
          // lấy kết quả cuối cùng
          if (currentPromise === lastPromise.current) {
            if (!result?.status) {
              setIsLoadingTable(false);
              return;
            }

            const dataCustomers = result.data.map(cus => ({
              ...cus,
              key: cus.id,
            }));
            setCustomers(dataCustomers);
            setPaging({
              total: result.paging.totalItemCount,
              current: result.paging.page,
              pageSize: result.paging.limit,
            });
            setIsLoadingTable(false);
          }
        },
        e => {
          if (currentPromise === lastPromise.current) {
            console.warn('fetch failure', e);
          }
        }
      );

      // const result = await apiGetCustomerList({ ...payload, limit: 20 });

      // const dataCustomers = result.data.map(cus => ({ ...cus, key: cus.id }));
      // setCustomers(dataCustomers);
      // setPaging({
      //   total: result.paging.totalItemCount,
      //   current: result.paging.page,
      //   pageSize: result.paging.limit,
      // });

      // setIsLoadingTable(false);
    } catch (error) {
      if (error.message === CODE_EZ_EXCEPTION_API_CANCEL) {
        console.log('Request canceled', error.message);
      } else {
        setIsLoadingTable(false);
      }
    }
  };

  const getDataFieldDisplayCustom = async () => {
    try {
      const result = await requestGetDataFieldDisplayCustom();
      setListDisplayFieldConfigGetAPI(result.data);
    } catch (error) {
      console.log('error getDataFieldDisplayCustom', error);
    }
  };
  useEffect(() => {
    if (!visibleDisplayConfiguration) {
      getDataFieldDisplayCustom();
    }
  }, [visibleDisplayConfiguration]);

  const getDataFieldFilterCustom = async () => {
    try {
      const result = await requestGetDataFieldFilterCustom();
      setListFilterFieldConfigGetAPI(result.data);
    } catch (error) {
      console.log('error getDataFieldFilterCustom', error);
    }
  };
  useEffect(() => {
    if (!visibleFilterConfiguration) {
      getDataFieldFilterCustom();
    }
  }, [visibleFilterConfiguration]);

  const debounceGetDataCustomer = useCallback(
    _.debounce(getDataCustomers, 500),
    []
  );

  useEffect(() => {
    debounceGetDataCustomer(params);
  }, [params]);

  useEffect(() => {
    const eventEmitter = EventEmitter.addListener('import_on_complete', () => {
      debounceGetDataCustomer(params);
    });
    return () => {
      eventEmitter.removeAllListeners(['import_on_complete']);
    };
  }, []);

  const downloadExcelFile = async () => {
    try {
      setIsLoadingExportButton(true);
      const payload = {
        ...params,
        userIds: rowSelected,
      };
      if (isSelectAll) payload.userIds = [];

      await requestExportCustomer(payload);
    } catch (e) {
      notifyFail(e?.msg);
    } finally {
      setIsLoadingExportButton(false);
    }
  };

  const handleChangeParamSearchCustomers = (field, value) => {
    setSearchParamsInUrlVer2({ [field]: value, page: 1 });
    mixpanel.track(`filter customer ${field}: ${value}`);
    setParams({
      ...params,
      [field]: value || '',
      page: 1,
    });
  };

  const selectedAllRowsInAPage = customers.map((r, index) => r.id);

  useEffect(() => {
    if (isSelectAll) {
      setRowSelected(selectedAllRowsInAPage);
    }
  }, [customers]);

  const handleDeleteCustomers = async () => {
    try {
      //all_customer = 1 nếu muốn xóa tất cả khách hàng
      //all_customer = 0 nếu không muốn xóa tất cả khách hàng
      const payload = {
        customer_ids: rowSelected,
        all_customer: isSelectAll ? DELETE_CUSTOMER.ALL : DELETE_CUSTOMER.SOME,
      };
      const result = await requestDeleteMultiCustomer(payload);
      notifySuccess(result.msg);
      setRowSelected([]);
      mixpanel.track(`Delete ${rowSelected.length} customer`, { payload });
    } catch (error) {
      setRowSelected([]);
      notifyFail(error?.msg);
    } finally {
      // getDataCustomers();
      debounceGetDataCustomer(params);
    }
  };

  const onSelectRow = selectedRowKeys => {
    if (selectedRowKeys.length === PAGE_SIZE) {
      notifySuccess(`Bạn đã chọn tất cả khách hàng trang ${paging.current}`);
    }
    setRowSelected([...selectedRowKeys]);
    setIsSelectAll(false);
  };

  const handleSelectRow = selectedRowKeys => {
    if (isSelectAll) {
      notifyFail('Vui lòng bỏ tích chọn tất cả!');
    } else {
      onSelectRow(selectedRowKeys);
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <BreadcrumbAntStyle>
          <Breadcrumb.Item>Danh sách khách hàng</Breadcrumb.Item>
        </BreadcrumbAntStyle>

        <Wrapper id="wrapper">
          <BlockStyle>
            <Filter
              params={params}
              setParams={setParams}
              handleChangeParamSearchCustomers={
                handleChangeParamSearchCustomers
              }
              listFilterFieldConfigGetAPI={listFilterFieldConfigGetAPI}
              setVisibleFilterConfiguration={setVisibleFilterConfiguration}
              setVisibleDisplayConfiguration={setVisibleDisplayConfiguration}
              user={user?.user_enterprise_info?.office_name}
            />
          </BlockStyle>

          <BlockStyle>
            Bộ lọc:{' '}
            <p
              style={{
                fontWeight: 'bold',
                display: 'inline-block',
                margin: '0px',
              }}
            >
              {paging.total}
            </p>{' '}
            khách hàng
          </BlockStyle>

          <BlockStyle>
            <Row justify="space-between" align="middle">
              <Col>
                <Row gutter={[16, 16]} align="middle">
                  <Col>
                    <Checkbox
                      style={{ marginLeft: '10px' }}
                      onChange={event => {
                        setIsSelectAll(event.target.checked);
                        if (event.target.checked) {
                          notifySuccess('Bạn đã chọn toàn bộ khách hàng!');
                          setRowSelected(selectedAllRowsInAPage);
                        } else {
                          setRowSelected([]);
                        }
                      }}
                    >
                      Chọn tất cả
                    </Checkbox>
                  </Col>
                  {rowSelected.length >= 1 && (
                    <>
                      {!isSelectAll ? (
                        <Col>
                          <Popconfirm
                            placement="topLeft"
                            title={
                              'Bạn có muốn xóa những khách hàng được chọn?'
                            }
                            onConfirm={handleDeleteCustomers}
                            okText="Xóa"
                            cancelText="Hủy"
                          >
                            <FontAwesomeIconStyle icon={faTrashAlt} size="lg" />
                          </Popconfirm>
                        </Col>
                      ) : (
                        <></>
                      )}
                      {rowSelected.length === 1 && (
                        <Col>
                          <FontAwesomeIconStyle
                            icon={faEdit}
                            size="lg"
                            onClick={() => {
                              history.push(
                                ROUTER.EDIT_CUSTOMER + '/' + rowSelected[0]
                              );
                            }}
                          />
                        </Col>
                      )}
                      <Col>
                        <ButtonSystemStyle
                          onClick={() => {
                            setIsVisibleAssignModal(true);
                          }}
                        >
                          Giao sale chăm sóc
                        </ButtonSystemStyle>
                      </Col>
                    </>
                  )}
                </Row>
              </Col>

              <Col>
                <Row gutter={[16, 16]} align="middle">
                  <Col>
                    <ButtonSystemStyle
                      bgButtonColor={primaryColor}
                      bgButtonColorHover={primaryColorHover}
                      onClick={() => {
                        history.push(ROUTER.ADD_CUSTOMER);
                      }}
                    >
                      <UserAddOutlined style={{ marginRight: '10px' }} />
                      Thêm mới
                    </ButtonSystemStyle>
                  </Col>
                  <Col>
                    <ButtonSystemStyle
                      bgButtonColor={blueColor}
                      bgButtonColorHover={blueColorHover}
                      onClick={() => {
                        setVisibleImportCustomerModal(true);
                      }}
                    >
                      <ImportOutlined style={{ marginRight: '10px' }} />
                      Import
                    </ButtonSystemStyle>
                  </Col>
                  <Col>
                    <ButtonSystemStyle
                      bgButtonColor={greenColor}
                      bgButtonColorHover={greenColorHover}
                      loading={isLoadingExportButton}
                      disabled={isLoadingExportButton}
                      onClick={() => {
                        notifySuccess('Vui lòng đợi xử lí trong ít phút');
                        downloadExcelFile();
                        mixpanel.track(`Export excel`);
                      }}
                    >
                      {isLoadingExportButton ? (
                        <SpinInButton />
                      ) : (
                        <ExportOutlined style={{ marginRight: '10px' }} />
                      )}
                      Xuất excel
                    </ButtonSystemStyle>
                  </Col>
                </Row>
              </Col>
            </Row>
          </BlockStyle>

          <BlockStyle>
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              spinning={isLoadingTable}
              tip="Đang tải dữ liệu..."
            >
              {columns.length ? (
                <TableAntStyle
                  // loading={isLoadingTable}
                  columns={columns}
                  dataSource={customers}
                  rowSelection={{
                    type: 'select',
                    selectedRowKeys: rowSelected,
                    preserveSelectedRowKeys: true,
                    onChange: (selectedRowKeys, selectedRows) => {
                      handleSelectRow(selectedRowKeys);
                    },
                  }}
                  scroll={{
                    x: 'max-content',
                  }}
                  pagination={{
                    showSizeChanger: false,
                    ...paging,
                    onChange: async (page, pageSize) => {
                      setSearchParamsInUrlVer2({ page });
                      setParams({ ...params, page });
                    },
                  }}
                  locale={{
                    emptyText: (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={<span>Không có dữ liệu.</span>}
                      />
                    ),
                    triggerDesc: '',
                    triggerAsc: '',
                    cancelSort: '',
                  }}
                />
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Vui lòng chọn cấu hình hiển thị để hiển thị thêm thông tin!"
                />
              )}
            </Spin>
          </BlockStyle>
        </Wrapper>
      </div>

      {/* Modal cấu hình hiển thị */}
      {visibleDisplayConfiguration && (
        <DragAndDropConfigurationModal
          titleModal="Cấu hình hiển thị"
          visible={visibleDisplayConfiguration}
          fieldConfig="displayConfig"
          cancelModal={() => setVisibleDisplayConfiguration(false)}
          listFieldConfigGetAPI={listDisplayFieldConfigGetAPI || []}
          getDataCustomers={getDataCustomers}
        />
      )}

      {/* Modal cấu hình bộ lọc */}
      {visibleFilterConfiguration && (
        <DragAndDropConfigurationModal
          titleModal="Cấu hình bộ lọc"
          fieldConfig="filterConfig"
          visible={visibleFilterConfiguration}
          cancelModal={() => setVisibleFilterConfiguration(false)}
          listFieldConfigGetAPI={listFilterFieldConfigGetAPI || []}
          getDataCustomers={getDataCustomers}
          user={user?.user_enterprise_info?.office_name}
        />
      )}

      {/* import khách hàng */}
      <ImportCustomersModal
        visibleImportCustomerModal={visibleImportCustomerModal}
        setVisibleImportCustomerModal={setVisibleImportCustomerModal}
        isRequiredCustomerGroupSelect={false}
        // id nhóm KH được chọn để import
        idCustomerGroupSelected={idCustomerGroupSelected}
        setIdCustomerGroupSelected={setIdCustomerGroupSelected}
        user={user?.user_enterprise_info?.office_name}
      />

      {/* Modal giao việc */}
      {isVisibleAssignModal ? (
        <AssignmentModal
          isVisible={isVisibleAssignModal}
          setIsVisible={setIsVisibleAssignModal}
          customerSearchParameters={params}
          listFilterFieldConfigGetAPI={listFilterFieldConfigGetAPI}
          setVisibleFilterConfiguration={setVisibleFilterConfiguration}
          setVisibleDisplayConfiguration={setVisibleDisplayConfiguration}
          customerIds={rowSelected}
          user={user?.user_enterprise_info?.office_name}
          //select all
          isSelectAll={isSelectAll}
          totalCustomer={paging.total}
        />
      ) : (
        <></>
      )}
    </>
  );
}
