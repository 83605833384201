import { WarningFilled, CheckCircleFilled } from '@ant-design/icons';
import { Select, Timeline, Upload } from 'antd';
import Icon from 'react-icons-kit';
import styled from 'styled-components';

const SelectStyled = styled(Select)`
  border-bottom: 1px solid;
  margin: 0 0 0 15px;
  width: 185px;

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 10px 0 0;
  }
`;

const TimelineStyled = styled(Timeline)`
  &&.last-item {
    border-left: none;
  }
  .ant-timeline-item-tail {
    border-left: 2px dotted #b3b3b3;
  }
`;

const NameCustomer = styled.span`
  font-size: 18px;
  cursor: pointer;
  :hover {
    color: #ef8737;
    text-decoration: underline;
  }
`;

const InputStyled = styled.input`
  border: none;
  padding: 0 0 0 10px;
  :focus-visible {
    fill: none;
  }
`;

const UploadStyled = styled(Upload)`
  .ant-upload-list-item-error {
    color: unset !important;
  }
  .ant-upload-list-item-name {
    color: unset !important;
  }
  .ant-upload-text-icon > .anticon {
    color: unset !important;
  }
  .ant-tooltip-inner {
    display: none;
  }
`;

const IconStyled = styled(Icon)`
  margin-left: 4px;
  transition: transform 0.3s;
  transform: rotate(0deg);
  cursor: pointer;
  padding-top: 1px;

  &.isExpand {
    transition: transform 0.3s;
    transform: rotate(180deg) translateY(-4px);
  }
`;

const ExpandInfoStyled = styled('div')`
  &.discount-code-container {
    animation: fade-in 0.3s;
  }

  &.discount-code-container.expanded {
    animation: slide-down 0.3s;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes slide-down {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const WarningIconStyled = styled(WarningFilled)`
  color: #ff5a65;

  svg {
    font-size: 20px;
  }
`;

const SuccessIconStyled = styled(CheckCircleFilled)`
  color: #3bc582;

  svg {
    font-size: 20px;
  }
`;

const PromotionStyled = styled('div')`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 3px 12px;
  border: 1px solid #3bc582;
  border-radius: 4px;
  color: #3bc582;

  &.notValid {
    text-decoration: line-through;
    border: 1px solid #ff5a65;
    color: #ff5a65;
    cursor: not-allowed;
  }
`;

export {
  ExpandInfoStyled,
  IconStyled,
  InputStyled,
  NameCustomer,
  SelectStyled,
  TimelineStyled,
  UploadStyled,
  WarningIconStyled,
  PromotionStyled,
  SuccessIconStyled,
};
