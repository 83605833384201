import { GET_JOB_STATISTIC, GET_JOB_STATISTIC_SUCCESS, GET_JOB_STATISTIC_FAIL } from '@actions/type';

import reactotron from 'reactotron-react-js';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    // *************  GET INFO ****************//
    case GET_JOB_STATISTIC:
      return {
        ...state,
        isLoading: true,
      };
    case GET_JOB_STATISTIC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.response.data,
        error: null,
      };
    case GET_JOB_STATISTIC_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
